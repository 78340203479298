import axios from '../shared/axios';
import CrudHelper from "./helper";

export function loadList(contractid, pricelistid) {
    return axios.get(`/api/PriceListItem/RegisterList?contractid=${contractid}&pricelistid=${pricelistid}`);
}


export function createPriceListItems(data) {
    return axios.post(`/api/PriceListItem/Create`, data);
}


export function updatePriceListItems(updatedPriceListItem) {
    return axios.put(`/api/PriceListItem/Update`, updatedPriceListItem);
}


export function deletePriceItemsList(pricelistitemid) {
    return axios.delete(`/api/PriceListItem/Delete?pricelistitemid=${pricelistitemid}`);
}