import axios from '../shared/axios';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function saveForecast(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/Forecast/SaveForecast`, data)
            .then(result => {
                if (data.showtasknotification === 1) {
                    store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                }
                resolve(result);
            })
            .catch(error => reject(error));
        });
    }

export function getForecast(forecastid) {
    return axios.get(`/api/Forecast/GetForecast?earlyforecastid=${forecastid}`);
}

export function getForecasts(companyid, projectid, contractid, type, accessibleWfIds) {
    return axios.post(`/api/Forecast/LoadForecastsList`, { accessibleWfIds }, { params: { companyid, projectid, contractid, type } });
}

export function deleteForecastDraft(forecastid) {
    return new Promise((resolve, reject) => {
    axios.post(`api/Forecast/DeleteDraft?forecastid=${forecastid}`)
        .then(result => {
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
            resolve(result);
        })
        .catch(error => reject(error));
    })
    
}