import React from 'react';
import { withSnackbar } from 'notistack';
import { Portlet, PortletBody, PortletHeader } from "../../../../app/partials/content/Portlet";
import * as companiesCrud from '../../../../app/crud/companies.crud';
import * as companyUserCrud from '../../../../app/crud/companyUser.crud';
import * as contractUserCrud from '../../../../app/crud/contractUser.crud';
import { contractRoleTypes, contractPartyTypes } from "../../../../app/shared/lookup";
import DataGrid, { Column, SearchPanel, HeaderFilter, FilterRow, Selection, Scrolling, ColumnChooser, ColumnFixing } from 'devextreme-react/data-grid';
import { injectIntl } from "react-intl";
import moment from 'moment';
import { SelectBox, DropDownBox, Button, ValidationGroup, ValidationSummary, Switch } from 'devextreme-react';
import { Validator, RequiredRule, } from 'devextreme-react/validator';
import { alert as dxAlert } from 'devextreme/ui/dialog';
import axios from 'axios';
import { connect } from "react-redux";

const bypassCompanyCheck = process.env.REACT_APP_ADD_BULK_CONTRACT_USER_BYPASS_COMPANYCHECK_POSSIBLE === "true";

class ContractBulkAddUsers extends React.Component {
    constructor(props) {
        super(props);
        this.validatorRef = React.createRef()
        this.dataGrid = React.createRef();
        this.contractsDropdown = React.createRef();
        this.contractGridColumns = [
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.ID" }), dataField: "contractid" },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.STATUS" }), dataField: "recordstatus", calculateCellValue: this.calculateContractState },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.NUMBER" }), dataField: "contractnumber" },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.NAME" }), dataField: "contractname" },
            { caption: this.props.intl.formatMessage({ id: "PROJECT.FIELD.CODE" }), dataField: "projectcode" },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.PROJECT_NAME" }), dataField: "projectname" },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.TYPE" }), dataField: "contracttype" },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.CLIENT.COMPANY" }), dataField: "clientcompany", visible: false },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.SUPPLIER.COMPANY" }), dataField: "suppliercompany", visible: false },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.PM.COMPANY" }), dataField: "pmcompany", visible: false },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.SUPERVISOR.COMPANY" }), dataField: "supervisorcompany", visible: false },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.CURRENT_CMP_DATE" }), dataField: "currentcompletiondate", dataType: "date", format: (date) => moment(date).format('DD/MM/YYYY'), visible: false },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.LEAD_CONSULTANT" }), dataField: "leadconsultantcontractor", visible: false },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.LEAD_CLIENT" }), dataField: "leadclient", visible: false },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.LEAD_PM" }), dataField: "leadpmsm", visible: false },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.COMPANY_PARTY_MATCH" }), dataField: "companyandpartymatch", visible: false }
        ];

        const companiesDataSource = this.getCompanySelectDataSource();

        this.state = {
            formData: {},
            companiesDataSource,
            companyUsersDataSource: [],
            contractsList: null,
            selectedContracts: null,
            isSubmitting: false,

        }
    }
    componentWillUnmount() {
        if (this.requestSource) {
            this.requestSource.cancel('Operation canceled by the user.');
        }
    }
    calculateContractState = (rowData) => {
        if (rowData.deleted === 1) {
            return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.DELETED' });
        }
        else {
            switch (rowData.recordstatus) {
                case 1:
                    return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.ACTIVE' });
                case 2:
                    return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.COMPLETED' });
                case 3:
                    return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.ARCHIVED' });
                default:
                    return 'Unknown';
            }
        }
    }
    clearForm = () => {
        const emptyItem = {};
        for (const prp in this.state.formData) {
            emptyItem[prp] = null;
        }
        this.contractsDropdown.current.instance.reset();
        // this.dataGrid.current.instance.clearSelection();
        this.setState({ formData: emptyItem, contractsList: null, selectedContracts: null, companyUsersDataSource: [] }, () => { this.validatorRef.current.instance.reset(); });
    }
    submitHandler = async (e) => {
        e.preventDefault();
        this.setState({ isSubmitting: true }, async () => {
            try {
                const payload = {
                    ...this.state.formData,
                    contractids: this.state.selectedContracts
                }
                await contractUserCrud.saveContractUserToMultipleContracts(payload);

                await dxAlert(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "CONTRACT.USERS.WARNING_TEXT_ADD_USER" })}</div>`, this.props.intl.formatMessage({ id: "CONTRACT.USERS.WARNING_TEXT_ADD_USER_TITLE" }));
                this.clearForm();
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: "error" });
            }
            finally {
                this.setState({ isSubmitting: false });
            }
        });
    }

    onDropdownValueChanged = (e) => {
        if (e.value == null) {
            //selection cleared
            this.dataGrid.current.instance.clearSelection();
            // this.setState({
            //     selectedContracts: null
            // });
        }
    }
    contractsGridOnSelectionChanged = (e, b) => {
        const { selectedContracts } = this.state;
        //The newly selected contracts
        const newlySelectedContracts = e.selectedRowKeys.filter(key => !selectedContracts || !selectedContracts.includes(key));

        //The contracts that are no longer selected
        const deselectedContracts = selectedContracts ? selectedContracts.filter(key => !e.selectedRowKeys.includes(key)) : [];
        if (newlySelectedContracts.length > 0 || deselectedContracts.length > 0) {
            //Update the selectedContracts state
            const updatedSelectedContracts = selectedContracts ? [...selectedContracts, ...newlySelectedContracts].filter(key => !deselectedContracts.includes(key)) : [...newlySelectedContracts];
            this.setState({ selectedContracts: updatedSelectedContracts });
        }
    }

    updateContractsListForUser = async () => {
        const { accountid, party, includeunmatchedcontracts } = this.state.formData;
        if (accountid && party) {
            try {
                if (this.requestSource) {
                    this.requestSource.cancel('Operation canceled due to new request.');
                }
                this.requestSource = axios.CancelToken.source();
                this.dataGrid.current.instance.beginCustomLoading('Fetching available contracts');
                this.setState({ contractsList: [] }, async () => {
                    let { data: contractsList } = await contractUserCrud.loadContractsNotAssignedToList(accountid, party, !!includeunmatchedcontracts, this.requestSource.token);
                    if (!Array.isArray(contractsList)) {
                        contractsList = [];
                    }
                    if (contractsList.length === 0) {
                        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "CONTRACT.USERS.BULK.WARNING_NO_CONTRACT" }), { variant: "warning" });
                    }
                    this.setState({ contractsList }, () => {
                        this.dataGrid.current.instance.endCustomLoading();
                    });
                })
            }
            catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ contractsList: null });
                this.dataGrid.current.instance.endCustomLoading();
            }
        }
    }

    getCompanySelectDataSource = () => {
        const datasource = {
            loadMode: 'raw',
            cacheRawData: true,
            load: () => {
                var promise = new Promise((resolve) => {
                    companiesCrud.getCutDownCompaniesList(this.props.userProfile)
                        .then(response => {
                            resolve(response.data);
                        });
                });
                return promise;
            }
        }
        return datasource;
    }
    getCompanyUserDataSource = (companyid) => {
        if (companyid) {
            const datasource = {
                loadMode: 'raw',
                cacheRawData: true,
                load: () => {
                    var promise = new Promise((resolve) => {
                        companyUserCrud.getCompanyUsersToSelectList(companyid)
                            .then(response => {
                                let users = response.data;
                                if (users.length < 1) {
                                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "WARNING.COMPANY_HAS_NO_USERS" }), { variant: 'warning', });
                                }
                                users = users.map(user => ({ accountid: user.accountid, display: `${user.name} (${user.email})` }));

                                resolve(users);
                            });
                    });
                    return promise;
                }
            }
            return datasource;
        }
        else return null;
    }

    render() {
        return (
            <Portlet>
                <PortletHeader resource="CONTRACT.USERS.BULK.TITLE" />
                <PortletBody style={{ maxWidth: 800 }}>
                    <form id='InitialSubmitForm' autoComplete='off' onSubmit={this.submitHandler}>
                        <div className="form">
                            <ValidationGroup ref={this.validatorRef} name="AddBulkContractUserValidator">
                                <div className="dx-fieldset">
                                    <div className="dx-field">
                                        <div className="dx-field-label" style={{ width: "20%" }}>{this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.COMPANY" })}</div>
                                        <div className="dx-field-value" style={{ width: "80%" }}>
                                            <SelectBox
                                                value={this.state.formData.companyid}
                                                valueExpr="companyid"
                                                displayExpr="name"
                                                placeholder={this.props.intl.formatMessage({ id: "CONTRACT.USERS.BULK.COMPANY_PH" })}
                                                dataSource={this.state.companiesDataSource}
                                                searchEnabled={true}
                                                searchMode="contains"
                                                validationMessagePosition="left"
                                                onValueChanged={(e) => { this.setState({ formData: { ...this.state.formData, companyid: e.value, accountid: null }, companyUsersDataSource: this.getCompanyUserDataSource(e.value) }) }}
                                            >
                                                <Validator>
                                                    <RequiredRule message={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.COMPANY" }) + ' ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.VALIDATION.REQUIRED' })} />
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label" style={{ width: "20%" }}>{this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.USER" })}</div>
                                        <div className="dx-field-value" style={{ width: "80%" }}>
                                            <SelectBox
                                                value={this.state.formData.accountid}
                                                valueExpr="accountid"
                                                displayExpr="display"
                                                placeholder={this.props.intl.formatMessage({ id: "CONTRACT.USERS.BULK.USER_PH" })}
                                                dataSource={this.state.companyUsersDataSource}
                                                searchEnabled={true}
                                                searchMode="contains"
                                                onValueChanged={(e) => {
                                                    this.setState({ formData: { ...this.state.formData, accountid: e.value } }, this.updateContractsListForUser);
                                                }}
                                            >
                                                <Validator>
                                                    <RequiredRule message={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.USER" }) + ' ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.VALIDATION.REQUIRED' })} />
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label" style={{ width: "20%" }}>{this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.PARTY" })}</div>
                                        <div className="dx-field-value" style={{ width: "80%" }}>
                                            <SelectBox
                                                value={this.state.formData.party}
                                                placeholder={this.props.intl.formatMessage({ id: "CONTRACT.USERS.BULK.PARTY_PH" })}
                                                dataSource={contractPartyTypes}
                                                searchEnabled={true}
                                                searchMode="contains"
                                                onValueChanged={(e) => {
                                                    this.setState({ formData: { ...this.state.formData, party: e.value } }, this.updateContractsListForUser);
                                                }}
                                            >
                                                <Validator>
                                                    <RequiredRule message={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.PARTY" }) + ' ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.VALIDATION.REQUIRED' })} />
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label" style={{ width: "20%" }}>{this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.ROLE" })}</div>
                                        <div className="dx-field-value" style={{ width: "80%" }}>
                                            <SelectBox
                                                value={this.state.formData.role}
                                                placeholder={this.props.intl.formatMessage({ id: "CONTRACT.USERS.BULK.ROLE_PH" })}
                                                dataSource={contractRoleTypes}
                                                searchEnabled={true}
                                                searchMode="contains"
                                                onValueChanged={(e) => { this.setState({ formData: { ...this.state.formData, role: e.value } }); }}
                                            >
                                                <Validator>
                                                    <RequiredRule message={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.ROLE" }) + ' ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.VALIDATION.REQUIRED' })} />
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                                    {
                                        bypassCompanyCheck && (
                                            <div className="dx-field">
                                                <div className="dx-field-label" style={{ width: "20%", whiteSpace: 'normal' }}>{this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.INCLUDE_UNMATCHED_CONTRACTS_TO_LIST" })}</div>
                                                <div style={{ width: "80%", marginTop: "15px" }}>
                                                    <Switch
                                                        // value={this.state.formData.includeunmatchedcontracts}
                                                        switchedOffText="No"
                                                        switchedOnText="Yes"
                                                        hint={this.props.intl.formatMessage({ id: "CONTRACT.FIELD.COMPANY_PARTY_MATCH.HINT" })}
                                                        onValueChanged={(e) => {
                                                            this.setState({ formData: { ...this.state.formData, includeunmatchedcontracts: e.value } }, this.updateContractsListForUser);

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className="dx-field">
                                        <div className="dx-field-label" style={{ width: "20%" }}>{this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.CONTRACTS" })}</div>
                                        <div className="dx-field-value" style={{ width: "80%" }}>
                                            <DropDownBox
                                                ref={this.contractsDropdown}
                                                id='AvailableContractsDropDown'
                                                dataSource={this.state.contractsList}
                                                valueExpr="contractid"
                                                deferRendering={true}
                                                displayExpr="contractname"
                                                placeholder={this.props.intl.formatMessage({ id: "CONTRACT.USERS.BULK.CONTRACTS_PH" })}
                                                showClearButton={true}
                                                value={this.state.selectedContracts}
                                                onValueChanged={this.onDropdownValueChanged}
                                                showDropDownButton={true}
                                                dropDownOptions={{ 
                                                    width: '80vw', 
                                                    maxHeight: '50vh', 
                                                    showCloseButton: false, 
                                                    showTitle: true, 
                                                    title: 'Available contracts for user',
                                                    position:{ at:"bottom",of:'#AvailableContractsDropDown'}
                                                }}
                                            >

                                                <Validator>
                                                    <RequiredRule message={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.CONTRACTS" }) + ' ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.VALIDATION.REQUIRED' })} />
                                                </Validator>
                                                <FilterRow visible={true} />
                                                <Column allowFiltering={false} />
                                                <DataGrid
                                                    ref={this.dataGrid}
                                                    keyExpr="contractid"
                                                    // height={440} //TEST TODO
                                                    dataSource={this.state.contractsList}
                                                    columns={this.contractGridColumns}
                                                    hoverStateEnabled={true}
                                                    // selectedRowKeys={this.state.selectedContracts}
                                                    onSelectionChanged={this.contractsGridOnSelectionChanged}
                                                    style={{ height: '100%' }}
                                                >
                                                    <SearchPanel visible={true} />
                                                    <HeaderFilter visible={true} />
                                                    <Selection mode="multiple" allowSelectAll="true" selectAllMode="allPages" showCheckBoxesMode="always" />
                                                    <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                                                    <FilterRow visible={true} />
                                                    <ColumnChooser enabled={true} />
                                                    <ColumnFixing enabled={true} />
                                                </DataGrid>
                                            </DropDownBox>
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <ValidationSummary elementAttr={{ align: "right" }} id={this.validatorName}></ValidationSummary>
                                    </div>
                                    <div className="dx-field" style={{ marginTop: "10px" }}>
                                        <Button
                                            style={{ float: 'right' }}
                                            text={this.props.intl.formatMessage({ id: "CONTRACT.USERS.BULK.ACTION" })}
                                            disabled={this.state.isSubmitting}
                                            type="default"
                                            stylingMode="outlined"
                                            icon={this.state.isSubmitting ? 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--dark' : null}
                                            useSubmitBehavior={true}
                                        >
                                        </Button>
                                    </div>
                                </div>
                            </ValidationGroup>
                        </div>
                    </form>
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    userProfile: store.auth.userProfile,
});

export default connect(mapStateToProps)(injectIntl(withSnackbar(ContractBulkAddUsers)));