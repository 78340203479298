import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import CertificateCustomForm from './CustomForm';
import * as certificateCrud from '../../../../crud/certificate.crud';
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import Common from '../../../../shared/common';
import { actionCodes } from "../../../../shared/lookup";

class CertificateCreatePage extends BaseCreatePage {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: true,
        };
    }

    submitHandler = async (certificate, setSubmitting) => {

        const postEvents = (createdid) => {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
            this.props.navigate(this.props.workflowItem.paths.basePath + createdid);
        };

        const { companyid, projectid, contractid } = this.props.contract;
        certificate.companyid = companyid;
        certificate.contractid = contractid;
        certificate.projectid = projectid;
        certificate.status = 'Not Communicated';
        certificate.showtasknotification = 0;
        certificate.party = this.props.notificationType;

        if (certificate.certifieddate) {
            certificate.certifieddatedisplay = moment(certificate.certifieddate).format('DD/MM/YYYY');
        }

        if (certificate.replyrequireddate) {
            const replyRequiredDate = moment(certificate.replyrequireddate);
            certificate.replyrequireddate = moment().set({ 'year': replyRequiredDate.year(), 'month': replyRequiredDate.month(), 'date': replyRequiredDate.date() }).toDate();
        }
        certificate.actioncode = actionCodes.CREATE;
        certificate.notifyingparty = this.props.workflowItem.notifyingParty;
        if (this.props.workflowItem.replyEnabled) {
            certificate.replyingparty = this.props.workflowItem.replyingParty;
        }
        this.appendRelationship(certificate);
        Common.PropertyConversionsBeforeNoticeSaveRequest(certificate, this.props.customFields, this.props.contractUsers);
        certificateCrud.saveCertificate(certificate)
            .then(response => {
                const createdID = Number(response.data);
                postEvents(createdID);
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });
    }
    render() {
        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    <CertificateCustomForm
                        {...this.props}
                        onSubmitHandler={this.submitHandler}
                        customFields={(this.props.customFields || {}).COMMUNICATION}
                        assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(CertificateCreatePage));
