import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper"
import * as inspectionDefectCrud from '../../../../crud/inspectionDefect.crud';
import { notificationAggregateListTagsFilter } from "../../../../store/helpers/FilterHelper";
import axios from 'axios';
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

class DefectListAll extends React.Component {
    constructor(props) {
        super(props);
        this.gridRef = React.createRef();

        if (this.props.notificationAbbreviation_SUPER) {
            this.maxReferenceIDPrefix = this.props.notificationAbbreviation_SUPER;
        }
        else if (this.props.notificationAbbreviation_SUPP) {
            this.maxReferenceIDPrefix = this.props.notificationAbbreviation_SUPP;
        }

        if (this.maxReferenceIDPrefix && this.props.notificationAbbreviation_SUPP && this.maxReferenceIDPrefix.length < (this.props.notificationAbbreviation_SUPP||'').length) {
            this.maxReferenceIDPrefix = this.props.notificationAbbreviation_SUPP;
        }

        this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.notificationsRelsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';
        this.customFields = [];
        if (props.customFields) {
            if (props.customFields.COMMUNICATION && props.customFields.COMMUNICATION.length > 0) {
                this.customFields.push(...(props.customFields.COMMUNICATION.filter(cf => cf.includelisting)));
            }
            if (props.customFields.REPLY && props.customFields.REPLY.length > 0) {
                this.customFields.push(...(props.customFields.REPLY.filter(cf => cf.includelisting)));
            }
        }
        this.state = {
            notifications: null,
            selectedDefect: null,
            showUpdateModal: false,
            showResolveModal: false,
        };
    }

    componentDidMount() {
        this.fetchNotifications();
    }

    fetchNotifications = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;

            const promises = [];
            promises.push(inspectionDefectCrud.getContractInspections(companyid, projectid, contractid, "42.2CON", this.props.accessibleWfIds));
            promises.push(inspectionDefectCrud.getContractInspections(companyid, projectid, contractid, "42.2SUP", this.props.accessibleWfIds));

            const responseObj = await axios.all(promises);
            let listData1 = notificationAggregateListTagsFilter(JSON.parse(responseObj[0].data.aggregatelist));
            listData1 = listData1.filter(ntf => ntf.showtasknotification === 1);

            let listData2 = notificationAggregateListTagsFilter(JSON.parse(responseObj[1].data.aggregatelist));
            listData2 = listData2.filter(ntf => ntf.showtasknotification === 1);

            const allItems = listData1.concat(listData2).sort((a, b) => a.sequencenumber - b.sequencenumber);

            this.setState({ notifications: allItems });
        } catch (error) {
            console.log(error);
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        let redirectURL = '';
        if (rowData.type === '42.2SUP') {
            redirectURL = this.props.workflowItem.paths.basePath.replace('register', 'supervisor')  + rowData.inspectiondefectid;
        }
        else if (rowData.type === '42.2CON') {
            redirectURL = this.props.workflowItem.paths.basePath.replace('register', 'supplier')  + rowData.inspectiondefectid;
        }
        return  (skipHostUrl ? '' : process.env.PUBLIC_URL) + redirectURL;
    }
    renderIdColumn = (cellInfo) => {
        let redirectURL = '';
        if (cellInfo.data.type === '42.2SUP') {
            redirectURL = this.props.workflowItem.paths.basePath.replace('register', 'supervisor')  + cellInfo.data.inspectiondefectid;
        }
        else if (cellInfo.data.type === '42.2CON') {
            redirectURL = this.props.workflowItem.paths.basePath.replace('register', 'supplier') + cellInfo.data.inspectiondefectid;
        }

        return (
            <Link to={redirectURL}>
                <strong style={{ whiteSpace: 'normal' }}>
                    {this.getDefectID(cellInfo.data)}
                </strong>
            </Link>
        );
    }
    sortIdColumn = (rowData) => {
        return rowData.sequencenumber;
    }

    renderStatusColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        //let label = cellInfo.data.replystatus;
        switch (cellInfo.data.replystatus) {
            case 'Awaiting Reply':
            case 'Awaiting Acceptance':
                badgeVariant = 'warning';
                break;
            case 'Not Communicated':
                badgeVariant = 'secondary';
                break;
            case 'Uncorrected':
                //label = 'Open';
                badgeVariant = 'danger';
                break;
            case 'Not Accepted':
                badgeVariant = 'danger';
                break;
            case 'Corrected':
                //label = 'Closed';
                badgeVariant = 'success';
                break;
            case 'Reply Received':
            case 'Accepted':
                badgeVariant = 'success';
                break;
            default:
                break;
        }
        const displayText = this.calculateStatusValue(cellInfo.data);
        return (<Badge variant={badgeVariant}>{displayText}</Badge>);
    }
    calculateStatusValue = (rowData) => {
        let status = rowData.replystatus;
        if (status === 'Uncorrected') {
            status = 'Open';
        }
        if (status === 'Corrected') {
            status = 'Closed';
        }
        return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + status });
    }
    calculateNCProposalStatusValue = (rowData) => {
        if (rowData.location) {
            if ((this.props.NCSubmissionReplyOptions || {}).hasOwnProperty(rowData.location)) {
                return this.props.NCSubmissionReplyOptions[rowData.location];
            }
            else {
                return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + rowData.location });
            }
        }
        else { return null; }

    }
    calculateNonConformityStatusValue = (rowData) => {
        if (rowData.reason) {
            if ((this.props.NonConformityReplyOptions || {}).hasOwnProperty(rowData.reason)) {
                return this.props.NonConformityReplyOptions[rowData.reason];
            }
            else {
                return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + rowData.reason });
            }
        }
        else { return null; }
    }
    // renderToolbar = () => {
    //     if (this.props.canActionSupplier || this.props.canActionSuper) {
    //         const enabled = this.state.selectedDefect &&
    //             this.state.selectedDefect.replystatus !== 'Corrected';
    //         const hasRight = enabled && ((this.state.selectedDefect.type === '42.2SUP' && this.props.canActionSuper) ||
    //             (this.state.selectedDefect.type === '42.2CON' && this.props.canActionSupplier));

    //         return (
    //             <div style={{ display: 'flex', flexFlow: 'row-reverse', margin: "20px 0px" }}>
    //                 <Button disabled={!hasRight} onClick={() => this.setState({ showResolveModal: true })} variant="primary" size="sm" style={{ marginLeft: "10px" }}>
    //                     <i className="fas fa-check-circle"></i>
    //                     <FormattedMessage id="CONTRACT.WF.DEFECTS.BUTTON.CLOSE_DEFECT" />
    //                 </Button>
    //                 <Button disabled={!hasRight} onClick={() => this.setState({ showUpdateModal: true })} variant="primary" size="sm" >
    //                     <i className="fas fa-pen"></i>
    //                     <FormattedMessage id="CONTRACT.WF.DEFECTS.BUTTON.UPDATE_OWNER_ACTIONS" />
    //                 </Button>
    //             </div>
    //         );
    //     }
    //     else {
    //         return null;
    //     }
    // }
    // onResolveModalResponse = (resp) => {
    //     this.setState({ showUpdateModal: false, showResolveModal: false });
    //     if (resp) {
    //         const defect = { ...this.state.selectedDefect };
    //         defect.replystatus = 'Corrected';
    //         defect.inspectiondatedisplay = moment(resp.CorrectionDate).format('DD/MM/YYYY HH:mm:ss');
    //         this.saveDefect(defect);
    //     }
    // }
    // onActionModalResponse = (resp) => {
    //     this.setState({ showUpdateModal: false, showResolveModal: false });
    //     if (resp) {
    //         const defect = { ...this.state.selectedDefect, ...resp };
    //         this.saveDefect(defect);
    //     }
    // }
    // saveDefect = (issue) => {
    //     if (!issue.notificationdatedisplay && issue.notificationdate) {
    //         issue.notificationdatedisplay = moment(issue.notificationdate).format('DD/MM/YYYY');
    //     }
    //     // if (!issue.createbyid && issue.createby) {
    //     //     issue.createbyid = issue.createby;
    //     // }
    //     if (!issue.inspectiondatedisplay && issue.inspectiondate) {
    //         issue.inspectiondatedisplay = moment(issue.inspectiondate).format('DD/MM/YYYY HH:mm:ss');
    //     }
    //     inspectionCrud.saveInspectionDefect(issue)
    //         .then(response => {
    //             this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });
    //             this.props.fetchInspection(issue.type, true);
    //             this.gridRef.current.instance.clearSelection();
    //         })
    //         .catch(err => {
    //             this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
    //         });
    // }


    getDefectID = (defect) => {
        return defect.referenceid;
    }

    // addGridContextMenuItems = (e) => {
    //     if (e.target === 'content') {
    //         this.gridRef.current.instance.selectRows([e.row.data.inspectiondefectid]);
    //         // e.items can be undefined
    //         if (!e.items) e.items = [];
    //         let enabled = false;
    //         if (e.row.data && e.row.data.replystatus !== 'Corrected') {
    //             if (e.row.data.type === '42.2CON') {
    //                 enabled = this.props.canActionSupplier;
    //             }
    //             else if (e.row.data.type === '42.2SUP') {
    //                 enabled = this.props.canActionSuper;
    //             }
    //         }

    //         // Add a custom menu item
    //         e.items.push({
    //             text: this.props.intl.formatMessage({ id: 'CONTRACT.WF.DEFECTS.BUTTON.UPDATE_OWNER_ACTIONS' }),
    //             disabled: !enabled,
    //             icon: 'edit',
    //             onItemClick: () => {
    //                 //this.dataGrid.instance.selectRows([e.row.data.warningid]);
    //                 this.setState({ selectedDefect: e.row.data, showUpdateModal: true });
    //             }
    //         });
    //         e.items.push({
    //             text: this.props.intl.formatMessage({ id: 'CONTRACT.WF.DEFECTS.BUTTON.CLOSE_DEFECT' }),
    //             disabled: !enabled,
    //             icon: 'check',
    //             onItemClick: () => {
    //                 //this.dataGrid.instance.selectRows([e.row.data.warningid]);
    //                 this.setState({ selectedDefect: e.row.data, showResolveModal: true });
    //             }
    //         });
    //     }
    // }
    // onToolbarPreparing = (e) => {
    //     const enabled = this.state.selectedDefect &&
    //         this.state.selectedDefect.replystatus !== 'Corrected';
    //     const hasRight = enabled && ((this.state.selectedDefect.type === '42.2SUP' && this.props.canActionSuper) ||
    //         (this.state.selectedDefect.type === '42.2CON' && this.props.canActionSupplier));

    //     const onCorrectDefect = () => this.setState({ showResolveModal: true });
    //     const onUpdateOwner = () => this.setState({ showUpdateModal: true });
    //     const items = [];
    //     items.push(
    //         {
    //             location: 'before',
    //             template: 'toolbarTitle'
    //         }
    //     );
    //     items.push(
    //         {
    //             location: "after",
    //             widget: "dxButton",
    //             options: {
    //                 icon: "edit",
    //                 text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_UPDATE' }),
    //                 disabled: !hasRight,
    //                 onClick: onUpdateOwner
    //             }
    //         }
    //     );
    //     items.push(
    //         {
    //             location: "after",
    //             widget: "dxButton",
    //             options: {
    //                 icon: "check",
    //                 text: this.props.intl.formatMessage({ id: 'CONTRACT.WF.DEFECTS.BUTTON.CLOSE_DEFECT' }),
    //                 disabled: !hasRight,
    //                 onClick: onCorrectDefect
    //             }
    //         }
    //     );

    //     e.toolbarOptions.items.unshift(...items);
    // }

    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }
        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.SUBJECT", dataField: 'replyactiondetail' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_DATE", dataField: "notificationdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_BY", dataField: "createby" },
            { resource: "GENERAL.FORMS.FIELD.ACTIONS", dataField: 'replycomment', visible: true, isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.OWNER", dataField: 'replyaction' },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "replystatus", cellRender: this.renderStatusColumn, calculateCellValue: this.calculateStatusValue },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "CONTRACT.WF.DEFECTS.FIELD.CLOSE_DATE", dataField: 'inspectiondate', dataType: 'date', visible: false },
            { resource: "CONTRACT.WF.DEFECTS.FIELD.CLOSURE_REASON", dataField: "closurereason", visible: false }
        ];
        if (this.props.plannedClosureEnabled || this.props.NCSubmissionWFexist) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PLANNED_CLOSURE", dataField: 'plannedclosuredate', dataType: 'date', visible: false }
            )
        }
        if (this.props.NCSubmissionWFexist) {
            columns.push(
                { resource: "CONTRACT.WF.DEFECTS.FIELD.PROPOSAL_ID", dataField: 'inspectiontime', visible: false },
                { resource: "CONTRACT.WF.DEFECTS.FIELD.POPOSAL_STATUS", dataField: 'location', visible: false, calculateCellValue: this.calculateNCProposalStatusValue },
                { resource: "CONTRACT.WF.DEFECTS.FIELD.NC_ID", dataField: 'assistancetestserchdefect', visible: false },
                { resource: "CONTRACT.WF.DEFECTS.FIELD.NC_STATUS", dataField: 'reason', visible: false, calculateCellValue: this.calculateNonConformityStatusValue },
                { resource: "CONTRACT.WF.DEFECTS.FIELD.EFFECTIVE_DATE", dataField: 'replydate', dataType: 'date', visible: false }
            )
        }
        if (this.props.workflowItem.assetFieldsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.ASSET_NAME", dataField: "assetname", visible: false },
                { resource: "GENERAL.FORMS.FIELD.ASSET_TYPE", dataField: "assettype", visible: false },
            );
        }
        if (this.notificationsTagsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag' },
                { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag' },
                { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag' },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag' }
            )
        }
        if (this.notificationsRelsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.TAB_RELATIONS", dataField: "relatednotices", visible: false },
            )
        }
        columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(this.customFields));

        return (
            <Portlet>
                <PortletBody >
                    <DataGridBase
                        version="1"
                        title={this.props.title}
                        // onToolbarPreparing={this.onToolbarPreparing}
                        gridRef={this.gridRef}
                        // selectedRowKeys={null}
                        referenceIDPrefix={this.maxReferenceIDPrefix}
                        name={this.props.intl.formatMessage({ id: 'CONTRACT.WF.DEFECTS.TITLE.REGISTER' })}
                        dataSource={this.state.notifications}
                        keyExpr="inspectiondefectid"
                        // allowSelection={true}
                        // selection={{ mode: 'single', showCheckBoxesMode: 'onClick' }}
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                    // onContextMenuPreparing={this.addGridContextMenuItems}
                    />
                    {/* <DataGrid
                        ref={(ref) => this.dataGrid = ref}
                        name={`Master Defect Register`}
                        style={{ width: '100%', height: '100%', maxHeight: '60vh' }}
                        dataSource={this.state.notifications}
                        keyExpr="inspectiondefectid"
                        allowSelection={true}
                        selection={{ mode: 'single', showCheckBoxesMode: 'onClick' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        //rowAlternationEnabled={false}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        remoteOperations={false}
                        allowColumnReordering={true}
                        columnResizingMode='widget'
                        onContextMenuPreparing={this.addGridContextMenuItems}
                    >
                        <ColumnChooser enabled={true} />
                        <ColumnFixing enabled={true} />
                        <StateStoring enabled={true} type="localStorage" storageKey={this.generateUniqueGridName() + storageKey} />
                        <Export enabled={true} fileName={this.props.name} />
                        <SearchPanel visible={true} highlightCaseSensitive={false} defaultText="" />
                        {
                            columns.map((columnItem, index) => (
                                <Column cssClass="WrappedColumnClass" key={index} {...columnItem} />
                            ))
                        }
                        <Paging pageSize={10} defaultPageSize={10} defaultPageIndex={0} /> 
                    <Scrolling mode="virtual" showScrollbar={true} useNative={true} />
                    </DataGrid> */}
                </PortletBody>
            </Portlet >
        );
    }
}




export default withListPageProps(DefectListAll);
