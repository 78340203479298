import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ErrorPage4 } from "./ErrorPage4";
import { ErrorPage5 } from "./ErrorPage5";


export default function ErrorsPage() {
  return (
    <Routes caseSensitive>
      <Route path="/error-v4" element={<ErrorPage4 />} sensitive />
      <Route path="/error-v5" element={<ErrorPage5 />} sensitive />
      <Route path="*" element={<Navigate replace to="/error/error-v5" />} />
    </Routes>
  );
}
