import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Common from '../../app/shared/common';
const actionTypes = {
  SetLanguage: "i18n/SET_LANGUAGE"
};

export const initialState = {
  lang: process.env.REACT_APP_LANGUAGE_DEFAULT ? process.env.REACT_APP_LANGUAGE_DEFAULT : 'en'
};
const storageKey = "Fdi18n" + process.env.REACT_APP_PRODUCT_KEY + Common.GetHashCode(process.env.PUBLIC_URL);

export const reducer = persistReducer(
  { storage, key: storageKey },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetLanguage:
        return { ...state, lang: action.payload.lang };

      default:
        return state;
    }
  }
);

export const actions = {
  setLanguage: lang => ({ type: actionTypes.SetLanguage, payload: { lang } })
};
