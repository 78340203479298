
import axios from 'axios';



export function getReport(reportName, reportingToken) {
  axios.defaults.withCredentials = true;
  return axios.post('https://powerbi.builtintelligence.com/api/embedding/report', { reportName: reportName, reportToken: reportingToken });
}
export function getDashboard(reportName, reportingToken) {
  axios.defaults.withCredentials = true;
  return axios.post('https://powerbi.builtintelligence.com/api/embedding/dashboard', { dashboardName: reportName, reportToken: reportingToken });
}
export function getScoreCard(reportName, reportingToken) {
  axios.defaults.withCredentials = true;
  return axios.post('https://powerbi.builtintelligence.com/api/embedding/scorecard', { reportName: reportName, reportToken: reportingToken });
}

