import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import { injectIntl } from "react-intl";
import { Button } from 'devextreme-react/button';
import SharepointFileGrid from './SharepointFileGrid';
import SharepointDialogModal from './SharepointDialogModal';
class SharepointFilepond extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            files: null
        };
    }
    onModalResponse = (response) => {
        this.setState({ showModal: false });
    }
    render() {
        return (
            <>
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}>
                    <Button
                        width='100%'
                        height='50px'
                        // icon="edit"
                        style={{ marginBottom: 10 }}
                        text={this.props.intl.formatMessage({ id: 'GENERAL.DOCUMENTS.ATTACH_DOCUMENTS' })}
                        type="default"
                        stylingMode="outlined"
                        onClick={() => {
                            if (this.props.assetid) {
                                this.setState({ showModal: true })
                            }
                            else {
                                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.DOCUMENTS.ASSET_REQUIRED' }), { variant: 'error', });
                            }

                        }}
                    />
                    <SharepointFileGrid
                        notificationid={this.props.notificationid}
                        documentType={this.props.documentType}
                        showModal={this.state.showModal}
                        isFinal={this.props.isFinal}
                        isReply={this.props.isReply}
                    />
                </div>
                <SharepointDialogModal
                    visible={this.state.showModal}
                    onDialogResult={this.onModalResponse}
                    notificationid={this.props.notificationid}
                    documentType={this.props.documentType}
                    isReply={this.props.isReply}
                    assetid={this.props.assetid}
                />
            </>
        )
    }
}

export default withSnackbar(injectIntl(SharepointFilepond));