import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import QuoteCreate from './Create';
import QuoteDetail from './Detail';
import QuoteList from './List';
import Common from '../../../../shared/common';
// import { supplier, supplierAndManagerOrSupport, supplierAndManager } from '../../../../Templates/TemplateHelper';

export const compensationQuotableStatuses = ['AQ', 'APMRQ', 'ARQ', 'ADAQ'];
export const compensationQuotableStatusesShort = ['AQ', 'APMRQ', 'ARQ', 'APMR'];

class QuoteRouter extends React.Component {
    constructor(props) {
        super(props);
        this.notifyingParty = props.parentWorkflowItem.quotingParty;
        this.replyingParty = props.parentWorkflowItem.quoteReplyingParty;

        this.communicateToUser = props.globalResources[this.replyingParty + "_NAME"];


        this.notificationType = props.parentWorkflowItem.config.quotationType;
        this.documentType = props.parentWorkflowItem.config.quotationDocumentType;

        const baseNotification = this.props.compensation ? this.props.compensation : this.props.instruction;
        this.referenceType = 'Quotation';

        this.notificationName = props.parentWorkflowItem.quoteNotificationName;// this.props.intl.formatMessage({ id: 'GENERAL.WORDS.QUOTE' });
        this.abbreviation = props.parentWorkflowItem.quoteReferencePrefix; //Common.CapitalFirstLetter(this.notificationName) + "-";
        this.pageTitle = this.props.intl.formatMessage({ id: 'GENERAL.NOTICE.TITLE' }, { ITEM_NAME: this.notificationName, PARTY_NAME: props.globalResources[this.notifyingParty + "_NAME"] });
        //Create a Custom WorkflowItem for Quote as its not a WF standalone
        this.workflowItem = {
            ...props.parentWorkflowItem,
            pageTitle: this.pageTitle,
            notificationName: this.notificationName,
            abbreviation: this.abbreviation,
            replyEnabled: true,
            notifyingParty: props.parentWorkflowItem.quotingParty,
            replyingParty: props.parentWorkflowItem.quoteReplyingParty,
            notifyAttachments: props.parentWorkflowItem.notifyAttachments,
            replyAttachments: props.parentWorkflowItem.notifyAttachments,
            includePdfjson: props.parentWorkflowItem.includePdfjson,
            userFilters: {
                ...props.parentWorkflowItem.userFilters,
                mainNotice: {
                    createFilter: props.parentWorkflowItem.userFilters.quoteMainNotice.createQuoteFilter,
                    createAllowed: props.parentWorkflowItem.userFilters.quoteMainNotice.createQuoteAllowed,
                    viewDraftFilter: props.parentWorkflowItem.userFilters.quoteMainNotice.createQuoteFilter,
                    viewDraftAllowed: props.parentWorkflowItem.userFilters.quoteMainNotice.createQuoteAllowed,
                    communicateFilter: props.parentWorkflowItem.userFilters.quoteMainNotice.communicateQuoteFilter,
                    communicateAllowed: props.parentWorkflowItem.userFilters.quoteMainNotice.communicateQuoteAllowed
                },
                replyNotice: {
                    saveDraftReplyFilter: props.parentWorkflowItem.userFilters.quoteReplyNotice.saveQuoteDraftReplyFilter,
                    saveDraftReplyAllowed: props.parentWorkflowItem.userFilters.quoteReplyNotice.saveQuoteDraftReplyAllowed,
                    communicateReplyFilter: props.parentWorkflowItem.userFilters.quoteReplyNotice.communicateQuoteReplyFilter,
                    communicateReplyAllowed: props.parentWorkflowItem.userFilters.quoteReplyNotice.communicateQuoteReplyAllowed
                }
            },
            paths: {
                ...props.parentWorkflowItem.paths,
                basePath: props.parentWorkflowItem.paths.basePath + baseNotification.compensationeventid + "/quote/",
                createPath: props.parentWorkflowItem.paths.basePath + baseNotification.compensationeventid + "/quote/create",
                listPath: props.parentWorkflowItem.paths.basePath + baseNotification.compensationeventid,
                defaultPath: props.parentWorkflowItem.paths.basePath + baseNotification.compensationeventid,
            },
            config: {
                ...props.parentWorkflowItem.config
            },
            associatedWFListAllowed: null
        };

        this.workflowItem.form_NotifyCompanyLogoID = Common.GetPartyOrUserCompanyID(props.contract, this.workflowItem.form_CompanyLogo, this.workflowItem.notifyingParty, props.userProfile);
        this.workflowItem.form_NotifyCompanyName = Common.GetPartyOrUserCompanyName(props.contract, this.workflowItem.form_CompanyName, this.workflowItem.notifyingParty, props.userProfile);
        this.workflowItem.form_ReplyCompanyLogoID = Common.GetPartyOrUserCompanyID(props.contract, this.workflowItem.form_CompanyLogo, this.workflowItem.replyingParty, props.userProfile);
        this.workflowItem.form_ReplyCompanyName = Common.GetPartyOrUserCompanyName(props.contract, this.workflowItem.form_CompanyName, this.workflowItem.replyingParty, props.userProfile);


        this.programmeReplyEnabled = true;
        if (this.props.workflowsQA['14']) {
            this.programmeReplyEnabled = this.props.workflowsQA['14'].replyEnabled;
        }

        this.instructionReplyProceedEnabled = true;
        try {
            this.instructionReplyProceedEnabled = (props.placeholders.find(ph => ph.replacementKey === 'InstructionReplyProceedEnabled')
                .values.find(phItem => phItem.field === 'InstructionReplyProceedEnabled').isActive);
        } catch (error) {
            console.log('InstructionReplyProceedEnabled placeholder not available!');
            this.instructionReplyProceedEnabled = true;
        }

        this.interimQuotesAllowed = false;
        try {
            this.interimQuotesAllowed = props.placeholdersQA.INTERIM_QUOTES_ALLOWED
                .values.find(phItem => phItem.field === 'INTERIM_QUOTES_ALLOWED').isActive;
        } catch (error) {
            console.log('INTERIM_QUOTES_ALLOWED placeholder not available!');
            this.interimQuotesAllowed = false;
        }


        this.quoteImpactOptions = props.placeholdersQA.QuoteProgrammeImpactOpts
            .values.filter((item) => item.isActive === true);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.compensation !== nextProps.compensation || this.props.instruction !== nextProps.instruction) {
            return true;
        }
        return false;
    }
    render() {

        const baseNotification = this.props.compensation ? this.props.compensation : this.props.instruction;
        const baseNotificationReadyForCreate = (this.props.mode === 'SHORT' || this.props.mode === 'CLAIMWN' || this.props.mode === 'CLIENTCWN') ? compensationQuotableStatusesShort.includes(baseNotification.status) : compensationQuotableStatuses.includes(baseNotification.status);
        // const canCreate = this.workflowItem.userFilters.quoteMainNotice.createQuoteAllowed && baseNotificationReadyForCreate;
        // this.workflowItem.basePath = this.props.parentWorkflowItem.basePath + baseNotification.compensationeventid + "/quote/";
        // this.workflowItem.registerPath = this.props.parentWorkflowItem.basePath + baseNotification.compensationeventid;

        return (
            <Routes caseSensitive>
                <Route
                    path={'/quote/create'}
                    exact
                    element={
                        baseNotificationReadyForCreate && this.workflowItem.userFilters.quoteMainNotice.createQuoteAllowed ? (
                            <QuoteCreate
                                {...this.props}
                                quoteImpactOptions={this.quoteImpactOptions}
                                interimQuotesAllowed={this.interimQuotesAllowed}
                                programmeReplyEnabled={this.programmeReplyEnabled}
                                mode={this.props.mode}
                                compensation={baseNotification}
                                parentTabPanel={this.props.parentTabPanel}

                                communicateToUser={this.communicateToUser}
                                title={this.notificationName}
                                abbreviation={this.abbreviation}
                                notificationType={this.notificationType}
                                workflowItem={this.workflowItem}
                            />) : (
                            <Navigate replace to={this.workflowItem.paths.defaultPath} />
                        )
                    }
                />

                <Route
                    path={'/quote/:quotationid'}
                    exact
                    element={<QuoteDetail
                        {...this.props}
                        quoteImpactOptions={this.quoteImpactOptions}
                        interimQuotesAllowed={this.interimQuotesAllowed}
                        programmeReplyEnabled={this.programmeReplyEnabled}
                        instructionReplyProceedEnabled={this.instructionReplyProceedEnabled}
                        mode={this.props.mode}
                        parentTabPanel={this.props.parentTabPanel}
                        onCompensationChanged={this.props.onCompensationChanged}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}

                        documentType={this.documentType}
                        baseNotificationReadyForCreate={baseNotificationReadyForCreate}
                        communicateToUser={this.communicateToUser}
                        compensation={baseNotification}
                        notificationType={this.notificationType}
                        notifyUserFilter={this.props.notifyUserFilter}
                        workflowItem={this.workflowItem}
                    />}
                />

                <Route
                    path="*"
                    element={<QuoteList
                        {...this.props}
                        mode={this.props.mode}
                        interimQuotesAllowed={this.interimQuotesAllowed}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}

                        workflowItem={this.workflowItem}
                        notificationType={this.notificationType}
                        baseNotificationReadyForCreate={baseNotificationReadyForCreate}
                        reference={this.props.reference}
                        notifyingParty={this.notifyingParty}
                    />}
                />

            </Routes>
        );
    }
};


const mapStateToProps = store => ({
    //WE NEED userProfile because Common.GetPartyOrUserCompanyID is re-assigned depending on party
    userProfile: store.auth.userProfile
});
export default connect(mapStateToProps)(QuoteRouter);