import axios from '../shared/axios';

export function getContractUsers(contractid) {
    return axios.get(`/api/ContractUser/RegisterList?contractid=${contractid}`);
}
export function getContractContacts(companyid, projectid, contractid, createbyid) {
    return axios.post(`/api/ContractUser/LoadContractUserList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&createbyid=${createbyid}`);
}
export function saveContractUser(data) {
    return axios.post(`/api/ContractUser/SaveContractUser`, data);
}
export function switchRole(contractid, party, role) {
    return axios.post(`/api/ContractUser/SwitchRole`, null, { params: { contractid, party, role } });
}
export function loadContractsNotAssignedToList(accountid, party, includeUnmatchedContractsToParty, cancelToken) {
    return axios.post(`/api/ContractUser/LoadContractsNotAssignedToList?accountid=${accountid}&party=${party}&includeUnmatchedContractsToParty=${includeUnmatchedContractsToParty}`, null, { cancelToken });
}

export function saveContractUserToMultipleContracts(data) {
    return axios.post(`/api/ContractUser/AddUserToMultipleContracts`, data);
}

export function deleteContractUser(contractid, contractuserid) {
    return axios.delete(`/api/ContractUser/Delete`,  { params: {contractid, contractuserid } });
}