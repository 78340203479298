/* eslint-disable eqeqeq */
import React from 'react';
import { withSnackbar } from 'notistack';
import * as userCrud from '../../../../crud/user.crud';
import * as companyUserCrud from '../../../../crud/companyUser.crud';
import * as companyDuck from '../../../../store/ducks/company.duck';
import * as companiesDuck from '../../../../store/ducks/companies.duck';
import * as authDuck from '../../../../store/ducks/auth.duck';
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import DxDialogModal from '../../../../partials/content/dxControls/dxDialogModal';
import { connect } from "react-redux";
import BiDynamicForm from "../../../../partials/layout/BiDynamicForm";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { countries, GetCurrencyCodes } from '../../../../shared/lookup';
import { injectIntl } from "react-intl";
import ChangeUserCompanyModal from './CustomComponents/ChangeUserCompanyModal';
import { Alert } from "react-bootstrap";
import Common from "../../../../shared/common";
import { withRouter } from '../../../../shared/hoc/withRouter';
import { GetAccessRights } from "../../../../router/Protector/Protector";

const overrideAuth0Enabled = process.env.REACT_APP_OVERRIDE_AUTH0_ENABLED === "true" && !!process.env.REACT_APP_SSO_METHOD;
const isFinancialLimitEnabled = process.env.REACT_APP_FINANCIAL_LIMIT_ENABLED === "true";
const isPowerBIMenusEnabled = process.env.REACT_APP_POWERBI_ENABLED === "true";

const initialState = {
    showModal: false,
    saving: false,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    showThirdModal: false
};

class CompanyUserEditor extends React.Component {
    constructor(props) {
        super(props);
        this.currencyCodes = GetCurrencyCodes();
        this.companyUserFormConfig = {
            buttonText: props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_UPDATE" }),
            fields: [
                { label: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.USERNAME" }), dataField: 'Username', validation: { type: 'pattern', pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.USERNAME_MATCH_EMAIL" }) } },
                { label: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.EMAIL" }), dataField: 'EmailAddress', validation: { type: 'pattern', pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_MAIL_FORMAT" }) } },
                { label: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.NAME" }), dataField: 'Fullname' },
                { label: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.JOB_TITLE" }), dataField: "JobTitle", required: false },
                { label: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.TELEPHONE" }), dataField: 'Telephone', required: false, validation: { type: 'pattern', pattern: /^[0-9+]*$/, warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_PHONE_NUMBER" }) } },
                // { label: 'Password', dataField: 'password', editorOptions: { mode: 'password' }, validationPattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/, validationWarning: 'Password must be minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character!' },
                { label: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.COUNTRY" }), dataField: 'Country', editorType: 'dxSelectBox', editorOptions: { dataSource: countries, defaultValue: 'United Kingdom' } },
                { label: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.TYPE" }), dataField: 'accounttype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.getUserTypesOptions() } },

            ]
        }
        if (process.env.REACT_APP_SSO_METHOD === 'DISCOVER' && this.props.company.companytenants?.length > 0) {
            this.companyUserFormConfig.fields.push(
                {
                    label: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.SSO_TENANT" }),
                    tooltipResource: 'COMPANY.USER.FIELD.SSO_TENANT_TOOLTIP',
                    dataField: 'companytenantid',
                    editorType: 'dxSelectBox',
                    required: false,
                    editorOptions: {
                        dataSource: this.props.company.companytenants,
                        valueExpr: 'companytenantid',
                        displayExpr: 'description',
                        showClearButton: true
                    }
                }
            );
        }
        if (isFinancialLimitEnabled) {
            this.companyUserFormConfig.fields.push(
                { label: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.APPLYFINANCIALLIMIT" }), dataField: 'applyfinanciallimit', editorType: 'dxSwitch', editorOptions: { switchedOffText: props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }) } },
                { label: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.LIMIT" }), dataField: 'userlimit', editorType: 'dxNumberBox', required: false, editorOptions: { format: '#,##0.00', placeholder: '0.00', min: 0 }, visible: (obj) => obj.applyfinanciallimit },
                { label: props.intl.formatMessage({ id: "CONTRACT.FIELD.CURRENCY" }), dataField: 'userlimitcurrency', editorType: 'dxSelectBox', editorOptions: { dataSource: this.currencyCodes, valueExpr: 'code', displayExpr: 'name', defaultValue: 'GBP' }, visible: (obj) => obj.applyfinanciallimit }
            );
        }
        if (isPowerBIMenusEnabled) {
            this.companyUserFormConfig.fields.push(
                { label: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.ALLOW_POWERBI_MENUS" }), dataField: 'allowpowerbimenus', editorType: 'dxSwitch', editorOptions: { switchedOffText: props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }), hint: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.ALLOW_POWERBI_MENUS_TOOLTIP" }) } }
            )
        }
        this.companyUserFormConfig.fields.push(
            { note: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.PREFERENCES" }) },
            { label: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.DISABLE_EMAIL" }), dataField: 'DisableGlobalEmailNotifications', editorType: 'dxSwitch', editorOptions: { switchedOffText: props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }), hint: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.DISABLE_EMAIL_TOOLTIP" }) } },
            { label: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.EMAILS_OPTEDOUT" }), dataField: 'optoutemailnotifications', editorType: 'dxSwitch', editorOptions: { switchedOffText: props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }) } }
        );
        if (overrideAuth0Enabled) {
            this.companyUserFormConfig.fields.push(
                { label: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.ALLOW_NON_SSO" }), dataField: 'OverrideSSOAuthentication', editorType: 'dxSwitch', editorOptions: { switchedOffText: props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }), hint: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.ALLOW_NON_SSO_TOOLTIP" }) } },
            )
        }
        this.canNavigateToReport = GetAccessRights("/reports/contract-users", this.props.userProfile).allowAccess;
        this.roles = Common.getFilteredRoles(props.userProfile);
        this.state = {
            ...initialState,
            user: null,
            userTypesInitialized: false,
        }
    }

    componentDidMount() {
        this.fetchUser();
    }

    getUserTypesOptions = () => {
        const datasource = {
            loadMode: 'raw',
            cacheRawData: true,
            load: () => {
                var promise = new Promise((resolve) => {
                    companyUserCrud.getUserTypeToSelectList()
                        .then(response => {
                            const typesArray = [];
                            if (response.data && Array.isArray(response.data)) {
                                typesArray.push(...response.data)
                                //IF CURRENT USER-TYPE DOES NOT EXIST IN LIST
                                //ADD TO LIST FOR DISPLAY PURPOSE
                                if (!typesArray.includes(this.state.user.accounttype)) {
                                    typesArray.push(this.state.user.accounttype)
                                }
                            }
                            resolve(typesArray);
                        });
                });
                return promise;
            }
        }
        return datasource;
    }
    fetchUser = () => {
        userCrud.getUser(this.props.params.userid)
            .then(resp => {
                const editingUser = {
                    Fullname: resp.data.name,
                    Telephone: resp.data.telephone,
                    JobTitle: resp.data.jobtitle,
                    Country: resp.data.country,
                    AccountID: resp.data.accountid,
                    Username: resp.data.username,
                    EmailAddress: resp.data.email,
                    accounttype: resp.data.accounttype,
                    Address1: resp.data.address1,
                    Address2: resp.data.address2,
                    City: resp.data.city,
                    State: resp.data.state,
                    PostCode: resp.data.postcode,
                    SiteURL: resp.data.siteURL,
                    Deleted: resp.data.deleted,
                    DisableGlobalEmailNotifications: resp.data.disableglobalemailnotifications,
                    OverrideSSOAuthentication: resp.data.overridessoauthentication,
                    applyfinanciallimit: resp.data.userlimit || resp.data.userlimit === 0 ? true : false,
                    userlimit: resp.data.userlimit,
                    userlimitcurrency: resp.data.userlimitcurrency,
                    allowpowerbimenus: resp.data.allowpowerbimenus,
                    customfieldvalues: resp.data.customfieldvalues,
                    optoutemailnotifications: resp.data.optoutemailnotifications,
                    companytenantid: resp.data.companytenantid
                }
                this.setState({ user: editingUser })
            });
    }
    showModal = (mode, e) => {
        const statusText = this.state.user.Deleted ?
            this.props.intl.formatMessage({ id: "COMPANY.USER.ACTIVATE.MODAL.ACTIVATING" })
            : this.props.intl.formatMessage({ id: "COMPANY.USER.ACTIVATE.MODAL.DEACTIVATING" });

        this.setState({
            showModal: true,
            modalTitle: this.props.intl.formatMessage({ id: "COMPANY.USER.ACTIVATE.MODAL.TITLE" }),
            modalText: statusText,
            modalMode: mode
        });
    }
    showThirdModal = (mode, e) => {
        this.props.loadCompanies();
        const statusText = this.props.intl.formatMessage({ id: "COMPANY.USER.COMPANY.UPDATING" });

        this.setState({
            showThirdModal: true,
            modalTitle: this.props.intl.formatMessage({ id: "COMPANY.USER.COMPANY.UPDATE.TITLE" }),
            modalText: statusText,
            modalMode: mode
        });
    }
    onModalResponse = (response, params) => {
        this.setState({ showModal: false }, () => {
            if (response === 'OK') {
                this.bulkActivateDeactivate(params.activationStatus);
            }
        });
    }
    onCompanyModalResponse = (response, params) => {
        this.setState({ showThirdModal: false }, () => {
            if (response === 'OK') {
                this.ReallocateUserToCompany(params.companyid);
            }
        });
    }
    bulkActivateDeactivate = (activationStatus) => {
        const formData = {
            userIds: [this.state.user.AccountID],
            actionType: this.state.user.Deleted ? 'activate' : 'deactivate',
            reinstateContractPermissionsOnActivate: activationStatus
        };

        userCrud.bulkActivateDeactivateUsers(formData)
            .then(() => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "COMPANY.USER.UPDATE.SUCCESS" }), { variant: 'success', });
                this.props.reloadCompanyUsers();
                /*if (companyUser.AccountID === this.props.userProfile.accountid) {
                    //IF THE USER IS LOGGEDIN USER ACCOUNT
                    this.props.refreshProfile();
                }*/
                this.props.navigate('/admin/companies/' + this.props.company.companyid + '/users');
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }
    ReallocateUserToCompany = (companyid) => {
        const companyuserid = this.props.companyUsers.find(user => user.accountid === this.state.user.AccountID).companyuserid;

        userCrud.reallocateUserToCompany(companyuserid, this.state.user.AccountID, companyid)
            .then(() => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "COMPANY.USER.UPDATE.SUCCESS" }), { variant: 'success', });
                this.props.reloadCompanyUsers();
                /*if (companyUser.AccountID === this.props.userProfile.accountid) {
                    //IF THE USER IS LOGGEDIN USER ACCOUNT
                    this.props.refreshProfile();
                }*/
                this.props.navigate('/admin/companies/' + this.props.company.companyid + '/users');
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    submitHandler = (companyUser, setSubmitting) => {
        companyUser.SiteURL = "https://www.builtintelligence.com/";
        companyUser.DisableGlobalEmailNotifications = companyUser.DisableGlobalEmailNotifications ? 1 : 0;
        if (!companyUser.applyfinanciallimit) {
            companyUser.userlimit = null;
        } else if (companyUser.applyfinanciallimit && !companyUser.userlimit) {
            companyUser.userlimit = 0;
        }
        companyUserCrud.updateCompanyUser(companyUser)
            .then(response => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "COMPANY.USER.UPDATE.SUCCESS" }), { variant: 'success', });
                this.props.reloadCompanyUsers();
                if (companyUser.AccountID === this.props.userProfile.accountid) {
                    //IF THE USER IS LOGGEDIN USER ACCOUNT
                    this.props.refreshProfile();
                }
                this.props.navigate('/admin/companies/' + this.props.company.companyid + '/users');
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                setSubmitting(false);
            });
    }
    render() {
        if (!this.props.company || !this.state.user) {
            return (
                <PortletSpinner />
            );
        }
        const { allowActivateDeactivate, allowUpdateCompany } = this.props.protectedRouteProps;

        const filteredCompanies = this.props.companies?.filter(c => c.archived !== 1 && c.enablecompanyusers != 0 && c.companyid != this.props.company.companyid);

        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: "COMPANY.USER.UPDATE.TITLE" })} />
                <PortletBody style={{ maxWidth: 800 }}>
                    <BiDynamicForm
                        item={this.state.user}
                        config={this.companyUserFormConfig}
                        onSubmitHandler={this.submitHandler}
                        secondaryButtonEnabled={allowActivateDeactivate}
                        secondaryButtonActive={!this.state.user.Deleted}
                        secondaryButtonActiveText={this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_ACTIVATE" })}
                        secondaryButtonDeactiveText={this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_DEACTIVATE" })}
                        secondaryButtonHandler={this.showModal}
                        secondaryButtonActiveStyle={{ backgroundColor: '#4CAF50', marginLeft: "10px" }}
                        secondaryButtonDeactiveStyle={{ backgroundColor: '#f44336', marginLeft: "10px" }}
                        customFields={this.props.company.usercustomfields}
                        thirdButtonEnabled={allowUpdateCompany}
                        thirdButtonText={this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_COMPANYUPDATE" })}
                        thirdButtonHandler={this.showThirdModal}
                    />
                </PortletBody>
                <DxDialogModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.user.Deleted ? 'ACTIVATION' : 'DEACTIVATION'}
                    onDialogResult={this.onModalResponse}
                />
                <ChangeUserCompanyModal
                    visible={this.state.showThirdModal}
                    title={this.props.intl.formatMessage({ id: "COMPANY.USER.COMPANY.UPDATE.TITLE" })}
                    text={this.props.intl.formatMessage({ id: "COMPANY.USER.COMPANY.UPDATING" })}
                    mode='COMPANY_UPDATE'
                    onDialogResult={this.onCompanyModalResponse}
                    dataSource1={filteredCompanies}
                >
                    <Alert variant="warning" >
                        <p>
                            {`Note: The user will maintain their access to all contracts they are currently associated with. To see a list of contracts view the Contract Users register `}
                            {this.canNavigateToReport && <Alert.Link href={`${process.env.PUBLIC_URL}/reports/contract-users`} target="_blank">here</Alert.Link>}
                            {`. Secondly, for any contracts where this user is named as a lead party their access will remain. To see a list of lead contracts click `}
                            <Alert.Link href={`${process.env.PUBLIC_URL}/contracts`} target="_blank">here</Alert.Link>
                            {"."}
                        </p>
                    </Alert>
                </ChangeUserCompanyModal>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    company: store.company.company,
    userProfile: store.auth.userProfile,
    companyUsers: store.company.companyUsers,
    companies: store.companies.companies
});
const mapDispatchToProps = {
    reloadCompanyUsers: companyDuck.actions.reloadUsers,
    refreshProfile: authDuck.actions.refreshProfile,
    loadCompanies: companiesDuck.actions.fetchStart
}

export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CompanyUserEditor))));
