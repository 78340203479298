import React from 'react';
import BaseCreatePage from '../../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "../../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import * as paymentBatchCrud from '../../../../../crud/paymentBatch.crud';
import * as paymentCertificateCrud from '../../../../../crud/paymentCertificate.crud';
import * as contractOtherPriceCrud from '../../../../../crud/contractOtherPrice.crud';
import BiDynamicForm from '../../../../../partials/layout/BiDynamicForm';
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import { withCreatePageProps } from '../../../../../shared/hoc/withCreatePageProps';
import Common from '../../../../../shared/common';
import { actionCodes, GetCurrencyCodes } from "../../../../../shared/lookup";
import PortletSpinner from '../../../../../partials/layout/PortletSpinner';

class FirstWizardPage extends BaseCreatePage {
    constructor(props) {
        super(props);
        this.currencyCodes = GetCurrencyCodes();
        if (props.workflowItem.config.currentModelType === "Application") {

            this.customFields = (props.allCustomFields || {})[21];
            if (props.placeholdersQA.ApplicationTypes) {
                this.configurableTypeFields = props.placeholdersQA.ApplicationTypes.values.filter(item => item.isActive === true).map(x => ({ value: x.field, display: x.display }));

            }
        }
        else if (props.workflowItem.config.currentModelType === "Certificate") {
            this.customFields = (props.allCustomFields || {})[20];
            if (props.placeholdersQA.PaymentTypes) {
                this.configurableTypeFields = props.placeholdersQA.PaymentTypes.values.filter(item => item.isActive === true).map(x => ({ value: x.field, display: x.display }));
            }
        }
        try {
            this.taxEnabled = props.placeholdersQA.TaxEnabled
                .values.find(phItem => phItem.field === 'TaxEnabled').isActive;
        } catch (error) {
            console.log("TaxEnabled placeholder not available!");
            this.taxEnabled = true;
        }
        try {
            this.poNumberEnabled = props.placeholdersQA.PONumberEnabled
                .values.find(phItem => phItem.field === 'PONumberEnabled').isActive;
        } catch (error) {
            console.log("PONumberEnabled placeholder not available!");
            this.poNumberEnabled = false;
        }


        this.state = {
            showAlert: true,
            //createFormConfig: createFormConfig
            secondaryPrices: null,
            secondaryPricesLastRetainAmounts: []
        };
    }

    componentDidMount() {
        this.fetchSecondaryPrices();
    }

    fetchSecondaryPrices = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data: pricesResp } = await contractOtherPriceCrud.getContractOtherPrices(companyid, projectid, contractid, '');
            if (pricesResp.length === 0) {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'PAYMENT.BATCH_WIZARD.NO_CURRENCY_DEF' }), { variant: 'error', });
                return;
            }
            if (pricesResp.length > 6) {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'PAYMENT.BATCH_WIZARD.MAX_CURRENCY_DEF' }), { variant: 'error', });
                return;
            }

            const currencies = pricesResp.map(x => x.currency);

            const { data: currenciesAndAmountsResp } = await paymentCertificateCrud.getLastPaymentAmountPerCurrency(contractid, ["Certificate"], currencies);

            //Adding the contract currency as the first item
            const secondaryPrices = [this.props.contract.currency, ...currencies];

            //Adding the contract currency and totalCertified value as the first item
            const contractCurrencyAndTotalCertifiedItem = {
                currencycode: this.props.contract.currency,
                amount: this.props.contract.totalcertified
            }

            const secondaryPricesLastRetainAmounts = [contractCurrencyAndTotalCertifiedItem, ...currenciesAndAmountsResp];

            this.setState({ secondaryPrices, secondaryPricesLastRetainAmounts });
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }

    submitHandler = (payment) => {
        payment.companyid = this.props.contract.companyid;
        payment.contractid = this.props.contract.contractid;
        payment.projectid = this.props.contract.projectid;
        payment.assessmentdatedisplay = moment(payment.assessmentdate).format('DD/MM/YYYY');

        payment.type = this.props.workflowItem.config.currentModelType;
        payment.status = 'Not Communicated';
        payment.createdate = new Date();
        payment.createdatedisplay = moment().format('DD/MM/YYYY');

        const othercumulativeprices = Object.keys(payment)
            .filter(key => key.startsWith('othercumulativeprice'))
            .sort((a, b) => {
                const numA = parseInt(a.replace('othercumulativeprice', ''));
                const numB = parseInt(b.replace('othercumulativeprice', ''));
                return numA - numB;
            })
            .map(key => payment[key]);

        payment.othercumulativeprices = othercumulativeprices;

        const otheramountpaids = Object.keys(payment)
            .filter(key => key.startsWith('otheramountpaid'))
            .sort((a, b) => {
                const numA = parseInt(a.replace('otheramountpaid', ''));
                const numB = parseInt(b.replace('otheramountpaid', ''));
                return numA - numB;
            })
            .map(key => payment[key]);

        payment.otheramountpaids = otheramountpaids;

        const subtotalpayments = Object.keys(payment)
            .filter(key => key.startsWith('subtotalpay'))
            .sort((a, b) => {
                const numA = parseInt(a.replace('subtotalpay', ''));
                const numB = parseInt(b.replace('subtotalpay', ''));
                return numA - numB;
            })
            .map(key => payment[key]);

        payment.subtotalpayments = subtotalpayments;

        const subtotalretains = Object.keys(payment)
            .filter(key => key.startsWith('subtotalretain'))
            .sort((a, b) => {
                const numA = parseInt(a.replace('subtotalretain', ''));
                const numB = parseInt(b.replace('subtotalretain', ''));
                return numA - numB;
            })
            .map(key => payment[key]);

        payment.subtotalretains = subtotalretains;

        const otheramountretains = Object.keys(payment)
            .filter(key => key.startsWith('otheramountretain'))
            .sort((a, b) => {
                const numA = parseInt(a.replace('otheramountretain', ''));
                const numB = parseInt(b.replace('otheramountretain', ''));
                return numA - numB;
            })
            .map(key => payment[key]);

        payment.otheramountretains = otheramountretains;

        const lastpaymentcertificateamounts = Object.keys(payment)
            .filter(key => key.startsWith('lastpaymentcertificateamount'))
            .sort((a, b) => {
                const numA = parseInt(a.replace('lastpaymentcertificateamount', ''));
                const numB = parseInt(b.replace('lastpaymentcertificateamount', ''));
                return numA - numB;
            })
            .map(key => payment[key]);

        payment.lastpaymentcertificateamounts = lastpaymentcertificateamounts;

        const othercertifiedpretaxes = Object.keys(payment)
            .filter(key => key.startsWith('othercertifiedpretax'))
            .sort((a, b) => {
                const numA = parseInt(a.replace('othercertifiedpretax', ''));
                const numB = parseInt(b.replace('othercertifiedpretax', ''));
                return numA - numB;
            })
            .map(key => payment[key]);

        payment.othercertifiedpretaxes = othercertifiedpretaxes;

        const othertaxamounts = Object.keys(payment)
            .filter(key => key.startsWith('othertaxamount'))
            .sort((a, b) => {
                const numA = parseInt(a.replace('othertaxamount', ''));
                const numB = parseInt(b.replace('othertaxamount', ''));
                return numA - numB;
            })
            .map(key => payment[key]);

        payment.othertaxamounts = othertaxamounts;

        const othercertfiedamounts = Object.keys(payment)
            .filter(key => key.startsWith('othercertfiedamount'))
            .sort((a, b) => {
                const numA = parseInt(a.replace('othercertfiedamount', ''));
                const numB = parseInt(b.replace('othercertfiedamount', ''));
                return numA - numB;
            })
            .map(key => payment[key]);

        payment.othercertfiedamounts = othercertfiedamounts;

        // const purchaseOrderNumbers = Object.keys(payment)
        //     .filter(key => key.startsWith('purchaseordernumber'))
        //     .map(key => payment[key]);

        // payment.purchaseOrderNumbers = purchaseOrderNumbers;

        payment.currencies = this.state.secondaryPrices;

        payment.actioncode = actionCodes.CREATE;
        payment.notifyingparty = this.props.workflowItem.notifyingParty;
        this.appendRelationship(payment);
        Common.PropertyConversionsBeforeNoticeSaveRequest(payment, this.customFields, this.props.contractUsers);
        paymentBatchCrud.batchCreateDrafts(payment)
            .then(response => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
                try {
                    const createdItems = response.data;
                    const paymentIdsAndCurrencyNamesList = createdItems.map((item, index) => {
                        const currencyCode = payment.currencies[index];
                        const currency = this.currencyCodes.find(currency => currency.code === currencyCode);
                        const id = item.paymentcertificateid;
                        return { id, currencyname: currency ? currency.name : currencyCode };
                    });
                    this.props.onHandlePaymentCertificateMultipleCurrencies(createdItems, paymentIdsAndCurrencyNamesList);
                    this.props.onValueChanged(false, null);
                    //this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
                }
                catch {
                    this.props.navigate(this.props.workflowItem.paths.listPath);
                }
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });

    }

    calculateDynamicSubTotal = (index, obj) => {
        const prc1 = obj[`othercumulativeprice${index}`] || 0;
        const prc2 = obj[`otheramountpaid${index}`] || 0;
        return prc1 + prc2;
    };

    calculateDynamicAmountDue = (index, obj) => {
        const prc1 = obj[`subtotalpay${index}`] || 0;
        const prc2 = obj[`subtotalretain${index}`] || 0;
        return prc1 - prc2;
    };

    calculateDynamicSubTotalPreTax = (index, obj) => {
        const prc1 = obj[`otheramountretain${index}`] || 0;
        //const prc2 = this.props.contract.totalcertified;
        const prc2 = this.calculateLastPaymentAmount(index);
        return prc1 - prc2;
    };

    calculateDynamicSubTotalPreTaxV2 = (index, obj) => {
        const prc1 = obj[`otheramountretain${index}`] || 0;
        const prc2 = obj[`lastpaymentcertificateamount${index}`] || 0;
        return prc1 - prc2;
    };

    calculateSubTotal = (index, obj) => {
        const lastPaymentAmount = this.calculateLastPaymentAmount(index);

        return lastPaymentAmount != null && lastPaymentAmount != 0
            ? this.calculateDynamicSubTotalPreTax(index, obj)
            : this.calculateDynamicSubTotalPreTaxV2(index, obj);
    }

    calculateDynamicTotal = (index, obj) => {
        const prc1 = obj[`othercertifiedpretax${index}`] || 0;
        const prc2 = obj[`othertaxamount${index}`] || 0;
        return prc1 + prc2;
    };

    formatCurrency = (index) => {
        const cur = this.currencyCodes.find(currency => currency.code === this.state.secondaryPrices[index - 1])?.name || this.state.secondaryPrices[index - 1];
        return cur;
    }

    calculateLastPaymentAmount = (index) => {
        const { secondaryPricesLastRetainAmounts } = this.state;

        if (secondaryPricesLastRetainAmounts && index > 0) {
            const prc = secondaryPricesLastRetainAmounts[index - 1];

            if (prc && prc.amount !== undefined) {
                return prc.amount;
            }
        }

        return 0;
    }

    duplicateFieldWithCount(field, count, calculationFunction) {
        const duplicatedFields = [];

        for (let i = 0; i < count; i++) {
            const duplicatedField = {
                label: `${field.label} ${i + 1}`,
                dataField: `${field.dataField}${i + 1}`,
                required: field.required,
                editorType: field.editorType,
                horizontalfield: true,
                editorOptions: field.editorOptions
            };

            if (i === 0 && field.resource) {
                duplicatedField.resource = field.resource;
            }

            if (this.state.secondaryPrices.length) {
                duplicatedField.currency = this.state.secondaryPrices[i];
            }

            if (field.value) {
                duplicatedField.value = calculationFunction ? calculationFunction.bind(null, i + 1) : undefined;
            }

            duplicatedFields.push(duplicatedField);
        }

        return duplicatedFields;
    }

    generateFieldsWithDuplicates(fields) {
        const updatedFields = [];

        fields.forEach(field => {
            if (field.columnCount) {
                const duplicatedFields = this.duplicateFieldWithCount(field, field.columnCount, field.value);
                const transformedField = {
                    horizontalrow: true,
                    ...(field.label && { label: field.label }),
                    ...(field.resource && { resource: field.resource }),
                    horizontalfields: duplicatedFields.map(duplicatedField => ({ ...duplicatedField }))
                };
                updatedFields.push(transformedField);
            } else {
                updatedFields.push(field);
            }
        });

        return updatedFields;
    }

    onValueChanged = async () => {
        this.props.onValueChanged(true, 'first');
    }

    render() {
        if (!this.state.secondaryPrices || !this.state.secondaryPricesLastRetainAmounts) {
            return (<PortletSpinner />);
        }

        const bottomLabel = this.props.workflowItem.config.currentModelType === 'Application' ? this.props.resources.PaymentAppliedPeriodLabel : this.props.resources.PaymentCertifiedPeriodLabel;

        const columnCount = this.state.secondaryPrices ? this.state.secondaryPrices.length : 1;

        const createFormConfig = {
            currencyCode: this.props.contract.currency,
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: []
        };
        if (this.configurableTypeFields) {
            createFormConfig.fields.push(
                { resource: "GENERAL.FIELD.APPLICATIONTYPE", dataField: 'paymenttype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.configurableTypeFields, valueExpr: 'value', displayExpr: 'display' } })
        }
        createFormConfig.fields.push(
            { label: this.props.resources.AssessmentDayLabel, dataField: 'assessmentdate', required: true, editorType: 'dxDateBox' },
            { label: this.props.resources.SummaryDetailsLabel, dataField: 'summary', required: true, editorType: 'dxHtmlEditor', },
            { horizontallabels: this.state.secondaryPrices },
            { resource: "GENERAL.FORMS.CURRENCY", value: this.formatCurrency, editorType: 'dxTextBox', columnCount: columnCount },
            { label: this.props.resources.CumulativePriceLabel, dataField: 'othercumulativeprice', required: true, editorType: 'dxNumberBox', columnCount: columnCount },
            { label: this.props.resources.PlusAmountsSupplierLabel, dataField: 'otheramountpaid', required: true, editorType: 'dxNumberBox', columnCount: columnCount },
            { resource: "CONTRACT.WF.PAYMENT.FIELD.SUB_TOTAL", dataField: 'subtotalpay', required: true, editorType: 'dxNumberBox', value: this.calculateDynamicSubTotal, columnCount: columnCount },
            { label: this.props.resources.LessAmountsSupplierLabel, dataField: 'subtotalretain', required: true, editorType: 'dxNumberBox', editorOptions: { min: 0 }, columnCount: columnCount },
            { resource: "CONTRACT.WF.PAYMENT.FIELD.AMOUNT_DUE", dataField: 'otheramountretain', required: true, editorType: 'dxNumberBox', value: this.calculateDynamicAmountDue, columnCount: columnCount },
            { label: this.props.resources.LessAmountsDueLabel, dataField: 'lastpaymentcertificateamount', required: true, editorType: 'dxNumberBox', value: this.calculateLastPaymentAmount, columnCount: columnCount },
        )
        // if (this.props.contract.totalcertified != null) {
        //     const lastPaymentField = createFormConfig.fields.find(itm => itm.dataField === 'lastpaymentcertificateamount');
        //     lastPaymentField.value = () => this.props.contract.totalcertified;
        // }

        if (this.taxEnabled) {
            createFormConfig.fields.push(
                { resource: "CONTRACT.WF.PAYMENT.FIELD.SUB_TOTAL", dataField: 'othercertifiedpretax', required: true, editorType: 'dxNumberBox', value: this.calculateSubTotal, columnCount: columnCount },
                { label: this.props.resources.TaxSupplierLabel, dataField: 'othertaxamount', required: true, editorType: 'dxNumberBox', columnCount: columnCount },
                { label: bottomLabel, dataField: 'othercertfiedamount', required: true, editorType: 'dxNumberBox', value: this.calculateDynamicTotal, columnCount: columnCount }
            )
        }
        else {
            createFormConfig.fields.push(
                { label: bottomLabel, dataField: 'othercertfiedamount', required: true, editorType: 'dxNumberBox', value: this.calculateSubTotal, columnCount: columnCount }
            )
        }

        if (this.poNumberEnabled) {
            createFormConfig.fields.push(
                { resource: 'CONTRACT.FIELD.PO_NUMBER', dataField: 'purchaseordernumber', required: false, editorOptions: { defaultValue: this.props.contract.versionnumber } }
            )
        }

        //const updatedFields = this.generateFieldsWithDuplicates(this.state.createFormConfig.fields);
        const updatedFields = this.generateFieldsWithDuplicates(createFormConfig.fields);

        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1001, height: 'auto' }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    {this.props.resources.WarningBanner && (
                        <Alert variant="warning" >
                            <p>
                                {this.props.resources.WarningBanner}
                            </p>
                        </Alert>
                    )}

                    <BiDynamicForm
                        onSubmitHandler={this.submitHandler}
                        //config={{ ...this.state.createFormConfig, fields: updatedFields }}
                        config={{ ...createFormConfig, fields: updatedFields }}
                        customFields={(this.customFields || {}).COMMUNICATION}
                        valueChangeHandler={this.onValueChanged}
                    />

                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    allCustomFields: store.contract.customFields,
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(FirstWizardPage));