/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from "react-redux";
import { Container, Col, Row } from "react-bootstrap";
import * as fileCrud from '../../../crud/file.crud';
import DragDropFilepond from '../../../partials/content/DragDropFilepond';
import { withRouter } from '../../../shared/hoc/withRouter';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html-react';
import { FormattedMessage, injectIntl } from "react-intl";
import AsiteFileList from '../../../partials/content/Asite/AsiteFileList';
import AttachmentGrid from './AttachmentGrid';
import SharepointFilepond from '../../../partials/content/Sharepoint/SharepointFilepond';
import { htmlSenitizeOptions } from '../../../shared/config/DxFormProperties';
import { withSnackbar } from 'notistack';
import Common from '../../../shared/common';
import { Button } from 'devextreme-react/button';
import { listAccountsAllowedDeleteCommunicatedAttachments } from '../../../router/Protector/AccessExceptions';
import DocumentLogo from './DocumentLogo';
import { Alert } from "react-bootstrap";
import { custom as customDialog } from 'devextreme/ui/dialog';

//const borderStyle = 'medium inset';
const borderStyle = 'thin solid';

const ColStyle = { border: borderStyle, padding: 10, display: 'flex', alignItems: 'center', borderBottom: 0 };

class DocumentV2 extends React.Component {
    constructor(props) {
        super(props);
        this.attachmentMode = process.env.REACT_APP_ATTACHMENT_MODE;
        const { config, containerStyle, headerStyle } = this.getConfig();
        this.state = {
            config,
            containerStyle,
            headerStyle
        };
    }

    getConfig = () => {
        const { isReply, contract, reference, workflowItem, overrideReplyParty, overrideReplyPartyValue } = this.props;
        const containerStyle = {};
        const headerStyle = {};
        let config = null;

        if (this.props.isFinal) {
            containerStyle.borderBottom = borderStyle;
            if (!this.props.docConfig) {
                console.log("Missing file content!");
            } else {
                try {
                    config = JSON.parse(this.props.docConfig)
                } catch (error) {
                    console.log(error);
                    this.props.enqueueSnackbar("Invalid file content!", { variant: 'error' });
                }
            }
        }
        else {
            headerStyle.borderBottom = borderStyle;
            config = {};
            config.title = isReply ? this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TITLE.REPLY' }, { ITEM_NAME: this.props.workflowItem.pageTitle }) : this.props.workflowItem.pageTitle;
            config.logoCompany = `${isReply ? workflowItem.form_ReplyCompanyLogoID : workflowItem.form_NotifyCompanyLogoID}`;
            config.logoCompanyName = `${isReply ? workflowItem.form_ReplyCompanyName : workflowItem.form_NotifyCompanyName}`;
            config.headerColumns = [
                {
                    label: this.props.intl.formatMessage({ id: "GENERAL.FORMS.FIELD.CONTRACT_NAME" }),
                    value: contract.name
                },
                {
                    label: this.props.intl.formatMessage({ id: "GENERAL.FORMS.FIELD.REFERENCE" }),
                    value: reference
                }
            ];
            if (this.props.reference2 && this.props.reference2Label) {
                config.headerColumns.push(
                    {
                        label: this.props.reference2Label,
                        value: this.props.reference2
                    }
                )
            }
            config.headerColumns.push(
                {
                    label: this.props.intl.formatMessage({ id: "GENERAL.FORMS.FIELD.DATE" }),
                    value: moment().format('L')
                }
            );

            //HANDLE EXCEPTIONAL FEATURE OF "DYNAMIC REPLY PARTY PER CLAUSE"
            //NOTIFICATION WF DIFFERENT CLAUSE SELECTIONS CAN CHANGE REPLY PARTY ON THE FLY
            if (overrideReplyParty && isReply && workflowItem.form_CompanyLogo === 'PARTY') {
                config.logoCompany = Common.GetPartyOrUserCompanyID(contract, workflowItem.form_CompanyLogo, overrideReplyPartyValue);
                config.logoCompanyName = Common.GetPartyOrUserCompanyName(contract, workflowItem.form_CompanyName, overrideReplyPartyValue);
            }
        }
        return { config, containerStyle, headerStyle }


    }

    downloadFile = (token, id) => {
        window.location.href = `${process.env.REACT_APP_API_URL}Helpers/Download.ashx?Token=${token}&ID=${id}`;
    }
    adminDeleteCommunicatedAttachment = async (deletingFileId, deletingFileTitle) => {
        try {
            const deleteConfirmationDialog = customDialog({
                title: this.props.intl.formatMessage({ id: "GENERAL.FORMS.WARNING_DELETE_ATTACHMENT" }, { ITEM_NAME: deletingFileTitle }),
                messageHtml: `<div style="max-width:50vw;"><p>${this.props.intl.formatMessage({ id: "GENERAL.FORMS.WARNING_DELETE_ATTACHMENT_DETAIL" }, { ITEM_NAME: deletingFileTitle })}</p></div>`,
                buttons: [
                    {
                        text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CANCEL" }),
                        onClick: (e) => { return "CANCEL" }
                    },
                    {
                        text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_DELETE" }),
                        type: "danger",
                        stylingMode: "outlined",
                        onClick: (e) => { return "DELETE" }
                    },
                ]
            });
            const result = await deleteConfirmationDialog.show();
            if (result === "DELETE") {
                const { companyid, projectid, contractid } = this.props.contract;
                await fileCrud.deleteContractFile(companyid, projectid, contractid, deletingFileId);
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_FILE_DELETED' }), { variant: 'success', });

            }
        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }



    renderAttachments = (files) => {
        if (files && files.length > 0) {
            return (
                <ul style={{ fontFamily: '"Open Sans",sans-serif', color: '#7e8691', listStyleType: 'none', paddingInlineStart: 0 }}>
                    {files.map(file => (
                        <li key={file.fileid}>
                            <i className="fas fa-file-alt"></i>
                            {
                                (this.attachmentMode === "SHAREPOINT" || this.attachmentMode === "ASITE") ? (
                                    <a href={file.directlink} rel="noopener noreferrer" target="_blank"  >
                                        <span style={{ paddingLeft: 5 }}>{file.text}</span>
                                    </a>
                                ) :
                                    (
                                        <a href="#" onClick={(e) => { e.preventDefault(); this.downloadFile(this.props.authToken, file.fileid); }}>
                                            <span style={{ paddingLeft: 5 }}>{file.text}</span>
                                        </a>
                                    )
                            }


                            {(listAccountsAllowedDeleteCommunicatedAttachments.includes(this.props.userProfile.accounttype) && !(this.attachmentMode === "SHAREPOINT" || this.attachmentMode === "ASITE")) && (
                                <Button icon="trash" stylingMode='text' type='danger' style={{ marginLeft: 5, border: 0 }} onClick={this.adminDeleteCommunicatedAttachment.bind(this, file.fileid, file.text)} />
                            )}

                        </li>
                    ))}
                </ul>
            );
        }
        else {
            return (<span style={{ fontFamily: '"Open Sans",sans-serif', color: '#7e8691' }}>--<FormattedMessage id="GENERAL.FORMS.WARNING_NO_ATTACHMENT" />--</span>);
        }
    }
    // ASITE specific datagrid rendering 
    renderAsiteAttachments = (rowItem) => {
        if (rowItem.data && rowItem.data.length > 0) {
            return (<AttachmentGrid rowItem={rowItem} />);
        }
    }

    getHtmlValue = (value) => {
        if (value && value.length > 0) {
            // if (process.env.REACT_APP_PDF_PREVIEW_CUTOFF) {
            //     const cutoffDate = moment(process.env.REACT_APP_PDF_PREVIEW_CUTOFF, "DD/MM/YYYY").toDate();

            //     if (this.props.notifyDate < cutoffDate) {
            return (
                <div style={{ marginTop: 20, wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: value }} />
            );
            //     }
            // }
            // return HTMLReactParser(value)
        }
        else {
            return null;
        }

    }
    renderBody = (config) => {
        if (!config.rows || config.rows.length === 0) { return null; }
        return (
            config.rows.map((item, index) => {
                if (!item) {
                    return (
                        <Row key={index}>
                            <Col style={ColStyle}>
                                <span style={{ fontFamily: '"Open Sans",sans-serif', color: '#7e8691' }}>-------<FormattedMessage id="GENERAL.FORMS.INVALID_DATA" />-------</span>
                            </Col>
                        </Row >
                    );
                }
                if (item.note) {
                    return (
                        <Row key={index}>
                            <Col style={ColStyle}>
                                <strong>{item.note}</strong>
                            </Col>
                        </Row >
                    );
                }
                let value = null;
                if (item.valueType) {
                    if (item.valueType === 'HTML' && item.value) {
                        item.value = sanitizeHtml(item.value, { ...htmlSenitizeOptions });
                        value = this.getHtmlValue(item.value);
                    }
                    // if (item.valueType === 'currency') {
                    //     value = Common.FormatCurrencyNum(item.value, (this.props.contract || {}).currency);
                    // }
                    else if (item.valueType === 'LIST') {
                        value = (
                            <ul style={{ listStyleType: 'none', paddingInlineStart: 0 }}>
                                {item.list.map((item, index) => (
                                    <li key={index}>
                                        <i className="fas fa-chevron-right"></i>
                                        <span style={{ paddingLeft: 5, wordWrap: 'break-word', wordBreak: 'break-word' }}>{(item || {}).label}</span>
                                    </li>
                                ))}
                            </ul>
                        );
                    }
                    else if (item.valueType === 'DOCUMENTSGRID') {
                        value = this.renderAsiteAttachments(item);
                    }
                    else if (item.valueType === 'DOCUMENTS') {
                        value = this.renderAttachments(item.data);
                    }
                    else if (item.url && (item.valueType === 'LINK' || (item.valueType === 'CUSTOMFIELD' && item.valueSubType === 'WEB_LINK'))) {
                        value = (
                            <a href={item.url} target="_blank" rel="noopener noreferrer">
                                <span style={{ paddingLeft: 5, wordWrap: 'break-word', wordBreak: 'break-word' }}>{item.text}</span>
                            </a>
                        )
                    }
                    else if (item.valueType === 'LINKS') {
                        value = (
                            <ul style={{ listStyleType: 'none', paddingInlineStart: 0 }}>
                                {item.data.map((item, index) => (
                                    <li key={index}>
                                        <i className="fas fa-chevron-right"></i>
                                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                                            <span style={{ paddingLeft: 5, wordWrap: 'break-word', wordBreak: 'break-word' }}>{item.text}</span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        );
                    }
                    else {
                        value = (<div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}  >{item.value}</div>);
                    }
                }
                else {
                    if (item.value instanceof Date) {
                        value = moment(item.value).format('L');
                    }
                    else if (item.value) {
                        value = (<div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}  >{item.value}</div>);
                    }
                    else {
                        value = (<span style={{ fontFamily: '"Open Sans",sans-serif', color: '#7e8691' }}>--<FormattedMessage id="GENERAL.FORMS.LABEL_NONE" />--</span>)
                    }
                }

                if (item.resource && item.labelType !== 'rendered') {
                    item.label = this.props.intl.formatMessage({ id: item.resource })
                }

                let label = null;
                if (item.hasOwnProperty("label")) {
                    if (item.labelType) {
                        if (item.labelType === 'rendered') {
                            label = item.label;
                        }
                    }
                    else {
                        label = (<strong>{item.label ? item.label + ':' : ''}</strong>);
                    }
                }



                if (item.hasOwnProperty("label")) {
                    if (item.align && item.align === 'vertical') {
                        const VerticalColStyle = { ...ColStyle, flexDirection: 'column', alignItems: 'normal' };

                        return (
                            <Row key={index}>
                                <Col style={VerticalColStyle}>
                                    <div style={{ marginBottom: 10 }}>{label}</div>
                                    {value}
                                </Col>
                            </Row >
                        );
                    }
                    else {
                        return (
                            <Row key={index}>
                                <Col style={{ ...ColStyle, borderRight: 0 }}>
                                    {label}
                                </Col>
                                <Col style={ColStyle}>
                                    {value}
                                </Col>
                            </Row >
                        );
                    }
                }
                else {
                    return (
                        <Row key={index}>
                            <Col style={ColStyle}>
                                {value}
                            </Col>
                        </Row >
                    );
                }

            })
        );
    }
    renderForm = () => {
        if (this.props.isFinal) {
            return (<div></div>);
        }
        else {
            const { companyid, projectid, contractid } = this.props.contract;
            const { isReply, workflowItem } = this.props;
            const renderAttachmentsGrid = (isReply && workflowItem.replyAttachments) || (!isReply && workflowItem.notifyAttachments)
            return (
                <>
                    <div style={{ marginTop: 10 }}>
                        {this.props.form}
                    </div>
                    {
                        renderAttachmentsGrid && (
                            <Row style={{ marginTop: 10 }}>
                                <Col>
                                    <span className="dx-field-item-label-text dx-box-item-content dx-widget"><FormattedMessage id="GENERAL.FORMS.FIELD.ATTACHED_DOCUMENTS" />:</span>
                                    {
                                        this.attachmentMode === "ASITE" && (
                                            <div className="dx-field" style={{ marginTop: "10px" }} >
                                                <AsiteFileList
                                                    files={this.props.files}
                                                    notificationid={this.props.notificationid}
                                                    documentType={this.props.documentType}
                                                    isDraft={true}
                                                    isReply={this.props.isReply}
                                                    workflowItem={workflowItem}
                                                />
                                            </div>
                                        )
                                    }
                                    {
                                        this.attachmentMode === "SHAREPOINT" && (
                                            <div className="dx-field" style={{ marginTop: "10px" }} >
                                                <SharepointFilepond
                                                    notificationid={this.props.notificationid}
                                                    documentType={this.props.documentType}
                                                    isReply={this.props.isReply}
                                                    assetid={this.props.assetid}
                                                />
                                            </div>
                                        )
                                    }
                                    {
                                        this.attachmentMode === "DEFAULT" && (
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <DragDropFilepond
                                                    authToken={this.props.authToken}
                                                    files={this.props.files}
                                                    uploadMethod={this.props.uploadMethod}
                                                    deleteMethod={(fileid) => { return fileCrud.deleteContractFile(companyid, projectid, contractid, fileid) }}
                                                    onFilesCountChanged={this.props.onFilesCountChanged}
                                                />
                                            </div>
                                        )
                                    }
                                </Col>
                            </Row>
                        )

                    }

                </>
            );
        }
    }

    render() {
        if (!this.state.config) {
            return (
                <Alert variant="secondary" style={{ display: 'block', margin: 20 }} >
                    <p>
                        <FormattedMessage id="GENERAL.DOCUMENT.NOT_AVAILABLE" />
                    </p>
                </Alert>
            )
        }
        else {
            return (
                <>
                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ width: '100%', maxWidth: '900px', margin: '20px' }}>
                            <div ref={this.props.printDivRef} id="divDocument" className="invoice-content-2 bordered dx-widget" >
                                <Container style={this.state.containerStyle}>
                                    <Row>
                                        <Col style={{ ...ColStyle, justifyContent: 'center', borderRight: 0 }}>
                                            <h1 className="uppercase" style={{ clear: 'both', fontSize: '18px', fontFamily: '"Open Sans",sans-serif', color: '#39424a', fontWeight: 700, textAlign: "center" }}>{this.state.config.title}<span></span></h1>
                                        </Col>
                                        <Col style={{ ...ColStyle, justifyContent: 'center' }}>
                                            <DocumentLogo
                                                companyid={this.state.config.logoCompany}
                                                companyname={this.state.config.logoCompanyName}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={this.state.headerStyle}>
                                        {
                                            this.state.config.headerColumns.map((column, index) => {
                                                const indexItemStyle = { borderRight: 0 }
                                                if (index + 1 === this.state.config.headerColumns.length) { delete indexItemStyle.borderRight; }
                                                return (
                                                    <Col key={"Header" + index} style={{ ...ColStyle, flexDirection: 'column', alignItems: 'normal', ...indexItemStyle }}>
                                                        <div style={{ marginBottom: 10 }}><strong>{(column || {}).label}:</strong></div>
                                                        <p >{(column || {}).value}</p>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                    {
                                        this.renderBody(this.state.config)
                                    }
                                </Container>
                                {this.renderForm()}
                            </div>
                        </div>

                    </div>
                </>
            );
        }

    }
}

const mapStateToProps = store => ({
    authToken: store.auth.authToken.token,
    userProfile: store.auth.userProfile,
    contract: store.contract.contract,
});

export default withSnackbar(injectIntl(withRouter(connect(mapStateToProps)(DocumentV2))));