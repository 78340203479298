const template = {
    name: "Default NEC4 PSC v3.0 06/06/2024",
    isSystemDefault: true,
    isGlobal: true,
    companies: [],
    resources: [
        {
            id: 1,
            type: "TEXT",
            key: "SUPPLIER_NAME",
            value: "Consultant"
        },
        {
            id: 2,
            type: "TEXT",
            key: "SUPPLIER_ABBREVIATION",
            value: "CON"
        },
        {
            id: 3,
            type: "TEXT",
            key: "PM_NAME",
            value: "Service Manager"
        },
        {
            id: 4,
            type: "TEXT",
            key: "PM_ABBREVIATION",
            value: "SM"
        },
        {
            id: 5,
            type: "TEXT",
            key: "SUPERVISOR_NAME",
            value: "Service Manager (Quality)"
        },
        {
            id: 6,
            type: "TEXT",
            key: "SUPERVISOR_ABBREVIATION",
            value: "CONS"
        },
        {
            id: 7,
            type: "TEXT",
            key: "CLIENT_NAME",
            value: "Client"
        },
        {
            id: 8,
            type: "NUMBER",
            key: "SUPPLIER_REPLY_PERIOD",
            value: 14
        },
        {
            id: 9,
            type: "NUMBER",
            key: "PM_REPLY_PERIOD",
            value: 14
        },
        {
            id: 10,
            type: "NUMBER",
            key: "QUOTE_DURATION",
            value: 21
        },
        {
            id: 13,
            type: "NUMBER",
            key: "REQUOTE_DURATION",
            value: 21
        },
        {
            id: 11,
            type: "NUMBER",
            key: "QUOTE_RESPONSE",
            value: 14
        },
        {
            id: 14,
            type: "NUMBER",
            key: "REQUOTE_RESPONSE",
            value: 14
        },
        {
            id: 12,
            type: "NUMBER",
            key: "VARIATION_RESPONSE",
            value: 7
        },
        {
            id: 16,
            type: "NUMBER",
            key: "PROGRAMME_REPLY_PERIOD",
            value: 14
        },
        {
            id: 8,
            type: "TEXT",
            key: "CLIENT_ABBREVIATION",
            value: "CLI"
        },
        {
            id: 28,
            type: "NUMBER",
            key: "PROP_QUOTE_DURATION",
            value: 21
        },
        {
            id: 29,
            type: "NUMBER",
            key: "PROP_REQUOTE_DURATION",
            value: 21
        },
        {
            id: 27,
            type: "NUMBER",
            key: "SUPERVISOR_REPLY_PERIOD",
            value: 14
        }
    ],
    workflowGroups: [
        {
            id: 101,
            title: "Early Warnings",
            workflows: [
                {
                    id: 1,
                    title: "$PM_NAME Early Warnings",
                    notificationName: "Early Warning",
                    abbreviation: "$PM_ABBREVIATION-Warning-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    actioningParty: "PM",
                    resolveActioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
                        resolveActionTab: {
                            resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                },
                {
                    id: 2,
                    title: "$SUPPLIER_NAME Early Warnings",
                    notificationName: "Early Warning",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Warning-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    actioningParty: "PM",
                    resolveActioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
                        resolveActionTab: {
                            resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                },
                {
                    id: 3,
                    title: "Early Warning Register",
                    notificationName: "Early Warning",
                    abbreviation: "Warning",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "MeetingRequiredLabel",
                            display: "Is Early Warning Meeting required?",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "NotificationClause",
                            display: "You are given early warning of a matter which could",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "RiskItemsLabel",
                            display: "You are given early warning of a matter which could",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Pm",
                            display: "$PM_ABBREVIATION Early Warnings awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Early Warnings awaiting reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypes",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "riskincreaseprice",
                            display: "Increase the total of the Prices",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "riskdelaycompletion",
                            display: "Delay Completion",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "riskdelaymilestone",
                            display: "Delay meeting a Key Date",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "riskimpairperformance",
                            display: "Impair the usefulness of the services to the Client",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "riskaffectwork",
                            display: "Affect the work of the Client, a Client’s contractor or another consultant",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "riskaffectmodel",
                            display: "Adversely affect the creation or use of the Information Model",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "riskotherpartner",
                            display: "Affect the achievement of another Partner's objectives",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "riskincreasecontractorcost",
                            display: "Increase the $SUPPLIER_NAME's total cost",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "riskenvironment",
                            display: "X29.3 adversely affect the achievement of the Climate Change Requirements",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "MeetingOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "0",
                            display: "Meeting Not Required",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Meeting Required - Next Scheduled Risk Reduction Meeting",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Meeting Required - You are instructed to attend a meeting as below",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "RiskStatusOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Mitigated",
                            display: "Mitigated",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "NotMitigated",
                            display: "Not Mitigated",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 102,
            title: "Instructions",
            workflows: [
                {
                    id: 4,
                    title: "$PM_NAME Instruction",
                    notificationName: "Instruction",
                    abbreviation: "$PM_ABBREVIATION-Instruction-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "InstructionTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "14.3 to change the Scope or a Key Date",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "14.3 to correct information provided by the Client",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "16.1 stating how to resolve an ambiguity or inconsistency",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "16.2 to change the Scope to remove something which is illegal or impossible",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "18.1 on how to deal with a prevention event",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "21.2 to remove a person",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "32.2 to submit a revised programme",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "34.1 to stop or not start work or to start or restart work",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "35.1 to submit a quotation for acceleration",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "40.3 to correct a failure to comply with the quality plan",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "62.1 to submit alternative quotations for a compensation event",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "92.2 to assign the benefit of any subcontractor to the Client",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "X10.4 to submit a revised Information Execution Plan",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "X12.1(6) providing Partnering Information",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "Other Instruction",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 103,
            title: "Compensation Events",
            workflows: [
                {
                    id: 5,
                    title: "$PM_NAME Compensation Events",
                    notificationName: "Compensation Event",
                    abbreviation: "$PM_ABBREVIATION-Compensation-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    quoteNotificationName: "Quote",
                    quoteReferencePrefix: "Quote-",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 6,
                    title: "$PM_NAME Proposed Instructions",
                    notificationName: "Proposed Instruction",
                    abbreviation: "$PM_ABBREVIATION-PROP-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    quoteNotificationName: "Quote",
                    quoteReferencePrefix: "Quote-",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 7,
                    title: "$SUPPLIER_NAME Compensation Events",
                    notificationName: "Compensation Event",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Compensation-",
                    notify: true,
                    replyEnabled: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "VARIATION_RESPONSE",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    quoteNotificationName: "Quote",
                    quoteReferencePrefix: "Quote-",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 8,
                    title: "$PM_NAME Assessments",
                    notificationName: "Assessment",
                    abbreviation: "$PM_ABBREVIATION-Assessment-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 9,
                    title: "$PM_NAME Extensions",
                    notificationName: "Extension",
                    abbreviation: "$PM_ABBREVIATION-Extension-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ORIGINAL_DATE",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 10,
                    title: "$SUPPLIER_NAME Extensions",
                    notificationName: "Extension",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Extension-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ORIGINAL_DATE",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 11,
                    title: "Master Quote Register",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null"
                    }
                },
                {
                    id: 12,
                    title: "Master CE Register",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null"
                    }
                },
                {
                    id: 13,
                    title: "Implemented CE Register",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmAssumptionLabel",
                            display: "The quotation is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmAssumptionNote",
                            display: "You are instructed to provide a quotation by $date",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierDescNote",
                            display: "You are notified of the following event as a compensation event",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "ReplyAcceptedCommentLabel",
                            display: "You are notified that the event is a compensation event",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "ReplyRejectedCommentLabel",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ReplyAcceptedAssumptionLabel",
                            display: "The quotation is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "QuoteNote",
                            display: "We submit our quotation for compensation event $reference",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "ExtensionPMLabel",
                            display: "We require an extension to the time to reply as follows",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ExtensionSupplierLabel",
                            display: "We require an extension to the time to reply as follows",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "AssessmentDetailLabel",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ProposedInstructionNote",
                            display: "You are instructed to provide a quotation by $date",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "ProposedInstructionLabel",
                            display: "The quotation is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "ReplyRejectedOptionsLabel",
                            display: "You are notified that the event is not a compensation event as it",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "QuoteCostEffectLabel",
                            display: "Change to the Prices",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "QuoteDateEffectLabel",
                            display: "Delay to planned Completion",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "QuoteDaysEffectLabel",
                            display: "The length of time that the Completion Date is delayed",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "AssessmentCostEffectLabel",
                            display: "Change in the Total of the Prices",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "AssessmentDaysEffectLabel",
                            display: "Delay to planned Completion",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "QuoteNoteProposedInstruction",
                            display: "We submit our quotation for proposed compensation event $reference",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmCompensationAQ",
                            display: "Compensation Events notified by $PM_ABBREVIATION awaiting quotes",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmCompensationARQ",
                            display: "Compensation Events notified by $PM_ABBREVIATION awaiting revised quotes",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "PmCompensationAPMRQ",
                            display: "Compensation Events notified by $PM_ABBREVIATION awaiting response to quotes",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "PmCompensationAPMA",
                            display: "Compensation Events notified by $PM_ABBREVIATION awaiting $PM_ABBREVIATION Assessment",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SupCompensationAQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting quotes",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SupCompensationARQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting revised quotes",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "SupCompensationAPMR",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION response",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "SupCompensationAPMRQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting response to quotes",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "SupCompensationAPMA",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "PmProposedAQ",
                            display: "Proposed changes awaiting quotes",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "PmProposedARQ",
                            display: "Proposed changes awaiting revised quotes",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "PmProposedAPMRQ",
                            display: "Proposed changes awaiting response to quotes",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "PmProposedAPMA",
                            display: "Proposed changes awaiting $PM_ABBREVIATION Assessment",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "PmExtension",
                            display: "$PM_ABBREVIATION Quote Response Extensions awaiting Acceptance",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "SupExtension",
                            display: "$SUPPLIER_NAME Quote Extensions awaiting Acceptance",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "CompensationClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "60.1(1) An instruction to change the Scope",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "60.1(2) Client does not provide access to a person, place or thing",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "60.1(3) Client does not provide something that it is to provide",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "60.1(4) An instruction to stop or not start work or to change a Key Date",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "60.1(5) Client or Others do not work within the stated constraints",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "60.1(6)$PM_NAME does not reply to a communication",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "60.1(7) Change of a decision",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "60.1(8) Withholds an acceptance",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "60.1(9) An event which is the Client's liability",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "60.1(10) Notification of a correction",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "60.1(11) A breach by the Client",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "60.1(12) A prevention event",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "60.1(13) Correcting a Defect for which the Consultant is not liable",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "60.1(14) An instruction correcting information provided by the Client",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "60.1(15) A notification that a quotation for a proposed instruction is not accepted",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "60.1(16) Additional compensation events stated in the Contract Data and identified below",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "X2.1 A change in the law",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "X12.3(7) A change in the Partnering Information",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "Y2.5 Suspension of performance",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "faultpscp",
                            display: "Arises from a fault of the $SUPPLIER_NAME",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "notexpectnothappen",
                            display: "Has not happened and is not expected to happen",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "notnotifiedwithintimescales",
                            display: "Has not been notified within the timescales set out in the contract",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "noteffectcostcompletion",
                            display: "Has no effect on Defined Cost, Completion or meeting a Key Date",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "statedcontract",
                            display: "Is not one of the compensation events stated in the contract",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ClaimClauses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "",
                            display: "60.1(6) Late reply to communication",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "60.1(1) Employer has given an instruction to change the Scope",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "60.1(3) Something not provided",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "60.1(5) Work by employer or others",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "60.1(7) Previous decision changed",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "60.1(2) Access not provided by date stated within the contract",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "60.1(4) Stop or change key date",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "60.1(9) PM corrects an assumption related to a previous compensation event",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "60.1(10) Breach of Contract",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "60.1(8) Acceptance Withheld",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "60.1(11) Prevention event",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "60.1(12) Defect corrected which was not the Consultant’s liability",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "60.1(13): Instruction to change Task Order issued by Employer",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "60.1(14): Consultant receives late Task Order",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "60.1(15): a Task Completion Date is later than the Completion Date",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "X2.1: Change in law",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "CompensationStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "APMA",
                            display: "Quote Not Accepted - $PM_ABBREVIATION to make own Assessment",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ARQ",
                            display: "Quote Not Accepted - $SUPPLIER_NAME to resubmit",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "AM",
                            display: "$PM_ABBREVIATION Assessment Made",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "QA",
                            display: "Quote Accepted",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "APMRQ",
                            display: "Awaiting $PM_ABBREVIATION Response to quote",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "APMR",
                            display: "Awaiting $PM_ABBREVIATION Response to CEN",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "AQ",
                            display: "Awaiting Quote",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NC",
                            display: "Not Communicated",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "R",
                            display: "Not a Compensation Event",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "DNG",
                            display: "Decision Not Given",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "QuoteStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "0",
                            display: "Not Accepted - $SUPPLIER_NAME to resubmit",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Accepted",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Awaiting Response",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "3",
                            display: "Not Accepted - $PM_ABBREVIATION to make assessment",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "4",
                            display: "Not Accepted - Other quote approved",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "5",
                            display: "Not Accepted - Decision will not be given",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "none",
                            display: "Not Communicated",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "InstructionReplyProceedOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "61.1",
                            display: "Consultant is instructed to proceed with works",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "61.2",
                            display: "Consultant is instructed to NOT proceed with works until instructed",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 104,
            title: "Programme",
            workflows: [
                {
                    id: 14,
                    title: "$SUPPLIER_NAME Programme",
                    notificationName: "Programme",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Programme-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "ReasonLabel",
                            display: "Reason for any change to Planned Completion",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ChangeLabel",
                            display: "Change in any other Key Date, Completion Date for a section or other important project milestone in period and reasons why",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "MainProgressLabel",
                            display: "Main progress achieved in period",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "KeyActivitiesLabel",
                            display: "Key activities forecast in next four weeks",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SummaryLabel",
                            display: "Summary of critical path",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ChangesLabel",
                            display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc.",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "CompensationLabel",
                            display: "Compensation events added to programme this period",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NotificationClause",
                            display: "Please find attached programme for acceptance",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ReplyRejectReasonLabel",
                            display: "The $SUPPLIER_NAME’s programme is not accepted for the following reasons",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "ReplyNotificationClause",
                            display: "You are notified that the $SUPPLIER_NAME’s Programme",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Programme Submission awaiting Acceptance",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "plansnotpractical",
                            display: "The $SUPPLIER_NAME’s plans which it shows are not practicable",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "missingcontractrequires",
                            display: "It does not show the information which the contract requires",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "notrepresentplan",
                            display: "It does not represent the $SUPPLIER_NAME’s plans realistically",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "notcomply",
                            display: "It does not comply with the Scope",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 105,
            title: "Notifications",
            workflows: [
                {
                    id: 15,
                    title: "$PM_NAME Notifications",
                    notificationName: "Notification",
                    abbreviation: "$PM_ABBREVIATION-Notification-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 16,
                    title: "$SUPPLIER_NAME Notifications",
                    notificationName: "Notification",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Notification-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "PmClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "13.2 of a change of address for communications",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "13.5 of an agreement to extend the period for reply for a communication (excluding compensation events periods)",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "14.2 of the cancellation of a delegation",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "16.1 of an ambiguity or inconsistency",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "16.2 of an illegal or impossible requirement",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "22.3 of the amount assessed for a failure to meet a Key Date",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "23.3 of an agreement that submission of Subcontractor documents is not needed",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "25.1 on behalf of the Client of an intention to transfer the benefit of the contract",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: false,
                            field: "",
                            display: "30.2 of certification of the date of Completion",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "33.2 on behalf of the Client of supply of information",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "41.2 of the amount to be paid for uncorrected Defects",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "42.1 of a proposal to accept a Defect",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "50.9 of acceptance that part of the Defined Cost is correct (main Options C and E)",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "50.9 of further records in respect of Defined Cost are needed (main Options C and E)",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "50.9 of errors in the Subcontractor's assessment (main Options C and E)",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "50.9 of the correct assessment of the part of the Defined Cost (main Options C and E)",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: false,
                            field: "",
                            display: "53.1 of certification of the final payment",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "61.5 that the$PM_NAME has decided the Contractor did not give early warning of a compensation event",
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "90.1 of a wish to terminate",
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: false,
                            field: "",
                            display: "90.1 of the issue of a termination certificate",
                            overrideResources: null
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "91.2 of a default/of the Consultant having not put right a default",
                            overrideResources: null
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "",
                            display: "91.3 of a default/of the Consultant having not put right a default",
                            overrideResources: null
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "",
                            display: "X18.4 of a matter for which the Consultant is liable",
                            overrideResources: null
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "",
                            display: "Y2.3 of an intention to pay less than the notified sum",
                            overrideResources: null
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "",
                            display: "Y3.3 on behalf of the Client of the name of the beneficiary",
                            overrideResources: null
                        },
                        {
                            id: 28,
                            isActive: true,
                            field: "",
                            display: "Other notification",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "SupplierClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "13.2 of a change of address for communications",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "16.1 of an ambiguity or inconsistency",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "16.2 of an illegal or impossible requirement",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "22.2 of a meeting with Others",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "28.1 of an intention to transfer the benefit of the contract",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "31.3 of a failure by the$PM_NAME to notify acceptance or non acceptance of a programme",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "50.9 of when the Defined Cost of part of the works has been finalised (main Options C and E)",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "61.4 of a failure by the$PM_NAME to respond to a compensation event notification",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "62.6 of a failure by the$PM_NAME to respond to a quotation",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "64.4 of a failure by the$PM_NAME to make an assessment of a compensation event",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "90.1 of a wish to terminate",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "X10.4(2) of a failure by the$PM_NAME to notify acceptance or non acceptance of an Information Execution Plan",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "Y2.3 of an intention to pay less than the notified sum",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "Other notification",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 106,
            title: "Submissions",
            workflows: [
                {
                    id: 18,
                    title: "$SUPPLIER_NAME Submissions",
                    notificationName: "Submission",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Submission-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 49,
                    title: "$PM_NAME Submissions",
                    notificationName: "Submission",
                    abbreviation: "$PM_ABBREVIATION-Submission-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "IssueClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "20.4 forecasts of total expenses for the whole of the services (main Option A)",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "20.5 forecasts of total Defined Costs and expenses for the whole of the services (main Option C and E)",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "21.1 details of a replacement person",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "23.2 the name of a proposed subconsultant",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "23.3 proposed subcontract documents",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "24.4 pricing information in a proposed subcontract document (main Options C and E)",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "40.2 a proposed quality policy statement or quality policy plan",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "42.1 a proposal to accept a Defect",
                            overrideResources: null
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "",
                            display: "50.9 of records to demonstrate Defined Cost is correctly assessed (main Options C and E) (4 weeks reply)",
                            applyReplyPeriod: true,
                            replyPeriod: 28,
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "50.9 further records to demonstrate Defined Cost is correctly assessed (main Options C, D, E and F) (13 weeks reply)",
                            applyReplyPeriod: true,
                            replyPeriod: 91,
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "51.1 of an invoice",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "53.1 of an invoice in connection with the final payment",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "55.3 a revised activity schedule (main Option A)",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "84.1 certificates of insurance",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "X4.1 an ultimate holding company guarantee",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "X9.1 documents which transfer rights to the Client",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "X10.4(1) an Information Execution Plan",
                            applyReplyPeriod: true,
                            replyPeriod: 14,
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "X10.4(3) a revised Information Execution Plan",
                            applyReplyPeriod: true,
                            replyPeriod: 14,
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "X13.1 a performance bond",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "X20.2 a report of performance",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "X20.3 proposals for improving performance",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "",
                            display: "X29.4(1) of a first climate change plan for acceptance",
                            applyReplyPeriod: true,
                            replyPeriod: 14,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 29,
                            isActive: true,
                            field: "",
                            display: "X29.4(4) of a revised climate change plan for acceptance",
                            applyReplyPeriod: true,
                            replyPeriod: 14,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 31,
                            isActive: true,
                            field: "",
                            display: "X29.11(1) of a proposal to change the Scope to reduce the impact on climate change",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 33,
                            isActive: true,
                            field: "",
                            display: "X29.12(1) of a report of performance against the targets in the Performance Table",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 35,
                            isActive: true,
                            field: "",
                            display: "X29.12(2) of a proposal for improving performance for acceptance",
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "Y1.4 details of the banking arrangement for the Project Bank Account",
                            overrideResources: null
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "Y1.6 a proposal to add a Supplier to the Named Suppliers",
                            overrideResources: null
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "",
                            display: "Y1.9 the Authorisation setting out sums due to the Named Suppliers",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "",
                            display: "Other submission",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Submission awaiting Acceptance",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "IssueClausesPM",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "",
                            display: "86.1 of certificates of insurance provided by the Client",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 107,
            title: "Submissions (with quote)",
            workflows: [
                {
                    id: 19,
                    title: "$SUPPLIER_NAME Submissions (wq)",
                    notificationName: "Submission",
                    abbreviation: "$SUPPLIER_ABBREVIATION-SubmissionWQ-",
                    notify: true,
                    replyEnabled: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CostEffectLabel",
                            display: "Change in the Prices",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "DaysEffectLabel",
                            display: "Delay to planned Completion",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ProposalClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "35.1 a quotation for acceleration",
                            applyReplyPeriod: true,
                            replyPeriod: 21,
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "42.2 a quotation to accept a Defect",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "X29.11(2) for a change to reduce the impact on climate change",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Proposals awaiting Acceptance",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 108,
            title: "Payments",
            workflows: [
                {
                    id: 20,
                    title: "$PM_NAME Assessment",
                    notificationName: "Assessment",
                    abbreviation: "$PM_ABBREVIATION-Assessment-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 21,
                    title: "$SUPPLIER_NAME Payment Applications",
                    notificationName: "Payment Application",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Payment-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CumulativePriceLabel",
                            display: "Price for Services Provided to Date plus expenses",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlusAmountsSupplierLabel",
                            display: "Plus other amounts to be paid to the $SUPPLIER_NAME",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "LessAmountsSupplierLabel",
                            display: "Less amounts to be paid by or retained from the $SUPPLIER_NAME",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TaxSupplierLabel",
                            display: "Tax which the law requires the Employer to pay to the $SUPPLIER_NAME",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "WarningBanner",
                            display: "The first assessment date is no later than the assessment interval after the starting date. Subsequent assessment dates are the assessment interval after the previous assessment. Assessments continue until 4 weeks after the defects date",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SummaryDetailsLabel",
                            display: "Summary details",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "RegisterAppliedByCaption",
                            display: "Submitted by",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: []
                },
                {
                    replacementKey: "TaxEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "TaxEnabled",
                            display: "Active-Inactive state indicates tax field will be included in notification forms",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ApplicationTypes",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "FINAL",
                            display: "Final",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "INTERIM",
                            display: "Interim",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SUPPLEMENTARY",
                            display: "Supplementary",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "PaymentTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "INTERIM",
                            display: "51.1 Payment Certificate",
                            overrideResources: null
                        },
                        {
                            id: 1,
                            isActive: true,
                            field: "FINAL",
                            display: "53.1 Final Payment Certificate",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "SUPPLEMENTARY",
                            display: "Supplementary",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "MandateAttachment",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "MandateAttachment",
                            display: "Active-Inactive state indicates weather attachment is mandatory on Payment Application and Certifications",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "UserLimitCheckEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "UserLimitCheckEnabled",
                            display: "Active-Inactive state indicates weather the user limit check can be applied in Payment communications",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "PONumberEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "PONumberEnabled",
                            display: "Purchase Order number field will be included in notification forms",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 119,
            title: "Other Assessments",
            workflows: [
                {
                    id: 77,
                    title: "$PM_NAME Assessments",
                    notificationName: "Assessment",
                    abbreviation: "$PM_ABBREVIATION-Other-Assessment-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "[[\"party\",\"=\",\"Client\"],\"and\",[\"party\",\"=\",\"Supervisor\"]]",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: []
                },
                {
                    replacementKey: "ActionReminders",
                    values: []
                },
                {
                    replacementKey: "FieldsPM",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Subject",
                            display: "Subject",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Type",
                            display: "Type",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Description",
                            display: "Description",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "EffectContractPrice",
                            display: "Affect contract price",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "Amount",
                            display: "Amount",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "EffectContractCompletion",
                            display: "Affect $COMPLETION_DATE",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "Days",
                            display: "Time",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ClausesPM",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "",
                            display: "22.3 of additional cost incurred by the $CLIENT_NAME as a result of the $SUPPLIER_NAME's failure to meet a Key Date",
                            effect: "NOEFFECT_COMPLETION_PRICE",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "41.2 of the cost to the $CLIENT_NAME of having an uncorrected Defect corrected by other people",
                            effect: "NOEFFECT_COMPLETION_PRICE",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "50.9 of the correct assessment of a part of the Defined Cost that has been finalised (main Options C and E)",
                            effect: "NOEFFECT_COMPLETION_PRICE",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "50.9 of the adjustment to the Prices following assessment of a part of the Defined Cost that has been finalised (main Options C and E)",
                            effect: "EFFECT_PRICE",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "54.3 preliminary assessment of the $SUPPLIER_NAME's share at Completion",
                            effect: "NOEFFECT_COMPLETION_PRICE",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "54.4 final assessment of the $SUPPLIER_NAME's share",
                            effect: "NOEFFECT_COMPLETION_PRICE",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "93.3 of the $SUPPLIER_NAME's share after termination",
                            effect: "NOEFFECT_COMPLETION_PRICE",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "X1.3 of a price adjustment for inflation (main Option A)",
                            effect: "EFFECT_PRICE",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "X1.4 of a price adjustment for inflation (main Option C)",
                            effect: "EFFECT_PRICE",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "X7.1 of delay damages",
                            effect: "NOEFFECT_COMPLETION_PRICE",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "X7.2 of interest of overpaid delay damages",
                            effect: "NOEFFECT_COMPLETION_PRICE",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 117,
            title: "Other Certificates",
            workflows: [
                {
                    id: 68,
                    title: "$PM_NAME Certificates",
                    notificationName: "Certificate",
                    abbreviation: "$PM_ABBREVIATION-Certificate-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmCertificateText",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SupplierCertificateText",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "ClientCertificateText",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "SupervisorCertificateText",
                            display: "",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PM",
                            display: "$PM_NAME Certificates awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Certificates awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Client",
                            display: "$CLIENT_NAME Certificates awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Supervisor",
                            display: "$SUPERVISOR_NAME Certificates awaiting reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "CompletionFields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CertifiedDate",
                            display: "Completion Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Description",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Works",
                            display: "Outstanding Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Defects",
                            display: "Defects",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "SectionalCompletionFields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CertifiedDate",
                            display: "Sectional Completion Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Description",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Works",
                            display: "Outstanding Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Defects",
                            display: "Defects",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "TakingOverFields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CertifiedDate",
                            display: "Taking Over Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Description",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Works",
                            display: "Outstanding Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Defects",
                            display: "Defects",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "DefectsFields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CertifiedDate",
                            display: "Certified Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Description",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "Works",
                            display: "Outstanding Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Defects",
                            display: "Outstanding Defects",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "TerminationFields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CertifiedDate",
                            display: "Certified Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Description",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "Works",
                            display: "Outstanding Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "Defects",
                            display: "Outstanding Defects",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "OtherFields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CertifiedDate",
                            display: "Certified Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Description",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "Works",
                            display: "Outstanding Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "Defects",
                            display: "Outstanding Defects",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "TaskOrderCompletionFields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CertifiedDate",
                            display: "Certified Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Description",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "Works",
                            display: "Outstanding Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "Defects",
                            display: "Outstanding Defects",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ProjectOrderCompletionFields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CertifiedDate",
                            display: "Certified Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Description",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "Works",
                            display: "Outstanding Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "Defects",
                            display: "Outstanding Defects",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Accepted",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Not Accepted",
                            display: "Not Accepted",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "PMCertTypes",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "",
                            display: "30.2 Completion Certificate",
                            effect: "COMPLETION",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "",
                            display: "Sectional Completion Certificate",
                            effect: "SECTIONAL_COMPLETION",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "",
                            display: "Taking Over Certificate",
                            effect: "TAKING_OVER",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "",
                            display: "Defects Certificate",
                            effect: "DEFECTS",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "90.1 Termination Certificate",
                            effect: "TERMINATION",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: false,
                            field: "",
                            display: "Other Certificate",
                            effect: "OTHER",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: false,
                            field: "",
                            display: "Task Order Completion",
                            effect: "TASK_ORDER_COMPLETION",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: false,
                            field: "",
                            display: "Project Order Completion",
                            effect: "PROJECT_ORDER_COMPLETION",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "CompletionChangesStateEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CompletionChangesStateEnabled",
                            display: "Indicate whether communication of a Completion Certificate should change the contract state",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 109,
            title: "Quality Management",
            workflows: [
                {
                    id: 23,
                    title: "$PM_NAME Defect Notifications",
                    notificationName: "Defect Notification",
                    abbreviation: "$PM_ABBREVIATION-Defect-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    actioningParty: "PM",
                    resolveActioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
                        resolveActionTab: {
                            resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                },
                {
                    id: 24,
                    title: "$SUPPLIER_NAME Defect Notifications",
                    notificationName: "Defect Notification",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Defect-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    actioningParty: "PM",
                    resolveActioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
                        resolveActionTab: {
                            resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                },
                {
                    id: 26,
                    title: "Master Defect Register",
                    notificationName: "Defect Notification",
                    abbreviation: "Defect",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "InstructionClauseLabel",
                            display: "You are instructed under clause 41.3 with Details",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SupervisorClauseLabel",
                            display: "You are notified of the following Defect",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierClauseLabel",
                            display: "You are notified of the following Defect",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TestsClauseLabel",
                            display: "You are instructed under clause 41.3 with Details",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Instruction",
                            display: "$PM_NAME Instruction to Search awaiting sign off",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SuperDefect",
                            display: "$PM_NAME Notification of Defect awaiting acceptance",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Inspection",
                            display: "$SUPPLIER_NAME Test/Inspection Notification awaiting sign off",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ClosureReasonOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Accepted",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Corrected",
                            display: "Corrected",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "NoDefect",
                            display: "Not a Defect",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 110,
            title: "Delegated Actions",
            workflows: [
                {
                    id: 27,
                    title: "$PM_NAME Delegated Actions",
                    notificationName: "Delegated Action",
                    abbreviation: "$PM_ABBREVIATION-Action-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "DelegationClause",
                            display: "You are notified that the following actions",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 111,
            title: "Records",
            workflows: [
                {
                    id: 30,
                    title: "$PM_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$PM_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 31,
                    title: "$SUPPLIER_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 32,
                    title: "$SUPPLIER_NAME Site Diary",
                    notificationName: "Site Diary",
                    abbreviation: "Diary-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 33,
                    title: "Weather",
                    notificationName: "Weather",
                    abbreviation: "Weather-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: []
        },
        {
            id: 112,
            title: "RFI / TQ",
            workflows: [
                {
                    id: 34,
                    title: "$SUPPLIER_NAME RFI / TQ",
                    notificationName: "RFI / TQ",
                    abbreviation: "RFI/TQ-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME RFI/TQ awaiting Reply",
                            overrideResources: null
                        }
                    ]
                }
            ]
        }
    ],
    copyInfo: []
};
export default template;