/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */

// CSS RESET for - user agent stylesheet
// https://meyerweb.com/eric/tools/css/reset/
import 'normalize.css';

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import store, { persistor } from "./app/store/store";
import App from "./App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
// import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/* const mock = */ //mockAxios(axios);

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
//setupAxios(axios, store);

const Layout = React.lazy(() => import("./_metronic/layout/Layout.js"));

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
    <App
      store={store}
      Layout={Layout}
      persistor={persistor}
      basename={PUBLIC_URL}
    />
  // </React.StrictMode>

);

// ReactDOM.render(
//   <App
//     store={store}
//     Layout={Layout}
//     persistor={persistor}
//     basename={PUBLIC_URL}
//   />,
//   document.getElementById("root")
// );
