import DefaultWorkflows from './DefaultWorkflows';
import DefaultResources from './DefaultResources';
import NEC3_ECS from './DefaultTemplateVersions/NEC3_ECS';
import NEC3_ECC from './DefaultTemplateVersions/NEC3_ECC';
import NEC3_PSC from './DefaultTemplateVersions/NEC3_PSC';
import NEC4_ECC from './DefaultTemplateVersions/NEC4_ECC';
import NEC4_PSC from './DefaultTemplateVersions/NEC4_PSC';
import NEC4_ECS from './DefaultTemplateVersions/NEC4_ECS';
import NEC4_TSC from './DefaultTemplateVersions/NEC4_TSC';
import NEC3_ECSS from './DefaultTemplateVersions/NEC3_ECSS';
import NEC3_ECSC from './DefaultTemplateVersions/NEC3_ECSC';
import JCT_DB16 from './DefaultTemplateVersions/JCT_DB16';
import JCT_SBC_16 from './DefaultTemplateVersions/JCT_SBC_16';
import NEC3_TSC from './DefaultTemplateVersions/NEC3_TSC';
//14/11/2022
import NEC3_PSSC from './DefaultTemplateVersions/NEC3_PSSC';
import NEC3_SSC from './DefaultTemplateVersions/NEC3_SSC';
import JCT_MC from './DefaultTemplateVersions/JCT_MC';
//19/12/2022
import NEC4_TSSC from './DefaultTemplateVersions/NEC4_TSSC';
//16/03/2023
import JCT_ICD_16 from './DefaultTemplateVersions/JCT_ICD_16';
//11/05/2023
import NEC3_SC from './DefaultTemplateVersions/NEC3_SC';
//10/08/2023
import FIDIC_SILVER_2017 from './DefaultTemplateVersions/FIDIC_SILVER_2017';
import NEC4_ECSC_2019 from './DefaultTemplateVersions/NEC4_ECSC_2019';
//22/12/2023
import FIDIC_RED_2017 from './DefaultTemplateVersions/FIDIC_RED_2017';
import FIDIC_WHITE_2017 from './DefaultTemplateVersions/FIDIC_WHITE_2017';
import FIDIC_YELLOW_2017 from './DefaultTemplateVersions/FIDIC_YELLOW_2017';
import FIDIC_GREEN_2021 from './DefaultTemplateVersions/FIDIC_GREEN_2021';
import FIDIC_GREEN_1999 from './DefaultTemplateVersions/FIDIC_GREEN_1999';
import FIDIC_RED_1999 from './DefaultTemplateVersions/FIDIC_RED_1999';
import FIDIC_SILVER_1999 from './DefaultTemplateVersions/FIDIC_SILVER_1999';
import FIDIC_WHITE_1999 from './DefaultTemplateVersions/FIDIC_WHITE_1999';
import FIDIC_YELLOW_1999 from './DefaultTemplateVersions/FIDIC_YELLOW_1999';
//10/05/2024
import NEC4_PSS from './DefaultTemplateVersions/NEC4_PSS';
import NEC4_ECSC from './DefaultTemplateVersions/NEC4_ECSC';

const defaultTemplateList = [
    {
        templateid: 1,
        name: 'Default Template',
        isSystemDefault: true,
        isGlobal: true,
        companies: [],
        resources: JSON.parse(JSON.stringify(DefaultResources)),
        workflowGroups: JSON.parse(JSON.stringify(DefaultWorkflows))
    },
    JSON.parse(JSON.stringify(NEC3_ECS)),
    JSON.parse(JSON.stringify(NEC3_ECC)),
    JSON.parse(JSON.stringify(NEC3_PSC)),
    JSON.parse(JSON.stringify(NEC4_ECC)),
    JSON.parse(JSON.stringify(NEC4_PSC)),
    JSON.parse(JSON.stringify(NEC4_ECS)),
    JSON.parse(JSON.stringify(NEC4_TSC)),
    JSON.parse(JSON.stringify(NEC3_ECSS)),
    JSON.parse(JSON.stringify(NEC3_ECSC)),
    JSON.parse(JSON.stringify(JCT_DB16)),
    JSON.parse(JSON.stringify(JCT_SBC_16)),
    JSON.parse(JSON.stringify(NEC3_TSC)),
    JSON.parse(JSON.stringify(NEC3_PSSC)),
    JSON.parse(JSON.stringify(NEC3_SSC)),
    JSON.parse(JSON.stringify(JCT_MC)),
    JSON.parse(JSON.stringify(NEC4_TSSC)),
    JSON.parse(JSON.stringify(JCT_ICD_16)),
    JSON.parse(JSON.stringify(NEC3_SC)),
    JSON.parse(JSON.stringify(FIDIC_SILVER_2017)),
    JSON.parse(JSON.stringify(NEC4_ECSC_2019)),
    JSON.parse(JSON.stringify(FIDIC_RED_2017)),
    JSON.parse(JSON.stringify(FIDIC_WHITE_2017)),
    JSON.parse(JSON.stringify(FIDIC_YELLOW_2017)),
    JSON.parse(JSON.stringify(FIDIC_GREEN_2021)),
    JSON.parse(JSON.stringify(FIDIC_GREEN_1999)),
    JSON.parse(JSON.stringify(FIDIC_RED_1999)),
    JSON.parse(JSON.stringify(FIDIC_SILVER_1999)),
    JSON.parse(JSON.stringify(FIDIC_WHITE_1999)),
    JSON.parse(JSON.stringify(FIDIC_YELLOW_1999)),
    JSON.parse(JSON.stringify(NEC4_PSS)),
    JSON.parse(JSON.stringify(NEC4_ECSC))
];
export default defaultTemplateList;