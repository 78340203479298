import axios from '../shared/axios';
// import qs from 'querystring';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function saveEarlyWarning(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/EarlyWarning/SaveEarlyWarning`, data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}

export function deleteEarlyWarningDraft(warningid) 
{
    return new Promise((resolve, reject) => {
     axios.post(`api/EarlyWarning/DeleteDraft?warningid=${warningid}`)
        .then(result => {
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
            resolve(result);
        })
        .catch(error => reject(error));
    })
}
export function getEarlyWarning(earlywarningid) {
    return axios.get(`/api/EarlyWarning/GetEarlyWarning?earlywarningid=${earlywarningid}`);
}
// export function getContractEarlyWarnings(companyid, projectid, contractid, accessibleWfIds) {
//     return axios.post(`/api/EarlyWarning/LoadEarlyWarningsList`, { accessibleWfIds }, { params: { companyid, projectid, contractid } });
// }
export function getEarlyWarningsReduced(companyid, projectid, contractid, type, accessibleWfIds) {
    return axios.post(`/api/EarlyWarning/RegisterList`, { accessibleWfIds }, { params: { companyid, projectid, contractid, party:type } });
}
export function getEarlyWarningsToSelectList(companyid, projectid, contractid) {
    return axios.get(`/api/EarlyWarning/ToSelectList`, { params: { companyid, projectid, contractid} });
}

export function ResolveRiskUpdate(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/EarlyWarning/ResolveRiskUpdate`, data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}

export function UpdateRisk(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/EarlyWarning/UpdateRisk`, data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}