import React from 'react';
import { connect } from "react-redux";
import BiDynamicForm from '../../../partials/layout/BiDynamicForm';
import * as contractDuck from '../../../store/ducks/contract.duck';
import * as authDuck from '../../../store/ducks/auth.duck';
import { Portlet, PortletBody, PortletHeader } from "../../../partials/content/Portlet";
import PortletSpinner from '../../../partials/layout/PortletSpinner';
import { withSnackbar } from 'notistack';
import { countries, GetCurrencyCodes } from '../../../shared/lookup';
import * as userCrud from '../../../crud/user.crud';
import { FormattedMessage, injectIntl } from "react-intl";
import moment from 'moment';
import { Alert } from "react-bootstrap";
import { withRouter } from '../../../shared/hoc/withRouter';
import NavigationBlocker from '../../../partials/Tools/NavigationBlocker';

const isFinancialLimitEnabled = process.env.REACT_APP_FINANCIAL_LIMIT_ENABLED === "true";
const isOpenliEnabled = process.env.REACT_APP_OPENLI_ENABLED === "true";
const isPowerBIMenusEnabled = process.env.REACT_APP_POWERBI_ENABLED === "true";

let myFunc = {
  getNextSiblingBySelector(elem, selector) {
    var sibling = elem.nextElementSibling;
    if (!selector) return sibling;
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }
  }
};
class UserOverview extends React.Component {
  constructor(props) {
    super(props);
    this.currencyCodes = GetCurrencyCodes();
    this.updateUserFormConfig = {
      buttonResource: "GENERAL.FORMS.BUTTON_UPDATE",
      fields: [
        { resource: 'COMPANY.USER.FIELD.EMAIL', dataField: 'email', editorOptions: { readOnly: true }/*, emailId: { id:'#emailAddress'}*/ },
        { resource: 'COMPANY.USER.FIELD.NAME', dataField: 'name', editorOptions: { readOnly: true }/*, fullname: { id:'#fullName'} */ },
        { resource: "COMPANY.USER.FIELD.JOB_TITLE", dataField: "jobtitle" , required: false},
        { resource: 'COMPANY.USER.FIELD.TELEPHONE', dataField: 'telephone', validation: { type: 'pattern', pattern: /^[0-9+]*$/, warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_PHONE_NUMBER" }) }, required: false},
        // { label: 'Password', dataField: 'password', editorOptions: { mode: 'password' }, validationPattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/, validationWarning: 'Password must be minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character!' },
        { resource: 'COMPANY.USER.FIELD.COUNTRY', dataField: 'country', editorType: 'dxSelectBox', editorOptions: { readOnly: true, dataSource: countries, defaultValue: 'United Kingdom' } },
        { resource: 'COMPANY.USER.FIELD.TYPE', dataField: 'accounttype', editorOptions: { readOnly: true } },
      ]
    };
    if (isFinancialLimitEnabled) {
      this.updateUserFormConfig.fields.push(
        { resource: 'COMPANY.USER.FIELD.LIMIT', dataField: 'userlimit', editorType: 'dxNumberBox', required: false, editorOptions: { readOnly: true } },
        { resource: 'CONTRACT.FIELD.CURRENCY', dataField: 'userlimitcurrency', required: false, editorOptions: { dataSource: this.currencyCodes, valueExpr: 'code', displayExpr: 'name', defaultValue: 'GBP', readOnly: true } }
      )
    }
    if (isPowerBIMenusEnabled) {
      this.updateUserFormConfig.fields.push(
        { label: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.POWERBI_MENUS" }), dataField: 'allowpowerbimenus', editorType: 'dxSwitch', editorOptions: { readOnly: true, switchedOffText: props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }) } }
      )
    }
    this.updateUserFormConfig.fields.push(
      { note: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.PREFERENCES" }) },
      { resource: 'COMPANY.USER.FIELD.DISABLE_EMAIL', dataField: 'disableglobalemailnotifications', editorType: 'dxSwitch', editorOptions: { switchedOffText: "No", switchedOnText: "Yes", hint: this.props.intl.formatMessage({ id: "COMPANY.USER.HINTS.DISABLE_EMAILS" })} },
      { resource: 'COMPANY.USER.FIELD.OPTOUT_EMAIL_NOTIFICATIONS', dataField: 'optoutemailnotifications', editorType: 'dxSwitch', editorOptions: { switchedOffText: "No", switchedOnText: "Yes" } }
    )
    if (isOpenliEnabled) {
      this.updateUserFormConfig.fields.push(
        { rendered: this.addSignupContainer() }
      )
    }
  }
  state = {
    userProfile: null,
    termsOfUseConsent: false,
    marketingConsent: false
  }

  addSignupContainer = () => (
    // <form>
    <div style={{ marginTop: '15px', color: "#000000", fontSize: "14px" }} id="signupContainer"></div>
    // </form>
  )

  hideSignupContainer = () => {
    if (isOpenliEnabled) {
      if (this.state.userProfile.termsofuseconsent) {
        var element = document.getElementById("signupContainer");
        element.style.display = "none";
      }
    }
  }

  hideCookie = () => {
    var cookieConsent = document.querySelector("div.legalmonster-cleanslate.lm-base-root.lm-modal-root");

    if (cookieConsent != null) {
      cookieConsent.remove();
    }
  }

  consent = () => {
    var consents = document.querySelectorAll('.lm-signup input[type="checkbox"]');
    const self = this;
    for (const consent of consents) {
      consent.addEventListener("click", function () {
        var consentText = myFunc.getNextSiblingBySelector(consent, '.lm-consent-text');
        //console.log(`${consent.checked} := ${consentText.innerText}`);
        if (consentText.innerText.includes('I accept Built Intelligence')) {
          self.setState({ termsOfUseConsent: consent.checked });
        } else {
          self.setState({ marketingConsent: consent.checked });
        }
      });
    }
  }

  //Showing Marketing consent varies country by country, so if the widget has just one checkbox (terms of use), we directly set marketing consent true
  checkIfMarketingConsentExists = () => {
    if (isOpenliEnabled) {
      var consentsCount = document.querySelectorAll('.lm-consentgroup input[type="checkbox"]');

      if (consentsCount < 2) {
        return true;
      } else {
        return false;
      }
    }
  }

  showConsentWidget = () => {
    if (isOpenliEnabled) {
      const user = this.state.userProfile;
      //return !user.termsofuseconsent && moment(addDays(new Date(user.dateredirectedtoconsent), Number(process.env.REACT_APP_OPENLI_DATE_REDIRECTING))).toDate() < new Date();
      return !user.termsofuseconsent;
    }
  }

  consentWidget = () => {
    //const showConsentWidget = !this.props.userProfile.termsofuseconsent || !this.props.userProfile.marketingconsent;

    if (this.showConsentWidget()) {
      this.setRedirectedToConsentDate();
      this.appendOpenliWidget();
      //this.consent();
      setTimeout(() => this.consent(), 2000);
      //setTimeout(() => this.checkCheckboxIfAlreadyConsented(), 2000);
    }
  }

  componentDidMount() {
    this.setState({ userProfile: { ...this.props.userProfile } }, () => this.consentWidget());
    if ((this.props.location.state || {}).redirectedForConsent) {
      if (this.props.userProfile.consentdocumentrevised) {
        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_CONSENTUPDATE' }), { variant: "warning" });
      } else {
        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_CONSENT' }), { variant: "warning" });
      }
    }
  }

  componentWillUnmount() {
    if (isOpenliEnabled) {
      this.hideCookie();
    }
  }

  setRedirectedToConsentDate = () => {
    if ((this.props.location.state || {}).redirectedForConsent) {
      userCrud.updateRedirectedToConsentDate()
        .then(() => {
          this.props.refreshProfile();
        })
        .catch(err => {
          this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        });
    }
  }

  // checkCheckboxIfAlreadyConsented = () => {
  //   var consents = document.querySelectorAll('.lm-consentgroup input[type="checkbox"]');

  //   for (const consent of consents) {
  //     var consentText = myFunc.getNextSiblingBySelector(consent, '.lm-consent-text');
  //     if (consentText.innerText.includes('I accept Built Intelligence') && this.props.userProfile.termsofuseconsent) {
  //       consent.setAttribute("checked", "checked");
  //       consent.setAttribute("aria-required", "true");
  //     } else {
  //       if (this.props.userProfile.marketingconsent) {
  //         consent.setAttribute("checked", "checked");
  //         consent.setAttribute("aria-required", "true");
  //       }
  //     }
  //   }
  // }

  appendOpenliWidget = () => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "/js/OpenLI.js";
    document.body.appendChild(script);
  }

  submitHandler = (userProfile, setSubmitting) => {
    userProfile.fullname = userProfile.name;
    userProfile.disableglobalemailnotifications = userProfile.disableglobalemailnotifications ? 1 : 0;
    userProfile.termsofuseconsent = this.props.userProfile.termsofuseconsent ? true : this.state.termsOfUseConsent;
    userProfile.marketingconsent = this.checkIfMarketingConsentExists() || this.props.userProfile.marketingconsent ? true : this.state.marketingConsent;
    
    if (isOpenliEnabled) {
      userProfile.dateredirectedtoconsentdisplay = moment().format('DD/MM/YYYY');
    }

    userCrud.updateUser(userProfile)
      .then(() => {
        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: "success" });
        setSubmitting(false);
        this.setState({ userProfile: userProfile }, () => this.hideSignupContainer());
        this.props.refreshProfile();
      })
      .catch(err => {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        setSubmitting(false);
      });
  }

  render() {
    if (!this.props.userProfile || !this.state.userProfile) {
      return (
        <PortletSpinner />
      );
    }
    // unstable_usePrompt({
    //   when: isOpenliEnabled && !this.state.userProfile.termsofuseconsent,
    //   message: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.LEAVE_WITHOUT_GIVING_CONSENT" })
    // });
    return (
      <>
        {/* <Helmet>
          <script src="/js/OpenLI.js" async></script>
        </Helmet> */}
        <NavigationBlocker when={(isOpenliEnabled && !this.state.userProfile.termsofuseconsent)} message={this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.LEAVE_WITHOUT_GIVING_CONSENT" })} />
        <Portlet>
          <PortletHeader resource="USER.PREFERENCE.TITLE" />
          <PortletBody style={{ maxWidth: 800 }}>
            {this.showConsentWidget() && (
              this.state.userProfile.consentdocumentrevised ?
                <Alert variant="warning">
                  <p>
                    <FormattedMessage id="GENERAL.FORMS.WARNING_CONSENTUPDATE" />
                  </p>
                </Alert> :
                <Alert variant="warning">
                  <p>
                    <FormattedMessage id="GENERAL.FORMS.WARNING_CONSENT" />
                  </p>
                </Alert>
            )}
            <BiDynamicForm openLi={this.showConsentWidget() ? true : false} item={this.state.userProfile} config={this.updateUserFormConfig} onSubmitHandler={this.submitHandler} />
          </PortletBody>
        </Portlet>
      </>
    );
  }
}

const mapStateToProps = store => ({
  userProfile: store.auth.userProfile
});

const mapDispatchToProps = {
  refreshContractUsers: contractDuck.actions.refreshUsers,
  refreshProfile: authDuck.actions.refreshProfile
}

export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(UserOverview))));
