import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import BiDynamicForm from "../../../../partials/layout/BiDynamicForm";
import { statusTypes } from "../../../../shared/lookup";
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";
class NonConformanceAction extends Component {
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  onSubmitUpdate = (defect, setSubmitting) => {
    this.props.onSubmitUpdate(defect, setSubmitting);
  };
  tabUpdate = () => {
    const editorFormConfig = {
      buttonResource: "GENERAL.FORMS.BUTTON_UPDATE",
      fields: [
        {
          resource: "GENERAL.FORMS.FIELD.STATUS",
          dataField: "replystatus",
          editorType: "dxSelectBox",
          editorOptions: { dataSource: statusTypes },
        },
        { resource: "GENERAL.FORMS.FIELD.ACTION_OWNER", dataField: "replyactiondetail" },
        {
          resource: "GENERAL.FORMS.FIELD.ACTION",
          dataField: "replycomment",
          editorType: "dxHtmlEditor",
        },
      ],
    };
    return (
      <div style={{ margin: 20, maxWidth: 800 }}>
        <BiDynamicForm
          item={this.props.notification}
          config={editorFormConfig}
          onSubmitHandler={this.onSubmitUpdate} />
      </div>
    );
  }
  renderInfoView = () => {
    return (
      <>
        {this.props.notification.replystatus === "Closed" && (
          <Alert variant="success" style={{ display: "block", margin: 20 }}>
            <Alert.Heading><FormattedMessage id="CONTRACT.WF.CONFORMANCE.LABEL.CLOSED" /></Alert.Heading>
            <hr />
            <p>
              <FormattedMessage id="CONTRACT.WF.DEFECTS.WARNING.CLOSED" values={{ ID: this.props.notification.referenceid, TIME_STAMP: this.props.notification.inspectiondate ? moment(this.props.notification.inspectiondate).format('L') : '' }} />

            </p>
          </Alert>
        )}
        <Alert variant="secondary" style={{ display: "block", margin: 20 }}>
          <Alert.Heading><FormattedMessage id="CONTRACT.WF.CONFORMANCE.LABEL.ACTIONS" /></Alert.Heading>
          <hr />
          <p>
            <strong><FormattedMessage id="CONTRACT.HEALTH.FIELD.OWNER" />: </strong> {this.props.notification.replyaction}
            <br />
            <strong><FormattedMessage id="GENERAL.FORMS.FIELD.SOLUTION" />: </strong> <br />
          </p>
          {this.props.notification.replycomment
            ? HTMLReactParser(this.props.notification.replycomment)
            : null}
        </Alert>
      </>
    );
  };
  render() {
    if (
      this.props.notification.replystatus === "Closed" ||
      !this.props.workflowItem.userFilters.actionTab.actionAllowed
    ) {
      return this.renderInfoView();
    }

    return this.tabUpdate();
  }
}

export default injectIntl(NonConformanceAction);
