import axios from '../shared/axios';

export function getRelationships(referenceid, referencetype, contractid) {
    return axios.get(`/api/Relationship/GetRelationships?referenceid=${referenceid}&referencetype=${referencetype}&contractid=${contractid}`);
}

export function saveRelationships(contractid, formData) {
    return axios.post('/api/Relationship/SaveRelationships', formData, { params: { contractid } });
}

export function removeRelationships(formData) {
    return axios.post('/api/Relationship/RemoveRelationships', formData);
}

export function getRelationItemsList(source, companyid, projectid, contractid, type) {
    let str = `/api/Relationship/RelationItemsList?sourcetable=${source}&companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&type=`;
    return axios.post(type != null ? str.concat(type) : str);
}