/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import { connect } from "react-redux";
import { Validator, CustomRule, } from 'devextreme-react/validator';
import { addYears } from 'date-fns';
import moment from 'moment';
import { injectIntl } from "react-intl";

class WarningCustomForm extends React.Component {
    constructor(props) {
        super(props);
        this.createDefaultSubject = (((props.location || {}).state || {}).itemCustomProps || {}).subject;
        this.createDefaultAsset = (((props.location || {}).state || {}).itemCustomProps || {}).assetid;
        this.riskOptions = [
            { value: "Very Low", effect: 1, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.Very Low' }) },
            { value: "Low", effect: 2, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.Low' }) },
            { value: "Medium", effect: 3, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.Medium' }) },
            { value: "High", effect: 4, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.High' }) },
            { value: "Very High", effect: 5, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.Very High' }) },
        ];

        this.riskTypes = props.riskTypes
            .map(riskItem => ({ ...riskItem, validatorRef: React.createRef() }));



        this.warningFormConfig = {
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'title', editorOptions: { defaultValue: this.createDefaultSubject } },
                { resource: "CONTRACT.WF.EW.FIELD.BECAME_AWARE", dataField: 'risk', editorType: 'dxDateBox', editorOptions: { calendarOptions: { max: new Date() } }, validation: { type: 'range', max: new Date(), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE_FUTURE" })} },
                { label: props.resources.RiskItemsLabel, render: this.renderCheckBoxItem },
                { label: props.resources.DescriptionLabel, dataField: 'description', editorType: 'dxHtmlEditor' },
                {
                    resource: "CONTRACT.WF.EW.LABEL.RISK_MANAGEMENT",
                    fields: [
                        { label: props.resources.MeetingRequiredLabel, dataField: 'meetingrequired', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.meetingOptions, valueExpr: 'field', displayExpr: 'display' } },
                        { resource: "CONTRACT.WF.EW.FIELD.PROPOSED_LOC", dataField: 'meetinglocation', visible: (obj) => obj.meetingrequired === 2 },
                        { resource: "CONTRACT.WF.EW.FIELD.PROPOSED_DATE", dataField: 'meetingdate', visible: (obj) => obj.meetingrequired === 2, editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: new Date() } }, validation: { type: 'range', min: moment().subtract(1, 'days'), max: addYears(new Date(), 10), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE_RANGE" })} },
                        { resource: "CONTRACT.WF.EW.FIELD.PROPOSED_TIME", dataField: 'meetingtime', visible: (obj) => obj.meetingrequired === 2, editorType: 'dxDateBox', editorOptions: { pickerType: 'native', type: 'time', placeholder: '--:--', invalidDateMessage: this.props.intl.formatMessage({ id: "CONTRACT.WF.PROPOSED_TIME" })} },
                        { resource: "CONTRACT.WF.EW.FIELD.PROPOSED_ATTENDEE", dataField: 'meetingattendees', visible: (obj) => obj.meetingrequired === 2, editorType: 'dxTextArea', editorOptions: { pickerType: 'native', type: 'time' } },

                    ]
                },
                {
                    label: '',
                    fields: [
                        { resource: "CONTRACT.WF.EW.FIELD.LIKELIHOOD", dataField: 'likelihood', editorType: 'dxSelectBox', editorOptions: { dataSource: this.riskOptions, valueExpr: 'value', displayExpr: 'display' } },
                        { resource: "CONTRACT.WF.EW.FIELD.SEVERITY", dataField: 'severity', editorType: 'dxSelectBox', editorOptions: { dataSource: this.riskOptions, valueExpr: 'value', displayExpr: 'display' } },
                        { resource: "CONTRACT.WF.EW.FIELD.PRIORITY", dataField: 'reference', required: false, value: this.calculateRiskPriority, editorType: 'dxSelectBox', editorOptions: { dataSource: this.riskOptions, valueExpr: 'value', displayExpr: 'display', readOnly: true } },

                    ]
                }
            ]
        };
        let initialItem = null;
        let initialRiskPriority = '';

        if (props.item) {
            if (props.item.likelihood && props.item.severity) {
                initialRiskPriority = this.calculateRiskPriority(props.item);
            }
            initialItem = { ...props.item }
            this.riskTypes.forEach(
                riskType => {
                    var val = initialItem[riskType.field];
                    if (val === 0) { initialItem[riskType.field] = false; }
                    else if (val === 1) { initialItem[riskType.field] = true; }
                    else if (val === null || typeof val === 'undefined') { initialItem[riskType.field] = false; }
                }
            )
        }
        else {
            initialItem = this.riskTypes.reduce((obj, riskType) => ({ ...obj, [riskType.field]: false }), {});
            initialItem.title = this.createDefaultSubject;
            initialItem.assetid = this.createDefaultAsset
        }
        initialItem.reference = initialRiskPriority;

        this.initialItem = initialItem;
        this.state = {
            warning: { ...initialItem },
        };
    }

    renderCheckBoxItem = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>

                {
                    this.riskTypes.map((riskItem, index) =>
                    (
                        <CheckBox
                            elementAttr={{ id: riskItem.field }}
                            key={index}
                            style={{ marginTop: 7, marginBottom: 4 }}
                            defaultValue={this.state.warning[riskItem.field] ? this.state.warning[riskItem.field] : false}
                            onValueChanged={(args) => this.onCheckboxValueChanged(riskItem.field, args.value)}
                            text={riskItem.display} >
                            <Validator ref={riskItem.validatorRef}>
                                <CustomRule
                                    type="custom"
                                    validationCallback={(e) => {
                                        if (e.value === true) { return true; }
                                        const validationresult = this.riskTypes.some(type => this.state.warning[type.field] === true && type.field !== riskItem.field);
                                        return validationresult;
                                    }}
                                    message={this.props.intl.formatMessage({ id: 'CONTRACT.WF.EW.WARNING.OPTION_REQUIRED' })}
                                />
                            </Validator>
                        </CheckBox>
                    ))
                }

            </div>
        );
    }
    submitHandler = (warning, setSubmitting) => {
        const newWarning = { ...this.state.warning }
        // newWarning.severity = this.riskOptions.find(itm => itm.value === newWarning.severity).display;
        // newWarning.likelihood = this.riskOptions.find(itm => itm.value === newWarning.likelihood).display;
        this.props.onSubmitHandler(newWarning, setSubmitting);
    }

    calculateRiskPriority = (obj) => {
        if (typeof obj.severity !== 'undefined' && obj.severity !== null && typeof obj.likelihood !== 'undefined' && obj.likelihood !== null) {
            const riskPoint = this.riskOptions.find(itm => itm.value === obj.severity).effect * this.riskOptions.find(itm => itm.value === obj.likelihood).effect;

            if (riskPoint >= 0 && riskPoint <= 4) {
                return 'Low';
            } else if (riskPoint >= 5 && riskPoint <= 10) {
                return 'Medium';
            } else if (riskPoint >= 11 && riskPoint <= 25) {
                return 'High';
            }
            return '';
        }
        else return null;

    }

    onCheckboxValueChanged = (field, value) => {
        const anyWasSelected = this.riskTypes.some(type => this.state.warning[type.field] === true);
        this.state.warning[field] = value;

        //FIRST ITEM SELECTED
        if (anyWasSelected === false && value === true) {
            //CLEAR ANY VALIDATION ERRORS ON OTHER BOXES
            this.riskTypes.forEach(itm => {
                if (itm.field !== field) {
                    itm.validatorRef.current.instance.reset()
                }
            });
        }

        if (this.props.valueChangeHandler) {
            const anySelected = this.riskTypes.some(type => this.state.warning[type.field] === true);
            this.props.valueChangeHandler(field, value, anySelected, true);
        }
    }
    onValueChanged = (field, value, isDxFormValid, additionalData) => {
        this.state.warning[field] = value;
        if (this.props.valueChangeHandler) {
            this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);
        }
    }

    render() {
        return (
            <BiDynamicForm
                item={this.initialItem}
                config={this.warningFormConfig}
                valueChangeHandler={this.onValueChanged}
                onSubmitHandler={this.props.onSubmitHandler ? this.submitHandler : null}
                customFields={this.props.customFields}
                assetFieldsEnabled={this.props.assetFieldsEnabled}
                assetid={this.createDefaultAsset}
            />
        );
    }
}


const mapStateToProps = store => ({
    // contractTemplate: store.contract.template,
    // contract: store.contract.contract
});
export default injectIntl(connect(mapStateToProps)(WarningCustomForm));
