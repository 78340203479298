import axios from '../shared/axios';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function saveRequestForQuote(data) {
    //axios.post(`/api/RequestForQuote/SaveRequestForQuote`, data);
    return new Promise((resolve, reject) => {
        axios.post(`/api/RequestForQuote/SaveRequestForQuote`, data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                //const compensationType = data.compensationsource;
                //store.dispatch(contractDuck.actions.fetchRequestForQuote(compensationType, true));
                resolve(result);
            })
            .catch(error => reject(error));
    });
}

export function getRequestForQuote(requestforquoteid) {
    return axios.get(`/api/RequestForQuote/GetRequestForQuote?requestforquoteid=${requestforquoteid}`);
}

export function getContractRequestForQuotes(companyid, projectid, contractid, accessibleWfIds) {
    return axios.post(`/api/RequestForQuote/RegisterList`, { accessibleWfIds }, { params: { companyid, projectid, contractid } });
}

export function deleteRequestForQuoteDraft(requestforquoteid) {
    return new Promise((resolve, reject) => {
    axios.post(`api/RequestForQuote/DeleteDraft?requestforquoteid=${requestforquoteid}`)
    .then(result => {
        store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
        resolve(result);
    })
    .catch(error => reject(error));
    })
}