import React from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { withRouter } from '../shared/hoc/withRouter';
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import { locale, loadMessages } from "devextreme/localization";
import nlMessages from "devextreme/localization/messages/nl.json";
import moment from "moment";
import "moment/locale/en-gb";
import SystemMessageDialog from "../../_metronic/layout/aside/SystemMessageDialog";
import AppRoutesWT from './AppRoutesWT';
import AppRoutesWOT from './AppRoutesWOT';
import ZendeskWidget from "../partials/Tools/ZendeskWidget";
import ResetPassword from "../pages/auth/ResetPassword";

const appcuesEnabled = process.env.REACT_APP_APPCUES_ENABLED === "true";
class AppRoutes extends React.Component {

  constructor(props) {
    super(props);
    // this.useAuth0 = !!process.env.REACT_APP_SSO_METHOD;
    moment.locale(props.lang === "en" ? "en-gb" : props.lang);
    if (props.lang !== "en") {
      if (props.lang === "nl") {
        loadMessages(nlMessages);
      }
      locale("nl");
    }
  }

  componentDidUpdate(prevProps) {
    if (appcuesEnabled && window.Appcues) {
      const { location: { pathname } } = this.props;
      const previousLocation = prevProps.location.pathname;

      if (pathname !== previousLocation) {
        window.Appcues.page();
      }
    }
  }

  render() {
    const {
      isAuthorized,
      menuConfig,
      location,
      Layout,
      navigate
    } = this.props;

    //DONT NEED THIS CAPITAL LETTER CHECK ANYMORE AFTER ReactRouterv6 UPGRADE,
    //IT WILL AUTO LOWERCASE THE URLS
    // if(/[A-Z]/.test(location.pathname)){
    //   return <Navigate replace to={`${location.pathname.toLowerCase()}`} />
    // }

    return (
      <LayoutContextProvider navigate={navigate} location={location} menuConfig={menuConfig}>
        <Routes caseSensitive>
          <Route path="/error/*" element={<ErrorsPage />} sensitive />
          <Route path="/logout" element={<LogoutPage />} sensitive />
          <Route path="/auth/reset-password" element={<ResetPassword />} sensitive />

          {isAuthorized ? (
            <Route path="*" element={<AppRoutesWT Layout={Layout} />} />
          ) : (
            <Route path="*" element={<AppRoutesWOT />} />
          )}
        </Routes>
        <SystemMessageDialog />
        <ZendeskWidget location = {location}/>
      </LayoutContextProvider>
    );
  }
}

const mapStateToProps = (store) => ({
  lang: store.i18n.lang,
  // auth: store.auth,
  // systemMessage: store.application.systemMessage,
  menuConfig: store.builder.menuConfig,
  isAuthorized: store.auth.authToken.token && store.auth.csrfToken.token && store.auth.userProfile,
});

const mapDispatchToProps = {};

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(AppRoutes)));
