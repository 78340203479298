/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { injectIntl } from "react-intl";
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import BiDynamicForm from '../../../../../partials/layout/BiDynamicForm';
import * as projectsCrud from '../../../../../crud/projects.crud';
import { withSnackbar } from 'notistack';
export const RESPONSE = {
    OK: 'OK',
    CANCEL: 'CANCEL',
};

class ChangeCompanyProjectModal extends React.Component {
    constructor(props) {
        super(props);
        this.validatorRef = React.createRef();
        this.validatorName = 'FormModalValidator';
        this.formRef = React.createRef();
        this.state = {
            popupRendered: false,//set true when first popup shown, popup will not be rendered until then
            item: {},
            saveButtonEnabled: false,
            optProjects: null
        }
    }

    componentDidUpdate(revProps, prevState) {
        //Modal closed
        if (revProps.visible === true && this.props.visible === false) {
            this.setState({ item: {}, optProjects: null });
        }
        //Modal opened
        if (revProps.visible === false && this.props.visible === true) {
            this.setState({ popupRendered: true, saveButtonEnabled: true });
        }
    }
    onClose = () => {
        if (this.props.visible) {
            this.props.onDialogResult();
        }
    }
    saveItem = () => {
        if (!this.validateForm()) {
            return;
        }

        this.setState({ saveButtonEnabled: false }, () => {
            this.props.onDialogResult(RESPONSE.OK, { ...this.state.item });
        });

    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        return validationResult.isValid;
    }
    onValueChanged = (field, value) => {
        if (this.state.item[field] !== value) {
            this.state.item[field] = value;
            if (field === 'companyid') {
                const optProjects = this.getProjectOptions2(Number(value));
                this.setState({ optProjects });
            }
        }
    }
    getProjectOptions2 = (companyid) => {
        const datasource = {
            loadMode: 'raw',
            cacheRawData: true,
            load: () => {
                var promise = new Promise((resolve, reject) => {
                    projectsCrud.getCutDownProjectsList(companyid)
                        .then(response => {
                            let projectList = [];
                            if (response.data && response.data.length > 0) {
                                projectList = response.data.map(project => ({ projectid: project.projectid, display: (project.projectcode ? `[${project.projectcode}] - ` : "") + `${project.name}` }));
                            }
                            else {
                                this.props.enqueueSnackbar('No project found for selected the company', { variant: 'warning', });
                            }
                            resolve(projectList);
                        })
                        .catch(err => {
                            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                            reject(err);
                        });
                });
                return promise;
            }
        }
        return datasource;
    }
    generateForm = () => {
        const dialogConfig = {
            fields: [
                { resource: 'CONTRACT.FIELD.COMPANY', dataField: 'companyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.companies, valueExpr: 'companyid', displayExpr: 'name' } },
                { resource: 'CONTRACT.FIELD.PROJECT', dataField: 'projectid', dependOn: 'companyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optProjects, valueExpr: 'projectid', displayExpr: 'display' } },
            ]
        };
        return (
            <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                <BiDynamicForm
                    item={this.props.item}
                    config={dialogConfig}
                    valueChangeHandler={this.onValueChanged}
                />
                <div>
                    <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                </div>
            </ValidationGroup>
        )
    }
    render() {
        if (!this.state.popupRendered) {
            return null;
        }
        return (
            <Popup
                visible={this.props.visible}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showTitle={true}
                title={this.props.title}
                width={600}
                height='auto'>
                {this.props.visible && (
                    this.generateForm()
                )
                }
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_CANCEL' }), onClick: this.onClose }}>
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: this.props.saveButtonText, disabled: !this.state.saveButtonEnabled, stylingMode: "contained", type: 'success', onClick: this.saveItem }}>
                </ToolbarItem>
            </Popup >
        );
    }
}

export default withSnackbar(injectIntl(ChangeCompanyProjectModal));