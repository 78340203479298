import React from 'react';
//import { getLocalUser } from '../../../store/ducks/contract.duck';
//import moment from 'moment';
//import { isIE } from 'react-device-detect'

class BaseDocument extends React.Component {
    //constructor(props, userid) {
    //    super(props);
    //}
}

export default BaseDocument;
