import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotifyList from './List';
import NotifyCreate from './Create';
import NotifyDetail from './Detail';

class ActionRouter extends React.Component {
    constructor(props) {
        super(props);

        if (props.mode === 'PM') {
            this.communicateToUser = props.globalResources.SUPPLIER_NAME;
            this.notificationType = '14.2PM';
            this.documentType = 'Document14.2PM';
            this.delegationUserParty = 'PM';
        }
        else if (props.mode === 'SUPERVISOR') {
            this.communicateToUser = props.globalResources.SUPPLIER_NAME;
            this.notificationType = '14.2SUP';
            this.documentType = 'Document14.2SUP';
            this.delegationUserParty = 'Supervisor';
        }
        else if (props.mode === 'SUPPLIER') {
            this.communicateToUser = props.globalResources.PM_NAME;
            this.notificationType = 'CONDEL';
            this.documentType = 'DocumentCONDEL';
            this.delegationUserParty = 'Contractor';
        }

        this.notifyingParty = props.workflowItem.notifyingParty;


        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;

        try {
            this.allowCrossPartyDelegationsIsEnabled = props.placeholders.find(ph => ph.replacementKey === 'AllowCrossPartyDelegations')
                .values.find(phItem => phItem.field === 'AllowCrossPartyDelegations').isActive;
        }
        catch (err) {
            console.log('AllowCrossPartyDelegations placeholder not available!');
            this.allowCrossPartyDelegationsIsEnabled = false;
        }

    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;
        return (
            <>
                <Routes caseSensitive>
                    <Route
                        path={workflowItem.config.paths.list}
                        exact
                        element={<NotifyList
                            {...this.props}
                            
                            title={this.notificationName}
                            abbreviation={this.abbreviation}
                            notificationType={this.notificationType}
                            allowCrossPartyDelegationsIsEnabled={this.allowCrossPartyDelegationsIsEnabled}
                        />}

                    />
                    <Route
                        path={workflowItem.config.paths.create}
                        exact
                        element={
                            workflowItem.userFilters.mainNotice.createAllowed ? (
                                <NotifyCreate
                                    {...this.props}
                                    allowCrossPartyDelegationsIsEnabled={this.allowCrossPartyDelegationsIsEnabled}
                                    
                                    title={this.notificationName}
                                    communicateToUser={this.communicateToUser}
                                    delegationUserParty={this.delegationUserParty}
                                    notificationType={this.notificationType}
                                />) : (
                                <Navigate replace to={workflowItem.paths.defaultPath} />
                            )
                        }
                    />
                    <Route
                        path={'/:notificationid/*'}
                        exact
                        element={<NotifyDetail
                            {...this.props}
                            allowCrossPartyDelegationsIsEnabled={this.allowCrossPartyDelegationsIsEnabled}
                            
                            title={this.notificationName}
                            abbreviation={this.abbreviation}
                            notificationType={this.notificationType}
                            documentType={this.documentType}
                            communicateToUser={this.communicateToUser}
                            delegationUserParty={this.delegationUserParty}
                            notifyUserFilter={workflowItem.notifyUserFilter}
                        />}
                    />
                </Routes>
            </>
        );
    }
}


export default (ActionRouter);