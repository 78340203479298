import React from 'react';
import { connect } from "react-redux";
import PortletSpinner from '../../../partials/layout/PortletSpinner';
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import * as projectsDuck from '../../../store/ducks/projects.duck';
import DataGridBase from '../../../partials/layout/DataGridBase';
import { injectIntl } from "react-intl";
import { withRouter } from '../../../shared/hoc/withRouter';
import { GetAccessRights } from "../../../router/Protector/Protector";

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.canCreateNew = GetAccessRights("/admin/projects/new-project", this.props.userProfile).allowAccess;

  }

  componentDidMount() {
    this.props.loadProjects();
  }

  onTableSelectionChanged = ({ selectedRowsData }) => {
    if (selectedRowsData.length > 0) {
      const data = selectedRowsData[0];
      this.props.navigate(`/admin/projects/${data.projectid}/overview`);
    }
  }
  calculateProjectState = (rowData) => {
    if (rowData.deleted === 1) {
      return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.DELETED' });
    }
    else {
      switch (rowData.recordstatus) {
        case 1:
          return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.ACTIVE' });
        case 2:
          return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.COMPLETED' });
        case 3:
          return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.ARCHIVED' });
        default:
          return 'Unknown';
      }
    }
  }
  render() {
    if (!this.props.projects) {
      return (<PortletSpinner />);
    }
    return (
      <Portlet>
        <PortletBody>
          <DataGridBase
            version="2"
            title={this.props.intl.formatMessage({ id: "PROJECT.LIST.TITLE" })}
            createPath={this.canCreateNew ? "/admin/projects/new-project" : null}
            name={`Projects`}
            dataSource={this.props.projects}
            keyExpr="projectid"
            selection={{ mode: 'single' }}
            onSelectionChanged={this.onTableSelectionChanged}
            hoverStateEnabled={true}
            rowAlternationEnabled={false}
            columns={[
              { caption: this.props.intl.formatMessage({ id: "PROJECT.FIELD.PROJECT.ID" }), dataField: "projectid" },
              { caption: this.props.intl.formatMessage({ id: "PROJECT.FIELD.COMPANY" }), dataField: "company" },
              { caption: this.props.intl.formatMessage({ id: "PROJECT.FIELD.NAME" }), dataField: "name" },
              { caption: "State", dataField: "recordstatus", calculateCellValue: this.calculateProjectState },
              { caption: this.props.intl.formatMessage({ id: "PROJECT.FIELD.TYPE" }), dataField: "projecttype" },
              { caption: this.props.intl.formatMessage({ id: "PROJECT.FIELD.COUNTRY" }), dataField: "country" },
              { caption: this.props.intl.formatMessage({ id: "PROJECT.FIELD.CODE" }), dataField: "projectcode" },
            ]}
          />
        </PortletBody>
      </Portlet>
    );
  }
}

const mapStateToProps = store => ({
  projects: store.projects.projects,
  userProfile: store.auth.userProfile,
});

const mapDispatchToProps = {
  loadProjects: projectsDuck.actions.fetchStart
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Projects)));
