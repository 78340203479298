import React from 'react';
import { connect } from "react-redux";
import Document from '../../_CustomComponents/DocumentV2';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { withSnackbar } from 'notistack';


class NotifyDocumentPage extends BaseDocument {

    render() {
        return (
            <Document
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={true}
                printDivRef={this.props.printDivRef}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                onFilesCountChanged={this.props.onFilesCountChanged}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.notification.assetid}
            />
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
});

export default withSnackbar(connect(mapStateToProps)(NotifyDocumentPage));
