import axios from "../shared/axios";
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function getCompensationQuote(quotationid) {
  return axios.get(`/api/Quotation/GetQuotation?quotationid=${quotationid}`);
}
export function saveCompensationQuote(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/Quotation/SaveQuotation`, data)
      .then((result) => {
        store.dispatch({
          type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD,
        });
        resolve(result);
      })
      .catch((error) => reject(error));
  });
}
export function updateCompensationQuote(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/Quotation/Update`, data)
      .then((result) => {
        if (data.actioncode === 'COMMUNICATE' || data.actioncode === 'REPLY_SEND') {
          store.dispatch({
            type: contractDuck.actionTypes.CONTRACT_REFRESH_CONTRACT,
          });
        }
        store.dispatch({
          type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD,
        });
        resolve(result);
      })
      .catch((error) => reject(error));
  });
}
export function saveQuotationActivity(data) {
  return axios.post(`/api/Quotation/SaveQuotationActivity`, data);
}
export function getQuotationActivities(
  companyid,
  projectid,
  contractid,
  quotationid,
  actionType
) {
  return axios.post(
    `/api/Quotation/LoadQuotationActivitiesList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&quotationid=${quotationid}&actiontype=${actionType}`
  );
}

export function getQuotationOtherPriceChanges(
  companyid,
  projectid,
  contractid,
  quotationid,
  actionType
) {
  return axios.post(
    `/api/Quotation/LoadQuotationOtherPriceChangeList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&quotationid=${quotationid}&actiontype=${actionType}`
  );
}

export function getQuotes(companyid, projectid, contractid) {
  return axios.post(
    `/api/Quotation/LoadAllQuotationsList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}`
  );
}

export function getQuotesReduced(companyid, contractid, cenid) {
  return axios.post(`/api/Quotation/RegisterList`, null, { params: { companyid, contractid, compensationeventid:cenid } });
}

export function deleteQuoteDraft(quotationid) {
  return new Promise((resolve, reject) => {
  axios.post(`/api/Quotation/DeleteDraft?quotationid=${quotationid}`)
    .then(result => {
      store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
      resolve(result);
  })
  .catch(error => reject(error));
  })
}