/* eslint-disable import/no-anonymous-default-export */
import Workflows from "./Workflows";
import Placeholders from "./Placeholders";
export default {
    id: 107,
    title: 'Proposals',
    path: 'proposals',
    icon: "flaticon-security",
    workflows: Workflows,
    placeholders: Placeholders,
};