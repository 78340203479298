import axios from "../shared/axios";

export function getContractAssets(companyid, projectid, contractid) {
  return axios.get(
    `/api/Asset/GetAssets?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}`
  );
}

export function getAsset(assetid) {
  return axios.get(
    `/api/Asset/GetAsset?assetid=${assetid}`
  );
}