import React from 'react';
import * as fileCrud from '../../../../crud/file.crud';
import Document from '../../_CustomComponents/DocumentV2';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { withSnackbar } from 'notistack';

class ForecastDocument extends BaseDocument {


    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.forecastid, data, config)
    }



    render() {
        const documentIsFinal = this.props.notification.showtasknotification === 1;
        return (
            <Document
                key={documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.props.form}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                typeFieldEnabled={this.props.typeFieldEnabled}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.notification.assetid}
            />
        );
    }
}

export default withSnackbar(ForecastDocument);
