
import React from 'react';

// import { CircularProgress, Fade } from '@material-ui/core';
import { LoadIndicator } from 'devextreme-react/load-indicator';

export default function Spinner({ active, children }) {

    return (
        <div style={{width:'100%',height:'100%',display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
            {/* <Fade
                in={active}
                style={{
                    transitionDelay: active ? '400ms' : '0ms',
                }}
                unmountOnExit
            > */}
            {/* <CircularProgress /> */}
                <LoadIndicator height={60} width={60} />
            {/* </Fade> */}
        </div>
    );
}
