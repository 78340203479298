import React from "react";
import BaseDetailPage from "../../_CustomComponents/BaseDetailPage";
import ReactToPrint from "react-to-print";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import * as fileCrud from "../../../../crud/file.crud";
import * as activityCrud from "../../../../crud/activity.crud";
import * as paymentCertificateCrud from "../../../../crud/paymentCertificate.crud";
import axios from "axios";
import ActionModal from '../../_CustomComponents/ActionModal';
import PaymentDocument, { calculateSubTotalPreTaxV2, calculateTotal, } from "./Document";
import CostBreakdown from "./CostBreakdown";
import { Navigate } from "react-router-dom";
import { ValidationSummary, ValidationGroup } from "devextreme-react";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import moment from "moment";
import Relations from '../../_CustomComponents/Relation/Relations';
import { CBS_ContractTypes_Payment, GetCurrencyCodes } from '../../../../shared/lookup';
import PageToolbar from '../../_CustomComponents/PageToolbar';
import Common from '../../../../shared/common';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import EmailNotifications from "../../_CustomComponents/EmailNotifications";
const initialState = {
  notification: null,
  files: null,
  showModal: false,
  saving: false,
  isChanged: false,
  paymentRetentionList: null,
  relatedItems: null,
  relationCount: 0,
  postActionUrl: null,
  modalTitle: '',
  modalText: '',
  modalMode: '',
  relations: null
};

class PaymentDetailPage extends BaseDetailPage {
  constructor(props) {
    super(props);
    this.currencyCodes = GetCurrencyCodes();
    this.printNotificationDivRef = React.createRef();
    this.validatorRef = React.createRef();
    this.validatorName = "PaymentValidator";
    this.state = { ...initialState };
    this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
    this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
    this.wbsEnabled = (process.env.REACT_APP_COST_BREAKDOWN_ENABLED === 'true' && CBS_ContractTypes_Payment.includes(props.contract.contracttype));
  }
  componentDidMount() {
    this.fetchPaymentCertificate();
    this.fetchFiles();
    this.loadPaymentRetentionList();
  }

  componentDidUpdate(prevprops, prevSatate) {
    if (
      this.props.params.notificationid !==
      prevprops.params.notificationid
    ) {
      this.setState({ ...initialState }, () => {
        this.fetchPaymentCertificate();
        this.fetchFiles();
        this.loadPaymentRetentionList();
      });
    }
  }

  getMailDescription = () => {
    return "Assessment - " + moment(this.state.notification.assessmentdate).format('L');
  };
  fetchPaymentCertificate = async () => {
    try {
      await this.props.closeSnackbar("PersistentFormWarning");
      const { data: payment } = await paymentCertificateCrud.getPaymentCertificate(this.props.params.notificationid);
      if (
        payment.contractid !== this.props.contract.contractid ||
        payment.type !== this.props.notificationType
      ) {
        throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
      }
      if (payment.assessmentdate) {
        payment.assessmentdate = new Date(payment.assessmentdate);
      }
      if (payment.createdate) {
        payment.createdate = new Date(payment.createdate);
      }

      if (payment.paymentreceivedate) {
        payment.paymentreceivedate = new Date(payment.paymentreceivedate);
      }

      //HANDLE THE CASE CONTRACT CERTIFIED/APPLIED VALUE HAS BEEN UPDATED SINCE THIS NOTIFICATION DRAFTED
      if (payment.showtasknotification !== 1 && this.props.contract.totalcertified != null) {
        let expectedLastCertifiedAmount = null;
        if (payment.currency === this.props.contract.currency) {
          expectedLastCertifiedAmount = this.props.contract.totalcertified;
        }
        else {
          //DIFFERENT CURRENCY, WE NEED TO FETCH LastCertifiedAmount from API
          const { data: lasCertifiedAmountsArray } = await paymentCertificateCrud.getLastPaymentAmountPerCurrency(payment.contractid, ["Certificate"], [payment.currency]);
          const lastCertifiedForthisCurrency = lasCertifiedAmountsArray.find(x => x.currencycode === payment.currency)?.amount;
          if (lastCertifiedForthisCurrency != null || lastCertifiedForthisCurrency !== undefined) {
            expectedLastCertifiedAmount = lastCertifiedForthisCurrency;
          }
        }

        if (expectedLastCertifiedAmount != null &&
          expectedLastCertifiedAmount !== undefined &&
          payment.lastpaymentcertificateamount !== expectedLastCertifiedAmount) {
          //PROBLEM
          console.log("EXPECTED:", expectedLastCertifiedAmount);
          console.log("FOUND:", payment.lastpaymentcertificateamount);

          payment.lastpaymentcertificateamount = expectedLastCertifiedAmount;
          payment.othercertifiedpretax = calculateSubTotalPreTaxV2(payment);
          payment.othercertfiedamount = calculateTotal(payment);
          this.props.enqueueSnackbar(
            this.props.intl.formatMessage({ id: 'CONTRACT.WF.PAYMENT.WARNING.RECALCULATED' }),
            { variant: "warning", key: "PersistentFormWarning", persist: true }
          );
        }

      }
      this.setState({
        notification: payment,
        saving: false,
        isChanged: false,
        relations: payment.relations,
        relationCount: Common.GetRelationCount(payment, this.props.accessibleWfIds)
      }, () => { this.props.setReference(this.state.notification.referenceid); });
    }
    catch (err) {
      if (!axios.isCancel(err)) {
        this.props.enqueueSnackbar(err.toString(), { variant: "error" });
      }
    }
  };

  fetchFiles = async () => {
    try {
      const { companyid, projectid, contractid } = this.props.contract;
      const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
      const filesList = JSON.parse(data.aggregatelist)
      this.setState({ files: filesList });
      return filesList;
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      }
    }
  }
  printHandler = () => {
    this.printRef.handlePrint();
  };
  validateForm = (mode) => {
    const validationResult = this.validatorRef.current.instance.validate();

    if (!validationResult.isValid) {
      this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), {
        variant: "warning",
      });
    }

    let costBreakdownisValid = true;

    let sumIsValid = true;
    let attachmentValid = true;

    if (this.wbsEnabled && this.props.contract.contracttype.includes('Packaged')) {
      costBreakdownisValid = this.state.paymentRetentionList.length;
      if (!costBreakdownisValid) {
        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.WF.PAYMENT.WARNING.ADD_WBS' }), {
          variant: "warning",
        });
      } else {
        const sum = this.state.paymentRetentionList.reduce(
          (a, v) => (a = a + v.amount),
          0
        );

        if (sum.toFixed(2) !== this.state.notification.othercertfiedamount.toFixed(2)) {
          sumIsValid = false;

          this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.WF.PAYMENT.WARNING.WBS_AMOUNT' }), {
            variant: "warning",
          });
        }
      }
    }
    if (mode === 'COMMUNICATE' && this.props.mandateAttachment && this.state.fileCount === 0) {
      attachmentValid = false;
      this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.WF.PAYMENT.WARNING.ATTACHMENT' }), {
        variant: "warning",
      });
    }

    return (
      validationResult.isValid &&
      costBreakdownisValid &&
      sumIsValid &&
      attachmentValid &&
      !this.state.saving
    );
  };
  showModal = (mode, postActionNavigateObj) => {
    if (mode === 'COMMUNICATE') {
      if (this.validateForm(mode)) {
        this.setState({
          showModal: true,
          modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
          modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
          modalMode: mode,
          postActionUrl: postActionNavigateObj
        });
      }
    }
    else if (mode === 'SAVE_DRAFT') {
      if (this.validateForm(mode)) {
        this.setState({
          showModal: true,
          modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
          modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
          modalMode: mode,
        });
      }
    }
    else if (mode === 'DELETE_DRAFT') {
      this.setState({
        showModal: true,
        modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
        modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
        modalMode: mode,
      });
    }
  };
  onModalResponse = (response, params) => {
    this.setState({ showModal: false, saving: response === 'OK' }, async () => {
      try {
        if (response === 'OK') {


          if (this.state.modalMode === 'COMMUNICATE') {
            //const { companyid, projectid, contractid } = this.props.contract;
            const payment = { ...this.state.notification };
            payment.status = "Communicated";
            payment.showtasknotification = 1;
            const docBodyConfig = this.documentBodyConfig(payment, this.props.resources);
            const docConfig = await this.documentConfig(payment, docBodyConfig, this.props.contractUser.name, false, null, null, this.props.workflowItem.form_NotifyCompanyName);
            payment.pdfjsoncontent = JSON.stringify(docConfig);



            this.saveNotification(payment, null, this.state.modalMode);

          }
          else if (this.state.modalMode === 'SAVE_DRAFT') {
            const payment = { ...this.state.notification };
            payment.status = "Not Communicated";
            this.saveNotification(payment, null, this.state.modalMode, params ? params.userid : null);

          }
          else if (this.state.modalMode === 'DELETE_DRAFT') {
            const paymentcertificate = { ...this.state.notification };
            const paymentcertificateid = paymentcertificate.paymentcertificateid
            this.deletePaymentCertificateDraft(paymentcertificateid);

          }


        }
      } catch (err) {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        this.setState({ saving: false });
      }

    });
  };
  documentBodyConfig = (notification, resources) => {
    const config = [];
    if (this.props.configurableTypeFields) {
      const configurableFieldsValue = this.props.configurableTypeFields.find(ph => ph.value === notification.paymenttype)
      config.push(
        { resource: 'GENERAL.FIELD.APPLICATIONTYPE', value: configurableFieldsValue.display }
      );
    }
    const currency = this.formatCurrency();
    config.push(
      { label: resources.AssessmentDayLabel, value: notification.assessmentdate, },
      { label: resources.SummaryDetailsLabel, value: notification.summary, valueType: 'HTML', align: 'vertical' },
      { resource: "GENERAL.FORMS.CURRENCY", value: currency },
      { label: resources.CumulativePriceLabel, value: Common.FormatCurrencyNum(notification.othercumulativeprice, notification.currency), valueType: 'CURRENCY', currencyCode: notification.currency },
      { label: resources.PlusAmountsSupplierLabel, value: Common.FormatCurrencyNum(notification.otheramountpaid, notification.currency), valueType: 'CURRENCY', currencyCode: notification.currency },
      { resource: "CONTRACT.WF.PAYMENT.FIELD.SUB_TOTAL", value: Common.FormatCurrencyNum(notification.subtotalpay, notification.currency), valueType: 'CURRENCY', currencyCode: notification.currency },
      { label: resources.LessAmountsSupplierLabel, value: Common.FormatCurrencyNum(notification.subtotalretain, notification.currency), valueType: 'CURRENCY', currencyCode: notification.currency },
      { resource: "CONTRACT.WF.PAYMENT.FIELD.AMOUNT_DUE", value: Common.FormatCurrencyNum(notification.otheramountretain, notification.currency), valueType: 'CURRENCY', currencyCode: notification.currency },
      { label: resources.LessAmountsDueLabel, value: Common.FormatCurrencyNum(notification.lastpaymentcertificateamount, notification.currency), valueType: 'CURRENCY', currencyCode: notification.currency },
    );

    if (this.props.taxEnabled) {
      config.push(
        { resource: "CONTRACT.WF.PAYMENT.FIELD.SUB_TOTAL", value: Common.FormatCurrencyNum(notification.othercertifiedpretax, notification.currency), valueType: 'CURRENCY', currencyCode: notification.currency },
        { label: resources.TaxSupplierLabel, value: Common.FormatCurrencyNum(notification.othertaxamount, notification.currency), valueType: 'CURRENCY', currencyCode: notification.currency }
      );
    }

    const bottomLabel = this.props.notificationType === 'Application' ? resources.PaymentAppliedPeriodLabel : resources.PaymentCertifiedPeriodLabel;
    config.push(
      { label: bottomLabel, value: Common.FormatCurrencyNum(notification.othercertfiedamount, notification.currency), valueType: 'CURRENCY', currencyCode: notification.currency },
    );
    if (this.props.poNumberEnabled) {
      config.push(
        { resource: 'CONTRACT.FIELD.PO_NUMBER', value: notification.purchaseordernumber }
      );
    }

    return config;
  }
  deletePaymentCertificateDraft = async (paymentcertificateid) => {

    try {
      await paymentCertificateCrud.deletePaymentCertificateDraft(paymentcertificateid);
      this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
      this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

    } catch (err) {
      this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      this.setState({ saving: false });
    }
  }
  saveNotification = async (payment, contractSpecifics, action, notifyuserid) => {
    try {
      const postEvents = () => {
        this.fetchPaymentCertificate();
        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), {
          variant: "success",
        });
      };
      if (payment.assessmentdate && !payment.assessmentdatedisplay) {
        payment.assessmentdatedisplay = moment(payment.assessmentdate).format(
          "DD/MM/YYYY"
        );
      }


      payment.actioncode = action;
      payment.notifyuserlist = this.getNotifyUserListForUpdate(action, notifyuserid);
      Common.PropertyConversionsBeforeNoticeSaveRequest(payment, this.props.customFields, this.props.contractUsers);
      await paymentCertificateCrud.updatePaymentCertificate(payment);

      if (this.state.postActionUrl) {
        this.setState({ isChanged: false, saving: false,  navigateto: this.state.postActionUrl.pathname });
      } else {
        postEvents();
      }
    } catch (err) {
      this.props.enqueueSnackbar(err.toString(), { variant: "error" });
      this.setState({ saving: false });
    }
  };
  formatCurrency = () => {
    const cur = this.currencyCodes.find(currency => currency.code === this.state.notification.currency)?.name || this.state.notification.currency;
    return cur;
  }
  fieldChanged = (field, value, isDxFormValid, additionalData) => {
    //SILENT STATE CHANGE
    //WE DONT WANT COMPONENT RERENDER WİTH THIS
    if (field) {
      if (field === "assetid") {
        this.setState({
          notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
          isChanged: true
        });
      }
      else {
        this.state.notification[field] = value;
        if (!this.state.isChanged) {
          this.setState({ isChanged: true });
        }
      }
    }
  };

  renderToolbar = (documentIsFinal) => {
    const saveDisabled = (this.state.saving || this.state.showModal);
    return (
      <PageToolbar
        documentIsFinal={documentIsFinal}
        saveDisabled={saveDisabled}
        actions={{
          showModal: this.showModal,
          onPrint: this.printHandler
        }}
        workflowItem={this.props.workflowItem}
        match={this.props.match}
        customFieldValues={this.state.notification.customfieldvalues}
        referenceid={this.state.notification.referenceid}
        contract={this.props.contract}
        relationParams={{
          subject: this.getMailDescription(),
          assetid: this.state.notification.assetid
        }}
        notification={this.state.notification}
      />
    );
  };

  tabDocument = () => {
    return (
      <PaymentDocument
        {...this.props}
        printDivRef={this.printNotificationDivRef}
        fieldChanged={this.fieldChanged}
        notification={this.state.notification}
        files={this.state.files}

        onFilesCountChanged={(fCount) => { if (this.state.fileCount !== fCount) { this.setState({ fileCount: fCount }) } }}
        notificationid={this.props.params.notificationid}
      />
    );
  };

  tabCostBreakdown = () => {
    return (
      <CostBreakdown
        {...this.props}
        notification={this.state.notification}
        paymentRetentionList={this.state.paymentRetentionList}
        loadPaymentRetentionList={this.loadPaymentRetentionList}
      />
    );
  };

  tabRelations = () => {
    return (
      <Relations
        contractid={this.props.contract.contractid}
        workflowItem={this.props.workflowItem}
        workflowGroupItem={this.props.workflowGroupItem}
        notificationid={Number(this.props.params.notificationid)}
        relations={this.state.notification.relations}
        itemversion={this.state.notification.version}
        subject={this.state.notification.title}
        onCountChange={(count) => this.setState({ relationCount: count })}
      />
    );
  }

  loadPaymentRetentionList = () => {
    if (this.wbsEnabled) {
      activityCrud
        .getPaymentRetentionList(this.props.params.notificationid)
        .then((response) => {
          this.setState({ paymentRetentionList: JSON.parse(response.data.aggregatelist) });
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            this.props.enqueueSnackbar(err.toString(), { variant: "error" });
          }
        });
    }
  };
  tabEmailNotification = () => {

    return (
      <EmailNotifications
        {...this.props}

      />
    );

  };
  render() {
    if (!this.state.notification || !this.state.files) {
      return <PortletSpinner />;
    }
    const documentIsFinal = this.state.notification.showtasknotification === 1;
    if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
      return <Navigate replace to={this.props.workflowItem.paths.listPath} />;
    }

    return (
      <Portlet>
        <ReactToPrint
          ref={(el) => (this.printRef = el)}
          trigger={() => <div style={{ display: "none" }}></div>}
          content={() => this.printNotificationDivRef.current}
        />
        <NavigationBlocker
          when={(this.state.isChanged || this.state.saving)}
          message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
        />
        {this.state.navigateto &&
          (
            <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
          )
        }
        <PortletHeader
          title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
          toolbar={this.renderToolbar(documentIsFinal)}
        />
        <PortletBody>
          <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
            <div>
              <ValidationSummary
                elementAttr={{ align: "right", paddingBottom: 50 }}
                id={this.validatorName}
              ></ValidationSummary>
            </div>
            <TabPanel
              width="100%"
              deferRendering={false}
              animationEnabled={true}
              focusStateEnabled={true}
              hoverStateEnabled={true}
              swipeEnabled={false}
            >
              <Item
                icon="file"
                title={this.props.title}
                render={this.tabDocument}
              />
              {
                this.wbsEnabled && (
                  <Item
                    icon="bulletlist"
                    title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_COST_BREAKDOWN' })}
                    render={this.tabCostBreakdown}
                  /*render={documentIsFinal ? this.tabCostBreakdown : null}
                  visible={documentIsFinal}*/
                  />
                )
              }
              {
                this.isRelationsEnabled && (
                  <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                )
              }
              <Item
                icon="bulletlist"
                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
              />
            </TabPanel>
          </ValidationGroup>
        </PortletBody>
        <ActionModal
          visible={this.state.showModal}
          title={this.state.modalTitle}
          text={this.state.modalText}
          mode={this.state.modalMode}
          onDialogResult={this.onModalResponse}
          workflowItem={this.props.workflowItem}
          userLimit={this.state.notification.othercertfiedamount}
        />
      </Portlet>
    );
  }
}


export default withDetailPageProps(PaymentDetailPage);
