import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { Badge } from "react-bootstrap";
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper";
import { notificationAggregateListTagsFilter, notificationUniqueTagsFilter } from "../../../../store/helpers/FilterHelper";
import * as earlyWarningCrud from '../../../../crud/earlyWarning.crud';
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

class WarningList extends React.Component {
    constructor(props) {
        super(props);


        this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.notificationsRelsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';
        this.customFields = [];
        if (props.customFields) {
            if (props.customFields.COMMUNICATION && props.customFields.COMMUNICATION.length > 0) {
                this.customFields.push(...(props.customFields.COMMUNICATION.filter(cf => cf.includelisting)));
            }
            if (props.customFields.REPLY && props.customFields.REPLY.length > 0) {
                this.customFields.push(...(props.customFields.REPLY.filter(cf => cf.includelisting)));
            }
        }
        this.state = {
            notifications: null,
            tags: null
        };
    }
    componentDidMount() {
        this.fetchNotifications();
    }

    fetchNotifications = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data: responseObj } = await earlyWarningCrud.getEarlyWarningsReduced(companyid, projectid, contractid, this.props.workflowItem.config.currentModelType, this.props.accessibleWfIds)
            let listData = notificationAggregateListTagsFilter(responseObj.aggregatelist);
            listData = (this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed === true) ?
                listData :
                listData.filter(ntf => ntf.showtasknotification === 1);

            let tagFilters = notificationUniqueTagsFilter(responseObj.uniquetags);

            this.setState({ notifications: listData, tags: tagFilters });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }

    }

    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }

    calculateUrl = (rowData, skipHostUrl) => {
        return (skipHostUrl ? '' : process.env.PUBLIC_URL) + this.props.workflowItem.paths.basePath + rowData.warningid;
    }
    calculateReplyStatusValue = (rowData) => {
        return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + rowData.replystatus });
    }
    renderReplyStatusColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        switch (cellInfo.data.replystatus) {
            case 'Awaiting Reply':
            case 'Awaiting Acceptance':
                badgeVariant = 'warning';
                break;
            case 'Not Communicated':
                badgeVariant = 'secondary';
                break;
            case 'Not Accepted':
                badgeVariant = 'danger';
                break;
            case 'Reply Not Required':
            case 'Reply Received':
            case 'Accepted':
                badgeVariant = 'success';
                break;
            default:
                break;
        }
        const displayText = this.calculateReplyStatusValue(cellInfo.data);
        return (<Badge variant={badgeVariant}>{displayText}</Badge>);
    }
    calculatePriorityValue = (rowData) => {
        return this.props.intl.formatMessage({ id: 'OPTIONS.RISK.' + rowData.reference });
    }
    renderPriorityColumn = (cellInfo) => {
        const priority = cellInfo.data.reference;
        let badgeVariant = 'secondary';
        switch (priority) {
            case 'Low':
                badgeVariant = 'secondary';
                break;
            case 'Medium':
                badgeVariant = 'warning';
                break;
            case 'High':
                badgeVariant = 'danger';
                break;
            default:
                break;
        }
        const displayText = this.calculatePriorityValue(cellInfo.data);
        return (<Badge variant={badgeVariant}>{displayText}</Badge>);
    }

    renderMeetingColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        let display = this.props.intl.formatMessage({ id: 'OPTIONS.MEETING.NOT_REQUIRED' });
        if (cellInfo.data.meetingrequired === 2) {
            badgeVariant = 'danger';
            display = this.props.intl.formatMessage({ id: 'OPTIONS.MEETING.REQUIRED' });
        }
        return (<Badge variant={badgeVariant}>{display}</Badge>);
    }
    renderMeetingDisplay = (rowData) => {
        return rowData.meetingrequired === 2 ? this.props.intl.formatMessage({ id: 'OPTIONS.MEETING.REQUIRED' }) : this.props.intl.formatMessage({ id: 'OPTIONS.MEETING.NOT_REQUIRED' });
    }

    renderStatusColumn = (cellInfo) => {
        let badgeVariant = 'danger';
        let display = this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.Open' });
        if (cellInfo.data.status === 'Resolved') {
            badgeVariant = 'success';
            display = this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.Closed' });
        }
        return (<Badge variant={badgeVariant}>{display}</Badge>);
    }
    renderStatusValue = (rowData) => {
        return rowData.status === 'Resolved' ? this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.Closed' }) : this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.Open' });
    }
    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }

        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.SUBJECT", dataField: 'title' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_DATE", dataField: "notificationdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_BY", dataField: "createby" },
            { resource: "GENERAL.FORMS.FIELD.REPLY_REQUIRED", dataField: "replyrequireddate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.REPLY_RECIEVED", dataField: "replydate", dataType: 'date' },
            { resource: "CONTRACT.WF.EW.FIELD.REPLY_STATUS", dataField: "replystatus", cellRender: this.renderReplyStatusColumn, calculateCellValue: this.calculateReplyStatusValue },
            { resource: "CONTRACT.WF.EW.FIELD.MEETING", dataField: "meetingrequired", visible: false, cellRender: this.renderMeetingColumn, calculateCellValue: this.renderMeetingDisplay },
            { resource: "CONTRACT.WF.EW.FIELD.PRIORITY", dataField: "reference", visible: false, cellRender: this.renderPriorityColumn, calculateCellValue: this.calculatePriorityValue },
            { resource: "CONTRACT.WF.EW.FIELD.RISK_STATUS", dataField: "riskstatus", visible: false },
            { resource: "GENERAL.FORMS.FIELD.ACTION_OWNER", dataField: "owner", visible: false },
            { resource: "CONTRACT.WF.EW.FIELD.RESOLVED", dataField: "resolvedate", visible: false, dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "status", visible: false, cellRender: this.renderStatusColumn, calculateCellValue: this.renderStatusValue },
            { resource: "GENERAL.FORMS.FIELD.REPLIED_BY", dataField: "replyby", visible: false },
            { resource: "GENERAL.FORMS.FIELD.REPLY_DRAFT_BY", dataField: "draftreplyby", visible: false },
            { resource: "GENERAL.FORMS.FIELD.ACTION", dataField: "solution", isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.DESCRIPTION", dataField: "description", isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.REPLY", dataField: "replycomment", isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false },
            { resource: "GENERAL.FORMS.REPLING_PARTY", dataField: "replyingparty", lookup: { dataSource: this.props.contractTemplate.parties.options, valueExpr: 'id', displayExpr: 'display' }, visible: false }

        ];
        if (this.props.workflowItem.assetFieldsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.ASSET_NAME", dataField: "assetname", visible: false },
                { resource: "GENERAL.FORMS.FIELD.ASSET_TYPE", dataField: "assettype", visible: false },
            );
        }
        if (this.notificationsTagsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.PortfolioFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProgrammeFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProjectFilter } },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.OtherFilter } }
            )
        }
        if (this.notificationsRelsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.TAB_RELATIONS", dataField: "relatednotices", visible: false },
            )
        }
        columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(this.customFields));

        return (
            <Portlet style={{ marginBottom: 0 }}>
                <PortletBody>
                    <DataGridBase
                        version="2"
                        title={this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.TITLE2' }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                        workflowItem={this.props.workflowItem}
                        createPath={this.props.workflowItem.userFilters.mainNotice.createAllowed ? this.props.workflowItem.paths.createPath : null}
                        name={this.props.workflowItem.pageTitle}
                        dataSource={this.state.notifications}
                        keyExpr="warningid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}



export default withListPageProps(WarningList);
