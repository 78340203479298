import axios from '../shared/axios';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function savePaymentCertificate(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/PaymentCertificate/SavePaymentCertificate`, data)
            .then(result => {
                // if (data.showtasknotification === 1) {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                // }
                resolve(result);
            })
            .catch(error => reject(error));
    });
}

export function updatePaymentCertificate(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/PaymentCertificate/Update`, data)
            .then(result => {
                if (data.actioncode === 'COMMUNICATE' || data.actioncode === 'REPLY_SEND') {
                    store.dispatch({
                        type: contractDuck.actionTypes.CONTRACT_REFRESH_CONTRACT,
                    });
                }
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}
export function getPaymentCertificate(paymentcertificateid) {
    return axios.get(`/api/PaymentCertificate/GetPaymentCertificateV2?earlypaymentcertificateid=${paymentcertificateid}`);
}

export function getPaymentCertificates(companyid, projectid, contractid, type, accessibleWfIds) {
    return axios.post(`/api/PaymentCertificate/RegisterList`, { accessibleWfIds }, { params: { companyid, projectid, contractid, type } });
}
export function deletePaymentCertificateDraft(paymentcertificateid) {
    return new Promise((resolve, reject) => {
        axios.post(`api/PaymentCertificate/DeleteDraft?paymentcertificateid=${paymentcertificateid}`)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    })
}

export function getLastPaymentAmountPerCurrency(contractid, paymentTypes, currencyCodes ) {
    return axios.post(`/api/PaymentCertificate/GetLastPaymentAmounts`, {contractid, paymentTypes,currencyCodes});
}