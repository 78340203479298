import Common from '../common';

//const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const colorValues = [null, 'NAVY', 'BLUE', 'AQUA', 'TEAL', 'OLIVE', 'GREEN', 'LIME', 'YELLOW', 'ORANGE', 'RED', 'MAROON', 'FUCHSIA', 'PURPLE', 'BLACK', 'GRAY', 'SILVER', 'WHITE'];
const htmlToolbarItems = ["undo", "redo", "separator", "bold", {"name":"italic"},{ "name": "color", "acceptedValues": colorValues, }, { "name": "background", "acceptedValues": colorValues, }, "bulletList"];//, { "formatName": "header", "formatValues": [1, 2, 3, false] } //{ "formatName": "size", "formatValues": sizeValues, }, //"orderedList", 

export const htmlSenitizeOptions = {
    allowedTags: ["address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
        "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
        "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
        "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
        "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
        "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
        "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr"],
    /*allowedAttributes: {
        a: ['href', 'name', 'target'],
        // We don't currently allow img itself by default, but this
        // would make sense if we did
        img: ['src']
    },*/
    allowedAttributes: {
        a: ['href', 'name', 'target'],
        img: ['src'],
        '*': ['style']
    },
    allowedStyles: {
        '*': {
            color: /^[a-zA-Z]/,
            'background-color': [/^[a-zA-Z]/],
        }
    }
};
export const dxHtmlEditorProps = {
    height: 300,
    valueType: 'html',
    toolbar: { items: htmlToolbarItems },
    width: '100%',
};

export const dxTextBoxProps = {
    width: '100%',
};

export const dxSelectBoxProps = {
    acceptCustomValue: false,
    searchEnabled: true,
    searchMode: 'contains',
    //searchExpr: item.editorOptions.displayExpr,
    searchTimeout: 200,
    minSearchLength: 0,
    width: '100%',
};
export const dxTagBoxProps = {
    hideSelectedItems: true,
    searchEnabled: true,
    searchMode: 'contains',
    searchTimeout: 200,
    minSearchLength: 0,
    // placeholder: 'Choose Tags...',
    width: '100%',
};
export const dxDateBoxProps = {
    invalidDateMessage: 'The date must have the following format: DD/MM/YYYY',
    displayFormat: 'dd/MM/yyyy',
    placeholder: 'DD/MM/YYYY',
    openOnFieldClick: true,
    useMaskBehavior: true,
    width: '100%',
};

export const dxNumberBoxProps = (currencyCode = 'GBP') => {
    let curCode = currencyCode ? currencyCode : 'GBP';
    return {
        format: { style: "currency", currency: curCode, useGrouping: true, currencyDisplay: 'narrowSymbol' },//currencySymbol + ' #,##0.00',
        placeholder: Common.FormatCurrencyNum(0, curCode),
        width: 200,
        step: 0
    }
};

export const dxTextAreaProps = {
    width: '100%',
};
export const dxDropDownBoxProps = {
    acceptCustomValue: false,
    searchEnabled: true,
    searchMode: 'contains',
    //searchExpr: item.editorOptions.displayExpr,
    searchTimeout: 200,
    minSearchLength: 0,
    width: '100%',
};

export const dxRadioGroupProps = {
    // acceptCustomValue: false,
    // searchEnabled: true,
    // searchMode: 'contains',
    // //searchExpr: item.editorOptions.displayExpr,
    // searchTimeout: 200,
    // minSearchLength: 0,
    // width: '100%',
};
