import axios from '../shared/axios';

export function saveNotification(data) {
    return axios.post('/api/TaskNotification/SaveTaskNotification', data);
}

export function pushNotifications(data) {

    return axios.post('/api/TaskNotification/PushTaskNotifications', data);
}

export async function sendMailNotifications(userList, notifyObject) {
    try {
        notifyObject.notifyusers = userList;
        pushNotifications(notifyObject);


        // const promises = [];
        // userList.forEach(user => {
        //     const data = { ...notifyObject, createforid: user.accountid };
        //     saveNotification(data)
        //         .catch(err => {
        //             console.log('error sending mail to:', user.accountid, user.mail);
        //         })


        //     // promises.push(saveNotification(data));
        // });

        // await axios.all(promises);
    } catch (error) {
        console.log('Error in sendMailNotifications:', error);
    }
}

export function getEmailList(contractid, referencetype, reference) {
    return axios.get(
        `/api/TaskNotification/GetNoticeEmailDetails?contractid=${contractid}&referencetype=${referencetype}&reference=${reference}`
    );
}