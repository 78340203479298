/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import DonutChart from "./DonutChart";
import { Portlet, PortletBody, } from "../partials/content/Portlet";
import { withRouter } from '../shared/hoc/withRouter';
import { FormattedMessage, injectIntl } from "react-intl";

const colors = {
    warningPalette: ['#e0440e', '#e6693e', '#ec8f6e'],
    redPalette: ['#B53737', '#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
    greenPalette: ['#51C048', '#74CE6D', '#98DB92', '#BCE8B8'],
    bluePalette: ['#26E3E0', '#4ACADE', '#38A6D9', '#4076D9', '#3854BD'],
    success: "#07B388",
    danger: "#D3438E",
    warning: "#E98E23",
    brand: "#3F96C4",
    dark: "#282a3c",
    light: "#ffffff",
    primary: "#0064A2",
    info: "#66C3E7",
}

class DonutCharts extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.dashboard !== nextProps.dashboard) {
            return true;
        }
        else {
            return false;
        }
    }
    render() {
        const { dashboard, globalResources, intl, contractid, workflowsQA } = this.props;

        const specificCEWorkflow = workflowsQA && workflowsQA[12];
        const specificImplementedCEWorkflow = workflowsQA && workflowsQA[13];
        const specificRiskRegisterWorkflow = workflowsQA && workflowsQA[3];

        const isMatchingCEWorkflow = specificCEWorkflow && specificCEWorkflow.id === 12;
        const isMatchingImplementedCEWorkflow = specificImplementedCEWorkflow && specificImplementedCEWorkflow.id === 13;
        const isMatchingRiskRegisterWorkflow = specificRiskRegisterWorkflow && specificRiskRegisterWorkflow.id === 3;
        if (!dashboard) {
            return null;
        }
        return (
            <div style={{ marginBottom: 20 }} className="row row-full-height">
                
                <div className="col-sm-12 col-md-6 col-lg-6">
                {isMatchingRiskRegisterWorkflow && (
                    <Portlet style={{ marginBottom: 20, minWidth:0, display:'flex', justifyContent:'space-between'  }} className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                        <PortletBody fluid={true}>
                            <DonutChart title={dashboard.resources.earlyWarningTitle}
                                data={[
                                    ['Notified By', 'Notification Count'],
                                    [this.props.globalResources.SUPPLIER_NAME, dashboard ? dashboard.earlywarningcontractorraised : 0],
                                    [this.props.globalResources.PM_NAME, dashboard ? dashboard.earlywarningpmraised : 0],
                                    [this.props.globalResources.CLIENT_NAME, dashboard ? dashboard.earlywarningclientraised : 0],
                                ]}
                                colors={colors.warningPalette}
                            />
                            <a onClick={() => { this.props.navigate(`/contract/${this.props.contractid}/early-warning/register/list`); }} style={{ position: 'relative', top: '5px', textDecoration: 'underline', fontSize:'1.1rem'}}><FormattedMessage id="CONTRACT.DASHBOARD.VIEW_REGISTER" /></a>
                        </PortletBody>
                    </Portlet>
                )}
                    <div className="kt-space-20" />
                    {isMatchingRiskRegisterWorkflow && (
                    <Portlet style={{ marginBottom: 20, minWidth:0  }} className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                        <PortletBody fluid={true}>
                            <DonutChart title={this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.RISKS' })}
                                data={[
                                    ['Risk', 'Count'],
                                    [this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.VERY_HIGH' }), dashboard ? dashboard.riskveryhigh : 0],
                                    [this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.HIGH' }), dashboard ? dashboard.riskhigh : 0],
                                    [this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.MEDIUM' }), dashboard ? dashboard.riskpriority : 0],
                                    [this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.LOW' }), dashboard ? dashboard.risklow : 0],
                                    [this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.VERY_LOW' }), dashboard ? dashboard.riskverylow : 0],
                                ]}
                                colors={colors.redPalette}
                            />
                            <a onClick={() => { this.props.navigate(`/contract/${this.props.contractid}/early-warning/register/list`); }} style={{ position: 'relative', top: '5px', textDecoration: 'underline', fontSize:'1.1rem'}}><FormattedMessage id="CONTRACT.DASHBOARD.VIEW_REGISTER" /></a>
                        </PortletBody>
                    </Portlet>
                      )}
                </div>
          
                <div className="col-sm-12 col-md-6 col-lg-6">
                {isMatchingCEWorkflow && (
                    <Portlet style={{ marginBottom: 20, minWidth:0  }} className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">

                        <PortletBody fluid={true}>
                            <DonutChart title={dashboard.resources.compensationTitle}
                                data={[
                                    ['Notified By', 'Notification Count'],
                                    [this.props.globalResources.SUPPLIER_NAME, dashboard ? dashboard.compeventcontractorraised : 0],
                                    [this.props.globalResources.PM_NAME, dashboard ? dashboard.compeventpmraised : 0],
                                    [this.props.globalResources.CLIENT_NAME, dashboard ? dashboard.compeventclientraised : 0],
                                ]}
                                colors={colors.bluePalette}
                            />
                            <a onClick={() => { this.props.navigate(`/contract/${this.props.contractid}/compensation-events/register/list`); }} style={{ position: 'relative', top: '5px', textDecoration: 'underline', fontSize:'1.1rem'}}><FormattedMessage id="CONTRACT.DASHBOARD.VIEW_REGISTER" /></a>

                        </PortletBody>
                    </Portlet>
                    )}
                    
                    <div className="kt-space-20" />
                    {isMatchingImplementedCEWorkflow && (
                    <Portlet style={{ marginBottom: 20, minWidth:0  }} className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                        <PortletBody fluid={true}>
                            <DonutChart title={this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.IMPLEMENTED_CE' }, { ITEM_NAME: dashboard.resources.compensationTitle })}
                                data={[
                                    ['Status', 'Notification Count'],
                                    [this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.QUOTE_ACCEPTED' }), dashboard ? dashboard.quoteimplemented : 0],
                                    [this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.PM_ASSESSMENT_MADE' }, { PM_ABBREVIATION: this.props.globalResources.PM_ABBREVIATION }), dashboard ? dashboard.assessmentmade : 0],
                                ]}
                                colors={colors.greenPalette}
                            />
                            <a onClick={() => { this.props.navigate(`/contract/${this.props.contractid}/compensation-events/register-implemented/list`); }} style={{ position: 'relative', top: '5px', textDecoration: 'underline', fontSize:'1.1rem'}}><FormattedMessage id="CONTRACT.DASHBOARD.VIEW_REGISTER" /></a>

                        </PortletBody>
                    </Portlet>
                     )}
                </div>
            </div>
        )
    }
}


const mapStateToProps = store => ({
    dashboard: store.contract.dashboard,
});

export default injectIntl(withRouter(connect(mapStateToProps)(DonutCharts)));