import React from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../shared/hoc/withRouter';
import DataGridBase from '../../../partials/layout/DataGridBase';
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import Common from '../../../shared/common';
import { FormattedMessage, injectIntl } from "react-intl";
import { Alert } from "react-bootstrap";
import { GetAccessRights } from "../../../router/Protector/Protector";
import { getContractPartyStructureTypes } from '../../../shared/lookup';
class ContractsGrid extends React.Component {
    constructor(props) {
        super(props);
        this.canCreateNew = GetAccessRights("/contracts/new-contract", this.props.userProfile).allowAccess;

        this.contractPartyStructureTypes = getContractPartyStructureTypes(this.props.intl);

        this.contractTagsEnabled = process.env.REACT_APP_CONTRACT_COMPANY_TAGS_ENABLED === 'true';
        this.cacheWarningEnabled = process.env.REACT_APP_SHOW_CONTRACTS_CACHE_WARNING === 'true';
        this.isForecastEnabled = process.env.REACT_APP_FORECAST_ENABLED === 'true';
    }
    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            this.props.navigate("/contract/" + data.contractid + process.env.REACT_APP_CONTRACT_HOMEPAGE);
        }
    }
    calculateImplementedCEValue = (rowData) => {
        return rowData.currentcontractvalue - rowData.contractvalue;
    }
    calculateContractValueOutstanding = (rowData) => {
        return rowData.currentcontractvalue - rowData.totalcertified;
    }
    calculateContractState = (rowData) => {
        if (rowData.deleted === 1) {
            return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.DELETED' });
        }
        else {
            switch (rowData.recordstatus) {
                case 1:
                    return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.ACTIVE' });
                case 2:
                    return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.COMPLETED' });
                case 3:
                    return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.ARCHIVED' });
                default:
                    return 'Unknown';
            }
        }
    }
    renderImplementedCEValue = (cellInfo) => {
        return Common.FormatCurrencyNum((cellInfo.data.currentcontractvalue - cellInfo.data.contractvalue), cellInfo.data.currency);
    }
    renderContractValueOutstanding = (cellInfo) => {
        return Common.FormatCurrencyNum((cellInfo.data.currentcontractvalue - cellInfo.data.totalcertified), cellInfo.data.currency);
    }

    renderOriginalPrice = (cellInfo) => {
        return Common.FormatCurrencyNum(cellInfo.data.contractvalue, cellInfo.data.currency);
    }
    renderCurrentPrice = (cellInfo) => {
        return Common.FormatCurrencyNum(cellInfo.data.currentcontractvalue, cellInfo.data.currency);
    }
    renderCostForecast = (cellInfo) => {
        return Common.FormatCurrencyNum(cellInfo.data.forecastedcost, cellInfo.data.currency);
    }
    renderTotalCertified = (cellInfo) => {
        return Common.FormatCurrencyNum(cellInfo.data.totalcertified, cellInfo.data.currency);
    }
    renderPOValue = (cellInfo) => {
        return Common.FormatCurrencyNum(cellInfo.data.scope, cellInfo.data.currency);
    }
    // editorPreparing = (e) => {
    //     if (e.parentType === "filterRow" && (e.dataField === "area" || e.dataField === "hub" || e.dataField === "framework" || e.dataField === "lot")) {
    //         e.cancel = true;
    //     }
    // }
    render() {
        const columns = [
            { resource: "CONTRACT.FIELD.NAME", dataField: "name", fixed: true },
            { resource: "CONTRACT.FIELD.PROJECT_NAME", dataField: "project" },
            { resource: "CONTRACT.FIELD.TYPE", dataField: "contracttype" },
            { resource: "CONTRACT.FIELD.STATUS", dataField: "recordstatus", calculateCellValue: this.calculateContractState },
            { resource: "CONTRACT.FIELD.SUPPLIER", dataField: "contractorcompany" },
            { resource: "CONTRACT.FIELD.ORIGINAL_PRICE", dataField: "contractvalue", dataType: "currency", cellRender: this.renderOriginalPrice },
            { resource: "CONTRACT.FIELD.CURRENT_PRICE", dataField: "currentcontractvalue", dataType: "currency", cellRender: this.renderCurrentPrice },
            { resource: "GENERAL.FORMS.FIELD.START_DATE", dataField: "startdate", dataType: "date", visible: false },
            { resource: "CONTRACT.FIELD.ORIGINAL_CMP_DATE", dataField: "completedate", dataType: "date", visible: false },
            { resource: "CONTRACT.FIELD.CURRENT_CMP_DATE", dataField: "completiondate", dataType: "date", visible: false },
            { resource: "CONTRACT.FIELD.TOTAL_CERTIFIED", dataField: "totalcertified", visible: false, dataType: "currency", cellRender: this.renderTotalCertified },
            { resource: "CONTRACT.FIELD.LEAD_CONSULTANT", dataField: "contractmanager", visible: false },
            { resource: "CONTRACT.FIELD.LEAD_CLIENT", dataField: "employer", visible: false },
            { resource: "CONTRACT.FIELD.LEAD_PM", dataField: "projectmanager", visible: false },
            { resource: "CONTRACT.DASHBOARD.PRICE_CHANGE", dataField: "implemented", calculateCellValue: this.calculateImplementedCEValue, visible: false, dataType: "currency", cellRender: this.renderImplementedCEValue },
            { resource: "CONTRACT.FIELD.VALUE_OUTSTANDING", dataField: "outstanding", calculateCellValue: this.calculateContractValueOutstanding, visible: false, dataType: "currency", cellRender: this.renderContractValueOutstanding },
            { resource: "GENERAL.FORMS.FIELD.CURRENCY", dataField: "currency", visible: false },
            { resource: "CONTRACT.FIELD.PO_NUMBER", dataField: "versionnumber", visible: false },
            { resource: "CONTRACT.FIELD.PO_VALUE", dataField: "scope", visible: false, dataType: "currency", cellRender: this.renderPOValue },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "GENERAL.FORMS.ACTUAL_COMPLETION_DATE", dataField: "actualcompletiondate", dataType: "date", visible: false },
            { resource: "CONTRACT.FIELD.ID", dataField: "contractid", visible: false },
            { resource: "CONTRACT.FIELD.NUMBER", dataField: "contractreferencenumber", visible: false },
            { resource: "CONTRACT.FIELD.PARTY_STRUCTURE", dataField: "partystructure", lookup: { dataSource: this.contractPartyStructureTypes, displayExpr: 'display', valueExpr: 'value', }, visible: false },
            { resource: "CONTRACT.FIELD.TEMPLATE_TYPE", dataField: "templatetype", visible: true },
            { resource: "CONTRACT.FIELD.TEMPLATE_NAME", dataField: "templatename", visible: true },
            { resource: "GENERAL.FORMS.DEFECTS_DATE", dataField: "defectsdate", dataType: "date", visible: false }
        
        ];
        if (this.isForecastEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.COSTFORECAST", dataField: "forecastedcost", visible: false, dataType: 'currency', cellRender: this.renderCostForecast }
            )
        }
        if (this.contractTagsEnabled) {
            columns.push(
                { resource: "CONTRACT.FIELD.AREAS", dataField: "tags_area", visible: false, dataType: 'tag', headerFilter: { dataSource: this.props.areasFilter } },
                { resource: "CONTRACT.FIELD.HUBS", dataField: "tags_hub", visible: false, dataType: 'tag', headerFilter: { dataSource: this.props.hubsFilter } },
                { resource: "CONTRACT.FIELD.FRAMEWORKS", dataField: "tags_framework", visible: false, dataType: 'tag', headerFilter: { dataSource: this.props.frameworkFilter } },
                { resource: "CONTRACT.FIELD.LOTS", dataField: "tags_lot", visible: false, dataType: 'tag', headerFilter: { dataSource: this.props.lotFilter } },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag', headerFilter: { dataSource: this.props.otherTagFilter } }
            )
        }
        return (
            <Portlet style={{ width: "inherit" }}>
                <PortletBody>
                    {this.cacheWarningEnabled && (
                        <Alert variant="secondary">
                            <p>
                                <FormattedMessage id="CONTRACT.LIST.WARNING_CACHE" />
                            </p>
                        </Alert>
                    )}
                    <DataGridBase
                        version="7"
                        title={this.props.intl.formatMessage({ id: 'NAVIGATION.MY_CONTRACTS' })}
                        createPath={this.canCreateNew ? '/contracts/new-contract' : null}
                        name="Contracts"
                        dataSource={this.props.contracts}
                        keyExpr="contractid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        repaint={true}
                        isContract={true}
                        columns={columns}
                        onEditorPreparing={this.editorPreparing}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    userProfile: store.auth.userProfile,
    areasFilter: store.contracts.areasFilter,
    hubsFilter: store.contracts.hubsFilter,
    frameworkFilter: store.contracts.frameworkFilter,
    lotFilter: store.contracts.lotFilter,
    otherTagFilter: store.contracts.otherTagFilter,
});

export default injectIntl(withRouter(connect(mapStateToProps)(ContractsGrid)));