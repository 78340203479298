import React from 'react';
import NotifyArea from '../../../home/Contract/CustomControl/NotifyArea';
import { Card } from "react-bootstrap";

class NotifyControl extends React.Component {
    render() {

        let notification = null;
        //let badgeVariant = 'secondary';
        if (this.props.warningCount && this.props.warningCount > 0) {
            notification = this.props.warningCount;
            //badgeVariant = 'danger';
        }
        else {
            notification = 0;
        }
        return (
            <>
                <Card style={{ marginBottom: 10 }}>
                    <Card.Header as="h5">{this.props.item.title}</Card.Header>
                    <Card.Body>
                        <NotifyArea
                            expanded={true}
                            item={this.props.item}
                            warningCount={notification}
                            contractid={this.props.contractid} />
                    </Card.Body>
                </Card>
                {/* 
                <div style={{ paddingLeft: 10, fontWeight: 500, fontSize: '14px', fontFamily: '\'Helvetica Neue\', \'Segoe UI\', Helvetica, Verdana, sans-serif', color: '#333' }}>
                    <div className="kt-notification__item-icon">
                        <Badge variant={badgeVariant}>{notification}</Badge>
                    </div>
                    <div className="kt-notification__item-details">
                        <div className="kt-notification__item-title" style={{ fontWeight: 400, fontSize: '14px', color: '#333' }}>

                        </div>
                    </div>
                </div>
                <div>
                </div> */}
            </>
        );
    }
}

export default NotifyControl;