/* eslint-disable no-eval */
import DefaultWorkflows from './DefaultWorkflows';
import DefaultTemplates from '../Templates/DefaultTemplates';
import DefaultResources from '../Templates/DefaultResources';
import { userFilterCategories } from "../shared/lookup";

export const pmAndManagerOrSupport = "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]";
// export const pmAndManagerOrCostManager = "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"]]]";
export const pmAndManagerOrCostManagerOrSupport = "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]";
export const pmOrSupplierAndManagerOrSupport = "[[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]";
export const pmOrSupervisorAndManagerOrSupport = "[[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Supervisor\"]],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]";
export const pmOrSupervisorAndManagerOrCostManager = "[[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Supervisor\"]],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"]]]";

export const pmAndCostManager = "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Cost Manager\"]]";
export const pmAndManager = "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]";
export const pmOrSupervisorAndManager = "[[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Supervisor\"]],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]";

export const pm = "[\"party\",\"=\",\"PM\"]";

export const supplierAndManagerOrSupport = "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]";
// export const supplierAndManagerOrCostManager = "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"]]]";
export const supplierAndManagerOrCostManagerOrSupport = "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]";
export const supplierAndCostManager = "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Cost Manager\"]]";
export const supplierAndManager = "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]";
export const supplierOrPMAndManager = "[[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]";
export const supplierOrPM = "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]";
export const supplier = "[\"party\",\"=\",\"Contractor\"]";

export const supervisorAndManagerOrSupport = "[[\"party\",\"=\",\"Supervisor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]";
// export const supervisorAndManagerOrCostManager = "[[\"party\",\"=\",\"Supervisor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"]]]";
export const supervisorAndManagerOrCostManagerOrSupport = "[[\"party\",\"=\",\"Supervisor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]";
export const supervisorAndCostManager = "[[\"party\",\"=\",\"Supervisor\"],\"and\",[\"rolepermissions\",\"=\",\"Cost Manager\"]]";
export const supervisorAndManager = "[[\"party\",\"=\",\"Supervisor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]";
export const supervisor = "[\"party\",\"=\",\"Supervisor\"]";

export const clientAndManagerOrSupport = "[[\"party\",\"=\",\"Client\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]";
// export const clientAndManagerOrCostManager = "[[\"party\",\"=\",\"Client\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"]]]";
export const clientAndManagerOrCostManagerOrSupport = "[[\"party\",\"=\",\"Client\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]";
export const clientAndManager = "[[\"party\",\"=\",\"Client\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]";
export const client = "[\"party\",\"=\",\"Client\"]";

export const pmOrSupplier = "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]";
export const pmOrSupervisor = "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Supervisor\"]]";
export const pmOrClient = "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Client\"]]";
export const supervisorOrSupplier = "[[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Contractor\"]]";
export const pmOrSupplierOrSupervisor = "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"]]";
export const pmOrSupplierOrSupervisorOrClient = "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]";

// const commonPMFilters = { createUserFilter: pmAndManagerOrSupport, communicateUserFilter: pmAndManager, viewDraftUserFilter: pm, replyUserFilter: supplierAndManager, replyDraftUserFilter: supplierAndCostManager, notifyUserFilter: pmOrSupplier };
// const commonSupplierFilters = { createUserFilter: supplierAndManagerOrSupport, communicateUserFilter: supplierAndManager, viewDraftUserFilter: supplier, replyUserFilter: pmAndManager, replyDraftUserFilter: pmAndCostManager, notifyUserFilter: pmOrSupplier };
// const commonSupervisorFilters = { createUserFilter: supervisorAndManagerOrSupport, communicateUserFilter: supervisorAndManager, viewDraftUserFilter: supervisor, replyUserFilter: supplierAndManager, replyDraftUserFilter: supplierAndCostManager, notifyUserFilter: supervisorOrSupplier };
// const commonClientFilters = { createUserFilter: clientAndManagerOrSupport, communicateUserFilter: clientAndManager, viewDraftUserFilter: client, replyUserFilter: pmAndManager, replyDraftUserFilter: pmAndManagerOrCostManager, notifyUserFilter: pmOrClient };

const emptyPartyOrExpression = "[\"party\",\"=\",\"{Party}\"]";
//const party1OrParty2 = "[[\"party\",\"=\",\"{Party1}\"],\"or\",[\"party\",\"=\",\"{Party2}\"]]";

// CONTRACT PARTY TYPES
//-----------------------
// Client
// PM
// Contractor
// Supervisor

// CONTRACT ROLE TYPES
//-----------------------
// Manager
// Cost Manager
// Support
// Site Agent
// Read Only


export default class TemplateHelper {

    static ConvertDxFilterExpToLogicExp(filterExp, objectName) {
        if (!filterExp) {
            return 'true';
        }
        if (filterExp.length <= 0) {
            return 'true';
        }
        if (typeof filterExp[0] === 'string') {
            if (filterExp[1] === '=' || filterExp[1] === '<>') {
                let valuePart = ''
                if (typeof filterExp[2] === 'string') {
                    valuePart = `'${filterExp[2]}'`
                }
                else if (typeof filterExp[2] === 'number') {
                    valuePart = filterExp[2]
                }

                let operandPart = '';
                if (filterExp[1] === '=') {
                    operandPart = '===';
                }
                else if (filterExp[1] === '<>') {
                    operandPart = '!==';
                }
                return `(${objectName}.${filterExp[0]} ${operandPart} ${valuePart})`;
            }
            else {
                console.log(filterExp[1] + ' operand is not implemented!!')
            }
        }
        else {
            let returnExpression = '('
            for (let index = 0; index < filterExp.length; index++) {
                if (filterExp[index] === 'and') {
                    returnExpression += ' && ';
                }
                else if (filterExp[index] === 'or') {
                    returnExpression += ' || ';
                }
                else if (Array.isArray(filterExp[index])) {
                    returnExpression += TemplateHelper.ConvertDxFilterExpToLogicExp(filterExp[index], objectName);
                }

            }
            returnExpression += ')';
            return returnExpression;
        }
    }
    static GetDefaultFilter_OverrideCommunicateReplyFilter = (party) => {
        //WHEN EDITED ALSO CHECK LOGIC IN BaseDetailPage.getOverrideReplyCommunicateAllowed
        switch (party) {
            case "PM":
                return pmAndManager;
            case "SUPPLIER":
                return supplierAndManager;
            case "CLIENT":
                return clientAndManager;
            case "SUPERVISOR":
                return supervisorAndManager;
            default:
                throw new Error("GetDefaultFilter_OverrideCommunicateReplyFilter: party not defined:" + party);
        }
    }
    static GetDefaultFilter_CreateFilter = (defaultWF) => {
        if (defaultWF.notifyingParty.includes("|")) {
            const genericPartyFilter = this.GetDefaultFilter_Generic(defaultWF.notifyingParty);
            return `[${genericPartyFilter},"and",[["rolepermissions","=","Manager"],"or",["rolepermissions","=","Support"]]]`;
        }
        switch (defaultWF.notifyingParty) {
            case "PM":
                return pmAndManagerOrSupport;
            case "SUPPLIER":
                return supplierAndManagerOrSupport;
            case "CLIENT":
                return clientAndManagerOrSupport;
            case "SUPERVISOR":
                return supervisorAndManagerOrSupport;
            default:
                throw new Error("GetDefaultFilter_CreateFilter: notifyingParty not defined:" + defaultWF.notifyingParty);
        }
    }
    static GetDefaultFilter_Generic = (possiblePartiesString) => {
        if (possiblePartiesString.includes("|")) {
            let partiesArr = possiblePartiesString.split("|");
            if (partiesArr.length > 1) {
                partiesArr = partiesArr.map(partyName => `["party","=","${this.MapPartiesToDBNaming(partyName)}"]`)
                return `[${partiesArr.join(`,"or",`)}]`;
            }
        }
        throw new Error("GetDefaultFilter_Generic: invalid notifying party definition:" + possiblePartiesString);
    }
    static GetDefaultFilter_CreateQuoteFilter = (defaultWF) => {
        if (defaultWF.quotingParty.includes("|")) {
            const genericPartyFilter = this.GetDefaultFilter_Generic(defaultWF.quotingParty);
            return `[${genericPartyFilter},"and",[["rolepermissions","=","Manager"],"or",["rolepermissions","=","Support"]]]`;
        }
        switch (defaultWF.quotingParty) {
            case "PM":
                return pmAndManagerOrSupport;
            case "SUPPLIER":
                return supplierAndManagerOrSupport;
            case "CLIENT":
                return clientAndManagerOrSupport;
            case "SUPERVISOR":
                return supervisorAndManagerOrSupport;
            default:
                console.log(defaultWF)
                throw new Error("GetDefaultFilter_CreateQuoteFilter: quotingParty not defined:" + defaultWF.quotingParty);
        }
    }
    static GetDefaultFilter_ViewDraftFilter = (defaultWF) => {
        if (defaultWF.notifyingParty.includes("|")) {
            const genericPartyFilter = this.GetDefaultFilter_Generic(defaultWF.notifyingParty);
            return genericPartyFilter;
        }
        switch (defaultWF.notifyingParty) {
            case "PM":
                return pm;
            case "SUPPLIER":
                return supplier;
            case "CLIENT":
                return client;
            case "SUPERVISOR":
                return supervisor;
            default:
                throw new Error("GetDefaultFilter_ViewDraftFilter: notifyingParty not defined:" + defaultWF.notifyingParty);
        }
    }
    static GetDefaultFilter_ComminicateFilter = (defaultWF) => {
        if (defaultWF.notifyingParty.includes("|")) {
            const genericPartyFilter = this.GetDefaultFilter_Generic(defaultWF.notifyingParty);
            return `[${genericPartyFilter},"and",["rolepermissions","=","Manager"]]`;
        }
        switch (defaultWF.notifyingParty) {
            case "PM":
                return pmAndManager;
            case "SUPPLIER":
                return supplierAndManager;
            case "CLIENT":
                return clientAndManager;
            case "SUPERVISOR":
                return supervisorAndManager;
            default:
                throw new Error("GetDefaultFilter_ComminicateFilter: notifyingParty not defined:" + defaultWF.notifyingParty);
        }
    }
    static GetDefaultFilter_ComminicateQuoteFilter = (defaultWF) => {
        if (defaultWF.quotingParty.includes("|")) {
            const genericPartyFilter = this.GetDefaultFilter_Generic(defaultWF.quotingParty);
            return `[${genericPartyFilter},"and",["rolepermissions","=","Manager"]]`;
        }
        switch (defaultWF.quotingParty) {
            case "PM":
                return pmAndManager;
            case "SUPPLIER":
                return supplierAndManager;
            case "CLIENT":
                return clientAndManager;
            case "SUPERVISOR":
                return supervisorAndManager;
            default:
                throw new Error("GetDefaultFilter_ComminicateQuoteFilter: quotingParty not defined:" + defaultWF.quotingParty);
        }
    }
    static GetDefaultFilter_SaveDraftReplyFilter = (defaultWF) => {
        switch (defaultWF.replyingParty) {
            case "PM":
                return pmAndManagerOrCostManagerOrSupport;
            case "SUPPLIER":
                return supplierAndManagerOrCostManagerOrSupport;
            case "CLIENT":
                return clientAndManagerOrCostManagerOrSupport;
            case "SUPERVISOR":
                return supervisorAndManagerOrCostManagerOrSupport;
            default:
                throw new Error("GetDefaultFilter_SaveDraftReplyFilter: replyingParty not defined:" + defaultWF.replyingParty);
        }
    }
    static GetDefaultFilter_SaveQuoteDraftReplyFilter = (defaultWF) => {
        switch (defaultWF.quoteReplyingParty) {
            case "PM":
                return pmAndManagerOrCostManagerOrSupport;
            case "SUPPLIER":
                return supplierAndManagerOrCostManagerOrSupport;
            case "CLIENT":
                return clientAndManagerOrCostManagerOrSupport;
            case "SUPERVISOR":
                return supervisorAndManagerOrCostManagerOrSupport;
            default:
                throw new Error("GetDefaultFilter_SaveQuoteDraftReplyFilter: quoteReplyingParty not defined:" + defaultWF.quoteReplyingParty);
        }
    }
    static GetDefaultFilter_CommunicateReplyFilter = (defaultWF) => {
        switch (defaultWF.replyingParty) {
            case "PM":
                return pmAndManager;
            case "SUPPLIER":
                return supplierAndManager;
            case "CLIENT":
                return clientAndManager;
            case "SUPERVISOR":
                return supervisorAndManager;
            default:
                throw new Error("GetDefaultFilter_CommunicateReplyFilter: replyingParty not defined:" + defaultWF.replyingParty);
        }
    }
    static GetDefaultFilter_CommunicateQuoteReplyFilter = (defaultWF) => {
        switch (defaultWF.quoteReplyingParty) {
            case "PM":
                return pmAndManager;
            case "SUPPLIER":
                return supplierAndManager;
            case "CLIENT":
                return clientAndManager;
            case "SUPERVISOR":
                return supervisorAndManager;
            default:
                throw new Error("GetDefaultFilter_CommunicateQuoteReplyFilter: quoteReplyingParty not defined:" + defaultWF.quoteReplyingParty);
        }
    }
    static GetDefaultFilter_ActionFilter = (defaultWF) => {
        switch (defaultWF.actioningParty) {
            case "PM":
                return pmAndManager;
            case "SUPPLIER":
                return supplierAndManager;
            case "CLIENT":
                return clientAndManager;
            case "SUPERVISOR":
                return supervisorAndManager;
            default:
                throw new Error("GetDefaultFilter_ActionFilter: actioningParty not defined:" + defaultWF.actioningParty);
        }
    }
    static GetDefaultFilter_ResolveActionFilter = (defaultWF) => {
        switch (defaultWF.resolveActioningParty) {
            case "PM":
                return pmAndManager;
            case "SUPPLIER":
                return supplierAndManager;
            case "CLIENT":
                return clientAndManager;
            case "SUPERVISOR":
                return supervisorAndManager;
            default:
                throw new Error("GetDefaultFilter_ResolveActionFilter: resolveActioningParty not defined:" + defaultWF.resolveActioningParty);
        }
    }
    static GetDefaultFilter_NotifyFilter = (defaultWF) => {
        const wfPartiesSet = new Set();
        if (typeof defaultWF.notifyingParty === "string") {
            // wfPartiesSet.add(defaultWF.notifyingParty);
            defaultWF.notifyingParty.split("|").forEach(wfPartiesSet.add, wfPartiesSet)
        }
        if (typeof defaultWF.replyingParty === "string") {
            // wfPartiesSet.add(defaultWF.replyingParty);
            defaultWF.replyingParty.split("|").forEach(wfPartiesSet.add, wfPartiesSet)
        }
        if (typeof defaultWF.actioningParty === "string") {
            // wfPartiesSet.add(defaultWF.actioningParty);
            defaultWF.actioningParty.split("|").forEach(wfPartiesSet.add, wfPartiesSet)
        }
        if (typeof defaultWF.resolveActioningParty === "string") {
            // wfPartiesSet.add(defaultWF.resolveActioningParty);
            defaultWF.resolveActioningParty.split("|").forEach(wfPartiesSet.add, wfPartiesSet)
        }
        if (typeof defaultWF.quotingParty === "string") {
            // wfPartiesSet.add(defaultWF.quotingParty);
            defaultWF.quotingParty.split("|").forEach(wfPartiesSet.add, wfPartiesSet)
        }
        if (typeof defaultWF.quoteReplyingParty === "string") {
            // wfPartiesSet.add(defaultWF.quoteReplyingParty);
            defaultWF.quoteReplyingParty.split("|").forEach(wfPartiesSet.add, wfPartiesSet)
        }


        if (wfPartiesSet.size > 1) {
            let filter = [];
            wfPartiesSet.forEach(pt => {
                filter.push(emptyPartyOrExpression.replace("{Party}", this.MapPartiesToDBNaming(pt)))
            })
            filter = `[${filter.join(",\"or\",")}]`;
            return filter;
        }
        else {
            //or all parties
            return pmOrSupplierOrSupervisorOrClient;
        }
    }
    static GetDefaultFilter_AccessFilter = (defaultWF) => {
        // Filter "null" means no users are filtered
        // Access allowed to all
        return "null"
    }
    static EvaluateUserAccessRight = (filter, user, contract) => {
        try {
            if (!contract || !user) {
                return false;
            }
            if (filter) {
                return eval(TemplateHelper.ConvertDxFilterExpToLogicExp(JSON.parse(filter), 'user'));
            }
            else {
                ///filter is set to null, meaning no filters
                return true;
            }
        } catch (err) {
            console.log("Error in SetEvaluatedUserFilters");
            throw err;
        }
    }
    static EvaluateUserActionRight = (filter, user, contract) => {
        try {
            if (!contract || !user || user.accounttype === 'Read Only' || contract.deleted === 1 || contract.recordstatus === 3) {
                return false;
            }
            if (filter) {
                return eval(TemplateHelper.ConvertDxFilterExpToLogicExp(JSON.parse(filter), 'user'));
            }
            else {
                ///filter is set to null, meaning no filters
                return true;
            }
        } catch (err) {
            console.log("Error in SetEvaluatedUserFilters");
            throw err;
        }
    }
    // static GetAllWorkflowsByReference() {
    //     return DefaultWorkflows.reduce((wfArray, wfg) => { wfArray.push(...wfg.workflows); return wfArray; }, [])
    // }
    // static GetAllWorkflows() {
    //     const workflowsCopy = JSON.parse(JSON.stringify(DefaultWorkflows));
    //     return workflowsCopy.reduce((wfArray, wfg) => { wfArray.push(...wfg.workflows); return wfArray; }, [])
    // }

    // static GetWorkflow(workflowid) {
    //     return WorkflowHelper.GetAllWorkflows().find(wf => wf.id === workflowid);
    // }
    static GetDefaultWorkflow = (wfgid, wfid) => {
        try {
            const wfgroup = DefaultWorkflows.find(wfg => wfg.id === wfgid);
            if (wfgroup) {
                const workflow = wfgroup.workflows.find(wf => wf.id === wfid);
                if (workflow) {
                    return workflow;
                }
            }
        } catch (error) {
            console.log('WorkflowModal Editor err:', error);
        }
        alert(`Please report this error to system administrator, Code :WM101 wfgid:${wfgid} wfid:${wfid}`);
        console.log('WorkflowModal Editor default workflow not found:', wfgid, wfid);
        return null;
    }
    static MapPartiesToDBNaming = (party) => {
        switch (party) {
            case "PM":
                return "PM";
            case "SUPPLIER":
                return "Contractor";
            case "CLIENT":
                return "Client";
            case "SUPERVISOR":
                return "Supervisor";
            default:
                throw new Error("MapPartiesToDBNaming: party not defined:" + party);
        }
    }

    ///Migration from old Format to New Format
    /// Moves Old user filter properties on WF object to inner object WF.userFilters and assign defaults if they are missing
    static getTransformedUserFilters = (WF, defaultWF, template, contract) => {
        try {
            const userFilters = {};
            if ((defaultWF.config || {}).userFilterCategories) {
                if (typeof defaultWF.notifyingParty === "function") {
                    defaultWF.notifyingParty = defaultWF.notifyingParty(contract);
                }
                if (typeof defaultWF.replyingParty === "function") {
                    defaultWF.replyingParty = defaultWF.replyingParty(contract);
                }
                if (typeof defaultWF.actioningParty === "function") {
                    defaultWF.actioningParty = defaultWF.actioningParty(contract);
                }
                if (typeof defaultWF.quotingParty === "function") {
                    defaultWF.quotingParty = defaultWF.quotingParty(contract);
                }
                if (typeof defaultWF.quoteReplyingParty === "function") {
                    defaultWF.quoteReplyingParty = defaultWF.quoteReplyingParty(contract);
                }
                if (typeof defaultWF.resolveActioningParty === "function") {
                    defaultWF.resolveActioningParty = defaultWF.resolveActioningParty(contract);
                }
                if (defaultWF.config.userFilterCategories.includes(userFilterCategories.ACCESS)) {
                    let accessFilter = "";
                    if (typeof WF.accessUserFilter !== 'undefined') {
                        accessFilter = WF.accessUserFilter;
                    }
                    else if ((WF.userFilters || {}).accessFilter) {
                        accessFilter = WF.userFilters.accessFilter;
                    }
                    else {
                        accessFilter = "null";
                    }
                    userFilters.accessFilter = accessFilter;
                }
                if (defaultWF.config.userFilterCategories.includes(userFilterCategories.MAIN_NOTICE)) {

                    let createFilter = "";
                    if (typeof WF.createUserFilter !== 'undefined') {
                        createFilter = WF.createUserFilter;
                    }
                    else if (((WF.userFilters || {}).mainNotice || {}).createFilter) {
                        createFilter = WF.userFilters.mainNotice.createFilter;
                    }
                    else {
                        createFilter = TemplateHelper.GetDefaultFilter_CreateFilter(defaultWF);
                    }

                    let viewDraftFilter = ""
                    if (typeof WF.viewDraftUserFilter !== 'undefined') {
                        viewDraftFilter = WF.viewDraftUserFilter;
                    }
                    else if (((WF.userFilters || {}).mainNotice || {}).viewDraftFilter) {
                        viewDraftFilter = WF.userFilters.mainNotice.viewDraftFilter;
                    }
                    else {
                        viewDraftFilter = TemplateHelper.GetDefaultFilter_ViewDraftFilter(defaultWF);
                    }

                    let communicateFilter = ""
                    if (typeof WF.communicateUserFilter !== 'undefined') {
                        communicateFilter = WF.communicateUserFilter;
                    }
                    else if (((WF.userFilters || {}).mainNotice || {}).communicateFilter) {
                        communicateFilter = WF.userFilters.mainNotice.communicateFilter;
                    }
                    else {
                        communicateFilter = TemplateHelper.GetDefaultFilter_ComminicateFilter(defaultWF);
                    }

                    userFilters.mainNotice = {
                        createFilter: createFilter,
                        viewDraftFilter: viewDraftFilter,
                        communicateFilter: communicateFilter,
                    }

                }
                if (defaultWF.config.userFilterCategories.includes(userFilterCategories.REPLY_NOTICE)) {

                    let saveDraftReplyFilter = "";
                    if (typeof WF.replyDraftUserFilter !== 'undefined') {
                        saveDraftReplyFilter = WF.replyDraftUserFilter;
                    }
                    else if (((WF.userFilters || {}).replyNotice || {}).saveDraftReplyFilter) {
                        saveDraftReplyFilter = WF.userFilters.replyNotice.saveDraftReplyFilter;
                    }
                    else {
                        saveDraftReplyFilter = TemplateHelper.GetDefaultFilter_SaveDraftReplyFilter(defaultWF);
                    }


                    let communicateReplyFilter = "";
                    if (typeof WF.replyUserFilter !== 'undefined') {
                        communicateReplyFilter = WF.replyUserFilter;
                    }
                    else if (((WF.userFilters || {}).replyNotice || {}).communicateReplyFilter) {
                        communicateReplyFilter = WF.userFilters.replyNotice.communicateReplyFilter;
                    }
                    else {
                        communicateReplyFilter = TemplateHelper.GetDefaultFilter_CommunicateReplyFilter(defaultWF);
                    }

                    userFilters.replyNotice = {
                        saveDraftReplyFilter: saveDraftReplyFilter,
                        communicateReplyFilter: communicateReplyFilter,
                    }
                }
                if (defaultWF.config.userFilterCategories.includes(userFilterCategories.ACTION_TAB)) {

                    let actionFilter = "";
                    if (typeof WF.actionUserFilter !== 'undefined') {
                        actionFilter = WF.actionUserFilter;
                    }
                    else if (((WF.userFilters || {}).actionTab || {}).actionFilter) {
                        actionFilter = WF.userFilters.actionTab.actionFilter;
                    }
                    else {
                        actionFilter = TemplateHelper.GetDefaultFilter_ActionFilter(defaultWF);
                    }

                    userFilters.actionTab = {
                        actionFilter: actionFilter
                    }
                }
                if (defaultWF.config.userFilterCategories.includes(userFilterCategories.QUOTATION)) {
                    let createQuoteFilter = "";
                    if (((WF.userFilters || {}).quoteMainNotice || {}).createQuoteFilter) {
                        createQuoteFilter = WF.userFilters.quoteMainNotice.createQuoteFilter;
                    }
                    else {
                        createQuoteFilter = TemplateHelper.GetDefaultFilter_CreateQuoteFilter(defaultWF);
                    }

                    let communicateQuoteFilter = "";
                    if (((WF.userFilters || {}).quoteMainNotice || {}).communicateQuoteFilter) {
                        communicateQuoteFilter = WF.userFilters.quoteMainNotice.communicateQuoteFilter;
                    }
                    else {
                        communicateQuoteFilter = TemplateHelper.GetDefaultFilter_ComminicateQuoteFilter(defaultWF);
                    }

                    userFilters.quoteMainNotice = {
                        createQuoteFilter: createQuoteFilter,
                        communicateQuoteFilter: communicateQuoteFilter
                    }

                }
                if (defaultWF.config.userFilterCategories.includes(userFilterCategories.QUOTE_REPLY)) {

                    let saveQuoteDraftReplyFilter = "";
                    if (((WF.userFilters || {}).quoteReplyNotice || {}).saveQuoteDraftReplyFilter) {
                        saveQuoteDraftReplyFilter = WF.userFilters.quoteReplyNotice.saveQuoteDraftReplyFilter;
                    }
                    else {
                        saveQuoteDraftReplyFilter = TemplateHelper.GetDefaultFilter_SaveQuoteDraftReplyFilter(defaultWF);
                    }

                    let communicateQuoteReplyFilter = "";
                    if (((WF.userFilters || {}).quoteReplyNotice || {}).communicateQuoteReplyFilter) {
                        communicateQuoteReplyFilter = WF.userFilters.quoteReplyNotice.communicateQuoteReplyFilter;
                    }
                    else {
                        communicateQuoteReplyFilter = TemplateHelper.GetDefaultFilter_CommunicateQuoteReplyFilter(defaultWF);
                    }

                    userFilters.quoteReplyNotice = {
                        saveQuoteDraftReplyFilter: saveQuoteDraftReplyFilter,
                        communicateQuoteReplyFilter: communicateQuoteReplyFilter,
                    }
                }

                if (defaultWF.config.userFilterCategories.includes(userFilterCategories.NOTIFY)) {

                    let notifyFilter = "";
                    if (typeof WF.notifyUserFilter !== 'undefined') {
                        notifyFilter = WF.notifyUserFilter;
                    }
                    else if ((WF.userFilters || {}).notifyFilter) {
                        notifyFilter = WF.userFilters.notifyFilter;
                    }
                    else {
                        notifyFilter = TemplateHelper.GetDefaultFilter_NotifyFilter(defaultWF);
                    }
                    userFilters.notifyFilter = notifyFilter;
                }

                if (defaultWF.config.userFilterCategories.includes(userFilterCategories.RESOLVE_ACTION_TAB)) {

                    let resolveActionFilter = "";
                    if (((WF.userFilters || {}).resolveActionTab || {}).resolveActionFilter) {
                        resolveActionFilter = WF.userFilters.resolveActionTab.resolveActionFilter;
                    }
                    else {
                        if (defaultWF.config.userFilterCategories.includes(userFilterCategories.ACTION_TAB)) {
                            resolveActionFilter = userFilters.actionTab.actionFilter;
                        } else {
                            resolveActionFilter = TemplateHelper.GetDefaultFilter_ResolveActionFilter(defaultWF);
                        }
                    }

                    userFilters.resolveActionTab = {
                        resolveActionFilter: resolveActionFilter
                    }
                }
            }
            return userFilters;
            //REMOVE LEGACY PROPERTIES
            // delete WF.accessUserFilter;
            // delete WF.actionUserFilter;
            // delete WF.createUserFilter;
            // delete WF.communicateUserFilter;
            // delete WF.viewDraftUserFilter;
            // delete WF.replyUserFilter;
            // delete WF.replyDraftUserFilter;
            // delete WF.accessUserFilter;
            // delete WF.notifyUserFilter;

            // delete WF.path;
            // delete WF.description;
            // delete WF.model;
            // delete WF.type;
            // delete WF.referenceType;
            // delete WF.accessUserFilter;
            // delete WF.actionUserFilter;
            // delete WF.createUserFilter;
            // delete WF.communicateUserFilter;
            // delete WF.viewDraftUserFilter;
            // delete WF.replyUserFilter;
            // delete WF.replyDraftUserFilter;
            // delete WF.accessUserFilter;
            // delete WF.notifyUserFilter;
            // delete WF.config;

        } catch (err) {
            console.log("Error in TransformLegacyWorkflowObjectsToNew");
            throw err;
        }
    }

    static getPlaceholderToggleValue = (workflowGroup, key, defaultVal) => {
        if (workflowGroup.placeholders) {
            const placeholder = workflowGroup.placeholders.find((ph) => ph.replacementKey === key);
            if (placeholder) {
                return placeholder.values.find((phItem) => phItem.field === key).isActive;
            }
        }
        return defaultVal;
    }
    ///Determine which fields to be saved to DB
    ///Sanitize static properties
    ///Responsible for giving it last shape before saving to DB
    ///Trim off any static config properties (doesnt need saving as a part of template, these can always be assigned in adjustContractTemplate while loading)
    ///If there are some useful some props that are missing assign defaults so they are available for backend ui for next load
    static templateSanitizer = (template, contract) => {
        try {
            let mappedWorkflowGroups = template.workflowGroups.map(wfg => {
                try {
                    const defaultWFG = DefaultWorkflows.find(dWFG => dWFG.id === wfg.id);
                    if (!defaultWFG) {
                        return null;
                    }
                    let propgrammeReplyPHIndex = -1;
                    //IF PROGRAMME WORKFLOW CHECK FOR REPLY ENABLED EXCEPTION
                    if (wfg.id === 104) {
                        if (wfg.placeholders) {
                            propgrammeReplyPHIndex = wfg.placeholders.findIndex((ph) => ph.replacementKey === "ProgrammeReplyEnabled");
                        }
                    }
                    let mappedWorkflows = (wfg.workflows || []).map(wf => {
                        try {
                            const originalWF = defaultWFG.workflows.find(dWF => dWF.id === wf.id);
                            if (!originalWF) {
                                return null;
                            }
                            const defaultWF = JSON.parse(JSON.stringify(originalWF));

                            //#region PARTIES
                            if (typeof originalWF.notifyingParty === "function") {
                                defaultWF.notifyingParty = originalWF.notifyingParty(contract);
                            }
                            if (typeof originalWF.replyingParty === "function") {
                                defaultWF.replyingParty = originalWF.replyingParty(contract);
                            }
                            if (typeof originalWF.actioningParty === "function") {
                                defaultWF.actioningParty = originalWF.actioningParty(contract);
                            }
                            if (typeof originalWF.quotingParty === "function") {
                                defaultWF.quotingParty = originalWF.quotingParty(contract);
                            }
                            if (typeof originalWF.quoteReplyingParty === "function") {
                                defaultWF.quoteReplyingParty = originalWF.quoteReplyingParty(contract);
                            }
                            if (typeof originalWF.resolveActioningParty === "function") {
                                defaultWF.resolveActioningParty = originalWF.resolveActioningParty(contract);
                            }

                            const transformedUserFilters = this.getTransformedUserFilters(wf, defaultWF, template, contract);

                            const newWF = {
                                id: wf.id,
                                title: wf.title ? wf.title : defaultWF.title,
                                notificationName: wf.notificationName ? wf.notificationName : defaultWF.notificationName,
                                pageTitle: wf.pageTitle,
                                abbreviation: wf.abbreviation ? wf.abbreviation : defaultWF.abbreviation,

                                notify: wf.hasOwnProperty("notify") ? wf.notify : defaultWF.notify,
                                replyEnabled: wf.hasOwnProperty("replyEnabled") ? wf.replyEnabled : defaultWF.replyEnabled,
                                financialLimitEnabled: wf.hasOwnProperty("financialLimitEnabled") ? wf.financialLimitEnabled : defaultWF.financialLimitEnabled,
                                assetFieldsEnabled: wf.hasOwnProperty("assetFieldsEnabled") ? wf.assetFieldsEnabled : defaultWF.assetFieldsEnabled,

                                replyPeriodOption: wf.replyPeriodOption ? wf.replyPeriodOption : defaultWF.replyPeriodOption,
                                replyPeriod: wf.hasOwnProperty("replyPeriod") ? wf.replyPeriod : defaultWF.replyPeriod,


                                notifyingParty: defaultWF.notifyingParty,
                                replyingParty: wf.hasOwnProperty("replyingParty") ? wf.replyingParty : defaultWF.replyingParty,//can be changed so lookup to wf setting
                                actioningParty: defaultWF.actioningParty,
                                resolveActioningParty: defaultWF.resolveActioningParty,
                                quotingParty: wf.hasOwnProperty("quotingParty") ? wf.quotingParty : defaultWF.quotingParty,//can be changed so lookup to wf setting
                                quoteReplyingParty: wf.hasOwnProperty("quoteReplyingParty") ? wf.quoteReplyingParty : defaultWF.quoteReplyingParty,//can be changed so lookup to wf setting
                                quoteNotificationName: wf.hasOwnProperty("quoteNotificationName") ? wf.quoteNotificationName : defaultWF.quoteNotificationName,
                                quoteReferencePrefix: wf.hasOwnProperty("quoteReferencePrefix") ? wf.quoteReferencePrefix : defaultWF.quoteReferencePrefix,

                                form_IncludeCompanyName: wf.hasOwnProperty("form_IncludeCompanyName") ? wf.form_IncludeCompanyName : defaultWF.form_IncludeCompanyName,
                                form_CompanyLogo: wf.form_CompanyLogo ? wf.form_CompanyLogo : defaultWF.form_CompanyLogo,
                                form_CompanyName: wf.form_CompanyName ? wf.form_CompanyName : defaultWF.form_CompanyName,

                                notifyAttachments: wf.hasOwnProperty("notifyAttachments") ? wf.notifyAttachments : defaultWF.notifyAttachments,
                                replyAttachments: wf.hasOwnProperty("replyAttachments") ? wf.replyAttachments : defaultWF.replyAttachments,

                                includePdfjson: wf.hasOwnProperty("includePdfjson") ? wf.includePdfjson : defaultWF.includePdfjson,

                                //ATTRIBUTE OF ARRAY, OVERRIDES GLOBAL RESOURCES
                                overrideResources: wf.overrideResources ? JSON.parse(JSON.stringify(wf.overrideResources)) : null,

                                userFilters: transformedUserFilters
                            }
                            for (const key in newWF) {
                                if (newWF[key] === undefined) {
                                    delete newWF[key];
                                }
                            }
                            //#region ProgrammeReplyEnabled
                            if (wfg.id === 104 && propgrammeReplyPHIndex > -1) {
                                newWF.replyEnabled = this.getPlaceholderToggleValue(wfg, "ProgrammeReplyEnabled", true);
                            }
                            //#endregion
                            return newWF;
                        } catch (err) {
                            console.log(err)
                            console.log("Error IN WF", wf)
                            throw Error(err)
                        }

                    });
                    mappedWorkflows = mappedWorkflows.filter(wf => wf != null);
                    let placeholders = (wfg.placeholders || []);

                    //#region ProgrammeReplyEnabled
                    ///HANDLE ProgrammeReplyEnabled EXCEPTION
                    ///DEPRICATED NEEDS TO BE REMOVED
                    if (wfg.id === 104 && propgrammeReplyPHIndex > -1) {
                        placeholders.splice(propgrammeReplyPHIndex, 1)
                    }
                    //#endregion
                    const mappedPlaceholders = placeholders.map(ph => {
                        const mappedPhValues = ph.values.map(phVal => {
                            return {
                                id: phVal.id,
                                wfid: phVal.wfid,
                                isActive: phVal.isActive,
                                field: phVal.field,
                                display: phVal.display,
                                applyReplyPeriod: phVal.applyReplyPeriod,
                                replyPeriod: phVal.replyPeriod,
                                noReply: phVal.noReply,
                                overrideReplyingParty: phVal.overrideReplyingParty,
                                replyingParty: phVal.replyingParty,
                                effect: phVal.effect,
                                overrideResources: phVal.overrideResources ? JSON.parse(JSON.stringify(phVal.overrideResources)) : null,
                                postActions: phVal.postActions ? JSON.parse(JSON.stringify(phVal.postActions)) : null,
                            }
                        })
                        return {
                            replacementKey: ph.replacementKey,
                            values: mappedPhValues
                        }
                    });
                    return {
                        id: wfg.id,
                        title: wfg.title,
                        workflows: mappedWorkflows,
                        placeholders: mappedPlaceholders
                    }
                } catch (err) {
                    console.log("Error IN wfg:", wfg)
                    throw Error(err)
                }
            });
            mappedWorkflowGroups = mappedWorkflowGroups.filter(wfg => wfg != null && wfg.workflows && wfg.workflows.length > 0);

            const sanitizedTemplate = {
                id: template.id,
                name: template.name,
                isSystemDefault: template.isSystemDefault,
                isGlobal: template.isGlobal,
                companies: template.companies,
                resources: template.resources,
                workflowGroups: mappedWorkflowGroups,
                copyInfo: template.copyInfo ? [...template.copyInfo] : []
            }
            return sanitizedTemplate;
            // console.log(template);
        } catch (err) {
            console.log("Error IN templateSanitizer", template)
            throw Error(err)
        }

    }
    static getDefaultSanitizedTemplate = () => {
        const dummyTemplate = JSON.parse(JSON.stringify(DefaultTemplates[0]));
        const sanitizedDummyTemplate = TemplateHelper.templateSanitizer(dummyTemplate);
        return sanitizedDummyTemplate;
    }
    static getGlobalResourcesOverriden = (globalResourceList, WF_PH_Object) => {
        const newGlobalResources = JSON.parse(JSON.stringify(globalResourceList));
        //CHECK FOR WORKFLOW OVERRIDES
        if (WF_PH_Object && WF_PH_Object.overrideResources && WF_PH_Object.overrideResources.length > 0) {
            WF_PH_Object.overrideResources.forEach(wfOverrideDef => {
                const resourceDefinition = DefaultResources.find(rsc => rsc.key === wfOverrideDef.key);
                if (!resourceDefinition) {
                    throw new Error("Global resource definition not found! " + wfOverrideDef.key);
                }
                if (resourceDefinition.type === "NUMBER") {
                    try {
                        const numValue = Number(wfOverrideDef.value);
                        if (isNaN(numValue)) { throw new Error() }
                        newGlobalResources[wfOverrideDef.key] = numValue;
                    }
                    catch {
                        throw new Error("Global resource definition fail - Invalid number");
                    }
                }
                else {
                    newGlobalResources[wfOverrideDef.key] = wfOverrideDef.value;
                }
            })
        }
        return newGlobalResources;
    }
    static getGlobalResourceForClause = (resourceKey, globalResourceList, workflowItem, clauseList, selectedClause) => {
        const resourceDefinition = DefaultResources.find(rsc => rsc.key === 'SUPPLIER_NAME');
        if (!resourceDefinition) {
            throw new Error("Global resource definition not found! " + resourceKey);
        }
        let retVal = globalResourceList[resourceKey];

        //CHECK FOR WORKFLOW OVERRIDES
        if (workflowItem && workflowItem.overrideResources && workflowItem.overrideResources.length > 0) {
            const wfOverrideDef = workflowItem.overrideResources.find(x => x.key === resourceKey);
            if (wfOverrideDef) {
                if (resourceDefinition.type === "NUMBER") {
                    try {
                        retVal = Number(wfOverrideDef.value);
                        if (isNaN(retVal)) { throw new Error() }
                    }
                    catch {
                        throw new Error("Global resource definition fail - Invalid number");
                    }
                }
                else {
                    retVal = wfOverrideDef.value;
                }
            }
        }

        //CHECK FOR CLAUSE OVERRIDES
        if (clauseList && selectedClause) {

        }
    }
}