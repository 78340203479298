import React from 'react';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { Alert } from "react-bootstrap";
import WarningRiskNotifications from './RiskNotifications';
import WarningRiskUpdate from './RiskUpdate';
import WarningRiskAssessment from './RiskAssessment';
import HTMLReactParser from 'html-react-parser';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";
import * as earlyWarningReductionCrud from '../../../../crud/earlyWarningReduction.crud';

class WarningRisk extends React.Component {
    constructor(props) {
        super(props);
        this.riskOptions = [
            { value: "Very Low", effect: 1, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.Very Low' }) },
            { value: "Low", effect: 2, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.Low' }) },
            { value: "Medium", effect: 3, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.Medium' }) },
            { value: "High", effect: 4, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.High' }) },
            { value: "Very High", effect: 5, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.Very High' }) },
        ];
        this.state = {
            warning: null
        }
    }
    componentWillUnmount() {
        clearTimeout(this.timer);
    }
    tabNotifications = () => {
        return (
            <WarningRiskNotifications
                warning={this.props.notification}
                warningReduction={this.state.warning}
                title={this.props.title}
                workflowItem={this.props.workflowItem}
            />
        );
    }
    fetchEWReduction = async () => {
        let { data: reduction } = await earlyWarningReductionCrud.getLastEarlyWarningReduction(this.props.notification.warningid);
        if (reduction === "NULL") {
            reduction = {};
        }
        this.setState({ warning: reduction });
    }
    onRiskAssessmentUpdate = () => {
        //Inform parent about update
        this.props.onSubmitUpdate();
    }
    onEWReductionUpdate = async () => {
        //Inform parent about update
        await this.fetchEWReduction();
        //Auto swith selected tab to default
        this.timer = setTimeout(() => {
            if ((this.tabPanel || {}).instance) {
                this.tabPanel.instance.resetOption('selectedIndex')
            }
        }, 300);
    }
    tabUpdate = () => {
        return (
            <WarningRiskUpdate
                customFieldsCommon={this.props.customFieldsCommon}
                warningid={this.props.notification.warningid}
                onEWReductionUpdate={this.onEWReductionUpdate}
                warning={this.state.warning}
            />
        );
    }
    tabAssessment = () => {
        return (
            <WarningRiskAssessment
                {...this.props}
                onRiskAssessmentUpdate={this.onRiskAssessmentUpdate}
                warning={this.props.notification}
            />
        );
    }
    tabResolve = () => {
        const editorFormConfig = {
            buttonResource: "CONTRACT.WF.EW.BUTTON.RESOLVE_RISK",
            fields: [
                { resource: 'CONTRACT.WF.EW.FIELD.RESOLVED', dataField: 'ResolvedDate', editorType: 'dxDateBox', editorOptions: { calendarOptions: { max: new Date() } }, validation: { type: 'range', max: new Date(), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE_FUTURE" }) } },
            ]
        };
        return (
            <div style={{ margin: '40px 20px 20px 20px', maxWidth: 400 }}>
                <BiDynamicForm
                    config={editorFormConfig}
                    onSubmitHandler={(item, setSubmitting) => this.props.onResolveRisk(item.ResolvedDate, setSubmitting)} />
            </div>
        );
    }
    calculateRiskPriority = (severity, likelihood) => {
        const riskPoint = severity * likelihood;
        if (riskPoint >= 0 && riskPoint <= 4) {
            return "Low";
        } else if (riskPoint >= 5 && riskPoint <= 10) {
            return "Medium";
        } else if (riskPoint >= 11 && riskPoint <= 25) {
            return "High";
        }
    }
    renderInfoView = () => {
        let likelihood = this.props.intl.formatMessage({ id: 'GENERAL.WORDS.UNDEFINED' });
        let severity = this.props.intl.formatMessage({ id: 'GENERAL.WORDS.UNDEFINED' });
        const likelihoodItem = this.riskOptions.find(itm => itm.value === this.props.notification.likelihood);
        if (likelihoodItem) {
            likelihood = likelihoodItem.display;
        } else { console.log(`likelihood item not fount for:${this.props.notification.likelihood}`) }
        const severityItem = this.riskOptions.find(itm => itm.value === this.props.notification.severity);
        if (severityItem) {
            severity = severityItem.display;
        } else { console.log(`severity item not fount for:${this.props.notification.severity}`) }

        const priority = this.calculateRiskPriority(this.props.notification.severity, this.props.notification.likelihood);
        const riskStatus = this.props.notification.riskstatus;

        return (
            <>
                {
                    this.props.notification.status === 'Resolved' && (
                        <Alert variant="success" style={{ display: 'block', margin: 20 }} >
                            <Alert.Heading><FormattedMessage id="CONTRACT.WF.EW.LABEL.RISK_RESOLVED" /> </Alert.Heading>
                            <hr />
                            <p>
                                <FormattedMessage id="CONTRACT.WF.EW.WARNING.CLOSED" values={{ ID: this.props.notification.referenceid, TIME_STAMP: this.props.notification.resolvedate ? moment(this.props.notification.resolvedate).format('L') : '' }} />
                            </p>
                        </Alert>
                    )
                }
                <Alert variant="secondary" style={{ display: 'block', margin: 20 }}>
                    <Alert.Heading><FormattedMessage id="CONTRACT.WF.EW.LABEL.RISK_DETAILS" /> </Alert.Heading>
                    <hr />
                    <p>
                        <strong><FormattedMessage id="CONTRACT.WF.EW.FIELD.LIKELIHOOD" /> : </strong> {likelihood} <br />
                        <strong><FormattedMessage id="CONTRACT.WF.EW.FIELD.SEVERITY" /> : </strong> {severity}<br />
                        <strong><FormattedMessage id="CONTRACT.WF.EW.FIELD.PRIORITY" />: </strong> {priority}<br />
                        <strong><FormattedMessage id="GENERAL.FORMS.FIELD.ACTION_OWNER" />: </strong> {this.props.notification.owner}<br />
                        <strong><FormattedMessage id="GENERAL.FORMS.FIELD.SOLUTION" />: </strong> <br />
                        <strong><FormattedMessage id="CONTRACT.WF.EW.FIELD.RISK_STATUS" />: </strong> {riskStatus}<br />
                    </p>
                    {this.props.notification.solution ? HTMLReactParser(this.props.notification.solution) : null}

                </Alert>
                <WarningRiskNotifications
                    warning={this.props.notification}
                    workflowItem={this.props.workflowItem}
                    title={this.props.title}
                />
                {/* {this.tabNotifications()} */}
            </>
        );
    }
    render() {
        if (this.props.status === 'Resolved' ||
            (!this.props.workflowItem.userFilters.actionTab.actionAllowed &&
            !this.props.workflowItem.userFilters.resolveActionTab.resolveActionAllowed)) {
            return this.renderInfoView();
        }
        return (
            <TabPanel
                width="100%"
                style={{ marginTop: 10 }}
                ref={(ref) => this.tabPanel = ref}
                //selectedIndex = {this.state.selectedTab}
                deferRendering={false}
                animationEnabled={true}
                focusStateEnabled={true}
                hoverStateEnabled={true}
                swipeEnabled={false}>
                {this.props.workflowItem.userFilters.actionTab.actionAllowed && <Item title={this.props.intl.formatMessage({ id: 'CONTRACT.WF.EW.LABEL.RISK_REDUCTION' })} render={this.tabNotifications} />}
                {this.props.workflowItem.userFilters.actionTab.actionAllowed && <Item title={this.props.intl.formatMessage({ id: 'CONTRACT.WF.DEFECTS.TAB.ACTIONS' })} render={this.tabUpdate} />}
                {this.props.workflowItem.userFilters.actionTab.actionAllowed && <Item title={this.props.intl.formatMessage({ id: 'CONTRACT.WF.EW.TITLE.RISK_ASSESSMENT' })} render={this.tabAssessment} />}
                {this.props.workflowItem.userFilters.resolveActionTab.resolveActionAllowed && <Item title={this.props.intl.formatMessage({ id: 'CONTRACT.WF.EW.BUTTON.RESOLVE_RISK' })} render={this.tabResolve} />}
            </TabPanel>
        );
    }
}

export default injectIntl(WarningRisk);
