import React, { Component } from "react";
import { connect } from "react-redux";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import BiDynamicForm from "../../../../partials/layout/BiDynamicForm";
import { withSnackbar } from "notistack";
import * as contractDuck from "../../../../store/ducks/contract.duck";
import * as activityCrud from "../../../../crud/activity.crud";
import * as quotationCrud from "../../../../crud/quotation.crud";
import SimpleBox from "../../../../partials/content/SimpleBox";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import { injectIntl } from "react-intl";
import DataGrid, {
  Column,
  SearchPanel,
  Export,
  Paging,
  ColumnChooser,
  ColumnFixing,
  Editing,
  HeaderFilter,
  FilterPanel,
} from "devextreme-react/data-grid";
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

const costBreakdown = {
  companyid: null,
  projectid: null,
  contractid: null,
  usersource: "Quotation",
  accountid: null,
  type: "Activity",
  createbyid: 1,
  deleted: 0,
  activity: null,
  oldprice: null,
  newprice: null,
  ponumber: null,
  ponumberreferencecode: null,
};

class CostBreakdown extends Component {
  constructor(props) {
    super(props);
    this.validatorRef = React.createRef();
    this.state = {
      activityId: 0,
      activityName: "",
      poNumber: null,
      sopCode: null,
      price: 0
    };
  }

  componentDidMount() {
    this.props.loadActivities();
  }

  onValueChanged = (field, activityId) => {
    if (field === "activity") {
      let activityName = this.props.activities.find(
        (activity) => activity.activityscheduleid === activityId
      ).name;

      let poNumber = this.props.activities.find(
        (activity) => activity.activityscheduleid === activityId
      ).ponumber;

      let sopCode = this.props.activities.find(
        (activity) => activity.activityscheduleid === activityId
      ).ponumberreferencecode;

      let price = this.props.activities.find(
        (activity) => activity.activityscheduleid === activityId
      ).price;

      this.setState({ activityName, activityId, poNumber, sopCode, price });
    }
  };

  handleSubmit = (activity, setSubmitting, clearForm) => {
    activity.companyid = this.props.contract.companyid;
    activity.contractid = this.props.contract.contractid;
    activity.projectid = this.props.contract.projectid;
    activity.quoteid = this.props.notification.quotationid;
    activity.ponumber = this.state.poNumber;
    activity.ponumberreferencecode = this.state.sopCode;
    activity.oldprice = this.state.price;
    activity.activityId = this.state.activityId;
    activity.name = this.state.activityName;
    activity.status = 0;

    quotationCrud.saveQuotationActivity(activity)
      .then(() => {
        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_ACTIVITY_SUCCESS' }), {
          variant: "success",
        });
        this.props.loadQuotationActivitiesList();
        setSubmitting(false);
        clearForm();
      })
      .catch((err) => {
        this.props.enqueueSnackbar(err.toString(), { variant: "error" });
        setSubmitting(false);
      });
  };

  onRowRemoved = (rowdata) => {
    const activity = { ...rowdata.data };
    activity.deleted = 1;

    activityCrud
      .saveActivity(activity)
      .then(() => {
        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_ACTIVITY_DELETED' }), {
          variant: "success",
        });
        this.props.loadQuotationActivitiesList();
      })
      .catch((err) => {
        this.props.enqueueSnackbar(err.toString(), { variant: "error" });
      });
  };
  onExporting = (e) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer()
        .then(function (buffer) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CostBreakdown.xlsx');
        });
    });
  }
  render() {
    if (!this.props.contract || !this.props.activities) {
      return <PortletSpinner />;
    }

    const documentIsFinal = this.props.notification.showtasknotification === 1;

    const costBreakdownFormConfig = {
      currencyCode: this.props.contract.currency,
      buttonResource: 'GENERAL.FORMS.BUTTON_ADD_ACTIVITY',
      fields: [
        {
          resource: 'GENERAL.FORMS.BUTTON_ADD_ACTIVITY',
          fields: [
            {
              resource: 'GENERAL.FORMS.BUTTON_SELECT_ACTIVITY',
              dataField: "activity",
              editorType: "dxSelectBox",
              editorOptions: {
                dataSource: this.props.activities,
                valueExpr: "activityscheduleid",
                displayExpr: "name",
              },
            },
            {
              resource: 'GENERAL.FORMS.FIELD.CURRENT_AMOUNT',
              dataField: "oldprice",
              editorOptions: {
                readOnly: true,
                value: this.state.price,
              },
              required: false,
              editorType: "dxNumberBox",
            },
            {
              resource: 'GENERAL.FORMS.FIELD.NEW_AMOUNT',
              dataField: "newprice",
              editorType: "dxNumberBox",
            },
            {
              resource: 'CONTRACT.FIELD.PO_NUMBER',
              dataField: "ponumber",
              editorOptions: {
                placeholder: this.props.intl.formatMessage({ id: 'CONTRACT.FIELD.PO_NUMBER' }),
                readOnly: true,
                value: this.state.poNumber,
              },
              required: false,
            },
            {
              resource: 'GENERAL.FORMS.FIELD.SOP_CODE',
              dataField: "ponumberreferencecode",
              editorOptions: {
                placeholder: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.SOP_CODE' }),
                value: this.state.sopCode,
                readOnly: true,
              },
              required: false,
            },
          ],
        },
      ],
    };

    const columns = [
      { resource: 'GENERAL.FORMS.FIELD.ACTIVITY', dataField: "name" },
      { resource: 'GENERAL.FORMS.FIELD.SOP_CODE', dataField: "ponumberreferencecode" },
      { resource: 'CONTRACT.FIELD.PO_NUMBER', dataField: "ponumber" },
      {
        resource: 'GENERAL.FORMS.FIELD.CURRENT_AMOUNT',
        dataField: "oldprice",
        dataType: "currency",
      },
      { resource: 'GENERAL.FORMS.FIELD.NEW_AMOUNT', dataField: "newprice", dataType: "currency", currencyCode:this.props.contract.currency },
    ];

    return (
      <Portlet>
        <PortletBody>
          {!documentIsFinal && this.props.workflowItem.userFilters.mainNotice.communicateAllowed && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            >
              <BiDynamicForm
                validatorRef={this.validatorRef}
                item={costBreakdown}
                config={costBreakdownFormConfig}
                valueChangeHandler={this.onValueChanged}
                onSubmitHandler={this.handleSubmit}
              />
            </div>
          )}
          <div style={{ height: 20 }} />
          <SimpleBox title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_COST_BREAKDOWN' })}>
            <DataGrid
              dataSource={this.props.quotationActivitiesList}
              keyExpr="quoteid"
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              remoteOperations={false}
              allowColumnReordering={true}
              columnResizingMode="widget"
              onContextMenuPreparing={this.addGridContextMenuItems}
              onExporting={this.onExporting}
              //onRowRemoved={this.onRowRemoved}
            >
              <Editing
                mode="row"
                allowUpdating={false}
                allowDeleting={false}
                allowAdding={false}
              />
              <HeaderFilter visible={true} />
              <FilterPanel visible={true} />
              <ColumnChooser enabled={true} />
              <ColumnFixing enabled={true} />
              <Export enabled={true} fileName={this.props.name} />
              <SearchPanel
                visible={true}
                highlightCaseSensitive={false}
                defaultText=""
              />
              {columns.map((columnItem, index) => (
                <Column
                  cssClass="WrappedColumnClass"
                  key={index}
                  {...columnItem}
                />
              ))}
              <Paging pageSize={10} defaultPageSize={10} defaultPageIndex={0} />
            </DataGrid>
          </SimpleBox>
        </PortletBody>
      </Portlet>
    );
  }
}

const mapStateToProps = (store) => ({
  contract: store.contract.contract,
  activities: store.contract.activities,
});

const mapDispatchToProps = {
  loadActivities: contractDuck.actions.fetchActivities,
};

export default injectIntl(withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(CostBreakdown)
));
