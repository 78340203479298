import axios from '../shared/axios';


export function exportContractArchive(contractid) {
    return axios.post(`api/Archive/ArchiveContract?contractid=${contractid}`)
}



// export function getContractExport(contractid, projectid, companyid){
//     console.log(contractid)
//     return axios.post(`api/Administrator/ContractExport?&contractid=${contractid}` )
// }

// export function getContractExport(contractid, projectid, companyid){
//     console.log(contractid)
//     return axios.post(`api/Archive/GetArchives?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}`, )
// }

export function loadContractExports(contractid) {

    return axios.get(`api/Archive/GetArchives?contractid=${contractid}`)
}

export function downloadContractExportZipFile(contractexportid, options) {

    return axios.get(`api/Archive/DownloadZipFile?contractexportid=${contractexportid}`, { responseType: 'blob', ...options })
}

