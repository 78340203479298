import React from 'react';
import { connect } from "react-redux";
import { Popup } from 'devextreme-react/popup';
import * as applicationDuck from "../../../app/store/ducks/application.duck";

class SystemMessageDialog extends React.Component {
  onClose = () => {
    this.props.clearSystemMessage();
  }

  render() {
    return (
      <Popup
        visible={this.props.systemMessage.showModal}
        onHiding={this.onClose}
        dragEnabled={true}
        hideOnOutsideClick={true}
        showTitle={true}
        title={this.props.systemMessage.title}
        width={600}
        height='auto'>
        <>
          <div>
            {this.props.systemMessage.message && (
              <span>
                {
                  this.props.systemMessage.message.split("\n").map((line, index) => {
                    return <div key={index}>{line}</div>
                  }
                  )
                }
              </span>
            )}

            {this.props.systemMessage.htmlRenderString && (
              <div dangerouslySetInnerHTML={{ __html: this.props.systemMessage.htmlRenderString }} />
            )}
          </div>
        </>

      </Popup>
    );
  }
}

const mapStateToProps = state => ({
  systemMessage: state.application.systemMessage,

});

const mapDispatchToProps = {
  clearSystemMessage: applicationDuck.actions.clearSystemMessage
};

export default (connect(mapStateToProps, mapDispatchToProps)(SystemMessageDialog));