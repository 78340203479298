import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import FirstWizardPage from "./FirstWizardPage";
import SecondWizardPage from "./SecondWizardPage";
import { injectIntl } from "react-intl";
import { confirm } from 'devextreme/ui/dialog';

class CurrencyBatchModal extends React.Component {
    constructor(props) {
        super(props);
        this.validatorRef = React.createRef();
        this.validatorName = 'PaymentWizardModalValidator';
        this.workflowItem = this.props.workflowsQA['38'];
        this.state = {
            paymentCertificateMultipleCurrencies: [],
            changed: false,
            page: null,
            paymentIdsAndCurrencyNamesList: null
        };
    }
    handlePaymentCertificateMultipleCurrencies = (items, paymentIdsAndCurrencyNamesList) => {
        this.setState({ paymentCertificateMultipleCurrencies: items, paymentIdsAndCurrencyNamesList, changed: false });
    }
    tabFirstWizardPage = () => {
        return (
            <div style={{ overflowY: 'auto' }}>
                <FirstWizardPage
                    {...this.props}
                    onHandlePaymentCertificateMultipleCurrencies={this.handlePaymentCertificateMultipleCurrencies}
                    onValueChanged={this.onValueChanged}
                />
            </div>
        );
    };
    tabSecondWizardPage = () => {
        return (
            <div style={{ overflowY: 'auto' }}>
                <SecondWizardPage
                    {...this.props}
                    onHandlePaymentCertificateMultipleCurrencies={this.handlePaymentCertificateMultipleCurrencies}
                    paymentCertificateMultipleCurrencies={this.state.paymentCertificateMultipleCurrencies}
                    onFilesCountChanged={(fCount) => { if (this.state.fileCount !== fCount) { this.setState({ fileCount: fCount }) } }}
                    onValueChanged={this.onValueChanged}
                    onClose={this.onClose}
                    paymentIdsAndCurrencyNamesList={this.state.paymentIdsAndCurrencyNamesList}
                />
            </div>
        );
    };
    onValueChanged = (changed, page) => {
        this.setState({ changed, page });
    }
    onClose = async (e, skipChecks) => {
        if (!skipChecks && this.state.changed && this.state.page) {
            e.cancel = true;

            let warningMessage = '';
            let warningMessageTitle = '';

            if (this.state.page === 'first') {
                warningMessage = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' });
                warningMessageTitle = this.props.intl.formatMessage({ id: 'GENERAL.WARNING.LEAVE_CHANGES_TITLE' });
            }
    
            if (this.state.page === 'second' && this.props.workflowItem.userFilters.mainNotice.communicateAllowed) {
                warningMessage = this.props.intl.formatMessage({ id: 'CURRENCY_BATCH_MODAL.SAVE_DRAFT.WARNING' },{ ITEM_NAME: this.props.workflowItem.notificationName });
                warningMessageTitle = this.props.intl.formatMessage({ id: 'CURRENCY_BATCH_MODAL.SAVE_DRAFT.WARNING_TITLE' });
            }
            let result = true;
            if(warningMessage){
                result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageTitle}`);
            }
            if (!result) {
                return; // User cancelled
            }
        }
        
        this.setState({ paymentCertificateMultipleCurrencies: [], changed: false, page: null }, () => {
            if (this.props.visible) {
                //LET KNOW REGISTER IF IT NEEDS TO REFRESH
                let paymentRegisterNeedsRefresh = true;
                this.props.onClose(paymentRegisterNeedsRefresh);
            }
        })
    }
    render() {
        if (!this.props.visible) {
            return null;
        }
        return (
            <Popup
                visible={this.props.visible}
                onHiding={this.onClose}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showTitle={true}
                title={this.props.intl.formatMessage({ id: 'CONTRACT.WF.PAYMENT.WIZARD' })}
                // fullScreen={true}
                minWidth={800}
                minHeight={600}
                width='90vw'
                height='92vh'
            >
                <TabPanel
                    width="100%"
                    height="100%"
                    deferRendering={true}
                    animationEnabled={true}
                    focusStateEnabled={true}
                    hoverStateEnabled={true}
                    swipeEnabled={false}
                >
                    {!this.state.paymentCertificateMultipleCurrencies.length
                        ? <Item height="100%"
                            render={this.tabFirstWizardPage}
                        />
                        : <Item height="100%"
                            render={this.tabSecondWizardPage}
                        />
                    }

                </TabPanel>

            </Popup>
        );
    }
}

export default injectIntl(CurrencyBatchModal);
