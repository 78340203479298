import React from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, { SimpleItem, EmptyItem, ButtonItem } from 'devextreme-react/form';
import DefaultResources from '../../../../../Templates/DefaultResources';

class GlobalResorcesModal extends React.Component {
    constructor() {
        super();
        this.state = {
            item: null,
            options: null
        };
    }

    componentDidMount() {
        if (this.props.item) {
            const defaultRsc = this.getDefaultResorce(this.props.item.key);
            this.setState({ item: { ...this.props.item }, options: (defaultRsc || {}).options });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.item !== this.props.item) {
                if (this.props.item) {
                    const defaultRsc = this.getDefaultResorce(this.props.item.key);
                    this.setState({ item: { ...this.props.item }, options: (defaultRsc || {}).options });
                }
                else {
                    this.setState({ item: null, options: null });
                }

            }
        }
    }
    getDefaultResorce = (searchKey) => {
        const defaultRsc = DefaultResources.find(x => x.key === searchKey);
        if (!defaultRsc) { alert("This resource is no longer supported!, please remove from template"); return null; };
        return defaultRsc;
    }
    onClose = () => {
        this.setState({ item: null });
        this.props.onClose();
    }
    saveItem = () => {
        this.props.onClose({ ...this.state.item });
        this.setState({ item: null });
    }
    render() {
        //const dataFieldProp = { editorType: '' }
        return (
            <Popup
                visible={this.props.item ? true : false}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showTitle={true}
                title={this.state.item && this.state.item.id && this.state.item.id > 0 ? "Edit Resource" : "Create New Resource"}
                width={600}
                height='auto'>
                <Form
                    formData={this.state.item}
                    readOnly={false}
                    showColonAfterLabel={true}
                    repaintChangesOnly={false}
                    cacheEnabled={false}>
                    <SimpleItem dataField="key" editorOptions={{ readOnly: true }} />
                    {!this.state.options && (
                        <SimpleItem dataField="value" editorType={this.state.item && this.state.item.type === 'NUMBER' ? 'dxNumberBox' : 'dxTextBox'} editorOptions={{ readOnly : this.props.readOnly }} />
                    )}
                    {this.state.options && (
                        <SimpleItem dataField="value" editorType={'dxSelectBox'} editorOptions={{ dataSource: this.state.options, readOnly : this.props.readOnly }} />
                    )}
                    <EmptyItem />
                    <ButtonItem horizontalAlignment="right"
                        buttonOptions={{
                            text: this.state.item && this.state.item.id && this.state.item.id > 0 ? 'Update Resource' : 'Add Resource',
                            type: 'default',
                            onClick: this.saveItem,
                            disabled:this.props.readOnly
                        }}
                    />
                </Form>
            </Popup>
        );
    }
}

export default GlobalResorcesModal;