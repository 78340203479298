import { scheduledTasks as ContractScheduledTasks } from '../ducks/contract.duck'
import { scheduledTasks as AuthScheduledTasks } from '../ducks/auth.duck'
const timerTickIntervalSec = Number(process.env.REACT_APP_CLOCK_INTERVAL);
export default class Scheduler {
    static StartClock = () => {
        if (this.timerId) {
            clearInterval(this.timerId);
        }
        this.timerId = setInterval(Scheduler.ApplicationClock, (timerTickIntervalSec * 1000));
    }
    static StopClock = () => {
        if (this.timerId) {
            clearInterval(this.timerId);
        }
    }

    static ApplicationClock = () => {
        ContractScheduledTasks();
        AuthScheduledTasks();
    }
}