/* eslint-disable import/no-anonymous-default-export */
import Workflows from "./Workflows";
import Placeholders from "./Placeholders";
export default {
    id: 104,
    title: 'Programme',
    path: 'programme',
    icon: "flaticon2-calendar-8",    
    workflows: Workflows,
    placeholders: Placeholders,
};