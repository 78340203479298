import React from 'react';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { Alert } from "react-bootstrap";
import HTMLReactParser from 'html-react-parser';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";
class DefectAction extends React.Component {

    componentWillUnmount() {
        clearTimeout(this.timer);
    }
    onSubmitUpdate = (defect, setSubmitting) => {
        this.props.onSubmitUpdate(defect, setSubmitting);
        this.timer = setTimeout(() => this.tabPanel.instance.resetOption('selectedIndex'), 300);
    }
    tabUpdate = () => {
        const editorFormConfig = {
            buttonResource: this.props.workflowItem.notificationName.toLowerCase().includes('defect') ? "CONTRACT.WF.DEFECTS.BUTTON.UPDATE_DEFECT_ACTIONS" : "CONTRACT.WF.DEFECTS.BUTTON.UPDATE_ACTIONS",
            fields: [
                { resource: "GENERAL.FORMS.FIELD.ACTION_OWNER", dataField: 'replyaction' },
                { resource: "GENERAL.FORMS.FIELD.ACTION", dataField: 'replycomment', editorType: 'dxHtmlEditor' },
            ]
        };
        return (
            <div style={{ margin: 20, maxWidth: 800 }}>
                <BiDynamicForm
                    item={this.props.notification}
                    config={editorFormConfig}
                    onSubmitHandler={this.onSubmitUpdate} />
            </div>
        );
    }
    tabPlanned = () => {
        const editorFormConfig = {
            buttonResource: "GENERAL.FORMS.BUTTON_UPDATE",
            fields: [
                { resource: "GENERAL.FORMS.FIELD.PLANNED_CLOSURE", dataField: 'plannedclosuredate', editorType: 'dxDateBox' },
            ]
        };
        return (
            <div style={{ margin: '40px 20px 20px 20px', maxWidth: 400 }}>
                <BiDynamicForm
                    item={this.props.notification}
                    config={editorFormConfig}
                    onSubmitHandler={(item, setSubmitting) => this.props.onPlannedUpdate(item.plannedclosuredate, setSubmitting)} />
            </div>
        );
    }
    tabCorrect = () => {
        const editorFormConfig = {
            buttonResource: this.props.workflowItem.notificationName.toLowerCase().includes('defect') ? "CONTRACT.WF.DEFECTS.BUTTON.CLOSE_DEFECT" : "CONTRACT.WF.DEFECTS.BUTTON.CLOSE",
            fields: [
                { resource: "CONTRACT.WF.DEFECTS.FIELD.CLOSE_DATE", dataField: 'CorrectionDate', editorType: 'dxDateBox', editorOptions: { calendarOptions: { max: new Date() } }, validation: { type: 'range', max: new Date(), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE_FUTURE" })} }
            ]
        };

        if (this.props.ClosureReasonOptions === null || Object.keys(this.props.ClosureReasonOptions).length > 0) {
            editorFormConfig.fields.push({ label: this.props.ClosureReasonLabel, dataField: 'closurereason', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.ClosureReasonOptions, valueExpr: 'display', displayExpr: 'display'} });
        }

        return (
            <div style={{ margin: '40px 20px 20px 20px', maxWidth: 400 }}>
                <BiDynamicForm
                    config={editorFormConfig}
                    onSubmitHandler={(item, setSubmitting) => this.props.onResolveRisk(item, setSubmitting)} />
            </div>
        );
    }

    renderInfoView = () => {
        return (
            <>
                {
                    this.props.notification.replystatus === 'Corrected' && (
                        <Alert variant="success" style={{ display: 'block', margin: 20 }} >
                            <Alert.Heading><FormattedMessage id="CONTRACT.WF.DEFECTS.LABEL.DEFECT_CLOSED" /></Alert.Heading>
                            <hr />
                            <p>
                                <FormattedMessage id="CONTRACT.WF.DEFECTS.WARNING.CLOSED" values={{ ID: this.props.notification.referenceid, TIME_STAMP: this.props.notification.inspectiondate ? moment(this.props.notification.inspectiondate).format('L') : '' }} />
                            </p>
                        </Alert>
                    )
                }
                <Alert variant="secondary" style={{ display: 'block', margin: 20 }}>
                    <Alert.Heading><FormattedMessage id="CONTRACT.WF.DEFECTS.LABEL.DEFECT_ACTIONS" /></Alert.Heading>
                    <hr />
                    <p>
                        <strong><FormattedMessage id="GENERAL.FORMS.FIELD.OWNER" />: </strong> {this.props.notification.replyaction}<br />
                        <strong><FormattedMessage id="GENERAL.FORMS.FIELD.SOLUTION" />: </strong> <br />
                    </p>
                    {this.props.notification.replycomment ? HTMLReactParser(this.props.notification.replycomment) : null}

                </Alert>
            </>
        );
    }
    render() {
        const isCorrected = (this.props.notification.replystatus === 'Corrected');
        if (!this.props.workflowItem.userFilters.actionTab.actionAllowed &&
            !this.props.workflowItem.userFilters.resolveActionTab.resolveActionAllowed) {
            return this.renderInfoView();
        }
        return (
            <TabPanel
                width="100%"
                style={{ marginTop: 10 }}
                ref={(ref) => this.tabPanel = ref}
                //selectedIndex = {this.state.selectedTab}
                deferRendering={false}
                animationEnabled={true}
                focusStateEnabled={true}
                hoverStateEnabled={true}
                swipeEnabled={false}>
                {isCorrected
                    ? <Item title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.ACTION' })} render={this.renderInfoView} />
                    : this.props.workflowItem.userFilters.actionTab.actionAllowed && <Item title={this.props.intl.formatMessage({ id: 'CONTRACT.WF.DEFECTS.TAB.UPDATE_ACTION' })} render={this.tabUpdate} />
                }
                {this.props.plannedClosureEnabled && this.props.workflowItem.userFilters.actionTab.actionAllowed && !this.props.NCSubmissionWFexist && (<Item title={this.props.intl.formatMessage({ id: 'CONTRACT.WF.DEFECTS.TAB.PLANNED_CLOSURE' })} render={this.tabPlanned} />)}
                {!isCorrected && this.props.workflowItem.userFilters.resolveActionTab.resolveActionAllowed && (<Item title={this.props.workflowItem.notificationName.toLowerCase().includes('defect') ? this.props.intl.formatMessage({ id: 'CONTRACT.WF.DEFECTS.BUTTON.CLOSE_DEFECT' }) : this.props.intl.formatMessage({ id: 'CONTRACT.WF.DEFECTS.BUTTON.CLOSE' })} render={this.tabCorrect} />)}
            </TabPanel>
        );
    }
}

export default injectIntl(DefectAction);
