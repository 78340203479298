const template = {
    name: "Default JCT ICD 2016 v2.5 01/01/2024",
    isSystemDefault: true,
    isGlobal: true,
    companies: [],
    resources: [
        {
            id: 1,
            type: "TEXT",
            key: "SUPPLIER_NAME",
            value: "Contractor"
        },
        {
            id: 2,
            type: "TEXT",
            key: "SUPPLIER_ABBREVIATION",
            value: "CON"
        },
        {
            id: 3,
            type: "TEXT",
            key: "PM_NAME",
            value: "Architect/Contract Administrator"
        },
        {
            id: 4,
            type: "TEXT",
            key: "PM_ABBREVIATION",
            value: "A/CA"
        },
        {
            id: 7,
            type: "TEXT",
            key: "CLIENT_NAME",
            value: "Employer"
        },
        {
            id: 8,
            type: "NUMBER",
            key: "SUPPLIER_REPLY_PERIOD",
            value: 7
        },
        {
            id: 9,
            type: "NUMBER",
            key: "PM_REPLY_PERIOD",
            value: 7
        },
        {
            id: 10,
            type: "NUMBER",
            key: "QUOTE_DURATION",
            value: 21
        },
        {
            id: 13,
            type: "NUMBER",
            key: "REQUOTE_DURATION",
            value: 21
        },
        {
            id: 11,
            type: "NUMBER",
            key: "QUOTE_RESPONSE",
            value: 14
        },
        {
            id: 14,
            type: "NUMBER",
            key: "REQUOTE_RESPONSE",
            value: 14
        },
        {
            id: 12,
            type: "NUMBER",
            key: "VARIATION_RESPONSE",
            value: 7
        },
        {
            id: 16,
            type: "NUMBER",
            key: "PROGRAMME_REPLY_PERIOD",
            value: 14
        },
        {
            id: 5,
            type: "TEXT",
            key: "SUPERVISOR_NAME",
            value: "Quantity Surveyor"
        },
        {
            id: 8,
            type: "TEXT",
            key: "CLIENT_ABBREVIATION",
            value: "EMP"
        },
        {
            id: 6,
            type: "TEXT",
            key: "SUPERVISOR_ABBREVIATION",
            value: "QS"
        },
        {
            id: 26,
            type: "NUMBER",
            key: "CLIENT_REPLY_PERIOD",
            value: 7
        },
        {
            id: 27,
            type: "NUMBER",
            key: "SUPERVISOR_REPLY_PERIOD",
            value: 7
        }
    ],
    workflowGroups: [
        {
            id: 102,
            title: "Instructions",
            workflows: [
                {
                    id: 4,
                    title: "A/CA Instruction",
                    notificationName: "Instruction",
                    abbreviation: "$PM_ABBREVIATION-Instruction-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "InstructionTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "2.1.2 Direction for the integration of the Contractor's design with the design of the whole of the Works"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "2.9 To not correct an error in setting out"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "2.11.1 To enable the $SUPPLIER_NAME to carry out the Works"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "2.12.2 To correct an error in the Bill of Quantities"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "2.13.1 To correct a departure, error or omission"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "2.15.2 To correct a divergence between Statutory Requirements and a Contract Document"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "2.30 To not make good made good a defect or defects"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "3.11 Requiring a Variation"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "3.12 To postpone works"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "3.13 To expend a provisional sum"
                        },
                        {
                            id: 30,
                            isActive: true,
                            field: "",
                            display: "3.14 To open work up for inspection or carry out any tests"
                        },
                        {
                            id: 31,
                            isActive: true,
                            field: "",
                            display: "3.15.1 To open work up for inspection or carry out any tests (work not in accordance with the Contract)"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "3.16.1 To remove work, materials or goods which are not in accordance with the Contract"
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "3.16.2 As a consequence of work not carried out in a proper and workmanlike manner"
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "6.5.1 To take out and maintain insurance for the liability of the $CLIENT_NAME"
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "6.10.4 On behalf of the $CLIENT_NAME not to renew Terrorism Cover"
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "",
                            display: "6.17.1.2 To carry out Remedial Measures"
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.2 In connection with a Named Subcontractor's appointment"
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.4 That the work is carried out by someone other than the Named Subcontractor"
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.7 In connection with the termination of a Named Subcontractor"
                        },
                        {
                            id: 29,
                            isActive: true,
                            field: "",
                            display: "Sch. 5.3.3 In connection with a value engineering submission"
                        },
                        {
                            id: 28,
                            isActive: true,
                            field: "",
                            display: "Other Instruction"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PM",
                            display: "$PM_NAME Instructions awaiting reply"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Client",
                            display: "$CLIENT_NAME Instructions awaiting reply"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Supervisor",
                            display: "$SUPERVISOR_NAME Instructions awaiting reply"
                        }
                    ]
                }
            ]
        },
        {
            id: 104,
            title: "Programme",
            workflows: [
                {
                    id: 14,
                    title: "$SUPPLIER_NAME Programme",
                    notificationName: "Programme",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Programme-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "ReasonLabel",
                            display: "Reason for any change to planned completion"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ChangeLabel",
                            display: "Change in any other important project milestone in period and reasons why"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "MainProgressLabel",
                            display: "Main progress achieved in period"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "KeyActivitiesLabel",
                            display: "Key activities forecast in next four weeks"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SummaryLabel",
                            display: "Summary of critical path"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ChangesLabel",
                            display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc."
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "CompensationLabel",
                            display: "Variations added to programme this period"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NotificationClause",
                            display: "Please find attached programme for acceptance"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ReplyRejectReasonLabel",
                            display: "The $SUPPLIER_NAME’s programme is not accepted for the following reasons"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "ReplyNotificationClause",
                            display: "In accordance with NEC4 PSC clause 31.3 you are notified that the $SUPPLIER_NAME’s Programme"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ReplyNotificationClause",
                            display: "You are notified that the $SUPPLIER_NAME’s Programme"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "DashboardPlannedCompletionLabel",
                            display: "Planned Completion Date"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Programme Submission awaiting Acceptance"
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "plansnotpractical",
                            display: "The $SUPPLIER_NAME’s plans which it shows are not practicable"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "missingcontractrequires",
                            display: "It does not show the information which this contract requires"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "notrepresentplan",
                            display: "It does not represent the $SUPPLIER_NAME’s plans realistically"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "notcomply",
                            display: "It does not comply with the Works Information"
                        }
                    ]
                },
                {
                    replacementKey: "Fields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PreviousPlanned",
                            display: "Previous Planned Completion"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlannedCompletion",
                            display: "Date of Planned Completion this period"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Reason",
                            display: "Reason for any change to Planned Completion"
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "Change",
                            display: "Change in any other Key Date, Sectional Completion or other important project milestone in period and reasons why"
                        },
                        {
                            id: 5,
                            isActive: false,
                            field: "MainProgress",
                            display: "Main progress achieved in period"
                        },
                        {
                            id: 6,
                            isActive: false,
                            field: "KeyActivities",
                            display: "Key activities forecast in next four weeks"
                        },
                        {
                            id: 7,
                            isActive: false,
                            field: "Summary",
                            display: "Summary of critical path"
                        },
                        {
                            id: 8,
                            isActive: false,
                            field: "Changes",
                            display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc."
                        },
                        {
                            id: 9,
                            isActive: false,
                            field: "Compensation",
                            display: "Compensation events added to programme this period"
                        }
                    ]
                }
            ]
        },
        {
            id: 105,
            title: "Notifications",
            workflows: [
                {
                    id: 15,
                    title: "A/CA Notifications",
                    notificationName: "Notification",
                    abbreviation: "$PM_ABBREVIATION-Notification-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 16,
                    title: "$SUPPLIER_NAME Notifications",
                    notificationName: "Notification",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Notification-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "PmClauses",
                    values: [
                        {
                            id: 43,
                            isActive: true,
                            field: "",
                            display: "2.5 of deferment of the date of possession"
                        },
                        {
                            id: 64,
                            isActive: true,
                            field: "",
                            display: "2.8.2 of copies of the Contract Documents"
                        },
                        {
                            id: 68,
                            isActive: true,
                            field: "",
                            display: "2.9 proposal to $CLIENT_NAME to instruct the Contractor to not amend an error in setting out\n",
                            applyReplyPeriod: true,
                            replyPeriod: 7,
                            noReply: false,
                            overrideReplyingParty: true,
                            replyingParty: "CLIENT"
                        },
                        {
                            id: 71,
                            isActive: true,
                            field: "",
                            display: "2.9\tof accurately dimensioned drawings to set out the Works"
                        },
                        {
                            id: 65,
                            isActive: true,
                            field: "",
                            display: "2.9 of dimensioned drawings to set out the Works"
                        },
                        {
                            id: 66,
                            isActive: true,
                            field: "",
                            display: "2.10 of information required in the Information Release Schedule"
                        },
                        {
                            id: 67,
                            isActive: true,
                            field: "",
                            display: "2.10.1 proposal to $SUPPLIER_NAME to vary times in the Information Release Schedule",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideReplyingParty: false,
                            deemed: false
                        },
                        {
                            id: 44,
                            isActive: true,
                            field: "",
                            display: "2.15.1 of a divergence between the Statutory Requirements and a Contract Document"
                        },
                        {
                            id: 45,
                            isActive: true,
                            field: "",
                            display: "2.19.1 of a reasonable extension to the time for Completion of the Works or a Section"
                        },
                        {
                            id: 46,
                            isActive: true,
                            field: "",
                            display: "2.21 of the issue of a Practical or Sectional Completion Certificate"
                        },
                        {
                            id: 48,
                            isActive: true,
                            field: "",
                            display: "2.22 of the issue of a non-completion certificate"
                        },
                        {
                            id: 50,
                            isActive: true,
                            field: "",
                            display: "2.25 identifying the parts of the work taken into possession"
                        },
                        {
                            id: 51,
                            isActive: true,
                            field: "",
                            display: "2.27 of the issue of a certificate of making good defects for a Relevant Part"
                        },
                        {
                            id: 69,
                            isActive: true,
                            field: "",
                            display: "2.30 proposal to $CLIENT_NAME to instruct the Contractor to not make good defects",
                            applyReplyPeriod: true,
                            replyPeriod: 7,
                            noReply: false,
                            overrideReplyingParty: true,
                            replyingParty: "CLIENT"
                        },
                        {
                            id: 53,
                            isActive: true,
                            field: "",
                            display: "2.31 of the issue of a certificate of making good defects for the Works"
                        },
                        {
                            id: 55,
                            isActive: true,
                            field: "",
                            display: "3.9 that the Contractor is required to comply with an instruction"
                        },
                        {
                            id: 56,
                            isActive: true,
                            field: "",
                            display: "3.10 of the provision empowering the issue of an instruction"
                        },
                        {
                            id: 57,
                            isActive: true,
                            field: "",
                            display: "3.15.2 of withdrawal of an instruction under 3.15.1"
                        },
                        {
                            id: 58,
                            isActive: true,
                            field: "",
                            display: "3.18.4 of a replacement Principal Designer or Principal Contractor"
                        },
                        {
                            id: 72,
                            isActive: true,
                            field: "",
                            display: "4.8.2\tof the issue of an Interim Certificate"
                        },
                        {
                            id: 59,
                            isActive: true,
                            field: "",
                            display: "4.16.4 of the assessment of loss and/or expense"
                        },
                        {
                            id: 60,
                            isActive: true,
                            field: "",
                            display: "4.20.2 of the assessment of the final account"
                        },
                        {
                            id: 61,
                            isActive: true,
                            field: "",
                            display: "4.21 of the issue of the Final Certificate"
                        },
                        {
                            id: 70,
                            isActive: true,
                            field: "",
                            display: "6.12.1 of evidence of insurance"
                        },
                        {
                            id: 63,
                            isActive: true,
                            field: "",
                            display: "Sch. 6.7 of a confirmation or withdrawal of a reply to a submission of a design document"
                        },
                        {
                            id: 42,
                            isActive: true,
                            field: "",
                            display: "Other notification"
                        }
                    ]
                },
                {
                    replacementKey: "SupplierClauses",
                    values: [
                        {
                            id: 50,
                            isActive: true,
                            field: "",
                            display: "1.7.2 of an agreement of an address for communications"
                        },
                        {
                            id: 51,
                            isActive: true,
                            field: "",
                            display: "1.7.3.1 of an address to receive communications"
                        },
                        {
                            id: 52,
                            isActive: true,
                            field: "",
                            display: "2.1.1 required by statute"
                        },
                        {
                            id: 61,
                            isActive: true,
                            field: "",
                            display: "2.6.1 of submission to use or occupy the site or the Works"
                        },
                        {
                            id: 62,
                            isActive: true,
                            field: "",
                            display: "2.6.2 of the amount of additional insurance"
                        },
                        {
                            id: 53,
                            isActive: true,
                            field: "",
                            display: "2.10.1 of Contractor's Design Documents"
                        },
                        {
                            id: 54,
                            isActive: true,
                            field: "",
                            display: "2.11.1 of further information to explain and amplify the Contract Drawings"
                        },
                        {
                            id: 55,
                            isActive: true,
                            field: "",
                            display: "2.11.3 of the time by which further information is requried"
                        },
                        {
                            id: 56,
                            isActive: true,
                            field: "",
                            display: "2.13.3.1 of a departure, error or omission"
                        },
                        {
                            id: 57,
                            isActive: true,
                            field: "",
                            display: "2.15.1 of a divergence between the Statutory Requirements and a Contract Document"
                        },
                        {
                            id: 58,
                            isActive: true,
                            field: "",
                            display: "2.16.2 of emergency compliance work"
                        },
                        {
                            id: 59,
                            isActive: true,
                            field: "",
                            display: "2.19.1 that the progress of the Works or a Section is or is likely to be delayed"
                        },
                        {
                            id: 60,
                            isActive: true,
                            field: "",
                            display: "2.32 of information in connection with CDM, Contractor's Design Documents and Designed Portion"
                        },
                        {
                            id: 66,
                            isActive: true,
                            field: "",
                            display: "3.4.1 of reasonable objection to the nomination of a replacement Architect/Contract Administrator or Quantity Surveyor"
                        },
                        {
                            id: 67,
                            isActive: true,
                            field: "",
                            display: "3.8.1 of a reasonable objection to an instruction"
                        },
                        {
                            id: 68,
                            isActive: true,
                            field: "",
                            display: "3.8.2 that an instruction or direction adversely affects the Contractor's Design or compliance with CDM"
                        },
                        {
                            id: 63,
                            isActive: true,
                            field: "",
                            display: "3.10 of a request to demonstrate that an instruction complies with the Contract"
                        },
                        {
                            id: 64,
                            isActive: true,
                            field: "",
                            display: "3.15.1 of the actions proposed to be taken in respect of work, materials and goods not in accordance with the Contract"
                        },
                        {
                            id: 65,
                            isActive: true,
                            field: "",
                            display: "3.15.2 of objection to an instruction under 3.15.1"
                        },
                        {
                            id: 79,
                            isActive: true,
                            field: "",
                            display: "4.7 of an advance payment bond"
                        },
                        {
                            id: 69,
                            isActive: true,
                            field: "",
                            display: "4.10.2 of proof that propery of the Listed Items is vested in the Contractor and is insured"
                        },
                        {
                            id: 70,
                            isActive: true,
                            field: "",
                            display: "4.10.5 of a bond for payment of Listed Items"
                        },
                        {
                            id: 71,
                            isActive: true,
                            field: "",
                            display: "4.12.2 of the issue of a payment notice"
                        },
                        {
                            id: 72,
                            isActive: true,
                            field: "",
                            display: "4.12.5 of the issue of a Pay Less Notice"
                        },
                        {
                            id: 73,
                            isActive: true,
                            field: "",
                            display: "4.14.1 Of an intention to suspend performance of all or part of the works"
                        },
                        {
                            id: 75,
                            isActive: true,
                            field: "",
                            display: "4.16.1 That the effect of a Relevant Matter on the regular progress of the works is likely to incur loss and/or expense"
                        },
                        {
                            id: 76,
                            isActive: true,
                            field: "",
                            display: "4.16.2 of an initial assessment of loss and/or expense"
                        },
                        {
                            id: 77,
                            isActive: true,
                            field: "",
                            display: "4.16.3 of further information in support of the assessment of loss and/or expense"
                        },
                        {
                            id: 78,
                            isActive: true,
                            field: "",
                            display: "4.20.1 of information for the final account"
                        },
                        {
                            id: 80,
                            isActive: true,
                            field: "",
                            display: "5.4 of vouchers for work carried out on daywork"
                        },
                        {
                            id: 81,
                            isActive: true,
                            field: "",
                            display: "6.11.1 that Terrorism Cover will cease"
                        },
                        {
                            id: 82,
                            isActive: true,
                            field: "",
                            display: "6.12.1 of a request to  provide evidence of insurance"
                        },
                        {
                            id: 83,
                            isActive: true,
                            field: "",
                            display: "6.12.1 of evidence of insurance"
                        },
                        {
                            id: 84,
                            isActive: true,
                            field: "",
                            display: "6.13.1 of loss or damage caused by an insured risk"
                        },
                        {
                            id: 86,
                            isActive: true,
                            field: "",
                            display: "6.17.1 of a breach of the Joint Fire Code"
                        },
                        {
                            id: 89,
                            isActive: true,
                            field: "",
                            display: "6.20 that insurance has ceased to be available at commercially reasonable rates"
                        },
                        {
                            id: 90,
                            isActive: true,
                            field: "",
                            display: "7.2 of a performance bond or guarantee"
                        },
                        {
                            id: 91,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.1 of the date of entering into a subcontract with a Named Subcontractor"
                        },
                        {
                            id: 92,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.2 that the Contractor is unable to enter into subcontract with a Named Subcontractor"
                        },
                        {
                            id: 93,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.6 of events leading to a termination of the Named Subcontractor's subcontract"
                        },
                        {
                            id: 94,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.7 of the circumstances surrounding a Named Subcontractor's termination"
                        },
                        {
                            id: 95,
                            isActive: true,
                            field: "",
                            display: "Sch. 5.2 of information necessary to monitor and assess the Contractor's performance"
                        },
                        {
                            id: 96,
                            isActive: true,
                            field: "",
                            display: "Sch. 6.7 of a disagreement with a reply to a submission of a design document"
                        },
                        {
                            id: 36,
                            isActive: true,
                            field: "",
                            display: "Other notification"
                        }
                    ]
                },
                {
                    replacementKey: "SupervisorClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "3.4 of a Direction"
                        }
                    ]
                }
            ]
        },
        {
            id: 106,
            title: "Submissions",
            workflows: [
                {
                    id: 18,
                    title: "$SUPPLIER_NAME Submissions",
                    notificationName: "Submission",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Submission-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 49,
                    title: "$PM_NAME Submissions",
                    notificationName: "Submission",
                    abbreviation: "$PM_ABBREVIATION-Submission-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "IssueClauses",
                    values: [
                        {
                            id: 29,
                            isActive: true,
                            field: "",
                            display: "2.2.1 of quality of materials, goods or workmanship"
                        },
                        {
                            id: 50,
                            isActive: true,
                            field: "",
                            display: "2.6.1 for consent to use or occupy the site or the Works"
                        },
                        {
                            id: 51,
                            isActive: true,
                            field: "",
                            display: "2.7.2 for consent for work to be carried out by the Employer"
                        },
                        {
                            id: 30,
                            isActive: true,
                            field: "",
                            display: "2.10.1 of a proposal to vary times in the Information Release Schedule"
                        },
                        {
                            id: 31,
                            isActive: true,
                            field: "",
                            display: "2.13.3.2 of a proposal to amend a departure, error or omission in the Contractor's Proposals or Design Documents"
                        },
                        {
                            id: 32,
                            isActive: true,
                            field: "",
                            display: "2.15.1 of a proposal to correct a divergence between the Statutory Requirements and the Contractor's Proposals or Design Documents"
                        },
                        {
                            id: 33,
                            isActive: true,
                            field: "",
                            display: "2.17 for consent to remove materials and goods from site"
                        },
                        {
                            id: 52,
                            isActive: true,
                            field: "",
                            display: "2.25 for consent for the Employer to take possession of part of the works"
                        },
                        {
                            id: 34,
                            isActive: true,
                            field: "",
                            display: "3.5 for consent to subcontract the whole or part of the works"
                        },
                        {
                            id: 35,
                            isActive: true,
                            field: "",
                            display: "4.7 for approval of a surety for an advance payment bond"
                        },
                        {
                            id: 36,
                            isActive: true,
                            field: "",
                            display: "4.10.5 for approval of a surety for payment of Listed Items"
                        },
                        {
                            id: 42,
                            isActive: true,
                            field: "",
                            display: "7.1 for consent to assign the Contract or any rights thereunder"
                        },
                        {
                            id: 43,
                            isActive: true,
                            field: "",
                            display: "Sch.1.A1 for approval of insurers for the All Risks Policy"
                        },
                        {
                            id: 44,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.6 for consent to accept termination or repudiation of a Named Subcontractor's subcontract"
                        },
                        {
                            id: 45,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.10.2 for an agreement that the Employer shall indemnify the Contractor against legal costs"
                        },
                        {
                            id: 46,
                            isActive: true,
                            field: "",
                            display: "Sch. 5.3.1 of proposed changes to designs and specifications for the purpose of value engineering"
                        },
                        {
                            id: 48,
                            isActive: true,
                            field: "",
                            display: "Sch. 5.3 of proposals to improve performance"
                        },
                        {
                            id: 47,
                            isActive: true,
                            field: "",
                            display: "Sch. 5.4.1 of proposed changes to improve environmental performance"
                        },
                        {
                            id: 49,
                            isActive: true,
                            field: "",
                            display: "Sch. 6.1 for approval of the Contractor's Design"
                        },
                        {
                            id: 28,
                            isActive: true,
                            field: "",
                            display: "Other submission"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Submission awaiting Acceptance"
                        }
                    ]
                },
                {
                    replacementKey: "IssueClausesPM",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "2.6.1 of a request to use or occupy the site or the Works"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "2.7.2 of a request on behalf of the Employer to execute its own works on site"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "2.9.1 of provision of schedules and similar documents"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "6.12.1 of evidence of insurance"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "Other submission"
                        }
                    ]
                }
            ]
        },
        {
            id: 103,
            title: "Variations and Extension of Time",
            workflows: [
                {
                    id: 50,
                    title: "Extension of Time",
                    notificationName: "Variation",
                    abbreviation: "Variation-Time-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 51,
                    title: "Variations and other changes to the Contract Sum",
                    notificationName: "Variation",
                    abbreviation: "Variation-Price-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmAssumptionLabel",
                            display: "The following assumptions shall be considered"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmAssumptionNote",
                            display: "You are hereby instructed to provide a quote"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierDescNote",
                            display: "In accordance with the contract I notify you that the event described below has happened which: We believe is a variation, has happened less than 5 weeks since we became aware of the event, you have not notified to us."
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "ReplyAcceptedCommentLabel",
                            display: "In accordance with the contract you are notified that the issue flagged is a Variation"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "ReplyRejectedCommentLabel",
                            display: "In accordance with the contract you are notified that the $SUPPLIER_NAME Notification is not a Variation"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ReplyAcceptedAssumptionLabel",
                            display: "In accordance with the contract the following assumptions shall be considered"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "QuoteNote",
                            display: "In accordance with the contract please find attached our quotation for $reference"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "ExtensionPMLabel",
                            display: "We require an extension to the contractual time to produce a quotation response as detailed below for the following reasons"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ExtensionSupplierLabel",
                            display: "We require an extension to the contractual time to produce a quotation as detailed below for the following reasons"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "AssessmentDetailLabel",
                            display: "Details"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ProposedInstructionNote",
                            display: "In accordance with the contract you are instructed to provide a quote"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "ProposedInstructionLabel",
                            display: "In accordance with the contract he following assumptions shall be considered"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "ReplyRejectedOptionsLabel",
                            display: "I have decided that the Notification"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "QuoteCostEffectLabel",
                            display: "Cost Increase/Decrease"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "QuoteDateEffectLabel",
                            display: "Extension of time required"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "QuoteDaysEffectLabel",
                            display: "Extension of time required"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "AssessmentCostEffectLabel",
                            display: "Cost Increase/Decrease"
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "AssessmentDaysEffectLabel",
                            display: "Number of days effect to the completion date"
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "CompensationTypeLabel",
                            display: "Variation types"
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "QuoteProgrammeAttachLabel",
                            display: "Are you attaching a programme showing the effect against the last Accepted Programme with this quotation?"
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "AssessmentCompensationLabel",
                            display: "Variation"
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "AssessmentWarningBanner",
                            display: ""
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "PmCompensationAQ",
                            display: "Variations notified by $PM_ABBREVIATION awaiting quotes"
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "PmCompensationARQ",
                            display: "Variations notified by $PM_ABBREVIATION awaiting revised quotes"
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "PmCompensationAPMRQ",
                            display: "Variations notified by $PM_ABBREVIATION awaiting response to quotes"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "PmCompensationAPMA",
                            display: "Variations notified by $PM_ABBREVIATION awaiting $PM_ABBREVIATION Assessment"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SupCompensationAQ",
                            display: "Variations notified by $SUPPLIER_NAME awaiting quotes"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SupCompensationARQ",
                            display: "Variations notified by $SUPPLIER_NAME awaiting revised quotes"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "SupCompensationAPMR",
                            display: "Variations notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION response"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "SupCompensationAPMRQ",
                            display: "Variations notified by $SUPPLIER_NAME awaiting response to quotes"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "SupCompensationAPMA",
                            display: "Variations notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "PmProposedAQ",
                            display: "Proposed Variations awaiting quotes"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "PmProposedARQ",
                            display: "Proposed Variations awaiting revised quotes"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "PmProposedAPMRQ",
                            display: "Proposed Variations awaiting response to quotes"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "PmProposedAPMA",
                            display: "Proposed Variations awaiting $PM_ABBREVIATION Assessment"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "PmExtension",
                            display: "$PM_ABBREVIATION Quote Response Extensions awaiting Acceptance"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "SupExtension",
                            display: "$SUPPLIER_NAME Quote Extensions awaiting Acceptance"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "SupAssumption",
                            display: "$SUPPLIER_NAME Assumptions awaiting Acceptance"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "SupClaimAQ",
                            display: "Claims notified by $SUPPLIER_NAME awaiting quotes"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "SupClaimARQ",
                            display: "Claims notified by $SUPPLIER_NAME awaiting revised quotes"
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "SupClaimAPMRQ",
                            display: "Claims notified by $SUPPLIER_NAME awaiting response to quotes"
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "SupClaimAPMA",
                            display: "Claims notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment"
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "faultpscp",
                            display: "Arises from a fault of the $SUPPLIER_NAME"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "notexpectnothappen",
                            display: "Has not happened and is not expected to happen"
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "noteffectcostcompletion",
                            display: "Has no effect on Defined Cost, Completion or meeting a Key Date"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "statedcontract",
                            display: "Is not one of the variations stated in this contract"
                        },
                        {
                            id: 5,
                            isActive: false,
                            field: "notnotifiedwithintimescales",
                            display: "Has not been notified within the timescales set out in the contract"
                        }
                    ]
                },
                {
                    replacementKey: "CompensationStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "APMA",
                            display: "Quote Not Accepted - $PM_ABBREVIATION to make own Assessment"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ARQ",
                            display: "Quote Not Accepted - $SUPPLIER_NAME to resubmit"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "AM",
                            display: "$PM_ABBREVIATION Assessment Made"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "QA",
                            display: "Quote Accepted"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "APMRQ",
                            display: "Awaiting $PM_ABBREVIATION Response to quote"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "APMR",
                            display: "Awaiting $PM_ABBREVIATION Response to Variation Notice"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "AQ",
                            display: "Awaiting Quote"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NC",
                            display: "Not Communicated"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "R",
                            display: "Not a Variation"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "DNG",
                            display: "Decision Not Given"
                        }
                    ]
                },
                {
                    replacementKey: "QuoteStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "0",
                            display: "Not Accepted - $SUPPLIER_NAME to resubmit"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Accepted"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Awaiting Response"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "3",
                            display: "Not Accepted - $PM_ABBREVIATION to make assessment"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "4",
                            display: "Not Accepted - Other quote approved"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "5",
                            display: "Not Accepted - Decision will not be given"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "none",
                            display: "Not Communicated"
                        }
                    ]
                },
                {
                    replacementKey: "InstructionReplyProceedOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "61.1",
                            display: "$SUPPLIER_NAME is instructed to proceed with works"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "61.2",
                            display: "$SUPPLIER_NAME is instructed to NOT proceed with works until instructed"
                        }
                    ]
                },
                {
                    replacementKey: "EWRelationEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "EWRelationEnabled",
                            display: "Active-Inactive state indicates weather the EWN can be attached in CEN communications"
                        }
                    ]
                },
                {
                    replacementKey: "Fields",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "Assumption",
                            display: "The quotation is to be based on the following assumptions"
                        }
                    ]
                },
                {
                    replacementKey: "QuoteProgrammeImpactOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "noeffect",
                            display: "There is no effect on the Accepted Programme"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "mitigated",
                            display: "There is an effect on the Accepted Programme but this has been mitigated by measures as detailed"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "notmitigated",
                            display: "There is an effect on the Accepted Programme which we are unable to mitigate"
                        }
                    ]
                },
                {
                    replacementKey: "VariationClauses",
                    values: [
                        {
                            id: 27,
                            isActive: true,
                            field: "",
                            display: "2.20.1 A Variation"
                        },
                        {
                            id: 28,
                            isActive: true,
                            field: "",
                            display: "2.20.2 An instruction under 2.13, 3.12, 3.13, 3.7, Schedule 2, or for the opening up and testing of work, materials or goods"
                        },
                        {
                            id: 29,
                            isActive: true,
                            field: "",
                            display: "2.20.3 Deferment of the date of possessions"
                        },
                        {
                            id: 30,
                            isActive: true,
                            field: "",
                            display: "2.20.4 Execution of work which which an Approximate Quantity is not accurate"
                        },
                        {
                            id: 31,
                            isActive: true,
                            field: "",
                            display: "2.20.5 Suspension by the Contractor"
                        },
                        {
                            id: 32,
                            isActive: true,
                            field: "",
                            display: "2.20.6 Any impediment, act or omission of the Employer or related persons"
                        },
                        {
                            id: 33,
                            isActive: true,
                            field: "",
                            display: "2.20.7 The carrying out of work by a Statutory Authority"
                        },
                        {
                            id: 34,
                            isActive: true,
                            field: "",
                            display: "2.20.8 Exceptionally adverse weather conditions"
                        },
                        {
                            id: 35,
                            isActive: true,
                            field: "",
                            display: "2.20.9 Loss of damage by a Specified Peril"
                        },
                        {
                            id: 36,
                            isActive: true,
                            field: "",
                            display: "2.20.10 Civil commotion or terrorism"
                        },
                        {
                            id: 37,
                            isActive: true,
                            field: "",
                            display: "2.20.11 Strike, lock out or union activities"
                        },
                        {
                            id: 38,
                            isActive: true,
                            field: "",
                            display: "2.20.12 The exercise of a statutory power by the government"
                        },
                        {
                            id: 39,
                            isActive: true,
                            field: "",
                            display: "2.20.13 Force majeure"
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "",
                            display: "Other Variation"
                        }
                    ]
                }
            ]
        },
        {
            id: 108,
            title: "Payments",
            workflows: [
                {
                    id: 20,
                    title: "Payment Certificates",
                    notificationName: "Payment Certificate",
                    abbreviation: "$PM_ABBREVIATION-Payment-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 21,
                    title: "Payment Applications",
                    notificationName: "Payment Application",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Payment-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CumulativePriceLabel",
                            display: "Value of Work Done"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlusAmountsSupplierLabel",
                            display: "Plus other amounts to be paid to the $SUPPLIER_NAME"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "LessAmountsSupplierLabel",
                            display: "Less amounts to be paid by or retained from the $SUPPLIER_NAME"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TaxSupplierLabel",
                            display: "Taxes"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "WarningBanner",
                            display: "During the period up to the due date for the final payment, the monthly due dates for interim payments by the Employer shall in each case be the date 7 days after the relevant Interim Valuation Date"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SummaryDetailsLabel",
                            display: "Summary details"
                        }
                    ]
                },
                {
                    replacementKey: "TaxEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "TaxEnabled",
                            display: "Active-Inactive state indicates tax field will be included in notification forms"
                        }
                    ]
                }
            ]
        },
        {
            id: 109,
            title: "Defects",
            workflows: [
                {
                    id: 23,
                    title: "Defect Notifications",
                    notificationName: "Defect Notification",
                    abbreviation: "$PM_NAME-Defect-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    actioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]],\"and\",[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Supervisor\"]]]",
                            viewDraftFilter: "[[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"PM\"]]",
                            communicateFilter: "[[\"rolepermissions\",\"=\",\"Manager\"],\"and\",[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Supervisor\"]]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"rolepermissions\",\"=\",\"Manager\"],\"and\",[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Supervisor\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "InstructionClauseLabel",
                            display: "You are instructed to search for a Defect as follows"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SupervisorClauseLabel",
                            display: "You are notified of the following Defect"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierClauseLabel",
                            display: "You are notified of the following Defect"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TestsClauseLabel",
                            display: "You are notified of the following test or inspection"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Instruction",
                            display: "$SUPERVISOR_NAME Instruction to Search awaiting sign off"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SuperDefect",
                            display: "$SUPERVISOR_NAME Notification of Defect awaiting acceptance"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Inspection",
                            display: "$SUPPLIER_NAME Test/Inspection Notification awaiting sign off"
                        }
                    ]
                }
            ]
        },
        {
            id: 111,
            title: "Records",
            workflows: [
                {
                    id: 30,
                    title: "A/CA Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$PM_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 31,
                    title: "$SUPPLIER_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 32,
                    title: "$SUPPLIER_NAME Site Diary",
                    notificationName: "Site Diary",
                    abbreviation: "Diary-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 33,
                    title: "Weather",
                    notificationName: "Weather",
                    abbreviation: "Weather-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: []
        },
        {
            id: 112,
            title: "RFI / TQ",
            workflows: [
                {
                    id: 34,
                    title: "$SUPPLIER_NAME RFI / TQ",
                    notificationName: "RFI / TQ",
                    abbreviation: "RFI/TQ-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME RFI/TQ awaiting Reply"
                        }
                    ]
                }
            ]
        },
        {
            id: 101,
            title: "Early Dispute Resolution",
            workflows: [
                {
                    id: 1,
                    title: "A/CA EDR Notices",
                    notificationName: "Early Dispute Resolution",
                    abbreviation: "$PM_ABBREVIATION-Warning-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    actioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Support\"]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 2,
                    title: "$SUPPLIER_NAME EDR Notes",
                    notificationName: "Early Dispute Resolution",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Warning-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    actioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Support\"]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 3,
                    title: "EDR Register",
                    notificationName: "Early Dispute Resolution",
                    abbreviation: "Warning",
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "MeetingRequiredLabel",
                            display: "Is a meeting required?"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "NotificationClause",
                            display: "You are hereby notified of"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "RiskItemsLabel",
                            display: ""
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Pm",
                            display: "$PM_ABBREVIATION EDR awaiting reply"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME EDR awaiting reply"
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "riskother",
                            display: "a matter which is likely to give rise to a dispute"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "riskaffectwork",
                            display: "Sch 5.1 of behaviour which is not collaborative"
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypesSupplier",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "riskother",
                            display: "a matter which is likely to give rise to a dispute"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "riskaffectwork",
                            display: "Sch 5.1 of behaviour which is not collaborative"
                        }
                    ]
                },
                {
                    replacementKey: "MeetingOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "0",
                            display: "Meeting Not Required"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Meeting Required - Next Progress Meeting"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Meeting Required - Propose New Progress Meeting"
                        }
                    ]
                }
            ]
        },
        {
            id: 113,
            title: "Contract Information",
            workflows: [
                {
                    id: 39,
                    title: "Documents",
                    notificationName: "Document",
                    abbreviation: "Documents-",
                    notify: true,
                    actioningParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: []
                }
            ]
        }
    ]
};
export default template;