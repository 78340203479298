import {placeholderTypes} from "../../Types/Placeholder";
const placeholders =  [
    {
        replacementKey: 'Resources', values: [],
        type:placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'ActionReminders', values:
            [
                { id: 1, isActive: true, field: 'NonConformance', display: 'Non-Conformance awaiting Reply' },
            ],
        type:placeholderTypes.ACTION_REMINDERS
    },

];
export default placeholders;