
import axios from '../shared/axios';
import CrudHelper from "./helper";

export function getCompanies() {
  return axios.post('/api/Company/LoadCompanysList');
}

export function getCompaniesIncludingDeactives() {
  return axios.post('/api/Company/LoadCompanysListIncludingDeactives');
}

export function getCompany(companyid) {
  return axios.get('/api/Company/GetCompany?companyid=' + companyid);
}

export function getCompanyPicture(companyid) {
  return axios.get('/api/Picture/GetPictureV2?type=Company&reference=' + companyid);
}

export function setCompanyPicture(companyid, file) {
  const data = new FormData();
  data.append("UploadedImage", file);

  const headers = {
    'Content-Type': 'multipart/form-data'
  }

  return axios.post('/api/picture/uploadpicture?type=Company&reference=' + companyid, data, {
    headers: headers
  });
}

export function saveCompany(data) {
  data.administratorid = 1;
  data = CrudHelper.appendCustomFields(data);
  return axios.post('/api/Company/SaveCompany', data);
}

export function checkIfCompanyNumberExists(companynumber) {
  return axios.get('/api/Company/CheckIfCompanyNumberExists?companynumber=' + companynumber);
}

export function getCutDownCompaniesList(userProfile) {
  if(userProfile?.accounttype ==="Company Administrator"){
    return axios.get('/api/Company/ToSelectListFiltered');
  }
  else{
    return axios.get('/api/Company/ToSelectList');
  }
  
}

export function getCompaniesListOfUserContracts() {
  return axios.post('/api/Company/LoadCompanysListOfUserContracts');
}

export function companyActivateDeactivate(formData) {
  return axios.post('/api/Company/CompanyActivateDeactivate', formData);
}
