/* eslint-disable import/no-anonymous-default-export */
import Workflows from "./Workflows";
import Placeholders from "./Placeholders";
export default {
    id: 114,
    title: 'Task Orders',
    path: 'tasks',
    icon: "flaticon2-soft-icons-1",
    workflows: Workflows,
    placeholders: Placeholders,
};