/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { connect } from "react-redux";
import * as fileCrud from '../../../crud/file.crud';
import * as asiteCrud from '../../../crud/asite.crud';
import {FormattedMessage, injectIntl } from "react-intl";
import axios from 'axios';
import { TextBox, List, SelectBox, CheckBox, FileManager, Tooltip } from 'devextreme-react';
import { withSnackbar } from 'notistack';
import { Button } from "react-bootstrap";
import PortletSpinner from '../../../partials/layout/PortletSpinner';
import DataSource from 'devextreme/data/data_source';

const fileFilterTabIndex = 1;

function ItemTemplate(data) {
    return (
        <div>
            <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>{data.docref}</div>
                <div style={{ flex: 1 }}>{data.revision}</div>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
                <div style={{ flex: 1 }}>{data.name}</div>
                <div style={{ flex: 1 }}>
                {data.customAttributes && Object.keys(data.customAttributes).length > 0 && (
                    Object.entries(data.customAttributes).map(([key, value], index) => (
                        <div key={index}>
                            {value}
                        </div>
                    ))
                )}
                </div>
            </div>
            <div style={{ marginTop: "10px" }}>{data.folderpath}</div>
        </div>
    );
}

const initialSearchParams = {
    contractid: 0,
    searchdoctitle: '',
    searchdocref: '',
    folderid: null,
    dynamicsearchfields: []
}

class AsiteFileFilter extends Component {
    constructor(props) {
        super(props);
        this.fileManagerRef = React.createRef();
        this.listRef = React.createRef();
    
        this.state = {
            parameters: null,
            searchParams: initialSearchParams,
            searchResults: null,
            loading: false,
            isFolderCheckboxSelected: false,
            folderPath: null,
            selectionMode: 'multiple',
            selectAllMode: 'allPages',
            totalItemsCount : 0,
            dataSource: null
        };
    }
    componentDidMount() {
        this.setState({ searchParams: { ...this.state.searchParams, contractid: this.props.contract.contractid } });
        this.getSearchParameters();
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.currentTabIndex === fileFilterTabIndex && this.props.currentTabIndex !== fileFilterTabIndex){
            //NAVIGATED AWAY FROM THIS TAB
            if(this.listRef.current && this.listRef.current.instance){
                this.listRef.current.instance.unselectAll();
            }
        }
        if (prevState.searchResults !== this.state.searchResults) {
            this.updateDataSource();
        }
    }
    updateDataSource = () => {
        this.setState({
            dataSource: new DataSource({
                store: {
                    type: 'array',
                    data: this.state.searchResults,
                    key: 'documentid'
                },
                paginate: false // Disables paging so that all items can be seen in the list
            })
        });
    }
    getSearchParameters = () => {
        asiteCrud.getAsiteSearchParameters(this.props.contract.contractid)
        .then(response => {
            let parameters = response.data.searchAttributes;

            this.setState({ parameters });
        })
        .catch(err => {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.props.onClose();
            }
        });
    }
    onItemClick = () => {
        this.setState({ loading: true, searchResults: null });

        const newDynamicFieldsState = this.state.searchParams.dynamicsearchfields;
        var newFormat = Object.entries(newDynamicFieldsState).map(([key, value]) => ({key,value})); // object converted to object array
        const newState = { ...this.state.searchParams, dynamicsearchfields: newFormat };
        fileCrud.asiteSearch(newState)
        .then(response => {
            let data = response.data;
            this.setState({ searchResults: data });
        })
        .catch(err => {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.props.onClose();
            }
        });
    };
    itemTemplate = data => {
        return "<div style={{ position: 'relative', minHeight: '30px', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title='" + data.name + "'>" + data.name + "</div>";  
    }
    onCurrentDirectoryChanged = (e) => {
        this.setState({ searchParams: { ...this.state.searchParams, folderid: e.directory.dataItem.id }, folderPath: e.directory.path });
    }
    filterResultsByDocumentId = (documentIds) => {
        return this.state.searchResults.filter(item => documentIds.includes(item.documentid));
    }
    onSelectedItemKeysChange = (args) => {
        if (args.name === 'selectedItemKeys') {
            const selectedItems = this.filterResultsByDocumentId(args.value);
            
            this.setState({ totalItemsCount: selectedItems.length });
            this.props.onSelectionChange(selectedItems);
        }
    }
    dynamicFilter = (parameter, index) => {
        let margLeft = 0;

        if (index == 2) {
            margLeft = 30;
        } else if (index > 3) {
            margLeft = 20;
        }

        const emptyItem = {name: 'Select', code: ''};

        const list = parameter ? (parameter.attributes !== null ? [emptyItem].concat(parameter.attributes) : null) : null;

        return (
            <div className="dx-field" style={ margLeft > 0 ? { marginLeft: `${margLeft}px`, width: "50%" } : { width: "50%" }}>
                <div id={`label${index}`} className="dx-field-label" style={{ width: "25%" }}>
                    {parameter.uiLabel}:
                    <Tooltip
                        target={`#label${index}`}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        contentRender={() => (parameter.uiLabel)}
                    />
                </div>
                <div className="dx-field-value" style={{ width: "75%" }}>
                    {parameter.dataType === 'List' ? (
                        <SelectBox
                            dataSource={list}
                            displayExpr="name"
                            itemTemplate={this.itemTemplate}
                            searchEnabled={true}
                            searchMode='contains'
                            searchExpr='name'
                            searchTimeout={300}
                            minSearchLength={0}
                            showDataBeforeSearch={true}
                            onValueChanged={(e) => this.setState({ searchParams: { 
                                    ...this.state.searchParams,
                                    dynamicsearchfields: {
                                        ...this.state.searchParams.dynamicsearchfields,
                                        [parameter.attributeName]: e.value.name === 'Select' ? null : e.value.name
                                    }
                                }})
                            }
                        />
                    ) : (
                        <TextBox
                            showClearButton={true}
                            valueChangeEvent="keyup"
                            onValueChanged={(e) => this.setState({ searchParams: { 
                                ...this.state.searchParams,
                                dynamicsearchfields: {
                                    ...this.state.searchParams.dynamicsearchfields,
                                    [parameter.attributeName]: e.value
                                } } })
                            }
                            hint={
                                parameter.uiLabel === 'Alt Ref'
                                    ? this.props.intl.formatMessage({ id: 'ASITE.FORMS.HINTDOCREFALTREF' })
                                    : ''
                            }
                        />
                    )}
                </div>
            </div>
        );        
    }
    render() {
        if (!this.state.parameters) {
            return <PortletSpinner />;
        }

        return (
            <div style={{ padding: '15px', height: '63vh' }}>
                <div style={{ display: 'flex', flexDirection: 'row', height: '5vh' }}>
                    <div className="dx-field" style={{ width: "50%" }}>
                        <div className="dx-field-label" style={{ width: "25%" }}><FormattedMessage id="ASITE.FORMS.FILE_MANAGER.DOC_TITLE" />:</div>
                        <div className="dx-field-value" style={{ width: "75%" }}>
                            <TextBox
                                showClearButton={true}
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => this.setState({ searchParams: { ...this.state.searchParams, searchdoctitle: e.value } })}
                            />
                        </div>
                    </div>
                    <div className="dx-field" style={{ marginLeft: "30px", width: "50%" }}>
                        <div className="dx-field-label" style={{ width: "25%" }}>Doc Ref:</div>
                        <div className="dx-field-value" style={{ width: "75%" }}>
                            <TextBox
                                showClearButton={true}
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => this.setState({ searchParams: { ...this.state.searchParams, searchdocref: e.value } })}
                                hint={this.props.intl.formatMessage({ id: 'ASITE.FORMS.HINTDOCREFALTREF' })}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', height: '5vh' }}>
                    { this.state.parameters[0] ? this.dynamicFilter(this.state.parameters[0], 0) : null }
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', height: '5vh' }}>
                    { this.state.parameters[1] ? this.dynamicFilter(this.state.parameters[1], 1) : null }
                    { this.state.parameters[2] ? this.dynamicFilter(this.state.parameters[2], 2) : null }
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', height: '5vh' }}>
                    { this.state.parameters[3] ? this.dynamicFilter(this.state.parameters[3], 3) : null }
                    { this.state.parameters[4] ? this.dynamicFilter(this.state.parameters[4], 4) : null }
                    { this.state.parameters[5] ? this.dynamicFilter(this.state.parameters[5], 5) : null }
                </div>
                {
                    typeof this.state.parameters[6] !== 'undefined' && (
                        <div style={{ display: 'flex', flexDirection: 'row', height: '5vh' }}>
                            { this.state.parameters[6] ? this.dynamicFilter(this.state.parameters[6], 6) : null }
                            { this.state.parameters[7] ? this.dynamicFilter(this.state.parameters[7], 7) : null }
                        </div>
                    )
                }
                {/* <div style={{ display: 'flex', flexDirection: 'row', height: '5vh', borderBottom: '1px solid gray' }}>
                    <div className="dx-field" style={{ width: "24%" }}>
                        <div className="dx-field-label" style={{ width: "90%" }}>
                            {this.props.intl.formatMessage({ id: 'ASITE.FORMS.SEARCH_IN_SELECTED_FOLDER' })}
                        </div>
                        <div className="dx-field-value" style={{ width: "10%" }}>
                            <CheckBox
                                defaultValue={false}
                                onValueChanged={(e) => this.setState({ isFolderCheckboxSelected: e.value, searchParams: { ...this.state.searchParams, folderid: null }, folderPath: null })}
                            />
                        </div>
                    </div>
                    <div className="dx-field" style={{ marginLeft: "50px", width: "71%" }}>
                        {this.state.isFolderCheckboxSelected &&
                        <React.Fragment>
                            <div className="dx-field-label" style={{ width: "25%" }}>
                                {this.props.intl.formatMessage({ id: 'ASITE.FORMS.SELECTED_FOLDER_PATH' })}
                            </div>
                            <div className="dx-field-value" style={{ width: "75%", paddingTop: "9px" }}>
                                {this.state.folderPath ? this.state.folderPath : "-"}
                            </div>
                        </React.Fragment>}
                    </div>
                    <div className="dx-field" style={{ marginLeft: "100px", width: "5%" }}>
                        <Button variant="primary" onClick={this.onItemClick} style={{ lineHeight: 1.3, paddingLeft: '10px' }}>
                            {this.props.intl.formatMessage({ id: 'GENERAL.DOCUMENTS.FILTER' })}
                        </Button>
                    </div>
                </div> */}
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className="dx-field" style={{ width: "24%", display: 'flex', alignItems: 'center' }}>
                            <div className="dx-field-label" style={{ width: "90%" }}>
                                {this.props.intl.formatMessage({ id: 'ASITE.FORMS.SEARCH_IN_SELECTED_FOLDER' })}
                            </div>
                            <div className="dx-field-value" style={{ width: "10%" }}>
                                <CheckBox
                                    defaultValue={false}
                                    onValueChanged={(e) => this.setState({ isFolderCheckboxSelected: e.value, searchParams: { ...this.state.searchParams, folderid: null }, folderPath: null })}
                                />
                            </div>
                        </div>
                        <div className="dx-field" style={{ marginLeft: "50px", width: "71%", display: 'flex', alignItems: 'center' }}>
                            {this.state.isFolderCheckboxSelected &&
                            <React.Fragment>
                                <div className="dx-field-label" style={{ width: "25%" }}>
                                    {this.props.intl.formatMessage({ id: 'ASITE.FORMS.SELECTED_FOLDER_PATH' })}
                                </div>
                                <div className="dx-field-value" style={{ width: "75%", paddingTop: "9px" }}>
                                    {this.state.folderPath ? this.state.folderPath : "-"}
                                </div>
                            </React.Fragment>}
                        </div>
                        <div className="dx-field" style={{ marginLeft: "100px", width: "5%" }}>
                            <Button variant="primary" onClick={this.onItemClick} style={{ lineHeight: 1.3, paddingLeft: '10px' }}>
                                {this.props.intl.formatMessage({ id: 'GENERAL.DOCUMENTS.FILTER' })}
                            </Button>
                        </div>
                    </div>
                    <div style={{ borderBottom: '1px solid gray', paddingTop: '5px', textAlign: 'right' }}>
                        {this.state.totalItemsCount} {this.props.intl.formatMessage({ id: 'ASITE.FORMS.NUMBER_OF_ITEMS_SELECTED' })}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: "20%", display: !this.state.isFolderCheckboxSelected ? 'none' : null }}>
                        <FileManager
                            ref={this.fileManagerRef}
                            style={{ width: '240px' }}
                            itemView={{ mode:'' }}
                            fileSystemProvider={this.props.dataSource}
                            height='40vh'
                            rootFolderName={this.props.intl.formatMessage({ id: 'GENERAL.FILE_MANAGER.NAME' })}
                            onCurrentDirectoryChanged={this.onCurrentDirectoryChanged}
                        />
                    </div>
                    <div style={{ width: "80%" }}>
                        {this.state.loading && !this.state.searchResults ? <PortletSpinner /> :
                            this.state.searchResults && 
                            <div className="list-container" style={{ minHeight: "200px", position: 'absolute', height: 'auto'/*, borderStyle: 'solid', borderColor: 'grey'*/ }}>
                                <List
                                    ref={this.listRef}
                                    dataSource={this.state.dataSource}
                                    keyExpr="documentid"
                                    height='40vh'
                                    itemRender={ItemTemplate}
                                    selectionMode="all"
                                    selectAllMode="allPages"
                                    showSelectionControls={true}
                                    searchExpr="name"
                                    searchEnabled={false}
                                    searchMode='contains'
                                    // selectedItemKeys={this.props.selectedItemKeys}
                                    // onSelectedItemKeysChange={this.props.selectedItemKeysChange}
                                    // onSelectionChanged={this.props.selectedItemKeysChange}
                                    onOptionChanged={this.onSelectedItemKeysChange}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(AsiteFileFilter)));
