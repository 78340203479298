import React from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import * as companiesCrud from '../../../../crud/companies.crud';
import * as companyUserCrud from '../../../../crud/companyUser.crud';
import * as contractUserCrud from '../../../../crud/contractUser.crud';
import * as contractDuck from '../../../../store/ducks/contract.duck';
import { contractRoleTypes, GetCurrencyCodes } from "../../../../shared/lookup";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import DataGrid, { Lookup, Column, SearchPanel, Export, ColumnChooser, ColumnFixing, Editing, HeaderFilter, Summary, TotalItem, Scrolling, Form, Toolbar, Item as ToolbarItem, Selection } from 'devextreme-react/data-grid';
import { FormattedMessage, injectIntl } from "react-intl";
import moment from 'moment';
import { Item as FormItem } from 'devextreme-react/form';
import { confirm } from 'devextreme/ui/dialog';
import Common from '../../../../shared/common';
import { dxNumberBoxProps } from '../../../../shared/config/DxFormProperties';
import { custom as CustomDialog } from 'devextreme/ui/dialog';
import * as userCrud from '../../../../crud/user.crud';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

const isFinancialLimitEnabled = process.env.REACT_APP_FINANCIAL_LIMIT_ENABLED === "true";
const isShowMultipleUsersOnDashboardEnabled = process.env.REACT_APP_SHOW_MULTIPLE_USERS_ON_DASHBOARD_ENABLED === "true";

class ContractUsers extends React.Component {
    constructor(props) {
        super(props);
        this.gridRef = React.createRef();
        this.currencyCodes = GetCurrencyCodes();
        this.contractPartyTypesAll = [
            { value: 'PM', display: this.props.contractTemplate.resources.PM_NAME },
            { value: 'Contractor', display: this.props.contractTemplate.resources.SUPPLIER_NAME },
            { value: 'Supervisor', display: this.props.contractTemplate.resources.SUPERVISOR_NAME },
            { value: 'Client', display: this.props.contractTemplate.resources.CLIENT_NAME },
        ];

        this.contractPartyTypesAll = this.contractPartyTypesAll.sort((a, b) => a.display.localeCompare(b.display));

       

        switch (props.contract.partystructure) {
            case 1:
                this.contractPartyTypesAvailable = this.contractPartyTypesAll.filter(p => p.value !== 'Client' && p.value !== 'Supervisor');
                break;
            case 2:
                this.contractPartyTypesAvailable = this.contractPartyTypesAll.filter(p => p.value !== 'Supervisor');
                break;
            case 3:
                this.contractPartyTypesAvailable = [...this.contractPartyTypesAll];
                break;
            case 4:
                this.contractPartyTypesAvailable = this.contractPartyTypesAll.filter(p => p.value !== 'Client');
                break;
            default:
                this.contractPartyTypesAvailable = [...this.contractPartyTypesAll];
                break;
        }


        this.allowEdit = this.props.protectedRouteProps.allowEdit;
        this.allowSeeFinancialFields = isFinancialLimitEnabled && this.props.contract.financiallimitenabled && this.props.protectedRouteProps.allowSeeFinancialFields;
        this.allowSeeDashboardButtons = isShowMultipleUsersOnDashboardEnabled && this.props.protectedRouteProps.allowSeeDashboardButtons;

        this.state = {
            companyOptions: null
        }
    }

    componentDidMount() {
        this.loadCompanyOptions();
    }

    loadCompanyOptions = async () => {
        try {
            const { data: companies } = await companiesCrud.getCutDownCompaniesList();
            this.setState({ companyOptions: companies });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    //TODO: Check before create and display warning
    checkIsUserPartyMatchedContractPartyCompany = async (user) => {
        let partySelectionMatchesContractCompany = false;
        switch (user.usergroup) {
            case "Contractor":
                partySelectionMatchesContractCompany = this.props.contract.contractorcompanyid === user.companyid;
                break;
            case "PM":
                partySelectionMatchesContractCompany = this.props.contract.pmcompanyid === user.companyid;
                break;
            case "Supervisor":
                partySelectionMatchesContractCompany = this.props.contract.supervisorcompanyid === user.companyid;
                break;
            case "Client":
                partySelectionMatchesContractCompany = this.props.contract.employercompanyid === user.companyid;
                break;
            default:
                break;
        }

        if (!partySelectionMatchesContractCompany) {
            let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "CONTRACT.USERS.WARNING_TEXT" })}</div>`, this.props.intl.formatMessage({ id: "CONTRACT.USERS.WARNING_TITLE" }));
            return result;
        }
        else {
            return true;
        }
    }
    addUserToContractPromise = (newContractUser, e) => {
        return new Promise(async (resolve, reject) => {
            try {
                const checkPassed = await this.checkIsUserPartyMatchedContractPartyCompany(newContractUser);
                if (checkPassed) {
                    const { companyid, projectid, contractid } = this.props.contract;
                    newContractUser.createbyid = this.props.userProfile.accountid;
                    newContractUser.companyid = companyid;
                    newContractUser.projectid = projectid;
                    newContractUser.contractid = contractid;
                    const resp = await contractUserCrud.saveContractUser(newContractUser);
                    e.data.contractuserid = resp.data;
                    this.props.refreshContractUsers();
                    resolve(false);
                }
                else {
                    reject(this.props.intl.formatMessage({ id: "CONTRACT.USERS.WARNING_TITLE" }))
                }


            } catch (error) {
                console.log(error);
                reject(error)
            }
        });
    }

    deleteContractUserPromise = (contractid, contractuserid) => {
        return new Promise(async (resolve, reject) => {
            try {
                await contractUserCrud.deleteContractUser(contractid, contractuserid);
                this.props.refreshContractUsers();
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        });
    }

    //deleting row
    onRowRemoving = async (e) => {
        const { contractid } = this.props.contract;
        e.cancel = this.deleteContractUserPromise(contractid, e.data.contractuserid);
    }

    //adding row
    onRowInserting = async (e) => {

        const newContractUser = { ...e.data, usersource: 'Account' };
        if (isFinancialLimitEnabled && this.props.contract.financiallimitenabled) {
            if (!newContractUser.userlimit) {
                newContractUser.userlimit = 0;
            }
            newContractUser.userlimitcurrency = this.props.contract.currency;
        }
        e.cancel = this.addUserToContractPromise(newContractUser, e);
    }
    onInitNewRow = async (e) => {
        e.data = { deleted: 0 };
        if (isFinancialLimitEnabled && this.props.contract.financiallimitenabled) {
            e.data.userlimitcurrency = this.props.contract.currency;
        }
    }
    // onRowValidating = async (e) => {
    //     const newObject = { ...e.oldData, ...e.newData };
    //     e.promise = new Promise(async (resolve, reject) => {
    //         const checkPassed = true;
    //         if (!checkPassed) {
    //             e.errorText = "Some error";
    //             e.isValid = false;
    //         }
    //         //DONT CHANGE STATUS IN OTHER CASES
    //         resolve();
    //     });
    // }

    getCompanyUserDataSource = (options) => {
        const { contractid } = this.props.contract;
        if ((options.data || {}).companyid) {
            const datasource = {
                loadMode: 'raw',
                cacheRawData: true,
                load: () => {
                    var promise = new Promise((resolve) => {
                        companyUserCrud.getCompanyUsersToSelectListForContract(options.data.companyid, contractid)
                            .then(response => {
                                let users = response.data;
                                if (users.length < 1) {
                                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "WARNING.COMPANY_HAS_NO_USERS" }), { variant: 'warning', });
                                }
                                users = users.map(user => ({ accountid: user.accountid, display: `${user.name} (${user.email})` }));
                                resolve(users);
                            });
                    });
                    return promise;
                }
            }
            return datasource;
        }
        else return [];
    }

    //USED FOR CASCADING FORM FIELDS
    setCompanyCellValue(rowData, value) {
        rowData.userid = null;
        this.defaultSetCellValue(rowData, value);
    }
    calculateEmailState = (rowData) => {
        if (rowData.disableglobalemailnotifications) {
            return true;
        }
        else {
            return false
        }
    }

    onbtnRemoveFromDashboardClick = async () => {
        try {
            const selection = this.gridRef.current.instance.getSelectedRowKeys();
            if (selection.length) {
                let activateDialog = CustomDialog({
                    title: this.props.intl.formatMessage({ id: 'GENERAL.USER_REMOVING_CONTRACT_DASHBOARD' }, { LENGTH: selection.length }),
                    messageHtml: `<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: 'GENERAL.USER_ABOUT_TO_REMOVE_CONTRACT_DASHBOARD' }, { LENGTH: selection.length })} </div>`,
                    buttons: [
                        {
                            text: "Cancel",
                            onClick: (e) => { return "CANCEL" }
                        },
                        {
                            text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.REMOVE_FROM_DASHBOARD" }),
                            type: "danger",
                            stylingMode: "outlined",
                            onClick: (e) => { return "HIDE" }
                        }
                    ]
                });
                const result = await activateDialog.show();
                if (result === "HIDE") {
                    const formData = {
                        contractUserIds: selection,
                        actionType: result,
                        contractId: this.props.contract.contractid
                    };

                    await userCrud.bulkShowHideUsersOnDashboard(formData);
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "GENERAL.USER_REMOVED_CONTRACT_DASHBOARD" }), { variant: "success" });
                    this.props.refreshContractUsers();
                    this.gridRef.current.instance.clearSelection();
                }
            }
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: "error" });
        }
    }

    onbtnShowOnDashboardClick = async () => {
        try {
            const selection = this.gridRef.current.instance.getSelectedRowKeys();
            if (selection.length) {
                let activateDialog = CustomDialog({
                    title: this.props.intl.formatMessage({ id: 'GENERAL.USER_SHOWING_CONTRACT_DASHBOARD' }, { LENGTH: selection.length }),
                    messageHtml: `<div style="max-width:60vw;">${this.props.intl.formatMessage({ id: 'GENERAL.USER_ABOUT_TO_SHOW_CONTRACT_DASHBOARD' }, { LENGTH: selection.length })} </div>`,
                    buttons: [
                        {
                            text: "Cancel",
                            onClick: (e) => { return "CANCEL" }
                        },
                        {
                            text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SHOW_ON_DASHBOARD" }),
                            type: "success",
                            stylingMode: "outlined",
                            onClick: (e) => { return "SHOW" }
                        }
                    ]
                });
                const result = await activateDialog.show();
                if (result === "SHOW") {
                    const formData = {
                        contractUserIds: selection,
                        actionType: result,
                        contractId: this.props.contract.contractid
                    };

                    await userCrud.bulkShowHideUsersOnDashboard(formData);
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "GENERAL.USER_ADDED_CONTRACT_DASHBOARD" }), { variant: "success" });
                    this.props.refreshContractUsers();
                    this.gridRef.current.instance.clearSelection();
                }
            }
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: "error" });
        }
    }

    onSelectionChanged = ({ selectedRowKeys }) => {
        if (selectedRowKeys.length > 0 && selectedRowKeys.length <= 100) {
            if (this.btnShowOnDashboard) { this.btnShowOnDashboard.component.option("disabled", false); }
            if (this.btnRemoveFromDashboard) { this.btnRemoveFromDashboard.component.option("disabled", false); }
        } else {
            if (this.btnShowOnDashboard) { this.btnShowOnDashboard.component.option("disabled", true); }
            if (this.btnRemoveFromDashboard) { this.btnRemoveFromDashboard.component.option("disabled", true); }
        }
        if (selectedRowKeys.length > 100) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "GENERAL.USER_SELECTION_LIMIT" }), { variant: "warning" });
        }
    }
    onExporting = (e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
          component: e.component,
          worksheet: worksheet,
        }).then(function () {
          workbook.xlsx.writeBuffer()
            .then(function (buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ContractUsers.xlsx');
            });
        });
      }
    render() {
        if (!this.props.contract || !this.props.contractUsers) {
            return (<PortletSpinner />);
        }
        return (
            <Portlet>
                <PortletBody >
                    <DataGrid
                        dataSource={this.props.contractUsers}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        remoteOperations={false}
                        allowColumnResizing={true}
                        allowColumnReordering={true}
                        columnResizingMode='widget'
                        onRowRemoving={this.onRowRemoving}
                        onInitNewRow={this.onInitNewRow}
                        onRowInserting={this.onRowInserting}
                        // onRowValidating={this.onRowValidating}
                        style={{ maxHeight: '67vh', minHeight: "50vh" }}
                        name="ContractUsers"
                        keyExpr="contractuserid"
                        loadPanel={{ showIndicator: !this.state.contractUsers }}
                        onSelectionChanged={this.onSelectionChanged}
                        ref={this.gridRef}
                        onExporting={this.onExporting}
                    >
                        {
                            this.allowSeeDashboardButtons && (
                                <Selection
                                    mode="multiple"
                                    selectAllMode="allPages"
                                    showCheckBoxesMode="always"
                                />
                            )
                        }
                        <Editing
                            //CACADING FORM DEMO IMPLEMENTATION
                            //https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CascadingLookups/React/Light/
                            mode="popup"
                            allowUpdating={false}
                            allowDeleting={this.allowEdit}
                            allowAdding={this.allowEdit}
                            confirmDelete={true}
                            //https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxPopup/Configuration/
                            popup={{ title: this.props.intl.formatMessage({ id: "CONTRACT.USERS.ADD_CONTRACT_USER" }), minWidth: 600, height: "auto", width: "auto", showTitle: true }}
                            // useIcons={true}
                            //https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/editing/texts/#deleteRow
                            texts={{
                                deleteRow: this.props.intl.formatMessage({ id: "GENERAL.FORMS.REMOVE" }),
                                confirmDeleteTitle: this.props.intl.formatMessage({ id: "CONTRACT.USERS.REMOVE_USER" }),
                                confirmDeleteMessage: this.props.intl.formatMessage({ id: "CONTRACT.USERS.REMOVE_MESSAGE" })
                            }}
                        >
                            <Form>
                                <FormItem colSpan={2} dataField="companyid" isRequired />
                                <FormItem colSpan={2} dataField="userid" isRequired />
                                <FormItem colSpan={2} dataField="usergroup" editorOptions={{ dataSource: this.contractPartyTypesAvailable, valueExpr: 'value', displayExpr: 'display' }} isRequired />
                                <FormItem colSpan={2} dataField="rolepermissions" isRequired />
                                {this.props.contract.financiallimitenabled && this.allowSeeFinancialFields && (
                                    <FormItem colSpan={2} itemType="group" caption={this.props.intl.formatMessage({ id: "GENERAL.FORMS.FINANCIAL_LIMIT" })}  >
                                        <FormItem colSpan={2} dataField="userlimit" editorType="dxNumberBox" label={{ text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.AMOUNT" }) }} editorOptions={{ ...dxNumberBoxProps(this.props.contract.currency) }} />
                                        <FormItem colSpan={2} dataField="userlimitcurrency" editorType="dxSelectBox" label={{ text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.CURRENCY" }) }} editorOptions={{ dataSource: this.currencyCodes, valueExpr: 'code', displayExpr: 'name', readOnly: true, width: 200 }} />
                                    </FormItem>
                                )}


                            </Form>
                        </Editing>
                        <HeaderFilter visible={true} />
                        <ColumnChooser enabled={true} />
                        <ColumnFixing enabled={true} />
                        <Export enabled={true} fileName={this.props.intl.formatMessage({ id: 'CONTRACT.USERS.TITLE' })} />
                        <SearchPanel visible={true} highlightCaseSensitive={false} defaultText="" />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.COMPANY" })}
                            dataField="usercompany"
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.NAME" })}
                            dataField="user"
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.EMAIL" })}
                            dataField="useremail"
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.PARTY" })}
                            dataField="usergroup"
                            lookup={{ dataSource: this.contractPartyTypesAll, valueExpr: 'value', displayExpr: 'display' }}
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.ROLE" })}
                            dataField="rolepermissions"
                            lookup={{ dataSource: contractRoleTypes }}
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.LAST_EDITED" })}
                            dataField="dateadded"
                            dataType="date"
                            format={(date) => moment(date).format('DD/MM/YYYY HH:mm')}
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.EMAILS_DISABLED" })}
                            dataField="disableglobalemailnotifications"
                            // falseText={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SWITCH_NO' })}
                            // trueText={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SWITCH_YES' })}
                            // showEditorAlways={false}
                            // dataType="boolean" 
                            calculateCellValue={this.calculateEmailState}
                        />
                        <Column
                            caption={this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.EMAILS_OPTEDOUT" })}
                            dataField="optoutemailnotifications"
                        // falseText={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SWITCH_NO' })}
                        // trueText={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SWITCH_YES' })}
                        // showEditorAlways={false}
                        // dataType="boolean"
                        />
                        {this.allowSeeDashboardButtons && (
                            <Column
                                caption={this.props.intl.formatMessage({ id: "GENERAL.FORMS.SHOW_ON_DASHBOARD" })}
                                dataField="showondashboard"
                            />
                        )}
                        {this.allowSeeFinancialFields && (
                            <Column
                                caption={this.props.intl.formatMessage({ id: "GENERAL.FORMS.FIELD.FINANCIAL_LIMIT_AMOUNT" })}
                                dataField="userlimit"
                                dataType="currency"
                                format={(num) => Common.FormatCurrencyNum(num, this.props.contract.currency)}
                                allowFiltering={false}
                            />
                        )}
                        {this.allowSeeFinancialFields && (
                            <Column
                                caption={this.props.intl.formatMessage({ id: "GENERAL.FORMS.FIELD.FINANCIAL_LIMIT_CURRENCY" })}
                                dataField="userlimitcurrency"
                            />
                        )}
                        <Column
                            //ADDED TO MATCH FORM INPUT
                            //NOT DISPLAYED
                            caption={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.COMPANY" })}
                            dataField="companyid"
                            setCellValue={this.setCompanyCellValue}
                            visible={false}
                            showInColumnChooser={false}
                        >
                            <Lookup dataSource={this.state.companyOptions} displayExpr="name" valueExpr="companyid" />
                        </Column>
                        <Column
                            //ADDED TO MATCH FORM INPUT
                            //NOT DISPLAYED
                            caption={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.USER" })}
                            dataField="userid"
                            visible={false}
                            showInColumnChooser={false}
                        >
                            <Lookup dataSource={this.getCompanyUserDataSource} displayExpr="display" valueExpr="accountid" />

                        </Column>
                        <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                        <Toolbar>
                            <ToolbarItem location="before">
                                <div>
                                    <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">
                                        <FormattedMessage id="CONTRACT.USERS.TITLE" />
                                    </h3>
                                </div>
                            </ToolbarItem>
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="addRowButton"
                                showText="always"
                                options={{
                                    icon: "plus",
                                    text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.ADD_USER" }),
                                }}
                            />
                            {
                                this.allowSeeDashboardButtons && (
                                    <ToolbarItem
                                        location="after"
                                        locateInMenu="auto"
                                        widget="dxButton"
                                        options={{
                                            onInitialized: (e) => { this.btnRemoveFromDashboard = e; },
                                            icon: "decreaseindent",
                                            text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.REMOVE_FROM_DASHBOARD" }),
                                            disabled: true,
                                            onClick: this.onbtnRemoveFromDashboardClick,
                                            type: "danger",
                                            stylingMode: "outlined",
                                        }}
                                    />
                                )
                            }
                            {
                                this.allowSeeDashboardButtons && (
                                    <ToolbarItem
                                        location="after"
                                        locateInMenu="auto"
                                        widget="dxButton"
                                        options={{
                                            onInitialized: (e) => { this.btnShowOnDashboard = e; },
                                            icon: "increaseindent",
                                            text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SHOW_ON_DASHBOARD" }),
                                            disabled: true,
                                            onClick: this.onbtnShowOnDashboardClick,
                                            type: "success",
                                            stylingMode: "outlined",
                                        }}
                                    />
                                )
                            }
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="exportButton"
                            />
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="columnChooserButton"
                            />
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="searchPanel"
                            />
                        </Toolbar>
                        <Summary>
                            <TotalItem
                                column="contractuserid"
                                summaryType="count"
                                showInColumn="usercompany"
                            />
                        </Summary>
                    </DataGrid>
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    contractUsers: store.contract.users,
    userProfile: store.auth.userProfile,
    contractTemplate: store.contract.template,
});

const mapDispatchToProps = {
    refreshContractUsers: contractDuck.actions.refreshUsers,
}

export default injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ContractUsers)));
