import React from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
// import DefaultTemplates from '../../../../Templates/DefaultTemplates';
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import MasterTemplateDetails from '../../Templates/MasterTemplates/MasterTemplateDetails';
import * as templatesDuck from '../../../../store/ducks/templates.duck';
import * as companiesDuck from '../../../../store/ducks/companies.duck';
import * as contractsCrud from '../../../../crud/contracts.crud';
import * as templatesCrud from '../../../../crud/templates.crud';
import * as contractDuck from '../../../../store/ducks/contract.duck';
import * as contractsDuck from '../../../../store/ducks/contracts.duck';
import CopyTracksModal from '../../Templates/MasterTemplates/CustomControls/CopyTracksModal';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import moment from 'moment';
import TemplateHelper from "../../../../Templates/TemplateHelper";
import { Badge } from "react-bootstrap";
import DxFormModal from '../../../../partials/content/dxControls/dxFormModal';
import { withRouter } from '../../../../shared/hoc/withRouter';
import Common from '../../../../shared/common';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import { Link, Navigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { LoadIndicator } from 'devextreme-react/load-indicator';

class ContractTemplateEditor extends React.Component {
    constructor(props) {
        super(props);
        this.fileImportInputRef = React.createRef();

        this.copyModalConfig = {
            fields: [
                { resource: "CONTRACT.TEMPLATE.NEW_TEMPLATE_NAME", dataField: 'name', required: true },
            ]
        };

        this.overrideWithMasterModalConfig = {
            fields: [
                { resource: "CONTRACT.FIELD.TEMPLATE", dataField: 'mastertemplateid', editorType: "dxSelectBox", required: true, editorOptions: { dataSource: this.getTemplateOptions(this.props.contract.companyid), valueExpr: 'templateid', displayExpr: 'name' } },
            ]
        };

        this.state = {
            contractTemplate: null,
            changed: false,
            isSaving: false,
            stateInitialized: false,
            showCopyAsMasterModal: false,
            showCopyTrackModal: false,
            versionTrackerForChildrenReRender: 1
        }
    }


    componentDidMount() {
        this.props.loadWorkflows();
        this.props.loadCompanies();
        if (!this.state.stateInitialized && this.props.contractTemplate) {
            this.initializeState();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (!this.state.stateInitialized && this.props.contractTemplate) {
                this.initializeState();
            }
        }
    }
    getTemplateOptions = (companyid) => {
        const datasource = {
            loadMode: 'raw',
            cacheRawData: true,
            load: () => {
                var promise = new Promise((resolve, reject) => {
                    templatesCrud.getTemplatesToSelectList(companyid)
                        .then(response => {
                            let retVal = [];
                            if (response.data) {
                                retVal = response.data;
                            }
                            resolve(retVal);
                        })
                        .catch((err) => {
                            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                            reject(err);
                        })
                });
                return promise;
            }
        }
        return datasource;
    }

    initializeState = () => {
        if (this.props.contract.templatejson && this.props.contract.templatejson.length > 0) {
            const initialTemplate = TemplateHelper.templateSanitizer(JSON.parse(this.props.contract.templatejson), this.props.contract);
            this.setState({
                contractTemplate: initialTemplate,
                stateInitialized: true,
            });
        }
        else {
            alert("Contract template not found! Contact System Administrator.")
            // this.initialTemplate = JSON.parse(JSON.stringify(DefaultTemplates[0]));
        }

    }

    saveChangesHandler = () => {
        this.setState({ isSaving: true }, async () => {
            try {
                const newTemplate = await this.updateContractTemplate();
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.TEMPLATE.UPDATE_SUCCESS' }), { variant: 'success', });
                this.props.contractsDuckSetPending();
                this.props.loadContract(this.props.contract.contractid);
                //SET TO NULL TO UN-MOUNT ALL COMPONENTS
                //FRESH START LOAD AGAIN
                this.setState({ contractTemplate: null, changed: false, isSaving: false }, () => {
                    this.setState({ contractTemplate: newTemplate })
                });
            }
            catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        })

    }

    downloadTemplateJson = (e) => {
        const templateFileName = `CONTRACT_TEMPLATE_${this.props.contract.contractid}`;
        const stringifiedJson = JSON.stringify({ ...TemplateHelper.templateSanitizer(this.state.contractTemplate, this.props.contract), name: templateFileName }, null, 2);
        Common.downloadFile({
            data: stringifiedJson,
            fileName: templateFileName + '.json',
            fileType: 'text/json',
        });
    }
    importTemplateJson = (e) => {
        if (this.fileImportInputRef.current) {
            this.fileImportInputRef.current.click();
        }
    }
    importFileSelected = async (e) => {
        if (e.stopPropagation) { e.stopPropagation(); }
        if (e.preventDefault) { e.preventDefault(); }
        if (e.target && e.target.files && e.target.files.length > 0) {
            var file = e.target.files[0];
            if (file) {
                try {
                    const text = await file.text();
                    const importedTemplate = JSON.parse(text)
                    importedTemplate.templateid = this.state.contractTemplate.templateid;
                    importedTemplate.name = "Contract ID:" + this.props.contract.contractid;
                    this.setState({
                        contractTemplate: importedTemplate,
                        //INCREASE VERSION SO CHILD COMPONENTS RELOAD
                        versionTrackerForChildrenReRender: this.state.versionTrackerForChildrenReRender + 1,
                        changed: true
                    });

                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.TEMPLATE.IMPORT_SUCCESS' }), { variant: 'success' });
                } catch (err) {
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.TEMPLATE.IMPORT_ERROR' }), { variant: 'error', });
                    console.log(err.toString())
                }
                finally {
                    if (this.fileImportInputRef.current) {
                        this.fileImportInputRef.current.value = null;
                    }
                }
            }
        }
    }
    updateContractTemplate = async () => {
        const newTemplate = TemplateHelper.templateSanitizer(this.state.contractTemplate, this.props.contract);
        const contractUpdateObj = {};
        contractUpdateObj.contractid = this.props.contract.contractid;
        contractUpdateObj.templatejson = JSON.stringify(newTemplate);
        await contractsCrud.updateContractTemplate(contractUpdateObj);
        return newTemplate;
    }

    onTemplateChanged = (newTemplate) => {
        newTemplate.workflowGroups = this.state.contractTemplate.workflowGroups;
        this.setState({ contractTemplate: newTemplate, changed: true });
    }
    onCopyAsMasterModalResponse = async (resp) => {

        if (resp) {
            this.setState({ showCopyAsMasterModal: false, isSaving: true }, async () => {
                try {
                    const newCopyInfo = this.state.contractTemplate.copyInfo ? JSON.parse(JSON.stringify(this.state.contractTemplate.copyInfo)) : [];
                    newCopyInfo.push(
                        {
                            copiedTemplateID: this.state.contractTemplate.templateid,
                            copiedTemplateName: "Contract ID:" + this.props.contract.contractid,
                            copiedById: this.props.userProfile.accountid,
                            copiedByName: this.props.userProfile.name,
                            copiedDateTime: moment().format('DD/MM/YYYY HH:mm')
                        }
                    );
                    const newTemplate = {
                        name: resp.name,
                        templatejson: JSON.stringify({ ...TemplateHelper.templateSanitizer(this.state.contractTemplate, this.props.contract), name: resp.name, isSystemDefault: false, copyInfo: newCopyInfo }),
                        deleted: 0,
                        templateid: null
                    };

                    await templatesCrud.saveTemplate(newTemplate)
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.TEMPLATE.COPY_AS_MASTER_SUCCESS' }), { variant: 'success', });
                    this.props.loadTemplates(true);
                    this.setState({ isSaving: false, navigateto: "/admin/templates/master-templates" });
                }
                catch (err) {
                    this.setState({ isSaving: false });
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
        }
        else {
            this.setState({ showCopyAsMasterModal: false });
        }
    }
    onOverrideWithMasterResponse = async (resp) => {
        if (resp) {
            this.setState({ contractTemplate: null, showOverrideTemplateModal: false, isSaving: true }, async () => {
                try {

                    const formData = {
                        contractid: this.props.contract.contractid,
                        mastertemplateid: resp.mastertemplateid
                    }
                    const result = await contractsCrud.overrideContractTemplate(formData)
                    const newTemplate = JSON.parse(result.data);
                    this.setState({ contractTemplate: newTemplate, isSaving: false });
                    this.props.loadContract(this.props.contract.contractid);
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.TEMPLATE.OVERRIDE_SUCCESS' }), { variant: 'success', });
                }
                catch (err) {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                    console.log(err.toString())
                }
            });
        }
        else {
            this.setState({ showOverrideTemplateModal: false });
        }
    }
    onWorkflowGroupChanged = (newWorkflowGroups) => {
        this.setState({ contractTemplate: { ...this.state.contractTemplate, workflowGroups: newWorkflowGroups }, changed: true });
    }
    onCopyTrackModalResponse = (resp) => {
        this.setState({ showCopyTrackModal: false });
    }
    renderOverrideWithMasterWarning = () => {
        return (
            <Alert variant="warning" style={{ display: 'block' }}>
                <p>
                    <FormattedMessage id="CONTRACT.TEMPLATE.OVERRIDE_WARNING" /><br />
                </p>
            </Alert>
        )
    }
    render() {
        if (!this.props.companies || !this.state.contractTemplate || !this.state.stateInitialized || !this.props.workflowGroups) {
            return (<PortletSpinner />);
        }

        const { allowUpdate, allowImportExport, allowClone, allowOverride, allowSeeHistory } = this.props.protectedRouteProps;

        return (
            <>
                {(!this.props.contract.usemastertemplate && !allowUpdate) && (<Badge variant="danger"><FormattedMessage id="GENERAL.WARNING.NOT_AUTHORIZED_ON_PAGE" /></Badge>)}
                <Portlet style={{ minWidth: 500 }}>
                    <NavigationBlocker
                        when={this.state.changed}
                        message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                    />
                    {this.state.navigateto &&
                        (
                            <Navigate replace to={this.state.navigateto} />
                        )
                    }
                    {allowImportExport && (
                        <input type='file' id='importFile' accept='.json' ref={this.fileImportInputRef} style={{ display: 'none' }} onChange={this.importFileSelected} />
                    )}
                    {!this.props.contract.usemastertemplate && (
                        <PortletHeader
                            title={this.props.intl.formatMessage({ id: 'CONTRACT.TEMPLATE.TITLE' })}
                            toolbar={(
                                <div style={{ display: 'flex', flex: 1, flexFlow: 'row-reverse', margin: "20px 0px" }}>
                                    <LoadIndicator className="button-indicator" visible={this.state.isSaving} />
                                    <Toolbar disabled={this.state.isSaving}>
                                        <ToolbarItem
                                            key="conTempSaveChanges"
                                            widget="dxButton"
                                            location="after"
                                            locateInMenu="never"
                                            disabled={!this.state.changed || !allowUpdate}
                                            options={{
                                                text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SAVE_CHANGES' }),
                                                type: "default",
                                                stylingMode: this.state.changed ? "contained" : "outlined",
                                                onClick: this.saveChangesHandler
                                            }} />
                                        {allowSeeHistory && (
                                            <ToolbarItem
                                                key="conTempViewHistory"
                                                location="after"
                                                locateInMenu="auto"
                                                widget="dxButton"
                                                options={
                                                    {
                                                        icon: "find",
                                                        onClick: () => this.setState({ showCopyTrackModal: true }),
                                                        text: this.props.intl.formatMessage({ id: 'TEMPLATE.BUTTONS.VIEW_COPY_HISTORY' }),
                                                    }
                                                }
                                            />
                                        )}
                                        {allowClone && (
                                            <ToolbarItem
                                                key="conTempClone"
                                                location="after"
                                                locateInMenu="always"
                                                widget="dxButton"
                                                disabled={this.state.changed}
                                                options={
                                                    {
                                                        icon: "copy",
                                                        onClick: () => this.setState({ showCopyAsMasterModal: true }),
                                                        text: this.props.intl.formatMessage({ id: 'TEMPLATE.BUTTONS.CLONE' }),
                                                    }
                                                }
                                            />
                                        )}
                                        {allowOverride && (
                                            <ToolbarItem
                                                key="conTempOverride"
                                                location="after"
                                                locateInMenu="always"
                                                widget="dxButton"
                                                disabled={this.state.changed}
                                                options={
                                                    {
                                                        icon: "refresh",
                                                        onClick: () => this.setState({ showOverrideTemplateModal: true }),
                                                        text: this.props.intl.formatMessage({ id: 'CONTRACT.TEMPLATE.BUTTONS.REPLACE' }),
                                                    }
                                                }
                                            />
                                        )}
                                        {allowImportExport &&
                                            (
                                                <ToolbarItem
                                                    key="conTempExport"
                                                    location="after"
                                                    locateInMenu="always"
                                                    widget="dxButton"
                                                    options={
                                                        {
                                                            icon: "export",
                                                            onClick: this.downloadTemplateJson,
                                                            text: this.props.intl.formatMessage({ id: 'TEMPLATE.BUTTONS.EXPORT_JSON' }),
                                                        }
                                                    }
                                                />
                                            )}
                                        {allowImportExport &&
                                            (
                                                <ToolbarItem
                                                    key="conTempImport"
                                                    location="after"
                                                    locateInMenu="always"
                                                    widget="dxButton"
                                                    options={
                                                        {
                                                            icon: "import",
                                                            onClick: this.importTemplateJson,
                                                            text: this.props.intl.formatMessage({ id: 'TEMPLATE.BUTTONS.IMPORT_JSON' }),
                                                        }
                                                    }
                                                />
                                            )}
                                    </Toolbar>
                                </div>
                            )}
                        />
                    )}
                    <PortletBody >
                        {!!this.props.contract.usemastertemplate && (
                            <Alert variant="warning" style={{ display: 'block', margin: 20 }}>
                                <Alert.Heading><FormattedMessage id="CONTRACT.TEMPLATE.ALERT.MASTER_IN_USE" values={{ TEMPLATE_NAME: this.state.contractTemplate.name }} /></Alert.Heading>
                                <hr />
                                <p>
                                    <FormattedMessage id="CONTRACT.TEMPLATE.WARNING.LINK_TO_TEMPLATE" values={{ LINK: (<Link to={`/admin/templates/template-editor/${this.props.contract.mastertemplateid}`} style={{ fontWeight: 500 }}>{this.state.contractTemplate.name}</Link>) }} /><br />
                                    <FormattedMessage id="CONTRACT.TEMPLATE.WARNING.LINK_TO_SETTING" values={{ LINK: (<Link to={`/contract/${this.props.contract.contractid}/settings/overview`} style={{ fontWeight: 500 }}><FormattedMessage id="CONTRACT.TEMPLATE.WARNING.CONTACT_OVERVIEW_PAGE" /></Link>) }} /><br />
                                </p>
                            </Alert>
                        )}
                        <MasterTemplateDetails
                            disabled={this.state.isSaving}
                            key={this.state.versionTrackerForChildrenReRender}
                            mode="CONTRACT"
                            companies={this.props.companies}
                            template={this.state.contractTemplate}
                            templateChanged={this.onTemplateChanged}
                            workFlowGroupChanged={this.onWorkflowGroupChanged}
                            workflowGroups={this.props.workflowGroups}
                            readOnly={!!this.props.contract.usemastertemplate}
                        />
                    </PortletBody>
                    <DxFormModal
                        visible={this.state.showCopyAsMasterModal}
                        title={this.props.intl.formatMessage({ id: 'CONTRACT.TEMPLATE.COPY_AS_MASTER_TITLE' })}
                        config={this.copyModalConfig}
                        onDialogResult={this.onCopyAsMasterModalResponse}
                        primaryButton={{
                            text: this.props.intl.formatMessage({ id: 'CONTRACT.TEMPLATE.COPY_AS_MASTER_BUTTON' }),
                            type: "success",
                            stylingMode: "outlined",
                        }}
                    />
                    <DxFormModal
                        headerComponent={this.renderOverrideWithMasterWarning()}
                        visible={this.state.showOverrideTemplateModal}
                        title={this.props.intl.formatMessage({ id: 'CONTRACT.TEMPLATE.OVERRIDE_WITH_MASTER_TITLE' })}
                        config={this.overrideWithMasterModalConfig}
                        onDialogResult={this.onOverrideWithMasterResponse}
                        primaryButton={{
                            text: this.props.intl.formatMessage({ id: 'CONTRACT.TEMPLATE.OVERRIDE_WITH_MASTER_BUTTON' }),
                            type: "danger",
                            stylingMode: "outlined",
                        }}
                    />
                    <CopyTracksModal
                        visible={this.state.showCopyTrackModal}
                        records={this.state.contractTemplate.copyInfo}
                        title={`Copy History Records`}
                        onDialogResult={this.onCopyTrackModalResponse}
                        disabled={!allowUpdate}
                    />
                </Portlet>
            </>
        )
    }
}


const mapStateToProps = store => ({
    contract: store.contract.contract,
    workflowGroups: store.templates.workflowGroups,
    companies: store.companies.companies,
    contractTemplate: store.contract.template,
    userProfile: store.auth.userProfile
});

const mapDispatchToProps = {
    loadWorkflows: templatesDuck.actions.fetchWorkflows,
    loadTemplates: templatesDuck.actions.fetchTemplates,
    loadCompanies: companiesDuck.actions.fetchStart,
    contractsDuckSetPending: contractsDuck.actions.setPending,
    loadContract: contractDuck.actions.fetchStart
}
export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ContractTemplateEditor))));

