import React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { injectIntl } from "react-intl";
import { dxHtmlEditorProps, } from '../../../shared/config/DxFormProperties';
import Form, {
    SimpleItem,
    GroupItem,
    RequiredRule,
    EmailRule
} from 'devextreme-react/form';

export const RESPONSE = {
    OK: 'OK',
    CANCEL: 'CANCEL',
};

class dxFormModal extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef()
    }
    state = {
        item: {},
    }
    componentDidUpdate(revProps, prevState) {
        //Modal closed
        if (revProps.visible === true && this.props.visible === false) {
            this.setState({ item: {} });
        }
    }
    onClose = () => {
        this.props.onDialogResult();
    }
    saveItem = () => {
        const validationRes = this.formRef.current.instance.validate();
        if (validationRes.isValid) {
            this.props.onDialogResult({ ...this.state.item });
        }

    }
    generateFieldItem = (item) => {

        let editorOptions = {};

        if (item.hasOwnProperty('editorOptions')) {
            editorOptions = { ...item.editorOptions };
        }
        if (item.resource) {
            item.label = this.props.intl.formatMessage({ id: item.resource })
        }

        if (item.hasOwnProperty('editorType')) {
            if (item.editorType === 'dxSelectBox') {
                editorOptions.searchEnabled = true;
                editorOptions.searchMode = 'contains';
                editorOptions.searchExpr = item.editorOptions.displayExpr;
                editorOptions.searchTimeout = 200;
                editorOptions.minSearchLength = 0;
            }
            else if (item.editorType === 'dxHtmlEditor') {
                editorOptions = { ...dxHtmlEditorProps };
                editorOptions.toolbar.items[4].options = { placeholder: this.props.intl.formatMessage({ id: "GENERAL.WORDS.COLOR" }) };
                editorOptions.toolbar.items[5].options = { placeholder: this.props.intl.formatMessage({ id: "GENERAL.WORDS.BACKGROUND" }) };
                editorOptions.height = 300;
                editorOptions.valueType = 'html';
            }
            else if (item.editorType === 'dxDateBox') {
                editorOptions.invalidDateMessage = 'The date must have the following format: DD/MM/YYYY';
                editorOptions.displayFormat = 'dd/MM/yyyy';
                editorOptions.placeholder = 'DD/MM/YYYY';
                editorOptions.openOnFieldClick = true;
                editorOptions.width = 200;
            }
        }

        const children = [];
        if (item.isEmail === true) {
            children.push(<EmailRule key="Sub2" message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.VALIDATION.INVALID_EMAIL' })} />);
        }

        let editorType = 'dxTextBox';
        if (item.hasOwnProperty('editorType')) {
            editorType = item.editorType;
        }

        return (
            <SimpleItem
                key={item.dataField}
                label={{ text: item.label }}
                dataField={item.dataField}
                editorType={editorType}
                editorOptions={editorOptions}
            >

                {item.required && (
                    <RequiredRule message={item.label + ' is required'} />
                )}
                {children}
            </SimpleItem>
        );
    }
    render() {
        return (
            <Popup
                visible={this.props.visible}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showTitle={true}
                title={this.props.title}
                width={600}
                height='auto'>
                    {
                        this.props.headerComponent 
                    }
                <form>
                    <Form
                        ref={this.formRef}
                        formData={this.state.item}
                        readOnly={false}
                        showColonAfterLabel={true}>
                        <GroupItem>
                            {
                                this.props.config.fields.map(item => this.generateFieldItem(item))
                            }
                        </GroupItem>
                    </Form>
                </form>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_CANCEL' }), onClick: this.onClose }}>
                </ToolbarItem>
                {
                    this.props.primaryButton && (
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={{
                                text: this.props.primaryButton.text,
                                type: this.props.primaryButton.type ? this.props.primaryButton.type : 'success',
                                stylingMode: this.props.primaryButton.stylingMode ? this.props.primaryButton.stylingMode : "contained",
                                onClick: this.saveItem
                            }}>
                        </ToolbarItem>
                    )
                }
            </Popup>
        );
    }
}

export default injectIntl(dxFormModal);