import React from "react";
import { Route, Routes } from "react-router-dom";
import CompensationListImplemented from './compensation/ListImplemented';


class CompensationRegisterRouter extends React.Component {
    constructor(props) {
        super(props);
        this.notificationAbbreviation_PM = (this.props.workflowsQA['5']||{}).abbreviation;
        this.notificationAbbreviation_SUP = (this.props.workflowsQA['7']||{}).abbreviation;
        this.notificationAbbreviation_CLIENT = (this.props.workflowsQA['76']||{}).abbreviation;
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;


        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<CompensationListImplemented
                        {...this.props}
                        notificationAbbreviation_PM={this.notificationAbbreviation_PM}
                        notificationAbbreviation_CLIENT={this.notificationAbbreviation_CLIENT}
                        notificationAbbreviation_SUP={this.notificationAbbreviation_SUP}
                        contractTemplate={this.props.contractTemplate}
                        contractUser={this.props.contractUser}
                        title={workflowItem.title}
                    />}
                />
            </Routes>
        );
    }
};




export default (CompensationRegisterRouter);