import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import UserOverview from './SubPages/Overview';
import ImageEditor from '../../partials/content/ImageEditor';
import SecondAsideMenu from '../../partials/layout/SecondAsideMenu';
import { SidedPage, SidedPageLeft, SidedPageRight } from '../../partials/content/SidedPage';
import { withSnackbar } from 'notistack';
import { toAbsoluteUrl } from "../../../_metronic";
import { withRouter } from '../../shared/hoc/withRouter';


class UserPage extends React.Component {

    // imageChangeHandler = (event) => {
    // }
    
    render() {
        if (Number(this.props.params.userid) !== this.props.userProfile.accountid) {
            this.props.enqueueSnackbar("Not accessable page", { variant: 'error' });
            return (<Navigate replace to={'/contracts'} />)
        }
        const secondAsideMenuConfig = {
            RootPath: '/user/' + this.props.params.userid,
            MenuItems: [
                { resource: 'NAVIGATION.MENU.OVERVIEW', icon: 'home', to: '/overview' },
                // { text: 'Notifications', icon: 'bell', to: '/notifications' },
            ]
        }

        return (
            <>
                <SidedPage>
                    <SidedPageLeft>
                        <SecondAsideMenu config={secondAsideMenuConfig} >
                            <ImageEditor src={toAbsoluteUrl("/media/bg/default-user.jpg")} fileChangeHandler={this.imageChangeHandler} />
                        </SecondAsideMenu>
                    </SidedPageLeft>
                    <SidedPageRight>
                        <UserOverview/>
                        {/* <Routes caseSensitive>
                            <Route path="/user/:userid/notifications" element={<UserNotifications/>} sensitive/> 
                            <Route path="/user/:userid" element={<UserOverview/>} sensitive/>
                            <Route path="*" element={<Navigate replace to="/contracts" />} />
                        </Routes> */}
                    </SidedPageRight>
                </SidedPage>
            </>
        );
    }
}


const mapStateToProps = store => ({
    userProfile: store.auth.userProfile
});


const mapDispatchToProps = {
}


export default withRouter(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(UserPage)));
