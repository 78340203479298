import React from 'react';
import { Route, Routes, Navigate, useLocation, matchRoutes } from "react-router-dom";
import "../../../../_metronic/_assets/sass/pages/login/login-auth0.scss";
import SuperTokenHandler from "./SuperTokenHandler";
import Auth0Handler from "./Auth0Handler";
import DiscoverHandler from "./DiscoverHandler"
import { connect } from "react-redux";
import Spinner from '../../../partials/layout/Spinner';



const DiscoverWithRouter = (props) => {
    let location = useLocation();
    const supertokenRouteMatch = matchRoutes([{ path: "/auth/discover/supertoken/*" }], location.pathname);
    // console.log(`${supertokenRouteMatch?.length} RouterMatches for path:${location.pathname}`, `token:`, props.authToken)
    return (
        <Routes caseSensitive>
            {/* <Route path="/" element={<Navigate replace to="/auth/discover" state={{}} />} /> */}
            <Route path="/auth/discover" exact element={<DiscoverHandler />} sensitive />
            <Route path="/auth/discover/auth0/*" element={<Auth0Handler />} sensitive />
            {(supertokenRouteMatch && supertokenRouteMatch.length > 0) && (
                <Route path="*" element={<SuperTokenHandler />} sensitive />
            )}
            
            {!props.authToken && (
                <Route path="*" element={<Navigate replace to="/auth/discover" state={{}} />} />
            )}
            {/* {(supertokenRouteMatch && supertokenRouteMatch.length > 0) ? (
                <Route path="*" element={<SuperTokenHandler />} sensitive />
            ) : (
                <Route path="*" element={<Navigate replace to="/auth/discover" state={{}} />} />
            )
            } */}
            <Route path="*" element={
                <div style={{ margin: "auto" }}>
                    <Spinner />
                </div>
            } sensitive />

            {/* <Route path="*" element={<Navigate replace to="/auth/discover" state={{}} />} /> */}
        </Routes>
    )

}

const mapStateToProps = (store) => ({

    authToken: store.auth.authToken.token,
});

export default connect(mapStateToProps)(DiscoverWithRouter);
