import React from "react";
import HorizontalBar from './HorizontalBar';

const colors = {
  success: "#07B388",
  danger: "#D3438E",
  warning: "#E98E23",
  brand: "#3F96C4",
  dark: "#282a3c",
  light: "#ffffff",
  primary: "#0064A2",
  info: "#66C3E7",
}

class ChartArea extends React.Component {
  constructor(props) {
    super(props);
    const { dashboard, resources } = props;
    this.allCharts = {
      labels: [
        resources.SUPPLIER_NAME + ' Early Warnings',
        resources.PM_NAME + ' Early Warnings',
        'High Risks',
        'Priority Risks',
        'Low Risks',
        resources.SUPPLIER_NAME + ' Compensation Events',
        resources.PM_NAME + ' Compensation Events',
        'Quote Accepted',
        'PM Assessment Made',
        'Change to works information',
        'No access and use of site',
        'Something not provided',
        'Stop or change key date',
        'Work by employer or others',
        'Late reply to communication',
        'Object found within sit',
        'Previous decision changed',
        'Acceptance Withheld',
        'Search but no defect',
        'Test causes a delay',
        'Unexpected Conditions',
        'Unexpected Weather',
        'Employer’s Risk Event',
        'Early Take Over',
        'Material for Test',
        'Correction to Assumption',
        'Breach of Contract',
        'Unexpected Event'
      ],
      data: [
        {
          backgroundColor: colors.brand,
          data: [
            dashboard.earlywarningcontractorraised,
            dashboard.earlywarningpmraised,
            dashboard.riskhigh,
            dashboard.riskpriority,
            dashboard.risklow,
            dashboard.compeventcontractorraised,
            dashboard.compeventpmraised,
            dashboard.quoteimplemented,
            dashboard.assessmentmade,
            dashboard.changeworks,
            dashboard.noaccess,
            dashboard.notprovided,
            dashboard.changekeydate,
            dashboard.workbyemployerorothers,
            dashboard.latereply,
            dashboard.objectfound,
            dashboard.previousdecision,
            dashboard.acceptancewithheld,
            dashboard.searchdefect,
            dashboard.testdelay,
            dashboard.unexpectedconditions,
            dashboard.unexpectedweather,
            dashboard.employersriskevent,
            dashboard.earlytakeover,
            dashboard.materialtest,
            dashboard.correctionassumption,
            dashboard.breachcontract,
            dashboard.unexpectedevent,
          ]
        },
      ]
    };
  }
  render() {
    return (
      <div style={{ width: '100%', marginTop: 20, marginBottom: 20 }}>
        <HorizontalBar type="horizontalBar" labels={this.allCharts.labels} datasets={this.allCharts.data} />
      </div>
    );
  }
}
export default ChartArea;