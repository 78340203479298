import axios from '../shared/axios';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function createProjectOrder(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/ProjectOrder/Create`, data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}

export function updateProjectOrder(data) {
    return new Promise((resolve, reject) => {
        axios.put(`/api/ProjectOrder/Update`, data)
            .then(result => {
                if (data.actioncode === 'COMMUNICATE' || data.actioncode === 'REPLY_SEND') {
                    store.dispatch({
                      type: contractDuck.actionTypes.CONTRACT_REFRESH_CONTRACT,
                    });
                  }
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}

export function getProjectOrder(projectorderid) {
    return axios.get(`/api/ProjectOrder/GetProjectOrder?earlyprojectorderid=${projectorderid}`);
}

export function getProjectOrdersReduced(companyid, projectid, contractid, accessibleWfIds) {
    return axios.post(`/api/ProjectOrder/RegisterList`, { accessibleWfIds }, { params: { companyid, projectid, contractid } });
}

export function getProjectOrdersToSelectList(contractid) {
    return axios.get(`/api/ProjectOrder/ToSelectList`, { params: { contractid} });
}

export function deleteProjectOrderDraft(projectorderid) {
    return new Promise((resolve, reject) => {
    axios.post(`/api/ProjectOrder/DeleteDraft?projectorderid=${projectorderid}`)
        .then(result => {
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
            resolve(result);
        })
        .catch(error => reject(error));
    })
}