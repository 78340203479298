/* eslint-disable import/no-anonymous-default-export */
import Workflows from "./Workflows";
import Placeholders from "./Placeholders";
export default {
    id: 109,
    title: 'Inspections & Defects',
    path: 'defects',
    icon: "flaticon2-magnifier-tool",
    workflows: Workflows,
    placeholders: Placeholders,
};