const template = {
    name: "Default NEC3 PSSC v2.5 01/01/2024",
    isSystemDefault: true,
    isGlobal: true,
    companies: [],
    resources: [
        {
            id: 1,
            type: "TEXT",
            key: "SUPPLIER_NAME",
            value: "Consultant"
        },
        {
            id: 2,
            type: "TEXT",
            key: "SUPPLIER_ABBREVIATION",
            value: "CON"
        },
        {
            id: 3,
            type: "TEXT",
            key: "PM_NAME",
            value: "Client"
        },
        {
            id: 4,
            type: "TEXT",
            key: "PM_ABBREVIATION",
            value: "Client"
        },
        {
            id: 5,
            type: "TEXT",
            key: "SUPERVISOR_NAME",
            value: "Supervisor"
        },
        {
            id: 6,
            type: "TEXT",
            key: "SUPERVISOR_ABBREVIATION",
            value: "SUP"
        },
        {
            id: 7,
            type: "TEXT",
            key: "CLIENT_NAME",
            value: "Client (Not Used)"
        },
        {
            id: 8,
            type: "NUMBER",
            key: "SUPPLIER_REPLY_PERIOD",
            value: 7
        },
        {
            id: 9,
            type: "NUMBER",
            key: "PM_REPLY_PERIOD",
            value: 7
        },
        {
            id: 10,
            type: "NUMBER",
            key: "QUOTE_DURATION",
            value: 7
        },
        {
            id: 13,
            type: "NUMBER",
            key: "REQUOTE_DURATION",
            value: 7
        },
        {
            id: 11,
            type: "NUMBER",
            key: "QUOTE_RESPONSE",
            value: 7
        },
        {
            id: 14,
            type: "NUMBER",
            key: "REQUOTE_RESPONSE",
            value: 7
        },
        {
            id: 12,
            type: "NUMBER",
            key: "VARIATION_RESPONSE",
            value: 7
        },
        {
            id: 8,
            type: "TEXT",
            key: "CLIENT_ABBREVIATION",
            value: "CLN"
        }
    ],
    workflowGroups: [
        {
            id: 101,
            title: "Early Warnings",
            workflows: [
                {
                    id: 1,
                    title: "$PM_NAME Early Warnings",
                    notificationName: "Early Warning",
                    abbreviation: "$PM_ABBREVIATION-Warning-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    actioningParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 2,
                    title: "$SUPPLIER_NAME Early Warnings",
                    notificationName: "Early Warning",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Warning-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    actioningParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                },
                {
                    id: 3,
                    title: "Risk Register",
                    notificationName: "Early Warning",
                    abbreviation: "Warning",
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "MeetingRequiredLabel",
                            display: "Is Risk Reduction Meeting required?"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "NotificationClause",
                            display: "You are given early warning of a matter which could"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "RiskItemsLabel",
                            display: "You are given early warning of a matter which could"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Pm",
                            display: "$PM_NAME Early Warnings awaiting reply"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Early Warnings awaiting reply"
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypes",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "riskincreaseprice",
                            display: "Increase the total of the Prices"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "riskdelaycompletion",
                            display: "Delay Completion"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "riskimpairperformance",
                            display: "Impair the usefulness of the services to the Client"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "riskcontractbreach",
                            display: "Affect the work of the Client or others with whom the client is in contract"
                        },
                        {
                            id: 9,
                            isActive: false,
                            field: "riskaffectmodel",
                            display: "Affect the achievement of another Partner's objectives"
                        },
                        {
                            id: 7,
                            isActive: false,
                            field: "riskaffectsafety",
                            display: "Delay meeting a Key Date"
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "riskdelaymilestone",
                            display: "Change the Accepted Programme"
                        },
                        {
                            id: 5,
                            isActive: false,
                            field: "riskincreasecontractorcost",
                            display: "Increase the Consultant's cost"
                        },
                        {
                            id: 6,
                            isActive: false,
                            field: "riskincreasedefinedcost",
                            display: "riskincreasedefinedcost"
                        }
                    ]
                },
                {
                    replacementKey: "MeetingOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "0",
                            display: "Meeting Not Required"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Meeting Required - Next Scheduled Risk Reduction Meeting"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Meeting Required - You are instructed to attend a meeting as below"
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypesSupplier",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "riskincreaseprice",
                            display: "Increase the total of the Prices"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "riskdelaycompletion",
                            display: "Delay Completion"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "riskimpairperformance",
                            display: "Impair the usefulness of the services to the Client"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "riskcontractbreach",
                            display: "Affect the work of the Client or others with whom the client is in contract"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "riskincreasecontractorcost",
                            display: "Increase the Consultant’s total cost"
                        },
                        {
                            id: 8,
                            isActive: false,
                            field: "riskaffectmodel",
                            display: "Affect the achievement of another Partner's objectives"
                        },
                        {
                            id: 5,
                            isActive: false,
                            field: "riskaffectsafety",
                            display: "Delay meeting a Key Date"
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "riskdelaymilestone",
                            display: "Change the Accepted Programme"
                        },
                        {
                            id: 10,
                            isActive: false,
                            field: "riskincreasedefinedcost",
                            display: "Increase the Defined Cost of expending any Provisional Sums"
                        }
                    ]
                }
            ]
        },
        {
            id: 102,
            title: "Instructions",
            workflows: [
                {
                    id: 4,
                    title: "$PM_NAME Instruction",
                    notificationName: "Instruction",
                    abbreviation: "$PM_ABBREVIATION-Instruction-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "InstructionTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "20.2 to change the Scope"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "22.3 to stop using a person to Provide the Services"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "30.3 to stop, not to start to restart or start work"
                        }
                    ]
                }
            ]
        },
        {
            id: 103,
            title: "Compensation Events",
            workflows: [
                {
                    id: 5,
                    title: "$PM_NAME Compensation Events",
                    notificationName: "Compensation Event",
                    abbreviation: "$PM_NAME-CEN-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 37,
                    title: "$SUPPLIER_NAME Compensation Event",
                    notificationName: " Compensation Event",
                    abbreviation: "$SUPPLIER_ABBREVIATION-CEN-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                },
                {
                    id: 8,
                    title: "$PM_NAME Assessments",
                    notificationName: "Assessment",
                    abbreviation: "$PM_ABBREVIATION-Assessment-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 11,
                    title: "Master Quote Register",
                    userFilters: {
                        accessFilter: "null"
                    }
                },
                {
                    id: 12,
                    title: "Master CE Register",
                    userFilters: {
                        accessFilter: "null"
                    }
                },
                {
                    id: 13,
                    title: "Implemented CE Register",
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmAssumptionLabel",
                            display: "The quotation is to be based on the following assumptions"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmAssumptionNote",
                            display: "You are instructed to provide a quotation by $date"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierDescNote",
                            display: "You are notified of the following event as a compensation event"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "ReplyAcceptedCommentLabel",
                            display: "You are notified that the event is a compensation event"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "ReplyRejectedCommentLabel",
                            display: "Details"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ReplyAcceptedAssumptionLabel",
                            display: "The quotation is to be based on the following assumptions"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "QuoteNote",
                            display: "We submit our quotation for compensation event $reference"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "ExtensionPMLabel",
                            display: "We require an extension to the time to reply as follows"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ExtensionSupplierLabel",
                            display: "We require an extension to the time to reply as follows"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "AssessmentDetailLabel",
                            display: "Details"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ProposedInstructionNote",
                            display: "You are instructed to provide a quotation by $date"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "ProposedInstructionLabel",
                            display: "The quotation is to be based on the following assumptions"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "ReplyRejectedOptionsLabel",
                            display: "You are notified that the event is not a compensation event as it"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "QuoteCostEffectLabel",
                            display: "Change to the Prices"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "QuoteDateEffectLabel",
                            display: "Delay to planned Completion"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "QuoteDaysEffectLabel",
                            display: "The length of time that the Completion Date is delayed"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "AssessmentCostEffectLabel",
                            display: "Change in the Total of the Prices"
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "AssessmentDaysEffectLabel",
                            display: "Delay to planned Completion"
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "PmCompensationWarningBanner",
                            display: "If the effect of the compensation event is too uncertain to forecast reasonably the Client notifies the Consultant of the date when the compensation event assessment is to be made. In that case, please raise and accept a Client Extension to update the assessment required date."
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "SupplierCompensationWarningBanner",
                            display: "Your quotation must be submitted immediately as required under 61.2 but will need to be be notified separately. "
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "ClaimWarningBanner",
                            display: "Your quotation must be submitted immediately as required under 61.2 but will need to be be notified separately. "
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "SupplierClaimWarningBanner",
                            display: "Your quotation must be submitted immediately as required under 61.2 but will need to be be notified separately. "
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "ClaimsWarningBanner",
                            display: "Your quotation must be submitted immediately as required under 61.2 but will need to be be notified separately. "
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "SupplierClaimsWarningBanner",
                            display: "Your quotation must be submitted immediately as required under 61.2 but will need to be be notified separately. "
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmCompensationAQ",
                            display: "Compensation Events notified by $PM_NAME awaiting quotes"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmCompensationARQ",
                            display: "Compensation Events notified by $PM_NAME awaiting revised quotes"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "PmCompensationAPMRQ",
                            display: "Compensation Events notified by $PM_NAME awaiting response to quotes"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "PmCompensationAPMA",
                            display: "Compensation Events notified by $PM_NAME awaiting $PM_NAME Assessment"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SupCompensationAQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting quotes"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SupCompensationARQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting revised quotes"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "SupCompensationAPMR",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION response"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "SupCompensationAPMRQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting response to quotes"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "SupCompensationAPMA",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "PmProposedAQ",
                            display: "Proposed changes awaiting quotes"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "PmProposedARQ",
                            display: "Proposed changes awaiting revised quotes"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "PmProposedAPMRQ",
                            display: "Proposed changes awaiting response to quotes"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "PmProposedAPMA",
                            display: "Proposed changes awaiting Assessment"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "PmExtension",
                            display: "$PM_NAME Response Extensions awaiting Acceptance"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "SupExtension",
                            display: "$SUPPLIER_NAME Quote Extensions awaiting Acceptance"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "SupClaimAQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting quotes"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "SupClaimAPMR",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_NAME response"
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "SupClaimARQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting revised quotes"
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "SupClaimAPMRQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting response to quotes"
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "SupClaimAPMA",
                            display: "Proposed changes awaiting $PM_ABBREVIATION Assessment"
                        }
                    ]
                },
                {
                    replacementKey: "CompensationClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "60.1(1) An instruction to change the Scope"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "60.1(2) Client does not take an action within the time required by the contract"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "60.1(3) An instruction to stop or not start any work"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "60.1(4) Client changes a decision"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "60.1(5) A breach of contract by the Client"
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "faultpscp",
                            display: "Arises from a fault of the Consultant"
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "notexpectnothappen",
                            display: "Has not happened and is not expected to happen"
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "noteffectcostcompletion",
                            display: "Has no effect on either the Consultant’s costs, Completion or meeting a Key Date"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "statedcontract",
                            display: "Is not one of the compensation events stated in this contract"
                        }
                    ]
                },
                {
                    replacementKey: "ClaimClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "60.1(1) An instruction to change the Scope"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "60.1(2) Client does not take an action within the time required by the contract"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "60.1(3) An instruction to stop or not start any work"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "60.1(4) Client changes a decision"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "60.1(5) A breach of contract by the Client"
                        }
                    ]
                },
                {
                    replacementKey: "CompensationStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "APMA",
                            display: "Quote Not Accepted - $PM_ABBREVIATION to make own Assessment"
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "ARQ",
                            display: "Quote Not Accepted - $SUPPLIER_NAME to resubmit"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "AM",
                            display: "$PM_ABBREVIATION Assessment Made"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "QA",
                            display: "Quote Accepted"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "APMRQ",
                            display: "Awaiting $PM_ABBREVIATION Response to quote"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "APMR",
                            display: "Awaiting $PM_ABBREVIATION Response to CEN"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "AQ",
                            display: "Awaiting Quote"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NC",
                            display: "Not Communicated"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "R",
                            display: "Not a Compensation Event"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "DNG",
                            display: "Not a Compensation Event (or Proposed Instruction will not be given)"
                        }
                    ]
                },
                {
                    replacementKey: "QuoteStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "0",
                            display: "Not Accepted - $SUPPLIER_NAME to resubmit"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Accepted"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Awaiting Response"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "3",
                            display: "Not Accepted - $PM_ABBREVIATION to make assessment"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "4",
                            display: "Not Accepted - Other quote approved"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "5",
                            display: "Not Accepted - Not a Compensation Event"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "none",
                            display: "Not Communicated"
                        }
                    ]
                },
                {
                    replacementKey: "Fields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Assumption",
                            display: "The quotation is to be based on the following assumptions"
                        }
                    ]
                },
                {
                    replacementKey: "InstructionReplyProceedEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "InstructionReplyProceedEnabled",
                            display: "Proposed instructions Quote Reply field proceed options Enabled/Disabled"
                        }
                    ]
                },
                {
                    replacementKey: "RelatedCompensationText",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "RelatedCompensationText",
                            display: "Regarding the aforementioned instruction, you are hereby notified of a compensation event under clause 61.1"
                        }
                    ]
                }
            ]
        },
        {
            id: 104,
            title: "Programme",
            workflows: [
                {
                    id: 14,
                    title: "$SUPPLIER_NAME Programme",
                    notificationName: "Programme",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Programme-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "ReasonLabel",
                            display: "Reason for any change to Planned Completion"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ChangeLabel",
                            display: "Change in any other Key Date, Completion Date for a section or other important project milestone in period and reasons why"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "MainProgressLabel",
                            display: "Main progress achieved in period"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "KeyActivitiesLabel",
                            display: "Key activities forecast in next four weeks"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SummaryLabel",
                            display: "Summary of critical path"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ChangesLabel",
                            display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc."
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "CompensationLabel",
                            display: "Compensation events added to programme this period"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NotificationClause",
                            display: "Please find attached programme for acceptance"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ReplyRejectReasonLabel",
                            display: "The $SUPPLIER_NAME’s programme is not accepted for the following reasons"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "ReplyNotificationClause",
                            display: "In accordance with NEC4 PSC clause 31.3 you are notified that the $SUPPLIER_NAME’s Programme"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ReplyNotificationClause",
                            display: "You are notified that the $SUPPLIER_NAME’s Programme"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Programme Submission awaiting Acceptance"
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "plansnotpractical",
                            display: "The $SUPPLIER_NAME’s plans which it shows are not practicable"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "missingcontractrequires",
                            display: "It does not show the information which this contract requires"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "notrepresentplan",
                            display: "It does not represent the $SUPPLIER_NAME’s plans realistically"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "notcomply",
                            display: "It does not comply with the Scope"
                        }
                    ]
                },
                {
                    replacementKey: "DashboardDisplayPlannedCompletionOnLatest",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "DashboardDisplayPlannedCompletionOnLatest",
                            display: "Active-Inactive state indicates weather (Planned Completion shown on the latest Accepted Programme) is displayed on dasboard"
                        }
                    ]
                },
                {
                    replacementKey: "ReplyOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Accepted"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Not Accepted",
                            display: "Not Accepted"
                        }
                    ]
                },
                {
                    replacementKey: "Fields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PreviousPlanned",
                            display: "Previous Planned Completion"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlannedCompletion",
                            display: "Date of Planned Completion this period"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Reason",
                            display: "Reason for any change to Planned Completion"
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "Change",
                            display: "Change in any other Key Date, Sectional Completion or other important project milestone in period and reasons why"
                        },
                        {
                            id: 5,
                            isActive: false,
                            field: "MainProgress",
                            display: "Main progress achieved in period"
                        },
                        {
                            id: 6,
                            isActive: false,
                            field: "KeyActivities",
                            display: "Key activities forecast in next four weeks"
                        },
                        {
                            id: 7,
                            isActive: false,
                            field: "Summary",
                            display: "Summary of critical path"
                        },
                        {
                            id: 8,
                            isActive: false,
                            field: "Changes",
                            display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc."
                        },
                        {
                            id: 9,
                            isActive: false,
                            field: "Compensation",
                            display: "Compensation events added to programme this period"
                        }
                    ]
                }
            ]
        },
        {
            id: 105,
            title: "Notifications",
            workflows: [
                {
                    id: 15,
                    title: "$PM_NAME Notifications",
                    notificationName: "Notification",
                    abbreviation: "$PM_ABBREVIATION-Notification-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 16,
                    title: "$SUPPLIER_NAME Notifications",
                    notificationName: "Notification",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Notification-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "PmClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "13.1 change of address"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "13.2 people who are to act on the Client’s behalf"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "30.2 of certification of Completion"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "42.2 of the cost of correcting a Defect"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "71.1 of permission to publicise the services"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "81.2 of copies of insurance certificates"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "90.1 to terminate the Consultant’s obligation to Provide the Service"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "90.2 of default"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "90.2 of termination"
                        }
                    ]
                },
                {
                    replacementKey: "SupplierClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "13.1 change of address"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "13.2 people who are to act on the Consultant’s behalf"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "41.2 Defects which have not been corrected at Completion"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "90.1 to terminate the Consultant’s obligation to Provide the Service"
                        }
                    ]
                },
                {
                    replacementKey: "SupervisorClauses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "",
                            display: "13.2 of a change in address as follows:"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "15.1 we have become aware of the following ambiguity/inconsistency:"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "17.1 we have become aware of the following matter that would either be illegal or impossible:"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "21.3 we provide the following forecasts of expenses"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "21.4 we provide the following forecasts of expenses and the total Time Charge"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "23.2 the following meeting(s) with Others are being held that you may wish to attend"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "61.4: the Client has not responded to a compensation event notification within the timescales outlined in the contract as detailed below:"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "62.6 the Client has not responded to a compensation event quotation within the timescales outlined in the contract as detailed below:"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "64.4 the Client has not made their own assessment of a compensation event quotation within the timescales outlined in the contract as detailed below:"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "81.1/2 the following insurances/insurance certificates have been provided by the Consultant:"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "90.1: we wish to terminate the contract for the reasons/detail listed below"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "X1.1: the following price adjustment factor"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "X4.1: the following guarantee from the parent company"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "X20.2: the performance against Key Performance Indicators as detailed below"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "other general notification"
                        }
                    ]
                }
            ]
        },
        {
            id: 106,
            title: "Submissions",
            workflows: [
                {
                    id: 18,
                    title: "$SUPPLIER_NAME Submissions",
                    notificationName: "Submission",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Submission-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Support\"]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "IssueClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "22.2 a replacement key person",
                            applyReplyPeriod: false,
                            noReply: false
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "50.4 of further information to justify an invoice",
                            applyReplyPeriod: false,
                            noReply: false
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "81.2 of copies of insurance certificates",
                            applyReplyPeriod: false,
                            noReply: false
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Submission awaiting Acceptance"
                        }
                    ]
                }
            ]
        },
        {
            id: 108,
            title: "Payments",
            workflows: [
                {
                    id: 21,
                    title: "$SUPPLIER_NAME Invoice",
                    notificationName: "Invoice",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Invoice-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 20,
                    title: "$PM_NAME Assessment",
                    notificationName: "Assessment",
                    abbreviation: "$PM_NAME-Assessment-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CumulativePriceLabel",
                            display: "Price for each lump sum or quantity of work done plus expenses"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlusAmountsSupplierLabel",
                            display: "Plus other amounts to be paid to the $SUPPLIER_NAME"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "LessAmountsSupplierLabel",
                            display: "Less amounts to be paid by or retained from the $SUPPLIER_NAME"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TaxSupplierLabel",
                            display: "Tax which the law requires the Employer to pay to the $SUPPLIER_NAME"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "WarningBanner",
                            display: "The Consultant assesses the amount due by each assessment day. There is an assessment day in each month until the month after the defects date."
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "LessAmountsDueLabel",
                            display: "Less amount due in the last Employer assessment"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "PaymentAppliedPeriodLabel",
                            display: "Amount invoiced this period"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "RegisterAppliedByCaption",
                            display: "Submitted by"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "RegisterApplicationDateCaption",
                            display: "Submission Date"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "PaymentCertifiedPeriodLabel",
                            display: "Amount assessed this period"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "RegisterCertifiedDateCaption",
                            display: "Notified Date"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "RegisterCertifiedByCaption",
                            display: "Notified By"
                        }
                    ]
                },
                {
                    replacementKey: "TaxEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "TaxEnabled",
                            display: "Active-Inactive state indicates tax field will be included in notification forms"
                        }
                    ]
                }
            ]
        },
        {
            id: 109,
            title: "Quality",
            workflows: [
                {
                    id: 23,
                    title: "$PM_NAME Defect Notifications",
                    notificationName: "Defect Notification",
                    abbreviation: "$PM_NAME-Defect-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    actioningParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 24,
                    title: "$SUPPLIER_NAME Defect Notifications",
                    notificationName: "Defect Notification",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Defect-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    actioningParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 26,
                    title: "Master Defect Register",
                    notificationName: "Defect Notification",
                    abbreviation: "Defect",
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "InstructionClauseLabel",
                            display: "You are instructed to search for a Defect as follows"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SupervisorClauseLabel",
                            display: "You are notified of the following Defect"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierClauseLabel",
                            display: "You are notified of the following Defect"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TestsClauseLabel",
                            display: "You are notified of the following test or inspection"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Instruction",
                            display: "$PM_NAME Instruction to Search awaiting sign off"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SuperDefect",
                            display: "$PM_NAME Notification of Defect awaiting closure"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Inspection",
                            display: "$SUPPLIER_NAME Test/Inspection Notification awaiting sign off"
                        }
                    ]
                }
            ]
        },
        {
            id: 111,
            title: "Records",
            workflows: [
                {
                    id: 30,
                    title: "$PM_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$PM_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 31,
                    title: "$SUPPLIER_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 32,
                    title: "$SUPPLIER_NAME Site Diary",
                    notificationName: "Site Diary",
                    abbreviation: "Diary-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 33,
                    title: "Weather",
                    notificationName: "Weather",
                    abbreviation: "Weather-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: []
        },
        {
            id: 112,
            title: "RFI / TQ",
            workflows: [
                {
                    id: 34,
                    title: "$SUPPLIER_NAME RFI / TQ",
                    notificationName: "RFI / TQ",
                    abbreviation: "RFI/TQ-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME RFI/TQ awaiting Reply"
                        }
                    ]
                }
            ]
        }
    ]
};
export default template;