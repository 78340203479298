import React from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { SelectBox, TextBox } from 'devextreme-react';
// import MenuConfig from "../../../_metronic/layout/MenuConfig";
import * as contractDuck from "../../store/ducks/contract.duck";
import Box, { Item } from 'devextreme-react/box';
import { Validator, RequiredRule } from 'devextreme-react/validator';
import Spinner from './Spinner';

class BiDynamicAssetFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            asset: {},
            waitForAssets: !!props.assetid //Need to wait for Asset options to be loaded since its preselected
        }
    }
    componentDidMount() {
        if (this.state.waitForAssets) {
            this.loadAssets();
        }
    }
    loadAssets = async () => {
        const assets = await contractDuck.getAssets();
        const selectedItem = assets.find(A => A.AssetID === this.props.assetid);

        this.setState({ asset: selectedItem, waitForAssets: false });
    }
    onSelectionChange = (e) => {
        var item = e.component.option('selectedItem');
        this.setState({ asset: item });
        this.props.onValueChanged(e, "assetid", e.value, { ...item })
    }
    render() {
        if (this.state.waitForAssets) {
            return (<Spinner />)
        }
        return (
            <div key="AssetsFieldGroup" style={{ marginTop: 20 }}>
                <div className="dx-fieldset-header" style={{ borderBottom: '1px solid #ddd' }}>
                    <span className="dx-widget dx-form-group-caption" style={{ marginBottom: 5 }}>
                        <FormattedMessage id="GENERAL.FORMS.SECTION.ASSETS" />
                    </span>
                </div>
                <div className="dx-widget dx-form-group-content dx-form-group-with-caption dx-form-group-content">
                    <div className="dx-field" key="AssetName" >
                        <Box direction="row" width="100%">
                            <Item ratio={0} baseSize={200}>
                                <div className="dx-field-label" style={{ width: '100%', whiteSpace: 'normal' }}>
                                    <FormattedMessage id="GENERAL.FORMS.FIELD.ASSET_NAME" />
                                </div>
                            </Item>
                            <Item ratio={1} baseSize={0}>
                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                                    <div className="dx-field-value" style={{ width: '100%', display: 'flex', alignItems: 'flex-start' }}>
                                        <SelectBox
                                            dataSource={{
                                                load: contractDuck.getAssets,
                                                loadMode: 'raw',
                                                cacheRawData: true,
                                                paginate: true,
                                                // pageSize: 10
                                            }}
                                            valueExpr="AssetID"
                                            displayExpr="AssetName"
                                            searchEnabled={true}
                                            searchMode="contains"
                                            searchExpr={['AssetName', 'AssetCode']}
                                            searchTimeout={200}
                                            minSearchLength={3}
                                            showDataBeforeSearch={true}
                                            onValueChanged={this.onSelectionChange}
                                            placeholder={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.PLACEHOLDER.SELECT_ASSET' })}
                                            value={(this.state.asset || {}).AssetID}
                                            acceptCustomValue={false}
                                            width="100%"
                                        >
                                            <Validator key="AssetNameValidator">
                                                <RequiredRule key="AssetNameRequired" message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING.ASSET_REQUIRED' })} />
                                            </Validator>

                                        </SelectBox>
                                    </div>
                                </div>
                            </Item>
                        </Box>
                    </div>
                    <div className="dx-field" key="AssetCode" >
                        <Box direction="row" width="100%">
                            <Item ratio={0} baseSize={200}>
                                <div className="dx-field-label" style={{ width: '100%', whiteSpace: 'normal' }}>
                                    <FormattedMessage id="GENERAL.FORMS.FIELD.ASSET_CODE" />
                                </div>
                            </Item>
                            <Item ratio={1} baseSize={0}>
                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                                    <div className="dx-field-value" style={{ width: '100%', display: 'flex', alignItems: 'flex-start' }}>
                                        <TextBox
                                            readOnly={true}
                                            placeholder={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.PLACEHOLDER.SELECT_ASSET' })}
                                            value={(this.state.asset || {}).AssetCode}
                                            width="100%"
                                        />
                                    </div>
                                </div>
                            </Item>
                        </Box>
                    </div>
                    <div className="dx-field" key="AssetType" >
                        <Box direction="row" width="100%">
                            <Item ratio={0} baseSize={200}>
                                <div className="dx-field-label" style={{ width: '100%', whiteSpace: 'normal' }}>
                                    <FormattedMessage id="GENERAL.FORMS.FIELD.ASSET_TYPE" />
                                </div>
                            </Item>
                            <Item ratio={1} baseSize={0}>
                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                                    <div className="dx-field-value" style={{ width: '100%', display: 'flex', alignItems: 'flex-start' }}>
                                        <TextBox
                                            readOnly={true}
                                            placeholder={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.PLACEHOLDER.SELECT_ASSET' })}
                                            value={(this.state.asset || {}).AssetType}
                                            width="100%"
                                        />
                                    </div>
                                </div>
                            </Item>
                        </Box>
                    </div>
                    <div className="dx-field" key="AssetLink" >
                        <Box direction="row" width="100%">
                            <Item ratio={0} baseSize={200}>
                                <div className="dx-field-label" style={{ width: '100%', whiteSpace: 'normal' }}>
                                    <FormattedMessage id="GENERAL.FORMS.FIELD.ASSET_LINK" />
                                </div>
                            </Item>
                            <Item ratio={1} baseSize={0}>
                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                                    <div className="dx-field-value" style={{ width: '100%', display: 'flex', alignItems: 'flex-start' }}>
                                        {/* <TextBox
                                            readOnly={true}
                                            placeholder={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.PLACEHOLDER.SELECT_ASSET' })}
                                            value={(this.state.asset || {}).AssetLink}
                                            width="100%"
                                        /> */}
                                        <a style={{ width: "100%" }} href={(this.state.asset || {}).AssetLink} target="_blank" rel="noopener noreferrer">
                                            <span style={{ paddingLeft: 5 }}>{(this.state.asset || {}).AssetLink}</span>
                                        </a>
                                    </div>
                                </div>
                            </Item>
                        </Box>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = store => ({
    contractUser: store.contract.user,
    contract: store.contract.contract,
});

export default injectIntl(connect(mapStateToProps)(BiDynamicAssetFields));
