/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import { Alert } from "react-bootstrap";
import { injectIntl } from "react-intl";
import * as taskOrderCrud from '../../../../crud/taskOrder.crud';
import * as projectOrderCrud from '../../../../crud/projectOrder.crud';
import Spinner from '../../../../partials/layout/Spinner';
import DataSource from 'devextreme/data/data_source';

class AssessmentCustomForm extends React.Component {
    constructor(props) {
        super(props);
        this.createDefaultSubject = (((props.location || {}).state || {}).itemCustomProps || {}).subject;
        this.createDefaultAsset = (((props.location || {}).state || {}).itemCustomProps || {}).assetid;

            
        this.validatorRef = props.validatorRef ? props.validatorRef : React.createRef();

        this.state = {
            isDxFormValid: true,
            taskOrderOptions: null,
            projectOrderOptions: null,
            dateSectionFilters: []
        };
    }

    componentDidMount() {
        if (this.props.taskOrderOptionEnabled) {
            this.fetchTaskOrders();
        }
        if (this.props.projectOrderOptionEnabled) {
            this.fetchProjectOrders();
        }
    }

    fetchTaskOrders = async () => {
        try {
            const { contractid } = this.props.contract;
            const { data: responseObj } = await taskOrderCrud.getTaskOrdersToSelectList(contractid);
            //COVERTED TO DATASOURCE WITH FD-11007 DUE TO PERFORMENCE ISSUES AFTER 3K ITEMS
            const taskOrderOptions = new DataSource({
                store: {
                    type: 'array',
                    data: responseObj,
                    key: 'value'
                }
            });
            this.setState({ taskOrderOptions });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    fetchProjectOrders = async () => {
        try {
            const { contractid } = this.props.contract;
            const { data: responseObj } = await projectOrderCrud.getProjectOrdersToSelectList(contractid);
            const projectOrderOptions = responseObj.map(TO => ({
                value: TO.projectorderid,
                reference: TO.referenceid,
                display: `${TO.referenceid} - ${TO.subject}`
            }));
            this.setState({ projectOrderOptions: projectOrderOptions });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        this.state.isDxFormValid = isDxFormValid;

        if (field) {
            if (field === 'taskorderid') {
                if (value) {
                    const newItem = { sourcedmodel: "TaskOrder", sourcedrecordid: value };

                    this.setState(prevState => ({
                        dateSectionFilters: [...prevState.dateSectionFilters, newItem]
                    }));
                } else {
                    this.setState(prevState => ({
                        dateSectionFilters: prevState.dateSectionFilters.filter(
                            item => (item.sourcedmodel !== 'TaskOrder')
                        )
                    }));
                }
                
                if (this.props.valueChangeHandler) {
                    this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);
    
                    if (field === 'taskorderid') {
                        if (value) {
                            this.props.valueChangeHandler('taskorderid', value, isDxFormValid, additionalData);
                            if (additionalData) {
                                this.props.valueChangeHandler('taskorderreference', additionalData.referenceid, isDxFormValid, additionalData);
                            }
                        }
                        else {
                            this.props.valueChangeHandler('taskorderid', value, isDxFormValid, additionalData);
                            this.props.valueChangeHandler('taskorderreference', "", isDxFormValid, additionalData);
                        }
                    }
                }
            } else if (field === 'projectorderid') {
                if (value) {
                    const newItem = { sourcedmodel: "ProjectOrder", sourcedrecordid: value };

                    this.setState(prevState => ({
                        dateSectionFilters: [...prevState.dateSectionFilters, newItem]
                    }));
                } else {
                    this.setState(prevState => ({
                        dateSectionFilters: prevState.dateSectionFilters.filter(
                            item => (item.sourcedmodel !== 'ProjectOrder')
                        )
                    }));
                }

                if (this.props.valueChangeHandler) {
                    this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);
    
                    if (field === 'projectorderid') {
                        if (value) {
                            this.props.valueChangeHandler('projectorderid', value, isDxFormValid, additionalData);
                            if (additionalData) {
                                this.props.valueChangeHandler('projectorderreference', additionalData.referenceid, isDxFormValid, additionalData);
                            }
                        }
                        else {
                            this.props.valueChangeHandler('projectorderid', value, isDxFormValid, additionalData);
                            this.props.valueChangeHandler('projectorderreference', "", isDxFormValid, additionalData);
                        }
                    }
                }
            } else {
                if (this.props.valueChangeHandler) {
                    this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);
                }
            }
        }
    }

    render() {
        if ((this.props.taskOrderOptionEnabled && !this.state.taskOrderOptions) ||
        (this.props.projectOrderOptionEnabled && !this.state.projectOrderOptions)) {
            return (<Spinner />);
        }

        const createFormConfig = {
            currencyCode: this.props.contract.currency,
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'compensationnumber', editorOptions: { defaultValue: this.createDefaultSubject } },
                { label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.ITEM_TYPE' }, { ITEM_NAME: this.props.title }), dataField: 'compensationtype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.clauses, valueExpr: 'display', displayExpr: 'display' } }
            ]
        };

        if (this.props.taskOrderOptionEnabled) {
            //AUTO-SELECT TASK ORDER DROPDOWN (IF REDIRECTED FROM CREATE-RELATED BUTTON)
            let defaultTaskOrderSelection = null;
            if (this.createDefaultFields?.itemBaseProps?.reftype === 'TaskOrder') {
                defaultTaskOrderSelection = Number(this.createDefaultFields.itemBaseProps.refid);
            }

            createFormConfig.fields.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.TASK_ORDER' }, { TASK_ORDER: this.props.taskOrderNotificationName }), dataField: 'taskorderid', editorType: 'dxSelectBox', required: false, editorOptions: { dataSource: this.state.taskOrderOptions, valueExpr: 'taskorderid', displayExpr: 'display', defaultValue: defaultTaskOrderSelection } });
        }

        if (this.props.projectOrderOptionEnabled) {        
            //AUTO-SELECT PROJECT ORDER DROPDOWN (IF REDIRECTED FROM CREATE-RELATED BUTTON)
            let defaultProjectOrderSelection = null;
            if (this.createDefaultFields?.itemBaseProps?.reftype === 'ProjectOrder') {
                defaultProjectOrderSelection = Number(this.createDefaultFields.itemBaseProps.refid);
            }

            createFormConfig.fields.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PROJECT_ORDER' }, { PROJECT_ORDER: this.props.projectOrderNotificationName }), dataField: 'projectorderid', editorType: 'dxSelectBox', required: false, editorOptions: { dataSource: this.state.projectOrderOptions, valueExpr: 'value', displayExpr: 'display', defaultValue: defaultProjectOrderSelection } });
        }

        createFormConfig.fields.push({ label: "Change in the Prices", dataField: 'assessmentamount', editorType: 'dxNumberBox' });
        createFormConfig.fields.push({ label: "Delay to planned Completion", dataField: 'alternativequotation', editorType: 'dxNumberBox', editorOptions: { format: '#,##0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' }), placeholder: '0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' }) } });
        createFormConfig.fields.push({ resource: "GENERAL.FORMS.FIELD.DETAILS", dataField: 'description', editorType: 'dxHtmlEditor' });

        return (
            <>
                {this.props.resources.SupplierClaimsWarningBanner && (
                    <Alert variant="warning" >
                        <p>
                            {this.props.resources.SupplierClaimsWarningBanner}
                        </p>
                    </Alert>
                )}

                <BiDynamicForm
                    validatorRef={this.validatorRef}
                    item={this.props.item}
                    config={createFormConfig}
                    onSubmitHandler={this.props.onSubmitHandler}
                    valueChangeHandler={this.fieldChanged}
                    customFields={this.props.customFields}
                    assetFieldsEnabled={this.props.assetFieldsEnabled}
                    assetid={this.createDefaultAsset}
                    secondaryPriceSectionEnabled={true}
                    secondaryDateSectionEnabled={true}
                    priceDateSectionFilters={this.state.dateSectionFilters?.length > 0 ? this.state.dateSectionFilters : [
                        { sourcedmodel: "TaskOrder", sourcedrecordid: this.props.item?.taskorderid },
                        { sourcedmodel: "ProjectOrder", sourcedrecordid: this.props.item?.projectorderid }
                    ]}
                />
            </>
        );
    }
}

const mapStateToProps = store => ({
    // contract: store.contract.contract,
    // contractTemplate: store.contract.template,
    // globalResources: store.contract.template.resources,
});



export default injectIntl(withSnackbar(connect(mapStateToProps)(AssessmentCustomForm)));
