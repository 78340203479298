import { userFilterCategories } from "../../../shared/lookup";
const workflows = [
    {
        id: 27,
        title: '$PM_NAME Delegated Actions',
        notificationName: 'Delegated Action',
        abbreviation: '$PM_ABBREVIATION-Action-',
        notifyingParty: 'PM',
        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Delegated Actions',
            currentModel: 'InspectionDefect',
            currentModelType: '14.2PM',
            referenceType: 'DelegatedAction',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 28,
        title: '$SUPPLIER_NAME Delegated Actions',
        notificationName: 'Delegated Action',
        abbreviation: '$SUPPLIER_ABBREVIATION-Action-',
        notifyingParty: 'SUPPLIER',
        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Delegated Actions',
            currentModel: 'InspectionDefect',
            currentModelType: 'CONDEL',
            referenceType: 'DelegatedAction',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supplier',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 29,
        title: '$SUPERVISOR_NAME Delegated Actions',
        notificationName: 'Delegated Action',
        abbreviation: '$SUPERVISOR_NAME-Action-',
        notifyingParty: 'SUPERVISOR',
        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supervisor Delegated Actions',
            currentModel: 'InspectionDefect',
            currentModelType: '14.2SUP',
            referenceType: 'DelegatedAction',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supervisor',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },

]

export default workflows;