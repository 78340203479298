import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import ReactToPrint from "react-to-print";
import { Navigate } from "react-router-dom";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as inspectionCrud from '../../../../crud/inspectionDefect.crud';
import * as fileCrud from '../../../../crud/file.crud';
import axios from 'axios';
import ActionModal from '../../_CustomComponents/ActionModal';
import IssueDocument from './Document';
import ReplyDocument from './DocumentReply';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { Alert } from "react-bootstrap";
import { addDays } from 'date-fns';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import moment from 'moment';
import Relations from '../../_CustomComponents/Relation/Relations';
import { FormattedMessage } from "react-intl";
import PageToolbar from '../../_CustomComponents/PageToolbar';
import Common from '../../../../shared/common';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';
const initialState = {
    notification: null,
    files: null,
    showModal: false,
    saving: false,
    // isDxFormValid: true,
    isChanged: false,
    //NEW REPLY STATES
    reply: null,
    replyFiles: null,
    isAwaitingReply: false,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    relationCount: 0,
    postActionUrl: null,
    relations: null
};

class IssueDetail extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.printIssueDivRef = React.createRef();
        this.printAcceptanceDivRef = React.createRef();
        //NEW REPLY
        this.validatorRef = React.createRef();
        this.validatorName = 'IssueValidator';
        this.state = { ...initialState };
        this.replyOptions = props.placeholders.find(ph => ph.replacementKey === 'NCProposalReplyOptions')
            .values.filter(item => item.isActive === true);
    }
    componentDidMount() {
        this.fetchInspectionDefect();
        this.fetchFiles();
        this.fetchReplyFiles();
    }


    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.fetchInspectionDefect();
                this.fetchFiles();
                this.fetchReplyFiles();
            });
        }
    }

    getMailDescription = () => {
        return this.state.notification.replyactiondetail;
    }
    fetchInspectionDefect = () => {
        inspectionCrud.getInspectionDefect(this.props.params.notificationid)
            .then(response => {
                const defect = response.data;
                if (defect.contractid !== this.props.contract.contractid ||
                    defect.type !== this.props.notificationType) {
                    throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
                }
                if (defect.createdate) {
                    defect.createdate = new Date(defect.createdate);
                }
                if (defect.inspectiondate) {
                    defect.inspectiondate = new Date(defect.inspectiondate);
                }
                if (defect.notificationdate) {
                    defect.notificationdate = new Date(defect.notificationdate);
                }
                if (defect.draftreplydate) {
                    defect.draftreplydate = new Date(defect.draftreplydate);
                }
                const awaitingReply = (defect.showtasknotification === 1 && defect.replystatus === 'Awaiting Acceptance' && defect.isreplyrequired);
                const replyItem = {
                    replystatus: defect.draftinspectionstatus,
                    replycomment: defect.draftreplycomment,
                    customfieldvalues: defect.customfieldvalues
                };

                this.setState({
                    notification: defect,
                    reply: replyItem,
                    isAwaitingReply: awaitingReply,
                    saving: false,
                    isChanged: false,
                    relations: defect.relations,
                    relationCount: Common.GetRelationCount(defect, this.props.accessibleWfIds)
                }, () => { this.props.setReference(this.state.notification.referenceid); });
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
    }

    fetchFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ files: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    fetchReplyFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType + 'Reply')
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ replyFiles: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    printHandler = () => {
        this.printIssueRef.handlePrint();
    }

    printAcceptanceHandler = () => {
        this.printAcceptanceRef.handlePrint();
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }

    showModal = (mode, postActionNavigateObj) => {
        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
                    modalMode: mode,
                    postActionUrl: postActionNavigateObj
                });
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
            });
        }
        else if (mode === 'REPLY_DRAFT') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_REPLY_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'REPLY_SEND') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                let modalText = "";
                if (this.state.reply.replystatus === "Accepted") {
                    modalText = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.NC_SUBMISSIN.WARNING_REPLY_ACCEPTED' });
                }
                else {
                    modalText = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_SENDING' }, { PARTY_NAME: this.props.globalResources[this.props.workflowItem.notifyingParty + "_NAME"] });
                }
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SEND_REPLY' }),
                    modalText: modalText,
                    modalMode: mode,
                });
            }
        }
    }
    onModalResponse = (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {

                    if (this.state.modalMode === 'COMMUNICATE') {
                        const notification = { ...this.state.notification };
                        // notification.createby = this.props.contractUser.name;
                        notification.createbyid = this.props.contractUser.accountid;
                        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');

                        notification.showtasknotification = 1;


                        let replyPeriod = 0;
                        notification.isreplyrequired = true;
                        notification.replystatus = 'Awaiting Acceptance';
                        replyPeriod = this.props.replyPeriod;
                        notification.replyrequireddate = moment(addDays(new Date(), replyPeriod)).toDate();

                        const docBodyConfig = this.documentBodyConfig(notification, this.props.resources);
                        const docConfig = await this.documentConfig(notification, docBodyConfig, this.props.contractUser.name, false, null, null, this.props.workflowItem.form_NotifyCompanyName);
                        notification.pdfjsoncontent = JSON.stringify(docConfig);

                        this.saveIssue(notification, this.state.modalMode);

                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {
                        const notification = { ...this.state.notification };
                        // notification.createby = this.props.contractUser.name;
                        notification.createbyid = this.props.contractUser.accountid;
                        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');
                        // notification.replyrequireddate = moment(addDays(new Date(), this.props.replyPeriod)).toDate();
                        this.saveIssue(notification, this.state.modalMode, params ? params.userid : null);

                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {
                        const inspection = { ...this.state.notification };
                        const inspectionid = inspection.inspectiondefectid
                        this.deleteInspectionDraft(inspectionid);

                    }
                    else if (this.state.modalMode === 'REPLY_DRAFT') {
                        const reply = { ...this.state.reply };
                        const notification = { ...this.state.notification };
                        notification.draftinspectionstatus = reply.replystatus;
                        notification.draftreplycomment = reply.replycomment;
                        notification.draftreplystatus = 'Drafted';
                        notification.draftreplybyid = this.props.contractUser.accountid;
                        if ((params || {}).userid) {
                            notification.draftreplytoid = params.userid;
                        }
                        notification.draftreplydatedisplay = moment().format('DD/MM/YYYY');
                        this.saveIssue(notification, this.state.modalMode, (params || {}).userid);

                    }
                    else if (this.state.modalMode === 'REPLY_SEND') {
                        const reply = { ...this.state.reply };
                        if (!this.replyValidation(reply)) {
                            //FINAL REPLY FIELDS CHECK FAILED
                            throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_FIELDS_MISSING' }))
                        }

                        const notification = { ...this.state.notification };
                        notification.replystatus = reply.replystatus;
                        notification.replycomment = reply.replycomment;
                        notification.inspectionbyid = this.props.contractUser.accountid;

                        const docReplyBodyConfig = this.documentReplyBodyConfig(notification, this.props.resources, this.replyOptions);
                        const docReplyConfig = await this.documentConfig(notification, docReplyBodyConfig, this.props.contractUser.name, true, null, null, this.props.workflowItem.form_ReplyCompanyName);
                        notification.pdfreplyjsoncontent = JSON.stringify(docReplyConfig);


                        this.saveIssue(notification, this.state.modalMode);



                    }

                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });
    }
    replyValidation = (reply) => {
        if (!reply.replystatus) { return false; }
        if (reply.replystatus !== 'Accepted' && !reply.replycomment) { return false; }
        return true;
    }

    documentBodyConfig = (notification, resources) => {
        const documentConfig = [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.replyactiondetail, },
            { note: resources.NCProposalNote.replace('$date', moment(notification.replyrequireddate).format('L')), valueType: 'NOTE' },
            { resource: 'CONTRACT.WF.DEFECTS.REFERENCE', value: notification.location },
            { resource: 'GENERAL.FORMS.FIELD.DETAILS', value: notification.inspectiondetails, valueType: 'HTML', align: 'vertical' },
            { resource: 'GENERAL.FORMS.FIELD.CONDITIONS', value: notification.assistancetestserchdefect, valueType: 'HTML', align: 'vertical' },
            { resource: 'CONTRACT.WF.NC_PROPOSAL.FIELD.PROP_CORRECTION_DATE', value: notification.inspectiondate }
        ];

        return documentConfig;
    }
    documentReplyBodyConfig = (notification, resources, replyOptions) => {
        let statusDisplay = "UNKNOWN";
        const selectedReply = replyOptions.find(opt => opt.field === notification.replystatus);
        if (selectedReply) {
            statusDisplay = selectedReply.display;
        }

        const config = [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.replyactiondetail, },
            // { resource: 'GENERAL.FORMS.FIELD.CLAUSE', value: notification.inspectiontime, },
            { resource: "GENERAL.FORMS.FIELD.REPLY", value: statusDisplay },


        ];
        if (notification.replystatus !== 'Accepted') {
            config.push({ resource: "GENERAL.FORMS.FIELD.REPLY_DETAIL", value: notification.replycomment, valueType: 'HTML', align: 'vertical' });
        }


        return config;
    }
    deleteInspectionDraft = async (inspectionid) => {

        try {
            await inspectionCrud.deleteInspectionDraft(inspectionid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }

    }
    saveIssue = (issue, action, notifyuserid) => {
        const postEvents = () => {
            this.fetchInspectionDefect();

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });
        };

        if (!issue.inspectiondatedisplay && !issue.inspectiondatedisplay && issue.inspectiondate) {
            issue.inspectiondatedisplay = moment(issue.inspectiondate).format('DD/MM/YYYY HH:mm:ss');
        }
        if (!issue.draftreplydatedisplay && issue.draftreplydate) {
            issue.draftreplydatedisplay = moment(issue.draftreplydate).format('DD/MM/YYYY');
        }
        issue.replyingparty = this.props.workflowItem.replyingParty;
        issue.actioncode = action;
        issue.notifyuserlist = this.getNotifyUserListForUpdate(action, notifyuserid);
        Common.PropertyConversionsBeforeNoticeSaveRequest(issue, this.props.customFields, this.props.contractUsers);
        inspectionCrud.updateInspectionDefect(issue)
            .then(() => {
                // this.notify(action, issue, notifyuserid);
                if (this.state.postActionUrl) {
                    this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
                }
                else {
                    postEvents();
                }
            })
            .catch(err => {
                this.setState({ saving: false });
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }
    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else {
                this.state.notification[field] = value;
                if (!this.state.isChanged) {
                    this.setState({ isChanged: true });
                }
            }
        }
    }
    replyFieldChanged = (field, value, isDxReplyFormValid, isCheckBoxValue) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            this.state.reply[field] = value;
            if (!this.state.isChanged) {
                this.setState({ isChanged: true });
            }
        }

    }

    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving || this.state.showModal);
        return (
            <PageToolbar
                documentIsFinal={documentIsFinal}
                saveDisabled={saveDisabled}
                isAwaitingReply={this.state.isAwaitingReply}
                actions={{
                    showModal: this.showModal,
                    onPrintReply: this.printAcceptanceHandler,
                    onPrint: this.printHandler
                }}
                workflowItem={this.props.workflowItem}
                match={this.props.match}
                customFieldValues={this.state.notification.customfieldvalues}
                referenceid={this.state.notification.referenceid}
                contract={this.props.contract}
                relationParams={{
                    subject: this.getMailDescription(),
                    assetid: this.state.notification.assetid
                }}
                notification={this.state.notification}
            />
        );
    }
    tabDocument = () => {
        return (
            <IssueDocument
                {...this.props}
                printDivRef={this.printIssueDivRef}
                fieldChanged={this.fieldChanged}
                notification={this.state.notification}
                files={this.state.files}

                notificationid={this.props.params.notificationid}
            />
        );
    }
    tabReply = (documentIsFinal) => {

        if (!this.props.workflowItem.userFilters.replyNotice.communicateReplyAllowed && !this.props.workflowItem.userFilters.replyNotice.saveDraftReplyAllowed && this.state.isAwaitingReply) {
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <div style={{ width: '100%', maxWidth: '900px', margin: '20px' }}>
                        <Alert variant="warning" >
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_AWAITING_REPLY" values={{ ITEM_NAME: this.props.workflowItem.pageTitle }} />
                            </p>
                        </Alert>
                    </div>
                </div>
            )
        }
        else if (this.state.notification.replystatus === "Other Accepted") {
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <div style={{ width: '100%', maxWidth: '900px', margin: '20px' }}>
                        <Alert variant="warning" >
                            <p>
                                <FormattedMessage id="CONTRACT.WF.NC_PROPOSAL.WARNING.OTHER_ACCEPTED" />
                            </p>
                        </Alert>
                    </div>
                </div>
            )
        }
        else {
            return (
                <ReplyDocument
                    {...this.props}
                    isAwaitingReply={this.state.isAwaitingReply}
                    printDivRef={this.printAcceptanceDivRef}
                    fieldChanged={this.replyFieldChanged}
                    notification={this.state.notification}
                    reply={this.state.reply}

                    files={this.state.replyFiles}
                    notificationid={this.props.params.notificationid}
                />
            )
        }
    }
    tabRelations = () => {
        return (
            <Relations
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.notification.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.replyactiondetail}
                onCountChange={(count) => this.setState({ relationCount: count })}
            />
        );
    }
    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}

            />
        );

    };
    render() {
        if (!this.state.notification || !this.state.files || !this.state.replyFiles) {
            return (<PortletSpinner />);
        }
        const documentIsFinal = this.state.notification.showtasknotification === 1;
        const showReply = documentIsFinal && this.state.notification.isreplyrequired;
        if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
            return (<Navigate replace to={this.props.workflowItem.paths.listPath} />)
        }
        return (
            <Portlet>
                <ReactToPrint
                    ref={el => (this.printIssueRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printIssueDivRef.current}
                />
                <ReactToPrint
                    ref={el => (this.printAcceptanceRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printAcceptanceDivRef.current}
                />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletHeader
                    title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                    toolbar={this.renderToolbar(documentIsFinal)}
                />
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            ref={(ref) => this.tabPanel = ref}
                            width="100%"
                            deferRendering={false}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}
                        >
                            <Item icon="file" title={this.props.title} render={this.tabDocument} />
                            <Item icon="rename" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_REPLY' })} render={showReply ? this.tabReply : null} visible={showReply} />
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>
                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                    workflowItem={this.props.workflowItem}
                />
            </Portlet>
        );
    }
}



export default withDetailPageProps(IssueDetail);
