import React from 'react';
import { connect } from "react-redux";
import { ListGroup, Badge } from "react-bootstrap";
import { addWeeks } from 'date-fns';
import DxRowItem from '../../../../partials/content/dxControls/dxRowBox';
import { withRouter } from '../../../../shared/hoc/withRouter';
// import * as proposalCrud from '../../../../crud/proposal.crud';
import moment from 'moment';
import { injectIntl } from "react-intl";


class NotifyArea extends React.Component {
    // state = {
    //     actionItems: null
    // }
    // componentDidMount() {
    //     if (this.props.expanded) {
    //         if (this.props.expanded === true) {
    //             this.loadItems();
    //         }
    //     }
    // }
    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps) {
    //         if (prevProps.expanded !== this.props.expanded) {
    //             if (this.props.expanded === true) {
    //                 this.loadItems();
    //                 return;
    //             }
    //         }
    //         if (prevProps.pendingActions !== this.props.pendingActions) {
    //             this.loadItems();
    //             return;
    //         }
    //     }
    // }
    // loadItems = () => {
    //     if (this.props.item && this.props.pendingActions) {
    //         const currentActionItems = this.props.pendingActions.filter(action => (this.props.item.actionCode === action.act && (!action.pty || action.pty === this.props.party)));
    //         this.setState({ actionItems: currentActionItems });
    //     }
    // }

    getNotificationDate = (actionItem) => {
        const actionDate = new Date(actionItem.rdt);
        if (actionDate) {
            let badgeVariant = "success";
            if (actionDate < new Date()) {
                badgeVariant = "danger";
            }
            else if (actionDate < addWeeks(new Date(), 1)) {
                badgeVariant = "warning";
            }

            return (<Badge variant={badgeVariant}>{moment(actionDate).format('L')}</Badge>)
        } else {
            return null;
        }
    }
    noItemComponent = (text) => {
        return (
            <ListGroup>
                <ListGroup.Item style={{ padding: '0.75rem 0.25rem' }} action variant="secondary">
                    <DxRowItem
                        title={text ? text : `${this.props.intl.formatMessage({ id: 'GENERAL.WORDS.NONE_PREFIX' })} ${this.props.item.title}`} />
                </ListGroup.Item>
            </ListGroup>

        );
    }


    render() {
        if (!this.props.item || (this.props.item.itemsList || []).length === 0) {
            // if (this.props.warningCount) {
            //     return (<div style={{ height: (43.44 * this.props.warningCount) }}><Spinner /></div>);
            // }
            // else {
            return this.noItemComponent();
            // }

        }
        return (
            <ListGroup>
                {
                    this.props.item.itemsList.map((actionItem, index) => (
                        <ListGroup.Item
                            key={this.props.party +this.props.item.actionCode + index}
                            style={{ padding: '0.75rem 0.25rem' }}
                            action
                            onClick={() => {
                                if (this.props.panelCloseBtnRef) {
                                    this.props.panelCloseBtnRef.current.click();
                                }
                                if(actionItem.nid){
                                    this.props.navigate(this.props.item.workflow.paths.basePath + actionItem.nid);
                                }
                                else{
                                    this.props.navigate(this.props.item.workflow.paths.createPath);
                                }
                                
                                // this.props.navigate(`/contract/${this.props.contractid}/${this.props.item.workflow.parentPath}/${this.props.item.workflow.path}/${actionItem.nid}`)
                            }}
                            variant="secondary">
                            <DxRowItem
                                strong={actionItem.ref}
                                title={actionItem.tit}
                                secondary={this.getNotificationDate(actionItem)} />
                        </ListGroup.Item>
                    ))
                }
            </ListGroup>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    pendingActions: store.contract.pendingActions,
});

export default injectIntl(withRouter(connect(mapStateToProps)(NotifyArea)));