/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Common from '../shared/common';
import { FormattedMessage, injectIntl } from "react-intl";
import * as paymentCertificateCrud from '../crud/paymentCertificate.crud';

class ContractPayments extends React.Component {
  state = {
    appliedAmount: 0,
    certifiedAmount: 0
  }
  componentDidMount() {
    this.setNewState(this.props.dashboardCurrencyCode);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dashboardCurrencyCode !== this.props.dashboardCurrencyCode) {
      this.setNewState(this.props.dashboardCurrencyCode)
    }
  }
  setNewState = async (currencyCode) => {
    let appliedAmount = 0, certifiedAmount = 0;
    const { data: resp } = await paymentCertificateCrud.getLastPaymentAmountPerCurrency(this.props.contract.contractid, ["Certificate","Application"], [currencyCode]);
    if (resp && resp.length > 0) {      
      appliedAmount = resp.find(x=>x.paymenttype === 'Application')?.amount || 0;
      certifiedAmount = resp.find(x=>x.paymenttype === 'Certificate')?.amount || 0;
    }

    this.setState({ appliedAmount, certifiedAmount })
  }
  getUserDetails = (title, price) => {

    return (
      <div className="contractPayments__item ">
        <div className="contractPayments__info ">
          <p className="contractPayments__text ">
            {title}<br />
          </p>
          <span className="contractPayments__price" >{Common.FormatCurrencyNum(price, this.props.currencyCode)}</span>
        </div>
        {/* <span className="contractPayments__desc" style={{ color: '#74788d', fontWeight: 600 }}>{user.company}</span> */}
      </div>
    );
  }
  render() {
    if (this.props.globalResources && (this.props.globalResources.WF_20_TITLE || this.props.globalResources.WF_21_TITLE)) {
      return (
        <>
          <div className="contractPayments">
            <div className="contractPayments__header">
              <h3 className="contractPayments__title">{this.props.globalResources.WFG_108_TITLE}</h3>
            </div>
            {
              this.props.globalResources.WF_21_TITLE && (
                <div className="contractPayments__item ">
                  <div className="contractPayments__info ">
                    <p className="contractPayments__text ">
                      {this.props.globalResources.WF_21_TITLE}<br />
                    </p>
                    <span className="contractPayments__price" >{Common.FormatCurrencyNum(this.state.appliedAmount, this.props.dashboardCurrencyCode)}</span>
                  </div>
                  {/* <span className="contractPayments__desc" style={{ color: '#74788d', fontWeight: 600 }}>{user.company}</span> */}
                </div>

              )
            }
            {this.props.globalResources.WF_20_TITLE && (
              <div className="contractPayments__item ">
                <div className="contractPayments__info ">
                  <p className="contractPayments__text ">
                    {this.props.globalResources.WF_20_TITLE}<br />
                  </p>
                  <span className="contractPayments__price" >{Common.FormatCurrencyNum(this.state.certifiedAmount, this.props.dashboardCurrencyCode)}</span>
                </div>
                {/* <span className="contractPayments__desc" style={{ color: '#74788d', fontWeight: 600 }}>{user.company}</span> */}
              </div>
            )
            }
          </div>
        </>
      );
    }
    else {

      return (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.1rem', color: '#74788d', textAlign: 'center' }}><FormattedMessage id="CONTRACT.DASHBOARD.PAYMENTS_NOT AVAILABLE" /></span>
        </div>
      )
    }

  }
}


export default injectIntl(ContractPayments);
