export const notificationUniqueTagsFilter = (list) => {
    let tagFilters = {
        PortfolioFilter: null,
        ProgrammeFilter: null,
        ProjectFilter: null,
        OtherFilter: null,
    }

    if (list && list.categories) {
        const portfoliotags = list.categories.find(ctg => ctg.name === 'Portfolio');
        if (portfoliotags && portfoliotags.tags) {
            tagFilters.PortfolioFilter = portfoliotags.tags.map(tag => ({ text: tag, value: ['tags_portfolio', 'contains', tag] }));
            tagFilters.PortfolioFilter.push({
                text: 'Blank',
                value: ['tags_portfolio', '=', null]
            });
        }
        const programmetags = list.categories.find(ctg => ctg.name === 'Programme');
        if (programmetags && programmetags.tags) {
            tagFilters.ProgrammeFilter = programmetags.tags.map(tag => ({ text: tag, value: ['tags_programme', 'contains', tag] }));
            tagFilters.ProgrammeFilter.push({
                text: 'Blank',
                value: ['tags_programme', '=', null]
            });
        }
        const projecttags = list.categories.find(ctg => ctg.name === 'Project');
        if (projecttags && projecttags.tags) {
            tagFilters.ProjectFilter = projecttags.tags.map(tag => ({ text: tag, value: ['tags_project', 'contains', tag] }));
            tagFilters.ProjectFilter.push({
                text: 'Blank',
                value: ['tags_project', '=', null]
            });
        }
        const othertags = list.categories.find(ctg => ctg.name === 'Other');
        if (othertags && othertags.tags) {
            tagFilters.OtherFilter = othertags.tags.map(tag => ({ text: tag, value: ['tags_other', 'contains', tag] }));
            tagFilters.OtherFilter.push({
                text: 'Blank',
                value: ['tags_other', '=', null]
            });
        }
    }

    return tagFilters;
}
export const notificationUniqueTagsFilterV2 = (responseObj) => {
    let tagFilters = {
        PortfolioFilter: null,
        ProgrammeFilter: null,
        ProjectFilter: null,
        OtherFilter: null,
    }

        if (responseObj.unique_tags_portfolio) {
            tagFilters.PortfolioFilter = responseObj.unique_tags_portfolio.map(tag => ({ text: tag, value: ['tags_portfolio', 'contains', tag] }));
            tagFilters.PortfolioFilter.push({
                text: 'Blank',
                value: ['tags_portfolio', '=', null]
            });
        }
        if (responseObj.unique_tags_programme) {
            tagFilters.ProgrammeFilter = responseObj.unique_tags_programme.map(tag => ({ text: tag, value: ['tags_programme', 'contains', tag] }));
            tagFilters.ProgrammeFilter.push({
                text: 'Blank',
                value: ['tags_programme', '=', null]
            });
        }
        if (responseObj.unique_tags_project) {
            tagFilters.ProjectFilter = responseObj.unique_tags_project.map(tag => ({ text: tag, value: ['tags_project', 'contains', tag] }));
            tagFilters.ProjectFilter.push({
                text: 'Blank',
                value: ['tags_project', '=', null]
            });
        }
        if (responseObj.unique_tags_other) {
            tagFilters.OtherFilter = responseObj.unique_tags_other.map(tag => ({ text: tag, value: ['tags_other', 'contains', tag] }));
            tagFilters.OtherFilter.push({
                text: 'Blank',
                value: ['tags_other', '=', null]
            });
        }

    return tagFilters;
}

export const notificationAggregateListTagsFilter = (list) => {
    list = list.map(item => {
        if (item.tags && item.tags.categories) {
            const contractPortfolios = item.tags.categories.find(ctg => ctg.name === 'Portfolio');
            if (contractPortfolios && contractPortfolios.tags) {
                item.tags_portfolio = contractPortfolios.tags.map(tag => tag.tagname);
            }
            const contractProgrammes = item.tags.categories.find(ctg => ctg.name === 'Programme');
            if (contractProgrammes && contractProgrammes.tags) {
                item.tags_programme = contractProgrammes.tags.map(tag => tag.tagname);
            }
            const contractProjects = item.tags.categories.find(ctg => ctg.name === 'Project');
            if (contractProjects && contractProjects.tags) {
                item.tags_project = contractProjects.tags.map(tag => tag.tagname);
            }
            const contractOthers = item.tags.categories.find(ctg => ctg.name === 'Other');
            if (contractOthers && contractOthers.tags) {
                item.tags_other = contractOthers.tags.map(tag => tag.tagname);
            }
        }
        if (item.customfieldvalues && item.customfieldvalues.length > 0) {
            item.customfieldvalues.forEach(cfv => {
                item["CF_" + cfv.customfieldid] = cfv.value;
            });
        }
        return item;
    });
    return list;
}