import axios from '../shared/axios';
// import qs from 'querystring';


export function getContractNotificationFiles(companyid, projectid, contractid, reference, type) {
    return axios.post(`/api/File/LoadFilesList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&reference=${reference}&type=${type}`);
}

export function uploadContractNotificationFiles(companyid, projectid, contractid, type, reference, data, config) {    
    config = {
        onUploadProgress: config.onUploadProgress,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    return axios.post(`/api/File/UploadFile?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&type=${type}&reference=${reference}`, data, config);
}

export function deleteContractFile(companyid, projectid, contractid, fileid) {
    const data = {
        companyid: companyid,
        projectid: projectid,
        contractid: contractid,
        fileid: fileid,
    }
    return axios.post(`/api/File/DeleteFile`, data);//?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&fileid=${fileid}
}

export function asiteAuthenticate(cancelTok) {
    return axios.post(`/api/Asite/Authenticate`, null, { cancelToken: cancelTok });
}

export function asiteGetWorkspaces(cancelTok) {
    return axios.get(`/api/Asite/GetWorkspaces`, { cancelToken: cancelTok });
}

export function asiteGetFolders(workspaceid, cancelTok) {
    return axios.get(`/api/Asite/GetFolders?workspaceid=${workspaceid}`, { cancelToken: cancelTok });
}

export function asiteGetDocuments(workspaceid, folderid, cancelTok) {
    return axios.get(`/api/Asite/GetDocuments?workspaceid=${workspaceid}&folderid=${folderid}`, { cancelToken: cancelTok });
}

export function asiteGetShareLink(contractid, folderid, docref, cancelTok) {
    return axios.get(`/api/Asite/GetShareLink?contractid=${contractid}&folderid=${folderid}&docref=${docref}`, { cancelToken: cancelTok });
}

export function uploadExternalFile(data) {
    return axios.post(`/api/File/UploadExternalFile`, data);
}

export function deleteExternalFile(data) {
    return axios.post(`/api/File/DeleteExternalFile`, data);
}

export function asiteSearch(formData) {
    return axios.post(`/api/Asite/Search`, formData);
}