import React, { Component } from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import SimpleBox from '../../../../partials/content/SimpleBox';
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import * as contractsCrud from "../../../../crud/contracts.crud";
import DataGrid, { Editing, Paging } from 'devextreme-react/data-grid';
import * as tagCrud from '../../../../crud/tag.crud';

class CompanyHubs extends Component {
  constructor(props){
    super(props);
    this.contractTagsEnabled = process.env.REACT_APP_CONTRACT_COMPANY_TAGS_ENABLED === 'true';
  }
  state = {
    hubUsers: null,
    hubName: null,
    contractTags: null
  }
  componentDidMount() {
    this.setTags();
  }
  setTags = async (companyid) => {
    if (!this.contractTagsEnabled) { return; }
    try {
      const { data: contractTags } = await tagCrud.GetTagsForCategory(["Hubs"],this.props.company.companyid);
      // const contractTags = Common.GroupBy(tags, 'category');
      this.setState({ contractTags });

    } catch (err) {
      this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
    }
  }
  fetchHubUsers = (hubId) => {
    contractsCrud.getContractHubUserList(this.props.company.companyid, hubId, null)
      .then(response => {
        const hubUsers = JSON.parse(response.data.aggregatelist);
        this.setState({ hubUsers });
      })
      .catch(err => {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      });
  }

  onValueChanged = (field, value) => {
    if (field === "hubid") {
      let hubName = this.state.contractTags.filter(tag => tag.tagid === value)[0].name;
      this.setState({ hubName }, () => {
        this.fetchHubUsers(value);
      });
    }
  };

  handleSubmit = (hub, setSubmitting, clearForm) => {
    contractsCrud.createContractHubUser(hub)
      .then(() => {
        this.props.enqueueSnackbar('User successfully added', { variant: 'success', });
        setSubmitting(false);
        clearForm();
        this.fetchHubUsers(hub.hubid);
      })
      .catch(err => {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        setSubmitting(false);
      });
  }

  onRowRemoved = (rowdata) => {
    const hub = { ...rowdata.data };
    hub.deleted = 1;
    contractsCrud.createContractHubUser(hub)
      .then(() => {
        this.props.enqueueSnackbar('User successfully deleted', { variant: 'success', });
        this.fetchHubUsers(hub.hubid);
      })
      .catch(err => {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      });
  }

  render() {
    if (!this.state.contractTags) {
      return (<PortletSpinner />);
    }

    var companyUsers = this.props.companyUsers ? this.props.companyUsers.filter(user => user.deleted === 0 && user.type === 'View All Hub') : null;

    if (this.state.hubUsers && this.state.hubUsers.length > 0 && companyUsers && companyUsers.length > 0) {
      companyUsers = companyUsers.filter(user => !this.state.hubUsers.some(hubUser => user.accountid === hubUser.userid));
    }

    const newHubFormConfig = {
      buttonText: 'Add User to Hub',
      fields: [
        {
          label: 'Add New User to Hub',
          fields: [
            { label: 'Select Hub', dataField: 'hubid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.contractTags, valueExpr: 'tagid', displayExpr: 'name' } },
            { label: 'Select User', dataField: 'userid', editorType: 'dxSelectBox', editorOptions: { dataSource: companyUsers, valueExpr: 'accountid', displayExpr: 'name' } },
          ]
        }
      ]
    };

    const simpleBoxTitle = this.state.hubName ? `${this.state.hubName} Users List` : 'Hub Users List';

    return (
      <Portlet>
        <PortletHeader title="Hub Users" />
        <PortletBody>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '10px' }}>
            <BiDynamicForm
              config={newHubFormConfig}
              valueChangeHandler={this.onValueChanged}
              onSubmitHandler={this.handleSubmit}
            />
          </div>
          <div style={{ height: 20 }} />
          <SimpleBox title={simpleBoxTitle}>
            <DataGrid
              version="1"
              height={470}
              hoverStateEnabled={true}
              rowAlternationEnabled={true}
              showBorders={true}
              showColumnLines={true}
              showRowLines={true}
              remoteOperations={false}
              allowColumnReordering={true}
              columnResizingMode='widget'
              name="Hub Users"
              dataSource={this.state.hubUsers}
              keyExpr="contracthubuserid"
              onRowRemoved={this.onRowRemoved}
              columns={[
                { caption: "User Name", dataField: "user" },
              ]}
            >
              <Editing
                mode="row"
                allowUpdating={false}
                allowDeleting={true}
                allowAdding={false}
                texts={{ deleteRow: "Delete" }}
              />
              <Paging pageSize={10} defaultPageSize={10} defaultPageIndex={0} />
            </DataGrid>
          </SimpleBox>
        </PortletBody>
      </Portlet>
    );
  }
}

const mapStateToProps = store => ({
  company: store.company.company,
  companyUsers: store.company.companyUsers,
  userProfile: store.auth.userProfile,
});

export default withSnackbar(connect(mapStateToProps)(CompanyHubs));