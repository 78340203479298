import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { injectIntl } from "react-intl";
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

export const withCreatePageProps = (Component) => {
  function ComponentWithCreatePageProp(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const contract = useSelector(store => store.contract.contract);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    return (
      <Component
        {...props}
        contract={contract}
        location={location}
        navigate={navigate}
        closeSnackbar={closeSnackbar}
        enqueueSnackbar={enqueueSnackbar}
      />
    );
  }

  return injectIntl(ComponentWithCreatePageProp);
}
