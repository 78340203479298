import axios from '../shared/axios';
// import qs from 'querystring';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function saveInspectionDefect(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/InspectionDefect/SaveInspectionDefect`, data)
            .then(result => {
                // if (data.showtasknotification === 1 ) {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                // }
                // const inspectionType = data.type ? data.type : data.Type;
                // store.dispatch(contractDuck.actions.fetchInspection(inspectionType,true));
                resolve(result);
            })
            .catch(error => reject(error));
    });
}
export function updateInspectionDefect(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/InspectionDefect/Update`, data)
            .then(result => {
                if (data.actioncode === 'COMMUNICATE' || data.actioncode === 'REPLY_SEND') {
                    store.dispatch({
                      type: contractDuck.actionTypes.CONTRACT_REFRESH_CONTRACT,
                    });
                  }
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}
export function updateInspectionDefectAction(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/InspectionDefect/UpdateDefectAction`, data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}
export function updateConformanceAction(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/InspectionDefect/UpdateConformanceAction`, data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}
export function closeInspectionDefect(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/InspectionDefect/CloseDefect`, data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}
export function updateInspectionDefectPlannedClosure(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/InspectionDefect/PlannedClosureUpdate`, data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}
export function getInspectionDefect(earlyinspectiondefectid) {
    return axios.get(`/api/InspectionDefect/GetInspectionDefectV2?earlyinspectiondefectid=${earlyinspectiondefectid}`);
}
// export function getContractNotifications(companyid, projectid, contractid) {
//     return axios.post(`/api/InspectionDefect/LoadInspectionDefectsList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&type=Notification`);
// }

// export function getContractRfi(companyid, projectid, contractid) {
//     return axios.post(`/api/InspectionDefect/LoadInspectionDefectsList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&type=RFI`);
// }
export function getContractInspections(companyid, projectid, contractid, type, accessibleWfIds) {
    return axios.post(`/api/InspectionDefect/LoadInspectionDefectsList`, { accessibleWfIds }, { params: { companyid, projectid, contractid, type } });
}
export function getInspectionsReduced(companyid, projectid, contractid, type, accessibleWfIds) {
    return axios.post(`/api/InspectionDefect/RegisterList`, { accessibleWfIds }, { params: { companyid, projectid, contractid, type } });
}
export function getInsDefToSelectList(contractid, type) {
    return axios.get(`/api/InspectionDefect/ToSelectList`, { params: { contractid, type} });
}

export function deleteInspectionDraft(inspectiondefectid) {
    return new Promise((resolve, reject) => {
        axios.post(`api/InspectionDefect/DeleteDraft?inspectiondefectid=${inspectiondefectid}`)
        .then(result => {
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
            resolve(result);
        })
        .catch(error => reject(error));
    })
}