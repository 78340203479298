import React from 'react';
import Box, { Item } from 'devextreme-react/box';




export default function dxGroupBox(props) {
    let boxBorderStyle = { height: '100%', width: props.width ? props.width : "100%" };
    if (props.showBorder !== false) {
        boxBorderStyle.border = '1px solid #ccc';
        boxBorderStyle.borderRadius = '10px';
    }
    if (props.maxWidth) {
        boxBorderStyle.maxWidth = props.maxWidth;
    }
    return (
        <div style={boxBorderStyle}>
            <Box
                direction="col"
                width="100%"
                height="100%"
            >
                {(props.title || props.toolbar) && (
                    <Item
                        ratio={0}
                        baseSize={30}>
                        <Box
                            direction="row"
                            width="100%"
                            height={30}>
                            {props.title && (
                                <Item ratio={3}>
                                    <div style={{
                                        marginLeft: '15px',
                                        marginTop: '10px',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        boxOrient: 'vertical',
                                        overflow: 'hidden',
                                    }}>
                                        {props.title}
                                    </div>
                                </Item>
                            )}

                            {props.toolbar && (
                                <Item ratio={2} align="right">
                                    <div style={{ float: 'right', display: 'flex', flexDirection: 'row-reverse', marginRight: '10px', marginTop: '5px' }}>
                                        {props.toolbar}
                                    </div>
                                </Item>
                            )}

                        </Box>
                    </Item>
                )}

                <Item ratio={1}
                    baseSize={0}>
                    <div style={{ paddingTop: (props.title || props.toolbar) ? 20 : 0, height: "100%" }}>
                        <div style={{ display: 'flex', flexDirection: 'column', height: "100%" }}>
                            {props.children}
                        </div>
                    </div>
                </Item>
            </Box>
        </div>

    )
}