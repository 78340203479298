/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import * as companyUserCrud from '../../../crud/companyUser.crud';
import * as contractsCrud from '../../../crud/contracts.crud';
import * as templatesCrud from '../../../crud/templates.crud';
import * as projectsCrud from '../../../crud/projects.crud';
import * as companiesCrud from '../../../crud/companies.crud';
import * as tagCrud from '../../../crud/tag.crud';
import * as asiteCrud from '../../../crud/asite.crud';
import * as customFieldCrud from '../../../crud/customField.crud';
import * as contractsDuck from '../../../store/ducks/contracts.duck';
import { contractTypes, GetCurrencyCodes, getContractPartyStructureTypes } from '../../../shared/lookup';
import DefaultResources from '../../../Templates/DefaultResources';
import { Portlet, PortletBody, PortletHeader } from "../../../partials/content/Portlet";
import moment from 'moment';
import BiDynamicForm from "../../../partials/layout/BiDynamicForm";
import { injectIntl } from "react-intl";
import { contractTagCategories } from '../../../shared/lookup';
import Common from '../../../shared/common';
import { withRouter } from '../../../shared/hoc/withRouter';
import { confirm } from 'devextreme/ui/dialog';
import NavigationBlocker from '../../../partials/Tools/NavigationBlocker';
import { Navigate } from "react-router-dom";

const isFinancialLimitEnabled = process.env.REACT_APP_FINANCIAL_LIMIT_ENABLED === "true";
const isSecondaryPricesEnabled = process.env.REACT_APP_SECONDARY_PRICES_ENABLED === "true";

class ContractNew extends React.Component {
    constructor(props) {
        super(props);
        this.currencyCodes = GetCurrencyCodes();
        this.defaultSupplierName = (DefaultResources.find(rsc => rsc.key === 'SUPPLIER_NAME')).value;
        this.defaultPmName = (DefaultResources.find(rsc => rsc.key === 'PM_NAME')).value;
        this.defaultClientName = (DefaultResources.find(rsc => rsc.key === 'CLIENT_NAME')).value;
        this.defaultSupervisorName = (DefaultResources.find(rsc => rsc.key === 'SUPERVISOR_NAME')).value;
        this.contractTagsEnabled = process.env.REACT_APP_CONTRACT_COMPANY_TAGS_ENABLED === 'true';
        this.isAsiteEnabled = process.env.REACT_APP_ATTACHMENT_MODE === 'ASITE';
        this.customFieldsEnabled = process.env.REACT_APP_CUSTOM_FIELDS_ENABLED === "true";

        this.templateModeOptions = [
            { value: 0, display: this.props.intl.formatMessage({ id: 'CONTRACT.CREATE.TEMPLATE_OPTIONS.COPY_MASTER' }) },
            { value: 1, display: this.props.intl.formatMessage({ id: 'CONTRACT.CREATE.TEMPLATE_OPTIONS.USE_MASTER' }) }
        ]

        this.contractPartyStructureTypes = getContractPartyStructureTypes(this.props.intl);

        this.state = {
            contract: {
                contractreferencenumber: null,
                name: null,
                companyid: null,
                projectid: null,
                mastertemplateid: null,
                employercompanyid: null,
                employerid: null,
                contractorcompanyid: null,
                contractmanagerid: null,
                supervisorcompanyid: null,
                supervisorid: null,
                startdatedisplay: null,
                completedatedisplay: null,
                section: false,
                otherprice: false,
                contractvalue: null,
                currency: 'GBP'
            },
            optCompanies: [],
            optCompaniesFiltered: [],
            optTemplates: [],
            optProjects: [],
            optEmployerUsers: [],
            optPMUsers: [],
            optContractorUsers: [],
            optSupervisorUsers: [],

            clientName: this.defaultClientName,
            pmName: this.defaultPmName,
            supplierName: this.defaultSupplierName,
            supervisorName: this.defaultSupervisorName,
            asiteUsers: null,
            asiteWorkspaces: null,
            customFields: [],
            contractTags: {},
            changed: false,
            isLoading: true
        };
    }

    componentDidMount() {
        this.pageStartUpActions();
    }

    pageStartUpActions = async () => {
        if (this.isAsiteEnabled) {
            let [optCompaniesFiltered, optCompanies, asiteUsers, asiteWorkspaces] = await Promise.all([this.getCompanyOptions(this.props.userProfile), this.getCompanyOptions(), this.fetchAsiteUsers(), this.fetchAsiteWorkspaces()]);
            this.setState({ isLoading: false, optCompaniesFiltered, optCompanies, asiteUsers, asiteWorkspaces })
        }
        else {
            let [optCompaniesFiltered, optCompanies] = await Promise.all([this.getCompanyOptions(this.props.userProfile), this.getCompanyOptions()]);
            this.setState({ isLoading: false, optCompaniesFiltered, optCompanies })
        }
    }

    fetchAsiteUsers = () => {
        return new Promise(async (resolve, reject) => {
            let { data: asiteUsers } = await asiteCrud.getAsiteAPIUsers();
            if (!asiteUsers || !asiteUsers.length > 0) {
                this.props.enqueueSnackbar('No asite users found', { variant: 'warning', });
                resolve([]);
            }
            resolve(asiteUsers);
        });
    }

    fetchAsiteWorkspaces = () => {
        return new Promise(async (resolve, reject) => {
            let { data: asiteWorkspaces } = await asiteCrud.getAsiteWorkspaces();
            if (!asiteWorkspaces || !asiteWorkspaces.length > 0) {
                this.props.enqueueSnackbar('No asite workspaces found', { variant: 'warning', });
                resolve([]);
            }
            resolve(asiteWorkspaces);
        });
    }


    getTemplateOptions = (companyid) => {
        const datasource = {
            loadMode: 'raw',
            cacheRawData: true,
            load: () => {
                var promise = new Promise((resolve, reject) => {
                    templatesCrud.getTemplatesToSelectList(companyid)
                        .then(response => {
                            let retVal = [];
                            if (response.data) {
                                retVal = response.data;
                            }
                            resolve(retVal);
                        })
                        .catch((err) => {
                            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                            reject(err);
                        })
                });
                return promise;
            }
        }
        return datasource;
    }
    getCompanyOptions = (userProfile) => {
        return new Promise(async (resolve, reject) => {
            let { data: companiesList } = await companiesCrud.getCutDownCompaniesList(userProfile);
            if (!companiesList || !companiesList.length > 0) {
                this.props.enqueueSnackbar('No companies found', { variant: 'warning', });
                resolve([]);
            }
            resolve(companiesList);
        });
    }
    getProjectOptions = (companyid) => {
        const datasource = {
            loadMode: 'raw',
            cacheRawData: true,
            load: () => {
                var promise = new Promise((resolve, reject) => {
                    projectsCrud.getCutDownProjectsList(companyid)
                        .then(response => {
                            let projectList = [];
                            if (response.data && response.data.length > 0) {
                                projectList = response.data.map(project => ({ projectid: project.projectid, display: (project.projectcode ? `[${project.projectcode}] - ` : "") + `${project.name}` }));
                            }
                            else {
                                this.props.enqueueSnackbar('No project found for selected the company', { variant: 'warning', });
                            }
                            resolve(projectList);
                        })
                        .catch(err => {
                            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                            reject(err);
                        });
                });
                return promise;
            }
        }
        return datasource;
    }
    //FD-8918
    //Awaitable function will check supplier company Framework Tags agains Contract Framework tags
    //If they dont match this will be validated with user
    //Will return TRUE if its okay to continue with update
    //Will return FALSE if process need to be interrupted
    validateSupplierCompanyTagsWithContract = async () => {
        const { contract, supplierName } = this.state;

        //FETCH THE SUPPLIER COMPANY DETAILS
        let { data: supplierCompany } = await companiesCrud.getCompany(contract.contractorcompanyid);

        if (!supplierCompany) {
            //should not be possible
            //something is wrong here
            throw new Error("Supplier company does not exist in companies!!");
        }

        //GET SUPPLIER COMPANY FRAMEWORK TAGS
        const supplierCompanyFrameworkTags = (supplierCompany.tags?.categories || []).find(
            (ctg) => ctg.name === 'Frameworks'
        )

        if (supplierCompanyFrameworkTags && supplierCompanyFrameworkTags.tags.length > 0) {
            //SUPPLIER COMPANY TAGS EXIST
            const contractHasAnyFrameorkTagsMatchingSupplierCompanyTags = (contract.frameworkids || []).some((contractframeworkid) =>
                supplierCompanyFrameworkTags.tags.some((tag) => tag.tagid === contractframeworkid)
            );

            if (!contractHasAnyFrameorkTagsMatchingSupplierCompanyTags) {
                //IF THEY ARE NO MATCHES, VALIDATE WITH USER
                const warningMessage = this.props.intl.formatMessage({ id: 'CONTRACT.WARNING.FIELD.COMPANY_FRAMEWORK' }, { SUPPLIER_NAME: supplierName });
                const warningMessageLabel = this.props.intl.formatMessage({ id: 'CONTRACT.WARNING.FIELD.COMPANY_FRAMEWORK_LABEL' });
                let result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageLabel}`);
                return result;
            }
        }

        return true;
    };

    submitHandler = async (contract, setSubmitting) => {
        let newContractID = null;
        try {
            const newContract = { ...this.state.contract };

            //FD-8918 Compare & Verify with Supplier Company Framework Tags
            const supplierCompanyTagsValidated = await this.validateSupplierCompanyTagsWithContract();
            if (!supplierCompanyTagsValidated) {
                //if not validated exit save
                setSubmitting(false);
                return;
            }

            newContract.isdemocontract = +newContract.isdemocontract; //CONVERT BOOLEAN TO INT
            newContract.financiallimitenabled = +newContract.financiallimitenabled; //CONVERT BOOLEAN TO INT
            newContract.section = +newContract.section; //CONVERT BOOLEAN TO INT
            newContract.otherprice = newContract.otherprice ? 1 : 0;
            newContract.startdatedisplay = moment(newContract.startdatedisplay).format('DD/MM/YYYY');
            newContract.completedatedisplay = moment(newContract.completedatedisplay).format('DD/MM/YYYY');

            const response = await contractsCrud.saveContract(newContract);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.SUCCESSFULLY.CREATED' }), { variant: 'success', });
            this.props.contractsDuckSetPending();
            newContractID = Number(response.data);

            if (this.isAsiteEnabled) {
                const formData = {
                    contractid: newContractID,
                    workspaceid: newContract.workspaceid,
                    folderid: newContract.folderid,
                    apiuser: newContract.apiuser
                };
                await asiteCrud.saveAsiteContractData(formData);
            }

        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
        finally {
            setSubmitting(false);
            if (newContractID) {
                this.setState({ changed: false, navigateto: '/contract/' + newContractID + '/settings/overview' });
            }
        }
    }
    // getProjectOptions = async (companyid) => {
    //     if (this.props.projects) {
    //         let newPrjOptions = this.props.projects.filter(prj => prj.companyid === companyid);
    //         if (newPrjOptions.length < 1) {
    //             this.props.enqueueSnackbar('No project found for selected the company', { variant: 'warning', });
    //         }
    //         newPrjOptions = newPrjOptions.map(project => ({ projectid: project.projectid, display: (project.projectcode ? `[${project.projectcode}] - ` : "") + `${project.name}` }));

    //         this.setState({ optProjects: newPrjOptions })
    //     }
    // }
    getCustomFields = async (companyid) => {
        if (!this.customFieldsEnabled) { return []; }
        try {
            const result = await customFieldCrud.getCustomFieldByCompany(companyid, "Contract");
            if (result.data && result.data.length > 0) {
                return result.data[0].fields;
            }
            else {
                return [];
            }
        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    getTags = async (companyid) => {
        if (!this.contractTagsEnabled) { return {}; }
        try {
            const { data: tags } = await tagCrud.GetTagsForCategory(contractTagCategories, companyid);
            const contractTags = Common.GroupBy(tags, 'category');
            return contractTags;

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    getCompanyUserOptions = (companyid) => {
        const datasource = {
            loadMode: 'raw',
            cacheRawData: true,
            load: () => {
                var promise = new Promise((resolve) => {
                    companyUserCrud.getCompanyUsersToSelectList(companyid)
                        .then(response => {
                            let users = response.data;
                            if (users.length < 1) {
                                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "WARNING.COMPANY_HAS_NO_USERS" }), { variant: 'warning', });
                            }
                            users = users.map(user => ({ accountid: user.accountid, display: `${user.name} (${user.email})` }));

                            resolve(users);
                        });
                });
                return promise;
            }
        }

        return datasource;

    }
    onTemplateChanged = (mastertemplateid) => {
        if (mastertemplateid && mastertemplateid > 0) {
            this.setState({ isLoading: true }, async () => {
                const { data: selectedTempResult } = await templatesCrud.getTemplate(mastertemplateid);
                if (!selectedTempResult || !selectedTempResult.templatejson) { throw new Error("Selected templated not found!") }
                const selectedTemplateObj = JSON.parse(selectedTempResult.templatejson);
                const rscSupplier = selectedTemplateObj.resources.find(rsc => rsc.key === 'SUPPLIER_NAME');
                const rscPM = selectedTemplateObj.resources.find(rsc => rsc.key === 'PM_NAME');
                const rscClient = selectedTemplateObj.resources.find(rsc => rsc.key === 'CLIENT_NAME');
                const rscSupervisor = selectedTemplateObj.resources.find(rsc => rsc.key === 'SUPERVISOR_NAME');
                const supplierName = rscSupplier ? rscSupplier.value : this.defaultSupplierName;
                const pmName = rscPM ? rscPM.value : this.defaultPmName;
                const clientName = rscClient ? rscClient.value : this.defaultClientName;
                const supervisorName = rscSupervisor ? rscSupervisor.value : this.defaultSupervisorName;
                this.setState({ isLoading: false, supplierName, pmName, clientName, supervisorName, contract: { ...this.state.contract } });
            })
            // const template = this.props.templates.find(tmp => tmp.templateid === mastertemplateid);





            // this.setState({ supplierName: supplierName, pmName: pmName, clientName: clientName, supervisorName: supervisorName, contract: { ...this.state.contract } });
        }
    }
    onValueChanged = async (field, value) => {
        if (this.state.contract[field] !== value) {
            this.state.contract[field] = value;
            if (field === 'companyid') {
                const optProjects = this.getProjectOptions(Number(value));
                const optTemplates = this.getTemplateOptions(Number(value));
                let [customFields, contractTags] = await Promise.all([this.getCustomFields(Number(value)), this.getTags(Number(value))]);
                this.setState({ optTemplates, optProjects, customFields, contractTags, changed: true });
            }
            else if (field === 'mastertemplateid') {
                this.onTemplateChanged(Number(value));
            }
            else if (field === 'useremployercompanyid') {
                const datasource = this.getCompanyUserOptions(Number(value));
                this.setState({ optEmployerUsers: datasource, changed: true });
            }
            else if (field === 'userpmcompanyid') {
                const datasource = this.getCompanyUserOptions(Number(value));
                this.setState({ optPMUsers: datasource, changed: true });
            }
            else if (field === 'usercontractcompanyid') {
                const datasource = this.getCompanyUserOptions(Number(value));
                this.setState({ optContractorUsers: datasource, changed: true });
            }
            else if (field === 'usersupervisorcompanyid') {
                const datasource = this.getCompanyUserOptions(Number(value));
                this.setState({ optSupervisorUsers: datasource, changed: true });
            }
            else if (field === 'startdatedisplay') {
                this.setState({ contract: { ...this.state.contract }, changed: true });
            }
            else {
                if (!this.state.changed) {
                    this.setState({ changed: true });
                }
            }
        }
    }
    customDisplayExpr = (data) => {
        if (data) {
            return `${data.id} - ${data.description}`;
        }
    }

    render() {
        const { allowEditDemo } = this.props.protectedRouteProps;
        const newContractFormConfig = {
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE",
            fields: [
                { resource: 'CONTRACT.FIELD.NUMBER', dataField: 'contractreferencenumber' },
                { resource: 'CONTRACT.FIELD.NAME', dataField: 'name', },
                { resource: 'CONTRACT.FIELD.COMPANY', dataField: 'companyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompaniesFiltered, valueExpr: 'companyid', displayExpr: 'name' } },
                { resource: 'CONTRACT.FIELD.PROJECT', dataField: 'projectid', dependOn: 'companyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optProjects, valueExpr: 'projectid', displayExpr: 'display' } },
                {
                    resource: 'CONTRACT.FIELD.TYPE',
                    tooltipResource: 'CONTRACT.FIELD.CONTRACT_TEMPLATE_TOOLTIP',
                    dataField: 'contracttype',
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: contractTypes }
                },
                {
                    resource: 'CONTRACT.FIELD.PARTY_STRUCTURE',
                    tooltipResource: 'CONTRACT.FIELD.PARTY_STRUCTURE_TOOLTIP',
                    dataField: 'partystructure',
                    dependOn: 'contracttype',
                    dependOnValue: (item) => {
                        if (item.partystructure) { return item.partystructure; }
                        if (["NEC4 ALC"].some(el => item.contracttype.includes(el))) { return 3; }
                        if (["NEC3 ECC", "NEC4 ECC"].some(el => item.contracttype.includes(el))) { return 4; }
                        else { return 1; }
                    },
                    editorType: 'dxSelectBox',
                    editorOptions: { dataSource: this.contractPartyStructureTypes, displayExpr: 'display', valueExpr: 'value', }
                },
                { resource: 'CONTRACT.FIELD.TEMPLATE_MODE_CREATE', dataField: 'usemastertemplate', editorType: 'dxRadioGroup', editorOptions: { items: this.templateModeOptions, valueExpr: 'value', displayExpr: 'display' } },
                { resource: 'CONTRACT.FIELD.TEMPLATE', dataField: 'mastertemplateid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optTemplates, valueExpr: 'templateid', displayExpr: 'name' } },
                { resource: 'CONTRACT.FIELD.PO_NUMBER', dataField: 'versionnumber', required: false },
                { resource: 'CONTRACT.FIELD.PO_VALUE', dataField: 'scope', editorType: 'dxNumberBox', required: false, editorOptions: { format: '#,##0.00', placeholder: '0.00' } },
                { resource: 'CONTRACT.FIELD.DEFECTS_PERIOD', dataField: 'defectinterval', required: false, editorType: 'dxNumberBox', editorOptions: { format: '#,##0 ' + this.props.intl.formatMessage({ id: "WATERMARK.WEEKS" }), placeholder: '0 ' + this.props.intl.formatMessage({ id: "WATERMARK.WEEKS" }) } }
            ]
        };
        if (this.contractTagsEnabled) {
            newContractFormConfig.fields.push(
                { resource: 'CONTRACT.FIELD.AREAS', dataField: 'areaids', required: false, editorType: 'dxTagBox', editorOptions: { dataSource: this.state.contractTags.Areas, valueExpr: 'tagid', displayExpr: 'name' } },
                { resource: 'CONTRACT.FIELD.HUBS', dataField: 'hubids', required: false, editorType: 'dxTagBox', editorOptions: { dataSource: this.state.contractTags.Hubs, valueExpr: 'tagid', displayExpr: 'name' } },
                { resource: 'CONTRACT.FIELD.LOTS', dataField: 'lotids', required: false, editorType: 'dxTagBox', editorOptions: { dataSource: this.state.contractTags.Lots, valueExpr: 'tagid', displayExpr: 'name' } },
                { resource: 'CONTRACT.FIELD.FRAMEWORKS', dataField: 'frameworkids', required: false, editorType: 'dxTagBox', editorOptions: { dataSource: this.state.contractTags.Frameworks, valueExpr: 'tagid', displayExpr: 'name' } },
                { resource: 'GENERAL.FORMS.FIELD.OTHER', dataField: 'othercontracttagids', required: false, editorType: 'dxTagBox', editorOptions: { dataSource: this.state.contractTags.ContractOther, valueExpr: 'tagid', displayExpr: 'name' } },
            );
        }

        const visibleConditionClient = (item) => {
            return (item.partystructure === 2 || item.partystructure === 3);
        }

        const visibleConditionSupervisor = (item) => {
            return (item.partystructure === 3 || item.partystructure === 4);
        }
        newContractFormConfig.fields.push(
            {
                label: this.state.clientName,
                visible: visibleConditionClient,
                fields: [
                    { resource: 'CONTRACT.FIELD.COMPANY', dataField: 'employercompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    { resource: 'CONTRACT.FIELD.EMPLOYER.USER', dataField: 'useremployercompanyid', dependOn: 'employercompanyid', dependOnValue: (item) => { if (item.useremployercompanyid) { return item.useremployercompanyid; } else { return item.employercompanyid; } }, editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    { resource: 'CONTRACT.FIELD.USER', dataField: 'employerid', dependOn: 'useremployercompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optEmployerUsers, valueExpr: 'accountid', displayExpr: 'display' } },
                ]
            },
            {
                label: this.state.pmName,
                fields: [
                    { resource: 'CONTRACT.FIELD.COMPANY', dataField: 'pmcompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    { resource: 'CONTRACT.FIELD.PM.USER', dataField: 'userpmcompanyid', dependOn: 'pmcompanyid', dependOnValue: (item) => { if (item.userpmcompanyid) { return item.userpmcompanyid; } else { return item.pmcompanyid; } }, editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    { resource: 'CONTRACT.FIELD.USER', dataField: 'projectmanagerid', dependOn: 'userpmcompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optPMUsers, valueExpr: 'accountid', displayExpr: 'display' } },
                ]
            },
            {
                label: this.state.supplierName,
                fields: [
                    { resource: 'CONTRACT.FIELD.COMPANY', dataField: 'contractorcompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    { resource: 'CONTRACT.FIELD.CONTRACTOR.USER', dataField: 'usercontractcompanyid', dependOn: 'contractorcompanyid', dependOnValue: (item) => { if (item.usercontractcompanyid) { return item.usercontractcompanyid; } else { return item.contractorcompanyid; } }, editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    { resource: 'CONTRACT.FIELD.USER', dataField: 'contractmanagerid', dependOn: 'usercontractcompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optContractorUsers, valueExpr: 'accountid', displayExpr: 'display' } },
                ]
            },
            {
                label: this.state.supervisorName,
                visible: visibleConditionSupervisor,
                fields: [
                    { resource: 'CONTRACT.FIELD.COMPANY', dataField: 'supervisorcompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    { resource: 'CONTRACT.FIELD.SUPERVISOR.USER', dataField: 'usersupervisorcompanyid', dependOn: 'supervisorcompanyid', dependOnValue: (item) => { if (item.usersupervisorcompanyid) { return item.usersupervisorcompanyid; } else { return item.supervisorcompanyid; } }, editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    { resource: 'CONTRACT.FIELD.USER', dataField: 'supervisorid', dependOn: 'usersupervisorcompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optSupervisorUsers, valueExpr: 'accountid', displayExpr: 'display' } },
                ]
            }
        );

        newContractFormConfig.fields.push(
            {
                label: '',
                fields: [
                    { resource: 'GENERAL.FORMS.FIELD.START_DATE', dataField: 'startdatedisplay', editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: new Date(1900, 0, 1), max: new Date(2079, 5, 6) } }, validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "REPORTS.FORM.VALIDATION.STARTDATE" }) } },
                    { resource: 'CONTRACT.FIELD.CMP_DATE', dataField: 'completedatedisplay', editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: this.state.contract.startdatedisplay, max: moment().add(100, "years")._d } }, validation: { type: 'range', min: this.state.contract.startdatedisplay, max: moment().add(100, "years")._d, warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_COMPLETION_DATE" }) } },
                    { resource: 'CONTRACT.FIELD.CURRENCY', dataField: 'currency', editorType: 'dxSelectBox', editorOptions: { dataSource: this.currencyCodes, valueExpr: 'code', displayExpr: 'name', defaultValue: 'GBP' } },
                    { resource: 'CONTRACT.FIELD.PRICE', dataField: 'contractvalue', editorType: 'dxNumberBox', editorOptions: { format: '#,##0.00', placeholder: '0.00' } },
                ]
            }
        );

        const preferenceFields = [];
        preferenceFields.push(
            { resource: 'CONTRACT.FIELD.SECONDARY_DATES', dataField: 'section', editorType: 'dxSwitch', editorOptions: { switchedOffText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }) } }
        );
        if (isSecondaryPricesEnabled) {
            preferenceFields.push(
                { resource: 'CONTRACT.FIELD.SECONDARY_PRICES', dataField: 'otherprice', editorType: 'dxSwitch', editorOptions: { switchedOffText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }) } }
            );
        }
        if (allowEditDemo) {
            preferenceFields.push(
                { resource: 'CONTRACT.FIELD.ISDEMOCONTRACT', dataField: 'isdemocontract', editorType: 'dxSwitch', editorOptions: { switchedOffText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }) } }
            );
        }
        if (isFinancialLimitEnabled) {
            preferenceFields.push(
                { resource: 'CONTRACT.FIELD.CONTRACTFINACIALLIMITENABLED', dataField: 'financiallimitenabled', editorType: 'dxSwitch', editorOptions: { switchedOffText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }) } }
            );
        }
        if (preferenceFields.length > 0) {
            newContractFormConfig.fields.push(
                {
                    resource: 'CONTRACT.FIELD.PREFERENCES',
                    fields: preferenceFields
                }
            );
        }

        if (this.isAsiteEnabled) {
            newContractFormConfig.fields.push(
                {
                    label: 'Asite Configuration',
                    fields: [
                        { resource: 'CONTRACT.FIELD.WORKSPACE', dataField: 'workspaceid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.asiteWorkspaces, valueExpr: 'id', displayExpr: this.customDisplayExpr } },
                        { resource: 'CONTRACT.FIELD.FOLDERID', dataField: 'folderid', required: false },
                        { resource: 'CONTRACT.FIELD.APIUSER', dataField: 'apiuser', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.asiteUsers } }
                    ]
                }
            );
        }

        return (
            <Portlet isLoading={this.state.isLoading}>
                <NavigationBlocker
                    when={(this.state.changed || this.state.isBusy)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} />
                    )
                }
                <PortletHeader resource="CONTRACT.CREATE.TITLE" />
                <PortletBody style={{ maxWidth: 800 }}>
                    <BiDynamicForm
                        config={newContractFormConfig}
                        valueChangeHandler={this.onValueChanged}
                        onSubmitHandler={this.submitHandler}
                        customFields={this.state.customFields}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    userProfile: store.auth.userProfile,
});

const mapDispatchToProps = {
    contractsDuckSetPending: contractsDuck.actions.setPending,
}

export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ContractNew))));