import {placeholderTypes} from "../../Types/Placeholder"
const placeholders =  [
    {
        replacementKey: 'Resources', values:
            [
                { id: 1, isActive: true, field: 'CostEffectLabel', display: 'Cost effect on total of the prices' },
                { id: 2, isActive: true, field: 'DaysEffectLabel', display: 'Number of days effect to the completion date' },
                { id: 3, isActive: true, field: 'WarningBanner', display: '' },
            ],
        type:placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'ProposalClauses', values:
            [
                { id: 1, isActive: true, field: '', display: '22.1 the following change in key person (from/to) for acceptance, who are equally qualified and experienced as the person originally named in the Contract Data:' },
                { id: 2, isActive: true, field: '', display: '24.2 the following Subconsultant who is being submitted for acceptance:' },
                { id: 3, isActive: true, field: '', display: '24.3 the following Subconsultant contract conditions which are being issued for acceptance:' },
                { id: 4, isActive: true, field: '', display: '34.2 a quotation for acceleration as follows to meet the date requested (in advance of the current Completion Date):' },
                { id: 5, isActive: true, field: '', display: '40.2 the following quality policy statement and/or quality plan for acceptance:' },
                { id: 6, isActive: true, field: '', display: '53.2 a revised activity schedule of which a summary of the key changes are listed below:' },
                { id: 7, isActive: true, field: '', display: 'X13.1: a performance bond:' },
            ],
        allowCustomReplyPeriods: true,
        type:placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'ActionReminders', values:
            [
                { id: 1, isActive: true, field: 'Supplier', display: '$SUPPLIER_NAME Proposals awaiting Acceptance' },
            ],
        type:placeholderTypes.ACTION_REMINDERS
    },
    {
        replacementKey: 'ReplyOptions', values:
            [
                { id: 1, isActive: true, field: 'Accepted', display: 'Accepted' },
                { id: 2, isActive: true, field: 'Not Accepted', display: 'Not Accepted' },
            ],
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'TaskOrderOptionEnabled', values:
            [
                { id: 1, isActive: false, field: 'TaskOrderOptionEnabled', display: 'Active-Inactive state indicates whether Task-Order can be selected in Proposal communications' },
            ],
        isToggle: true,
        fieldRequired: true,
        type: placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'ProjectOrderOptionEnabled', values:
            [
                { id: 1, isActive: false, field: 'ProjectOrderOptionEnabled', display: 'Active-Inactive state indicates whether Project-Order can be selected in Proposal communications' },
            ],
        isToggle: true,
        fieldRequired: true,
        type: placeholderTypes.TOGGLE_SWITCH
    }
];

export default placeholders;