import React from 'react';
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import { withCreatePageProps } from '../../../shared/hoc/withCreatePageProps';
import BaseCreatePage from '../../contract/_CustomComponents/BaseCreatePage';
import { TextBox, Button, ValidationGroup, ValidationSummary } from 'devextreme-react';
//import { Validator, RequiredRule, } from 'devextreme-react/validator';

class BookmarkPageSecond extends BaseCreatePage {
    constructor(props) {
        super(props);

        this.state = {
            link : null,
            copiedText: null,
            isSubmitting: false,
            isLinkEmpty: false
        }

        //this.onValueChanged = this.onValueChanged.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }

    componentDidMount() {
        const link = this.props.onCreateLink();

        this.setState({ link });
    }

    // onValueChanged(e) {
    //     const newValue = e.value;
    
    //     this.setState({ link: newValue });
    // }

    submitHandler() {
        if (!this.state.link) {
            this.setState({ isLinkEmpty: true });
            return;
        }

        const parts = this.state.link.split("id=");
        const bookmarkName = parts[1]; // this will give "bookmark_X"

        this.props.onBookmarkCaptured(bookmarkName);

        navigator.clipboard.writeText(this.state.link)
        .then(() => {
            this.setState({ isSubmitting: true });
        })
        .catch((error) => {
            console.error(error);
        });
    }

    render() {
        return (
            <Portlet>
                <PortletBody style={{ maxWidth: 1001 }}>
                    <div className="form">
                        {/* <ValidationGroup ref={this.validatorRef} name="CopyBookmarkValidator"> */}
                            <div className="dx-fieldset">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: "20%", whiteSpace: "normal" }}>{this.props.intl.formatMessage({ id: "GENERAL.FORMS.POWERBI_BOOKMARK_LINK" })}</div>
                                    <div style={{ width: "80%", marginLeft: "10px" }}>
                                        <TextBox
                                            value={this.state.link}
                                            //validationMessagePosition="left"
                                            disabled={true}
                                            //onValueChanged={this.onValueChanged}
                                        >
                                            {/* <Validator>
                                                <RequiredRule
                                                    key="Sub1"
                                                    message={this.props.intl.formatMessage({ id: "GENERAL.FORMS.FIELD.LINK" }) + ' ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.VALIDATION.REQUIRED' })}
                                                    isValid={!this.state.isLinkEmpty}
                                                />
                                            </Validator> */}
                                        </TextBox>
                                    </div>
                                </div>
                                <hr />
                                {/* <ValidationSummary
                                    elementAttr={{ align: "right" }}
                                    id={'BiValidator'}
                                    visible={this.state.isLinkEmpty && this.state.isSubmitting}
                                /> */}
                                <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '10px' }}>
                                    <Button
                                        style={{ marginLeft: "10px", ...(this.state.isSubmitting && { backgroundColor: '#4CAF50', color: 'white' }) }}
                                        text={!this.state.isSubmitting ? this.props.intl.formatMessage({ id: "GENERAL.FORMS.POWERBI_BUTTON_COPY" }) : this.props.intl.formatMessage({ id: "GENERAL.FORMS.POWERBI_BUTTON_COPIED_TEXT" })}
                                        type="default"
                                        stylingMode="outlined"
                                        onClick={this.submitHandler}
                                        useSubmitBehavior={false}
                                    >
                                    </Button>
                                </div>
                            </div>
                        {/* </ValidationGroup> */}
                    </div>
                </PortletBody>
            </Portlet>
        );
    }
}

export default withCreatePageProps(BookmarkPageSecond);