import React from 'react';
import { withSnackbar } from 'notistack';
import * as fileCrud from '../../../../crud/file.crud';
import Document from '../../_CustomComponents/DocumentV2';
import { connect } from "react-redux";
import Spinner from '../../../../partials/layout/Spinner';
import CompensationCustomForm from './CustomForm';
import * as compensationEventCrud from '../../../../crud/compensationEvent.crud';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { injectIntl } from "react-intl";

class CompensationDocumentMain extends BaseDocument {
    constructor(props) {
        super(props);
        this.state = {
            relatedWarnings: null,
        };
    }
    componentDidMount() {
        if (this.props.ewRelationEnabled) {
            this.fetchCompensationWarnings();
        }

    }

    fetchCompensationWarnings = () => {
        const { companyid, projectid, contractid } = this.props.contract;
        compensationEventCrud.getCompensationWarnings(companyid, projectid, contractid, this.props.notification.compensationeventid)
            .then(response => {
                const compWarnings = JSON.parse(response.data.aggregatelist);
                const mappedItems = compWarnings.map(sew => {
                    const url = `/contract/${contractid}/early-warning/${sew.earlywarningparty === 'PM' ? 'pm' : 'supplier'}/${sew.earlywarningid}`;
                    return {
                        warningid: sew.earlywarningid,
                        title: sew.earlywarning,
                        url: url,
                        reference: sew.earlywarningreferenceid,
                        existing: true
                    }
                });

                this.setState({ relatedWarnings: mappedItems });
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    renderForm = () => {
        return (
            <CompensationCustomForm
                {...this.props}
                relatedWarnings={this.state.relatedWarnings}
                item={this.props.notification}
                valueChangeHandler={this.props.fieldChanged}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
            />
        );
    }

    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.compensationeventid, data, config)
    }


    render() {
        if ((this.props.ewRelationEnabled && !this.state.relatedWarnings)) {
            return (<Spinner />);
        }

        const documentIsFinal = this.props.notification.showtasknotification === 1;
        return (
            <Document
                key={documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.renderForm()}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.notification.assetid}
            />
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
});


export default injectIntl(withSnackbar(connect(mapStateToProps)(CompensationDocumentMain)));
