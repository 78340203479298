import React from 'react';
import DataGrid, { Column, ColumnFixing, Scrolling, HeaderFilter, FilterRow, FilterPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import { withCreatePageProps } from '../../../shared/hoc/withCreatePageProps';
import BaseCreatePage from '../../contract/_CustomComponents/BaseCreatePage';
import { Popup } from 'devextreme-react/popup';
import PortletSpinner from '../../../partials/layout/PortletSpinner';
import { Button, SelectBox } from 'devextreme-react';
import { confirm } from 'devextreme/ui/dialog';
class BookmarkList extends BaseCreatePage {
    constructor(props) {
        super(props);
        this.state = {
            bookmarks: null,
            loading: true,
            isExportPopupVisible: false, 
            selectedExportFormat: 1, 
            bookmarkState: null
        };
    }

    async componentDidMount() {
        if (this.props.visible) {
            try {
                const resolvedBookmarks = await this.props.bookmarks;
                this.setState({
                    bookmarks: resolvedBookmarks,
                    loading: false
                });
            } catch (error) {
                console.error('Error fetching bookmarks:', error);
            }
        }
    }

    async componentDidUpdate(prevProps) {
        if ((this.props.visible !== prevProps.visible && this.props.visible) || this.props.bookmarks !== prevProps.bookmarks) {
            try {
                const resolvedBookmarks = await this.props.bookmarks;
                this.setState({
                    bookmarks: resolvedBookmarks,
                    loading: false
                });
            } catch (error) {
                console.error('Error fetching bookmarks:', error);
            }
        }
    }

    onLoadClick = (e) => {
        
        this.props.onLoadBookmark(e.row.data.bookmarkstate);
        this.props.onClose();
    }

    onDeleteClick = async (e) => {
        const warningMessage = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING.POWERBI_DELETE_MESSAGE' });
        const warningMessageLabel = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING.POWERBI_DELETE_LABEL' });
        const result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageLabel}`);
        
        if (!result) {
            return;
        }

        this.props.onDeleteBookmark(e.row.key);
    }

    onExportClick = (e) => {
        
        const bookmarkState = e.row.data.bookmarkstate
        this.setState({ isExportPopupVisible: true,  bookmarkState });
    }

    handleExport = (e) => {
        
        const { selectedExportFormat, bookmarkState } = this.state
        if (selectedExportFormat && bookmarkState) {
            this.props.onCreateExportBookmark(selectedExportFormat, bookmarkState);
            this.setState({ isExportPopupVisible: false, selectedExportFormat: null, bookmarkState: null });
        }
    }

    handleCancelExport = () => {
        this.setState({ isExportPopupVisible: false, selectedExportFormat: null });
    }
    onCopyClick = (e) => {
        let url = (window.location != window.parent.location) ?
            document.referrer :
            document.location.href;

        const urlObject = new URL(url);

        const baseUrl = urlObject.origin + urlObject.pathname;

        const bookmarkName = this.state.bookmarks.find(b => b.powerbicustombookmarkviewid === e.row.key)?.bookmarkname;

        // Build the share bookmark URL
        const shareUrl = baseUrl + "?id=" + bookmarkName;

        navigator.clipboard.writeText(shareUrl)
        .then(() => {
            this.setState({ isSubmitting: true });
        })
        .catch((error) => {
            console.error(error);
        });

        //this.props.onLoadBookmark(e.row.data.bookmarkstate);
        this.props.onClose();
    }
    render() {
        const { bookmarks, loading, isExportPopupVisible, selectedExportFormat } = this.state;
        const { visible } = this.props;

        if (!visible || loading) {
            return <PortletSpinner />;
        }

        return (
            <>
                <Popup
                    visible={this.props.visible}
                    onHiding={this.props.onClose}
                    dragEnabled={true}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    height='auto'
                    width='40vw'
                >
                    <DataGrid
                        dataSource={bookmarks}
                        keyExpr="powerbicustombookmarkviewid"
                        height={470}
                        name="Bookmarks"
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        remoteOperations={false}
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        rowAlternationEnabled={true}
                        allowColumnResizing={true}
                        columnResizingMode='widget'
                        wordWrapEnabled={true}
                        noDataText={this.props.intl.formatMessage({ id: 'REPORTS.TITLE.NOBOOKMARK' })}
                        style={{ width: '100%', height: '100%', maxHeight: '67vh', ...this.props.style }}
                    >
                        <HeaderFilter visible={true} />
                        <FilterRow applyFilter="auto" visible={true} />
                        <FilterPanel visible={true} />
                        <ColumnFixing enabled={true} />
                        <Column caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.NAME' })} dataField="bookmarkname" visible={false} />
                        <Column caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.NAME' })} dataField="bookmarkdisplayname" />
                        <Column caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POWERBI_BUTTON_LOAD' })} name="loadButton" type="buttons" buttons={[{ hint: 'Load', icon: 'import', onClick: this.onLoadClick }]} />
                        <Column caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POWERBI_BUTTON_COPY_LINK' })} name="copyButton" type="buttons" buttons={[{ hint: 'Copy Link', icon: 'copy', onClick: this.onCopyClick }]} />
                        <Column caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POWERBI_BUTTON_DELETE' })} name="deleteButton" type="buttons" buttons={[{ hint: 'Delete', icon: 'clear', onClick: this.onDeleteClick }]} />
                        <Column caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POWERBI_BUTTON_EXPORT' })} name="exportButton" type="buttons" buttons={[{ hint: 'Export', icon: 'export', onClick: this.onExportClick }]} />
                        <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                        <Summary>
                            <TotalItem column={0} summaryType="count" />
                        </Summary>
                    </DataGrid>
                </Popup>

                <Popup
                    visible={isExportPopupVisible}
                    onHiding={this.handleCancelExport}
                    dragEnabled={true}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POWERBI_BUTTON_EXPORT' })}
                    height='auto'
                    width='auto'
                >
                    <div style={{ padding: '20px' }}>
                        <div style={{ marginBottom: '10px' }}>
                            {this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POWERBI_EXPORT_MESSAGE' })}
                        </div>
                        <SelectBox
                            items={[
                                { text: 'PDF', value: 1 },
                                { text: 'PPTX', value: 2 },
                            ]}
                            displayExpr="text"
                            valueExpr="value"
                            value={selectedExportFormat}
                            onValueChanged={(e) => this.setState({ selectedExportFormat: e.value })}
                        />
                        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                text={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON.CANCEL' })}
                                onClick={this.handleCancelExport}
                                style={{ marginRight: '10px' }}
                            />
                            <Button
                                text={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON.EXPORT' })}
                                onClick={this.handleExport}
                                disabled={!selectedExportFormat}
                            />
                        </div>
                    </div>
                </Popup>
            </>
        );
    }
}

export default withCreatePageProps(BookmarkList);
