import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PaymentBatchList from './List';
import PaymentBatchDetail from './Detail';


class PaymentBatchRouter extends React.Component {
    constructor(props) {
        super(props);
        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;

        return (
            <Routes caseSensitive>
                <Route path={workflowItem.paths.basePath} exact element={<Navigate replace to={workflowItem.paths.defaultPath} />} />
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<PaymentBatchList
                        {...this.props}
                        
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                    />}
                />
                <Route
                    path={'/:notificationid/*'}
                    element={<PaymentBatchDetail
                        {...this.props}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                    />}
                />

            </Routes>
        );
    }
};



export default PaymentBatchRouter;