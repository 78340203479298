import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CompanyRouter from "./Companies/Router";
import ProjectsRouter from "./Projects/Router";
import TemplatesRouter from "./Templates/Router";
import SettingsRouter from "./Settings/Router";
import UsersRouter from "./Users/Router";


class AdminRouter extends React.Component {
    render() {
        return (
            <Routes caseSensitive>
                <Route path="/companies/*" element={<CompanyRouter />} sensitive />
                <Route path="/projects/*" element={<ProjectsRouter />} sensitive />
                <Route path="/settings/*" element={<SettingsRouter />} sensitive />
                <Route path="/users/*" element={<UsersRouter />} sensitive />
                <Route path="/templates/*" element={<TemplatesRouter />} sensitive />
                <Route path="*" element={<Navigate replace to="/contracts" />} />
            </Routes>
        );
    }
}

export default AdminRouter;