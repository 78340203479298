import React, { Component } from "react";
import moment from 'moment';
import DataGrid, {
  Column,
  SearchPanel,
  Export,
  ColumnChooser,
  ColumnFixing,
  Scrolling,
  HeaderFilter,
  FilterRow,
  FilterPanel,
  Summary,
  TotalItem,
  Selection,
  Toolbar,
  Item as ToolbarItem
} from "devextreme-react/data-grid";
import { Template } from "devextreme-react/core/template";
import { withSnackbar } from "notistack";
import PortletSpinner from "../../../partials/layout/PortletSpinner";
import * as userCrud from "../../../crud/user.crud";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import { systemAccountStatus } from "../../../shared/lookup";
import { injectIntl } from "react-intl";
import { custom as CustomDialog } from 'devextreme/ui/dialog';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

const isFinancialLimitEnabled = process.env.REACT_APP_FINANCIAL_LIMIT_ENABLED === "true";
const isPowerBIMenusEnabled = process.env.REACT_APP_POWERBI_ENABLED === "true";


class SystemUsers extends Component {
  constructor(props) {
    super(props);
    this.gridRef = React.createRef();

    this.state = {
      allUsers: null,
    };


    this.columnsConfig = [
      {
        caption: "System Account Status",
        dataField: "deleted",
        allowHiding: false,
        lookup: {
          dataSource: systemAccountStatus,
          displayExpr: "value",
          valueExpr: "key",
        },
      },
      { caption: "Account ID", dataField: "accountid" },
      { caption: "Name", dataField: "name" },
      { caption: "Account Type", dataField: "accountType" },
      { caption: "Username", dataField: "username" },
      { caption: "Job Title", dataField: "jobtitle" },
      { caption: "Email", dataField: "emailAddress" },
      { caption: "Company Name", dataField: "companyName" },
      { caption: "Last Login Date", dataField: "lastLoginDate", dataType: "date", format: (date) => moment(date).format('DD/MM/YYYY') },
      { caption: "Date Added", dataField: "dateAdded", dataType: "date", format: (date) => moment(date).format('DD/MM/YYYY HH:mm') },
      { caption: "Added By", dataField: "addedBy" },
      { caption: "Last Edited Date", dataField: "lastEditedDate", dataType: "date", format: (date) => moment(date).format('DD/MM/YYYY HH:mm') },
      { caption: "Last Edited By", dataField: "lastEditedBy" },
      { caption: "Country", dataField: "country" }
    ];
    if (isFinancialLimitEnabled) {
      this.columnsConfig.push(
        { caption: props.intl.formatMessage({ id: "GENERAL.FORMS.FIELD.FINANCIAL_LIMIT_AMOUNT" }), dataField: "userlimit" },
        { caption: props.intl.formatMessage({ id: "GENERAL.FORMS.FIELD.FINANCIAL_LIMIT_CURRENCY" }), dataField: "userlimitcurrency" },
      )
    }
    if (isPowerBIMenusEnabled) {
      this.columnsConfig.push(
        { caption: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.ALLOW_POWERBI_MENUS" }), dataField: 'allowpowerbimenus' }
      )
    }
    this.columnsConfig.push(
      { caption: props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.EMAILS_DISABLED" }), dataField: "disableglobalemailnotifications" },
      { caption: props.intl.formatMessage({ id: "COMPANY.USER.FIELD.EMAILS_OPTEDOUT" }), dataField: "optoutemailnotifications" }
    )
  }
  componentDidMount() {
    this.getSystemUsers();
  }
  getSystemUsers = async () => {
    try {
      const response = await userCrud.getSystemUsers();
      let allUsers = JSON.parse(response.data);
      this.setState({ allUsers });
    }
    catch (err) {
      this.props.enqueueSnackbar(err.toString(), { variant: "error" });
    }
  };
  toolbarTitleRender = () => {
    return (
      <div>
        <h3
          style={{
            fontFamily: "Poppins, Helvetica, sans-serif",
            fontSize: "1.2rem",
            fontWeight: 500,
            color: "#48465b",
            padding: 0,
            margin: 0,
            lineHeight: 1.2,
          }}
          className="kt-portlet__head-title"
        >
          System Users
        </h3>
      </div>
    );
  };


  onActivateClick = async () => {
    try {
      const selection = this.gridRef.current.instance.getSelectedRowKeys();
      if (selection.length) {
        let activateDialog = CustomDialog({
          title: `Activating ${selection.length} user(s)`,
          messageHtml: `<div style="max-width:50vw;">You are about to activate ${selection.length} user(s). Please select the "activation method" you would like to use.</div>`,
          buttons: [
            {
              text: "Cancel",
              onClick: (e) => { return "CANCEL" }
            },
            {
              text: "Activate Only",
              type: "success",
              stylingMode: "outlined",
              onClick: (e) => { return "ACTIVATE_ONLY" }
            },
            {
              text: "Activate & Reinstate Permissions",
              type: "success",
              stylingMode: "outlined",
              onClick: (e) => { return "ACTIVATE_REINSTATE" }
            },

          ]
        });
        const result = await activateDialog.show();
        if (["ACTIVATE_ONLY", "ACTIVATE_REINSTATE"].includes(result)) {
          const formData = {
            userIds: selection,
            actionType: "activate",
            reinstateContractPermissionsOnActivate: (result === "ACTIVATE_REINSTATE"),
          };
          await userCrud.bulkActivateDeactivateUsers(formData);
          this.props.enqueueSnackbar("Users successfully activated", { variant: "success" });
          this.getSystemUsers();
          this.gridRef.current.instance.clearSelection();
        }

      }
    }
    catch (err) {
      this.props.enqueueSnackbar(err.toString(), { variant: "error" });
    }
  }
  onDeactivateClick = async () => {
    try {
      const selection = this.gridRef.current.instance.getSelectedRowKeys();
      if (selection.length) {
        let activateDialog = CustomDialog({
          title: `Deactivating ${selection.length} user(s)`,
          messageHtml: `<div style="max-width:50vw;">You are about to deactivate ${selection.length} user(s). </div>`,
          buttons: [
            {
              text: "Cancel",
              onClick: (e) => { return "CANCEL" }
            },
            {
              text: "Deactivate",
              type: "danger",
              stylingMode: "outlined",
              onClick: (e) => { return "DEACTIVATE" }
            },

          ]
        });
        const result = await activateDialog.show();
        if (result === "DEACTIVATE") {
          const formData = {
            userIds: selection,
            actionType: "deactivate",
            reinstateContractPermissionsOnActivate: null,
          };
          await userCrud.bulkActivateDeactivateUsers(formData);
          this.props.enqueueSnackbar("Users successfully deactivated", { variant: "success" });
          this.getSystemUsers();
          this.gridRef.current.instance.clearSelection();
        }

      }
    }
    catch (err) {
      this.props.enqueueSnackbar(err.toString(), { variant: "error" });
    }
  }
  onbtnDisablePowerBIClick = async () => {
    try {
      const selection = this.gridRef.current.instance.getSelectedRowKeys();
      if (selection.length) {
        let activateDialog = CustomDialog({
          title: `Disabling ${selection.length} user(s) "PowerBI" menu access`,
          messageHtml: `<div style="max-width:50vw;">You are about to disable "PowerBI" menu access for ${selection.length} user(s). </div>`,
          buttons: [
            {
              text: "Cancel",
              onClick: (e) => { return "CANCEL" }
            },
            {
              text: "Disable",
              type: "danger",
              stylingMode: "outlined",
              onClick: (e) => { return "DISABLE" }
            },

          ]
        });
        const result = await activateDialog.show();
        if (result === "DISABLE") {

          const formData = {
            userIds: selection,
            actionType: result
          };

          await userCrud.bulkEnableDisablePowerBiMenus(formData);
          this.props.enqueueSnackbar("Users PowerBI menu successfully disabled", { variant: "success" });
          this.getSystemUsers();
          this.gridRef.current.instance.clearSelection();
        }

      }
    }
    catch (err) {
      this.props.enqueueSnackbar(err.toString(), { variant: "error" });
    }
  }
  onbtnEnablePowerBIClick = async () => {
    try {
      const selection = this.gridRef.current.instance.getSelectedRowKeys();
      if (selection.length) {
        let activateDialog = CustomDialog({
          title: `Enabling ${selection.length} user(s) "PowerBI" menu access`,
          messageHtml: `<div style="max-width:50vw;">You are about to enable "PowerBI" menu access for ${selection.length} user(s). </div>`,
          buttons: [
            {
              text: "Cancel",
              onClick: (e) => { return "CANCEL" }
            },
            {
              text: "Enable",
              type: "success",
              stylingMode: "outlined",
              onClick: (e) => { return "ENABLE" }
            },

          ]
        });
        const result = await activateDialog.show();
        if (result === "ENABLE") {
          const formData = {
            userIds: selection,
            actionType: result
          };

          await userCrud.bulkEnableDisablePowerBiMenus(formData);
          this.props.enqueueSnackbar("Users PowerBI menu successfully enabled", { variant: "success" });
          this.getSystemUsers();
          this.gridRef.current.instance.clearSelection();
        }

      }
    }
    catch (err) {
      this.props.enqueueSnackbar(err.toString(), { variant: "error" });
    }
  }


  onClearSelection = async () => {

    const selection = this.gridRef.current.instance.getSelectedRowKeys();
    if (selection.length) {
      let activateDialog = CustomDialog({
        title: `Clear Selected user(s)`,
        messageHtml: `<div style="max-width:50vw;">You are about to clear selected user(s). </div>`,
        buttons: [
          {
            text: "Cancel",
            onClick: (e) => { return "CANCEL" }
          },
          {
            text: "Clear",
            type: "success",
            stylingMode: "outlined",
            onClick: (e) => { return "CLEAR" }
          },

        ]
      });
      const result = await activateDialog.show();
      if (result === "CLEAR") {
        this.gridRef.current.instance.clearSelection();
      }

    }

  }


  onSelectionChanged = ({ selectedRowKeys }) => {
    if (selectedRowKeys.length > 0 && selectedRowKeys.length <= 100) {
      if (this.btnEnablePowerBI) { this.btnEnablePowerBI.component.option("disabled", false); }
      if (this.btnDeactivate) { this.btnDeactivate.component.option("disabled", false); }
      if (this.btnActivate) { this.btnActivate.component.option("disabled", false); }
      if (this.btnDisablePowerBI) { this.btnDisablePowerBI.component.option("disabled", false); }
      if (this.btnClearSelection) { this.btnClearSelection.component.option("disabled", false); }
    } else {
      if (this.btnEnablePowerBI) { this.btnEnablePowerBI.component.option("disabled", true); }
      if (this.btnDeactivate) { this.btnDeactivate.component.option("disabled", true); }
      if (this.btnActivate) { this.btnActivate.component.option("disabled", true); }
      if (this.btnDisablePowerBI) { this.btnDisablePowerBI.component.option("disabled", true); }
      if (this.btnClearSelection) { this.btnClearSelection.component.option("disabled", true); }
    }
    if (selectedRowKeys.length > 100) {
      this.props.enqueueSnackbar("No more than 100 records can be updated in single transaction.", { variant: "warning" });
    }
  }
  onExporting = (e) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer()
        .then(function (buffer) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'SystemUsers.xlsx');
        });
    });
  }
  render() {
    if (!this.state.allUsers) {
      return <PortletSpinner />;
    }


    return (
      <Portlet>
        <PortletBody>
          <DataGrid
            showBorders={true}
            showColumnLines={true}
            showRowLines={true}
            remoteOperations={false}
            allowColumnReordering={true}
            columnAutoWidth={true}
            rowAlternationEnabled={true}
            allowColumnResizing={true}
            columnResizingMode="widget"
            wordWrapEnabled={false}
            allowSelection={true}
            noDataText="No data"
            dataSource={this.state.allUsers}
            keyExpr="accountid"
            onExporting={this.onExporting}
            className="DxDatagridFullPage"
            // style={{
            //   width: "100%",
            //   height: "100%",
            //   maxHeight: "67vh",
            // }}
            ref={this.gridRef}
            onSelectionChanged={this.onSelectionChanged}
          >
            {
              (this.props.mode !== "REPORTS") && (
                <Selection
                  mode="multiple"
                  selectAllMode="allPages"
                  showCheckBoxesMode="always"
                />
              )
            }

            <HeaderFilter visible={true} />
            <FilterRow applyFilter="auto" visible={true} />
            <FilterPanel visible={true} />
            <ColumnChooser enabled={true} />
            <ColumnFixing enabled={true} />
            <Export enabled={true} />
            <SearchPanel
              visible={true}
              highlightCaseSensitive={false}
              defaultText=""
            />
            {this.columnsConfig.map((columnItem, index) => (
              <Column
                key={index}
                {...columnItem}
              />
            ))}
            <Template name="toolbarTitle" render={this.toolbarTitleRender} />
            <Scrolling
              mode="virtual"
              showScrollbar={true}
              useNative={true}
              columnRenderingMode="virtual"
              rowRenderingMode="virtual"
            />
            <Toolbar>
              <ToolbarItem location="before"
                locateInMenu="never"
                template="toolbarTitle"
              />

              {
                (this.props.mode !== "REPORTS" && isPowerBIMenusEnabled) && (
                  <ToolbarItem
                    location="after"
                    locateInMenu="auto"
                    widget="dxButton"
                    options={{
                      onInitialized: (e) => { this.btnDisablePowerBI = e; },
                      icon: "decreaseindent",
                      text: "Disable Power BI Menus",
                      disabled: true,
                      onClick: this.onbtnDisablePowerBIClick,
                      type: "danger",
                      stylingMode: "outlined",
                    }}
                  />
                )
              }
              {
                (this.props.mode !== "REPORTS") && (
                  <ToolbarItem
                    location="after"
                    locateInMenu="auto"
                    widget="dxButton"
                    options={{
                      onInitialized: (e) => { this.btnDeactivate = e; },
                      icon: "range",
                      text: "Deactivate",
                      disabled: true,
                      onClick: this.onDeactivateClick,
                      type: "danger",
                      stylingMode: "outlined",
                    }}
                  />
                )
              }
              {
                (this.props.mode !== "REPORTS" && isPowerBIMenusEnabled) && (
                  <ToolbarItem
                    location="after"
                    locateInMenu="auto"
                    widget="dxButton"
                    options={{
                      onInitialized: (e) => { this.btnEnablePowerBI = e; },
                      icon: "increaseindent",
                      text: "Enable Power BI Menus",
                      disabled: true,
                      onClick: this.onbtnEnablePowerBIClick,
                      type: "success",
                      stylingMode: "outlined",
                    }}
                  />
                )
              }
              {
                (this.props.mode !== "REPORTS") && (
                  <ToolbarItem
                    location="after"
                    locateInMenu="auto"
                    widget="dxButton"
                    options={{
                      onInitialized: (e) => { this.btnActivate = e; },
                      icon: "add",
                      text: "Activate",
                      disabled: true,
                      onClick: this.onActivateClick,
                      type: "success",
                      stylingMode: "outlined",
                    }}
                  />
                )
              }
              {
                (this.props.mode !== "REPORTS") && (
                  <ToolbarItem
                    location="after"
                    locateInMenu="auto"
                    widget="dxButton"
                    options={{
                      onInitialized: (e) => { this.btnClearSelection = e; },
                      icon: "clear",
                      text: "Clear Selection",
                      disabled: true,
                      onClick: this.onClearSelection,
                      stylingMode: "outlined",
                    }}
                  />
                )
              }
              <ToolbarItem location="after"
                locateInMenu="auto"
                name="exportButton"
              />
              <ToolbarItem location="after"
                locateInMenu="auto"
                name="columnChooserButton"
              />
              <ToolbarItem location="after"
                locateInMenu="auto"
                name="searchPanel"
              />
            </Toolbar>
            <Summary>
              <TotalItem showInColumn="deleted" summaryType="count" />
            </Summary>
          </DataGrid>
        </PortletBody>
      </Portlet>
    );
  }
}

export default injectIntl(withSnackbar(SystemUsers));
