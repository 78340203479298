/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Collapse } from "react-bootstrap";
import NotifyArea from './NotifyArea';
import { Badge } from "react-bootstrap";

class ExpandableActionControl extends React.Component {
    state = {
        expanded: false,
    }
    handleChange = event => {
        this.setState({ expanded: !this.state.expanded }, () => {
            if (this.props.onSizeChanged) {
                this.props.onSizeChanged();
            }
        });

    };
    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.isOpen !== this.props.isOpen) {
                if (!this.props.isOpen) {
                    this.setState({ expanded: false }, () => {
                        if (this.props.onSizeChanged) {
                            this.props.onSizeChanged();
                        }
                    });
                }
            }
        }
    }
    render() {
        const toggleContainerClasses = ['kt-notification__item'];
        if (this.state.expanded) {
            toggleContainerClasses.push('kt-notification__item--open');
        }
        let notification = null;
        let badgeVariant = 'secondary';
        if (this.props.item.itemsList?.length > 0) {
            notification = this.props.item.itemsList.length;
            badgeVariant = 'danger';
        }
        else {
            notification = 0;
        }
        return (
            <>
                <a href="#" style={{ backgroundColor: "#FFFFCC", marginTop: 5, marginLeft: 5, marginRight: 5, borderRadius: 10, paddingLeft: 10, fontWeight: 500, fontSize: '14px', fontFamily: '\'Helvetica Neue\', \'Segoe UI\', Helvetica, Verdana, sans-serif', color: '#333' }} onClick={this.handleChange} className={toggleContainerClasses.join(' ')}>
                    {/* <div className="kt-notification__item-icon">
                        <i className="flaticon2-line-chart kt-font-success" />
                    </div> */}
                    <div className="kt-notification__item-icon">
                        <Badge variant={badgeVariant}>{notification}</Badge>

                        {/* {notification && (<span className={badgeClass.join(' ')} id="EarlyWarningPMSpan">{notification}</span>)} */}
                    </div>
                    {/* <Badge badgeContent={8} color="error"></Badge> */}
                    <div className="kt-notification__item-details">
                        <div className="kt-notification__item-title" style={{ fontWeight: 400, fontSize: '14px', color: '#333' }}>
                            {this.props.item.title}
                        </div>
                        {/* <div className="kt-notification__item-time">
                            2 hrs ago
                        </div> */}
                    </div>
                </a>

                <Collapse in={this.state.expanded}>
                    <div>
                        <NotifyArea
                            panelCloseBtnRef={this.props.panelCloseBtnRef}
                            expanded={this.state.expanded}
                            item={this.props.item}
                            warningCount={notification}
                            contractid={this.props.contractid}
                        />
                    </div>
                </Collapse>


            </>
        );
    }
}

export default ExpandableActionControl;