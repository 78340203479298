
import axios from '../shared/axios';


export function getUser(accountID) {
  return axios.get('/api/user/getuser?userid='+accountID);
}
export function getUsers(cancelTok) {
  return axios.post('/api/User/LoadUsersList',null,{cancelToken: cancelTok});
}

export function getContractUser(companyid, projectid, contractid){
  return axios.get(`/api/User/GetProfileContractUser?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}`);
}

export function createUser(formData) {
  return axios.post('/api/Account/RegisterCustom', formData);
}

export function updateUser(formData) {
  return axios.put('/api/User/PutProfile', formData);
}

export function bulkActivateDeactivateUsers(formData) {
  return axios.post('/api/Administrator/BulkActivateDeactivateUsers', formData);
}

export function bulkEnableDisablePowerBiMenus(formData) {
  return axios.post('/api/Administrator/BulkEnableDisablePowerBiMenus', formData);
}

export function getSystemUsers(){
  return axios.get('/api/Administrator/ListSystemUsers');
}

export function reallocateUserToCompany(companyuserid, accountid, companyid) {
  return axios.post(`/api/Administrator/ReallocateUserToCompany?companyuserid=${companyuserid}&accountid=${accountid}&companyid=${companyid}`);
}

export function updateRedirectedToConsentDate() {
  return axios.put('/api/User/UpdateRedirectedToConsentDate');
}

export function bulkShowHideUsersOnDashboard(formData) {
  return axios.post('/api/Administrator/BulkShowHideUsersOnDashboard', formData);
}