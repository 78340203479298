import { placeholderTypes } from "../../Types/Placeholder";

const placeholders = [
    {
        replacementKey: 'Resources', values:
            [

            ],
        type: placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'ActionReminders', values:
            [

            ],
        type: placeholderTypes.ACTION_REMINDERS
    },
    {
        replacementKey: 'FieldsPM', values:
            [
                { id: 1, isActive: true, field: 'Subject', display: 'Subject' },
                { id: 2, isActive: true, field: 'Type', display: 'Type' },
                { id: 3, isActive: true, field: 'Description', display: 'Description' },
                { id: 4, isActive: true, field: 'EffectContractPrice', display: 'Affects Contract Price' },
                { id: 5, isActive: true, field: 'Amount', display: 'Amount' },
                { id: 6, isActive: true, field: 'EffectContractCompletion', display: 'Affects $COMPLETION_DATE' },
                { id: 7, isActive: true, field: 'Days', display: 'Time' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'ClausesPM', values:
            [
                { id: 1, isActive: true, field: '', display: 'TEST CLAUSE', effect: "EFFECT_COMPLETION_PRICE", },
            ],
        allowOverrideResources: true,
        effects: ["EFFECT_COMPLETION_PRICE", "EFFECT_PRICE", "EFFECT_COMPLETION", "NOEFFECT_COMPLETION_PRICE"],
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'TaskOrderOptionEnabled', values:
            [
                { id: 1, isActive: false, field: 'TaskOrderOptionEnabled', display: 'Active-Inactive state indicates whether Task-Order can be selected in CEN communications' },
            ],
        isToggle: true,
        fieldRequired: true,
        type: placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'ProjectOrderOptionEnabled', values:
            [
                { id: 1, isActive: false, field: 'ProjectOrderOptionEnabled', display: 'Active-Inactive state indicates whether Project-Order can be selected in CEN communications' },
            ],
        isToggle: true,
        fieldRequired: true,
        type: placeholderTypes.TOGGLE_SWITCH
    }
];

export default placeholders;