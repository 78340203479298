import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProjectDetails from './Project/ProjectDetails';
import ProjectNew from './ProjectNew';
import Home from "./Home";
import Protector from "../../../router/Protector/Protector";

class ProjectsRouter extends React.Component {
    render() {
        return (
            <Routes caseSensitive>
                <Route path="/" exact element={<Protector><Home /></Protector>} sensitive />
                <Route path="/new-project" exact element={<Protector><ProjectNew /></Protector>} sensitive />
                <Route path="/:projectid/*" element={<Protector><ProjectDetails /></Protector>} sensitive />
                <Route path="*" element={<Navigate replace to="/contracts" />} />
            </Routes>
        );
    }

}


export default ProjectsRouter;