import { userFilterCategories } from "../../../shared/lookup";
const workflows = [

    {
        id: 14,
        title: '$SUPPLIER_NAME Programme',
        notificationName: 'Programme',
        abbreviation: '$SUPPLIER_ABBREVIATION-Programme-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'PM',
        //REPLY
        replyEnabled: true,
        replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Programme',
            currentModel: 'Program',
            currentModelType: '',
            referenceType: 'Programme',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,
            configurableReplyTab: true,

            replyPeriodOptions: ["PROGRAMME_REPLY_PERIOD", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supplier',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },

]

export default workflows;