export default class CrudHelper {
    static appendCustomFields = (formData) => {
        let customFieldsExist = false;
        const isCustomFieldsEnabled = process.env.REACT_APP_CUSTOM_FIELDS_ENABLED === "true";
        const customFields = [];
        if (isCustomFieldsEnabled) {
            for (let field in formData) {
                if (field.startsWith("CF_")) {
                    customFieldsExist = true;
                    let cfvalue = formData[field];
                    if (Array.isArray(formData[field])) {
                        cfvalue = (formData[field]).join("|");
                    }
                    customFields.push({ customfieldid: Number(field.substring(3)), value: cfvalue })
                    delete formData[field];
                }
            }
            if (customFieldsExist) {
                formData.customfieldvalues = customFields;
            }
        }
        return formData;
    }
}