import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import * as earlyWarningCrud from '../../../../crud/earlyWarning.crud';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import WarningCustomForm from './CustomForm';
import moment from 'moment';
import { addDays } from 'date-fns';
import { FormattedMessage } from "react-intl";
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import Common from '../../../../shared/common';
import { actionCodes } from "../../../../shared/lookup";
class WarningCreate extends BaseCreatePage {
    constructor(props) {
        super(props);


        this.state = {
            showAlert: true,
        };
    }

    submitHandler = (warning, setSubmitting) => {
        warning.companyid = this.props.contract.companyid;
        warning.contractid = this.props.contract.contractid;
        warning.projectid = this.props.contract.projectid;
        warning.replystatus = 'Not Communicated';
        warning.status = 'Not Communicated';
        // warning.notificationdatedisplay = moment().format('DD/MM/YYYY');
        if (warning.meetingtime) {
            warning.meetingtime = moment(warning.meetingtime).format('HH:mm');
        }
        //MEETINGDATE FORMAT IS "MM/DD/YYYY" different than others
        if (warning.meetingdate) {
            warning.meetingdate = moment(warning.meetingdate).format('MM/DD/YYYY');
        }
        if (warning.risk) {
            warning.risk = moment(warning.risk).format('DD/MM/YYYY');
        }
        warning.party = this.props.workflowItem.config.currentModelType;
        warning.replyrequireddate = moment(addDays(new Date(), this.props.replyPeriod)).toDate();
        this.props.riskTypes.forEach(riskType => {
            warning[riskType.field] = warning[riskType.field] ? 1 : 0;
        });
        warning.actioncode = actionCodes.CREATE;
        warning.notifyingparty = this.props.workflowItem.notifyingParty;
        warning.replyingparty = this.props.workflowItem.replyingParty;
        // delete warning.riskdisplay;
        this.appendRelationship(warning);
        Common.PropertyConversionsBeforeNoticeSaveRequest(warning, this.props.customFields, this.props.contractUsers);
        earlyWarningCrud.saveEarlyWarning(warning)
            .then(response => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
                try {
                    const createdID = Number(response.data);
                    // if (Object.keys(queryObj).length !== 0) {
                    //     this.props.navigate(this.props.workflowItem.paths.basePath + createdID + '?notice=associated');
                    // } else {
                    this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
                    // }
                }
                catch {
                    this.props.navigate(this.props.workflowItem.paths.listPath);
                }
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });

    }
    render() {
        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    <WarningCustomForm
                        {...this.props}
                        riskTypes={this.props.riskTypes}
                        onSubmitHandler={this.submitHandler}
                        customFields={(this.props.customFields || {}).COMMUNICATION}
                        assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(WarningCreate));
