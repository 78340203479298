import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CompensationList from './List';
import CompensationCreate from './Create';
import CompensationDetail from './Detail';
import { injectIntl } from "react-intl";

const defaultFields = {
    Assumption: null,
    Instructions: null
}

class CompensationRouter extends React.Component {
    constructor(props) {
        super(props);
        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;
        this.replyPeriod = props.workflowItem.replyPeriod;

        this.notifyingParty = props.workflowItem.notifyingParty;
        this.replyingParty = props.workflowItem.replyingParty;
        if (this.replyingParty) {
            this.communicateToUser = props.globalResources[this.replyingParty + "_NAME"];
        }
        else {
            if (this.notifyingParty === "PM") {
                this.communicateToUser = props.globalResources.SUPPLIER_NAME;
            }
            else if (this.notifyingParty === "SUPPLIER" || this.notifyingParty === "CLIENT") {
                this.communicateToUser = props.globalResources.PM_NAME;
            }

        }


        if (props.mode === 'SUPPLIER') {
            this.documentType = 'Document612con';
        }
        if (props.mode === 'SHORT') {
            this.documentType = 'Document612scon';
        }
        else if (props.mode === 'CLAIM') {
            this.documentType = 'Document612CWQ';
        }
        else if (props.mode === 'PM') {
            this.documentType = 'Document611pm';
        }
        else if (props.mode === 'CLAIMWN') {
            this.documentType = 'Document611cwn';
        }
        else if (props.mode === 'CLIENTCWN') {
            this.documentType = 'Document611clientcwn';
        }
        else if (props.mode === 'CLIENT') {
            this.documentType = 'Document612client';
        }

        // #region FIND CLAUSES 
        let clausesKey = 'CompensationClauses';
        if (props.mode === 'CLAIM' || props.mode === 'CLAIMWN') {
            clausesKey = 'ClaimClauses';
        }
        else if (props.mode === 'CLIENTCWN') {
            clausesKey = 'ClientClaimWithNoticeClauses';
        }
        this.clauses = props.placeholders
            .find(ph => ph.replacementKey === clausesKey)
            .values.filter(item => item.isActive === true);
        //#endregion

        // #region FIND REPLY OPTIONS
        if (this.props.mode === 'CLAIMWN' || this.props.mode === 'CLIENTCWN') {
            if (props.placeholdersQA.ClaimWNAcceptanceOptions) {
                //if custom placeholder exist use placeholder
                this.replyOptions = props.placeholdersQA.ClaimWNAcceptanceOptions.values
                    .filter(item => item.isActive === true)
                    .map(x => ({ value: Number(x.field), display: x.display }));
            } else {
                //else use static options
                this.replyOptions = [
                    { value: 1, display: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.Accepted' }) },
                    { value: 0, display: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.Not Accepted' }) },
                ];
            }
        }
        else {

            if (props.placeholdersQA.CompensationEventsAcceptanceOptions) {
                //if custom placeholder exist use placeholder
                this.replyOptions = props.placeholdersQA.CompensationEventsAcceptanceOptions.values
                    .filter(item => item.isActive === true)
                    .map(x => ({ value: Number(x.field), display: x.display }));
            } else {
                //else use static options
                this.replyOptions = [
                    { value: 1, display: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.IS_COMPENSATION' }) },
                    { value: 0, display: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.NOT_COMPENSATION' }) },
                ];
            }
        }
        //#endregion

        this.instructionNotificationName = (this.props.workflowsQA['4'] || {}).notificationName;

        // #region EW RELATION ENABLED
        this.ewRelationEnabled = true;
        try {
            if (props.placeholdersQA.EWRelationEnabled) {
                this.ewRelationEnabled = props.placeholdersQA.EWRelationEnabled.values
                    .find(phItem => phItem.field === 'EWRelationEnabled').isActive;
            }

        } catch (error) {
            console.log('EWRelationEnabled placeholder not available!');
            this.ewRelationEnabled = true;
        }
        //#endregion

        if (this.ewRelationEnabled) {
            this.ewNotificationName = (this.props.workflowsQA['1'] || this.props.workflowsQA['2']).notificationName;
        }

        // #region TASK ORDER ENABLED
        this.taskOrderOptionEnabled = false;
        try {
            if (props.placeholdersQA.TaskOrderOptionEnabled) {
                this.taskOrderOptionEnabled = props.placeholdersQA.TaskOrderOptionEnabled.values
                    .find(phItem => phItem.field === 'TaskOrderOptionEnabled').isActive;
            }
        } catch (error) {
            console.log('TaskOrderOptionEnabled placeholder not available!');
            this.taskOrderOptionEnabled = false;
        }
        //#endregion


        if (this.taskOrderOptionEnabled) {
            this.taskOrderNotificationName = (this.props.workflowsQA['38'] || {}).notificationName;
        }

        // #region PROJECT ORDER ENABLED
        this.projectOrderOptionEnabled = false;
        try {
            if (props.placeholdersQA.ProjectOrderOptionEnabled) {
                this.projectOrderOptionEnabled = props.placeholdersQA.ProjectOrderOptionEnabled.values
                    .find(phItem => phItem.field === 'ProjectOrderOptionEnabled').isActive;
            }
        } catch (error) {
            console.log('ProjectOrderOptionEnabled placeholder not available!');
            this.projectOrderOptionEnabled = false;
        }
        //#endregion

        if (this.projectOrderOptionEnabled) {
            this.projectOrderNotificationName = (this.props.workflowsQA['72'] || {}).notificationName;
        }

        // #region FIELDS
        this.fields = { ...defaultFields };
        this.fields.Assumption = props.resources.PmAssumptionLabel;
        this.fields.Instructions = props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.INSTRUCTION' }, { PM_NAME: props.globalResources.PM_NAME, INSTRUCTION: this.instructionNotificationName });
        if (props.mode === 'CLAIMWN' || props.mode === 'CLIENTCWN') {
            this.fields.EventAwareDate = props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.AWARENESS_DATE' });
        }

        try {
            //DEFAULT
            let fieldsPH = props.placeholdersQA.Fields;
            if (props.mode === 'PM' && props.placeholdersQA.FieldsPM) {
                //OVERRIDE IF EXIST
                fieldsPH = props.placeholdersQA.FieldsPM;
            }
            else if (props.mode === 'CLIENT' && props.placeholdersQA.FieldsClient) {
                //OVERRIDE IF EXIST
                fieldsPH = props.placeholdersQA.FieldsClient;
            }
            else if (props.mode === 'CLAIM' && props.placeholdersQA.FieldsCWQ) {
                //OVERRIDE IF EXIST
                fieldsPH = props.placeholdersQA.FieldsCWQ;
            }
            else if ((props.mode === 'CLAIMWN' || props.mode === 'CLIENTCWN') && props.placeholdersQA.FieldsCWN) {
                //OVERRIDE IF EXIST
                fieldsPH = props.placeholdersQA.FieldsCWN;
            }


            if (fieldsPH) {
                //IF FIELD PH EXIST USE IT
                fieldsPH.values.forEach(fieldItem => {
                    this.fields[fieldItem.field] = fieldItem.isActive ? fieldItem.display : '';
                });
            }
        } catch (error) {
            console.log('Error loading CEN Fields Placeholder!');
        }
        //#endregion
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;

        return (
            <Routes caseSensitive>
                <Route path={workflowItem.paths.basePath} exact element={<Navigate replace to={workflowItem.paths.defaultPath} />} />
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<CompensationList
                        {...this.props}
                        fields={this.fields}
                        ewRelationEnabled={this.ewRelationEnabled}
                        ewNotificationName={this.ewNotificationName}
                        taskOrderOptionEnabled={this.taskOrderOptionEnabled}
                        taskOrderNotificationName={this.taskOrderNotificationName}
                        projectOrderOptionEnabled={this.projectOrderOptionEnabled}
                        projectOrderNotificationName={this.projectOrderNotificationName}
                        mode={this.props.mode}
                        
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        instructionNotificationName={this.instructionNotificationName}
                    />}

                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                            <CompensationCreate
                                {...this.props}
                                fields={this.fields}
                                clauses={this.clauses}
                                ewRelationEnabled={this.ewRelationEnabled}
                                ewNotificationName={this.ewNotificationName}
                                taskOrderOptionEnabled={this.taskOrderOptionEnabled}
                                mode={this.props.mode}
                                instructionNotificationName={this.instructionNotificationName}
                                taskOrderNotificationName={this.taskOrderNotificationName}
                                projectOrderOptionEnabled={this.projectOrderOptionEnabled}
                                projectOrderNotificationName={this.projectOrderNotificationName}
                                title={this.notificationName}
                                
                                communicateToUser={this.communicateToUser}
                            />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    element={<CompensationDetail
                        {...this.props}
                        replyOptions={this.replyOptions}
                        replyPeriod={this.replyPeriod}
                        fields={this.fields}
                        clauses={this.clauses}
                        ewRelationEnabled={this.ewRelationEnabled}
                        ewNotificationName={this.ewNotificationName}
                        taskOrderOptionEnabled={this.taskOrderOptionEnabled}
                        mode={this.props.mode}
                        instructionNotificationName={this.instructionNotificationName}
                        taskOrderNotificationName={this.taskOrderNotificationName}
                        projectOrderOptionEnabled={this.projectOrderOptionEnabled}
                        projectOrderNotificationName={this.projectOrderNotificationName}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                        documentType={this.documentType}
                        communicateToUser={this.communicateToUser}
                        ewAbbreviation_SUP={this.ewAbbreviation_SUP}
                        ewAbbreviation_PM={this.ewAbbreviation_PM}
                        notifyUserFilter={workflowItem.notifyUserFilter}
                        canCreateInstruction={this.canCreateInstruction}
                        contractTemplate={this.props.contractTemplate}
                    />}
                />
            </Routes>
        );
    }
};



export default injectIntl(CompensationRouter);