import React from 'react';
import { withSnackbar } from 'notistack';
import { models, service, factories } from "powerbi-client";
import { PowerBIEmbed } from 'powerbi-client-react';
import * as powerbiCrud from "../../../crud/powerbi.crud";
import * as powerBIExportCrud from "../../../../app/crud/powerBiExport.crud";
import * as powerbiBookmarkCrud from "../../../crud/powerbiBookmark.crud";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import PortletSpinner from '../../../partials/layout/PortletSpinner';
import BookmarkModal from './BookmarkModal';
import BookmarkList from './BookmarkList';
import { Button } from 'devextreme-react';

//SOURCE: https://github.com/microsoft/powerbi-client-react
//SETTINGS: https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/configure-report-settings

const isPowerBICaptureFeatureEnabled = process.env.REACT_APP_POWERBI_CAPTURE_FEATURE_ENABLED === "true";

class PowerBI extends React.Component {
    constructor(props) {
        super(props);
        this.refPowerBIEmbedded = React.createRef()
        // if (this.props.service) {
		// 	this.powerbi = this.props.service;
		// }
		// else {
		// 	this.powerbi = new service.Service(
		// 		factories.hpmFactory,
		// 		factories.wpmpFactory,
		// 		factories.routerFactory);
		// }

        const queryParams = new URLSearchParams(window.location.search);

        // Get the value of the 'id' parameter
        this.bookmarkName = queryParams.get('id');

        // this.reportContainer = "";

        // Set minutes before the access token should get refreshed
        // this.minutesToRefreshBeforeExpiration = 2;

        // Store the amount of time left for refresh token
        // this.tokenExpiration = 0;

        this.containerRef = React.createRef();
        this.state = {
            accessToken: "",
            embedUrl: "",
            reportId: "",
            type: "",
            // minutesToExpiration: 0,
            showBookmarkModal: false,
            showBookmarkList: false,
            bookmarkShowcaseState: {
                bookmarks: [],
                report: null,
            }
        };
    }
    componentDidMount(){
        this.startupActions();
    }
    startupActions = async () => {
        switch (this.props.mode) {
            case "REPORTS":
                if (isPowerBICaptureFeatureEnabled) {
                    if (this.bookmarkName) {
                        await this.fetchReports();
                        const bookmark = await this.fetchBookmarkByName(this.bookmarkName);
                        await this.loadBookmark(bookmark?.bookmarkstate);
                    } else {
                        this.embedBookmarksReport();
                    }
                } else {
                    this.fetchReports();
                }

                break;
            case "DASHBOARD":
                this.fetchDashboard();
                break;
            case "SCORECARD":
                this.fetchScoreCard();
                break;
            default:
                break;
        }
    }
    loadBookmarks = async () => {
        try {
            const resp = await powerbiBookmarkCrud.getPowerBICustomBookmarkViews(this.props.userProfile.companyid);

            const bookmarks = resp.data;
            const userSpecificBookmarks = bookmarks.filter(x => x.accountid === this.props.userProfile.accountid);
            
            this.setState(prevState => ({
                bookmarkShowcaseState: {
                    ...prevState.bookmarkShowcaseState,
                    bookmarks: userSpecificBookmarks
                }
            }));
        } catch (error) {
            console.error(error);
        }
    }
    fetchBookmarkByName = async (bookmarkName) => {
        let bookmark = null;
            
        try {
            const resp = await powerbiBookmarkCrud.getPowerBICustomBookmarkViewByName(bookmarkName);
            bookmark = resp.data;
        } catch (error) {
            console.error(error);
        }

        return bookmark;
    }
    loadBookmark = (state) => {
        
        setTimeout(() => {
            this.props.refPowerBIEmbedded.current.containerRef.current.powerBiEmbed.bookmarksManager.applyState(state);
        }, 5000);
    }
    deleteBookmark = async (id) => {
        try {
            await powerbiBookmarkCrud.deletePowerBICustomBookmarkView(id)
            .then(() => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POWERBI_DELETE_SUCCESS' }), { variant: 'success' });
                this.loadBookmarks();
            })
            .catch(err => {
                console.log(err)
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });

        } catch (error) {
            console.error(error);
        }
    }
    createExportBookmark = async (format, bookmarkState) => {
       
        const exportData = {
            reportToken: this.props.authToken.reportingToken,
            reportFormat: format,
            reportName: this.props.pbix,
            state: bookmarkState,
            // state: await captureBookmarkState() /

          };
        try {
            powerBIExportCrud.createExport(exportData)
            .then(() => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POWERBI_EXPORT_SUCCESS' }), { variant: 'success' });
                this.loadBookmarks(false);
            })
            .catch(err => {
                console.log(err)
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });

        } catch (error) {
            console.error(error);
        }
    }
    
   captureBookmarkState = async () => {
        try {
            const capturedBookmark = await this.props.refPowerBIEmbedded.current.containerRef.current.powerBiEmbed.bookmarksManager.capture({ personalizeVisuals: true });
            return capturedBookmark.state;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    bookmarkCaptured = async (bookmarkName) => {
        try {
            // Capture the report's current state with personalized visuals
            const capturedBookmark = await this.props.refPowerBIEmbedded.current.containerRef.current.powerBiEmbed.bookmarksManager.capture({ personalizeVisuals: true });
            let newBookmark = {
                bookmarkdisplayname: bookmarkName,
                bookmarkstate: capturedBookmark.state,
                companyid: this.props.userProfile.companyid
            };

            await powerbiBookmarkCrud.savePowerBICustomBookmarkView(newBookmark)
                .then(() => {
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success' });
                    this.loadBookmarks();
                })
                .catch(err => {
                    console.log(err)
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                });
        } catch (error) {
            console.error(error);
        }
    }
    embedBookmarksReport = async () => {
        await this.loadSampleReportIntoSession();

        this.loadBookmarks();
    }
    loadSampleReportIntoSession = () => {
        this.populateEmbedConfigIntoCurrentSession(false);
    }
    populateEmbedConfigIntoCurrentSession = (updateCurrentToken) => {
        try {
            let configFromParentWindow = window.parent.showcases.captureReportViews;
            
            if (configFromParentWindow) {
                // let diffMs = new Date(configFromParentWindow.expiration) - new Date();
                // let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

                //this.setState({ embedUrl: configFromParentWindow.embedUrl, accessToken: configFromParentWindow.token, reportId: configFromParentWindow.id, type: 'report', tokenType: models.TokenType.Embed, minutesToExpiration: diffMins })

                const embedConfig = {
                    EmbedUrl: configFromParentWindow.embedUrl,
                    EmbedToken: configFromParentWindow.accessToken,
                    Id: configFromParentWindow.id,
                    // MinutesToExpiration: diffMins
                };
                
                this.handleNewEmbedConfig(embedConfig, updateCurrentToken);
            }
    
            return;
        } catch (error) {
            console.error(error);
        }

        this.fetchReports(updateCurrentToken);
    }
    handleNewEmbedConfig = (embedConfig/*, updateCurrentToken*/) => {
        this.setConfig(embedConfig.EmbedToken, embedConfig.EmbedUrl, embedConfig.Id/*, embedConfig.MinutesToExpiration*/);

        // if (updateCurrentToken) {
        //     // Get the reference to the embedded element
        //     this.reportContainer = document.querySelector(".powerbi-report-class");
        //     if (this.reportContainer) {
        //         const report = this.powerbi.get(this.reportContainer);
        //         report?.setAccessToken(embedConfig.EmbedToken);
        //     }
        // }
    
        // // Get the milliseconds after token will get refreshed
        // this.tokenExpiration = this.state.minutesToExpiration * 60 * 1000;

        // // Set the tokenRefresh timer to count the seconds and request the JSON again when token expires
        // this.setTokenExpirationListener();
    }
    setConfig(accessToken, embedUrl, reportId/*, minutesToExpiration*/) {
        this.setState({ accessToken, embedUrl, reportId, type: 'report'/*, minutesToExpiration*/, tokenType: models.TokenType.Embed });
    }
    // Check the remaining time and call the API again
    // setTokenExpirationListener = () => {
    //     const safetyInterval = this.minutesToRefreshBeforeExpiration * 60 * 1000;

    //     // Time until token refresh in milliseconds
    //     //const timeout = this.tokenExpiration - safetyInterval;
    //     const timeout = 60000;
    //     //console.log(timeout);

    //     if (timeout <= 0) {
    //         this.populateEmbedConfigIntoCurrentSession(true);
    //     }
    //     else {
    //         console.log(`Report Embed Token will be updated in ${timeout} milliseconds.`);
    //         setTimeout(() => {
    //             this.populateEmbedConfigIntoCurrentSession(true);
    //         }, timeout);
    //     }
    // }
    hideBookmarkModal = () => {
        this.setState({ showBookmarkModal: false });
    }
    hideBookmarkList = () => {
        this.setState({ showBookmarkList: false });
    }
    fetchReports = async (/*updateCurrentToken*/) => {
        const resp = await powerbiCrud.getReport(this.props.pbix, this.props.authToken.reportingToken)
            
        const embedConfig = {
            EmbedUrl: resp.data.embedUrl,
            EmbedToken: resp.data.accessToken,
            Id: resp.data.id,
            // MinutesToExpiration: this.state.minutesToExpiration
        };

        this.handleNewEmbedConfig(embedConfig/*, updateCurrentToken*/);
    }
    fetchDashboard = () => {
        powerbiCrud.getDashboard(this.props.pbix, this.props.authToken.reportingToken)
            .then((resp) => {
                this.setState({ embedUrl: resp.data.embedUrl, accessToken: resp.data.accessToken, reportId: resp.data.id, type: 'dashboard', tokenType: models.TokenType.Embed })
            })
            .catch(err => {
                console.log(err)
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }
    fetchScoreCard = () => {
        powerbiCrud.getScoreCard(this.props.pbix, this.props.authToken.reportingToken)
            .then((resp) => {
                this.setState({ embedUrl: resp.data.embedUrl, accessToken: resp.data.accessToken, reportId: resp.data.id, type: 'report', tokenType: null })
            })
            .catch(err => {
                console.log(err)
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }
    render() {
        const { accessToken, embedUrl, bookmarkShowcaseState } = this.state;

        if (accessToken === "" || embedUrl === "") {
            return (
                <PortletSpinner />
            );
        }

        if (isPowerBICaptureFeatureEnabled && this.bookmarkName !== null && bookmarkShowcaseState === null) {
            return (
                <PortletSpinner />
            );
        }

        return (
            <>
                {isPowerBICaptureFeatureEnabled && (
                    <>
                        <Button
                            width='10%'
                            height='50px'
                            style={{ marginBottom: 10, marginRight: 10 }}
                            text={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_POWERBI_SAVED_VIEWS' })}
                            type="default"
                            stylingMode="contained"
                            icon='bulletlist'
                            onClick={() => {
                                this.setState({ showBookmarkList: true })
                            }}
                        />
                        <Button
                            width='10%'
                            height='50px'
                            style={{ marginBottom: 10 }}
                            text={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_POWERBI_CAPTURE_VIEW' })}
                            type="default"
                            stylingMode="contained"
                            icon='plus'
                            onClick={() => {
                                this.setState({ showBookmarkModal: true })
                            }}
                        />
                    </>
                )}
                <PowerBIEmbed
                    ref={this.props.refPowerBIEmbedded}
                    embedConfig={{
                        type: this.state.type, //'report',   // Supported types: report, dashboard, tile, visual and qna
                        id: this.state.reportId,
                        embedUrl: this.state.embedUrl,
                        accessToken: this.state.accessToken,
                        tokenType: this.state.tokenType,
                        pageName:this.props.pageName,
                        datasetBinding: {
                            datasetId: `${process.env.REACT_APP_POWERBI_DATASET}`
                        },
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                },
                                pageNavigation: {
                                    visible: false
                                },
                                // localeSettings: {
                                //     language: "en",
                                //     formatLocale: "es"
                                // }

                            },
                            // layoutSettings
                            // background: models.BackgroundType.Transparent,

                        },
                        // contrastMode: models.ContrastMode.HighContrastWhite,
                        // theme: { themeJson: EmbeddedReportingLightTheme }
                    }}

                    eventHandlers={
                        new Map([
                            ['loaded', function () { /*console.log('Report loaded');*/ }],
                            ['rendered', function () { /*console.log('Report rendered');*/ }],
                            ['error', function (event) { console.log(event.detail); }]
                        ])
                    }

                    cssClassName={"powerbi-report-class"}

                    getEmbeddedComponent={(embeddedReport) => {
                        this.report = embeddedReport;
                    }}
                />
                {
                    this.state.showBookmarkModal && (
                        <BookmarkModal
                            {...this.props}
                            visible={this.state.showBookmarkModal}
                            onClose={this.hideBookmarkModal}
                            onBookmarkCaptured={this.bookmarkCaptured}
                        />
                    )
                }
                {
                    this.state.showBookmarkList && (
                        <BookmarkList
                            bookmarks={this.state.bookmarkShowcaseState.bookmarks}
                            visible={this.state.showBookmarkList}
                            onClose={this.hideBookmarkList}
                            onLoadBookmark={this.loadBookmark}
                            onDeleteBookmark={this.deleteBookmark}
                            onCreateExportBookmark={this.createExportBookmark}
                        />
                    )
                }
            </>
        )
    }
}

const mapStateToProps = store => ({
    userProfile: store.auth.userProfile,
    authToken: store.auth.authToken
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(PowerBI)));