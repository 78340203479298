import React from 'react';
import { connect } from "react-redux";
import { Portlet, PortletBody, PortletFooter, PortletHeader } from "../../../../partials/content/Portlet";
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import { withSnackbar } from 'notistack';
import * as projectsCrud from '../../../../crud/projects.crud';
import * as companiesDuck from '../../../../store/ducks/companies.duck';
import * as projectsDuck from '../../../../store/ducks/projects.duck';
import * as projectDuck from '../../../../store/ducks/project.duck';
import { FormattedMessage, injectIntl } from "react-intl";
import { confirm } from 'devextreme/ui/dialog';
import { Alert } from "react-bootstrap";
import moment from 'moment';
import { Button } from 'devextreme-react/button';
import Form from 'devextreme-react/form';

class ProjectOverview extends React.Component {
  constructor(props) {
    super(props);
    this.recordStatuses = [
      { valueExpr: 1, displayExpr: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.ACTIVE' }) },
      { valueExpr: 2, displayExpr: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.COMPLETED' }) },
      { valueExpr: 3, displayExpr: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.ARCHIVED' }) },
    ]
    this.state = {
      project: null,
      isBusy: true
    };

  }
  componentDidMount() {
    this.props.loadCompanies();
    this.setState({ isBusy: true }, this.setDefaultValues)
  }
  setDefaultValues = async () => {

    this.setState({
      project: {
        ...this.props.project,
        lastLoadedEpochTime: new Date().getTime(),
      },
      isBusy: false
    });
  }
  deleteProject = async () => {
    this.setState({ isBusy: true }, async () => {
      try {
        let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "PROJECT.MODAL.DELETE.MESSAGE" })}</div>`, this.props.intl.formatMessage({ id: "PROJECT.MODAL.DELETE.TITLE" }));
        if (result) {
          await projectsCrud.deleteProject(this.props.project.projectid);
          this.setState({ project: { ...this.state.project, deleted: 1, recordstatusdeleteddate: new Date(), lastLoadedEpochTime: new Date().getTime() } });
          this.props.projectsDuckSetPending();
          this.props.fetchProject(this.props.project.projectid);
          this.props.enqueueSnackbar('Project deleted', { variant: 'success', });
        }
      } catch (err) {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      }
      finally {
        this.setState({ isBusy: false });
      }
    })
  }
  undeleteProject = async () => {
    this.setState({ isBusy: true }, async () => {
      try {
        let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "PROJECT.MODAL.UNDELETE.MESSAGE" })}</div>`, this.props.intl.formatMessage({ id: "PROJECT.MODAL.UNDELETE.TITLE" }));
        if (result) {
          await projectsCrud.restoreDeletedProject(this.props.project.projectid);
          this.setState({ project: { ...this.state.project, deleted: 0, lastLoadedEpochTime: new Date().getTime() } });
          this.props.projectsDuckSetPending();
          this.props.fetchProject(this.props.project.projectid);
          this.props.enqueueSnackbar('Project restored', { variant: 'success', });
        }
      } catch (err) {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      }
      finally {
        this.setState({ isBusy: false });
      }
    })
  }
  completeProject = async () => {
    this.setState({ isBusy: true }, async () => {
      try {
        let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "PROJECT.MODAL.COMPLETE.MESSAGE" })}</div>`, this.props.intl.formatMessage({ id: "PROJECT.MODAL.COMPLETE.TITLE" }));
        if (result) {
          await projectsCrud.completeProject(this.props.project.projectid);
          this.setState({ project: { ...this.state.project, recordstatus: 2, recordstatuscompleteddate: new Date(), lastLoadedEpochTime: new Date().getTime() } });
          this.props.projectsDuckSetPending();
          this.props.fetchProject(this.props.project.projectid);
          this.props.enqueueSnackbar('Project completed', { variant: 'success', });
        }
      } catch (err) {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      }
      finally {
        this.setState({ isBusy: false });
      }
    })
  }
  restoreCompletedProject = async () => {
    this.setState({ isBusy: true }, async () => {
      try {
        let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "PROJECT.MODAL.UNCOMPLETE.MESSAGE" })}</div>`, this.props.intl.formatMessage({ id: "PROJECT.MODAL.UNCOMPLETE.TITLE" }));
        if (result) {
          await projectsCrud.restoreCompletedProject(this.props.project.projectid);
          this.setState({ project: { ...this.state.project, recordstatus: 1, lastLoadedEpochTime: new Date().getTime() } });
          this.props.projectsDuckSetPending();
          this.props.fetchProject(this.props.project.projectid);
          this.props.enqueueSnackbar('Project restored from completed', { variant: 'success', });
        }
      } catch (err) {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      }
      finally {
        this.setState({ isBusy: false });
      }
    })
  }
  archiveProject = async () => {
    this.setState({ isBusy: true }, async () => {
      try {
        const response = await projectsCrud.getRelatedActiveContractCount(this.props.project.projectid);
        let promptMessage = "";
        if (response.data > 0) {
          promptMessage = this.props.intl.formatMessage({ id: "PROJECT.MODAL.ARCHIVE.MESSAGE" }, { COUNT: response.data.toString() });
        }
        else {
          promptMessage = this.props.intl.formatMessage({ id: "PROJECT.MODAL.ARCHIVE.MESSAGE2" })
        }
        let result = await confirm(`<div style="max-width:50vw;">${promptMessage}</div>`, this.props.intl.formatMessage({ id: "PROJECT.MODAL.ARCHIVE.TITLE" }));
        if (result) {
          await projectsCrud.archiveProject(this.props.project.projectid);
          this.setState({ project: { ...this.state.project, recordstatus: 3, recordstatusarchiveddate: new Date(), lastLoadedEpochTime: new Date().getTime() } });
          this.props.projectsDuckSetPending();
          this.props.fetchProject(this.props.project.projectid);
          this.props.enqueueSnackbar('Project archived', { variant: 'success', });
        }
      } catch (err) {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      }
      finally {
        this.setState({ isBusy: false });
      }
    })
  }
  restoreArchivedProject = async () => {
    this.setState({ isBusy: true }, async () => {
      try {
        const response = await projectsCrud.getRelatedArchivedContractCount(this.props.project.projectid);

        let promptMessage = ""
        if (response.data > 0) {
          promptMessage = this.props.intl.formatMessage({ id: "PROJECT.MODAL.UNARCHIVE.MESSAGE" }, { COUNT: response.data.toString() });
        }
        else {
          promptMessage = this.props.intl.formatMessage({ id: "PROJECT.MODAL.UNARCHIVE.MESSAGE2" })
        }
        let result = await confirm(`<div style="max-width:50vw;">${promptMessage}</div>`, this.props.intl.formatMessage({ id: "PROJECT.MODAL.UNARCHIVE.TITLE" }));
        if (result) {
          await projectsCrud.restoreArchivedProject(this.props.project.projectid);
          this.setState({ project: { ...this.state.project, recordstatus: 1, lastLoadedEpochTime: new Date().getTime() } });
          this.props.projectsDuckSetPending();
          this.props.fetchProject(this.props.project.projectid);
          this.props.enqueueSnackbar('Project restored from archive', { variant: 'success', });
        }
      } catch (err) {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      }
      finally {
        this.setState({ isBusy: false });
      }
    })
  }
  submitHandler = (project, setSubmitting) => {
    projectsCrud.saveProject(project)
      .then(response => {
        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "PROJECT.OVERVIEW.UPDATE.SUCCESS" }), { variant: 'success', });
        this.props.projectsDuckSetPending();
        this.props.fetchProject(project.projectid);
        setSubmitting(false);
        //this.props.navigate('/admin/projects');
      })
      .catch(err => {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        setSubmitting(false);
      });
  }

  render() {

    if (!this.props.companies || !this.state.project) {
      return (<PortletSpinner />);
    }
    const {allowChangeState} = this.props.protectedRouteProps;
    const updateProjectFormConfig = {
      buttonText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_UPDATE" }),
      fields: [
        {
          resource: 'PROJECT.FIELD.STATUS',
          dataField: 'recordstatus',
          editorType: 'dxSelectBox',
          editorOptions: {
            readOnly: true,
            dataSource: this.recordStatuses,
            displayExpr: 'displayExpr',
            valueExpr: 'valueExpr',
            buttons: [
              {
                location: 'after',
                name: 'complete',
                options: {
                  text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_COMPLETE" }),
                  type: "success",
                  stylingMode: "outlined",
                  disabled: this.state.isBusy,
                  onClick: this.completeProject,
                  visible: (this.state.project.deleted === 0 && [1].includes(this.state.project.recordstatus) && allowChangeState)
                }
              },
              {
                location: 'after',
                name: 'uncomplete',
                options: {
                  text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_RESTORE_ACTIVE" }),
                  type: "success",
                  stylingMode: "outlined",
                  disabled: this.state.isBusy,
                  onClick: this.restoreCompletedProject,
                  visible: (this.state.project.deleted === 0 && [2].includes(this.state.project.recordstatus) && allowChangeState)
                }
              },
              {
                location: 'after',
                name: 'archive',
                options: {
                  text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_ARCHIVE" }),
                  type: "danger",
                  stylingMode: "outlined",
                  disabled: this.state.isBusy,
                  onClick: this.archiveProject,
                  visible: (this.state.project.deleted === 0 && [1, 2].includes(this.state.project.recordstatus) && allowChangeState)
                }
              },
              {
                location: 'after',
                name: 'unarchive',
                options: {
                  text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_RESTORE_ARCHIVE" }),
                  type: "danger",
                  stylingMode: "outlined",
                  disabled: this.state.isBusy,
                  onClick: this.restoreArchivedProject,
                  visible: (this.state.project.deleted === 0 && [3].includes(this.state.project.recordstatus) && allowChangeState)
                }
              },
              {
                location: 'after',
                name: 'delete',
                options: {
                  text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_DELETE" }),
                  type: "danger",
                  stylingMode: "contained",
                  disabled: this.state.isBusy,
                  onClick: this.deleteProject,
                  visible: (this.state.project.deleted === 0 && allowChangeState)
                }
              }
            ]
          },

        },
        { resource: "PROJECT.FIELD.NAME", dataField: 'name' },
        { resource: "PROJECT.FIELD.COMPANY", dataField: 'company', editorOptions: { readOnly: true } },
        { resource: "PROJECT.FIELD.TYPE", dataField: 'projecttype', required: false },//, editorType: 'dxSelectBox', editorOptions: { dataSource: projectTypes } 
        { resource: "PROJECT.FIELD.CODE", dataField: 'projectcode' },
        // { label: 'Address 1', dataField: 'address1' },
        // { label: 'Address 2', dataField: 'address2', required: false },
        // { label: 'City', dataField: 'city' },
        // { label: 'County', dataField: 'state' },
        // { label: 'Postcode', dataField: 'postcode' },
        // { label: 'Country', dataField: 'country' },
      ]
    };
    return (

      <Portlet>
        <PortletHeader title={this.props.intl.formatMessage({ id: "PROJECT.OVERVIEW.TITLE" })} />
        <PortletBody>
          {this.state.project.deleted === 1 && (
            <Alert variant="warning" style={{ display: 'block', margin: 20 }}>
              <Alert.Heading><FormattedMessage id="PROJECT.ALERT.DELETED.TITLE" /></Alert.Heading>
              <hr />
              <p>
                <FormattedMessage id="PROJECT.ALERT.DELETED.MESSAGE" values={{ DATE: moment(this.state.project.recordstatusdeleteddate).format('DD/MM/YYYY') }} />
              </p>
              {allowChangeState && (
                <>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <Button
                      disabled={false}
                      type="normal"
                      stylingMode="outlined"
                      icon="revert"
                      text={'Undelete (Restore) Project'}
                      onClick={this.undeleteProject}
                    />
                  </div>
                </>
              )}
            </Alert>
          )}
          {
            (this.state.project.recordstatuscompleteddate || this.state.project.recordstatusarchiveddate || this.state.project.recordstatusdeleteddate) && (
              <Form
                id="form"
                style={{ marginBottom: 10 }}
                labelMode="floating"
                formData={this.state.project}
                readOnly={true}
                showColonAfterLabel={true}
                labelLocation="left"
                minColWidth={200}
                colCount={3}
                width="100%"
                items={[
                  { label: { text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.COMPLETE_DATE" }), }, dataField: 'recordstatuscompleteddate', itemType: 'simple', editorType: 'dxDateBox', editorOptions: { displayFormat: 'dd/MM/yyyy HH:mma', placeholder: 'No Complete Date' } },
                  { label: { text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.ARCHIVE_DATE" }), }, dataField: 'recordstatusarchiveddate', itemType: 'simple', editorType: 'dxDateBox', editorOptions: { displayFormat: 'dd/MM/yyyy HH:mma', placeholder: 'No Archive Date' } },
                  { label: { text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.DELETE_DATE" }), }, dataField: 'recordstatusdeleteddate', itemType: 'simple', editorType: 'dxDateBox', editorOptions: { displayFormat: 'dd/MM/yyyy HH:mma', placeholder: 'No Delete Date' } }
                ]}
              />
            )
          }
          <BiDynamicForm
            key={this.state.project.lastLoadedEpochTime}
            item={this.state.project}
            config={updateProjectFormConfig}
            onSubmitHandler={this.submitHandler}
            customFields={this.state.project.customfields}
            disabled={(this.state.project.deleted === 1 || this.state.isBusy)}
            buttonDisabled={(this.state.project.recordstatus === 3 || this.state.isBusy)}
          />
        </PortletBody>
        <PortletFooter />
      </Portlet>
    );
  }
}



const mapStateToProps = store => ({
  companies: store.companies.companies,
  project: store.project.project,
  userProfile: store.auth.userProfile
});

const mapDispatchToProps = {
  fetchProject: projectDuck.actions.fetchStart,
  loadCompanies: companiesDuck.actions.fetchStart,
  projectsDuckSetPending: projectsDuck.actions.setPending
}


export default injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ProjectOverview)));
