import React from 'react';
import { withSnackbar } from 'notistack';
import * as projectsCrud from '../../../crud/projects.crud';
import * as customFieldCrud from '../../../crud/customField.crud';
import * as companiesDuck from '../../../store/ducks/companies.duck';
import * as projectsDuck from '../../../store/ducks/projects.duck';
import { connect } from "react-redux";
import BiDynamicForm from "../../../partials/layout/BiDynamicForm";
import PortletSpinner from '../../../partials/layout/PortletSpinner';
import { Portlet, PortletBody, PortletHeader } from "../../../partials/content/Portlet";
import { injectIntl } from "react-intl";
import { withRouter } from '../../../shared/hoc/withRouter';
class ProjectNew extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            customFields: []
        }
    }
    componentDidMount() {
        this.props.loadCompanies();
    }

    submitHandler = (project, setSubmitting) => {
        projectsCrud.saveProject(project)
            .then(response => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "PROJECT.CREATE.SUCCESS" }), { variant: 'success', });
                this.props.projectsDuckSetPending();

                try {
                    const createdID = Number(response.data);
                    this.props.navigate('/admin/projects/' + createdID + '/overview');
                }
                catch {
                    this.props.navigate('/admin/projects');
                }
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                setSubmitting(false);
            });
    }
    onFieldChanged = (dataField, value) => {
        if (dataField === "companyid") {
            this.fetchCustomFields(value);
        }
    }
    fetchCustomFields = async (companyid) => {
        const result = await customFieldCrud.getCustomFieldByCompany(companyid, "Project");
        if (result.data && result.data.length > 0) {
            this.setState({ customFields: result.data[0].fields });
        }
        else{
            this.setState({ customFields: [] });
        }
    }
    render() {
        if (!this.props.companies) {
            return (<PortletSpinner />);
        }

        const newProjectFormConfig = {
            buttonText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CREATE" }),
            fields: [
                { label: this.props.intl.formatMessage({ id: "PROJECT.FIELD.NAME" }), dataField: 'name' },
                { label: this.props.intl.formatMessage({ id: "PROJECT.FIELD.COMPANY" }), dataField: 'companyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.companies, valueExpr: 'companyid', displayExpr: 'name' } },
                { label: this.props.intl.formatMessage({ id: "PROJECT.FIELD.TYPE" }), dataField: 'projecttype', required: false }, //, editorType: 'dxSelectBox', editorOptions: { dataSource: projectTypes, defaultValue: 'Development' }
                { label: this.props.intl.formatMessage({ id: "PROJECT.FIELD.CODE" }), dataField: 'projectcode' },

            ]
        };

        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: "PROJECT.CREATE.TITLE" })} />
                <PortletBody style={{ maxWidth: 800 }}>
                    <BiDynamicForm
                        config={newProjectFormConfig}
                        valueChangeHandler={this.onFieldChanged}
                        onSubmitHandler={this.submitHandler}
                        customFields={this.state.customFields}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    companies: store.companies.companies,
});

const mapDispatchToProps = {
    loadCompanies: companiesDuck.actions.fetchStart,
    projectsDuckSetPending: projectsDuck.actions.setPending
}


export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ProjectNew))));
