/* eslint-disable no-useless-rename, react/no-direct-mutation-state */
import React from 'react';
import { SelectBox, TagBox, TextBox, Button, TextArea, CheckBox, DateBox, HtmlEditor, NumberBox, ValidationSummary, ValidationGroup, Switch, DropDownBox, RadioGroup } from 'devextreme-react';
import Box, { Item } from 'devextreme-react/box';
import sanitizeHtml from 'sanitize-html-react';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import BiDynamicAssetFields from './BiDynamicAssetFields';
import BiDynamicPriceDateFields from './BiDynamicPriceDateFields';
import { withSnackbar } from 'notistack';
import Common from '../../shared/common';
import WebLinkEditorModal from './dxWebLinkForm';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { htmlSenitizeOptions, dxHtmlEditorProps, dxSelectBoxProps, dxDateBoxProps, dxNumberBoxProps, dxTextBoxProps, dxTextAreaProps, dxTagBoxProps, dxDropDownBoxProps, dxRadioGroupProps } from '../../shared/config/DxFormProperties';
import {
    Validator,
    RequiredRule,
    EmailRule,
    PatternRule,
    StringLengthRule,
    RangeRule,
    CustomRule
} from 'devextreme-react/validator';

const cf_dx_FiltTypeMaping = {
    TEXT_SINGLE: "dxTextBox",
    TEXT_MULTI: "dxTextArea",
    SELECTBOX: "dxSelectBox",
    MULTI_SELECT: "dxTagBox",
    CHECKBOX: "dxCheckBox",
    EMAIL: "dxTextBox",
    DATE: "dxDateBox",
    DATETIME: "dxDateBox",
    NUMBER: "dxNumberBox",
    INTEGER: "dxNumberBox",
    CURRENCY: "dxNumberBox",
    SINGLE_CONTRACT_USER: "dxSelectBox",
    MULTIPLE_CONTRACT_USERS: "dxTagBox",
    WEB_LINK: "dxTextBox"
}

class BiDynamicForm extends React.Component {
    constructor(props) {
        super(props);
        this.isContractOtherDatesEnabled = Boolean(this.props.contract?.section);
        this.isContractOtherPricesEnabled = Boolean(this.props.contract?.otherprice) && process.env.REACT_APP_SECONDARY_PRICES_ENABLED === "true";;

        this.validatorRef = props.validatorRef ? props.validatorRef : React.createRef()
        this.validatorName = props.validatorName ? props.validatorName : props.config.buttonText ? props.config.buttonText.replace(/\s/g, "") : 'BiValidator';
        let initialItem = {}
        if (props.item) {
            initialItem = { ...props.item }
        }
        //const isCustomFieldsEnabled = process.env.REACT_APP_CUSTOM_FIELDS_ENABLED === "true";
        const mappedCustomFields = this.mapCustomFields(initialItem);
        const defaultValuedFields = props.config.fields.filter(field => field.editorOptions && field.editorOptions.defaultValue);
        const valuedFields = props.config.fields.filter(field => field.hasOwnProperty('value'));
        const dependentFields = props.config.fields.filter(field => field.hasOwnProperty('dependOn'));
        const visibleFields = props.config.fields.filter(field => field.hasOwnProperty('visible'));
        const groups = props.config.fields.filter(field => field.hasOwnProperty('fields'));

        if (groups && groups.length > 0) {
            groups.forEach(group => {
                const subDefaultValuedFields = group.fields.filter(field => field.editorOptions && field.editorOptions.defaultValue);
                if (subDefaultValuedFields && subDefaultValuedFields.length > 0) {
                    defaultValuedFields.push(...subDefaultValuedFields);
                }
                const subValuedFields = group.fields.filter(field => field.hasOwnProperty('value'));
                if (subValuedFields && subValuedFields.length > 0) {
                    valuedFields.push(...subValuedFields);
                }
                const subDependentFields = group.fields.filter(field => field.hasOwnProperty('dependOn'));
                if (subDependentFields && subDependentFields.length > 0) {
                    dependentFields.push(...subDependentFields);
                }
                const subVisibleFields = group.fields.filter(field => field.hasOwnProperty('visible'));
                if (subVisibleFields && subVisibleFields.length > 0) {
                    visibleFields.push(...subVisibleFields);
                }
            });
        }

        this.valuedFields = valuedFields.length > 0 ? valuedFields : (props.config.fields?.flatMap(item =>
            item.horizontalfields?.filter(field => field.hasOwnProperty('value')) || [])).filter(field => field !== null && field !== undefined);
        this.dependentFields = dependentFields;
        this.visibleFields = visibleFields;

        const values = this.valuedFields.reduce((obj, fieldItem) => ({ ...obj, [fieldItem.dataField]: fieldItem.value({ ...initialItem, ...obj }) }), {});
        const defaultValues = defaultValuedFields.reduce((obj, fieldItem) => ({ ...obj, [fieldItem.dataField]: fieldItem.editorOptions.defaultValue }), {});
        defaultValues.assetid = props.assetid;

        this.state = { item: { ...defaultValues, ...initialItem, ...values }, isSubmitting: false, customFields: mappedCustomFields, showWebLinkModal: false, webLinkEditingDataField: null, webLinkEditingValue: null };
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextState !== this.state ||
            nextProps.config !== this.props.config ||
            nextProps.active !== this.props.active ||
            nextProps.secondaryButtonActive !== this.props.secondaryButtonActive ||
            nextProps.customFields !== this.props.customFields) {
            return true;
        }
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.customFields !== this.props.customFields) {
            const newCustomFields = this.mapCustomFields();
            //reomve previour custom field values
            for (let key in this.state.item) {
                if (key.startsWith("CF_")) {
                    delete this.state.item[key];
                }
            }
            this.setState({ customFields: newCustomFields });
        }
    }
    mapCustomFields = (initialItem) => {
        const isCustomFieldsEnabled = process.env.REACT_APP_CUSTOM_FIELDS_ENABLED === "true";
        let mappedCustomFields = [];
        if (isCustomFieldsEnabled) {
            const contractUsersIds = this.props.contractUsers ? this.props.contractUsers.map(u => u.accountid) : null;
            mappedCustomFields = (this.props.customFields || []).filter(cf => cf.isactive).map(cf => {
                if (initialItem) {
                    let customFieldValue = (initialItem.customfieldvalues || []).find(cfv => cfv.customfieldid === cf.customfieldid);
                    ///FILTER IF A USER IS NOT INCLUDED IN CURRENT CONTRACT USER LIST
                    if (customFieldValue && customFieldValue.value && customFieldValue.value !== undefined && contractUsersIds) {
                        if (cf.fieldtype === "SINGLE_CONTRACT_USER") {
                            const user = JSON.parse(customFieldValue.value);
                            customFieldValue.value = contractUsersIds.includes(user.id) ? customFieldValue.value : null;
                        }
                        else if (cf.fieldtype === "MULTIPLE_CONTRACT_USERS") {
                            const users = JSON.parse(customFieldValue.value);
                            const filteredUsersIds = users.map(u => u.id).filter(id => contractUsersIds.includes(id));
                            const filteredUsers = filteredUsersIds.map(i => {
                                const { id: id, name: name } = users.find(obj => obj.id === i) || {};
                                return { id, name };
                            });
                            customFieldValue.value = JSON.stringify(filteredUsers);
                        }
                    }
                    ///IF VALUE EXIST SET THE INITIAL ITEM FIELD
                    if (customFieldValue) {
                        if (cf.fieldtype === "CHECKBOX") {
                            initialItem["CF_" + cf.customfieldid] = (customFieldValue.value === 'true');
                        }
                        else if (cf.fieldtype === "SINGLE_CONTRACT_USER") {
                            if (customFieldValue.value && customFieldValue.value !== undefined) {
                                const user = JSON.parse(customFieldValue.value);
                                initialItem["CF_" + cf.customfieldid] = user.id;
                            }
                        }
                        else if (cf.fieldtype === "MULTIPLE_CONTRACT_USERS") {
                            if (customFieldValue.value && customFieldValue.value !== undefined) {
                                const users = JSON.parse(customFieldValue.value);
                                initialItem["CF_" + cf.customfieldid] = users.map(u => u.id);
                            }
                        }
                        else if (cf.fieldtype === "MULTI_SELECT") {
                            initialItem["CF_" + cf.customfieldid] = customFieldValue.value.split('|');
                        }
                        else if (cf.fieldtype === "NUMBER") {
                            initialItem["CF_" + cf.customfieldid] = Number(customFieldValue.value);
                        }
                        else if (cf.fieldtype === "INTEGER") {
                            initialItem["CF_" + cf.customfieldid] = Number(customFieldValue.value);
                        }
                        else if (cf.fieldtype === "CURRENCY") {
                            initialItem["CF_" + cf.customfieldid] = Number(customFieldValue.value);
                        }
                        else {
                            initialItem["CF_" + cf.customfieldid] = customFieldValue.value;
                        }
                    }
                }

                //DEFINE EDITOR OPTIONS
                let editorOpts = {}
                if (cf.fieldtype === "SELECTBOX" || cf.fieldtype === "MULTI_SELECT") {
                    editorOpts.dataSource = (cf.selectoptions || "").split('|');
                    editorOpts.dataSource.sort((a, b) => a > b ? 1 : -1)
                }
                if (cf.fieldtype === "NUMBER") {
                    editorOpts.format = "#,##0.00";
                    editorOpts.placeholder = new Intl.NumberFormat('en-GB', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(0)
                }
                if (cf.fieldtype === "INTEGER") {
                    editorOpts.format = "#,##0";
                    editorOpts.placeholder = new Intl.NumberFormat('en-GB', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    }).format(0)
                }
                if (cf.fieldtype === "CURRENCY") {
                    let curCode = (this.props.contract || {}).currency ? this.props.contract.currency : 'GBP';
                    editorOpts.placeholder = Common.FormatCurrencyNum(0, curCode);
                    editorOpts.format = { style: "currency", currency: curCode, useGrouping: true, currencyDisplay: 'narrowSymbol' };//currencySymbol + ' #,##0.00',
                }
                if (cf.fieldtype === "DATETIME") {
                    editorOpts.type = "datetime";
                    editorOpts.placeholder = 'DD/MM/YYYY HH:MM';
                    editorOpts.displayFormat = 'dd/MM/yyyy HH:MM';
                    editorOpts.pickerType = 'native';
                }
                if (cf.fieldtype === "TEXT_SINGLE") {
                    if (cf.maxlength) {
                        editorOpts.maxLength = cf.maxlength;
                    }
                }
                if (cf.fieldtype === "TEXT_MULTI") {
                    if (cf.maxlength) {
                        editorOpts.maxLength = cf.maxlength;
                    }
                }
                if (cf.fieldtype === "SINGLE_CONTRACT_USER" || cf.fieldtype === "MULTIPLE_CONTRACT_USERS") {
                    //if (!cf.selectoptions) {
                    const users = this.props.contractUsers ? this.props.contractUsers.map(u => ({ value: u.accountid, display: u.user })) : null;
                    editorOpts.dataSource = users;
                    editorOpts.valueExpr = 'value';
                    editorOpts.displayExpr = 'display';
                    //} else {
                    //    editorOpts.dataSource = (cf.selectoptions || "").split('|');
                    //}

                    if (cf.fieldtype === "MULTIPLE_CONTRACT_USERS") {
                        editorOpts.placeholder = this.props.intl.formatMessage({ id: "WATERMARK.SELECT_USERS" });
                    }
                }
                if (cf.fieldtype === "WEB_LINK") {
                    editorOpts.placeholder = this.props.intl.formatMessage({ id: "GENERAL.FORMS.LINK_PH" });
                    editorOpts.readOnly = true;
                    editorOpts.showClearButton = true;
                    editorOpts.buttons = [
                        {
                            location: 'after',
                            name: 'MyClear',
                            options: {
                                icon: 'clear',
                                hint: this.props.intl.formatMessage({ id: "GENERAL.FORMS.CLEAR" }),
                                type: "normal",
                                disabled: false,
                                stylingMode: "contained",
                                onClick: this.onClearWebLinkClick.bind(this, "CF_" + cf.customfieldid),
                            }
                        },
                        {
                            location: 'after',
                            name: 'Edit Link',
                            options: {
                                text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.EDIT_LINK" }),
                                type: "normal",
                                stylingMode: "contained",
                                disabled: false,
                                onClick: this.onEditWebLinkClick.bind(this, "CF_" + cf.customfieldid),
                            }
                        },
                    ];
                }

                const returnItem = {
                    editorType: cf_dx_FiltTypeMaping[cf.fieldtype],
                    dataField: "CF_" + cf.customfieldid,
                    label: cf.label,
                    editorOptions: editorOpts,
                    required: cf.ismandatory
                };
                //Define Validation Property, Only if necessary
                if (cf.fieldtype === "TEXT_SINGLE" || cf.fieldtype === "TEXT_MULTI") {
                    if (cf.maxlength) {
                        returnItem.validation = { type: "string", max: cf.maxlength }
                    }
                }
                return returnItem;
            }
            )
        }
        return mappedCustomFields;
    }
    submitHandler = (e) => {
        e.preventDefault();
        if (!this.props.minValueCheck) {
            this.setState({ isSubmitting: true }, () => {
                this.props.onSubmitHandler({ ...this.state.item }, this.setSubmitting, this.clearForm);
            });
        } else {
            this.props.onSubmitHandler({ ...this.state.item }, this.setSubmitting, this.clearForm);
        }
    }
    setSubmitting = (isSubmitting) => {
        return new Promise((resolve, reject) => {
            this.setState({ isSubmitting: isSubmitting }, () => {
                resolve();
            });
        });
    }

    clearForm = () => {
        const emptyItem = {};
        for (const prp in this.state.item) {
            emptyItem[prp] = null;
        }
        this.setState({ item: emptyItem }, () => { this.validatorRef.current.instance.reset(); });
    }
    selectboxItemTemplate = (displayProperty, data) => {
        return "<div style={{ position: 'relative', minHeight: '30px', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title='" + data[displayProperty] + "'>" + data[displayProperty] + "</div>";
    }
    generateFieldItem = (item, index) => {
        if (item.hasOwnProperty('visible')) {
            let isVisible = true;
            if (typeof item.visible == "boolean") {
                isVisible = item.visible;
            }
            else if (typeof item.visible === 'function') {
                isVisible = item.visible(this.state.item);
            }
            if (isVisible === false) {
                return;
            }
        }
        if (item.resource) {
            item.label = this.props.intl.formatMessage({ id: item.resource })
        }
        //#region HANDLE GROUP ITEM
        if (item.fields) {
            return (
                <div key={'Group' + index} style={{ marginTop: index === 0 ? 0 : 20 }}>
                    {item.label &&
                        (<div className="dx-fieldset-header" style={{ borderBottom: '1px solid #ddd' }}>
                            <span className="dx-widget dx-form-group-caption" style={{ marginBottom: 5 }}>
                                {item.label}
                            </span>
                        </div>)}
                    {item.groupHorizontalDisplay ?
                        <div key={'Group' + item.label} style={{ display: 'flex', flexDirection: 'row', height: '5vh' }}>
                            {
                                item.fields.map((item, index2) =>
                                    <div key={index2} className="dx-field" style={{ marginLeft: "30px", width: "50%" }}>
                                        <div className="dx-field-value" style={{ width: "100%" }}>
                                            {
                                                this.generateFieldItem(item, index.toString() + index2.toString())
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        : <div className="dx-widget dx-form-group-content dx-form-group-with-caption dx-form-group-content">
                            {
                                item.fields.map((item, index2) => this.generateFieldItem(item, index.toString() + index2.toString()))
                            }
                        </div>}
                </div>
            );
        }
        //#endregion

        if (item.hasOwnProperty('note')) {
            if (item.note) {
                return (
                    <div key={'Note' + index} style={{ marginTop: index === 0 ? 0 : 20 }}>
                        <div className="dx-fieldset-header" style={{ borderBottom: '1px solid #ddd' }}>
                            <span className="dx-widget dx-form-group-caption" style={{ marginBottom: 5 }}>
                                {item.note}
                            </span>
                        </div>
                    </div>
                );
            }
            else return null;

        }
        if (item.hasOwnProperty('rendered')) {
            if (item.rendered) {
                return (
                    <div key={'Rendered' + index} style={{ marginTop: index === 0 ? 0 : 20 }}>
                        {item.rendered}
                    </div>
                );
            }
            else return null;
        }

        //#region editorType
        let editorType = 'dxTextBox';
        if (item.hasOwnProperty('editorType')) {
            editorType = item.editorType;
        }
        else if (item.hasOwnProperty('render')) {
            editorType = null;
        }
        //#

        //#region EDITOR OPTIONS
        let editorOptions = {};
        //editorOptions.inputAttr = { "autocomplete": "new-user-phone" };
        if (editorType) {
            if (editorType === 'dxTextBox') {
                editorOptions = { ...dxTextBoxProps }
                editorOptions.onChange = this.onValueChanged;
                editorOptions.value = this.state.item[item.dataField];
            }
            else if (editorType === 'dxSelectBox') {
                editorOptions = { ...dxSelectBoxProps }
                editorOptions.searchExpr = (item.editorOptions || {}).displayExpr;
                editorOptions.onValueChanged = this.onValueChanged;

                //ADDITIONAL CHECK ON ContentReady
                //Check if Value assigned is an available option, If not clear the value on load
                // editorOptions.onContentReady = (e)=>{
                //     const value = e.component.option("value");
                //     const selectedItem = e.component.option("selectedItem");
                //     if(value && !selectedItem){
                //         console.log("SelectedItem not found!",value,selectedItem,item.dataField);
                //         e.component.clear()
                //     }
                // };

                editorOptions.value = this.state.item[item.dataField];
                //if displayExpr assigned give default itemTemplate for all selectboxes
                if (item.editorOptions?.displayExpr) {
                    editorOptions.itemTemplate = this.selectboxItemTemplate.bind(this, item.editorOptions.displayExpr);
                }
            }
            else if (editorType === 'dxTagBox') {
                editorOptions = { ...dxTagBoxProps }
                editorOptions.searchExpr = (item.editorOptions || {}).displayExpr;
                editorOptions.onValueChanged = this.onValueChanged;
                editorOptions.value = this.state.item[item.dataField];
                editorOptions.placeholder = this.props.intl.formatMessage({ id: "WATERMARK.CHOOSE_TAGS" })
            }
            else if (editorType === 'dxHtmlEditor') {
                editorOptions = { ...dxHtmlEditorProps };
                editorOptions.toolbar.items[4].options = { placeholder: this.props.intl.formatMessage({ id: "GENERAL.WORDS.COLOR" }) };
                editorOptions.toolbar.items[5].options = { placeholder: this.props.intl.formatMessage({ id: "GENERAL.WORDS.BACKGROUND" }) };
                editorOptions.ref = React.createRef();
                editorOptions.onValueChanged = this.onHtmlValueChanged.bind(this, editorOptions.ref);
                editorOptions.onFocusOut = this.onValueChanged;
                editorOptions.elementAttr = { id: item.dataField };
                if (this.state.item[item.dataField]) {
                    const cleanHTML = sanitizeHtml(this.state.item[item.dataField], { ...htmlSenitizeOptions });
                    this.state.item[item.dataField] = cleanHTML;
                }
            }
            else if (editorType === 'dxDateBox') {
                editorOptions = { ...dxDateBoxProps }
                editorOptions.onValueChanged = this.onValueChanged;
                editorOptions.placeholder = this.props.intl.formatMessage({ id: "WATERMARK.DATE_PICKER" });
            }
            //item.currency is for multiple horizontal currency fields ex:payment wizard page
            //this.state.item.currency is to support other currencies ex:payment page
            //this.props.contract.currency is the default contract currency
            else if (editorType === 'dxNumberBox') {
                editorOptions = { ...dxNumberBoxProps(item.currency || (this.state.item && this.state.item.currency) || (this.props.contract || {}).currency) }
                editorOptions.onValueChanged = this.onValueChanged;
            }
            else if (editorType === 'dxSwitch') {
                editorOptions = {}
                editorOptions.onValueChanged = this.onValueChanged;
            }
            else if (editorType === 'dxTextArea') {
                editorOptions = { ...dxTextAreaProps }
                editorOptions.onValueChanged = this.onValueChanged;
            }
            else if (editorType === 'dxCheckBox') {
                editorOptions = {
                    // enableThreeStateBehavior: true,
                    defaultValue: null,
                }
                editorOptions.value = this.state.item[item.dataField];
                editorOptions.onValueChanged = this.onValueChanged;
            }
            else if (editorType === 'dxDropDownBox') {
                editorOptions = { ...dxDropDownBoxProps }
                editorOptions.onValueChanged = this.onValueChanged;
            }
            else if (editorType === 'dxRadioGroup') {
                editorOptions = { ...dxRadioGroupProps }
                editorOptions.onValueChanged = this.onValueChanged;
                editorOptions.elementAttr = { id: item.dataField };
            }
        }
        if (item.hasOwnProperty('editorOptions')) {
            editorOptions = { ...editorOptions, ...item.editorOptions };
        }
        editorOptions.defaultValue = this.state.item[item.dataField];
        const title = this.props.config.buttonText ? this.props.config.buttonText : 'editor-form';
        //editorOptions.inputAttr = { "autocomplete": title.replace(' ', '-') + "-" + item.dataField };
        if (editorType !== 'dxTextBox') {
            editorOptions.inputAttr = { "autocomplete": "off", "id": item.dataField };
        } else {
            editorOptions.inputAttr = { "autocomplete": title.replace(' ', '-') + "-" + item.dataField, "id": item.dataField };
        }
        if (item.hasOwnProperty('value')) {
            editorOptions.value = this.state.item[item.dataField];
            editorOptions.readOnly = true;
        }
        if (item.hasOwnProperty('dependOn')) {
            editorOptions.value = this.state.item[item.dataField];
        }
        //#endregion

        //#region dataField
        let dataField = null;
        if (item.hasOwnProperty('dataField')) {
            dataField = item.dataField;
        }
        //#endregion

        //#region component
        let component = null;
        if (editorType) {
            if (editorType === 'dxTextBox') {
                component = TextBox;
            }
            else if (editorType === 'dxSelectBox') {
                component = SelectBox;
            }
            else if (editorType === 'dxTagBox') {
                component = TagBox;
            }
            else if (editorType === 'dxDateBox') {
                component = DateBox;
            }
            else if (editorType === 'dxHtmlEditor') {
                component = HtmlEditor;
            }
            else if (editorType === 'dxNumberBox') {
                component = NumberBox;
            }
            else if (editorType === 'dxSwitch') {
                component = Switch;
            }
            else if (editorType === 'dxTextArea') {
                component = TextArea;
            }
            else if (editorType === 'dxCheckBox') {
                component = CheckBox;
            }
            else if (editorType === 'dxDropDownBox') {
                component = DropDownBox;
            }
            else if (editorType === 'dxRadioGroup') {
                component = RadioGroup;
            }
        }
        else if (item.hasOwnProperty('render')) {
            component = item.render;
        }
        //#endregion

        //#region children
        let children = [];
        if (!item.hasOwnProperty('render')) {
            if (!item.hasOwnProperty('required') || item.required === true) {

                //Required rule is custom if its a Checkbox
                //Unchecked state returns invalis if we use regular RequiredRule of Devex
                if (editorType === 'dxCheckBox' && !item.hasOwnProperty('validation')) {
                    children.push(
                        <CustomRule
                            key="Sub7"
                            type="custom"
                            validationCallback={(e) => {
                                return (e.value === true || e.value === false);
                            }}
                            message={item.label + ' ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.VALIDATION.REQUIRED' })}
                        />
                    );
                }
                //Add required rule for all fields by default exceot dxSwitch
                else if (editorType !== 'dxSwitch') {
                    children.push(
                        <RequiredRule key="Sub1" message={item.label + ' ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.VALIDATION.REQUIRED' })} />
                    );
                }

            }
            if (item.hasOwnProperty('validation')) {
                if (item.validation.type === 'email') {
                    children.push(
                        <EmailRule key="Sub2" message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.VALIDATION.INVALID_EMAIL' })} />
                    );
                }
                else if (item.validation.type === 'pattern') {
                    children.push(
                        <PatternRule key="Sub3" message={item.validation.warning} pattern={item.validation.pattern} />
                    );
                }
                else if (item.validation.type === 'range') {
                    children.push(
                        <RangeRule key="Sub4" min={item.validation.min} max={item.validation.max} message={item.validation.warning} />
                    );
                }
                else if (item.validation.type === 'string') {
                    children.push(
                        <StringLengthRule key="Sub5" min={item.validation.min} max={item.validation.max} message={item.validation.warning} />
                    );
                }
                else if (item.validation.type === 'custom') {
                    children.push(
                        <CustomRule
                            key="Sub6"
                            type="custom"
                            validationCallback={(e) => {
                                return item.validation.validationCallback(this.state.item, e.value, e);
                            }}
                            message={item.validation.warning}
                        />
                    );
                }
            }


            if (children.length > 0) {
                children = [(
                    <Validator key={item.dataField + 'Validator'}>
                        {children}
                    </Validator>
                )];
            }
        }
        //#endregion

        let label = null;
        if (item.labelType === 'rendered') {
            label = item.label;
        }
        else {
            label = item.label + ':'
        }

        if (item.hasOwnProperty('horizontallabels')) {
            // Display labels horizontally
            return (
                <div key={index.toString()} style={{ marginTop: index === 0 ? 0 : 20 }}>
                    <Box direction="row" width="100%">
                        <Item ratio={0} baseSize={200}>
                            <div className="dx-field-label" style={{ width: '100%', whiteSpace: 'normal' }} />
                        </Item>
                        {item.horizontallabels.map((label, index2) => (
                            <Item key={index2} ratio={1} baseSize={0}>
                                <div className="dx-field-value" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div>{label}</div>
                                </div>
                            </Item>
                        ))}
                    </Box>
                </div>
            );
        }

        if (item.horizontalrow) {
            return (
                <div key={'Group' + index} style={{ marginTop: index === 0 ? 0 : 20 }}>
                    <div className="dx-field" style={{ width: '100%' }}>
                        <Box direction="row" width="100%">
                            {
                                item.label && (
                                    <Item ratio={0} baseSize={200}>
                                        <div className="dx-field-label" style={{ width: '100%', whiteSpace: 'normal' }}>
                                            {label}
                                        </div>
                                    </Item>
                                )
                            }
                            {
                                <Item ratio={1} baseSize={0}>
                                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                                        <div className="dx-field-value" style={{ display: 'flex', width: '100%', gap: '1rem', justifyContent: 'space-evenly' }}>
                                            {
                                                item.horizontalfields.map((item, index2) =>
                                                    <div key={index2}>
                                                        {
                                                            this.generateFieldItem(item, index.toString() + index2.toString())
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </Item>
                            }
                        </Box>
                    </div>
                </div>
            );
        }

        if (item.horizontalfield) {
            return (
                <div key={`ColumnItem${item.dataField}`}>
                    {React.createElement(
                        component,
                        {
                            ...editorOptions,
                            name: item.dataField,
                            width: `100%`,
                            inputAttr: { "autocomplete": "off", "id": item.dataField }
                        },
                        children
                    )}
                </div>
            )
        }

        if (component) {
            let fieldClass = "dx-field";
            if (this.props.disabled) {
                fieldClass += " dx-state-disabled";
            }
            return (
                <div className={fieldClass} key={index.toString()} >
                    <Box direction="row" width="100%">
                        <Item ratio={0} baseSize={200}>
                            <div className="dx-field-label" style={{ width: '100%', whiteSpace: 'normal' }}>
                                {label}
                            </div>
                        </Item>
                        <Item ratio={1} baseSize={0}>
                            <div style={{ width: item.hasOwnProperty("tooltipResource") ? "97%" : "100%", height: '100%', display: 'flex', alignItems: 'center' }}>
                                {/* //, flexDirection: 'row'  */}
                                <div className="dx-field-value" onPaste={editorType === 'dxHtmlEditor' ? (e) => this.onHtmlPaste(e, editorOptions.ref) : null} style={{ width: '100%', display: 'flex', alignItems: 'flex-start' }}>
                                    {React.createElement(
                                        component,
                                        {
                                            ...editorOptions,
                                            name: dataField,
                                        },
                                        children
                                    )}
                                </div>
                                {item.hasOwnProperty("tooltipResource") && (
                                    <OverlayTrigger delay={{ show: 0, hide: 400 }} overlay={<Tooltip>{this.props.intl.formatMessage({ id: item.tooltipResource })}</Tooltip>}>
                                        <div style={{ width: '3%', display: 'flex', alignItems: 'center' }}><i id="helpedInfo" className="dx-icon dx-icon-info align-middle ml-2" style={{ fontSize: '20px' }}></i></div>
                                    </OverlayTrigger>
                                )}
                            </div>
                        </Item>
                    </Box>
                </div>
            );
        }
    }
    onHtmlPaste = (e, ref) => {
        // this.pasteValue = (e.clipboardData || window.clipboardData).getData('text');

        // if (!this.pasteValue) {
        //     // console.log(e.clipboardData);
        //     // console.log(this.pasteValue);
        //     e.clipboardData = null;
        //     e.stopPropagation();
        //     e.preventDefault();
        //     return false
        // }
        // console.log('pasted', paste);
        // const value = ref.current.instance.option('value');
        // // console.log(value, ref.current.instance._$element);
        // const position = ref.current.instance._$element[0].children[2].selectionStart;
        // var range = window.getSelection().getRangeAt(0);
        // // var preCaretRange = range.cloneRange();
        // // preCaretRange.selectNodeContents(ref.current.instance._$submitElement[0]);
        // // preCaretRange.setEnd(range.endContainer, range.endOffset);
        // // const caretOffset = preCaretRange.toString().length;

        // // const position2 =  window.getSelection().anchorNode;//ref.current.instance._$submitElement[0].getSelection();
        // // const foc = ref.current.instance.focus();
        // console.log('position:', position);
        // console.log('position2:', range);
        // if (value && position) {
        //     const finalValue = value.substring(0, position) + paste + value.substring(position);
        //     ref.current.instance.option('value', finalValue);
        // }

        // console.log(e.target.selectionStart );
        // this.isPastingValue = true;
        // console.log(ref.current.instance._$element[0].children);
        // const selection = ref.current.instance.getSelection();
        // console.log(selection);
        //ref.current.instance._$element[0].children[2].selectionStart
        // ref.current.instance.insertText(5, paste, {});
        // e.preventDefault();
        // ref.current.instance.
    }
    onHtmlValueChanged = (ref, e) => {
        // const selection = e.component.getSelection()
        const clipboardData = ((e.event || {}).originalEvent || {}).clipboardData;
        if (clipboardData) {
            ref.current.instance.removeFormat(0, e.element.innerText.length + 100);
            // const pastedPlainText = clipboardData.getData('text');            
        }
        // if (this.pasteValue) {
        //     ref.current.instance.beginUpdate();
        //     const plainPasteValue = this.pasteValue;
        //     this.pasteValue = null;

        //     if (e.previousValue && e.previousValue.length > 0) {
        //         // if (isIE) {
        //         ref.current.instance.removeFormat(0, e.element.innerText.length);
        //         // }
        //         // else {
        //         //     let insertIndex = 0;
        //         //     for (let charIndex = 0; charIndex < e.previousValue.length; charIndex++) {
        //         //         if (e.previousValue[charIndex] !== e.value[charIndex]) {
        //         //             if (e.previousValue[charIndex - 1] === '<') {
        //         //                 insertIndex = charIndex - 1;
        //         //                 break;
        //         //             }
        //         //             else {
        //         //                 insertIndex = charIndex;
        //         //                 break;
        //         //             }

        //         //         }
        //         //     }
        //         //     const dummyValue = e.previousValue.substring(0, insertIndex) + '{|x|}';
        //         //     ref.current.instance.option('value', dummyValue);
        //         //     // console.log('e.element.innerText:', e.element.innerText.length, e.element.innerText.replace(/(\n\n)/gm, " ").replace(/(\n)/gm, ""));
        //         //     const insertPosition = e.element.innerText.replace(/(\n\n)/gm, " ").replace(/(\n)/gm, "").indexOf('{|x|}'); //
        //         //     // console.log('insertPosition:', insertPosition);
        //         //     ref.current.instance.option('value', e.previousValue);
        //         //     ref.current.instance.insertText(insertPosition, plainPasteValue, {});//plainPasteValue
        //         // }
        //     }
        //     else {
        //         ref.current.instance.option('value', '');
        //         ref.current.instance.insertText(0, plainPasteValue, {});
        //     }
        //     ref.current.instance.endUpdate();
        // }
        else {
            if (e.value) {
                if (e.value.includes('<img')) {
                    ref.current.instance.undo();
                    // htmlStr = htmlStr.replace(/<img([\s\S]*?)<\/img>/gi, '');
                }
            }
        }
        this.onValueChanged(e);
    }
    //dataFieldI, valueI can be passed by subcomponents such as Assets
    onValueChanged = (e, dataFieldS, valueS, additionalData, forceUpdate) => {
        let isDxFormValid = null;
        if (!this.props.onSubmitHandler && this.validatorRef.current) {
            isDxFormValid = this.validatorRef.current.instance.validate().isValid;
        }

        const dataField = dataFieldS ? dataFieldS : e.component._options._optionManager._options.name;
        const value = dataFieldS ? valueS : e.component._options._optionManager._options.value;
        const selectedItem = additionalData ? additionalData : e?.component?._options?._optionManager?._options?.selectedItem;

        if (this.state.item[dataField] !== value) {
            let stateUpdateNecessary = (!!e && !!e.component && (e.component.NAME === 'dxSelectBox' || e.component.NAME === 'dxTagBox' || e.component.NAME === 'dxTextBox' || e.component.NAME === 'dxDropDownBox' || e.component.NAME === 'dxDateBox' || e.component.NAME === 'dxCheckBox')) || forceUpdate ? true : false;
            const initialStateCopy = { ...this.state.item };
            this.state.item[dataField] = value;

            if (this.props.valueChangeHandler) {
                this.props.valueChangeHandler(dataField, value, isDxFormValid, selectedItem);
            }

            const dependentFields = this.dependentFields.filter(field => field.dependOn === dataField && field.dataField !== dataField);
            if (dependentFields && dependentFields.length > 0) {
                dependentFields.forEach(field => {
                    let newDependentValue = null;
                    if (field.hasOwnProperty("dependOnValue")) {
                        newDependentValue = field.dependOnValue(this.state.item, selectedItem);;
                    }
                    this.state.item[field.dataField] = newDependentValue;
                    if (this.props.valueChangeHandler) {
                        this.props.valueChangeHandler(field.dataField, newDependentValue, isDxFormValid, selectedItem);
                    }
                    stateUpdateNecessary = true;
                });
            }
            this.valuedFields.forEach(field => {
                const fieldInitialVal = this.state.item[field.dataField];
                this.state.item[field.dataField] = field.value(this.state.item);
                if (fieldInitialVal !== this.state.item[field.dataField]) {
                    if (this.props.valueChangeHandler) {
                        this.props.valueChangeHandler(field.dataField, this.state.item[field.dataField], isDxFormValid, selectedItem);
                    }
                    stateUpdateNecessary = true;
                }
            });

            /// Check for visibility change if not decided to update state yet!!
            // if (!stateUpdateNecessary) {
            this.visibleFields.forEach(field => {
                if (typeof field.visible === 'function') {
                    const initialVisibleStatus = field.visible(initialStateCopy);
                    const newVisibleStatus = field.visible(this.state.item);
                    if (initialVisibleStatus !== newVisibleStatus) {
                        stateUpdateNecessary = true;
                        //IF FIELD IS NO LONGER VISIBLE
                        //CLEAR THE VALUE FROM STATE OBJ
                        if (this.state.item[field.dataField] && !newVisibleStatus) {
                            this.state.item[field.dataField] = null;
                            if (this.props.valueChangeHandler) {
                                this.props.valueChangeHandler(field.dataField, null, isDxFormValid, selectedItem);
                            }
                        }
                    }
                }
            });
            // }

            if (stateUpdateNecessary === true) {
                this.setState({ item: { ...this.state.item } });
            }
        }

    }



    onEditWebLinkClick = (dataField) => {
        let webLinkData = this.state.item ? this.state.item[dataField] : null;
        this.setState({
            showWebLinkModal: true,
            webLinkEditingDataField: dataField,
            webLinkEditingValue: webLinkData,
        });
    }
    onClearWebLinkClick = (dataField) => {
        this.onValueChanged(null, dataField, null, null, true);
    }


    onWebLinkModalResponse = (response, value) => {
        const editingWebLinkDataField = this.state.webLinkEditingDataField;
        this.setState({ showWebLinkModal: false, webLinkEditingDataField: null, webLinkEditingValue: null }, () => {
            if (response === 'OK') {
                this.onValueChanged(null, editingWebLinkDataField, value, null, true);
            }
        });
    }

    generateUniqueName = () => {
        return this.props.config.buttonText.replace(/\s/g, "");
    }

    generateSubmitForm = () => {
        return (
            <form id='InitialSubmitForm' autoComplete='off' onSubmit={this.submitHandler}>
                <div className="form">
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div className="dx-fieldset" style={{ margin: 0 }}>
                            {
                                this.props.assetFieldsEnabled && (
                                    <BiDynamicAssetFields onValueChanged={this.onValueChanged} assetid={this.props.assetid} />
                                )
                            }
                            {
                                this.props.config.fields.map((item, index) => this.generateFieldItem(item, index))
                            }
                            {
                                ((this.state.customFields || []).length > 0) && (
                                    <div>
                                        <div key="CustomFieldGroup" style={{ marginTop: 20 }}>
                                            <div className="dx-fieldset-header" style={{ borderBottom: '1px solid #ddd' }}>
                                                <span className="dx-widget dx-form-group-caption" style={{ marginBottom: 5 }}>
                                                    Custom Fields
                                                </span>
                                            </div>
                                            <div className="dx-widget dx-form-group-content dx-form-group-with-caption dx-form-group-content"  >
                                                {

                                                    this.state.customFields.map((item, indexcf) => this.generateFieldItem(item, item.dataField))

                                                }

                                            </div>
                                        </div>
                                        <WebLinkEditorModal
                                            visible={this.state.showWebLinkModal}
                                            onDialogResult={this.onWebLinkModalResponse}
                                            item={this.state.webLinkEditingValue}
                                        />
                                    </div>
                                )
                            }
                            {
                                (this.props.secondaryPriceSectionEnabled || this.props.secondaryDateSectionEnabled) && (
                                    <BiDynamicPriceDateFields
                                        isReply={this.props.isReply}
                                        contract={this.props.contract}
                                        onValueChanged={this.onValueChanged}
                                        item={this.props.item}
                                        priceDateSectionFilters={this.props.priceDateSectionFilters}
                                        interimQuotesAllowed={this.props.interimQuotesAllowed}
                                        secondaryPriceSectionEnabled={this.props.secondaryPriceSectionEnabled}
                                        secondaryDateSectionEnabled={this.props.secondaryDateSectionEnabled}
                                    />
                                )
                            }
                            <hr />
                            <div>
                                <div>
                                    <ValidationSummary elementAttr={{ align: "right" }} id={this.validatorName}></ValidationSummary>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '10px' }}>
                                    {!this.props.openLi ?
                                        <Button
                                            id="InitialSubmit"
                                            type="default"
                                            elementAttr={{ name: "InitialSubmit" }}
                                            stylingMode="outlined"
                                            text={this.props.config.buttonResource ? this.props.intl.formatMessage({ id: this.props.config.buttonResource }) : this.props.config.buttonText}
                                            useSubmitBehavior={true}
                                            disabled={(this.state.isSubmitting || this.props.disabled || this.props.buttonDisabled)}
                                            style={{ marginLeft: "10px" }}
                                            icon={this.state.isSubmitting ? 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--dark' : null}
                                        /> :
                                        <button
                                            id="InitialSubmit"
                                            className={this.state.isSubmitting ? 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--dark' : "dx-widget dx-button dx-button-mode-outlined dx-button-default dx-button-has-text"}
                                            style={{ marginLeft: "10px", padding: "7px 18px 8px" }}
                                            disabled={(this.state.isSubmitting || this.props.disabled || this.props.buttonDisabled)}
                                            type="submit">
                                            {this.props.config.buttonResource ? this.props.intl.formatMessage({ id: this.props.config.buttonResource }) : this.props.config.buttonText}
                                        </button>
                                    }

                                    {this.props.secondaryButtonEnabled ? <Button
                                        id="SecondarySubmit"
                                        type="default"
                                        elementAttr={{ name: "SecondarySubmit" }}
                                        stylingMode="outlined"
                                        onClick={this.props.secondaryButtonHandler}
                                        text={this.props.secondaryButtonActive ? this.props.secondaryButtonDeactiveText : this.props.secondaryButtonActiveText}
                                        style={this.props.secondaryButtonActive ? this.props.secondaryButtonDeactiveStyle : this.props.secondaryButtonActiveStyle}
                                    /> : null}
                                    {this.props.thirdButtonEnabled ? <Button
                                        id="ThirdSubmit"
                                        type="default"
                                        elementAttr={{ name: "ThirdSubmit" }}
                                        stylingMode="outlined"
                                        onClick={this.props.thirdButtonHandler}
                                        text={this.props.thirdButtonText}
                                    /> : null}
                                </div>
                            </div>

                        </div>
                    </ValidationGroup>
                </div>
            </form>
        );
    }
    generateForm = () => {
        return (
            <div className="form">
                <div className="dx-fieldset" style={{ margin: 0 }}>
                    {
                        this.props.assetFieldsEnabled && (
                            <BiDynamicAssetFields onValueChanged={this.onValueChanged} assetid={this.state.item.assetid} />
                        )
                    }
                    {
                        this.props.config.fields.map((item, index) => this.generateFieldItem(item, index))
                    }
                    {
                        ((this.state.customFields || []).length > 0) && (
                            <div>
                                <div key="CustomFieldGroup" style={{ marginTop: 20 }}>
                                    <div className="dx-fieldset-header" style={{ borderBottom: '1px solid #ddd' }}>
                                        <span className="dx-widget dx-form-group-caption" style={{ marginBottom: 5 }}>
                                            Custom Fields
                                        </span>
                                    </div>
                                    <div className="dx-widget dx-form-group-content dx-form-group-with-caption dx-form-group-content">
                                        {
                                            this.state.customFields.map((item, indexcf) => this.generateFieldItem(item, item.dataField))
                                        }
                                    </div>
                                </div>
                                <WebLinkEditorModal
                                    visible={this.state.showWebLinkModal}
                                    onDialogResult={this.onWebLinkModalResponse}
                                    item={this.state.webLinkEditingValue}
                                />
                            </div>
                        )
                    }
                    {
                        (this.props.secondaryPriceSectionEnabled || this.props.secondaryDateSectionEnabled) && (
                            <BiDynamicPriceDateFields
                                isReply={this.props.isReply}
                                contract={this.props.contract}
                                onValueChanged={this.onValueChanged}
                                item={this.props.item}
                                priceDateSectionFilters={this.props.priceDateSectionFilters}
                                interimQuotesAllowed={this.props.interimQuotesAllowed}
                                secondaryPriceSectionEnabled={this.props.secondaryPriceSectionEnabled}
                                secondaryDateSectionEnabled={this.props.secondaryDateSectionEnabled}
                            />
                        )
                    }
                </div>
            </div>
        );
    }
    render() {
        if (this.props.onSubmitHandler) {
            return this.generateSubmitForm();
        }
        else {
            return this.generateForm();
        }

    }
}
const mapStateToProps = store => ({
    contract: store.contract.contract,
    contractUsers: store.contract.users
});

export default withSnackbar(injectIntl(connect(mapStateToProps)(BiDynamicForm)));