import React from 'react';
import { connect } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material';
import { Portlet, PortletBody, PortletHeader } from "../../../partials/content/Portlet";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";
import PortletSpinner from '../../../partials/layout/PortletSpinner';
import TableActionItems from './widgets/TableActionItems';
import NotifyArea from './widgets/NotifyArea';
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";

const theme = createTheme({
    typography: { fontSize: 20 },
});

class ContractRagReport extends React.Component {
    constructor(props) {
        super(props);
        this.printDivRef = React.createRef();
    }


    printHandler = () => {
        this.printerRef.handlePrint();
    }
    renderToolbar = () => {
        return (
            <div style={{ display: 'flex', flexFlow: 'row-reverse', margin: "20px 0px" }}>
                <Button onClick={this.printHandler} variant="primary" size="sm">
                    <i className="fas fa-print"></i>
                    <FormattedMessage id="CONTRACT.DASHBOARD.PRINT_REPORT" />
                </Button>
            </div>
        );
    }
    render() {
        const { sidePanel, contractDashboard, contract, contractTemplate } = this.props;
        if (!sidePanel || !contractDashboard || !contractTemplate) {
            return (<PortletSpinner />);
        }

        const { resources } = contractTemplate;


        return (
            <Portlet ref={this.printDivRef}>
                <PortletHeader
                    title={`${this.props.intl.formatMessage({ id: 'CONTRACT.RAG.TITLE' })} - ${contract.name} - ${moment().format('L')}`}
                    toolbar={this.renderToolbar()} />
                <ReactToPrint
                    ref={el => (this.printerRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printDivRef.current}
                />
                <PortletBody style={{ maxWidth: 1200 }}>
                    <ThemeProvider theme={theme}>
                        <TableActionItems />
                        <NotifyArea
                            title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.OUTSTANDING_PARTY' }, { PARTY: resources.SUPPLIER_NAME })}
                            items={sidePanel.supplierItems}
                            contractDashboard={contractDashboard}
                            contractid={contract.contractid}
                        />
                        <NotifyArea
                            title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.OUTSTANDING_PARTY' }, { PARTY: resources.PM_NAME })}
                            items={sidePanel.pmItems}
                            contractDashboard={contractDashboard}
                            contractid={contract.contractid}
                        />
                        {
                            contractTemplate.parties.supervisorExist && (
                                <NotifyArea
                                    title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.OUTSTANDING_PARTY' }, { PARTY: resources.SUPERVISOR_NAME })}
                                    items={sidePanel.supervisorItems}
                                    contractDashboard={contractDashboard}
                                    contractid={contract.contractid}
                                />
                            )
                        }
                    </ThemeProvider>
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    contractDashboard: store.contract.dashboard,
    contractTemplate: store.contract.template,
    sidePanel: store.contract.sidePanel,
});

export default injectIntl(connect(mapStateToProps)(ContractRagReport));