/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import { injectIntl } from "react-intl";
import * as taskOrderCrud from '../../../../crud/taskOrder.crud';
import * as projectOrderCrud from '../../../../crud/projectOrder.crud';
import DataSource from 'devextreme/data/data_source';
import Spinner from '../../../../partials/layout/Spinner';

class AssessmentCustomForm extends React.Component {
    constructor(props) {
        super(props);

        this.createDefaultSubject = (((props.location || {}).state || {}).itemCustomProps || {}).subject;
        this.createDefaultAsset = (((props.location || {}).state || {}).itemCustomProps || {}).assetid;

        this.validatorRef = props.validatorRef ? props.validatorRef : React.createRef();

        this.state = {
            isDxFormValid: true,
            taskOrderOptions: null,
            projectOrderOptions: null,
            isLoading: true,
            dateSectionFilters: [],
            showSecondaryPriceSection: !!this.props.item?.effectcontractprice,
            showSecondaryDateSection: !!this.props.item?.effectcontractcompletion
        };
    }

    componentDidMount() {
        if (this.props.taskOrderOptionEnabled) {
            this.fetchTaskOrders();
        }
        if (this.props.projectOrderOptionEnabled) {
            this.fetchProjectOrders();
        }
    }

    fetchTaskOrders = async () => {
        try {
            const { contractid } = this.props.contract;
            const { data: responseObj } = await taskOrderCrud.getTaskOrdersToSelectList(contractid);
            //COVERTED TO DATASOURCE WITH FD-11007 DUE TO PERFORMENCE ISSUES AFTER 3K ITEMS
            const taskOrderOptions = new DataSource({
                store: {
                    type: 'array',
                    data: responseObj,
                    key: 'value'
                }
            });
            this.setState({ taskOrderOptions });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    fetchProjectOrders = async () => {
        try {
            const { contractid } = this.props.contract;
            const { data: responseObj } = await projectOrderCrud.getProjectOrdersToSelectList(contractid);
            const projectOrderOptions = responseObj.map(TO => ({
                value: TO.projectorderid,
                reference: TO.referenceid,
                display: `${TO.referenceid} - ${TO.subject}`
            }));
            this.setState({ projectOrderOptions: projectOrderOptions });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        this.state.isDxFormValid = isDxFormValid;

        if (field) {
            if (field === "effectcontractprice") {
                this.setState({ showSecondaryPriceSection: value })
                if (this.props.valueChangeHandler) {
                    this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);
                }
            }
            else if (field === "effectcontractcompletion") {
                this.setState({ showSecondaryDateSection: value })
                if (this.props.valueChangeHandler) {
                    this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);
                }
            }
            else if (field === 'taskorderid') {
                if (value) {
                    const newItem = { sourcedmodel: "TaskOrder", sourcedrecordid: value };

                    this.setState(prevState => ({
                        dateSectionFilters: [...prevState.dateSectionFilters, newItem]
                    }));
                } else {
                    this.setState(prevState => ({
                        dateSectionFilters: prevState.dateSectionFilters.filter(
                            item => (item.sourcedmodel !== 'TaskOrder')
                        )
                    }));
                }

                if (this.props.valueChangeHandler) {
                    this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);

                    if (field === 'taskorderid') {
                        if (value) {
                            this.props.valueChangeHandler('taskorderid', value, isDxFormValid, additionalData);
                            if (additionalData) {
                                this.props.valueChangeHandler('taskorderreference', additionalData.referenceid, isDxFormValid, additionalData);
                            }
                        }
                        else {
                            this.props.valueChangeHandler('taskorderid', value, isDxFormValid, additionalData);
                            this.props.valueChangeHandler('taskorderreference', "", isDxFormValid, additionalData);
                        }
                    }
                }
            } else if (field === 'projectorderid') {
                if (value) {
                    const newItem = { sourcedmodel: "ProjectOrder", sourcedrecordid: value };

                    this.setState(prevState => ({
                        dateSectionFilters: [...prevState.dateSectionFilters, newItem]
                    }));
                } else {
                    this.setState(prevState => ({
                        dateSectionFilters: prevState.dateSectionFilters.filter(
                            item => (item.sourcedmodel !== 'ProjectOrder')
                        )
                    }));
                }

                if (this.props.valueChangeHandler) {
                    this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);

                    if (field === 'projectorderid') {
                        if (value) {
                            this.props.valueChangeHandler('projectorderid', value, isDxFormValid, additionalData);
                            if (additionalData) {
                                this.props.valueChangeHandler('projectorderreference', additionalData.referenceid, isDxFormValid, additionalData);
                            }
                        }
                        else {
                            this.props.valueChangeHandler('projectorderid', value, isDxFormValid, additionalData);
                            this.props.valueChangeHandler('projectorderreference', "", isDxFormValid, additionalData);
                        }
                    }
                }
            } else {
                if (this.props.valueChangeHandler) {
                    this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);
                }
            }
        }
    }

    render() {
        if ((this.props.taskOrderOptionEnabled && !this.state.taskOrderOptions) ||
            (this.props.projectOrderOptionEnabled && !this.state.projectOrderOptions)) {
            return (<Spinner />);
        }

        const createFormConfig = {
            currencyCode: this.props.contract.currency,
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { label: this.props.fields.Subject.display, dataField: 'subject', editorOptions: { defaultValue: this.createDefaultSubject } },

            ]
        };
        if (this.props.fields.Type?.isActive) {
            createFormConfig.fields.push(
                { label: this.props.fields.Type.display, dataField: 'type', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.clauses, valueExpr: 'display', displayExpr: 'display' } },
            )
        }
        if (this.props.fields.Description?.isActive) {
            createFormConfig.fields.push(
                { label: this.props.fields.Description.display, dataField: 'description', editorType: 'dxHtmlEditor' },
            )

            if (this.props.taskOrderOptionEnabled) {
                //AUTO-SELECT TASK ORDER DROPDOWN (IF REDIRECTED FROM CREATE-RELATED BUTTON)
                let defaultTaskOrderSelection = null;
                if (this.createDefaultFields?.itemBaseProps?.reftype === 'TaskOrder') {
                    defaultTaskOrderSelection = Number(this.createDefaultFields.itemBaseProps.refid);
                }

                createFormConfig.fields.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.TASK_ORDER' }, { TASK_ORDER: this.props.taskOrderNotificationName }), dataField: 'taskorderid', editorType: 'dxSelectBox', required: false, editorOptions: { dataSource: this.state.taskOrderOptions, valueExpr: 'taskorderid', displayExpr: 'display', defaultValue: defaultTaskOrderSelection } });
            }

            if (this.props.projectOrderOptionEnabled) {
                //AUTO-SELECT PROJECT ORDER DROPDOWN (IF REDIRECTED FROM CREATE-RELATED BUTTON)
                let defaultProjectOrderSelection = null;
                if (this.createDefaultFields?.itemBaseProps?.reftype === 'ProjectOrder') {
                    defaultProjectOrderSelection = Number(this.createDefaultFields.itemBaseProps.refid);
                }

                createFormConfig.fields.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PROJECT_ORDER' }, { PROJECT_ORDER: this.props.projectOrderNotificationName }), dataField: 'projectorderid', editorType: 'dxSelectBox', required: false, editorOptions: { dataSource: this.state.projectOrderOptions, valueExpr: 'value', displayExpr: 'display', defaultValue: defaultProjectOrderSelection } });
            }

            if (this.props.fields.Amount && this.props.fields.EffectContractPrice && this.props.fields.Amount.isActive) {
                createFormConfig.fields.push({
                    label: this.props.fields.EffectContractPrice.display,
                    dataField: 'effectcontractprice',
                    editorType: 'dxCheckBox',
                    editorOptions: { readOnly: !this.props.fields.EffectContractPrice.isActive },
                    dependOn: 'type',
                    dependOnValue: (item, selectedItem) => {
                        if (!this.props.fields.EffectContractPrice.isActive) {
                            //Always use the effect
                            return ["EFFECT_PRICE", "EFFECT_COMPLETION_PRICE"].includes(selectedItem.effect);
                        }
                        else if (selectedItem && selectedItem.effect && (item.effectcontractprice === null || item.effectcontractprice === undefined)) {
                            //ONLY ASSIGN DEFAULTS IF NOT ASSIGNED BEFORE
                            return ["EFFECT_PRICE", "EFFECT_COMPLETION_PRICE"].includes(selectedItem.effect);
                        } else {
                            //return whats ASSIGNED BEFORE
                            return item.effectcontractprice;
                        }
                    }
                },
                    { label: this.props.fields.Amount.display, dataField: 'amount', editorType: 'dxNumberBox' },
                )
            }
            if (this.props.fields.Days && this.props.fields.EffectContractCompletion && this.props.fields.Days.isActive) {
                createFormConfig.fields.push(
                    {
                        label: this.props.fields.EffectContractCompletion.display,
                        dataField: 'effectcontractcompletion',
                        editorType: 'dxCheckBox',
                        editorOptions: { readOnly: !this.props.fields.EffectContractCompletion.isActive },
                        dependOn: 'type',
                        dependOnValue: (item, selectedItem) => {
                            if (!this.props.fields.EffectContractCompletion.isActive) {
                                //Always use the effect
                                return ["EFFECT_COMPLETION", "EFFECT_COMPLETION_PRICE"].includes(selectedItem.effect);
                            }
                            else if (selectedItem && selectedItem.effect && (item.effectcontractcompletion === null || item.effectcontractcompletion === undefined)) {
                                //ONLY ASSIGN DEFAULTS IF NOT ASSIGNED BEFORE
                                return ["EFFECT_COMPLETION", "EFFECT_COMPLETION_PRICE"].includes(selectedItem.effect);
                            } else {
                                //return whats ASSIGNED BEFORE
                                return item.effectcontractcompletion;
                            }
                        }
                    },
                    { label: this.props.fields.Days.display, dataField: 'days', editorType: 'dxNumberBox', editorOptions: { format: '#,##0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' }), placeholder: '0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' }) }, validation: { type: 'range', min: -36525, max: 36525, warning: this.props.intl.formatMessage({ id: 'ASSESSMENT.FORM.INVALID_DATE' }, { VALUE: 36525 }) } }
                )
            }

            return (
                <div>
                    <BiDynamicForm
                        validatorRef={this.validatorRef}
                        item={this.props.item}
                        config={createFormConfig}
                        onSubmitHandler={this.props.onSubmitHandler}
                        //valueChangeHandler={this.props.valueChangeHandler}
                        valueChangeHandler={this.fieldChanged}
                        customFields={this.props.customFields}
                        assetFieldsEnabled={this.props.assetFieldsEnabled}
                        assetid={this.createDefaultAsset}
                        secondaryPriceSectionEnabled={this.state.showSecondaryPriceSection}
                        secondaryDateSectionEnabled={this.state.showSecondaryDateSection}
                        //priceDateSectionFilters={[
                        priceDateSectionFilters={this.state.dateSectionFilters?.length > 0 ? this.state.dateSectionFilters : [
                            { sourcedmodel: "TaskOrder", sourcedrecordid: this.props.item?.taskorderid },
                            { sourcedmodel: "ProjectOrder", sourcedrecordid: this.props.item?.projectorderid }
                        ]}
                    />
                </div>
            );
        }
    }
}

const mapStateToProps = store => ({
    // contract: store.contract.contract,
    // contractTemplate: store.contract.template,
    // globalResources: store.contract.template.resources
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(AssessmentCustomForm)));