import React from 'react';
import Document from '../../_CustomComponents/DocumentV2';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import * as fileCrud from '../../../../crud/file.crud';
import { Alert } from "react-bootstrap";
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";

class ReplyDocument extends BaseDocument {
    constructor(props) {
        super(props);
        this.replyOptions = [
            { value: 'Defect Found', display: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.Defect Found' }) },
            { value: 'No Defect Found', display: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.No Defect Found' }) }
        ]
        this.replyFormConfig = {
            fields: [
                { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: 'replystatus', editorType: 'dxSelectBox', editorOptions: { dataSource: this.replyOptions, valueExpr: 'value', displayExpr: 'display' } },
                { resource: "GENERAL.FORMS.FIELD.COMMENTS", dataField: 'replycomment', editorType: 'dxHtmlEditor' },
                { resource: "GENERAL.FORMS.FIELD.ACTIONS", dataField: 'replyaction', editorType: 'dxHtmlEditor' },
            ]
        };
    }



    renderForm = () => {
        return (
            <BiDynamicForm
                isReply={true}
                item={this.props.reply}
                config={this.replyFormConfig}
                valueChangeHandler={this.props.fieldChanged}
                customFields={(this.props.customFields || {}).REPLY}
            />
        );
    }
    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType + 'Reply', this.props.notification.inspectiondefectid, data, config)
    }
    render() {
        const documentIsFinal = !this.props.isAwaitingReply;
        const showNote = !documentIsFinal && this.props.notification.draftreplydate;


        return (
            <>
                {
                    showNote && (
                        <Alert variant="secondary">
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFTED" values={{ PERSON_NAME: this.props.notification.draftreplyby, TIME_STAMP: moment(this.props.notification.draftreplydate).format('L') }} />
                                <br />
                                {
                                    this.props.notification.draftreplyto && (
                                        <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT_NOTIFIED" values={{ PERSON_NAME: this.props.notification.draftreplyto }} />
                                    )
                                }
                            </p>
                        </Alert>
                    )
                }
                <Document
                    key={documentIsFinal}
                    docConfig={this.props.notification.pdfreplyjsoncontent}
                    isFinal={documentIsFinal}
                    printDivRef={this.props.printDivRef}
                    workflowItem={this.props.workflowItem}
                    contract={this.props.contract}
                    reference={this.props.notification.referenceid}
                    form={documentIsFinal ? null : this.renderForm()}
                    uploadMethod={documentIsFinal ? null : this.uploadFile}
                    files={this.props.files}
                    notificationid={this.props.notificationid}
                    documentType={this.props.documentType}
                    isReply={true}
                    customFields={(this.props.customFields || {}).REPLY}
                    customFieldValues={this.props.notification.customfieldvalues}
                    assetid={this.props.notification.assetid}
                />
            </>
        );
    }
}


export default injectIntl(ReplyDocument);
