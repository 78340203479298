import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import { addDays } from 'date-fns';
import ReactToPrint from "react-to-print";
import { Navigate } from "react-router-dom";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as compensationEventCrud from '../../../../crud/compensationEvent.crud';
import * as contractsCrud from '../../../../crud/contracts.crud';
import * as fileCrud from '../../../../crud/file.crud';
import axios from 'axios';
import ActionModal from '../../_CustomComponents/ActionModal';
import ClaimsDocument from './Document';
import ReplyDocument from './DocumentReply';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { Alert } from "react-bootstrap";
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import moment from 'moment';
import Relations from '../../_CustomComponents/Relation/Relations';
import { FormattedMessage } from "react-intl";
import PageToolbar from '../../_CustomComponents/PageToolbar';
import Common from '../../../../shared/common';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';
import TemplateHelper from "../../../../Templates/TemplateHelper";

const initialState = {
    notification: null,
    files: null,
    showModal: false,
    saving: false,
    // isDxFormValid: true,
    isChanged: false,
    //NEW REPLY STATES
    reply: null,
    replyFiles: null,
    isAwaitingReply: false,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    relationCount: 0,
    postActionUrl: null,
    relations: null,
    selectedIndex: 0,
    selectedClause: null
};

class ClaimsDetail extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.printIssueDivRef = React.createRef();
        this.printAcceptanceDivRef = React.createRef();
        //NEW REPLY
        this.validatorRef = React.createRef();
        this.validatorName = 'ClaimsValidator';
        this.state = { ...initialState };
        this.replyOptions = [
            { field: "QA", display: "Accepted" },
            { field: "DNG", display: "Not Accepted" },
        ]
    }
    componentDidMount() {
        this.fetchCompensation();
        this.fetchFiles();
        this.fetchReplyFiles();
    }
    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.fetchCompensation();
                this.fetchFiles();
                this.fetchReplyFiles();
            });
        }
    }
    getMailDescription = () => {
        return this.state.notification.compensationnumber;
    }
    fetchCompensation = () => {
        compensationEventCrud.getCompensationEvent(this.props.params.notificationid)
            .then(response => {

                const compensation = response.data;
                if (compensation.contractid !== this.props.contract.contractid ||
                    compensation.compensationsource !== this.props.notificationType) {
                    throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
                }
                if (compensation.draftreplydate) {
                    compensation.draftreplydate = new Date(compensation.draftreplydate);
                }

                const awaitingReply = (compensation.showtasknotification === 1 && compensation.status === 'APMR');

                const replyItem = {
                    status: compensation.draftcestatus,
                    eventcomment: compensation.draftreplycomment,
                    customfieldvalues: compensation.customfieldvalues,
                    otherpricechanges: compensation.otherpricechanges,
                    otherdatechanges: compensation.otherdatechanges
                };
                compensation.assessmentamount = Number(compensation.assessmentamount);

                let selectedClause = null;
                let globalResources = this.props.globalResources;
                if (compensation.compensationtype && this.props.clauses) {
                    selectedClause = this.props.clauses.find(c => c.display === compensation.compensationtype);
                    if (selectedClause) {
                        globalResources = TemplateHelper.getGlobalResourcesOverriden(globalResources, selectedClause)
                    }
                    else {
                        if (compensation.showtasknotification !== 1) {
                            compensation.compensationtype = null;
                            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_CLAUSE_MISSING' }), { variant: 'warning', });
                        }
                    }
                }

                this.setState({
                    notification: compensation,
                    reply: replyItem,
                    isAwaitingReply: awaitingReply,
                    saving: false,
                    isChanged: false,
                    relations: compensation.relations,
                    relationCount: Common.GetRelationCount(compensation, this.props.accessibleWfIds),
                    selectedClause,
                    globalResources
                }, () => { this.props.setReference(this.state.notification.referenceid); });
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
    }

    fetchFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ files: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    fetchReplyFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType + 'Reply')
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ replyFiles: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    printHandler = () => {
        this.printIssueRef.handlePrint();
    }

    printAcceptanceHandler = () => {
        this.printAcceptanceRef.handlePrint();
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }
    showModal = (mode, postActionNavigateObj) => {
        let selectedClause = null;
        if (this.state.notification.compensationtype && this.props.clauses) {
            selectedClause = this.props.clauses.find(c => c.display === this.state.notification.compensationtype);
        }
        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
                    modalMode: mode,
                    postActionUrl: postActionNavigateObj,
                    selectedClause
                });
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
            });
        }
        else if (mode === 'REPLY_DRAFT') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_REPLY_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'REPLY_SEND') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SEND_REPLY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_SENDING' }, { PARTY_NAME: this.props.globalResources[this.props.workflowItem.notifyingParty + "_NAME"] }),
                    modalMode: mode,
                    selectedClause
                });
            }
        }
    }
    onModalResponse = (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {
                    if (this.state.modalMode === 'COMMUNICATE') {
                        const notification = { ...this.state.notification };
                        notification.createactions = params?.createactions;
                        notification.completeactions = params?.completeactions;
                        notification.createby = this.props.contractUser.name;
                        notification.createbyid = this.props.contractUser.accountid;
                        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');
                        notification.status = 'APMR';
                        notification.showtasknotification = 1;
                        const pmReplyRequired = moment(addDays(new Date(), this.props.replyPeriod)).format('DD/MM/YYYY');
                        notification.projectmanagersigneddatedisplay = pmReplyRequired;

                        const docBodyConfig = this.documentBodyConfig(notification, this.props.resources);
                        const docConfig = await this.documentConfig(notification, docBodyConfig, this.props.contractUser.name, false, null, null, this.props.workflowItem.form_NotifyCompanyName);
                        notification.pdfjsoncontent = JSON.stringify(docConfig);

                        this.saveClaim(notification, null, this.state.modalMode);

                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {
                        const notification = { ...this.state.notification };
                        notification.createby = this.props.contractUser.name;
                        notification.createbyid = this.props.contractUser.accountid;
                        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');
                        this.saveClaim(notification, null, this.state.modalMode, params ? params.userid : null);

                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {
                        const compensationevent = { ...this.state.notification };
                        const compensationeventid = compensationevent.compensationeventid
                        this.deleteCompensationEventDraft(compensationeventid);

                    }
                    else if (this.state.modalMode === 'REPLY_DRAFT') {
                        const reply = { ...this.state.reply };
                        const comp = { ...this.state.notification };
                        comp.draftcestatus = reply.status;
                        comp.draftreplycomment = reply.eventcomment;
                        for (let key in reply) {
                            if (key.startsWith("CF_")) {
                                comp[key] = reply[key];
                            }
                        }
                        this.saveClaim(comp, null, this.state.modalMode);

                    }
                    else if (this.state.modalMode === 'REPLY_SEND') {
                        const reply = { ...this.state.reply };
                        if (!this.replyValidation(reply)) {
                            //FINAL REPLY FIELDS CHECK FAILED
                            throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_FIELDS_MISSING' }))
                        }
                        const comp = { ...this.state.notification };
                        comp.createactions = params?.createactions;
                        comp.completeactions = params?.completeactions;
                        comp.status = reply.status;
                        comp.eventcomment = reply.eventcomment;
                        for (let key in reply) {
                            if (key.startsWith("CF_")) {
                                comp[key] = reply[key];
                            }
                        }
                        const docReplyBodyConfig = this.documentReplyBodyConfig(comp, this.props.resources, this.replyOptions);
                        const docReplyConfig = await this.documentConfig(comp, docReplyBodyConfig, this.props.contractUser.name, true, null, null, this.props.workflowItem.form_ReplyCompanyName);
                        comp.pdfreplyjsoncontent = JSON.stringify(docReplyConfig);
                        this.onSubmitReply(comp);
                    }
                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });

    }

    replyValidation = (reply) => {
        if (!reply.status) { return false; }
        if (!reply.eventcomment) { return false; }
        return true;
    }
    getTaskURL = (task) => {
        const { contractid, taskorderid } = task;
        return `${window.location.origin}/contract/${contractid}/tasks/pm/${taskorderid}`;
    }
    getProjectURL = (project) => {
        const { contractid, projectorderid } = project;
        return `${window.location.origin}/contract/${contractid}/projects/pm/${projectorderid}`;
    }
    documentBodyConfig = (notification, resources) => {
        const bodyConfig = [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.compensationnumber },
            { resource: 'GENERAL.FORMS.FIELD.CLAUSE', value: notification.compensationtype, }
        ];

        if (this.props.taskOrderOptionEnabled) {
            bodyConfig.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.TASK_ORDER' }, { TASK_ORDER: this.props.taskOrderNotificationName }), text: notification.taskorderreference ? notification.taskorderreference : "", valueType: 'LINK', url: this.getTaskURL(notification) });
        }

        if (this.props.projectOrderOptionEnabled) {
            bodyConfig.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PROJECT_ORDER' }, { PROJECT_ORDER: this.props.projectOrderNotificationName }), text: notification.projectorderreference ? notification.projectorderreference : "", valueType: 'LINK', url: this.getProjectURL(notification) });
        }

        bodyConfig.push(
            { label: "Change in the Prices", value: Common.FormatCurrencyNum(notification.assessmentamount, (this.props.contract || {}).currency), valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency },
            { label: "Delay to planned Completion", value: `${notification.alternativequotation} ${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' })}` },
            { resource: 'GENERAL.FORMS.FIELD.DETAILS', value: notification.description, valueType: 'HTML', align: 'vertical' }
        );

        return bodyConfig;
    }
    documentReplyBodyConfig = (notification, resources, replyOptions) => {
        let statusDisplay = "UNKNOWN";
        const selectedReply = replyOptions.find(opt => opt.field === notification.status);
        if (selectedReply) {
            statusDisplay = selectedReply.display;
        }
        return [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.compensationnumber, },
            { resource: 'GENERAL.FORMS.FIELD.CLAUSE', value: notification.compensationtype, },
            { label: "Change in the Prices", value: Common.FormatCurrencyNum(notification.assessmentamount, (this.props.contract || {}).currency), valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency },
            { label: "Delay to planned Completion", value: `${notification.alternativequotation} ${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' })}` },
            { resource: "GENERAL.FORMS.FIELD.REPLY", value: statusDisplay },
            { resource: "GENERAL.FORMS.FIELD.REPLY_DETAIL", value: notification.eventcomment, valueType: 'HTML', align: 'vertical' }
        ]
    }
    onSubmitReply = async (comp) => {
        // comp.replyby = this.props.contractUser.name;
        comp.projectmanagersignedbyid = this.props.contractUser.accountid;
        // comp.replydatedisplay = moment().format('DD/MM/YYYY');
        // comp.contractorreceiveddatedisplay = moment().format('DD/MM/YYYY');
        if (comp.status === 'QA') {
            //UPDATE CONTRACT SPECIFICS
            const { companyid, projectid, contractid } = this.props.contract;
            const { data: contractDetails } = await contractsCrud.getContract(contractid);
            const contractSpecifics = {
                companyid: companyid,
                projectid: projectid,
                contractid: contractid,
                contractorapplied: contractDetails.contractorapplied,
                totalcertified: contractDetails.totalcertified,
                expectedcompletiondatedisplay: contractDetails.expectedcompletiondate ? moment(new Date(contractDetails.expectedcompletiondate)).format('DD/MM/YYYY') : null,
                completiondatedisplay: moment(addDays(new Date(contractDetails.completiondate), comp.alternativequotation)).format('DD/MM/YYYY'),
                currentcontractvalue: contractDetails.currentcontractvalue + comp.assessmentamount,
            };
            this.saveClaim(comp, contractSpecifics, this.state.modalMode);
        } else {
            this.saveClaim(comp, null, this.state.modalMode);
        }

    }
    deleteCompensationEventDraft = async (compensationeventid) => {
        try {
            await compensationEventCrud.deleteCompensationEventDraft(compensationeventid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }
    }
    saveClaim = async (claim, contractSpecifics, action, notifyuserid) => {
        const { companyid, projectid, contractid } = this.props.contract;

        if (!claim.draftreplydatedisplay && claim.draftreplydate) {
            claim.draftreplydatedisplay = moment(claim.draftreplydate).format('DD/MM/YYYY');
        }
        if (!claim.projectmanagersigneddatedisplay && claim.projectmanagersigneddate) {
            claim.projectmanagersigneddatedisplay = moment(new Date(claim.projectmanagersigneddate)).format('DD/MM/YYYY');
        }

        const onSuccess = () => {
            this.fetchCompensation();
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });
        };
        const onError = (err) => {
            this.setState({ saving: false });
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
        try {
            claim.actioncode = action;
            claim.replyingparty = this.props.workflowItem.replyingParty;

            Common.PropertyConversionsBeforeNoticeSaveRequest(claim, this.props.customFields, this.props.contractUsers);
            await compensationEventCrud.saveCompensationEvent(claim)
            if (contractSpecifics) {
                await contractsCrud.updateContractSpecifics(contractSpecifics);
            }
            claim.notificationtypeforemailtemplate = claim.compensationsource;
            claim.pdfjsoncontent = claim.pdfjsoncontent?.replace("$NOW", moment().format('DD/MM/YYYY'));
            this.notify(action, claim, notifyuserid);

            if (this.state.postActionUrl) {
                this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
            } else {
                onSuccess();
            }
        } catch (error) {
            onError(error);
        }
    }

    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else {
                this.state.notification[field] = value;
                if (!this.state.isChanged) {
                    this.setState({ isChanged: true });
                }
            }
        }

        // if (typeof isDxFormValid !== 'undefined' && isDxFormValid !== null) {
        //     if (isDxFormValid !== this.state.isDxFormValid) {
        //         this.setState({ isDxFormValid: isDxFormValid });
        //     }
        // }
    }
    replyFieldChanged = (field, value, isDxReplyFormValid, isCheckBoxValue) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            this.state.reply[field] = value;
            if (!this.state.isChanged) {
                this.setState({ isChanged: true });
            }
        }
    }

    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving || this.state.showModal);
        return (
            <PageToolbar
                documentIsFinal={documentIsFinal}
                saveDisabled={saveDisabled}
                isAwaitingReply={this.state.isAwaitingReply}
                actions={{
                    showModal: this.showModal,
                    onPrintReply: this.printAcceptanceHandler,
                    onPrint: this.printHandler
                }}
                workflowItem={this.props.workflowItem}
                match={this.props.match}
                customFieldValues={this.state.notification.customfieldvalues}
                referenceid={this.state.notification.referenceid}
                contract={this.props.contract}
                relationParams={{
                    subject: this.getMailDescription(),
                    assetid: this.state.notification.assetid
                }}
                notification={this.state.notification}
            />
        );
    }


    tabDocument = () => {
        return (
            <ClaimsDocument
                {...this.props}
                validatorRef={this.validatorRef}
                printDivRef={this.printIssueDivRef}
                fieldChanged={this.fieldChanged}
                notification={this.state.notification}
                files={this.state.files}
                notificationid={this.props.params.notificationid}
            />
        );
    }
    tabReply = () => {

        if (!this.props.workflowItem.userFilters.replyNotice.communicateReplyAllowed && !this.props.workflowItem.userFilters.replyNotice.saveDraftReplyAllowed && this.state.isAwaitingReply) {
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <div style={{ width: '100%', maxWidth: '900px', margin: '20px' }}>
                        <Alert variant="warning" >
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_AWAITING_REPLY" values={{ ITEM_NAME: this.props.workflowItem.pageTitle }} />
                            </p>
                        </Alert>
                    </div>
                </div>
            )
        }
        else {
            return (
                <ReplyDocument
                    {...this.props}
                    isAwaitingReply={this.state.isAwaitingReply}
                    printDivRef={this.printAcceptanceDivRef}
                    fieldChanged={this.replyFieldChanged}
                    notification={this.state.notification}
                    reply={this.state.reply}

                    files={this.state.replyFiles}
                    notificationid={this.props.params.notificationid}
                />
            )
        }
    }
    tabRelations = () => {
        return (
            <Relations
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.notification.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.subject}
                onCountChange={(count) => this.setState({ relationCount: count })}
            />
        );
    }
    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}

            />
        );

    };
    render() {
        if (!this.state.notification || !this.state.files || !this.state.replyFiles) {
            return (<PortletSpinner />);
        }
        const documentIsFinal = this.state.notification.showtasknotification === 1;

        if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
            return (<Navigate replace to={this.props.workflowItem.paths.listPath} />)
        }
        return (
            <Portlet>
                <ReactToPrint
                    ref={el => (this.printIssueRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printIssueDivRef.current}
                />
                <ReactToPrint
                    ref={el => (this.printAcceptanceRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printAcceptanceDivRef.current}
                />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletHeader
                    title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                    toolbar={this.renderToolbar(documentIsFinal)}
                />
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            ref={(ref) => this.tabPanel = ref}
                            width="100%"
                            deferRendering={false}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}
                        >
                            <Item icon="file" title={this.props.title} render={this.tabDocument} />
                            <Item icon="rename" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_REPLY' })} render={documentIsFinal ? this.tabReply : null} visible={documentIsFinal} />
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>
                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                    workflowItem={this.props.workflowItem}
                    userLimit={this.state.notification.assessmentamount}
                    selectedClause={this.state.selectedClause}
                />
            </Portlet>
        );
    }
}

export default withDetailPageProps(ClaimsDetail);