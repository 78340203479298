import React from 'react';
import clsx from "clsx";

export const SidedPage =  ({className, ...props }) => (
      <div
        {...props}
        className={clsx(
          "sided-page_wrapper",
          className
        )}
      />
  );

  export const SidedPageLeft =  ({className, ...props }) => (
    <div
      {...props}
      className={clsx(
        "sided-page_left",
        className
      )}
    />
);

export const SidedPageRight =  ({className, ...props }) => (
    <div
      {...props}
      className={clsx(
        "sided-page_right",
        className
      )}
    />
);