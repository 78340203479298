import { userFilterCategories } from "../../../shared/lookup";
const workflows = [
    {
        id: 52,
        title: 'Cost Forecast',
        notificationName: 'Cost Forecast',
        abbreviation: '$SUPPLIER_ABBREVIATION-CostForecast-',
        notifyingParty: 'SUPPLIER',
        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Cost Forecast',
            currentModel: 'Forecast',
            currentModelType: 'CostForecast',
            referenceType: 'Forecast',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'cost',
                create: 'create',
                list: 'list',
                default: 'list'
            },
            enabled: () => (process.env.REACT_APP_FORECAST_ENABLED === "true")
        }
    },
    {
        id: 53,
        title: 'Carbon Forecast',
        notificationName: 'Carbon Forecast',
        abbreviation: '$SUPPLIER_ABBREVIATION-CarbonForecast-',
        notifyingParty: 'SUPPLIER',
        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Carbon Forecast',
            currentModel: 'Forecast',
            currentModelType: 'CarbonForecast',
            referenceType: 'Forecast',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'carbon',
                create: 'create',
                list: 'list',
                default: 'list'
            },
            enabled: () => (process.env.REACT_APP_FORECAST_ENABLED === "true")
        }
    }


]

export default workflows;