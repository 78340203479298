import axios from '../shared/axios';

export function getSystemSettingById(id) {
    return axios.get(`/api/SystemSetting/Load?systemsettingid=${id}`);
}

export function getSystemSettingByReference(reference) {
    return axios.get(`/api/SystemSetting/Load?systemreference=${reference}`);
}

export function getSystemSettings(deepload) {
    return axios.get(`/api/SystemSetting/ToList?deepload=${deepload}`);
}

export function saveSystemSetting(formData) {
    return axios.post('/api/SystemSetting/Save', formData);
}