import * as companiesCrud from "../../crud/companies.crud";
import * as authDuck from './auth.duck';
import store from "../store";
import { select, put, takeLatest } from "redux-saga/effects";

const getCompanies = (store) => store.companies;

const actionTypes = {
    COMPANIES_SET_PENDING: "[COMPANIES_SET_PENDING]",
    COMPANIES_FETCH_START: "[COMPANIES_FETCH_START]",
    COMPANIES_SET_COMPANIES: "[COMPANIES_SET_COMPANIES]",
    COMPANIES_LOGOUT: "[COMPANIES_LOGOUT]"
};

const initialCompaniesState = {
    companies: null,
    lastLoadedEpochTime: null,
    pendingFetch: false,
    frameworkFilter: null
};

export const reducer = (state = initialCompaniesState, action) => {
    switch (action.type) {
        case actionTypes.COMPANIES_SET_PENDING: {
            return { ...state, pendingFetch: true };
        }
        case actionTypes.COMPANIES_FETCH_START: {
            return { ...state, pendingFetch: false, lastLoadedEpochTime: new Date().getTime() };
        }
        case actionTypes.COMPANIES_SET_COMPANIES: {
            return { ...state, companies: action.payload.companies, frameworkFilter: action.payload.frameworkFilter };
        }
        case actionTypes.COMPANIES_LOGOUT: {
            return initialCompaniesState;
        }
        default:
            return state;
    }
};

export const actions = {
    fetchStart: (force = false) => {

        const authStore = store.getState().auth;
        const companiesStore = store.getState().companies;

        if (authStore) {
            if (!authStore.authToken.token || !authStore.csrfToken.token) {
                if (!companiesStore.pendingFetch) {
                    return { type: actionTypes.COMPANIES_SET_PENDING }
                }
                return { type: '' };
            }
        }
        if (!companiesStore.pendingFetch && !force && companiesStore) {
            //DO NOT REPEAT REQUEST IN LESS THAN A MINUTE
            if (companiesStore.lastLoadedEpochTime) {
                if (new Date(companiesStore.lastLoadedEpochTime + (60 * 1000)) > new Date()) {
                    // console.log("Companies loaded less than a minute ago!");
                    return { type: '' };
                }
            }
        }


        return { type: actionTypes.COMPANIES_FETCH_START };
    },
    setPending:()=> ({ type: actionTypes.COMPANIES_SET_PENDING }),
    setCompanies: (companies, frameworkFilter) => ({ type: actionTypes.COMPANIES_SET_COMPANIES, payload: { companies, frameworkFilter } }),
    logout: () => ({ type: actionTypes.COMPANIES_LOGOUT })
};
export function* saga() {
    yield takeLatest(authDuck.actionTypes.AUTH_LOAD_COMPLETED, function* companiesLoginSaga() {
        try {
            let companiesStore = yield select(getCompanies);
            if (companiesStore.pendingFetch) {
                yield put(actions.fetchStart(true));
            }

        }
        catch{ console.log("error in companiesLoginSaga") }

    });
    yield takeLatest(actionTypes.COMPANIES_FETCH_START, function* companiesLoadCompaniesSaga() {
        try {
            const response = yield companiesCrud.getCompanies();
            const unArchivedCmp = JSON.parse(response.data.aggregatelist).filter(cmp => cmp.archived !==1);
            yield put(actions.setCompanies(unArchivedCmp));
        }
        catch{
            console.log("companiesFetchStartSaga Error fetching data");
        }

    });
}
