import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import ReactToPrint from "react-to-print";
import { Navigate } from "react-router-dom";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as inspectionCrud from '../../../../crud/inspectionDefect.crud';
import * as fileCrud from '../../../../crud/file.crud';
import axios from 'axios';
import ActionModal from '../../_CustomComponents/ActionModal';
import NonConformanceDocument from './Document';
import NonConformanceAction from './Action';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import moment from 'moment';
import Relations from '../../_CustomComponents/Relation/Relations';
import PageToolbar from '../../_CustomComponents/PageToolbar';
import Common from '../../../../shared/common';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';
const initialState = {
    notification: null,
    files: null,
    showModal: false,
    saving: false,
    isChanged: false,
    relationCount: 0,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    relations: null
};

class NonConformanceDetail extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.printIssueDivRef = React.createRef();
        this.validatorRef = React.createRef();
        this.validatorName = 'DefectValidator';
        this.state = { ...initialState };
    }
    componentDidMount() {
        this.fetchInspectionDefect();
        this.fetchFiles();
    }


    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.fetchInspectionDefect();
                this.fetchFiles();
            });
        }
    }
    getMailDescription = () => {
        return this.state.notification.replyactiondetail;
    }
    fetchInspectionDefect = () => {
        inspectionCrud.getInspectionDefect(this.props.params.notificationid)
            .then(response => {
                const defect = response.data;
                if (defect.contractid !== this.props.contract.contractid ||
                    defect.type !== this.props.notificationType) {
                    throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
                }
                if (defect.inspectiondate) {
                    defect.inspectiondate = new Date(defect.inspectiondate);
                }
                if (defect.notificationdate) {
                    defect.notificationdate = new Date(defect.notificationdate);
                }

                this.setState({
                    notification: defect,
                    saving: false,
                    isChanged: false,
                    relations: defect.relations,
                    relationCount: Common.GetRelationCount(defect, this.props.accessibleWfIds)
                }, () => { this.props.setReference(this.state.notification.referenceid); });
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
    }

    fetchFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ files: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    printHandler = () => {
        this.printIssueRef.handlePrint();
    }

    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }

    showModal = (mode, postActionNavigateObj) => {
        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
                    modalMode: mode,
                    postActionUrl: postActionNavigateObj
                });
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
            });
        }
    }
    onModalResponse = (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {


                    if (this.state.modalMode === 'COMMUNICATE') {
                        const notification = { ...this.state.notification };
                        // notification.createby = this.props.contractUser.name;
                        notification.createbyid = this.props.contractUser.accountid;
                        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');
                        notification.replystatus = 'Open';
                        notification.showtasknotification = 1;
                        const docBodyConfig = this.documentBodyConfig(notification, this.props.resources);
                        const docConfig = await this.documentConfig(notification, docBodyConfig, this.props.contractUser.name, false, null, null, this.props.workflowItem.form_NotifyCompanyName);
                        notification.pdfjsoncontent = JSON.stringify(docConfig);
                        this.saveIssue(notification, this.state.modalMode);

                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {
                        const notification = { ...this.state.notification };
                        // notification.createby = this.props.contractUser.name;
                        notification.createbyid = this.props.contractUser.accountid;
                        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');
                        this.saveIssue(notification, this.state.modalMode, params ? params.userid : null);

                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {
                        const inspection = { ...this.state.notification };
                        const inspectiondefectid = inspection.inspectiondefectid

                        this.deleteNonConformityDraft(inspectiondefectid);

                    }


                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });
    }

    documentBodyConfig = (notification, resources) => {
        return [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.assistancetestserchdefect, },
            { resource: 'CONTRACT.WF.CONFORMANCE.FIELD.NOTIFICATION', value: notification.inspectiondetails, valueType: 'HTML', align: 'vertical' },
        ]
    }
    deleteNonConformityDraft = async (inspectionid) => {

        try {
            await inspectionCrud.deleteInspectionDraft(inspectionid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }

    }
    saveIssue = async (issue, action, notifyuserid) => {
        try {
            // if (!issue.notificationdatedisplay && issue.notificationdate) {
            //     issue.notificationdatedisplay = moment(new Date(issue.notificationdate)).format('DD/MM/YYYY');
            // }
            // if (!issue.createbyid && issue.createby) {
            //     issue.createbyid = issue.createby;
            // }
            if (!issue.inspectiondatedisplay && !issue.inspectiondatedisplay && issue.inspectiondate) {
                issue.inspectiondatedisplay = moment(issue.inspectiondate).format('DD/MM/YYYY HH:mm:ss');
            }
            issue.actioncode = action;
            issue.notifyuserlist = this.getNotifyUserListForUpdate(action, notifyuserid);
            Common.PropertyConversionsBeforeNoticeSaveRequest(issue, this.props.customFields, this.props.contractUsers);
            await inspectionCrud.updateInspectionDefect(issue);
            // this.notify(action, issue, notifyuserid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });
            if (this.state.postActionUrl) {
                this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
            }
            else {
                this.fetchInspectionDefect();
            }

        } catch (err) {
            this.setState({ saving: false });
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else {
                this.state.notification[field] = value;
                if (!this.state.isChanged) {
                    this.setState({ isChanged: true });
                }
            }
        }
    }

    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving || this.state.showModal);
        return (
            <PageToolbar
                documentIsFinal={documentIsFinal}
                saveDisabled={saveDisabled}
                actions={{
                    showModal: this.showModal,
                    onPrint: this.printHandler
                }}
                workflowItem={this.props.workflowItem}
                match={this.props.match}
                customFieldValues={this.state.notification.customfieldvalues}
                referenceid={this.state.notification.referenceid}
                contract={this.props.contract}
                relationParams={{
                    subject: this.getMailDescription(),
                    assetid: this.state.notification.assetid
                }}
                notification={this.state.notification}
            />
        );
    }
    tabDocument = () => {
        return (
            <NonConformanceDocument
                {...this.props}
                printDivRef={this.printIssueDivRef}
                fieldChanged={this.fieldChanged}
                notification={this.state.notification}
                files={this.state.files}

                notificationid={this.props.params.notificationid}
            />
        );
    }

    onUpdateRisk = async (defect, setSubmitting) => {
        if (!defect.replycomment || !defect.replystatus || !defect.replyactiondetail) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.WF.DEFECTS.WARNING.ACT_FIELDS_MISSING' }), { variant: "warning" });
            return;
        }
        try {
            const data = {
                inspectiondefectid: this.state.notification.inspectiondefectid,
                version: this.state.notification.version,
                replycomment: defect.replycomment,
                replyactiondetail: defect.replyactiondetail,
                replystatus: defect.replystatus
            }
            await inspectionCrud.updateConformanceAction(data);

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), {
                variant: "success",
            });
            this.fetchInspectionDefect();
            setSubmitting(false);
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    tabActions = () => {
        return (
            <NonConformanceAction
                {...this.props}
                onSubmitUpdate={this.onUpdateRisk}
                notification={this.state.notification}
            />
        );
    }
    tabRelations = () => {
        return (
            <Relations
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.notification.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.replyactiondetail}
                onCountChange={(count) => this.setState({ relationCount: count })}
            />
        );
    }
    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}

            />
        );

    };
    render() {
        if (!this.state.notification || !this.state.files) {
            return (<PortletSpinner />);
        }
        const documentIsFinal = this.state.notification.showtasknotification === 1;

        if (!documentIsFinal && (!this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed || this.state.notification.replyaction !== this.props.contractUser.party)) {
            return (<Navigate replace to={this.props.workflowItem.paths.listPath} />)
        }
        return (
            <Portlet>
                <ReactToPrint
                    ref={el => (this.printIssueRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printIssueDivRef.current}
                />
                <PortletHeader
                    title={`${this.props.title}${documentIsFinal ? '' : ' Draft'}`}
                    toolbar={this.renderToolbar(documentIsFinal)}
                />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            width="100%"
                            deferRendering={false}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}>
                            <Item icon="file" title={this.props.title} render={this.tabDocument} />
                            <Item icon="repeat" title={this.props.intl.formatMessage({ id: 'CONTRACT.WF.DEFECTS.TAB.ACTIONS' })} render={documentIsFinal ? this.tabActions : null} visible={documentIsFinal} />
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>
                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                    workflowItem={this.props.workflowItem}
                />
            </Portlet>
        );
    }
}


export default withDetailPageProps(NonConformanceDetail);
