import React from 'react';
import { connect } from "react-redux";
import { TableCell } from "@mui/material";
import Spinner from '../../../../partials/layout/Spinner';
import TableArea from './TableArea';
import { injectIntl } from "react-intl";

class TableActionItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionItems: null,
        }
    }
    componentDidMount() {
        this.setItems();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.contractTemplate !== this.props.contractTemplate ||
            prevProps.contractDashboard !== this.props.contractDashboard) {
            this.setItems();
        }
    }
    setItems = () => {
        if (this.props.contractTemplate && this.props.contractDashboard) {
            const actionItems = this.getActionItems();
            this.setState({ actionItems: actionItems });
        }
    }

    getActionItems = () => {
        const actionItems = { header: [], row: [] };
        const { resources } = this.props.contractTemplate;
        const { warningCountSupplier, warningCountPm, warningCountSupervisor, warningCountClient } = this.props.contractDashboard;


        if (this.props.contractTemplate.parties.supplierExist) {
            actionItems.header.push(<TableCell align="center" key="warningCountSupplier">{resources.SUPPLIER_NAME}</TableCell>);
            actionItems.row.push(<TableCell align="center" key="warningCountSupplier">{warningCountSupplier}</TableCell>);
        }

        if (this.props.contractTemplate.parties.pmExist) {
            actionItems.header.push(<TableCell align="center" key="warningCountPm">{resources.PM_NAME}</TableCell>);
            actionItems.row.push(<TableCell align="center" key="warningCountPm">{warningCountPm}</TableCell>);
        }

        if (this.props.contractTemplate.parties.supervisorExist) {
            actionItems.header.push(<TableCell align="center" key="warningCountSupervisor">{resources.SUPERVISOR_NAME}</TableCell>);
            actionItems.row.push(<TableCell align="center" key="warningCountSupervisor">{warningCountSupervisor}</TableCell>);
        }

        if (this.props.contractTemplate.parties.clientExist) {
            actionItems.header.push(<TableCell align="center" key="warningCountClient">{resources.CLIENT_NAME}</TableCell>);
            actionItems.row.push(<TableCell align="center" key="warningCountClient">{warningCountClient}</TableCell>);
        }

        return actionItems;
    }

    render() {
        if (!this.state.actionItems) {
            return (<Spinner />);
        }
        return (<TableArea title={this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.OUTSTANDING_ACTIONS' })} items={this.state.actionItems} />);
    }
}

const mapStateToProps = store => ({
    contractDashboard: store.contract.dashboard,
    contractTemplate: store.contract.template,
});


export default injectIntl(connect(mapStateToProps)(TableActionItems));