/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from 'react';
import { connect } from "react-redux";
import FileManager, { ItemView, Details, Column, Toolbar, Item as Item1 } from 'devextreme-react/file-manager';
import ObjectFileSystemProvider from 'devextreme/file_management/object_provider';
import * as asiteCrud from '../../../crud/asite.crud';
import Spinner from '../../../partials/layout/Spinner';
import { injectIntl } from "react-intl";
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { TabPanel, Item as Item2 } from 'devextreme-react/tab-panel';
// import AsiteFileSearch from './AsiteFileSearch';
import AsiteFileFilter from './AsiteFileFilter';

const initialState = {
  folders: null,
  selectedFolderIdList: [],
  loading: false,
  currentTabIndex: 0
};

class AsiteFileManager extends Component {
  constructor(props) {
    super(props);
    this.fileManagerRef = React.createRef();
    const newRemoteProvider = new ObjectFileSystemProvider({
      data: null
    });

    this.state = { ...initialState, remoteProvider: newRemoteProvider };

    this.LoaderMessageOptions = {
      items: [
        {
          text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LOADING_MESSAGE' }),
          items: []
        }
      ]
    }
  }

  get fileManager() {
    return this.fileManagerRef.current.instance;
  }
  refreshFileManager = () => {
    if (this.fileManagerRef && this.fileManagerRef.current && this.fileManagerRef.current.instance) {
      this.fileManagerRef.current.instance.refresh();
    }
    else {
      console.log("fileManagerRef not found", this.fileManagerRef)
    }
  }

  componentDidMount() {
    this.requestSource = axios.CancelToken.source();

    this.getCachedContract();
  }
  componentWillUnmount() {
    this.closed = true;
    this.requestSource.cancel('Operation canceled by the user.');
  }

  onCurrentDirectoryChanged = (e) => {
    if (this.closed) {
      console.log("EXECUTE ON CLOSED - onCurrentDirectoryChanged")
    }
    //this.setState({ loading: true });
    if (e && e.directory && e.directory.dataItem && e.directory.dataItem.id) {
      if (e.component.option('currentPath').split("/").length > 1) {
        this.setState({ loading: true });
        if (!this.state.selectedFolderIdList.includes(e.directory.dataItem.id)) {
          this.setState(state => {
            const selectedFolderIdList = state.selectedFolderIdList.concat(e.directory.dataItem.id);
            return { selectedFolderIdList };
          }, () => this.getCachedDocuments(e.component.option('currentPath'), e.directory.dataItem.id));
        } else {
          this.setState({ loading: false });
        }
      }
    }
  }

  getCachedContract = () => {
    asiteCrud.getAsiteCachedContract(this.props.contract.contractid)
      .then((response) => {
        let folders = response.data.folders.folderTree;
        const newRemoteProvider = new ObjectFileSystemProvider({
          data: folders
        });

        this.setState({ folders, remoteProvider: newRemoteProvider, loading: false }/*, () => { this.refreshFileManager(); }*/);
      })
      .catch(err => {
        console.log(err);
        if (!axios.isCancel(err)) {
          this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
          this.props.onDialogResult();
        }
      });
  }

  getCachedDocuments = (currentPath, selectedFolderId) => {
    asiteCrud.getAsiteCachedDocuments(this.props.contract.contractid, selectedFolderId, 1, this.requestSource.token)
      .then(response => {
        let documents = response.data ? response.data.documents : response.data;
        this.getFormattedData(currentPath, documents);
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
          this.props.onDialogResult();
        }
      });
  }

  getFormattedData = (currentPath, documents) => {
    const pathItems = currentPath.split("/");
    pathItems.forEach(item => {
      //this.formatData(this.state.workspaces, item, pathItems[pathItems.length - 1], documents);
      this.formatData(this.state.folders, item, pathItems[pathItems.length - 1], documents);
    });
    //this.state.remoteProvider.data = JSON.parse(JSON.stringify(this.state.workspaces));
    this.state.remoteProvider.data = JSON.parse(JSON.stringify(this.state.folders));

    this.setState({ loading: false }, () => { this.refreshFileManager(); });
  }

  formatData(arr, value, lastValue, documents) {
    if (arr) {
      arr.forEach(i => {
        if (i.name === value) {
          if (value === lastValue) {
            if (documents) {
              documents.forEach(x => {
                i.items = [
                  ...i.items, x
                ]
              })
            }
          }
        } else {
          this.formatData(i.items, value, lastValue, documents);
        }
      })
    }
  }


  tabFileManager = () => {
    return (
      <FileManager
        ref={this.fileManagerRef}
        disabled={this.state.loading}
        fileSystemProvider={this.state.remoteProvider}
        height='100%'
        rootFolderName={this.props.intl.formatMessage({ id: 'GENERAL.FILE_MANAGER.NAME' })}
        onCurrentDirectoryChanged={this.onCurrentDirectoryChanged}
        onSelectionChanged={this.onFileManagerSelectionChanged}
      >
        <Toolbar>
          {
            this.state.loading ? <Item1 widget="dxMenu" location="center" options={this.LoaderMessageOptions} /> : null
          }
        </Toolbar>
        <ItemView>
          <Details>
            <Column dataField="thumbnail" />
            <Column dataField="name" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.NAME' })} width={280} />
            <Column dataField="docref" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.DOCREF' })} width={280} />
            <Column dataField="revision" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.REVISION' })} width={85} />
            <Column dataField="dateModified" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.MODIFIEDDATE' })} />
            <Column dataField="size" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.FILESIZE' })} />
          </Details>
        </ItemView>
      </FileManager>

    );
  }
  onFileManagerSelectionChanged = (e) => {
    if (e.selectedItems) {
      const mappedItems = e.selectedItems.filter(item => !item.isDirectory).map(item => ({ ...item.dataItem }));
      this.props.onSelectedItemsChanged(mappedItems);
    }
  }

  onSearchListSelectedItemChanged = (itemList) => {
    const mappedItems = itemList.filter(item => !item.isDirectory).map(item => ({ ...item }));
    this.props.onSelectedItemsChanged(mappedItems);
  }


  tabFileFilter = () => {
    return (
      <AsiteFileFilter
        currentTabIndex={this.state.currentTabIndex}
        onSelectionChange={this.onSearchListSelectedItemChanged}
        dataSource={this.state.remoteProvider}
      />
    );
  }
  onTabSelectionChanged = (newTabIndex) => {
    if (newTabIndex === 0) {
      this.setState({
        currentTabIndex: newTabIndex
      });
    } else {
      this.fileManagerRef.current.instance.option('selectedItemKeys', []);
      this.setState({
        currentTabIndex: newTabIndex
      });
    }
  }
  render() {
    if (!this.state.folders) {
      return (<Spinner />)
    }

    return (
      <TabPanel
        width="100%"
        height="inherit"
        deferRendering={false}
        animationEnabled={true}
        focusStateEnabled={true}
        hoverStateEnabled={true}
        onSelectedIndexChange={this.onTabSelectionChanged}
        swipeEnabled={false}>
        <Item2 icon="file" title={this.props.intl.formatMessage({ id: 'GENERAL.DOCUMENTS.SELECT_DOCUMENTS' })} render={this.tabFileManager} />
        <Item2 icon="filter" title={this.props.intl.formatMessage({ id: 'GENERAL.DOCUMENTS.FILTER_DOCUMENTS' })} render={this.tabFileFilter} />
      </TabPanel>
    )
  }
}

const mapStateToProps = store => ({
  contract: store.contract.contract,
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(AsiteFileManager)));