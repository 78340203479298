import { RoutesConfigPaths } from "./config/config";
import { GetAccessRights } from "./Protector";
export default class ProtectorHelper {

    static getAllowedPaths = (userProfile) => {
        const allowedPaths = [];
        RoutesConfigPaths.forEach(pathObj => {
            if (GetAccessRights(pathObj.path, userProfile).allowAccess) {
                allowedPaths.push(pathObj.path);
            }
        })
        return allowedPaths;
    }
    static isPathAllowed = (path, userProfile, contract) => {
        return GetAccessRights(path, userProfile, contract).allowAccess;
    }
}