import axios from '../shared/axios';

export function getCustomFieldByCompany(companyid, objectname) {
    return axios.get(`/api/CustomField/LoadByCompany`, { params: { companyid, objectname } });
}

export function getCustomFieldSettings(companyid) {
    return axios.get(`/api/CustomField/LoadCompanySettings?companyid=${companyid}`);
}

export function createCustomField(data) {
    return axios.post(`/api/CustomField/Create`, data);
}
export function saveCustomField(data) {
    return axios.post(`/api/CustomField/Save`, data);
}
export function deleteCustomField(customfieldid) {
    return axios.delete(`/api/CustomField/Delete`, { params: { customfieldid } });
}

export function changeOrder(customfieldid, movedindex) {
    return axios.put(`/api/CustomField/ChangeOrder`, null, { params: { customfieldid, movedindex } });
}
export function changeObject(customfield, movedindex) {
    return axios.put(`/api/CustomField/ChangeObject`, customfield, { params: {  movedindex } });
}

export function getCustomFieldToAndCcValues(companyid, workflowid, itemid, isreply) {
    return axios.get(`/api/CustomField/GetCustomFieldToAndCcValues?companyid=${companyid}&workflowid=${workflowid}&itemid=${itemid}&isreply=${isreply}`);
}

export function getCustomFieldToAndCcValuesBySubType(companyid, workflowSubtype, itemid, isreply) {
    return axios.get(`/api/CustomField/GetCustomFieldToAndCcValuesBySubType?companyid=${companyid}&subtype=${workflowSubtype}&itemid=${itemid}&isreply=${isreply}`);
}