import React from 'react';
import DefaultResources from '../../../../../Templates/DefaultResources';
import DataGrid, {
    Editing,
    Column,
    Lookup,
    RequiredRule
} from 'devextreme-react/data-grid';

class OverrideResourcesGrid extends React.Component {
    constructor(props) {
        super(props);
        const initialList = props.overrideResourcesList ? JSON.parse(JSON.stringify(props.overrideResourcesList)) : [];
        const availableResources = DefaultResources.map(rsc => rsc.key)
        // const initialState = this.getInitialState(props);
        this.state = {
            items: initialList,
            availableResources
        };
    }
    // componentDidMount() {
    //     console.log("OverrideResourcesGrid Mounted")
    // }
    // componentWillUnmount() {
    //     console.log("OverrideResourcesGrid Un-Mounted")
    // }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.overrideResourcesList !== this.props.overrideResourcesList) {
            this.setState({items: this.props.overrideResourcesList? JSON.parse(JSON.stringify(this.props.overrideResourcesList)):[]});
        }
    }
    onRowValidating = async (e) => {        
        e.promise = new Promise((resolve, reject) => {
            const newObject = { ...e.oldData, ...e.newData };

            const valResult = this.validateData(newObject)

            e.errorText = valResult.errorText;
            e.isValid = valResult.isValid;

            //DONT CHANGE STATUS IN OTHER CASES
            resolve();
        });
    }
    validateData = (data) =>{
        const valResult = {isValid:true,errorText:''};
        const def = DefaultResources.find(rsc => rsc.key === data.key);
        if (!def) {
            valResult.errorText = "Invalid resource";
            valResult.isValid = false;
        }
        if(data.value.trim().length === 0){
            valResult.errorText = "Value can not be empty";
            valResult.isValid = false;
        }
        if(def.type ==="NUMBER"){
            try{
                const num = Number(data.value);
                if(isNaN(num)){throw new Error()}
            }
            catch{
                valResult.errorText = "Invalid number";
                valResult.isValid = false;
            }
        }
        return valResult;
    }
    updateParentList=(e)=>{
        const source =e.component.getDataSource();
        if(source._items){
            //remove auto generated ID Key
            source._items.forEach((v)=>{ delete v.__KEY__ });
        }
        this.props.onValueChanged(JSON.parse(JSON.stringify(source._items)))
    }
    render() {
        return (

            <DataGrid
            // key={this.props.id}
                keyExpr="key"
                title="Override Global Resources"
                dataSource={this.state.items}
                showBorders={true}
                columnAutoWidth={true}
                onRowValidating={this.onRowValidating}
                onRowInserted={this.updateParentList}
                onRowUpdated={this.updateParentList}
                onRowRemoved={this.updateParentList}                
                repaintChangesOnly={true}
            >
                <Editing
                    mode="form"
                    allowUpdating={!this.props.readOnly}
                    allowAdding={!this.props.readOnly}
                    allowDeleting={!this.props.readOnly}
                    // useIcons={true}
                />
                <Column
                    dataField="key"
                    caption="Resource Name"
                >
                    <Lookup
                        dataSource={this.state.availableResources}
                        // displayExpr="display"
                        // valueExpr="value"
                    />
                    <RequiredRule />
                </Column>
                <Column
                    dataField="value"
                    caption="Value"
                >
                    <RequiredRule />

                    {/* <StringLengthRule message="Label must have 2 to 250 characters" min={2} max={250} /> */}
                </Column>
            </DataGrid>
        );
    }


}


export default OverrideResourcesGrid