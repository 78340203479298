import React, { Component } from "react";
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import ContractTags from "./ContractTags";
import CommunicationTags from "./CommunicationTags";
import CompanyTags from "./CompanyTags";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";

class TagsPanel extends Component {
  tabContractTag = () => (
    <ContractTags />
  );

  tabCommunicationTag = () => (
    <CommunicationTags />
  );

  tabCompanyTag = () => (
    <CompanyTags />
  );

  render() {
    return (
      <Portlet>
        <PortletBody>
          <TabPanel
            width="100%"
            deferRendering={true}
            animationEnabled={true}
            swipeEnabled={true}>
            <Item title={"Contract Tags"} render={this.tabContractTag} />
            <Item title={"Communication Tags"} render={this.tabCommunicationTag} />
            <Item title={"Company Tags"} render={this.tabCompanyTag} />
          </TabPanel>
        </PortletBody>
      </Portlet>
    );
  }
}

export default TagsPanel;