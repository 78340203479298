import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ExtensionList from './List';
import ExtensionCreate from './Create';
import ExtensionDetail from './Detail';

class ExtensionRouter extends React.Component {
    constructor(props) {
        super(props);
        if (props.mode === 'SUPPLIER') {
            this.communicateToUser = this.props.globalResources.PM_NAME;
            this.documentType = 'DocumentSupExt';
            this.notificationName = props.workflowItem.notificationName;
            this.abbreviation = props.workflowItem.abbreviation;
        }
        else if (props.mode === 'PM') {
            this.communicateToUser = this.props.globalResources.SUPPLIER_NAME;
            this.documentType = 'DocumentPmExt';
            this.notificationName = props.workflowItem.notificationName;
            this.abbreviation = props.workflowItem.abbreviation;
        }

        this.notifyingParty = props.workflowItem.notifyingParty;
        this.replyingParty = props.workflowItem.replyingParty;




        this.replyPeriod = props.workflowItem.replyPeriod;


    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;
        return (
            <Routes caseSensitive>
                <Route path={workflowItem.paths.basePath} exact element={<Navigate replace to={workflowItem.paths.defaultPath} />} />
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<ExtensionList
                        {...this.props}
                        mode={this.props.mode}
                        
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                    />}

                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                            <ExtensionCreate
                                {...this.props}
                                replyPeriod={this.replyPeriod}
                                mode={this.props.mode}
                                instructionAbbreviation={this.instructionAbbreviation}
                                title={this.notificationName}
                                
                                communicateToUser={this.communicateToUser}
                            />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    exact
                    element={<ExtensionDetail
                        {...this.props}
                        replyPeriod={this.replyPeriod}
                        mode={this.props.mode}

                        earlyWarningTitle={this.earlyWarningTitle}
                        instructionAbbreviation={this.instructionAbbreviation}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                        documentType={this.documentType}
                        communicateToUser={this.communicateToUser}
                        notifyUserFilter={workflowItem.notifyUserFilter}
                    />}
                />
            </Routes>
        );
    }
};




export default (ExtensionRouter);