/* eslint-disable import/no-anonymous-default-export */
import Workflows from "./Workflows";
import Placeholders from "./Placeholders";
export default {
    id: 117,
    title: 'Certificates',
    path: 'certificates',
    icon: "flaticon2-list-3",
    workflows: Workflows,
    placeholders: Placeholders
};