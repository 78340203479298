import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AggregatedReports from "./Aggregated";
import Protector from "../../router/Protector/Protector";

class DownloadsRouter extends React.Component {
    render() {
        return (
            <Routes caseSensitive>
                <Route path="/aggregate" element={<Protector><AggregatedReports/></Protector>} sensitive/>
                <Route path="*" element={<Navigate replace to="/contracts" />} />
            </Routes>
        );
    }

}


export default DownloadsRouter;