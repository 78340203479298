import React from 'react';
import * as fileCrud from '../../../../crud/file.crud';
import Document from '../../_CustomComponents/DocumentV2';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { Alert } from "react-bootstrap";
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import { Validator, CustomRule, } from 'devextreme-react/validator';
import { CheckBox } from 'devextreme-react/check-box';
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";
class ReplyDocument extends BaseDocument {
    constructor(props) {
        super(props);
        this.rejectOptions = props.placeholders.find(ph => ph.replacementKey === 'ReplyRejectOptions')
            .values.filter(item => item.isActive === true);
        this.rejectOptions = this.rejectOptions.map(opt => ({ ...opt, validatorRef: React.createRef() }));

        this.replyOptions = props.placeholders.find(ph => ph.replacementKey === 'ReplyOptions')
            .values.filter(item => item.isActive === true);

        // this.replyOptions = [
        //     { value: 'Accepted', display: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.Accepted' }) },
        //     { value: 'Not Accepted', display: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.Not Accepted' }) }
        // ]

        const initialOptions = this.rejectOptions.reduce((obj, option) => ({ ...obj, [option.field]: props.reply[option.field] }), {});

        this.replyFormConfig = {
            fields: [
                { label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.STATUS' }), dataField: 'status', editorType: 'dxSelectBox', editorOptions: { dataSource: this.replyOptions, valueExpr: 'field', displayExpr: 'display' } },
                { label: props.resources.ReplyRejectReasonLabel, render: this.renderCheckboxes, visible: (obj) => obj.status === 'Not Accepted', },
                { resource: "GENERAL.FORMS.FIELD.COMMENTS", dataField: 'comments', editorType: 'dxHtmlEditor' },
            ]
        };

        this.state = {
            rejectOptions: initialOptions
        };

    }
    onCheckboxValueChanged = (field, value) => {
        const anyWasSelected = this.rejectOptions.some(type => this.state.rejectOptions[type.field] === true);
        this.state.rejectOptions[field] = value;

        //FIRST ITEM SELECTED
        if (anyWasSelected === false && value === true) {
            //CLEAR ANY VALIDATION ERRORS ON OTHER BOXES
            this.rejectOptions.forEach(itm => {
                if (itm.field !== field) {
                    itm.validatorRef.current.instance.reset()
                }
            });
        }
        this.props.fieldChanged(field, value);
    }
    renderCheckboxes = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    this.rejectOptions.map((option, index) =>
                    (
                        <CheckBox
                            key={index}
                            style={{ marginTop: 7, marginBottom: 4 }}
                            defaultValue={this.state.rejectOptions[option.field] ? this.state.rejectOptions[option.field] : false}
                            onValueChanged={(args) => this.onCheckboxValueChanged(option.field, args.value)}
                            text={option.display} >
                            <Validator ref={option.validatorRef}>
                                <CustomRule
                                    type="custom"
                                    validationCallback={(e) => {
                                        if (e.value === true) { return true; }
                                        const validationresult = this.rejectOptions.some(opt => this.state.rejectOptions[opt.field] === true && opt.field !== option.field);
                                        return validationresult;
                                    }}
                                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING.ONE_OPTION_REQUIRED' })}
                                />
                            </Validator>
                        </CheckBox>
                    ))
                }
            </div>
        );
    }

    renderChackedRejectItems = () => {
        const items = this.rejectOptions.filter(itm => (this.props.notification[itm.field] && this.props.notification[itm.field] === 1));
        return (
            <ul style={{ listStyleType: 'none', paddingInlineStart: 0 }}>
                {items.map((item, index) => (
                    <li key={index}>
                        <i className="fas fa-chevron-right"></i>
                        <span style={{ paddingLeft: 5 }}>{item.display}</span>
                    </li>
                ))}
            </ul>
        );
    }



    renderForm = () => {
        return (
            <BiDynamicForm
                isReply={true}
                item={this.props.reply}
                config={this.replyFormConfig}
                valueChangeHandler={this.props.fieldChanged}
                customFields={(this.props.customFields || {}).REPLY}
            />
        );
    }
    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType + 'Reply', this.props.notification.programid, data, config)
    }
    render() {
        const documentIsFinal = !this.props.isAwaitingReply;
        const showNote = !documentIsFinal && this.props.notification.draftreplydate;


        return (
            <>
                {
                    showNote && (
                        <Alert variant="secondary">
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFTED" values={{ PERSON_NAME: this.props.notification.draftreplyby, TIME_STAMP: moment(this.props.notification.draftreplydate).format('L') }} />
                                <br />
                                {
                                    this.props.notification.draftreplyto && (
                                        <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT_NOTIFIED" values={{ PERSON_NAME: this.props.notification.draftreplyto }} />
                                    )
                                }
                            </p>
                        </Alert>
                    )
                }
                <Document
                    key={documentIsFinal}
                    docConfig={this.props.notification.pdfreplyjsoncontent}
                    isFinal={documentIsFinal}
                    printDivRef={this.props.printDivRef}
                    workflowItem={this.props.workflowItem}
                    contract={this.props.contract}
                    reference={this.props.notification.referenceid}
                    form={documentIsFinal ? null : this.renderForm()}
                    uploadMethod={documentIsFinal ? null : this.uploadFile}
                    files={this.props.files}
                    notificationid={this.props.notificationid}
                    documentType={this.props.documentType}
                    isReply={true}
                    customFields={(this.props.customFields || {}).REPLY}
                    customFieldValues={this.props.notification.customfieldvalues}
                    assetid={this.props.notification.assetid}
                />
            </>
        );

    }
}


export default injectIntl(ReplyDocument);
