/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
// import { signOut } from "supertokens-auth-react/recipe/session";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import * as reactRouterDom from "react-router-dom";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { connect } from "react-redux";
import * as authDuck from "../../../store/ducks/auth.duck";
import Spinner from '../../../partials/layout/Spinner';
import { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokenConfig } from "../../../shared/config/SuperTokens";
import SuperTokens from "supertokens-auth-react";
import QueryString from "query-string";
import { TextBox } from 'devextreme-react';
import { FormattedMessage } from "react-intl";
import "../../../../_metronic/_assets/sass/pages/login/login-auth0.scss";



const SuperTokenHandler = (props) => {

    const navigate = useNavigate();
    const sessionContext = useSessionContext();
    let location = useLocation();
    const queryObj = QueryString.parse(location.search);

    useEffect(() => {
        // console.log("SuperTokenHandler Mounted!")
        if (location.pathname === "/auth/discover/supertoken") {
            const discovrAutoRedirect = setTimeout(async () => {
                if (props.btnRef?.current) {
                    props.btnRef?.current.click();
                }
                else {
                    console.log("SuperTokenHandler Can not redirect due to missing reference", props.btnRef)
                }
            }, 1000);

            return () => {
                clearTimeout(discovrAutoRedirect);
            }
        }
    }, [])



    useEffect(() => {
        // console.log("Current session state", JSON.parse(JSON.stringify(sessionContext)))
        if (sessionContext && !sessionContext.loading && sessionContext.doesSessionExist && sessionContext.accessTokenPayload && sessionContext.accessTokenPayload.access_token && sessionContext.accessTokenPayload.access_token.accessToken) {
            console.log("SuperTokenHandler setting redux token")
            props.storeSetAuthToken(
                sessionContext.accessTokenPayload.access_token.accessToken,
                sessionContext.accessTokenPayload.access_token.reportingToken,
                "",
                "",
                "SUPERTOKEN");
        }
        else if (sessionContext && !sessionContext.loading && sessionContext.doesSessionExist) {
            //PROBLEMATIC STATE
            if (!sessionContext.accessTokenPayload || !sessionContext.accessTokenPayload.access_token || !sessionContext.accessTokenPayload.access_token.accessToken) {
                console.log("Supertoken Session Created with missing access token", sessionContext)
                navigate(`/error/error-v4`);
            }
        }
        else if (sessionContext && !sessionContext.loading && !sessionContext.doesSessionExist) {
            //NO SESSION
            //WAITING FOR LOGIN CLICK            
            if (process.env.REACT_APP_SSO_METHOD === "DISCOVER" && !location?.state?.tennantid && Object.keys(queryObj).length === 0) {
                console.log("tennantid is missing for SuperTokenAuth, will be redirected to Discover")
                navigate("/auth/discover");
            }
        }

    }, [sessionContext]);

    // async function logoutClicked(e) {
    //     e.preventDefault();
    //     await signOut();
    //     props.startLogout();
    // }
    return (

        <div style={{ margin: "auto" }}>
            {/*SuperTokens - {process.env.REACT_APP_VERSION}
            <div>Auth0 Handler Page</div>
            <div>Bearer Token</div>
            <div>{token}</div> 
            <div
                style={{
                    display: "flex",
                    height: "70px",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: "75px",
                    paddingRight: "75px",
                    cursor: "pointer"
                }}
            >
                <button
                    onClick={logoutClicked}
                >
                    <h3 className="text-danger">Logout</h3>
                </button>
            </div>*/}
            {/* <SuperTokensWrapper> */}
            {/* <SignInAndUp/> */}
            <Routes caseSensitive>
                {/*This renders the login UI on the /auth route*/}
                {getSuperTokensRoutesForReactRouterDom(reactRouterDom)}
                <Route path="/auth/discover/supertoken/callback/*" element={<Spinner />} />
                <Route path="/auth/callback/*" element={<Spinner />} />
                {process.env.REACT_APP_SSO_METHOD === "SUPERTOKEN" && (
                    <Route path="*" element={<Navigate replace to={"/auth"} />} />
                )}
                {/* {(!sessionContext?.doesSessionExist && !sessionContext?.loading && Object.keys(queryObj).length === 0) && (
                    <Route path="*" element={<Navigate replace to={process.env.REACT_APP_SSO_METHOD === "DISCOVER" ? "/auth/discover" : "/auth"} />} />
                )} */}
                {/* <Navigate replace to="/" /> */}
            </Routes>
            {/* </SuperTokensWrapper> */}
            {sessionContext.doesSessionExist && (
                <Spinner />
            )}
        </div>

    )
}

const mapDispatchToProps = {
    storeSetAuthToken: authDuck.actions.setAuthToken,
    startLogout: authDuck.actions.startLogout
};
const renderCustomFormFields = (username) => {
    if (process.env.REACT_APP_SSO_METHOD === 'DISCOVER') {
        return (
            <div>
                <div style={{
                    margin: "10px 0px",
                    padding: "10px",
                    color: " #270",
                    backgroundColor: "#DFF2BF",
                    letterSpacing: "0.2px",
                    fontSize: "14px",
                    borderRadius: "8px",
                    overflowWrap: "break-word",
                    fontFamily: "Rubik, sans-serif"
                }}>
                    <FormattedMessage id="SUPERTOKEN.SUCCESS_FOUND_TENANT" />
                </div>
                <TextBox
                    value={username}
                    disabled={true}
                    readOnly={true}
                />
            </div>
        )
    }
    else { return null; }

}
export const superTokenWrapper = (Component) => {
    function SuperTokenWrapper(props) {
        let location = useLocation();
        let tennantid = "";
        let username = "";
        if (location.pathname.startsWith('/auth/discover/supertoken')) {
            if (location?.state?.tennantid) {
                tennantid = location.state.tennantid;
                username = location.state.username;
            }
            else {
                try {
                    const dscState = JSON.parse(localStorage.getItem('auth-discover-user'));
                    // console.log("Discover state restored from cookies",dscState)
                    tennantid = dscState.tennantid;
                    username = dscState.username;
                } catch (error) {
                    console.log("problem setting up supertoken config",error)
                }

            }
        }

        const btnRef = useRef(null);
        const config = getSuperTokenConfig(
            tennantid,
            renderCustomFormFields(username),
            btnRef
        );

        SuperTokens.init(config);
        return (
            <SuperTokensWrapper>
                <Component
                    {...props}
                    btnRef={btnRef}
                />
            </SuperTokensWrapper>
        );
    }

    return SuperTokenWrapper;
}


export default superTokenWrapper(connect(null, mapDispatchToProps)(SuperTokenHandler));
