import React from 'react';
import { TableRow, TableHead, TableBody, Table, Typography } from "@mui/material";

class TableArea extends React.Component {
    render() {
        return (
            <div style={{ marginTop: 20 }}>
                <Typography style={{ marginLeft: 16 }} variant="h6" component="div" gutterBottom>
                    {this.props.title}
                </Typography>
                <Table style={{ border: '1px solid #ccc', borderRadius: '2px' }}>
                    <TableHead>
                        <TableRow>
                            {this.props.items.header.map(itm => itm)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {this.props.items.row.map(itm => itm)}
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export default TableArea;