import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import WarningList from './List';
import WarningCreate from './Create';
import WarningDetail from './Detail';

class EarlyWarningRouter extends React.Component {
    constructor(props) {
        super(props);

        if (props.mode === 'SUPPLIER') {
            this.documentType = 'Document161CON';
        }
        else if (props.mode === 'PM') {
            this.documentType = 'Document161PM';
        }
        else if (props.mode === 'CLIENT') {
            this.documentType = 'Document161CLI';
        }

        this.replyingParty = props.workflowItem.replyingParty;

        this.communicateToUser = props.globalResources[this.replyingParty + "_NAME"];

        this.replyPeriod = props.workflowItem.replyPeriod;
        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;

        ////////////////////////////////////////////////////////////////////////////
        this.riskStatusOptions = [];
        if (props.placeholdersQA.RiskStatusOptions) {
            this.riskStatusOptions = props.placeholdersQA.RiskStatusOptions.values.filter(item => item.isActive === true);
        }
        ////////////////////////////////////////////////////////////////////////////
        this.meetingOptions = [];
        if (props.placeholdersQA.MeetingOptions) {
            this.meetingOptions = props.placeholdersQA.MeetingOptions.values.map(option => ({ ...option, field: Number(option.field) }));
        }
        ////////////////////////////////////////////////////////////////////////////
        this.riskTypes = [];
        //USE RiskTypesSupplier for Supplier if exist
        if (props.mode === 'SUPPLIER' && props.placeholdersQA.RiskTypesSupplier) {
            this.riskTypes = props.placeholdersQA.RiskTypesSupplier.values.filter(riskItem => riskItem.isActive === true);
        }
        //USE RiskTypesClient for CLIENT if exist
        else if (props.mode === 'CLIENT' && props.placeholdersQA.RiskTypesClient) {
            this.riskTypes = props.placeholdersQA.RiskTypesClient.values.filter(riskItem => riskItem.isActive === true);
        }
        //else use common RiskTypes
        else if (props.placeholdersQA.RiskTypes) {
            this.riskTypes = props.placeholdersQA.RiskTypes.values.filter(riskItem => riskItem.isActive === true);
        }
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;
        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<WarningList
                        {...this.props}
                        replyPeriod={this.replyPeriod}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                    />}
                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                            <WarningCreate
                                {...this.props}
                                meetingOptions={this.meetingOptions}
                                riskTypes={this.riskTypes}
                                replyPeriod={this.replyPeriod}
                                title={this.notificationName}
                                abbreviation={this.abbreviation}
                                communicateToUser={this.communicateToUser}
                            />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    exact
                    element={
                        <WarningDetail
                            {...this.props}
                            riskTypes={this.riskTypes}
                            replyPeriod={this.replyPeriod}
                            title={this.notificationName}
                            abbreviation={this.abbreviation}
                            documentType={this.documentType}
                            communicateToUser={this.communicateToUser}
                            meetingOptions={this.meetingOptions}
                            riskStatusOptions={this.riskStatusOptions}
                        />
                    }
                />
            </Routes>
        );
    }
};




export default (EarlyWarningRouter);