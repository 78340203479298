import React from "react";
import { withSnackbar } from "notistack";
import {
  Portlet,
  PortletBody,
} from "../../partials/content/Portlet";
import * as administratorCrud from "../../crud/administrator.crud";
import DataGridBase from "../../partials/layout/DataGridBase";
import { connect } from "react-redux";
import PortletSpinner from "../../partials/layout/PortletSpinner";
import moment from 'moment';
import { injectIntl } from "react-intl";

const isFinancialLimitEnabled = process.env.REACT_APP_FINANCIAL_LIMIT_ENABLED === "true";

class ContractUsersReport extends React.Component {
  constructor(props) {
    super(props);
    this.gridRef = React.createRef();

    this.state = {
      // processing: false,
      usersList: null,
    };

    this.columns = [
      {
        caption: "Contract ID",
        dataField: "contractid",
        dataType: "number",
        visible: false,
      },
      { caption: "Contract Name", dataField: "contractname" },
      { caption: "PM Company", dataField: "contractorcompany" },
      { caption: "Supplier Company", dataField: "suppliercompany" },
      { caption: "Supervisor Company", dataField: "supervisorcompany" },
      {
        caption: "System Level User ID",
        dataField: "systemuserid",
        dataType: "number",
        headerFilter: { search:{ enabled:false } },
      },
      {
        caption: "Contract Level User ID",
        dataField: "contractuserid",
        dataType: "number",
        visible: false,
      },
      { caption: "Username", dataField: "username", visible: false },
      { caption: "Name", dataField: "name" },
      { caption: "User's Company", dataField: "userscompany" },
      { caption: "Email", dataField: "email", visible: false },
      { caption: "Party", dataField: "party" },
      { caption: "Role", dataField: "role" },
      {
        resource: "CONTRACT.USERS.FIELD.EMAILS_DISABLED",
        dataField: "emailsdisabled",
        dataType: "boolean",
        visible: false,
      },
      {
        resource: "COMPANY.USER.FIELD.EMAILS_OPTEDOUT",
        dataField: "optoutemailnotifications",
        dataType: "boolean",
        visible: false,
      },
      { caption: "System Level User Type", dataField: "systemusertype" },
      { caption: "Last Edited", dataField: "dateadded", dataType: "date", format: (date) => moment(date).format('DD/MM/YYYY HH:mm') },
      { caption: "Added By", dataField: "addedby" },
      { caption: "Client Company", dataField: "clientcompany" },
    ];
    if (isFinancialLimitEnabled) {
      this.columns.push(
        { resource: "GENERAL.FORMS.FIELD.FINANCIAL_LIMIT_AMOUNT", dataField: "userlimit" },
        { resource: "GENERAL.FORMS.FIELD.FINANCIAL_LIMIT_CURRENCY", dataField: "userlimitcurrency" },
        { resource: "GENERAL.FORMS.FIELD.CONTRACT_LIMIT_AMOUNT", dataField: "contractuserlimit" },
        { resource: "GENERAL.FORMS.FIELD.CONTRACT_LIMIT_CURRENCY", dataField: "contractuserlimitcurrency" }       
      )
    }
  }

  componentDidMount() {
    this.loadReport();
  }

  loadReport = () => {
    administratorCrud
      .getContractUsersReport()
      .then((response) => {
        this.setState({ usersList: JSON.parse(response.data) });
      })
      .catch((err) => {
        this.props.enqueueSnackbar(err.toString(), { variant: "error", });
      });
  };

  render() {
    if (!this.state.usersList) {
      return <PortletSpinner />;
    }
    return (
      <Portlet>
        <PortletBody>
          <DataGridBase
            version="4"
            title="Contract Users Register"
            name="Contract Users"
            dataSource={this.state.usersList}
            hoverStateEnabled={false}
            rowAlternationEnabled={true}
            columns={this.columns}
          />
        </PortletBody>
      </Portlet>
    );
  }
}

const mapStateToProps = (store) => ({
  // contracts: store.contracts.contracts,
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(ContractUsersReport)));
