import axios from '../shared/axios';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function getContractPrograms(companyid, projectid, contractid, accessibleWfIds) {
    return axios.post(`/api/Program/LoadProgramsList`, { accessibleWfIds }, { params: { companyid, projectid, contractid } });
}

export function getProgram(programid) {
    return axios.get(`/api/Program/GetProgram?programid=${programid}`);
}

export function saveContractProgram(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/Program/SaveProgram`, data)
            .then(result => {
                // if (data.showtasknotification === 1 ) {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                // }
                resolve(result);
            })
            .catch(error => reject(error));
    });
}

export function updateProgram(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/Program/Update`, data)
            .then(result => {
                if (data.actioncode === 'COMMUNICATE' || data.actioncode === 'REPLY_SEND') {
                    store.dispatch({
                      type: contractDuck.actionTypes.CONTRACT_REFRESH_CONTRACT,
                    });
                  }
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}

export function deleteProgramDraft(programid) {
    new Promise((resolve, reject) => {
    return axios.post(`api/Program/DeleteDraft?programid=${programid}`)
    .then(result => {
        store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
        resolve(result);
    })
    .catch(error => reject(error));
})
}