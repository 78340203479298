/* eslint-disable no-redeclare */
import Common from '../../../shared/common';
import moment from 'moment';

export default class CustomFieldHelper {
    static MapDataListCustomFieldsToObjectProperties(list) {
        list = list.map(item => {
            if (item.customfieldvalues && item.customfieldvalues.length > 0) {
                item.customfieldvalues.forEach(cfv => {
                    item["CF_" + cfv.customfieldid] = cfv.value;
                });
            }
            return item;
        });
        return list;
    }
    static MapCustomFieldsToDataGridColumns(customFields) {
        const isCustomFieldsEnabled = process.env.REACT_APP_CUSTOM_FIELDS_ENABLED === "true";
        const columns = []
        if (isCustomFieldsEnabled && customFields && customFields.length > 0) {
            customFields.forEach(cf => {
                const columnProp = { caption: cf.label, dataField: "CF_" + cf.customfieldid, visible: false };
                if (cf.fieldtype === "DATE") {
                    columnProp.dataType = 'date';
                }
                if (cf.fieldtype === "DATETIME") {
                    columnProp.dataType = 'datetime';
                }
                if (cf.fieldtype === "SINGLE_CONTRACT_USER") {
                    columnProp.calculateCellValue = cellInfo => this.FormatCustomField(cellInfo, "CF_" + cf.customfieldid, cf.fieldtype);
                }
                if (cf.fieldtype === "MULTIPLE_CONTRACT_USERS") {
                    columnProp.calculateCellValue = cellInfo => this.FormatCustomField(cellInfo, "CF_" + cf.customfieldid, cf.fieldtype);
                }
                columns.push(columnProp);
            });
        }
        return columns;
    }
    static FormatCustomField = (cellInfo, cfid, cftype) => {
        if (cftype === "SINGLE_CONTRACT_USER") {
            var value = cellInfo[cfid];
            
            try {
                return JSON.parse(value).name;
            } catch (error) {
                return value;
            }
        } else if (cftype === "MULTIPLE_CONTRACT_USERS") {
            var value = cellInfo[cfid];

            try {
                var valueArray = JSON.parse(value).map(u => u.name)
                .filter(name => name !== null)
                .join(", ");

                return valueArray;
            } catch (error) {
                return value;
            }
        }
    }
    static MapCustomFieldsToDocumentConfiguration(customFields, customFieldValues, bodyConfig, isReply, enqueueSnackbar, currencyCode) {
        if (customFieldValues && customFieldValues.length > 0) {
            const customFieldsConfig = customFieldValues
                .filter(cfv => (isReply ? ["REPLY", "QUOTE_REPLY", "RFQQUOTE_REPLY", "ITQQUOTE_REPLY"].includes(cfv.subtype) : ["COMMUNICATION", "QUOTE", "EWREDUCTION", "RFQQUOTE", "ITQQUOTE"].includes(cfv.subtype)))
                .map(cfv => {
                    try {
                        const cf = customFields.find(cf => cf.customfieldid === cfv.customfieldid)
                        if (!cf) { throw new Error("[Error] - custom field definition not found ID: " + cfv.customfieldid) };
                        let cfvalue = cfv.value;
                        let cfvalueType = null;
                        if (cf.fieldtype === "CHECKBOX") {
                            cfvalue = cfvalue === "true" ? "Checked" : "Unchecked";
                        }
                        if (cf.fieldtype === "MULTI_SELECT" || cf.fieldtype === "MULTIPLE_CONTRACT_USERS") {
                            let valueArray = [];
                            if(typeof cfvalue === "string"){
                                valueArray = cfvalue.split('|')
                            }
                            else if((cfvalue.constructor||{}).name === "Array"){
                                valueArray = cfvalue;
                            }
                            cfvalue = valueArray.join(', ');
                        }
                        if (cf.fieldtype === "DATE" || cf.fieldtype === "DATETIME") {
                            cfvalue = new Date(cfvalue);
                            cfvalueType = cf.fieldtype === "DATETIME" ? "datetime" : null;
                        }
                        if (cf.fieldtype === "NUMBER") {
                            cfvalue = new Intl.NumberFormat('en-GB',{
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(Number(cfvalue))
                        }
                        if (cf.fieldtype === "INTEGER") {
                            cfvalue = new Intl.NumberFormat('en-GB',{
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(Number(cfvalue))
                        }
                        if (cf.fieldtype === "CURRENCY") {
                            cfvalue =  Common.FormatCurrencyNum(cfvalue, currencyCode);
                        }

                        return {
                            customfieldid: cfv.customfieldid,
                            label: cf.label,
                            value: cfvalue,
                            fieldorder: cfv.fieldorder,
                            valueType: cfvalueType
                        };
                    }
                    catch (err) {
                        if (enqueueSnackbar) {
                            enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
                        }
                        return { note: "[Error] - custom field definition not found ID: " + cfv.customfieldid };
                    }

                })
                .sort((a, b) => a.fieldorder - b.fieldorder)
            bodyConfig.push(...customFieldsConfig);
        }

    }
    static MapCustomFieldsToDocumentConfigurationV2(customFields, notification, bodyConfig, isReply, enqueueSnackbar, currencyCode, contractUsers) {
        if (customFields && customFields.length > 0) {
            const customFieldsConfig = customFields.filter(cf => cf.isactive).map(cf => {
                let value = "";
                const additionalProps = {}
                if(notification.hasOwnProperty("CF_" + cf.customfieldid)){
                    value = notification["CF_" + cf.customfieldid];
                }
                else{
                    const cfvUnchangedValue = (notification.customfieldvalues||[]).find(cfv=>cfv.customfieldid===cf.customfieldid);
                    if(cfvUnchangedValue){
                        value = cfvUnchangedValue.value;
                    }
                }

                if (value) {
                    if (cf.fieldtype === "CHECKBOX") {
                        value = value === "true" ? "Checked" : "Unchecked";
                    }
                    if (cf.fieldtype === "SINGLE_CONTRACT_USER") {
                        if (typeof value === "number") {
                            const singleUserSelectionOnContractUsers = contractUsers.find(u => u.accountid === value);
                            if(!singleUserSelectionOnContractUsers){
                                throw Error("Could not find user selection in Contract-Users!")
                            }
                            value = singleUserSelectionOnContractUsers.user;
                        } else {
                            value = JSON.parse(value).name;
                        }
                    }
                    if (cf.fieldtype === "MULTI_SELECT" || cf.fieldtype === "MULTIPLE_CONTRACT_USERS") {
                        let valueArray = [];

                        if (typeof value === "string") {
                            valueArray = value.split('|');

                            if (cf.fieldtype === "MULTIPLE_CONTRACT_USERS") {
                                valueArray = JSON.parse(value).map(u => u.name)
                                    .filter(name => name !== null) // Filter out null values
                                    .join(", "); // Join the names with a delimiter
                            } else {
                                valueArray = valueArray.join(', ');
                            }
                        } else if ((value.constructor||{}).name === "Array") {
                            valueArray = value;

                            if (cf.fieldtype === "MULTIPLE_CONTRACT_USERS") {
                                valueArray = contractUsers
                                    .filter(u => value.includes(u.accountid))
                                    .map(u => u.user);
                            }

                            valueArray = valueArray.join(', ');
                        }

                        value = valueArray;
                    }
                    if (cf.fieldtype === "DATETIME") {
                        value = moment(value).format('DD/MM/YYYY HH:mm');
                    }
                    if (cf.fieldtype === "DATE" ) {
                        value = moment(value).format('L');
                    }
                    if (cf.fieldtype === "NUMBER") {
                        value = new Intl.NumberFormat('en-GB', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }).format(Number(value))
                    }
                    if (cf.fieldtype === "INTEGER") {
                        value = new Intl.NumberFormat('en-GB', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }).format(Number(value))
                    }
                    if (cf.fieldtype === "CURRENCY") {
                        value = Common.FormatCurrencyNum(value, currencyCode);
                    }
                    if (cf.fieldtype === "WEB_LINK") {
                        let valueArray = [];
                        valueArray = value.split('|');                        
                        additionalProps.url = valueArray[0];
                        additionalProps.text = valueArray[1];
                    }
                }

                return {
                    customfieldid: cf.customfieldid,
                    label: cf.label,
                    value: value,
                    valueType: "CUSTOMFIELD",
                    valueSubType:cf.fieldtype,
                    ...additionalProps
                };

            })
            .sort((a, b) => a.fieldorder - b.fieldorder);

            bodyConfig.push(...customFieldsConfig);
        }
    }
}