import { LogglyTracker } from 'loggly-jslogger';
import ErrorHandler from './errorHandler';
//https://builtintell.loggly.com/

//GENERATE TOKEN
//https://documentation.solarwinds.com/en/success_center/loggly/content/admin/customer-token-authentication-token.htm


//BEST PRACTICES
//https://www.loggly.com/blog/best-practices-for-client-side-logging-and-error-handling-in-react/

const logger = new LogglyTracker();
const baseTags = [
    'UI',
    window.location.hostname,
    process.env.REACT_APP_PRODUCT_KEY,
    process.env.REACT_APP_ENVIRONMENT
]


export default class Logger {
    static PushError = (errorObj, info, errorType) => {
        try {
            if (process.env.REACT_APP_LOGGLY_KEY) {
                const errMessageStack = ErrorHandler.ExtractMessageFromStack(errorObj);
                const tags = [...baseTags, 'ERROR'];
                logger.push({ 'logglyKey': process.env.REACT_APP_LOGGLY_KEY, 'tag': tags.join(',') });
                const connectionProps = Logger.GetNetworkProps();
                logger.push(
                    {
                        'type': 'ERROR',
                        'errorType': errorType,
                        productKey: process.env.REACT_APP_PRODUCT_KEY,
                        domain: window.location.origin,
                        url: window.location.href,
                        environment: process.env.REACT_APP_ENVIRONMENT,
                        errorObj,
                        errorMessageStack: errMessageStack,
                        info,
                        connection: connectionProps
                    }
                );
            }
        }
        catch { }
    };
    static PushInfo = (info, infoType) => {
        try {
            if (process.env.REACT_APP_LOGGLY_KEY) {
                const tags = [...baseTags, 'INFO'];
                logger.push({ 'logglyKey': process.env.REACT_APP_LOGGLY_KEY, 'tag': tags.join(',') });
                const connectionProps = Logger.GetNetworkProps();
                logger.push(
                    {
                        'type': 'info',
                        'infoType': infoType,
                        productKey: process.env.REACT_APP_PRODUCT_KEY,
                        domain: window.location.origin,
                        url: window.location.href,
                        environment: process.env.REACT_APP_ENVIRONMENT,
                        info,
                        connection: connectionProps
                    }
                );
            }
        }
        catch { }
    };
    static GetNetworkProps = () => {
        try {
            return {
                downlink: navigator.connection.downlink,
                effectiveType: navigator.connection.effectiveType
            };
        }
        catch {
            return {};
        }
    }
}
