import React from 'react';
import * as fileCrud from '../../../../crud/file.crud';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import Document from '../../_CustomComponents/DocumentV2';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { withSnackbar } from 'notistack';

class ProgrammeDocument extends BaseDocument {
    constructor(props) {
        super(props);
        this.editorFormConfig = {
            fields: [
                { label: props.fields.Reference, dataField: 'reference' },
            ]
        };
        if (props.fields.Type) {
            this.editorFormConfig.fields.push({ label: props.fields.Type, dataField: 'type', editorType: 'dxSelectBox',editorOptions: { dataSource: this.props.workflowItem.clauses, valueExpr: 'display', displayExpr: 'display' } })
        }
        if (props.fields.PreviousPlanned && props.contract.expectedcompletiondate) {
            this.editorFormConfig.fields.push({ label: props.fields.PreviousPlanned, dataField: 'previousplanneddate', editorType: 'dxDateBox', editorOptions: { readOnly: true, value: new Date(props.contract.expectedcompletiondate) } })
        }
        if (props.fields.PlannedCompletion) {
            this.editorFormConfig.fields.push({ label: props.fields.PlannedCompletion, dataField: 'plannedcompletiondate', editorType: 'dxDateBox' })
        }
        if (props.fields.Reason) {
            this.editorFormConfig.fields.push({ label: props.fields.Reason, dataField: 'reasonchanged', editorType: 'dxHtmlEditor' })
        }
        if (props.fields.Change) {
            this.editorFormConfig.fields.push({ label: props.fields.Change, dataField: 'details', editorType: 'dxHtmlEditor' })
        }
        if (props.fields.MainProgress) {
            this.editorFormConfig.fields.push({ label: props.fields.MainProgress, dataField: 'mainprogress', editorType: 'dxHtmlEditor' })
        }
        if (props.fields.KeyActivities) {
            this.editorFormConfig.fields.push({ label: props.fields.KeyActivities, dataField: 'keyactivities', editorType: 'dxHtmlEditor' })
        }
        if (props.fields.Summary) {
            this.editorFormConfig.fields.push({ label: props.fields.Summary, dataField: 'summarycritical', editorType: 'dxHtmlEditor' })
        }
        if (props.fields.Changes) {
            this.editorFormConfig.fields.push({ label: props.fields.Changes, dataField: 'mainchanges', editorType: 'dxHtmlEditor' })
        }
        if (props.fields.Compensation) {
            this.editorFormConfig.fields.push({ label: props.fields.Compensation, dataField: 'compensationevents', editorType: 'dxHtmlEditor' })
        }

    }


    renderForm = () => {
        return (
            <BiDynamicForm
                labelLocation="top"
                item={this.props.notification}
                config={this.editorFormConfig}
                valueChangeHandler={this.props.fieldChanged}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
            />
        );
    }


    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.programid, data, config)
    }


    render() {
        const documentIsFinal = this.props.notification.showtasknotification === 1;
        return (
            <Document
                key={documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.renderForm()}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.notification.assetid}
            />
        );
    }
}

export default withSnackbar(ProgrammeDocument);
