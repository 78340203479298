import axios from '../shared/axios';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function saveCertificate(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/Certificate/SaveCertificate`, data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}

export function updateCertificate(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/Certificate/Update`, data)
            .then(result => {
                if (data.actioncode === 'COMMUNICATE' || data.actioncode === 'REPLY_SEND') {
                    store.dispatch({
                      type: contractDuck.actionTypes.CONTRACT_REFRESH_CONTRACT,
                    });
                  }
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}

export function getCertificate(certificateid) {
    return axios.get(`/api/Certificate/GetCertificate?certificateid=${certificateid}`);
}

export function getCertificates(companyid, projectid, contractid, type, accessibleWfIds) {
    return axios.post(`/api/Certificate/LoadCertificatesList`, { accessibleWfIds }, { params: { companyid, projectid, contractid, type } });
}

export function deleteCertificateDraft(certificateid) {
    return new Promise((resolve, reject) => {
        axios.post(`api/Certificate/DeleteDraft?certificateid=${certificateid}`)
        .then(result => {
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
            resolve(result);
        })
        .catch(error => reject(error));
    })
}