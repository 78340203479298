import axios from "../shared/axios";

export function getOptions() {
  return axios.get("/api/Option/GetPublicOption");
}


export function getCompanyAreas(companyid = 1) {
  return axios.post(
    `/api/CompanyAreaHub/LoadCompanyAreaHubsList?companyid=${companyid}&category=area`
  );
}

export function getCompanyHubs(companyid = 1) {
  return axios.post(
    `/api/CompanyAreaHub/LoadCompanyAreaHubsList?companyid=${companyid}&category=hub`
  );
}

export function createCompanyAreaOrHub(data) {
  return axios.post("/api/CompanyAreaHub/SaveCompanyAreaHub", data);
}

export function getSmartLinks() {
  return axios.get("/api/SystemLinks/GetList");
}
