import React from "react";
import { Route, Routes } from "react-router-dom";
import RfiListAll from './Rfi/ListAll';


class MasterRfitqRouter extends React.Component {
    constructor(props) {
        super(props);

        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;
        this.abbreviationWF1 = (this.props.workflowsQA['34'] || {}).abbreviation;
        this.abbreviationWF2 = (this.props.workflowsQA['80'] || {}).abbreviation;

        // const superWf = props.workflowGroupItem.workflows.find(wf => wf.id === 23);
        // if (superWf) {
        //     this.notificationAbbreviation_SUPER = superWf.abbreviation;
        //     this.canActionSuper = superWf.userFilters.actionTab.actionAllowed;
        // }
        // const supplierWf = props.workflowGroupItem.workflows.find(wf => wf.id === 24);
        // if (supplierWf) {
        //     this.notificationAbbreviation_SUPP = supplierWf.abbreviation;
        //     this.canActionSupplier = supplierWf.userFilters.actionTab.actionAllowed;
        // }



    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;


        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<RfiListAll
                        {...this.props}
                        // notificationAbbreviation_SUPER={this.notificationAbbreviation_SUPER}
                        // notificationAbbreviation_SUPP={this.notificationAbbreviation_SUPP}
                        abbreviationWF1={this.abbreviationWF1}
                        abbreviationWF2={this.abbreviationWF2}
                        contractTemplate={this.props.contractTemplate}
                        contractUser={this.props.contractUser}
                        title={workflowItem.title}
                        // canActionSuper={this.canActionSuper}
                        // canActionSupplier={this.canActionSupplier}
                        // plannedClosureEnabled={this.plannedClosureEnabled}
                        // NCSubmissionWFexist={this.NCSubmissionWFexist}
                        // NCSubmissionReplyOptions={this.NCSubmissionReplyOptions}
                        // NonConformityReplyOptions={this.NonConformityReplyOptions}
                    />}
                />
            </Routes>
        );
    }
};




export default MasterRfitqRouter;