import React from 'react';
import { withSnackbar } from 'notistack';
import * as fileCrud from '../../../../crud/file.crud';
import Document from '../../_CustomComponents/DocumentV2';
import { connect } from "react-redux";
import CertificateCustomForm from './CustomForm';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { injectIntl } from "react-intl";

class CertificateDocumentMain extends BaseDocument {
    renderForm = () => {
        return (
            <CertificateCustomForm
                {...this.props}
                item={this.props.notification}
                valueChangeHandler={this.props.fieldChanged}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
            />
        );
    }

    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.certificateid, data, config)
    }

    render() {
        const documentIsFinal = this.props.notification.showtasknotification === 1;
        return (
            <Document
                key={documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.renderForm()}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.notification.assetid}
            />
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(CertificateDocumentMain)));
