import {placeholderTypes} from "../../Types/Placeholder";
const placeholders =  [
    {
        replacementKey: 'Resources', values:[ ],
        type:placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'ActionReminders', values:[],
        type:placeholderTypes.ACTION_REMINDERS
    },
];
export default placeholders;