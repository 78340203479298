import React from 'react';
import { withSnackbar } from 'notistack';
import { Portlet, PortletBody, PortletHeader } from "../../../partials/content/Portlet";
import moment from 'moment';
import { LinearProgress } from "@mui/material";
import { injectIntl } from "react-intl";
import BiDynamicForm from '../../../partials/layout/BiDynamicForm';
import SimpleBox from '../../../partials/content/SimpleBox';
import DataGrid, { Column, ColumnFixing, Scrolling, HeaderFilter, FilterRow, FilterPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
import * as powerBiExportCrud from '../../../crud/powerBiExport.crud';
import { connect } from "react-redux";
import saveAs from 'file-saver';
class PowerBIExports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            powerBIExportResults: null,
            accessToken: "",
            processing: false,
            downloadInfo: null
        };
    }

    componentDidMount() {
        this.getExportStatus();
        this.setRefreshTimer();
    }

    
    setRefreshTimer = () => {
        this.timer = setTimeout(() => {
            if (!this.state.processing) {
                this.getExportStatus();
            }
            this.setRefreshTimer();
        }, 20000);
    }
    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
            
        }
    }
    
    getExportStatus = () => {
        const reportToken = { reportToken: this.props.authToken.reportingToken };
        
        powerBiExportCrud.getExportStatus(reportToken)
            .then(resp => {
              
                this.setState({ powerBIExportResults: resp.data });
            })
            .catch(err => {
                console.log(err);
            });
    }


    
    isDownloadIconVisible = (e) => {
        
        return e.row.data.percentcomplete === 100
          
    }
    onDownloadClick = (e) => {
        
        
        const exportData = {
            powerbiexportid: e.row.data.powerbiexportid,
            reportToken: this.props.authToken.reportingToken
        };
        const reportExtension = e.row.data.reportextension
        e.event.preventDefault();
        

        this.setState({ processing: true, downloadInfo: null }, () => {
            powerBiExportCrud.downloadPowerBIExport(exportData)
                .then(resp => {
                    this.saveFileToDisk(resp, reportExtension);
                })
                .catch(err => {
                    
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                    console.log(err);
                });
        });

    }
    saveFileToDisk = async (dataArr, reportExtension) => {
        
        const exportReportName = this.props.pbix
        
        
        try {
            
            const link = document.createElement('a');

            // var binaryData = [];
            // binaryData.push(dataArr);
            // const url = window.URL.createObjectURL(new Blob(binaryData, { type: "application/octet-stream" }))
            const blob =  new Blob ([dataArr.data]);
            const downloadUrl = window.URL.createObjectURL(blob);
            link.href = downloadUrl;
            // link.download = exportReportName + reportExtension;
           
 
            const date = new Date();
            const formattedDate = date
                .toISOString()
                .replace(/[-:T]/g, "")
                .slice(0, 14); // Format: yyyyMMddHHmmss
            let filename = `Export_${exportReportName}-${formattedDate}${reportExtension}`;
 
            
 
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'REPORTS.MESSAGE.POWERBIEXPORTCOMPLETED' }), { variant: 'success' });

        } catch (error) {
            this.props.enqueueSnackbar(error.toString(), { variant: 'error', });
            console.log(error);
        }
    }

    
    render() {
        return (
            <Portlet>
                <PortletHeader
                    title={this.props.intl.formatMessage({ id: 'REPORTS.TITLE.POWERBIEXPORTS' })} />
                <PortletBody>
                    <div style={{ height: 20 }} />
                    <div style={{ width: '100%', height: '100%' }}>
                        <DataGrid
                            dataSource={this.state.powerBIExportResults}
                            height={470}
                            name="PowerBIExports"
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            remoteOperations={false}
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            rowAlternationEnabled={true}
                            allowColumnResizing={true}
                            columnResizingMode='widget'
                            wordWrapEnabled={true}
                            noDataText={this.props.intl.formatMessage({ id: 'REPORTS.TITLE.EXPORTS' })}
                            style={{ width: '100%', height: '100%', maxHeight: '67vh', ...this.props.style }}
                        >
                            <HeaderFilter visible={true} />
                            <FilterRow applyFilter="auto" visible={true} />
                            <FilterPanel visible={true} />
                            <ColumnFixing enabled={true} />
                            <Column  caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.POWERBIEXPORTID' })} dataField="powerbiexportid"  visible={false} />
                            <Column  caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.REPORTNAME' })} dataField="reportname" />
                            <Column  caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.CREATEDATETIME' })} dataField="createddatetime" dataType='date' format={(date) => moment(date).format('DD/MM/YYYY HH:mm')} />
                            <Column  caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.EXPORTSTATUS' })} dataField="status" />
                            <Column  caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.PERCENTCOMPLETE' })} dataField="percentcomplete"cellRender={(data) => {
                                return <span>{data.value}%</span>;
                            }} />
                            <Column  caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.LASTACTIONDDATETIME' })} dataField="lastactionddatetime" dataType='date' format={(date) => moment(date).format('DD/MM/YYYY HH:mm')} />
                            <Column  caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.EXPIRATIONDATE' })} dataField="expirationdatetime" dataType='date' format={(date) => moment(date).format('DD/MM/YYYY  HH:mm')} />
                            <Column  caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.REPORTEXTENSION' })} dataField="reportextension" />
                            <Column  caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.REQUESTBY' })} dataField="requestedby" />
                            <Column  caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.DOWNLOAD' })} type="buttons" buttons={[{ hint: 'Download', icon: 'download', visible: this.isDownloadIconVisible, onClick: this.onDownloadClick }]} />
                            <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                            <Summary>
                                <TotalItem column={0} summaryType="count" />
                            </Summary>
                        </DataGrid>
                    </div>
                    
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    authToken: store.auth.authToken
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(PowerBIExports)));
