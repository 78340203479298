/**
 * Entry application component used to compose providers and render Routes.
 *
 * Note: Because of
 */

import React from "react";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./app/router/AppRoutes";
import { PersistGate } from "redux-persist/integration/react";
// import { LastLocationProvider } from "react-router-dom-last-location";
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from "./_metronic";
import { SnackbarProvider } from 'notistack';
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module';
import browserUpdate from 'browser-update';
import { isIE } from 'react-device-detect';
import SystemHelper from "./app/shared/systemHelper";
import ErrorBoundary from "./app/partials/layout/ErrorBoundary";
import history from "./history";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import UserNotifier from "./app/shared/UserNotifier";

import config from 'devextreme/core/config';

config({ licenseKey: process.env.REACT_APP_DEVEX_LICENSE })

const gtmId = process.env.REACT_APP_GTM_TAG_ID;
if (gtmId) {
  var tagManagerArgs = { gtmId: gtmId };
  TagManager.initialize(tagManagerArgs);
}


const pingdomURL = process.env.REACT_APP_PINGDOM_URL;
const accessibeEnabled = process.env.REACT_APP_ACCESSIBE_ENABLED === "true";
const appcuesEnabled = process.env.REACT_APP_APPCUES_ENABLED === "true";
const delightedEnabled = process.env.REACT_APP_DELIGHTED_ENABLED === "true";

class App extends React.Component {
  componentDidMount() {
    if (isIE) {
      browserUpdate({
        required: {
          e: -2,
          // i: 11,
          f: -3,
          o: -3,
          s: 10.1,
          c: "64.0.3282.16817",
          samsung: 7.0,
          vivaldi: 1.2
        },
        insecure: true,
        // test: true,
        reminderClosed: 24,
        reminder: 0,
        unsupported: true,
        nostatistics: true,
        text: 'Your browser is out of date! <br />Please switch to a newer browser for more security, improved performance and the best experience on this site. <br />We recommend a chromium based browser such as Chrome, Firefox, Safari or Edge. <br /> <a{ignore_but}>ignore</a> <br />'
      });
    }

    if (delightedEnabled) {
      SystemHelper.delightedScript();
    }
  }
  handleScriptInject({ scriptTags }) {
    if (scriptTags) {
      //const scriptTag = scriptTags[0];
      scriptTags.forEach(script => {
        //POST ASYNC SCRIPT LOAD TASK FOR APPCUES
        //APPCUES JS LOAD SLOWER THAN FASTDRAFT
        //WE WAIT INTIL IT IS FULLY LOADED TO MAKE IDENTIFY CALL
        if (script && script.src && script.src.includes("fast.appcues.com")) {
          script.onload = () => { SystemHelper.appcuesUpdate(); };
        }
      });
    }
  }

  render() {
    const { store, Layout, persistor } = this.props;
    return (
      <ErrorBoundary>
        <Helmet
          onChangeClientState={(newState, addedTags) => this.handleScriptInject(addedTags)}
        >
          {
            pingdomURL && (
              <script src={pingdomURL} async></script>
            )
          }
          {
            accessibeEnabled && (
              <script
                src="https://acsbapp.com/apps/app/dist/js/app.js"
                onLoad={"(function(){ acsbJS.init({ statementLink : '', footerHtml : 'Press ALT+9 (Shortcut Keys) to Display the Widget', hideMobile : false, hideTrigger : true, disableBgProcess : false, language : 'en', position : 'left', leadColor : '#0064a2', triggerColor : '#0064a2', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'medium', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'center', triggerOffsetX : 10, triggerOffsetY : 0, triggerRadius : '50%' } }); })();"}
                async
                defer />
            )
          }
          {
            appcuesEnabled && (
              <script
                src="//fast.appcues.com/105345.js"
                // onLoad={ console.log(!!window.Appcues) } 
                async
                defer
              />
            )
          }
        </Helmet>
        <Provider store={store} loading={<LayoutSplashScreen />}>
          {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
          <PersistGate persistor={persistor}>
            {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
            <React.Suspense fallback={<LayoutSplashScreen />}>
              {/* Override `basename` (e.g: `homepage` in `package.json`) basename={basename}*/}
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              {/* <LastLocationProvider> */}
              {/* Provide Metronic theme overrides. */}
              <ThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    classes={{
                      containerAnchorOriginTopRight: "notistack-alert-container"
                    }}
                  >
                    <UserNotifier />
                      {/* #TODO: HistoryRouter WILL BE REMOVED WHEN react-router v6 support (Propmpt, usePrompt, useBlocker) */}
                      {/* #TODO:  Use BrowserRouter instead again*/}
                      <HistoryRouter history={history}>
                        {/* <BrowserRouter> */}
                        <Routes caseSensitive>
                          <Route path='*' element={<AppRoutes Layout={Layout} />} />
                        </Routes>
                        {/* </BrowserRouter> */}
                      </HistoryRouter>
                  </SnackbarProvider>
                </I18nProvider>
              </ThemeProvider>
              {/* </LastLocationProvider> */}
            </React.Suspense>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App
