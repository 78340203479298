import React from "react";
import { Route, Routes } from "react-router-dom";
import QuotesListAll from './quotes/ListAll';
import { injectIntl } from "react-intl";
import Common from '../../../shared/common';

class QuoteRegisterRouter extends React.Component {
    constructor(props) {
        super(props);
        this.notificationName = this.props.intl.formatMessage({ id: 'GENERAL.WORDS.QUOTE' });
        this.abbreviation = Common.CapitalFirstLetter(this.notificationName) + "-";

    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;


        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<QuotesListAll
                        {...this.props}
                        abbreviation={this.abbreviation}
                        contractTemplate={this.props.contractTemplate}
                        contractUser={this.props.contractUser}
                        title={workflowItem.title}
                    />}
                />
            </Routes>
        );
    }
};



export default injectIntl(QuoteRegisterRouter);