

import axios from '../shared/axios';

export function sendResetLink(eMail) {
    let formData = {
        email: eMail,
        action: 'reset'
    };
    return axios.post('/api/Security/ResetLink', formData);
}

export function resetPassword(email, token, password) {
    let formData = {
        email: email,
        token: token,
        password: password
    };
    return axios.post('/api/Security/ResetPassword', formData);
}

export function getDecryptedToken(tokenId) {
    return axios.get('/api/Security/GetDecryptedToken?encryptedToken=' + tokenId);
}

export function getCompanyTenantID(username) {
    let formData = {
        emailAddress: username
    };
    return axios.post('/api/Security/GetCompanyTenantID',formData);
}