/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import { injectIntl } from "react-intl";
import { Alert } from "react-bootstrap";

class QuoteCustomForm extends React.Component {
    constructor(props) {
        super(props);
        this.validatorRef = props.validatorRef ? props.validatorRef : React.createRef();
        this.effectTypes = [...props.quoteImpactOptions];
        this.createDefaultSubject = props.compensation ? props.compensation.compensationnumber : "";


        this.continuingEffectOptions = [
            { value: 1, display: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SWITCH_YES' }) },
            { value: 0, display: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SWITCH_NO' }) },
        ];

        this.createFormConfig = {
            currencyCode: this.props.contract.currency,
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: "GENERAL.FORMS.FIELD.TITLE", dataField: 'quotereference', editorOptions: { defaultValue: this.createDefaultSubject } },
            ]
        };

        if (this.props.compensation && this.props.compensationReference) {
            if (this.props.compensation.compensationsource === 'PRO' && this.props.resources.QuoteNoteProposedInstruction) {
                this.createFormConfig.fields.push({ note: this.props.resources.QuoteNoteProposedInstruction.replace('$reference', this.props.compensationReference) });
            }
            else {
                if (this.props.resources.QuoteNote) {
                    let quoteNote = (this.props.resources.QuoteNote || '');
                    if (quoteNote.includes("$reference")) {
                        //FD-8804
                        quoteNote = quoteNote.replace('$reference', this.props.compensationReference);
                    }
                    else {
                        //support historic template configurations FD-8804
                        quoteNote = quoteNote + ' ' + this.props.compensationReference;
                    }
                    this.createFormConfig.fields.push({ note: quoteNote });
                }
            }
        }
        if ((this.props.mode === "CLAIMWN" || this.props.mode === "CLIENTCWN") && this.props.interimQuotesAllowed) {
            this.createFormConfig.fields.push({ label: this.props.resources.QuoteContinuingEffectLabel, dataField: "continuingeffect", editorType: 'dxSelectBox', editorOptions: { dataSource: this.continuingEffectOptions, valueExpr: 'value', displayExpr: 'display' } });
        }
        this.createFormConfig.fields.push(
            { resource: 'CONTRACT.WF.QUOTES.FIELD.SUMMARY', dataField: 'quotecomment', editorType: 'dxHtmlEditor' },
            { label: this.props.resources.QuoteCostEffectLabel, dataField: 'amount', editorType: 'dxNumberBox' },
            { label: this.props.resources.QuoteDateEffectLabel, dataField: 'effect', editorType: 'dxSelectBox', editorOptions: { dataSource: this.effectTypes, valueExpr: 'field', displayExpr: 'display' } },
            { resource: "CONTRACT.WF.QUOTES.FIELD.PROGRAMME_DETAILS", dataField: 'effecttoprogrammitigatedcomment', editorType: 'dxHtmlEditor', visible: (obj) => obj.effect === 'mitigated' || obj.effect === 'notmitigated' },
            { label: this.props.resources.QuoteDaysEffectLabel, dataField: 'days', editorType: 'dxNumberBox', editorOptions: { format: '#,##0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' }), placeholder: '0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' }) }, visible: (obj) => obj.effect === 'notmitigated' },
            { label: this.props.resources.QuoteProgrammeAttachLabel, dataField: 'attachedprogramme', editorType: 'dxSelectBox', editorOptions: { dataSource: [this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SWITCH_YES' }), this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SWITCH_NO' })] }, visible: (obj) => obj.effect === 'mitigated' || obj.effect === 'notmitigated' },
        );


        this.state = {
            isDxFormValid: true,
            contractSecondaryDates: null,
            contractOtherPrices: null
        };
    }


    render() {
        return (
            <>
                {this.props.resources.SupplierQuoteWarningBanner && (
                    <Alert variant="warning" >
                        <p>
                            {this.props.resources.SupplierQuoteWarningBanner}
                        </p>
                    </Alert>
                )}

                <BiDynamicForm
                    validatorRef={this.validatorRef}
                    item={this.props.item}
                    config={this.createFormConfig}
                    onSubmitHandler={this.props.onSubmitHandler}
                    valueChangeHandler={this.props.valueChangeHandler}
                    customFields={this.props.customFields}
                    secondaryPriceSectionEnabled={true}
                    secondaryDateSectionEnabled={true}
                    priceDateSectionFilters={[
                        { sourcedmodel: "TaskOrder", sourcedrecordid: this.props.compensation?.taskorderid },
                        { sourcedmodel: "ProjectOrder", sourcedrecordid: this.props.compensation?.projectorderid }
                    ]}
                    interimQuotesAllowed={this.props.interimQuotesAllowed}
                />
            </>
        );
    }
}

const mapStateToProps = store => ({
    // contract: store.contract.contract,
    // contractTemplate: store.contract.template,
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(QuoteCustomForm)));
