const template = {
    name: "Default JCT DB 2016 v2.5 01/01/2024",
    isSystemDefault: true,
    isGlobal: true,
    companies: [],
    resources: [
        {
            id: 1,
            type: "TEXT",
            key: "SUPPLIER_NAME",
            value: "Contractor"
        },
        {
            id: 2,
            type: "TEXT",
            key: "SUPPLIER_ABBREVIATION",
            value: "CON"
        },
        {
            id: 3,
            type: "TEXT",
            key: "PM_NAME",
            value: "Employer"
        },
        {
            id: 4,
            type: "TEXT",
            key: "PM_ABBREVIATION",
            value: "EMP"
        },
        {
            id: 7,
            type: "TEXT",
            key: "CLIENT_NAME",
            value: "Client"
        },
        {
            id: 8,
            type: "NUMBER",
            key: "SUPPLIER_REPLY_PERIOD",
            value: 7
        },
        {
            id: 9,
            type: "NUMBER",
            key: "PM_REPLY_PERIOD",
            value: 7
        },
        {
            id: 10,
            type: "NUMBER",
            key: "QUOTE_DURATION",
            value: 21
        },
        {
            id: 13,
            type: "NUMBER",
            key: "REQUOTE_DURATION",
            value: 21
        },
        {
            id: 11,
            type: "NUMBER",
            key: "QUOTE_RESPONSE",
            value: 14
        },
        {
            id: 14,
            type: "NUMBER",
            key: "REQUOTE_RESPONSE",
            value: 14
        },
        {
            id: 12,
            type: "NUMBER",
            key: "VARIATION_RESPONSE",
            value: 7
        },
        {
            id: 16,
            type: "NUMBER",
            key: "PROGRAMME_REPLY_PERIOD",
            value: 14
        },
        {
            id: 18,
            type: "TEXT",
            key: "COMPLETION_DATE",
            value: "Completion Date"
        }
    ],
    workflowGroups: [
        {
            id: 102,
            title: "Instructions",
            workflows: [
                {
                    id: 4,
                    title: "$PM_NAME Instruction",
                    notificationName: "Instruction",
                    abbreviation: "$PM_ABBREVIATION-Instruction-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: null,
                            communicateReplyFilter: null
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "InstructionTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "2.2.4 To provide reasonable proof of compliance with clause 2.2"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "2.10.1 To correct a divergence between the ERs and the Site Boundary"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "2.13 To corrrect discrepancies"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "2.15.1 To deal with a divergence between Stautory Requirements and the CPs or ERs"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "2.35.1 Providing a Schedule of Defects"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "2.35.2 Requiring a defect to be made good"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "3.9 Requiring a Change"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "3.9.4 Varying an instruction following an objection by the Contractor"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "3.10 To postpone works"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "3.16 To expend a provisional sum"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "3.12 To open up for inspection any covered works"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "3.13 To deal with work not in accordance with the Contract"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "3.14 To deal with workmanship not in accordance with the Contract"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "3.15 On how to deal with the discovery of fossils or antiquities"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "4.24.3 Requiring the Contractor to supply the final account documents"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "6.11.3 To take out Terrorism Cover"
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "6.19.2 In connection with a breach of the Joint Fire Code"
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.7 To provide an Acceleration Quotation"
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.7 To change the specification following a proposal by the Contractor"
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.1.1 In connection with a Named Sub-Contractor"
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.8 Requesting information on the environmental impact of materials and goods"
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "Other Instruction"
                        }
                    ]
                }
            ]
        },
        {
            id: 104,
            title: "Programme",
            workflows: [
                {
                    id: 14,
                    title: "$SUPPLIER_NAME Programme",
                    notificationName: "Programme",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Programme-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "ReasonLabel",
                            display: "Reason for any change to completion date"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ChangeLabel",
                            display: "Change in any other important project milestone in period and reasons why"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "MainProgressLabel",
                            display: "Main progress achieved in period"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "KeyActivitiesLabel",
                            display: "Key activities forecast in next four weeks"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SummaryLabel",
                            display: "Summary of critical path"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ChangesLabel",
                            display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc."
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "CompensationLabel",
                            display: "Variations added to programme this period"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NotificationClause",
                            display: "Please find attached programme for acceptance"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ReplyRejectReasonLabel",
                            display: "The $SUPPLIER_NAME’s programme is not accepted for the following reasons"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "ReplyNotificationClause",
                            display: "In accordance with NEC4 PSC clause 31.3 you are notified that the $SUPPLIER_NAME’s Programme"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ReplyNotificationClause",
                            display: "You are notified that the $SUPPLIER_NAME’s Programme"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "DashboardPlannedCompletionLabel",
                            display: "Planned Completion Date"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Programme Submission awaiting Acceptance"
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "plansnotpractical",
                            display: "The $SUPPLIER_NAME’s plans which it shows are not practicable"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "missingcontractrequires",
                            display: "It does not show the information which this contract requires"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "notrepresentplan",
                            display: "It does not represent the $SUPPLIER_NAME’s plans realistically"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "notcomply",
                            display: "It does not comply with the Works Information"
                        }
                    ]
                },
                {
                    replacementKey: "Fields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PreviousPlanned",
                            display: "Previous Completion Date"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlannedCompletion",
                            display: "Date of Completion this period"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Reason",
                            display: "Reason for any change to Completion Date"
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "Change",
                            display: "Change in any other Key Date, Sectional Completion or other important project milestone in period and reasons why"
                        },
                        {
                            id: 5,
                            isActive: false,
                            field: "MainProgress",
                            display: "Main progress achieved in period"
                        },
                        {
                            id: 6,
                            isActive: false,
                            field: "KeyActivities",
                            display: "Key activities forecast in next four weeks"
                        },
                        {
                            id: 7,
                            isActive: false,
                            field: "Summary",
                            display: "Summary of critical path"
                        },
                        {
                            id: 8,
                            isActive: false,
                            field: "Changes",
                            display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc."
                        },
                        {
                            id: 9,
                            isActive: false,
                            field: "Compensation",
                            display: "Compensation events added to programme this period"
                        }
                    ]
                }
            ]
        },
        {
            id: 105,
            title: "Notifications",
            workflows: [
                {
                    id: 15,
                    title: "A/CA Notifications",
                    notificationName: "Notification",
                    abbreviation: "$PM_ABBREVIATION-Notification-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: null,
                            communicateReplyFilter: null
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 16,
                    title: "$SUPPLIER_NAME Notifications",
                    notificationName: "Notification",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Notification-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "PmClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "2.4 Of a deferement of the date of possession"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "2.7.2 Of provision of the Contract Documents"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "2.10.2 Of a divergence between the ERs and the Site Boundary"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "2.15.1 Of a divergence between Stautory Requirements and the CPs or ERs"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "2.20.2 That an instruction infringes patent rights"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "2.20.2 Of confirmation of an instruction in conection with patent rights"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "2.27 Of the issue of a Practical Completion or Sectional Completion Statement"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "2.28 Of the issue of a Non Completion Notice"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "2.32 Of making good defects in the relevant part"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "2.36 Of Completion of Making Good"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "3.3.1.1 Of permission to subcontract the works"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "3.6 Of non-compliance with an instruction"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "3.7.1 Of dissent from a verbal instruction"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "3.7.2/.3 Of confirmation of an instruction"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "3.8 Of advice in connection with empowerment to issue an instruction"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "4.10.1 Of an intention to pay less than the notified sum"
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "4.20.2 That further information is required in connection with a loss and expense claim"
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "4.20.4 Of the ascertained amount of loss and expense"
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "4.24.2 Of copies of the final account calculations"
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "4.24.3 Of copies of the final account assessment"
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "4.24.4 Of the issue of the Employer's Final Statement"
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "6.11.1 Of the cessation of Terrorism cover"
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "",
                            display: "6.12.1 Of a request to provide evidence of insurance"
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "",
                            display: "6.15.3 Of a request to provide evidence of insurance"
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "",
                            display: "7D Requiring the Contractor to enter into a Collateral Warranty with the Funder"
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "",
                            display: "7E.1 Requiring the Contractor to provide a Collareral Warranty from a subcontractor"
                        },
                        {
                            id: 28,
                            isActive: true,
                            field: "",
                            display: "8.7.2.1 Requiring the Contractor to remove its temporary equipment from site"
                        },
                        {
                            id: 29,
                            isActive: true,
                            field: "",
                            display: "8.7.2.3 Requiring the Contractor to assign the benefit of any agreements to the Employer"
                        },
                        {
                            id: 30,
                            isActive: true,
                            field: "",
                            display: "8.7.4.4 Of a statement of the account"
                        },
                        {
                            id: 31,
                            isActive: true,
                            field: "",
                            display: "8.8.1 Of a decision not to have the works carried out or completed"
                        },
                        {
                            id: 32,
                            isActive: true,
                            field: "",
                            display: "8.8.1 Of a statement of the account"
                        },
                        {
                            id: 33,
                            isActive: true,
                            field: "",
                            display: "Sch. 1.7 That a comment on the Contractor's design is a Change"
                        },
                        {
                            id: 34,
                            isActive: true,
                            field: "",
                            display: "Other Notification"
                        }
                    ]
                },
                {
                    replacementKey: "SupplierClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "2.1.3 Of approvals received by the Contractor"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "2.5.2 Of an additional insurance premium for Early Use by the Employer"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "2.10.2 Of a divergence between the ERs and the Site Boundary"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "2.12.1 Of an error in the Employer's Requirements"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "2.13 Of a discrepancy in the Contract Documents or instruction"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "2.15.1 Of a divergence between Stautory Requirements and the CPs or ERs"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "2.16.2 Of emergency steps taken to comply with Statutory Requirements"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "2.20.2 That an instruction infringes patent rights"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "2.24.1 That progress of the Works is being or likely to be delayed"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "2.24.2 Of particulars of a notified delay"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "2.24.3 Of any change to a notified delay"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "2.30 Of partial possession"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "3.5.1 Of a reasonable objection to a Change"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "3.7.1 Of confirmation of a verbal instruction"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "3.8 Of a request that the Employer notifies the provision empowering an instruction"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "3.9.4 Of an objection to an instruction in connection with a Provisional Sum"
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "3.15 Of the discovery of fossils or antiquities"
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "3.16.4 Of a direction under regulation 15(3) of the CDM Regulations"
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "4.6 Of the provision of an Advanced Payment Bond"
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "4.10.1 Of an intention to pay less than the notified sum"
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "4.11.1 Of an intention to suspend the performance of obligations"
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "4.15.2 Of proof in connection with a Listed Item"
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "",
                            display: "4.15.4 Of a bond in connection with a Listed Item"
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "",
                            display: "4.20 Of incurring loss and expense"
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "",
                            display: "4.24.1 Of documents necessary for the final account"
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "",
                            display: "6.11.1 Of the cessation of Terrorism cover"
                        },
                        {
                            id: 28,
                            isActive: true,
                            field: "",
                            display: "6.12.1 Of a request to provide evidence of insurance"
                        },
                        {
                            id: 29,
                            isActive: true,
                            field: "",
                            display: "6.16 That insurance has ceased to be available at commercially reasonable rates"
                        },
                        {
                            id: 30,
                            isActive: true,
                            field: "",
                            display: "6.19.2 Of emergency steps taken to comply with the Joint Fire Code"
                        },
                        {
                            id: 31,
                            isActive: true,
                            field: "",
                            display: "Sch. 1.7 Of a disagreement with a comment of the Employer in connection with the design"
                        },
                        {
                            id: 32,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.1.1.2 That a contract with a Named Sub-Contractor cannot be entered into"
                        },
                        {
                            id: 33,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.1.3.1 That a Named Sub-Contractor's contract will be terminated"
                        },
                        {
                            id: 34,
                            isActive: true,
                            field: "",
                            display: "Other Notification"
                        }
                    ]
                }
            ]
        },
        {
            id: 106,
            title: "Submissions",
            workflows: [
                {
                    id: 18,
                    title: "$SUPPLIER_NAME Submissions",
                    notificationName: "Submission",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Submission-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 49,
                    title: "$PM_NAME Submissions",
                    notificationName: "Submission",
                    abbreviation: "$PM_ABBREVIATION-Submission-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "IssueClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "2.2.1 Of a proposal to substitute materials"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "2.2.3 Of samples of the standard of workmanship or quality of goods"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "2.2.4 Of proof that goods comply with clause 2.3"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "2.8 Of Contractor's Design Documents"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "2.14.1 Of proposed amendments to correct an error in the Contractor's Proposals"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "2.14.2 Of a proposal to correct a discrepancy in the Employer's Requirements"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "2.15.1 Of a proposal to correct a divergence between Stautory Requirements and the Contractor's Proposals"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "2.21 Of a request to remove Site Materials"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "2.37 Of Contractor's Design Documents"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "3.2 Of a replacement Site Manager"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "3.3.1.1 For consent to subcontract the works"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "3.3.1.2 For consent to subcontract the design"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "4.7 Of an advance payment bond"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "4.11.3 Of an amount incurred due to suspension under 4.11.1"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "4.17.2 Of a retention bond"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "4.20.2 Of the extent of the loss and expense"
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "4.20.3 Of further information in connection with a loss and expense claim"
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "5.5 Of a daywork voucher"
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "6.12.1 Of evidence of insurance"
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "6.15.3 Of evidence of insurance"
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "7.3.1 Of submission of a performance bond"
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "7.3.2 Of submission of a parent company guarantee"
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "",
                            display: "7E.2 Of a Collareral Warranty from a subcontractor"
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "",
                            display: "8.12.3 Of a statement of account"
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.7 Of a proposal for cost savings and value improvements"
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.8 Of information on the environment impact of the materials and goods"
                        },
                        {
                            id: 28,
                            isActive: true,
                            field: "",
                            display: "Other Submission"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Submission awaiting Acceptance"
                        }
                    ]
                },
                {
                    replacementKey: "IssueClausesPM",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "2.5.1 Of a request to use or occupy the site or the Works"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "2.6.2 Of a request to execute its own works on site"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "6.12.1 Of evidence of insurance"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "Other Submission"
                        }
                    ]
                }
            ]
        },
        {
            id: 103,
            title: "Changes",
            workflows: [
                {
                    id: 50,
                    title: "Extension of Time",
                    notificationName: "Change",
                    abbreviation: "Change-Time-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 51,
                    title: "Changes to the Contract Sum",
                    notificationName: "Change",
                    abbreviation: "Change-Price-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmAssumptionLabel",
                            display: "The following assumptions shall be considered"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmAssumptionNote",
                            display: "You are hereby instructed to provide a quote"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierDescNote",
                            display: "In accordance with the contract I notify you that the event described below has happened which: We believe is a variation, has happened less than 5 weeks since we became aware of the event, you have not notified to us."
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "ReplyAcceptedCommentLabel",
                            display: "In accordance with the contract you are notified that the issue flagged is a Variation"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "ReplyRejectedCommentLabel",
                            display: "In accordance with the contract you are notified that the $SUPPLIER_NAME Notification is not a Variation"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ReplyAcceptedAssumptionLabel",
                            display: "In accordance with the contract the following assumptions shall be considered"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "QuoteNote",
                            display: "In accordance with the contract please find attached our quotation for $reference"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "ExtensionPMLabel",
                            display: "We require an extension to the contractual time to produce a quotation response as detailed below for the following reasons"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ExtensionSupplierLabel",
                            display: "We require an extension to the contractual time to produce a quotation as detailed below for the following reasons"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "AssessmentDetailLabel",
                            display: "Details"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ProposedInstructionNote",
                            display: "In accordance with the contract you are instructed to provide a quote"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "ProposedInstructionLabel",
                            display: "In accordance with the contract he following assumptions shall be considered"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "ReplyRejectedOptionsLabel",
                            display: "I have decided that the Notification"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "QuoteCostEffectLabel",
                            display: "Claimed  Cost Increase/Decrease"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "QuoteDateEffectLabel",
                            display: "Extension of time required"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "QuoteDaysEffectLabel",
                            display: "Extension of time required"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "AssessmentCostEffectLabel",
                            display: "Claimed Cost Increase/Decrease"
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "AssessmentDaysEffectLabel",
                            display: "Number of days effect to the completion date"
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "CompensationTypeLabel",
                            display: "Variation types"
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "QuoteProgrammeAttachLabel",
                            display: "Are you attaching a programme showing the effect against the last Accepted Programme with this quotation?"
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "AssessmentCompensationLabel",
                            display: "Variation"
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "AssessmentWarningBanner",
                            display: ""
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "PmCompensationAQ",
                            display: "Variations notified by $PM_ABBREVIATION awaiting quotes"
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "PmCompensationARQ",
                            display: "Variations notified by $PM_ABBREVIATION awaiting revised quotes"
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "PmCompensationAPMRQ",
                            display: "Variations notified by $PM_ABBREVIATION awaiting response to quotes"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "PmCompensationAPMA",
                            display: "Changes notified by $PM_ABBREVIATION awaiting $PM_ABBREVIATION Assessment"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SupCompensationAQ",
                            display: "Changes notified by $SUPPLIER_NAME awaiting quotes"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SupCompensationARQ",
                            display: "Changes notified by $SUPPLIER_NAME awaiting revised quotes"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "SupCompensationAPMR",
                            display: "Changes notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION response"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "SupCompensationAPMRQ",
                            display: "Changes notified by $SUPPLIER_NAME awaiting response to quotes"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "SupCompensationAPMA",
                            display: "Changes notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "PmProposedAQ",
                            display: "Proposed Changes awaiting quotes"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "PmProposedARQ",
                            display: "Proposed Changes awaiting revised quotes"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "PmProposedAPMRQ",
                            display: "Proposed Variations awaiting response to quotes"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "PmProposedAPMA",
                            display: "Proposed Variations awaiting $PM_ABBREVIATION Assessment"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "PmExtension",
                            display: "$PM_ABBREVIATION Quote Response Extensions awaiting Acceptance"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "SupExtension",
                            display: "$SUPPLIER_NAME Quote Extensions awaiting Acceptance"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "SupAssumption",
                            display: "$SUPPLIER_NAME Assumptions awaiting Acceptance"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "SupClaimAQ",
                            display: "Claims notified by $SUPPLIER_NAME awaiting quotes"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "SupClaimARQ",
                            display: "Claims notified by $SUPPLIER_NAME awaiting revised quotes"
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "SupClaimAPMRQ",
                            display: "Claims notified by $SUPPLIER_NAME awaiting response to quotes"
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "SupClaimAPMA",
                            display: "Claims notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment"
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "faultpscp",
                            display: "Arises from a fault of the $SUPPLIER_NAME"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "notexpectnothappen",
                            display: "Has not happened and is not expected to happen"
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "noteffectcostcompletion",
                            display: "Has no effect on Defined Cost, Completion or meeting a Key Date"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "statedcontract",
                            display: "Is not one of the variations stated in this contract"
                        },
                        {
                            id: 5,
                            isActive: false,
                            field: "notnotifiedwithintimescales",
                            display: "Has not been notified within the timescales set out in the contract"
                        }
                    ]
                },
                {
                    replacementKey: "CompensationStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "APMA",
                            display: "Quote Not Accepted - $PM_ABBREVIATION to make own Assessment"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ARQ",
                            display: "Quote Not Accepted - $SUPPLIER_NAME to resubmit"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "AM",
                            display: "$PM_ABBREVIATION Assessment Made"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "QA",
                            display: "Quote Accepted"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "APMRQ",
                            display: "Awaiting $PM_ABBREVIATION Response to quote"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "APMR",
                            display: "Awaiting $PM_ABBREVIATION Response to Variation Notice"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "AQ",
                            display: "Awaiting Quote"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NC",
                            display: "Not Communicated"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "R",
                            display: "Not a Variation"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "DNG",
                            display: "Decision Not Given"
                        }
                    ]
                },
                {
                    replacementKey: "QuoteStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "0",
                            display: "Not Accepted - $SUPPLIER_NAME to resubmit"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Accepted"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Awaiting Response"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "3",
                            display: "Not Accepted - $PM_ABBREVIATION to make assessment"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "4",
                            display: "Not Accepted - Other quote approved"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "5",
                            display: "Not Accepted - Decision will not be given"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "none",
                            display: "Not Communicated"
                        }
                    ]
                },
                {
                    replacementKey: "InstructionReplyProceedOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "61.1",
                            display: "$SUPPLIER_NAME is instructed to proceed with works"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "61.2",
                            display: "$SUPPLIER_NAME is instructed to NOT proceed with works until instructed"
                        }
                    ]
                },
                {
                    replacementKey: "EWRelationEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "EWRelationEnabled",
                            display: "Active-Inactive state indicates weather the EWN can be attached in CEN communications"
                        }
                    ]
                },
                {
                    replacementKey: "Fields",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "Assumption",
                            display: "The quotation is to be based on the following assumptions"
                        }
                    ]
                },
                {
                    replacementKey: "QuoteProgrammeImpactOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "noeffect",
                            display: "There is no effect on the Accepted Programme"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "mitigated",
                            display: "There is an effect on the Accepted Programme but this has been mitigated by measures as detailed"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "notmitigated",
                            display: "There is an effect on the Accepted Programme which we are unable to mitigate"
                        }
                    ]
                },
                {
                    replacementKey: "VariationClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "2.5.2 An additional insurance premium for Early Use by the Employer"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "2.10.1 Correction of a divergence between the ERs and the Site Boundary"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "2.12.1 Correction of an error in the Employer's Requirements"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "2.14 To correct an error in the Contract Documents"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "2.15.2 Correction of a divergence between Stautory Requirements and the Contract Documents"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "3.5 An instruction requiring a Change"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "3.7 A confirmed verbal instruction"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "3.10 Postponement of the works"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "3.16 Expenditure of a provisional sum"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "3.12 Opening up for inspection any covered works"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "3.15 Dealing with the discovery of fossils or antiquities"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "4.11.3 An amount incurred following a suspension of the works"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "4.2 An amount of loss and expense"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "5.6 A Change due to a change in conditions of the work"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "6.11.2 Costs in connection with taking out Terrorism cover"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "6.13.5 An insured amount under clause 6.13"
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "6.19 Works in connection with a breach of the Joint Fire Code"
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "6.20 Amendments made to the Joint Fire Code"
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "Sch 2.7.3 To change the specification following a proposal by the Contractor"
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.1 In connection with a Named Specialist"
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "Other Change"
                        }
                    ]
                }
            ]
        },
        {
            id: 107,
            title: "Quotations",
            workflows: [
                {
                    id: 19,
                    title: "$SUPPLIER_NAME Quotations",
                    notificationName: "Quotations",
                    abbreviation: "CON-JCTQuotations-",
                    notify: true,
                    replyEnabled: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CostEffectLabel",
                            display: "Claimed Cost Increase/Decrease"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "DaysEffectLabel",
                            display: "Extension of time required"
                        }
                    ]
                },
                {
                    replacementKey: "ProposalClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.2/.3 Contractor's Estimates"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "Sch. 2.4 Acceleration Quotation"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "Quotations awaiting Acceptance"
                        }
                    ]
                }
            ]
        },
        {
            id: 108,
            title: "Payments",
            workflows: [
                {
                    id: 20,
                    title: "Payment Certificates",
                    notificationName: "Payment Certificate",
                    abbreviation: "$PM_ABBREVIATION-Payment-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 21,
                    title: "Payment Applications",
                    notificationName: "Payment Application",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Payment-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CumulativePriceLabel",
                            display: "Value of Work Done"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlusAmountsSupplierLabel",
                            display: "Plus other amounts to be paid to the $SUPPLIER_NAME"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "LessAmountsSupplierLabel",
                            display: "Less amounts to be paid by or retained from the $SUPPLIER_NAME"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TaxSupplierLabel",
                            display: "Taxes"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "WarningBanner",
                            display: "During the period up to the due date for the final payment, the monthly due dates for interim payments by the Employer shall in each case be the date 7 days after the relevant Interim Valuation Date"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SummaryDetailsLabel",
                            display: "Summary details"
                        }
                    ]
                },
                {
                    replacementKey: "TaxEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "TaxEnabled",
                            display: "Active-Inactive state indicates tax field will be included in notification forms"
                        }
                    ]
                }
            ]
        },
        {
            id: 109,
            title: "Testing & Defects",
            workflows: [
                {
                    id: 22,
                    title: "Instruction to Search",
                    notificationName: "Instruction to Search",
                    abbreviation: "Search-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "NOTIFICATION_DATE",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Supervisor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Supervisor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Supervisor\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Supervisor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 23,
                    title: "Defect Notifications",
                    notificationName: "Defect Notification",
                    abbreviation: "$SUPERVISOR_NAME-Defect-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    actioningParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Supervisor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Supervisor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"Supervisor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "InstructionClauseLabel",
                            display: "You are instructed to search for a Defect as follows"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SupervisorClauseLabel",
                            display: "You are notified of the following Defect"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierClauseLabel",
                            display: "You are notified of the following Defect"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TestsClauseLabel",
                            display: "You are notified of the following test or inspection"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Instruction",
                            display: "$SUPERVISOR_NAME Instruction to Search awaiting sign off"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SuperDefect",
                            display: "$SUPERVISOR_NAME Notification of Defect awaiting acceptance"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Inspection",
                            display: "$SUPPLIER_NAME Test/Inspection Notification awaiting sign off"
                        }
                    ]
                }
            ]
        },
        {
            id: 111,
            title: "Records",
            workflows: [
                {
                    id: 30,
                    title: "A/CA Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$PM_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 31,
                    title: "$SUPPLIER_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 32,
                    title: "$SUPPLIER_NAME Site Diary",
                    notificationName: "Site Diary",
                    abbreviation: "Diary-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 33,
                    title: "Weather",
                    notificationName: "Weather",
                    abbreviation: "Weather-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: []
        },
        {
            id: 112,
            title: "RFI / TQ",
            workflows: [
                {
                    id: 34,
                    title: "$SUPPLIER_NAME RFI / TQ",
                    notificationName: "RFI / TQ",
                    abbreviation: "RFI/TQ-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME RFI/TQ awaiting Reply"
                        }
                    ]
                }
            ]
        },
        {
            id: 101,
            title: "Early Dispute Resolution",
            workflows: [
                {
                    id: 1,
                    title: "A/CA EDR Notices",
                    notificationName: "Early Dispute Resolution",
                    abbreviation: "$PM_ABBREVIATION-Warning-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    actioningParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Support\"]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 2,
                    title: "$SUPPLIER_NAME EDR Notes",
                    notificationName: "Early Dispute Resolution",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Warning-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    actioningParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Support\"]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 3,
                    title: "EDR Register",
                    notificationName: "Early Dispute Resolution",
                    abbreviation: "Warning",
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "MeetingRequiredLabel",
                            display: "Is Risk Reduction Meeting required?"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "NotificationClause",
                            display: "In accordance with NEC4 PSC clause 15.1. You are given early warning that an occurrence/situation is anticipated which may"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "RiskItemsLabel",
                            display: "You are given early warning that an occurrence/situation is anticipated which may"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Pm",
                            display: "$PM_ABBREVIATION EDR awaiting reply"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME EDR awaiting reply"
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "riskother",
                            display: "any matter which may cause a dispute or difference"
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypesSupplier",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "riskother",
                            display: "any matter which may cause a dispute or difference"
                        }
                    ]
                },
                {
                    replacementKey: "MeetingOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "0",
                            display: "Meeting Not Required"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Meeting Required - Next Progress Meeting"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Meeting Required - Propose New Progress Meeting"
                        }
                    ]
                }
            ]
        },
        {
            id: 113,
            title: "Contract Information",
            workflows: [
                {
                    id: 39,
                    title: "Documents",
                    notificationName: "Document",
                    abbreviation: "Documents-",
                    notify: true,
                    actioningParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: []
                }
            ]
        }
    ],
    copyInfo: []
};
export default template;