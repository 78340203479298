/* eslint-disable eqeqeq */
import React from 'react';
import { connect } from "react-redux";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import MasterTemplateDetails from './MasterTemplateDetails';
import * as templatesDuck from '../../../../store/ducks/templates.duck';
import * as companiesDuck from '../../../../store/ducks/companies.duck';
import * as templateCrud from '../../../../crud/templates.crud';
import { withSnackbar } from 'notistack';
import DefaultTemplates from '../../../../Templates/DefaultTemplates';
import DxFormModal from '../../../../partials/content/dxControls/dxFormModal';
import CopyTracksModal from './CustomControls/CopyTracksModal';
import moment from 'moment';
import TemplateHelper from "../../../../Templates/TemplateHelper";
import { withRouter } from '../../../../shared/hoc/withRouter';
import * as contractDuck from '../../../../store/ducks/contract.duck';
import Common from '../../../../shared/common';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import { Navigate } from "react-router-dom";
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Badge } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { LoadIndicator } from 'devextreme-react/load-indicator';

const copyModalConfig = {
    fields: [
        { label: 'New Template Name', dataField: 'name', required: true },
    ]
};

class MasterTemplateEditor extends React.Component {
    fileImportInputRef = React.createRef();
    state = {
        template: null,
        changed: false,
        isSaving: false,
        templateJson: null,
        templateName: null,
        versionTrackerForChildrenReRender: 1
    }
    componentDidMount() {
        this.props.loadTemplates();
        this.props.loadWorkflows();
        this.props.loadCompanies();
        this.setInitialTemplate();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.templates !== this.props.templates ||
                prevProps.workflowGroups !== this.props.workflowGroups ||
                prevProps.params.templateid !== this.props.params.templateid
            ) {
                this.setInitialTemplate();
            }
        }
    }

    setInitialTemplate() {
        if (this.props.workflowGroups && this.props.templates) {
            if (this.props.params.templateid) {
                const editingTemplate = this.props.templates.find(temp => temp.templateid == this.props.params.templateid);
                if (editingTemplate) {
                    this.setState({ template: JSON.parse(JSON.stringify(editingTemplate)) });
                }
                else {
                    this.props.navigate('/admin/templates/template-editor');
                }
            }
            else {
                const newTemplate = JSON.parse(JSON.stringify(DefaultTemplates[0]));
                newTemplate.workflowGroups = JSON.parse(JSON.stringify(this.props.workflowGroups));
                this.setState({ template: newTemplate });
            }
        }
    }


    saveTemplate = async () => {
        try {
            const newTemplate = {
                templateid: this.props.params.templateid,
                name: this.state.template.name,
                templatejson: JSON.stringify(TemplateHelper.templateSanitizer(this.state.template)),
                deleted: 0
            };

            await templateCrud.saveTemplate(newTemplate);
            this.props.enqueueSnackbar('Template Successfully Saved', { variant: 'success', });
            this.props.loadTemplates(true);
            this.setState({ template: null, changed: false, isSaving: false, navigateto: "/admin/templates/master-templates" });
        }
        catch (err) {
            this.setState({ isSaving: false });
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }

    }

    saveTemplateCopy = async (newTemplateName) => {
        const newCopyInfo = this.state.template.copyInfo ? JSON.parse(JSON.stringify(this.state.template.copyInfo)) : [];
        newCopyInfo.push(
            {
                copiedTemplateID: this.state.template.templateid,
                copiedTemplateName: this.state.template.name,
                copiedById: this.props.userProfile.accountid,
                copiedByName: this.props.userProfile.name,
                copiedDateTime: moment().format('DD/MM/YYYY HH:mm')
            }
        );
        const newTemplate = {
            name: newTemplateName,
            templatejson: JSON.stringify({ ...TemplateHelper.templateSanitizer(this.state.template), name: newTemplateName, isSystemDefault: false, copyInfo: newCopyInfo }),
            deleted: 0,
            templateid: null
        };

        await templateCrud.saveTemplate(newTemplate)
    }
    onTemplateChanged = (newTemplate) => {
        this.setState({ template: newTemplate, changed: true });
    }
    onCopyModalResponse = (resp) => {
        if (resp) {
            this.setState({ showCopyModal: false, isSaving: true }, async () => {
                try {
                    await this.saveTemplateCopy(resp.name);
                    this.props.enqueueSnackbar('Template Successfully Copied', { variant: 'success', });
                    this.props.loadTemplates(true);
                    this.setState({ isSaving: false, navigateto: "/admin/templates/master-templates" });
                }
                catch (err) {
                    this.setState({ isSaving: false });
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
        }
        else {
            this.setState({ showCopyModal: false });
        }
    }
    onCopyTrackModalResponse = (resp) => {
        this.setState({ showCopyTrackModal: false });
    }

    downloadTemplateJson = (e) => {
        const templateFileName = `MASTER_TEMPLATE_${this.props.params.templateid}`;
        const stringifiedJson = JSON.stringify(TemplateHelper.templateSanitizer(this.state.template), null, 2);
        Common.downloadFile({
            data: stringifiedJson,
            fileName: templateFileName + '.json',
            fileType: 'text/json',
        });
    }
    importTemplateJson = (e) => {
        if (this.fileImportInputRef.current) {
            this.fileImportInputRef.current.click();
        }
    }
    importFileSelected = async (e) => {
        if (e.stopPropagation) { e.stopPropagation(); }
        if (e.preventDefault) { e.preventDefault(); }
        if (e.target && e.target.files && e.target.files.length > 0) {
            var file = e.target.files[0];
            if (file) {
                try {
                    const text = await file.text();
                    const importedTemplate = JSON.parse(text)
                    importedTemplate.templateid = this.props.params.templateid;
                    importedTemplate.name = this.state.template.name;
                    this.setState({
                        template: importedTemplate,
                        //INCREASE VERSION SO CHILD COMPONENTS RELOAD
                        versionTrackerForChildrenReRender: this.state.versionTrackerForChildrenReRender + 1,
                        changed: true
                    });
                    this.props.enqueueSnackbar('Template successfully imported', { variant: 'success' });
                } catch (err) {
                    this.props.enqueueSnackbar("Error reading file", { variant: 'error', });
                    console.log(err.toString())
                }
                finally {
                    if (this.fileImportInputRef.current) {
                        this.fileImportInputRef.current.value = null;
                    }
                }
            }
        }
    }


    render() {

        if (!this.props.templates || !this.props.companies || !this.props.workflowGroups || !this.state.template) {
            return (<PortletSpinner />);
        }
        const { allowUpdate, allowImportExport } = this.props.protectedRouteProps;
        return (
            <Portlet style={{ minWidth: 500 }}>
                {!allowUpdate && (<Badge variant="danger"><FormattedMessage id="GENERAL.WARNING.NOT_AUTHORIZED_ON_PAGE" /></Badge>)}
                <NavigationBlocker
                    when={this.state.changed}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} />
                    )
                }
                {allowImportExport && (
                    <input type='file' id='importFile' accept='.json' ref={this.fileImportInputRef} style={{ display: 'none' }} onChange={this.importFileSelected} />
                )}
                <PortletHeader
                    title={this.props.params.templateid ? "Edit Template - " + this.state.template.name : "New Template"}
                    toolbar={(
                        <div style={{ display: 'flex', flex: 1, flexFlow: 'row-reverse', margin: "20px 0px" }}>
                            <LoadIndicator className="button-indicator" visible={this.state.isSaving} />
                            <Toolbar disabled={this.state.isSaving}>
                                <ToolbarItem
                                    key="masterTempBtnUpdate"
                                    widget="dxButton"
                                    location="after"
                                    locateInMenu="never"
                                    disabled={!this.state.changed || !allowUpdate}
                                    options={{
                                        text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SAVE_CHANGES' }),
                                        type: "default",
                                        stylingMode: this.state.changed ? "contained" : "outlined",
                                        onClick: () => { this.setState({ isSaving: true }, this.saveTemplate) }
                                    }} />
                                <ToolbarItem
                                    key="masterTempBtnShowHistory"
                                    location="after"
                                    locateInMenu="auto"
                                    widget="dxButton"
                                    options={
                                        {
                                            icon: "find",
                                            onClick: () => this.setState({ showCopyTrackModal: true }),
                                            text: this.props.intl.formatMessage({ id: 'TEMPLATE.BUTTONS.VIEW_COPY_HISTORY' }),
                                        }
                                    }
                                />

                                {allowUpdate && (
                                    <ToolbarItem
                                        key="masterTempBtnClone"
                                        location="after"
                                        locateInMenu="always"
                                        widget="dxButton"
                                        disabled={this.state.changed}
                                        options={
                                            {
                                                icon: "copy",
                                                onClick: () => this.setState({ showCopyModal: true }),
                                                text: this.props.intl.formatMessage({ id: 'TEMPLATE.BUTTONS.CLONE' }),
                                            }
                                        }
                                    />
                                )}
                                {allowImportExport &&
                                    (
                                        <ToolbarItem
                                            key="masterTempBtnExportJSON"
                                            location="after"
                                            locateInMenu="always"
                                            widget="dxButton"
                                            options={
                                                {
                                                    icon: "export",
                                                    onClick: this.downloadTemplateJson,
                                                    text: this.props.intl.formatMessage({ id: 'TEMPLATE.BUTTONS.EXPORT_JSON' }),
                                                }
                                            }
                                        />
                                    )}
                                {allowImportExport &&
                                    (
                                        <ToolbarItem
                                            key="masterTempBtnImportJSON"
                                            location="after"
                                            locateInMenu="always"
                                            widget="dxButton"
                                            options={
                                                {
                                                    icon: "import",
                                                    onClick: this.importTemplateJson,
                                                    text: this.props.intl.formatMessage({ id: 'TEMPLATE.BUTTONS.IMPORT_JSON' }),
                                                }
                                            }
                                        />
                                    )}



                            </Toolbar>
                        </div>
                    )}
                />
                <PortletBody >
                    <MasterTemplateDetails
                        disabled={this.state.isSaving}
                        key={this.state.versionTrackerForChildrenReRender}
                        mode="TEMPLATE"
                        companies={this.props.companies}
                        template={this.state.template}
                        templateChanged={this.onTemplateChanged}
                        workflowGroups={this.props.workflowGroups}
                    />

                </PortletBody>
                <DxFormModal
                    visible={this.state.showCopyModal}
                    title={`Copy Template`}
                    config={copyModalConfig}
                    onDialogResult={this.onCopyModalResponse}
                    primaryButton={{
                        text: "Copy Template",
                        type: "success",
                        stylingMode: "outlined",
                    }}
                />
                <CopyTracksModal
                    visible={this.state.showCopyTrackModal}
                    records={this.state.template.copyInfo}
                    title={`Copy History Records`}
                    onDialogResult={this.onCopyTrackModalResponse}
                />
            </Portlet>

        )
    }
}


const mapStateToProps = store => ({
    templates: store.templates.templates,
    workflowGroups: store.templates.workflowGroups,
    companies: store.companies.companies,
    userProfile: store.auth.userProfile,
    contract: store.contract.contract,
});

const mapDispatchToProps = {
    loadWorkflows: templatesDuck.actions.fetchWorkflows,
    loadTemplates: templatesDuck.actions.fetchTemplates,
    loadCompanies: companiesDuck.actions.fetchStart,
    setTemplates: templatesDuck.actions.setTemplates,
    loadContract: contractDuck.actions.fetchStart

}
export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(MasterTemplateEditor))));

