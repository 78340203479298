import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ContractOverview from '../../admin/Contracts/Contract/ContractOverview';
import ContractUsers from '../../admin/Contracts/Contract/ContractUsers';
import ContractEmailRecipients from '../../admin/Contracts/Contract/ContractEmailRecipients';
import ContractTemplate from '../../admin/Contracts/Contract/ContractTemplate';
import ContractSecondaryDates from '../../admin/Contracts/Contract/ContractSecondaryDates';
import ContractSecondaryPrices from '../../admin/Contracts/Contract/ContractSecondaryPrices';
import ContractActions from '../../admin/Contracts/Contract/ContractActions';
import Assets from '../../admin/Contracts/Contract/Assets';
import Exports from '../../admin/Contracts/Contract/ContractDataExport';
import PriceList from "../../admin/Contracts/Contract/PriceList";
import PriceListItems from "../../admin/Contracts/Contract/PriceListItems";
// import ContractNotifications from '../admin/Contracts/Contract/ContractNotifications';
import Protector from "../../../router/Protector/Protector";

class ContractSettingsRouter extends React.Component {
    render() {
        return (
            <Routes caseSensitive>
                <Route path="/overview" element={<Protector><ContractOverview /></Protector>} sensitive />
                <Route path="/users" element={<Protector><ContractUsers /></Protector>} sensitive />
                <Route path="/secondary-dates" element={<Protector><ContractSecondaryDates /></Protector>} sensitive />
                <Route path="/secondary-prices" element={<Protector><ContractSecondaryPrices /></Protector>} sensitive />
                <Route path="/actions" element={<Protector><ContractActions /></Protector>} sensitive />
                <Route path="/assets" element={<Protector><Assets /></Protector>} sensitive />
                <Route path="/email-recipients" element={<Protector><ContractEmailRecipients /></Protector>} sensitive />
                <Route path="/price-list" element={<Protector><PriceList /></Protector>} sensitive />
                {/* <Route path="/" element={<Navigate to="pricelist" />} sensitive /> */}
                <Route path="/price-list/:id/price-list-items" element={<Protector><PriceListItems /></Protector>} sensitive />
                <Route path="/template" element={<Protector><ContractTemplate /></Protector>} sensitive />
                <Route path="/exports" element={<Protector><Exports /></Protector>} sensitive />
                <Route path="*" element={<Navigate replace to="/contracts" />} />
            </Routes>
        );
    }
}

export default ContractSettingsRouter;