import React from 'react';
export default function SimpleBox(props) {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '10px', ...props.style }}>
            <span className="dx-form-group-caption dx-widget">{props.title}</span>
            <div style={{ borderTop: '1px solid #ddd', paddingBottom: '20px', paddingTop: '19px', marginTop: '6px' }} className="dx-form-group-content dx-form-group-with-caption dx-widget">
                {props.children}
            </div>
        </div>
    );
}