import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import * as paymentBatchCrud from "../../../../crud/paymentBatch.crud";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';
import { Badge } from "react-bootstrap";
import CurrencyBatchModal from './CurrencyBatchModal/CurrencyBatchModal';

class PaymentBatchList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: null,
            showWizard: false
        };
        this.multipleCurrencyWizardEnabled =
            process.env.REACT_APP_SECONDARY_PRICES_ENABLED === 'true'
            && process.env.REACT_APP_PAYMENT_BATCH_ENABLED === 'true'
            && Boolean(props.contract.otherprice)
            && props.workflowItem.userFilters.mainNotice.createAllowed;


        this.additionalButtons = [];
        if (this.multipleCurrencyWizardEnabled) {
            this.additionalButtons.push(
                {
                    location: "after",
                    locateInMenu: "auto",
                    widget: "dxButton",
                    options:
                    {
                        icon: "plus",
                        onClick: () => { this.setState({ showWizard: true }) },
                        text: "Create Batch",
                    }
                }
            );
        }


        this.columns = [
            { caption: "Reference ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.CREATED_DATE", dataField: "createdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.CREATED_BY", dataField: "createby" },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_DATE", dataField: "communicateddate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_BY", dataField: "communicatedby" },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "status", cellRender: this.renderStatusColumn, calculateCellValue: this.calculateStatusValue },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
        ];

    }

    componentDidMount() {
        this.loadPaymentBatches();
        if (window.location.hash === "#wizard") {
            //remove hash from URL
            window.history.replaceState("", document.title, window.location.pathname);
            this.setState({ showWizard: true })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (window.location.hash === "#wizard") {
                //remove hash from URL
                window.history.replaceState("", document.title, window.location.pathname);
                this.setState({ showWizard: true })
            }
        }
    }
    loadPaymentBatches = async () => {
        try {
            const { contractid } = this.props.contract;
            const { data: responseObj } = await paymentBatchCrud.getRegisterList(contractid, this.props.workflowItem.config.currentModelType);
            this.setState({ notifications: responseObj });
        } catch (error) {
            console.log(error);
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            if (data.communicated) {
                const url = this.calculateUrl(data, true);
                this.props.navigate(url);
            }
            else {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'PAYMENTBATCH.WARNING.NOT_ALL_COMMUNICATED' }), { variant: 'warning', });
            }

        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        return (skipHostUrl ? '' : process.env.PUBLIC_URL) + this.props.workflowItem.paths.basePath + rowData.paymentbatchid;
    }
    renderStatusColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        switch (cellInfo.data.status) {
            case 'NOT_COMMUNICATED':
                badgeVariant = 'secondary';
                break;
            case 'COMMUNICATED':
                badgeVariant = 'success';
                break;
            default:
                break;
        }
        const displayText = this.calculateStatusValue(cellInfo.data);
        return (<Badge variant={badgeVariant}>{displayText}</Badge>);
    }
    calculateStatusValue = (rowData) => {
        if (rowData.status) {
            return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + rowData.status });
        }
        else {
            return "";
        }
    }


    onCurrencyBatchClosed = () => {
        this.setState({ showWizard: false }, () => {
            this.loadPaymentBatches();
        });
    }

    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }

        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="1"
                        title={`${this.props.title} Register`}
                        name={`${this.props.title}`}
                        dataSource={this.state.notifications}
                        keyExpr="paymentbatchid"
                        columns={this.columns}
                        additionalToolbarItems={this.additionalButtons}
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                    />
                </PortletBody>
                {
                    this.multipleCurrencyWizardEnabled && (
                        <CurrencyBatchModal
                            {...this.props}
                            visible={this.state.showWizard}
                            onClose={this.onCurrencyBatchClosed}
                        />
                    )
                }
            </Portlet>
        );
    }
}




export default withListPageProps(PaymentBatchList);
