import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import { FilePond, registerPlugin } from 'react-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import '../../../_metronic/_assets/sass/global/integration/plugins/_filepond.scss';
import { injectIntl } from "react-intl";
//PLUGINS
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
//Inorder to make this plugin work, all files must be restored from server initially!
// import FilePondPluginGetFile from 'filepond-plugin-get-file'
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';

//FilePond.registerPlugin(FilePondPluginFileRename);
// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
// // Register the plugins


registerPlugin(FilePondPluginFileRename, FilePondPluginGetFile);//FilePondPluginGetFile

class DragDropFilepond extends Component {

    constructor(props) {
        super(props);
        const pondFiles = this.getInitialFiles(props.files);
        this.state = {
            files: pondFiles
        };
    }
    shouldComponentUpdate() {
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.files !== this.props.files) {
            const pondFiles = this.getInitialFiles(this.props.files);
            this.setState({ files: pondFiles });
        }
    }
    getInitialFiles = (files) => {
        const pondFiles = [];
        //https://pqina.nl/filepond/docs/patterns/api/filepond-object/#setting-initial-files
        files.forEach(file => {
            pondFiles.push(
                {
                    source: file.fileid,
                    status: 3,
                    options: {
                        type: 'limbo', // 'local'
                        // status: 3,
                        metadata: {
                            url: `${process.env.REACT_APP_API_URL}Helpers/Download.ashx?Token=${this.props.authToken}&ID=${file.fileid}`
                        },
                        file: {
                            status: 3,
                            name: file.title + file.ext,
                            // src: 'http://localhost:600/Helpers/Download.ashx?Token=654456'
                            //type: file.ext,
                            // metadata: {
                            //     url: 'http://localhost:600/Helpers/Download.ashx?Token=654456'
                            // }
                        },
                    }
                }
            );
        });
        return pondFiles.sort((a, b) => Number(b.source) - Number(a.source));
    }

    // filesUpdated = (fileItems) => {
    //     console.log('fileItems', fileItems);
    // }
    process = (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        if (file.name === 'NO_TITLE') {
            abort(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_ENTER_ATTACHMENT_NONAME' }));
            return;
        }

        let title = file.name;
        if (file.name && file.name.includes('.')) {
            const parts = file.name.split('.');
            title = parts[0];
        }

        const data = new FormData();
        data.append("title", title);
        data.append("UploadedImage", file, file.name);
        var config = {
            onUploadProgress: function (e) {
                progress(e.lengthComputable, e.loaded, e.total);
            }
        };
        this.props.uploadMethod(data, config)
            .then(response => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_FILE_UPLOADED' }), { variant: 'success', });
                progress(true, 1, 1);
                load(response.data);
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                error(err.toString());
            });
    }
    load = (source, load, error, progress, abort, headers) => {
        return {
            abort: () => {
                abort();
            }
        };
    }
    fetch = (url, load, error, progress, abort, headers) => {
        return {
            abort: () => {
                abort();
            }
        };
    }
    delete = (uniqueFileId, load, error) => {
        if (uniqueFileId) {
            this.props.deleteMethod(uniqueFileId)
                .then(response => {
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_FILE_DELETED' }), { variant: 'success', });
                    load();
                })
                .catch(err => {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                    error(err.toString());
                });
        }
        else {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.ERROR_FILE_ID_MISSING' }), { variant: 'error', });
            error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.ERROR_FILE_ID_MISSING' }));
        }

    }
    // onAddFile = (error, file) => {
    //     console.log(error, file);
    // }
    fileRenameFunction = (file) => new Promise((resolve, reject) => {
        let fileName = file.name;
        let originalExtension = null;
        if (fileName.includes('.')) {
            const parts = fileName.split('.');
            originalExtension = parts.pop();
            fileName = parts.join('.');
        }

        const result = window.prompt(`${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_ENTER_ATTACHMENT_NAME' })} ${fileName}`, fileName);
        if (result) {
            resolve(result + '.' + originalExtension);
        }
        else {
            resolve('NO_TITLE');
        }
    })
    onupdatefiles = (files) => {
        try {
            if (this.props.onFilesCountChanged) {
                this.props.onFilesCountChanged(files.length);
            }
        } catch (error) {
            console.log('Error in filepond', error)
        }
    }
    render() {
        const fleProps = {}
        fleProps.maxFiles = this.props.disabled ? this.state.files.length : 10;
        if (this.props.files?.length > 10) {
            //IN Case migrated data has more than 10 files
            fleProps.maxFiles = this.props.files.length;
        }
        if (this.props.disabled) {
            fleProps.labelIdle = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_NO_ATTACHMENT' });
            fleProps.disabled = true;
        }
        const IdleBrowseButton = `<span class="filepond--label-action">${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_BROWSE' })}`;
        const IdleText = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DRAG_DROP' }).replace("{BROWSE_BUTTON}", IdleBrowseButton)
        return (
            <FilePond
                name="filePond"
                // className="filePondStyle pondStyle"
                // stylePanelLayout="compact circle"
                // onaddfile={this.filesUpdated}
                // onremovefile={this.filesUpdated}
                ref={ref => this.pond = ref}
                onupdatefiles={this.onupdatefiles}
                {...fleProps}
                files={this.state.files}
                allowMultiple={true}
                allowRevert={this.props.canNotEdit ? false : true}
                allowFileRename={true}
                fileRenameFunction={this.fileRenameFunction}
                labelFileProcessingComplete={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UPLOADED' })}
                labelButtonDownloadItem={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DOWNLOAD_FILE' })}
                allowDownloadByUrl={true}
                allowDrop={this.props.canNotEdit ? false : true}
                allowBrowse={this.props.canNotEdit ? false : true}
                allowPaste={false}
                disabled={this.props.disabled}
                labelIdle={IdleText}
                server={{
                    // GO TO https://pqina.nl/filepond/docs/patterns/api/server/#configuration
                    url: 'custom',
                    process: this.process,
                    load: this.load,
                    fetch: this.fetch,
                    restore: this.fetch,
                    revert: this.delete,
                }}
            //labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            />
        );
    }
}

export default injectIntl(withSnackbar(DragDropFilepond));