import { userFilterCategories } from "../../../shared/lookup";
const workflows = [

    {
        id: 19,
        title: '$SUPPLIER_NAME Proposals',
        notificationName: 'Proposal',
        abbreviation: 'Proposal-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'PM',
        //REPLY
        replyEnabled: true,
        replyPeriodOption: "PM_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Proposals',
            currentModel: 'Proposal',
            currentModelType: '',
            referenceType: 'SubmissionWithQuote',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableFinancialLimit: true,
            configurableAssets: true,

            replyPeriodOptions: ["PM_REPLY_PERIOD", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'proposal',
                create: 'create',
                list: 'list',
                default: 'list'
            }

        }
    }

]

export default workflows;