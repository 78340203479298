import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { injectIntl } from "react-intl";
import BookmarkPageFirst from './BookmarkPageFirst';
import BookmarkPageSecond from './BookmarkPageSecond';
import { TabPanel, Item } from 'devextreme-react/tab-panel';

export const RESPONSE = {
    OK: 'OK',
    CANCEL: 'CANCEL',
};

class BookmarkModal extends React.Component {
    constructor(props) {
        super(props);
        this.validatorRef = React.createRef();
        this.validatorName = 'BookmarkModalValidator';
        this.state = {
            saveButtonEnabled: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible !== this.props.visible) {
            if (this.props.visible) {
                this.saved = false;
            }
            this.setState({ saveButtonEnabled: this.props.visible });
        }
    }
    tabFirstPage = () => {
        return (
            <div style={{ overflowY: 'auto' }}>
                <BookmarkPageFirst
                    {...this.props}
                    onValueChanged={this.onValueChanged}
                    onBookmarkCaptured={this.props.onBookmarkCaptured}
                />
            </div>
        );
    };
    tabSecondPage = () => {
        return (
            <div style={{ overflowY: 'auto' }}>
                <BookmarkPageSecond
                    {...this.props}
                    onValueChanged={this.onValueChanged}
                    onClose={this.onClose}
                    onBookmarkCaptured={this.props.onBookmarkCaptured}
                />
            </div>
        );
    };
    render() {
        return (
            <Popup
                visible={this.props.visible}
                onHiding={this.props.onClose}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showTitle={true}
                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POWERBI_BOOKMARK_TITLE' })}
                // width={600}
                height='auto'
                //minWidth={800}
                //minHeight={600}
                width='40vw'
            >
                <TabPanel
                    width="100%"
                    height="100%"
                    deferRendering={true}
                    animationEnabled={true}
                    focusStateEnabled={true}
                    hoverStateEnabled={true}
                    swipeEnabled={false}
                >
                    <Item
                        //height="100%"
                        title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POWERBI_SAVE_TO_MY_VIEWS' })}
                        render={this.tabFirstPage}
                    />
                </TabPanel>
            </Popup>
        );
    }
}

export default injectIntl(BookmarkModal);