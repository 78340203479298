import React from 'react';
import DxGroupBox from '../../../../../partials/content/dxControls/dxGroupBox';
import PlaceholderSimpleModal from './PlaceholderSimpleModal';
import List from 'devextreme-react/list';
import { SelectBox } from 'devextreme-react/select-box';
import DefaultResources from '../../../../../Templates/DefaultResources';

class ResourcesListManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editingItem: null,
        };
    }
    onItemSelected = (e) => {
        if (e.itemData) {
            const selectedItem = JSON.parse(JSON.stringify(e.itemData));
            this.setState({ editingItem: selectedItem });
        }
        else {
            this.setState({ editingItem: null });
        }
    }
    // newItemClicked = () => {
    //     const emptyItem = this.props.itemProperties.reduce((obj, field) => ({ ...obj, [field]: null }), {});
    //     this.setState({ editingItem: emptyItem });
    // }
    itemRender = (item) => {
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ width: '50%' }}>
                    {item.key}
                </div>
                <div style={{ width: '50%', borderLeft: '2px solid #ccc', paddingLeft: 10 }}>
                    {item.value}
                </div>
            </div>
        );
    }
    dropItemSelected = (e) => {
        if (e.value) {
            let newItemList = [];
            if (this.props.items && this.props.items.length > 0) {
                newItemList = JSON.parse(JSON.stringify(this.props.items));
            }
            newItemList.push(e.value.ToTemplateJSON());
            this.updateParent(newItemList);
        }
    }
    onModalClose = (itemFromModal) => {
        this.setState({ editingItem: null });
        if (itemFromModal) {
            const newItem = JSON.parse(JSON.stringify(itemFromModal));
            let newItemList = [];
            if (this.props.items && this.props.items.length > 0) {
                newItemList = JSON.parse(JSON.stringify(this.props.items));
            }
            if (newItem.id && newItem.id > 0) {
                /// UPDATE EXISTING ITEM
                newItemList[newItemList.findIndex(itm => itm.key === newItem.key)] = newItem;
            }
            // else {
            //     /// ADD NEW ITEM
            //     let maxId = 1;
            //     if (newItemList.length > 0) {
            //         maxId = Math.max(...newItemList.map(itm => itm[this.props.keyExpr]));
            //     }
            //     newItem[this.props.keyExpr] = maxId + 1;
            //     newItemList.push(newItem);
            // }
            this.updateParent(newItemList);
        }
    }
    itemRemoved = (e) => {
        if (e.itemData) {
            this.updateParent(this.props.items);
        }
    }
    updateParent = (newList) => {
        this.props.listChanged(newList);
    }

    render() {
        const selectOptions = DefaultResources.filter(rsc => (this.props.items.findIndex(givenRsc => givenRsc.key === rsc.key)) < 0);
        return (
            <DxGroupBox showBorder={true}>
                <SelectBox dataSource={selectOptions}
                    displayExpr="key"
                    searchEnabled={true}
                    searchMode="contains"
                    searchExpr="key"
                    searchTimeout={200}
                    minSearchLength={0}
                    showDataBeforeSearch={true}
                    onValueChanged={this.dropItemSelected}
                    value={null}
                    readOnly = {this.props.readOnly}
                />
                <List
                    ref={(ref) => this.listComp = ref}
                    style={{ border: '1px solid #ccc', borderRadius: '4px' }}
                    //height='100%'
                    allowItemDeleting= {!this.props.readOnly}
                    items={this.props.items}
                    keyExpr="key"
                    displayExpr="key"
                    selectionMode="single"
                    //selectedItem={this.state.selectedItem}
                    onItemDeleted={this.itemRemoved}
                    onItemClick={this.onItemSelected}
                    repaintChangesOnly={false}
                    cacheEnabled={false}
                    itemRender={this.itemRender}                    
                />
                <PlaceholderSimpleModal
                    onClose={this.onModalClose}
                    item={this.state.editingItem}
                    itemDescription={this.props.itemDescription}
                    readOnly = {this.props.readOnly}
                />

            </DxGroupBox>
        );
    }


}


export default ResourcesListManager