import React from 'react';
import { connect } from "react-redux";
import { TableRow, TableHead, TableCell, TableBody, Table, Typography } from "@mui/material";
import Common from '../../../../shared/common';
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";
class CompensationArea extends React.Component {
    state = {
        notifications: null,
    };
    componentDidMount() {
        this.setNotifications();
    }

    setNotifications = () => {
        if (this.props.compensationsList) {
            const cenStates = ['QA', 'AM'];
            const notifications = this.props.compensationsList
                .filter(itm => 
                    itm.compensationsource === this.props.notificationType && 
                    itm.showtasknotification === 1 && 
                    cenStates.includes(itm.status))
                .sort((a, b) => a.referenceid > b.referenceid);
            this.setState({ notifications: notifications });
        }
    }

    // setNotifications = () => {
    //     if (this.props.compensationsList) {
    //         const notifications = this.props.compensationEvents[this.props.notificationType].data
    //             .filter(itm => itm.status === "QA" || itm.status === "AM")
    //             .sort((a, b) => a.sequencenumber - b.sequencenumber);
    //         this.setState({ notifications: notifications });
    //     }
    // }
    render() {
        if (!this.state.notifications) {
            return null;
        }
        return (
            <div style={{ marginTop: 20 }}>
                <Typography style={{ marginLeft: 16 }} variant="h6" component="div" gutterBottom>
                    {this.props.title}
                </Typography>
                <Table style={{ border: '1px solid #ccc', borderRadius: '2px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.ID" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.SUBJECT" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.CLAUSE" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.NOTIFIED_DATE" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.NOTIFIED_BY" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.IMPLEMENTED" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.AMOUNT" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.DAYS" /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.state.notifications.map(compensation => (
                                <TableRow key={compensation.sequencenumber}>
                                    <TableCell>{compensation.referenceid}</TableCell>
                                    <TableCell>{compensation.compensationnumber}</TableCell>
                                    <TableCell>{compensation.compensationtype}</TableCell>
                                    <TableCell>{moment(compensation.notificationdate).format('L')}</TableCell>
                                    <TableCell>{compensation.createby}</TableCell>
                                    <TableCell>{moment(compensation.assessmentdate).format('L')}</TableCell>
                                    <TableCell>{Common.FormatCurrencyNum(compensation.assessmentamount, this.props.contract.currency)}</TableCell>
                                    <TableCell>{`${compensation.alternativequotation ? compensation.alternativequotation : '0'} ${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' })}`}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </div>
        );
    }
}
const mapStateToProps = store => ({
    contract: store.contract.contract,
});

export default injectIntl(connect(mapStateToProps)(CompensationArea));