import React, { Component } from 'react';
import Logger from '../../shared/logger';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: "" };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    let errorMessage = "";
    try {
      errorMessage = error.toString();
    }
    catch {
      errorMessage = "";
    }

    return { hasError: true, errorMessage };
  }

  componentDidCatch(error, info) {
    // console.log(error, info)
    let errorObj = error;
    if (error instanceof Error) {
      errorObj = {
        // Pull all enumerable properties, supporting properties on custom Errors
        ...error,
        // Explicitly pull Error's non-enumerable properties
        name: error.name,
        message: error.message,
        stack: error.stack,
      }
    }
    Logger.PushError(errorObj, info, 'UNHANDLED');
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ margin: 20 }}>
          <h1> Something went wrong.</h1>
          <p >
            {this.state.errorMessage}  <br />
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;