/* eslint-disable no-eval */
import React from 'react';
import TemplateHelper from '../../../Templates/TemplateHelper';
import * as notificationCrud from '../../../crud/notification.crud';
import moment from 'moment';
import CustomFieldHelper from "../_Helpers/CustomFieldHelper";
import { customFieldsWfSubTypes } from '../../../shared/lookup';
import Common from "../../../shared/common";

const attachmentMode = process.env.REACT_APP_ATTACHMENT_MODE;
const isAssetsCustomFieldsEnabled = process.env.REACT_APP_EMAIL_ASSET_CUSTOMFIELDS_ENABLED === "true";
const customFieldsWfSubTypesCommon = [customFieldsWfSubTypes.EWREDUCTION, customFieldsWfSubTypes.QUOTE, customFieldsWfSubTypes.QUOTE_REPLY, customFieldsWfSubTypes.RFQQUOTE, customFieldsWfSubTypes.RFQQUOTE_REPLY, customFieldsWfSubTypes.ITQQUOTE, customFieldsWfSubTypes.ITQQUOTE_REPLY];

class BaseDetailPage extends React.Component {
    componentWillUnmount() {
        if (this.props.closeSnackbar) {
            this.props.closeSnackbar("PersistentFormWarning");
        }
        if (this.props.setReference) {
            this.props.setReference(null);
        }
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }
    getNotifyUserList = (action) => {
        try {
            if (!this.props.workflowItem.userFilters.notifyFilter) {
                throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.MISSING_NOTIFY_FILTER' }));
            }
            // if(!(this.props.contract||{}).designatedUsers){
            //     throw new Error("Contract designated users are not available!");
            // }
            if (!action || (this.props.workflowItem.notify === false && (action === 'COMMUNICATE' || action === 'REPLY_SEND'))) {
                return [];
            }
            // const { pm, supplier, supervisor, employer } = (this.props.contract||{}).designatedUsers;
            // //DO NOT DISABLE MAIL NOTIFICATION FOR CONTRACT PARTY MANAGERS
            // const exceptionUserIDList = [];
            // if (pm && pm.userid) {
            //     exceptionUserIDList.push(pm.userid);
            // }
            // if (supplier && supplier.userid) {
            //     exceptionUserIDList.push(supplier.userid);
            // }
            // if (supervisor && supervisor.userid) {
            //     exceptionUserIDList.push(supervisor.userid);
            // }
            // if (employer && employer.userid) {
            //     exceptionUserIDList.push(employer.userid);
            // }
            ///.filter(u => !(u.disableglobalemailnotifications === true && !exceptionUserIDList.includes(u.accountid)))
            const mappedUsers = this.props.contractUsers.map(u => ({
                party: u.usergroup,
                rolepermissions: u.rolepermissions,
                accountid: u.accountid,
                mail: u.useremail
            }));
            const logicExpression = TemplateHelper.ConvertDxFilterExpToLogicExp(JSON.parse(this.props.workflowItem.userFilters.notifyFilter), 'user');
            const notifyUserList = [];
            mappedUsers.forEach(user => {
                if (eval(logicExpression)) {
                    notifyUserList.push(user.accountid);
                }
            });
            
            const contractOverviewUsers = this.prepareMustAddContractOverviewUsers();
            let mergedArray = notifyUserList.concat(contractOverviewUsers);
            let uniqueArray = [...new Set(mergedArray)];
            return uniqueArray;
        } catch (error) {
            console.log('error in getNotifyUserList', error, this.props.contractUsers);
        }
    }
    prepareMustAddContractOverviewUsers = () => {
        const { projectmanagerid, contractmanagerid, employerid, supervisorid } = this.props.contract;

        const userList = [];

        const userFilters = this.props.workflowItem.userFilters.notifyFilter;

        if (userFilters.includes('PM') && projectmanagerid) {
            userList.push(projectmanagerid);
        }

        if (userFilters.includes('Contractor') && contractmanagerid) {
            userList.push(contractmanagerid);
        }

        if (userFilters.includes('Client') && employerid) {
            userList.push(employerid);
        }

        if (userFilters.includes('Supervisor') && supervisorid) {
            userList.push(supervisorid);
        }

        return userList;
    }
    prepareNotifyObject = (action, assetId, customFields) => {
        const { companyid, projectid, contractid, name } = this.props.contract;
        const description = this.getMailDescription()
        let title = '';
        if (action === 'COMMUNICATE') {
            title = this.props.intl.formatMessage({ id: 'MAIL.TITLE.COMMUNICATE' }, { CONTRACT_NAME: name, ITEM_ID: this.state.notification.referenceid, SUBJECT: description });
        }
        else if (action === 'REPLY_SEND') {
            title = this.props.intl.formatMessage({ id: 'MAIL.TITLE.REPLY' }, { CONTRACT_NAME: name, ITEM_ID: this.state.notification.referenceid, SUBJECT: description });
        }
        else if (action === 'REPLY_DRAFT') {
            title = this.props.intl.formatMessage({ id: 'MAIL.TITLE.REPLY_DRAFTED' }, { CONTRACT_NAME: name, ITEM_ID: this.state.notification.referenceid, SUBJECT: description });
        }
        else if (action === 'SAVE_DRAFT') {
            title = this.props.intl.formatMessage({ id: 'MAIL.TITLE.DRAFTED' }, { CONTRACT_NAME: name, ITEM_ID: this.state.notification.referenceid, SUBJECT: description });
        }

        const notifyObject = {
            companyid: companyid,
            projectid: projectid,
            contractid: contractid,
            // type: 'Notification',
            taskurl: window.location.href,
            description: description,
            // accountid: this.props.contractUser.accountid,
            // createbyid: this.props.contractUser.accountid,
            // createforid: action === 'REPLY_DRAFT' ? this.state.notification.draftreplytoid : null,
            // createdate: moment().format('DD/MM/YYYY'),
            // requireddate: moment().format('DD/MM/YYYY'),
            group: this.props.workflowItem.pageTitle,
            category: this.props.workflowItem.pageTitle,
            title: title,
            reference: this.props.params.notificationid,
            action: action,
            assetid: assetId,
            referenceType: this.props.workflowItem.config.referenceType,
            customfieldvalues: customFields
        };
        return notifyObject;
    }
    notify = (action, childObject, notifyuserid) => {
        //CHECK IF FILTER EXIST ON CHILD COMPONENT (DETAIL PAGE)
        if (!this.props.workflowItem.userFilters.notifyFilter) {
            throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.MISSING_NOTIFY_FILTER' }));
        }
        // if(!(this.props.contract||{}).designatedUsers){
        //     throw new Error("Contract designated users are not available!");
        // }
        if (!action || (this.props.workflowItem.notify === false && (action === 'COMMUNICATE' || action === 'REPLY_SEND'))) {
            return;
        }
        try {
            if (action === 'COMMUNICATE' || action === 'REPLY_SEND' || ((action === 'REPLY_DRAFT' || action === 'SAVE_DRAFT') && notifyuserid)) {
                const notifyUserList = notifyuserid ? [notifyuserid] : this.getNotifyUserList(action);
                if (notifyUserList && notifyUserList.length > 0) {
                    let assetId = 0;
                    let customFields = [];

                    if (isAssetsCustomFieldsEnabled) {
                        assetId = childObject.assetid;
                        customFields = childObject.customfieldvalues;
                    }

                    const notifyObject = this.prepareNotifyObject(action, assetId, customFields);
                    notifyObject.pdfjsoncontent = childObject.pdfjsoncontent;
                    notifyObject.notificationtype = childObject.notificationtypeforemailtemplate;
                    notificationCrud.sendMailNotifications(notifyUserList, notifyObject);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    ///USE TO GENERATE DOCUMENT JSON
    documentConfig = async (notification, bodyConfig, notifiedBy, isReply, customFieldSubType, resources, notifiedByCompanyName) => {
        const title = isReply ?
            this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TITLE.REPLY' }, { ITEM_NAME: this.props.workflowItem.pageTitle }) :
            this.props.workflowItem.pageTitle;

        const companyLogoID = isReply ? this.props.workflowItem.form_ReplyCompanyLogoID : this.props.workflowItem.form_NotifyCompanyLogoID;
        const companyName = isReply ? this.props.workflowItem.form_ReplyCompanyName : this.props.workflowItem.form_NotifyCompanyName;

        const docConfig = {
            title: title,
            logoCompany: companyLogoID,
            logoCompanyName: companyName,
            appDomainUri: `${window.location.origin}/`,
            apiDomainUri: `${process.env.REACT_APP_API_URL}`,
            noticeUri: `${window.location.href}`,
            headerColumns: [
                { label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.CONTRACT_NAME' }), value: this.props.contract.name },
                { label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.REFERENCE' }), value: notification.referenceid },
                { label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.DATE' }), value: "$NOW" },
            ],
            rows: []
        }

        //handle second reference exception for Proposed Instructions
        if (notification.referenceid_proposedinstructions) {
            docConfig.headerColumns.splice(2, 0, { label: resources.reference2Label, value: notification.referenceid_proposedinstructions })
        }

        /// Inject Asset Fields
        if (!isReply && notification.asset) {
            docConfig.rows.push(
                { label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.ASSET_NAME' }), value: (notification.asset.AssetName || "") },
                { label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.ASSET_CODE' }), value: (notification.asset.AssetCode || "") },
                { label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.ASSET_TYPE' }), value: (notification.asset.AssetType || "") },
                { label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.ASSET_LINK' }), text: (notification.asset.AssetLink || ""), valueType: 'LINK', url: (notification.asset.AssetLink || "") }
            );
        }

        //Inject Price & Date Changes
        if (notification.otherpricechanges && notification.otherpricechanges.length > 0) {
            notification.otherpricechanges.forEach(pr => {
                const valText = `${this.props.intl.formatMessage({ id: 'SECONDARYPRICE.FORMS.IMPACT_ON_PRICE' })} ${Common.FormatCurrencyNum(pr.pricechange, pr.currency)}`;
                bodyConfig.push({
                    label: pr.name,
                    value: valText,
                    valueType: 'CURRENCY',
                    currencyCode: pr.currency
                })
            });
        }

        if (notification.otherdatechanges && notification.otherdatechanges.length > 0) {
            notification.otherdatechanges.forEach(dtChange => {
                const valText = `${this.props.intl.formatMessage({ id: 'SECONDARYDATE.FORMS.IMPACT_ON_DATE' })} ${(dtChange.daychange ? dtChange.daychange : 0)} ${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' })}`;
                bodyConfig.push({
                    label: Common.setDateName(dtChange, this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_START_DATE' }), this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_COMPLETION_DATE' })),
                    value: valText
                })
            });
        }

        const bodyFields = bodyConfig.map(row => {
            if (row.resource) {
                row.label = this.props.intl.formatMessage({ id: row.resource });
                delete row.resource;
            }
            //if the value is null, replace with empty string
            if (row.hasOwnProperty("value") && row.value === null) {
                row.value = "";
            }
            if (row.value instanceof Date) {
                row.value = moment(row.value).format('L');
            }
            return row;
        });

        docConfig.rows.push(...bodyFields);



        /// Inject CustomFields
        if (!customFieldSubType) {
            //ASSIGN DEFAULT IF NOT STATED
            customFieldSubType = isReply ? "REPLY" : "COMMUNICATION";
        }

        let customFields = [];
        if (customFieldsWfSubTypesCommon.includes(customFieldSubType)) {
            customFields = (this.props.customFieldsCommon || {})[customFieldSubType];
        }
        else {
            customFields = (this.props.customFields || {})[customFieldSubType];
        }
        CustomFieldHelper.MapCustomFieldsToDocumentConfigurationV2(
            customFields,
            notification,
            docConfig.rows,
            isReply,
            this.props.enqueueSnackbar,
            (this.props.contract || {}).currency,
            this.props.contractUsers
        );
        //ATTACHMENTS
        const renderAttachmentsGrid = (isReply && this.props.workflowItem.replyAttachments) || (!isReply && this.props.workflowItem.notifyAttachments)
        if (renderAttachmentsGrid) {
            //get latest files list
            let files = [];
            if (isReply) {
                files = await this.fetchReplyFiles();
            }
            else {
                files = await this.fetchFiles();
            }
            let documentsRow = {};
            if (attachmentMode === 'ASITE') {
                documentsRow = {
                    label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.DOCUMENTS' }),
                    headerColumns: [
                        { caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.DOCREF' }), dataField: "docref" },
                        { caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.NAME' }), dataField: "name" },
                        { caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.REVISION' }), dataField: "revision" },
                        { caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.STATUS' }), dataField: "status" },
                        { caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.MODIFIEDDATE' }), dataField: "dateModified", type: 'DATETIME' }
                    ],
                    data: files.map(file => {
                        const docData = ({
                            fileid: file.fileid,
                            text: file.name,
                            url: window.location.href,
                            directlink: ((file.metadata || {}).directlink || ""),
                            name: ((file.metadata || {}).name || ""),
                            docref: ((file.metadata || {}).docref || ""),
                            revision: ((file.metadata || {}).revision || ""),
                            status: ((file.metadata || {}).status || ""),
                            dateModified: ((file.metadata || {}).dateModified || "")
                        });
                        return docData;
                    }),
                    valueType: 'DOCUMENTSGRID',
                    align: 'vertical'
                }
            } else {
                documentsRow = {
                    label: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.DOCUMENTS' }),
                    data: files.map(file => {
                        const docData = ({
                            fileid: file.fileid,
                            text: file.name,
                            url: window.location.href,
                            directlink: ((file.metadata || {}).directlink || "")
                        });
                        return docData;
                    }),
                    valueType: 'DOCUMENTS'
                }
            }
            docConfig.rows.push(
                documentsRow
            );
        }

        const notifyByLabel = isReply ? this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.REPLIED_BY' }) : this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.NOTIFIED_BY' });
        docConfig.rows.push(
            { label: notifyByLabel, value: notifiedBy, }
        );


        //APPENT COMPANY NAME IF NECESSARY.
        if (this.props.workflowItem.form_IncludeCompanyName) {
            docConfig.rows.push(
                {
                    label: '',
                    value: notifiedByCompanyName
                }
            )
        }

        return docConfig;
    }
    // getCurrentNotificationReference = () => {
    //     return this.state.notification.referenceid;
    // }

    getOverrideReplyDraftAllowed = (party) => {
        //WHEN EDITED ALSO CHECK LOGIC IN TemplateHelper.GetDefaultFilter_OverrideCommunicateReplyFilter
        return this.props.contractUser.party === TemplateHelper.MapPartiesToDBNaming(party) &&
            this.props.contractUser.rolepermissions !== "Read Only";
    }
    getOverrideReplyCommunicateAllowed = (party) => {
        //WHEN EDITED ALSO CHECK LOGIC IN TemplateHelper.GetDefaultFilter_OverrideCommunicateReplyFilter
        return this.props.contractUser.party === TemplateHelper.MapPartiesToDBNaming(party) &&
            this.props.contractUser.rolepermissions === "Manager";
    }
    getNotifyUserListForUpdate = (action, notifyuserid) => {
        if (['COMMUNICATE', 'REPLY_SEND'].includes(action)) {
            return this.getNotifyUserList(action);
        }
        else if (notifyuserid) {
            return [notifyuserid];
        }
        else {
            return [];
        }
    }
}

export default BaseDetailPage;
