/* eslint-disable import/no-anonymous-default-export */
import ThirdParty from "supertokens-auth-react/recipe/thirdparty";
import Session from "supertokens-auth-react/recipe/session";
import React from 'react';

//supertokens-auth-react API
//https://supertokens.com/docs/auth-react/0.27.X/modules/recipe_thirdparty.html
//https://github.com/supertokens/supertokens-auth-react

//Implementation Guide
//https://supertokens.com/docs/emailpassword/pre-built-ui/setup/frontend
export const getSuperTokenConfig = (tennantID, formFields, buttonRef) => {
    return {
        languageTranslations: {
            translations: {
                en: {
                    BRANDING_POWERED_BY_START: "Power by Built Intelligence(v"+process.env.REACT_APP_VERSION+") and ",
                    THIRD_PARTY_SIGN_IN_AND_UP_HEADER_TITLE: "FastDraft SSO Login"
                },
            },
            defaultLanguage: "en",
        },
        appInfo: {
            appName: "Built Intelligence SSO",
            apiDomain: "https://passport.builtintelligence.com",
            websiteDomain: window.location.origin,//"https://lantisuat.builtintelligence.com",
            apiBasePath: "/auth",
            websiteBasePath: process.env.REACT_APP_SSO_METHOD === 'DISCOVER' ? "/auth/discover/supertoken" : "/auth",
        },
        recipeList: [
            ThirdParty.init({
                override: {
                    components: {
                        ThirdPartySignInAndUpHeader_Override: ({ DefaultComponent, ...props }) => {
                            return (
                                <div>
                                    <img src="https://cdn.shopify.com/s/files/1/0248/4926/files/BI_logo_300x90_300x90.png?v=1613712008" alt="Built Intelligence" />
                                    <DefaultComponent {...props} />
                                </div>
                            );
                        },
                        ThirdPartySignInAndUpProvidersForm_Override: ({ DefaultComponent, ...props }) => {
                            return (
                                <div>
                                    {formFields}
                                    <DefaultComponent {...props} />
                                </div>
                            );
                        },
                    }
                },
                preAPIHook: async (context) => {
                    let requestInit = context.requestInit;

                    let headers = {
                        ...requestInit.headers,
                        "customer-orgid": tennantID ? tennantID : "builtintelligence",
                    };
                    requestInit = {
                        ...requestInit,
                        headers,
                    };

                    return {
                        url: context.url,
                        requestInit,
                    };
                },
                signInAndUpFeature: {
                    style: {
                        providerButton: {
                            backgroundColor: '#3f96c4 !important',
                            color: '#fff !important',
                            borderColor: '#3f96c4 !important',
                        }
                    },
                    providers:
                        [
                            {
                                id: process.env.REACT_APP_SUPERTOKEN_PROVIDER_ID,
                                name: process.env.REACT_APP_SUPERTOKEN_PROVIDER_NAME,
                                ...(process.env.REACT_APP_SSO_METHOD === 'DISCOVER' && { buttonComponent: 
                                    (props) => {
                                        return (
                                            <div style={{
                                                paddinTop: 9,
                                                paddingBottom: 9,
                                            }}>
                                                    <div ref={buttonRef}   
                                                    className="kt-spinner kt-spinner--center kt-spinner--md kt-spinner--dark"                                              
                                                    style={{
                                                        background: "#3f96c4",
                                                        color: "#fff",
                                                        borderColor: "#3f96c4",
                                                        minHeight: "32px",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        padding: "2px 8px",
                                                        fontWeight: 700,
                                                        borderWidth: "1px",
                                                        borderStyle: "solid",
                                                        borderRadius: "6px",
                                                        transition: "all 0.4s",
                                                        cursor: "pointer",
                                                        justifyContent:"center",
                                                        pointerEvents:"none",
                                                        opacity: 0.5
                                                    }}>
                                                        <div style={{
                                                                fontWeight: 700,
                                                                textAlign: "center",
                                                                justifyContent: "center",
                                                                overflow: "hidden",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis",
                                                                display: "inline-block",
                                                        }}>
                                                            Redirecting to tenant
                                                        </div>
                                                    </div>
                                            </div>
                                        );
                                    }
                                 })
                            }
                        ],
                },
            }),
            Session.init(),
        ],

    };
}

