import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ClaimsList from './claims/List';
import ClaimsCreate from './claims/Create';
import ClaimsDetail from './claims/Detail';

class ClaimsRouter extends React.Component {
    constructor(props) {
        super(props);
        this.communicateToUser = props.globalResources.PM_NAME;
        this.notificationType = "CLAIM";
        this.documentType = 'DocumentClaim';

        this.notifyingParty = props.workflowItem.notifyingParty;
        this.replyingParty = props.workflowItem.replyingParty;

        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;
        this.replyPeriod = props.workflowItem.replyPeriod;

        this.clauses = props.placeholders.find(ph => ph.replacementKey === 'ClaimClauses')
        .values.filter(item => item.isActive === true);

        // #region TASK ORDER ENABLED
        this.taskOrderOptionEnabled = false;
        try {
            if (props.placeholdersQA.TaskOrderOptionEnabled) {
                this.taskOrderOptionEnabled = props.placeholdersQA.TaskOrderOptionEnabled.values
                    .find(phItem => phItem.field === 'TaskOrderOptionEnabled').isActive;
            }
        } catch (error) {
            console.log('TaskOrderOptionEnabled placeholder not available!');
            this.taskOrderOptionEnabled = false;
        }
        //#endregion

        if (this.taskOrderOptionEnabled) {
            this.taskOrderNotificationName = (this.props.workflowsQA['38'] || {}).notificationName;
        }

        // #region PROJECT ORDER ENABLED
        this.projectOrderOptionEnabled = false;
        try {
            if (props.placeholdersQA.ProjectOrderOptionEnabled) {
                this.projectOrderOptionEnabled = props.placeholdersQA.ProjectOrderOptionEnabled.values
                    .find(phItem => phItem.field === 'ProjectOrderOptionEnabled').isActive;
            }
        } catch (error) {
            console.log('ProjectOrderOptionEnabled placeholder not available!');
            this.projectOrderOptionEnabled = false;
        }
        //#endregion

        if (this.projectOrderOptionEnabled) {
            this.projectOrderNotificationName = (this.props.workflowsQA['72'] || {}).notificationName;
        }
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;

        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<ClaimsList
                        {...this.props}
                        
                        notificationType={this.notificationType}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        taskOrderOptionEnabled={this.taskOrderOptionEnabled}
                        taskOrderNotificationName={this.taskOrderNotificationName}
                        projectOrderOptionEnabled={this.projectOrderOptionEnabled}
                        projectOrderNotificationName={this.projectOrderNotificationName}
                    />
                }
                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                            <ClaimsCreate
                                {...this.props}
                                title={this.notificationName}
                                clauses={this.clauses}
                                communicateToUser={this.communicateToUser}
                                notificationType={this.notificationType}
                                taskOrderOptionEnabled={this.taskOrderOptionEnabled}
                                taskOrderNotificationName={this.taskOrderNotificationName}
                                projectOrderOptionEnabled={this.projectOrderOptionEnabled}
                                projectOrderNotificationName={this.projectOrderNotificationName}
                            />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    exact
                    element={<ClaimsDetail
                        {...this.props}
                        replyPeriod={this.replyPeriod}
                        clauses={this.clauses}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                        notificationType={this.notificationType}
                        documentType={this.documentType}
                        communicateToUser={this.communicateToUser}
                        notifyUserFilter={workflowItem.notifyUserFilter}
                        taskOrderOptionEnabled={this.taskOrderOptionEnabled}
                        taskOrderNotificationName={this.taskOrderNotificationName}
                        projectOrderOptionEnabled={this.projectOrderOptionEnabled}
                        projectOrderNotificationName={this.projectOrderNotificationName}
                    />}
                />
            </Routes>
        );
    }
};




export default (ClaimsRouter);