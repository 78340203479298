import React from 'react';
import { connect } from "react-redux";
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { injectIntl } from "react-intl";
import AsiteFileManager from '../Asite/AsiteFileManager';
import * as fileCrud from '../../../crud/file.crud';
import axios from 'axios';
import { withSnackbar } from 'notistack';

export const RESPONSE = {
    OK: 'OK',
    CANCEL: 'CANCEL',
};

class AsiteDialogModal extends React.Component {
    constructor(props) {
        super(props);
        this.validatorRef = React.createRef();
        this.validatorName = 'ReplyDraftModalValidator';
        this.state = {
            selectedItems: null,
            isSavingAttachments: false
        }
    }

    componentDidMount() {
        this.requestSource = axios.CancelToken.source();
    }
    onHiding = () => {
        if (this.props.visible) {
            this.setState({
                selectedItems: null
            }, () => {
                this.props.onDialogResult();
            })

        }
    }
    ///SELECTED ITEMS LIST UPDATED FROM CHILD COMPONENTS
    onSelectedItemsChanged = (items) => {
        this.setState({ selectedItems: items });
    }

    ///ATTACH BUTTON CLICK
    onSaveAttachDocuments = async () => {
        this.setState({ isSavingAttachments: true }, () => { this.processItemsToUpload(); })
    }

    //#region UPLOAD PROCESS
    processItemsToUpload = async (items) => {
        try {
            if ((this.state.selectedItems || []).length > 0 && this.props.visible) {
                const promises = []
                this.state.selectedItems.forEach(item => {
                    if (this.checkFileIfAlreadyAttached(item)) {
                        this.props.enqueueSnackbar(item.docref + " already attached", { variant: 'warning' });
                    }
                    else {
                        promises.push(this.fetchShareLinkAndUpload(item.folderid, item.docref));
                    }
                });
                const allResults = await axios.all(promises);
                const successResults = allResults.filter(file => !!file.fileid)
                this.props.onDialogResult(successResults);
            }
        } catch (err) {
            console.log(err);
            this.props.enqueueSnackbar("Error processing files " + err.toString(), { variant: 'error' });
        }
        finally {
            this.setState({ isSavingAttachments: false });
        }
    }
    checkFileIfAlreadyAttached = (item) => {
        if ((this.props.currentlyAttachedFiles || []).length > 0) {
            return this.props.currentlyAttachedFiles.some(file => (file.docref === item.docref && file.documentid === item.documentid && file.revision === item.revision));
        }
    }
    fetchShareLinkAndUpload = (folderId, docRef) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { data: documentMetaData } = await fileCrud.asiteGetShareLink(this.props.contract.contractid, folderId, docRef, this.requestSource.token)
                const externalFile = this.generateExternalFileObject(documentMetaData);
                const formData = { 
                    metadata: externalFile.metadata,
                    companyid: externalFile.companyid,
                    projectid: externalFile.projectid,
                    contractid: externalFile.contractid,
                    type: externalFile.type,
                    reference: externalFile.reference,
                    title: externalFile.title,
                    name: externalFile.name
                };
                const { data: uploadResultID } = await fileCrud.uploadExternalFile(formData);
                externalFile.fileid = uploadResultID;
                resolve(externalFile);
            } catch (err) {
                console.log(err);
                this.props.enqueueSnackbar("Failed to upload " + docRef + ' ' + err.toString(), { variant: 'error' });
                resolve({ folderId, docRef, error: err });
            }
        });
    }
    generateExternalFileObject = (documentMetaData) => {
        const externalFileObject = { ...documentMetaData };
        let arr = [];
        let notArr = ['isDirectory', 'items'];
        const documentList = Object.entries(documentMetaData);
        if (documentList) {
            documentList.forEach(item => {
                if (!notArr.includes(item[0])) {
                    let doc = {
                        metakey: item[0],
                        metavalue: item[1] !== null ? item[1] : '-'
                    };
                    arr.push(doc);
                }
            })
        }

        const { companyid, projectid, contractid } = this.props.contract;
        externalFileObject.metadata = arr;
        externalFileObject.companyid = companyid;
        externalFileObject.projectid = projectid;
        externalFileObject.contractid = contractid;
        if (this.props.isReply) {
            externalFileObject.type = this.props.documentType + 'Reply';
        } else {
            externalFileObject.type = this.props.documentType;
        }
        externalFileObject.reference = this.props.notificationid;
        externalFileObject.title = documentMetaData.name;
        externalFileObject.name = documentMetaData.name;

        return externalFileObject;
    }
    //#endregion

    render() {
        return (
            <Popup
                visible={this.props.visible}
                onHiding={this.onHiding}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showTitle={true}
                title={this.props.title}
                minWidth={800}
                width='80vw'
                height='82vh'
            >
                <div id="PopupContent" style={{ height: '100%' }}>
                    {
                        this.props.visible && (
                            <AsiteFileManager
                                onSelectedItemsChanged={this.onSelectedItemsChanged}
                            />
                        )
                    }
                </div>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        disabled: (this.state.isSavingAttachments),
                        text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CANCEL" }),
                        onClick: this.onHiding
                    }}>
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: this.props.intl.formatMessage({ id: 'GENERAL.DOCUMENTS.ATTACH_DOCUMENTS' }),
                        disabled: (this.state.isSavingAttachments || !(this.state.selectedItems || []).length),
                        icon: this.state.isSavingAttachments ? 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--dark' : 'save',
                        onClick: this.onSaveAttachDocuments
                    }}>
                </ToolbarItem>
            </Popup>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
});

export default withSnackbar(injectIntl(connect(mapStateToProps)(AsiteDialogModal)));