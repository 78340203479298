import React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import BiDynamicForm from '../../../../../partials/layout/BiDynamicForm';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import { injectIntl } from "react-intl";

export const RESPONSE = {
    OK: 'OK',
    CANCEL: 'CANCEL',
};

class ChangeUserCompanyModal extends React.Component {
    constructor(props) {
        super(props);
        this.validatorRef = React.createRef();
        this.validatorName = 'ReplyDraftModalValidator';
        this.state = {
            saveButtonEnabled: false,
            companyid: null,
            projectid: null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible !== this.props.visible) {
            if (this.props.visible) {
                this.saved = false;
            }
            this.setState({ saveButtonEnabled: this.props.visible });
        }
    }

    onClose = () => {
        this.props.onDialogResult(RESPONSE.CANCEL);
    }
    saveItem = () => {
        let props;
        if (!this.validateForm()) {
            return;
        }
        if (this.props.mode === 'COMPANY_UPDATE') {
            props = { companyid: this.state.companyid };
        }
        if (!this.saved) {
            this.saved = true;
            this.setState({ saveButtonEnabled: false }, () => {
                this.props.onDialogResult(RESPONSE.OK, props);
            });
        }
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        return validationResult.isValid;
    }
    onValueChanged = (field, value) => {
        if (field === 'companyid') {
            this.setState({ companyid: value });

        }
    }
    generateForm = () => {
        if (this.props.mode === 'COMPANY_UPDATE') {
            const userConfig = {
                fields: [
                    // { label: this.props.intl.formatMessage({ id: "COMPANY.USER.COMPANY.UPDATE.MODAL.UPDATING" }), dataField: 'activationStatus', editorType: 'dxSelectBox', editorOptions: { dataSource: [this.props.intl.formatMessage({ id: "COMPANY.USER.ACTIVATE.MODAL.ACTIVATING_TYPE_REINSTATE" }), this.props.intl.formatMessage({ id: "COMPANY.USER.ACTIVATE.MODAL.ACTIVATING_TYPE_NO_REINSTATE" })] } },
                    { resource: 'CONTRACT.FIELD.COMPANY', dataField: 'companyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.dataSource1, valueExpr: 'companyid', displayExpr: 'name' } }
                ]
            };
            return (
                <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                    <BiDynamicForm
                        config={userConfig}
                        valueChangeHandler={this.onValueChanged}
                    />
                    <div>
                        <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                    </div>
                </ValidationGroup>
            )
        }
        else { return null; }
    }
    render() {
        const optionType = 'success';

        let primaryButtonText;
        primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_UPDATE" });


        return (

            <Popup
                visible={this.props.visible}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showTitle={true}
                title={this.props.title}
                width={600}
                height='auto'>
                <div>
                    {
                        this.props.children
                    }
                    {
                        this.generateForm()
                    }
                </div>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CANCEL" }), onClick: this.onClose }}>
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: primaryButtonText, disabled: !this.state.saveButtonEnabled, type: optionType, ...this.props.buttonOptions, onClick: this.saveItem }}>
                </ToolbarItem>
            </Popup>
        );
    }
}


export default injectIntl(ChangeUserCompanyModal);