import React from 'react';
import { connect } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "../../partials/content/Portlet";
import { withSnackbar } from 'notistack';
import TreeView from 'devextreme-react/tree-view';
import DefaultWorkflows from '../../Templates/DefaultWorkflows';
import DefaultResources from '../../Templates/DefaultResources';
import { Button } from 'devextreme-react';
import Common from '../../shared/common';
import * as preferencesDuck from '../../store/ducks/preferences.duck';
import { withRouter } from '../../shared/hoc/withRouter';

// const renderTreeViewItem = (item) => {
//     return `${item.fullName} (${item.position})`;
// }

class UserPage extends React.Component {
    constructor(props) {
        super(props);
        this.treeViewRef = React.createRef();

        let items = DefaultWorkflows.map(WfGroup => {
            const notifyWorkflows = WfGroup.workflows.filter(wf => wf.hasOwnProperty('notify'));
            return {
                id: WfGroup.id,
                title: WfGroup.title,
                expanded: true,
                workflows: notifyWorkflows.map(workflow => {
                    let selected = null;
                    const userPreferences = props.contractNotifications.find(x => x.contractid === props.params.contractid)
                    if (userPreferences) {
                        const userPrefWF = userPreferences.items.find(wf => wf.id === workflow.id);
                        if (userPrefWF) {
                            selected = userPrefWF.selected;
                        }
                    }
                    if (selected === null) {
                        selected = workflow.notify;
                    }
                    return {
                        id: workflow.id,
                        title: workflow.title,
                        selected: selected
                    };
                })
            };
        });
        items = items.filter(WfGroup => WfGroup.workflows.length > 0);
        this.items = Common.ReplaceResources(items, DefaultResources);
        this.state = {
            isChanged: false,
            isSubmitting: false
        };


    }
    selectionChangeHandler = () => {
        if (!this.state.isChanged) {
            this.setState({ isChanged: true });
        }
    }
    submitHandler = () => {
        const storeWF = [];
        this.treeViewRef.current.props.items.forEach(wfg => {
            storeWF.push(...wfg.workflows);
        });
        this.props.setContractNotifications(this.props.params.contractid, storeWF);
        this.setState({ isChanged: false });
    }
    render() {
        // if (this.props.params.userid !== this.props.userProfile.accountid && !this.props.userProfile.isAdmin) {
        //     return (<Navigate replace to={`/contract/${this.props.params.contractid + process.env.REACT_APP_CONTRACT_HOMEPAGE}`} />)
        // }
        return (
            <Portlet>
                <PortletHeader title={"Notification settings for \"" + this.props.contract.name + "\""} />
                <PortletBody style={{ maxWidth: 800 }}>
                    <div>
                        <h4>Workflow Notification Preferences</h4>
                        <TreeView
                            id="treeview"
                            ref={this.treeViewRef}
                            // width={340}
                            height='50vh'
                            items={this.items}
                            selectNodesRecursive={true}
                            selectByClick={false}
                            showCheckBoxesMode="normal"
                            selectionMode="multiple"
                            onSelectionChanged={this.selectionChangeHandler}
                            // onContentReady={this.treeViewContentReady}
                            // itemRender={renderTreeViewItem}
                            itemsExpr="workflows"
                            displayExpr="title"
                        />
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '10px' }}>
                            <Button
                                type="default"
                                stylingMode="contained"
                                text="Update Notification Settings"
                                useSubmitBehavior={false}
                                disabled={!this.state.isChanged}
                                onClick={this.submitHandler}
                                icon={this.state.isSubmitting ? 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light' : null}
                            />
                        </div>
                    </div>
                </PortletBody>
            </Portlet>
        );
    }
}



const mapStateToProps = store => ({
    contractNotifications: store.preferences.contractNotifications,
    contract: store.contract.contract,
    userProfile: store.auth.userProfile,
});


const mapDispatchToProps = {
    setContractNotifications: preferencesDuck.actions.setContractNotifications,
}

export default withRouter(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(UserPage)));
