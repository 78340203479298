import React, { Component } from 'react';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { injectIntl } from "react-intl";
import { withSnackbar } from 'notistack';
import Spinner from '../../../partials/layout/Spinner';

class AttachmentGrid extends Component {
    state = {
        longestDocRefLength: null
    };

    componentDidMount() {
        this.findLongestDocrefLength(this.props.rowItem.data);
    }
    cellRender = (e) => {
        if (e && e.row && e.row.data && e.row.data.directlink) {
            return <a href={e.data.directlink} target='_blank' rel="noreferrer" title={e.value}>{e.value}</a>;
        }
    }
    cellRenderDatetime = (e) => {
        if (e && e.row && e.row.data && e.row.data.dateModified) {
            const datetimeParts = e.row.data.dateModified.split('#');
            const datePart = datetimeParts[0];
            
            return <span title={e.row.data.dateModified}>{datePart}</span>;
        }
    }
    setColumnWidth = (dataField) => {
        var width = 100;

        switch (dataField) {
            case 'docref':
                if (this.state.longestDocRefLength < 22) {
                    width = 230;
                } else {
                    width = 285;
                }
                break;
            case 'name':
                //If no DateModified and Link columns, make name's width wider otherwise keep it narrow
                const wide = !this.datetimeColumnExists();
                if (this.state.longestDocRefLength < 22) {
                    width = wide ? 380 : 280;
                } else {
                    width = wide ? 335 : 225;
                }
                break;
            case 'revision':
                width = 50;
                break;
            case 'status':
                width = 60;
                break;
            case 'dateModified':
                width = 110;
                break;
            default:
                break;
        }

        return width;
    }
    datetimeColumnExists = () => {
        return this.props.rowItem.headerColumns.some(col => col.dataField === 'dateModified');
    }
    findLongestDocrefLength = (data) => {
        let maxLength = 0;
      
        data.forEach((obj) => {
          const docrefLength = obj.docref.length;
          if (docrefLength > maxLength) {
            maxLength = docrefLength;
          }
        });

        this.setState({
            longestDocRefLength: maxLength
        });
    }
      
    render() {
        if (!this.state.longestDocRefLength) {
            return (<Spinner />)
        }

        return (
            <div style={{ width: 'inherit' }}>
                <DataGrid
                    name={`Attachments Grid`}
                    dataSource={this.props.rowItem.data}
                    keyExpr="fileid"
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    remoteOperations={false}
                    columnAutoWidth={false}
                    noDataText={this.props.intl.formatMessage({ id: 'GENERAL.DOCUMENTS.NO_ATTACHMENT' })}
                    rowAlternationEnabled={true}
                    paging={false}
                    sorting={{
                        mode: 'none'
                    }}
                    stateStoring={{
                        enabled: false
                    }}
                >
                    {
                        this.props.rowItem.headerColumns.map(col => (
                            <Column key={col.dataField} dataField={col.dataField} caption={col.caption} width={this.setColumnWidth(col.dataField)} cellRender={col.type === 'DATETIME' ? this.cellRenderDatetime : (col.dataField === 'docref' ? this.cellRender : null)} />
                        ))
                    }
                </DataGrid>
            </div>
        );
    }
}

export default withSnackbar(injectIntl(AttachmentGrid));