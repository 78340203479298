const template = {
    name: "Default JCT MC 2016 v2.5 01/01/2024",
    isSystemDefault: true,
    isGlobal: true,
    companies: [],
    resources: [
      {
        id: 1,
        type: "TEXT",
        key: "SUPPLIER_NAME",
        value: "Management Contractor"
      },
      {
        id: 2,
        type: "TEXT",
        key: "SUPPLIER_ABBREVIATION",
        value: "MC"
      },
      {
        id: 3,
        type: "TEXT",
        key: "PM_NAME",
        value: "Architect/Contract Administrator"
      },
      {
        id: 4,
        type: "TEXT",
        key: "PM_ABBREVIATION",
        value: "A/CA"
      },
      {
        id: 7,
        type: "TEXT",
        key: "CLIENT_NAME",
        value: "Employer"
      },
      {
        id: 8,
        type: "NUMBER",
        key: "SUPPLIER_REPLY_PERIOD",
        value: 7
      },
      {
        id: 9,
        type: "NUMBER",
        key: "PM_REPLY_PERIOD",
        value: 7
      },
      {
        id: 10,
        type: "NUMBER",
        key: "QUOTE_DURATION",
        value: 21
      },
      {
        id: 13,
        type: "NUMBER",
        key: "REQUOTE_DURATION",
        value: 21
      },
      {
        id: 11,
        type: "NUMBER",
        key: "QUOTE_RESPONSE",
        value: 14
      },
      {
        id: 14,
        type: "NUMBER",
        key: "REQUOTE_RESPONSE",
        value: 14
      },
      {
        id: 12,
        type: "NUMBER",
        key: "VARIATION_RESPONSE",
        value: 7
      },
      {
        id: 16,
        type: "NUMBER",
        key: "PROGRAMME_REPLY_PERIOD",
        value: 14
      },
      {
        id: 5,
        type: "TEXT",
        key: "SUPERVISOR_NAME",
        value: "Quantity Surveyor"
      },
      {
        id: 8,
        type: "TEXT",
        key: "CLIENT_ABBREVIATION",
        value: "EMP"
      },
      {
        id: 6,
        type: "TEXT",
        key: "SUPERVISOR_ABBREVIATION",
        value: "QS"
      },
      {
        id: 26,
        type: "NUMBER",
        key: "CLIENT_REPLY_PERIOD",
        value: 7
      },
      {
        id: 27,
        type: "NUMBER",
        key: "SUPERVISOR_REPLY_PERIOD",
        value: 7
      }
    ],
    workflowGroups: [
      {
        id: 105,
        title: "Communications",
        workflows: [
          {
            id: 15,
            title: "$PM_NAME Communications",
            notificationName: "Communication",
            abbreviation: "$PM_ABBREVIATION-Comm-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "PM",
            replyingParty: "SUPPLIER",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Client\"],\"or\",[\"party\",\"=\",\"Supervisor\"]]"
            }
          },
          {
            id: 16,
            title: "$SUPPLIER_NAME Communications",
            notificationName: "Communication",
            abbreviation: "$SUPPLIER_ABBREVIATION-Comm-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "PM_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Client\"],\"or\",[\"party\",\"=\",\"Supervisor\"]]"
            }
          },
          {
            id: 17,
            title: "$SUPERVISOR_NAME Communications",
            notificationName: "Communication",
            abbreviation: "$SUPERVISOR_ABBREVIATION-Comm-",
            notify: true,
            replyEnabled: false,
            assetFieldsEnabled: false,
            replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "SUPERVISOR",
            replyingParty: "SUPPLIER",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Supervisor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Supervisor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Supervisor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Client\"]]"
            }
          },
          {
            id: 57,
            title: "$CLIENT_NAME Communications",
            notificationName: "Communication",
            abbreviation: "$CLIENT_NAME-Comm-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "PM_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "CLIENT",
            replyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Client\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Client\"]",
                communicateFilter: "[[\"party\",\"=\",\"Client\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Client\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "PmClauses",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "1.7.2 Notification of an agreement of the means and format of a communication",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 3,
                isActive: true,
                field: "",
                display: "1.7.2.1 Notification of an address to receive communications",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 4,
                isActive: true,
                field: "",
                display: "2.2.1 Notification of proposed terms and that it is reasonably practicable to commence construction",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 5,
                isActive: true,
                field: "",
                display: "2.5 Notification of deferment of the date of possession",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 6,
                isActive: true,
                field: "",
                display: "2.8.2 Provision of copies of the Contract Documents",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 7,
                isActive: true,
                field: "",
                display: "2.9 Provision of dimensioned drawings to set out the Works",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 8,
                isActive: true,
                field: "",
                display: "2.9 Submission to Employer for consent to instruct the Contractor to not amend an error in setting out",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: true,
                replyingParty: "CLIENT",
                deemed: false
              },
              {
                id: 9,
                isActive: true,
                field: "",
                display: "2.9 Instruction to not correct an error in setting out",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 10,
                isActive: true,
                field: "",
                display: "2.10.1 Provision of further information to explain and amplify the Contract Drawings",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 11,
                isActive: true,
                field: "",
                display: "2.10.1 Instruction necessary to enable the Contractor to carry out the Works",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 12,
                isActive: true,
                field: "",
                display: "2.11.1 Instruction to correct a departure, error or omission",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 13,
                isActive: true,
                field: "",
                display: "2.12.1 Notification of a divergence between the Statutory Requirements and a Contract Document",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 14,
                isActive: true,
                field: "",
                display: "2.12.2 Instruction to correct a divergence between the Statutory Reqiurements and a Contract Document",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 15,
                isActive: true,
                field: "",
                display: "2.18 Notification of a reasonable extension to the time for Completion of the Project or a Section or notification of no extension",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 16,
                isActive: true,
                field: "",
                display: "2.21 Notification of the issue of a Practical or Sectional Completion Certificate",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 17,
                isActive: true,
                field: "",
                display: "2.22 Notification of the issue of a non-completion certificate",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 18,
                isActive: true,
                field: "",
                display: "2.24 Notification of comments in respect of the passing on of the notification of practical completion",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 19,
                isActive: true,
                field: "",
                display: "2.25 Notification identifying the parts of the work taken into possession",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 20,
                isActive: true,
                field: "",
                display: "2.27 Notification of the issue of a certificate of making good defects for a Relevant Part",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 22,
                isActive: true,
                field: "",
                display: "2.30 Submission to Employer for consent to not make good defects",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: false,
                replyingParty: "CLIENT"
              },
              {
                id: 23,
                isActive: true,
                field: "",
                display: "2.30 Instruction not to make good defects",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 24,
                isActive: true,
                field: "",
                display: "2.31 Notification of the issue of a certificate of making good",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 25,
                isActive: true,
                field: "",
                display: "2.32 Notification of comments in respect of the passing on of the notification of making good of defects of the Works",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 26,
                isActive: true,
                field: "",
                display: "3.3.3 Instruction to remove a manager from the Project",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 27,
                isActive: true,
                field: "",
                display: "3.6 Instruction selecting a Works Contractor",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 28,
                isActive: true,
                field: "",
                display: "3.10.1 Notification of a dissent from an instruction otherwise than in writing",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 29,
                isActive: true,
                field: "",
                display: "3.11 Notification of the provision empowering the issue of an instruction",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 30,
                isActive: true,
                field: "",
                display: "3.12 Instruction permitted by the Contract",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 31,
                isActive: true,
                field: "",
                display: "3.13 Instruction of a Variation",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 32,
                isActive: true,
                field: "",
                display: "3.14 Instruction to postpone the works",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 33,
                isActive: true,
                field: "",
                display: "3.15 Instruction to expend a provisional sum",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 34,
                isActive: true,
                field: "",
                display: "3.16 Instruction for an Acceleration Quotation",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 35,
                isActive: true,
                field: "",
                display: "3.17 Instruction to open work up for inspection or carry out any tests",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 36,
                isActive: true,
                field: "",
                display: "3.18 Instruction in connection with work, material or goods not in accordance with the contract",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 37,
                isActive: true,
                field: "",
                display: "3.18.2 Submission to Employer for consent to not remedy defective work",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: true,
                replyingParty: "CLIENT"
              },
              {
                id: 38,
                isActive: true,
                field: "",
                display: "3.18.2 Notification to not remedy defective work",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 39,
                isActive: true,
                field: "",
                display: "3.19 Instruction as a consequence of work not carried out in a proper and workmanlike manner",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 41,
                isActive: true,
                field: "",
                display: "3.20 Instruction to make good any defect, shrinkage or other fault",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 40,
                isActive: true,
                field: "",
                display: "3.21 Notification of dissatisfaction with materials, goods or workmanship",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 42,
                isActive: true,
                field: "",
                display: "3.22.2 Instruction in connection with the discovery of antiquities",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 43,
                isActive: true,
                field: "",
                display: "3.23.4 Notification of a replacement Principal Designer or Principal Contractor",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 44,
                isActive: true,
                field: "",
                display: "4.3.3 Provision of a copy of the statement of the Prime Cost and Management Fee",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 46,
                isActive: true,
                field: "",
                display: "4.21 Notification of the issue of the Final Certificate",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 47,
                isActive: true,
                field: "",
                display: "5.6.2 Instruction to terminate the Works Contract",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 48,
                isActive: true,
                field: "",
                display: "6.5.1 Instruction to take out insurance for the liability of the Employer",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 49,
                isActive: true,
                field: "",
                display: "6.10.3 Instruction on behalf of the Employer not to renew Terrorism Cover",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 50,
                isActive: true,
                field: "",
                display: "6.15.3 Instruction to provide evidence of Professional Indemnity Insurance",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 51,
                isActive: true,
                field: "",
                display: "6.19.1.2 Instruction to carry out Remedial Measures",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 52,
                isActive: true,
                field: "",
                display: "Sch. 6.1 Instruction requesting an Acceleration Quotation",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 53,
                isActive: true,
                field: "",
                display: "Sch. 6.2 Notification of a modification to or a withdrawal of an instruction requesting an Acceleration Quotation",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 54,
                isActive: true,
                field: "",
                display: "Sch. 7.1 Notification of behaviour which is not collaborative",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 55,
                isActive: true,
                field: "",
                display: "Sch. 7.3.3 Instruction in connection with a value engineering submission",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 56,
                isActive: true,
                field: "",
                display: "Sch. 7.6 Notification of a matter which is likely to give rise to a dispute",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 57,
                isActive: true,
                field: "",
                display: "Other Notification",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 58,
                isActive: true,
                field: "",
                display: "Other Instruction",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 59,
                isActive: true,
                field: "",
                display: "Other Submission to Employer for acceptance",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: false,
                deemed: false
              }
            ]
          },
          {
            replacementKey: "SupplierClauses",
            values: [
              {
                id: 37,
                isActive: true,
                field: "",
                display: "1.7.2 Notification of an agreement of the means and format of a communication",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 38,
                isActive: true,
                field: "",
                display: "1.7.2.1 Notification of an address to receive communications",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 39,
                isActive: true,
                field: "",
                display: "2.1.2 Notification of agreement of the matters listed at 2.1.2",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 40,
                isActive: true,
                field: "",
                display: "2.2.1.2 Notification to give the Notice to Proceed",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 41,
                isActive: true,
                field: "",
                display: "2.2.1.2 Notification to terminate the Management Contractor's employment",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 42,
                isActive: true,
                field: "",
                display: "2.2.1.2 Notification to give the Notice to Proceed",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 43,
                isActive: true,
                field: "",
                display: "2.2.1.2 Notification to terminate the Management Contractor's employment",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 44,
                isActive: true,
                field: "",
                display: "2.2.3 Notification requiring the Architect/Contract Administrator to issue the clause 2.2.1 notice",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 45,
                isActive: true,
                field: "",
                display: "2.3.6 Submission to Architect/Contract Administrator for approval of quality of materials, goods or workmanship",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 46,
                isActive: true,
                field: "",
                display: "2.6.2 Notification of the amount of additional insurance",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 47,
                isActive: true,
                field: "",
                display: "2.8.3 Provision of copies of a Works Contract",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 48,
                isActive: true,
                field: "",
                display: "2.10.3 Notification of the time by which further information is required",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 49,
                isActive: true,
                field: "",
                display: "2.11.3 Notification of a departure, error or omission",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 50,
                isActive: true,
                field: "",
                display: "2.12.1 Notification of a divergence between the Statutory Requirements and a Contract Document",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 51,
                isActive: true,
                field: "",
                display: "2.13.2 Notification of emergency compliance work",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 52,
                isActive: true,
                field: "",
                display: "2.14 Submission to Architect/Contract Administrator for consent to remove materials and goods from site",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 53,
                isActive: true,
                field: "",
                display: "2.17.1 Notification that the progress of the Project or a Section is or is likely to be delayed and any relevant information or particulars",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 54,
                isActive: true,
                field: "",
                display: "2.19.1 Notification of a Relevant Project Event which impeded the proper discharge of the Management Contractor's obligations",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 55,
                isActive: true,
                field: "",
                display: "2.19.2 Notification of a Relevant Project Event or a Relevant Works Contract Event which entitles a Works Contrator to an extension of time",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 56,
                isActive: true,
                field: "",
                display: "2.24 Notification passing on the notification of practical completion of Works under a Works Contract",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 57,
                isActive: true,
                field: "",
                display: "2.32 Notification passing on the notificaiton of notification of making good defects of the Works under a Works Contract",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 58,
                isActive: true,
                field: "",
                display: "3.3.1 Submission to Architect/Contract Administrator for consent to change a member of the Key Personnel or a manager on site",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 59,
                isActive: true,
                field: "",
                display: "3.5.1 Notification of reasonable objection to the nomination of a replacement Architect/Contract Administrator or Quantity Surveyor",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 60,
                isActive: true,
                field: "",
                display: "3.8 Submission to Architect/Contract Administrator for consent to subcontract the Pre Construction or Construction Period Services",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 61,
                isActive: true,
                field: "",
                display: "3.9 Notification of a reasonable objection to an instruction from a Works Contractor",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 62,
                isActive: true,
                field: "",
                display: "3.10.1 Notification of confirmation of an instruction otherwise than in writing",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 63,
                isActive: true,
                field: "",
                display: "3.11 Notification of a request to demonstrate that an instruction complies with the Contract",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 64,
                isActive: true,
                field: "",
                display: "3.13 Notification of disagreement with the application of the instruction procedure",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 65,
                isActive: true,
                field: "",
                display: "3.22.3 Inform of the discovery of antiquities",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 66,
                isActive: true,
                field: "",
                display: "4.3.1 Submission of documents to ascertain the Prime Cost",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 67,
                isActive: true,
                field: "",
                display: "4.3.2 Submission of documents relating to the Works Contractor's accounts",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 69,
                isActive: true,
                field: "",
                display: "4.12.2 Notification of the issue of a payment notice",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 70,
                isActive: true,
                field: "",
                display: "4.12.5 Notification of the issue of a Pay Less Notice",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 71,
                isActive: true,
                field: "",
                display: "4.14.1 Notification of an intention to suspend performance of all or part of the works",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 72,
                isActive: true,
                field: "",
                display: "5.3.1 Inform of a claim from the Works Contractor",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 73,
                isActive: true,
                field: "",
                display: "5.5 Submission of details of a claim from the Works Contractor",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 74,
                isActive: true,
                field: "",
                display: "5.6.1 Notification of a default of a Works Contractor",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 75,
                isActive: true,
                field: "",
                display: "6.11.1 Notification that Terrorism Cover will cease",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 76,
                isActive: true,
                field: "",
                display: "6.12.1 Notification of a request to  provide evidence of insurance",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 77,
                isActive: true,
                field: "",
                display: "6.12.1 Submission of evidence of insurance",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 78,
                isActive: true,
                field: "",
                display: "6.13.1 Notification of loss or damage caused by an insured risk",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 79,
                isActive: true,
                field: "",
                display: "6.16 Notification that insurance has ceased to be available at commercially available rates",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 80,
                isActive: true,
                field: "",
                display: "6.19.1 Notification of a breach of the Joint Fire Code",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 81,
                isActive: true,
                field: "",
                display: "6.19.1.2 Notification of emergency compliance work",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 82,
                isActive: true,
                field: "",
                display: "7.1 Submission to Employer for consent to assign the Contract or any rights thereunder",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: true,
                replyingParty: "CLIENT",
                deemed: false
              },
              {
                id: 83,
                isActive: true,
                field: "",
                display: "7.3 Provision of a performance bond or guarantee",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 84,
                isActive: true,
                field: "",
                display: "Sch. 6.1 Notification of a reasonable objection to an instruction requesting an Acceleration Quotation",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 86,
                isActive: true,
                field: "",
                display: "Sch. 7.1 Notification of behaviour which is not collaborative",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 87,
                isActive: true,
                field: "",
                display: "Sch. 7.3.2 Submission of proposed changes to designs and specifications for the purpose of value engineering",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 88,
                isActive: true,
                field: "",
                display: "Sch. 7.4.2 Submission of proposed changes to improve environmental performance",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 89,
                isActive: true,
                field: "",
                display: "Sch. 7.5.2 Submission of information necessary to monitor and assess the Contractor's performance",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 90,
                isActive: true,
                field: "",
                display: "Sch. 7.5.3 Submission of proposals to improve performance",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 91,
                isActive: true,
                field: "",
                display: "Sch. 7.6 Notification of a matter which is likely to give rise to a dispute",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 36,
                isActive: true,
                field: "",
                display: "Other Notification",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              }
            ]
          },
          {
            replacementKey: "SupervisorClauses",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "1.7.2 Notification of an agreement of the means and format of a communication",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 3,
                isActive: true,
                field: "",
                display: "1.7.2.1 Notification of an address to receive communications",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 4,
                isActive: true,
                field: "",
                display: "4.3.3 Submission of a statement of the Prime Cost and Management Fee",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 5,
                isActive: true,
                field: "",
                display: "Other notification",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              }
            ]
          },
          {
            replacementKey: "ClientClauses",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "1.7.2 Notification of an agreement of the means and format of a communication",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 3,
                isActive: true,
                field: "",
                display: "1.7.2.1 Notification of an address to receive communications",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 4,
                isActive: true,
                field: "",
                display: "2.2.1.1 Notification of an agreement to extend the time to respond",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 5,
                isActive: true,
                field: "",
                display: "2.2.1.1 Notification to proceed",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 6,
                isActive: true,
                field: "",
                display: "2.6.1 Submission to Management Contractor for consent to use or occupy the site or the Works",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: true,
                replyingParty: "SUPPLIER",
                deemed: false
              },
              {
                id: 7,
                isActive: true,
                field: "",
                display: "2.7.2 Submission to Management Contractor for consent for work to be carried out by the Employer",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: true,
                replyingParty: "SUPPLIER",
                deemed: false
              },
              {
                id: 8,
                isActive: true,
                field: "",
                display: "2.23.1 Notification that the Employer may require payment of liquidated damages",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 9,
                isActive: true,
                field: "",
                display: "2.23.1 Notification of the amount of liquidated damages payable",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 10,
                isActive: true,
                field: "",
                display: "2.25 Submission to Management Contractor for consent to take possession of part of the works",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: true,
                replyingParty: "SUPPLIER",
                deemed: false
              },
              {
                id: 11,
                isActive: true,
                field: "",
                display: "3.5.1 Notification of the nomination of a replacement Architect/Contract Administrator or Quantity Surveyor",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 12,
                isActive: true,
                field: "",
                display: "4.12.5 Notification of the issue of a Pay Less Notice",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 13,
                isActive: true,
                field: "",
                display: "5.6.2 Instruction to terminate the Works Contract",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 14,
                isActive: true,
                field: "",
                display: "6.11.1 Notification that Terrorism Cover will cease",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 15,
                isActive: true,
                field: "",
                display: "6.11.2 Notification on connection with cessation of Terrorism Cover",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 16,
                isActive: true,
                field: "",
                display: "6.11.3 Instruction to effect and maintain alternative Terrorism Cover",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 17,
                isActive: true,
                field: "",
                display: "6.12.1 Submission of evidence of insurance",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 18,
                isActive: true,
                field: "",
                display: "6.12.1 Notification of a request to  provide evidence of insurance",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 19,
                isActive: true,
                field: "",
                display: "6.19.1 Notification of a breach of the Joint Fire Code",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 20,
                isActive: true,
                field: "",
                display: "7.1 Submission to Management Contractor for consent to assign the Contract or any rights thereunder",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: true,
                replyingParty: "SUPPLIER",
                deemed: false
              },
              {
                id: 21,
                isActive: true,
                field: "",
                display: "Sch. 7.1 Notification of behaviour which is not collaborative",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 22,
                isActive: true,
                field: "",
                display: "Sch. 7.5.3 Notification that performance targets may not be met",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 23,
                isActive: true,
                field: "",
                display: "Sch. 7.6 Notification of a matter which is likely to give rise to a dispute",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 24,
                isActive: true,
                field: "",
                display: "Other notification",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              }
            ]
          }
        ]
      },
      {
        id: 104,
        title: "Programme",
        workflows: [
          {
            id: 14,
            title: "$SUPPLIER_NAME Programme",
            notificationName: "Programme",
            abbreviation: "$SUPPLIER_ABBREVIATION-Programme-",
            notify: true,
            replyEnabled: false,
            assetFieldsEnabled: false,
            replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "ReasonLabel",
                display: "Reason for any change to date of practical completion"
              },
              {
                id: 2,
                isActive: true,
                field: "ChangeLabel",
                display: "Change in any other important project milestone in period and reasons why"
              },
              {
                id: 3,
                isActive: true,
                field: "MainProgressLabel",
                display: "Main progress achieved in period"
              },
              {
                id: 4,
                isActive: true,
                field: "KeyActivitiesLabel",
                display: "Key activities forecast in next four weeks"
              },
              {
                id: 5,
                isActive: true,
                field: "SummaryLabel",
                display: "Summary of critical path"
              },
              {
                id: 6,
                isActive: true,
                field: "ChangesLabel",
                display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc."
              },
              {
                id: 7,
                isActive: true,
                field: "CompensationLabel",
                display: "Variations added to programme this period"
              },
              {
                id: 8,
                isActive: true,
                field: "NotificationClause",
                display: "Please find attached programme for acceptance"
              },
              {
                id: 9,
                isActive: true,
                field: "ReplyRejectReasonLabel",
                display: "The $SUPPLIER_NAME’s programme is not accepted for the following reasons"
              },
              {
                id: 10,
                isActive: true,
                field: "ReplyNotificationClause",
                display: "In accordance with NEC4 PSC clause 31.3 you are notified that the $SUPPLIER_NAME’s Programme"
              },
              {
                id: 11,
                isActive: true,
                field: "ReplyNotificationClause",
                display: "You are notified that the $SUPPLIER_NAME’s Programme"
              },
              {
                id: 12,
                isActive: true,
                field: "DashboardPlannedCompletionLabel",
                display: "Date of practical completion"
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Supplier",
                display: "$SUPPLIER_NAME Programme Submission awaiting Acceptance"
              }
            ]
          },
          {
            replacementKey: "ReplyRejectOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "plansnotpractical",
                display: "The $SUPPLIER_NAME’s plans which it shows are not practicable"
              },
              {
                id: 2,
                isActive: true,
                field: "missingcontractrequires",
                display: "It does not show the information which this contract requires"
              },
              {
                id: 3,
                isActive: true,
                field: "notrepresentplan",
                display: "It does not represent the $SUPPLIER_NAME’s plans realistically"
              },
              {
                id: 4,
                isActive: true,
                field: "notcomply",
                display: "It does not comply with the Works Information"
              }
            ]
          },
          {
            replacementKey: "Fields",
            values: [
              {
                id: 1,
                isActive: true,
                field: "PreviousPlanned",
                display: "Previous date of practical completion"
              },
              {
                id: 2,
                isActive: true,
                field: "PlannedCompletion",
                display: "Date of practical completion"
              },
              {
                id: 3,
                isActive: true,
                field: "Reason",
                display: "Reason for any change to date of practical completion"
              },
              {
                id: 4,
                isActive: false,
                field: "Change",
                display: "Change in any other Key Date, Sectional Completion or other important project milestone in period and reasons why"
              },
              {
                id: 5,
                isActive: false,
                field: "MainProgress",
                display: "Main progress achieved in period"
              },
              {
                id: 6,
                isActive: false,
                field: "KeyActivities",
                display: "Key activities forecast in next four weeks"
              },
              {
                id: 7,
                isActive: false,
                field: "Summary",
                display: "Summary of critical path"
              },
              {
                id: 8,
                isActive: false,
                field: "Changes",
                display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc."
              },
              {
                id: 9,
                isActive: false,
                field: "Compensation",
                display: "Compensation events added to programme this period"
              }
            ]
          }
        ]
      },
      {
        id: 103,
        title: "Works Contracts and Extensions of Time",
        workflows: [
          {
            id: 51,
            title: "Works Contracts",
            notificationName: "Works Contract",
            abbreviation: "WC-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 50,
            title: "Extension of Time",
            notificationName: "Extension of Time",
            abbreviation: "EOT-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "PmAssumptionLabel",
                display: "The following assumptions shall be considered"
              },
              {
                id: 2,
                isActive: true,
                field: "PmAssumptionNote",
                display: "You are hereby instructed to provide a quote"
              },
              {
                id: 3,
                isActive: true,
                field: "SupplierDescNote",
                display: "In accordance with the contract I notify you that the event described below has happened which: We believe is a variation, has happened less than 5 weeks since we became aware of the event, you have not notified to us."
              },
              {
                id: 4,
                isActive: true,
                field: "ReplyAcceptedCommentLabel",
                display: "In accordance with the contract you are notified that the issue flagged is a Variation"
              },
              {
                id: 5,
                isActive: true,
                field: "ReplyRejectedCommentLabel",
                display: "In accordance with the contract you are notified that the $SUPPLIER_NAME Notification is not a Variation"
              },
              {
                id: 6,
                isActive: true,
                field: "ReplyAcceptedAssumptionLabel",
                display: "In accordance with the contract the following assumptions shall be considered"
              },
              {
                id: 7,
                isActive: true,
                field: "QuoteNote",
                display: "In accordance with the contract please find attached our quotation for $reference"
              },
              {
                id: 8,
                isActive: true,
                field: "ExtensionPMLabel",
                display: "We require an extension to the contractual time to produce a quotation response as detailed below for the following reasons"
              },
              {
                id: 9,
                isActive: true,
                field: "ExtensionSupplierLabel",
                display: "We require an extension to the contractual time to produce a quotation as detailed below for the following reasons"
              },
              {
                id: 10,
                isActive: true,
                field: "AssessmentDetailLabel",
                display: "Details"
              },
              {
                id: 11,
                isActive: true,
                field: "ProposedInstructionNote",
                display: "In accordance with the contract you are instructed to provide a quote"
              },
              {
                id: 12,
                isActive: true,
                field: "ProposedInstructionLabel",
                display: "In accordance with the contract he following assumptions shall be considered"
              },
              {
                id: 13,
                isActive: true,
                field: "ReplyRejectedOptionsLabel",
                display: "I have decided that the Notification"
              },
              {
                id: 14,
                isActive: true,
                field: "QuoteCostEffectLabel",
                display: "Cost Increase/Decrease"
              },
              {
                id: 15,
                isActive: true,
                field: "QuoteDateEffectLabel",
                display: "Extension of time required"
              },
              {
                id: 16,
                isActive: true,
                field: "QuoteDaysEffectLabel",
                display: "Extension of time required"
              },
              {
                id: 17,
                isActive: true,
                field: "AssessmentCostEffectLabel",
                display: "Cost Increase/Decrease"
              },
              {
                id: 18,
                isActive: true,
                field: "AssessmentDaysEffectLabel",
                display: "Number of days effect to the completion date"
              },
              {
                id: 19,
                isActive: true,
                field: "CompensationTypeLabel",
                display: "Type"
              },
              {
                id: 20,
                isActive: true,
                field: "QuoteProgrammeAttachLabel",
                display: "Are you attaching a programme showing the effect against the last Accepted Programme with this quotation?"
              },
              {
                id: 21,
                isActive: true,
                field: "AssessmentCompensationLabel",
                display: "Details"
              },
              {
                id: 22,
                isActive: true,
                field: "AssessmentWarningBanner",
                display: ""
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: false,
                field: "PmCompensationAQ",
                display: "Variations notified by $PM_ABBREVIATION awaiting quotes"
              },
              {
                id: 2,
                isActive: false,
                field: "PmCompensationARQ",
                display: "Variations notified by $PM_ABBREVIATION awaiting revised quotes"
              },
              {
                id: 3,
                isActive: false,
                field: "PmCompensationAPMRQ",
                display: "Variations notified by $PM_ABBREVIATION awaiting response to quotes"
              },
              {
                id: 4,
                isActive: true,
                field: "PmCompensationAPMA",
                display: "Variations notified by $PM_ABBREVIATION awaiting $PM_ABBREVIATION Assessment"
              },
              {
                id: 5,
                isActive: true,
                field: "SupCompensationAQ",
                display: "Variations notified by $SUPPLIER_NAME awaiting quotes"
              },
              {
                id: 6,
                isActive: true,
                field: "SupCompensationARQ",
                display: "Variations notified by $SUPPLIER_NAME awaiting revised quotes"
              },
              {
                id: 7,
                isActive: true,
                field: "SupCompensationAPMR",
                display: "Variations notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION response"
              },
              {
                id: 8,
                isActive: true,
                field: "SupCompensationAPMRQ",
                display: "Variations notified by $SUPPLIER_NAME awaiting response to quotes"
              },
              {
                id: 9,
                isActive: true,
                field: "SupCompensationAPMA",
                display: "Variations notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment"
              },
              {
                id: 10,
                isActive: true,
                field: "PmProposedAQ",
                display: "Proposed Variations awaiting quotes"
              },
              {
                id: 11,
                isActive: true,
                field: "PmProposedARQ",
                display: "Proposed Variations awaiting revised quotes"
              },
              {
                id: 12,
                isActive: true,
                field: "PmProposedAPMRQ",
                display: "Proposed Variations awaiting response to quotes"
              },
              {
                id: 13,
                isActive: true,
                field: "PmProposedAPMA",
                display: "Proposed Variations awaiting $PM_ABBREVIATION Assessment"
              },
              {
                id: 14,
                isActive: true,
                field: "PmExtension",
                display: "$PM_ABBREVIATION Quote Response Extensions awaiting Acceptance"
              },
              {
                id: 15,
                isActive: true,
                field: "SupExtension",
                display: "$SUPPLIER_NAME Quote Extensions awaiting Acceptance"
              },
              {
                id: 15,
                isActive: true,
                field: "SupAssumption",
                display: "$SUPPLIER_NAME Assumptions awaiting Acceptance"
              },
              {
                id: 16,
                isActive: true,
                field: "SupClaimAQ",
                display: "Claims notified by $SUPPLIER_NAME awaiting quotes"
              },
              {
                id: 17,
                isActive: true,
                field: "SupClaimARQ",
                display: "Claims notified by $SUPPLIER_NAME awaiting revised quotes"
              },
              {
                id: 19,
                isActive: true,
                field: "SupClaimAPMRQ",
                display: "Claims notified by $SUPPLIER_NAME awaiting response to quotes"
              },
              {
                id: 20,
                isActive: true,
                field: "SupClaimAPMA",
                display: "Claims notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment"
              }
            ]
          },
          {
            replacementKey: "ReplyRejectOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "faultpscp",
                display: "Arises from a fault of the $SUPPLIER_NAME"
              },
              {
                id: 2,
                isActive: true,
                field: "notexpectnothappen",
                display: "Has not happened and is not expected to happen"
              },
              {
                id: 3,
                isActive: false,
                field: "noteffectcostcompletion",
                display: "Has no effect on Defined Cost, Completion or meeting a Key Date"
              },
              {
                id: 4,
                isActive: true,
                field: "statedcontract",
                display: "Is not one of the variations stated in this contract"
              },
              {
                id: 5,
                isActive: false,
                field: "notnotifiedwithintimescales",
                display: "Has not been notified within the timescales set out in the contract"
              }
            ]
          },
          {
            replacementKey: "CompensationStatuses",
            values: [
              {
                id: 1,
                isActive: true,
                field: "APMA",
                display: "Quote Not Accepted - $PM_ABBREVIATION to make own Assessment"
              },
              {
                id: 2,
                isActive: true,
                field: "ARQ",
                display: "Quote Not Accepted - $SUPPLIER_NAME to resubmit"
              },
              {
                id: 3,
                isActive: true,
                field: "AM",
                display: "$PM_ABBREVIATION Assessment Made"
              },
              {
                id: 4,
                isActive: true,
                field: "QA",
                display: "Quote Accepted"
              },
              {
                id: 5,
                isActive: true,
                field: "APMRQ",
                display: "Awaiting $PM_ABBREVIATION Response to quote"
              },
              {
                id: 6,
                isActive: true,
                field: "APMR",
                display: "Awaiting $PM_ABBREVIATION Response to Variation Notice"
              },
              {
                id: 7,
                isActive: true,
                field: "AQ",
                display: "Awaiting Quote"
              },
              {
                id: 8,
                isActive: true,
                field: "NC",
                display: "Not Communicated"
              },
              {
                id: 9,
                isActive: true,
                field: "R",
                display: "Not a Variation"
              },
              {
                id: 10,
                isActive: true,
                field: "DNG",
                display: "Decision Not Given"
              }
            ]
          },
          {
            replacementKey: "QuoteStatuses",
            values: [
              {
                id: 1,
                isActive: true,
                field: "0",
                display: "Not Accepted - $SUPPLIER_NAME to resubmit"
              },
              {
                id: 2,
                isActive: true,
                field: "1",
                display: "Accepted"
              },
              {
                id: 3,
                isActive: true,
                field: "2",
                display: "Awaiting Response"
              },
              {
                id: 4,
                isActive: true,
                field: "3",
                display: "Not Accepted - $PM_ABBREVIATION to make assessment"
              },
              {
                id: 5,
                isActive: true,
                field: "4",
                display: "Not Accepted - Other quote approved"
              },
              {
                id: 6,
                isActive: true,
                field: "5",
                display: "Not Accepted - Decision will not be given"
              },
              {
                id: 7,
                isActive: true,
                field: "none",
                display: "Not Communicated"
              }
            ]
          },
          {
            replacementKey: "InstructionReplyProceedOpts",
            values: [
              {
                id: 1,
                isActive: true,
                field: "61.1",
                display: "$SUPPLIER_NAME is instructed to proceed with works"
              },
              {
                id: 2,
                isActive: true,
                field: "61.2",
                display: "$SUPPLIER_NAME is instructed to NOT proceed with works until instructed"
              }
            ]
          },
          {
            replacementKey: "EWRelationEnabled",
            values: [
              {
                id: 1,
                isActive: false,
                field: "EWRelationEnabled",
                display: "Active-Inactive state indicates weather the EWN can be attached in CEN communications"
              }
            ]
          },
          {
            replacementKey: "Fields",
            values: [
              {
                id: 1,
                isActive: false,
                field: "Assumption",
                display: "The quotation is to be based on the following assumptions"
              }
            ]
          },
          {
            replacementKey: "QuoteProgrammeImpactOpts",
            values: [
              {
                id: 1,
                isActive: true,
                field: "noeffect",
                display: "There is no effect on the Accepted Programme"
              },
              {
                id: 2,
                isActive: true,
                field: "mitigated",
                display: "There is an effect on the Accepted Programme but this has been mitigated by measures as detailed"
              },
              {
                id: 3,
                isActive: true,
                field: "notmitigated",
                display: "There is an effect on the Accepted Programme which we are unable to mitigate"
              }
            ]
          },
          {
            replacementKey: "VariationClauses",
            values: [
              {
                id: 26,
                isActive: true,
                field: "",
                display: "Works Contract"
              }
            ]
          }
        ]
      },
      {
        id: 107,
        title: "Quotations",
        workflows: [
          {
            id: 19,
            title: "$SUPPLIER_NAME Quotations",
            notificationName: "Quotation for Acceleration",
            abbreviation: "CON-JCT-Quotations-",
            notify: true,
            replyEnabled: true,
            financialLimitEnabled: false,
            assetFieldsEnabled: false,
            replyPeriodOption: "PM_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "CostEffectLabel",
                display: "Claimed Cost Increase/Decrease"
              },
              {
                id: 2,
                isActive: true,
                field: "DaysEffectLabel",
                display: "Extension of time required"
              }
            ]
          },
          {
            replacementKey: "ProposalClauses",
            values: [
              {
                id: 3,
                isActive: true,
                field: "",
                display: "Sch. 6.3 Acceleration Quotation"
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Supplier",
                display: "Quotations awaiting Acceptance"
              }
            ]
          }
        ]
      },
      {
        id: 108,
        title: "Payments",
        workflows: [
          {
            id: 20,
            title: "Payment Certificates",
            notificationName: "Payment Certificate",
            abbreviation: "$PM_ABBREVIATION-Payment-",
            notify: true,
            financialLimitEnabled: false,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 21,
            title: "Payment Applications",
            notificationName: "Payment Application",
            abbreviation: "$SUPPLIER_ABBREVIATION-Payment-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "SUPPLIER",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "CumulativePriceLabel",
                display: "Value of Work Done"
              },
              {
                id: 2,
                isActive: true,
                field: "PlusAmountsSupplierLabel",
                display: "Plus other amounts to be paid to the $SUPPLIER_NAME"
              },
              {
                id: 3,
                isActive: true,
                field: "LessAmountsSupplierLabel",
                display: "Less amounts to be paid by or retained from the $SUPPLIER_NAME"
              },
              {
                id: 4,
                isActive: true,
                field: "TaxSupplierLabel",
                display: "Taxes"
              },
              {
                id: 5,
                isActive: true,
                field: "WarningBanner",
                display: ""
              },
              {
                id: 6,
                isActive: true,
                field: "SummaryDetailsLabel",
                display: "Summary details"
              }
            ]
          },
          {
            replacementKey: "TaxEnabled",
            values: [
              {
                id: 1,
                isActive: false,
                field: "TaxEnabled",
                display: "Active-Inactive state indicates tax field will be included in notification forms"
              }
            ]
          }
        ]
      },
      {
        id: 109,
        title: "Defects",
        workflows: [
          {
            id: 23,
            title: "Defect Notifications",
            notificationName: "Defect Notification",
            abbreviation: "$SUPERVISOR_NAME-Defect-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "SUPERVISOR",
            actioningParty: "SUPERVISOR",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              actionTab: {
                actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "InstructionClauseLabel",
                display: "You are instructed to search for a Defect as follows"
              },
              {
                id: 2,
                isActive: true,
                field: "SupervisorClauseLabel",
                display: "You are notified of the following Defect"
              },
              {
                id: 3,
                isActive: true,
                field: "SupplierClauseLabel",
                display: "You are notified of the following Defect"
              },
              {
                id: 4,
                isActive: true,
                field: "TestsClauseLabel",
                display: "You are notified of the following test or inspection"
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Instruction",
                display: "$SUPERVISOR_NAME Instruction to Search awaiting sign off"
              },
              {
                id: 2,
                isActive: true,
                field: "SuperDefect",
                display: "$SUPERVISOR_NAME Notification of Defect awaiting acceptance"
              },
              {
                id: 3,
                isActive: true,
                field: "Inspection",
                display: "$SUPPLIER_NAME Test/Inspection Notification awaiting sign off"
              }
            ]
          }
        ]
      },
      {
        id: 111,
        title: "Records",
        workflows: [
          {
            id: 30,
            title: "A/CA Meetings",
            notificationName: "Meeting",
            abbreviation: "$PM_ABBREVIATION-Meeting-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 31,
            title: "$SUPPLIER_NAME Meetings",
            notificationName: "Meeting",
            abbreviation: "$SUPPLIER_ABBREVIATION-Meeting-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "SUPPLIER",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
            }
          },
          {
            id: 32,
            title: "$SUPPLIER_NAME Site Diary",
            notificationName: "Site Diary",
            abbreviation: "Diary-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "SUPPLIER",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
            }
          },
          {
            id: 33,
            title: "Weather",
            notificationName: "Weather",
            abbreviation: "Weather-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "SUPPLIER",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
            }
          }
        ],
        placeholders: []
      },
      {
        id: 112,
        title: "RFI / TQ",
        workflows: [
          {
            id: 34,
            title: "$SUPPLIER_NAME RFI / TQ",
            notificationName: "RFI / TQ",
            abbreviation: "RFI/TQ-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Supplier",
                display: "$SUPPLIER_NAME RFI/TQ awaiting Reply"
              }
            ]
          }
        ]
      },
      {
        id: 113,
        title: "Contract Information",
        workflows: [
          {
            id: 39,
            title: "Documents",
            notificationName: "Document",
            abbreviation: "Documents-",
            notify: true,
            actioningParty: "PM",
            userFilters: {
              accessFilter: "null",
              actionTab: {
                actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              }
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: []
          }
        ]
      }
    ]
  }
  export default template;