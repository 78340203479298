import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CompaniesRegister from "./CompaniesRegister";
import CompanyHome from './Company/CompanyHome';
import CompanyNew from './CompanyNew';
import Protector from "../../../router/Protector/Protector";

class CompaniesRouter extends React.Component {
    render() {
        return (
            <Routes caseSensitive>
                <Route path="/" exact element={<Protector><CompaniesRegister /></Protector>} sensitive />
                <Route path="/new-company" exact element={<Protector><CompanyNew /></Protector>} sensitive />
                <Route path="/:companyid/*" element={<Protector><CompanyHome /></Protector>} sensitive />
                <Route path="*" element={<Navigate replace to="/contracts" />} />
            </Routes>
        );
    }

}



export default CompaniesRouter;