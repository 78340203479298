import React from 'react';
import List, { ItemDragging } from 'devextreme-react/list';



class dxOrderList extends React.Component {
    constructor(props) {
        super(props);
        let initialItems = null;
        if (props.items) {
            initialItems = JSON.parse(JSON.stringify(props.items))
        }
        this.state = {
            items: initialItems,
            selectedItem: null,
        };
    }
    clearSelection() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        this.setState({ selectedItem: null });
    }
    componentDidUpdate(prevProps, prevState) {

        if (prevProps.items !== this.props.items) {
            if (this.props.items) {
                this.setState({ items: JSON.parse(JSON.stringify(this.props.items)), selectedItem: null });
            }
            else {
                this.setState({ items: null, selectedItem: null });
            }

        }
    }
    onDragStart = (e) => {
        e.itemData = this.state[e.fromData][e.fromIndex];
    }
    onAdd = (e) => {
        const tasks = this.state[e.toData];
        this.setState({
            [e.toData]: [...tasks.slice(0, e.toIndex), e.itemData, ...tasks.slice(e.toIndex)]
        });
    }
    onRemove = (e) => {
        const tasks = this.state[e.fromData];
        this.setState({
            [e.fromData]: [...tasks.slice(0, e.fromIndex), ...tasks.slice(e.fromIndex + 1)]
        });
    }

    onReorder = (e) => {
        const items = this.state.items;
        const removedList = [...items.slice(0, e.fromIndex), ...items.slice(e.fromIndex + 1)];
        const addedList = [...removedList.slice(0, e.toIndex), e.itemData, ...removedList.slice(e.toIndex)];
        this.setState({ selectedItem: null, items: addedList }, this.updateParent);
    }

    itemRemoved = (e) => {
        //DO NOT UPDATE PARENT IF NO ITEMS
        //PREVENT DISMOUNT REMOVE CALLS
        if (e.itemData && this.props.items && this.props.items.length > 0) {
            this.updateParent();
        }
    }
    updateParent = () => {

        if (this.props.items) {
            const newList = this.state.items.map(itm => ({ ...itm }));
            this.props.listChanged(newList);
        }

    }
    onItemSelected = async (e) => {
        if (e.itemData) {
            if (this.props.selectionChangeValidation) {
                const valResult = await this.props.selectionChangeValidation();
                if (!valResult) {
                    return;
                }
            }
            const selectedItemCopy = JSON.parse(JSON.stringify(e.itemData));
            this.setState({ selectedItem: e.itemData });
            this.props.onItemClick(selectedItemCopy);
        }
        else {
            this.setState({ selectedItem: null });
        }
    }
    render() {
        return (
            <>
                <List
                    ref={(ref) => this.listComp = ref}
                    style={{ border: '1px solid #ccc', borderRadius: '4px' }}
                    height='100%'
                    allowItemDeleting={!this.props.readOnly}
                    items={this.state.items}
                    keyExpr={this.props.keyExpr}
                    displayExpr={this.props.displayExpr}
                    selectionMode="single"
                    selectedItem={this.state.selectedItem}
                    onItemDeleted={this.itemRemoved}
                    onItemClick={this.onItemSelected}
                    repaintChangesOnly={true}
                    itemRender={this.props.itemRender}
                    useNativeScrolling={true}
                    showScrollbar='always'
                // cacheEnabled={false}
                >
                    {
                        !this.props.readOnly && (
                            <ItemDragging
                                allowReordering={true}
                                group="items"
                                data="items"
                                onDragStart={this.onDragStart}
                                onAdd={this.onAdd}
                                onRemove={this.onRemove}
                                onReorder={this.onReorder}>
                            </ItemDragging>
                        )
                    }

                </List>
            </>
        );
    }
}


export default dxOrderList