import { userFilterCategories } from "../../../shared/lookup";
const workflows = [
    {
        id: 1,
        title: '$PM_NAME Early Warnings',
        notificationName: 'Early Warning',
        abbreviation: '$PM_ABBREVIATION-Warning-',
        notifyingParty: 'PM',
        replyingParty: 'SUPPLIER',
        actioningParty: 'PM',
        resolveActioningParty: 'PM',

        replyEnabled: true,
        replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: "PARTY",
        form_CompanyLogo: "PARTY",

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Early Warnings',
            currentModel: 'EarlyWarning',
            currentModelType: 'PM',
            referenceType: 'EarlyWarning',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,

            replyPeriodOptions: ["SUPPLIER_REPLY_PERIOD", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.ACTION_TAB,
                userFilterCategories.NOTIFY,
                userFilterCategories.RESOLVE_ACTION_TAB
            ],
            paths: {
                base: 'pm',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 2,
        title: '$SUPPLIER_NAME Early Warnings',
        notificationName: 'Early Warning',
        abbreviation: '$SUPPLIER_ABBREVIATION-Warning-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'PM',
        actioningParty: 'PM',
        resolveActioningParty: 'PM',

        replyEnabled: true,
        replyPeriodOption: "PM_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyLogo: "PARTY",
        form_CompanyName: "PARTY",


        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Early Warnings',
            currentModel: 'EarlyWarning',
            currentModelType: 'Contractor',
            referenceType: 'EarlyWarning',


            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,

            replyPeriodOptions: ["PM_REPLY_PERIOD", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.ACTION_TAB,
                userFilterCategories.NOTIFY,
                userFilterCategories.RESOLVE_ACTION_TAB
            ],
            paths: {
                base: 'supplier',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 74,
        title: '$CLIENT_NAME Early Warnings',
        notificationName: 'Early Warning',
        abbreviation: '$CLIENT_ABBREVIATION-Warning-',
        notifyingParty: 'CLIENT',
        replyingParty: 'PM',
        actioningParty: 'PM',
        resolveActioningParty: 'PM',

        replyEnabled: true,
        replyPeriodOption: "PM_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyLogo: "PARTY",
        form_CompanyName: "PARTY",


        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Client Early Warnings',
            currentModel: 'EarlyWarning',
            currentModelType: 'CLIENT',
            referenceType: 'EarlyWarning',


            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,

            replyPeriodOptions: ["PM_REPLY_PERIOD", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.ACTION_TAB,
                userFilterCategories.NOTIFY,
                userFilterCategories.RESOLVE_ACTION_TAB
            ],
            paths: {
                base: 'client',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 3,
        title: 'Early Warning Register',
        config: {
            description: 'Risk Register',
            showInRelations: false,
            showInCustomFields: false,
            replyPossible: false,
            userFilterCategories: [
                userFilterCategories.ACCESS
            ],
            paths: {
                base: 'register',
                list: 'list',
                default: 'list'
            }
        }
    },

]

export default workflows;