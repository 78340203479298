import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { resetPassword } from "../../crud/security.crud";
import QueryString from 'query-string';
import { withSnackbar } from 'notistack';
import { withRouter } from '../../shared/hoc/withRouter';
import { toAbsoluteUrl } from "../../../_metronic";
class ResetPassword extends Component {
  state = { isRequested: false };
  componentDidMount() {
    const queryObj = QueryString.parse(this.props.location.search);
    if (!queryObj || !queryObj.Token) {
      this.props.navigate('/auth/login');
    }
    else {
      this.token = queryObj.Token;
    }
  }

  render() {
    const { intl } = this.props;
    const { isRequested } = this.state;
    if (isRequested) {
      return <Navigate replace to="/auth" />;
    }

    return (

      <>
        <div className="kt-grid kt-grid--ver kt-grid--root">
          <div
            id="kt_login"
            className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
          >
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
              <div
                className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl("/media/bg/bi-1.png")})`,
                  minHeight: 200
                }}
              >
              </div>
              <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">



                <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
                  <div className="kt-login__body">
                    <div className="kt-login__form">
                      <div className="kt-login__title">
                        <h3>
                          <FormattedMessage id="AUTH.RESET.TITLE" />
                        </h3>
                      </div>

                      <Formik
                        initialValues={{ email: '', password1: '', password2: '' }}
                        validate={values => {
                          const errors = {};
                          if (!values.email) {

                            errors.email = intl.formatMessage({
                              id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                          } else if (
                            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(values.email)
                          ) {
                            errors.email = intl.formatMessage({
                              id: "AUTH.VALIDATION.INVALID_FIELD"
                            });
                          }

                          if (!values.password1) {
                            errors.password1 = intl.formatMessage({
                              id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                          }
                          else if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/i.test(values.password1)) {
                            errors.password1 = intl.formatMessage({
                              id: "AUTH.VALIDATION.PASSWORD_NOT_COMPLEX"
                            });
                          }

                          if (!values.password2) {
                            errors.password2 = intl.formatMessage({
                              id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                          }
                          else if (values.password1 !== values.password2) {
                            errors.password2 = intl.formatMessage({
                              id: "AUTH.VALIDATION.PASSWORD_NOT_MATCH"
                            });
                          }

                          return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                          resetPassword(values.email, this.token, values.password1)
                            .then((resp) => {
                              this.props.enqueueSnackbar(intl.formatMessage({ id: "AUTH.RESET.SUCCESS" }), { variant: 'success', });
                              this.props.navigate('/auth/login');
                              this.setState({ isRequested: true });
                            })
                            .catch((err) => {
                              setSubmitting(false);
                              let errorMessage = intl.formatMessage({ id: "AUTH.RESET.ERROR" });
                              setStatus(errorMessage);
                            });
                        }}
                      >
                        {({
                          values,
                          status,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                        }) => (
                          <form onSubmit={handleSubmit} className="kt-form">
                            {status && (
                              <div role="alert" className="alert alert-danger">
                                <div className="alert-text">{status}</div>
                              </div>
                            )}
                            <div className="form-group">
                              <TextField
                                type="email"
                                label={intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
                                margin="normal"
                                fullWidth={true}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                helperText={touched.email && errors.email}
                                error={Boolean(touched.email && errors.email)}
                              />
                            </div>
                            <div className="form-group">
                              <TextField
                                type="password"
                                margin="normal"
                                label={intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
                                className="kt-width-full"
                                name="password1"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password1}
                                helperText={touched.password1 && errors.password1}
                                error={Boolean(touched.password1 && errors.password1)}
                              />
                            </div>
                            <div className="form-group">
                              <TextField
                                type="password"
                                margin="normal"
                                label={intl.formatMessage({ id: "AUTH.INPUT.VERIFY_PASSWORD" })}
                                className="kt-width-full"
                                name="password2"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password2}
                                helperText={touched.password2 && errors.password2}
                                error={Boolean(touched.password2 && errors.password2)}
                              />
                            </div>
                            <div className="kt-login__actions">
                              <Link
                                to="/auth/login"
                                className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                                style={{ display: 'flex' }}
                              >
                                <FormattedMessage id="AUTH.RESET.BACK_TO_SIGNIN_PAGE" />
                              </Link>

                              <button
                                type="submit"
                                className="btn btn-primary btn-elevate kt-login__btn-primary"
                                disabled={isSubmitting}
                              >
                                <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withSnackbar(injectIntl(
  connect(
    null,
    auth.actions
  )(ResetPassword)
)));
