import axios from "../shared/axios";

export function createActivity(data) {
  return new Promise((resolve, reject) => {
    axios.post(`/api/ActivitySchedule/SaveActivitySchedule`, data)
        .then(result => {
            // store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_SECONDARY_DATES });
            resolve(result);
        })
        .catch(error => reject(error));
});
}
//It's because the api returns the same result with actiontype=KeyDate or actiontype=Sectional, i can use either of them and i use keydate
export function getContractSecondaryDates(companyid, projectid, contractid) {
  return axios.post(
    `/api/ActivitySchedule/LoadActivitySchedulesList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&actiontype=KeyDate`
  );
}
export function getContractActivities(companyid, projectid, contractid) {
  return axios.post(
    `/api/ActivitySchedule/LoadActivitySchedulesList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&actiontype=Activity`
  );
}

export function saveActivity(data) {
  return axios.post(`/api/PaymentRetention/SavePaymentRetention`, data);
}
export function getPaymentRetentionList(paymentCertificateId) {
  return axios.post(
    `/api/PaymentRetention/LoadPaymentRetentionsAssessmentList?paymentcertificateid=${paymentCertificateId}&type=Activity`
  );
}
