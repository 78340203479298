const template = {
    name: "Default FIDIC White Book 5th Edition 2017 v2.5 01/01/2024",
    isSystemDefault: true,
    isGlobal: true,
    companies: [],
    resources: [
        {
            id: 1,
            type: "TEXT",
            key: "SUPPLIER_NAME",
            value: "Contractor"
        },
        {
            id: 2,
            type: "TEXT",
            key: "SUPPLIER_ABBREVIATION",
            value: "CR"
        },
        {
            id: 3,
            type: "TEXT",
            key: "PM_NAME",
            value: "Client"
        },
        {
            id: 4,
            type: "TEXT",
            key: "PM_ABBREVIATION",
            value: "CLI"
        },
        {
            id: 5,
            type: "TEXT",
            key: "SUPERVISOR_NAME",
            value: "Supervisor"
        },
        {
            id: 6,
            type: "TEXT",
            key: "SUPERVISOR_ABBREVIATION",
            value: "SUP"
        },
        {
            id: 7,
            type: "TEXT",
            key: "CLIENT_NAME",
            value: "Client"
        },
        {
            id: 8,
            type: "NUMBER",
            key: "SUPPLIER_REPLY_PERIOD",
            value: 7
        },
        {
            id: 9,
            type: "NUMBER",
            key: "PM_REPLY_PERIOD",
            value: 7
        },
        {
            id: 10,
            type: "NUMBER",
            key: "QUOTE_DURATION",
            value: 28
        },
        {
            id: 13,
            type: "NUMBER",
            key: "REQUOTE_DURATION",
            value: 14
        },
        {
            id: 11,
            type: "NUMBER",
            key: "QUOTE_RESPONSE",
            value: 14
        },
        {
            id: 14,
            type: "NUMBER",
            key: "REQUOTE_RESPONSE",
            value: 14
        },
        {
            id: 12,
            type: "NUMBER",
            key: "VARIATION_RESPONSE",
            value: 14
        },
        {
            id: 16,
            type: "NUMBER",
            key: "PROGRAMME_REPLY_PERIOD",
            value: 14
        },
        {
            id: 30,
            type: "NUMBER",
            key: "CWN_QUOTE_DURATION",
            value: 84
        },
        {
            id: 31,
            type: "NUMBER",
            key: "CWN_QUOTE_RESPONSE",
            value: 42
        },
        {
            id: 32,
            type: "NUMBER",
            key: "INTERIM_CWN_QUOTE_DURATION",
            value: 28
        },
        {
            id: 33,
            type: "NUMBER",
            key: "INTERIM_CWN_QUOTE_RESPONSE",
            value: 42
        },
        {
            id: 8,
            type: "TEXT",
            key: "CLIENT_ABBREVIATION",
            value: "CLI"
        },
        {
            id: 28,
            type: "NUMBER",
            key: "PROP_QUOTE_DURATION",
            value: 14
        },
        {
            id: 29,
            type: "NUMBER",
            key: "PROP_REQUOTE_DURATION",
            value: 14
        },
        {
            id: 9,
            type: "TEXT",
            key: "COMPLETION_DATE",
            value: "Completion Date"
        }
    ],
    workflowGroups: [
        {
            id: 102,
            title: "Instructions",
            workflows: [
                {
                    id: 4,
                    title: "$PM_NAME Instruction",
                    notificationName: "Instruction",
                    abbreviation: "$PM_ABBREVIATION-Instruction-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "InstructionTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "1.15.1 resolving a conflict between documents",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "5.2.3 to commence work on a Variation pursuant to agreement of value and impact on the Programme",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "5.2.4 Notice of an instruction to commence work on a Variation prior to agreement of value and impact on the Programme",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "6.2.1 Notice of an instruction to resume the Services or part thereof",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 103,
            title: "Variations",
            workflows: [
                {
                    id: 5,
                    title: "$PM_NAME Variation Notice (Instruction)",
                    notificationName: " Variation Notice",
                    abbreviation: "$PM_ABBREVIATION-Variation-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    quoteNotificationName: "$SUPPLIER_NAME's Estimate",
                    quoteReferencePrefix: "Estimate-",
                    form_IncludeCompanyName: true,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 6,
                    title: "$PM_NAME Request to submit a proposal for a proposed Variation",
                    notificationName: "Proposed Variation",
                    abbreviation: "$PM_ABBREVIATION-Variation-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    quoteNotificationName: "SUPPLIER_NAME's Proposal",
                    quoteReferencePrefix: "Proposal-",
                    form_IncludeCompanyName: true,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 37,
                    title: "$SUPPLIER_NAME Notice of a Variation",
                    notificationName: "Notice of a Variation to the Services",
                    abbreviation: "$SUPPLIER_ABBREVIATION-NVS-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    quoteNotificationName: "$SUPPLIER_NAME's Estimated Impact",
                    quoteReferencePrefix: "Estimate-",
                    form_IncludeCompanyName: true,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                },
                {
                    id: 8,
                    title: "Variation Notice (Approval)",
                    notificationName: "Variation Notice",
                    abbreviation: "$PM_ABBREVIATION-VN-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: true,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmAssumptionLabel",
                            display: "Your estimate is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmAssumptionNote",
                            display: "You are requested to provide an estimate by $date",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierDescNote",
                            display: "Under clause 5.1.3 you are hereby given Notice of an instruction/direction/circumstance that constitutes a Variation to the Services",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "ReplyAcceptedCommentLabel",
                            display: "Comments",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "ReplyRejectedCommentLabel",
                            display: "Comments",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ReplyAcceptedAssumptionLabel",
                            display: "Your estimate is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "QuoteNote",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "ExtensionPMLabel",
                            display: "An extension of the time to reply is requested as follows",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ExtensionSupplierLabel",
                            display: "An extension of time to provide a proposal/estimate is requested as follows",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "AssessmentDetailLabel",
                            display: "Variation Notice confirming the value and impact on the Programme",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ProposedInstructionNote",
                            display: "You are requested to provide a proposal by $date",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "ProposedInstructionLabel",
                            display: "Your proposal is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "ReplyRejectedOptionsLabel",
                            display: "You are hereby given Notice why the $CLIENT_NAME considers the instruction, direction or circumstance does not constitute a Variation to the Services. ",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "QuoteCostEffectLabel",
                            display: "Change to the Prices",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "QuoteDateEffectLabel",
                            display: "Extension of Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "AssessmentCostEffectLabel",
                            display: "Change to the Prices",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "AssessmentDaysEffectLabel",
                            display: "Extension of Time for Completion (days)",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "AssessmentWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "PmCompensationWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "InstructionQuoteReplyWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "CompensationTypeLabel",
                            display: "Type",
                            overrideResources: null
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "QuoteDaysEffectLabel",
                            display: "Extension of Time for Completion (days)",
                            overrideResources: null
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "QuoteProgrammeAttachLabel",
                            display: "Are you attaching a programme showing the effect against the last Accepted Programme with this quotation?",
                            overrideResources: null
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "AssessmentCompensationLabel",
                            display: "Variation",
                            overrideResources: null
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "QuoteNoteProposedInstruction",
                            display: "We submit our quotation for proposed Variation $reference",
                            overrideResources: null
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "QuoteContinuingEffectLabel",
                            display: "Does this Claim have a continuing effect (i.e. is this an Interim Claim)",
                            overrideResources: null
                        },
                        {
                            id: 28,
                            isActive: true,
                            field: "SupplierCompensationWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 29,
                            isActive: true,
                            field: "SupplierClaimWithQuotationWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 30,
                            isActive: true,
                            field: "SupplierQuoteWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 31,
                            isActive: true,
                            field: "QuoteReplyWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 32,
                            isActive: true,
                            field: "SupplierClaimsWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 33,
                            isActive: true,
                            field: "SupplierCompensationReplyWarningBanner",
                            display: "",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmCompensationAQ",
                            display: "Variations awaiting $SUPPLIER_NAME's estimate",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmCompensationARQ",
                            display: "Variations awaiting $SUPPLIER_NAME's revised estimate",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "PmCompensationAPMRQ",
                            display: "Variations awaiting $PM_NAME's response to estimate",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "PmCompensationAPMA",
                            display: "Variations awaiting awaiting $PM_NAME's Variation Notice",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "PmProposedAQ",
                            display: "Proposed Variations awaiting $SUPPLIER_NAME's proposal",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "PmProposedARQ",
                            display: "Proposed Variations awaiting $SUPPLIER_NAME's revised proposal",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "PmProposedAPMRQ",
                            display: "Awaiting $PM_NAME's response to $SUPPLIER_NAME's proposal",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "PmProposedAPMA",
                            display: "Proposed Variation awaiting $PM_NAME's Variation Notice",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "SupClaimAPMR",
                            display: "$SUPPLIER_NAME Notices of Variation awaiting $PM_NAME's response",
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "SupClaimAQ",
                            display: "$SUPPLIER_NAME Notices of Variation awaiting estimate",
                            overrideResources: null
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "SupClaimARQ",
                            display: "$SUPPLIER_NAME Notices of Variation awaiting revised estimate",
                            overrideResources: null
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "SupClaimAPMRQ",
                            display: "$SUPPLIER_NAME Estimates awaiting response",
                            overrideResources: null
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "SupClaimAPMA",
                            display: "$SUPPLIER_NAME Notices of Variation awaiting $PM_NAME's Variation Notice",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "PmExtension",
                            display: "$PM_NAME Response Extensions awaiting Acceptance",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "SupExtension",
                            display: "$SUPPLIER_NAME Quote Extensions awaiting Acceptance",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SupCompensationAQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting quotes",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SupCompensationARQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting revised quotes",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "SupCompensationAPMR",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION response",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "SupCompensationAPMRQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting response to quotes",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "SupCompensationAPMA",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "COMPENSATION_CWN_AQ",
                            display: "$SUPPLIER_NAME Notices of Variation awaiting estimate",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "COMPENSATION_CWN_ARQ",
                            display: "$SUPPLIER_NAME Notices of Variation awaiting revised estimate",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "COMPENSATION_CWN_APMR",
                            display: "$SUPPLIER_NAME Notices of Variation awaiting $PM_NAME's response",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "COMPENSATION_CWN_APMRQ",
                            display: "$SUPPLIER_NAME's estimates awaiting $PM_NAME's response",
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "COMPENSATION_CWN_APMA",
                            display: "$SUPPLIER_NAME estimates awaiting $PM_NAME's Variation Notice",
                            overrideResources: null
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "SupAssumption",
                            display: "$SUPPLIER_NAME Assumptions awaiting Acceptance",
                            overrideResources: null
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "replydraftpmcequotessupplier",
                            display: "$SUPPLIER_NAME Proposals/Estimates",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "faultpscp",
                            display: "Arises from a fault of the Contractor",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "notexpectnothappen",
                            display: "Has not happened and is not expected to happen",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: false,
                            field: "notnotifiedwithintimescales",
                            display: "The claiming Party has failed to give the Notice of Claim within the period of 28 days under Sub-Clause 20.2.1",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "noteffectcostcompletion",
                            display: "Has no effect on Defined Cost, Completion or meeting a Key Date",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "statedcontract",
                            display: "Does not constitute a Variation under the terms of this contract",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "CompensationClauses",
                    values: [
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "1.5.1 a change in legislation having application to the Services",
                            overrideResources: []
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "1.15.1 resolving a conflict between documents",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "2.1.3 rectification of error, omission or ambiguity",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "2.6.3 alternative supply of the $PM_NAME's personnel",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "5.1.2(a) amendment to the Scope of Services",
                            overrideResources: []
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "5.1.2(a) amendment to Personnel, Equipment, Facilities and Services of Others to be Provided by the Client",
                            overrideResources: []
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "5.1.2(b) omission of part of the Servces no longer required by the Client",
                            overrideResources: []
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "5.1.2(c) changes in the specified sequence or timing of the performance of the Services",
                            overrideResources: []
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "5.1.2(d) changes in the method of implementation of the Services",
                            overrideResources: []
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "5.1.2(e) provision of the Agreement requiring the issue of a Variation",
                            overrideResources: []
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "5.1.2(f) proposal submitted by the Consultant and accepted in writing by the Client",
                            overrideResources: []
                        }
                    ]
                },
                {
                    replacementKey: "ProposedInstructionClauses",
                    values: [
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "1.5.1 a change in legislation having application to the Services",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "1.15.1 resolving a conflict between documents",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "2.1.3 of rectification of error, omission or ambiguity",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "2.6.3 alternative supply of the $PM_NAME's personnel",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "5.1.2(a) amendment to the Scope of Services",
                            overrideResources: []
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "5.1.2(a) amendment to Personnel, Equipment, Facilities and Services of Others to be Provided by the Client",
                            overrideResources: []
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "5.1.2(b) omission of part of the Servces no longer required by the Client",
                            overrideResources: []
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "5.1.2(c) changes in the specified sequence or timing of the performance of the Services",
                            overrideResources: []
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "5.1.2(d) changes in the method of implementation of the Services",
                            overrideResources: []
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "5.1.2(e) provision of the Agreement requiring the issue of a Variation",
                            overrideResources: []
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "5.1.2(f) proposal submitted by the Consultant and accepted in writing by the Client",
                            overrideResources: []
                        }
                    ]
                },
                {
                    replacementKey: "ClaimClauses",
                    values: [
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "1.5.1 a change in legislation having application to the Services",
                            overrideResources: [
                                {
                                    key: "QUOTE_DURATION",
                                    value: "0"
                                },
                                {
                                    key: "QUOTE_RESPONSE",
                                    value: "14"
                                }
                            ]
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "1.15.1 resolving a conflict between documents",
                            overrideResources: [
                                {
                                    key: "QUOTE_DURATION",
                                    value: "0"
                                },
                                {
                                    key: "QUOTE_RESPONSE",
                                    value: "14"
                                }
                            ]
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "2.1.3 of rectification of error, omission or ambiguity",
                            overrideResources: [
                                {
                                    key: "QUOTE_DURATION",
                                    value: "0"
                                },
                                {
                                    key: "QUOTE_RESPONSE",
                                    value: "14"
                                }
                            ]
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "2.6.3 alternative supply of the $PM_NAME's personnel",
                            overrideResources: [
                                {
                                    key: "QUOTE_DURATION",
                                    value: "0"
                                },
                                {
                                    key: "QUOTE_RESPONSE",
                                    value: "14"
                                }
                            ]
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "5.1.2(a) amendment to the Scope of Services",
                            overrideResources: [
                                {
                                    key: "QUOTE_DURATION",
                                    value: "0"
                                },
                                {
                                    key: "QUOTE_RESPONSE",
                                    value: "14"
                                }
                            ]
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "5.1.2(a) amendment to Personnel, Equipment, Facilities and Services of Others to be Provided by the Client",
                            overrideResources: [
                                {
                                    key: "QUOTE_DURATION",
                                    value: "0"
                                },
                                {
                                    key: "QUOTE_RESPONSE",
                                    value: "14"
                                }
                            ]
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "5.1.2(b) omission of part of the Servces no longer required by the Client",
                            overrideResources: [
                                {
                                    key: "QUOTE_DURATION",
                                    value: "0"
                                },
                                {
                                    key: "QUOTE_RESPONSE",
                                    value: "14"
                                }
                            ]
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "5.1.2(c) changes in the specified sequence or timing of the performance of the Services",
                            overrideResources: [
                                {
                                    key: "QUOTE_DURATION",
                                    value: "0"
                                },
                                {
                                    key: "QUOTE_RESPONSE",
                                    value: "14"
                                }
                            ]
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "5.1.2(d) changes in the method of implementation of the Services",
                            overrideResources: [
                                {
                                    key: "QUOTE_DURATION",
                                    value: "0"
                                },
                                {
                                    key: "QUOTE_RESPONSE",
                                    value: "14"
                                }
                            ]
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "5.1.2(e) provision of the Agreement requiring the issue of a Variation",
                            overrideResources: [
                                {
                                    key: "QUOTE_DURATION",
                                    value: "0"
                                },
                                {
                                    key: "QUOTE_RESPONSE",
                                    value: "14"
                                }
                            ]
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "5.1.2(f) proposal submitted by the Consultant and accepted in writing by the Client",
                            overrideResources: [
                                {
                                    key: "QUOTE_DURATION",
                                    value: "0"
                                },
                                {
                                    key: "QUOTE_RESPONSE",
                                    value: "14"
                                }
                            ]
                        }
                    ]
                },
                {
                    replacementKey: "CompensationStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "APMA",
                            display: "Not Accepted - $PM_NAME to make a Determination",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ARQ",
                            display: "Not Accepted - $SUPPLIER_NAME to resubmit",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "AM",
                            display: "$PM_ABBREVIATION Determination Made",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "QA",
                            display: "Quote Accepted",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "APMRQ",
                            display: "Awaiting $PM_NAME Response to estimate",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "APMR",
                            display: "Awaiting $PM_ABBREVIATION Response to Notice of Claim",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "AQ",
                            display: "Awaiting estimate",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NC",
                            display: "Not Communicated",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "R",
                            display: "Rejected",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "DNG",
                            display: "Proposed Variation will not be instructed",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "InstructionReplyProceedOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "61.1",
                            display: "Instruction of the Variation",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "61.2",
                            display: "Variation not instructed",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "INTERIM_QUOTES_ALLOWED",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "INTERIM_QUOTES_ALLOWED",
                            display: "Active-Inactive state indicates whether the continuing effect field is asked on quote communications",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "EWRelationEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "EWRelationEnabled",
                            display: "Active-Inactive state indicates weather the EWN can be attached in CEN communications",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "RelatedCompensationText",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "RelatedCompensationText",
                            display: "Regarding the aforementioned instruction, you are hereby notified of a Variation",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "VariationClauses",
                    values: [
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "13.3.1 Variation by Instruction",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ClaimWNAcceptanceOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "1",
                            display: "Accepted",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "0",
                            display: "Not Accepted",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "Fields",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "Assumption",
                            display: "Additional information",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "QuoteProgrammeImpactOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "noeffect",
                            display: "There is no effect on the Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "mitigated",
                            display: "There is an effect on the Time for Completion but this has been mitigated by measures as detailed",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "notmitigated",
                            display: "There is an effect on the Time for Completion",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "QuoteStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "0",
                            display: "Not Agreed - $SUPPLIER_NAME to resubmit",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Agreed",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Awaiting Response",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "3",
                            display: "$PM_NAME Variation Notice to follow",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "4",
                            display: "Rejected - Other particulars/claim/proposal agreed",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "5",
                            display: "Rejected - Not a Variation",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: false,
                            field: "6",
                            display: "Awaiting Deemed Acceptance [Not Applicable]",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: false,
                            field: "7",
                            display: "Deemed Acceptance [Not Applicable]",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "none",
                            display: "Not Communicated",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: false,
                            field: "8",
                            display: "Acknowledged [Not Applicable]",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "9",
                            display: "Not Agreed - Process ended",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "InstructionReplyOpts",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "1",
                            display: "QuoteStatus_1",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "0",
                            display: "QuoteStatus_0",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "3",
                            display: "QuoteStatus_3",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "5",
                            display: "QuoteStatus_5",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "CompensationEventsAcceptanceOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "1",
                            display: "Is a Compensation Event",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "0",
                            display: "Is not a Compensation Event",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "SupplierCWQReplyOpts",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "1",
                            display: "QuoteStatus_1",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "0",
                            display: "QuoteStatus_0",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "3",
                            display: "QuoteStatus_3",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "5",
                            display: "QuoteStatus_5",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "InstructionReplyProceedEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "InstructionReplyProceedEnabled",
                            display: "Proposed instructions Quote Reply field proceed options Enabled/Disabled",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "TaskOrderOptionEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "TaskOrderOptionEnabled",
                            display: "Active-Inactive state indicates weather Task-Order can be selected in CEN communications",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "UserLimitCheckEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "UserLimitCheckEnabled",
                            display: "Active-Inactive state indicates weather the user limit check can be applied in Assessment communications",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "OverdueExtensionsEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "OverdueExtensionsEnabled",
                            display: "Active-Inactive state indicates whether the EWN can be attached in CEN communications",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ProjectOrderOptionEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "ProjectOrderOptionEnabled",
                            display: "Active-Inactive state indicates whether Project-Order can be selected in CEN communications",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "PMCENReplyOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "1",
                            display: "QuoteStatus_1",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "0",
                            display: "QuoteStatus_0",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "3",
                            display: "QuoteStatus_3",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "5",
                            display: "QuoteStatus_5",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "SupplierCENReplyOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "1",
                            display: "QuoteStatus_1",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "0",
                            display: "QuoteStatus_0",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "3",
                            display: "QuoteStatus_3",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "5",
                            display: "QuoteStatus_5",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "SupplierCWNReplyOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "1",
                            display: "QuoteStatus_1",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "0",
                            display: "QuoteStatus_0",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "3",
                            display: "QuoteStatus_3",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "5",
                            display: "QuoteStatus_5",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "SupplierShortCENReplyOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "1",
                            display: "QuoteStatus_1",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "0",
                            display: "QuoteStatus_0",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "3",
                            display: "QuoteStatus_3",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "5",
                            display: "QuoteStatus_5",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "FieldsPM",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Assumption",
                            display: "The quotation is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ProposedInstructionGivenDate",
                            display: "Date instruction may be given by",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Instructions",
                            display: "Instructions",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 107,
            title: "Delays and Exceptional Costs",
            workflows: [
                {
                    id: 19,
                    title: "$SUPPLIER_NAME Claim for Extension of Time and/or Exceptional Costs",
                    notificationName: "Claim for Extension of Time and/or Exceptional Costs",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Claim-",
                    notify: true,
                    replyEnabled: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: true,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CostEffectLabel",
                            display: "Cost incurred or saved",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "DaysEffectLabel",
                            display: "Delay suffered",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "WarningBanner",
                            display: "Any Change in Legislation under 1.5.1 or a delay under 4.4.1(a) or should be dealt with as a Variation",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ProposalClauses",
                    values: [
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "1.5.2 a change in legislation in a country in which the services are required",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "4.4.1(b) impediment or prevention caused by or attributable to the Client, or the Clients other consultants, contractors, or other third parties",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "4.4.1(c) an Exceptional Event",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "4.4.1(d) other event or circumstance giving an entitlement to extension of the Time for Completion under the Agreement",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Claims awaiting response",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Agreed",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Not Accepted",
                            display: "Not agreed",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 104,
            title: "Programmes",
            workflows: [
                {
                    id: 14,
                    title: "Works Programmes",
                    notificationName: "Programme",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Programme-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: true,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 8,
                            isActive: true,
                            field: "NotificationClause",
                            display: "Under clause 4.3.1 we submit a Programme",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ReplyRejectReasonLabel",
                            display: "No-objection",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "ReplyNotificationClause",
                            display: "Notice of Rejection",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ReasonLabel",
                            display: "Reason for any change to Planned Completion",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "ChangeLabel",
                            display: "Change in any other Key Date, Sectional Completion or other important project milestone in period and reasons why",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "MainProgressLabel",
                            display: "Main progress achieved in period",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "KeyActivitiesLabel",
                            display: "Key activities forecast in next four weeks",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "SummaryLabel",
                            display: "Summary of critical path",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "ChangesLabel",
                            display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc.",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "CompensationLabel",
                            display: "Claims/Variations added to programme this period",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "ReferenceLabel",
                            display: "Programme Reference",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "DashboardPlannedCompletionLabel",
                            display: "Planned Completion shown on the latest Accepted Programme",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Programme Submission awaiting Reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 4,
                            isActive: true,
                            field: "notcomply",
                            display: "The programme does not comply with the Contract",
                            overrideResources: null
                        },
                        {
                            id: 1,
                            isActive: true,
                            field: "plansnotpractical",
                            display: "The programme does not reflect actual progress",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "missingcontractrequires",
                            display: "The programme is otherwise inconsistent with the Contractor's obligations",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "Fields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PreviousPlanned",
                            display: "Previous Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlannedCompletion",
                            display: "Anticipated Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Reason",
                            display: "Reason for any change in the Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Change",
                            display: "Change to important project milestone in the period and reasons why",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "MainProgress",
                            display: "Main progress achieved in period",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "KeyActivities",
                            display: "Key activities forecast in next four weeks",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "Summary",
                            display: "Summary of critical path",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "Changes",
                            display: "Change to important project milestones in the period and reasons why",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "Compensation",
                            display: "Claims or Variations added to programme this period",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Clause 4.3.2 - Notice of acceptance",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Not Accepted",
                            display: "Clause 4.3.2 - Notice of non-compliance with the Agreement",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "DashboardDisplayPlannedCompletionOnLatest",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "DashboardDisplayPlannedCompletionOnLatest",
                            display: "Active-Inactive state indicates weather (Planned Completion shown on the latest Accepted Programme) is displayed on dasboard",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 108,
            title: "Payments",
            workflows: [
                {
                    id: 21,
                    title: "$SUPPLIER_NAME Statement of Application of Payment",
                    notificationName: "Statement of Application of Payment",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Statement-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 20,
                    title: "$PM_NAME Notice of a Statement of Payment",
                    notificationName: "Notice of a Statement of Payment",
                    abbreviation: "$PM_ABBREVIATION-Payment-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CumulativePriceLabel",
                            display: "Value of Work Done",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlusAmountsSupplierLabel",
                            display: "Plus other amounts to be paid to the Contractor",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "LessAmountsSupplierLabel",
                            display: "Less amounts to be paid by or retained from the Contractor",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TaxSupplierLabel",
                            display: "Tax which the law requires the Employer to pay to the Employer",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "WarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "RegisterAppliedByCaption",
                            display: "Submitted by",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "SummaryDetailsLabel",
                            display: "Summary details",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "TaxEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "TaxEnabled",
                            display: "Active-Inactive state indicates tax field will be included in notification forms",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ApplicationTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "INTERIM",
                            display: "14.3 submission of a statement of application for interim payment",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SUPPLEMENTARY",
                            display: "14.10 submission of a statement at completion",
                            overrideResources: null
                        },
                        {
                            id: 1,
                            isActive: true,
                            field: "FINAL",
                            display: "14.11 of a draft/agreed final statement",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "PaymentTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "INTERIM",
                            display: "14.6.1 of a statement of interim payment",
                            overrideResources: null
                        },
                        {
                            id: 1,
                            isActive: true,
                            field: "FINAL",
                            display: "14.13 of the amount finally due",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "SUPPLEMENTARY",
                            display: "Supplementary",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 105,
            title: "Other Notices",
            workflows: [
                {
                    id: 15,
                    title: "$PM_NAME Notices",
                    notificationName: "Notice",
                    abbreviation: "$PM_ABBREVIATION-Notice-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 16,
                    title: "$SUPPLIER_NAME Notices",
                    notificationName: "Notice",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Notice-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "PmClauses",
                    values: [
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "1.3.1 of a change in address for communications",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "1.5.2 requiring that the provisions of the agreement be amended to comply with a change in legislation",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "1.14.1 of a waiver of rights under the Agreement",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "2.1.2 of adverse findings with regards to error, omission or ambiguity of information",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "2.1.3 of rectification of error, omission or ambiguity",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "2.4.2 of a change of financial arrangements",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "3.7 of a request to replace Consultant's personnel",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "4.3.2 that the $SUPPLIER_NAME's Programme does not comply with the Agreement",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "4.3.3 of specific, actual or probable future events or circumstances which may adversely affect or delay the Services or lead to an increase in the cost of the Services",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "4.5.1 that the rate of progress is insufficient",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "4.6.1 of an Exceptional Event",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "6.1.1 of supension of the Services",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "6.1.1 of supension of part of the Services",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "6.4.1(a) outlining breach of the Agreement and remedy required",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "6.4.1(a) of termination due to breach of the Agreement",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "6.4.1(a) of termination due to breach of the Agreement",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "6.4.1(b) of termination due to $SUPPLIER_NAME bankruptcy/insolvency/liquidation/administration or similar",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "6.4.1(c) of termination due to $SUPPLIER_NAME breach of clause 1.10 [Anti-Corruption]",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "6.4.1(c) of termination at the $PM_NAME's discretion",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "6.4.1(d) of termination due to suspension following an Exceptional Event",
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "7.6.2 requiring an independent audit of time and expense records",
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "10.2.3(b) of Dissatisfaction with the Adjudicator's Decision",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "SupplierClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "1.3.1 of a change in address for communications",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "1.5.2 requiring that the provisions of the agreement be amended to comply with a change in legislation",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "1.7.5 of revocation of a licence",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "1.14.1 of a waiver of rights under the Agreement",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "3.9.6 of ambiguity or discrepancy between obligations under the Agreement and duties under a Works Contract",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "4.3.3 of specific, actual or probable future events or circumstances which may adversely affect or delay the Services or lead to an increase in the cost of the Services",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "4.6.1 of an Exceptional Event",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "6.1.2(a) of supension of the Services due to non-payment",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "6.1.2(b) of supension of the Services due to an Exceptional Event",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "6.1.2(c) of supension of the Services due to financial arrangements",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "6.3.3(c) of Exceptional Costs as a result of suspension/resumption",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "6.4.2(a) of termination due to suspension under 6.1.1",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "6.4.2(b) of termination due to suspension under 6.1.2(a) or 6.1.2(c)",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "6.4.2(c) of termination due to $PM_NAME bankruptcy/insolvency/liquidation/administration or similar",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "6.4.2(c) of termination due to $PM_NAME breach of clause 1.10 [Anti-Corruption]",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "6.4.2(d) of termination due to suspension under 6.1.2(b)",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "6.5.3 of Exceptional Costs as a result of termination under 6.4.1(d) or (e) or 6.4.2",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "6.5.4 of loss of profit as a result of termination under 6.4.1(d) or 6.4.2(a) to (d)",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "10.2.3(b) of Dissatisfaction with the Adjudicator's Decision",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Notifications awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PM",
                            display: "$PM_NAME Notifications awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Supervisor",
                            display: "$SUPERVISOR_NAME Notifications awaiting reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Notice of no objection",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "Accepted with comments",
                            display: "Accepted with comments",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Not Accepted",
                            display: "Notice of objection",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Reply Not Required",
                            display: "No reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "Resources",
                    values: []
                }
            ]
        },
        {
            id: 106,
            title: "Other Communications",
            workflows: [
                {
                    id: 49,
                    title: "$PM_NAME Communication",
                    notificationName: "Communication",
                    abbreviation: "$PM_ABBREVIATION-Comm-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 18,
                    title: "$SUPPLIER_NAME Communication",
                    notificationName: "Communication",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Comm-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "ReplyWarningBanner",
                            display: "",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "IssueClauses",
                    values: [
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "1.6.1 of a request for consent to assign benefits under the Agreement",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "1.6.2 of a request for consent to assign obligations under the Agreement",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "1.6.3 of a request for consent to sub-contract",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "1.8.2 of a request for consent to disclose Confidential Information",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "1.9.1 of a request for consent to publish material relating to the Services",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "1.12.1 of a request for consent to amend the Agreement",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "1.10.3 demonstration of adherence to a documented code of conduct",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "2.1.1 request for information",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "2.2.1 of a referral for a decision",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "2.3.1 request for assistance",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "2.4.1 of a request for reasonable evidence of financial arrangements",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "3.1.3 of a conflict of interest",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "3.5.1 of a submission for acceptance of details of key personnel",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "3.6.1 notifying the extent of powers and authority delegated to the Consultant's Representative",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "3.6.2 designating an individual to liaise with the Client's Representative",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "9.1.3 of insurance certificates as evidence that required cover is being maintained",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "9.1.4 of a notification that required insurance has been cancelled",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "10.1.1 of a request for amicable dispute resolution",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "IssueClausesPM",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "1.6.1 of a request for consent to assign benefits under the Agreement",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "1.6.2 of a request for consent to assign obligations under the Agreement",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "1.8.1 of a request for consent to disclose Confidential Information",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "1.10.3 of a request to demonstrate adherence to a documented code of conduct",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "1.12.1 of a request for consent to amend the Agreement",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "2.1.1 provision of information",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "2.2.1 of a decision",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "2.4.1 of a submission of financial arrangements",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "2.6.2 of a submission for acceptance of details of replacement personnel",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "2.7.1 notifying the extent of powers and authority delegated to the Client's Representative",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "9.1.3 of a request to produce insurance certificates",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "10.1.1 of a request for amicable dispute resolution",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Communication awaiting Reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Consent/Acceptance/Notice of No-objection",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "Accepted with comments",
                            display: "Accepted with comments",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Not Accepted",
                            display: "Rejection/Notice of objection",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Reply Not Required",
                            display: "No reply",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 111,
            title: "Records",
            workflows: [
                {
                    id: 30,
                    title: "$PM_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$PM_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 31,
                    title: "$SUPPLIER_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 32,
                    title: "$SUPPLIER_NAME Site Diary",
                    notificationName: "Site Diary",
                    abbreviation: "Diary-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 33,
                    title: "Weather",
                    notificationName: "Weather",
                    abbreviation: "Weather-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: []
        },
        {
            id: 112,
            title: "RFI / TQ",
            workflows: [
                {
                    id: 34,
                    title: "$SUPPLIER_NAME RFI/TQ",
                    notificationName: "Request for Information/Technical Query",
                    abbreviation: "RFI/TQ-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: true,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME RFI awaiting Reply",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 113,
            title: "Contract Information",
            workflows: [
                {
                    id: 39,
                    title: "Documents",
                    notificationName: "Document",
                    abbreviation: "Documents-",
                    notify: true,
                    actioningParty: "PM",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: []
                }
            ]
        }
    ]
};

export default template;