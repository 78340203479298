import React from "react";
import * as fileCrud from "../../../../crud/file.crud";
import CustomDocument from "./CustomForm";
import BiDynamicForm from "../../../../partials/layout/BiDynamicForm";
import BaseDocument from "../../_CustomComponents/BaseDocument";

class DocumentPage extends BaseDocument {
  constructor(props) {
    super(props);

    this.editorFormConfig = {
      fields: [
        {
          label: `Reference`,
          dataField: "reference",
          editorOptions: { readOnly: true },
        },
        {
          label: `Title`,
          dataField: "title",
          editorOptions: { readOnly: true },
        },
        { label: `Type`, dataField: "type", editorOptions: { readOnly: true } },
        {
          label: `Issued By`,
          dataField: "issuedby",
          editorOptions: { readOnly: true },
        },
        {
          label: `Issued Date`,
          dataField: "issueddate",
          editorOptions: { readOnly: true },
        },
        {
          resource: "GENERAL.FORMS.FIELD.STATUS",
          dataField: "status",
          editorOptions: { readOnly: true },
        },
        {
          label: `Revision`,
          dataField: "revision",
          editorOptions: { readOnly: true },
        },
      ],
    };

    this.isAsiteEnabled = process.env.REACT_APP_ATTACHMENT_MODE === 'ASITE';
  }

  renderForm = () => {
    return (
      <>
        <BiDynamicForm
          item={this.props.notification}
          config={this.editorFormConfig}
          customFields={(this.props.customFields || {}).COMMUNICATION}
          customFieldValues={this.props.notification.customfieldvalues}
        />
      </>
    );
  };

  uploadFile = (data, config) => {
    const { companyid, projectid, contractid } = this.props.contract;

    return fileCrud.uploadContractNotificationFiles(
      companyid,
      projectid,
      contractid,
      "Document",
      this.props.notification.documentid,
      data,
      config
    );
  };

  documentBodyConfig = () => {
    const config = [
      { value: this.props.files, resource: 'GENERAL.FORMS.FIELD.DOCUMENTS', valueType: 'files', align: this.props.notification.showtasknotification === 1 && this.isAsiteEnabled ? 'vertical' : null },
    ];

    return config;
  };


  render() {
    return (
      <CustomDocument
        {...this.props}
        bodyConfig={this.documentBodyConfig()}
        form={this.renderForm()}
        uploadMethod={this.uploadFile}
        documentType="Document"
      />
    );
  }
}

export default DocumentPage;
