import { userFilterCategories } from "../../../shared/lookup";
const workflows = [
    {
        id: 34,
        title: '$SUPPLIER_NAME RFI / TQ',
        notificationName: 'RFI / TQ',
        abbreviation: 'RFI/TQ-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'PM',
        //REPLY
        replyEnabled: true,
        replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier RFI / TQ',
            currentModel: 'InspectionDefect',
            currentModelType: 'RFI',
            referenceType: 'RFITQ',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,


            replyPeriodOptions: ["ASKED_ON_COMMUNICATION_FORM", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supplier',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 80,
        title: '$PM_NAME RFI / TQ',
        notificationName: 'RFI / TQ',
        abbreviation: '$PM_ABBREVIATION-RFI/TQ-',
        notifyingParty: 'PM',
        replyingParty: 'SUPPLIER',
        //REPLY
        replyEnabled: true,
        replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM RFI / TQ',
            currentModel: 'InspectionDefect',
            currentModelType: 'RFIPM',
            referenceType: 'RFITQ',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,


            replyPeriodOptions: ["ASKED_ON_COMMUNICATION_FORM", "CUSTOM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 82,
        title: 'Master RFI / TQ Register',
        notificationName: 'RFI / TQ',
        abbreviation: 'RFI/TQ-',
        config: {
            description: 'Master RFI / TQ Register',
            showInRelations: false,
            showInCustomFields: false,
            replyPossible: false,
            userFilterCategories: [
                userFilterCategories.ACCESS
            ],
            paths: {
                base: 'master',
                list: 'list',
                default: 'list'
            }
        }
    },
]

export default workflows;