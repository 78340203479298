import { persistReducer } from "redux-persist";
import expireReducer from 'redux-persist-expire';
import storage from "redux-persist/lib/storage";
import store from "../store";
import { select, put, takeLatest } from "redux-saga/effects";
import * as authCrud from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";
import Common from '../../shared/common';
import Scheduler from '../helpers/Scheduler';
// OTHER DUCKS //
import * as companiesDuck from "./companies.duck";
import * as companyDuck from "./company.duck";
import * as contractDuck from "./contract.duck";
import * as contractsDuck from "./contracts.duck";
import * as projectsDuck from "./projects.duck";
import * as projectDuck from "./project.duck";
import * as applicationDuck from "./application.duck";
import * as notificationsDuck from "./notifications.duck";
import SystemHelper from "../../shared/systemHelper";
import moment from "moment";
import { addDays } from 'date-fns';
// import { signOut } from "supertokens-auth-react/recipe/session";
import { allMessages } from "../../../_metronic/i18n/I18nProvider";
import history from "../../../history";

const getAuth = (store) => store.auth;
//const appcuesEnabled = process.env.REACT_APP_APPCUES_ENABLED === "true";
const isOpenliEnabled = process.env.REACT_APP_OPENLI_ENABLED === "true";
const delightedEnabled = process.env.REACT_APP_DELIGHTED_ENABLED === "true";

export const actionTypes = {
  AUTH_SET_AUTH_TOKEN: "[AUTH_SET_AUTH_TOKEN]",
  AUTH_SET_CSRF_TOKEN: "[AUTH_SET_CSRF_TOKEN]",
  AUTH_REFRESH_CSRF: "[AUTH_REFRESH_CSRF]",
  AUTH_SET_REFRESH_CSRF_TOKEN: "[AUTH_SET_REFRESH_CSRF_TOKEN]",
  AUTH_REFRESH_PROFILE: "[AUTH_REFRESH_PROFILE]",
  AUTH_SET_PROFILE: "[AUTH_SET_PROFILE]",
  AUTH_LOAD_COMPLETED: "[AUTH_LOAD_COMPLETED]",
  AUTH_LOGOUT_START: "[AUTH_LOGOUT_START]",
  AUTH_LOGOUT: "[AUTH_LOGOUT]",
  AUTH_LOGOUT_COMPLETE: "[AUTH_LOGOUT_COMPLETE]",
};

const initialAuthState = {
  authToken: {},
  csrfToken: {},
  userProfile: null,
  logoutProcessing: false
};

//STORAGE KEY SHOULD DIFFER BETWEEN PRODUCTS AND SERVER ADDRESS
const storageKey = "FdAuth" + process.env.REACT_APP_PRODUCT_KEY + process.env.REACT_APP_VERSION + Common.GetHashCode(process.env.REACT_APP_API_URL);
export const reducer = persistReducer(
  {
    //, whitelist: ["user", "authToken", "csrfToken", "userProfile"]
    blacklist: ['logoutProcessing'],
    storage, key: storageKey,
    transforms: [
      expireReducer('userProfile', {
        //persistedAtKey: 'userProfile',
        expireSeconds: Number(process.env.REACT_APP_AUTH_EXPIRE),
        autoExpire: true,
        expiredState: null
      }),
      expireReducer('authToken', {
        expireSeconds: Number(process.env.REACT_APP_AUTH_EXPIRE),
        autoExpire: true,
        expiredState: null
      }),
      expireReducer('csrfToken', {
        expireSeconds: Number(process.env.REACT_APP_AUTH_EXPIRE),
        autoExpire: true,
        expiredState: null
      })
    ]
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.AUTH_SET_AUTH_TOKEN: {
        return { ...initialAuthState, logoutProcessing: false, authToken: { token: action.payload.token, reportingToken: action.payload.reportingToken, expireDateEpochTime: (new Date().getTime() + (Number(process.env.REACT_APP_AUTH_EXPIRE) * 1000)), fetchDate: new Date(), issued: action.payload.issued, expires: action.payload.expires, loginMethod:action.payload.loginMethod  } };
      }
      case actionTypes.AUTH_SET_CSRF_TOKEN: {
        return { ...state, csrfToken: { token: action.payload.token, lastLoadedEpochTime: new Date().getTime(), expireDateEpochTime: (new Date().getTime() + (59 * 60 * 1000)) } };
      }
      case actionTypes.AUTH_SET_REFRESH_CSRF_TOKEN: {
        return { ...state, csrfToken: { token: action.payload.token, lastLoadedEpochTime: new Date().getTime(), expireDateEpochTime: (new Date().getTime() + (59 * 60 * 1000)) } };
      }
      case actionTypes.AUTH_SET_PROFILE: {
        return { ...state, userProfile: action.payload.userProfile };
      }
      case actionTypes.AUTH_LOGOUT_START: {
        return { ...state, logoutProcessing: true };
      }
      case actionTypes.AUTH_LOGOUT: {
        return { ...initialAuthState, logoutProcessing: true };
      }
      case actionTypes.AUTH_LOGOUT_COMPLETE: {
        routerHelpers.forgotLastLocation();
        return { ...initialAuthState };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  setAuthToken: (token, reportingToken, issued, expires, loginMethod) => ({ type: actionTypes.AUTH_SET_AUTH_TOKEN, payload: { token, reportingToken, issued, expires, loginMethod } }),
  setCsrfToken: (token) => ({ type: actionTypes.AUTH_SET_CSRF_TOKEN, payload: { token } }),
  refreshCsrfToken: () => ({ type: actionTypes.AUTH_REFRESH_CSRF }),
  setRefreshCsrfToken: (token) => ({ type: actionTypes.AUTH_SET_REFRESH_CSRF_TOKEN, payload: { token } }),
  refreshProfile: () => ({ type: actionTypes.AUTH_REFRESH_PROFILE }),
  setUserProfile: (userProfile) => ({ type: actionTypes.AUTH_SET_PROFILE, payload: { userProfile } }),
  loadCompleted: () => ({ type: actionTypes.AUTH_LOAD_COMPLETED }),
  startLogout: () => ({ type: actionTypes.AUTH_LOGOUT_START }),
  logout: () => ({ type: actionTypes.AUTH_LOGOUT }),
  logoutCompleted: () => ({ type: actionTypes.AUTH_LOGOUT_COMPLETE })
};

export const scheduledTasks = () => {
  const authStore = store.getState().auth;
  const langStore = store.getState().i18n;
  if (authStore) {
    if (authStore.authToken.token && authStore.csrfToken.token) {
      if (authStore.csrfToken.lastLoadedEpochTime) {
        const refreshIntervalSec = Number(process.env.REACT_APP_CSRF_CLOCK_INTERVAL);
        //CSRF TOKEN RELOAD IF LOADED BEFORE $refreshIntervalSec SECONDS
        if (new Date(authStore.csrfToken.lastLoadedEpochTime + (refreshIntervalSec * 1000)) < new Date()) {
          store.dispatch(actions.refreshCsrfToken());
        }
      }
    }

    if (authStore.authToken.expireDateEpochTime) {
      try {
        const timeLeftForExpireMs = (authStore.authToken.expireDateEpochTime - new Date().getTime());
        //IF LESS THAN 6 MINUTES
        if (timeLeftForExpireMs > 0 && timeLeftForExpireMs < 360000) {
          const minutes = Math.floor(timeLeftForExpireMs / 60000);
          store.dispatch(notificationsDuck.enqueueSnackbar({
            message: minutes ? allMessages[langStore.lang]["SYSTEM.SESSION_EXPIRE_IN_MINUTES"].replace("{MINUTES}", minutes) : allMessages[langStore.lang]["SYSTEM.SESSION_EXPIRE_SOON"],
            options: {
              key: "SessionExpire5",
              variant: 'warning',
              autoHideDuration: 10000 //SHOW FOR 10 SECONDS 
            },
          }));
        }
        else if (timeLeftForExpireMs < 0) { //(authExpiryDate < new Date()) {
          const authExpiryDate = new Date(authStore.authToken.expireDateEpochTime);
          console.log('Auth fetch date:', authStore.authToken.fetchDate);
          console.log('Auth expire UI time:', authExpiryDate);
          console.log('token .issued:', authStore.authToken.issued);
          console.log('token .expires:', authStore.authToken.expires);
          console.log("Auth token expired will be redirected to Login");
          store.dispatch(notificationsDuck.enqueueSnackbar({
            message: allMessages[langStore.lang]["SYSTEM.SESSION_EXPIRED"],
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning',
            },
          }));
          store.dispatch(actions.startLogout());
        }
      } catch (error) {
        console.log("Error in auth.duck->scheduledTasks")
        console.log(error)
      }
    }
  }
}

export function* saga() {
  yield takeLatest(actionTypes.AUTH_SET_AUTH_TOKEN, function* authLoadCsrfSaga() {
    const authStore = store.getState().auth;
    try {
      const { data: csrfToken } = yield authCrud.getCsrfToken();
      yield put(actions.setCsrfToken(csrfToken));
      Scheduler.StartClock();
    }
    catch (err) {
      console.log("authLoadCsrfSaga: Authorization failed for given token:", (authStore.authToken || {}).token)
      console.log(err)
      history.push("/error/error-v4")
    }


  });
  yield takeLatest(actionTypes.AUTH_REFRESH_CSRF, function* authLoadCsrfSaga() {
    try {
      const { data: csrfToken } = yield authCrud.getCsrfToken();
      yield put(actions.setRefreshCsrfToken(csrfToken));
    }
    catch { }
  });
  yield takeLatest([actionTypes.AUTH_SET_CSRF_TOKEN, actionTypes.AUTH_REFRESH_PROFILE], function* authLoadProfileSaga() {
    try {
      const authStore = yield select(getAuth);
      if (authStore) {
        if (authStore.authToken.token && authStore.csrfToken.token) {
          const { data: userProfile } = yield authCrud.getUserProfile();
          userProfile.shouldRedirectToUserPageForConsent = isOpenliEnabled && !userProfile.termsofuseconsent && moment(addDays(new Date(userProfile.dateredirectedtoconsent), Number(process.env.REACT_APP_OPENLI_DATE_REDIRECTING))).toDate() < new Date();

          yield put(actions.setUserProfile(userProfile));
          yield put(actions.loadCompleted());
          SystemHelper.appcuesUpdate();

          if (delightedEnabled) {
            SystemHelper.delightedScript();
          }
        }
      }
    }
    catch (err) {
      console.log("Error in contractLoadUserSaga:", err);
    }

  });
  yield takeLatest(actionTypes.AUTH_LOGOUT_START, function* authLogoutSaga() {
    try {
      Scheduler.StopClock();
      const authStore = yield select(getAuth);
      const loginMethodUsed = authStore?.authToken?.loginMethod;

      yield put(companyDuck.actions.logout());
      yield put(companiesDuck.actions.logout());
      yield put(contractDuck.actions.logout());
      yield put(contractsDuck.actions.logout());
      yield put(projectsDuck.actions.logout());
      yield put(projectDuck.actions.logout());
      yield put(applicationDuck.actions.logout());
      yield put(actions.logout());
      yield put(notificationsDuck.removeSnackbar());
      // setTimeout(() => {
      //   window.location.href = toAbsoluteUrl("/auth/login");
      // }, 400);
      // if (authStore?.authToken?.loginMethod === 'SUPERTOKEN') {
      //   yield signOut();
      //   yield put(actions.logoutCompleted());
      // }
      // else 
      if (loginMethodUsed === 'FDCONNECTOR') {
        window.location.href = process.env.REACT_APP_AUTH0_LOGOUT_URL;
      }
      else {
        yield put(actions.logoutCompleted());
      }

      if(process.env.REACT_APP_SSO_METHOD === 'DISCOVER' && loginMethodUsed === 'SUPERTOKEN'){
        window.location.reload();
      }
    }
    catch (err) {
      console.log("Error in authLogoutSaga:", err);
    }
  });
}
