import React from 'react';
import * as fileCrud from '../../../../crud/file.crud';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import Document from '../../_CustomComponents/DocumentV2';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { withSnackbar } from 'notistack';

class NonConformanceDocument extends BaseDocument {
    constructor(props) {
        super(props);
        this.editorFormConfig = {
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'assistancetestserchdefect' },
                { resource: 'CONTRACT.WF.CONFORMANCE.FIELD.NOTIFICATION', dataField: 'inspectiondetails', editorType: 'dxHtmlEditor' },
            ]
        };
    }


    renderForm = () => {
        return (
            <>
                <BiDynamicForm
                    item={this.props.notification}
                    config={this.editorFormConfig}
                    valueChangeHandler={this.props.fieldChanged}
                    customFields={(this.props.customFields || {}).COMMUNICATION}
                    assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                />
            </>
        );

    }
    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.inspectiondefectid, data, config)
    }



    render() {
        const documentIsFinal = this.props.notification.showtasknotification === 1;
        return (
            <Document
                key={documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                title={this.props.title}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.renderForm()}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.notification.assetid}
            />
        );
    }
}

export default withSnackbar(NonConformanceDocument);
