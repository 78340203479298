import {placeholderTypes} from "../../Types/Placeholder";
const placeholders =  [
    {
        replacementKey: 'Resources', values:[ ],
        type:placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'ActionReminders', values:
            [
                { id: 1, isActive: true, field: 'Supplier', display: '$SUPPLIER_NAME RFI/TQ awaiting Reply' },
                { id: 2, isActive: true, field: 'PM', display: '$PM_NAME RFI/TQ awaiting Reply' },
            ],
        type:placeholderTypes.ACTION_REMINDERS
    },
];
export default placeholders;