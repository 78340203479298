const template = {
    name: "Default NEC3 TSC v2.6 30/01/2024",
    isSystemDefault: true,
    isGlobal: true,
    companies: [],
    resources: [
        {
            id: 1,
            type: "TEXT",
            key: "SUPPLIER_NAME",
            value: "Contractor"
        },
        {
            id: 2,
            type: "TEXT",
            key: "SUPPLIER_ABBREVIATION",
            value: "CON"
        },
        {
            id: 3,
            type: "TEXT",
            key: "PM_NAME",
            value: "Service Manager"
        },
        {
            id: 4,
            type: "TEXT",
            key: "PM_ABBREVIATION",
            value: "SM"
        },
        {
            id: 5,
            type: "TEXT",
            key: "SUPERVISOR_NAME",
            value: "Supervisor"
        },
        {
            id: 6,
            type: "TEXT",
            key: "SUPERVISOR_ABBREVIATION",
            value: "SUP"
        },
        {
            id: 7,
            type: "TEXT",
            key: "CLIENT_NAME",
            value: "Employer"
        },
        {
            id: 8,
            type: "NUMBER",
            key: "SUPPLIER_REPLY_PERIOD",
            value: 7
        },
        {
            id: 9,
            type: "NUMBER",
            key: "PM_REPLY_PERIOD",
            value: 14
        },
        {
            id: 10,
            type: "NUMBER",
            key: "QUOTE_DURATION",
            value: 21
        },
        {
            id: 13,
            type: "NUMBER",
            key: "REQUOTE_DURATION",
            value: 21
        },
        {
            id: 11,
            type: "NUMBER",
            key: "QUOTE_RESPONSE",
            value: 14
        },
        {
            id: 14,
            type: "NUMBER",
            key: "REQUOTE_RESPONSE",
            value: 14
        },
        {
            id: 12,
            type: "NUMBER",
            key: "VARIATION_RESPONSE",
            value: 7
        },
        {
            id: 18,
            type: "TEXT",
            key: "COMPLETION_DATE",
            value: "end of service period"
        }
    ],
    workflowGroups: [
        {
            id: 101,
            title: "Risks",
            workflows: [
                {
                    id: 1,
                    title: "$PM_NAME Risks",
                    notificationName: "Risk",
                    abbreviation: "$PM_ABBREVIATION-Risk-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    actioningParty: "PM",
                    resolveActioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Support\"]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
                        resolveActionTab: {
                            resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        }
                    }
                },
                {
                    id: 2,
                    title: "$SUPPLIER_NAME Risks",
                    notificationName: "Risk",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Risk-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    actioningParty: "PM",
                    resolveActioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Support\"]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
                        resolveActionTab: {
                            resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        }
                    }
                },
                {
                    id: 3,
                    title: "Risk Register",
                    notificationName: "Risk",
                    abbreviation: "Risk",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "MeetingRequiredLabel",
                            display: "Is Risk Reduction Meeting required?",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "NotificationClause",
                            display: "You are given risk of a matter which could",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "RiskItemsLabel",
                            display: "You are given risk of a matter which could",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Pm",
                            display: "$PM_ABBREVIATION Risks awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Risks awaiting reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypes",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "riskincreaseprice",
                            display: "Increase the total of the Prices",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "riskservicetiming",
                            display: "Interfere with the timing of the service",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "riskserviceperformance",
                            display: "Impair the effectiveness of the service",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: false,
                            field: "riskincreasecontractorcost",
                            display: "Increase the $SUPPLIER_NAME's cost",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: false,
                            field: "riskaffectmodel",
                            display: "Adversely affect the creation or use of the Information Model",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "riskotherpartner",
                            display: "Affect the achievement of another Partner's objectives",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: false,
                            field: "riskcontractbreach",
                            display: "Affect the achievement of another Partner’s objectives",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "riskdelaymilestone",
                            display: "Delay meeting a Key Date",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: false,
                            field: "riskincreasedefinedcost",
                            display: "Increase the Defined Cost of expending any Provisional Sums",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: false,
                            field: "riskaffectsafety",
                            display: "Adversely affect the $SUPPLIER_NAME’s ability to safely carry out the works",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "MeetingOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "0",
                            display: "Meeting Not Required",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Meeting Required - Next Scheduled Risk Reduction Meeting",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Meeting Required - You are instructed to attend a meeting as below",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypesSupplier",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "riskincreaseprice",
                            display: "Increase the total of the Prices",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "riskservicetiming",
                            display: "Interfere with the timing of the service",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "riskserviceperformance",
                            display: "Impair the effectiveness of the service",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "riskincreasecontractorcost",
                            display: "Increase the $SUPPLIER_NAME's total cost",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: false,
                            field: "riskaffectmodel",
                            display: "Adversely affect the creation or use of the Information Model",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "riskotherpartner",
                            display: "Affect the achievement of another Partner's objectives",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: false,
                            field: "riskcontractbreach",
                            display: "Affect the achievement of another Partner’s objectives",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "riskdelaymilestone",
                            display: "Delay meeting a Key Date",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: false,
                            field: "riskincreasedefinedcost",
                            display: "Increase the Defined Cost of expending any Provisional Sums",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: false,
                            field: "riskaffectsafety",
                            display: "Adversely affect the $SUPPLIER_NAME’s ability to safely carry out the works",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 102,
            title: "Instructions",
            workflows: [
                {
                    id: 4,
                    title: "$PM_NAME Instruction",
                    notificationName: "Instruction",
                    abbreviation: "$PM_ABBREVIATION-Instruction-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "InstructionTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "14.3 to change the Service Information",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "17.1 to resolve an ambiguity or inconsistency",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "18.1 to change the Service Information to remove something which is illegal or impossible",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "22.1 to submit a revised programme",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "23.1 to submit particulars of the design of an item of Equipment",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "24.2 to remove an employee",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "26.4 to submit proposed contract data for a Subcontractor (main Options C and E)",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "32.2 to stop, not start or restart or start work",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "43.1 to change the Service Information after accepting a proposal not to correct a Defect",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "86.1 to submit insurance policies",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "92.2 to remove Equipment, Plant and Materials and assign the benefit of a subcontract",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "Other instruction",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 114,
            title: "Task Orders",
            workflows: [
                {
                    id: 38,
                    title: "$PM_NAME Task Orders",
                    notificationName: "Task Order",
                    abbreviation: "$PM_ABBREVIATION-Task-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Note",
                            display: "Further to our consultation, under option X19 you are instructed to carry out the following Task",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "DescriptionLabel",
                            display: "Detailed description of the work in the task",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "ItemsListLabel",
                            display: "Items taken from price list",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TotalListPriceLabel",
                            display: "Price list total",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "CompensationDescriptionLabel",
                            display: "Items assessed in the same way as a compensation event",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "TotalAssessedLabel",
                            display: "Assessed list total",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "TotalPriceLabel",
                            display: "Total of prices",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "StartDateLabel",
                            display: "Start Date",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "CompletionDateLabel",
                            display: "Completion Date",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "DelayAmountLabel",
                            display: "Amount of delay damages for the late completion of the task",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "DelayPaymentPeriodLabel",
                            display: "Delay damages period",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 103,
            title: "Compensation Events",
            workflows: [
                {
                    id: 5,
                    title: "$PM_NAME Compensation Events",
                    notificationName: "Compensation Event",
                    abbreviation: "$PM_ABBREVIATION-Compensation-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    quoteNotificationName: "Quote",
                    quoteReferencePrefix: "Quote-",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 6,
                    title: "$PM_NAME Proposed Instructions",
                    notificationName: "Proposed Instruction",
                    abbreviation: "$PM_ABBREVIATION-Instruction-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    quoteNotificationName: "Quote",
                    quoteReferencePrefix: "Quote-",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 7,
                    title: "$SUPPLIER_NAME Compensation Events",
                    notificationName: "Compensation Event",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Compensation-",
                    notify: true,
                    replyEnabled: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "VARIATION_RESPONSE",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    quoteNotificationName: "Quote",
                    quoteReferencePrefix: "Quote-",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                },
                {
                    id: 8,
                    title: "$PM_NAME Assessments",
                    notificationName: "Assessment",
                    abbreviation: "$PM_ABBREVIATION-Assessment-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 9,
                    title: "$PM_NAME Extensions",
                    notificationName: "Extension",
                    abbreviation: "$PM_ABBREVIATION-Extension-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ORIGINAL_DATE",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 10,
                    title: "$SUPPLIER_NAME Extensions",
                    notificationName: "Extension",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Extension-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ORIGINAL_DATE",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 11,
                    title: "Master Quote Register",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null"
                    }
                },
                {
                    id: 12,
                    title: "Master CE Register",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null"
                    }
                },
                {
                    id: 13,
                    title: "Implemented CE Register",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmAssumptionLabel",
                            display: "The quotation is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmAssumptionNote",
                            display: "You are instructed to provide a quotation by $date",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierDescNote",
                            display: "You are notified of the following event as a compensation event",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "ReplyAcceptedCommentLabel",
                            display: "You are notified that the event is a compensation event",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "ReplyRejectedCommentLabel",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ReplyAcceptedAssumptionLabel",
                            display: "The quotation is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "QuoteNote",
                            display: "We submit our quotation for compensation event $reference",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "ExtensionPMLabel",
                            display: "We require an extension to the time to reply as follows",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ExtensionSupplierLabel",
                            display: "We require an extension to the time to reply as follows",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "AssessmentDetailLabel",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ProposedInstructionNote",
                            display: "You are instructed to provide a quotation by $date",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "ProposedInstructionLabel",
                            display: "The quotation is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "ReplyRejectedOptionsLabel",
                            display: "You are notified that the event is not a compensation event as it",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "QuoteCostEffectLabel",
                            display: "Change to the Prices",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "QuoteDateEffectLabel",
                            display: "Delay",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "QuoteDaysEffectLabel",
                            display: "The length of time that the $COMPLETION_DATE is delayed",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "AssessmentCostEffectLabel",
                            display: "Change in the Total of the Prices",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "AssessmentDaysEffectLabel",
                            display: "The length of time that the $COMPLETION_DATE is delayed",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "QuoteProgrammeAttachLabel",
                            display: "Are you attaching a programme or plan showing the effect against the last programme or plan with this quotation?",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "QuoteNoteProposedInstruction",
                            display: "We submit our quotation for proposed compensation event $reference",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmCompensationAQ",
                            display: "Compensation Events notified by $PM_ABBREVIATION awaiting quotes",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmCompensationARQ",
                            display: "Compensation Events notified by $PM_ABBREVIATION awaiting revised quotes",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "PmCompensationAPMRQ",
                            display: "Compensation Events notified by $PM_ABBREVIATION awaiting response to quotes",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "PmCompensationAPMA",
                            display: "Compensation Events notified by $PM_ABBREVIATION awaiting $PM_ABBREVIATION Assessment",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SupCompensationAQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting quotes",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SupCompensationARQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting revised quotes",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "SupCompensationAPMR",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION response",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "SupCompensationAPMRQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting response to quotes",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "SupCompensationAPMA",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "PmProposedAQ",
                            display: "Proposed changes awaiting quotes",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "PmProposedARQ",
                            display: "Proposed changes awaiting revised quotes",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "PmProposedAPMRQ",
                            display: "Proposed changes awaiting response to quotes",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "PmProposedAPMA",
                            display: "Proposed changes awaiting $PM_ABBREVIATION Assessment",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "PmExtension",
                            display: "$PM_ABBREVIATION Quote Response Extensions awaiting Acceptance",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "SupExtension",
                            display: "$SUPPLIER_NAME Quote Extensions awaiting Acceptance",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "CompensationClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "60.1(1) An instruction to change the Service Information",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "60.1(2) Employer does not provide right of access",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "60.1(3) Employer does not provide something which he is to provide",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "60.1(4) An instruction to stop or not to start work",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "60.1(5) Employer or Others do not work within the Accepted Plan or Service Information",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "60.1(6) Service Manager does not reply to a communication",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "60.1(7) Service Manager changes a decision",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "60.1(8) Service Manager withholds an acceptance",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "60.1(9) A test or inspection causes unnecessary delay",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "60.1(10) A change to the Affected Property",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "60.1(11) Employer does not provide things for tests and inspections",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "60.1(12) An event which is the Employer’s risk",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "60.1(13) Service Manager corrects an assumption",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "60.1(14) A breach of contract by the Employer",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "X19.10(1) An instruction to change a Task Order",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "X19.10(2) Contractor receives a Task Order after the starting date stated in the Task Order",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "X19.10(3) Employer does not provide right of access",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "X19.10(4) Employer does not provide something which he is to provide",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "X19.10(5) Employer or Others do not work within the Accepted Task Order Programme or Service Information",
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "X19.10(6) A prevention event occurs",
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "X19.10(7) Task Completion Date is later than the end of the service period",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "faultpscp",
                            display: "arises from a fault of the Contractor",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "notexpectnothappen",
                            display: "has not happened and is not expected to happen",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "noteffectcostcompletion",
                            display: "has no effect on Defined Cost, Completion or meeting a Key Date or",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "statedcontract",
                            display: "is not one of the compensation events stated in this contract",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "CompensationStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "APMA",
                            display: "Quote Not Accepted - $PM_ABBREVIATION to make own Assessment",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ARQ",
                            display: "Quote Not Accepted - $SUPPLIER_NAME to resubmit",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "AM",
                            display: "$PM_ABBREVIATION Assessment Made",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "QA",
                            display: "Quote Accepted",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "APMRQ",
                            display: "Awaiting $PM_ABBREVIATION Response to quote",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "APMR",
                            display: "Awaiting $PM_ABBREVIATION Response to CEN",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "AQ",
                            display: "Awaiting Quote",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NC",
                            display: "Not Communicated",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "R",
                            display: "Not a Compensation Event",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "DNG",
                            display: "Decision Not Given",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "QuoteStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "0",
                            display: "Not Accepted, $SUPPLIER_NAME to resubmit - proceed with works",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Accepted, proceed with works",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Awaiting Response",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "3",
                            display: "Not Accepted, $PM_ABBREVIATION to assess - proceed with works",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "4",
                            display: "Not Accepted, Other quote approved",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "5",
                            display: "Not Accepted, instruction will not be given - do not proceed with works",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "none",
                            display: "Not Communicated",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "InstructionReplyProceedOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "61.1",
                            display: "$SUPPLIER_NAME is instructed to proceed with works",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "61.2",
                            display: "$SUPPLIER_NAME is instructed to NOT proceed with works",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "QuoteProgrammeImpactOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "noeffect",
                            display: "There is no effect on the $COMPLETION_DATE",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "mitigated",
                            display: "There is an effect on the $COMPLETION_DATE but this has been mitigated by measures as detailed",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "notmitigated",
                            display: "There is an effect on the $COMPLETION_DATE which we are unable to mitigate",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "TaskOrderOptionEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "TaskOrderOptionEnabled",
                            display: "Active-Inactive state indicates weather Task-Order can be selected in CEN communications",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 104,
            title: "Plan & Programme",
            workflows: [
                {
                    id: 14,
                    title: "$SUPPLIER_NAME Plan/Programme",
                    notificationName: "Plan/Programme",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Plan/Prog-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "ReasonLabel",
                            display: "Reason for any changes",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ChangeLabel",
                            display: "Change in any other important milestone in period and reasons why",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "MainProgressLabel",
                            display: "Main progress achieved in period",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "KeyActivitiesLabel",
                            display: "Key activities forecast before the next submission",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SummaryLabel",
                            display: "Summary of critical path",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ChangesLabel",
                            display: "Main changes made in period",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "CompensationLabel",
                            display: "Compensation events added this period",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NotificationClause",
                            display: "Please find attached Task Order Programme or Plan for acceptance",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ReplyRejectReasonLabel",
                            display: "The $SUPPLIER_NAME’s Task Order Programme or Plan is not accepted for the following reasons",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "ReplyNotificationClause",
                            display: "In accordance with the conditions of contract you are notified that the $SUPPLIER_NAME’s Task Order Programme or Plan",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ReplyNotificationClause",
                            display: "You are notified that the $SUPPLIER_NAME’s Task Order Programme/Plan",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "ReferenceLabel",
                            display: "TO Programme or Plan Reference",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME's Plan/Programme Submission awaiting Acceptance",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "plansnotpractical",
                            display: "The $SUPPLIER_NAME’s plans which it shows are not practical",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "missingcontractrequires",
                            display: "It does not show the information which this contract requires",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "notrepresentplan",
                            display: "It does not represent the $SUPPLIER_NAME’s plan realistically (Plan only)",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "notcomply",
                            display: "It does not comply with the Service Information",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "DashboardDisplayPlannedCompletionOnLatest",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "DashboardDisplayPlannedCompletionOnLatest",
                            display: "Active-Inactive state indicates weather (Planned Completion shown on the latest Accepted Programme) is displayed on dasboard",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "Fields",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "PreviousPlanned",
                            display: "Previous Planned Completion",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "PlannedCompletion",
                            display: "Date of Planned Completion this period",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: false,
                            field: "Changes",
                            display: "Main changes made in period",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Reason",
                            display: "Reason for any changes since last period",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "Change",
                            display: "Change in any other important project milestone in period and reasons why",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "MainProgress",
                            display: "Main progress achieved in period",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: false,
                            field: "KeyActivities",
                            display: "Key activities forecast in next reporting period",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: false,
                            field: "Summary",
                            display: "Summary of critical path",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "Compensation",
                            display: "Compensation events added to programme/plan this period",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: false,
                            field: "",
                            display: "",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 105,
            title: "Notifications",
            workflows: [
                {
                    id: 15,
                    title: "$PM_NAME Notifications",
                    notificationName: "Notification",
                    abbreviation: "$PM_ABBREVIATION-Notification-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 16,
                    title: "$SUPPLIER_NAME Notifications",
                    notificationName: "Notification",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Notification-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "PmClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "13.2 of change of address for communications",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "13.5 of an agreement to extend the period for reply for a communication (excluding compensation event periods)",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "17.1 of an ambiguity or inconsistency",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "18.1 of an illegal or impossible requirement",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "25.2 of costs incurred by the Employer as a result of the Contractor not providing facilities and other things",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "27.2 to provide access to the work being done and Plant and Materials being stored",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "41.1 that Plant and Materials have passed tests before delivery",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "90.1 of a wish to terminate the Contractor’s obligation to Provide the Works",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "90.1 of a termination certificate",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "91.2 of a default",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "91.3 of a default",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "X18.5 of a matter for which the Contractor is liable",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "Y2.3 to pay less than the notified sum",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "Other notification",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "SupplierClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "13.2 of change of address for communications",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "17.1 of an ambiguity or inconsistency",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "90.1 of a wish to terminate the Contractor’s obligation to Provide the Works",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "Y2.3 to pay less than the notified sum",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "Other notification",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 106,
            title: "Submissions",
            workflows: [
                {
                    id: 18,
                    title: "$SUPPLIER_NAME Submissions",
                    notificationName: "Submission",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Submission-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 49,
                    title: "$PM_NAME Submissions",
                    notificationName: "Submission",
                    abbreviation: "$PM_ABBREVIATION-Submission-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "IssueClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "20.4 of forecasts of the total Defined Costs (main Options C and E)",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "20.5 of forecasts of the final total of the Prices (main Option A)",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "23.1 of particulars of design of Equipment",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "24.1 of the name, qualifications and experience of a proposed replacement person",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "26.2 of the name of each proposed Subcontractor",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "26.3 of the conditions of contract for each subcontract",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "26.4 of proposed contract data for each subcontract (main Options C and E)",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "43.1 of a proposal to accept a Defect",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "54.2 of a revised Price List (main Options A and C)",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "85.1 of certificates of insurance",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "X4.1 of the provision of a parent company guarantee",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "X13.1 of a performance bond",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "X20.2 of a report of performance",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "X20.3 of proposals for improving performance",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "Y1.4 of banking arrangements for the Project Bank Account",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "Y1.6 of a proposal to add a Supplier to the Named Suppliers",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "Y1.9 of a signed Authorisation",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "Other submission",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Submission awaiting Acceptance",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "IssueClausesPM",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "86.1 of certificates of insurance provided by the Employer",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 107,
            title: "Submissions (with quote)",
            workflows: [
                {
                    id: 19,
                    title: "$SUPPLIER_NAME Submissions (wq)",
                    notificationName: "Submission",
                    abbreviation: "CON-SubmissionWQ-",
                    notify: true,
                    replyEnabled: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CostEffectLabel",
                            display: "Change in the Prices",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "DaysEffectLabel",
                            display: "The length of time that the $COMPLETION_DATE is delayed",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "WarningBanner",
                            display: "Do not use this workflow for a Task Order quotation.",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ProposalClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "43.1 a quote for accepting a Defect",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Proposals awaiting Acceptance",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 108,
            title: "Payments",
            workflows: [
                {
                    id: 20,
                    title: "$PM_NAME Payment Certificates",
                    notificationName: "Payment Certificate",
                    abbreviation: "$PM_ABBREVIATION-Payment-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 21,
                    title: "$SUPPLIER_NAME Payment Applications",
                    notificationName: "Payment Application",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Payment-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CumulativePriceLabel",
                            display: "Price for Services Provided to Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlusAmountsSupplierLabel",
                            display: "Plus other amounts to be paid to the $SUPPLIER_NAME",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "LessAmountsSupplierLabel",
                            display: "Less amounts to be paid by or retained from the $SUPPLIER_NAME",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TaxSupplierLabel",
                            display: "Tax which the law requires the Employer to pay to the $SUPPLIER_NAME",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "WarningBanner",
                            display: "The first assessment date is no later than the assessment interval after the starting date. Subsequent assessment dates are the assessment interval after the previous assessment. Assessments continue until 4 weeks after the issue of the Defects Certificate with an additional assessment at Completion.",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SummaryDetailsLabel",
                            display: "Summary details",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "TaxEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "TaxEnabled",
                            display: "Active-Inactive state indicates tax field will be included in notification forms",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 109,
            title: "Inspections and Defects",
            workflows: [
                {
                    id: 23,
                    title: "$PM_NAME Defect Notifications",
                    notificationName: "Defect Notification",
                    abbreviation: "$PM_ABBREVIATION-Defect-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    actioningParty: "PM",
                    resolveActioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
                        resolveActionTab: {
                            resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                },
                {
                    id: 24,
                    title: "$SUPPLIER_NAME Defect Notifications",
                    notificationName: "Defect Notification",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Defect-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    actioningParty: "PM",
                    resolveActioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
                        resolveActionTab: {
                            resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                },
                {
                    id: 25,
                    title: "$SUPPLIER_NAME Tests & Inspections",
                    notificationName: "Test / Inspection",
                    abbreviation: "Test-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "NOTIFICATION_DATE",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 26,
                    title: "Master Defect Register",
                    notificationName: "Defect Notification",
                    abbreviation: "Defect",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "InstructionClauseLabel",
                            display: "You are instructed to search for a Defect as follows",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SupervisorClauseLabel",
                            display: "You are notified of the following Defect",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierClauseLabel",
                            display: "You are notified of the following Defect",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TestsClauseLabel",
                            display: "You are notified of the following test or inspection",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "DefectsWarningBanner",
                            display: "A Defect is not notified after the end of the service period.",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Instruction",
                            display: "$PM_NAME Instruction to Search awaiting sign off",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SuperDefect",
                            display: "$PM_NAME Notification of Defect awaiting acceptance",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Inspection",
                            display: "$SUPPLIER_NAME Test/Inspection Notification awaiting sign off",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 110,
            title: "Delegated Actions",
            workflows: [
                {
                    id: 27,
                    title: "$PM_NAME Delegated Actions",
                    notificationName: "Delegated Action",
                    abbreviation: "$PM_ABBREVIATION-Action-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "DelegationClause",
                            display: "You are notified that the following actions",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 111,
            title: "Records",
            workflows: [
                {
                    id: 30,
                    title: "$PM_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$PM_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 31,
                    title: "$SUPPLIER_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 32,
                    title: "$SUPPLIER_NAME Site Diary",
                    notificationName: "Site Diary",
                    abbreviation: "Diary-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 33,
                    title: "Weather",
                    notificationName: "Weather",
                    abbreviation: "Weather-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: []
        },
        {
            id: 112,
            title: "RFI / TQ",
            workflows: [
                {
                    id: 34,
                    title: "$SUPPLIER_NAME RFI / TQ",
                    notificationName: "RFI / TQ",
                    abbreviation: "RFI/TQ-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME RFI/TQ awaiting Reply",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 113,
            title: "Contract Information",
            workflows: [
                {
                    id: 39,
                    title: "Documents",
                    notificationName: "Document",
                    abbreviation: "Documents-",
                    notify: true,
                    actioningParty: "PM",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: []
                }
            ]
        }
    ]
};
export default template;