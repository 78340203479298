import React from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { SelectBox } from 'devextreme-react';
import Box, { Item } from 'devextreme-react/box';
import { Validator, RequiredRule } from 'devextreme-react/validator';
import { DateBox, NumberBox } from 'devextreme-react';
import { dxDateBoxProps, dxNumberBoxProps } from '../../shared/config/DxFormProperties';
import { Button as DxButton } from 'devextreme-react/button';
import * as activityScheduleCrud from '../../crud/activitySchedule.crud';
import * as contractOtherPriceCrud from '../../crud/contractOtherPrice.crud';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import Common from '../../shared/common';
import ContractSecondaryDateDetails from '../../pages/admin/Contracts/Contract/ContractSecondaryDateDetails';
import ContractSecondaryPriceDetails from '../../pages/admin/Contracts/Contract/ContractSecondaryPriceDetails';


class BiDynamicPriceDateFields extends React.Component {
    constructor(props) {
        super(props);
        this.isContractOtherDatesEnabled = Boolean(this.props.contract.section);
        this.isContractOtherPricesEnabled = Boolean(this.props.contract.otherprice) && process.env.REACT_APP_SECONDARY_PRICES_ENABLED === "true";;

        //ADDITIONAL FILTER MAY BE GIVEN (Quotation WF)
        if (this.props.priceDateSectionFilters) {
            //CHECK IF THERE ARE ANY FILTERS WITH "sourcedrecordid" EXIST
            this.activeAdditionalFilter = this.props.priceDateSectionFilters.filter(x => !!x.sourcedrecordid);
        }

        let otherDateChanges = [];
        let otherPriceChanges = [];
        if (this.isContractOtherDatesEnabled && props.item?.otherdatechanges) {
            otherDateChanges = props.item.otherdatechanges.map(dateChange => ({
                ...dateChange,
                currentdate: new Date(dateChange.currentdate),
                // currentdatedisplay: moment(new Date(dateChange.currentdate)).format('DD/MM/YYYY'),
                // newdate: new Date(dateChange.newdate),
                // newdatedisplay: moment(new Date(dateChange.newdate)).format('DD/MM/YYYY')
            }));
        }
        if (this.isContractOtherPricesEnabled && props.item?.otherpricechanges) {
            otherPriceChanges = [...props.item.otherpricechanges];
        }

        this.state = {
            isLoading: true,
            otherDates: [],
            otherDateChanges,
            otherPriceChanges,
            viewDetailsSelectedDateItem: null,
            viewDetailsSelectedPriceItem: null,
            isSettingOtherDates: false
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.priceDateSectionFilters !== this.props.priceDateSectionFilters) {
            const activeAdditionalFilter = this.props.priceDateSectionFilters.filter(x => !!x.sourcedrecordid);
            this.activeAdditionalFilter = activeAdditionalFilter;
            this.setState({ otherDateChanges: [] });
        }
    }
    addDateChange = (e) => {
        const item = e.component.option('selectedItem');
        if (item) {
            const newOtherDateChanges = [...this.state.otherDateChanges]
            newOtherDateChanges.push({
                name: item.name,
                activityreferenceid: item.activityscheduleid,
                currentdate: item.actiondate,
                actiontype: item.actiontype,
                sourcedmodel: item.sourcedmodel,
                originalreference: item.originalreference,
                lastamendingreference: item.lastamendingreference
                // currentdatedisplay: moment(item.actiondate).format('DD/MM/YYYY'),
            });
            this.setState({ otherDateChanges: newOtherDateChanges }, this.updateParentDates);
        }
    }
    setDateItem = (activityreferenceid, value) => {
        const newOtherDateChanges = [...this.state.otherDateChanges];
        const editedItem = newOtherDateChanges.find(dt => dt.activityreferenceid === activityreferenceid);
        editedItem.daychange = value;
        // editedItem.newdatedisplay = moment(editedItem.currentdate).add(value, 'days').format('DD/MM/YYYY');
        this.setState({ otherDateChanges: newOtherDateChanges }, this.updateParentDates);
    }
    removeDateItem = (activityreferenceid) => {
        const newOtherDateChanges = [...this.state.otherDateChanges];
        const removedIndex = newOtherDateChanges.findIndex(dt => dt.activityreferenceid === activityreferenceid);
        newOtherDateChanges.splice(removedIndex, 1);
        this.setState({ otherDateChanges: newOtherDateChanges }, this.updateParentDates);
    }
    updateParentDates = () => {
        this.props.onValueChanged(null, "otherdatechanges", this.state.otherDateChanges)
    }
    loadDateChangeOptions = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { data: datesOptions } = await activityScheduleCrud.getToSelectList(this.props.contract.contractid);
                const selectedActivityIds = this.state.otherDateChanges.map(dateChange => dateChange.activityreferenceid);
                let filteredFromSelected = null;

                //ADDITIONAL FILTER MAY BE GIVEN (Quotation WF)
                //CHECK IF THERE ARE ANY FILTERS WITH "sourcedrecordid" EXIST
                if (this.activeAdditionalFilter.length > 0) {
                    this.props.enqueueSnackbar("Date selection is limited to related notice!", { key: "DateSelectionsLimited", variant: "warning", autoHideDuration: 5000, preventDuplicate: true });

                    // Apply each filter found in this.activeAdditionalFilter
                    filteredFromSelected = datesOptions.filter(date =>
                        this.activeAdditionalFilter.some(filter =>
                            date.sourcedmodel === filter.sourcedmodel && date.sourcedrecordid === filter.sourcedrecordid
                        )
                    );

                    filteredFromSelected = filteredFromSelected.filter(date => !selectedActivityIds.includes(date.activityscheduleid));
                } else {
                    filteredFromSelected = datesOptions.filter(date => !selectedActivityIds.includes(date.activityscheduleid));
                }

                // Check if any item in this.activeAdditionalFilter has sourcedmodel equal to 'TaskOrder'
                const includesTaskOrder = this.activeAdditionalFilter.some(x => x.sourcedmodel === 'TaskOrder');

                // // If none of the items in this.activeAdditionalFilter have sourcedmodel equal to 'TaskOrder', apply the filter
                if (!includesTaskOrder) {
                    filteredFromSelected = filteredFromSelected.filter(x => x.sourcedmodel !== 'TaskOrder');
                }

                // Check if any item in this.activeAdditionalFilter has sourcedmodel equal to 'ProjectOrder'
                const includesProjectOrder = this.activeAdditionalFilter.some(x => x.sourcedmodel === 'ProjectOrder');

                // // If none of the items in this.activeAdditionalFilter have sourcedmodel equal to 'ProjectOrder', apply the filter
                if (!includesProjectOrder) {
                    filteredFromSelected = filteredFromSelected.filter(x => x.sourcedmodel !== 'ProjectOrder');
                }

                filteredFromSelected = filteredFromSelected/*.filter(x => !!x.sourcedmodel)*/.map(d => ({
                    ...d,
                    actiondate: new Date(d.actiondate),
                    actiondatedisplay: moment(new Date(d.actiondate)).format('DD/MM/YYYY')
                }));

                // this.setState({ otherDates: datesOptions }, () => {
                //     resolve(filteredFromSelected);
                // });

                // this.setState({ isSettingOtherDates: true });

                // //this.setState({ otherDates: datesOptions });
                // this.state.otherDates = datesOptions;

                // this.setState({ isSettingOtherDates: false });

                resolve(filteredFromSelected);
            } catch (error) {
                reject(error);
            }
        });
    }
    addPriceChange = (e) => {
        const item = e.component.option('selectedItem');
        if (item) {
            const newOtherPriceChanges = [...this.state.otherPriceChanges]
            newOtherPriceChanges.push({
                name: item.name,
                currency: item.currency,
                contractotherpriceid: item.contractotherpriceid,
                currentprice: item.price,
            });
            this.setState({ otherPriceChanges: newOtherPriceChanges }, this.updateParentPrices);
        }
    }
    setPriceItem = (contractotherpriceid, value) => {
        const newOtherPriceChanges = [...this.state.otherPriceChanges];
        const editedItem = newOtherPriceChanges.find(dt => dt.contractotherpriceid === contractotherpriceid);
        editedItem.pricechange = value;
        this.setState({ otherPriceChanges: newOtherPriceChanges }, this.updateParentPrices);
    }
    removePriceItem = (contractotherpriceid) => {
        const newOtherPriceChanges = [...this.state.otherPriceChanges];
        const removedIndex = newOtherPriceChanges.findIndex(dt => dt.contractotherpriceid === contractotherpriceid);
        newOtherPriceChanges.splice(removedIndex, 1);
        this.setState({ otherPriceChanges: newOtherPriceChanges }, this.updateParentPrices);
    }
    updateParentPrices = () => {
        this.props.onValueChanged(null, "otherpricechanges", this.state.otherPriceChanges)
    }
    loadPriceChangeOptions = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { data: pricesOptions } = await contractOtherPriceCrud.getToSelectList(this.props.contract.contractid)
                const selectedPriceIds = this.state.otherPriceChanges.map(priceChange => priceChange.contractotherpriceid);
                let filteredFromSelected = pricesOptions.filter(price => !selectedPriceIds.includes(price.contractotherpriceid));
                resolve(filteredFromSelected);
            } catch (error) {
                reject(error);
            }
        });
    }
    viewDateChangeLogClick = async (data) => {
        const clonedItem = {
            activityscheduleid: data.activityreferenceid,
            name: data.name
        };
        this.setState({ viewDetailsSelectedDateItem: clonedItem })
    }
    viewPriceChangeLogClick = async (data) => {
        const clonedItem = {
            contractotherpriceid: data.contractotherpriceid,
            name: data.name,
            currency: data.currency
        };
        this.setState({ viewDetailsSelectedPriceItem: clonedItem })
    }
    render() {
        if (!this.isContractOtherDatesEnabled && !this.isContractOtherPricesEnabled) {
            //both are disabled return null
            return null;
        }
        return (
            <>
                {
                    (this.props.secondaryPriceSectionEnabled && this.isContractOtherPricesEnabled) && (
                        <div key="PriceChangesFieldGroup" style={{ marginTop: 20 }}>
                            <div className="dx-fieldset-header" style={{ borderBottom: '1px solid #ddd' }}>
                                <span className="dx-widget dx-form-group-caption" style={{ marginBottom: 5 }}>
                                    <FormattedMessage id="GENERAL.FORMS.LABEL_AFFECTED_PRICES" />
                                </span>
                            </div>
                            <div className="dx-widget dx-form-group-content dx-form-group-with-caption dx-form-group-content">
                                {!this.props.isReply && (
                                    <div className="dx-field" key="OtherPriceOptions" >
                                        <Box direction="row" width="100%">
                                            <Item ratio={0} baseSize={200}>
                                                <div className="dx-field-label" style={{ width: '100%', whiteSpace: 'normal' }}>
                                                    <FormattedMessage id="GENERAL.FORMS.LABEL_SELECT_AFFECTED_PRICES" />
                                                </div>
                                            </Item>
                                            <Item ratio={1} baseSize={0}>
                                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                                                    <div className="dx-field-value" style={{ width: '100%', display: 'flex', alignItems: 'flex-start' }}>
                                                        <SelectBox
                                                            dataSource={{
                                                                load: this.loadPriceChangeOptions,
                                                                loadMode: 'raw',
                                                                cacheRawData: true,
                                                                paginate: true,
                                                            }}
                                                            value={null}
                                                            valueExpr="contractotherpriceid"
                                                            displayExpr={(item) => { return item && `${item.name} [${Common.FormatCurrencyNum(item.price, item.currency)}]`; }}
                                                            searchEnabled={true}
                                                            searchMode="contains"
                                                            searchExpr={["name", "currency"]}
                                                            searchTimeout={200}
                                                            minSearchLength={3}
                                                            showDataBeforeSearch={true}
                                                            onValueChanged={this.addPriceChange}
                                                            placeholder={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_SELECT_SECONDARY_PRICE' })}
                                                            acceptCustomValue={false}
                                                            width="100%"
                                                        >
                                                        </SelectBox>
                                                    </div>
                                                </div>
                                            </Item>
                                        </Box>
                                    </div>
                                )}

                                {
                                    this.state.otherPriceChanges.map(priceChange => (
                                        <div className="dx-field" key={"PriceChange" + priceChange.name} >
                                            <Box direction="row" width="100%">
                                                <Item ratio={0} baseSize={200}>
                                                    <div className="dx-field-label" style={{ width: '100%', whiteSpace: 'normal' }}>
                                                        <strong>{`${priceChange.name}`}</strong>
                                                        {
                                                            !this.props.interimQuotesAllowed && (
                                                                <>
                                                                    <br />
                                                                    {`[${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CURRENT' })}: ${Common.FormatCurrencyNum(priceChange.currentprice, priceChange.currency)}]`}
                                                                    <br />
                                                                    {`[${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.EXPECTED' })}: ${Common.FormatCurrencyNum(priceChange.currentprice + (priceChange.pricechange ? priceChange.pricechange : 0), priceChange.currency)}]`}
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </Item>
                                                <Item ratio={1} baseSize={0}>
                                                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                                                        <div className="dx-field-value" style={{ width: '100%', display: 'flex', alignItems: 'flex-start' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <span style={{ padding: 8, whiteSpace: 'nowrap' }}> <FormattedMessage id="SECONDARYPRICE.FORMS.IMPACT_ON_PRICE" /></span>
                                                                <NumberBox
                                                                    {...dxNumberBoxProps(priceChange.currency)}
                                                                    value={priceChange.pricechange}
                                                                    onValueChanged={(args) => this.setPriceItem(priceChange.contractotherpriceid, args.value)}
                                                                    readOnly={this.props.isReply}
                                                                >
                                                                    <Validator>
                                                                        <RequiredRule message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_OTHER_PRICE_REQUIRED' }, { ITEM_NAME: priceChange.name })} />
                                                                    </Validator>
                                                                </NumberBox>
                                                                {!this.props.isReply && (
                                                                    <DxButton style={{ marginLeft: 8 }}
                                                                        icon="close"
                                                                        hint={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.REMOVE' })}
                                                                        onClick={() => this.removePriceItem(priceChange.contractotherpriceid)}
                                                                    />
                                                                )}
                                                                <DxButton style={{ marginLeft: 8 }}
                                                                    icon="bulletlist"
                                                                    hint={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.VIEW_CHANGE_LOG' })}
                                                                    onClick={this.viewPriceChangeLogClick.bind(this, priceChange)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Item>
                                            </Box>
                                        </div>
                                    ))
                                }
                                {
                                    this.props.isReply && this.state.otherPriceChanges.length === 0 && (
                                        <div style={{ textAlign: "center" }}> <FormattedMessage id="SECONDARYPRICE.LABEL.NO_PRICE_CHANGE" /></div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
                {
                    (this.props.secondaryDateSectionEnabled && this.isContractOtherDatesEnabled) && (
                        <div key="DateChangesFieldGroup" style={{ marginTop: 20 }}>
                            <div className="dx-fieldset-header" style={{ borderBottom: '1px solid #ddd' }}>
                                <span className="dx-widget dx-form-group-caption" style={{ marginBottom: 5 }}>
                                    <FormattedMessage id="GENERAL.FORMS.SECTION.OTHER_DATE_CHANGES" />
                                </span>
                            </div>
                            <div className="dx-widget dx-form-group-content dx-form-group-with-caption dx-form-group-content">
                                {!this.props.isReply && (
                                    <div className="dx-field" key="OtherDateOptions" >
                                        <Box direction="row" width="100%">
                                            <Item ratio={0} baseSize={200}>
                                                <div className="dx-field-label" style={{ width: '100%', whiteSpace: 'normal' }}>
                                                    <FormattedMessage id="GENERAL.FORMS.LABEL_SELECT_EFFECTED_DATES" />
                                                </div>
                                            </Item>
                                            <Item ratio={1} baseSize={0}>
                                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                                                    <div className="dx-field-value" style={{ width: '100%', display: 'flex', alignItems: 'flex-start' }}>
                                                        {this.state.isSettingOtherDates ? null : (
                                                            <SelectBox
                                                                dataSource={{
                                                                    load: this.loadDateChangeOptions,
                                                                    loadMode: 'raw',
                                                                    cacheRawData: true,
                                                                    paginate: true,
                                                                }}
                                                                value={null}
                                                                valueExpr="activityscheduleid"
                                                                displayExpr={(item) => { return item && `${Common.setDateName(item, this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_START_DATE' }), this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_COMPLETION_DATE' }))} [${moment(new Date(item.actiondate)).format('DD/MM/YYYY')}]`; }}
                                                                searchEnabled={true}
                                                                searchMode="contains"
                                                                searchExpr="name"
                                                                searchTimeout={200}
                                                                minSearchLength={3}
                                                                showDataBeforeSearch={true}
                                                                onValueChanged={this.addDateChange}
                                                                placeholder={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_SELECT_SECONDARY' })}
                                                                acceptCustomValue={false}
                                                                width="100%"
                                                            >
                                                            </SelectBox>
                                                        )}
                                                    </div>
                                                </div>
                                            </Item>
                                        </Box>
                                    </div>
                                )}
                                {
                                    this.state.otherDateChanges.map(dateChange => (
                                        <div className="dx-field" key={"DateChange" + dateChange.name} >
                                            <Box direction="row" width="100%">
                                                <Item ratio={0} baseSize={200}>
                                                    <div className="dx-field-label" style={{ width: '100%', whiteSpace: 'normal' }}>
                                                        <strong>{Common.setDateName(dateChange, this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_START_DATE' }), this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_COMPLETION_DATE' }))}</strong>
                                                        {
                                                            !this.props.interimQuotesAllowed && (
                                                                <>
                                                                    <br />
                                                                    {`[${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CURRENT' })}: ${moment(new Date(dateChange.currentdate)).format('DD/MM/YYYY')}]`}
                                                                    <br />
                                                                    {`[${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.EXPECTED' })}: ${moment(new Date(dateChange.currentdate)).add(dateChange.daychange ? dateChange.daychange : 0, 'days').format('DD/MM/YYYY')}]`}
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </Item>
                                                <Item ratio={1} baseSize={0}>
                                                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                                                        <div className="dx-field-value" style={{ width: '100%', display: 'flex', alignItems: 'flex-start' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <span style={{ padding: 8, whiteSpace: 'nowrap' }}> <FormattedMessage id="SECONDARYDATE.FORMS.IMPACT_ON_DATE" /></span>
                                                                <NumberBox
                                                                    {...dxNumberBoxProps()}
                                                                    format={'#,##0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' })}
                                                                    placeholder={'0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' })}
                                                                    value={dateChange.daychange}
                                                                    onValueChanged={(args) => this.setDateItem(dateChange.activityreferenceid, args.value)}
                                                                    readOnly={this.props.isReply}
                                                                >
                                                                    <Validator>
                                                                        <RequiredRule message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_OTHER_DATE_REQUIRED' }, { ITEM_NAME: dateChange.name })} />
                                                                    </Validator>
                                                                </NumberBox>


                                                                {!this.props.isReply && (
                                                                    <DxButton style={{ marginLeft: 8 }}
                                                                        icon="close"
                                                                        hint={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.REMOVE' })}
                                                                        onClick={() => this.removeDateItem(dateChange.activityreferenceid)}
                                                                    />
                                                                )}
                                                                <DxButton style={{ marginLeft: 8 }}
                                                                    icon="bulletlist"
                                                                    hint={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.VIEW_CHANGE_LOG' })}
                                                                    onClick={this.viewDateChangeLogClick.bind(this, dateChange)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Item>
                                            </Box>
                                        </div>
                                    ))
                                }
                                {
                                    this.props.isReply && this.state.otherDateChanges.length === 0 && (
                                        <div style={{ textAlign: "center" }}> <FormattedMessage id="SECONDARYDATE.LABEL.NO_DATE_CHANGE" /></div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
                <ContractSecondaryDateDetails
                    secondaryDate={this.state.viewDetailsSelectedDateItem}
                    onClose={() => { this.setState({ viewDetailsSelectedDateItem: null }) }}
                />
                <ContractSecondaryPriceDetails
                    secondaryPrice={this.state.viewDetailsSelectedPriceItem}
                    onClose={() => { this.setState({ viewDetailsSelectedPriceItem: null }) }}
                />
            </>
        );
    }
}

const mapStateToProps = store => ({
    contractUser: store.contract.user,
    contract: store.contract.contract,
});

export default withSnackbar(injectIntl(connect(mapStateToProps)(BiDynamicPriceDateFields)));
