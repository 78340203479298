import React, { Component } from 'react';
import { connect } from "react-redux";
import PortletSpinner from "../../layout/PortletSpinner";
import DataGrid, { Column, ColumnChooser, StateStoring, Button, ColumnFixing, Editing, Toolbar, Item as ToolbarItem } from 'devextreme-react/data-grid';
import * as fileCrud from '../../../crud/file.crud';
import { injectIntl } from "react-intl";
import { withSnackbar } from 'notistack';
import AsiteDialogModal from './AsiteDialogModal';

class AsiteFileList extends Component {
    state = {
        files: [],
        showModal: false,
        longestDocRefLength: null
    }

    componentDidMount() {
        if (this.props.files && this.props.files.length > 0) {
            const mappedFiles = this.props.files.map(file => ({ ...file, ...(file.metadata || {}) }));
            this.findLongestDocrefLength(mappedFiles);
            this.setState({ files: mappedFiles });
        }
        else if (this.props.isDraft) {
            this.fetchFiles();
        }
    }
    findLongestDocrefLength = (data) => {
        let maxLength = 0;

        data.forEach((obj) => {
          const docrefLength = obj.docref.length;
          if (docrefLength > maxLength) {
            maxLength = docrefLength;
          }
        });

        this.setState({
            longestDocRefLength: maxLength
        });
    }
    onModalResponse = (response) => {
        let files = this.state.files;

        // if (response && response.length > 0) {
        //     files = [...this.state.files, ...response];
        //     this.findLongestDocrefLength(files);
        // }

        if (response && response.length > 0) {
            // Convert customAttributes to string for each response item
            // to be able to parse it in altRefCellRender function
            const processedResponse = response.map(file => {
                if (file.customAttributes && typeof file.customAttributes === 'object') {
                    file.customAttributes = JSON.stringify(file.customAttributes);
                }
                return file;
            });
    
            files = [...this.state.files, ...processedResponse];
            this.findLongestDocrefLength(files);
        }

        this.setState({ files: files, showModal: false });
    }
    fetchFiles = () => {
        const { companyid, projectid, contractid } = this.props.contract;
        fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.notificationid, this.props.isReply ? this.props.documentType + 'Reply' : this.props.documentType)
            .then(response => {
                let fetchedFiles = JSON.parse(response.data.aggregatelist);
                fetchedFiles = fetchedFiles.map(file => ({ ...file, ...(file.metadata || {}) }));
                const longestDocRefLength = this.findLongestDocrefLength(fetchedFiles);
                this.setState({ files: fetchedFiles, longestDocRefLength });
            })
            .catch(err => {
                console.log(err);
            });
    }

    removeSelectedFileFromServer = (file) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { companyid, projectid, contractid } = this.props.contract;
                file.companyid = companyid;
                file.projectid = projectid;
                file.contractid = contractid;
                await fileCrud.deleteExternalFile(file);
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        });
    }
    onRowRemoving = (e) => {
        e.cancel = this.removeSelectedFileFromServer({ ...e.data });
    }
    onRowRemoved = async (e) => {
        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_ATTACHMENT_REMOVED' }), { variant: "warning" });
    }
    onAddAttachmentsClicked = async (e) => {
        // e.cancel = true;
        this.setState({ showModal: true });
    }

    cellRender = (e) => {
        return <a href={e.data.directlink} target='_blank' rel="noreferrer" title={e.value}>{e.value}</a>;
    }

    altRefCellRender = (cellInfo) => {
        const customAttributes = cellInfo.data.customAttributes;

        let altRefValue = '';
        try {
            const parsedAttributes = JSON.parse(customAttributes);
            altRefValue = parsedAttributes['Alt Ref'] || '';
        } catch (error) {
            console.error('Failed to parse customAttributes:', error);
        }

        return (
            <div>{altRefValue}</div>
        );
    };

    generateUniqueGridName = () => {
        const mainOrReply = this.props.isReply ? 'Reply' : 'Main';

        return process.env.REACT_APP_PRODUCT_KEY + this.props.contract.contractid + this.props.workflowItem.id + mainOrReply;
    }
    
    render() {
        if (!this.state.files || (this.state.files.length && !this.state.longestDocRefLength)) {
            return (<PortletSpinner />);
        }
        
        return (
            <div style={{ width: 'inherit' }}>
                <DataGrid
                    name={this.props.workflowItem.pageTitle}
                    dataSource={this.state.files}
                    keyExpr="fileid"
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    remoteOperations={false}
                    allowColumnReordering={true}
                    columnResizingMode='widget'
                    columnAutoWidth={true}
                    noDataText={this.props.intl.formatMessage({ id: 'GENERAL.DOCUMENTS.NO_ATTACHMENT' })}
                    onRowRemoving={this.onRowRemoving}
                    onRowRemoved={this.onRowRemoved}
                    onRowInserting={this.onRowInserting}
                    rowAlternationEnabled={true}
                >
                    <Editing
                        mode="popup"
                        allowUpdating={false}
                        allowDeleting={this.props.isDraft}
                        allowAdding={false}
                        confirmDelete={true}
                        useIcons={true}
                    />
                    <ColumnChooser mode="select" enabled={true} /*search={{ enabled: true }}*/ />
                    <ColumnFixing enabled={true} />
                    <StateStoring enabled={true} type="localStorage" storageKey={this.generateUniqueGridName()} />
                    <Column dataField="docref" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.DOCREF' })} width={this.state.longestDocRefLength < 22 ? 230 : 285} cellRender={this.cellRender} />
                    <Column dataField="altRef" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.ALTREF' })} width={this.state.longestDocRefLength < 22 ? 230 : 285} cellRender={this.altRefCellRender} visible={false} />
                    <Column dataField="name" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.NAME' })} width={this.state.longestDocRefLength < 22 ? 380 : 325} />
                    <Column dataField="revision" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.REVISION' })} width={40} />
                    <Column dataField="status" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.STATUS' })} width={60} />
                    <Column type="buttons" width={45} allowHiding={false} >
                        <Button name="delete" />
                    </Column>
                    {/* <Template name="toolbarTitle" render={this.toolbarTitleRender} /> */}
                    <Toolbar
                        visible={!!this.props.isDraft}
                    >
                        {/* <ToolbarItem
                            location="before"
                            locateInMenu="never"
                            template="toolbarTitle"
                            visible={!!this.props.isDraft}
                        /> */}
                        <ToolbarItem
                            location="after"
                            locateInMenu="auto"
                            widget="dxButton"
                            visible={!!this.props.isDraft}
                            options={
                                {
                                    icon: "plus",
                                    onClick: this.onAddAttachmentsClicked,
                                    text: this.props.intl.formatMessage({ id: 'GENERAL.DOCUMENTS.ATTACH_DOCUMENTS' }),
                                }
                            }
                        />
                        <ToolbarItem location="after"
                            locateInMenu="auto"
                            name="columnChooserButton"
                        />
                    </Toolbar>
                </DataGrid>
                <AsiteDialogModal
                    currentlyAttachedFiles={this.state.files}
                    visible={this.state.showModal}
                    title={this.props.intl.formatMessage({ id: 'ASITE.FORMS.FILE_MANAGER.TITLE' })}
                    onDialogResult={this.onModalResponse}
                    notificationid={this.props.notificationid}
                    documentType={this.props.documentType}
                    isReply={this.props.isReply}
                />
            </div>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
});

export default withSnackbar(injectIntl(connect(mapStateToProps)(AsiteFileList)));
