import React from 'react';
import { connect } from "react-redux";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { withSnackbar } from 'notistack';
import DataGrid, { Column, Toolbar, Item as ToolbarItem, ColumnFixing, Scrolling, HeaderFilter, FilterRow } from 'devextreme-react/data-grid';
import moment from 'moment';
import * as archiveCrud from "../../../../crud/archive.crud";
import { injectIntl } from "react-intl";
import { ProgressBar } from 'devextreme-react/progress-bar';
import { Template } from 'devextreme-react/core/template';

class ContractDataExport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contractExports: null,
            processing: false,
            downloadInfo: null
        };

    }

    componentDidMount() {

        this.loadExportList();
        // this.setRefreshTimer();

    }
    // setRefreshTimer = () => {
    //     this.timer = setTimeout(() => {
    //         if (!this.state.processing) {
    //             this.loadExportList();
    //         }
    //         this.setRefreshTimer();
    //     }, 20000);
    // }
    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }
    loadExportList = () => {
        const contractid = this.props.contract.contractid;
        archiveCrud.loadContractExports(contractid)
            .then(response => {
                let contractExports = JSON.parse(response.data);
                this.setState({ contractExports: contractExports });
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }


    isDownloadIconVisible = (e) => {
        return e.row.data.pdfstatus === 'Complete'
            && e.row.data.registercreatestatus === 'Complete'
            && e.row.data.registerdownloadstatus === 'Complete'
            && e.row.data.attachmentstatus === 'Complete'
            && e.row.data.zipstatus === 'Complete'
            && !this.state.processing;
    }
    onDownloadClick = (e) => {
        const contractexportid = e.row.data.contractexportid
        e.event.preventDefault();
        const options = {
            onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const downloadInfo = {
                    progress: Math.floor((loaded * 100) / total)
                }
                this.setState({ downloadInfo })
            }
        }

        this.setState({ processing: true, downloadInfo: null }, () => {
            archiveCrud.downloadContractExportZipFile(contractexportid, options)
                .then(resp => {
                    this.saveFileToDisk(resp.data);
                })
                .catch(err => {
                    this.setState({ processing: false });
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                    console.log(err);
                });
        });

    }
    saveFileToDisk = async (dataArr) => {
        try {
            const contractName = this.props.contract.name;
            const link = document.createElement('a');

            var binaryData = [];
            binaryData.push(dataArr);
            const url = window.URL.createObjectURL(new Blob(binaryData, { type: "application/x-zip-compressed" }))
            link.href = url;
            link.download = contractName + '.zip';
            link.click();
            this.setState({ processing: false });
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'REPORTS.MESSAGE.CONTRACTEXPORTCOMPLETED' }), { variant: 'success' });

        } catch (error) {
            this.props.enqueueSnackbar(error.toString(), { variant: 'error', });
            console.log(error);
        }
    }
    statusFormat = (ratio) => {
        return `Downloading: ${Math.floor(ratio * 100)}%`;
    }
    toolbarTitleRender = () => {
        return (
            <div>
                <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">{this.props.intl.formatMessage({ id: 'REPORTS.TITLE.EXPORT_LIST' })}</h3>
            </div>
        );

    }
    render() {
        return (
            <Portlet>
                <PortletBody>
                    <DataGrid
                        height='67vh'
                        dataSource={this.state.contractExports}
                        // disabled={this.state.processing}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        remoteOperations={false}
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        rowAlternationEnabled={false}
                        allowColumnResizing={true}
                        columnResizingMode='widget'
                        wordWrapEnabled={true}
                        noDataText={this.props.intl.formatMessage({ id: 'CONTRACT.MENU.EXPORTS' })}
                        style={{ width: '100%', height: '100%', maxHeight: '67vh' }}

                    >
                        <Template name="toolbarTitle" render={this.toolbarTitleRender} />
                        <Toolbar>
                            <ToolbarItem location="before"
                                locateInMenu="never"
                                template="toolbarTitle"
                            />
                            <ToolbarItem >
                                <ProgressBar
                                    id="progress-bar-status"
                                    // className={this.state.seconds === 0 ? 'complete' : ''}
                                    // width="100%"
                                    min={0}
                                    max={100}
                                    statusFormat={this.statusFormat}
                                    value={(this.state.downloadInfo || {}).progress}
                                    visible={this.state.processing}
                                    width="15vw"
                                />
                            </ToolbarItem>
                        </Toolbar>

                        <HeaderFilter visible={true} />
                        <FilterRow applyFilter="auto" visible={true} />
                        <ColumnFixing enabled={true} />

                        <Column cssClass="WrappedColumnClass" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.USER' })} dataField="accountname" />
                        <Column cssClass="WrappedColumnClass" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.START_DATE' })} dataField="startdate" dataType='date' format={(date) => moment(date).format('DD/MM/YYYY')} />
                        <Column cssClass="WrappedColumnClass" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.END_DATE' })} dataField="enddate" dataType='date' format={(date) => moment(date).format('DD/MM/YYYY')} />
                        <Column cssClass="WrappedColumnClass" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.PDF_STATUS' })} dataField="pdfstatus" />
                        <Column cssClass="WrappedColumnClass" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.REGISTER_CREATE_STATUS' })} dataField="registercreatestatus" />
                        <Column cssClass="WrappedColumnClass" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.REGISTER_DOWNLOAD_STATUS' })} dataField="registerdownloadstatus" />
                        <Column cssClass="WrappedColumnClass" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.ATTACHMENT_STATUS' })} dataField="attachmentstatus" />
                        <Column cssClass="WrappedColumnClass" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.ZIP_STATUS' })} dataField="zipstatus" />
                        <Column cssClass="WrappedColumnClass" caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.DOWNLOAD' })} type="buttons" buttons={[{ hint: 'Download', icon: 'download', visible: this.isDownloadIconVisible, onClick: this.onDownloadClick }]} />
                        <Column cssClass="WrappedColumnClass" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.DOWNLOADED_DATE' })} dataField="downloadeddate" dataType='date' format={(date) => moment(date).format('DD/MM/YYYY')} />
                        <Column cssClass="WrappedColumnClass" caption={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.PURGE_DATE' })} dataField="purgedate" dataType='date' format={(date) => moment(date).format('DD/MM/YYYY')} />
                        

                        <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />

                    </DataGrid>
                </PortletBody>
            </Portlet>
        );
    }
}


const mapStateToProps = (store) => ({
    contract: store.contract.contract,
    contractUser: store.contract.user,
});


export default injectIntl(withSnackbar(connect(mapStateToProps)(ContractDataExport)));
