import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import InstructionList from './Instruction/List';
import InstructionCreate from './Instruction/Create';
import InstructionDetail from './Instruction/Detail';

class InstructionsRouter extends React.Component {
    constructor(props) {
        super(props);

        this.notifyingParty = props.workflowItem.notifyingParty;
        this.replyingParty = props.workflowItem.replyingParty;



        this.communicateToUser = props.globalResources[this.replyingParty + "_NAME"];

        this.notificationType = '43.1SUP';
        this.documentType = 'Document431sup';
        this.replyPeriod = props.workflowItem.replyPeriod;
        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;

        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<InstructionList
                        {...this.props}
                        
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        notificationType={this.notificationType}
                    />}

                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                            <InstructionCreate
                                {...this.props}
                                title={this.notificationName}
                                
                                communicateToUser={this.communicateToUser}
                                notificationType={this.notificationType}
                            />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    exact
                    element={<InstructionDetail
                        {...this.props}
                        replyPeriod={this.replyPeriod}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                        documentType={this.documentType}
                        communicateToUser={this.communicateToUser}
                        notificationType={this.notificationType}
                        notifyUserFilter={workflowItem.notifyUserFilter}
                    />}
                />
            </Routes>
        );
    }
};




export default (InstructionsRouter);