import React from 'react';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { connect } from "react-redux";
import TabGeneral from './CustomControls/TabGeneral';
import TabResources from './CustomControls/TabResources';
import MasterWorkflowsDetails from '../MasterWorkflows/MasterWorkflowsDetails';
import DefaultWorkflows from '../../../../Templates/DefaultWorkflows';

class MasterTemplateDetails extends React.Component {
    constructor(props) {
        super(props);
        const initState = this.getInitialState(props);
        this.state = initState;
    }

    getInitialState = (props) => {
        const newTemp = JSON.parse(JSON.stringify(props.template));
        if (newTemp.workflowGroups == null) {
            console.log('Template WorkflowGroups data is corrupted, default workflows will be loaded!!');
            alert('Template WorkflowGroups data is corrupted, default workflows will be loaded!!');
            newTemp.workflowGroups = JSON.parse(JSON.stringify(DefaultWorkflows));
        }
        return {
            template: newTemp,
        };
    }
    componentDidUpdate(prevProps, prevState) {

        if (prevProps) {
            if (prevProps.template !== this.props.template) {
                if (this.props.template && this.props.workflowGroups) {
                    if (!this.state.template) {
                        this.setState(this.getInitialState(this.props));
                    }
                }
            }
        }
    }

    onWorkflowGroupsChanged = (newWfGList) => {
        if (this.props.workFlowGroupChanged) {
            this.props.workFlowGroupChanged(JSON.parse(JSON.stringify(newWfGList)));
        }
        this.setState({ template: { ...this.state.template, workflowGroups: JSON.parse(JSON.stringify(newWfGList)) } }, this.updateParent);
    }
    onResourcesChanged = (newResourcesList) => {
        this.setState({ template: { ...this.state.template, resources: newResourcesList } }, this.updateParent);

    }
    updateParent = () => {
        this.props.templateChanged(JSON.parse(JSON.stringify(this.state.template)));
    }

    templateValueChangeHandler = (property, value) => {
        const newObj = { ...this.state.template, [property]: value };
        this.setState({ template: newObj }, this.updateParent);

    }
    tabGeneral = () => {
        return (
            <TabGeneral
                userProfile={this.props.userProfile}
                mode={this.props.mode}
                companies={this.props.companies}
                template={this.state.template}
                valueChangeHandler={this.templateValueChangeHandler}
                readOnly={this.props.readOnly}
            />
        )
    }
    tabResources = () => {
        return (
            <TabResources
                resources={this.state.template.resources}
                resourcesChanged={this.onResourcesChanged}
                readOnly={this.props.readOnly}
            />
        )
    }
    tabWorkflows = () => {
        return (
            <MasterWorkflowsDetails
                mode={this.props.mode}
                workflowGroups={this.state.template.workflowGroups}
                allWorkflowGroups={this.props.workflowGroups}
                onChange={this.onWorkflowGroupsChanged}
                readOnly={this.props.readOnly}
            />
        )
    }
    render() {

        return (
            <TabPanel
                disabled={this.props.disabled}
                height="470px"
                width="100%"
                deferRendering={false}
                animationEnabled={true}
                swipeEnabled={true}>
                {
                    this.props.mode === "TEMPLATE" && (
                        <Item title={"General"} render={this.tabGeneral} />
                    )
                }
                <Item icon="hierarchy" title={"Workflows"} render={this.tabWorkflows} />
                <Item title={"Global Resources"} render={this.tabResources} />
            </TabPanel>
        )
    }
}
const mapStateToProps = store => ({
    userProfile: store.auth.userProfile,
});

export default connect(mapStateToProps)(MasterTemplateDetails);

