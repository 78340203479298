import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import * as inspectionCrud from '../../../../crud/inspectionDefect.crud';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import moment from 'moment';
// import QueryString from 'query-string';
import { FormattedMessage } from "react-intl";
import { addYears } from 'date-fns';
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import Common from '../../../../shared/common';
import { actionCodes } from "../../../../shared/lookup";

class NotifyCreatePage extends BaseCreatePage {
    constructor(props) {
        super(props);
        // const { subject } = ((props.location.state || {}).itemCustomProps || {});
        this.createDefaultSubject = (((props.location || {}).state || {}).itemCustomProps || {}).subject;
        this.createDefaultAsset = (((props.location || {}).state || {}).itemCustomProps || {}).assetid;
        this.state = {
            showAlert: true,
            selectedClause: null,
            communicateToUser: props.communicateToUser
        };
    }

    submitHandler = (notification, setSubmitting) => {
        notification.companyid = this.props.contract.companyid;
        notification.contractid = this.props.contract.contractid;
        notification.projectid = this.props.contract.projectid;
        notification.replystatus = 'Not Communicated';
        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');
        notification.type = this.props.notificationType;
        // if (this.props.workflowItem.replyPeriodOption === "ASKED_ON_COMMUNICATION_FORM") {
        //     notification.inspectiondatedisplay = moment(notification.inspectiondatedisplay).format('DD/MM/YYYY HH:mm:ss');
        // }

        const selectedClause = this.props.clauses.find(item => item.display === notification.replycomment);
        if (!selectedClause) {
            console.log("Clause selection not found!");
        }
        notification.actioncode = actionCodes.CREATE;
        notification.notifyingparty = this.props.workflowItem.notifyingParty;
        if(this.props.workflowItem.replyEnabled){
            notification.replyingparty = this.props.workflowItem.replyingParty;
            if (selectedClause && selectedClause.overrideReplyingParty) {
                notification.replyingparty = selectedClause.replyingParty;
            }
            if (notification.replyrequireddate) {
                const replyRequiredDate = moment(notification.replyrequireddate);
                notification.replyrequireddate = moment().set({ 'year': replyRequiredDate.year(), 'month': replyRequiredDate.month(), 'date': replyRequiredDate.date() }).toDate();
            }
        }
        

        this.appendRelationship(notification);
        Common.PropertyConversionsBeforeNoticeSaveRequest(notification, this.props.customFields, this.props.contractUsers);
        inspectionCrud.saveInspectionDefect(notification)
            .then(response => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
                try {
                    const createdID = Number(response.data);
                    this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
                    // if (Object.keys(queryObj).length !== 0) {
                    //     this.props.navigate(this.props.workflowItem.paths.basePath + createdID + '?notice=associated');
                    // } else {
                    //     this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
                    // }
                }
                catch {
                    this.props.navigate(this.props.workflowItem.paths.listPath);
                }
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });

    }
    fieldChanged = (field, value, isDxFormValid) => {
        if (field) {
            if (field === 'replycomment') {
                const selectedClause = this.props.clauses.find(item => item.display === value);
                let replyingParty = this.props.workflowItem.replyingParty;
                if (selectedClause && selectedClause.overrideReplyingParty) {
                    replyingParty = selectedClause.replyingParty;
                }
                const communicateToUser = this.props.globalResources[replyingParty + "_NAME"];
                this.setState({ communicateToUser: communicateToUser, selectedClause: selectedClause });
            }
        }
    }
    render() {

        // const { assetid } = ((this.props.location.state || {}).itemCustomProps || {});
        const createFormConfig = {
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: "GENERAL.FORMS.FIELD.SUBJECT", dataField: 'assistancetestserchdefect', editorOptions: { defaultValue: this.createDefaultSubject } },
                { resource: "GENERAL.FORMS.FIELD.TYPE", dataField: 'replycomment', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.clauses, valueExpr: 'display', displayExpr: 'display' } },
            ]
        };
        if (this.props.workflowItem.replyEnabled && this.props.workflowItem.replyPeriodOption === "ASKED_ON_COMMUNICATION_FORM" && !(this.state.selectedClause && (this.state.selectedClause.noReply || this.state.selectedClause.applyReplyPeriod))) {
            createFormConfig.fields.push({ resource: "GENERAL.FORMS.FIELD.RESPONSE_REQUIRED", dataField: 'replyrequireddate', editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: new Date() } }, validation: { type: 'range', min: moment().subtract(1, 'days'), max: addYears(new Date(), 10), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} })
        }
        createFormConfig.fields.push(
            { label: `${this.props.workflowItem.notificationName}`, dataField: 'inspectiondetails', editorType: 'dxHtmlEditor' },

        )
        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    <BiDynamicForm
                        config={createFormConfig}
                        onSubmitHandler={this.submitHandler}
                        valueChangeHandler={this.fieldChanged}
                        customFields={(this.props.customFields || {}).COMMUNICATION}
                        assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                        assetid={this.createDefaultAsset}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(NotifyCreatePage));
