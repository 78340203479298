import React, { Component } from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import * as requestForQuoteCrud from '../../../../crud/requestForQuote.crud';
import { Badge } from "react-bootstrap";
import moment from 'moment';
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper";
import { notificationAggregateListTagsFilter, notificationUniqueTagsFilter } from "../../../../store/helpers/FilterHelper";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

class RfqListPage extends Component {
    constructor(props) {
        super(props);
        //this.props.fetchRequestForQuote(this.props.notificationType, true);
        this.rfqStatuses = props.placeholders
            .find(ph => ph.replacementKey === 'RFQStatuses')
            .values.filter(item => item.isActive === true);

        this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.notificationsRelsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';
        this.customFields = [];
        if (props.customFields) {
            if (props.customFields.COMMUNICATION && props.customFields.COMMUNICATION.length > 0) {
                this.customFields.push(...(props.customFields.COMMUNICATION.filter(cf => cf.includelisting)));
            }
            if (props.customFields.REPLY && props.customFields.REPLY.length > 0) {
                this.customFields.push(...(props.customFields.REPLY.filter(cf => cf.includelisting)));
            }
        }
        this.state = {
            notifications: null,
            tags: null
        };
    }
    componentDidMount() {
        this.fetchRequestForQuotes();
    }


    fetchRequestForQuotes = () => {
        const { companyid, projectid, contractid } = this.props.contract;
        requestForQuoteCrud.getContractRequestForQuotes(companyid, projectid, contractid, this.props.accessibleWfIds)
            .then(response => {
                let listData = notificationAggregateListTagsFilter(response.data.aggregatelist);
                listData = (this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed === true) ?
                    listData :
                    listData.filter(ntf => ntf.showtasknotification === 1);

                let tagFilters = notificationUniqueTagsFilter(response.data.uniquetags);

                this.setState({ notifications: listData, tags: tagFilters });
            })
            .catch(err => {
                this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
            });
    }

    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        return  (skipHostUrl ? '' : process.env.PUBLIC_URL) + this.props.workflowItem.paths.basePath + rowData.requestforquoteid;
    }
    renderStatusColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        let status = this.renderStatusDisplay(cellInfo.data);
        switch (cellInfo.data.status) {
            case 'R':
            case 'APMA':
            case 'ARQ':
            case 'DNG':
                badgeVariant = 'danger';
                break;
            case 'QA':
                badgeVariant = 'success';
                break;
            case 'AM':
            case 'APMRQ':
            case 'APMR':
            case 'AQ':
                badgeVariant = 'warning';
                break;
            case 'NC':
                badgeVariant = 'secondary';
                break;
            default:
                badgeVariant = 'secondary';
                break;
        }
        return (<Badge variant={badgeVariant}>{status}</Badge>);
    }
    renderStatusDisplay = (rowData) => {
        const status = this.rfqStatuses.find(x => x.field === rowData.status);
        if (status) {
            return status.display;
        } else {
            return rowData.status;
        }
    }
    renderQuoteReceived = (cellInfo) => {
        if (cellInfo.data.quotereceiveddate) {
            let badgeVariant = 'secondary';
            const quoteRequiredDate = new Date(cellInfo.data.requiredate);
            const quoteReceiveDate = new Date(cellInfo.data.quotereceiveddate);
            if (quoteRequiredDate < quoteReceiveDate) {
                badgeVariant = 'danger';
            }
            else {
                badgeVariant = 'success';
            }
            return (<Badge variant={badgeVariant}>{moment(cellInfo.data.quotereceiveddate).format('L')}</Badge>);
        }
    }
    pmResponseReceived = (cellInfo) => {
        if (cellInfo.data.quoteresponsereceiveddate) {
            let badgeVariant = 'secondary';
            const pmResponseRequiredDate = new Date(cellInfo.data.quoteresponserequireddate);
            const pmResponseReceiveDate = new Date(cellInfo.data.quoteresponsereceiveddate);
            if (pmResponseRequiredDate < pmResponseReceiveDate) {
                badgeVariant = 'danger';
            }
            else {
                badgeVariant = 'success';
            }
            return (<Badge variant={badgeVariant}>{moment(pmResponseReceiveDate).format('L')}</Badge>);
        }
    }

    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }

        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.SUBJECT", dataField: 'subject' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_DATE", dataField: "notificationdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_BY", dataField: "createby" },
            { resource: "CONTRACT.WF.COMPENSATION.FIELD.QUOTE_REQUIRED", dataField: "quoterequireddate", dataType: 'date' },
            { resource: "CONTRACT.WF.COMPENSATION.FIELD.QUOTE_RECEIVED", dataField: "quotereceiveddate", dataType: 'date', cellRender: this.renderQuoteReceived },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PM_RESPONSE_REQUIRED' }, { PM_ABBREVIATION: this.props.globalResources.PM_ABBREVIATION }), dataField: "quoteresponserequireddate", dataType: 'date' },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PM_RESPONSE_RECEIVED' }, { PM_ABBREVIATION: this.props.globalResources.PM_ABBREVIATION }), dataField: "quoteresponsereceiveddate", dataType: 'date', cellRender: this.pmResponseReceived },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "status", cellRender: this.renderStatusColumn, calculateCellValue: this.renderStatusDisplay },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false }
        ];
        if (this.props.workflowItem.assetFieldsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.ASSET_NAME", dataField: "assetname", visible: false },
                { resource: "GENERAL.FORMS.FIELD.ASSET_TYPE", dataField: "assettype", visible: false },
            );
        }
        if (this.notificationsTagsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.PortfolioFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProgrammeFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProjectFilter } },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.OtherFilter } }
            );
        }
        if (this.notificationsRelsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.TAB_RELATIONS", dataField: "relatednotices", visible: false },
            )
        }

        columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(this.customFields));

        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="1"
                        title={this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.TITLE2' }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                        createPath={this.props.workflowItem.userFilters.mainNotice.createAllowed ? this.props.workflowItem.paths.createPath : null}
                        workflowItem={this.props.workflowItem}
                        name={this.props.workflowItem.pageTitle}
                        dataSource={this.state.notifications}
                        keyExpr="requestforquoteid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}



export default withListPageProps(RfqListPage);
