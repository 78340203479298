import axios from '../shared/axios';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function saveCompensationEvent(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/CompensationEvent/SaveCompensationEvent`, data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}

export function saveCompensationEarlyWarning(data) {
    return axios.post(`/api/CompensationEvent/SaveCompensationEventEarlyWarning`, data);
}
export function deleteCompensationEarlyWarning(compensationeventid, earlywarningid) {
    return axios.delete(`/api/CompensationEvent/DeleteCompensationEventEarlyWarning?compensationeventid=${compensationeventid}&earlywarningid=${earlywarningid}`);
}
export function saveCompensationAssessment(data) {
    return axios.post(`/api/CompensationEvent/SaveCompensationEventAssessment`, data);
}

export function saveCompensationEventActivity(data) {
    //THIS EP PATH SAYS /SaveQuotationActivity, But in practice it Saves CompensationEventActivity
    return axios.post(`/api/CompensationEvent/SaveQuotationActivity`, data);
}

export function getCompensationEventActivities(companyid, projectid, contractid, compensationeventid, actionType) {
    return axios.post(`/api/CompensationEvent/LoadCompensationEventActivitiesList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&compensationeventid=${compensationeventid}&actiontype=${actionType}`);
}

export function getCompensationWarnings(companyid, projectid, contractid, compensationeventid) {
    return axios.post(`/api/CompensationEvent/LoadCompensationEventEarlyWarningList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&compensationeventid=${compensationeventid}`);
}

export function getCompensationEvent(compensationeventid) {
    return axios.get(`/api/CompensationEvent/GetCompensationEvent?compensationeventid=${compensationeventid}`);
}

// export function getContractCompansationEvents(companyid, projectid, contractid, source, startdate, enddate, accessibleWfIds) {
//     return axios.post(`/api/CompensationEvent/LoadCompensationEventsList`, { accessibleWfIds }, { params: { companyid, projectid, contractid, source, startdate, enddate } });
// }

export function getCompansationEventsReduced(companyid, projectid, contractid, type, accessibleWfIds) {
    const startdate = '', enddate = '';
    if (type == null) { type = '' }
    return axios.post(`/api/CompensationEvent/RegisterList`, { accessibleWfIds }, { params: { companyid, projectid, contractid, source: type, startdate, enddate } });
}

export function getCompansationEventsToSelectList(contractid, statuses) {
    return axios.post(`/api/CompensationEvent/ToSelectList`, { contractid, statuses });
}

export function deleteCompensationEventDraft(compensationeventid) {
    return new Promise((resolve, reject) => {
    axios.post(`api/CompensationEvent/DeleteDraft?compensationeventid=${compensationeventid}`)
    .then(result => {
        store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
        resolve(result);
    })
    .catch(error => reject(error));
})
}