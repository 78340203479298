import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ItqList from './Itq/List';
import ItqCreate from './Itq/Create';
import ItqDetail from './Itq/Detail';

class ItqRouter extends React.Component {
    constructor(props) {
        super(props);
        this.communicateToUser = this.props.globalResources.SUPPLIER_NAME;
        this.notificationType = 'ITQ';
        this.documentType = 'DocumentITQ';
        this.notifyingParty = props.workflowItem.notifyingParty;

        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;

        this.configurableITQFields = {};

        try {
            const projectITQFields = props.placeholders.find(ph => ph.replacementKey === 'ITQFields');

            if (projectITQFields) {
                projectITQFields.values.forEach(fieldItem => {
                    this.configurableITQFields[fieldItem.field] = fieldItem.isActive ? fieldItem.display : '';
                });
            }
        } catch (error) {
            console.log('Error loading ITQ Fields Placeholder!');
        }
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;
        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<ItqList
                        {...this.props}
                        // fields={this.fields}
                        
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        notificationType={this.notificationType}
                        configurableITQFields={this.configurableITQFields}
                    />}
                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                            <ItqCreate
                                {...this.props}
                                // fields={this.fields}
                                title={this.notificationName}
                                
                                communicateToUser={this.communicateToUser}
                                notificationType={this.notificationType}
                                configurableITQFields={this.configurableITQFields}
                            />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    element={<ItqDetail
                        {...this.props}
                        // fields={this.fields}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                        documentType={this.documentType}
                        communicateToUser={this.communicateToUser}
                        notificationType={this.notificationType}
                        notifyUserFilter={workflowItem.notifyUserFilter}
                        configurableITQFields={this.configurableITQFields}
                    />}
                />
            </Routes>
        );
    }
};

export default (ItqRouter);