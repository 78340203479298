import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DefectList from './List';
import DefectCreate from './Create';
import DefectDetail from './Detail';

class DefectRouter extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.plannedClosureEnabled = props.placeholders
                .find((ph) => ph.replacementKey === "DefectPlannedClosureEnabled")
                .values.find((phItem) => phItem.field === "DefectPlannedClosureEnabled").isActive;
        } catch (error) {
            console.log("DefectPlannedClosureEnabled placeholder not available!");
            this.plannedClosureEnabled = false;
        }

        if (props.mode === 'SUPPLIER') {
            if (props.workflowItem.actioningParty === 'SUPERVISOR') {
                this.communicateToUser = props.globalResources.SUPERVISOR_NAME;
            }
            else {
                this.communicateToUser = props.globalResources.PM_NAME;
            }
            this.notificationType = '42.2CON';
            this.documentType = 'Document422CON';
        }
        else if (props.mode === 'SUPERVISOR') {
            this.communicateToUser = props.globalResources.SUPPLIER_NAME;
            this.notificationType = '42.2SUP';
            this.documentType = 'Document422sup';
        }

        this.notifyingParty = props.workflowItem.notifyingParty;


        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;
        this.replyPeriod = props.workflowItem.replyPeriod;

        this.NCSubmissionWFexist = !!props.workflowGroupItem.workflows.find(wf => wf.id === 54);
        this.NonConformityWFexist = !!props.workflowGroupItem.workflows.find(wf => wf.id === 55);
        if (this.NCSubmissionWFexist) {
            this.NCSubmissionReplyOptions = props.placeholders.find(ph => ph.replacementKey === 'NCProposalReplyOptions')
                .values.reduce((obj, item) => ({ ...obj, [item.field]: item.display }), {});

            if (this.NonConformityWFexist) {
                this.NonConformityReplyOptions = props.placeholders.find(ph => ph.replacementKey === 'NonConformityReplyOptions')
                    .values.reduce((obj, item) => ({ ...obj, [item.field]: item.display }), {});
            }
        }

        this.ClosureReasonLabel = props.resources.ClosureReasonLabel;

        this.ClosureReasonOptions = {};

        const closureReasonOptions = props.placeholders.find(ph => ph.replacementKey === 'ClosureReasonOptions');
        if (closureReasonOptions && closureReasonOptions.values && closureReasonOptions.values.length > 0) {
            this.ClosureReasonOptions = closureReasonOptions.values.filter(item => item.isActive === true);
        }
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;

        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<DefectList
                        {...this.props}
                        plannedClosureEnabled={this.plannedClosureEnabled}
                        
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        notificationType={this.notificationType}
                        NCSubmissionWFexist={this.NCSubmissionWFexist}
                        NCSubmissionReplyOptions={this.NCSubmissionReplyOptions}
                        NonConformityReplyOptions={this.NonConformityReplyOptions}
                    />}
                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                            <DefectCreate
                                {...this.props}
                                title={this.notificationName}
                                
                                communicateToUser={this.communicateToUser}
                                notificationType={this.notificationType}
                                plannedClosureEnabled={this.plannedClosureEnabled}
                                NCSubmissionWFexist={this.NCSubmissionWFexist}
                            />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    exact
                    element={<DefectDetail
                        {...this.props}
                        replyPeriod={this.replyPeriod}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                        documentType={this.documentType}
                        communicateToUser={this.communicateToUser}
                        notificationType={this.notificationType}
                        notifyUserFilter={workflowItem.notifyUserFilter}
                        plannedClosureEnabled={this.plannedClosureEnabled}
                        NCSubmissionWFexist={this.NCSubmissionWFexist}
                        ClosureReasonOptions={this.ClosureReasonOptions}
                        ClosureReasonLabel={this.ClosureReasonLabel}
                    />}
                />
            </Routes>
        );
    }
};



export default (DefectRouter);
