import * as companiesCrud from "../../crud/companies.crud";
import * as companyUserCrud from "../../crud/companyUser.crud";
import * as authDuck from './auth.duck';
import store from "../store";
import { select, put, takeLatest } from "redux-saga/effects";
import { toAbsoluteUrl } from "../../../_metronic";
import CustomFieldHelper from "../../pages/contract/_Helpers/CustomFieldHelper"

const getCompany = (store) => store.company
const actionTypes = {
    COMPANY_SET_PENDING: "[COMPANY_SET_PENDING]",
    COMPANY_LOAD_START: "[COMPANY_LOAD_START]",
    COMPANY_SET_COMPANY: "[COMPANY_SET_COMPANY]",
    COMPANY_SET_USERS: "[COMPANY_SET_USERS]",
    COMPANY_SET_PICTURE: "[COMPANY_SET_PICTURE]",
    COMPANY_RELOAD_PICTURE: "[COMPANY_RELOAD_PICTURE]",
    COMPANY_RELOAD_USERS: "[COMPANY_RELOAD_USERS]",
    COMPANY_LOGOUT: "[COMPANY_LOGOUT]"
};

const initialCompanyState = {
    companyid: null,
    company: null,
    companyUsers: null,
    companyPicture: null,
    lastLoadedEpochTime: null,
    pendingFetch: false,
};

export const reducer = (state = initialCompanyState, action) => {
    switch (action.type) {
        case actionTypes.COMPANY_SET_PENDING: {
            return { ...state, pendingFetch: true, companyid: action.payload.companyid };
        }
        case actionTypes.COMPANY_LOAD_START: {
            if (state.company && state.company.companyid === action.payload.companyid) {
                return { ...state, pendingFetch: false, companyid: action.payload.companyid, lastLoadedEpochTime: new Date().getTime() };
            }
            else {
                return { ...initialCompanyState, pendingFetch: false, companyid: action.payload.companyid, lastLoadedEpochTime: new Date().getTime() };
            }
        }
        case actionTypes.COMPANY_SET_COMPANY: {
            return { ...state, company: action.payload.company };
        }
        case actionTypes.COMPANY_SET_USERS: {
            return { ...state, companyUsers: action.payload.companyUsers };
        }
        case actionTypes.COMPANY_SET_PICTURE: {
            return { ...state, companyPicture: action.payload.picture };
        }
        case actionTypes.COMPANY_RELOAD_PICTURE: {
            return { ...state, companyPicture: null };
        }
        case actionTypes.COMPANY_RELOAD_USERS: {
            return { ...state, companyUsers: null };
        }
        case actionTypes.COMPANY_LOGOUT: {
            return initialCompanyState;
        }
        default:
            return state;
    }
};
export const actions = {
    fetchStart: (companyid) => {

        const authStore = store.getState().auth;
        const companyStore = store.getState().company;

        if (authStore) {
            if (!authStore.authToken.token || !authStore.csrfToken.token) {
                if (!companyStore.pendingFetch) {
                    return { type: actionTypes.COMPANY_SET_PENDING, payload: { companyid } }
                }
                return { type: '' };
            }
        }

        return { type: actionTypes.COMPANY_LOAD_START, payload: { companyid } }
    },
    unload: () => ({ type: actionTypes.COMPANY_LOGOUT}),
    setCompany: (company) => ({ type: actionTypes.COMPANY_SET_COMPANY, payload: { company } }),
    setUsers: (companyUsers) => ({ type: actionTypes.COMPANY_SET_USERS, payload: { companyUsers } }),
    setPicture: (picture) => ({ type: actionTypes.COMPANY_SET_PICTURE, payload: { picture } }),
    reloadPicture: () => ({ type: actionTypes.COMPANY_RELOAD_PICTURE }),
    reloadUsers: () => ({ type: actionTypes.COMPANY_RELOAD_USERS }),
    logout: () => ({ type: actionTypes.COMPANY_LOGOUT }),
};


export function* saga() {
    yield takeLatest(authDuck.actionTypes.AUTH_LOAD_COMPLETED, function* companyLoginSaga() {
        try {
            let companyStore = yield select(getCompany);
            if (companyStore.pendingFetch) {
                yield put(actions.fetchStart(companyStore.companyid));
            }
        }
        catch { console.log("error in companyLoginSaga") }
    });
    yield takeLatest(actionTypes.COMPANY_LOAD_START, function* companyLoadCompanySaga() {
        try {
            let companyStore = yield select(getCompany);
            const { data: companyDetails } = yield companiesCrud.getCompany(companyStore.companyid);
            if (companyDetails.tags && companyDetails.tags.categories) {
                companyDetails.tags.categories.forEach(tagCategory => {
                  switch (tagCategory.name) {
                    case 'Frameworks':
                        companyDetails.frameworkids = tagCategory.tags.map(tag => tag.tagid);
                      break;
                    default:
                      break;
                  }
                });
              }
            yield put(actions.setCompany(companyDetails));
        }
        catch { }

    });
    yield takeLatest(actionTypes.COMPANY_LOAD_START, function* companyLoadUsersSaga() {
        try {
            let companyStore = yield select(getCompany);
            const { data: usersList } = yield companyUserCrud.getCompanyUsersV2(companyStore.companyid, 99);
            const mappedList = CustomFieldHelper.MapDataListCustomFieldsToObjectProperties(JSON.parse(usersList.aggregatelist));
            yield put(actions.setUsers(mappedList));

            //yield all command applied for waiting all user requests to finish
            // const userList = [];
            // const userDetailResults = yield all(userIDList.map(userID => {
            //     return call(userCrud.getUser, userID.accountid)
            // }));
            // userDetailResults.forEach(userDetailResult => { userList.push(userDetailResult.data); })
            // yield put(actions.fetchSuccessUsers(userList));
        }
        catch { }

    });
    yield takeLatest(actionTypes.COMPANY_LOAD_START, function* companyLoadPictureSaga() {
        try {
            let companyStore = yield select(getCompany);
            const { data: pictureProperties } = yield companiesCrud.getCompanyPicture(companyStore.companyid);
            yield put(actions.setPicture(pictureProperties.image));
        }
        catch {
            // console.log('company picture coulnt be retrieved');
            yield put(actions.setPicture(toAbsoluteUrl("/media/logos/default.png")));
        }

    });


    yield takeLatest(actionTypes.COMPANY_RELOAD_PICTURE, function* companyReloadPictureSaga() {
        try {
            let companyStore = yield select(getCompany);
            const { data: pictureProperties } = yield companiesCrud.getCompanyPicture(companyStore.companyid);
            yield put(actions.setPicture(pictureProperties.image));
        }
        catch {
            yield put(actions.reloadPictureSuccess(toAbsoluteUrl("/media/logos/default.png")));
        }

    });
    yield takeLatest(actionTypes.COMPANY_RELOAD_USERS, function* companyReloadUsersSaga() {
        try {
            let companyStore = yield select(getCompany);
            const { data: usersList } = yield companyUserCrud.getCompanyUsersV2(companyStore.companyid, 99);
            const mappedList = CustomFieldHelper.MapDataListCustomFieldsToObjectProperties(JSON.parse(usersList.aggregatelist));
            yield put(actions.setUsers(mappedList));
        }
        catch { }

    });

}

