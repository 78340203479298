import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import RfiList from './List';
import RfiCreate from './Create';
import RfiDetail from './Detail';

class RfiRouter extends React.Component {
    constructor(props) {
        super(props);
        
        this.notificationType = props.workflowItem.config.currentModelType;
        
        if(props.mode === 'SUPPLIER'){
            this.documentType = 'DocumentRFI';
        }
        else if(props.mode === 'PM') {
            this.documentType = 'DocumentRFIPM';
        }
        this.notifyingParty = props.workflowItem.notifyingParty;
        this.replyingParty = props.workflowItem.replyingParty;
        if (this.replyingParty) {
            this.communicateToUser = props.globalResources[this.replyingParty + "_NAME"];
        }

        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;
        this.replyPeriod = props.workflowItem.replyPeriod;
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;

        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<RfiList
                        {...this.props}
                        
                        notificationType={this.notificationType}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                    />}
                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                            <RfiCreate
                                {...this.props}
                                title={this.notificationName}
                                
                                communicateToUser={this.communicateToUser}
                                notificationType={this.notificationType}
                            />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    exact
                    element={<RfiDetail
                        {...this.props}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                        notificationType={this.notificationType}
                        documentType={this.documentType}
                        communicateToUser={this.communicateToUser}
                        notifyUserFilter={workflowItem.notifyUserFilter}
                    />}
                />
            </Routes>
        );
    }
};



export default (RfiRouter);