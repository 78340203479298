import axios from '../shared/axios';
// import qs from 'querystring';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function saveProposal(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/Proposal/SaveProposal`, data)
            .then(result => {
                // if (data.showtasknotification === 1 ) {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                // }
                resolve(result);
            })
            .catch(error => reject(error));
    });
}

export function updateProposal(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/Proposal/Update`, data)
            .then(result => {
                if (data.actioncode === 'COMMUNICATE' || data.actioncode === 'REPLY_SEND') {
                    store.dispatch({
                      type: contractDuck.actionTypes.CONTRACT_REFRESH_CONTRACT,
                    });
                  }
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}
export function getProposal(proposalid) {
    return axios.get(`/api/Proposal/GetProposal?earlyproposalid=${proposalid}`);
}

export function getProposals(companyid, projectid, contractid, accessibleWfIds) {
    return axios.post(`/api/Proposal/LoadProposalsList`, { accessibleWfIds }, { params: { companyid, projectid, contractid } });
}
export function deleteProposalDraft(proposalid) {
    return new Promise((resolve, reject) => {
    axios.post(`api/Proposal/DeleteDraft?proposalid=${proposalid}`)
        .then(result => {
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
            resolve(result);
        })
        .catch(error => reject(error));
    })    
}