/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from '../../../shared/hoc/withRouter';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { injectIntl } from "react-intl";
import { withSnackbar } from 'notistack';
import * as companiesCrud from '../../../crud/companies.crud';

const DocumentLogo = (props) => {
    const [logoProps, setLogoProps] = useState(null);
    const fetchLogo = async () => {
        try {
            const { data: logoProperties } = await companiesCrud.getCompanyPicture(props.companyid);
            setLogoProps(logoProperties);
        } catch (err) {
            props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }
    useEffect(() => {
        fetchLogo();
        return () => {
            // this gets called when the component unmounts
        };
    }, [])

    if (!logoProps) {
        //LOADING SPINNER
        return (<div style={{ minHeight: 170, textAlign: "center", alignItems:"center", justifyContent:"center", display:"flex" }}><LoadIndicator /></div>)
    }
    if (props.companyname && logoProps.isReplacement) {
        return (
            <h1 className="uppercase" style={{ clear: 'both', fontSize: '18px', fontFamily: '"Open Sans",sans-serif', color: '#39424a', fontWeight: 700, textAlign: "center" }}>{props.companyname}<span></span></h1>
        )
    }
    return (
        <img alt="Company Logo" style={{ maxHeight: '150px', maxWidth: '300px' }} src={logoProps.image} />
    );
}
export default withRouter(injectIntl(withSnackbar(DocumentLogo)));