import React, { Component } from "react";
import { withRouter } from '../../../../shared/hoc/withRouter';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import SimpleBox from '../../../../partials/content/SimpleBox';
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGrid, { SearchPanel, Paging, ColumnChooser, ColumnFixing, Editing, HeaderFilter, FilterPanel, FilterRow } from 'devextreme-react/data-grid';
import { withSnackbar } from 'notistack';
import * as settingCrud from "../../../../crud/setting.crud";

class System extends Component {
    state = {
        settings: null /*[
          {
              systemsettingid:1,            
              systemname:"WBS",            
              description:"Defines if WBS is tuned on in Quotes and Payment Workflows",            
              Hidden:false,            
              ReadOnly:false,            
              BI_Only:true,            
              lasteditedby:"Tom Levitt",            
              lastediteddate: moment().format('DD/MM/YYYY'),            
              value: true
          },
          {
              systemsettingid:2,            
              systemname:"Smart Links",            
              description:"Defines if Smart Links is tuned on in Main Menu",            
              Hidden:false,            
              ReadOnly:false,            
              BI_Only:true,            
              lasteditedby:"Tom Levitt",            
              lastediteddate: moment().format('DD/MM/YYYY'),            
              value: false
          },
        ]*/,
        showModal: false,
        settingId: null,
        settingName: null,
        settingValue: null,
        settingDescription: null
    };

    componentDidMount() {
        this.fetchSettings();
    }

    fetchSettings = () => {
        settingCrud.getSystemSettings(false)
            .then(response => {
                const settings = response.data;                
                this.setState({ settings });
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }
  
    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            this.props.navigate('settings/' + data.systemsettingid);
        }
    }
  
    showModal = (e) => {
        this.setState({
          showModal: true,
          settingId: e.systemsettingid,
          settingName: e.systemname,
          settingValue: e.value,
          settingDescription: e.description
        });
    };
  
    onModalResponse = (response) => {
      this.setState({ showModal: false }/*, () => { this.updateTag(response); }*/);
    };
  
    render() {
      if (!this.state.settings) {
        return <PortletSpinner />;
      }
  
      return (
        <Portlet>
          <PortletBody>
              <SimpleBox title="Setting Items">
                  <DataGrid
                      dataSource={this.state.settings}
                      keyExpr="systemsettingid"
                      selection={{ mode: "single" }}
                      onSelectionChanged={this.onTableSelectionChanged}
                      hoverStateEnabled={true}
                      rowAlternationEnabled={false}
                      noDataText={'No Data'}
                      showBorders={true}
                      showColumnLines={true}
                      showRowLines={true}
                      remoteOperations={false}
                      allowColumnReordering={true}
                      columnResizingMode='widget'
                      //onContextMenuPreparing={this.addGridContextMenuItems}
                      onRowRemoved={this.onRowRemoved}
                      //onToolbarPreparing={this.state.currentToolbarRenderer}
                      columns={[
                          {
                              caption: "Setting Name",
                              dataField: "name",
                          },
                          {
                              caption: "Enabled",
                              dataField: "enabled",
                          },
                          {
                              caption: "Description",
                              dataField: "description",
                          },
                          {
                              caption: "BI Only",
                              dataField: "bi_only",
                          },
                          {
                              caption: "Read Only",
                              dataField: "readonly",
                          },
                          {
                              caption: "Last Edited Date",
                              dataField: "lastediteddate",
                              dataType: "date",
                          },
                          {
                              caption: "Last Edited By",
                              dataField: "lasteditedby",
                          },
                      ]}
                  >
                    <Editing
                        mode="row"
                        allowUpdating={false}
                        allowDeleting={false}
                        allowAdding={false} />
                    <HeaderFilter visible={true} />
                    <FilterPanel visible={true} />
                    <FilterRow applyFilter="auto" visible={true} />
                    <ColumnChooser enabled={true} />
                    <ColumnFixing enabled={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={false} defaultText="" />
                    <Paging pageSize={10} defaultPageSize={10} defaultPageIndex={0} />
                  </DataGrid>
              </SimpleBox>
          </PortletBody>
        </Portlet>
      );
    }
  }
  
  export default withSnackbar(withRouter(System));
