import * as projectsCrud from "../../crud/projects.crud";
import * as authDuck from './auth.duck';
import store from "../store";
import { select, put, takeLatest } from "redux-saga/effects";

//const getAuth = (store) => store.auth;
const getProject = (store) => store.project
const actionTypes = {
    PROJECT_SET_PENDING: "[PROJECT_SET_PENDING]",
    PROJECT_LOAD_START: "[PROJECT_LOAD_START]",
    PROJECT_SET_PROJECT: "[PROJECT_SET_PROJECT]",
    PROJECT_LOGOUT: "[PROJECT_LOGOUT]"
};

const initialProjectState = {
    projectid: null,
    project: null,
    lastLoadedEpochTime: null,
    pendingFetch: false
};

export const reducer = (state = initialProjectState, action) => {
    switch (action.type) {
        case actionTypes.PROJECT_SET_PENDING: {
            return { ...state, pendingFetch: true, projectid: action.payload.projectid };
        }
        case actionTypes.PROJECT_LOAD_START: {
            if (state.project && state.project.projectid === action.payload.projectid) {
                return { ...state, pendingFetch: false, projectid: action.payload.projectid, lastLoadedEpochTime: new Date().getTime() };
            }
            else {
                return { ...initialProjectState, pendingFetch: false, projectid: action.payload.projectid, lastLoadedEpochTime: new Date().getTime() };
            }
        }
        case actionTypes.PROJECT_SET_PROJECT: {
            return { ...state, project: { ...action.payload.project, projectid: state.projectid } };
        }
        case actionTypes.PROJECT_LOGOUT: {
            return initialProjectState;
        }
        default:
            return state;
    }
};
export const actions = {
    fetchStart: (projectid) => {

        const authStore = store.getState().auth;
        const projectStore = store.getState().project;

        if (authStore) {
            if (!authStore.authToken.token || !authStore.csrfToken.token) {
                if (!projectStore.pendingFetch) {
                    return { type: actionTypes.PROJECT_SET_PENDING, payload: { projectid } }
                }
                return { type: '' };
            }
        }

        return { type: actionTypes.PROJECT_LOAD_START, payload: { projectid } };
    },
    setProject: (project) => ({ type: actionTypes.PROJECT_SET_PROJECT, payload: { project } }),
    logout: () => ({ type: actionTypes.PROJECT_LOGOUT }),
};


export function* saga() {
    yield takeLatest(authDuck.actionTypes.AUTH_LOAD_COMPLETED, function* projectLoginSaga() {
        try {
            let projectStore = yield select(getProject);
            if (projectStore.pendingFetch) {
                yield put(actions.fetchStart(projectStore.projectid));
            }
        }
        catch{ console.log("error in companyLoginSaga") }
    });
    yield takeLatest(actionTypes.PROJECT_LOAD_START, function* projectLoadProjectSaga() {
        try {
            let projectStore = yield select(getProject);
            const { data: projectDetails } = yield projectsCrud.getProject(projectStore.projectid);
            yield put(actions.setProject(projectDetails));
        }
        catch{ }

    });

}

