import React, { Component } from 'react';
import { connect } from "react-redux";
import { Portlet, PortletBody } from "../Portlet";
import PortletSpinner from "../../layout/PortletSpinner";
import DataGrid, {  SearchPanel, Paging,  ColumnChooser, ColumnFixing, Editing, HeaderFilter, FilterRow } from 'devextreme-react/data-grid';
import * as fileCrud from '../../../crud/file.crud';
import { injectIntl } from "react-intl";
import DxDialogModal from '../dxControls/dxDialogModal';
import { listAccountsAllowedDeleteCommunicatedAttachments } from '../../../router/Protector/AccessExceptions';

class SharepointFileGrid extends Component {
    state = {
        data: null,
        files: [],
        showDeleteModal: false,
        file: null,
        fileTitle: null
        //files: null
    }
    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.showModal !== prevprops.showModal) {
            if (!this.props.showModal) {
                this.fetchFiles();
            }
        }
    }
    componentDidMount() {
        this.fetchFiles();
    }
    onModalResponse = (response) => {
        this.setState({ showDeleteModal: false });
        if (response === 'OK') {
            this.onRemoveFile();
        }
        else {
            this.setState({ file: null, fileTitle: null });
        }
    }
    fetchFiles = () => {
        const { companyid, projectid, contractid } = this.props.contract;
        fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.notificationid, this.props.isReply ? this.props.documentType + 'Reply' : this.props.documentType)
            .then(response => {
                const files = JSON.parse(response.data.aggregatelist);
                files.forEach(file => {
                    if (file.metadata) {
                        file.directlink = file.metadata.directlink;
                    }
                });
                this.setState({ files: files });
            })
            .catch(err => {
                console.log(err);
            });
    }

    onRemoveFile = () => {
        fileCrud.deleteExternalFile(this.state.file)
          .then(() => {
              this.fetchFiles();
          })
          .catch(err => {
            console.log(err);
          });
    }
    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            window.open(data.directlink);
        }
    }
    onRowRemoving = (e) => {
        e.cancel = true;

        const { companyid, projectid, contractid } = this.props.contract;
        let file = { ...e.data };
        file.companyid = companyid;
        file.projectid = projectid;
        file.contractid = contractid;
        file.fileid = e.key;
        this.setState({ fileTitle: file.name, file, showDeleteModal: true });
    }
    render() {
        if (!this.state.files) {
            return (<PortletSpinner />);
        }

        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "fileid" },
            { resource: "GENERAL.FORMS.FIELD.NAME", dataField: "name" },
            // { resource: "GENERAL.FORMS.FIELD.REVISION", dataField: "revision" },
            // { resource: "GENERAL.FORMS.FIELD.VERSION", dataField: "version" },
            // { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "status" },
            // { resource: "GENERAL.FORMS.FIELD.MODIFIEDDATE", dataField: "dateModified" },
            // { resource: "GENERAL.FORMS.FIELD.DOCTYPE", dataField: "replystatus", cellRender: this.renderStatusColumn, calculateCellValue: this.calculateReplyStatusValue },
        ];

        return (
            <>
                <Portlet>
                    <PortletBody>
                        <DataGrid
                            //version="1"
                            title={this.props.intl.formatMessage({ id: 'GENERAL.DOCUMENTS.LIST.TITLE' }, { ITEM_NAME: '' })}
                            name={`Sharepont File Attachments`}
                            dataSource={this.state.files}
                            keyExpr="fileid"
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            remoteOperations={false}
                            allowColumnReordering={true}
                            columnResizingMode='widget'
                            columnAutoWidth={true}
                            noDataText={this.props.intl.formatMessage({ id: 'GENERAL.DOCUMENTS.NO_ATTACHMENT' })}
                            onRowRemoving={this.onRowRemoving}
                            selection={{ mode: "single" }}
                            hoverStateEnabled={true}
                            rowAlternationEnabled={false}
                            columns={columns}
                            onSelectionChanged={this.onTableSelectionChanged}
                        >
                            <Editing
                                mode="row"
                                allowUpdating={false}
                                allowDeleting={!this.props.isFinal || listAccountsAllowedDeleteCommunicatedAttachments.includes(this.props.userProfile.accounttype) ? true : false}
                                allowAdding={false}
                                confirmDelete={false}
                                texts={{ deleteRow: this.props.intl.formatMessage({ id: 'GENERAL.DOCUMENTS.REMOVE' }) }}
                            />
                            <HeaderFilter visible={true} />
                            {/* <FilterPanel visible={true} /> */}
                            <FilterRow applyFilter="auto" visible={true} />
                            <ColumnChooser enabled={true} />
                            <ColumnFixing enabled={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={false} defaultText="" />
                            <Paging pageSize={10} defaultPageSize={10} defaultPageIndex={0} />
                        </DataGrid>
                    </PortletBody>
                </Portlet>
                <DxDialogModal
                    visible={this.state.showDeleteModal}
                    title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DELETE_ATTACHMENT' }, { ITEM_NAME: this.state.fileTitle })}
                    text={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DELETE_ATTACHMENT_DETAIL_2' }, { ITEM_NAME: this.state.fileTitle })}
                    buttonOptions={{ text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE' }), type: 'danger' }}
                    onDialogResult={this.onModalResponse}
                />
            </>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    userProfile: store.auth.userProfile
});

export default injectIntl(connect(mapStateToProps)(SharepointFileGrid));
