import React from "react";
import { useDispatch } from "react-redux";
import { signOut } from "supertokens-auth-react/recipe/session";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/error/error-5.scss";
import { useNavigate } from "react-router-dom";
import * as authDuck from "../../store/ducks/auth.duck";
import { useAuth0 } from "@auth0/auth0-react";


export function ErrorPage4() {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  // const { user, isAuthenticated, loginWithRedirect, logout, isLoading, error, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const clearSessionAndLogout = async () => {
    try {
      if (process.env.REACT_APP_SSO_METHOD === 'SUPERTOKEN' || process.env.REACT_APP_SSO_METHOD === 'DISCOVER') {
        console.log("Supertoken logout in progress");
        await signOut();
      }
    } catch (err) {
      console.log(err)
    }

    console.log("Clearing cache");
    dispatch(authDuck.actions.logout());
    setTimeout(() => {
      navigate("/auth");
    }, 2000);

  }
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v5"
          style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/error/bg5.jpg")})`
          }}
        >
          <div className="kt-error_container">
            <span className="kt-error_title">
              <h1>Oops!</h1>
            </span>
            <p className="kt-error_subtitle">Looks like your session is invalid.</p>
            <p className="kt-error_description">
              You have successfully been authenticated but it looks like  <br />
              you don't have an active account in FastDraft.
              <br />
              <br />
              Please contact your System Administrator.  Alternatively, <br />
              if you are confident you already have an active FastDraft  <br />
              account please try to clear your session and try to log in again.
              <br />
              {
                process.env.REACT_APP_SSO_METHOD === 'FDCONNECTOR' && (
                  <a
                    href={process.env.REACT_APP_AUTH0_LOGOUT_URL}
                    style={{ textDecoration: "underline" }}
                  >
                    Clear session and login again
                  </a>
                )
              }
              {/* {
                process.env.REACT_APP_SSO_METHOD === 'AUTH0' && (
                  <a
                    href={`https://biuat.eu.auth0.com/api/v2/logout?returnTo=${window.location.origin}&client_id=${"fSbr3dgHDm8j4CRoL7bdmL0riMgzvCuT"}`}
                    style={{ textDecoration: "underline" }}
                  >
                    Clear session and login again
                  </a>
                )
              } */}
              {
                process.env.REACT_APP_SSO_METHOD === 'SUPERTOKEN' && (
                  <a
                    href="#logout"
                    style={{ textDecoration: "underline" }}
                    onClick={clearSessionAndLogout}
                  >
                    Clear session and login again
                  </a>
                )
              }
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
