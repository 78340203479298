import React, { Component } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CertificateList from './List';
import CertificateCreate from './Create';
import CertificateDetail from './Detail';

class CertificateRouter extends Component {
    constructor(props) {
        super(props);
        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;
        this.replyPeriod = props.workflowItem.replyPeriod;

        this.notifyingParty = props.workflowItem.notifyingParty;
        this.replyingParty = props.workflowItem.replyingParty;

        this.communicateToUser = props.globalResources[this.replyingParty + "_NAME"];



        this.certTypes = null;

        if (props.mode === 'PM') {
            this.notificationType = 'PM';
            this.documentType = 'DocumentPmCertificate';
            this.certTypes = props.placeholders.find(ph => ph.replacementKey === 'PMCertTypes')
                .values.filter(item => item.isActive === true);
        }
        else if (props.mode === 'SUPPLIER') {
            this.notificationType = 'CON';
            this.documentType = 'DocumentSupplierCertificate';
            this.certTypes = props.placeholders.find(ph => ph.replacementKey === 'SupplierCertTypes')
                .values.filter(item => item.isActive === true);
        }
        else if (props.mode === 'CLIENT') {
            this.notificationType = 'CLI';
            this.documentType = 'DocumentClientCertificate';
            this.certTypes = props.placeholders.find(ph => ph.replacementKey === 'ClientCertTypes')
                .values.filter(item => item.isActive === true);
        }
        else if (props.mode === 'SUPERVISOR') {
            this.notificationType = 'SUP';
            this.documentType = 'DocumentSupervisorCertificate';
            this.certTypes = props.placeholders.find(ph => ph.replacementKey === 'SupervisorCertTypes')
                .values.filter(item => item.isActive === true);
        }
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        const { workflowItem } = this.props;

        return (
            <Routes caseSensitive>
                <Route path={workflowItem.paths.basePath} exact element={<Navigate replace to={workflowItem.paths.defaultPath} />} />
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<CertificateList
                        {...this.props}
                        certTypes={this.certTypes}
                        mode={this.props.mode}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        notificationType={this.notificationType}
                    />}

                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                            <CertificateCreate
                                {...this.props}
                                mode={this.props.mode}
                                certTypes={this.certTypes}
                                title={this.notificationName}
                                communicateToUser={this.communicateToUser}
                                notificationType={this.notificationType}
                            />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    element={<CertificateDetail
                        {...this.props}
                        replyPeriod={this.replyPeriod}
                        mode={this.props.mode}
                        certTypes={this.certTypes}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        documentType={this.documentType}
                        communicateToUser={this.communicateToUser}
                        notificationType={this.notificationType}
                        notifyUserFilter={workflowItem.notifyUserFilter}
                        contractTemplate={this.props.contractTemplate}
                    />}
                />
            </Routes>
        );
    }
};



export default (CertificateRouter);