import React from 'react';
import { Popup, ToolbarItem as PopupToolbarItem } from 'devextreme-react/popup';
import { SelectBox, TextArea } from 'devextreme-react';
import DxOrderList from '../../../../../../partials/content/dxControls/dxOrderList';
import DxGroupBox from '../../../../../../partials/content/dxControls/dxGroupBox';
import { withSnackbar } from 'notistack';
import { confirm } from 'devextreme/ui/dialog';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Badge } from "react-bootstrap";
import { injectIntl } from "react-intl";

const emptyPlaceHolder = {
    // id: 0,
    replacementKey: '',
    values: [],
}
const resourcesMaxLength = 500;

class PHModalResources extends React.Component {
    constructor() {
        super();
        this.state = {
            placeholder: { ...emptyPlaceHolder },
            drpOptions: null,
            value: null,
            phChanged: false,
            valueChanged: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.item !== this.props.item) {
                if (this.props.item && this.props.item.replacementKey) {
                    const phItemCopy = JSON.parse(JSON.stringify(this.props.item));
                    const existingResources = phItemCopy.values.map(rsc => rsc.field);
                    const { defaultPH } = this.props;
                    const drpOptions = defaultPH.values.filter(rsc => !existingResources.includes(rsc.field));
                    this.setState({
                        placeholder: phItemCopy,
                        drpOptions,
                        value: null,
                        phChanged: false,
                        valueChanged: false
                    });
                }
                else {
                    this.setState({ placeholder: { ...emptyPlaceHolder }, value: null, phChanged: false, valueChanged: false });
                }
            }
        }
    }

    btnUpdateValue_Clicked = () => {
        const newValue = { ...this.state.value };
        const newValues = [...this.state.placeholder.values];
        if (!newValue.field) {
            this.props.enqueueSnackbar("FIELD is required!", { variant: 'error', });
            return;
        }
        if (newValue.display.length >= resourcesMaxLength) {
            this.props.enqueueSnackbar(`The display value exceeds the max length of ${resourcesMaxLength}`, { variant: 'error', });
            return;
        }

        if (newValue.id > 0) {
            newValues[newValues.findIndex(val => val.id === newValue.id)] = newValue;
        }
        else {
            if (newValues.findIndex(val => val.field === newValue.field) !== -1) {
                this.props.enqueueSnackbar("FIELD already exist!", { variant: 'error', });
                return;
            }
            let maxId = 1;
            if (newValues.length > 0) {
                maxId = Math.max(...newValues.map(val => val.id));
            }
            newValue.id = maxId + 1;
            newValues.push(newValue);
        }
        this.setState({ placeholder: { ...this.state.placeholder, values: newValues }, value: null, phChanged: true, valueChanged: false });
    }
    onListUpdated = (newList) => {
        const { defaultPH } = this.props;
        if (newList && defaultPH) {
            const existingResources = newList.map(rsc => rsc.field);
            const drpOptions = defaultPH.values.filter(rsc => !existingResources.includes(rsc.field));
            this.setState({ placeholder: { ...this.state.placeholder, values: JSON.parse(JSON.stringify(newList)) }, drpOptions, phChanged: true });
        }
    }
    onItemSelected = (item) => {
        //SET TO NULL FIRST TO UN-MOUNT COMPONENTS
        //FRESH START
        this.setState({ value: null, valueChanged: false }, () => {
            this.setState({ value: JSON.parse(JSON.stringify(item)) });
        })

    }

    onClose = async () => {
        if (this.props.item) {
            if (this.state.phChanged) {
                let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}</div>`, this.props.intl.formatMessage({ id: 'GENERAL.WARNING.LEAVE_CHANGES_TITLE' }));
                if (!result) {
                    return;
                }

            }
            this.setState({ placeholder: { ...emptyPlaceHolder }, value: null, valueChanged: false, phChanged: false });
            this.props.onClose();
        }
    }
    savePlaceholder = () => {
        const retVal = JSON.parse(JSON.stringify(this.state.placeholder));
        //CLEAR ANY EDITOR OPTIONS
        delete retVal.skipDefaultLoadingIfMissing;
        this.props.onClose(retVal);
        this.setState({ placeholder: { ...emptyPlaceHolder }, value: null });
    }

    renderListItem = (item) => {

        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Badge variant="secondary">{item.field}</Badge>
                <div style={{ marginLeft: 5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{`${item.display}`}</div>
            </div>
        );
    }
    dropItemSelected = (e) => {
        if (e.value) {
            const newListDrpOptions = [...this.state.drpOptions];
            const newValues = [...this.state.placeholder.values];
            const newValue = { ...e.value };

            newListDrpOptions.splice(newListDrpOptions.findIndex(rsc => rsc.field === newValue.field), 1);

            let maxId = 1;
            if (newValues.length > 0) {
                maxId = Math.max(...newValues.map(val => val.id));
            }
            newValue.id = maxId + 1;
            newValues.push(newValue);

            this.setState({ placeholder: { ...this.state.placeholder, values: newValues }, value: null, drpOptions: newListDrpOptions, phChanged: true });
        }
    }

    render() {
        return (
            <Popup
                visible={this.props.item ? true : false}
                onHiding={this.onClose}
                dragEnabled={false}
                showCloseButton={false}
                hideOnOutsideClick={false}
                showTitle={true}
                title={"Edit Resources"}
                width={1200}
                height='80vh'
                minHeight={600}
            >
                <PopupToolbarItem
                    widget="dxButton"
                    toolbar="top"
                    location="after"
                    options={{ text: "Cancel", onClick: this.onClose }}>
                </PopupToolbarItem>
                <PopupToolbarItem
                    widget="dxButton"
                    toolbar="top"
                    location="after"
                    disabled={!this.state.phChanged}
                    options={{
                        text: 'Save Changes',
                        type: "default",
                        stylingMode: this.state.phChanged ? "contained" : "outlined",
                        onClick: this.savePlaceholder
                    }}>
                </PopupToolbarItem>
                <div style={{ display: 'flex', flexDirection: 'column', height: "100%" }}>
                    <div style={{ display: 'flex', flexDirection: "row", height: "100%" }}>
                        <div style={{ width: "50%" }}>
                            <DxGroupBox showBorder={true}                    >
                                <SelectBox dataSource={this.state.drpOptions}
                                    keyExpr="field"
                                    displayExpr="field"
                                    searchEnabled={true}
                                    searchMode="contains"
                                    searchExpr="field"
                                    searchTimeout={200}
                                    minSearchLength={0}
                                    showDataBeforeSearch={true}
                                    onValueChanged={this.dropItemSelected}
                                    value={null}
                                    placeholder="Select Available Resources"
                                    readOnly={this.props.readOnly}
                                />
                                <DxOrderList
                                    items={this.state.placeholder.values}
                                    // keyExpr="id"
                                    displayExpr="display"
                                    listChanged={this.onListUpdated}
                                    onItemClick={this.onItemSelected}
                                    itemRender={this.renderListItem}
                                    readOnly={this.props.readOnly}
                                />
                            </DxGroupBox>
                        </div>
                        {
                            this.state.value ? (
                                <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: 10, width: "50%", margin: 10, display: "flex", flexDirection: "column" }}>
                                    <div style={{ margin: 10 }}>
                                        <Toolbar>
                                            <ToolbarItem location="before">
                                                <div>
                                                    <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">
                                                        {(this.state.value.field ? this.state.value.field : "select an item")}
                                                    </h3>
                                                </div>
                                            </ToolbarItem>
                                            <ToolbarItem location="after"
                                                locateInMenu="never"
                                                widget="dxButton"
                                                disabled={this.props.readOnly || !this.state.valueChanged}
                                                options={
                                                    {
                                                        type: "default",
                                                        stylingMode: this.state.valueChanged ? "contained" : "outlined",
                                                        icon: "save",
                                                        onClick: this.btnUpdateValue_Clicked,
                                                        text: "Update Resource",
                                                    }
                                                }
                                            />
                                        </Toolbar>
                                    </div>
                                    <div style={{ flexGrow: 1, minHeight: 0 }}>
                                        <span className="dx-field-item-label-text dx-box-item-content dx-widget">Display Text</span>
                                        <div className="dx-field" style={{ marginTop: "10px" }}>
                                            <TextArea
                                                id={this.state.value.id}
                                                height={60}
                                                placeholder="Display text"
                                                disabled={!this.state.value.id}
                                                value={this.state.value.display}
                                                onValueChanged={(e) => this.setState({ value: { ...this.state.value, display: e.value }, valueChanged: true })}
                                                readOnly={this.props.readOnly}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ padding: 10, width: "50%", margin: 10, textAlign: "center" }}>Select an item...</div>
                            )
                        }
                    </div>
                </div>
            </Popup>
        );
    }
}


export default injectIntl(withSnackbar(PHModalResources));