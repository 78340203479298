import axios from '../shared/axios';

export function getTag(tagid) {
    return axios.get(`/api/Tag/GetTag?tagid=${tagid}`);
}

export function getTags(companyid, projectid, contractid) {
    return axios.get(`/api/Tag/LoadTagList`);
}

export function getTagsByCategory(categories) {
    return axios.post(`/api/Tag/LoadTagsByCategory`, categories);
}

export function GetTagsForCategory(categories,companyid) {
    return axios.post(`/api/Tag/GetTagsForCategory`,categories, { params: { companyid } });
}


export function createTag(formData) {
    return axios.post('/api/Tag/CreateTag', formData);
}

export function updateTag(formData) {
    return axios.post('/api/Tag/UpdateTag', formData);
}

export function deleteTag(tagid) {
    return axios.delete('/api/Tag/DeleteTag', { params: { tagid } });
}

export function getTagAssociation(referenceid, referencetype) {
    return axios.get(`/api/TagAssociation/GetTagAssociation?referenceid=${referenceid}&referencetype=${referencetype}`);
}

export function createTagAssociation(formData) {
    return axios.post('/api/TagAssociation/CreateTagAssociation', formData);
}

export function removeTagAssociation(formData) {
    return axios.post('/api/TagAssociation/RemoveTagAssociation', formData);
}