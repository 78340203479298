import React from 'react';
import { Badge } from "react-bootstrap";
import TreeView from 'devextreme-react/tree-view';
import RelationItemsRegister from './RelationItemsRegister';

// const TabContent = props => {
//     return (<div style={{ paddingBottom: 50, height: '100%' }} >Test</div>);
// }
class RelationModules extends React.Component {
    constructor(props) {
        super(props);
        this.treeViewRef = React.createRef();
        const treeItems = this.generateTreeIteems(props.relatedItems);

        this.state = {
            treeItems: treeItems,
            selectedWF: null
        };
    }
    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.relatedItems !== prevprops.relatedItems) {
            let treeItems = [];
            if (this.props.relatedItems && this.props.relatedItems.length > 0) {
                treeItems = this.generateTreeIteems(this.props.relatedItems);
            }
            this.setState({ treeItems: treeItems, selectedWF: null });
        }
    }
    generateTreeIteems = (relatedItems) => {
        let items = this.props.workflowGroups.map(WfGroup => {
            const availableWorkflows = WfGroup.workflows.filter(wf => this.props.availableWorkflowIds.includes(wf.id));
            //Notifyable Workflows are the ones contains notifications
            const groupChildren = relatedItems ? relatedItems.filter(x => x.wfgid === WfGroup.id) : [];
            return {
                id: WfGroup.id,
                title: WfGroup.title,
                itemType: 'WFG',
                expanded: groupChildren.length > 0,
                items: availableWorkflows.map(workflow => {
                    const wfChildren = groupChildren.filter(x => x.wfid === workflow.id)
                        .map(item => ({ ...item, disabled: true, itemType: 'NOTIFICATION' }));
                    const selectedKeys = wfChildren.map(x => x.id);
                    return {
                        parentid: WfGroup.id,
                        id: workflow.id,
                        title: workflow.title,
                        itemType: 'WF',
                        model: workflow.config.currentModel,
                        type: workflow.config.currentModelType,
                        selectedKeys: selectedKeys,
                        expanded: wfChildren.length > 0,
                        path: workflow.paths.basePath,
                        items: wfChildren,
                        referenceType: workflow.config.referenceType
                    };
                })
            };
        });
        items = items.filter(WfGroup => WfGroup.items.length > 0);
        return items;
    }
    onItemClick = (e) => {
        if (e.itemData.itemType === 'WF') {
            this.setState({ selectedWF: { ...e.itemData } });
        }
    }

    onGridSelectionChanged = (selectedRowKeys, selectedItems) => {
        const newTreeItems = [...this.state.treeItems];
        const treeWFItem = newTreeItems.find(wfg => wfg.id === this.state.selectedWF.parentid)
            .items.find(wf => wf.id === this.state.selectedWF.id);

        treeWFItem.selectedKeys = [...selectedRowKeys];
        treeWFItem.items = selectedItems.map(notification => (
            {
                ...notification,
                itemType: 'NOTIFICATION',
                selected: true,
                disabled: true,
                title: this.state.selectedWF.title,
                referenceType: this.state.selectedWF.referenceType,
                creationsource: notification.creationsource ? notification.creationsource : 2
            }));
        treeWFItem.expanded = true;
        this.props.onChange(newTreeItems);
        this.setState({ treeItems: newTreeItems });
    }
    renderTreeViewItem = (item) => {
        let style = {};
        let badge = null;
        let displatText = '';
        if (this.state.selectedWF && item.id === this.state.selectedWF.id) {
            style = { fontWeight: 'bold' };
        }
        if (item.itemType === 'WFG') {
            displatText = item.title;
            if (item.items && item.items.length > 0) {
                const selectedChildrenCount = item.items.reduce((total, wfItem) => total + (wfItem.selectedKeys ? wfItem.selectedKeys.length : 0), 0);
                if (selectedChildrenCount > 0) {
                    badge = <Badge variant="secondary">{selectedChildrenCount}</Badge>;
                }
            }
        }
        else if (item.itemType === 'WF') {
            displatText = item.title;
            if (item.selectedKeys && item.selectedKeys.length) {
                badge = <Badge variant="secondary">{item.selectedKeys.length}</Badge>;
            }
        }
        else if (item.itemType === 'NOTIFICATION') {
            displatText = item.id;
            style = { color: '#337ab7', fontWeight: 600 };
        }
        return (
            <div style={style} className="relationTreeRow">
                {`${displatText}`}<div style={{ marginLeft: 5 }}>{badge}</div>
            </div>
        );
    }
    render() {
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                <TreeView
                    id="relation-treeview"
                    style={{ border: '1px solid #ccc', borderRadius: '10px' }}
                    ref={this.treeViewRef}
                    activeStateEnabled={false}
                    // width={340}
                    // height='50vh'
                    items={this.state.treeItems}
                    // rootValue={0}
                    // createChildren={this.createChildren}
                    searchMode="contains"
                    searchEnabled={true}
                    // selectNodesRecursive={true}
                    // selectByClick={false}
                    // dataStructure="plain"
                    // showCheckBoxesMode="normal"
                    selectionMode="single"
                    // onItemSelectionChanged={this.selectionChangeHandler}
                    // onContentReady={this.treeViewContentReady}
                    onItemClick={this.onItemClick}
                    itemRender={this.renderTreeViewItem}
                    // itemsExpr="workflows"
                    keyExpr="id"
                    parentIdExpr="parentid"
                    displayExpr="title"
                />
                <div id="relation-details">
                    <RelationItemsRegister
                        item={this.state.selectedWF}
                        onSelectionChanged={this.onGridSelectionChanged}
                        isCurrentWF={this.state.selectedWF && this.props.currentWFid === this.state.selectedWF.id}
                        currentNotificationID={this.props.currentNotificationID}
                        relatedItems={this.props.relatedItems}
                    />
                </div>
            </div>


            // <TabPanel
            //     width="100%"
            //     height="100%"
            //     style={{ paddingBottom:20}}
            //     deferRendering={true}
            //     animationEnabled={false}
            //     focusStateEnabled={true}
            //     hoverStateEnabled={true}
            //     swipeEnabled={false}
            //     // scrollByContent={false}
            //     showNavButtons={true}
            //     >
            //     {
            //         WFS.map((title, index) => <Item key={index.toString()} badge={"1"} title={title} component={TabContent} />)
            //     }
            // </TabPanel>
        );
    }
}
// const mapStateToProps = store => ({
//     contractNotifications: store.preferences.contractNotifications,
//     contract: store.contract.contract,
//     userProfile: store.auth.userProfile,
// });

export default RelationModules;
