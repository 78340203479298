import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { Badge } from "react-bootstrap";
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper";
import * as inspectionDefectCrud from '../../../../crud/inspectionDefect.crud';
import { notificationAggregateListTagsFilter, notificationUniqueTagsFilter } from "../../../../store/helpers/FilterHelper";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

class DefectListPage extends React.Component {
    constructor(props) {
        super(props);

        this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.notificationsRelsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';
        this.customFields = [];
        if (props.customFields) {
            if (props.customFields.COMMUNICATION && props.customFields.COMMUNICATION.length > 0) {
                this.customFields.push(...(props.customFields.COMMUNICATION.filter(cf => cf.includelisting)));
            }
            if (props.customFields.REPLY && props.customFields.REPLY.length > 0) {
                this.customFields.push(...(props.customFields.REPLY.filter(cf => cf.includelisting)));
            }
        }
        this.state = {
            notifications: null,
            tags: null
        };
    }

    componentDidMount() {
        this.fetchNotifications();
    }

    fetchNotifications = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const {data: responseObj } = await inspectionDefectCrud.getContractInspections(companyid, projectid, contractid, this.props.notificationType, this.props.accessibleWfIds);
            let listData = notificationAggregateListTagsFilter(JSON.parse(responseObj.aggregatelist));
            listData = (this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed === true) ?
                listData :
                listData.filter(ntf => ntf.showtasknotification === 1);

            let tagFilters = notificationUniqueTagsFilter(JSON.parse(responseObj.uniquetags));

            this.setState({ notifications: listData, tags: tagFilters });
        } catch (error) {
            console.log(error);
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }

    }

    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        return  (skipHostUrl ? '' : process.env.PUBLIC_URL) +this.props.workflowItem.paths.basePath + rowData.inspectiondefectid;
    }

    renderStatusColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        //let label = cellInfo.data.replystatus;
        switch (cellInfo.data.replystatus) {
            case 'Awaiting Reply':
            case 'Awaiting Acceptance':
                badgeVariant = 'warning';
                break;
            case 'Not Communicated':
                badgeVariant = 'secondary';
                break;
            case 'Uncorrected':
                //label = 'Open'
                badgeVariant = 'danger';
                break;
            case 'Not Accepted':
                badgeVariant = 'danger';
                break;
            case 'Corrected':
                //label = 'Closed'
                badgeVariant = 'success';
                break;
            case 'Reply Received':
            case 'Accepted':
                badgeVariant = 'success';
                break;
            default:
                break;
        }
        const displayText = this.calculateStatusValue(cellInfo.data);
        return (<Badge variant={badgeVariant}>{displayText}</Badge>);
    }
    calculateStatusValue = (rowData) => {
        let status = rowData.replystatus;
        if (status === 'Uncorrected') {
            status = 'Open';
        }
        if (status === 'Corrected') {
            status = 'Closed';
        }
        return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + status });
    }
    calculateNCProposalStatusValue = (rowData) => {
        if (rowData.location) {
            if ((this.props.NCSubmissionReplyOptions || {}).hasOwnProperty(rowData.location)) {
                return this.props.NCSubmissionReplyOptions[rowData.location];
            }
            else {
                return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + rowData.location });
            }
        }
        else { return null; }

    }
    calculateNonConformityStatusValue = (rowData) => {
        if (rowData.reason) {
            if ((this.props.NonConformityReplyOptions || {}).hasOwnProperty(rowData.reason)) {
                return this.props.NonConformityReplyOptions[rowData.reason];
            }
            else {
                return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + rowData.reason });
            }
        }
        else { return null; }
    }
    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }

        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.SUBJECT", dataField: 'replyactiondetail' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_DATE", dataField: "notificationdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_BY", dataField: "createby" },
            { resource: "GENERAL.FORMS.FIELD.OWNER", dataField: 'replyaction' },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "replystatus", cellRender: this.renderStatusColumn, calculateCellValue: this.calculateStatusValue },
            { resource: "GENERAL.FORMS.FIELD.ACTIONS", dataField: 'replycomment', isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.DETAILS", dataField: "inspectiondetails", isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "CONTRACT.WF.DEFECTS.FIELD.CLOSE_DATE", dataField: 'inspectiondate', dataType: 'date', visible: false },
            { resource: "CONTRACT.WF.DEFECTS.FIELD.CLOSURE_REASON", dataField: "closurereason", visible: false },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false }
        ];
        if (this.props.plannedClosureEnabled || this.props.NCSubmissionWFexist) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PLANNED_CLOSURE", dataField: 'plannedclosuredate', dataType: 'date', visible: false }
            )
        }
        if (this.props.NCSubmissionWFexist) {
            columns.push(
                { resource: "CONTRACT.WF.DEFECTS.FIELD.PROPOSAL_ID", dataField: 'inspectiontime', visible: false },
                { resource: "CONTRACT.WF.DEFECTS.FIELD.POPOSAL_STATUS", dataField: 'location', visible: false, calculateCellValue: this.calculateNCProposalStatusValue },
                { resource: "CONTRACT.WF.DEFECTS.FIELD.NC_ID", dataField: 'assistancetestserchdefect', visible: false },
                { resource: "CONTRACT.WF.DEFECTS.FIELD.NC_STATUS", dataField: 'reason', visible: false, calculateCellValue: this.calculateNonConformityStatusValue },
                { resource: "CONTRACT.WF.DEFECTS.FIELD.EFFECTIVE_DATE", dataField: 'replydate', dataType: 'date', visible: false }
            )
        }
        if (this.props.workflowItem.assetFieldsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.ASSET_NAME", dataField: "assetname", visible: false },
                { resource: "GENERAL.FORMS.FIELD.ASSET_TYPE", dataField: "assettype", visible: false },
            );
        }
        if (this.notificationsTagsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.PortfolioFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProgrammeFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProjectFilter } },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.OtherFilter } }
            )
        }
        if (this.notificationsRelsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.TAB_RELATIONS", dataField: "relatednotices", visible: false },
            )
        }
        columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(this.customFields));

        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="1"
                        title={this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.TITLE2' }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                        createPath={this.props.workflowItem.userFilters.mainNotice.createAllowed ? this.props.workflowItem.paths.createPath : null}
                        workflowItem={this.props.workflowItem}
                        name={this.props.workflowItem.pageTitle}
                        dataSource={this.state.notifications}
                        keyExpr="inspectiondefectid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}


export default withListPageProps(DefectListPage);
