import React from 'react';
import WorkflowGroupDetails from './CustomControls/WorkflowGroupDetails';
import DxGroupBox from '../../../../partials/content/dxControls/dxGroupBox';
import DxOrderList from '../../../../partials/content/dxControls/dxOrderList';
import { SelectBox } from 'devextreme-react/select-box';
import TemplateHelper from "../../../../Templates/TemplateHelper";
import { confirm } from 'devextreme/ui/dialog';
import { Badge } from "react-bootstrap";
import { injectIntl } from "react-intl";

class MasterWorkflowsDetails extends React.Component {
    constructor(props) {
        super(props);
        this.defaultWorkflowGroups =  TemplateHelper.getDefaultSanitizedTemplate().workflowGroups;

        let listDrpOptions = null;
        if (props.mode === 'WORKFLOW') {
            listDrpOptions = this.defaultWorkflowGroups.filter(wfg => (props.workflowGroups.findIndex(selectedwfg => selectedwfg.id === wfg.id) === -1));
        }
        else {
            listDrpOptions = props.allWorkflowGroups.filter(wfg => (props.workflowGroups.findIndex(selectedwfg => selectedwfg.id === wfg.id) === -1));
        }
        this.state = {
            workflowGroups: JSON.parse(JSON.stringify(props.workflowGroups)),
            selectedWFG: null,
            drpOptions: listDrpOptions,
            changeInWFG: false
        };
    }
    // componentDidMount() {
    //     if (this.props.workflowGroups) {
    //         const newList = JSON.parse(JSON.stringify(this.props.workflowGroups));
    //         this.setState({ workflowGroups: newList });
    //     }
    // }
    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps) {
    //         if (prevProps.workflowGroups !== this.props.workflowGroups) {
    //             if (this.props.workflowGroups) {
    //                 const newList = JSON.parse(JSON.stringify(this.props.workflowGroups));
    //                 this.setState({ workflowGroups: newList, selectedWFG: { ...blankWorkflowGroup } });
    //             }
    //         }
    //     }
    // }
    dropItemSelected = (e) => {
        if (e.value) {
            const newListDrpOptions = [...this.state.drpOptions];
            newListDrpOptions.splice(newListDrpOptions.findIndex(wf => wf.id === e.value.id), 1);

            const newWorkflows = [...this.state.workflowGroups];
            e.value.changed = true;
            newWorkflows.push(JSON.parse(JSON.stringify(e.value)));
            this.setState({ workflowGroups: newWorkflows, drpOptions: newListDrpOptions, selectedWFG: null, changeInWFG: false }, this.updateParent);
        }
    }
    selectionChangeValidation = async () => {
        if (this.state.changeInWFG) {
            let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}</div>`, this.props.intl.formatMessage({ id: 'GENERAL.WARNING.LEAVE_CHANGES_TITLE' }));
            return result;
        }
        return true;
    }
    onWorkflowGroupSelected = async (item) => {
        const selectedItem = JSON.parse(JSON.stringify(item));
        this.setState({ selectedWFG: selectedItem, changeInWFG: false });
    }

    saveWorkflowGroupHandler = (newWFG) => {
        newWFG.changed = true;
        if (newWFG.id === 0) {
            const maxId = Math.max(...this.state.workflowGroups.map(wfg => wfg.id));
            const newItem = JSON.parse(JSON.stringify(newWFG));
            newItem.id = maxId + 1;

            const newWorkflows = JSON.parse(JSON.stringify(this.state.workflowGroups));
            newWorkflows.push(newItem);
            this.setState({ workflowGroups: newWorkflows, selectedWFG: null, changeInWFG: false }, this.updateParent);
        }
        else {
            const newWorkflows = JSON.parse(JSON.stringify(this.state.workflowGroups));
            newWorkflows[newWorkflows.findIndex(wf => wf.id === newWFG.id)] = JSON.parse(JSON.stringify(newWFG));
            this.setState({ workflowGroups: newWorkflows, selectedWFG: null, changeInWFG: false }, this.updateParent);
        }

    }

    onWorkflowGroupsChanged = (newList) => {
        let newListDrpOptions = null;
        if (this.props.mode === 'WORKFLOW') {
            newListDrpOptions = this.defaultWorkflowGroups.filter(wfg => (newList.findIndex(selectedwfg => selectedwfg.id === wfg.id) < 0));
        }
        else {
            newListDrpOptions = this.props.allWorkflowGroups.filter(wfg => (newList.findIndex(selectedwfg => selectedwfg.id === wfg.id) < 0));
        }
        this.setState({ workflowGroups: JSON.parse(JSON.stringify(newList)), drpOptions: newListDrpOptions, selectedWFG: null, changeInWFG: false }, this.updateParent);

    }
    updateParent = () => {
        this.props.onChange(this.state.workflowGroups.map(itm => ({ ...itm })));
    }
    renderListItem = (item) => {

        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 'auto' }}>{`${item.id} | ${item.title}`}</div>
                {
                    //C-BADGE if any changes
                    (item.changed) && (
                        <Badge variant="warning">C</Badge>
                    )
                }
            </div>
        );
    }
    render() {
        return (

            <div style={{ display: 'flex', height: '100%' }}>
                <DxGroupBox
                    maxWidth={360}
                    width='34%'
                    title="Workflow Groups"
                // toolbar={this.props.mode === 'WORKFLOW' && (
                //     <Button
                //         type="normal"
                //         stylingMode="contained"
                //         icon="plus"
                //         text="New"
                //         onClick={() => this.setState({ selectedWFG: { ...blankWorkflowGroup } })} />
                // )}
                >

                    <SelectBox dataSource={this.state.drpOptions}
                        displayExpr={(wfg) => { return wfg && `${wfg.id} | ${wfg.title}`; }}
                        searchEnabled={true}
                        searchMode="contains"
                        searchExpr="title"
                        searchTimeout={200}
                        minSearchLength={0}
                        showDataBeforeSearch={true}
                        onValueChanged={this.dropItemSelected}
                        value={null}
                        readOnly={this.props.readOnly}
                    />
                    <DxOrderList
                        items={this.state.workflowGroups}
                        keyExpr="id"
                        displayExpr="title"
                        listChanged={this.onWorkflowGroupsChanged}
                        onItemClick={this.onWorkflowGroupSelected}
                        selectionChangeValidation={this.selectionChangeValidation}
                        itemRender={this.renderListItem}
                        readOnly={this.props.readOnly}
                    />

                </DxGroupBox>
                <div style={{ width: 10 }}></div>
                <DxGroupBox
                    maxWidth={530}
                    width='64%'
                >
                    <WorkflowGroupDetails
                        mode={this.props.mode}
                        workflowGroup={this.state.selectedWFG}
                        allWorkflowGroups={this.props.allWorkflowGroups}
                        onChange={() => this.setState({ changeInWFG: true })}
                        saveHandler={this.saveWorkflowGroupHandler}
                        readOnly={this.props.readOnly}
                    />
                </DxGroupBox>
            </div >
        );
    }
}

export default injectIntl(MasterWorkflowsDetails);

