import { userFilterCategories } from "../../../shared/lookup";
const workflows = [

    {
        id: 30,
        title: '$PM_NAME Meetings',
        notificationName: 'Meeting',
        abbreviation: '$PM_ABBREVIATION-Meeting-',
        notifyingParty: 'PM',
        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Meetings',
            currentModel: 'InspectionDefect',
            currentModelType: 'PMMeeting',
            referenceType: 'Meeting',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 31,
        title: '$SUPPLIER_NAME Meetings',
        notificationName: 'Meeting',
        abbreviation: '$SUPPLIER_ABBREVIATION-Meeting-',
        notifyingParty: 'SUPPLIER',
        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Meetings',
            currentModel: 'InspectionDefect',
            currentModelType: 'ContractorMeeting',
            referenceType: 'Meeting',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supplier',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 32,
        title: '$SUPPLIER_NAME Site Diary',
        notificationName: 'Site Diary',
        abbreviation: 'Diary-',
        notifyingParty: 'SUPPLIER',
        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Site Diary',
            currentModel: 'InspectionDefect',
            currentModelType: 'SiteDiary',
            referenceType: 'SiteDiary',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'site-diary',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 33,
        title: 'Weather',
        notificationName: 'Weather',
        abbreviation: 'Weather-',
        notifyingParty: 'SUPPLIER',
        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Weather',
            currentModel: 'InspectionDefect',
            currentModelType: 'Weather',
            referenceType: 'Weather',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'weather',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },

]

export default workflows;