

export const listAccountsAllowedRoleSwitch = ["System Owner", "System Administrator", "Site Administrator", "Site Support", "Super User", "Contract Administrator", "User Administrator"];
export const listAccountsAllowedDeleteCommunicatedAttachments = ["System Owner", "System Administrator", "Site Administrator"];
export const listAccountsAllowedEditSystemGeneratedRelations = ["System Owner", "System Administrator"];
export const listAccountsAllowedEditTemplateDefaultFlag = ["System Owner", "System Administrator", "Site Administrator"];
export const listAccountsAllowedEditTemplatePlaceholderField = ["System Owner", "System Administrator", "Site Administrator", "Site Support"];
export const listAccountsAllowedViewReportSystemUser = ["System Owner", "System Administrator", "Site Administrator", "Site Support", "User Administrator"];
export const listAccountsAllowedViewReportHubUser = ["System Owner", "System Administrator", "Site Administrator", "Site Support", "Contract Administrator", "Super User", "User Administrator"];
export const listAccountsAllowedViewReportContractUser = ["System Owner", "System Administrator", "Site Administrator", "Site Support", "Contract Administrator", "Super User", "User Administrator"];
export const listAccountsAllowedContractUsersInDomo = ["System Owner", "System Administrator", "Site Administrator", "Site Support", "Contract Administrator", "Super User", "User Administrator"];
export const listAccountsAllowedDeleteDraft = ["System Owner", "System Administrator"];
export const listAccountsAllowedGenerateSystemDefaultTemplates = ["System Owner"];
export const listAccountsAllowedExportImportDocumentJSON = ["System Owner", "System Administrator"];
export const listAccountsAllowedEditSecondaryAfterAmended = ["System Owner"];
export const listAccountsAllowedDeleteCompletedAction = ["System Owner", "System Administrator","Site Administrator"];
