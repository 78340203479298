import React from 'react';
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import BiDynamicForm from '../../../partials/layout/BiDynamicForm';
import { withCreatePageProps } from '../../../shared/hoc/withCreatePageProps';
import BaseCreatePage from '../../contract/_CustomComponents/BaseCreatePage';

class BookmarkPageFirst extends BaseCreatePage {
    constructor(props) {
        super(props);

        this.createFormConfig = {
            buttonResource: "GENERAL.FORMS.POWERBI_BUTTON_SAVE",
            fields: [
                { resource: "GENERAL.FORMS.POWERBI_BOOKMARK_NAME", dataField: 'bookmark', editorType: 'dxTextBox', required: true }
            ]
        };
    }

    submitHandler = (item) => {
        this.props.onBookmarkCaptured(item.bookmark);
        this.props.onClose();
    }

    render() {
        return (
            <Portlet>
                <PortletBody style={{ maxWidth: 1001 }}>
                    <BiDynamicForm
                        onSubmitHandler={this.submitHandler}
                        config={this.createFormConfig}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

export default withCreatePageProps(BookmarkPageFirst);