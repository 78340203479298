import React, { Component } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NonConformanceList from "./conformance/List";
import NonConformanceCreate from "./conformance/Create";
import NonConformanceDetail from "./conformance/Detail";

class NonConformanceRouter extends Component {
  constructor(props) {
    super(props);
    this.communicateToUser = this.props.globalResources.PM_NAME;
    this.notificationType = "NC";
    this.documentType = 'DocumentNC';
    this.notifyingParty = props.workflowItem.notifyingParty;

    this.notificationName = props.workflowItem.notificationName;
    this.abbreviation = props.workflowItem.abbreviation;
  }
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { workflowItem } = this.props;

    return (
      <Routes caseSensitive>
        <Route
          path={workflowItem.config.paths.list}
          exact
          element={
            <NonConformanceList
              {...this.props}
              title={this.notificationName}
              abbreviation={this.abbreviation}
              notificationType={this.notificationType}
            />
          }
        />
          <Route
            path={workflowItem.config.paths.create}
            exact
            element={
                workflowItem.userFilters.mainNotice.createAllowed ? (
                    <NonConformanceCreate
                      {...this.props}
                      title={this.notificationName}
                      communicateToUser={this.communicateToUser}
                      notificationType={this.notificationType}
                    />) : (
                    <Navigate replace to={workflowItem.paths.defaultPath} />
                )
            }
        />
        <Route
          path={'/:notificationid/*'}
          exact
          element={
            <NonConformanceDetail
              {...this.props}
              title={this.notificationName}
              abbreviation={this.abbreviation}
              documentType={this.documentType}
              communicateToUser={this.communicateToUser}
              notificationType={this.notificationType}
              notifyUserFilter={workflowItem.notifyUserFilter}
            />
          }
        />

      </Routes>
    );
  }
}



export default (NonConformanceRouter);
