import React, { useEffect, useState } from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as authDuck from "../../../store/ducks/auth.duck";
import { TextBox } from 'devextreme-react';
import Spinner from '../../../partials/layout/Spinner';
import { useAuth0 } from "@auth0/auth0-react";
import * as securityCrud from '../../../crud/security.crud';
import "../../../../_metronic/_assets/sass/pages/login/login-auth0.scss";
import { SuperTokensWrapper } from "supertokens-auth-react";
import SuperTokenHandler from "./SuperTokenHandler";
import { Auth0Provider } from "@auth0/auth0-react";
import Auth0Handler from "./Auth0Handler";
import SuperTokens from "supertokens-auth-react";
import { getSuperTokenConfig } from "../../../shared/config/SuperTokens";
import QueryString from "query-string";


const auth0ProviderConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    // onRedirectCallback,
    authorizationParams: {
        redirect_uri: window.location.origin,
        // audience: "https://biuat.eu.auth0.com/api/v2/",
        // scope: "read:current_user update:current_user_metadata"
    },
};



const DiscoverHandlerSinglePage = (props) => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [error, setError] = useState("");
    const [fetchingTennantID, setFetchingTennantID] = useState(false);
    const [tennantID, setTennantID] = useState("");
    let location = useLocation();
    const queryObj = QueryString.parse(location.search);
    console.log("QueryParams",queryObj)

    const { isAuthenticated, loginWithRedirect, isLoading, getIdTokenClaims } = useAuth0();//getAccessTokenSilently, user
    const setToken = async () => {
        // const encryptedToken = await getAccessTokenSilently(
        //     //     {
        //     //     authorizationParams: {
        //     //       audience: `https://biuat.eu.auth0.com/api/v2/`,
        //     //       scope: "read:current_user update:current_user_metadata",
        //     //     },
        //     //   }
        // );
        // const claims = await getIdTokenClaims();

        // try {
        //     const fdTokenResponse = await securityCrud.getDecryptedToken(claims.__raw);
        //     if (!fdTokenResponse.data.accessToken.includes('Error') && !fdTokenResponse.data.accessToken.includes('error')) {
        //         props.storeSetAuthToken(fdTokenResponse.data.accessToken, fdTokenResponse.data.reportingToken, "", "");
        //     }
        //     else {
        //         console.log('Token decryption error:', fdTokenResponse.data);
        //         props.navigate(`/error/error-v5`);
        //     }
        // }
        // catch (err) {
        //     console.log(err);
        //     if (err.response && err.response.status) {
        //         if (err.response.status === 401) {
        //             navigate(`/error/error-v4`);
        //         }
        //         else {
        //             navigate(`/error/error-v5`);
        //         }
        //     }
        //     else {
        //         navigate(`/error/error-v5`);
        //     }
        // }
    }
    // useEffect(() => {
    //     if (isAuthenticated && !isLoading) {
    //         setToken();
    //     }
    // }, [])

    useEffect(() => {
        if (tennantID === 'NotSet') {

        }
        else if (tennantID.length > 0) {

        }
    }, [tennantID]);


    const continueClicked = async () => {
        try {
            setFetchingTennantID(true);
            const { data: tennantIDResp } = await securityCrud.getCompanyTenantID(username);
            if(tennantIDResp != null && tennantIDResp.length > 0 && tennantIDResp !== 'NotSet'){
                //we have a proper tennant ID response
                const config = getSuperTokenConfig(tennantID);
                console.log("Supertoken started with config",config)
                SuperTokens.init(config);
            }
            setTennantID(tennantIDResp)
            setFetchingTennantID(false);
        } catch (err) {
            setFetchingTennantID(false);
            setError(err.toString());
        }

        // loginWithRedirect();
    }

    // if (isLoading) {
    //     return (
    //         <div style={{ margin: "auto" }}>
    //             <Spinner />
    //         </div>
    //     )
    // }
    // if (!isAuthenticated) {
    if (tennantID === 'NotSet') {
        return (
            <Auth0Provider {...auth0ProviderConfig}  >
                <Auth0Handler />
            </Auth0Provider>
        )

    }
    if (tennantID.length > 0) {
        return (
            <SuperTokensWrapper>
                <SuperTokenHandler />
            </SuperTokensWrapper>
        )
    }
    return (
        <div style={{ margin: "auto" }}>
            <div className='a0-container'>
                <div className='a0-row'>
                    <div>
                        <img src="https://cdn.shopify.com/s/files/1/0248/4926/files/BI_logo_300x90_300x90.png?v=1613712008" alt="Built Intelligence" />
                        <div className='a0-header'>
                            FastDraft Realm Discovery
                        </div>
                    </div>
                    <div className='a0-divider' />
                    <div className="dx-field-value" style={{ width: "100%", marginBottom: 12 }}>
                        <TextBox
                            mode="email"

                            placeholder="Username"
                            value={username}
                            onValueChanged={(e) => setUsername(e.value)}
                            disabled={fetchingTennantID}
                            readOnly={fetchingTennantID}
                            validationError={error}
                        />
                    </div>
                    <div className='a0-button-container'>
                        <span>
                            <button
                                disabled={fetchingTennantID}
                                className={fetchingTennantID ? 'a0-button kt-spinner kt-spinner--center kt-spinner--md kt-spinner--dark' : 'a0-button'}
                                onClick={continueClicked}>
                                <div className='a0-button-text'>
                                    Continue
                                </div>
                            </button>
                        </span>
                    </div>

                </div>
                <a className='a0-branding' href='https://www.builtintelligence.com/' target='_blank' rel='noreferrer'>
                    Power by <strong>Built Intelligence</strong>
                </a>
            </div>

        </div>
    )
    // }

}

const mapDispatchToProps = {
    storeSetAuthToken: authDuck.actions.setAuthToken,
    startLogout: authDuck.actions.startLogout
};

export default connect(null, mapDispatchToProps)(DiscoverHandlerSinglePage);
