import React, { Component } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AssumptionList from "./assumption/List";
import AssumptionCreate from "./assumption/Create";
import AssumptionDetail from "./assumption/Detail";

class AssumptionRouter extends Component {
  constructor(props) {
    super(props);
    this.notificationName = props.workflowItem.notificationName;

    this.communicateToUser = this.props.globalResources.PM_NAME;
    this.notificationType = "Assumptions";
    this.documentType = "DocumentAssumptions";

    this.notifyingParty = props.workflowItem.notifyingParty;
    this.replyingParty = props.workflowItem.replyingParty;

    this.notificationName = props.workflowItem.notificationName;
    this.abbreviation = props.workflowItem.abbreviation;
    this.assumptionsTitle = "Contractor Assumptions";
    this.assumptionsAbbreviation = "Assumptions-";
  }
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const {      workflowItem    } = this.props;

    return (
      <Routes caseSensitive>
        <Route path={workflowItem.paths.basePath} exact element={<Navigate replace to={workflowItem.paths.defaultPath} />} />
        <Route
          path={workflowItem.config.paths.list}
          exact
          element={
            <AssumptionList
              {...this.props}
              
              title={this.notificationName}
              abbreviation={this.abbreviation}
              notificationType={this.notificationType}
            />
          }
        />
        <Route
            path={workflowItem.config.paths.create}
            exact
            element={
                workflowItem.userFilters.mainNotice.createAllowed ? (
                  <AssumptionCreate
                      {...this.props}
                      abbreviation={this.abbreviation}
                      title={this.notificationName}
                      
                      communicateToUser={this.communicateToUser}
                      notificationType={this.notificationType}
                    />) : (
                    <Navigate replace to={workflowItem.paths.defaultPath} />
                )
            }
        />
        <Route
          path={'/:notificationid/*'}
          element={
            <AssumptionDetail
              {...this.props}
              compAbbreviation_PM={this.compAbbreviation_PM}
              contractorAssumptionsTitle={this.contractorAssumptionsTitle}
              assumptionAbbreviation={this.assumptionAbbreviation}
              title={this.notificationName}
              abbreviation={this.abbreviation}
              
              documentType={this.documentType}
              communicateToUser={this.communicateToUser}
              notificationType={this.notificationType}
              ewAbbreviation_SUP={this.ewAbbreviation_SUP}
              ewAbbreviation_PM={this.ewAbbreviation_PM}
              notifyUserFilter={workflowItem.notifyUserFilter}
            />
          }
        />
      </Routes>
    );
  }
}


export default (AssumptionRouter);
