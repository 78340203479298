
import axios from '../shared/axios';
import CrudHelper from "./helper";

export function getPriceList(pricelistid) {
    return axios.get(`/api/PriceList/GetPriceList?pricelistid=${pricelistid}`);
}

export function loadList(contractid) {
    return axios.get(`/api/PriceList/RegisterList?contractid=${contractid}`);
}

export function createPriceList(priceList) {
    return axios.post(`/api/PriceList/Create`, priceList);
}

export function updatePriceList(updatedPriceList) {
    return axios.put(`/api/PriceList/Update`, updatedPriceList);
}

export function deletePriceList(pricelistid) {
    return axios.delete(`/api/PriceList/Delete?pricelistid=${pricelistid}`);
}
