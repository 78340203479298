import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import InspectionList from './List';
import InspectionCreate from './Create';
import InspectionDetail from './Detail';
import Common from '../../../../shared/common';

class InspectionsRouter extends React.Component {
    constructor(props) {
        super(props);
        if (props.mode === 'SUPPLIER') {
            this.notificationType = '41.3';
            this.documentType = 'Document413';
            if (props.workflowItem.notifyingParty === 'SUPERVISOR') {
                this.communicateToUser = props.globalResources.SUPERVISOR_NAME;
                this.documentReplyLogoIDLegacy = Common.GetPartyOrUserCompanyID(props.contract, props.workflowItem.form_CompanyLogo, 'SUPERVISOR', props.userProfile);
            }
            else {
                this.communicateToUser = props.globalResources.PM_NAME;
                this.documentReplyLogoIDLegacy = Common.GetPartyOrUserCompanyID(props.contract, props.workflowItem.form_CompanyLogo, 'PM', props.userProfile);
            }

        }
        else if (props.mode === 'SUPERVISOR') {
            this.notificationType = 'SupervisorTestAndInspection';
            this.documentType = 'DocumentSupTestAndInspection';
            this.communicateToUser = props.globalResources.SUPPLIER_NAME;
        }
        else if (props.mode === 'PM') {
            this.notificationType = 'PMTestAndInspection';
            this.documentType = 'DocumentPMTestAndInspection';
            this.communicateToUser = props.globalResources.SUPPLIER_NAME;
        }

        this.notifyingParty = props.workflowItem.notifyingParty;
        this.replyingParty = props.workflowItem.replyingParty;




        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;
        this.replyPeriod = props.workflowItem.replyPeriod;
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem} = this.props;

        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<InspectionList
                        {...this.props}
                        
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        notificationType={this.notificationType}
                    />}

                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                            <InspectionCreate
                                {...this.props}
                                title={this.notificationName}
                                
                                communicateToUser={this.communicateToUser}
                                notificationType={this.notificationType}
                            />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    exact
                    element={<InspectionDetail
                        {...this.props}
                        mode={this.props.mode}
                        replyPeriod={this.replyPeriod}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                        documentType={this.documentType}
                        communicateToUser={this.communicateToUser}
                        documentReplyLogoIDLegacy={this.documentReplyLogoIDLegacy}
                        notificationType={this.notificationType}
                        notifyUserFilter={workflowItem.notifyUserFilter}
                    />}
                />
            </Routes>
        );
    }
};



const mapStateToProps = store => ({
    //WE NEED userProfile because Common.GetPartyOrUserCompanyID is re-assigned depending on party
    userProfile: store.auth.userProfile
  });
export default  connect(mapStateToProps) (InspectionsRouter);