import axios from '../shared/axios';
// import qs from 'querystring';

export function saveEarlyWarningReduction(data) {
    return axios.post(`/api/EarlyWarningReduction/SaveEarlyWarningReduction`, data);
}

export function getEarlyWarningReductions(warningid,cancelTok) {
    return axios.post(`/api/EarlyWarningReduction/LoadEarlyWarningReductionsList?warningid=${warningid}`,null,{cancelToken: cancelTok});
}

export function getLastEarlyWarningReduction(warningid,cancelTok) {
    return axios.get(`/api/EarlyWarningReduction/GetLastEarlyWarningReduction`,{cancelToken: cancelTok, params:{warningid}});
}