import { userFilterCategories } from "../../../shared/lookup";
const workflows = [
    {
        id: 15,
        title: '$PM_NAME Notifications',
        notificationName: 'Notification',
        abbreviation: '$PM_ABBREVIATION-Notification-',
        notifyingParty: 'PM',
        replyingParty: 'SUPPLIER',
        //REPLY 
        replyEnabled: false,
        replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM To Supplier Notifications',
            currentModel: 'InspectionDefect',
            currentModelType: 'PMN',
            referenceType: 'Notification',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["SUPPLIER_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm',
                create: 'create',
                list: 'list',
                default: 'list'
            }

        }
    },
    {
        id: 16,
        title: '$SUPPLIER_NAME Notifications',
        notificationName: 'Notification',
        abbreviation: '$SUPPLIER_ABBREVIATION-Notification-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'PM',
        //REPLY
        replyEnabled: false,
        replyPeriodOption: "PM_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier To PM Notifications',
            currentModel: 'InspectionDefect',
            currentModelType: 'CN',
            referenceType: 'Notification',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["PM_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supplier',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 17,
        title: '$SUPERVISOR_NAME Notifications',
        notificationName: 'Notification',
        abbreviation: '$SUPERVISOR_NAME-Notification-',
        notifyingParty: 'SUPERVISOR',
        replyingParty: 'SUPPLIER',

        replyEnabled: false,
        replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supervisor To Supplier Notifications',
            currentModel: 'InspectionDefect',
            currentModelType: 'SN',
            referenceType: 'Notification',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["SUPPLIER_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supervisor',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 57,
        title: '$CLIENT_NAME Notifications',
        notificationName: 'Notification',
        abbreviation: '$CLIENT_NAME-Notification-',
        notifyingParty: 'CLIENT',
        replyingParty: 'PM',

        replyEnabled: false,
        replyPeriodOption: "PM_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Client To PM Notifications',
            currentModel: 'InspectionDefect',
            currentModelType: 'ClientNotification',
            referenceType: 'Notification',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["PM_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'client',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 58,
        title: '$PM_NAME\'s $SUPERVISOR_NAME Notifications',
        notificationName: 'Notification',
        abbreviation: '$PM_ABBREVIATION-To-$SUPERVISOR_NAME-',
        notifyingParty: 'PM',
        replyingParty: 'SUPERVISOR',
        //REPLY
        replyEnabled: false,
        replyPeriodOption: "CUSTOM",
        replyPeriod: 7,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM To Supervisor Notifications',
            currentModel: 'InspectionDefect',
            currentModelType: 'PmToSupvNotification',
            referenceType: 'Notification',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["CUSTOM", "ASKED_ON_COMMUNICATION_FORM", "SUPERVISOR_REPLY_PERIOD"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm-supervisor',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 59,
        title: '$PM_NAME\'s $CLIENT_NAME Notifications',
        notificationName: 'Notification',
        abbreviation: '$PM_ABBREVIATION-To-$CLIENT_NAME-',
        notifyingParty: 'PM',
        replyingParty: 'CLIENT',
        //REPLY
        replyEnabled: false,
        replyPeriodOption: "CUSTOM",
        replyPeriod: 7,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM To Client Notifications',
            currentModel: 'InspectionDefect',
            currentModelType: 'PmToClientNotification',
            referenceType: 'Notification',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["CUSTOM", "ASKED_ON_COMMUNICATION_FORM", "CLIENT_REPLY_PERIOD"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm-client',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 60,
        title: '$SUPERVISOR_NAME\'s $PM_NAME Notifications',
        notificationName: 'Notification',
        abbreviation: '$SUPERVISOR_NAME-To-$PM_ABBREVIATION-',
        notifyingParty: 'SUPERVISOR',
        replyingParty: 'PM',
        //REPLY
        replyEnabled: false,
        replyPeriodOption: "PM_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supervisor To PM Notifications',
            currentModel: 'InspectionDefect',
            currentModelType: 'SupvToPmNotification',
            referenceType: 'Notification',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["PM_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supervisor-pm',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 63,
        title: '$PM_NAME\'s $PM_NAME Notifications',
        notificationName: 'Notification',
        abbreviation: '$PM_NAME-To-$PM_ABBREVIATION-',
        notifyingParty: 'PM',
        replyingParty: 'PM',
        //REPLY
        replyEnabled: false,
        replyPeriodOption: "PM_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM To PM Notifications',
            currentModel: 'InspectionDefect',
            currentModelType: 'PmToPmNotification',
            referenceType: 'Notification',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["PM_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm-pm',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 64,
        title: '$CLIENT_NAME\'s $CLIENT_NAME Notifications',
        notificationName: 'Notification',
        abbreviation: '$CLIENT_NAME-To-$CLIENT_ABBREVIATION-',
        notifyingParty: 'CLIENT',
        replyingParty: 'CLIENT',
        //REPLY
        replyEnabled: false,
        replyPeriodOption: "CLIENT_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Client To Client Notifications',
            currentModel: 'InspectionDefect',
            currentModelType: 'ClientToClientNotification',
            referenceType: 'Notification',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["CLIENT_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'client-client',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 65,
        title: '$SUPPLIER_NAME\'s $SUPPLIER_NAME Notifications',
        notificationName: 'Notification',
        abbreviation: '$SUPPLIER_NAME-To-$SUPPLIER_ABBREVIATION-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'SUPPLIER',
        //REPLY
        replyEnabled: false,
        replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier To Supplier Notifications',
            currentModel: 'InspectionDefect',
            currentModelType: 'SupplierToSupplierNotification',
            referenceType: 'Notification',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["SUPPLIER_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supplier-supplier',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 66,
        title: '$SUPERVISOR_NAME\'s $SUPERVISOR_NAME Notifications',
        notificationName: 'Notification',
        abbreviation: '$SUPERVISOR_NAME-To-$SUPERVISOR_ABBREVIATION-',
        notifyingParty: 'SUPERVISOR',
        replyingParty: 'SUPERVISOR',
        //REPLY
        replyEnabled: false,
        replyPeriodOption: "SUPERVISOR_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supervisor To Supervisor Notifications',
            currentModel: 'InspectionDefect',
            currentModelType: 'SupvToSupvNotification',
            referenceType: 'Notification',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["SUPERVISOR_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supervisor-supervisor',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 81,
        title: '$SUPPLIER_NAME\'s $SUPERVISOR_NAME Notifications',
        notificationName: 'Notification',
        abbreviation: '$SUPPLIER_ABBREVIATION-To-$SUPERVISOR_ABBREVIATION-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'SUPERVISOR',
        //REPLY
        replyEnabled: false,
        replyPeriodOption: "SUPERVISOR_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier To Supervisor Notifications',
            currentModel: 'InspectionDefect',
            currentModelType: 'SupplierToSupvNotification',
            referenceType: 'Notification',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["SUPERVISOR_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supplier-supervisor',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
]

export default workflows;