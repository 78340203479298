import React from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import Spinner from '../../../../partials/layout/Spinner';
import * as compensationEventCrud from '../../../../crud/compensationEvent.crud';
import { addYears } from 'date-fns';
import { injectIntl } from "react-intl";

export const compensationStatusFilterPM = ['APMRQ', 'APMR'];
export const compensationStatusFilterSupplier = ['AQ', 'ARQ'];
export const editingDatePropertyPM = 'projectmanagersigneddate';
export const editingDatePropertySupplier = 'requiredate';

class ExtensionCustomForm extends React.Component {
    constructor(props) {
        super(props);
        this.createDefaultSubject = (((props.location || {}).state || {}).itemCustomProps || {}).subject;
        this.createDefaultAsset = (((props.location || {}).state || {}).itemCustomProps || {}).assetid;
        this.createDefaultFields = ((props.location || {}).state || {});

        if (props.mode === 'PM') {
            this.editingProperty = editingDatePropertyPM
            this.compensationFilter = compensationStatusFilterPM;
            this.editingDateLabel = this.props.intl.formatMessage({ id: 'CONTRACT.WF.EXTENSION.FIELD.CURRENT_RESPONSE_DATE' });
            this.editingDetailLabel = props.resources.ExtensionPMLabel;
        }
        else if (props.mode === 'SUPPLIER') {
            this.editingProperty = editingDatePropertySupplier;
            this.compensationFilter = compensationStatusFilterSupplier;
            this.editingDateLabel = this.props.intl.formatMessage({ id: 'CONTRACT.WF.EXTENSION.FIELD.CURRENT_QUOTE_DATE' });
            this.editingDetailLabel = props.resources.ExtensionSupplierLabel;
        }
        this.state = {
            compensations: null,
            compStatus: null,
            quoteDate: null,
            quoteRef: null,
        };
    }
    componentDidMount() {
        this.fetchCompensations();
    }
    fetchCompensations = async () => {
        try {
            const { contractid } = this.props.contract;
            const { data: responseObj } = await compensationEventCrud.getCompansationEventsToSelectList(contractid,this.compensationFilter);
            let comps = responseObj.map(itm => {
                    const reference = itm.referenceid;
                    const display = reference + '   ' + itm.compensationnumber;
                    return {
                        value: itm.compensationeventid,
                        reference: reference,
                        quoteReference: itm.lastquotereference ? itm.lastquotereference : '-',
                        display: display,
                        quoteDate: new Date(itm[this.editingProperty]),
                        status: itm.status
                    }
                });
            let quoteDate = null;
            let quoteRef = null;
            let compStatus = null;

            const ceid = this.props.item?.inspectiontime || (this.createDefaultFields?.itemBaseProps?.refid ? this.createDefaultFields.itemBaseProps.refid : null);

            if (ceid) {
                const compOpt = comps.find(x => x.value == ceid);
                if (compOpt) {
                    quoteDate = compOpt.quoteDate;
                    quoteRef = compOpt.quoteReference;
                    compStatus = compOpt.status;
                }
                else {
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.ERROR_CEN_NOT_FOUND_IN_THE_LIST' }, { ID: ceid }), { variant: 'warning' });
                }
            }

            this.setState({ compensations: comps, quoteDate: quoteDate, quoteRef: quoteRef, compStatus: compStatus });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    valueChangeHandler = (field, value, isDxFormValid, additionalData) => {
        if (field) {
            if (field === 'inspectiontime' && value) {
                const comp = this.state.compensations.find(x => x.value === value);
                if (this.props.valueChangeHandler) {
                    this.props.valueChangeHandler('replyaction', comp.reference, isDxFormValid, additionalData);
                    // this.props.valueChangeHandler('location', comp.quoteReference, isDxFormValid, additionalData);
                    this.props.valueChangeHandler('inspectiontime', value, isDxFormValid, additionalData);
                }
                this.setState({ quoteDate: comp.quoteDate, quoteRef: comp.quoteReference });
            }
            else {
                if (this.props.valueChangeHandler) {
                    this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);
                }
            }
        }
    }
    onSubmitHandler = (notification, setSubmitting) => {
        if (this.props.onSubmitHandler) {
            if (notification.inspectiontime) {
                const compOption = this.state.compensations.find(x => x.value === Number(notification.inspectiontime));
                notification.replyaction = compOption.reference;
                // notification.location = compOption.quoteReference;
            }
            this.props.onSubmitHandler(notification, setSubmitting);
        }
    }
    fillQuoteDate = (item) => {
        if (item.inspectiontime) {
            return this.state.compensations.find(x => x.value === item.inspectiontime).quoteDate;
        } else {
            return null;
        }
    }
    fillQuoteRef = (item) => {
        if (item.inspectiontime) {
            return this.state.compensations.find(x => x.value === item.inspectiontime).quoteReference;
        } else {
            return null;
        }
    }
    render() {
        if (!this.state.compensations) {
            return (<Spinner />);
        }
        
        //AUTO-SELECT CE DROPDOWN (IF REDIRECTED FROM CREATE-RELATED BUTTON)
        let defaultCESelection = null;
        let defaultQuoteDate = null;
        let defaultQuoteRef = null;
        if (this.createDefaultFields?.itemBaseProps?.reftype === 'CompensationEvent' && this.state.compensations) {
            const ceOption = this.state.compensations.find(x => x.value == this.createDefaultFields.itemBaseProps.refid);
            if (ceOption) {
                defaultCESelection = ceOption.value;
                defaultQuoteDate = ceOption.quoteDate;
                defaultQuoteRef = ceOption.quoteReference;
            }
        }

        const createFormConfig = {
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.COMPENSATION', dataField: 'inspectiontime', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.compensations, valueExpr: 'value', displayExpr: 'display', defaultValue: defaultCESelection } },
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'replyactiondetail', editorOptions: { defaultValue: this.createDefaultSubject } },
                { label: this.editingDateLabel, dataField: 'assistancetestserchdefect', editorType: 'dxDateBox', dependOn: 'inspectiontime', dependOnValue: (item) => this.fillQuoteDate(item), editorOptions: { readOnly: true, defaultValue: defaultQuoteDate } },
                { resource: "CONTRACT.WF.EXTENSION.FIELD.PROPOSED_EXT_DATE", dataField: 'inspectiondate', editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: this.state.quoteDate } }, validation: { type: 'range', min: this.state.quoteDate, max: addYears(new Date(), 10), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" })} },
                { label: this.editingDetailLabel, dataField: 'inspectiondetails', editorType: 'dxHtmlEditor' },
            ]
        };
        if (this.props.mode === 'PM') {
            createFormConfig.fields.splice(2, 0, { resource: "CONTRACT.WF.EXTENSION.FIELD.QUOTE_REFERENCE", dataField: 'location', dependOn: 'inspectiontime', dependOnValue: (item) => this.fillQuoteRef(item), editorOptions: { readOnly: true, defaultValue: defaultQuoteRef } });
        }
        return (
            <BiDynamicForm
                item={this.props.item}
                config={createFormConfig}
                onSubmitHandler={this.props.onSubmitHandler ? this.onSubmitHandler : null}
                valueChangeHandler={this.valueChangeHandler}
                customFields={this.props.customFields}
                assetFieldsEnabled={this.props.assetFieldsEnabled}
                assetid={this.createDefaultAsset}
            />
        );
    }
}

const mapStateToProps = store => ({
    // contract: store.contract.contract,
    // contractTemplate: store.contract.template,
});



export default injectIntl(withSnackbar(connect(mapStateToProps)(ExtensionCustomForm)));
