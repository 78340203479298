import React from 'react';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { SelectBox, TextArea, CheckBox, NumberBox } from 'devextreme-react';
import { withSnackbar } from 'notistack';
import OverrideResourcesGrid from '../OverrideResourcesGrid';
import PostActionsGrid from '../PostActionsGrid';
import Accordion, { Item as AccordionItem } from 'devextreme-react/accordion';
import ScrollView from 'devextreme-react/scroll-view';
import DefaultWorkflows from "../../../../../../Templates/DefaultWorkflows";
import TemplateHelper from '../../../../../../Templates/TemplateHelper';

const partySelection = ["PM", "SUPPLIER", "SUPERVISOR", "CLIENT"];
const clauseMaxLength = 250;
const manuelActionsEnabled = process.env.REACT_APP_MANUAL_ACTIONS_ENABLED === 'true';

class UnifiedClauseValueEditor extends React.Component {
    constructor() {
        super();
        this.state = {
            item: null,
            changed: false,
            availableWorkflows: null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.clauseItem !== this.props.clauseItem) {
            if (this.props.clauseItem) {
                const newItem = JSON.parse(JSON.stringify(this.props.clauseItem));
                // const defaultTemp = TemplateHelper.getDefaultSanitizedTemplate();
                let availableWorkflows = DefaultWorkflows.find(wfg => wfg.id === this.props.wfgid).workflows.map(wf => ({ display: wf.config.description, value: wf.id }));//[{display: "test1", value: 1}]
                // 
                // console.log("After MAPP",DefaultWorkflows)
                //UNLOAD ALL COMPONENTS TO START-FRESH
                this.setState({ item: null, changed: false }, () => {
                    this.setState({ item: newItem, availableWorkflows });
                });
            }
            else {
                this.setState({ item: null, availableWorkflows: null, changed: false });
            }

        }

    }

    btnAddSaveValue_Clicked = () => {
        const newValue = { ...this.state.item };
        newValue.display = newValue.display.trim()
        if (!newValue.display) {
            this.props.enqueueSnackbar(`The display value cen not be empty`, { variant: 'error', });
            return;
        }
        if (!newValue.wfid) {
            this.props.enqueueSnackbar(`Workflow can not be empty`, { variant: 'error', });
            return;
        }
        if (newValue.display.length >= clauseMaxLength) {
            this.props.enqueueSnackbar(`The display value exceeds the max length of ${clauseMaxLength}`, { variant: 'error', });
            return;
        }
        if (newValue.overrideReplyingParty && !newValue.replyingParty) {
            this.props.enqueueSnackbar(`Replying Party must be provided!`, { variant: 'error', });
            return;
        }
        this.props.onUpdate(newValue);
        // this.setState({ item:null});
    }
    btnCancel_Clicked = () => {
        this.props.onUpdate(null);
    }

    render() {
        if (!this.state.item) {
            return <div style={{ padding: 10, width: "50%", margin: 10, textAlign: "center" }}>Please select an item from list to start editing.</div>;
        }
        return (
            <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: 10, width: "50%", margin: 10, display: "flex", flexDirection: "column" }}>
                <div style={{ margin: 10 }}>
                    <Toolbar>
                        <ToolbarItem location="before">
                            <div>
                                <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">
                                    {"Clause Editor Item : " + (this.state.item.id > 0 ? this.state.item.id : "new")}
                                </h3>
                            </div>
                        </ToolbarItem>
                        <ToolbarItem location="after"
                            locateInMenu="never"
                            widget="dxButton"
                            options={
                                {
                                    icon: "close",
                                    onClick: this.btnCancel_Clicked,
                                    text: "Cancel",
                                }
                            }
                        />
                        <ToolbarItem location="after"
                            locateInMenu="never"
                            widget="dxButton"
                            disabled={this.props.readOnly || !this.state.changed}
                            options={
                                {
                                    type: "default",
                                    stylingMode: this.state.changed ? "contained" : "outlined",
                                    icon: this.state.item.id > 0 ? "save" : "plus",
                                    onClick: this.btnAddSaveValue_Clicked,
                                    text: this.state.item.id > 0 ? "Update Item" : "Add Item",
                                }
                            }
                        />
                    </Toolbar>
                </div>
                <div style={{ flexGrow: 1, minHeight: 0 }}>
                    <ScrollView showScrollbar="always" useNative >
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 10px' }}>
                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                <div className="dx-field-label" style={{ width: "20%" }}>Workflow</div>
                                <div className="dx-field-value" style={{ width: "80%" }}>
                                    <SelectBox
                                        dataSource={this.state.availableWorkflows}
                                        displayExpr={(wf) => { return wf && `${wf.value} | ${wf.display}`; }}
                                        valueExpr="value"
                                        value={this.state.item.wfid}
                                        showClearButton={true}
                                        onValueChanged={(e) => this.setState({ item: { ...this.state.item, wfid: e.value }, changed: true })}
                                        readOnly={this.props.readOnly}
                                    />
                                </div>
                            </div>
                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                <div className="dx-field-label" style={{ width: "20%" }}>Is Active</div>
                                <div className="dx-field-value" style={{ width: "80%", display: 'flex', flexDirection: 'column' }}>
                                    <CheckBox
                                        value={this.state.item.isActive}
                                        onValueChanged={(e) => { this.setState({ item: { ...this.state.item, isActive: e.value }, changed: true }); }}
                                        readOnly={this.props.readOnly}
                                        width={25}
                                    />
                                </div>
                            </div>
                            <span className="dx-field-item-label-text dx-box-item-content dx-widget">Display Text</span>
                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                <TextArea
                                    height={60}
                                    maxLength={clauseMaxLength}
                                    // onPaste={this.handlePastedValues}
                                    placeholder="Display text / Paste multiple items"
                                    value={this.state.item.display}
                                    onValueChanged={(e) => { this.setState({ item: { ...this.state.item, display: e.value }, changed: true }); }}
                                    readOnly={this.props.readOnly}
                                />
                            </div>
                            <Accordion collapsible={true} multiple={true} noDataText='' deferRendering={false}>
                                {
                                    this.props.allowCustomReplyPeriods && (

                                        <AccordionItem title="Override Reply Period" >
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "25%", display: 'flex', flexDirection: 'column' }}>
                                                    <CheckBox
                                                        text="No Reply"
                                                        value={this.state.item.noReply}
                                                        enableThreeStateBehavior={false}
                                                        onValueChanged={(e) => this.setState({ item: { ...this.state.item, noReply: e.value, applyReplyPeriod: (e.value ? false : this.state.item.applyReplyPeriod) }, changed: true })}
                                                        readOnly={this.props.readOnly}
                                                    />
                                                </div>
                                                <div className="dx-field-label" style={{ width: "35%", display: 'flex', flexDirection: 'column' }}>
                                                    <CheckBox
                                                        text="Custom Reply Period"
                                                        value={this.state.item.applyReplyPeriod}
                                                        enableThreeStateBehavior={false}
                                                        onValueChanged={(e) => this.setState({ item: { ...this.state.item, applyReplyPeriod: e.value, noReply: (e.value ? false : this.state.item.noReply) }, changed: true })}
                                                        readOnly={this.props.readOnly}
                                                    />
                                                </div>
                                                <div className="dx-field-value" style={{ width: "40%", display: 'flex', flexDirection: 'column' }}>
                                                    <NumberBox
                                                        width={200}
                                                        format="#,##0 day(s)"
                                                        placeholder="0 day(s)"
                                                        disabled={!this.state.item.applyReplyPeriod}
                                                        value={this.state.item.replyPeriod}
                                                        onValueChanged={(e) => this.setState({ item: { ...this.state.item, replyPeriod: e.value }, changed: true })}
                                                        readOnly={this.props.readOnly}
                                                        max={365}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItem>
                                    )
                                }
                                {
                                    this.props.allowConfigureReplyParty && (
                                        <AccordionItem title="Override Reply Party" >
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "35%", display: 'flex', flexDirection: 'column' }}>
                                                    <CheckBox
                                                        text="Override Replying Party"
                                                        value={this.state.item.overrideReplyingParty}
                                                        enableThreeStateBehavior={false}
                                                        onValueChanged={(e) => this.setState({ item: { ...this.state.item, overrideReplyingParty: e.value }, changed: true })}
                                                        readOnly={this.props.readOnly}
                                                    />
                                                </div>
                                                <div className="dx-field-value" style={{ width: "40%", display: 'flex', flexDirection: 'column' }}>
                                                    <SelectBox
                                                        dataSource={partySelection}
                                                        value={this.state.item.replyingParty}
                                                        onValueChanged={(e) => this.setState({ item: { ...this.state.item, replyingParty: e.value }, changed: true })}
                                                        readOnly={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItem>
                                    )
                                }
                                {
                                    this.props.allowOverrideResources && (

                                        <AccordionItem title="Override Global Resources" >
                                            <OverrideResourcesGrid
                                                id={this.state.item.id}
                                                overrideResourcesList={this.state.item.overrideResources}
                                                onValueChanged={(newwList) => this.setState({ item: { ...this.state.item, overrideResources: newwList }, changed: true })}
                                                readOnly={this.props.readOnly}
                                            />
                                        </AccordionItem>


                                    )
                                }
                                {
                                    this.props.availableEffects && (
                                        <AccordionItem title="Effect / Action" >
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "20%" }}>Effect</div>
                                                <div className="dx-field-value" style={{ width: "80%" }}>
                                                    <SelectBox
                                                        dataSource={this.props.availableEffects}
                                                        value={this.state.item.effect}
                                                        showClearButton={true}
                                                        onValueChanged={(e) => this.setState({ item: { ...this.state.item, effect: e.value }, changed: true })}
                                                        readOnly={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItem>
                                    )
                                }
                                {manuelActionsEnabled && (
                                    <AccordionItem title="Post Actions" >
                                        <PostActionsGrid
                                            id={this.state.item.id}
                                            postActionsList={this.state.item.postActions}
                                            onValueChanged={(newList) => this.setState({ item: { ...this.state.item, postActions: newList }, changed: true })}
                                            readOnly={this.props.readOnly}
                                        />
                                    </AccordionItem>
                                )}
                            </Accordion>
                        </div>
                    </ScrollView>
                </div>
            </div>
        );
    }
}


export default withSnackbar(UnifiedClauseValueEditor);