import React from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import * as earlyWarningReductionCrud from '../../../../crud/earlyWarningReduction.crud';
import { injectIntl } from "react-intl";
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import Common from '../../../../shared/common';

class WarningRiskUpdate extends React.Component {
    constructor(props) {
        super(props);

        this.formConfig = {
            buttonResource: 'CONTRACT.WF.EW.BUTTON.UPDATE_RISK',
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.ACTION_OWNER', dataField: 'owner', },
                { resource: 'GENERAL.FORMS.FIELD.ACTION', dataField: 'solution', editorType: 'dxHtmlEditor' }
            ]
        }
    }
    submitHandler = async (reduction, setSubmitting) => {
        try {
            const earlyWarningReduction = {
                warningid: this.props.warningid,
                solution: reduction.solution,
                action: reduction.owner,
                reference: reduction.reference
            }

            for (let key in reduction) {
                if (key.startsWith("CF_")) {
                    earlyWarningReduction[key] = reduction[key];
                }
            }

            Common.PropertyConversionsBeforeNoticeSaveRequest(earlyWarningReduction, (this.props.customFieldsCommon || {}), this.props.contractUsers);
            await earlyWarningReductionCrud.saveEarlyWarningReduction(earlyWarningReduction);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });
            //Inform parent about update
            this.props.onEWReductionUpdate();
            setSubmitting(false);
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            setSubmitting(false);
        }
    }
    render() {
        return (
            <div style={{ margin: 20 }}>
                <BiDynamicForm
                    item={this.props.warning}
                    config={this.formConfig}
                    onSubmitHandler={this.submitHandler}
                    customFields={(this.props.customFieldsCommon || {}).EWREDUCTION}
                />
            </div>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(WarningRiskUpdate)));
