import axios from '../shared/axios';
// import qs from 'querystring';

export function createRegister(data) {
    return axios.post(`/api/Register/CreateRegister`, data);
}
export function getRegisterList() {
    return axios.get('/api/Register/GetRegisterList');
  }
export function getRegister(registerid) {
    return axios.get(`/api/Register/GetRegister?registerid=${registerid}`);
}
