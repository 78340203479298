import React from 'react';
import { Portlet, PortletBody } from "../content/Portlet";
import { LoadIndicator } from 'devextreme-react/load-indicator';

export default function PortletSpinner(props) {
    return (
        <Portlet>
            <PortletBody>
                <div style={{ textAlign: "center" }}>
                    <LoadIndicator height={60} width={60} />
                </div>
                {props.title && (
                <div style={{ textAlign: "center", fontWeight: 600, marginTop: "0.5rem" }} className="text-gray-800" >{props.title}</div>
                )}
            </PortletBody>
        </Portlet>
    );
}