
import axios from '../shared/axios';


import DefaultWorkflows from '../Templates/DefaultWorkflows';
import DefaultTemplates from '../Templates/DefaultTemplates';


export function saveWorkflowGroups(data) {
    return axios.post('/api/Workflow/SaveWorkflow', data);
}
export function getWorkflowGroups() {
    return axios.get('/api/Workflow/GetWorkflow?workflowid=1');
}


export function saveTemplate(data) {
    return axios.post('/api/Template/SaveTemplate', data);
}
export function getTemplates() {
    return axios.post('/api/Template/LoadTemplatesList');
}

export function getTemplate(templateid) {
    return axios.get(`/api/Template/GetTemplate`, { params: { templateid} });
}

export function getDefaultWorkflowGroups() {
    return DefaultWorkflows;
}
export function getDefaultTemplates() {
    return DefaultTemplates;
}

export function getTemplatesToSelectList(companyid) {
    return axios.get(`/api/Template/ToSelectList`, { params: { companyid} });
}