import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as authDuck from "../../../store/ducks/auth.duck";
import Spinner from '../../../partials/layout/Spinner';
import { useAuth0 } from "@auth0/auth0-react";
import * as securityCrud from '../../../crud/security.crud';
import "../../../../_metronic/_assets/sass/pages/login/login-auth0.scss";
import { Auth0Provider } from "@auth0/auth0-react";
import QueryString from "query-string";
import { TextBox } from 'devextreme-react';

const auth0ProviderConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    // onRedirectCallback,
    authorizationParams: {
        redirect_uri: process.env.REACT_APP_SSO_METHOD === 'DISCOVER' ? `${window.location.origin}/auth/discover/auth0` : window.location.origin,
        // audience: "https://biuat.eu.auth0.com/api/v2/",
        // scope: "read:current_user update:current_user_metadata"
    },
};


const Auth0Handler = (props) => {
    const navigate = useNavigate();
    let location = useLocation();
    const queryObj = QueryString.parse(location.search);

    const { isAuthenticated, loginWithRedirect, isLoading, getIdTokenClaims } = useAuth0();//getAccessTokenSilently, user
    const setToken = async () => {
        // const encryptedToken = await getAccessTokenSilently(
        //     //     {
        //     //     authorizationParams: {
        //     //       audience: `https://biuat.eu.auth0.com/api/v2/`,
        //     //       scope: "read:current_user update:current_user_metadata",
        //     //     },
        //     //   }
        // );
        const claims = await getIdTokenClaims();

        try {
            const fdTokenResponse = await securityCrud.getDecryptedToken(claims.__raw);
            if (!fdTokenResponse.data.accessToken.includes('Error') && !fdTokenResponse.data.accessToken.includes('error')) {
                props.storeSetAuthToken(
                    fdTokenResponse.data.accessToken,
                    fdTokenResponse.data.reportingToken,
                    "",
                    "",
                    "AUTH0"
                );
            }
            else {
                console.log('Token decryption error:', fdTokenResponse.data);
                props.navigate(`/error/error-v5`);
            }
        }
        catch (err) {
            console.log(err);
            if (err.response && err.response.status) {
                if (err.response.status === 401) {
                    navigate(`/error/error-v4`);
                }
                else {
                    navigate(`/error/error-v5`);
                }
            }
            else {
                navigate(`/error/error-v5`);
            }
        }
    }

    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            setToken();
        }
    }, [])

    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            setToken();
        }
        else if (!isLoading && !isAuthenticated) {
            //NO SESSION
            //WAITING FOR LOGIN CLICK
            if (process.env.REACT_APP_SSO_METHOD === "DISCOVER" && !location?.state?.username && Object.keys(queryObj).length === 0) {
                console.log("username is missing for Auth0, will be redirected to Discover")
                navigate("/auth/discover");
            }
            else if (process.env.REACT_APP_SSO_METHOD === "DISCOVER"){
                loginWithRedirect();
            }

        }
    }, [isLoading, isAuthenticated]);


    const loginClicked = async () => {
        loginWithRedirect();
    }

    if (isLoading) {
        return (
            <div style={{ margin: "auto" }}>
                <Spinner />
            </div>
        )
    }
    if (!isAuthenticated) {
        return (
            <div style={{ margin: "auto" }}>
                <div className='a0-container'>
                    <div className='a0-row'>
                        <div>
                            <img src="https://cdn.shopify.com/s/files/1/0248/4926/files/BI_logo_300x90_300x90.png?v=1613712008" alt="Built Intelligence" />
                            <div className='a0-header'>
                                FastDraft SSO Login
                            </div>
                        </div>
                        <div className='a0-divider' />
                        {process.env.REACT_APP_SSO_METHOD === 'DISCOVER' && (
                            <TextBox
                                value={location?.state?.username}
                                disabled={true}
                                readOnly={true}
                            />
                        )}

                        <div className='a0-button-container'>
                            <span>
                                <button className='a0-button' onClick={loginClicked}>
                                    <div className='a0-button-text'>
                                        Continue Login with Auth0
                                    </div>
                                </button>
                            </span>
                        </div>

                    </div>
                    <a className='a0-branding' href='https://www.builtintelligence.com/' target='_blank' rel='noreferrer'>
                        Power by <strong>Built Intelligence</strong>
                    </a>
                </div>

            </div>
        )
    }

}

const mapDispatchToProps = {
    storeSetAuthToken: authDuck.actions.setAuthToken,
    startLogout: authDuck.actions.startLogout
};

const auth0Wrapper = (Component) => {
    function Auth0Wrapper(props) {
        return (
            <Auth0Provider {...auth0ProviderConfig}>
                <Component
                    {...props}
                />
            </Auth0Provider>
        );
    }

    return Auth0Wrapper;
}


export default auth0Wrapper(connect(null, mapDispatchToProps)(Auth0Handler));
