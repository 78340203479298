import React from 'react';
import { connect } from "react-redux";
import { Popup, ToolbarItem as PopupToolbarItem } from 'devextreme-react/popup';
import { TextBox, TextArea, CheckBox } from 'devextreme-react';
import DxOrderList from '../../../../../../partials/content/dxControls/dxOrderList';
import DxGroupBox from '../../../../../../partials/content/dxControls/dxGroupBox';
import { withSnackbar } from 'notistack';
import { listAccountsAllowedEditTemplatePlaceholderField } from '../../../../../../router/Protector/AccessExceptions';
import { confirm } from 'devextreme/ui/dialog';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Badge } from "react-bootstrap";
import { injectIntl } from "react-intl";

const emptyPlaceHolder = {
    // id: 0,
    replacementKey: '',
    values: [],
}
const emptyValue = {
    id: 0,
    isActive: true,
    field: '',
    display: '',
}



class PHModalGeneric extends React.Component {
    constructor() {
        super();
        this.listReference = React.createRef();
        this.state = {
            placeholder: { ...emptyPlaceHolder },
            value: null,
            phChanged: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.item !== this.props.item) {
                if (this.props.item && this.props.item.replacementKey) {
                    const { defaultPH } = this.props;
                    this.setState({
                        placeholder: JSON.parse(JSON.stringify(this.props.item)),
                        fieldRequired: defaultPH.fieldRequired ? true : false,
                        maxLength: defaultPH.maxLength,
                        phChanged: false
                    });
                }
                else {
                    this.setState({ placeholder: { ...emptyPlaceHolder }, value: null, phChanged: false });
                }
            }
        }
    }
    btnAddNew_Clicked = () => {
        this.listReference.current.clearSelection();
        this.setState({ value: { ...emptyValue } });
    }
    btnCancelValue =()=>{
        this.listReference.current.clearSelection();
        this.setState({ value: null });
    }
    onSelectedValueUpdated = () => {
        const newValue = { ...this.state.value };
        const newValues = [...this.state.placeholder.values];
        if (this.state.fieldRequired) {
            if (!newValue.field) {
                this.props.enqueueSnackbar("FIELD is required!", { variant: 'error', });
                return;
            }
        }
        if (this.state.maxLength && newValue.display.length >= this.state.maxLength) {
            this.props.enqueueSnackbar(`The display value exceeds the max length of ${this.state.maxLength}`, { variant: 'error', });
            return;
        }

        if (newValue.id > 0) {
            newValues[newValues.findIndex(val => val.id === newValue.id)] = newValue;
        }
        else {
            if (this.state.fieldRequired) {
                if (newValues.findIndex(val => val.field === newValue.field) !== -1) {
                    this.props.enqueueSnackbar("FIELD already exist!", { variant: 'error', });
                    return;
                }
            }
            let maxId = 1;
            if (newValues.length > 0) {
                maxId = Math.max(...newValues.map(val => val.id));
            }
            newValue.id = maxId + 1;
            newValues.push(newValue);
        }
        this.setState({ placeholder: { ...this.state.placeholder, values: newValues }, value: null, phChanged: true });
    }
    onListUpdated = (newList) => {
        this.setState({ placeholder: { ...this.state.placeholder, values: JSON.parse(JSON.stringify(newList)) }, phChanged: true });
    }
    onItemSelected = (item) => {
        this.setState({ value: JSON.parse(JSON.stringify(item)) });
    }

    onClose = async () => {
        if (this.props.item) {
            if (this.state.phChanged) {
                let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}</div>`, this.props.intl.formatMessage({ id: 'GENERAL.WARNING.LEAVE_CHANGES_TITLE' }));
                if (!result) {
                    return;
                }

            }
            this.setState({ placeholder: { ...emptyPlaceHolder }, value: null, phChanged: false });
            this.props.onClose();
        }

    }
    savePlaceholder = () => {
        const retVal = JSON.parse(JSON.stringify(this.state.placeholder));
        //CLEAR ANY EDITOR OPTIONS
        delete retVal.fieldRequired;
        delete retVal.maxLength;
        delete retVal.skipDefaultLoadingIfMissing;
        this.props.onClose(retVal);
        this.setState({ placeholder: { ...emptyPlaceHolder }, value: null, phChanged: false });
    }
    handlePastedValues = (e) => {
        // console.log('target.value', e.event.target.value);
        // console.log('clipboardData', e.event.clipboardData);
        // console.log('clipboardData.types', e.event.clipboardData.types);
        // console.log('clipboardData.items', e.event.clipboardData.items);
        // console.log('clipboardData.items[0].length', e.event.clipboardData.items.length);
        let containsList = false;
        for (let index = 0; index < ((e.event.originalEvent.clipboardData || {}).items || []).length; index++) {
            if (e.event.originalEvent.clipboardData.items[index].type === 'text/rtf') {
                containsList = true;
                break;
            }
        }
        // console.log('clipboardData.Get', e.event.clipboardData.getData('text/plain'));
        // console.log('clipboardData.Get', e.event.clipboardData.getData('text/html'));
        if (containsList) {
            e.event.preventDefault();
            if (this.state.fieldRequired) {
                this.props.enqueueSnackbar("List insertion is not possible for this placeholder, FIELD is required!", { variant: 'error', });
                return;
            }
            const pasteData = e.event.originalEvent.clipboardData.getData('text/plain');
            const pastedValues = pasteData.split('\n');
            let maxLengthExceeded = false;
            const newValues = [...this.state.placeholder.values];
            let maxId = 1;
            if (newValues.length > 0) {
                maxId = Math.max(...newValues.map(val => val.id));
            }

            pastedValues.forEach(value => {
                if (value.trim()) {
                    if (this.state.maxLength && value.trim().length >= this.state.maxLength) {
                        maxLengthExceeded = true;
                        return;
                    }
                    const newValue = {
                        field: '',
                        display: value.trim(),
                        isActive: true,
                    }
                    maxId += 1;
                    newValue.id = maxId;
                    newValues.push(newValue);
                }
            });
            if (maxLengthExceeded) {
                this.props.enqueueSnackbar(`List contains an element that exceeds the max length of ${this.state.maxLength}`, { variant: 'error', });
                return;
            }
            this.setState({ placeholder: { ...this.state.placeholder, values: newValues }, value: null, phChanged: true });
            // setTimeout(() => this.drpList.instance.repaint(), 300);
        }

        // console.log('clipboardData.Get', e.event.clipboardData.getData('Files'));
        // console.log('clipboardData.Get', e.event.clipboardData.getData('Text'));
    }
    renderListItem = (item) => {

        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <div style={{ width: '100%', display: "flex", flexGrow: 1, minWidth: 0 }}>
                    <Badge variant="secondary">{item.field}</Badge>
                    <div style={{ marginLeft: 5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{`${item.display}`}</div>
                </div>
                <Badge variant={item.isActive ? 'success' : 'danger'}>{item.isActive ? 'Active' : 'Passive'}</Badge>
            </div>
        );
    }


    render() {
        return (
            <Popup
                visible={this.props.item ? true : false}
                onHiding={this.onClose}
                dragEnabled={false}
                showCloseButton={false}
                hideOnOutsideClick={false}
                showTitle={true}
                title={this.state.placeholder ? `Placeholder - ${this.state.placeholder.replacementKey}` : ""}
                width={1200}
                height='80vh'
                minHeight={600}
            >
                <PopupToolbarItem
                    widget="dxButton"
                    toolbar="top"
                    location="after"
                    options={{ text: "Cancel", onClick: this.onClose }}>
                </PopupToolbarItem>
                <PopupToolbarItem
                    widget="dxButton"
                    toolbar="top"
                    location="after"
                    disabled={!this.state.phChanged}
                    options={{
                        text: 'Update Placeholder',
                        type: "default",
                        stylingMode: this.state.phChanged ? "contained" : "outlined",
                        onClick: this.savePlaceholder
                    }}>
                </PopupToolbarItem>
                {/* <ScrollView style={{ border: '1px solid #ccc', borderRadius: '10px' }} showScrollbar="always" useNative> */}
                <div style={{ display: 'flex', flexDirection: 'column', height: "100%" }}>
                    <div style={{ display: 'flex', flexDirection: "row", height: "100%" }}>
                        <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                            <div style={{ margin: 10 }}>
                                <Toolbar >
                                    <ToolbarItem location="before">
                                        <div>
                                            <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">
                                                {`PH List - ${this.state.placeholder.values ? this.state.placeholder.values.length : 0} items`}
                                            </h3>
                                        </div>
                                    </ToolbarItem>
                                    <ToolbarItem location="after"
                                        locateInMenu="auto"
                                        widget="dxButton"
                                        disabled={this.props.readOnly}
                                        options={
                                            {
                                                icon: "add",
                                                onClick: this.btnAddNew_Clicked,
                                                text: "Add New",
                                                
                                            }
                                        }
                                    />
                                </Toolbar>
                            </div>
                            <div style={{ flexGrow: 1 }}>
                                <DxGroupBox showBorder={true}                    >
                                    <DxOrderList
                                        ref={this.listReference}
                                        items={this.state.placeholder.values}
                                        // keyExpr="id"
                                        displayExpr="display"
                                        listChanged={this.onListUpdated}
                                        onItemClick={this.onItemSelected}
                                        itemRender={this.renderListItem}
                                        readOnly = {this.props.readOnly}
                                    />
                                </DxGroupBox>
                            </div>
                        </div>
                        {
                            this.state.value ? (
                                <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: 10, width: "50%", margin: 10, display: "flex", flexDirection: "column" }}>
                                    <div style={{ margin: 10 }}>
                                        <Toolbar>
                                            <ToolbarItem location="before">
                                                <div>
                                                    <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">
                                                        {`item : ${this.state.value.id ? this.state.value.id : "new"}`}
                                                    </h3>
                                                </div>
                                            </ToolbarItem>
                                            <ToolbarItem location="after"
                                                locateInMenu="never"
                                                widget="dxButton"
                                                options={
                                                    {
                                                        icon: "close",
                                                        onClick: this.btnCancelValue,
                                                        text: "Cancel",
                                                    }
                                                }
                                            />
                                            <ToolbarItem location="after"
                                                locateInMenu="never"
                                                widget="dxButton"
                                                disabled={this.props.readOnly}
                                                options={
                                                    {
                                                        type: "default",
                                                        stylingMode: "outlined",
                                                        icon: this.state.value.id > 0 ? "save" : "plus",
                                                        onClick: this.onSelectedValueUpdated,
                                                        text: this.state.value.id > 0 ? "Update Item" : "Add Item",
                                                    }
                                                }
                                            />
                                        </Toolbar>
                                    </div>
                                    <div style={{ flexGrow: 1, minHeight: 0 }}>
                                        <div className="dx-field" style={{ marginTop: "10px" }}>
                                            <div className="dx-field-label" style={{ width: "20%" }}>Is Active</div>
                                            <div className="dx-field-value" style={{ width: "80%", display: 'flex', flexDirection: 'column' }}>
                                                <CheckBox
                                                    readOnly={this.props.readOnly}
                                                    value={this.state.value.isActive}
                                                    onValueChanged={(e) => this.setState({ value: { ...this.state.value, isActive: e.value } })}
                                                    width={25}
                                                />
                                            </div>
                                        </div>
                                        <div className="dx-field" style={{ marginTop: "10px" }}>
                                            <div className="dx-field-label" style={{ width: "20%" }}>Field</div>
                                            <div className="dx-field-value" style={{ width: "80%" }}>
                                                <TextBox
                                                    readOnly={this.props.readOnly || (this.props.mode !== 'WORKFLOW' && !listAccountsAllowedEditTemplatePlaceholderField.includes(this.props.userProfile.accounttype))}
                                                    placeholder="Provide Field Name if mapped to specific field"
                                                    value={this.state.value.field}
                                                    onValueChanged={(e) => this.setState({ value: { ...this.state.value, field: e.value } })}
                                                />
                                            </div>
                                        </div>
                                        <span className="dx-field-item-label-text dx-box-item-content dx-widget">Display Text</span>
                                        <div className="dx-field" style={{ marginTop: "10px" }}>
                                            <TextArea
                                                height={60}
                                                onPaste={this.handlePastedValues}
                                                placeholder="Display text / Paste multiple items"
                                                value={this.state.value.display}
                                                onValueChanged={(e) => this.setState({ value: { ...this.state.value, display: e.value } })}
                                                readOnly = {this.props.readOnly}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ padding: 10, width: "50%", margin: 10, textAlign: "center" }}>Select an item...</div>
                            )
                        }
                    </div>

                </div>
                {/* </ScrollView> */}
            </Popup>
        );
    }
}
const mapStateToProps = store => ({
    userProfile: store.auth.userProfile,
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(PHModalGeneric)));