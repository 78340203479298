import axios from '../shared/axios';
// import qs from 'querystring';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function saveTaskOrder(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/TaskOrder/SaveTaskOrder`, data)
            .then(result => {
                // if (data.showtasknotification === 1 ) {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                // }
                resolve(result);
            })
            .catch(error => reject(error));
    });
}
export function updateTaskOrder(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/TaskOrder/Update`, data)
            .then(result => {
                if (data.actioncode === 'COMMUNICATE' || data.actioncode === 'REPLY_SEND') {
                    store.dispatch({
                      type: contractDuck.actionTypes.CONTRACT_REFRESH_CONTRACT,
                    });
                  }
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}
export function getTaskOrder(taskorderid) {
    return axios.get(`/api/TaskOrder/GetTaskOrder?earlytaskorderid=${taskorderid}`);
}

// export function getTaskOrders(companyid, projectid, contractid, type, accessibleWfIds) {
//     return axios.post(`/api/TaskOrder/LoadTaskOrdersList`, { accessibleWfIds }, { params: { companyid, projectid, contractid, type } });
// }

export function getTaskOrdersReduced(companyid, projectid, contractid, accessibleWfIds) {
    return axios.post(`/api/TaskOrder/RegisterList`, { accessibleWfIds }, { params: { companyid, projectid, contractid } });
}

export function getTaskOrdersToSelectList(contractid) {
    return axios.get(`/api/TaskOrder/ToSelectList`, { params: { contractid} });
}

export function deleteTaskOrderDraft(taskorderid) {
    return new Promise((resolve, reject) => {
    axios.post(`api/TaskOrder/DeleteDraft?taskorderid=${taskorderid}`)
    .then(result => {
        store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
        resolve(result);
    })
    .catch(error => reject(error));
    })
}