import React from 'react';
import { connect } from "react-redux";
import PortletSpinner from '../../../partials/layout/PortletSpinner';
import DataGridBase from '../../../partials/layout/DataGridBase';
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import { injectIntl } from "react-intl";
import { systemStatus } from "../../../shared/lookup";
import { Badge } from "react-bootstrap";
import * as companiesCrud from "../../../crud/companies.crud";
import { withSnackbar } from 'notistack';
import { withRouter } from '../../../shared/hoc/withRouter';
import { GetAccessRights } from "../../../router/Protector/Protector";

class CompaniesRegister extends React.Component {
  constructor(props) {
    super(props);
    this.companyTagsEnabled = process.env.REACT_APP_CONTRACT_COMPANY_TAGS_ENABLED === 'true';
    this.canCreateNew = GetAccessRights("/admin/companies/new-company", this.props.userProfile).allowAccess;
    this.state = {
      companies: null,
      frameworkFilter: null
    }
  }

  componentDidMount() {
    this.fetchCompanies();
  }

  fetchCompanies = () => {
    companiesCrud.getCompaniesIncludingDeactives()
      .then(response => {
        let companies = JSON.parse(response.data.aggregatelist);
        if (companies) {
            companies = companies.map(company => {
                if (company.tags && company.tags.categories) {
                    const companyFrameworks = company.tags.categories.find(ctg => ctg.name === 'Frameworks');
                    if (companyFrameworks && companyFrameworks.tags) {
                      company.framework = companyFrameworks.tags.map(tag => tag.tagname);
                    }
                }
                return company;
            })
        }

        let frameworkFilter = null;
        if (response.data.uniquetags) {
            const allUniquieTags = response.data.uniquetags;
            if (allUniquieTags) {
                const frameworktags = allUniquieTags.categories.find(ctg => ctg.name === 'Frameworks');
                if (frameworktags && frameworktags.tags) {
                    frameworkFilter = frameworktags.tags.map(tag => ({ text: tag, value: ['framework', 'contains', tag] }));
                    frameworkFilter.push({
                        text: 'Blank',
                        value: ['framework', '=', null]
                    });
                }
            }
        }
      
        this.setState({ companies, frameworkFilter });
      })
      .catch(err => {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      });
  }

  onTableSelectionChanged = ({ selectedRowsData }) => {
    if (selectedRowsData.length > 0) {
      const data = selectedRowsData[0];
      this.props.navigate(`/admin/companies/${data.companyid}/overview`);
    }
  }

  renderStatusColumn = (cellInfo) => {
    let badgeVariant = 'secondary';
    let label = '';
    switch (cellInfo.data.deactivated) {
      case 1:
        label = this.props.intl.formatMessage({ id: "GENERAL.ACCOUNT.STATUS_DEACTIVATED" })
        badgeVariant = 'secondary';
        break;
      case 0:
        label = this.props.intl.formatMessage({ id: "GENERAL.ACCOUNT.STATUS_ACTIVE" })
        badgeVariant = 'success';
        break;
      default:
        break;
    }
    return (<Badge variant={badgeVariant}>{label}</Badge>);
  }

  render() {
    if (!this.state.companies) {
      return (<PortletSpinner />);
    }

    const columns = [
      { caption: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_ID" }), dataField: "companyid" },
      { caption: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_NAME" }), dataField: "name" },
      { caption: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_TYPE" }), dataField: "companytype" },
      { caption: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_ADDRESS1" }), dataField: "address1" },
      { caption: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_ADDRESS2" }), dataField: "address2" },
      { caption: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_COUNTY" }), dataField: "state" },
      { caption: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_COUNTRY" }), dataField: "country" }
    ];

    if (!(process.env.REACT_APP_PRODUCT_KEY === "NG" && process.env.REACT_APP_ENVIRONMENT === 'PROD')) {
      columns.push({ caption: this.props.intl.formatMessage({ id: "GENERAL.FORMS.FIELD.STATUS" }), dataField: "deactivated", cellRender: this.renderStatusColumn, lookup: { dataSource: systemStatus, displayExpr: 'value', valueExpr: 'key' } })
    }
    
    columns.push(
      { resource: "COMPANY.FIELD.COMPANY_ENABLECOMPANYUSERS", dataField: "enablecompanyusers" },
      { resource: "COMPANY.FIELD.COMPANY_ADDCOMPANYUSERSTO", dataField: "adduserstocompanyid", lookup: { dataSource: this.state.companies, displayExpr: 'name', valueExpr: 'companyid' } },
      { resource: 'COMPANY.FIELD.COMPANY_NUMBER', dataField: 'companynumber' }
    )

    if (this.companyTagsEnabled) {
      columns.push(
          { resource: "CONTRACT.FIELD.FRAMEWORKS", dataField: "framework", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.frameworkFilter } }
      )
    }
  
    return (
      <Portlet>
        <PortletBody>
          <DataGridBase
            version="1"
            title={this.props.intl.formatMessage({ id: "COMPANY.LIST.TITLE" })}
            createPath={this.canCreateNew ? "/admin/companies/new-company" : null}
            name={`Companies`}
            dataSource={this.state.companies}
            keyExpr="companyid"
            selection={{ mode: 'single' }}
            onSelectionChanged={this.onTableSelectionChanged}
            hoverStateEnabled={true}
            rowAlternationEnabled={false}
            columns={columns}
          />
        </PortletBody>
      </Portlet>
    );
  }
}

const mapStateToProps = store => ({
  userProfile: store.auth.userProfile
});

export default withRouter(withSnackbar(injectIntl(connect(mapStateToProps)(CompaniesRegister))));