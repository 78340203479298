/* eslint-disable no-script-url,array-callback-return */
import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import { connect } from "react-redux";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import Spinner from '../partials/layout/Spinner';
import { addWeeks } from 'date-fns';
import { withRouter } from '../shared/hoc/withRouter';
import { OverlayTrigger, Popover } from "react-bootstrap";
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/bootstrap/main.css';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import nlLocale from '@fullcalendar/core/locales/nl';
import { injectIntl } from "react-intl";

const popover = (event, referenceLabel, titleLabel) => (
  <Popover id="popover-basic">
    <Popover.Title as="h3">{event.extendedProps.header}</Popover.Title>
    <Popover.Content>
      {event.extendedProps.reference && (<div><strong>{referenceLabel}: </strong>{event.extendedProps.reference}<br /></div>)}
      {event.extendedProps.description && (<><strong>{titleLabel}: </strong>{event.extendedProps.description}<br /></>)}
    </Popover.Content>
  </Popover>
);


class ConrtractCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
    this.state = {
      dates: null
    }
  }

  componentDidMount() {
    this.setDates();
  }
  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (this.timer2) {
      clearTimeout(this.timer2);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sidePanel !== this.props.sidePanel ||
      prevProps.dashboard !== this.props.dashboard ||
      prevProps.pendingActions !== this.props.pendingActions) {
      this.setDates();
    }

    // if (this.calendarRef.current) {
    //   this.timer2 = setTimeout(() => { if (this.calendarRef.current) { this.calendarRef.current.calendar.rerenderEvents(); } }, 1000);
    // }
  }
  setDates = () => {
    if (this.props.sidePanel && this.props.dashboard && this.props.pendingActions) {

      const allAvailableWFCodes = this.props.sidePanel.supplierItems.concat(this.props.sidePanel.pmItems).concat(this.props.sidePanel.supervisorItems).concat(this.props.sidePanel.clientItems)
        .concat(this.props.sidePanel.supplierActions).concat(this.props.sidePanel.pmActions).concat(this.props.sidePanel.supervisorActions).concat(this.props.sidePanel.clientActions);

      const calendarDates = this.props.pendingActions.map(action => {

        let actionMenuItem = null;
        if (action.act === "ACTION") {
          actionMenuItem = allAvailableWFCodes.find(item => item.actionCode === action.typ);
        }
        else {
          actionMenuItem = allAvailableWFCodes.find(item => item.actionCode === action.act);
        }
        if (action.rdt) {
          const actionDate = new Date(action.rdt);
          let url = null;
          if (actionMenuItem?.workflow) {
            if (action.nid) {
              url = actionMenuItem.workflow.paths.basePath + action.nid
            }
            else {
              url = actionMenuItem.workflow.paths.createPath
            }
          }
          let className = 'bg-success';
          if (actionDate < new Date()) {
            className = 'bg-danger';
          }
          else if (actionDate < addWeeks(new Date(), 1)) {
            className = 'bg-warning';
          }
          // const recordID = actionMenuItem.workflow.abbreviation + Common.Lpad(action.seq, 5);
          if (action.rdt) {
            return {
              title: action.act === "ACTION" ? action.tit : action.ref,
              reference: action.ref,
              date: actionDate,
              url: url,
              allDay: true,
              classNames: [className],
              header: action.act === "ACTION" ? "Assigned Action" : actionMenuItem?.title,
              description: action.tit,
            };
          }
        }


      }).filter(itm => !!itm);
      this.setState({ dates: calendarDates });

    }
  }



  eventClick = (args) => {
    args.jsEvent.preventDefault();
    // console.log(args.event.extendedProps.overlayRef.current);
    if (args.event.url && args.event.url !== "null") {
      this.props.navigate(args.event.url);
    }
  }
  eventRender = ({ event, el, view }) => {
    const component = (
      <OverlayTrigger
        // ref={event.extendedProps.overlayRef}
        key={event.title}
        trigger={['hover', 'focus']}
        // container={this.calendarRef.current}
        overlay={popover(event, this.props.intl.formatMessage({ id: "GENERAL.FORMS.FIELD.REFERENCE" }), this.props.intl.formatMessage({ id: "GENERAL.FORMS.FIELD.TITLE" }))}
        placement="auto"
        rootClose={true}
        rootCloseEvent="mousedown"
      >
        <div className="fc-content">
          <span className="fc-title">{event.title}</span>
        </div>
      </OverlayTrigger>
    );
    const root = createRoot(el);
    root.render(component);
    return el;
  }
  render() {
    if (!this.state.dates) {
      return (<Spinner />);
    }

    return (
      <FullCalendar
        ref={this.calendarRef}
        locales={[nlLocale]}
        locale={this.props.lang}
        defaultView="dayGridMonth"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin]}
        weekends={true}
        // editable={true}
        header={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',

        }}
        events={this.state.dates}
        // dateClick={null}
        eventClick={this.eventClick}
        // eventPositioned={this.handleEventPositioned}
        eventRender={this.eventRender}
        eventLimit={true}
        themeSystem="bootstrap"
      />
    )
  }
}

const mapStateToProps = store => ({
  lang: store.i18n.lang,
  contract: store.contract.contract,
  dashboard: store.contract.dashboard,
  sidePanel: store.contract.sidePanel,
  pendingActions: store.contract.pendingActions,
});



export default injectIntl(withRouter(connect(mapStateToProps)(ConrtractCalendar)));