import {placeholderTypes} from "../../Types/Placeholder";
const placeholders =  [
    {
        replacementKey: 'Resources', values:
            [
                { id: 1, isActive: true, field: 'CumulativePriceLabel', display: 'Cumulative Price for Work Done to Date' },
                { id: 2, isActive: true, field: 'PlusAmountsSupplierLabel', display: 'Plus other amounts to be paid to the $SUPPLIER_NAME' },
                { id: 3, isActive: true, field: 'LessAmountsSupplierLabel', display: 'Less amounts to be paid by or retained from the $SUPPLIER_NAME' },
                { id: 4, isActive: true, field: 'TaxSupplierLabel', display: 'Tax which the law requires the Employer to pay to the $SUPPLIER_NAME' },
                { id: 5, isActive: true, field: 'SummaryDetailsLabel', display: 'Summary details of attached payment application' },
                { id: 6, isActive: true, field: 'WarningBanner', display: 'The first assessment date is no later than the assessment interval after the starting date. Subsequent assessment dates are the assessment interval after the previous assessment. Assessments continue until 4 weeks after the issue of the Defects Certificate with an additional assessment at Completion.' },
                { id: 7, isActive: true, field: 'LessAmountsDueLabel', display: 'Less amount due in the last payment certificate' },
                { id: 8, isActive: true, field: 'PaymentAppliedPeriodLabel', display: 'Payment applied for this period' },
                { id: 9, isActive: true, field: 'PaymentCertifiedPeriodLabel', display: 'Payment certified for this period' },
                { id: 10, isActive: true, field: 'RegisterAppliedByCaption', display: 'Notified By' },
                { id: 11, isActive: true, field: 'RegisterApplicationDateCaption', display: 'Application Date' },
                { id: 12, isActive: true, field: 'RegisterCertifiedDateCaption', display: 'Certified Date' },
                { id: 13, isActive: true, field: 'RegisterCertifiedByCaption', display: 'Notified By' },
                { id: 14, isActive: true, field: 'AssessmentDayLabel', display: 'Assessment Date' },
            ],
        type:placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'TaxEnabled', values:
            [
                { id: 1, isActive: true, field: 'TaxEnabled', display: 'Active-Inactive state indicates tax field will be included in notification forms' },
            ],
        isToggle: true,
        fieldRequired: true,
        type:placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'MandateAttachment', values:
            [
                { id: 1, isActive: false, field: 'MandateAttachment', display: 'Active-Inactive state indicates whether attachment is mandatory on Payment Application and Certifications' },
            ],
        isToggle: true,
        fieldRequired: true,
        type:placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'ActionReminders', values:[],
        type:placeholderTypes.ACTION_REMINDERS
    },
    {
        replacementKey: 'PONumberEnabled', values:
            [
                { id: 1, isActive: false, field: 'PONumberEnabled', display: 'Purchase Order number field will be included in notification forms' },
            ],
        isToggle: true,
        fieldRequired: true,
        type:placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'ApplicationTypes', values:
            [
                { id: 1, isActive: true, field: 'FINAL', display: 'Final' },
                { id: 2, isActive: true, field: 'INTERIM', display: 'Interim' },
                { id: 3, isActive: true, field: 'SUPPLEMENTARY', display: 'Supplementary' }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'PaymentTypes', values:
            [
                { id: 1, isActive: true, field: 'FINAL', display: 'Final' },
                { id: 2, isActive: true, field: 'INTERIM', display: 'Interim' },
                { id: 3, isActive: true, field: 'SUPPLEMENTARY', display: 'Supplementary' }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    }
];

export default placeholders;