export const propertySummary = (obj, propList) => {
    let sum = 0;
    propList.forEach(item => {
        if (obj[item.prop]) {
            if (typeof obj[item.prop] == 'number') {
                sum += obj[item.prop];
            }
        }
    });
    return sum;
}

export const actionCodeReducerToCount = (pendingActionList, sidePanelItems) => {
    return sidePanelItems.reduce((count, item) => (count = count + item.itemsList.length), 0);
    // const availableActionCodes = sidePanelItems.reduce((arr, item) => { arr.push(item.actionCode); return arr; }, []);
    // return pendingActionList.filter(action => availableActionCodes.includes(action.act)).length;
}