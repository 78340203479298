const template = {
  name: "Default NEC4 PSS v2.5 01/05/2024",
  isSystemDefault: true,
  isGlobal: true,
  companies: [],
  resources: [
    {
      id: 1,
      type: "TEXT",
      key: "SUPPLIER_NAME",
      value: "Subcontractor"
    },
    {
      id: 2,
      type: "TEXT",
      key: "SUPPLIER_ABBREVIATION",
      value: "SUB"
    },
    {
      id: 3,
      type: "TEXT",
      key: "PM_NAME",
      value: "Contractor"
    },
    {
      id: 4,
      type: "TEXT",
      key: "PM_ABBREVIATION",
      value: "CON"
    },
    {
      id: 5,
      type: "TEXT",
      key: "SUPERVISOR_NAME",
      value: "Contractor"
    },
    {
      id: 6,
      type: "TEXT",
      key: "SUPERVISOR_ABBREVIATION",
      value: "CON"
    },
    {
      id: 7,
      type: "TEXT",
      key: "CLIENT_NAME",
      value: "Client"
    },
    {
      id: 8,
      type: "NUMBER",
      key: "SUPPLIER_REPLY_PERIOD",
      value: 7
    },
    {
      id: 9,
      type: "NUMBER",
      key: "PM_REPLY_PERIOD",
      value: 7
    },
    {
      id: 10,
      type: "NUMBER",
      key: "QUOTE_DURATION",
      value: 14
    },
    {
      id: 13,
      type: "NUMBER",
      key: "REQUOTE_DURATION",
      value: 14
    },
    {
      id: 11,
      type: "NUMBER",
      key: "QUOTE_RESPONSE",
      value: 28
    },
    {
      id: 14,
      type: "NUMBER",
      key: "REQUOTE_RESPONSE",
      value: 28
    },
    {
      id: 12,
      type: "NUMBER",
      key: "VARIATION_RESPONSE",
      value: 21
    },
    {
      id: 16,
      type: "NUMBER",
      key: "PROGRAMME_REPLY_PERIOD",
      value: 21
    },
    {
      id: 8,
      type: "TEXT",
      key: "CLIENT_ABBREVIATION",
      value: "CL"
    },
    {
      id: 9,
      type: "TEXT",
      key: "COMPLETION_DATE",
      value: "Completion Date"
    }
  ],
  workflowGroups: [
    {
      id: 101,
      title: "Early Warnings",
      workflows: [
        {
          id: 1,
          title: "$PM_NAME Early Warnings",
          notificationName: "Early Warning",
          abbreviation: "$PM_ABBREVIATION-Warning-",
          notify: true,
          replyEnabled: true,
          assetFieldsEnabled: false,
          replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "PM",
          replyingParty: "SUPPLIER",
          actioningParty: "PM",
          resolveActioningParty: "PM",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            actionTab: {
              actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
            resolveActionTab: {
              resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            }
          }
        },
        {
          id: 2,
          title: "$SUPPLIER_NAME Early Warnings",
          notificationName: "Early Warning",
          abbreviation: "$SUPPLIER_ABBREVIATION-Warning-",
          notify: true,
          replyEnabled: true,
          assetFieldsEnabled: false,
          replyPeriodOption: "PM_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "SUPPLIER",
          replyingParty: "PM",
          actioningParty: "PM",
          resolveActioningParty: "PM",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            actionTab: {
              actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
            resolveActionTab: {
              resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            }
          }
        },
        {
          id: 3,
          title: "Early Warning Register",
          notificationName: "Early Warning",
          abbreviation: "Warning",
          overrideResources: null,
          userFilters: {
            accessFilter: "null"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "MeetingRequiredLabel",
              display: "Is Early Warning Meeting required?",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "NotificationClause",
              display: "You are given early warning of a matter which could",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "RiskItemsLabel",
              display: "You are given early warning of a matter which could",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Pm",
              display: "$PM_ABBREVIATION Early Warnings awaiting reply",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "Supplier",
              display: "$SUPPLIER_NAME Early Warnings awaiting reply",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "MeetingOptions",
          values: [
            {
              id: 1,
              isActive: true,
              field: "0",
              display: "Meeting Not Required",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "1",
              display: "Meeting Required - Next Scheduled Risk Reduction Meeting",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "2",
              display: "Meeting Required - You are instructed to attend a meeting as below",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "RiskTypes",
          values: [
            {
              id: 1,
              isActive: true,
              field: "riskincreaseprice",
              display: "increase the total of the Prices",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "riskdelaycompletion",
              display: "delay Completion",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "riskdelaymilestone",
              display: "delay meeting a Key Date",
              overrideResources: null
            },
            {
              id: 14,
              isActive: true,
              field: "riskserviceperformance",
              display: "impair the usefulness of the Subcontract Service",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "riskincreasecontractorcost",
              display: "increase $SUPPLIER_NAME's total cost",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "riskaffectmodel",
              display: "adversely affect the creation or use of the Information Model (X10.3)",
              overrideResources: null
            },
            {
              id: 11,
              isActive: true,
              field: "riskotherparty",
              display: "affect the achievement of another Partner's objectives (X12.3(3)",
              overrideResources: null
            },
            {
              id: 4,
              isActive: false,
              field: "riskimpairperformance",
              display: "impair the performance of the works in use",
              overrideResources: null
            },
            {
              id: 6,
              isActive: false,
              field: "riskincreasedefinedcost",
              display: "Increase the Defined Cost of expending any Provisional Sums",
              overrideResources: null
            },
            {
              id: 7,
              isActive: false,
              field: "riskaffectsafety",
              display: "Adversely affect the $SUPPLIER_NAME’s ability to safely carry out the works",
              overrideResources: null
            },
            {
              id: 8,
              isActive: false,
              field: "riskcontractbreach",
              display: "Constitute or cause a breach of any of the $SUPPLIER_NAME’s obligations under this contract",
              overrideResources: null
            },
            {
              id: 10,
              isActive: false,
              field: "riskaffectwork",
              display: "Affect the work of the $PM_NAME",
              overrideResources: null
            },
            {
              id: 12,
              isActive: false,
              field: "riskchangeprogramme",
              display: "Affect the programme",
              overrideResources: null
            },
            {
              id: 13,
              isActive: false,
              field: "riskservicetiming",
              display: "Affect the timing of the Service",
              overrideResources: null
            },
            {
              id: 15,
              isActive: false,
              field: "riskenvironment",
              display: "Adversely affect the environment",
              overrideResources: null
            },
            {
              id: 16,
              isActive: false,
              field: "riskother",
              display: "Other risk mentioned in this contract",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 102,
      title: "Instructions",
      workflows: [
        {
          id: 4,
          title: "$PM_NAME Instruction",
          notificationName: "Instruction",
          abbreviation: "$PM_ABBREVIATION-Instruction-",
          notify: true,
          replyEnabled: false,
          assetFieldsEnabled: false,
          replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "PM",
          replyingParty: "SUPPLIER",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "InstructionTypes",
          values: [
            {
              id: 17,
              isActive: true,
              field: "",
              display: "14.3 to change the Subcontract Scope or a Key Date",
              overrideResources: null
            },
            {
              id: 18,
              isActive: true,
              field: "",
              display: "16.1 how to resolve an ambiguity or inconsistency",
              overrideResources: null
            },
            {
              id: 19,
              isActive: true,
              field: "",
              display: "17.2 to change the Subcontract Scope to remove something which is illegal or impossible",
              overrideResources: null
            },
            {
              id: 20,
              isActive: true,
              field: "",
              display: "18.1 on how to deal with a prevention event",
              overrideResources: null
            },
            {
              id: 21,
              isActive: true,
              field: "",
              display: "21.2 to remove a person",
              overrideResources: null
            },
            {
              id: 22,
              isActive: true,
              field: "",
              display: "32.2 to submit a revised programme",
              overrideResources: null
            },
            {
              id: 23,
              isActive: true,
              field: "",
              display: "34.1 to stop or not start work or to start or restart work",
              overrideResources: null
            },
            {
              id: 24,
              isActive: true,
              field: "",
              display: "35.1 to submit a quotation for acceleration",
              overrideResources: null
            },
            {
              id: 25,
              isActive: true,
              field: "",
              display: "40.3 to correct a failure to comply with the quality plan",
              overrideResources: null
            },
            {
              id: 26,
              isActive: true,
              field: "",
              display: "42.1 to change the Subcontract Scope, Prices and Completion Date after accepting a proposal not to correct a Defect",
              overrideResources: null
            },
            {
              id: 27,
              isActive: true,
              field: "",
              display: "62.1 to submit alternative quotations for a compensation event",
              overrideResources: null
            },
            {
              id: 28,
              isActive: true,
              field: "",
              display: "92.2 to assign the benefit of subcontracts to the Contractor",
              overrideResources: null
            },
            {
              id: 29,
              isActive: true,
              field: "",
              display: "X10.4(3) to submit a revised Information Execution Plan",
              overrideResources: null
            },
            {
              id: 30,
              isActive: true,
              field: "",
              display: "X12.1(6) providing Partnering Information",
              overrideResources: null
            },
            {
              id: 16,
              isActive: true,
              field: "",
              display: "Other Instruction",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "Resources",
          values: []
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "PM",
              display: "$PM_NAME Instructions awaiting reply",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "Client",
              display: "$CLIENT_NAME Instructions awaiting reply",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "Supervisor",
              display: "$SUPERVISOR_NAME Instructions awaiting reply",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ReplyOptions",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Accepted",
              display: "Accepted",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "Accepted with comments",
              display: "Accepted with comments",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "Not Accepted",
              display: "Not Accepted",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "Reply Not Required",
              display: "Reply Not Required",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 103,
      title: "Compensation Events",
      workflows: [
        {
          id: 5,
          title: "$PM_NAME Compensation Events",
          notificationName: "Compensation Event",
          abbreviation: "$PM_ABBREVIATION-Compensation-",
          notify: true,
          financialLimitEnabled: false,
          assetFieldsEnabled: false,
          notifyingParty: "PM",
          quotingParty: "SUPPLIER",
          quoteReplyingParty: "PM",
          quoteNotificationName: "Quote",
          quoteReferencePrefix: "Quote-",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            quoteMainNotice: {
              createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            quoteReplyNotice: {
              saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        },
        {
          id: 6,
          title: "$PM_NAME Proposed Instructions",
          notificationName: "Proposed Instruction",
          abbreviation: "$PM_ABBREVIATION-Instruction-",
          notify: true,
          financialLimitEnabled: false,
          assetFieldsEnabled: false,
          notifyingParty: "PM",
          quotingParty: "SUPPLIER",
          quoteReplyingParty: "PM",
          quoteNotificationName: "Quote",
          quoteReferencePrefix: "Quote-",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            quoteMainNotice: {
              createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            quoteReplyNotice: {
              saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        },
        {
          id: 7,
          title: "$SUPPLIER_NAME Compensation Events",
          notificationName: "Compensation Event",
          abbreviation: "$SUPPLIER_ABBREVIATION-Compensation-",
          notify: true,
          replyEnabled: true,
          financialLimitEnabled: false,
          assetFieldsEnabled: false,
          replyPeriodOption: "VARIATION_RESPONSE",
          replyPeriod: 0,
          notifyingParty: "SUPPLIER",
          replyingParty: "PM",
          quotingParty: "SUPPLIER",
          quoteReplyingParty: "PM",
          quoteNotificationName: "Quote",
          quoteReferencePrefix: "Quote-",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            quoteMainNotice: {
              createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            quoteReplyNotice: {
              saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        },
        {
          id: 8,
          title: "$PM_NAME Assessments",
          notificationName: "Assessment",
          abbreviation: "$PM_ABBREVIATION-Assessment-",
          notify: true,
          financialLimitEnabled: false,
          assetFieldsEnabled: false,
          notifyingParty: "PM",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
          }
        },
        {
          id: 9,
          title: "$PM_NAME Extensions",
          notificationName: "Extension",
          abbreviation: "$PM_ABBREVIATION-Extension-",
          notify: true,
          replyEnabled: true,
          assetFieldsEnabled: false,
          replyPeriodOption: "ORIGINAL_DATE",
          replyPeriod: 0,
          notifyingParty: "PM",
          replyingParty: "SUPPLIER",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        },
        {
          id: 10,
          title: "$SUPPLIER_NAME Extensions",
          notificationName: "Extension",
          abbreviation: "$SUPPLIER_ABBREVIATION-Extension-",
          notify: true,
          replyEnabled: true,
          assetFieldsEnabled: false,
          replyPeriodOption: "ORIGINAL_DATE",
          replyPeriod: 0,
          notifyingParty: "SUPPLIER",
          replyingParty: "PM",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        },
        {
          id: 11,
          title: "Master Quote Register",
          overrideResources: null,
          userFilters: {
            accessFilter: "null"
          }
        },
        {
          id: 12,
          title: "Master CE Register",
          overrideResources: null,
          userFilters: {
            accessFilter: "null"
          }
        },
        {
          id: 13,
          title: "Implemented CE Register",
          overrideResources: null,
          userFilters: {
            accessFilter: "null"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "PmAssumptionLabel",
              display: "The quotation is to be based on the following assumptions",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "PmAssumptionNote",
              display: "61.2 Under 61.2, you are instructed to provide a quotation by $date",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "SupplierDescNote",
              display: "You are notified of the following event as a compensation event",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "ReplyAcceptedCommentLabel",
              display: "You are notified that the event is a compensation event",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "ReplyRejectedCommentLabel",
              display: "Details",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "ReplyAcceptedAssumptionLabel",
              display: "The quotation is to be based on the following assumptions",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "QuoteNote",
              display: "We submit our quotation for compensation event",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "ExtensionPMLabel",
              display: "We require an extension to the time to reply as follows",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "ExtensionSupplierLabel",
              display: "We require an extension to the time to reply as follows",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "AssessmentDetailLabel",
              display: "Details",
              overrideResources: null
            },
            {
              id: 11,
              isActive: true,
              field: "ProposedInstructionNote",
              display: "Under clause 65.1, you are instructed to submit a quotation for a proposed instruction by $date",
              overrideResources: null
            },
            {
              id: 12,
              isActive: true,
              field: "ProposedInstructionLabel",
              display: "The quotation is to be based on the following assumptions",
              overrideResources: null
            },
            {
              id: 13,
              isActive: true,
              field: "ReplyRejectedOptionsLabel",
              display: "You are notified that the event is not a compensation event as it",
              overrideResources: null
            },
            {
              id: 14,
              isActive: true,
              field: "QuoteCostEffectLabel",
              display: "Change to the Prices",
              overrideResources: null
            },
            {
              id: 15,
              isActive: true,
              field: "QuoteDateEffectLabel",
              display: "Delay to planned Completion",
              overrideResources: null
            },
            {
              id: 16,
              isActive: true,
              field: "QuoteDaysEffectLabel",
              display: "The length of time that the Completion Date is delayed",
              overrideResources: null
            },
            {
              id: 17,
              isActive: true,
              field: "AssessmentCostEffectLabel",
              display: "Change in the Total of the Prices",
              overrideResources: null
            },
            {
              id: 18,
              isActive: true,
              field: "AssessmentDaysEffectLabel",
              display: "Delay to planned Completion",
              overrideResources: null
            },
            {
              id: 19,
              isActive: true,
              field: "QuoteNoteProposedInstruction",
              display: "We submit our quotation for proposed compensation event $reference",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "PmCompensationAQ",
              display: "Compensation Events notified by $PM_ABBREVIATION awaiting quotes",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "PmCompensationARQ",
              display: "Compensation Events notified by $PM_ABBREVIATION awaiting revised quotes",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "PmCompensationAPMRQ",
              display: "Compensation Events notified by $PM_ABBREVIATION awaiting response to quotes",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "PmCompensationAPMA",
              display: "Compensation Events notified by $PM_ABBREVIATION awaiting $PM_ABBREVIATION Assessment",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "SupCompensationAQ",
              display: "Compensation Events notified by $SUPPLIER_NAME awaiting quotes",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "SupCompensationARQ",
              display: "Compensation Events notified by $SUPPLIER_NAME awaiting revised quotes",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "SupCompensationAPMR",
              display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION response",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "SupCompensationAPMRQ",
              display: "Compensation Events notified by $SUPPLIER_NAME awaiting response to quotes",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "SupCompensationAPMA",
              display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "PmProposedAQ",
              display: "Proposed changes awaiting quotes",
              overrideResources: null
            },
            {
              id: 11,
              isActive: true,
              field: "PmProposedARQ",
              display: "Proposed changes awaiting revised quotes",
              overrideResources: null
            },
            {
              id: 12,
              isActive: true,
              field: "PmProposedAPMRQ",
              display: "Proposed changes awaiting response to quotes",
              overrideResources: null
            },
            {
              id: 13,
              isActive: true,
              field: "PmProposedAPMA",
              display: "Proposed changes awaiting $PM_ABBREVIATION Assessment",
              overrideResources: null
            },
            {
              id: 14,
              isActive: true,
              field: "PmExtension",
              display: "$PM_ABBREVIATION Quote Response Extensions awaiting Acceptance",
              overrideResources: null
            },
            {
              id: 15,
              isActive: true,
              field: "SupExtension",
              display: "$SUPPLIER_NAME Quote Extensions awaiting Acceptance",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "CompensationClauses",
          values: [
            {
              id: 2,
              isActive: true,
              field: "",
              display: "60.1(1) of an instruction to change the Subcontract Scope",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "",
              display: "60.1(2) that the Contractor does not allow access to a person, place or thing",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "",
              display: "60.1(3) that the Contractor does not provide something that it is to provide",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "",
              display: "60.1(4) of an instruction to stop or not start work or to change a Key Date",
              overrideResources: null
            },
            {
              id: 11,
              isActive: true,
              field: "",
              display: "60.1(5) that the Client, Contractor or Others do not work within the stated constraints",
              overrideResources: null
            },
            {
              id: 12,
              isActive: true,
              field: "",
              display: "60.1(6) that the Contractor does not reply to a communication",
              overrideResources: null
            },
            {
              id: 13,
              isActive: true,
              field: "",
              display: "60.1(7) that the Contractor has changed a decision",
              overrideResources: null
            },
            {
              id: 14,
              isActive: true,
              field: "",
              display: "60.1(8) that the Contractor withholds an acceptance",
              overrideResources: null
            },
            {
              id: 15,
              isActive: true,
              field: "",
              display: "60.1(9) of an event which is the Client's or Contractor's liability",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "",
              display: "60.1(10) that the Contractor notifies a correction to an assumption",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "",
              display: "60.1(11) of a breach by the Contractor",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "",
              display: "60.1(12) of a prevention event",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "",
              display: "60.1(13) of a correction of a Defect for which the Subcontractor is not liable",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "",
              display: "60.1(14) of an instruction correcting information provided by the Contractor",
              overrideResources: null
            },
            {
              id: 27,
              isActive: true,
              field: "",
              display: "60.1(15) that a quotation for a proposed instruction is not accepted",
              overrideResources: null
            },
            {
              id: 25,
              isActive: true,
              field: "",
              display: "X2.1 of a change in the law",
              overrideResources: null
            },
            {
              id: 23,
              isActive: true,
              field: "",
              display: "X12.3(6) of a change in the Partnering Information",
              overrideResources: null
            },
            {
              id: 24,
              isActive: true,
              field: "",
              display: "X12.3(7) of a change in the Partnering timetable",
              overrideResources: null
            },
            {
              id: 26,
              isActive: true,
              field: "",
              display: "Y2.5 of a suspension of performance",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ReplyRejectOptions",
          values: [
            {
              id: 1,
              isActive: true,
              field: "faultpscp",
              display: "Arises from a fault of the $SUPPLIER_NAME",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "notexpectnothappen",
              display: "Has not happened and is not expected to happen",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "notnotifiedwithintimescales",
              display: "Has not been notified within the timescales set out in the contract",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "noteffectcostcompletion",
              display: "Has no effect on Defined Cost, Completion or meeting a Key Date",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "statedcontract",
              display: "Is not one of the compensation events stated in the contract",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ClaimClauses",
          values: [
            {
              id: 1,
              isActive: true,
              field: "",
              display: "60.1(6) Late reply to communication",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "",
              display: "60.1(1) Employer has given an instruction to change the Scope",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "",
              display: "60.1(3) Something not provided",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "",
              display: "60.1(5) Work by employer or others",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "",
              display: "60.1(7) Previous decision changed",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "",
              display: "60.1(2) Access not provided by date stated within the contract",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "",
              display: "60.1(4) Stop or change key date",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "",
              display: "60.1(9) PM corrects an assumption related to a previous compensation event",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "",
              display: "60.1(10) Breach of Contract",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "",
              display: "60.1(8) Acceptance Withheld",
              overrideResources: null
            },
            {
              id: 11,
              isActive: true,
              field: "",
              display: "60.1(11) Prevention event",
              overrideResources: null
            },
            {
              id: 12,
              isActive: true,
              field: "",
              display: "60.1(12) Defect corrected which was not the Consultant’s liability",
              overrideResources: null
            },
            {
              id: 13,
              isActive: true,
              field: "",
              display: "60.1(13): Instruction to change Task Order issued by Employer",
              overrideResources: null
            },
            {
              id: 14,
              isActive: true,
              field: "",
              display: "60.1(14): Consultant receives late Task Order",
              overrideResources: null
            },
            {
              id: 15,
              isActive: true,
              field: "",
              display: "60.1(15): a Task Completion Date is later than the Completion Date",
              overrideResources: null
            },
            {
              id: 16,
              isActive: true,
              field: "",
              display: "X2.1: Change in law",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "CompensationStatuses",
          values: [
            {
              id: 1,
              isActive: true,
              field: "APMA",
              display: "Quote Not Accepted - $PM_ABBREVIATION to make own Assessment",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "ARQ",
              display: "Quote Not Accepted - $SUPPLIER_NAME to resubmit",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "AM",
              display: "$PM_ABBREVIATION Assessment Made",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "QA",
              display: "Quote Accepted",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "APMRQ",
              display: "Awaiting $PM_ABBREVIATION Response to quote",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "APMR",
              display: "Awaiting $PM_ABBREVIATION Response to CEN",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "AQ",
              display: "Awaiting Quote",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "NC",
              display: "Not Communicated",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "R",
              display: "Not a Compensation Event",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "DNG",
              display: "Decision Not Given",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "QuoteStatuses",
          values: [
            {
              id: 1,
              isActive: true,
              field: "0",
              display: "Not Accepted - $SUPPLIER_NAME to resubmit",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "1",
              display: "Accepted",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "2",
              display: "Awaiting Response",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "3",
              display: "Not Accepted - $PM_ABBREVIATION to make assessment",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "4",
              display: "Not Accepted - Other quote approved",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "5",
              display: "Not Accepted - Decision will not be given",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "none",
              display: "Not Communicated",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "InstructionReplyProceedOpts",
          values: [
            {
              id: 1,
              isActive: true,
              field: "61.1",
              display: "Consultant is instructed to proceed with works",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "61.2",
              display: "Consultant is instructed to NOT proceed with works until instructed",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 104,
      title: "Programme",
      workflows: [
        {
          id: 14,
          title: "$SUPPLIER_NAME Programme",
          notificationName: "Programme",
          abbreviation: "$SUPPLIER_ABBREVIATION-Programme-",
          notify: true,
          replyEnabled: true,
          assetFieldsEnabled: false,
          replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "SUPPLIER",
          replyingParty: "PM",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "ReasonLabel",
              display: "Reason for any change to Planned Completion",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "ChangeLabel",
              display: "Change in any other Key Date, Completion Date for a section or other important project milestone in period and reasons why",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "MainProgressLabel",
              display: "Main progress achieved in period",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "KeyActivitiesLabel",
              display: "Key activities forecast in next four weeks",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "SummaryLabel",
              display: "Summary of critical path",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "ChangesLabel",
              display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc.",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "CompensationLabel",
              display: "Compensation events added to programme this period",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "NotificationClause",
              display: "Please find attached programme for acceptance",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "ReplyRejectReasonLabel",
              display: "The $SUPPLIER_NAME’s programme is not accepted for the following reasons",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "ReplyNotificationClause",
              display: "You are notified that the $SUPPLIER_NAME’s Programme",
              overrideResources: null
            },
            {
              id: 11,
              isActive: true,
              field: "ReferenceLabel",
              display: "Programme Reference",
              overrideResources: null
            },
            {
              id: 12,
              isActive: true,
              field: "DashboardPlannedCompletionLabel",
              display: "Planned Completion shown on the latest Accepted Programme",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Supplier",
              display: "$SUPPLIER_NAME Programme Submission awaiting Acceptance",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ReplyRejectOptions",
          values: [
            {
              id: 1,
              isActive: true,
              field: "plansnotpractical",
              display: "The $SUPPLIER_NAME’s plans which it shows are not practicable",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "missingcontractrequires",
              display: "It does not show the information which the contract requires",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "notrepresentplan",
              display: "It does not represent the $SUPPLIER_NAME’s plans realistically",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "notcomply",
              display: "It does not comply with the Scope",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 105,
      title: "Notifications",
      workflows: [
        {
          id: 15,
          title: "$PM_NAME Notifications",
          notificationName: "Notification",
          abbreviation: "$PM_ABBREVIATION-Notification-",
          notify: true,
          replyEnabled: false,
          assetFieldsEnabled: false,
          replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "PM",
          replyingParty: "SUPPLIER",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        },
        {
          id: 16,
          title: "$SUPPLIER_NAME Notifications",
          notificationName: "Notification",
          abbreviation: "$SUPPLIER_ABBREVIATION-Notification-",
          notify: true,
          replyEnabled: false,
          assetFieldsEnabled: false,
          replyPeriodOption: "PM_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "SUPPLIER",
          replyingParty: "PM",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "PmClauses",
          values: [
            {
              id: 29,
              isActive: true,
              field: "",
              display: "13.2 of an address to receive communications",
              overrideResources: null
            },
            {
              id: 30,
              isActive: true,
              field: "",
              display: "13.5 of an agreement to extend the period for reply for a communication",
              overrideResources: null
            },
            {
              id: 31,
              isActive: true,
              field: "",
              display: "14.2 of the cancellation of a delegation",
              overrideResources: null
            },
            {
              id: 32,
              isActive: true,
              field: "",
              display: "16.1 of an ambiguity or inconsistency",
              overrideResources: null
            },
            {
              id: 33,
              isActive: true,
              field: "",
              display: "17.2 of an illegal or impossible requirement",
              overrideResources: null
            },
            {
              id: 34,
              isActive: true,
              field: "",
              display: "22.3 of the amount for a failure to meet a Key Date",
              overrideResources: null
            },
            {
              id: 35,
              isActive: true,
              field: "",
              display: "23.3 of an agreement that subcontract documents should not be submitted",
              overrideResources: null
            },
            {
              id: 36,
              isActive: true,
              field: "",
              display: "24.4 of an agreement not to submit pricing information for a subcontract (main Options C and E)",
              overrideResources: null
            },
            {
              id: 37,
              isActive: true,
              field: "",
              display: "25.1 of an intention to transfer the benefit of the contract or any rights under it",
              overrideResources: null
            },
            {
              id: 39,
              isActive: true,
              field: "",
              display: "41.2 of the cost to the Contractor of having a Defect corrected",
              overrideResources: null
            },
            {
              id: 58,
              isActive: true,
              field: "",
              display: "50.9 of a reply to a submission of records demonstrating Defined Cost has been correctly assessed (main Options C and E)",
              overrideResources: null
            },
            {
              id: 59,
              isActive: true,
              field: "",
              display: "50.9 of a reply to a submission of further records in connection with Defined Cost (main Options C and E)",
              overrideResources: null
            },
            {
              id: 40,
              isActive: true,
              field: "",
              display: "51.3 of interest on a corrected payment certificate",
              overrideResources: null
            },
            {
              id: 41,
              isActive: true,
              field: "",
              display: "54.3 of the preliminary amount of the Subcontractor's share (main Option C)",
              overrideResources: null
            },
            {
              id: 42,
              isActive: true,
              field: "",
              display: "54.4 of the final amount of the Subcontractor's share (main Option C)",
              overrideResources: null
            },
            {
              id: 43,
              isActive: true,
              field: "",
              display: "61.5 that the Contractor has decided the Subcontractor did not give early warning of a compensation event",
              overrideResources: null
            },
            {
              id: 44,
              isActive: true,
              field: "",
              display: "61.6 of a correction because an assumption on which a compensation event quotation is based was wrong",
              overrideResources: null
            },
            {
              id: 45,
              isActive: true,
              field: "",
              display: "85.1 of the cost of insurance provided by the Contractor",
              overrideResources: null
            },
            {
              id: 47,
              isActive: true,
              field: "",
              display: "90.1 of a wish to terminate",
              overrideResources: null
            },
            {
              id: 48,
              isActive: true,
              field: "",
              display: "91.2 of a default/of the Subcontractor having not put right a default",
              overrideResources: null
            },
            {
              id: 49,
              isActive: true,
              field: "",
              display: "91.3 of a default/of the Subcontractor having not put right a default",
              overrideResources: null
            },
            {
              id: 50,
              isActive: true,
              field: "",
              display: "W1.1(1) of a replacement Senior Representative",
              overrideResources: null
            },
            {
              id: 53,
              isActive: true,
              field: "",
              display: "X7.2 of the amount of reduction in delay damages following take over of part of the works",
              overrideResources: null
            },
            {
              id: 60,
              isActive: true,
              field: "",
              display: "X10.4(2) of a reply to a submission of a first Information Execution Plan for acceptance",
              overrideResources: null
            },
            {
              id: 61,
              isActive: true,
              field: "",
              display: "X10.4(2) of a reply to a submission of a revised Information Execution Plan for acceptance",
              overrideResources: null
            },
            {
              id: 51,
              isActive: true,
              field: "",
              display: "X18.4 of a matter for which the Subcontractor is liable",
              overrideResources: null
            },
            {
              id: 52,
              isActive: true,
              field: "",
              display: "X20.5 adding a KPI and payment to the Incentive Schedule",
              overrideResources: null
            },
            {
              id: 57,
              isActive: true,
              field: "",
              display: "Y1.6 of amounts to be paid from the Project Bank Account to the Subcontractor and Named Suppliers",
              overrideResources: null
            },
            {
              id: 55,
              isActive: true,
              field: "",
              display: "Y2.3 of an intention to pay less than the notified sum",
              overrideResources: null
            },
            {
              id: 56,
              isActive: true,
              field: "",
              display: "Y3.3 of the name of a beneficiary",
              overrideResources: null
            },
            {
              id: 28,
              isActive: true,
              field: "",
              display: "Other notification",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "SupplierClauses",
          values: [
            {
              id: 16,
              isActive: true,
              field: "",
              display: "13.2 of an address to receive communications",
              overrideResources: null
            },
            {
              id: 17,
              isActive: true,
              field: "",
              display: "16.1 of an ambiguity or inconsistency",
              overrideResources: null
            },
            {
              id: 18,
              isActive: true,
              field: "",
              display: "16.1 of an illegal or impossible requirement",
              overrideResources: null
            },
            {
              id: 23,
              isActive: true,
              field: "",
              display: "25.1 of an intention to transfer the benefit of the contract or any rights under it",
              overrideResources: null
            },
            {
              id: 24,
              isActive: true,
              field: "",
              display: "31.3 of a failure by the Contractor to respond to a programme submission",
              overrideResources: null
            },
            {
              id: 25,
              isActive: true,
              field: "",
              display: "41.1 of Defects which have not been corrected",
              overrideResources: null
            },
            {
              id: 26,
              isActive: true,
              field: "",
              display: "50.9 that Defined Cost for part of the work has been finalised (main Options C and E)",
              overrideResources: null
            },
            {
              id: 27,
              isActive: true,
              field: "",
              display: "61.4 of a failure by the Contractor to respond to a compensation event notification",
              overrideResources: null
            },
            {
              id: 28,
              isActive: true,
              field: "",
              display: "62.6 of a failure by the Contractor to respond to a quotation",
              overrideResources: null
            },
            {
              id: 29,
              isActive: true,
              field: "",
              display: "64.4 of a failure by the Contractor to make an assessment of a compensation event",
              overrideResources: null
            },
            {
              id: 30,
              isActive: true,
              field: "",
              display: "86.1 to submit insurance certificates",
              overrideResources: null
            },
            {
              id: 31,
              isActive: true,
              field: "",
              display: "90.1 of a wish to terminate",
              overrideResources: null
            },
            {
              id: 32,
              isActive: true,
              field: "",
              display: "W1.1(1) of a replacement Senior Representative",
              overrideResources: null
            },
            {
              id: 33,
              isActive: true,
              field: "",
              display: "X10.4(2) of a failure of the Contractor to reply to the submission of a first Information Execution Plan",
              overrideResources: null
            },
            {
              id: 34,
              isActive: true,
              field: "",
              display: "X10.4(2) of a failure of the Contractor to reply to the submission of a revised Information Execution Plan",
              overrideResources: null
            },
            {
              id: 45,
              isActive: true,
              field: "",
              display: "Y2.3 of an intention to pay less than the notified sum",
              overrideResources: null
            },
            {
              id: 46,
              isActive: true,
              field: "",
              display: "Y2.5 of an intention to suspend performance",
              overrideResources: null
            },
            {
              id: 15,
              isActive: true,
              field: "",
              display: "Other notification",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 106,
      title: "Submissions",
      workflows: [
        {
          id: 49,
          title: "$PM_NAME Submissions",
          notificationName: "Submission",
          abbreviation: "$PM_ABBREVIATION-Submission-",
          notify: true,
          replyEnabled: true,
          assetFieldsEnabled: false,
          replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "PM",
          replyingParty: "SUPPLIER",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        },
        {
          id: 18,
          title: "$SUPPLIER_NAME Submissions",
          notificationName: "Submission",
          abbreviation: "$SUPPLIER_ABBREVIATION-Submission-",
          notify: true,
          replyEnabled: true,
          assetFieldsEnabled: false,
          replyPeriodOption: "PM_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "SUPPLIER",
          replyingParty: "PM",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "IssueClauses",
          values: [
            {
              id: 20,
              isActive: true,
              field: "",
              display: "20.4 of forecasts of the total expenses for the whole of the services",
              applyReplyPeriod: false,
              noReply: true,
              overrideResources: null
            },
            {
              id: 21,
              isActive: true,
              field: "",
              display: "20.5 of forecasts of the total Defined Cost and expenses for the whole of the Subcontract Service (main Options C and E)",
              applyReplyPeriod: false,
              noReply: true,
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "",
              display: "21.1 of details of a replacement person",
              overrideResources: null
            },
            {
              id: 22,
              isActive: true,
              field: "",
              display: "22.2 of meetings with Others",
              applyReplyPeriod: false,
              noReply: true,
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "",
              display: "23.2 of the name of a proposed Subcontractor",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "",
              display: "23.3 of proposed subcontract documents",
              overrideResources: null
            },
            {
              id: 23,
              isActive: true,
              field: "",
              display: "23.4 of pricing information for each subcontract (main Options C and E)",
              applyReplyPeriod: false,
              noReply: true,
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "",
              display: "24.1 of design for approval",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "",
              display: "26.2 for permission to publicise the works",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "",
              display: "35.1 of an acceleration to achieve Completion before the Completion Date",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "",
              display: "40.2 of a quality policy statement and/or a quality plan",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "",
              display: "42.1 to accept a Defect",
              overrideResources: null
            },
            {
              id: 11,
              isActive: true,
              field: "",
              display: "50.9 of records demonstrating Defined Cost has been correctly assessed (main Options C and E)",
              applyReplyPeriod: true,
              replyPeriod: 98,
              noReply: false,
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "",
              display: "50.9 of further records in connection with Defined Cost (main Options C and E)",
              applyReplyPeriod: true,
              replyPeriod: 35,
              noReply: false,
              overrideResources: null
            },
            {
              id: 12,
              isActive: true,
              field: "",
              display: "55.3 a revised Activity Schedule (main Option A)",
              overrideResources: null
            },
            {
              id: 13,
              isActive: true,
              field: "",
              display: "84.1 of certificates of insurance",
              overrideResources: null
            },
            {
              id: 24,
              isActive: true,
              field: "",
              display: "X4.1 an ultimate holding company guarantee",
              applyReplyPeriod: false,
              noReply: true,
              overrideResources: null
            },
            {
              id: 17,
              isActive: true,
              field: "",
              display: "X4.2 of an alternative guarantor",
              overrideResources: null
            },
            {
              id: 25,
              isActive: true,
              field: "",
              display: "X8.3 of signed copies of the undertakings",
              applyReplyPeriod: false,
              noReply: true,
              overrideResources: null
            },
            {
              id: 26,
              isActive: true,
              field: "",
              display: "X9.1 of documents transferring rights",
              applyReplyPeriod: false,
              noReply: true,
              overrideResources: null
            },
            {
              id: 14,
              isActive: true,
              field: "",
              display: "X10.4(1) of a first Information Execution Plan",
              applyReplyPeriod: false,
              replyPeriod: 14,
              noReply: true,
              overrideResources: null
            },
            {
              id: 15,
              isActive: true,
              field: "",
              display: "X10.4(3) of a revised Information Execution Plan",
              applyReplyPeriod: false,
              replyPeriod: 14,
              noReply: true,
              overrideResources: null
            },
            {
              id: 27,
              isActive: true,
              field: "",
              display: "X10.6 of information transfering rights",
              applyReplyPeriod: false,
              noReply: true,
              overrideResources: null
            },
            {
              id: 16,
              isActive: true,
              field: "",
              display: "X13.1 of a bank or insurer to provide a performance bond",
              overrideResources: null
            },
            {
              id: 28,
              isActive: true,
              field: "",
              display: "X13.1 a performance bond",
              applyReplyPeriod: false,
              noReply: true,
              overrideResources: null
            },
            {
              id: 29,
              isActive: true,
              field: "",
              display: "X20.2 of performance",
              applyReplyPeriod: false,
              noReply: true,
              overrideResources: null
            },
            {
              id: 30,
              isActive: true,
              field: "",
              display: "X20.3 of proposals for improving performance",
              applyReplyPeriod: false,
              noReply: true,
              overrideResources: null
            },
            {
              id: 19,
              isActive: true,
              field: "",
              display: "Y1.3 of a proposal to add a Supplier to the Named Suppliers",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 2,
              isActive: true,
              field: "PM",
              display: "$PM_NAME Submission awaiting Acceptance",
              overrideResources: null
            },
            {
              id: 1,
              isActive: true,
              field: "Supplier",
              display: "$SUPPLIER_NAME Submission awaiting Acceptance",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "IssueClausesPM",
          values: [
            {
              id: 2,
              isActive: true,
              field: "",
              display: "35.1 of a proposal for an acceleration to achieve Completion before the Completion Date",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "",
              display: "86.1 of the insurance certificates for insurances taken out by the Contractor",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "",
              display: "X8.3 of the undertakings",
              applyReplyPeriod: false,
              noReply: true,
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 107,
      title: "Submissions (with quote)",
      workflows: [
        {
          id: 19,
          title: "$SUPPLIER_NAME Submissions with quote",
          notificationName: "Submission",
          abbreviation: "$SUPPLIER_ABBREVIATION-SubmissionWQ-",
          notify: true,
          replyEnabled: true,
          financialLimitEnabled: false,
          assetFieldsEnabled: false,
          replyPeriodOption: "PM_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "SUPPLIER",
          replyingParty: "PM",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "CostEffectLabel",
              display: "Change in the Prices",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "DaysEffectLabel",
              display: "Delay to planned Completion",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ProposalClauses",
          values: [
            {
              id: 2,
              isActive: true,
              field: "",
              display: "35.1 a quotation for acceleration",
              applyReplyPeriod: true,
              replyPeriod: 21,
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "",
              display: "42.1 of a quotation to accept a Defect",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Supplier",
              display: "$SUPPLIER_NAME Proposals awaiting Acceptance",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 108,
      title: "Payments",
      workflows: [
        {
          id: 21,
          title: "$SUPPLIER_NAME Payment Applications",
          notificationName: "Payment Application",
          abbreviation: "$SUPPLIER_ABBREVIATION-Payment-",
          notify: true,
          assetFieldsEnabled: false,
          notifyingParty: "SUPPLIER",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        },
        {
          id: 20,
          title: "$PM_NAME Payment Certificate",
          notificationName: "Payment Certificate",
          abbreviation: "$PM_ABBREVIATION-PC-",
          notify: true,
          financialLimitEnabled: false,
          assetFieldsEnabled: false,
          notifyingParty: "PM",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "CumulativePriceLabel",
              display: "Price for Services Provided to Date plus expenses",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "PlusAmountsSupplierLabel",
              display: "Plus other amounts to be paid to the $SUPPLIER_NAME",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "LessAmountsSupplierLabel",
              display: "Less amounts to be paid by or retained from the $SUPPLIER_NAME",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "TaxSupplierLabel",
              display: "Tax which the law requires the Employer to pay to the $SUPPLIER_NAME",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "WarningBanner",
              display: "The first assessment date is no later than the assessment interval after the starting date. Subsequent assessment dates are the assessment interval after the previous assessment. Assessments continue until 4 weeks after the defects date",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "SummaryDetailsLabel",
              display: "Summary details",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "RegisterAppliedByCaption",
              display: "Submitted by",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "LessAmountsDueLabel",
              display: "Less amount due in the last payment certificate",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "PaymentAppliedPeriodLabel",
              display: "Payment applied for this period",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "PaymentCertifiedPeriodLabel",
              display: "Payment certified for this period",
              overrideResources: null
            },
            {
              id: 11,
              isActive: true,
              field: "RegisterApplicationDateCaption",
              display: "Application Date",
              overrideResources: null
            },
            {
              id: 12,
              isActive: true,
              field: "RegisterCertifiedDateCaption",
              display: "Certified Date",
              overrideResources: null
            },
            {
              id: 13,
              isActive: true,
              field: "RegisterCertifiedByCaption",
              display: "Notified By",
              overrideResources: null
            },
            {
              id: 14,
              isActive: true,
              field: "AssessmentDayLabel",
              display: "Assessment Date",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "TaxEnabled",
          values: [
            {
              id: 1,
              isActive: false,
              field: "TaxEnabled",
              display: "Active-Inactive state indicates tax field will be included in notification forms",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "MandateAttachment",
          values: [
            {
              id: 1,
              isActive: false,
              field: "MandateAttachment",
              display: "Active-Inactive state indicates weather attachment is mandatory on Payment Application and Certifications",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: []
        },
        {
          replacementKey: "ApplicationTypes",
          values: [
            {
              id: 2,
              isActive: true,
              field: "INTERIM",
              display: "Interim",
              overrideResources: null
            },
            {
              id: 1,
              isActive: true,
              field: "FINAL",
              display: "Final",
              overrideResources: null
            },
            {
              id: 3,
              isActive: false,
              field: "SUPPLEMENTARY",
              display: "Supplementary",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "PaymentTypes",
          values: [
            {
              id: 2,
              isActive: true,
              field: "INTERIM",
              display: "Interim",
              overrideResources: null
            },
            {
              id: 1,
              isActive: true,
              field: "FINAL",
              display: "Final",
              overrideResources: null
            },
            {
              id: 3,
              isActive: false,
              field: "SUPPLEMENTARY",
              display: "Supplementary",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "PONumberEnabled",
          values: [
            {
              id: 1,
              isActive: false,
              field: "PONumberEnabled",
              display: "Purchase Order number field will be included in notification forms",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 109,
      title: "Quality Management",
      workflows: [
        {
          id: 23,
          title: "$PM_NAME Defect Notifications",
          notificationName: "Defect Notification",
          abbreviation: "$PM_ABBREVIATION-Defect-",
          notify: true,
          assetFieldsEnabled: false,
          notifyingParty: "PM",
          actioningParty: "PM",
          resolveActioningParty: "PM",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            actionTab: {
              actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
            resolveActionTab: {
              resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            }
          }
        },
        {
          id: 24,
          title: "$SUPPLIER_NAME Defect Notifications",
          notificationName: "Defect Notification",
          abbreviation: "$SUPPLIER_ABBREVIATION-Defect-",
          notify: true,
          assetFieldsEnabled: false,
          notifyingParty: "SUPPLIER",
          actioningParty: "PM",
          resolveActioningParty: "PM",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            actionTab: {
              actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
            resolveActionTab: {
              resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            }
          }
        },
        {
          id: 26,
          title: "Master Defect Register",
          notificationName: "Defect Notification",
          abbreviation: "Defect",
          overrideResources: null,
          userFilters: {
            accessFilter: "null"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "InstructionClauseLabel",
              display: "You are instructed under clause 41.3 with Details",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "SupervisorClauseLabel",
              display: "You are notified of the following Defect",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "SupplierClauseLabel",
              display: "You are notified of the following Defect",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "TestsClauseLabel",
              display: "You are instructed under clause 41.3 with Details",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Instruction",
              display: "$PM_NAME Instruction to Search awaiting sign off",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "SuperDefect",
              display: "$PM_NAME Notification of Defect awaiting acceptance",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "Inspection",
              display: "$SUPPLIER_NAME Test/Inspection Notification awaiting sign off",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 110,
      title: "Delegated Actions",
      workflows: [
        {
          id: 27,
          title: "$PM_NAME Delegated Actions",
          notificationName: "Delegated Action",
          abbreviation: "$PM_ABBREVIATION-Action-",
          notify: true,
          assetFieldsEnabled: false,
          notifyingParty: "PM",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "DelegationClause",
              display: "You are notified that the following actions",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 117,
      title: "Certificates",
      workflows: [
        {
          id: 68,
          title: "$PM_NAME Certificates",
          notificationName: "Certificate",
          abbreviation: "$PM_ABBREVIATION-Certificate-",
          notify: true,
          replyEnabled: false,
          assetFieldsEnabled: false,
          replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "PM",
          replyingParty: "SUPPLIER",
          form_IncludeCompanyName: true,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "PmCertificateText",
              display: "",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "SupplierCertificateText",
              display: "",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "ClientCertificateText",
              display: "",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "SupervisorCertificateText",
              display: "",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "PM",
              display: "$PM_NAME Certificates awaiting reply",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "Supplier",
              display: "$SUPPLIER_NAME Certificates awaiting reply",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "Client",
              display: "$CLIENT_NAME Certificates awaiting reply",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "Supervisor",
              display: "$SUPERVISOR_NAME Certificates awaiting reply",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "CompletionFields",
          values: [
            {
              id: 1,
              isActive: true,
              field: "CertifiedDate",
              display: "Completion Date",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "Description",
              display: "Details",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "Works",
              display: "Outstanding Works",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "Defects",
              display: "Defects",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "SectionalCompletionFields",
          values: [
            {
              id: 1,
              isActive: true,
              field: "CertifiedDate",
              display: "Sectional Completion Date",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "Description",
              display: "Details",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "Works",
              display: "Outstanding Works",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "Defects",
              display: "Defects",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "TakingOverFields",
          values: [
            {
              id: 1,
              isActive: true,
              field: "CertifiedDate",
              display: "Taking Over Date",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "Description",
              display: "Details",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "Works",
              display: "Outstanding Works",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "Defects",
              display: "Defects",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "DefectsFields",
          values: [
            {
              id: 1,
              isActive: true,
              field: "CertifiedDate",
              display: "Certified Date",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "Description",
              display: "Details",
              overrideResources: null
            },
            {
              id: 3,
              isActive: false,
              field: "Works",
              display: "Outstanding Works",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "Defects",
              display: "Outstanding Defects",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "TerminationFields",
          values: [
            {
              id: 1,
              isActive: true,
              field: "CertifiedDate",
              display: "Certified Date",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "Description",
              display: "Details",
              overrideResources: null
            },
            {
              id: 3,
              isActive: false,
              field: "Works",
              display: "Outstanding Works",
              overrideResources: null
            },
            {
              id: 4,
              isActive: false,
              field: "Defects",
              display: "Outstanding Defects",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "OtherFields",
          values: [
            {
              id: 1,
              isActive: true,
              field: "CertifiedDate",
              display: "Certified Date",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "Description",
              display: "Details",
              overrideResources: null
            },
            {
              id: 3,
              isActive: false,
              field: "Works",
              display: "Outstanding Works",
              overrideResources: null
            },
            {
              id: 4,
              isActive: false,
              field: "Defects",
              display: "Outstanding Defects",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ReplyOptions",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Accepted",
              display: "Accepted",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "Not Accepted",
              display: "Not Accepted",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "PMCertTypes",
          values: [
            {
              id: 1,
              isActive: true,
              field: "",
              display: "30.2 Completion Certificate",
              effect: "COMPLETION",
              overrideResources: null
            },
            {
              id: 2,
              isActive: false,
              field: "",
              display: "Sectional Completion Certificate",
              effect: "SECTIONAL_COMPLETION",
              overrideResources: null
            },
            {
              id: 3,
              isActive: false,
              field: "",
              display: "Taking Over Certificate",
              effect: "TAKING_OVER",
              overrideResources: null
            },
            {
              id: 4,
              isActive: false,
              field: "",
              display: "Defects Certificate",
              effect: "DEFECTS",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "",
              display: "90.1 Termination Certificate",
              effect: "TERMINATION",
              overrideResources: null
            },
            {
              id: 6,
              isActive: false,
              field: "",
              display: "Other Certificate",
              effect: "OTHER",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "SupplierCertTypes",
          values: [
            {
              id: 1,
              isActive: false,
              field: "",
              display: "Completion Certificate",
              effect: "COMPLETION",
              overrideResources: null
            },
            {
              id: 2,
              isActive: false,
              field: "",
              display: "Sectional Completion Certificate",
              effect: "SECTIONAL_COMPLETION",
              overrideResources: null
            },
            {
              id: 3,
              isActive: false,
              field: "",
              display: "Taking Over Certificate",
              effect: "TAKING_OVER",
              overrideResources: null
            },
            {
              id: 4,
              isActive: false,
              field: "",
              display: "Defects Certificate",
              effect: "DEFECTS",
              overrideResources: null
            },
            {
              id: 5,
              isActive: false,
              field: "",
              display: "Termination Certificate",
              effect: "TERMINATION",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "",
              display: "Other Certificate",
              effect: "OTHER",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ClientCertTypes",
          values: [
            {
              id: 1,
              isActive: false,
              field: "",
              display: "Completion Certificate",
              effect: "COMPLETION",
              overrideResources: null
            },
            {
              id: 2,
              isActive: false,
              field: "",
              display: "Sectional Completion Certificate",
              effect: "SECTIONAL_COMPLETION",
              overrideResources: null
            },
            {
              id: 3,
              isActive: false,
              field: "",
              display: "Taking Over Certificate",
              effect: "TAKING_OVER",
              overrideResources: null
            },
            {
              id: 4,
              isActive: false,
              field: "",
              display: "Defects Certificate",
              effect: "DEFECTS",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "",
              display: "Termination Certificate",
              effect: "TERMINATION",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "",
              display: "Other Certificate",
              effect: "OTHER",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "SupervisorCertTypes",
          values: [
            {
              id: 1,
              isActive: false,
              field: "",
              display: "Completion Certificate",
              effect: "COMPLETION",
              overrideResources: null
            },
            {
              id: 2,
              isActive: false,
              field: "",
              display: "Sectional Completion Certificate",
              effect: "SECTIONAL_COMPLETION",
              overrideResources: null
            },
            {
              id: 3,
              isActive: false,
              field: "",
              display: "Taking Over Certificate",
              effect: "TAKING_OVER",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "",
              display: "Defects Certificate",
              effect: "DEFECTS",
              overrideResources: null
            },
            {
              id: 5,
              isActive: false,
              field: "",
              display: "Termination Certificate",
              effect: "TERMINATION",
              overrideResources: null
            },
            {
              id: 6,
              isActive: false,
              field: "",
              display: "Other Certificate",
              effect: "OTHER",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "CompletionChangesStateEnabled",
          values: [
            {
              id: 1,
              isActive: true,
              field: "CompletionChangesStateEnabled",
              display: "Indicate whether communication of a Completion Certificate should change the contract state",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 111,
      title: "Records",
      workflows: [
        {
          id: 30,
          title: "$PM_NAME Meetings",
          notificationName: "Meeting",
          abbreviation: "$PM_ABBREVIATION-Meeting-",
          notify: true,
          assetFieldsEnabled: false,
          notifyingParty: "PM",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
          }
        },
        {
          id: 31,
          title: "$SUPPLIER_NAME Meetings",
          notificationName: "Meeting",
          abbreviation: "$SUPPLIER_ABBREVIATION-Meeting-",
          notify: true,
          assetFieldsEnabled: false,
          notifyingParty: "SUPPLIER",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
          }
        },
        {
          id: 32,
          title: "$SUPPLIER_NAME Site Diary",
          notificationName: "Site Diary",
          abbreviation: "Diary-",
          notify: true,
          assetFieldsEnabled: false,
          notifyingParty: "SUPPLIER",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
          }
        },
        {
          id: 33,
          title: "Weather",
          notificationName: "Weather",
          abbreviation: "Weather-",
          notify: true,
          assetFieldsEnabled: false,
          notifyingParty: "SUPPLIER",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
          }
        }
      ],
      placeholders: []
    },
    {
      id: 112,
      title: "RFI / TQ",
      workflows: [
        {
          id: 34,
          title: "$SUPPLIER_NAME RFI / TQ",
          notificationName: "RFI / TQ",
          abbreviation: "RFI/TQ-",
          notify: true,
          replyEnabled: true,
          assetFieldsEnabled: false,
          replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
          replyPeriod: 0,
          notifyingParty: "SUPPLIER",
          replyingParty: "PM",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Supplier",
              display: "$SUPPLIER_NAME RFI/TQ awaiting Reply",
              overrideResources: null
            }
          ]
        }
      ]
    }
  ]
};

export default template;