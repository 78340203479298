import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { injectIntl } from "react-intl";
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

export const withListPageProps = (Component) => {
  function ComponentWithListPageProp(props) {
    let navigate = useNavigate();
    let location = useLocation();
    // const contract = useSelector(store => store.contract.contract);
    const contractTemplate = useSelector(store => store.contract.template);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    return (

      <Component
        {...props}
        location={location}
        navigate={navigate}
        // contract={contract}
        contractTemplate={contractTemplate}
        closeSnackbar={closeSnackbar}
        enqueueSnackbar={enqueueSnackbar}
      />
    );
  }

  return injectIntl(ComponentWithListPageProp);
}
