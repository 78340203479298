import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import ReactToPrint from "react-to-print";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as fileCrud from '../../../../crud/file.crud';
import * as taskOrderCrud from '../../../../crud/taskOrder.crud';
import * as requestForQuoteCrud from '../../../../crud/requestForQuote.crud';
import axios from 'axios';
import ActionModal from '../../_CustomComponents/ActionModal';
import TaskDocument from './Document';
import { Navigate } from "react-router-dom";
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import moment from 'moment';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import Relations from '../../_CustomComponents/Relation/Relations';
import PageToolbar from '../../_CustomComponents/PageToolbar';
import Common from '../../../../shared/common';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';

const initialState = {
    notification: null,
    files: null,
    showModal: false,
    saving: false,
    isChanged: false,
    relationCount: 0,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    rfqs: null,
    relations: null
};

class TaskDetailPage extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';

        this.printNotificationDivRef = React.createRef();
        this.validatorRef = React.createRef();
        this.validatorName = 'TaskValidator';
        this.state = { ...initialState };
    }

    componentDidMount() {
        this.fetchTaskOrder();
        this.fetchFiles();
        if (this.props.rfqRelationEnabled) {
            this.fetchRequestForQuotes();
        }
    }


    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.fetchTaskOrder();
                this.fetchFiles();
            });
        }
    }
    getMailDescription = () => {
        return this.state.notification.subject;
    }

    fetchRequestForQuotes = () => {
        const { companyid, projectid, contractid } = this.props.contract;
        const toStates = ['APMA', 'ARQ', 'APMRQ', 'AQ', 'QA', 'APMR'];
        requestForQuoteCrud.getContractRequestForQuotes(companyid, projectid, contractid)
            .then(response => {
                let comps = response.data.aggregatelist;
                comps = comps.filter(ntf => {
                    //PRIOR LOGIC FOR ASSESSMENT
                    // if (ntf.status === 'APMA') { return true; }
                    // if (ntf.status === 'AQ' || ntf.status === 'ARQ') {
                    //     if (new Date(ntf.requiredate) < new Date()) { return true; }
                    // }
                    // return false;
                    //NEW LOGIC FOR ASSESSMENT
                    if (toStates.includes(ntf.status)) {
                        return true;
                    }
                    else return false;
                })
                    .map(itm => {
                        const reference = itm.referenceid;
                        let reason = null;
                        if (itm.status === 'APMA') {
                            reason = 'Not Accepted';
                        }
                        else if (itm.status === 'AQ' || itm.status === 'ARQ') {
                            if (new Date(itm.quoterequireddate) < new Date()) {
                                reason = 'Expired';
                            }
                        }

                        return {
                            value: itm.requestforquoteid,
                            reference: reference,
                            reason: reason,
                            title: itm.subject,
                            display: `${reference}   {${itm.subject}}` + (reason ? ` [${this.props.intl.formatMessage({ id: "OPTIONS.STATUS." + reason })}]` : '')
                        }
                    });
                this.setState({ rfqs: comps });
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    fetchTaskOrder = () => {
        taskOrderCrud.getTaskOrder(this.props.params.notificationid)
            .then(response => {
                const task = response.data;
                if (task.contractid !== this.props.contract.contractid) {
                    throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
                }
                if (task.startdate) {
                    task.startdate = new Date(task.startdate);
                }
                if (task.completiondate) {
                    task.completiondate = new Date(task.completiondate);
                }

                this.setState({
                    notification: task,
                    saving: false,
                    isChanged: false,
                    relations: task.relations,
                    relationCount: Common.GetRelationCount(task, this.props.accessibleWfIds)
                }, () => { this.props.setReference(this.state.notification.referenceid); });
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
    }

    fetchFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ files: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    printHandler = () => {
        this.printRef.handlePrint();
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }
    showModal = (mode, postActionNavigateObj) => {
        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
                    modalMode: mode,
                    postActionUrl: postActionNavigateObj
                });
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
            });

        }
    }
    onModalResponse = (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {


                    if (this.state.modalMode === 'COMMUNICATE') {

                        const task = { ...this.state.notification };

                        const docBodyConfig = this.documentBodyConfig(task, this.props.resources);
                        const docConfig = await this.documentConfig(task, docBodyConfig, this.props.contractUser.name, false, null, null, this.props.workflowItem.form_NotifyCompanyName);
                        task.pdfjsoncontent = JSON.stringify(docConfig);

                        this.saveNotification(task, this.state.modalMode);


                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {
                        const notification = { ...this.state.notification };
                        notification.createby = this.props.contractUser.name;
                        notification.createbyid = this.props.contractUser.accountid;
                        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');
                        this.saveNotification(notification, this.state.modalMode, params ? params.userid : null);

                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {
                        const taskorder = { ...this.state.notification };
                        const taskorderid = taskorder.taskorderid
                        this.deleteTaskOrderDraft(taskorderid);

                    }


                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });
    }
    documentBodyConfig = (notification, resources) => {
        const config = [{ resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.subject }];

        if (this.props.rfqRelationEnabled) {
            config.push({ label: this.props.rfqNotificationName, value: notification.requestforquotereferenceid, valueType: 'LINK', url: '' });
        }
        if (this.props.fields.Description) {
            config.push({ label: this.props.fields.Description, value: notification.description, valueType: 'HTML', align: 'vertical' })
        }
        if (this.props.fields.Comments) {
            config.push({ label: this.props.fields.Comments, value: notification.comments, valueType: 'HTML', align: 'vertical' })
        }
        if (this.props.fields.ItemsList) {
            config.push({ label: this.props.fields.ItemsList, value: notification.pricelistdescription })
        }
        if (this.props.fields.TotalListPrice) {
            config.push({ label: this.props.fields.TotalListPrice, value: Common.FormatCurrencyNum(notification.pricelisttotal, (this.props.contract || {}).currency), valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency })
        }
        if (this.props.fields.CompensationDescription) {
            config.push({ label: this.props.fields.CompensationDescription, value: notification.compensationeventdescripton })
        }
        if (this.props.fields.TotalAssessed) {
            config.push({ label: this.props.fields.TotalAssessed, value: Common.FormatCurrencyNum(notification.compensationeventtotal, (this.props.contract || {}).currency), valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency })
        }
        if (this.props.fields.TotalPrice) {
            config.push({ label: this.props.fields.TotalPrice, value: Common.FormatCurrencyNum(notification.taskordertotal, (this.props.contract || {}).currency), valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency })
        }
        if (this.props.fields.StartDate) {
            config.push({ label: this.props.fields.StartDate, value: notification.startdate })
        }
        if (this.props.fields.CompletionDate) {
            config.push({ label: this.props.fields.CompletionDate, value: notification.completiondate })
        }
        if (this.props.fields.DelayAmount) {
            config.push({ label: this.props.fields.DelayAmount, value: Common.FormatCurrencyNum(notification.delaydamagestotal, this.props.contract.currency) + ' / ' + notification.delaydamagestype, valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency })
        }


        return config;
    }

    deleteTaskOrderDraft = async (taskorderid) => {

        try {
            await taskOrderCrud.deleteTaskOrderDraft(taskorderid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }

    }

    saveNotification = async (task, action, notifyuserid) => {
        try {
            if (task.startdate) {
                task.startdatedisplay = moment(task.startdate).format('DD/MM/YYYY');
            }
            if (task.completiondate) {
                task.completiondatedisplay = moment(task.completiondate).format('DD/MM/YYYY');
            }

            task.actioncode = action;
            task.notifyuserlist = this.getNotifyUserListForUpdate(action, notifyuserid);

            Common.PropertyConversionsBeforeNoticeSaveRequest(task, this.props.customFields, this.props.contractUsers);
            await taskOrderCrud.updateTaskOrder(task);

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });
            if (this.state.postActionUrl) {
                this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
            }
            else {
                this.fetchTaskOrder();

            }

        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }
    }

    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else {
                this.state.notification[field] = value;

                if (field === 'requestforquoteid') {
                    if (value) {
                        const rfq = this.state.rfqs.find(x => x.value === value);
                        this.state.notification['requestforquotereferenceid'] = rfq.reference;
                    } else {
                        this.state.notification['requestforquotereferenceid'] = null;
                    }
                }

                if (!this.state.isChanged) {
                    this.setState({ isChanged: true });
                }
            }
        }
    }

    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving || this.state.showModal);
        return (
            <PageToolbar
                documentIsFinal={documentIsFinal}
                saveDisabled={saveDisabled}
                actions={{
                    showModal: this.showModal,
                    onPrint: this.printHandler
                }}
                workflowItem={this.props.workflowItem}
                match={this.props.match}
                customFieldValues={this.state.notification.customfieldvalues}
                referenceid={this.state.notification.referenceid}
                contract={this.props.contract}
                relationParams={{
                    subject: this.getMailDescription(),
                    assetid: this.state.notification.assetid
                }}
                notification={this.state.notification}
            />
        );
    }

    tabDocument = () => {
        return (
            <TaskDocument
                {...this.props}
                printDivRef={this.printNotificationDivRef}
                fieldChanged={this.fieldChanged}
                notification={this.state.notification}
                files={this.state.files}
                notificationid={this.props.params.notificationid}
                rfqs={this.state.rfqs}
            />
        );
    }
    tabRelations = () => {
        return (
            <Relations
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.notification.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.title}
                onCountChange={(count) => this.setState({ relationCount: count })}
            />
        );
    }
    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}

            />
        );

    };
    render() {
        if (!this.state.notification || !this.state.files || (this.props.rfqRelationEnabled && !this.state.rfqs)) {
            return (<PortletSpinner />);
        }
        const documentIsFinal = this.state.notification.showtasknotification === 1;
        if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
            return (<Navigate replace to={this.props.workflowItem.paths.listPath} />)
        }

        return (
            <Portlet>
                <ReactToPrint
                    ref={el => (this.printRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printNotificationDivRef.current}
                />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletHeader
                    title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                    toolbar={this.renderToolbar(documentIsFinal)}
                />
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            width="100%"
                            deferRendering={false}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}>
                            <Item icon="file" title={this.props.title} render={this.tabDocument} />
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>
                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                    workflowItem={this.props.workflowItem}
                    userLimit={this.state.notification.taskordertotal}
                />
            </Portlet>
        );
    }
}


export default withDetailPageProps(TaskDetailPage);
