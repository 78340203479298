import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PortletSpinner from '../../../partials/layout/PortletSpinner';
import ContractSidePanel from "../Contract/ContractSidePanel";
import { connect } from "react-redux";
import * as contractDuck from "../../../store/ducks/contract.duck";

import Dashboard from '../../contract/dashboard';
import HealthReport from '../../contract/reports/health';
import RagDashboard from '../../contract/reports/rag';
// import ScheduledReport from '../contract/reports/scheduled';

import ContractSettingsRouter from './ContractSettingsRouter';
import { withRouter } from '../../../shared/hoc/withRouter';
import Protector from "../../../router/Protector/Protector";
import TemplateHelper from "../../../Templates/TemplateHelper";
class ContractRouter extends React.Component {

    componentDidMount() {
        this.props.loadContract(this.props.params.contractid);
    }
    componentWillUnmount() {
        this.props.unloadContract();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.params.contractid !== this.props.params.contractid) {
                this.props.loadContract(this.props.params.contractid);
            }
        }
    }

    render() {
        if (!this.props.contract ||
            !this.props.contractTemplate ||
            !this.props.contractTemplate.workflowGroups ||
            !this.props.contractUser ||
            !this.props.contractUsers ||
            this.props.contract.contractid !== Number(this.props.params.contractid)
        ) {
            return (<PortletSpinner />);
        }

        return (
            <>
                <Routes caseSensitive>
                    <Route path="/" element={<Navigate to="dashboard" />} sensitive />
                    {/* issue-for-acceptance path was changed to submissions*/}
                    {/* redirect to support any links copied before the change*/}
                    <Route path="/issue-for-acceptance/*" element={<Navigate replace to={this.props.location.pathname.replace('issue-for-acceptance', 'submissions')} />} />
                    <Route path={'/dashboard'} element={<Protector><Dashboard /></Protector>} sensitive />
                    {/* <Route path="/preferences" element={<UserPage />} sensitive /> */}
                    <Route path="/settings/*" element={<ContractSettingsRouter />} sensitive />
                    <Route path={'/reports/health'} element={<Protector><HealthReport /></Protector>} sensitive />
                    <Route path={'/reports/rag'} element={<Protector><RagDashboard /></Protector>} sensitive />
                    {/* <Route path={'/contract/:contractid/reports/scheduled'} element={<ScheduledReport} sensitive/> */}
                    {
                        this.props.contractTemplate.workflowGroups.map(workflowGroupItem =>
                        (
                            workflowGroupItem.workflows.map(workflowItem => {
                                const DynoComp = require('../../contract/' + workflowGroupItem.path + '/' + workflowItem.config.paths.base).default;
                                // let resources = null;
                                // if (workflowGroupItem.placeholders) {
                                //     const resourcesPlaceHolder = workflowGroupItem.placeholders.find(ph => ph.replacementKey === 'Resources');
                                //     if (resourcesPlaceHolder) {
                                //         resources = resourcesPlaceHolder.values.reduce((obj, rsc) => ({ ...obj, [rsc.field]: rsc.display }), {})
                                //     }
                                // }
                                const globalResourcesOverriden = TemplateHelper.getGlobalResourcesOverriden(this.props.contractTemplate.resources, workflowItem)
                                return (
                                    <Route
                                        sensitive
                                        path={workflowItem.paths.routerMatch}
                                        element={<DynoComp
                                            resources={workflowGroupItem.resources}
                                            placeholders={workflowGroupItem.placeholders}
                                            placeholdersQA={workflowGroupItem.placeholdersQA}
                                            workflowsQA={this.props.contractTemplate.workflowsQA}
                                            workflowItem={workflowItem}
                                            workflowGroupItem={workflowGroupItem}
                                            customFields={(this.props.customFields || {})[workflowItem.id]}
                                            customFieldsCommon={(this.props.customFields || {})[0]}
                                            globalResources={globalResourcesOverriden}
                                            accessibleWfIds={this.props.contractTemplate.accessibleWfIds}
                                            contract={this.props.contract}
                                            contractUser={this.props.contractUser}
                                        />}
                                    // element={(props) => {

                                    //     return (<DynoComp {...props}
                                    //         resources={resources}
                                    //         placeholders={workflowGroupItem.placeholders}
                                    //         workflowItem={workflowItem}
                                    //         workflowGroupItem={workflowGroupItem}
                                    //         customFields={(this.props.customFields || {})[workflowItem.id]}
                                    //         customFieldsCommon={(this.props.customFields || {})[0]}
                                    //     />)
                                    // }}
                                    />
                                )
                            }
                            )
                        )
                        )
                    }
                    <Route path="*" element={<Navigate replace to="/contracts" />} />
                </Routes>
                <ContractSidePanel contractid={this.props.params.contractid} />
            </>
        );
    }

}



const mapStateToProps = store => ({
    contractTemplate: store.contract.template,
    contract: store.contract.contract,
    customFields: store.contract.customFields,
    contractUser: store.contract.user,
    contractUsers: store.contract.users,
});

const mapDispatchToProps = {
    loadContract: contractDuck.actions.fetchStart,
    unloadContract: contractDuck.actions.logout
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractRouter));