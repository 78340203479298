export const placeholderTypes = {
    CLAUSES: "CLAUSES",
    UNIFIED_CLAUSES: "UNIFIED_CLAUSES",
    ACTION_REMINDERS: "ACTION_REMINDERS",
    RESOURCES: "RESOURCES",
    TOGGLE_SWITCH: "TOGGLE_SWITCH",
    GENERIC: "GENERIC",
}

// export class PH_Clauses {
//     constructor(replacementKey, values, allowCustomReplyPeriods = false, allowConfigureReplyParty = false, effects = null) {
//         this.type = placeholderTypes.CLAUSES;
//         this.replacementKey = replacementKey;
//         this.values = values ? [...values] : null; //DEEP COPY TO ENSURE NEW REFERENCE
//         this.effects = effects ? [...effects] : null; //DEEP COPY TO ENSURE NEW REFERENCE
//         this.allowCustomReplyPeriods = allowCustomReplyPeriods;
//         this.allowConfigureReplyParty = allowConfigureReplyParty;
//     }
//     // All templates should be saved this stripped props
//     ToTemplateJSON = () => {
//         const { type, replacementKey, values } = this;
//         return { type, replacementKey, values: [...values] };
//     }
// }