import React from 'react';
import * as fileCrud from '../../../../crud/file.crud';
import Document from '../../_CustomComponents/DocumentV2';
import QuoteCustomForm from './CustomForm';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import moment from 'moment';
import { injectIntl } from "react-intl";


export const effectTypes = [
    { value: 'noeffect', display: 'There is no effect on the Accepted Programme' },
    { value: 'mitigated', display: 'There is an effect on the Accepted Programme but this has been mitigated by measures as detailed' },
    { value: 'notmitigated', display: 'There is an effect on the Accepted Programme which we are unable to mitigate' },
];
export const effectTypesNoProgramReply = [
    { value: 'noeffect', display: 'There is no effect on the Programme' },
    { value: 'mitigated', display: 'There is an effect on the Programme but this has been mitigated by measures as detailed' },
    { value: 'notmitigated', display: 'There is an effect on the Programme which we are unable to mitigate' },
]

class QuoteDocument extends BaseDocument {
    constructor(props) {
        super(props);
        if (props.programmeReplyEnabled) {
            this.effectTypes = effectTypes;
        }
        else {
            this.effectTypes = effectTypesNoProgramReply;
        }
    }



    renderForm = () => {
        return (
            <QuoteCustomForm
                {...this.props}
                compensationReference={(this.props.compensation || {}).referenceid}
                item={this.props.notification}
                valueChangeHandler={this.props.fieldChanged}
                customFields={(this.props.customFieldsCommon || {}).QUOTE}
            />
        );
    }
    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.quotationid, data, config)
    }


    render() {
        const documentIsFinal = this.props.notification.showtasknotification === 1;
        return (
            <Document
                key={this.props.notification.referenceid+documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.renderForm()}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFieldsCommon || {}).QUOTE}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.compensation.assetid}
            />
        );
    }
}

export default injectIntl(QuoteDocument);
