import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import InstructionList from './instruction/List';
import InstructionCreate from './instruction/Create';
import InstructionDetail from './instruction/Detail';
import { injectIntl } from "react-intl";

//FD-8788 Only certain types are allowed to have this field
//NEC4 books (ECC, ECS, FMC, PSC, SC, TSC) introduced a new clause 65
const awareDateFieldAllowedContractTypes = ["NEC4 ECC", "NEC4 ECS", "NEC4 FMC", "NEC4 PSC", "NEC4 SC", "NEC4 TSC"]


export const checkIfAwareDateFieldAllowed = (contractType) => {
    for (const allowedType of awareDateFieldAllowedContractTypes) {
        if (contractType.includes(allowedType)) {
            return true;
        }
    }
    return false
}

class InstructionRouter extends React.Component {
    constructor(props) {
        super(props);
        this.communicateToUser = this.props.globalResources.SUPPLIER_NAME;
        this.notificationType = 'PRO';
        this.documentType = 'Document612pm';
        this.notifyingParty = props.workflowItem.notifyingParty;
        this.replyingParty = props.workflowItem.replyingParty;


        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;

        const clausePH = props.placeholders.find(ph => ph.replacementKey === "ProposedInstructionClauses");
        if (clausePH && clausePH.values && clausePH.values.length > 0) {
            this.clauses = clausePH.values.filter(item => item.isActive === true);
        }

        const pmWf = props.workflowGroupItem.workflows.find(wf => wf.id === 5);
        if (pmWf) {
            this.compAbbreviation_PM = pmWf.abbreviation;
        }



        this.ewRelationEnabled = true;
        try {
            this.ewRelationEnabled = (props.placeholders.find(ph => ph.replacementKey === 'EWRelationEnabled')
                .values.find(phItem => phItem.field === 'EWRelationEnabled').isActive);
        } catch (error) {
            console.log('EWRelationEnabled placeholder not available!');
            this.ewRelationEnabled = true;
        }

        if (this.ewRelationEnabled) {
            this.ewNotificationName = (this.props.workflowsQA['1'] || this.props.workflowsQA['2']).notificationName;
        }

        this.taskOrderOptionEnabled = false;
        try {
            this.taskOrderOptionEnabled = (props.placeholders.find(ph => ph.replacementKey === 'TaskOrderOptionEnabled')
                .values.find(phItem => phItem.field === 'TaskOrderOptionEnabled').isActive);
        } catch (error) {
            console.log('TaskOrderOptionEnabled placeholder not available!');
            this.taskOrderOptionEnabled = false;
        }

        if (this.taskOrderOptionEnabled) {
            this.taskOrderAbbreviation = 'Task-';
            if (this.props.workflowsQA['38']) {
                this.taskOrderAbbreviation = this.props.workflowsQA['38'].abbreviation;
                this.taskOrderNotificationName = this.props.workflowsQA['38'].notificationName;
            }
        }

        this.projectOrderOptionEnabled = false;
        try {
            this.projectOrderOptionEnabled = (props.placeholders.find(ph => ph.replacementKey === 'ProjectOrderOptionEnabled')
                .values.find(phItem => phItem.field === 'ProjectOrderOptionEnabled').isActive);
        } catch (error) {
            console.log('ProjectOrderOptionEnabled placeholder not available!');
            this.projectOrderOptionEnabled = false;
        }

        if (this.projectOrderOptionEnabled) {
            this.projectOrderAbbreviation = 'Project-';
            if (this.props.workflowsQA['72']) {
                this.projectOrderAbbreviation = this.props.workflowsQA['72'].abbreviation;
                this.projectOrderNotificationName = this.props.workflowsQA['72'].notificationName;
            }
        }

        this.ceNotificationName = 'Compensation';
        if (this.props.workflowsQA['5'] || this.props.workflowsQA['7'] || this.props.workflowsQA['76']) {
            this.ceNotificationName = (this.props.workflowsQA['5'] || this.props.workflowsQA['7'] || this.props.workflowsQA['76']).notificationName;
        }
        const defaultFields = {
            Assumption: null,
            ProposedInstructionGivenDate: null
        }


        this.fields = { ...defaultFields };
        this.fields.Assumption = props.resources.ProposedInstructionLabel;
        this.fields.ProposedInstructionGivenDate = this.props.intl.formatMessage({ id: 'CONTRACT.WF.P_INSTRUCTION.FIELD.GIVEN_DATE' })
        try {
            const cenFields = props.placeholders.find(ph => ph.replacementKey === 'Fields');
            if (cenFields) {
                cenFields.values.forEach(fieldItem => {
                    this.fields[fieldItem.field] = fieldItem.isActive ? fieldItem.display : '';
                });
            }
        } catch (error) {
            console.log('Error loading CEN Fields Placeholder!');
        }
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;
        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<InstructionList
                        {...this.props}
                        fields={this.fields}
                        ewRelationEnabled={this.ewRelationEnabled}
                        taskOrderOptionEnabled={this.taskOrderOptionEnabled}
                        taskOrderNotificationName={this.taskOrderNotificationName}
                        projectOrderOptionEnabled={this.projectOrderOptionEnabled}
                        projectOrderNotificationName={this.projectOrderNotificationName}
                        
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        notificationType={this.notificationType}
                    />}
                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                            <InstructionCreate
                                {...this.props}
                                fields={this.fields}
                                clauses={this.clauses}
                                ewRelationEnabled={this.ewRelationEnabled}
                                taskOrderOptionEnabled={this.taskOrderOptionEnabled}
                                taskOrderNotificationName={this.taskOrderNotificationName}
                                projectOrderOptionEnabled={this.projectOrderOptionEnabled}
                                projectOrderNotificationName={this.projectOrderNotificationName}
                                ewNotificationName={this.ewNotificationName}
                                taskOrderAbbreviation={this.taskOrderAbbreviation}
                                projectOrderAbbreviation={this.projectOrderAbbreviation}
                                title={this.notificationName}
                                
                                communicateToUser={this.communicateToUser}
                                notificationType={this.notificationType}
                            />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    element={<InstructionDetail
                        {...this.props}
                        fields={this.fields}
                        clauses={this.clauses}
                        ewRelationEnabled={this.ewRelationEnabled}
                        taskOrderOptionEnabled={this.taskOrderOptionEnabled}
                        taskOrderNotificationName={this.taskOrderNotificationName}
                        projectOrderOptionEnabled={this.projectOrderOptionEnabled}
                        projectOrderNotificationName={this.projectOrderNotificationName}
                        ewNotificationName={this.ewNotificationName}
                        ceNotificationName={this.ceNotificationName}
                        compAbbreviation_PM={this.compAbbreviation_PM}
                        taskOrderAbbreviation={this.taskOrderAbbreviation}
                        projectOrderAbbreviation={this.projectOrderAbbreviation}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                        documentType={this.documentType}
                        communicateToUser={this.communicateToUser}
                        notificationType={this.notificationType}
                        notifyUserFilter={workflowItem.notifyUserFilter}
                    />}
                />
            </Routes>
        );
    }
};

export default injectIntl(InstructionRouter);