import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import * as activityCrud from "../../../../crud/activity.crud";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

class ActivityScheduleList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activities: null
        };
        this.columns = [
            { resource: "CONTRACT.WF.ACTIVITY.FIELD.ACTIVITY_NO", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: 'GENERAL.FORMS.FIELD.SOP_CODE', dataField: "ponumberreferencecode" },
            { resource: "CONTRACT.FIELD.PO_NUMBER", dataField: "ponumber" },
            { resource: "CONTRACT.WF.ACTIVITY.FIELD.ACTIVITY_DESC", dataField: "name" },
            { resource: "CONTRACT.WF.ACTIVITY.FIELD.REVISED_PRICE", dataField: "price", dataType: 'currency', currencyCode: this.props.contract.currency },
        ];

    }

    componentDidMount() {
        this.loadActivities();
    }
    loadActivities = () => {
        activityCrud.getContractActivities(this.props.contract.companyid, this.props.contract.projectid, this.props.contract.contractid)
            .then(response => {
                const parsedList = JSON.parse(response.data.aggregatelist);

                this.setState({ activities: parsedList });
            })
            .catch(err => {
                this.props.enqueueSnackbar('Error loading ' + this.props.title, { variant: 'error', });
            });
    }




    render() {
        if (!this.state.activities) {
            return (<PortletSpinner />);
        }

        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="1"
                        title={`${this.props.title} Register`}
                        // createPath={this.props.workflowItem.userFilters.mainNotice.createAllowed ? this.props.workflowItem.paths.createPath : null}
                        // workflowItem={this.props.workflowItem}
                        name={`${this.props.title}`}
                        dataSource={this.state.activities}
                        keyExpr="activityscheduleid"
                        // selection={{ mode: 'single' }}
                        // onSelectionChanged={this.onTableSelectionChanged}
                        // hoverStateEnabled={true}
                        rowAlternationEnabled={true}
                        columns={this.columns}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}




export default withListPageProps(ActivityScheduleList);
