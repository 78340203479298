import React from 'react'
import ZenDesk from "react-zendesk";
import { ZendeskAPI } from "react-zendesk";
import { useSelector } from 'react-redux';

const zenDeskWidgetEnabled = process.env.REACT_APP_ZENDESK_ENABLED === "true";
const zenDeskKey = process.env.REACT_APP_ZENDESK_KEY;
const zenDeskProduct = process.env.REACT_APP_ZENDESK_PRODUCT;
const zenDeskProductID = process.env.REACT_APP_ZENDESK_PRODUCT_ID;
const zenDeskSettings = {
  contactForm: {
    fields: [{ id: zenDeskProductID, prefill: { "*": zenDeskProduct } }],
  },
};

const ZendeskWidget = (props) => {
  const userProfile = useSelector(store => store.auth.userProfile);
  const isAuthorized = useSelector(store => store.auth.authToken.token && store.auth.csrfToken.token && store.auth.userProfile);
  const { location: { pathname } } = props;
  return (
    <>
      {
        zenDeskWidgetEnabled && (
          <>
            {
              (isAuthorized && !pathname.startsWith("/admin") && !pathname.includes("settings")) ? (
                <ZenDesk
                  defer
                  zendeskKey={zenDeskKey}
                  {...zenDeskSettings}
                  onLoaded={() => {
                    ZendeskAPI("webWidget", "prefill", {
                      name: {
                        value: userProfile.name,
                        readOnly: true,
                      },
                      email: {
                        value: userProfile.email,
                        readOnly: true,
                      },
                    });
                  }}
                />
              ) :
                (
                  ZendeskAPI("webWidget", "hide")
                )
            }
          </>
        )
      }
    </>
  );
};

export default ZendeskWidget;