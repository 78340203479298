import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PowerBIComponent from "./PowerBI";
import Protector from "../../../router/Protector/Protector";
import PowerBIExports from "./PowerBIExports";

class PowerbiRouter extends React.Component {
    render() {
        return (
            <Routes caseSensitive>
                <Route path="/reports" element={<Protector><PowerBIComponent key="REPORTS" refPowerBIEmbedded={this.props.refPowerBIEmbedded} mode="REPORTS" pbix="Reports" pageName="ReportSection12ca4d982452587d6a66" /></Protector>} sensitive />
                <Route path="/registers" element={<Protector><PowerBIComponent key="REGISTERS" mode="REPORTS" pbix="Reports" pageName="ReportSection0eaf701006b4422a71ce" /></Protector>} sensitive />
                <Route path="/exports" element={<Protector><PowerBIExports pbix="Reports" /></Protector>} sensitive />
                <Route path="*" element={<Navigate replace to="/contracts" />} />
            </Routes>
        );
    }
}



export default PowerbiRouter;
