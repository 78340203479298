import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Contracts from "../Contracts";
import ContractNew from "../../admin/Contracts/ContractNew";
import Protector from "../../../router/Protector/Protector";


class ContractsRouter extends React.Component {

    render() {
        return (
            <Routes caseSensitive>
                <Route path="/" element={<Contracts />} sensitive />
                <Route path="/new-contract" exact element={<Protector><ContractNew /></Protector>} sensitive />
                <Route path="*" element={<Navigate replace to="/contracts" />} />
            </Routes>
        );
    }

}



export default ContractsRouter;