import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import CompensationCustomForm from './CustomForm';
import * as compensationEventCrud from '../../../../crud/compensationEvent.crud';
import axios from 'axios';
import moment from 'moment';
// import QueryString from 'query-string';
import { FormattedMessage } from "react-intl";
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import Common from '../../../../shared/common';
import { actionCodes } from "../../../../shared/lookup";

class CompensationCreatePage extends BaseCreatePage {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: true,
        };
    }

    submitHandler = async (compensation, setSubmitting, warnings) => {

        const postEvents = (createdid) => {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
            this.props.navigate(this.props.workflowItem.paths.basePath + createdid);
        };

        const { companyid, projectid, contractid } = this.props.contract;
        compensation.companyid = companyid;
        compensation.contractid = contractid;
        compensation.projectid = projectid;
        compensation.compensationsource = this.props.workflowItem.config.currentModelType; //this.props.notificationType;
        compensation.status = 'NC';
        compensation.showtasknotification = 0;
        if (compensation.eventawaredate) {
            compensation.eventawaredatedisplay = moment(compensation.eventawaredate).format('DD/MM/YYYY');
        }

        if (warnings && warnings.length > 0) {
            compensation.timeextension = warnings[warnings.length - 1].reference;
        }

        compensation.actioncode = actionCodes.CREATE;
        compensation.notifyingparty = this.props.workflowItem.notifyingParty;
        compensation.replyingparty = this.props.workflowItem.replyingParty;

        this.appendRelationship(compensation);
        Common.PropertyConversionsBeforeNoticeSaveRequest(compensation, this.props.customFields, this.props.contractUsers);
        compensationEventCrud.saveCompensationEvent(compensation)
            .then(response => {
                const createdID = Number(response.data);
                if (warnings && warnings.length > 0) {
                    const promises = []
                    warnings.forEach(warning => {
                        const relationObj = {
                            companyid: companyid,
                            projectid: projectid,
                            contractid: contractid,
                            compensationeventid: createdID,
                            earlywarningid: warning.warningid,
                        };
                        promises.push(compensationEventCrud.saveCompensationEarlyWarning(relationObj));
                    });
                    axios.all(promises)
                        .then(innerRsp => {
                            postEvents(createdID);
                        })
                        .catch(err2 => {
                            this.props.enqueueSnackbar(err2.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
                        });
                }
                else {
                    postEvents(createdID);
                }

            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });

    }
    render() {
        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    <CompensationCustomForm
                        {...this.props}
                        onSubmitHandler={this.submitHandler}
                        customFields={(this.props.customFields || {}).COMMUNICATION}
                        assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(CompensationCreatePage));
