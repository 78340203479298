import axios from '../shared/axios';

export function saveAsiteContractData(data) {
    return axios.post(`/api/Asite/SaveContractData`, data);
}

export function getAsiteContractData(contractid) {
    return axios.get(`/api/Asite/GetContractData?contractid=${contractid}`);
}

export function getAsiteAPIUsers() {
    return axios.get(`/api/Asite/GetAPIusers`);
}

export function getAsiteWorkspaces() {
    return axios.get(`/api/Asite/GetWorkspaces`);
}

export function getAsiteCachedContract(contractid, cancelTok) {
    return axios.get(`/api/Asite/GetCachedContract?contractid=${contractid}`, { cancelToken: cancelTok });
}

export function getAsiteCachedDocuments(contractid, folderid, page, cancelTok) {
    return axios.get(`/api/Asite/GetCachedDocuments?contractid=${contractid}&folderid=${folderid}&page=${page}`, { cancelToken: cancelTok });
}

export function getAsiteSearchParameters(contractid, cancelTok) {
    return axios.get(`/api/Asite/GetSearchParameters?contractid=${contractid}`, { cancelToken: cancelTok });
}