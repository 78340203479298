/* eslint-disable eqeqeq */
import React from "react";
import { connect } from "react-redux";
import * as companyDuck from '../../../../store/ducks/company.duck';
import Spinner from '../../../../partials/layout/Spinner';
import { withSnackbar } from 'notistack';
import { injectIntl } from "react-intl";
import { withRouter } from '../../../../shared/hoc/withRouter';
import CompanySubRouter from "./CompanySubRouter";


class CompanyHome extends React.Component {
    componentDidMount() {
        this.props.reduxLoadCompany(this.props.params.companyid);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.params.companyid !== this.props.params.companyid) {
                this.props.reduxLoadCompany(this.props.params.companyid);
            }
        }
    }
    componentWillUnmount() {
        this.props.reduxUnloadCompany();
    }

    render() {
        if (!this.props.company || (this.props.company && this.props.company.companyid != this.props.params.companyid)) {
            return (<Spinner />);
        }
        return (<CompanySubRouter protectedRouteProps={this.props.protectedRouteProps} />);
    }
}


const mapStateToProps = store => ({
    company: store.company.company
});


const mapDispatchToProps = {
    reduxLoadCompany: companyDuck.actions.fetchStart,
    reduxUnloadCompany: companyDuck.actions.unload,
}


export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CompanyHome))));
