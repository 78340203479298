import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProjectList from './Project/List';
import ProjectCreate from './Project/Create';
import ProjectDetail from './Project/Detail';

const defaultFields = {
    Description: null,
    ItemsList: null,
    TotalListPrice: null,
    CompensationDescription: null,
    TotalAssessed: null,
    TotalPrice: null,
    StartDate: null,
    CompletionDate: null,
    DelayAmount: null,
    DelayPaymentPeriod: null,
}

class ProjectPmRouter extends React.Component {
    constructor(props) {
        super(props);
        this.communicateToUser = props.globalResources.SUPPLIER_NAME;
        // this.notificationType = 'PM';
        this.documentType = 'DocumentPmProject';
        this.notifyingParty = props.workflowItem.notifyingParty;

        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;

        this.fields = { ...defaultFields };
        this.fields.Description = this.props.resources.DescriptionLabel;
        this.fields.ItemsList = this.props.resources.ItemsListLabel;
        this.fields.TotalListPrice = this.props.resources.TotalListPriceLabel;
        this.fields.CompensationDescription = this.props.resources.CompensationDescriptionLabel;
        this.fields.TotalAssessed = this.props.resources.TotalAssessedLabel;
        this.fields.TotalPrice = this.props.resources.TotalPriceLabel;
        this.fields.StartDate = this.props.resources.StartDateLabel;
        this.fields.CompletionDate = this.props.resources.CompletionDateLabel;
        this.fields.DelayAmount = this.props.resources.DelayAmountLabel;
        this.fields.DelayPaymentPeriod = this.props.resources.DelayPaymentPeriodLabel;

        this.CENProjectOrderOptionEnabled = false;
        if (this.props.workflowsQA['103']) {
            try {
                this.CENProjectOrderOptionEnabled = (this.props.workflowsQA['103'].placeholders.find(ph => ph.replacementKey === 'ProjectOrderOptionEnabled')
                    .values.find(phItem => phItem.field === 'ProjectOrderOptionEnabled').isActive);
            } catch (error) {
                console.log('ProjectOrderOptionEnabled placeholder not available!');
                this.CENProjectOrderOptionEnabled = false;
            }
        }

        this.fields = {};
        try {
            const ProjectOrderFields = props.placeholders.find(ph => ph.replacementKey === 'Fields');
            if (ProjectOrderFields) {
                ProjectOrderFields.values.forEach(fieldItem => {
                    this.fields[fieldItem.field] = fieldItem.isActive ? fieldItem.display : '';
                });
            }
        } catch (error) {
            console.log('Error loading ProjectOrder Fields Placeholder!');
        }

        this.itqRelationRequired = false;
        try {
            this.itqRelationRequired = props.placeholders.find(ph => ph.replacementKey === 'ITQRelationRequired')
                .values.find(phItem => phItem.field === 'ITQRelationRequired').isActive;
        } catch (error) {
            console.log('ITQRelationRequired placeholder not available!');
            this.itqRelationRequired = false;
        }

        this.itqRelationEnabled = false;
        try {
            this.itqRelationEnabled = (props.placeholders.find(ph => ph.replacementKey === 'ITQRelationEnabled')
                .values.find(phItem => phItem.field === 'ITQRelationEnabled').isActive);
        } catch (error) {
            console.log('ITQRelationEnabled placeholder not available!');
            this.itqRelationEnabled = false;
        }

        this.itqNotificationName = (this.props.workflowsQA['73']||{}).notificationName;
        this.poNotificationName = (this.props.workflowsQA['72']||{}).notificationName;

        this.projectOrderCertifiedDateFieldName = null;

        if (this.props.workflowsQA['117']) {
            try {
                this.projectOrderCertifiedDateField = this.props.workflowsQA['117'].placeholders.find(ph => ph.replacementKey === 'ProjectOrderCompletionFields')
                    .values.find(phItem => phItem.field === 'CertifiedDate');

                if (this.projectOrderCertifiedDateField?.isActive) {
                    this.projectOrderCertifiedDateFieldName = this.projectOrderCertifiedDateField.display;
                }
            } catch (error) {
                console.log('ProjectOrderCompletionFields placeholder not available!');
            }
        }

        this.projectOrderCertifiedStartDateFieldName = null;

        if (props.resources.CertifiedStartDateLabel) {
            this.projectOrderCertifiedStartDateFieldName = props.resources.CertifiedStartDateLabel;
        }
        else if (this.props.workflowsQA['117']) {
            try {
                this.projectOrderCertifiedDateField = this.props.workflowsQA['117'].placeholders.find(ph => ph.replacementKey === 'ProjectOrderStartFields')
                    .values.find(phItem => phItem.field === 'CertifiedDate');

                if (this.projectOrderCertifiedDateField?.isActive) {
                    this.projectOrderCertifiedStartDateFieldName = this.projectOrderCertifiedDateField.display;
                }
            } catch (error) {
                console.log('ProjectOrderStartFields placeholder not available!');
            }
        }

        this.projectOrderCertifiedCompletionDateFieldName = null;

        if (props.resources.CertifiedCompletionDateLabel) {
            this.projectOrderCertifiedCompletionDateFieldName = props.resources.CertifiedCompletionDateLabel;
        }
        else if (this.props.workflowsQA['117']) {
            try {
                this.projectOrderCertifiedDateField = this.props.workflowsQA['117'].placeholders.find(ph => ph.replacementKey === 'ProjectOrderCompletionFields')
                    .values.find(phItem => phItem.field === 'CertifiedDate');

                if (this.projectOrderCertifiedDateField?.isActive) {
                    this.projectOrderCertifiedCompletionDateFieldName = this.projectOrderCertifiedDateField.display;
                }
            } catch (error) {
                console.log('ProjectOrderCompletionFields placeholder not available!');
            }
        }
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;

        return (
            <>
                <Routes caseSensitive>
                    <Route
                        path={workflowItem.config.paths.list}
                        exact
                        element={<ProjectList
                            {...this.props}
                            fields={this.fields}
                            CENProjectOrderOptionEnabled={this.CENProjectOrderOptionEnabled}
                            
                            title={this.notificationName}
                            abbreviation={this.abbreviation}
                            itqNotificationName={this.itqNotificationName}
                            notificationType={this.notificationType}
                            projectOrderCertifiedStartDateFieldName={this.projectOrderCertifiedStartDateFieldName}
                            projectOrderCertifiedCompletionDateFieldName={this.projectOrderCertifiedCompletionDateFieldName}
                        />}
                    />
                    <Route
                        path={workflowItem.config.paths.create}
                        exact
                        element={
                            workflowItem.userFilters.mainNotice.createAllowed ? (
                                <ProjectCreate
                                    {...this.props}
                                    fields={this.fields}
                                    
                                    notificationType={this.notificationType}
                                    title={this.notificationName}
                                    communicateToUser={this.communicateToUser}
                                    itqRelationEnabled={this.itqRelationEnabled}
                                    itqNotificationName={this.itqNotificationName}
                                />) : (
                                <Navigate replace to={workflowItem.paths.defaultPath} />
                            )
                        }
                    />
                    <Route
                        path={'/:notificationid/*'}
                        exact
                        element={<ProjectDetail
                            {...this.props}
                            fields={this.fields}
                            
                            title={this.notificationName}
                            abbreviation={this.abbreviation}
                            documentType={this.documentType}
                            communicateToUser={this.communicateToUser}
                            notificationType={this.notificationType}
                            itqRelationRequired={this.itqRelationRequired}
                            poNotificationName={this.poNotificationName}
                            itqRelationEnabled={this.itqRelationEnabled}
                            itqNotificationName={this.itqNotificationName}
                        />}
                    />
                </Routes>
            </>
        );
    }
}

export default (ProjectPmRouter);