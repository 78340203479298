import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

//const getPreferences = (store) => store.preferences;

export const actionTypes = {
    // PREFERENCES_SET_CONTRACTS_VIEWMODE: "[PREFERENCES_SET_CONTRACTS_VIEWMODE]",
    // PREFERENCES_SET_CONTRACTS_DISPLAYIMAGES: "[PREFERENCES_SET_CONTRACTS_DISPLAYIMAGES]",
    // PREFERENCES_SET_CONTRACTS_FIELDS: "[PREFERENCES_SET_CONTRACTS_FIELDS]",
    // PREFERENCES_SET_CONTRACTS_FILTERS: "[PREFERENCES_SET_CONTRACTS_FILTERS]",
    // PREFERENCES_SET_NOTIFICATIONS: "[PREFERENCES_SET_NOTIFICATIONS]",
    // PREFERENCES_SET_C_NOTIFICATIONS: "[PREFERENCES_SET_C_NOTIFICATIONS]",
    PREFERENCES_SET_PENDING_ACTION_FILTER: "[PREFERENCES_SET_PENDING_ACTION_FILTER]",
};

const initialPreferencesState = {
    // contractsPage: {
    //     viewMode: 1,
    //     displayImages: false,
    //     fields: [
    //         { caption: "Project Name", dataField: "project", show: true },
    //         { caption: "Contract Name", dataField: "name", show: true },
    //         { caption: "Contract Type", dataField: "contracttype", show: true },
    //         { caption: "Supplier", dataField: "contractorcompany", show: true },
    //         { caption: "Original Contract Price", dataField: "contractvalue", show: false, dataType: "price" },
    //         { caption: "Current Contract Price", dataField: "currentcontractvalue", show: true, dataType: "price" },
    //         { resource: "GENERAL.FORMS.FIELD.START_DATE", dataField: "startdate", show: false, dataType: "date" },
    //         { caption: "Original Completion Date", dataField: "completedate", show: false, dataType: "date" },
    //         { caption: "Current Completion Date", dataField: "completiondate", show: true, dataType: "date" },
    //         { caption: "Total Certified", dataField: "totalcertified", show: false },
    //         { caption: "Lead Consultant/Contractor", dataField: "contractmanager", show: false },
    //         { caption: "Lead Client", dataField: "employer", show: false },
    //         { caption: "Lead PM/SM", dataField: "projectmanager", show: false },
    //     ],
    //     filters: [
    //         { caption: "Project Name", dataField: "project", show: false },
    //         { caption: "Contract Type", dataField: "contracttype", show: false },
    //         { caption: "Supplier", dataField: "contractorcompany", show: false },
    //         { caption: "Hubs", dataField: "hubid", dataLabelField: "hub", show: false },
    //         { caption: "Areas", dataField: "areaid", dataLabelField: "area", show: false },

    //     ],
    // },
    // notifications: null,
    // contractNotifications: [],
    // contractTags: [],
    pendingActionsShowAll:false
};

//PREFERENCES ARE SUPPOSED TO VARY BETWEEN PRODUCTS AND VERSIONS, UNIQUE NAME PROVIDED FOR EACH
const storageKey = "FD-Pref" + process.env.REACT_APP_PRODUCT_KEY;
export const reducer = persistReducer({ storage, key: storageKey }, (state = initialPreferencesState, action) => {
    switch (action.type) {
        case actionTypes.PREFERENCES_SET_PENDING_ACTION_FILTER: {
            return { ...state, pendingActionsShowAll: action.payload.pendingActionsShowAll };
        }
        default:
            return state;
    }
});
export const actions = {

    setPendingActionFilter: (pendingActionsShowAll) => ({ type: actionTypes.PREFERENCES_SET_PENDING_ACTION_FILTER, payload: { pendingActionsShowAll } }),

    // setContractsViewMode: (viewMode) => ({ type: actionTypes.PREFERENCES_SET_CONTRACTS_VIEWMODE, payload: { viewMode } }),
    // setContractsDisplayImages: (displayImages) => ({ type: actionTypes.PREFERENCES_SET_CONTRACTS_DISPLAYIMAGES, payload: { displayImages } }),
    // setContractsFields: (fields) => ({ type: actionTypes.PREFERENCES_SET_CONTRACTS_FIELDS, payload: { fields } }),
    // setContractsFilters: (filters) => ({ type: actionTypes.PREFERENCES_SET_CONTRACTS_FILTERS, payload: { filters } }),
    // setUserNotifications: (notifications) => ({ type: actionTypes.PREFERENCES_SET_NOTIFICATIONS, payload: { notifications } }),
    // setContractNotifications: (contractid, notifications) => ({ type: actionTypes.PREFERENCES_SET_C_NOTIFICATIONS, payload: { contractid, notifications } }),
};


export function* saga() {

}

