/* eslint-disable eqeqeq */
import React from 'react';
import { connect } from "react-redux";
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import * as companiesDuck from '../../../../store/ducks/companies.duck';
import * as companiesCrud from '../../../../crud/companies.crud';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { companyTypes, countries, companyTagCategories } from '../../../../shared/lookup';
import { withSnackbar } from 'notistack';
import { injectIntl } from "react-intl";
import DxDialogModal from '../../../../partials/content/dxControls/dxDialogModal';
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import axios from 'axios';
import { withRouter } from '../../../../shared/hoc/withRouter';
import * as tagCrud from '../../../../crud/tag.crud';
import { confirm } from 'devextreme/ui/dialog';
import * as companyDuck from '../../../../store/ducks/company.duck';
const initialState = {
  showModal: false,
  modalTitle: '',
  modalText: '',
  modalMode: ''
};

class CompanyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.companyTagsEnabled = process.env.REACT_APP_CONTRACT_COMPANY_TAGS_ENABLED === 'true';
    this.companyTypes = companyTypes.map(ty => ({ value: ty.value, display: this.props.intl.formatMessage({ id: "OPTIONS.COMPANY_TYPE." + ty.value }) }));

    this.updateCompanyFormConfig = {
      buttonText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_UPDATE" }),
      fields: [
        { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_NAME" }), dataField: 'name' },
        { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_TYPE" }), dataField: 'companytype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.companyTypes, valueExpr: 'value', displayExpr: 'display' } },
        { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_ADDRESS1" }), dataField: 'address1', },
        { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_ADDRESS2" }), dataField: 'address2', required: false },
        { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_CITY" }), dataField: 'city', },
        { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_COUNTY" }), dataField: 'state', },
        { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_COUNTRY" }), dataField: 'country', editorType: 'dxSelectBox', editorOptions: { dataSource: countries } },
        { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_POSTCODE" }), dataField: 'postcode' },
        { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_ENABLECOMPANYUSERS" }), dataField: 'enablecompanyusers', editorType: 'dxSwitch', editorOptions: { switchedOffText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }), readOnly: !props.protectedRouteProps.allowEnablingCompanyUsers } },
        { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_ADDCOMPANYUSERSTO" }), dataField: 'adduserstocompanyid', required: false, editorType: 'dxSelectBox', editorOptions: { dataSource: this.fetchCompanies(), valueExpr: 'companyid', displayExpr: 'name', readOnly: !props.protectedRouteProps.allowEnablingCompanyUsers }, visible: (obj) => !obj.enablecompanyusers }
      ]
    };

    if (this.companyTagsEnabled) {
      this.updateCompanyFormConfig.fields.push(
        { resource: 'CONTRACT.FIELD.FRAMEWORKS', dataField: 'frameworkids', required: false, editorType: 'dxTagBox', editorOptions: { dataSource: this.getTags(props.params.companyid), valueExpr: 'tagid', displayExpr: 'name' } }
      );
    };

    this.updateCompanyFormConfig.fields.push(
      { resource: 'COMPANY.FIELD.COMPANY_NUMBER', dataField: 'companynumber', required: false }
    );

    this.state = {
      ...initialState,
      company: null,
      companies: []
    }
  }
  componentDidMount() {
    this.fetchCompany();
    
  }
  fetchCompany = () => {
    companiesCrud.getCompany(this.props.params.companyid)
      .then(resp => {

        if (resp.data.tags && resp.data.tags.categories) {
          resp.data.tags.categories.forEach(tagCategory => {
            switch (tagCategory.name) {
              case 'Frameworks':
                resp.data.frameworkids = tagCategory.tags.map(tag => tag.tagid);
                break;
              default:
                break;
            }
          });
        }

        this.setState({ company: resp.data })
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
      });
  }
  fetchCompanies = () => {
    const datasource = {
      loadMode: 'raw',
      cacheRawData: true,
      load: () => {
        return new Promise((resolve) => {
          companiesCrud.getCompanies()
            .then(response => {
              const filteredCompanies = JSON.parse(response.data.aggregatelist).filter(c => c.archived !== 1 && c.enablecompanyusers != 0 && c.companyid != this.props.params.companyid);
              this.setState({ companies: filteredCompanies }, () => {
                resolve(filteredCompanies);
              });
            })
            .catch(error => {
              console.error("Error fetching companies:", error);
              resolve([]);
            });
        });
      }
    };

    return datasource;
  }

  updateCompany = async (company, setSubmitting) => {
    //CHECK IF SAME companynumber EXIST IF IT EXIST AND CHANGED
    if (company.companynumber && this.state.company.companynumber !== company.companynumber) {
      const companyNumberResponse = await companiesCrud.checkIfCompanyNumberExists(company.companynumber);

      if (companyNumberResponse.data === true) {
        //THERE IS A COMPANY WITH SAME NUMBER ALREADY
        const userApprovalToContinue = await this.confirmCompanyNumberMismatch();
        if (!userApprovalToContinue) {
          setSubmitting(false);
          return; //cancelled
        }
      }
    }

    //WHAT IS BEING CONFIRMED HERE?
    //What DOES The FUNCTION CHECK?
    const companyConfirmed = await this.confirmCompanyAssignment(company);
    if (!companyConfirmed) {
      //if not confirmed exit save
      setSubmitting(false);
      return;
    }
    if (company.enablecompanyusers) {
      company.adduserstocompanyid = null;
    }
    companiesCrud.saveCompany(company)
      .then(() => {
        this.props.enqueueSnackbar('Company updated successfully', { variant: 'success', });
        this.fetchCompany();
        this.props.companiesDuckSetPending();
        this.props.dispatchLoadCompany(this.props.params.companyid);
        setSubmitting(false);
      })
      .catch(err => {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        setSubmitting(false);
      });
  }
  confirmCompanyAssignment = async (company) => {
    if (this.state.company.adduserstocompanyid != company.adduserstocompanyid) {
      if (!company.enablecompanyusers && company.adduserstocompanyid) {
        const companyName = this.state.companies.find(c => c.companyid == company.adduserstocompanyid).name;
        const warningMessage = this.props.intl.formatMessage({ id: 'COMPANY.WARNING.ENABLE_COMPANY_USERS' }, { COMPANY_NAME: companyName });
        const warningMessageLabel = this.props.intl.formatMessage({ id: 'COMPANY.WARNING.ADDING_COMPANY_USERS_TO_ANOTHER_COMPANY' });
        let result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageLabel}`);
        return result;
      }
    }

    return true;
  }
  confirmCompanyNumberMismatch = async () => {
    const warningMessage = this.props.intl.formatMessage({ id: 'COMPANY.WARNING.COMPANY_NUMBER_EXISTS' });
    const warningMessageLabel = this.props.intl.formatMessage({ id: 'COMPANY.WARNING.COMPANY_NUMBER_CONFIRMATION' });
    let result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageLabel}`);

    return result;
  }
  showModal = (mode, e) => {
    const statusText = this.state.company.deactivated ?
      this.props.intl.formatMessage({ id: "COMPANY.ACTIVATE.MODAL.ACTIVATING" })
      : this.props.intl.formatMessage({ id: "COMPANY.ACTIVATE.MODAL.DEACTIVATING" });

    this.setState({
      showModal: true,
      modalTitle: this.props.intl.formatMessage({ id: "COMPANY.ACTIVATE.MODAL.TITLE" }),
      modalText: statusText,
      modalMode: mode
    });
  }
  onModalResponse = (response, params) => {
    this.setState({ showModal: false/*, isSubmitting: true*/ }, () => {
      if (response === 'OK') {
        //this.setState({ isSubmitting: true });
        this.companyActivateDeactivate(params.activationStatus);
      }
    });
  }
  companyActivateDeactivate = (setSubmitting) => {
    const formData = {
      companyid: this.state.company.companyid,
      actionType: this.state.company.deactivated ? 'activate' : 'deactivate'
    };

    companiesCrud.companyActivateDeactivate(formData)
      .then(() => {
        this.props.enqueueSnackbar(formData.actionType === 'activate' ?
          this.props.intl.formatMessage({ id: "COMPANY.ACTIVATE.SUCCESS" })
          : this.props.intl.formatMessage({ id: "COMPANY.DEACTIVATE.SUCCESS" }), { variant: 'success', });
        this.props.companiesDuckSetPending();
        this.fetchCompany();
      })
      .catch(err => {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      });
  }
  getTags = (companyid) => {
    const datasource = {
      loadMode: 'raw',
      cacheRawData: true,
      load: () => {
        var promise = new Promise((resolve) => {
          tagCrud.GetTagsForCategory(companyTagCategories, companyid)
            .then(response => {
              const tags = response.data;
              resolve(tags);
            });
        });
        return promise;
      }
    }

    return datasource;
  }
  render() {
    if (!this.state.company) {
      return <PortletSpinner />;
    }

    const secondaryButtonProps = {}
    secondaryButtonProps.secondaryButtonEnabled = this.props.protectedRouteProps.allowActivateDeactivate && !(process.env.REACT_APP_PRODUCT_KEY === "NG" && process.env.REACT_APP_ENVIRONMENT === 'PROD');
    secondaryButtonProps.secondaryButtonActive = !this.state.company.deactivated;
    secondaryButtonProps.secondaryButtonActiveText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_ACTIVATE" });
    secondaryButtonProps.secondaryButtonDeactiveText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_DEACTIVATE" });
    secondaryButtonProps.secondaryButtonHandler = this.showModal;
    secondaryButtonProps.secondaryButtonActiveStyle = { backgroundColor: '#4CAF50', marginLeft: "10px" };
    secondaryButtonProps.secondaryButtonDeactiveStyle = { backgroundColor: '#f44336', marginLeft: "10px" };

    return (
      <Portlet>
        <PortletHeader title={this.props.intl.formatMessage({ id: "COMPANY.OVERVIEW.TITLE" })} />
        <PortletBody style={{ maxWidth: 800 }}>
          <BiDynamicForm
            //item={this.props.company}
            item={this.state.company}
            config={this.updateCompanyFormConfig}
            onSubmitHandler={this.updateCompany}
            disabled={this.state.company.deactivated === 1 ? true : false}
            customFields={this.state.company.customfields}
            {...secondaryButtonProps}
          />
        </PortletBody>
        <DxDialogModal
          visible={this.state.showModal}
          title={this.state.modalTitle}
          text={this.state.modalText}
          mode={this.state.company.deactivated ? 'COMPANY_ACTIVATION' : 'DEACTIVATION'}
          onDialogResult={this.onModalResponse}
        />
      </Portlet>
    );
  }
}

const mapStateToProps = store => ({
  userProfile: store.auth.userProfile,
  companies: store.companies.companies
});

const mapDispatchToProps = {
  forceCompaniesReload: companiesDuck.actions.setPending,
  companiesDuckSetPending: companiesDuck.actions.setPending,
  loadCompanies: companiesDuck.actions.fetchStart,
  dispatchLoadCompany: companyDuck.actions.fetchStart,
}

export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CompanyEditor))));
