/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import PortletSpinner from '../../../../partials/layout/PortletSpinner'
import * as contractsDuck from '../../../../store/ducks/contracts.duck';
import * as contractsCrud from '../../../../crud/contracts.crud';
import * as contractDuck from '../../../../store/ducks/contract.duck';
import * as companiesCrud from '../../../../crud/companies.crud';
import * as templatesCrud from '../../../../crud/templates.crud';
import * as companyUserCrud from '../../../../crud/companyUser.crud';
import * as asiteCrud from '../../../../crud/asite.crud';
import * as archiveCrud from '../../../../crud/archive.crud';
import * as projectsCrud from '../../../../crud/projects.crud';
import { contractTypes, GetCurrencyCodes, getContractPartyStructureTypes } from '../../../../shared/lookup';
import moment from 'moment';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { FormattedMessage, injectIntl } from "react-intl";
import * as tagCrud from '../../../../crud/tag.crud';
import Common from '../../../../shared/common';
import { contractTagCategories } from '../../../../shared/lookup';
import ChangeCompanyProjectModal from './Helpers/ChangeCompanyProjectModal';
import { confirm, alert as dxAlert } from 'devextreme/ui/dialog';
import { Alert } from "react-bootstrap";
import Form from 'devextreme-react/form';
import { withRouter } from '../../../../shared/hoc/withRouter';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import DxFormModal from '../../../../partials/content/dxControls/dxFormModal';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import DataSource from "devextreme/data/data_source";

const isFinancialLimitEnabled = process.env.REACT_APP_FINANCIAL_LIMIT_ENABLED === "true";
const isSecondaryPricesEnabled = process.env.REACT_APP_SECONDARY_PRICES_ENABLED === "true";

class ContractOverview extends React.Component {
    constructor(props) {
        super(props);
        this.currencyCodes = GetCurrencyCodes();
        this.validatorRef = React.createRef();
        this.pmUserSelectBoxRef = React.createRef();
        this.clientUserSelectBoxRef = React.createRef();
        this.supplierUserSelectBoxRef = React.createRef();
        this.supervisorUserSelectBoxRef = React.createRef();
        this.validatorName = 'ContractOverviewValidator';
        this.contractTagsEnabled = process.env.REACT_APP_CONTRACT_COMPANY_TAGS_ENABLED === 'true';
        this.isAsiteEnabled = process.env.REACT_APP_ATTACHMENT_MODE === 'ASITE';
        this.recordStatuses = [
            { valueExpr: 1, displayExpr: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.ACTIVE' }) },
            { valueExpr: 2, displayExpr: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.COMPLETED' }) },
            { valueExpr: 3, displayExpr: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.ARCHIVED' }) },
        ];

        this.contractPartyStructureTypes = getContractPartyStructureTypes(this.props.intl);

        this.copyModalConfig = {
            fields: [
                { label: this.props.intl.formatMessage({ id: 'CONTRACT.FORMS.NEW_CONTRACT_NAME' }), dataField: 'name', required: true },
                { label: this.props.intl.formatMessage({ id: 'CONTRACT.FORMS.CLONE.USERS' }), editorType: 'dxSwitch', dataField: 'cloneContractUsers', editorOptions: { value: false } }
            ]
        };
        this.state = {
            contract: null,
            showCopyTrackModal: false,
            optCompanies: [],
            optCompaniesFiltered: [],
            optEmployerUsers: [],
            optPMUsers: [],
            optContractorUsers: [],
            optSupervisorUsers: [],
            clientName: '',
            pmName: '',
            supplierName: '',
            supervisorName: '',
            changed: false,
            asiteUsers: [],
            asiteContractData: {
                contractid: 0,
                workspaceid: null,
                folderid: null,
                apiuser: ''
            },
            asiteWorkspaces: [],
            showCompanyProjectModal: false,
            isBusy: true,
        };
    }




    //FD-8918
    //Awaitable function will check supplier company Framework Tags agains Contract Framework tags
    //If they dont match this will be validated with user
    //Will return TRUE if its okay to continue with update
    //Will return FALSE if process need to be interrupted
    validateSupplierCompanyTagsWithContract = async () => {
        const { contract, supplierName } = this.state;

        //FETCH THE SUPPLIER COMPANY DETAILS
        let { data: supplierCompany } = await companiesCrud.getCompany(contract.contractorcompanyid);

        if (!supplierCompany) {
            //should not be possible
            //something is wrong here
            throw new Error("Supplier company does not exist in companies!!");
        }

        //GET SUPPLIER COMPANY FRAMEWORK TAGS
        const supplierCompanyFrameworkTags = (supplierCompany.tags?.categories || []).find(
            (ctg) => ctg.name === 'Frameworks'
        )

        if (supplierCompanyFrameworkTags && supplierCompanyFrameworkTags.tags.length > 0) {
            //SUPPLIER COMPANY TAGS EXIST
            const contractHasAnyFrameorkTagsMatchingSupplierCompanyTags = (contract.frameworkids || []).some((contractframeworkid) =>
                supplierCompanyFrameworkTags.tags.some((tag) => tag.tagid === contractframeworkid)
            );

            if (!contractHasAnyFrameorkTagsMatchingSupplierCompanyTags) {
                //IF THEY ARE NO MATCHES, VALIDATE WITH USER
                const warningMessage = this.props.intl.formatMessage({ id: 'CONTRACT.WARNING.FIELD.COMPANY_FRAMEWORK' }, { SUPPLIER_NAME: supplierName });
                const warningMessageLabel = this.props.intl.formatMessage({ id: 'CONTRACT.WARNING.FIELD.COMPANY_FRAMEWORK_LABEL' });
                let result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageLabel}`);
                return result;
            }
        }

        return true;
    };

    onUpdate = async () => {
        this.checkSelectboxValueExist(this.supervisorUserSelectBoxRef);
        this.checkSelectboxValueExist(this.pmUserSelectBoxRef);
        this.checkSelectboxValueExist(this.clientUserSelectBoxRef);
        this.checkSelectboxValueExist(this.supplierUserSelectBoxRef);
        if (this.validatorRef.current) {
            const validationResult = this.validatorRef.current.instance.validate();

            if (!validationResult.isValid) {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
                return;
            }
        }

        const newContract = { ...this.state.contract };

        //FD-8918 Compare & Verify with Supplier Company Framework Tags
        const supplierCompanyTagsValidated = await this.validateSupplierCompanyTagsWithContract();
        if (!supplierCompanyTagsValidated) {
            //if not validated exit save
            return;
        }

        this.setState({ isBusy: true }, async () => {
            try {

                delete newContract.templatejson;
                newContract.startdatedisplay = moment(newContract.startdatedisplay).format('DD/MM/YYYY');;
                newContract.completedatedisplay = moment(newContract.completedatedisplay).format('DD/MM/YYYY');;

                newContract.otherprice = +newContract.otherprice;
                newContract.section = +newContract.section;//CONVERT BOOLEAN TO INT
                newContract.isdemocontract = +newContract.isdemocontract;//CONVERT BOOLEAN TO INT
                newContract.financiallimitenabled = +newContract.financiallimitenabled;//CONVERT BOOLEAN TO INT
                
                await contractsCrud.saveContract(newContract);
                if (this.isAsiteEnabled) {
                    const formData = {
                        contractid: newContract.contractid,
                        workspaceid: newContract.workspaceid,
                        folderid: newContract.folderid,
                        apiuser: newContract.apiuser
                    };

                    await asiteCrud.saveAsiteContractData(formData);
                }
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "CONTRACT.MESSAGE.UPDATED_SUCCESS" }), { variant: 'success', });
                this.props.contractsDuckSetPending();
                this.props.loadContract(newContract.contractid);

            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
            finally {
                this.setState({ isBusy: false, changed: false });
            }
        })
    }

    componentDidMount() {
        this.setState({ isBusy: true }, this.setDefaultValues);
        const alertMessage = ((this.props.location || {}).state || {}).alertMessage;
        if (alertMessage) {
            dxAlert(`<div style="max-width:50vw;">${alertMessage.message}</div>`, alertMessage.title);
        }
    }

    setDefaultValues = async () => {
        // const { data: contractDetails } = await contractsCrud.getContract(this.props.params.contractid);
        const optContractTags = await this.getTags(Number(this.props.contract.companyid));
        let [optCompaniesFiltered, optCompanies] = await Promise.all([this.getCompanyOptions(this.props.userProfile), this.getCompanyOptions()]);




        const optEmployerUsers = this.getCompanyUserOptions(Number(this.props.contract.useremployercompanyid));
        const optPMUsers = this.getCompanyUserOptions(Number(this.props.contract.userpmcompanyid));
        const optContractorUsers = this.getCompanyUserOptions(Number(this.props.contract.usercontractcompanyid));
        const optSupervisorUsers = this.getCompanyUserOptions(Number(this.props.contract.usersupervisorcompanyid));
        const optTemplates = this.getTemplateOptions(Number(this.props.contract.companyid))

        let asiteUsers = [];
        let asiteContractData = {};
        let asiteWorkspaces = [];

        if (this.isAsiteEnabled) {
            try {
                asiteUsers = (await asiteCrud.getAsiteAPIUsers()).data;
            } catch (err) {
                this.props.enqueueSnackbar("Error fetching Asite users: " + err.toString(), { variant: 'error', });
            }
            try {
                asiteContractData = (await asiteCrud.getAsiteContractData(this.props.params.contractid)).data
            } catch (err) {
                this.props.enqueueSnackbar("Error fetching Asite contract data: " + err.toString(), { variant: 'error', });
            }
            try {
                asiteWorkspaces = (await asiteCrud.getAsiteWorkspaces()).data;
            } catch (err) {
                this.props.enqueueSnackbar("Error fetching Asite workspaces: " + err.toString(), { variant: 'error', });
            }
        }
        this.setState({
            contract: {
                ...this.props.contract,
                recordstatusdeleteddate: this.props.contract.recordstatusdeleteddate ? new Date(this.props.contract.recordstatusdeleteddate) : null,
                recordstatusarchiveddate: this.props.contract.recordstatusarchiveddate ? new Date(this.props.contract.recordstatusarchiveddate) : null,
                recordstatuscompleteddate: this.props.contract.recordstatuscompleteddate ? new Date(this.props.contract.recordstatuscompleteddate) : null,
                actualcompletiondate: this.props.contract.actualcompletiondate ? new Date(this.props.contract.actualcompletiondate) : null,
                exporteddate: this.props.contract.exporteddate ? new Date(this.props.contract.exporteddate) : null,
                lastcommunicateddate: this.props.contract.lastcommunicateddate ? new Date(this.props.contract.lastcommunicateddate) : null,
                startdatedisplay: new Date(this.props.contract.startdate),
                completedatedisplay: new Date(this.props.contract.completedate),
                section: this.props.contract.section === 1 ? true : false,
                otherprice: this.props.contract.otherprice === 1 ? true : false,
                apiuser: asiteContractData.apiuser,
                folderid: asiteContractData.folderid,
                workspaceid: asiteContractData.workspaceid,
                lastLoadedEpochTime: new Date().getTime(),
            },
            optCompanies: optCompanies,
            optCompaniesFiltered,
            optEmployerUsers: optEmployerUsers,
            optPMUsers: optPMUsers,
            optContractorUsers: optContractorUsers,
            optSupervisorUsers: optSupervisorUsers,
            optTemplates,
            clientName: this.props.contractTemplate.resources.CLIENT_NAME,
            pmName: this.props.contractTemplate.resources.PM_NAME,
            supplierName: this.props.contractTemplate.resources.SUPPLIER_NAME,
            supervisorName: this.props.contractTemplate.resources.SUPERVISOR_NAME,
            asiteUsers,
            asiteContractData,
            asiteWorkspaces,
            contractTags: optContractTags,
            isBusy: false
        });
    }
    getTags = async (companyid) => {
        if (!this.contractTagsEnabled) { return {}; }
        try {
            const { data: tags } = await tagCrud.GetTagsForCategory(contractTagCategories, companyid);
            const contractTags = Common.GroupBy(tags, 'category');
            return contractTags;

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            return {};
        }
    }

    getCompanyOptions = async (userProfile) => {
        try {
            const { data: companies } = await companiesCrud.getCutDownCompaniesList(userProfile);
            return companies;

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            return [];
        }
    }
    getTemplateOptions = (companyid) => {
        const datasource = {
            loadMode: 'raw',
            cacheRawData: true,
            load: () => {
                var promise = new Promise((resolve, reject) => {
                    templatesCrud.getTemplatesToSelectList(companyid)
                        .then(response => {
                            let retVal = [];
                            if (response.data) {
                                retVal = response.data;
                            }
                            resolve(retVal);
                        })
                        .catch((err) => {
                            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                            reject(err);
                        })
                });
                return promise;
            }
        }
        return datasource;
    }

    getCompanyUserOptions = (companyid) => {
        const datasource = new DataSource({
            loadMode: 'raw',
            cacheRawData: true,
            load: () => {
                var promise = new Promise((resolve, reject) => {
                    companyUserCrud.getCompanyUsersToSelectList(companyid)
                        .then(response => {
                            let users = response.data;
                            if (users.length < 1) {
                                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "WARNING.COMPANY_HAS_NO_USERS" }), { variant: 'error', });
                            }
                            users = users.map(user => ({ accountid: user.accountid, display: `${user.name} (${user.email})` }));

                            resolve(users);
                        })
                        .catch((err) => {
                            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                            reject(err);
                        })
                });
                return promise;
            }
        });
        return datasource;
    }
    checkSelectboxValueExist = (selectBoxRef) => {
        if (selectBoxRef && selectBoxRef.current?._instance) {
            //GET CURRENT ASSIGNED VALUE
            const value = selectBoxRef.current._instance.option("value");
            //GET CURRENT SELECTED ITEM 
            const selectedItem = selectBoxRef.current._instance.option("selectedItem");
            if (value && !selectedItem) {
                //IF VALUE EXIST BUT NOT SELECTED_ITEM
                //IT MEANS SELECTION NO LONGER EXIST
                //LETS CLEAR THE VALUE
                console.log("Resetting selectbox value since value no longer exist in selections!");
                selectBoxRef.current._instance.option("value", null)

            }
        };
    }
    onValueChanged = async (field, value) => {
        if (this.state.contract[field] !== value) {

            //Validate form first
            if (this.validatorRef.current) {
                this.validatorRef.current.instance.validate();
            }

            const stateChanges = { changed: true };

            //dont change contract obj reference to avoid unnecessary child re-render
            this.state.contract[field] = value;

            if (field === 'companyid') {
                stateChanges.contractTags = await this.getTags(Number(value));
            }
            else if (field === 'useremployercompanyid') {
                stateChanges.optEmployerUsers = this.getCompanyUserOptions(Number(value));
            }
            else if (field === 'userpmcompanyid') {
                stateChanges.optPMUsers = this.getCompanyUserOptions(Number(value));
            }
            else if (field === 'usercontractcompanyid') {
                stateChanges.optContractorUsers = this.getCompanyUserOptions(Number(value));
            }
            else if (field === 'usersupervisorcompanyid') {
                stateChanges.optSupervisorUsers = this.getCompanyUserOptions(Number(value));
            }

            this.setState(stateChanges);
        }
    }

    changeCompanyIdProjectId = async (newcompanyid, newprojectid) => {
        this.setState({ isBusy: true }, async () => {
            try {
                await projectsCrud.changeCompanyIdProjectId(this.props.contract.contractid, this.state.contract.companyid, newcompanyid, this.state.contract.projectid, newprojectid);
                // this.props.loadContract(this.props.contract.contractid);
                // this.setState({ contract: { ...this.state.contract, companyid: newcompanyid, projectid: newprojectid, lastLoadedEpochTime: new Date().getTime() } });
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "CONTRACT.MESSAGE.UPDATED_SUCCESS" }), { variant: 'success' });
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
            finally {
                this.setState({ isBusy: false });
            }
        })

    }
    showCompanyProjectModal = (mode, e) => {
        if (this.checkForChangesOnForm()) {
            return;
        }
        this.setState({
            showCompanyProjectModal: true
        });
    }
    onCompanyProjectModalResponse = (response, params) => {
        this.setState({ showCompanyProjectModal: false }, () => {
            if (response === 'OK') {
                this.changeCompanyIdProjectId(params.companyid, params.projectid);
            }
        });
    }
    deleteContract = async () => {
        if (this.checkForChangesOnForm()) {
            return;
        }
        this.setState({ isBusy: true }, async () => {
            try {
                let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "CONTRACT.MODAL.DELETE.MESSAGE" })}</div>`, this.props.intl.formatMessage({ id: "CONTRACT.MODAL.DELETE.TITLE" }));
                if (result) {
                    await contractsCrud.deleteContract(this.props.params.contractid);
                    this.setState({ contract: { ...this.state.contract, deleted: 1, recordstatusdeleteddate: new Date(), lastLoadedEpochTime: new Date().getTime() } });
                    this.props.loadContract(this.props.contract.contractid);
                    this.props.contractsDuckSetPending();
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "CONTRACT.MESSAGE.DELETED" }), { variant: 'success', });
                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
            finally {
                this.setState({ isBusy: false });
            }
        })
    }
    undeleteContract = async () => {
        if (this.checkForChangesOnForm()) {
            return;
        }
        this.setState({ isBusy: true }, async () => {
            try {
                let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "CONTRACT.MODAL.UNDELETE.MESSAGE" })}</div>`, this.props.intl.formatMessage({ id: "CONTRACT.MODAL.UNDELETE.TITLE" }));
                if (result) {
                    await contractsCrud.restoreDeletedContract(this.props.params.contractid);
                    this.setState({ contract: { ...this.state.contract, deleted: 0, lastLoadedEpochTime: new Date().getTime() } });
                    this.props.loadContract(this.props.contract.contractid);
                    this.props.contractsDuckSetPending();
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "CONTRACT.MESSAGE.RESTORED" }), { variant: 'success', });
                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
            finally {
                this.setState({ isBusy: false });
            }
        })
    }
    completeContract = async () => {
        if (this.checkForChangesOnForm()) {
            return;
        }
        this.setState({ isBusy: true }, async () => {
            try {
                let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "CONTRACT.MODAL.COMPLETE.MESSAGE" })}</div>`, this.props.intl.formatMessage({ id: "CONTRACT.MODAL.COMPLETE.TITLE" }));
                if (result) {
                    await contractsCrud.completeContract(this.props.params.contractid);
                    this.setState({ contract: { ...this.state.contract, recordstatus: 2, recordstatuscompleteddate: new Date(), lastLoadedEpochTime: new Date().getTime() } });
                    this.props.loadContract(this.props.contract.contractid);
                    this.props.contractsDuckSetPending();
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "CONTRACT.MESSAGE.COMPLETED" }), { variant: 'success', });
                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
            finally {
                this.setState({ isBusy: false });
            }
        })
    }

    onCopyModalResponse = (resp) => {

        this.setState({ showCopyModal: false }, async () => {
            try {
                if (resp) {
                    const contractid = this.props.contract.contractid;
                    const newContractName = resp.name;
                    const { data: newContractID } = await contractsCrud.cloneContract(contractid, newContractName, resp.cloneContractUsers);
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "CONTRACT.MESSAGE.CLONED" }), { variant: 'success', });
                    this.props.navigate(`/contract/${newContractID}/settings/overview`,
                        {
                            state: {
                                alertMessage: {
                                    message: this.props.intl.formatMessage({ id: "CONTRACT.MESSAGE.CLONED_WARNING" }),
                                    title: this.props.intl.formatMessage({ id: "CONTRACT.MESSAGE.CLONED_WARNING_TITLE" }),
                                }
                            }
                        })
                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
            finally {
                this.setState({ isBusy: false });
            }
        })


    }

    exportContractArchive = async () => {
        if (this.checkForChangesOnForm()) {
            return;
        }
        this.setState({ isBusy: true }, async () => {
            try {
                let continiueWithAction = true;
                if (this.state.contract.recordstatus != 3) {
                    //if contract is not in archived state
                    //ask user want to continue?                    
                    continiueWithAction = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "CONTRACT.MODAL.EXPORT.MESSAGE" })}</div>`, this.props.intl.formatMessage({ id: "CONTRACT.MODAL.EXPORT.TITLE" }));

                }
                if (continiueWithAction) {
                    const postData = {}
                    postData.referenceid = this.props.contract.contractid;
                    await archiveCrud.exportContractArchive(this.props.contract.contractid);
                    this.setState({ contract: { ...this.state.contract, exporteddate: new Date(), lastLoadedEpochTime: new Date().getTime() } });
                    this.props.loadContract(this.props.contract.contractid);
                    this.props.contractsDuckSetPending();
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "CONTRACT.MESSAGE.EXPORT_STARTED" }), { variant: 'success', });
                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
            finally {
                this.setState({ isBusy: false });
            }
        })
    }

    restoreCompletedContract = async () => {
        if (this.checkForChangesOnForm()) {
            return;
        }
        this.setState({ isBusy: true }, async () => {
            try {
                let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "CONTRACT.MODAL.UNCOMPLETE.MESSAGE" })}</div>`, this.props.intl.formatMessage({ id: "CONTRACT.MODAL.UNCOMPLETE.TITLE" }));
                if (result) {
                    await contractsCrud.restoreCompletedContract(this.props.params.contractid);
                    this.setState({ contract: { ...this.state.contract, recordstatus: 1, lastLoadedEpochTime: new Date().getTime() } });
                    this.props.loadContract(this.props.contract.contractid);
                    this.props.contractsDuckSetPending();
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "CONTRACT.MESSAGE.RESTORED_FROM_COMPLETED" }), { variant: 'success', });
                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
            finally {
                this.setState({ isBusy: false });
            }
        })
    }
    archiveContract = async () => {
        if (this.checkForChangesOnForm()) {
            return;
        }
        this.setState({ isBusy: true }, async () => {
            try {
                let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "CONTRACT.MODAL.ARCHIVE.MESSAGE" })}</div>`, this.props.intl.formatMessage({ id: "CONTRACT.MODAL.ARCHIVE.TITLE" }));
                if (result) {
                    await contractsCrud.archiveContract(this.props.params.contractid);
                    this.setState({ contract: { ...this.state.contract, recordstatus: 3, recordstatusarchiveddate: new Date(), lastLoadedEpochTime: new Date().getTime() } });
                    this.props.loadContract(this.props.contract.contractid);
                    this.props.contractsDuckSetPending();
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "CONTRACT.MESSAGE.ARCHIVED" }), { variant: 'success', });
                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
            finally {
                this.setState({ isBusy: false });
            }
        })
    }
    restoreArchivedContract = async () => {
        if (this.checkForChangesOnForm()) {
            return;
        }
        this.setState({ isBusy: true }, async () => {
            try {
                let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "CONTRACT.MODAL.UNARCHIVE.MESSAGE" })}</div>`, this.props.intl.formatMessage({ id: "CONTRACT.MODAL.UNARCHIVE.TITLE" }));
                if (result) {
                    await contractsCrud.restoreArchivedContract(this.props.params.contractid);
                    this.setState({ contract: { ...this.state.contract, recordstatus: 1, lastLoadedEpochTime: new Date().getTime() } });
                    this.props.loadContract(this.props.contract.contractid);
                    this.props.contractsDuckSetPending();
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "CONTRACT.MESSAGE.RESTORED_FROM_ARCHIVE" }), { variant: 'success', });
                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
            finally {
                this.setState({ isBusy: false });
            }
        })
    }
    customDisplayExpr = (data) => {
        if (data) {
            return `${data.id} - ${data.description}`;
        }
    }
    checkForChangesOnForm = () => {
        if (this.state.changed) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "CONTRACT.WARNING.PENDING_CHANGES" }), { variant: 'warning' });
        }
        return this.state.changed;
    }

    render() {
        //NEEDS TO WAIT companies BECAUSE ITS USED FOR TAG VALIDATION
        if (!this.state.contract) {
            return (<PortletSpinner />)
        }

        let { allowEditDemo, allowExport, allowChangeState, allowClone, allowChangeTemplate } = this.props.protectedRouteProps;
        const canUpdateCompanyProject = this.state.contract.recordstatus !== 3 && this.state.contract.deleted === 0;

        const templateModeOptions = []
        if (this.props.contract.usemastertemplate) {
            if (this.props.contract.lastcustomtemplateediteddate) {
                templateModeOptions.push(
                    { value: 0, display: this.props.intl.formatMessage({ id: 'CONTRACT.OVERVIEW.TEMPLATE_OPTIONS.CUSTOM_SWITCH_OLD' }, { LAST_MODIFIED: moment(this.props.contract.lastcustomtemplateediteddate).format('DD/MM/YYYY') }) },
                    { value: 1, display: this.props.intl.formatMessage({ id: 'CONTRACT.OVERVIEW.TEMPLATE_OPTIONS.USE_MASTER' }) }
                )
            }
            else {
                templateModeOptions.push(
                    { value: 0, display: this.props.intl.formatMessage({ id: 'CONTRACT.OVERVIEW.TEMPLATE_OPTIONS.CUSTOM_SWITCH_NEW' }) },
                    { value: 1, display: this.props.intl.formatMessage({ id: 'CONTRACT.OVERVIEW.TEMPLATE_OPTIONS.USE_MASTER' }) }
                )
            }

        }
        else {
            templateModeOptions.push(
                { value: 0, display: this.props.intl.formatMessage({ id: 'CONTRACT.OVERVIEW.TEMPLATE_OPTIONS.CUSTOM_OWN' }) },
                { value: 1, display: this.props.intl.formatMessage({ id: 'CONTRACT.OVERVIEW.TEMPLATE_OPTIONS.USE_MASTER' }) }
            )
        }

        const visibleConditionTemplate = (item) => {
            if (!this.props.contract.usemastertemplate && !item.usemastertemplate) {
                //has its own template
                //no change - no need to show
                return false;
            }
            if (this.props.contract.usemastertemplate && !item.usemastertemplate) {
                //switched to use custom
                //no need to show
                if (this.props.contract.lastcustomtemplateediteddate) {
                    //if switching back to existing template
                    //no need to show master selection
                    return false;
                }

            }
            return true;
        }
        const updateContractFormConfig = {
            fields: [
                {
                    resource: 'CONTRACT.FIELD.STATUS',
                    dataField: 'recordstatus',
                    editorType: 'dxSelectBox',
                    editorOptions: {
                        readOnly: true,
                        dataSource: this.recordStatuses,
                        displayExpr: 'displayExpr',
                        valueExpr: 'valueExpr',
                    },

                },
                { resource: 'CONTRACT.FIELD.NUMBER', dataField: 'contractreferencenumber' },
                { resource: 'CONTRACT.FIELD.NAME', dataField: 'name', },
                { resource: 'CONTRACT.FIELD.COMPANY', dataField: 'company', editorOptions: { readOnly: true, buttons: [] } },
                { resource: 'CONTRACT.FIELD.PROJECT', dataField: 'project', editorOptions: { readOnly: true, buttons: [] } },
                { resource: 'CONTRACT.FIELD.TYPE', 
                    tooltipResource: 'CONTRACT.FIELD.CONTRACT_TEMPLATE_TOOLTIP',
                    dataField: 'contracttype', 
                    editorType: 'dxSelectBox', 
                    editorOptions: { dataSource: contractTypes } },
                { 
                    resource: 'CONTRACT.FIELD.PARTY_STRUCTURE', 
                    tooltipResource: 'CONTRACT.FIELD.PARTY_STRUCTURE_TOOLTIP', 
                    dataField: 'partystructure', 
                    editorType: 'dxSelectBox', 
                    editorOptions: { dataSource: this.contractPartyStructureTypes, displayExpr: 'display', valueExpr: 'value', } 
                },
                { resource: 'CONTRACT.FIELD.TEMPLATE_MODE', dataField: 'usemastertemplate', editorType: 'dxRadioGroup', editorOptions: { items: templateModeOptions, valueExpr: 'value', displayExpr: 'display', readOnly: !allowChangeTemplate, disabled: !allowChangeTemplate } },
                { resource: 'CONTRACT.FIELD.TEMPLATE',dataField: 'mastertemplateid', editorType: 'dxSelectBox', visible: visibleConditionTemplate, editorOptions: { dataSource: this.state.optTemplates, valueExpr: 'templateid', displayExpr: 'name', readOnly: !allowChangeTemplate, disabled: !allowChangeTemplate } },
                { resource: 'CONTRACT.FIELD.PO_NUMBER', dataField: 'versionnumber', required: false },
                { resource: 'CONTRACT.FIELD.PO_VALUE', dataField: 'scope', editorType: 'dxNumberBox', required: false, editorOptions: { format: '#,##0.00', placeholder: '0.00' } },
                { resource: 'CONTRACT.FIELD.DEFECTS_PERIOD', dataField: 'defectinterval', required: false, editorType: 'dxNumberBox', editorOptions: { format: '#,##0 ' + this.props.intl.formatMessage({ id: "WATERMARK.WEEKS" }), placeholder: '0 ' + this.props.intl.formatMessage({ id: "WATERMARK.WEEKS" }) } }
            ]
        };
        if (this.contractTagsEnabled) {
            updateContractFormConfig.fields.push(
                { resource: 'CONTRACT.FIELD.AREAS', dataField: 'areaids', required: false, editorType: 'dxTagBox', editorOptions: { dataSource: this.state.contractTags.Areas, valueExpr: 'tagid', displayExpr: 'name' } },
                { resource: 'CONTRACT.FIELD.HUBS', dataField: 'hubids', required: false, editorType: 'dxTagBox', editorOptions: { dataSource: this.state.contractTags.Hubs, valueExpr: 'tagid', displayExpr: 'name' } },
                { resource: 'CONTRACT.FIELD.LOTS', dataField: 'lotids', required: false, editorType: 'dxTagBox', editorOptions: { dataSource: this.state.contractTags.Lots, valueExpr: 'tagid', displayExpr: 'name' } },
                { resource: 'CONTRACT.FIELD.FRAMEWORKS', dataField: 'frameworkids', required: false, editorType: 'dxTagBox', editorOptions: { dataSource: this.state.contractTags.Frameworks, valueExpr: 'tagid', displayExpr: 'name' } },
                { resource: 'GENERAL.FORMS.FIELD.OTHER', dataField: 'othercontracttagids', required: false, editorType: 'dxTagBox', editorOptions: { dataSource: this.state.contractTags.ContractOther, valueExpr: 'tagid', displayExpr: 'name' } },
            );
        }

        const visibleConditionClient = (item) => {
            return (item.partystructure === 2 || item.partystructure === 3);
        }

        const visibleConditionSupervisor = (item) => {
            return (item.partystructure === 3 || item.partystructure === 4);
        }

        updateContractFormConfig.fields.push(
            {
                label: this.state.clientName,
                visible: visibleConditionClient,
                fields: [
                    { resource: 'CONTRACT.FIELD.COMPANY', dataField: 'employercompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    { resource: 'CONTRACT.FIELD.EMPLOYER.USER', dataField: 'useremployercompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    {
                        resource: 'CONTRACT.FIELD.USER',
                        dataField: 'employerid',
                        dependOn: 'useremployercompanyid',
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            //Pass ref, this will be used to clear value after data loaded ( if selection no longer exist)
                            ref: this.clientUserSelectBoxRef,
                            dataSource: this.state.optEmployerUsers,
                            valueExpr: 'accountid',
                            displayExpr: 'display'
                        }
                    }
                ]
            },
            {
                label: this.state.pmName,
                fields: [
                    { resource: 'CONTRACT.FIELD.COMPANY', dataField: 'pmcompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    { resource: 'CONTRACT.FIELD.PM.USER', dataField: 'userpmcompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    {
                        resource: 'CONTRACT.FIELD.USER',
                        dataField: 'projectmanagerid',
                        dependOn: 'userpmcompanyid',
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            //Pass ref, this will be used to clear value after data loaded ( if selection no longer exist)
                            ref: this.pmUserSelectBoxRef,
                            dataSource: this.state.optPMUsers,
                            valueExpr: 'accountid',
                            displayExpr: 'display',
                            deferRendering: false
                        }
                    },
                ]
            },
            {
                label: this.state.supplierName,
                fields: [
                    { resource: 'CONTRACT.FIELD.COMPANY', dataField: 'contractorcompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    { resource: 'CONTRACT.FIELD.CONTRACTOR.USER', dataField: 'usercontractcompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    {
                        resource: 'CONTRACT.FIELD.USER',
                        dataField: 'contractmanagerid',
                        dependOn: 'usercontractcompanyid',
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            //Pass ref, this will be used to clear value after data loaded ( if selection no longer exist)
                            ref: this.supplierUserSelectBoxRef,
                            dataSource: this.state.optContractorUsers,
                            valueExpr: 'accountid',
                            displayExpr: 'display'
                        }
                    },
                ]
            },
            {
                label: this.state.supervisorName,
                visible: visibleConditionSupervisor,
                fields: [
                    { resource: 'CONTRACT.FIELD.COMPANY', dataField: 'supervisorcompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    { resource: 'CONTRACT.FIELD.SUPERVISOR.USER', dataField: 'usersupervisorcompanyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.optCompanies, valueExpr: 'companyid', displayExpr: 'name' } },
                    {
                        resource: 'CONTRACT.FIELD.USER',
                        dataField: 'supervisorid',
                        dependOn: 'usersupervisorcompanyid',
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            //Pass ref, this will be used to clear value after data loaded ( if selection no longer exist)
                            ref: this.supervisorUserSelectBoxRef,
                            dataSource: this.state.optSupervisorUsers,
                            valueExpr: 'accountid',
                            displayExpr: 'display'
                        }
                    },
                ]
            }
        );


        updateContractFormConfig.fields.push(
            {
                label: '',
                fields: [
                    { resource: 'GENERAL.FORMS.FIELD.START_DATE', dataField: 'startdatedisplay', editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: new Date(1900, 0, 1), max: new Date(2079, 5, 6) } }, validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: "REPORTS.FORM.VALIDATION.STARTDATE" }) } },
                    { resource: 'CONTRACT.FIELD.CMP_DATE', dataField: 'completedatedisplay', editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: this.state.contract.startdatedisplay, max: moment().add(100, "years")._d } }, validation: { type: 'range', min: this.state.contract.startdatedisplay, max: moment().add(100, "years")._d , warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_COMPLETION_DATE" }) } },
                    { resource: 'CONTRACT.FIELD.CURRENCY', dataField: 'currency', editorType: 'dxSelectBox', editorOptions: { dataSource: this.currencyCodes, valueExpr: 'code', displayExpr: 'name' } },
                    { resource: 'CONTRACT.FIELD.PRICE', dataField: 'contractvalue', editorType: 'dxNumberBox', editorOptions: { format: '#,##0.00', placeholder: '0.00' } },
                ]
            }
        );


        const preferenceFields = [];
        preferenceFields.push(
            { resource: 'CONTRACT.FIELD.SECONDARY_DATES', dataField: 'section', editorType: 'dxSwitch', editorOptions: { switchedOffText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }) } },
        );
        if (isSecondaryPricesEnabled) {
            preferenceFields.push(
                { resource: 'CONTRACT.FIELD.SECONDARY_PRICES', dataField: 'otherprice', editorType: 'dxSwitch', editorOptions: { switchedOffText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }) } }
            );
        }
        if (allowEditDemo) {
            preferenceFields.push(
                { resource: 'CONTRACT.FIELD.ISDEMOCONTRACT', dataField: 'isdemocontract', editorType: 'dxSwitch', editorOptions: { switchedOffText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }) } }
            );
        }
        if (isFinancialLimitEnabled) {
            preferenceFields.push(
                { resource: 'CONTRACT.FIELD.CONTRACTFINACIALLIMITENABLED', dataField: 'financiallimitenabled', editorType: 'dxSwitch', editorOptions: { switchedOffText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }) } }
            );
        }
        if (preferenceFields.length > 0) {
            updateContractFormConfig.fields.push(
                {
                    resource: 'CONTRACT.FIELD.PREFERENCES',
                    fields: preferenceFields
                }
            );
        }

        if (this.isAsiteEnabled) {
            updateContractFormConfig.fields.push(
                {
                    label: 'Asite Configuration',
                    fields: [
                        { resource: 'CONTRACT.FIELD.WORKSPACE', dataField: 'workspaceid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.asiteWorkspaces, valueExpr: 'id', displayExpr: this.customDisplayExpr } },
                        { resource: 'CONTRACT.FIELD.FOLDERID', dataField: 'folderid', required: false },
                        { resource: 'CONTRACT.FIELD.APIUSER', dataField: 'apiuser', editorType: 'dxSelectBox', editorOptions: { dataSource: this.state.asiteUsers } }
                    ]
                }
            );
        }
        return (
            <>
                <Portlet>
                    <NavigationBlocker
                        when={(this.state.changed || this.state.isBusy)}
                        message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                    />
                    <PortletHeader resource="CONTRACT.OVERVIEW.TITLE"
                        toolbar={(
                            <div style={{ display: 'flex', flex: 1, flexFlow: 'row-reverse', margin: "20px 0px" }}>
                                <LoadIndicator className="button-indicator" visible={this.state.isBusy} />
                                <Toolbar disabled={this.state.isBusy}>
                                    <ToolbarItem
                                        key="contractBtnUpdate"
                                        widget="dxButton"
                                        location="after"
                                        locateInMenu="never"
                                        disabled={!this.state.changed || this.state.contract.deleted !== 0}
                                        options={{
                                            icon: "save",
                                            text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_UPDATE" }),
                                            type: "default",
                                            stylingMode: this.state.changed ? "contained" : "outlined",
                                            onClick: this.onUpdate
                                        }} />
                                    {allowClone && (
                                        <ToolbarItem
                                            key="contractBtnClone"
                                            location="after"
                                            locateInMenu="always"
                                            widget="dxButton"
                                            options={
                                                {
                                                    icon: "copy",
                                                    onClick: () => {
                                                        if (this.checkForChangesOnForm()) {
                                                            return;
                                                        }
                                                        this.setState({ showCopyModal: true })
                                                    },
                                                    text: this.props.intl.formatMessage({ id: "CONTRACT.FORMS.BUTTON_CLONE" }),
                                                }
                                            }
                                        />
                                    )}
                                    {
                                        (this.state.contract.deleted === 0 && process.env.REACT_APP_EXPORT_ENABLED === 'true' && allowExport) && (
                                            <ToolbarItem
                                                key="contractBtnStartExport"
                                                location='after'
                                                locateInMenu="always"
                                                widget="dxButton"
                                                name='export'
                                                options={{
                                                    icon: "export",
                                                    text: this.props.intl.formatMessage({ id: "CONTRACT.FORMS.BUTTON_EXPORT_START" }),
                                                    type: "normal",
                                                    onClick: this.exportContractArchive,
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        (this.state.contract.deleted === 0 && [1, 2].includes(this.state.contract.recordstatus) && canUpdateCompanyProject) && (
                                            <ToolbarItem
                                                key="contractBtnChangeCompany"
                                                location='after'
                                                name='changecompanyproject'
                                                locateInMenu="always"
                                                widget="dxButton"
                                                options={{
                                                    icon: "sorted",
                                                    text: this.props.intl.formatMessage({ id: "CONTRACT.FORMS.BUTTON_CHANGE_COMPANY_PROJECT" }),
                                                    type: "normal",
                                                    onClick: this.showCompanyProjectModal
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        (this.state.contract.deleted === 0 && [1].includes(this.state.contract.recordstatus) && allowChangeState) && (
                                            <ToolbarItem
                                                key="contractBtnComplete"
                                                location='after'
                                                locateInMenu="always"
                                                widget="dxButton"
                                                name='complete'
                                                options={{
                                                    icon: "check",
                                                    text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_COMPLETE" }),
                                                    type: "success",
                                                    stylingMode: "outlined",
                                                    onClick: this.completeContract,
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        (this.state.contract.deleted === 0 && [2].includes(this.state.contract.recordstatus) && allowChangeState) && (
                                            <ToolbarItem
                                                key="contractBtnRestoreToActive"
                                                location='after'
                                                locateInMenu="always"
                                                widget="dxButton"
                                                name='uncomplete'
                                                options={{
                                                    icon: "undo",
                                                    text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_RESTORE_ACTIVE" }),
                                                    type: "success",
                                                    stylingMode: "outlined",
                                                    onClick: this.restoreCompletedContract,
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        (this.state.contract.deleted === 0 && [1, 2].includes(this.state.contract.recordstatus) && allowChangeState) && (
                                            <ToolbarItem
                                                key="contractBtnArchive"
                                                location='after'
                                                locateInMenu="always"
                                                widget="dxButton"
                                                name='archive'
                                                options={{
                                                    icon: "movetofolder",
                                                    text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_ARCHIVE" }),
                                                    type: "danger",
                                                    stylingMode: "outlined",
                                                    onClick: this.archiveContract,
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        (this.state.contract.deleted === 0 && [3].includes(this.state.contract.recordstatus) && allowChangeState) && (
                                            <ToolbarItem
                                                key="contractBtnRestoreFromArchive"
                                                location='after'
                                                locateInMenu="always"
                                                widget="dxButton"
                                                name='unarchive'
                                                options={{
                                                    icon: "undo",
                                                    text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_RESTORE_ARCHIVE" }),
                                                    type: "danger",
                                                    stylingMode: "outlined",
                                                    onClick: this.restoreArchivedContract,
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        (this.state.contract.deleted === 0 && allowChangeState) && (
                                            <ToolbarItem
                                                key="contractBtnDelete"
                                                location='after'
                                                locateInMenu="always"
                                                widget="dxButton"
                                                name='delete'
                                                options={{
                                                    icon: "trash",
                                                    text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_DELETE" }),
                                                    type: "danger",
                                                    stylingMode: "outlined",
                                                    onClick: this.deleteContract,
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        (this.state.contract.deleted === 1 && allowChangeState) && (
                                            <ToolbarItem
                                                key="contractBtnUndelete"
                                                location='after'
                                                locateInMenu="always"
                                                widget="dxButton"
                                                name='undelete'
                                                options={{
                                                    icon: "undo",
                                                    text: this.props.intl.formatMessage({ id: "CONTRACT.FORMS.BUTTON_UNDELETE" }),
                                                    type: "danger",
                                                    stylingMode: "outlined",
                                                    onClick: this.undeleteContract,
                                                }}
                                            />
                                        )
                                    }
                                </Toolbar>
                            </div>
                        )}
                    />
                    <PortletBody style={{ maxWidth: 800 }}>
                        <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                            <div>
                                <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                            </div>
                            {this.state.contract.deleted === 1 && (
                                <Alert variant="warning" style={{ display: 'block', margin: 20 }}>
                                    <Alert.Heading><FormattedMessage id="CONTRACT.ALERT.DELETED.TITLE" /></Alert.Heading>
                                    <hr />
                                    <p>
                                        <FormattedMessage id="CONTRACT.ALERT.DELETED.MESSAGE" values={{ DATE: moment(this.state.contract.recordstatusdeleteddate).format('DD/MM/YYYY') }} />
                                    </p>
                                </Alert>
                            )}

                            <Form
                                id="form"
                                style={{ marginBottom: 10 }}
                                labelMode="floating"
                                formData={this.state.contract}
                                readOnly={true}
                                showColonAfterLabel={true}
                                labelLocation="left"
                                minColWidth={200}
                                colCount={3}
                                width="100%"
                                items={[
                                    { label: { text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.COMPLETE_DATE" }), }, dataField: 'recordstatuscompleteddate', itemType: 'simple', editorType: 'dxDateBox', editorOptions: { displayFormat: 'dd/MM/yyyy HH:mma' } },
                                    { label: { text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.ARCHIVE_DATE" }), }, dataField: 'recordstatusarchiveddate', itemType: 'simple', editorType: 'dxDateBox', editorOptions: { displayFormat: 'dd/MM/yyyy HH:mma' } },
                                    { label: { text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.DELETE_DATE" }), }, dataField: 'recordstatusdeleteddate', itemType: 'simple', editorType: 'dxDateBox', editorOptions: { displayFormat: 'dd/MM/yyyy HH:mma' } },
                                    { label: { text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.LAST_COMMUNICATED_DATE" }), }, dataField: 'lastcommunicateddate', itemType: 'simple', editorType: 'dxDateBox', editorOptions: { displayFormat: 'dd/MM/yyyy HH:mma' } },
                                    { label: { text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.EXPORTED_DATE" }), }, dataField: 'exporteddate', itemType: 'simple', editorType: 'dxDateBox', editorOptions: { displayFormat: 'dd/MM/yyyy HH:mma' } },
                                    { label: { text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.ACTUAL_COMPLETION_DATE" }), }, dataField: 'actualcompletiondate', itemType: 'simple', editorType: 'dxDateBox', editorOptions: { displayFormat: 'dd/MM/yyyy' } }
                                ]}
                            />

                            <BiDynamicForm
                                key={this.state.contract.lastLoadedEpochTime}
                                item={this.state.contract}
                                config={updateContractFormConfig}
                                valueChangeHandler={this.onValueChanged}
                                customFields={this.state.contract.customfields}
                                disabled={(this.state.contract.deleted === 1 || this.state.isBusy)}
                                buttonDisabled={(this.state.contract.recordstatus === 3 || this.state.isBusy)}
                            />

                        </ValidationGroup>
                    </PortletBody>
                    <ChangeCompanyProjectModal
                        visible={this.state.showCompanyProjectModal}
                        title={this.props.intl.formatMessage({ id: "CONTRACT.COMPANY.PROJECT.UPDATE.MODAL.TITLE" })}
                        saveButtonText={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_UPDATE' })}
                        onDialogResult={this.onCompanyProjectModalResponse}
                        companies={this.state.optCompaniesFiltered}
                    />
                </Portlet>
                <DxFormModal
                    visible={this.state.showCopyModal}
                    title={this.props.intl.formatMessage({ id: 'CONTRACT.FORMS.CLONE_TITLE' })}
                    config={this.copyModalConfig}
                    onDialogResult={this.onCopyModalResponse}
                    primaryButton={{
                        text: this.props.intl.formatMessage({ id: "CONTRACT.FORMS.BUTTON_CLONE" })
                    }}
                />
            </>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    contractTemplate: store.contract.template,
    userProfile: store.auth.userProfile,
});

const mapDispatchToProps = {
    contractsDuckSetPending: contractsDuck.actions.setPending,
    loadContract: contractDuck.actions.fetchStart,
}

export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ContractOverview))));
