import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProgrammeList from './List';
import ProgrammeCreate from './Create';
import ProgrammeDetail from './Detail';
import { injectIntl } from "react-intl";

const defaultFields = {
    Reference: null,
    PreviousPlanned: 'Previous Planned Completion',
    PlannedCompletion: 'Date of Planned Completion this period',
    Reason: null,
    Change: null,
    MainProgress: null,
    KeyActivities: null,
    Summary: null,
    Changes: null,
    Compensation: null,
}
class ProgrammeRouter extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.programmeReplyEnabled = props.workflowItem.replyEnabled;
        } catch (error) {
            console.log('ProgrammeReplyEnabled placeholder not available!');
            this.programmeReplyEnabled = props.mode === 'SUPPLIER';
        }

        this.fields = { ...defaultFields };
        this.fields.PreviousPlanned = this.props.intl.formatMessage({ id: 'CONTRACT.WF.PROGRAMME.FIELD.PREVIOUSLY_PLANNED' });
        this.fields.PlannedCompletion = this.props.intl.formatMessage({ id: 'CONTRACT.WF.PROGRAMME.FIELD.PLANNED_COMPLETION' });
        this.fields.Reference = props.resources.ReferenceLabel;
        this.fields.Reason = props.resources.ReasonLabel;
        this.fields.Change = props.resources.ChangeLabel;
        this.fields.MainProgress = props.resources.MainProgressLabel;
        this.fields.KeyActivities = props.resources.KeyActivitiesLabel;
        this.fields.Summary = props.resources.SummaryLabel;
        this.fields.Changes = props.resources.ChangesLabel;
        this.fields.Compensation = props.resources.CompensationLabel;

        try {
            const programmeFields = props.placeholders.find(ph => ph.replacementKey === 'Fields');
            if (programmeFields) {
                programmeFields.values.forEach(fieldItem => {
                    this.fields[fieldItem.field] = fieldItem.isActive ? fieldItem.display : '';
                });
            }
        } catch (error) {
            console.log('Error loading Programme Fields Placeholder!');
        }

        this.communicateToUser = props.globalResources.PM_NAME;
        this.documentType = 'Document313con';
        this.notifyingParty = props.workflowItem.notifyingParty;
        this.replyingParty = props.workflowItem.replyingParty;


        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;
        this.replyPeriod = props.workflowItem.replyPeriod;
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;

        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<ProgrammeList
                        {...this.props}
                        fields={this.fields}
                        programmeReplyEnabled={this.programmeReplyEnabled}
                        
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                    />}
                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                            <ProgrammeCreate
                                {...this.props}
                                fields={this.fields}
                                programmeReplyEnabled={this.programmeReplyEnabled}
                                title={this.notificationName}
                                
                                communicateToUser={this.communicateToUser}
                                replyPeriod={this.replyPeriod}
                            />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    exact
                    element={<ProgrammeDetail
                        {...this.props}
                        fields={this.fields}
                        programmeReplyEnabled={this.programmeReplyEnabled}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                        documentType={this.documentType}
                        replyPeriod={this.replyPeriod}
                        communicateToUser={this.communicateToUser}
                        notifyUserFilter={workflowItem.notifyUserFilter}
                    />}
                />
            </Routes>
        );
    }
};




export default injectIntl(ProgrammeRouter);