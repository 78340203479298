import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { LayoutSplashScreen } from "../../../../../_metronic";
import * as projectDuck from '../../../../store/ducks/project.duck';
import ProjectOverview from './ProjectOverview';
import * as projectsCrud from '../../../../crud/projects.crud';
import SecondAsideMenu from '../../../../partials/layout/SecondAsideMenu';
import Spinner from '../../../../partials/layout/Spinner';
import { SidedPage, SidedPageLeft, SidedPageRight } from '../../../../partials/content/SidedPage';
import { withSnackbar } from 'notistack';
import { injectIntl } from "react-intl";
import { withRouter } from '../../../../shared/hoc/withRouter';
import Protector from "../../../../router/Protector/Protector";


class Project extends React.Component {

  componentDidMount() {
    this.props.fetchStart(this.props.params.projectid);
  }

  imageChangeHandler = (event) => {
    projectsCrud.setProjectPicture(this.props.project.projectid, event.target.files[0])
      .then(result => {
        this.props.enqueueSnackbar('Picture uploaded successfully', { variant: 'success', })
        this.props.fetchStart(this.props.params.projectid);
      })
      .catch(err => {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      });

  }
  render() {

    const secondAsideMenuConfig = {
      RootPath: '/admin/projects/' + this.props.params.projectid,
      MenuItems: [
        { text: this.props.intl.formatMessage({ id: "PROJECT.MENU.OVERVIEW" }), icon: 'home', to: '/overview' },
        { text: this.props.intl.formatMessage({ id: "PROJECT.MENU.BACK" }), icon: 'back', toExact: '/admin/projects' }
      ]
    }

    if (!this.props.project) {
      return (<Spinner />);
    }
    return (
      <>
        <SidedPage>
          <SidedPageLeft>
            <SecondAsideMenu config={secondAsideMenuConfig} >
              {/* <ImageEditor src={this.props.project.profile} fileChangeHandler={this.imageChangeHandler} /> */}
            </SecondAsideMenu>
          </SidedPageLeft>
          <SidedPageRight>
            <Suspense fallback={<LayoutSplashScreen />}>
              <Routes caseSensitive>
                <Route path="/" element={<Navigate to="overview" />} sensitive />
                <Route path="/overview" element={<Protector><ProjectOverview /></Protector>} sensitive />
                <Route path="*" element={<Navigate replace to="/contracts" />} />
              </Routes>
            </Suspense>
          </SidedPageRight>
        </SidedPage>
      </>
    );
  }
}


const mapStateToProps = store => ({
  project: store.project.project,
});


const mapDispatchToProps = {
  fetchStart: projectDuck.actions.fetchStart,
  reloadProjectPicture: projectDuck.actions.reloadPicture
}


export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Project))));
