import {placeholderTypes} from "../../Types/Placeholder";
const placeholders = [
    {
        replacementKey: 'Resources', values:
            [
                { id: 1, isActive: true, field: 'Note', display: 'Further to our consultation, you are instructed to carry out the following' },
                { id: 2, isActive: true, field: 'DescriptionLabel', display: 'Detailed description' },
                { id: 3, isActive: true, field: 'ItemsListLabel', display: 'Items taken from price list' },
                { id: 4, isActive: true, field: 'TotalListPriceLabel', display: 'Price list total' },
                { id: 5, isActive: true, field: 'CompensationDescriptionLabel', display: 'Items assessed in the same way as a compensation event' },
                { id: 6, isActive: true, field: 'TotalAssessedLabel', display: 'Assessed list total' },
                { id: 7, isActive: true, field: 'TotalPriceLabel', display: 'Total of prices' },
                { id: 8, isActive: true, field: 'StartDateLabel', display: 'Start Date' },
                { id: 9, isActive: true, field: 'CompletionDateLabel', display: 'Completion Date' },
                { id: 10, isActive: true, field: 'DelayAmountLabel', display: 'Amount of delay damages for the late completion' },
                { id: 11, isActive: true, field: 'DelayPaymentPeriodLabel', display: 'Delay damages calculation period' },
                { id: 12, isActive: true, field: 'WarningBanner', display: 'An Order is not issued after the end of the service period.' },
                { id: 13, isActive: true, field: 'RequestForQuoteNote', display: 'You are requested to provide a quotation for a proposed order by $date' },
                { id: 14, isActive: true, field: 'QuoteNoteRequestForQuote', display: 'We submit our quotation for request for quote $reference', skipDefaultLoadingIfMissing: true },
                { id: 15, isActive: true, field: 'RequestForQuoteDescription', display: 'Items assessed in the same way as a request for quote' },
                { id: 16, isActive: true, field: 'CertifiedStartDateLabel', display: 'Certified Start Date' },
                { id: 17, isActive: true, field: 'CertifiedCompletionDateLabel', display: 'Certified Completion Date' }
            ],
        type:placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'ActionReminders', values:
            [
                { id: 1, isActive: true, field: 'PmRfqAQ', display: 'Rfq awaiting quotes' },
                { id: 2, isActive: true, field: 'PmRfqARQ', display: 'Rfq awaiting revised quotes' },
                { id: 3, isActive: true, field: 'PmRfqAPMRQ', display: 'Rfq awaiting response to quotes' },
                { id: 4, isActive: true, field: 'PmRfqAPMA', display: 'Rfq awaiting $PM_ABBREVIATION Assessment' },
                { id: 5, isActive: true, field: 'replydraftpmcequotessupplier', display: '$SUPPLIER_NAME Quotation' },
                { id: 6, isActive: true, field: 'draftconrfqquotation', display: 'RFQ Quotation' }
            ],
        type:placeholderTypes.ACTION_REMINDERS
    },
    {
        replacementKey: 'Fields', values:
            [
                { id: 1, isActive: true, field: 'Description', display: 'Detailed description' },
                { id: 2, isActive: true, field: 'ItemsList', display: 'Items taken from price list' },
                { id: 3, isActive: true, field: 'TotalListPrice', display: 'Price list total' },
                { id: 4, isActive: false, field: 'CompensationDescription', display: 'Items assessed in the same way as a compensation event' },
                { id: 5, isActive: false, field: 'TotalAssessed', display: 'Assessed list total' },
                { id: 6, isActive: true, field: 'TotalPrice', display: 'Total of prices' },
                { id: 7, isActive: true, field: 'StartDate', display: 'Start Date' },
                { id: 8, isActive: true, field: 'CompletionDate', display: 'Completion Date' },
                { id: 9, isActive: true, field: 'DelayAmount', display: 'Amount of delay damages for the late completion of the task' },
                { id: 10, isActive: true, field: 'DelayPaymentPeriod', display: 'Delay damages calculation period' },
                { id: 11, isActive: true, field: 'Comments', display: 'Comments' },
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'RFQRelationEnabled', values:
            [
                { id: 1, isActive: false, field: 'RFQRelationEnabled', display: 'Active-Inactive state indicates whether the RFQ can be attached in Task Order communications' },
            ],
        isToggle: true,
        fieldRequired: true,
        type:placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'RFQStatuses', values:
            [
                { id: 1, isActive: true, field: 'APMA', display: 'Quote Not Accepted - $PM_ABBREVIATION to make own Assessment' },
                { id: 2, isActive: true, field: 'ARQ', display: 'Quote Not Accepted - $SUPPLIER_NAME to resubmit' },
                { id: 3, isActive: true, field: 'AM', display: '$PM_ABBREVIATION Assessment Made' },
                { id: 4, isActive: true, field: 'QA', display: 'Quote Accepted' },
                { id: 5, isActive: true, field: 'APMRQ', display: 'Awaiting $PM_ABBREVIATION Response to quote' },
                { id: 6, isActive: true, field: 'APMR', display: 'Awaiting $PM_ABBREVIATION Response to notice' },
                { id: 7, isActive: true, field: 'AQ', display: 'Awaiting Quote' },
                { id: 8, isActive: true, field: 'NC', display: 'Not Communicated' },
                { id: 9, isActive: true, field: 'R', display: 'Rejected' },
                { id: 10, isActive: true, field: 'DNG', display: 'Proposed order will not be given' },
            ],
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'QuoteStatuses', values:
            [
                { id: 1, isActive: true, field: '0', display: 'Not Accepted - $SUPPLIER_NAME to resubmit' },
                { id: 2, isActive: true, field: '1', display: 'Accepted' },
                { id: 3, isActive: true, field: '2', display: 'Awaiting Response' },
                { id: 4, isActive: true, field: '3', display: 'Not Accepted - $PM_ABBREVIATION to make assessment' },
                { id: 5, isActive: true, field: '4', display: 'Not Accepted - Other quote approved' },
                { id: 6, isActive: true, field: '5', display: 'Not Accepted - Order will not be given' },
                { id: 7, isActive: true, field: 'none', display: 'Not Communicated' },
            ],
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'QuoteRfqImpactOpts', values:
            [
                { id: 1, isActive: true, field: 'noeffect', display: 'There is no effect on the Accepted Programme' },
                { id: 2, isActive: true, field: 'mitigated', display: 'There is an effect on the Accepted Programme but this has been mitigated by measures as detailed' },
                { id: 3, isActive: true, field: 'notmitigated', display: 'There is an effect on the Accepted Programme which we are unable to mitigate' },

            ],
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'RfqReplyProceedEnabled', values:
            [
                { id: 1, isActive: false, field: 'RfqReplyProceedEnabled', display: 'RFQ Quote Reply field proceed options Enabled/Disabled' },
            ],
        isToggle: true,
        fieldRequired: true,
        type:placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'QuoteFields', values:
            [
                { id: 1, isActive: true, field: 'Description', display: 'Detailed description' },
                { id: 2, isActive: true, field: 'ItemsList', display: 'Items taken from price list' },
                { id: 3, isActive: true, field: 'TotalListPrice', display: 'Price list total' },
                { id: 4, isActive: false, field: 'RequestForQuoteDescription', display: 'Items assessed in the same way as a compensation event' },
                { id: 5, isActive: false, field: 'TotalAssessed', display: 'Assessed list total' },
                { id: 6, isActive: true, field: 'TotalPrice', display: 'Total of prices' },
                { id: 7, isActive: true, field: 'StartDate', display: 'Start Date' },
                { id: 8, isActive: true, field: 'CompletionDate', display: 'Completion Date' },
                { id: 9, isActive: false, field: 'DelayAmount', display: 'Amount of delay damages for the late completion of the task' },
                { id: 10, isActive: false, field: 'DelayPaymentPeriod', display: 'Delay damages calculation period' },
                { id: 11, isActive: true, field: 'Comments', display: 'Comments' },
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'RFQFields', values:
            [
                { id: 1, isActive: false, field: 'StartDate', display: 'Start Date' },
                { id: 2, isActive: false, field: 'CompletionDate', display: 'Completion Date' },
                { id: 3, isActive: false, field: 'DelayAmount', display: 'Amount of delay damages for the late completion of the task' },
                { id: 4, isActive: false, field: 'DelayPaymentPeriod', display: 'Delay damages calculations period' }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'RFQRelationRequired', values:
            [
                { id: 1, isActive: false, field: 'RFQRelationRequired', display: 'Active-Inactive state indicates whether a Task Order can be issued without selecting an associated RFQ' },
            ],
        isToggle: true,
        fieldRequired: true,
        type:placeholderTypes.TOGGLE_SWITCH
    }
];

export default placeholders;