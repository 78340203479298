import React from 'react';
import NotifyControl from './NotifyControl';
import { Typography } from "@mui/material";

class NotifyArea extends React.Component {
    render() {
        return (
            <div style={{ marginTop: 20 }}>
                <Typography style={{ marginLeft: 16 }} variant="h6" component="div" gutterBottom>
                    {this.props.title}
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '10px' }}>
                    {
                        this.props.items.map((item, index) => (
                            <NotifyControl
                                key={item.actionCode}
                                contractid={this.props.contractid}
                                warningCount={this.props.contractDashboard[item.actionCode]}
                                item={item} />
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default NotifyArea;