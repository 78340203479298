import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
//import thunk from 'redux-thunk';
import * as applicationDuck from "./ducks/application.duck";

import { rootReducer, rootSaga } from "./rootDuck";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    console.log('Unhandled global error detected for SAGA', error);
  }
}
);


let enhancedMiddleware;
if (process.env.REACT_APP_ENVIRONMENT === 'DEV') {
  enhancedMiddleware = composeEnhancers(applyMiddleware(sagaMiddleware)); //,thunk
} else {
  enhancedMiddleware = applyMiddleware(sagaMiddleware); //,thunk
}


const store = createStore(rootReducer, enhancedMiddleware);

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store, null, () => { store.dispatch({ type: applicationDuck.actionTypes.APPLICATION_LOADED }) });

sagaMiddleware.run(rootSaga);

export default store;
