import React, { Component } from "react";
import Common from '../shared/common';
import { withRouter } from '../shared/hoc/withRouter';
import { FormattedMessage } from "react-intl";
import moment from 'moment';
import { SelectBox } from 'devextreme-react';
import * as contractOtherPriceCrud from '../crud/contractOtherPrice.crud';
import { withSnackbar } from 'notistack';
import { injectIntl } from "react-intl";

const isSecondaryPricesEnabled = process.env.REACT_APP_SECONDARY_PRICES_ENABLED === "true";

const spanStyle = {
  whiteSpace: 'normal',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  paddingBottom: 0
};

const spanStyleViewRegister = {
  whiteSpace: 'normal',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  paddingBottom: 0,
  fontWeight: 400
};



function calculateChangeRate(numerator, denominator) {
  if ((denominator === 0 || denominator === null) && (numerator > 0)) {
    return '100%';
  } else if (numerator === null || numerator === 0 || denominator === null || denominator === 0) {
    return '0%';
  } else {
    const changeRate = (((numerator) + 0) / (denominator + 0)) * 100;
    return Common.FormatNum(changeRate) + '%'
  }
}

class ContractPrice extends Component {
  state = {
    priceChanges: []
  }
  componentDidMount() {
    this.setNewState(this.props.dashboardCurrencyCode);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dashboardCurrencyCode !== this.props.dashboardCurrencyCode) {
      this.setNewState(this.props.dashboardCurrencyCode)
    }
  }
  setNewState = async (currencyCode) => {
    const newState = await this.getState(currencyCode);
    this.setState({ ...newState });
  }
  getState = async (currencyCode) => {
    try {

      if (currencyCode === this.props.contract.currency) {
        const defaultState = this.getDefaultState();
        return defaultState;
      } else {
        const selectedCurrencyOption = this.props.currencyOptions.find(pr => pr.currency === currencyCode);
        const { data: currencyChangeSummary } = await contractOtherPriceCrud.getContractOtherPriceChangeSummary(selectedCurrencyOption.contractotherpriceid);
        const currencyState = this.getCurrencyState(currencyChangeSummary, selectedCurrencyOption);
        return currencyState;
      }
    }
    catch (err) {
      this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
    }
  };
  getDefaultState = () => {
    const priceChanges = [];
    const { workflowsQA } = this.props.contractTemplate;

    priceChanges.push({
      type: "INITIAL_PRICE",
      label: this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.INITIAL_PRICE' }),
      amount: this.props.contract.contractvalue,
    });

    /////////ORDER GROUP///////
    //PM Task Orders
    let wf = workflowsQA[38];
    if (wf) {
      priceChanges.push({
        type: "PRICE_CHANGE",
        label: wf.groupTitle,
        amount: this.props.contractDashboard.taskordercost,
        link: wf.paths.defaultPath
      });
    }

    //Project Orders
    wf = workflowsQA[72];
    if (wf) {
      priceChanges.push({
        type: "PRICE_CHANGE",
        label: wf.groupTitle,
        amount: this.props.contractDashboard.projectordercost,
        link: wf.paths.defaultPath
      });
    }

    ///IF Order Workflows exist add Group Sum & Horizontal Line
    if (priceChanges.length > 1) {
      let orderGroupAmountSum = priceChanges
        .filter(x => x.type === "PRICE_CHANGE" || x.type === "INITIAL_PRICE")
        .reduce((total, wfItem) => total + (wfItem.amount ? wfItem.amount : 0), 0);
      priceChanges.push({
        type: "SUM",
        label: this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.NEW_PRICE' }),
        amount: orderGroupAmountSum,
      });
    }

    /////////OTHER GROUP///////
    //All CEN Workflows that might effect Price
    wf = workflowsQA[7] || workflowsQA[5] || workflowsQA[6] || workflowsQA[37] || workflowsQA[40] || workflowsQA[67] || workflowsQA[75] || workflowsQA[76] || workflowsQA[46];
    if (wf) {
      //Implemented or Master CEN Register
      const masterCenRegisterWF = workflowsQA[13] || workflowsQA[12];
      priceChanges.push({
        type: "PRICE_CHANGE",
        label: wf.groupTitle,
        amount: this.props.contractDashboard.compensationeventcost,
        link: masterCenRegisterWF?.paths?.defaultPath
      });
    }

    //Price Assessment WF
    wf = workflowsQA[51];
    if (wf) {
      priceChanges.push({
        type: "PRICE_CHANGE",
        label: wf.title,
        amount: this.props.contractDashboard.compensationeventpriceassessmentcost,
        link: wf.paths.defaultPath
      });
    }

    //Submission with Quote (Proposal)
    wf = workflowsQA[19];
    if (wf) {
      priceChanges.push({
        type: "PRICE_CHANGE",
        label: wf.groupTitle,
        amount: this.props.contractDashboard.supplierproposalcost,
        link: wf.paths.defaultPath
      });
    }

    //Assessment
    wf = workflowsQA[77];
    if (wf) {
      priceChanges.push({
        type: "PRICE_CHANGE",
        label: wf.groupTitle,
        amount: this.props.contractDashboard.pmassessmentcost,
        link: wf.paths.defaultPath
      });
    }

    let currentPriceSum = 0;
    ///IF Order Workflows exist add Group Sum & Horizontal Line
    if (priceChanges.length > 0) {
      currentPriceSum = priceChanges
        .filter(x => x.type === "PRICE_CHANGE" || x.type === "INITIAL_PRICE")
        .reduce((total, wfItem) => total + (wfItem.amount ? wfItem.amount : 0), 0);
      priceChanges.push({
        type: "SUM",
        label: this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.CURRENT_PRICE' }),
        amount: currentPriceSum,
      });
    }

    ///Price Change Section
    if (priceChanges.length > 0) {
      let allPriceChanges = priceChanges
        .filter(x => x.type === "PRICE_CHANGE")
        .reduce((total, wfItem) => total + (wfItem.amount ? wfItem.amount : 0), 0);
      priceChanges.push({
        type: "PRICE_CHANGE_SUM",
        label: this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.PRICE_CHANGE' }),
        amount: allPriceChanges,
      });

      priceChanges.push({
        type: "CHANGE_RATE",
        label: this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.CHANGE_RATE' }),
        text: calculateChangeRate(allPriceChanges, currentPriceSum),
      });
      priceChanges.push({
        type: "SEPERATOR",
      });
    }

    ///Additional Forecast INFO
    wf = workflowsQA[52];
    if (wf) {
      priceChanges.push({
        type: "FORECAST_INFO",
        label: this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.FORECASTCOSTTOCOMPLETE' }),
        text: this.props.contractDashboard.forecastcost ? Common.FormatCurrencyNum(this.props.contractDashboard.forecastcost, this.props.contract.currency) : '-',
        text2: this.props.contractDashboard.forecastdate ? moment(this.props.contractDashboard.forecastdate).format('L').replace(/\//g, '.') : '-'
      });
    }

    const defaultState = {
      currencyCode: this.props.contract.currency,
      priceChanges
    }
    return defaultState;
  }
  getCurrencyState = (currencyChangeSummary, selectedSecondaryPrice) => {
    const priceChanges = [];
    const { workflowsQA } = this.props.contractTemplate;



    priceChanges.push({
      type: "INITIAL_PRICE",
      label: this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.INITIAL_PRICE' }),
      amount: selectedSecondaryPrice.originalprice,
    });
    const cenGroupModels = ["Quotation","CompensationEvent"];
    const allCenGroupChanges = currencyChangeSummary
      .filter(x => cenGroupModels.includes(x.modelname));

    const allOtherChanges = currencyChangeSummary
      .filter(x => !cenGroupModels.includes(x.modelname));

    //Master Quote or Implemented or Master CEN Register
    const masterCenRegisterWF = workflowsQA[11] || workflowsQA[13] || workflowsQA[12];
    //All CEN Workflows that have Quotation  7-5-6-37-40-67-75-76-46
    let wf = workflowsQA[7] || workflowsQA[5] || workflowsQA[6] || workflowsQA[37] || workflowsQA[40] || workflowsQA[67] || workflowsQA[75] || workflowsQA[76] || workflowsQA[46];
    if (wf) {
      const quoteChangeAmount = allCenGroupChanges.reduce((total, item) => total + (item.changeamount ? item.changeamount : 0), 0);
      priceChanges.push({
        type: "PRICE_CHANGE",
        label: wf.groupTitle,
        amount: quoteChangeAmount,
        link: masterCenRegisterWF?.paths?.defaultPath
      });
    }
    //CEN Assessment
    wf = workflowsQA[8];
    if (wf) {
      const wfChangeAmount = allOtherChanges.filter(x => x.workflowid === 8).reduce((total, item) => total + (item.changeamount ? item.changeamount : 0), 0);
      priceChanges.push({
        type: "PRICE_CHANGE",
        label: wf.title,
        amount: wfChangeAmount,
        link: wf.paths.defaultPath
      });
    }


    //SUBMISSION WITH QUOTE(PROPOSAL)
    wf = workflowsQA[19];
    if (wf) {
      const wfChangeAmount = allOtherChanges.filter(x => x.workflowid === 19).reduce((total, item) => total + (item.changeamount ? item.changeamount : 0), 0);
      priceChanges.push({
        type: "PRICE_CHANGE",
        label: wf.title,
        amount: wfChangeAmount,
        link: wf.paths.defaultPath
      });
    }

    //ASSESSMENTS
    wf = workflowsQA[77];
    if (wf) {
      const wfChangeAmount = allOtherChanges.filter(x => x.workflowid === 77).reduce((total, item) => total + (item.changeamount ? item.changeamount : 0), 0);
      priceChanges.push({
        type: "PRICE_CHANGE",
        label: wf.title,
        amount: wfChangeAmount,
        link: wf.paths.defaultPath
      });
    }

    // allOtherChanges.forEach(summaryItem => {
    //   let wf = workflowsQA[summaryItem.workflowid];
    //   if (wf) {
    //     priceChanges.push({
    //       type: "PRICE_CHANGE",
    //       label: wf.title,
    //       amount: summaryItem.changeamount,
    //       link: wf.paths.defaultPath
    //     });
    //   }
    // });


    let currentPriceSum = 0;
    if (priceChanges.length > 0) {
      currentPriceSum = priceChanges
        .filter(x => x.type === "PRICE_CHANGE" || x.type === "INITIAL_PRICE")
        .reduce((total, wfItem) => total + (wfItem.amount ? wfItem.amount : 0), 0);
      priceChanges.push({
        type: "SUM",
        label: this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.CURRENT_PRICE' }),
        amount: currentPriceSum,
      });
    }

    ///Price Change Section
    if (priceChanges.length > 0) {
      let allPriceChanges = priceChanges
        .filter(x => x.type === "PRICE_CHANGE")
        .reduce((total, wfItem) => total + (wfItem.amount ? wfItem.amount : 0), 0);
      priceChanges.push({
        type: "PRICE_CHANGE_SUM",
        label: this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.PRICE_CHANGE' }),
        amount: allPriceChanges,
      });

      priceChanges.push({
        type: "CHANGE_RATE",
        label: this.props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.CHANGE_RATE' }),
        text: calculateChangeRate(allPriceChanges, currentPriceSum),
      });
    }

    const currencyState = {
      currencyCode: selectedSecondaryPrice.currency,
      priceChanges
    }
    return currencyState;
  }


  render() {
    return (
      <div className="kt-widget14">
        <div className="kt-widget14__header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h3 className="kt-widget14__title"><FormattedMessage id={"CONTRACT.DASHBOARD.CONTRACT_PRICE"} /></h3>
        </div>
        <div className="kt-widget12">
          <div className="kt-widget12__content">
            {
              this.state.priceChanges.map((item, index) => (
                <div key={`PriceChanges${index}`} >
                  {
                    item.type === "SEPERATOR" ? (
                      <div className="contractPriceSeperator" />
                    ) : (
                      <div className={"kt-widget12__item" + (item.type === "SUM" ? " kt-widget12__summary" : "")} style={{ marginBottom: '1rem' }}>
                        <div className="kt-widget12__info" style={{ flexGrow: 3 }}>
                          <span className="kt-widget12__desc" style={spanStyle}>{item.label}</span>
                        </div>
                        {
                          item.hasOwnProperty("amount") && (
                            <div className="kt-widget12__info" style={{ textAlign: 'right', flexGrow: 2 }}>
                              <span className="kt-widget12__value" style={spanStyle}>{
                                Common.FormatCurrencyNum(item.amount, this.state.currencyCode)
                              }</span>
                            </div>
                          )
                        }
                        {
                          item.hasOwnProperty("text") && (
                            <div className="kt-widget12__info" style={{ textAlign: 'right', flexGrow: 2 }}>
                              <span className="kt-widget12__value" style={spanStyle}>{
                                item.text
                              }</span>
                            </div>
                          )
                        }
                        {
                          item.hasOwnProperty("text2") && (
                            <div className="kt-widget12__info" style={{ textAlign: 'right', flexGrow: 2 }}>
                              <span className="kt-widget12__value" style={spanStyle}>{
                                item.text2
                              }</span>
                            </div>
                          )
                        }
                        {
                          item.hasOwnProperty("link") && (
                            <div className="kt-widget12__info" style={{ textAlign: 'right', flexGrow: 2 }}>
                              <span className="kt-widget12__desc" style={spanStyleViewRegister}><a onClick={() => { this.props.navigate(item.link); }} style={{ textDecoration: 'underline', fontSize: '1.1rem', paddingTop: '0.5rem' }}><FormattedMessage id="CONTRACT.DASHBOARD.VIEW" /></a></span>
                            </div>
                          )
                        }
                        {
                          ///EMPTY PLACEHOLDER IF THERE IS NO SECOND COLUMN
                          !item.hasOwnProperty("link") && !item.hasOwnProperty("text2") && (
                            <div className="kt-widget12__info" style={{ textAlign: 'right', flexGrow: 2 }}>
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withSnackbar(withRouter(ContractPrice)));
