import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import * as settingCrud from "../../../../crud/setting.crud";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import {
    Portlet,
    PortletBody,
    PortletHeader
} from "../../../../partials/content/Portlet";
import { Button } from "react-bootstrap";
import { withRouter } from '../../../../shared/hoc/withRouter';

class Detail extends Component {
    state = {
        settingDetails: null,
        configSettingDetails: null
    }

    componentDidMount() {
        this.fetchSettingDetails(this.props.params.settingId);
    }

    fetchSettingDetails = (id) => {
        settingCrud.getSystemSettingById(id)
            .then(response => {
                const settingDetails = response.data;                
                this.setState({ settingDetails });
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    submitHandler = (setting, setSubmitting) => {
        settingCrud.saveSystemSetting(setting)
            .then(() => {
                this.props.enqueueSnackbar('Setting successfully updated', { variant: 'success', });
                this.props.navigate('/admin/settings');
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                setSubmitting(false);
            });
    }

    renderToolbar = () => {
        const toolbarButtons = [];
        toolbarButtons.push((
            <Button key="1" onClick={() => this.props.navigate('/admin/settings')} variant="secondary" size="sm">
                <i className="fas fa-caret-square-left"></i>
                {'Back to Register'}
            </Button>
        ));

        return (
            <div style={{ display: 'flex', flexFlow: 'row-reverse', margin: "20px 0px" }}>
                {toolbarButtons}
            </div>
        );
    }

    render() {
        if (!this.state.settingDetails) {
            return (<PortletSpinner />);
        }
        
        const settingFormConfig = {
            buttonText: 'Update',
            fields: [
                {
                    label: 'Setting Details',
                    fields: [
                        { label: 'Name', dataField: 'name' },
                        { label: 'Description', dataField: 'description' },
                        { label: 'Enabled', dataField: 'enabled', editorType: 'dxSwitch', editorOptions: { switchedOffText: "No", switchedOnText: "Yes" } },
                        { label: 'BI Only', dataField: 'bi_only', editorType: 'dxSwitch', editorOptions: { switchedOffText: "No", switchedOnText: "Yes" } },
                        { label: 'Read Only', dataField: 'readonly', editorType: 'dxSwitch', editorOptions: { switchedOffText: "No", switchedOnText: "Yes" } },
                    ]
                },
            ]
        };

        const settings = [];
        
        this.state.settingDetails.settings.forEach(item => {
            switch (item.datatype) {
                case 'string':
                    settings.push({ label: item.key, dataField: item.key, editorOptions: { readOnly: true }, });
                    break;
                case 'int':
                    //settings.push({ label: item.name, dataField: item.value, editorType: 'dxNumberBox', editorOptions: { format: '#,##0.00', placeholder: '0.00' } });
                    settings.push({ label: item.key, dataField: item.key, editorOptions: { readOnly: true }, });
                    break;
                case 'bool':
                    settings.push({ label: item.key, dataField: item.key, editorType: 'dxSwitch', editorOptions: { switchedOffText: "No", switchedOnText: "Yes", readOnly: true } });
                    break;
                default:
                    break;
        }});

        const configSettingFormConfig = {
            fields: [
                {
                    label: 'Related Subsettings',
                    fields: settings
                },
            ]
        };

        const settingDetailMappedItem = {};

        this.state.settingDetails.settings.forEach(item => {
            switch (item.datatype) {
                case 'int':
                    settingDetailMappedItem[item.key] = parseInt(item.value);
                    break;
                case 'bool':
                    settingDetailMappedItem[item.key] = item.value === '1' ? true : false;
                    break;
                default:
                    settingDetailMappedItem[item.key] = item.value;
                    break;
        }});

        return (
            <Portlet>
              <PortletHeader title="Setting Details" toolbar={this.renderToolbar()}/>
              <PortletBody>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '10px' }}>
                  <BiDynamicForm
                    item={this.state.settingDetails}
                    config={settingFormConfig}
                    onSubmitHandler={this.submitHandler}
                  />
                </div>
                <div style={{ height: 20 }} />
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '10px' }}>
                  <BiDynamicForm
                    item={settingDetailMappedItem}
                    config={configSettingFormConfig}
                  />
                </div>
              </PortletBody>
            </Portlet>
          );
        }
    }

export default withRouter(withSnackbar(Detail));