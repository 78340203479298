import React from 'react';
import { confirm } from 'devextreme/ui/dialog';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import FilterBuilder from 'devextreme-react/filter-builder';
import { TextBox, Switch, SelectBox, NumberBox } from 'devextreme-react';
import { contractPartyTypes, contractRoleTypes, userFilterCategories } from "../../../../../../shared/lookup";
import ScrollView from 'devextreme-react/scroll-view';
//import DxEditorForm from '../../../../../../partials/layout/dxEditorForm';
import TemplateHelper from '../../../../../../Templates/TemplateHelper';
import Accordion, { Item as AccordionItem } from 'devextreme-react/accordion';
import OverrideResourcesGrid from '../OverrideResourcesGrid';
import { injectIntl } from "react-intl";
// import Form, { Item as FormItem, SimpleItem, GroupItem, RangeRule } from 'devextreme-react/form';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import Validator, {
    RequiredRule,
    PatternRule
} from 'devextreme-react/validator';

const partySelection = ["PM", "SUPPLIER", "SUPERVISOR", "CLIENT"]
// const emptyWorkflow = {}
// const emptyDefaultWorkflow = {
//     config: {}
// }

const userFilterFields = [
    {
        dataField: 'party',
        filterOperations: ["=", "<>"],
        lookup: {
            dataSource: contractPartyTypes,
            // valueExpr: 'value',
            // displayExpr: 'key',
        }
    },
    {
        dataField: 'rolepermissions',
        filterOperations: ["=", "<>"],
        lookup: {
            dataSource: contractRoleTypes,
            // valueExpr: 'value',
            // displayExpr: 'key',
        }
    },
];

const partyUserSelection = ["PARTY", "USER"];

const isIncludePdfJsonInNotificationEmailEnabled = process.env.REACT_APP_PDFJSON_IN_NOTIFICATION_EMAIL_ENABLED === 'true';

class WorkflowModal extends React.Component {
    constructor() {
        super();
        this.validatorRef = React.createRef();
        this.validatorName = 'WorkflowModalValidator';
        this.state = {
            workflow: null,
            defaultWF: null,
            changed: false
        };
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps.item !== this.props.item) {
            if (this.props.item) {
                this.setWorkflowItem();
            }
            else {
                this.setState({
                    workflow: null,
                    defaultWF: null
                });
            }

        }
    }


    getInitialFilterValue = (defaultFilter, templateFilter) => {
        //null or undefined
        if (defaultFilter == null) {
            //filter is not in use
            return null;
        }
        if (typeof templateFilter === 'undefined') {
            /// never saved load default filter
            return JSON.parse(defaultFilter);
        }
        if (templateFilter) {
            return JSON.parse(templateFilter);
        }
        else { return null; }

    }

    setWorkflowItem = () => {
        if (this.props.item) {
            const workflow = {
                ...this.props.item
            };
            if (this.props.item.userFilters) {
                workflow.userFilters = {}
                if (this.props.item.userFilters.notifyFilter) {
                    workflow.userFilters.notifyFilter = JSON.parse(this.props.item.userFilters.notifyFilter)
                }
                if (this.props.item.userFilters.accessFilter) {
                    workflow.userFilters.accessFilter = JSON.parse(this.props.item.userFilters.accessFilter)
                }
                if (this.props.item.userFilters.mainNotice) {
                    workflow.userFilters.mainNotice = {};
                    workflow.userFilters.mainNotice.createFilter = JSON.parse(this.props.item.userFilters.mainNotice.createFilter)
                    workflow.userFilters.mainNotice.viewDraftFilter = JSON.parse(this.props.item.userFilters.mainNotice.viewDraftFilter)
                    workflow.userFilters.mainNotice.communicateFilter = JSON.parse(this.props.item.userFilters.mainNotice.communicateFilter)
                }
                if (this.props.item.userFilters.replyNotice) {
                    workflow.userFilters.replyNotice = {};
                    workflow.userFilters.replyNotice.saveDraftReplyFilter = JSON.parse(this.props.item.userFilters.replyNotice.saveDraftReplyFilter)
                    workflow.userFilters.replyNotice.communicateReplyFilter = JSON.parse(this.props.item.userFilters.replyNotice.communicateReplyFilter)
                }
                if (this.props.item.userFilters.actionTab) {
                    workflow.userFilters.actionTab = {};
                    workflow.userFilters.actionTab.actionFilter = JSON.parse(this.props.item.userFilters.actionTab.actionFilter)
                }
                if (this.props.item.userFilters.quoteMainNotice) {
                    workflow.userFilters.quoteMainNotice = {};
                    workflow.userFilters.quoteMainNotice.createQuoteFilter = JSON.parse(this.props.item.userFilters.quoteMainNotice.createQuoteFilter)
                    workflow.userFilters.quoteMainNotice.communicateQuoteFilter = JSON.parse(this.props.item.userFilters.quoteMainNotice.communicateQuoteFilter)
                }
                if (this.props.item.userFilters.quoteReplyNotice) {
                    workflow.userFilters.quoteReplyNotice = {};
                    workflow.userFilters.quoteReplyNotice.saveQuoteDraftReplyFilter = JSON.parse(this.props.item.userFilters.quoteReplyNotice.saveQuoteDraftReplyFilter)
                    workflow.userFilters.quoteReplyNotice.communicateQuoteReplyFilter = JSON.parse(this.props.item.userFilters.quoteReplyNotice.communicateQuoteReplyFilter)
                }
                if (this.props.item.userFilters.quoteDecision) {
                    workflow.userFilters.quoteDecision = {};
                    workflow.userFilters.quoteDecision.decisionFilter = JSON.parse(this.props.item.userFilters.quoteDecision.decisionFilter)
                }
                if (this.props.item.userFilters.resolveActionTab) {
                    workflow.userFilters.resolveActionTab = {};
                    workflow.userFilters.resolveActionTab.resolveActionFilter = JSON.parse(this.props.item.userFilters.resolveActionTab.resolveActionFilter)
                }
            }
            const defaultWF = TemplateHelper.GetDefaultWorkflow(this.props.wfgid, workflow.id);
            this.setState({ workflow: workflow, defaultWF: defaultWF, changed: false });

        }
    }
    onClose = async () => {
        if (this.props.item) {
            if (this.state.changed) {
                let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}</div>`, this.props.intl.formatMessage({ id: 'GENERAL.WARNING.LEAVE_CHANGES_TITLE' }));
                if (!result) {
                    return;
                }
            }
            this.props.onClose();
        }
    }
    saveWorkflow = () => {
        if (!this.validateForm()) {
            return;
        }
        const saveObj = { ...this.state.workflow };
        if (this.state.workflow.userFilters) {
            saveObj.userFilters = {}
            if (this.state.workflow.userFilters.notifyFilter) {
                saveObj.userFilters.notifyFilter = JSON.stringify(this.state.workflow.userFilters.notifyFilter)
            }
            if (this.state.workflow.userFilters.accessFilter) {
                saveObj.userFilters.accessFilter = JSON.stringify(this.state.workflow.userFilters.accessFilter)
            }
            if (this.state.workflow.userFilters.mainNotice) {
                saveObj.userFilters.mainNotice = {};
                saveObj.userFilters.mainNotice.createFilter = JSON.stringify(this.state.workflow.userFilters.mainNotice.createFilter)
                saveObj.userFilters.mainNotice.viewDraftFilter = JSON.stringify(this.state.workflow.userFilters.mainNotice.viewDraftFilter)
                saveObj.userFilters.mainNotice.communicateFilter = JSON.stringify(this.state.workflow.userFilters.mainNotice.communicateFilter)
            }
            if (this.state.workflow.userFilters.replyNotice) {
                saveObj.userFilters.replyNotice = {};
                saveObj.userFilters.replyNotice.saveDraftReplyFilter = JSON.stringify(this.state.workflow.userFilters.replyNotice.saveDraftReplyFilter)
                saveObj.userFilters.replyNotice.communicateReplyFilter = JSON.stringify(this.state.workflow.userFilters.replyNotice.communicateReplyFilter)
            }
            if (this.state.workflow.userFilters.actionTab) {
                saveObj.userFilters.actionTab = {};
                saveObj.userFilters.actionTab.actionFilter = JSON.stringify(this.state.workflow.userFilters.actionTab.actionFilter)
            }
            if (this.state.workflow.userFilters.quoteMainNotice) {
                saveObj.userFilters.quoteMainNotice = {};
                saveObj.userFilters.quoteMainNotice.createQuoteFilter = JSON.stringify(this.state.workflow.userFilters.quoteMainNotice.createQuoteFilter)
                saveObj.userFilters.quoteMainNotice.communicateQuoteFilter = JSON.stringify(this.state.workflow.userFilters.quoteMainNotice.communicateQuoteFilter)
            }
            if (this.state.workflow.userFilters.quoteReplyNotice) {
                saveObj.userFilters.quoteReplyNotice = {};
                saveObj.userFilters.quoteReplyNotice.saveQuoteDraftReplyFilter = JSON.stringify(this.state.workflow.userFilters.quoteReplyNotice.saveQuoteDraftReplyFilter)
                saveObj.userFilters.quoteReplyNotice.communicateQuoteReplyFilter = JSON.stringify(this.state.workflow.userFilters.quoteReplyNotice.communicateQuoteReplyFilter)
            }
            if (this.state.workflow.userFilters.quoteDecision) {
                saveObj.userFilters.quoteDecision = {};
                saveObj.userFilters.quoteDecision.decisionFilter = JSON.stringify(this.state.workflow.userFilters.quoteDecision.decisionFilter)
            }
            if (this.state.workflow.userFilters.resolveActionTab) {
                saveObj.userFilters.resolveActionTab = {};
                saveObj.userFilters.resolveActionTab.resolveActionFilter = JSON.stringify(this.state.workflow.userFilters.resolveActionTab.resolveActionFilter)
            }
        }
        this.props.onClose(saveObj);
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        return validationResult.isValid;
    }
    render() {
        if (!this.state.workflow) {
            return null;
        }
        return (
            <Popup
                visible={this.props.item ? true : false}
                onHiding={this.onClose}
                dragEnabled={false}
                showCloseButton={false}
                hideOnOutsideClick={false}
                showTitle={true}
                title={this.state.workflow && this.state.workflow.id && this.state.workflow.id > 0 ? `${this.state.workflow.id} | ${this.state.defaultWF.config.description}` : ""}
                minWidth={750}
                width='50vh'
                height='80vh'
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="top"
                    location="after"
                    options={{ text: "Cancel", onClick: this.onClose }}>
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="top"
                    location="after"
                    disabled={this.props.readOnly || !this.state.changed}
                    options={{
                        text: this.state.workflow && this.state.workflow.id && this.state.workflow.id > 0 ? 'Update Workflow' : 'Add Workflow',
                        type: 'default',
                        stylingMode: this.state.changed ? "contained" : "outlined",
                        onClick: this.saveWorkflow
                    }}>
                </ToolbarItem>
                {/* style={{ height: '100%', overflow: 'auto' }} */}
                <ScrollView style={{ border: '1px solid #ccc', borderRadius: '10px' }} showScrollbar="always" useNative >
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 10px' }}>
                            <div>
                                <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                            </div>

                            {/* <div className="dx-field" style={{ marginTop: "10px" }}>
                            <div className="dx-field-label" style={{ width: "25%" }}>Workflow</div>
                            <div className="dx-field-value" style={{ width: "75%" }}>
                                <TextBox
                                    readOnly={true}
                                    placeholder="Workflow Name"
                                    value={this.state.defaultWF.config.description}
                                />
                            </div>
                        </div> */}
                            {/* <div className="dx-field" style={{ marginTop: "5px" }}>
                            <div className="dx-field-label" style={{ width: "25%" }}>Path</div>
                            <div className="dx-field-value" style={{ width: "75%" }}>
                                <TextBox
                                    readOnly={this.props.mode !== 'WORKFLOW'}
                                    placeholder="Exact URL path"
                                    value={this.state.workflow.path}
                                    onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, path: e.value } })}
                                />
                            </div>
                        </div> */}
                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                <div className="dx-field-label" style={{ width: "25%" }}>Menu Title</div>
                                <div className="dx-field-value" style={{ width: "75%" }}>
                                    <TextBox
                                        placeholder="As displayed on Menu"
                                        value={this.state.workflow.title}
                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, title: e.value }, changed: true })}
                                        readOnly={this.props.readOnly}
                                    >
                                        <Validator>
                                            <RequiredRule message='Menu Title is required' />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            {this.state.defaultWF.config.notifyPossible &&
                                (<div className="dx-field" style={{ marginTop: "5px" }}>
                                    <div className="dx-field-label" style={{ width: "25%" }}>Notification Name</div>
                                    <div className="dx-field-value" style={{ width: "75%" }}>
                                        <TextBox
                                            placeholder="Singular Notification Item Name, This Will Be Used To Refer to Item In Pages."
                                            value={this.state.workflow.notificationName}
                                            onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, notificationName: e.value }, changed: true })}
                                        >
                                        </TextBox>
                                    </div>
                                </div>)
                            }
                            <div className="dx-field" style={{ marginTop: "5px" }}>
                                <div className="dx-field-label" style={{ width: "25%" }}>Page Title</div>
                                <div className="dx-field-value" style={{ width: "75%" }}>
                                    <TextBox
                                        placeholder="If not configured will be sender party name followed by Notification name"
                                        value={this.state.workflow.pageTitle}
                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, pageTitle: e.value }, changed: true })}
                                        readOnly={this.props.readOnly}

                                    >
                                        {/* <Validator>
                                            <RequiredRule message='Page Title is required' />
                                        </Validator> */}
                                    </TextBox>
                                </div>
                            </div>
                            {this.state.workflow.abbreviation &&
                                (<div className="dx-field" style={{ marginTop: "5px" }}>
                                    <div className="dx-field-label" style={{ width: "25%" }}>Reference ID Prefix</div>
                                    <div className="dx-field-value" style={{ width: "75%" }}>
                                        <TextBox
                                            placeholder="Singular Item Reference ID Prefix, This Will Be Used To Render Unique Reference."
                                            value={this.state.workflow.abbreviation}
                                            onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, abbreviation: e.value }, changed: true })}
                                            readOnly={this.props.readOnly}
                                        />
                                    </div>
                                </div>)
                            }
                            {this.state.defaultWF.config.notifyPossible &&
                                (<div className="dx-field" style={{ marginTop: "5px" }}>
                                    <div className="dx-field-label" style={{ width: "25%" }}>Email Notify Enabled</div>
                                    <div className="dx-field-value" style={{ width: "75%" }}>
                                        <Switch
                                            style={{ float: 'left' }}
                                            defaultValue={this.state.workflow.notify}
                                            onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, notify: e.value }, changed: true })}
                                            readOnly={this.props.readOnly}
                                        />
                                    </div>
                                </div>)
                            }
                            {this.state.defaultWF.config.notifyPossible &&
                                (<div className="dx-field" style={{ marginTop: "5px" }}>
                                    <div className="dx-field-label" style={{ width: "25%" }}>Notice Attachments</div>
                                    <div className="dx-field-value" style={{ width: "75%" }}>
                                        <Switch
                                            style={{ float: 'left' }}
                                            defaultValue={this.state.workflow.notifyAttachments}
                                            onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, notifyAttachments: e.value }, changed: true })}
                                            readOnly={this.props.readOnly}
                                        />
                                    </div>
                                </div>)
                            }
                            {(this.state.defaultWF.config.configurableFinancialLimit && process.env.REACT_APP_FINANCIAL_LIMIT_ENABLED === 'true') &&
                                (<div className="dx-field" style={{ marginTop: "5px" }}>
                                    <div className="dx-field-label" style={{ width: "25%" }}>Financial Limit Enabled</div>
                                    <div className="dx-field-value" style={{ width: "75%" }}>
                                        <Switch
                                            style={{ float: 'left' }}
                                            defaultValue={this.state.workflow.financialLimitEnabled}
                                            onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, financialLimitEnabled: e.value }, changed: true })}
                                            readOnly={this.props.readOnly}
                                        />
                                    </div>
                                </div>)
                            }


                            {(this.state.defaultWF.config.configurableAssets && process.env.REACT_APP_ASSETS_ENABLED === 'true') &&
                                (<div className="dx-field" style={{ marginTop: "5px" }}>
                                    <div className="dx-field-label" style={{ width: "25%" }}>Asset Fields Enabled</div>
                                    <div className="dx-field-value" style={{ width: "75%" }}>
                                        <Switch
                                            style={{ float: 'left' }}
                                            defaultValue={this.state.workflow.assetFieldsEnabled}
                                            onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, assetFieldsEnabled: e.value }, changed: true })}
                                            readOnly={this.props.readOnly}
                                        />
                                    </div>
                                </div>)
                            }
                            {
                                this.state.defaultWF.config.replyPossible &&
                                (
                                    <>
                                        {this.state.defaultWF.config.configurableReplyTab &&
                                            (<div className="dx-field" style={{ marginTop: "5px" }}>
                                                <div className="dx-field-label" style={{ width: "25%" }}>Reply Enabled</div>
                                                <div className="dx-field-value" style={{ width: "75%" }}>
                                                    <Switch
                                                        style={{ float: 'left' }}
                                                        defaultValue={this.state.workflow.replyEnabled}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, replyEnabled: e.value }, changed: true })}
                                                        readOnly={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>)
                                        }
                                        {this.state.workflow.replyEnabled &&
                                            (<div className="dx-field" style={{ marginTop: "5px" }}>
                                                <div className="dx-field-label" style={{ width: "25%" }}>Reply Attachments</div>
                                                <div className="dx-field-value" style={{ width: "75%" }}>
                                                    <Switch
                                                        style={{ float: 'left' }}
                                                        defaultValue={this.state.workflow.replyAttachments}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, replyAttachments: e.value }, changed: true })}
                                                        readOnly={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>)
                                        }
                                        {(this.state.defaultWF.config.replyPeriodOptions && this.state.workflow.replyEnabled) &&
                                            (
                                                <div className="dx-field" style={{ marginTop: "5px" }}>
                                                    <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Reply Period Options</div>
                                                    <div className="dx-field-value" style={{ width: "75%" }}>
                                                        <SelectBox
                                                            dataSource={this.state.defaultWF.config.replyPeriodOptions}
                                                            value={this.state.workflow.replyPeriodOption}
                                                            onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, replyPeriodOption: e.value }, changed: true })}
                                                            readOnly={this.props.readOnly}
                                                        />
                                                    </div>
                                                </div>

                                            )
                                        }
                                        {(this.state.workflow.replyEnabled && this.state.defaultWF.config.replyPeriodOptions && this.state.workflow.replyPeriodOption === 'CUSTOM') &&
                                            (
                                                <div className="dx-field" style={{ marginTop: "5px" }}>
                                                    <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Custom Reply Period</div>
                                                    <div className="dx-field-value" style={{ width: "75%" }}>
                                                        <NumberBox
                                                            width={200}
                                                            format="#,##0 day(s)"
                                                            placeholder="0 day(s)"
                                                            value={this.state.workflow.replyPeriod}
                                                            onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, replyPeriod: e.value }, changed: true })}
                                                            readOnly={this.props.readOnly}
                                                            max={365}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {(this.state.workflow.replyEnabled && this.state.defaultWF.config.configurableReplyingParty) &&
                                            (
                                                <div className="dx-field" style={{ marginTop: "5px" }}>
                                                    <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Replying Party</div>
                                                    <div className="dx-field-value" style={{ width: "75%" }}>
                                                        <SelectBox
                                                            dataSource={partySelection}
                                                            value={this.state.workflow.replyingParty}
                                                            onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, replyingParty: e.value }, changed: true })}
                                                            readOnly={this.props.readOnly}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </>
                                )
                            }

                            {(this.state.defaultWF.config.quotationPossible) &&
                                (
                                    <>
                                        <div className="dx-field" style={{ marginTop: "5px" }}>
                                            <div className="dx-field-label" style={{ width: "20%" }}>Quotation Name</div>
                                            <div className="dx-field-value" style={{ width: "80%" }}>
                                                <TextBox
                                                    placeholder="Singular Notification Item Name, This Will Be Used To Refer to Item In Pages."
                                                    value={this.state.workflow.quoteNotificationName}
                                                    onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, quoteNotificationName: e.value }, changed: true })}
                                                    readOnly={this.props.readOnly}
                                                />
                                            </div>
                                        </div>
                                        <div className="dx-field" style={{ marginTop: "5px" }}>
                                            <div className="dx-field-label" style={{ width: "20%" }}>Quote Reference ID Prefix</div>
                                            <div className="dx-field-value" style={{ width: "80%" }}>
                                                <TextBox
                                                    placeholder="Singular Item Reference ID Prefix, This Will Be Used To Render Unique Reference."
                                                    value={this.state.workflow.quoteReferencePrefix}
                                                    onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, quoteReferencePrefix: e.value }, changed: true })}
                                                    readOnly={this.props.readOnly}
                                                />
                                            </div>
                                        </div>
                                        <div className="dx-field" style={{ marginTop: "5px" }}>
                                            <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Quoting Party</div>
                                            <div className="dx-field-value" style={{ width: "75%" }}>
                                                <SelectBox
                                                    dataSource={partySelection}
                                                    value={this.state.workflow.quotingParty}
                                                    onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, quotingParty: e.value }, changed: true })}
                                                    readOnly={this.props.readOnly}
                                                />
                                            </div>
                                        </div>
                                        <div className="dx-field" style={{ marginTop: "5px" }}>
                                            <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Quoting Replying Party</div>
                                            <div className="dx-field-value" style={{ width: "75%" }}>
                                                <SelectBox
                                                    dataSource={partySelection}
                                                    value={this.state.workflow.quoteReplyingParty}
                                                    onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, quoteReplyingParty: e.value }, changed: true })}
                                                    readOnly={this.props.readOnly}
                                                />
                                            </div>
                                        </div>
                                    </>

                                )
                            }
                            {(this.state.defaultWF.config.notifyPossible || this.state.defaultWF.config.replyPossible) &&
                                (
                                    <div style={{ marginBottom: "15px" }}>
                                        <div className="dx-field" style={{ marginTop: "5px" }}>
                                            <div className="dx-field-label" style={{ width: "25%" }}>Include Company Name</div>
                                            <div className="dx-field-value" style={{ width: "75%" }}>
                                                <Switch
                                                    style={{ float: 'left' }}
                                                    defaultValue={this.state.workflow.form_IncludeCompanyName}
                                                    onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, form_IncludeCompanyName: e.value }, changed: true })}
                                                    readOnly={this.props.readOnly}
                                                />
                                            </div>
                                        </div>
                                        {this.state.workflow.form_IncludeCompanyName &&
                                            (
                                                <div className="dx-field" style={{ marginTop: "5px" }}>
                                                    <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Company Name</div>
                                                    <div className="dx-field-value" style={{ width: "75%" }}>
                                                        <SelectBox
                                                            dataSource={partyUserSelection}
                                                            value={this.state.workflow.form_CompanyName}
                                                            onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, form_CompanyName: e.value }, changed: true })}
                                                            readOnly={this.props.readOnly}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className="dx-field" style={{ marginTop: "5px" }}>
                                            <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Company Logo</div>
                                            <div className="dx-field-value" style={{ width: "75%" }}>
                                                <SelectBox
                                                    dataSource={partyUserSelection}
                                                    value={this.state.workflow.form_CompanyLogo}
                                                    onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, form_CompanyLogo: e.value }, changed: true })}
                                                    readOnly={this.props.readOnly}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )
                            }
                            {isIncludePdfJsonInNotificationEmailEnabled &&
                                (
                                    <div className="dx-field" style={{ marginTop: "5px" }}>
                                        <div className="dx-field-label" style={{ width: "25%" }}>Email full content</div>
                                        <div className="dx-field-value" style={{ width: "75%" }}>
                                            <Switch
                                                style={{ float: 'left' }}
                                                defaultValue={this.state.workflow.includePdfjson}
                                                onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, includePdfjson: e.value }, changed: true })}
                                                readOnly={this.props.readOnly}
                                            />
                                        </div>
                                    </div>
                                )
                            }

                            {/* <DropDownButton
                            text="Format Filters to Defaults"
                            icon="undo"
                            dropDownOptions={{ width: 300 }}
                            // items={this.state.filterFormatOptions}
                            onItemClick={this.onFormatFilterClick}
                        /> */}
                            <Accordion collapsible={true} multiple={true} >

                                {
                                    (this.state.defaultWF.config.userFilterCategories || []).includes(userFilterCategories.ACCESS) && (
                                        <AccordionItem title="Access User Filter" >
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Access</div>
                                                <div className="dx-field-value" style={{ width: "80%", padding: '10px', border: '1px solid #ccc', borderRadius: '2px' }}>
                                                    <FilterBuilder
                                                        fields={userFilterFields}
                                                        value={this.state.workflow.userFilters.accessFilter}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, userFilters: { ...this.state.workflow.userFilters, accessFilter: e.value } }, changed: true })}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItem>
                                    )
                                }
                                {
                                    (this.state.defaultWF.config.userFilterCategories || []).includes(userFilterCategories.MAIN_NOTICE) && (

                                        <AccordionItem title={"Main Notice User Filter"} >
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Create</div>
                                                <div className="dx-field-value" style={{ width: "80%", padding: '10px', border: '1px solid #ccc', borderRadius: '2px' }}>
                                                    <FilterBuilder
                                                        fields={userFilterFields}
                                                        value={this.state.workflow.userFilters.mainNotice.createFilter}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, userFilters: { ...this.state.workflow.userFilters, mainNotice: { ...this.state.workflow.userFilters.mainNotice, createFilter: e.value } } }, changed: true })}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>


                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>View Draft</div>
                                                <div className="dx-field-value" style={{ width: "80%", padding: '10px', border: '1px solid #ccc', borderRadius: '2px' }}>
                                                    <FilterBuilder
                                                        fields={userFilterFields}
                                                        value={this.state.workflow.userFilters.mainNotice.viewDraftFilter}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, userFilters: { ...this.state.workflow.userFilters, mainNotice: { ...this.state.workflow.userFilters.mainNotice, viewDraftFilter: e.value } } }, changed: true })}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>

                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Communicate</div>
                                                <div className="dx-field-value" style={{ width: "80%", padding: '10px', border: '1px solid #ccc', borderRadius: '2px' }}>
                                                    <FilterBuilder
                                                        fields={userFilterFields}
                                                        value={this.state.workflow.userFilters.mainNotice.communicateFilter}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, userFilters: { ...this.state.workflow.userFilters, mainNotice: { ...this.state.workflow.userFilters.mainNotice, communicateFilter: e.value } } }, changed: true })}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItem>


                                    )
                                }
                                {
                                    (this.state.workflow.replyEnabled && (this.state.defaultWF.config.userFilterCategories || []).includes(userFilterCategories.REPLY_NOTICE)) && (
                                        <AccordionItem title="Reply Notice User Filter">
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Reply Draft</div>
                                                <div className="dx-field-value" style={{ width: "80%", padding: '10px', border: '1px solid #ccc', borderRadius: '2px' }}>
                                                    <FilterBuilder
                                                        fields={userFilterFields}
                                                        value={this.state.workflow.userFilters.replyNotice.saveDraftReplyFilter}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, userFilters: { ...this.state.workflow.userFilters, replyNotice: { ...this.state.workflow.userFilters.replyNotice, saveDraftReplyFilter: e.value } } }, changed: true })}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Reply Communicate</div>
                                                <div className="dx-field-value" style={{ width: "80%", padding: '10px', border: '1px solid #ccc', borderRadius: '2px' }}>
                                                    <FilterBuilder
                                                        fields={userFilterFields}
                                                        value={this.state.workflow.userFilters.replyNotice.communicateReplyFilter}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, userFilters: { ...this.state.workflow.userFilters, replyNotice: { ...this.state.workflow.userFilters.replyNotice, communicateReplyFilter: e.value } } }, changed: true })}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItem>
                                    )
                                }
                                {
                                    (this.state.defaultWF.config.userFilterCategories || []).includes(userFilterCategories.ACTION_TAB) && (
                                        <AccordionItem title="Action Tab User Filter">
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Action</div>
                                                <div className="dx-field-value" style={{ width: "80%", padding: '10px', border: '1px solid #ccc', borderRadius: '2px' }}>
                                                    <FilterBuilder
                                                        fields={userFilterFields}
                                                        value={this.state.workflow.userFilters.actionTab.actionFilter}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, userFilters: { ...this.state.workflow.userFilters, actionTab: { ...this.state.workflow.userFilters.actionTab, actionFilter: e.value } } }, changed: true })}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItem>
                                    )
                                }
                                {
                                    (this.state.defaultWF.config.userFilterCategories || []).includes(userFilterCategories.QUOTATION) && (
                                        <AccordionItem title="Quotation User Filter">
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Quote Create</div>
                                                <div className="dx-field-value" style={{ width: "80%", padding: '10px', border: '1px solid #ccc', borderRadius: '2px' }}>
                                                    <FilterBuilder
                                                        fields={userFilterFields}
                                                        value={this.state.workflow.userFilters.quoteMainNotice.createQuoteFilter}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, userFilters: { ...this.state.workflow.userFilters, quoteMainNotice: { ...this.state.workflow.userFilters.quoteMainNotice, createQuoteFilter: e.value } } }, changed: true })}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Quote Communicate</div>
                                                <div className="dx-field-value" style={{ width: "80%", padding: '10px', border: '1px solid #ccc', borderRadius: '2px' }}>
                                                    <FilterBuilder
                                                        fields={userFilterFields}
                                                        value={this.state.workflow.userFilters.quoteMainNotice.communicateQuoteFilter}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, userFilters: { ...this.state.workflow.userFilters, quoteMainNotice: { ...this.state.workflow.userFilters.quoteMainNotice, communicateQuoteFilter: e.value } } }, changed: true })}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItem>
                                    )
                                }
                                {
                                    (this.state.defaultWF.config.userFilterCategories || []).includes(userFilterCategories.QUOTE_REPLY) && (
                                        <AccordionItem title="Quotation Reply User Filter">
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Quote Reply Draft</div>
                                                <div className="dx-field-value" style={{ width: "80%", padding: '10px', border: '1px solid #ccc', borderRadius: '2px' }}>
                                                    <FilterBuilder
                                                        fields={userFilterFields}
                                                        value={this.state.workflow.userFilters.quoteReplyNotice.saveQuoteDraftReplyFilter}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, userFilters: { ...this.state.workflow.userFilters, quoteReplyNotice: { ...this.state.workflow.userFilters.quoteReplyNotice, saveQuoteDraftReplyFilter: e.value } } }, changed: true })}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Quote Reply Communicate</div>
                                                <div className="dx-field-value" style={{ width: "80%", padding: '10px', border: '1px solid #ccc', borderRadius: '2px' }}>
                                                    <FilterBuilder
                                                        fields={userFilterFields}
                                                        value={this.state.workflow.userFilters.quoteReplyNotice.communicateQuoteReplyFilter}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, userFilters: { ...this.state.workflow.userFilters, quoteReplyNotice: { ...this.state.workflow.userFilters.quoteReplyNotice, communicateQuoteReplyFilter: e.value } } }, changed: true })}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItem>
                                    )
                                }
                                {
                                    (this.state.defaultWF.config.notifyPossible && this.state.workflow.notify && (this.state.defaultWF.config.userFilterCategories || []).includes(userFilterCategories.NOTIFY)) && (
                                        <AccordionItem title="Notify User Filter">
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Email Notify</div>
                                                <div className="dx-field-value" style={{ width: "80%", padding: '10px', border: '1px solid #ccc', borderRadius: '2px' }}>
                                                    <FilterBuilder
                                                        fields={userFilterFields}
                                                        value={this.state.workflow.userFilters.notifyFilter}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, userFilters: { ...this.state.workflow.userFilters, notifyFilter: e.value } }, changed: true })}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItem>
                                    )
                                }
                                {
                                    this.state.defaultWF.config.allowOverrideResources && (
                                        <AccordionItem title="Override Global Resources" >
                                            <OverrideResourcesGrid
                                                overrideResourcesList={this.state.workflow.overrideResources}
                                                onValueChanged={(newwList) => this.setState({ workflow: { ...this.state.workflow, overrideResources: newwList }, changed: true })}
                                                readOnly={this.props.readOnly}
                                            />
                                        </AccordionItem>
                                    )
                                }
                                {
                                    (this.state.defaultWF.config.userFilterCategories || []).includes(userFilterCategories.RESOLVE_ACTION_TAB) && (
                                        <AccordionItem title="Resolve Action Tab User Filter">
                                            <div className="dx-field" style={{ marginTop: "10px" }}>
                                                <div className="dx-field-label" style={{ width: "20%", whiteSpace: "normal" }}>Resolve Action</div>
                                                <div className="dx-field-value" style={{ width: "80%", padding: '10px', border: '1px solid #ccc', borderRadius: '2px' }}>
                                                    <FilterBuilder
                                                        fields={userFilterFields}
                                                        value={this.state.workflow.userFilters.resolveActionTab.resolveActionFilter}
                                                        onValueChanged={(e) => this.setState({ workflow: { ...this.state.workflow, userFilters: { ...this.state.workflow.userFilters, resolveActionTab: { ...this.state.workflow.userFilters.resolveActionTab, resolveActionFilter: e.value } } }, changed: true })}
                                                        disabled={this.props.readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionItem>
                                    )
                                }
                            </Accordion>

                        </div>
                    </ValidationGroup>
                </ScrollView>

            </Popup >
        );
    }
}

export default injectIntl(WorkflowModal);