const template = {
    name: "Default NEC4 ECSC v2.6 08/05/2024",
    isSystemDefault: true,
    isGlobal: true,
    companies: [],
    resources: [
      {
        id: 1,
        type: "TEXT",
        key: "SUPPLIER_NAME",
        value: "Contractor"
      },
      {
        id: 2,
        type: "TEXT",
        key: "SUPPLIER_ABBREVIATION",
        value: "CON"
      },
      {
        id: 3,
        type: "TEXT",
        key: "PM_NAME",
        value: "Client"
      },
      {
        id: 4,
        type: "TEXT",
        key: "PM_ABBREVIATION",
        value: "CLI"
      },
      {
        id: 7,
        type: "TEXT",
        key: "CLIENT_NAME",
        value: "Client"
      },
      {
        id: 8,
        type: "NUMBER",
        key: "SUPPLIER_REPLY_PERIOD",
        value: 7
      },
      {
        id: 9,
        type: "NUMBER",
        key: "PM_REPLY_PERIOD",
        value: 7
      },
      {
        id: 10,
        type: "NUMBER",
        key: "QUOTE_DURATION",
        value: 7
      },
      {
        id: 13,
        type: "NUMBER",
        key: "REQUOTE_DURATION",
        value: 7
      },
      {
        id: 11,
        type: "NUMBER",
        key: "QUOTE_RESPONSE",
        value: 14
      },
      {
        id: 14,
        type: "NUMBER",
        key: "REQUOTE_RESPONSE",
        value: 14
      },
      {
        id: 12,
        type: "NUMBER",
        key: "VARIATION_RESPONSE",
        value: 7
      },
      {
        id: 15,
        type: "NUMBER",
        key: "PAYMENT_PERIOD",
        value: 21
      },
      {
        id: 17,
        type: "NUMBER",
        key: "QUOTE_DURATION_PRIOR_REPLY",
        value: 7
      },
      {
        id: 10,
        type: "TEXT",
        key: "QUOTE_DEEMED_ACCEPTANCE_STARTS",
        value: "OFF"
      },
      {
        id: 25,
        type: "NUMBER",
        key: "QUOTE_DEEMED_ACCEPTANCE",
        value: 14
      },
      {
        id: 8,
        type: "TEXT",
        key: "CLIENT_ABBREVIATION",
        value: "CLI"
      },
      {
        id: 5,
        type: "TEXT",
        key: "SUPERVISOR_NAME",
        value: "Client"
      },
      {
        id: 6,
        type: "TEXT",
        key: "SUPERVISOR_ABBREVIATION",
        value: "CLI"
      }
    ],
    workflowGroups: [
      {
        id: 101,
        title: "Early Warnings",
        workflows: [
          {
            id: 1,
            title: "$PM_NAME Early Warnings",
            notificationName: "Early Warning",
            abbreviation: "$PM_ABBREVIATION-Warning-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "PM",
            replyingParty: "SUPPLIER",
            actioningParty: "PM",
            resolveActioningParty: "PM",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            replyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              actionTab: {
                actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
              resolveActionTab: {
                resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              }
            }
          },
          {
            id: 2,
            title: "$SUPPLIER_NAME Early Warnings",
            notificationName: "Early Warning",
            abbreviation: "$SUPPLIER_ABBREVIATION-Warning-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "PM_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            actioningParty: "PM",
            resolveActioningParty: "PM",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            replyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              actionTab: {
                actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]",
              resolveActionTab: {
                resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              }
            }
          },
          {
            id: 3,
            title: "Early Warning Register",
            notificationName: "Early Warning",
            abbreviation: "Warning",
            overrideResources: null,
            userFilters: {
              accessFilter: "null"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "MeetingRequiredLabel",
                display: "Is early warning meeting required?",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "NotificationClause",
                display: "You are given early warning of a matter which could",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "RiskItemsLabel",
                display: "You are given early warning of a matter which could",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Pm",
                display: "$PM_ABBREVIATION Early Warnings awaiting reply",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "Supplier",
                display: "$SUPPLIER_NAME Early Warnings awaiting reply",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "RiskTypes",
            values: [
              {
                id: 1,
                isActive: true,
                field: "riskincreaseprice",
                display: "increase the total of the Prices",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "riskdelaycompletion",
                display: "delay Completion",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "riskimpairperformance",
                display: "impair the performance of the works in use",
                overrideResources: null
              },
              {
                id: 5,
                isActive: true,
                field: "riskincreasecontractorcost",
                display: "increase the $SUPPLIER_NAME's total cost",
                overrideResources: null
              },
              {
                id: 6,
                isActive: false,
                field: "riskincreasedefinedcost",
                display: "Increase the Defined Cost of expending any Provisional Sums",
                overrideResources: null
              },
              {
                id: 7,
                isActive: false,
                field: "riskaffectsafety",
                display: "Adversely affect the $SUPPLIER_NAME’s ability to safely carry out the works",
                overrideResources: null
              },
              {
                id: 8,
                isActive: false,
                field: "riskcontractbreach",
                display: "Constitute or cause a breach of any of the $SUPPLIER_NAME’s obligations under this contract",
                overrideResources: null
              },
              {
                id: 9,
                isActive: false,
                field: "riskaffectmodel",
                display: "Adversely affect the creation or use of the Information Model",
                overrideResources: null
              },
              {
                id: 3,
                isActive: false,
                field: "riskdelaymilestone",
                display: "Delay meeting a Key Date",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "MeetingOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "0",
                display: "Meeting not required",
                overrideResources: null
              },
              {
                id: 2,
                isActive: false,
                field: "1",
                display: "Meeting required - next scheduled early warning meeting",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "2",
                display: "Meeting required - you are instructed to attend a meeting as below",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "RiskTypesSupplier",
            values: [
              {
                id: 2,
                isActive: true,
                field: "riskincreaseprice",
                display: "increase the total of the Prices",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "riskdelaycompletion",
                display: "delay Completion",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "riskimpairperformance",
                display: "impair the performance of the works in use",
                overrideResources: null
              },
              {
                id: 6,
                isActive: true,
                field: "riskincreasecontractorcost",
                display: "increase the $SUPPLIER_NAME's total cost",
                overrideResources: null
              },
              {
                id: 7,
                isActive: false,
                field: "riskincreasedefinedcost",
                display: "Increase the $PM_NAME's total cost",
                overrideResources: null
              },
              {
                id: 5,
                isActive: false,
                field: "riskdelaymilestone",
                display: "Delay meeting a Key Date",
                overrideResources: null
              },
              {
                id: 8,
                isActive: false,
                field: "riskaffectsafety",
                display: "Adversely affect the $SUPPLIER_NAME’s ability to safely carry out the works",
                overrideResources: null
              },
              {
                id: 9,
                isActive: false,
                field: "riskcontractbreach",
                display: "Constitute or cause a breach of any of the $SUPPLIER_NAME’s obligations under this contract",
                overrideResources: null
              },
              {
                id: 10,
                isActive: false,
                field: "riskaffectmodel",
                display: "Adversely affect the creation or use of the Information Model",
                overrideResources: null
              }
            ]
          }
        ]
      },
      {
        id: 102,
        title: "Instructions",
        workflows: [
          {
            id: 4,
            title: "$PM_NAME Instruction",
            notificationName: "Instruction",
            abbreviation: "$PM_ABBREVIATION-Instruction-",
            notify: true,
            replyEnabled: false,
            assetFieldsEnabled: false,
            replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "PM",
            replyingParty: "SUPPLIER",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            replyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "InstructionTypes",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "14.2 to change the Scope",
                overrideResources: null
              },
              {
                id: 9,
                isActive: true,
                field: "",
                display: "14.3 to correct a mistake in the Price List",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "",
                display: "21.3 to remove a person",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "",
                display: "30.4 to stop or not start work",
                overrideResources: null
              },
              {
                id: 10,
                isActive: true,
                field: "",
                display: "30.4 to start or restart work",
                overrideResources: null
              },
              {
                id: 12,
                isActive: true,
                field: "",
                display: "43.1 to change the Scope, Prices and Completion Date after accepting a proposal not to correct a Defect",
                overrideResources: null
              },
              {
                id: 7,
                isActive: true,
                field: "",
                display: "70.1 on how to deal with an object of value or historical interest",
                overrideResources: null
              },
              {
                id: 8,
                isActive: true,
                field: "",
                display: "Other instruction",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "Resources",
            values: []
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "PM",
                display: "$PM_NAME Instructions awaiting reply",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "Client",
                display: "$CLIENT_NAME Instructions awaiting reply",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "Supervisor",
                display: "$SUPERVISOR_NAME Instructions awaiting reply",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ReplyOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Accepted",
                display: "Accepted",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "Accepted with comments",
                display: "Accepted with comments",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "Not Accepted",
                display: "Not Accepted",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "Reply Not Required",
                display: "Reply Not Required",
                overrideResources: null
              }
            ]
          }
        ]
      },
      {
        id: 103,
        title: "Compensation Events",
        workflows: [
          {
            id: 5,
            title: "$PM_NAME Compensation Events",
            notificationName: "Compensation Event",
            abbreviation: "$PM_ABBREVIATION-Compensation-",
            notify: true,
            financialLimitEnabled: false,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            quotingParty: "SUPPLIER",
            quoteReplyingParty: "PM",
            quoteNotificationName: "Quote",
            quoteReferencePrefix: "Quote-",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              quoteMainNotice: {
                createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              quoteReplyNotice: {
                saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 37,
            title: "$SUPPLIER_NAME Compensation Events",
            notificationName: "Compensation Event",
            abbreviation: "$SUPPLIER_ABBREVIATION-CEN-",
            notify: true,
            financialLimitEnabled: false,
            assetFieldsEnabled: false,
            notifyingParty: "SUPPLIER",
            quotingParty: "SUPPLIER",
            quoteReplyingParty: "PM",
            quoteNotificationName: "Quote",
            quoteReferencePrefix: "Quote-",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              quoteMainNotice: {
                createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              quoteReplyNotice: {
                saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
            }
          },
          {
            id: 8,
            title: "$PM_NAME Assessments",
            notificationName: "Assessment",
            abbreviation: "$PM_ABBREVIATION-Assessment-",
            notify: true,
            financialLimitEnabled: false,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
            }
          },
          {
            id: 11,
            title: "Master Quote Register",
            overrideResources: null,
            userFilters: {
              accessFilter: "null"
            }
          },
          {
            id: 12,
            title: "Master CE Register",
            overrideResources: null,
            userFilters: {
              accessFilter: "null"
            }
          },
          {
            id: 13,
            title: "Implemented CE Register",
            overrideResources: null,
            userFilters: {
              accessFilter: "null"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 2,
                isActive: true,
                field: "PmAssumptionNote",
                display: "You are instructed under clause 61.2 to submit a quotation. ",
                overrideResources: null
              },
              {
                id: 1,
                isActive: true,
                field: "PmAssumptionLabel",
                display: "Your quotation is to be based on the following assumptions:",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "SupplierDescNote",
                display: "You are notified of the following event as a compensation event",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "ReplyAcceptedCommentLabel",
                display: "You are notified that the event is a compensation event.",
                overrideResources: null
              },
              {
                id: 5,
                isActive: true,
                field: "ReplyRejectedCommentLabel",
                display: "Details",
                overrideResources: null
              },
              {
                id: 6,
                isActive: true,
                field: "ReplyAcceptedAssumptionLabel",
                display: "The quotation is to be based on the following assumptions",
                overrideResources: null
              },
              {
                id: 7,
                isActive: true,
                field: "QuoteNote",
                display: "We submit our quotation for compensation event",
                overrideResources: null
              },
              {
                id: 8,
                isActive: true,
                field: "ExtensionPMLabel",
                display: "We require an extension to the contractual time to produce a compensation event quotation response as detailed below for the following reasons",
                overrideResources: null
              },
              {
                id: 9,
                isActive: true,
                field: "ExtensionSupplierLabel",
                display: "We require an extension to the contractual time to produce a compensation event quotation as detailed below for the following reasons",
                overrideResources: null
              },
              {
                id: 10,
                isActive: true,
                field: "AssessmentDetailLabel",
                display: "Details",
                overrideResources: null
              },
              {
                id: 11,
                isActive: true,
                field: "ProposedInstructionNote",
                display: "You are instructed to provide a quotation by $date",
                overrideResources: null
              },
              {
                id: 12,
                isActive: true,
                field: "ProposedInstructionLabel",
                display: "The quotation is to be based on the following assumptions",
                overrideResources: null
              },
              {
                id: 13,
                isActive: true,
                field: "ReplyRejectedOptionsLabel",
                display: "You are notified that the event is not a compensation event as it",
                overrideResources: null
              },
              {
                id: 14,
                isActive: true,
                field: "QuoteCostEffectLabel",
                display: "Change to the Prices",
                overrideResources: null
              },
              {
                id: 15,
                isActive: true,
                field: "QuoteDateEffectLabel",
                display: "Delay to the completion date",
                overrideResources: null
              },
              {
                id: 16,
                isActive: true,
                field: "QuoteDaysEffectLabel",
                display: "The length of time that the completion date is delayed",
                overrideResources: null
              },
              {
                id: 17,
                isActive: true,
                field: "AssessmentCostEffectLabel",
                display: "Cost effect on total of the prices",
                overrideResources: null
              },
              {
                id: 18,
                isActive: true,
                field: "AssessmentDaysEffectLabel",
                display: "Number of days effect to the completion date",
                overrideResources: null
              },
              {
                id: 19,
                isActive: true,
                field: "SupplierCompensationReplyWarningBanner",
                display: "If the $PM_NAME does not accept the $SUPPLIER_NAME's quotation, clause 62.2 requires the $PM_NAME to include details of the PM_NAME's own assessment. In order for the price and/or date changes to be reflected in the contract dashboard  $PM_NAME Assessment will need to be issued separately.",
                overrideResources: null
              },
              {
                id: 20,
                isActive: true,
                field: "QuoteReplyWarningBanner",
                display: "If the $PM_NAME does not accept the $SUPPLIER_NAME's quotation, clause 62.2 requires the $PM_NAME to include details of the PM_NAME's own assessment. In order for the price and/or date changes to be reflected in the contract dashboard  $PM_NAME Assessment will need to be issued separately.",
                overrideResources: null
              },
              {
                id: 21,
                isActive: true,
                field: "PmCompensationWarningBanner",
                display: "",
                overrideResources: null
              },
              {
                id: 22,
                isActive: true,
                field: "SupplierClaimWithQuotationWarningBanner",
                display: "Clause 61.2 states that if the Contractor notifies the compensation event it submits the quotation with the notification. Please submit your quotation separately but remember to do so immediately after notifying a compensation event.",
                overrideResources: null
              },
              {
                id: 23,
                isActive: true,
                field: "QuoteProgrammeAttachLabel",
                display: "Are you attaching a programme showing the effect against the completion date with this quotation?",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "PmCompensationAQ",
                display: "CE's notified by $PM_NAME awaiting quotes",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "PmCompensationARQ",
                display: "CE's notified by $PM_NAME awaiting revised quotes",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "PmCompensationAPMRQ",
                display: "CE's notified by $PM_NAME awaiting response to quotes",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "PmCompensationAPMA",
                display: "CE's notified by $PM_NAME awaiting $PM_NAME Assessment",
                overrideResources: null
              },
              {
                id: 5,
                isActive: true,
                field: "SupCompensationAQ",
                display: "Compensation Events notified by $SUPPLIER_NAME awaiting quotes",
                overrideResources: null
              },
              {
                id: 6,
                isActive: true,
                field: "SupCompensationARQ",
                display: "Compensation Events notified by $SUPPLIER_NAME awaiting revised quotes",
                overrideResources: null
              },
              {
                id: 7,
                isActive: true,
                field: "SupCompensationAPMR",
                display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_NAME response",
                overrideResources: null
              },
              {
                id: 8,
                isActive: true,
                field: "SupCompensationAPMRQ",
                display: "Compensation Events notified by $SUPPLIER_NAME awaiting response to quotes",
                overrideResources: null
              },
              {
                id: 9,
                isActive: true,
                field: "SupCompensationAPMA",
                display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_NAME Assessment",
                overrideResources: null
              },
              {
                id: 10,
                isActive: true,
                field: "PmProposedAQ",
                display: "Proposed changes awaiting quotes",
                overrideResources: null
              },
              {
                id: 11,
                isActive: true,
                field: "PmProposedARQ",
                display: "Proposed changes awaiting revised quotes",
                overrideResources: null
              },
              {
                id: 12,
                isActive: true,
                field: "PmProposedAPMRQ",
                display: "Proposed changes awaiting response to quotes",
                overrideResources: null
              },
              {
                id: 13,
                isActive: true,
                field: "PmProposedAPMA",
                display: "Proposed changes awaiting $PM_NAME Assessment",
                overrideResources: null
              },
              {
                id: 14,
                isActive: true,
                field: "PmExtension",
                display: "$PM_NAME Quote Response Extensions awaiting Acceptance",
                overrideResources: null
              },
              {
                id: 15,
                isActive: true,
                field: "SupExtension",
                display: "$SUPPLIER_NAME Quote Extensions awaiting Acceptance",
                overrideResources: null
              },
              {
                id: 16,
                isActive: true,
                field: "SupClaimAQ",
                display: "CE's notified by $SUPPLIER_NAME awaiting quotes",
                overrideResources: null
              },
              {
                id: 17,
                isActive: true,
                field: "SupClaimARQ",
                display: "CE's notified by $SUPPLIER_NAME awaiting revised quotes",
                overrideResources: null
              },
              {
                id: 18,
                isActive: true,
                field: "SupClaimAPMRQ",
                display: "CE's notified by $SUPPLIER_NAME awaiting response to quotes",
                overrideResources: null
              },
              {
                id: 19,
                isActive: true,
                field: "SupClaimAPMA",
                display: "CE's notified by $SUPPLIER_NAME awaiting $PM_NAME Assessment",
                overrideResources: null
              },
              {
                id: 20,
                isActive: true,
                field: "SupClaimAPMR",
                display: "CE's notified by $SUPPLIER_NAME awaiting $PM_NAME response",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "CompensationClauses",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "60.1(1) An instruction to change the Scope",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "",
                display: "60.1(2) Client does not allow access to the Site",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "",
                display: "60.1(3) Client does not provide something that it is to provide",
                overrideResources: null
              },
              {
                id: 5,
                isActive: true,
                field: "",
                display: "60.1(4) An instruction to stop or not to start work",
                overrideResources: null
              },
              {
                id: 6,
                isActive: true,
                field: "",
                display: "60.1(5) Client does not work within the conditions stated in the Scope",
                overrideResources: null
              },
              {
                id: 7,
                isActive: true,
                field: "",
                display: "60.1(6) Client does not reply to a communication within the period required by the contract",
                overrideResources: null
              },
              {
                id: 8,
                isActive: true,
                field: "",
                display: "60.1(7) Client changes a decision",
                overrideResources: null
              },
              {
                id: 10,
                isActive: true,
                field: "",
                display: "60.1(8) Physical conditions encountered",
                overrideResources: null
              },
              {
                id: 11,
                isActive: true,
                field: "",
                display: "60.1(9) Weather conditions encountered",
                overrideResources: null
              },
              {
                id: 12,
                isActive: true,
                field: "",
                display: "60.1(10) A notification of a correction to an assumption stated about a compensation event",
                overrideResources: null
              },
              {
                id: 13,
                isActive: true,
                field: "",
                display: "60.1(11) Prevention event",
                overrideResources: null
              },
              {
                id: 14,
                isActive: true,
                field: "",
                display: "60.1(12) Client instruction to correct a mistake in the Price List",
                overrideResources: null
              },
              {
                id: 16,
                isActive: true,
                field: "",
                display: "1.5 Suspension of performance",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ClaimClauses",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "60.1(1) An instruction to change the Scope",
                overrideResources: [
                  {
                    key: "QUOTE_DURATION",
                    value: "0"
                  }
                ]
              },
              {
                id: 3,
                isActive: true,
                field: "",
                display: "60.1(2) Client does not allow access to the Site",
                overrideResources: [
                  {
                    key: "QUOTE_DURATION",
                    value: "0"
                  }
                ]
              },
              {
                id: 4,
                isActive: true,
                field: "",
                display: "60.1(3) Client does not provide something that it is to provide",
                overrideResources: [
                  {
                    key: "QUOTE_DURATION",
                    value: "0"
                  }
                ]
              },
              {
                id: 5,
                isActive: true,
                field: "",
                display: "60.1(4) An instruction to stop or not to start work",
                overrideResources: [
                  {
                    key: "QUOTE_DURATION",
                    value: "0"
                  }
                ]
              },
              {
                id: 6,
                isActive: true,
                field: "",
                display: "60.1(5) Client does not work within the conditions stated in the Scope",
                overrideResources: [
                  {
                    key: "QUOTE_DURATION",
                    value: "0"
                  }
                ]
              },
              {
                id: 7,
                isActive: true,
                field: "",
                display: "60.1(6) Client does not reply to a communication within the period required by the contract",
                overrideResources: [
                  {
                    key: "QUOTE_DURATION",
                    value: "0"
                  }
                ]
              },
              {
                id: 8,
                isActive: true,
                field: "",
                display: "60.1(7) Client changes a decision",
                overrideResources: [
                  {
                    key: "QUOTE_DURATION",
                    value: "0"
                  }
                ]
              },
              {
                id: 9,
                isActive: true,
                field: "",
                display: "60.1(8) Physical conditions encountered",
                overrideResources: [
                  {
                    key: "QUOTE_DURATION",
                    value: "0"
                  }
                ]
              },
              {
                id: 10,
                isActive: true,
                field: "",
                display: "60.1(9) Weather conditions encountered",
                overrideResources: [
                  {
                    key: "QUOTE_DURATION",
                    value: "0"
                  }
                ]
              },
              {
                id: 11,
                isActive: true,
                field: "",
                display: "60.1(10) A notification of a correction to an assumption stated about a compensation event",
                overrideResources: [
                  {
                    key: "QUOTE_DURATION",
                    value: "0"
                  }
                ]
              },
              {
                id: 12,
                isActive: true,
                field: "",
                display: "60.1(11) Prevention event",
                overrideResources: [
                  {
                    key: "QUOTE_DURATION",
                    value: "0"
                  }
                ]
              },
              {
                id: 13,
                isActive: true,
                field: "",
                display: "60.1(12) Client instruction to correct a mistake in the Price List",
                overrideResources: [
                  {
                    key: "QUOTE_DURATION",
                    value: "0"
                  }
                ]
              },
              {
                id: 14,
                isActive: true,
                field: "",
                display: "1.5 Suspension of performance",
                overrideResources: [
                  {
                    key: "QUOTE_DURATION",
                    value: "0"
                  }
                ]
              }
            ]
          },
          {
            replacementKey: "ReplyRejectOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "faultpscp",
                display: "arises from a fault of the $SUPPLIER_NAME",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "notexpectnothappen",
                display: "has not happened and is not expected to happen",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "noteffectcostcompletion",
                display: "has no effect upon the Defined Cost or upon Completion",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "statedcontract",
                display: "is not one of the compensation events stated in the contract",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "CompensationStatuses",
            values: [
              {
                id: 1,
                isActive: true,
                field: "APMA",
                display: "Quote Not Accepted - $PM_NAME to make own assessment",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "ARQ",
                display: "Quote Not Accepted - $SUPPLIER_NAME to resubmit",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "AM",
                display: "$PM_NAME Assessment Made",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "QA",
                display: "Quote Accepted",
                overrideResources: null
              },
              {
                id: 5,
                isActive: true,
                field: "APMRQ",
                display: "Awaiting $PM_NAME response to quote",
                overrideResources: null
              },
              {
                id: 6,
                isActive: true,
                field: "APMR",
                display: "Awaiting $PM_NAME response to CEN",
                overrideResources: null
              },
              {
                id: 7,
                isActive: true,
                field: "AQ",
                display: "Awaiting Quote",
                overrideResources: null
              },
              {
                id: 8,
                isActive: true,
                field: "NC",
                display: "Not Communicated",
                overrideResources: null
              },
              {
                id: 9,
                isActive: true,
                field: "R",
                display: "Not a Compensation Event",
                overrideResources: null
              },
              {
                id: 10,
                isActive: true,
                field: "DNG",
                display: "Decision Not Given",
                overrideResources: null
              },
              {
                id: 11,
                isActive: true,
                field: "ADAQ",
                display: "Awaiting $PM_NAME response to quote",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "QuoteStatuses",
            values: [
              {
                id: 1,
                isActive: true,
                field: "0",
                display: "Not Accepted - Revised quotation required",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "1",
                display: "Accepted",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "2",
                display: "Awaiting Response",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "3",
                display: "Not Accepted - $PM_NAME to make assessment",
                overrideResources: null
              },
              {
                id: 5,
                isActive: true,
                field: "4",
                display: "Not Accepted - Other quote approved",
                overrideResources: null
              },
              {
                id: 6,
                isActive: true,
                field: "5",
                display: "Not Accepted - Not a compensation event",
                overrideResources: null
              },
              {
                id: 7,
                isActive: true,
                field: "none",
                display: "Not Communicated",
                overrideResources: null
              },
              {
                id: 8,
                isActive: true,
                field: "6",
                display: "Awaiting Acceptance",
                overrideResources: null
              },
              {
                id: 9,
                isActive: true,
                field: "7",
                display: "Deemed Accepted",
                overrideResources: null
              },
              {
                id: 10,
                isActive: true,
                field: "8",
                display: "Acknowledged",
                overrideResources: null
              },
              {
                id: 11,
                isActive: true,
                field: "9",
                display: "Not Accepted",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "InstructionReplyProceedOpts",
            values: [
              {
                id: 1,
                isActive: true,
                field: "61.1",
                display: "61.1 Consultant is instructed to proceed with works",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "61.2",
                display: "61.2 Consultant is instructed to NOT proceed with works until instructed",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "Fields",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Assumption",
                display: "The quotation is to be based on the following assumptions",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "InstructionReplyProceedEnabled",
            values: [
              {
                id: 1,
                isActive: false,
                field: "InstructionReplyProceedEnabled",
                display: "Proposed instructions Quote Reply field proceed options Enabled/Disabled",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "EWRelationEnabled",
            values: [
              {
                id: 1,
                isActive: true,
                field: "EWRelationEnabled",
                display: "Active-Inactive state indicates whether the EWN can be attached in CEN communications",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "RelatedCompensationText",
            values: [
              {
                id: 1,
                isActive: true,
                field: "RelatedCompensationText",
                display: "Regarding the aforementioned instruction, you are hereby notified of a compensation event under clause 61.1",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "CompensationEventsAcceptanceOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "1",
                display: "Is a compensation event",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "0",
                display: "Is not a compensation event",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "QuoteProgrammeImpactOpts",
            values: [
              {
                id: 1,
                isActive: true,
                field: "noeffect",
                display: "There is no effect on the completion date",
                overrideResources: null
              },
              {
                id: 2,
                isActive: false,
                field: "mitigated",
                display: "There is an effect on the completion date but this has been mitigated by measures as detailed",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "notmitigated",
                display: "There is an effect on the completion date",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "SupplierCWQReplyOpts",
            values: [
              {
                id: 1,
                isActive: true,
                field: "1",
                display: "QuoteStatus_1",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "0",
                display: "QuoteStatus_0",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "3",
                display: "QuoteStatus_3",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "5",
                display: "QuoteStatus_5",
                overrideResources: null
              }
            ]
          }
        ]
      },
      {
        id: 104,
        title: "Programme",
        workflows: [
          {
            id: 14,
            title: "$SUPPLIER_NAME Programme",
            notificationName: "Programme",
            abbreviation: "$SUPPLIER_ABBREVIATION-Programme-",
            notify: true,
            replyEnabled: false,
            assetFieldsEnabled: false,
            replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            form_IncludeCompanyName: true,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            replyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "ReasonLabel",
                display: "Reason for any change to Planned Completion",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "ChangeLabel",
                display: "Change in any other Key Date, Completion Date for a section or other important project milestone in period and reasons why",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "MainProgressLabel",
                display: "Main progress achieved in period",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "KeyActivitiesLabel",
                display: "Key activities forecast in next four weeks",
                overrideResources: null
              },
              {
                id: 5,
                isActive: true,
                field: "SummaryLabel",
                display: "Summary of critical path",
                overrideResources: null
              },
              {
                id: 6,
                isActive: true,
                field: "ChangesLabel",
                display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc.",
                overrideResources: null
              },
              {
                id: 7,
                isActive: true,
                field: "CompensationLabel",
                display: "Compensation events added to programme this period",
                overrideResources: null
              },
              {
                id: 8,
                isActive: true,
                field: "NotificationClause",
                display: "Please find attached programme for acceptance",
                overrideResources: null
              },
              {
                id: 9,
                isActive: true,
                field: "ReplyRejectReasonLabel",
                display: "The $SUPPLIER_NAME’s programme is not accepted for the following reasons",
                overrideResources: null
              },
              {
                id: 10,
                isActive: true,
                field: "ReplyNotificationClause",
                display: "You are notified that the $SUPPLIER_NAME’s Programme",
                overrideResources: null
              },
              {
                id: 11,
                isActive: true,
                field: "DashboardPlannedCompletionLabel",
                display: "Latest Forecast Completion Date",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Supplier",
                display: "$SUPPLIER_NAME Programme Submission awaiting Acceptance",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ReplyRejectOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "plansnotpractical",
                display: "The $SUPPLIER_NAME’s plans which it shows are not practicable",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "missingcontractrequires",
                display: "It does not show the information which this contract requires",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "notrepresentplan",
                display: "It does not represent the $SUPPLIER_NAME’s plans realistically",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "notcomply",
                display: "It does not comply with Works Information",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "Fields",
            values: [
              {
                id: 1,
                isActive: true,
                field: "PreviousPlanned",
                display: "Previous forecast date of completion",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "PlannedCompletion",
                display: "Forecast date of completion",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "Reason",
                display: "Comments on programme update",
                overrideResources: null
              },
              {
                id: 4,
                isActive: false,
                field: "Change",
                display: "Change in any other Key Date, Sectional Completion or other important project milestone in period and reasons why",
                overrideResources: null
              },
              {
                id: 5,
                isActive: false,
                field: "MainProgress",
                display: "Main progress achieved in period",
                overrideResources: null
              },
              {
                id: 6,
                isActive: false,
                field: "KeyActivities",
                display: "Key activities forecast in next four weeks",
                overrideResources: null
              },
              {
                id: 7,
                isActive: false,
                field: "Summary",
                display: "Summary of critical path",
                overrideResources: null
              },
              {
                id: 8,
                isActive: false,
                field: "Changes",
                display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc.",
                overrideResources: null
              },
              {
                id: 9,
                isActive: false,
                field: "Compensation",
                display: "Compensation events added to programme this period",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "DashboardDisplayPlannedCompletionOnLatest",
            values: [
              {
                id: 1,
                isActive: true,
                field: "DashboardDisplayPlannedCompletionOnLatest",
                display: "Active-Inactive state indicates weather (Planned Completion shown on the latest Accepted Programme) is displayed on dasboard",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ReplyOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Accepted",
                display: "Accepted",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "Not Accepted",
                display: "Not Accepted",
                overrideResources: null
              }
            ]
          }
        ]
      },
      {
        id: 105,
        title: "Notifications",
        workflows: [
          {
            id: 15,
            title: "$PM_NAME Notifications",
            notificationName: "Notification",
            abbreviation: "$PM_ABBREVIATION-Notification-",
            notify: true,
            replyEnabled: false,
            assetFieldsEnabled: false,
            replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "PM",
            replyingParty: "SUPPLIER",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            replyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 16,
            title: "$SUPPLIER_NAME Notifications",
            notificationName: "Notification",
            abbreviation: "$SUPPLIER_ABBREVIATION-Notification-",
            notify: true,
            replyEnabled: false,
            assetFieldsEnabled: false,
            replyPeriodOption: "PM_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            replyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "PmClauses",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "13.1 of an address to receive communications",
                overrideResources: null
              },
              {
                id: 12,
                isActive: true,
                field: "",
                display: "14.5 of cancellation of a delegation",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "",
                display: "22.1 of access required for others",
                overrideResources: null
              },
              {
                id: 17,
                isActive: true,
                field: "",
                display: "44.1 of the cost of having a Defect corrected by others",
                overrideResources: null
              },
              {
                id: 18,
                isActive: true,
                field: "",
                display: "51.2 of the interest on a corrected payment",
                overrideResources: null
              },
              {
                id: 13,
                isActive: true,
                field: "",
                display: "62.1 of correction of an assumption about a compensation event",
                overrideResources: null
              },
              {
                id: 14,
                isActive: true,
                field: "",
                display: "62.5 of correction of an assumption in the Client's assessment of a compensation event",
                overrideResources: null
              },
              {
                id: 10,
                isActive: true,
                field: "",
                display: "90.3 of a default by the Contractor",
                overrideResources: null
              },
              {
                id: 15,
                isActive: true,
                field: "",
                display: "90.3 that the Contractor has not stopped defaulting",
                overrideResources: null
              },
              {
                id: 16,
                isActive: true,
                field: "",
                display: "1.4 of intention to pay less than the notified sum",
                overrideResources: null
              },
              {
                id: 11,
                isActive: true,
                field: "",
                display: "Other notification",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "SupplierClauses",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "13.1 of an address to receive communications",
                overrideResources: null
              },
              {
                id: 5,
                isActive: true,
                field: "",
                display: "62.1 of correction of an assumption in the Contractor's assessment of a compensation event",
                overrideResources: null
              },
              {
                id: 6,
                isActive: true,
                field: "",
                display: "62.5 of correction of an assumption in the Client's assessment of a compensation event",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "",
                display: "90.1 of termination",
                overrideResources: null
              },
              {
                id: 7,
                isActive: true,
                field: "",
                display: "1.4 of an intention to pay less than the notified sum",
                overrideResources: null
              },
              {
                id: 8,
                isActive: true,
                field: "",
                display: "1.5 of an intention to suspend performance",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "",
                display: "Other notification",
                overrideResources: null
              }
            ]
          }
        ]
      },
      {
        id: 106,
        title: "Submissions for acceptance",
        workflows: [
          {
            id: 49,
            title: "$PM_NAME Submissions",
            notificationName: "Submission",
            abbreviation: "$PM_ABBREVIATION-Submission-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "PM",
            replyingParty: "SUPPLIER",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            replyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 18,
            title: "$SUPPLIER_NAME Submissions",
            notificationName: "Submission",
            abbreviation: "$SUPPLIER_ABBREVIATION-Submission-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "PM_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            replyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "ReplyWarningBanner",
                display: "",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "IssueClauses",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "20.1 of particulars of a design",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "",
                display: "30.2 of a forecast date of Completion",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "",
                display: "43.1 of a proposal to accept a defect",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Supplier",
                display: "$SUPPLIER_NAME Submission awaiting Acceptance",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "IssueClausesPM",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "43.1 of a proposal to accept a defect",
                applyReplyPeriod: false,
                noReply: false,
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ReplyOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Accepted",
                display: "Accepted",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "Accepted with comments",
                display: "Accepted with comments",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "Not Accepted",
                display: "Not Accepted",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "Reply Not Required",
                display: "Reply Not Required",
                overrideResources: null
              }
            ]
          }
        ]
      },
      {
        id: 107,
        title: "Quotations for acceptance of a Defect",
        workflows: [
          {
            id: 19,
            title: "$SUPPLIER_NAME Quotation for Acceptance of a Defect",
            notificationName: "Quotation for Acceptance of a Defect",
            abbreviation: "Defect-Quote-",
            notify: true,
            replyEnabled: true,
            financialLimitEnabled: false,
            assetFieldsEnabled: false,
            replyPeriodOption: "PM_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            replyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "CostEffectLabel",
                display: "Cost effect on total of the prices",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "DaysEffectLabel",
                display: "Number of days effect to the completion date",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ProposalClauses",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "43.1 of a quotation for reduced Prices or an earlier Completion Date or both",
                applyReplyPeriod: false,
                noReply: false,
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Supplier",
                display: "$SUPPLIER_NAME Defect quotations awaiting acceptance",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ReplyOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Accepted",
                display: "Accepted",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "Not Accepted",
                display: "Not Accepted",
                overrideResources: null
              }
            ]
          }
        ]
      },
      {
        id: 108,
        title: "Payments",
        workflows: [
          {
            id: 20,
            title: "$PM_NAME Assessment",
            notificationName: "Amount Due Notification",
            abbreviation: "$PM_ABBREVIATION-AmountDue-",
            notify: true,
            financialLimitEnabled: false,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 21,
            title: "$SUPPLIER_NAME Payment Applications",
            notificationName: "Payment Application",
            abbreviation: "$SUPPLIER_ABBREVIATION-Payment-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "SUPPLIER",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "CumulativePriceLabel",
                display: "Price for Work Done to Date",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "PlusAmountsSupplierLabel",
                display: "Plus other amounts to be paid to the $SUPPLIER_NAME",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "LessAmountsSupplierLabel",
                display: "Less amounts to be paid by or retained from the $SUPPLIER_NAME",
                overrideResources: null
              },
              {
                id: 5,
                isActive: true,
                field: "RegisterCertifiedDateCaption",
                display: "Notified Date",
                overrideResources: null
              },
              {
                id: 6,
                isActive: true,
                field: "WarningBanner",
                display: "The assessment day is stated in the Contract Data. There is an assessment day in each month from the starting date until the month after the Defects Certificate has been issued",
                overrideResources: null
              },
              {
                id: 7,
                isActive: true,
                field: "LessAmountsDueLabel",
                display: "Less amount due as previously notified by the Contractor",
                overrideResources: null
              },
              {
                id: 8,
                isActive: true,
                field: "RegisterAppliedByCaption",
                display: "Submitted by",
                overrideResources: null
              },
              {
                id: 9,
                isActive: true,
                field: "SummaryDetailsLabel",
                display: "Summary details",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "TaxEnabled",
            values: [
              {
                id: 1,
                isActive: false,
                field: "TaxEnabled",
                display: "Active-Inactive state indicates tax field will be included in notification forms",
                overrideResources: null
              }
            ]
          }
        ]
      },
      {
        id: 109,
        title: "Defects",
        workflows: [
          {
            id: 48,
            title: "$PM_NAME Tests & Inspections",
            notificationName: "Test / Inspection",
            abbreviation: "$PM_NAME-Test-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "NOTIFICATION_DATE",
            replyPeriod: 0,
            notifyingParty: "PM",
            replyingParty: "PM",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            replyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 25,
            title: "$SUPPLIER_NAME Tests & Inspections",
            notificationName: "Test / Inspection",
            abbreviation: "Test-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "NOTIFICATION_DATE",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "SUPPLIER",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            replyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 22,
            title: "$PM_NAME Instruction to Search",
            notificationName: "Instruction to Search",
            abbreviation: "Search-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "NOTIFICATION_DATE",
            replyPeriod: 0,
            notifyingParty: "PM",
            replyingParty: "PM",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            replyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
            }
          },
          {
            id: 23,
            title: "$PM_NAME Defect Notifications",
            notificationName: "Defect Notification",
            abbreviation: "$PM_NAME-Defect-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            actioningParty: "PM",
            resolveActioningParty: "PM",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              actionTab: {
                actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
              resolveActionTab: {
                resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              }
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "InstructionClauseLabel",
                display: "You are instructed under clause 41.1 to search for a Defect.",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "SupervisorClauseLabel",
                display: "You are notified of the following Defect",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "SupplierClauseLabel",
                display: "",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "TestsClauseLabel",
                display: "",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 2,
                isActive: true,
                field: "SuperDefect",
                display: "$PM_NAME Notification of Defect awaiting closure",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "Instruction",
                display: "$SUPERVISOR_NAME Instruction to Search awaiting sign off",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "Inspection",
                display: "$SUPPLIER_NAME Test/Inspection awaiting sign off",
                overrideResources: null
              },
              {
                id: 5,
                isActive: true,
                field: "PMTestAndInspection",
                display: "$PM_NAME Test/Inspection awaiting sign off",
                overrideResources: null
              }
            ]
          }
        ]
      },
      {
        id: 111,
        title: "Records",
        workflows: [
          {
            id: 30,
            title: "$PM_NAME Meetings",
            notificationName: "Meeting",
            abbreviation: "$PM_ABBREVIATION-Meeting-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
            }
          },
          {
            id: 31,
            title: "$SUPPLIER_NAME Meetings",
            notificationName: "Meeting",
            abbreviation: "$SUPPLIER_ABBREVIATION-Meeting-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "SUPPLIER",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
            }
          },
          {
            id: 32,
            title: "$SUPPLIER_NAME Site Diary",
            notificationName: "Site Diary",
            abbreviation: "Diary-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "SUPPLIER",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
            }
          },
          {
            id: 33,
            title: "Weather",
            notificationName: "Weather",
            abbreviation: "Weather-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "SUPPLIER",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
            }
          }
        ],
        placeholders: []
      },
      {
        id: 117,
        title: "Certificates",
        workflows: [
          {
            id: 68,
            title: "$PM_NAME Certificates",
            notificationName: "Certificate",
            abbreviation: "$PM_ABBREVIATION-Certificate-",
            notify: true,
            replyEnabled: false,
            assetFieldsEnabled: false,
            replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "PM",
            replyingParty: "SUPPLIER",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            replyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "PmCertificateText",
                display: "",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "SupplierCertificateText",
                display: "In accordance with clause 30.3, I hereby certify that Completion was achieved on the date mentioned below.",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "ClientCertificateText",
                display: "In accordance with clause 30.3, I hereby certify that Completion was achieved on the date mentioned below.",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "SupervisorCertificateText",
                display: "In accordance with clause 30.2, I hereby certify that Completion was achieved on the date mentioned below.",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "PM",
                display: "$PM_NAME Certificates awaiting reply",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "Supplier",
                display: "$SUPPLIER_NAME Certificates awaiting reply",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "Client",
                display: "$CLIENT_NAME Certificates awaiting reply",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "Supervisor",
                display: "$SUPERVISOR_NAME Certificates awaiting reply",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "CompletionFields",
            values: [
              {
                id: 1,
                isActive: true,
                field: "CertifiedDate",
                display: "Completion Date",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "Description",
                display: "Details",
                overrideResources: null
              },
              {
                id: 3,
                isActive: true,
                field: "Works",
                display: "Outstanding Works",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "Defects",
                display: "Defects",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "DefectsFields",
            values: [
              {
                id: 1,
                isActive: true,
                field: "CertifiedDate",
                display: "Certified Date",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "Description",
                display: "Details",
                overrideResources: null
              },
              {
                id: 3,
                isActive: false,
                field: "Works",
                display: "Outstanding Works",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "Defects",
                display: "Outstanding Defects",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "TerminationFields",
            values: [
              {
                id: 1,
                isActive: true,
                field: "CertifiedDate",
                display: "Certified Date",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "Description",
                display: "Details",
                overrideResources: null
              },
              {
                id: 3,
                isActive: false,
                field: "Works",
                display: "Outstanding Works",
                overrideResources: null
              },
              {
                id: 4,
                isActive: false,
                field: "Defects",
                display: "Outstanding Defects",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "ReplyOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Accepted",
                display: "Accepted",
                overrideResources: null
              },
              {
                id: 2,
                isActive: true,
                field: "Not Accepted",
                display: "Not Accepted",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "PMCertTypes",
            values: [
              {
                id: 1,
                isActive: true,
                field: "",
                display: "Completion Certificate",
                effect: "COMPLETION",
                overrideResources: null
              },
              {
                id: 2,
                isActive: false,
                field: "",
                display: "Sectional Completion Certificate",
                effect: "SECTIONAL_COMPLETION",
                overrideResources: null
              },
              {
                id: 3,
                isActive: false,
                field: "",
                display: "Taking Over Certificate",
                effect: "TAKING_OVER",
                overrideResources: null
              },
              {
                id: 4,
                isActive: true,
                field: "",
                display: "Defects Certificate",
                effect: "DEFECTS",
                overrideResources: null
              },
              {
                id: 5,
                isActive: true,
                field: "",
                display: "Termination Certificate",
                effect: "TERMINATION",
                overrideResources: null
              },
              {
                id: 6,
                isActive: false,
                field: "",
                display: "Other Certificate",
                effect: "OTHER",
                overrideResources: null
              }
            ]
          },
          {
            replacementKey: "CompletionChangesStateEnabled",
            values: [
              {
                id: 1,
                isActive: true,
                field: "CompletionChangesStateEnabled",
                display: "Indicate whether communication of a Completion Certificate should change the contract state",
                overrideResources: null
              }
            ]
          }
        ]
      },
      {
        id: 112,
        title: "RFI / TQ",
        workflows: [
          {
            id: 34,
            title: "$SUPPLIER_NAME RFI / TQ",
            notificationName: "RFI / TQ",
            abbreviation: "RFI/TQ-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            replyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Cost Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Supplier",
                display: "$SUPPLIER_NAME RFI/TQ awaiting Reply",
                overrideResources: null
              }
            ]
          }
        ]
      },
      {
        id: 110,
        title: "Delegated Actions",
        workflows: [
          {
            id: 27,
            title: "$PM_NAME Delegated Actions",
            notificationName: "Delegated Action",
            abbreviation: "$PM_ABBREVIATION-Action-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            form_IncludeCompanyName: false,
            form_CompanyLogo: "PARTY",
            form_CompanyName: "PARTY",
            notifyAttachments: true,
            overrideResources: null,
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "DelegationClause",
                display: "You are notified that the following actions",
                overrideResources: null
              }
            ]
          }
        ]
      }
    ]
  };

export default template;