import React from "react";
import { Route, Routes } from "react-router-dom";
import BankAccountList from './BankAccount/List';


class BankAccount extends React.Component {
    constructor(props) {
        super(props);
        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;

        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<BankAccountList
                        {...this.props}
                        
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                    />}
                />

            </Routes>
        );
    }
};



export default BankAccount;