import store from "../store/store";
const appcuesEnabled = process.env.REACT_APP_APPCUES_ENABLED === "true";
export default class SystemHelper {
    static appcuesUpdate = () => {
        if (appcuesEnabled && window.Appcues) {
            const storeState = store.getState();
            if (storeState.auth && storeState.auth.userProfile) {
                const props = {
                    userCreatedAt: storeState.auth.userProfile.createdate ? Math.floor(new Date(storeState.auth.userProfile.createdate).getTime() / 1000) : null,
                    userCompanyName: storeState.auth.userProfile.company,
                    accountId: storeState.auth.userProfile.accountid,
                    userFullName: storeState.auth.userProfile.name,
                    userAccountType: storeState.auth.userProfile.accounttype,
                    instanceCode: process.env.REACT_APP_PRODUCT_KEY,
                    urlDomain: process.env.PUBLIC_URL,
                    userEmail: storeState.auth.userProfile.email,
                    telephone: storeState.auth.userProfile.telephone,
                    jobtitle: storeState.auth.userProfile.jobtitle
                }
                if (storeState.contract && storeState.contract.contract && storeState.contract.contractid && storeState.contract.user) {
                    props.contractUserRole = storeState.contract.user.rolepermissions;
                    props.contractUserPartyType = storeState.contract.user.party;
                    props.contractStartDate = storeState.contract.contract.startdate ? Math.floor(new Date(storeState.contract.contract.startdate).getTime() / 1000) : null;
                    props.contractCurrentEndDate = storeState.contract.contract.completiondate ? Math.floor(new Date(storeState.contract.contract.completiondate).getTime() / 1000) : null;
                    props.contractType = storeState.contract.contract.contracttype;
                }
                window.Appcues.identify(storeState.auth.userProfile.userguid, props);
                window.Appcues.loadLaunchpad("#bi-fd-launchpad", {
                    //Optionally specify the position of the content relative to the Launchpad icon. Possible values are as followed: 
                    //	- center (default value, i.e. bottom-center)
                    //	- left (i.e. bottom-left)
                    //	- right (i.e. bottom-right)
                    //	- top (i.e. top-center)
                    //	- top-left
                    //	- top-right
                    position: "bottom-left",
                    // Optionally add a header or footer. This must be HTML.
                    header: "<h1>Tutorials</h1>",
                    footer: "<p>Questions? Let us know support@builtintelligence.com</p>",
                    // And if you'd prefer to use a custom icon rather than the default "bell" icon, you can optionally pass 
                    // in an icon as well. Make sure that src is set to the right resource, whether it's in your site's directory or hosted
                    icon: "/media/icons/alert-svgrepo-com5.svg"
                });
            }
        }
    }

    static delightedScript = () => {
        const storeState = store.getState();
        if (storeState.auth && storeState.auth.userProfile) {
            const delightedScript = document.createElement("script");
            delightedScript.innerHTML = `
            !function(e,t,r,n){
                if(!e[n]){
                for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){
                    var c=i[s];
                    a[c]=a[c]||function(e){
                    return function(){
                        var t=Array.prototype.slice.call(arguments);
                        a.push([e,t]);
                    };
                    }(c);
                }
                a.SNIPPET_VERSION="1.0.1";
                var o=t.createElement("script");
                o.type="text/javascript";
                o.async=!0;
                o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";
                var p=t.getElementsByTagName("script")[0];
                p.parentNode.insertBefore(o,p);
                }
            }(window,document,"BxFxoYI7ywNEqzGO","delightedNps");

            delightedNps.survey({
                email: "${storeState.auth.userProfile.email}",
                name: "${storeState.auth.userProfile.name}",
                createdAt: "${storeState.auth.userProfile.createdate}",
                properties: {
                company: "${storeState.auth.userProfile.company}",
                productKey: "${process.env.REACT_APP_PRODUCT_KEY}"
                }
            });
            `;
            
            document.head.appendChild(delightedScript);
        }
    }
}