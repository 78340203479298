import {placeholderTypes} from "../../Types/Placeholder";
const placeholders =  [
    {
        replacementKey: 'Resources', values:
            [
                { id: 1, isActive: true, field: 'DelegationClause', display: 'You are notified that the following specific powers' },
            ],
        type:placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'ActionReminders', values:[],
        type:placeholderTypes.ACTION_REMINDERS
    },
    {
        replacementKey: 'AllowCrossPartyDelegations', values:
            [
                { id: 1, isActive: false, field: 'AllowCrossPartyDelegations', display: 'Set this to allow the sender to delegate to any contract user irrespective of which party the user is assigned to' },

            ],
            isToggle: true,
            fieldRequired: true,
            type:placeholderTypes.TOGGLE_SWITCH
    },
];

export default placeholders;