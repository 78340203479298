import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { TextBox } from 'devextreme-react';
import * as securityCrud from '../../../crud/security.crud';
import "../../../../_metronic/_assets/sass/pages/login/login-auth0.scss";
import { PatternRule, RequiredRule, Validator } from 'devextreme-react/validator';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';





const DiscoverHandler = (props) => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [error, setError] = useState("");
    const [fetchingTennantID, setFetchingTennantID] = useState(false);
    // const [tennantID, setTennantID] = useState("");
    const refTxtUsername = useRef(null);
    const refValUsername = useRef(null);
    useEffect(()=>{
        console.log("DiscoverHandler Mounted!")
    },[])
    // useEffect(() => {
    //     if (tennantID === 'NotSet') {
    //         navigate(`/auth/discover/auth0`);
    //     }
    //     else if (tennantID.length > 0) {
    //         // navigate(`/auth/discover/supertoken`);
    //         navigate({
    //             pathname: "/auth/discover/supertoken",
    //             search: createSearchParams({
    //                 tennantid: tennantID
    //             }).toString()
    //         });
    //     }
    // }, [tennantID]);


    const continueClicked = async () => {
        try {
            if (refValUsername?.current?.instance) {
                const validationResult = refValUsername.current.instance.validate();
                if (!validationResult.isValid) {
                    return;
                }
            }
            setFetchingTennantID(true);
            const { data: tennantIDResp } = await securityCrud.getCompanyTenantID(username);

            if (tennantIDResp === 'NotSet') {
                navigate(`/auth/discover/auth0`, {
                    state: {
                        username: username
                    }
                });
            }
            else if (tennantIDResp.length > 0) {
                console.log("Redirecting to /auth/discover/supertoken with username:"+username);
                const dscState = {
                    username: username,
                    tennantid: tennantIDResp
                };
                localStorage.setItem('auth-discover-user', JSON.stringify(dscState));
                navigate(`/auth/discover/supertoken`, {
                    state: dscState
                });
                // navigate({
                //     pathname: "/auth/discover/supertoken",
                //     search: createSearchParams({
                //         tennantid: tennantID
                //     }).toString()
                // });
            }
            else {
                setError("Invalid response");
                setFetchingTennantID(false);
            }



            // setTennantID(tennantIDResp)
            // setFetchingTennantID(false);
        } catch (err) {
            setFetchingTennantID(false);
            setError(err.toString());
        }

    }


    return (
        <div style={{ margin: "auto" }}>
            <div className='a0-container'>
                <div className='a0-row'>
                    <div>
                        <img src="https://cdn.shopify.com/s/files/1/0248/4926/files/BI_logo_300x90_300x90.png?v=1613712008" alt="Built Intelligence" />
                        <div className='a0-header'>
                            FastDraft Realm Discovery
                        </div>
                    </div>
                    <div className='a0-divider' />
                    <form onSubmit={(e) => e.preventDefault()} >
                        <ValidationGroup ref={refValUsername} name="validatorEmailDiscovery">
                            <div className="dx-field-value" style={{ width: "100%", marginBottom: 12 }}>
                                <TextBox
                                    ref={refTxtUsername}
                                    mode="email"
                                    placeholder="Enter Username"
                                    value={username}
                                    onValueChanged={(e) => setUsername(e.value)}
                                    disabled={fetchingTennantID}
                                    readOnly={fetchingTennantID}
                                    validationError={error}
                                >
                                    <Validator>
                                        <RequiredRule message={"Username is required"} />
                                        <PatternRule message={"Invalid username"} pattern={/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/} />
                                    </Validator>

                                </TextBox>

                            </div>
                            <div>
                                <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id="validatorEmailDiscovery"></ValidationSummary>
                            </div>
                        </ValidationGroup>
                        <div className='a0-button-container'>
                            <span>
                                <button
                                    disabled={fetchingTennantID}
                                    className={fetchingTennantID ? 'a0-button kt-spinner kt-spinner--center kt-spinner--md kt-spinner--dark' : 'a0-button'}
                                    onClick={continueClicked}>
                                    <div className='a0-button-text'>
                                        Continue
                                    </div>
                                </button>
                            </span>
                        </div>
                    </form>

                </div>
                <a className='a0-branding' href='https://www.builtintelligence.com/' target='_blank' rel='noreferrer'>
                    Power by <strong>Built Intelligence</strong> v{process.env.REACT_APP_VERSION}
                </a>
            </div>

        </div >
    )
    // }

}



export default (DiscoverHandler);
