/* eslint-disable import/no-anonymous-default-export */
import Workflows from "./Workflows";
import Placeholders from "./Placeholders";
export default {
    id: 112,
    title: 'RFI / TQ',
    path: 'rfi-tq',
    icon: "flaticon-questions-circular-button",
    workflows: Workflows,
    placeholders: Placeholders,
};