/* eslint-disable import/no-anonymous-default-export */
import Workflows from "./Workflows";
import Placeholders from "./Placeholders";
export default {
    id: 103,
    title: 'Compensation Events',
    path: 'compensation-events',
    icon: 'flaticon2-calendar-6',
    workflows: Workflows,
    placeholders: Placeholders,
};