import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    // let match = useMatch();
    // console.log(this._reactInternalInstance._currentElement._owner._instance.__proto__.constructor.name      )
    return (
      
      <Component
        {...props}
        location={location}
        params={params}
        navigate={navigate}
        // match={match}
      />
    );
  }

  return ComponentWithRouterProp;
}