/* eslint-disable import/no-anonymous-default-export */
import Workflows from "./Workflows";
import Placeholders from "./Placeholders";
export default {
    id: 116,
    title: 'Forecast',
    path: 'forecast',
    icon: "flaticon2-zig-zag-line-sign",
    workflows: Workflows,
    placeholders: Placeholders
};