import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotifyList from '../../_SharedWorkflow/Notify/List';
import NotifyCreate from '../../_SharedWorkflow/Notify/Create';
import NotifyDetail from '../../_SharedWorkflow/Notify/Detail';

class InstructionRouter extends React.Component {
    constructor(props) {
        super(props);
        this.clauses = props.workflowItem.clauses;
        if (props.mode === 'PM') {
            this.communicateToUser = props.globalResources.SUPPLIER_NAME;
            this.notificationType = 'Instruction';
            this.documentType = 'Document143pm';
            if (!this.clauses) {
                this.clauses = props.placeholders.find(ph => ph.replacementKey === 'InstructionTypes')
                    .values.filter(item => item.isActive === true);
            }
        }
        else if (props.mode === 'CLIENT') {
            this.communicateToUser = props.globalResources.PM_NAME;
            this.notificationType = 'InstructionClient';
            this.documentType = 'DocumentInstructionClient';
            if (!this.clauses) {
                this.clauses = props.placeholders.find(ph => ph.replacementKey === 'ClientInstructionTypes')
                    .values.filter(item => item.isActive === true);
            }
        }
        else if (props.mode === 'SUPERVISOR') {
            this.communicateToUser = props.globalResources.SUPPLIER_NAME;
            this.notificationType = 'InstructionSupv';
            this.documentType = 'DocumentInstructionSupv';
            if (!this.clauses) {
                this.clauses = props.placeholders.find(ph => ph.replacementKey === 'SupervisorInstructionTypes')
                    .values.filter(item => item.isActive === true);
            }
        }

        this.notifyingParty = props.workflowItem.notifyingParty;
        this.replyingParty = props.workflowItem.replyingParty;




        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;

        return (
            <>
                <Routes caseSensitive>
                    <Route
                        path={workflowItem.config.paths.list}
                        exact
                        element={<NotifyList
                            {...this.props}

                            title={this.notificationName}
                            abbreviation={this.abbreviation}
                            notificationType={this.notificationType}
                        />}

                    />
                    <Route
                        path={workflowItem.config.paths.create}
                        exact
                        element={
                            workflowItem.userFilters.mainNotice.createAllowed ? (
                                <NotifyCreate
                                    {...this.props}

                                    title={this.notificationName}
                                    clauses={this.clauses}
                                    communicateToUser={this.communicateToUser}
                                    notificationType={this.notificationType}
                                />) : (
                                <Navigate replace to={workflowItem.paths.defaultPath} />
                            )
                        }
                    />
                    <Route
                        path={'/:notificationid/*'}
                        exact
                        element={<NotifyDetail
                            {...this.props}
                            clauses={this.clauses}

                            title={this.notificationName}
                            abbreviation={this.abbreviation}
                            notificationType={this.notificationType}
                            documentType={this.documentType}
                            communicateToUser={this.communicateToUser}
                            notifyUserFilter={workflowItem.notifyUserFilter}
                        />}
                    />
                </Routes>
            </>
        );
    }
}



export default (InstructionRouter);