import React from 'react';
import clsx from "clsx";
import { Button } from 'devextreme-react/button';


const ImageEditor = ({ src, fileChangeHandler, allowChangeLogo, className, ...props }) => (
    <div {...props}
        className={clsx(
            "image-editor_container",
            className
        )} >
        <div style={{ width: '100%', height: '100%' }}>
            <img src={src} alt='Company Logo' style={{ width: '100%', height: '100%' }} />
        </div>
        <input
            accept="image/x-png,image/jpeg"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            visbility="hidden"
            onChange={fileChangeHandler}
        />
        {
            fileChangeHandler && allowChangeLogo && (
                <div className="image-editor_overlay">
                    <label htmlFor="raised-button-file">
                        <Button icon="edit" stylingMode='text' style={{ marginTop: 10 }} />
                    </label>
                </div>
            )
        }
    </div>
);

export default ImageEditor;