import React from 'react';
import { connect } from "react-redux";
import DataGrid, { Selection, Column, Scrolling, HeaderFilter, FilterRow, FilterPanel } from 'devextreme-react/data-grid';
import Spinner from '../../../../partials/layout/Spinner';
import * as relationCrud from '../../../../crud/relationship.crud';
import { withSnackbar } from 'notistack';
import { FormattedMessage, injectIntl } from "react-intl";
import { listAccountsAllowedEditSystemGeneratedRelations } from '../../../../router/Protector/AccessExceptions';



const initialState = {
    notifications: null,
    selectedKeys: [],
    allUniqueKeys: []
}

class RelationItemsRegister extends React.Component {
    constructor(props) {
        super(props);
        this.dataGrid = React.createRef();
        this.state = { ...initialState };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.item && this.state.notifications) {
            this.setState({ ...initialState });
            return;
        }
        //ITEM CHANGED
        if ((!prevProps.item && this.props.item) ||
            (prevProps.item && this.props.item && prevProps.item.id !== this.props.item.id)) {
            this.setState({ ...initialState }, () => this.loadNotifications(true));
            return;
        }
    }

    loadNotifications = () => {
        const { companyid, projectid, contractid } = this.props.contract;
        relationCrud.getRelationItemsList(this.props.item.model, companyid, projectid, contractid, this.props.item.type ? this.props.item.type : null)
            .then(response => {
                const notifications = response.data
                    .filter(ntf => !(this.props.isCurrentWF && this.props.currentNotificationID === ntf.id))//FILTER OUT THE NOTIFICATION ITSELF, SO IT CANT BE SELECTED TO RELATE ITSELF
                    .map(itm => {
                        //FIND EXISTING RELATION ITEM IF EXIST
                        const relationItem = this.props.relatedItems.find(x => x.notificationid === itm.id);
                        return {
                            id: itm.referenceid,
                            path: this.props.item.path + itm.id,
                            notificationid: itm.id,
                            subject: itm.title,
                            creationsource: relationItem ? relationItem.creationsource : 2
                        };
                    }
                    );
                this.setState({ notifications: notifications, selectedKeys: [...this.props.item.selectedKeys], selectedKeysCopyToCompare: [...this.props.item.selectedKeys] });
            });
    }

    onSelectionChanged = (eventArgs) => {
        const { selectedRowKeys, selectedRowsData, currentSelectedRowKeys, currentDeselectedRowKeys, } = eventArgs;

        if (currentSelectedRowKeys && currentSelectedRowKeys.length > 0) {
            const selectedItemKey = currentSelectedRowKeys[0];
            if (this.state.selectedKeysCopyToCompare.includes(selectedItemKey)) {
                //RECALL OF A CANCELLED EVENT
                //KEY IS ALREADY SELECTED
                //DO NOTHING
                return;
            }
            // const selectedItem = selectedRowsData.find(selectedItem => selectedItem.id === selectedItemKey);
            // if (selectedItem) {
            //     //SELECTED A NEW ITEM
            //     console.log("Selected Item:", selectedItem)                
            // }
        }

        else if (currentDeselectedRowKeys && currentDeselectedRowKeys.length > 0) {
            const unselectedItemKey = currentDeselectedRowKeys[0];
            if (!this.state.selectedKeysCopyToCompare.includes(unselectedItemKey)) {
                //RECALL OF A CANCELLED EVENT
                //KEY IS ALREADY UNSELECTED
                return;
            }

            const unselectedItem = this.state.notifications.find(ntf => ntf.id === unselectedItemKey)
            if (unselectedItem) {
                // console.log("UN-Selected Item:", unselectedItem)
                if (unselectedItem.creationsource === 1 && !listAccountsAllowedEditSystemGeneratedRelations.includes(this.props.userProfile.accounttype)) {
                    //SOURCE = CREATE RELATED BUTTON
                    //CANCEL ACTION FOR NON-ADMIN
                    return;
                }
                if (unselectedItem.creationsource === 3) {
                    //SOURCE = FORM FIELD RELATION
                    //CANCEL ACTION FOR EVERYONE
                    return;
                }
            }
        }
        this.setState({ selectedKeys: selectedRowKeys, selectedKeysCopyToCompare: [...selectedRowKeys] });
        this.props.onSelectionChanged(selectedRowKeys, selectedRowsData);
    }
    onEditorPreparing = (e) => {
        if (e.command === "select" && e && e.row && e.row.data) {
            if (e.row.data.creationsource === 1 && !listAccountsAllowedEditSystemGeneratedRelations.includes(this.props.userProfile.accounttype)) {
                //SOURCE = CREATE RELATED BUTTON
                //DISABLE FOR NON-ADMIN
                e.editorOptions.disabled = true;
            }
            if (e.row.data.creationsource === 3) {
                //SOURCE = FORM FIELD RELATION
                //DISABLE FOR EVERYONE
                e.editorOptions.disabled = true;
            }
        }
    }

    render() {
        if (!this.props.item) {
            return null;
        }

        if (!this.state.notifications) {
            return <Spinner />;
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '10px' }}>
                <span className="dx-form-group-caption dx-widget"><FormattedMessage id="GENERAL.FORMS.LABEL_ITEM_LIST" values={{ ITEM_NAME: this.props.item.title }} /></span>
                <div style={{ borderTop: '1px solid #ddd', paddingBottom: '20px', paddingTop: '19px', marginTop: '6px', height: '95%', width: '100%' }} className="dx-form-group-content dx-form-group-with-caption dx-widget">
                    <div style={{ width: '100%', height: '100%' }}>
                        <DataGrid
                            ref={this.dataGrid}
                            dataSource={this.state.notifications}
                            keyExpr="id"
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            remoteOperations={false}
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            columnResizingMode='widget'
                            wordWrapEnabled={true}
                            noDataText={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_SELECT_WF_TO_LIST' })}
                            // style={{ width: '100%', height: '100%', maxHeight: '100%' }}
                            // defaultSelectionFilter={selectionFilter}
                            onSelectionChanged={this.onSelectionChanged}
                            selectedRowKeys={this.state.selectedKeys}
                            stateStoring={{ enabled: false }}
                            height='100%'
                            onEditorPreparing={this.onEditorPreparing}
                        >
                            <Selection mode="multiple" showCheckBoxesMode="always" allowSelectAll={false} />
                            <HeaderFilter visible={true} />
                            <FilterRow applyFilter="auto" visible={true} />
                            <FilterPanel visible={true} />
                            <Column cssClass="WrappedColumnClass" caption={this.props.intl.formatMessage({ id: "GENERAL.FORMS.FIELD.ID" })} dataField="id" />
                            <Column cssClass="WrappedColumnClass" caption={this.props.intl.formatMessage({ id: "GENERAL.FORMS.FIELD.TITLE" })} dataField="subject" />
                            <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                        </DataGrid>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    userProfile: store.auth.userProfile
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(RelationItemsRegister)));
