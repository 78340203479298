import React, { Component } from "react";
import * as contractInformationCrud from "../../../../crud/contractInformation.crud";
import axios from "axios";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { notificationAggregateListTagsFilter, notificationUniqueTagsFilter } from "../../../../store/helpers/FilterHelper";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

class DocumentList extends Component {
  constructor(props) {
    super(props);

    this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
    this.notificationsRelsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';

    this.state = {
      notifications: null,
      tags: null
    };
  }

  componentDidMount() {
    this.fetchDocumentsList();
  }

  fetchDocumentsList = () => {
    contractInformationCrud
      .getDocumentsList(
        this.props.contract.companyid,
        this.props.contract.projectid,
        this.props.contract.contractid
      )
      .then((response) => {
        let list = notificationAggregateListTagsFilter(JSON.parse(response.data.aggregatelist));

        let tagFilters = notificationUniqueTagsFilter(JSON.parse(response.data.uniquetags));

        this.setState({ documentsList: list, tags: tagFilters });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          this.props.enqueueSnackbar(err.toString(), { variant: "error" });
        }
      });
  };

  onTableSelectionChanged = ({ selectedRowsData }) => {
    if (selectedRowsData.length > 0) {
      const data = selectedRowsData[0];
      this.props.navigate(this.props.workflowItem.paths.basePath + data.documentid);
    }
  };

  setUrl = (rowData) => {
    return this.props.workflowItem.paths.basePath + rowData.documentid;
  };

  render() {
    if (!this.state.documentsList) {
      return <PortletSpinner />;
    }

    const columns = [
      {
        caption: "Reference",
        dataField: "reference",
      },
      { resource: "GENERAL.FORMS.FIELD.TITLE", dataField: "title" },
      {
        resource: "GENERAL.FORMS.FIELD.TYPE",
        dataField: "type",
      },
      {
        caption: "Issued Date",
        dataField: "issueddate",
        dataType: "date",
      },
      { resource: "GENERAL.FORMS.FIELD.ISSUED_BY", dataField: "issuedby" },
      {
        resource: "GENERAL.FORMS.FIELD.STATUS",
        dataField: "status",
      },
      {
        caption: "Revision",
        dataField: "revision",
      },
      {
        resource: "GENERAL.FORMS.FIELD.LINK",
        dataField: "url",
        calculateCellValue: this.setUrl,
        visible: false,
        showInColumnChooser: false,
      },
    ];

    if (this.notificationsTagsEnabled) {
      columns.push(
        { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.PortfolioFilter } },
        { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProgrammeFilter } },
        { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProjectFilter } },
        { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.OtherFilter } },
        { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
        { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false }
      )
    }

    if (this.notificationsRelsEnabled) {
      columns.push(
          { resource: "GENERAL.FORMS.TAB_RELATIONS", dataField: "relatednotices", visible: false },
      )
    }

    return (
      <Portlet>
        <PortletBody>
          <DataGridBase
            version="1"
            title={this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.TITLE2' }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
            createPath={this.props.workflowItem.userFilters.actionTab.actionAllowed ? this.props.workflowItem.paths.createPath : null}
            name={`${this.props.title}`}
            dataSource={this.state.documentsList}
            keyExpr="documentid"
            selection={{ mode: "single" }}
            onSelectionChanged={this.onTableSelectionChanged}
            hoverStateEnabled={true}
            rowAlternationEnabled={false}
            columns={columns}
          />
        </PortletBody>
      </Portlet>
    );
  }
}



export default withListPageProps(DocumentList);
