import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { Badge } from "react-bootstrap";
import * as quotationCrud from '../../../../crud/quotation.crud';
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

class QuoteListPage extends React.Component {
    constructor(props) {
        super(props);
        this.quoteStatuses = props.placeholders
            .find(ph => ph.replacementKey === 'QuoteStatuses')
            .values.filter(item => item.isActive === true);

        if (this.props.workflowItem.replyingParty !== "PM") {
            this.responseRequiredDateCaption = this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.RESPONSE_REQUIRED' })
            this.responseReceivedDateCaption = this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.RESPONSE_RECEIVED' })
        }
        else {
            this.responseRequiredDateCaption = this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PM_RESPONSE_REQUIRED' }, { PM_ABBREVIATION: this.props.globalResources.PM_ABBREVIATION })
            this.responseReceivedDateCaption = this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PM_RESPONSE_RECEIVED' }, { PM_ABBREVIATION: this.props.globalResources.PM_ABBREVIATION })
        }


        this.customFields = [];
        if (props.customFieldsCommon) {
            if (props.customFieldsCommon.QUOTE && props.customFieldsCommon.QUOTE.length > 0) {
                this.customFields.push(...(props.customFieldsCommon.QUOTE.filter(cf => cf.includelisting)));
            }
            if (props.customFieldsCommon.QUOTE_REPLY && props.customFieldsCommon.QUOTE_REPLY.length > 0) {
                this.customFields.push(...(props.customFieldsCommon.QUOTE_REPLY.filter(cf => cf.includelisting)));
            }
        }
        this.continuingEffectOptions = [
            { value: 1, display: this.props.intl.formatMessage({ id: 'OPTIONS.CONTINUING_EFFECT.INTERIM' }) },
            { value: 0, display: this.props.intl.formatMessage({ id: 'OPTIONS.CONTINUING_EFFECT.FINAL' }) },
        ];
        this.state = {
            notifications: null,
        };
    }
    formatCurrency = () => {

        return this.props.contract.currency
    }
    componentDidMount() {
        this.fetchQuotes();
    }
    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.fetchQuotes();
        }
    }
    fetchQuotes = async () => {
        try {
            const { companyid, contractid } = this.props.contract;
            const { data: responseObj } = await quotationCrud.getQuotesReduced(companyid, contractid, this.props.params.notificationid);
            let quotes = (this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed === true) ?
                responseObj :
                responseObj.filter(quo => quo.showtasknotification === 1);
            quotes = CustomFieldHelper.MapDataListCustomFieldsToObjectProperties(quotes);
            this.setState({ notifications: quotes });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        return (skipHostUrl ? '' : process.env.PUBLIC_URL) + this.props.workflowItem.paths.basePath + rowData.quotationid;
    }
    renderStatusColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        let status = this.renderStatusDisplay(cellInfo.data);
        if (cellInfo.data.showtasknotification !== 0) {
            switch (cellInfo.data.responsestatus) {
                case 1:
                case 7:
                    badgeVariant = 'success';
                    break;
                case 2:
                case 6:
                    badgeVariant = 'warning';
                    break;
                case 0:
                case 3:
                case 4:
                case 5:
                    badgeVariant = 'danger';
                    break;
                case 9:
                    badgeVariant = 'danger';
                    break;
                default:
                    badgeVariant = 'secondary';
                    break;
            }
        }
        return (<Badge variant={badgeVariant}>{status}</Badge>);
    }
    renderStatusDisplay = (rowData) => {
        let resourceKey;
        if (rowData.showtasknotification === 1) {
            resourceKey = rowData.responsestatus.toString();
        } else {
            resourceKey = 'none';
        }
        const status = this.quoteStatuses.find(x => x.field === resourceKey);
        if (status) {
            return status.display;
        } else {
            return rowData.status;
        }
    }

    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }



        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.COMPENSATION_ID", dataField: "compensationreference", dataType: 'string' },
            { resource: "GENERAL.FORMS.FIELD.DAYS", dataField: 'days' },
            { resource: "GENERAL.FORMS.FIELD.AMOUNT", dataField: 'amount', dataType: 'currency', currencyCode: this.props.contract.currency },
            { resource: "CONTRACT.WF.QUOTES.FIELD.QUOTE_DATE", dataField: "notificationdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.ISSUED_BY", dataField: "createby" },
            { caption: this.responseRequiredDateCaption, dataField: "responsedate", dataType: 'date' },
            { caption: this.responseReceivedDateCaption, dataField: "responseresponsedate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "responsestatus", cellRender: this.renderStatusColumn, calculateCellValue: this.renderStatusDisplay },
            { resource: "GENERAL.FORMS.FIELD.REPLY_DRAFT_BY", dataField: "draftreplyby", visible: false },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "GENERAL.FORMS.FIELD.CURRENCY", dataField: "currency", visible: false, calculateCellValue: this.formatCurrency },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false },
            { resource: "GENERAL.FORMS.REPLING_PARTY", dataField: "replyingparty", lookup: { dataSource: this.props.contractTemplate.parties.options, valueExpr: 'id', displayExpr: 'display' }, visible: false }
        ];
        if (this.props.globalResources.QUOTE_DEEMED_ACCEPTANCE_STARTS !== 'OFF') {
            columns.push(
                { resource: "CONTRACT.WF.COMPENSATION.FIELD.QUOTE_DEEM_DATE", dataField: "quotedeemedacceptancedate", dataType: 'date' }
            );
        }

        if (this.props.interimQuotesAllowed) {
            columns.push(
                { resource: "GENERAL.FORMS.EFFECT", dataField: "continuingeffect", lookup: { dataSource: this.continuingEffectOptions, valueExpr: 'value', displayExpr: 'display' }, visible: false }
            );
        }

        columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(this.customFields));
        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="1"
                        title={this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.TITLE2' }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                        createPath={(this.props.workflowItem.userFilters.mainNotice.createAllowed && this.props.baseNotificationReadyForCreate) ? this.props.workflowItem.paths.createPath : null}
                        workflowItem={this.props.workflowItem}
                        name={this.props.workflowItem.pageTitle}
                        dataSource={this.state.notifications}
                        keyExpr="quotationid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}



export default withListPageProps(QuoteListPage);
