const template = {
    name: "Default FIDIC Red Book 2nd Edition 2017 v2.5 01/01/2024",
    isSystemDefault: true,
    isGlobal: true,
    companies: [],
    resources: [
        {
            id: 1,
            type: "TEXT",
            key: "SUPPLIER_NAME",
            value: "Contractor"
        },
        {
            id: 2,
            type: "TEXT",
            key: "SUPPLIER_ABBREVIATION",
            value: "CR"
        },
        {
            id: 3,
            type: "TEXT",
            key: "PM_NAME",
            value: "Engineer"
        },
        {
            id: 4,
            type: "TEXT",
            key: "PM_ABBREVIATION",
            value: "ENG"
        },
        {
            id: 5,
            type: "TEXT",
            key: "SUPERVISOR_NAME",
            value: "Supervisor"
        },
        {
            id: 6,
            type: "TEXT",
            key: "SUPERVISOR_ABBREVIATION",
            value: "SUP"
        },
        {
            id: 7,
            type: "TEXT",
            key: "CLIENT_NAME",
            value: "Employer"
        },
        {
            id: 8,
            type: "NUMBER",
            key: "SUPPLIER_REPLY_PERIOD",
            value: 7
        },
        {
            id: 9,
            type: "NUMBER",
            key: "PM_REPLY_PERIOD",
            value: 7
        },
        {
            id: 10,
            type: "NUMBER",
            key: "QUOTE_DURATION",
            value: 28
        },
        {
            id: 13,
            type: "NUMBER",
            key: "REQUOTE_DURATION",
            value: 14
        },
        {
            id: 11,
            type: "NUMBER",
            key: "QUOTE_RESPONSE",
            value: 14
        },
        {
            id: 14,
            type: "NUMBER",
            key: "REQUOTE_RESPONSE",
            value: 14
        },
        {
            id: 12,
            type: "NUMBER",
            key: "VARIATION_RESPONSE",
            value: 14
        },
        {
            id: 16,
            type: "NUMBER",
            key: "PROGRAMME_REPLY_PERIOD",
            value: 14
        },
        {
            id: 30,
            type: "NUMBER",
            key: "CWN_QUOTE_DURATION",
            value: 84
        },
        {
            id: 31,
            type: "NUMBER",
            key: "CWN_QUOTE_RESPONSE",
            value: 42
        },
        {
            id: 32,
            type: "NUMBER",
            key: "INTERIM_CWN_QUOTE_DURATION",
            value: 28
        },
        {
            id: 33,
            type: "NUMBER",
            key: "INTERIM_CWN_QUOTE_RESPONSE",
            value: 42
        },
        {
            id: 8,
            type: "TEXT",
            key: "CLIENT_ABBREVIATION",
            value: "EMP"
        },
        {
            id: 28,
            type: "NUMBER",
            key: "PROP_QUOTE_DURATION",
            value: 14
        },
        {
            id: 29,
            type: "NUMBER",
            key: "PROP_REQUOTE_DURATION",
            value: 14
        },
        {
            id: 9,
            type: "TEXT",
            key: "COMPLETION_DATE",
            value: "Completion Date"
        }
    ],
    workflowGroups: [
        {
            id: 101,
            title: "Advance Warning",
            workflows: [
                {
                    id: 1,
                    title: "$PM_NAME Advance Warning",
                    notificationName: "Advice of Advance Warning",
                    abbreviation: "$PM_ABBREVIATION-Warning-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    actioningParty: "PM",
                    resolveActioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
                        resolveActionTab: {
                            resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                },
                {
                    id: 2,
                    title: "$SUPPLIER_NAME Advance Warning",
                    notificationName: "Advice of Advance Warning",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Warning-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    actioningParty: "PM",
                    resolveActioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
                        resolveActionTab: {
                            resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                },
                {
                    id: 3,
                    title: "Advance Warning Register",
                    notificationName: "Advance Warning",
                    abbreviation: "Warning",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "MeetingRequiredLabel",
                            display: "Is a meeting required to discuss the matter?",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "NotificationClause",
                            display: "Under clause 8.4, you are advised of a probable future event or circumstance which may",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "RiskItemsLabel",
                            display: "You are advised of a probable future event or circumstance which may",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "DescriptionLabel",
                            display: "Description",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Pm",
                            display: "$PM_NAME Advance Warning awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Advance Warning awaiting reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypes",
                    values: [
                        {
                            id: 4,
                            isActive: true,
                            field: "riskaffectwork",
                            display: "adversely affect the work of the Contractor’s Personnel",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "riskimpairperformance",
                            display: "adversely affect the performance of the Works when completed ",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "riskincreaseprice",
                            display: "increase the Contract Price",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "riskdelaycompletion",
                            display: "delay the execution of the Works or a Section (if any)",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "MeetingOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "0",
                            display: "Meeting Not Required",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Meeting Required - Next Scheduled Meeting",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Meeting Required - You are required to attend a meeting as described below",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypesSupplier",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "riskaffectwork",
                            display: "adversely affect the work of the Contractor's Personnel",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "riskimpairperformance",
                            display: "adversley affect the performance of the Works when completed",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "riskincreaseprice",
                            display: "increase the Contract Price",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "riskdelaycompletion",
                            display: "delay the execution of the Works or a Section (if any)",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 102,
            title: "Instructions",
            workflows: [
                {
                    id: 4,
                    title: "$PM_NAME Instruction",
                    notificationName: "Instruction",
                    abbreviation: "$PM_ABBREVIATION-Instruction-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "InstructionTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "1.5 in clarification of any ambiguity or discrepancy",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "1.12 requiring the disclosure of Confidential Information",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "2.3 to require the removal of a person of the Employer's Personnel or other contractor",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "3.5 necessary for the execution of the works",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "3.8 to require attendance at a management meeting",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "4.1 to require details of arrangements and methods adopted for the works",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "4.6 to allow opportunities for the Employer, its personnel or of public bodies to carry out work on site",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "4.8 to require the maintenance of records and to make health and safety reports",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "4.23 on how to deal with the discovery of fossils, coins, articles of value or antiquity and the like",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "6.9 to require the removal of any person employed on the Site or Works",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "7.2 to provide further samples",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "7.3 to require the uncovering and reinstatement of any work",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "7.4 to vary the location or details of a test or to carry out additional tests",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "7.5 to require retesting",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "7.6 to remove and replace, re-execute or execute any work in connection with a defect",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "8.7 to submit a revised programme and supporting report describing methods to be adopted to expedite progress",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "8.9 to suspend progress of the works",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "9.1 of a day on which to carry out a Test on Completion",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "9.4 for further tests or reject the work",
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "11.5 to require further details in respect of remedying defects off site",
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "13.2 in connection a proposal to accelerate completion, reduce the cost, improve the efficiency or value of the works or be of benefit to the Employer",
                            overrideResources: null
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "13.3.1 to require further particulars",
                            overrideResources: null
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "",
                            display: "13.3.2 of a Variation",
                            overrideResources: null
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "",
                            display: "13.4 in connection with Provisional Sums",
                            overrideResources: null
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "",
                            display: "13.5 to carry out work on daywork",
                            overrideResources: null
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "",
                            display: "15.2.3 following termination",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 103,
            title: "Variations and Contractor Claims",
            workflows: [
                {
                    id: 5,
                    title: "Variation by Instruction",
                    notificationName: "Notice of Variation by Instruction",
                    abbreviation: "Variation-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    quoteNotificationName: "Detailed Particulars",
                    quoteReferencePrefix: "Particulars-",
                    form_IncludeCompanyName: true,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 6,
                    title: "Variations by Request for Proposal",
                    notificationName: "Notice of Variation by Request for Proposal (clause 13.3.2)",
                    abbreviation: "RFP-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    quoteNotificationName: "Contractor's Proposal for a Variation",
                    quoteReferencePrefix: "Proposal-",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 37,
                    title: "$SUPPLIER_NAME Claim",
                    notificationName: "Notice of Claim",
                    abbreviation: "$SUPPLIER_ABBREVIATION-NOC-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    quoteNotificationName: "Fully Detailed Claim",
                    quoteReferencePrefix: "Claim-",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                },
                {
                    id: 8,
                    title: "Determinations",
                    notificationName: "Notice of Determination of a Contractor's Claim",
                    abbreviation: "$PM_ABBREVIATION-Determination-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 9,
                    title: "$PM_NAME Extensions",
                    notificationName: "Extension",
                    abbreviation: "$PM_ABBREVIATION-Extension-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 10,
                    title: "$SUPPLIER_NAME Extensions",
                    notificationName: "Extension",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Extension-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmAssumptionLabel",
                            display: "The quotation is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmAssumptionNote",
                            display: "Under clause 13.3.1, you are required to provide detailed particulars by $date",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierDescNote",
                            display: "You are hereby given Notice of a Claim under clause 20.2.1",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "ReplyAcceptedCommentLabel",
                            display: "Comments",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "ReplyRejectedCommentLabel",
                            display: "Comments",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ReplyAcceptedAssumptionLabel",
                            display: "The quotation is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "QuoteNote",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "ExtensionPMLabel",
                            display: "An extension of the time to reply is requested as follows",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ExtensionSupplierLabel",
                            display: "An extension of time to provide a proposal/claim is requested as follows",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "AssessmentDetailLabel",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ProposedInstructionNote",
                            display: "You are requested to provide a proposal by $date",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "ProposedInstructionLabel",
                            display: "Your proposal is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "ReplyRejectedOptionsLabel",
                            display: "You are notified that the claim is not valid for the following reason(s)",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "QuoteCostEffectLabel",
                            display: "Change to the Prices",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "QuoteDateEffectLabel",
                            display: "Extension of Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "AssessmentCostEffectLabel",
                            display: "Change to the Prices",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "AssessmentDaysEffectLabel",
                            display: "Extension of Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "AssessmentWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "PmCompensationWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "InstructionQuoteReplyWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "CompensationTypeLabel",
                            display: "Type",
                            overrideResources: null
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "QuoteDaysEffectLabel",
                            display: "Extension of Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "QuoteProgrammeAttachLabel",
                            display: "Are you attaching a programme showing the effect against the last Accepted Programme with this quotation?",
                            overrideResources: null
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "AssessmentCompensationLabel",
                            display: "Claim/Variation",
                            overrideResources: null
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "QuoteNoteProposedInstruction",
                            display: "We submit our proposal for $reference",
                            overrideResources: null
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "QuoteContinuingEffectLabel",
                            display: "Does this Claim have a continuing effect (i.e. is this an Interim Claim)",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmCompensationAQ",
                            display: "Variations awaiting $SUPPLIER_NAME's proposal",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmCompensationARQ",
                            display: "Variations awaiting $SUPPLIER_NAME's revised proposal",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "PmCompensationAPMRQ",
                            display: "Variations awaiting $PM_NAME's response to proposal",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "PmCompensationAPMA",
                            display: "Variations awaiting awaiting $PM_NAME's Determination",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "PmProposedAQ",
                            display: "RFP awaiting proposal",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "PmProposedARQ",
                            display: "RFP awaiting revised proposal",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "PmProposedAPMRQ",
                            display: "Awaiting $PM_NAME's response to $SUPPLER_NAME's proposal",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "PmProposedAPMA",
                            display: "Proposed Variation awaiting $PM_NAME's Determination",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "SupClaimAPMR",
                            display: "$SUPPLIER_NAME Claims awaiting $PM_NAME's response",
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "SupClaimAQ",
                            display: "$SUPPLIER_NAME Notices of Claims awaiting Detailed Claim",
                            overrideResources: null
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "SupClaimARQ",
                            display: "$SUPPLIER_NAME Claims awaiting revised details",
                            overrideResources: null
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "SupClaimAPMRQ",
                            display: "$SUPPLIER_NAME Claims awaiting response",
                            overrideResources: null
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "SupClaimAPMA",
                            display: "$SUPPLIER_NAME Claims awaiting $PM_NAME's Determination",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "PmExtension",
                            display: "$PM_NAME Response extensions awaiting agreement",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "SupExtension",
                            display: "$SUPPLIER_NAME Response extensions awaiting agreement",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SupCompensationAQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting quotes",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SupCompensationARQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting revised quotes",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "SupCompensationAPMR",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION response",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "SupCompensationAPMRQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting response to quotes",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "SupCompensationAPMA",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "COMPENSATION_CWN_AQ",
                            display: "$SUPPLIER_NAME Notice of Claim awaiting Claim Details",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "COMPENSATION_CWN_ARQ",
                            display: "$SUPPLIER_NAME Claim awating revised details",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "COMPENSATION_CWN_APMR",
                            display: "$SUPPLIER_NAME Notice of Claim Awaiting $PM_NAME's Initial Response",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "COMPENSATION_CWN_APMRQ",
                            display: "$SUPPLIER_NAME's Claim awaiting $PM_NAME's response",
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "COMPENSATION_CWN_APMA",
                            display: "$SUPPLIER_NAME Claim awaiting $PM_NAME's Determination",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "faultpscp",
                            display: "Arises from a fault of the Contractor",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "notexpectnothappen",
                            display: "Has not happened and is not expected to happen",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "notnotifiedwithintimescales",
                            display: "The claiming Party has failed to give the Notice of Claim within the period of 28 days under Sub-Clause 20.2.1",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "noteffectcostcompletion",
                            display: "Has no effect on Defined Cost, Completion or meeting a Key Date",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "statedcontract",
                            display: "Is not one of the compensation events stated in the contract",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ClaimClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "1.13 that the Contractor has suffered delay and/or incurred Cost in connection with a delay in permits, permissions, licences or approvals",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "2.1 that the Contractor has suffered delay and/or incurred Cost as a consequence of the Employer's failure to give access to the Site",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "4.6 that the Contractor has suffered delay and/or incurred Cost in connection with an instruction under clause 4.6",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "4.12.3 in connection with Unforeseeable Physical Conditions",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "4.15 that the Contractor has suffered delay and/or incurred Cost as a consequence of the non-suitability of the access route",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "4.23 that the Contractor has suffered delay and/or incurred Cost as a consequence of complying with an instruction under 4.23",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "7.4 that the Contractor has suffered delay and/or incurred Cost due to compliance with an instruction in connection with testing",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "8.5 that the Contractor has suffered delay due to the events listed at 8.5",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "8.10 that the Contractor has suffered delay and/or incurred Cost due to an instruction under clause 8.9",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "10.2 that the Contractor has incurred Cost as a consequence of the Employer Taking Over parts of the Permanent Works",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "10.3 that the Contractor has suffered delay and/or incurred Cost as a consequence of the Employer interfering in tests",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "11.2 that a Defect was not the fault of the Contractor",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "11.7 that the Contractor has incurred Cost as a consequence of a delay in providing access after Taking Over",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "11.8 that the Contractor has incurred Cost as a consequence of a search for a defect",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "13.3.1 Notice of the instruction of a Variation",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "13.3.1 of detailed particulars in connection with a Variation",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "13.3.2 requesting a proposal before instructing a Variation",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "13.3.2(a) of a proposal for a proposed Variation",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "13.3.2 that the Contractor has incurred Cost as a result of submitting a proposal for a proposed Variation",
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "13.4 in connection with Provisional Sums",
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "13.4 of quotations, invoices, vouchers, accounts and receipts",
                            overrideResources: null
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "13.5 to carry out work on daywork",
                            overrideResources: null
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "",
                            display: "13.5 of quotations for daywork",
                            overrideResources: null
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "",
                            display: "13.6 that the Contractor has suffered delay and/or incurred Cost as a consequence of a change in the law",
                            overrideResources: null
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "",
                            display: "14.14 disputing amounts in the final statement",
                            overrideResources: null
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "",
                            display: "16.1 that the Contractor has suffered delay and/or incurred Cost as a consequence of a suspension",
                            overrideResources: null
                        },
                        {
                            id: 28,
                            isActive: true,
                            field: "",
                            display: "16.2.2 that the Contractor has suffered delay and/or incurred Cost during the 14 day period after a 16.2.1 Notice",
                            overrideResources: null
                        },
                        {
                            id: 29,
                            isActive: true,
                            field: "",
                            display: "17.2 that the Contractor has suffered delay and/or incurred Cost of loss or damage to the Works, Goods or Contractor's Documents",
                            overrideResources: null
                        },
                        {
                            id: 30,
                            isActive: true,
                            field: "",
                            display: "18.4 that the Contractor has suffered delay and/or incurred Cost as a consequence of an Exceptional Event",
                            overrideResources: null
                        },
                        {
                            id: 31,
                            isActive: true,
                            field: "",
                            display: "20.2.4 of a fully detailed claim",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "CompensationStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "APMA",
                            display: "Not Accepted - $PM_NAME to make a Determination",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ARQ",
                            display: "Not Accepted - $SUPPLIER_NAME to resubmit",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "AM",
                            display: "$PM_ABBREVIATION Determination Made",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "QA",
                            display: "Particulars/proposal/claim agreed",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "APMRQ",
                            display: "Awaiting $PM_NAME response to particulars/proposal/details",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "APMR",
                            display: "Awaiting $PM_ABBREVIATION Response to Notice of Claim",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "AQ",
                            display: "Awaiting particulars/proposal/details",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NC",
                            display: "Not Communicated",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "R",
                            display: "Rejected",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "DNG",
                            display: "Proposed Variation will not be instructed",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "InstructionReplyProceedOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "61.1",
                            display: "Instruction of the Variation",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "61.2",
                            display: "Variation not instructed",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "INTERIM_QUOTES_ALLOWED",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "INTERIM_QUOTES_ALLOWED",
                            display: "Active-Inactive state indicates whether the continuing effect field is asked on quote communications",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "EWRelationEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "EWRelationEnabled",
                            display: "Active-Inactive state indicates weather the EWN can be attached in CEN communications",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "RelatedCompensationText",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "RelatedCompensationText",
                            display: "Regarding the aforementioned instruction, you are hereby notified of a Variation",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "VariationClauses",
                    values: [
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "13.3.1 Variation by Instruction",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "CompensationClauses",
                    values: [
                        {
                            id: 41,
                            isActive: true,
                            field: "",
                            display: "5.4 Initiated in connection with a change in the law",
                            overrideResources: null
                        },
                        {
                            id: 38,
                            isActive: true,
                            field: "",
                            display: "13.3.1 Variation by Instruction",
                            overrideResources: null
                        },
                        {
                            id: 40,
                            isActive: true,
                            field: "",
                            display: "13.4 Variation by Instruction - Provisional Sum",
                            overrideResources: null
                        },
                        {
                            id: 39,
                            isActive: true,
                            field: "",
                            display: "13.5 Variation by Instruction - Daywork",
                            overrideResources: null
                        },
                        {
                            id: 42,
                            isActive: true,
                            field: "",
                            display: "4.12.2 Instruction in connection with Unforeseeable Physical Conditions",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ClaimWNAcceptanceOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "1",
                            display: "Accepted",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "0",
                            display: "Not Accepted",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "Fields",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "Assumption",
                            display: "Additional information",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "QuoteProgrammeImpactOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "noeffect",
                            display: "There is no effect on the Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "mitigated",
                            display: "There is an effect on the Time for Completion but this has been mitigated by measures as detailed",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "notmitigated",
                            display: "There is an effect on the Time for Completion",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "QuoteStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "0",
                            display: "Not Agreed - $SUPPLIER_NAME to resubmit",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Agreed",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Awaiting Response",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "3",
                            display: "Not Agreed - $PM_NAME to make a determination",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "4",
                            display: "Rejected - Other particulars/claim/proposal agreed",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "5",
                            display: "Rejected - Proposed Variation will not be instructed",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: false,
                            field: "6",
                            display: "Awaiting Deemed Acceptance [Not Applicable]",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: false,
                            field: "7",
                            display: "Deemed Acceptance [Not Applicable]",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "none",
                            display: "Not Communicated",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: false,
                            field: "8",
                            display: "Acknowledged [Not Applicable]",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "9",
                            display: "Not Agreed - Process ended",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "InstructionReplyOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "1",
                            display: "QuoteStatus_1",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "0",
                            display: "QuoteStatus_0",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "3",
                            display: "QuoteStatus_3",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "5",
                            display: "QuoteStatus_5",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 107,
            title: "Value Engineering",
            workflows: [
                {
                    id: 19,
                    title: "$SUPPLIER_NAME Proposal",
                    notificationName: "Proposal",
                    abbreviation: "CON-Proposal-",
                    notify: true,
                    replyEnabled: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CostEffectLabel",
                            display: "Cost incurred or saved",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "DaysEffectLabel",
                            display: "Delay suffered",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ProposalClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "13.2 of a value engineering proposal",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Proposals awaiting Response",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Notice of Consent",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Not Accepted",
                            display: "Notice of Rejection",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 104,
            title: "Programmes",
            workflows: [
                {
                    id: 14,
                    title: "Works Programmes",
                    notificationName: "Programme",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Programme-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 8,
                            isActive: true,
                            field: "NotificationClause",
                            display: "Under clause 8.3 we submit a works programme",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ReplyRejectReasonLabel",
                            display: "No-objection",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "ReplyNotificationClause",
                            display: "Notice of Rejection",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ReasonLabel",
                            display: "Reason for any change to Planned Completion",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "ChangeLabel",
                            display: "Change in any other Key Date, Sectional Completion or other important project milestone in period and reasons why",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "MainProgressLabel",
                            display: "Main progress achieved in period",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "KeyActivitiesLabel",
                            display: "Key activities forecast in next four weeks",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "SummaryLabel",
                            display: "Summary of critical path",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "ChangesLabel",
                            display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc.",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "CompensationLabel",
                            display: "Claims/Variations added to programme this period",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "ReferenceLabel",
                            display: "Programme Reference",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "DashboardPlannedCompletionLabel",
                            display: "Planned Completion",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Programme Submission awaiting Reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 4,
                            isActive: true,
                            field: "notcomply",
                            display: "The programme does not comply with the Contract",
                            overrideResources: null
                        },
                        {
                            id: 1,
                            isActive: true,
                            field: "plansnotpractical",
                            display: "The programme does not reflect actual progress",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "missingcontractrequires",
                            display: "The programme is otherwise inconsistent with the Contractor's obligations",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "Fields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PreviousPlanned",
                            display: "Previous Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlannedCompletion",
                            display: "Anticipated Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Reason",
                            display: "Reason for any change in the Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Change",
                            display: "Change to important project milestone in the period and reasons why",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "MainProgress",
                            display: "Main progress achieved in period",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "KeyActivities",
                            display: "Key activities forecast in next four weeks",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "Summary",
                            display: "Summary of critical path",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "Changes",
                            display: "Change to important project milestones in the period and reasons why",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "Compensation",
                            display: "Claims or Variations added to programme this period",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Clause 8.3 - Notice of No Objection",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Not Accepted",
                            display: "Clause 8.3 - Notice of Rejection",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "DashboardDisplayPlannedCompletionOnLatest",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "DashboardDisplayPlannedCompletionOnLatest",
                            display: "Active-Inactive state indicates weather (Planned Completion shown on the latest Accepted Programme) is displayed on dasboard",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 105,
            title: "Other Notices",
            workflows: [
                {
                    id: 15,
                    title: "$PM_NAME Notices",
                    notificationName: "Notice",
                    abbreviation: "$PM_ABBREVIATION-Notice-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 16,
                    title: "$SUPPLIER_NAME Notices",
                    notificationName: "Notice",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Notice-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "PmClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "1.3 of a change in address for communications",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "1.5 of an ambiguity or discrepancy",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "2.4 of an intention to make a material change to financial arrangements",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "3.1 of the appointment or revocation of appointment of the natural person or replacement authorised to be the Engineer",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "3.4 of assignment of authority or delegation of duties",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "3.4 reversing a delegated person's instruction",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "3.5 confirming, reversing or varying an instruction",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "3.7.1 of agreement of a Contractor Claim",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "3.7.1 of agreement of a Claim (other than a Contractor Claim)",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "3.7.1 that no agreement can be reached",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "3.7.2 of an Engineer's determination of a Contractor Claim",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "3.7.2 of an Engineer's determination (other than a Contractor Claim)",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "3.7.4b that an error in the determination has been found",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "3.7.4b that an error in the determination has been found",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "3.7.4 of a correction to a typographical, clerical or arithmetical error",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "3.7.5 of dissatisfaction",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "4.2.3 of the return of the Performance Security",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "7.3 that inspection is not required",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "7.4 of an intention to attend tests",
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "7.5 of a rejection of Plant, Materials, design or workmanship due to defects",
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "7.5 rejecting the design, Plant, Materials or workmanship",
                            overrideResources: null
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "8.1 of the commencement date",
                            overrideResources: null
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "",
                            display: "8.12 of a reply to a submission of a request to proceed with suspended works",
                            overrideResources: null
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "",
                            display: "9.2 that Tests must be carried out within 21 days",
                            overrideResources: null
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "",
                            display: "10.1 of the rejection of an application for Taking over",
                            overrideResources: null
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "",
                            display: "10.3 of the date the Contractor may carry out each Test on Completion",
                            overrideResources: null
                        },
                        {
                            id: 28,
                            isActive: true,
                            field: "",
                            display: "11.4 of a fixed date to remedy a Defect",
                            overrideResources: null
                        },
                        {
                            id: 29,
                            isActive: true,
                            field: "",
                            display: "11.5 of consent to remove Plant from Site for repair",
                            overrideResources: null
                        },
                        {
                            id: 30,
                            isActive: true,
                            field: "",
                            display: "11.6 in response to a notice describing work and tests completed",
                            overrideResources: null
                        },
                        {
                            id: 31,
                            isActive: true,
                            field: "",
                            display: "11.6 to require the repetition of a test",
                            overrideResources: null
                        },
                        {
                            id: 32,
                            isActive: true,
                            field: "",
                            display: "11.7(b) in response to a request for access to the Works or records",
                            overrideResources: null
                        },
                        {
                            id: 33,
                            isActive: true,
                            field: "",
                            display: "11.8 of a search being carried out by Employer's Personnel",
                            overrideResources: null
                        },
                        {
                            id: 34,
                            isActive: true,
                            field: "",
                            display: "13.1 cancelling, confirming or varying an instruction",
                            overrideResources: null
                        },
                        {
                            id: 35,
                            isActive: true,
                            field: "",
                            display: "13.3.1 of the instruction of a Variation",
                            overrideResources: null
                        },
                        {
                            id: 36,
                            isActive: true,
                            field: "",
                            display: "13.4 in response to the submission of quotations, invoices, vouchers, accounts and receipts",
                            overrideResources: null
                        },
                        {
                            id: 37,
                            isActive: true,
                            field: "",
                            display: "14.6.2 of an intention to withhold an amount from an IPC",
                            overrideResources: null
                        },
                        {
                            id: 38,
                            isActive: true,
                            field: "",
                            display: "14.11.1 to supply further information in connection with a draft final statement",
                            overrideResources: null
                        },
                        {
                            id: 39,
                            isActive: true,
                            field: "",
                            display: "15.1 of a failure to carry out an obligation under the contract",
                            overrideResources: null
                        },
                        {
                            id: 40,
                            isActive: true,
                            field: "",
                            display: "17.3 of receipt of a claim for infringing an intellectual property right",
                            overrideResources: null
                        },
                        {
                            id: 41,
                            isActive: true,
                            field: "",
                            display: "17.4 of a claim of intellectual or property right infringement",
                            overrideResources: null
                        },
                        {
                            id: 42,
                            isActive: true,
                            field: "",
                            display: "18.2 of an Exceptional Event",
                            overrideResources: null
                        },
                        {
                            id: 43,
                            isActive: true,
                            field: "",
                            display: "18.3 of further details of an Exceptional Event",
                            overrideResources: null
                        },
                        {
                            id: 44,
                            isActive: true,
                            field: "",
                            display: "20.2.2 of an initial response to a Notification of Claim",
                            overrideResources: null
                        },
                        {
                            id: 45,
                            isActive: true,
                            field: "",
                            display: "20.2.4 that a claim has lapsed",
                            overrideResources: null
                        },
                        {
                            id: 46,
                            isActive: true,
                            field: "",
                            display: "20.2.5 requiring additional particulars",
                            overrideResources: null
                        },
                        {
                            id: 47,
                            isActive: true,
                            field: "",
                            display: "20.2.5 of a response on the contractual basis of the claim",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "SupplierClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "1.3 of a change in address for communications",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "1.5 of an ambiguity or discrepancy",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "3.4 questioning an instruction, communication or notice by a delegated person",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "3.5 that an instruction is a Variation, does not comply with Laws, reduces safety or is impossible",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "3.7.4b that an error in the determination has been found",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "3.7.5 of dissatisfaction",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "4.7.2 of an error in any terms of reference in the setting out information",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "4.9.1 of any failings identified by an external audit",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "4.12.1 of Unforeseeable Physical Conditions",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "4.12.4 that the Contractor has suffered delay and/or incurred Cost due to Unforeseeable Physical Conditions",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "4.16 of the date of delivery of any Plant or major item of Goods will be delivered",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "4.17 of the date when a major item of Plant will be delivered",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "4.23 of the discovery of fossils, coins, articles of value or antiquity and the like",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "6.5 that unavoidable work was carried out outside the working hours",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "7.3 when work is ready to be inspected before it is covered",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "7.4 of a date to carry out the testing",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "8.12 of a request to proceed with suspended works",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "8.12 that the suspended works are treated as an omission",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "9.1 of the date on which the Contractor is ready to carry out tests",
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "9.1 that other tests are ready",
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "9.2 the day on which Tests shall be carried out",
                            overrideResources: null
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "10.1 of an application for the issue of the Taking over Certificate",
                            overrideResources: null
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "",
                            display: "10.2 identifying the part of the works used by the Employer",
                            overrideResources: null
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "",
                            display: "10.3 of interference with Tests on Completion",
                            overrideResources: null
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "",
                            display: "11.5 for consent to remove Plant from Site for repair",
                            overrideResources: null
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "",
                            display: "11.6 describing the remedied work and tests completed",
                            overrideResources: null
                        },
                        {
                            id: 28,
                            isActive: true,
                            field: "",
                            display: "11.7(a) of a request for access to the Works or records",
                            overrideResources: null
                        },
                        {
                            id: 29,
                            isActive: true,
                            field: "",
                            display: "13.1 that the Contractor cannot undertake the variation",
                            overrideResources: null
                        },
                        {
                            id: 30,
                            isActive: true,
                            field: "",
                            display: "14.6.3 referring a disagreement as to amounts included in an IPC",
                            overrideResources: null
                        },
                        {
                            id: 31,
                            isActive: true,
                            field: "",
                            display: "15.1 in response to a Notice to Correct",
                            overrideResources: null
                        },
                        {
                            id: 32,
                            isActive: true,
                            field: "",
                            display: "17.3 of receipt of a claim for infringing an intellectual property right",
                            overrideResources: null
                        },
                        {
                            id: 33,
                            isActive: true,
                            field: "",
                            display: "17.4 of a claim of intellectual or property right infringement",
                            overrideResources: null
                        },
                        {
                            id: 34,
                            isActive: true,
                            field: "",
                            display: "18.2 of an Exceptional Event",
                            overrideResources: null
                        },
                        {
                            id: 35,
                            isActive: true,
                            field: "",
                            display: "18.3 of further details of an Exceptional Event",
                            overrideResources: null
                        },
                        {
                            id: 36,
                            isActive: true,
                            field: "",
                            display: "20.2.2 of an initial response to a Notification of Claim",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Notifications awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PM",
                            display: "$PM_NAME Notifications awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Supervisor",
                            display: "$SUPERVISOR_NAME Notifications awaiting reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Notice of no objection",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "Accepted with comments",
                            display: "Accepted with comments",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Not Accepted",
                            display: "Notice of objection",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Reply Not Required",
                            display: "No reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "Resources",
                    values: []
                }
            ]
        },
        {
            id: 106,
            title: "Other Communications",
            workflows: [
                {
                    id: 49,
                    title: "$PM_NAME Communication",
                    notificationName: "Communication",
                    abbreviation: "$PM_ABBREVIATION-Comm-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 18,
                    title: "$SUPPLIER_NAME Communication",
                    notificationName: "Communication",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Comm-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "ReplyWarningBanner",
                            display: "",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "IssueClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "1.7 to request to assign the benefit of the Contract",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "1.11 of a submission for consent to use the Employer's Documents for a purpose other than set out in the Contract",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "1.13 to provide documentation to obtain a permit, permission, licence or approval",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "2.2 to request the provision of reasonable assistance to obtain laws, permits and the like",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "2.4 to request reasonable evidence of financial arrangements",
                            applyReplyPeriod: true,
                            replyPeriod: 28,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "3.6 to require attendance at a management meeting",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "4.1 to provide Contractor's documents",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "4.1 to provide details of arrangements and methods adopted for the works",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "4.2.1 to deliver the Performance Security",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "4.2.1 of a submission for consent of details of an entity to provide Performance Security",
                            applyReplyPeriod: true,
                            replyPeriod: 28,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "4.2.1 of a submission for consent to reduce Performance Security",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "4.3 of a submission for consent of the name of a proposed Contractor's Representative or temporary or permanent replacement",
                            applyReplyPeriod: true,
                            replyPeriod: 28,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "4.3 of a submission for consent to revoke the appointment of the Contractor's Representative",
                            applyReplyPeriod: true,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 63,
                            isActive: true,
                            field: "",
                            display: "4.7.1 to deliver the results of a verification of the setting out information",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "4.8 of a submission of a Health and Safety manual",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "4.8 of a submission of details of an accident",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "4.8 of a submission of health and safety reports",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "4.9.1 of a submission of a quality management system",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "4.9.1 of a submission of a report on internal audits",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "4.17 of a submission for consent for permission to remove a major item of Contractor's Equipment",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "",
                            display: "4.20 of a submission for acceptance of a proposed form for the progress reports",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "",
                            display: "4.20 of a submission of progress reports",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 30,
                            isActive: true,
                            field: "",
                            display: "6.5 of a submission for consent to work outside the working hours",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 31,
                            isActive: true,
                            field: "",
                            display: "6.6 of a submission for permission to locate facilities outside the areas identified in the Employer's Requirements",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 32,
                            isActive: true,
                            field: "",
                            display: "6.10 of a submission for agreement of a proposal to omit information from the progress report",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 33,
                            isActive: true,
                            field: "",
                            display: "6.12 of a submission for consent of the appointment or replacement of Key Personnel",
                            applyReplyPeriod: true,
                            replyPeriod: 14,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 34,
                            isActive: true,
                            field: "",
                            display: "7.2 of a submission for consent of samples, materials and other relevant information",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 35,
                            isActive: true,
                            field: "",
                            display: "7.4 to provide documents and other things for testing",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 64,
                            isActive: true,
                            field: "",
                            display: "7.4 to forward duly certified reports of tests",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 36,
                            isActive: true,
                            field: "",
                            display: "7.5 of a submission of a proposal in connection with a defect",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 37,
                            isActive: true,
                            field: "",
                            display: "8.7 of a submission of a report describing methods to be adopted to expedite progress",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 38,
                            isActive: true,
                            field: "",
                            display: "8.11 to provide evidence that Plant and Materials comply with the contract",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 59,
                            isActive: true,
                            field: "",
                            display: "9.1 of submission of a test programme",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 62,
                            isActive: true,
                            field: "",
                            display: "9.1 of a submission of a certified report",
                            overrideResources: null
                        },
                        {
                            id: 39,
                            isActive: true,
                            field: "",
                            display: "9.3 to require failed tests to be retested",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 40,
                            isActive: true,
                            field: "",
                            display: "11.1(ii) of a submission of a proposal for remedial work",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 41,
                            isActive: true,
                            field: "",
                            display: "11.5 of a submission of further details in respect of remedying defects off site",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 42,
                            isActive: true,
                            field: "",
                            display: "13.2 of a submission of further particulars",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 43,
                            isActive: true,
                            field: "",
                            display: "13.3.1 of a submission of further particulars in connection with a Variation",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 44,
                            isActive: true,
                            field: "",
                            display: "13.5 of a submission of daily daywork vouchers",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 45,
                            isActive: true,
                            field: "",
                            display: "13.5 of a submission of priced daywork vouchers",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 46,
                            isActive: true,
                            field: "",
                            display: "14.2.1 of a submission for consent of an entity to provide an advance payment guarantee",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 47,
                            isActive: true,
                            field: "",
                            display: "14.2.1 of a submission of an advance payment guarantee",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 48,
                            isActive: true,
                            field: "",
                            display: "14.3 of a submission for acceptance of the form of the payment application",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 49,
                            isActive: true,
                            field: "",
                            display: "14.4 of a submission of non-binding estimates",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 50,
                            isActive: true,
                            field: "",
                            display: "14.5 of a submission of a statement of the Cost of acquiring and delivering Plant and Materials to Site",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 51,
                            isActive: true,
                            field: "",
                            display: "14.8 of a submission of a request for payment of finance charges",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 54,
                            isActive: true,
                            field: "",
                            display: "14.11.1 of a submission of further information in connection with a draft final statement",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 55,
                            isActive: true,
                            field: "",
                            display: "14.12 of a submission of a discharge that the amount in the Final Statement or Partially Agreed Final Statement is in full and final settlement",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 56,
                            isActive: true,
                            field: "",
                            display: "15.2.3 of a submission of Contractor's Documents following termination",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 57,
                            isActive: true,
                            field: "",
                            display: "16.3 of a submission of Contractor's Documents following termination",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 58,
                            isActive: true,
                            field: "",
                            display: "19.1 of a submission of details of insurance",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Communication awaiting Reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Consent/Acceptance/Notice of No-objection",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "Accepted with comments",
                            display: "Accepted with comments",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Not Accepted",
                            display: "Rejection/Notice of objection",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Reply Not Required",
                            display: "No reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "IssueClausesPM",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "1.7 to request to assign the benefit of the Contract",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "1.10 of a submission for consent to use the Contractor's Documents for a purpose other than set out in the Contract",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "1.13c to request assistance and documentation to obtain a permit, permission, licence or approval",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "2.4 to provide reasonable evidence of financial arrangements",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "2.5 of a submission making available any new site data",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "3.7.1 of a proposal for agreement that no records are to be provided",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "3.7.1 to provide records of a consultation",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "3.8 copies of the minutes of a management meeting",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "4.2.1 to request an increase in Performance Security",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "8.4 to request  a proposal to avoid or minimise the effect of the events",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "8.13 to provide a copy of the record to suspended works",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "13.2 to request further particulars",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "14.13 of a submission of a request to provide a draft final statement",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "19.1 of a submission of details of insurance",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "20.2.4 of a submission of a fully detailed claim",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 108,
            title: "Payments",
            workflows: [
                {
                    id: 20,
                    title: "$PM_NAME Notice of a Statement of Payment",
                    notificationName: "Notice of a Statement of Payment",
                    abbreviation: "$PM_ABBREVIATION-Payment-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 21,
                    title: "$SUPPLIER_NAME Statement of Application of Payment",
                    notificationName: "Statement of Application of Payment",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Statement-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CumulativePriceLabel",
                            display: "Value of Work Done",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlusAmountsSupplierLabel",
                            display: "Plus other amounts to be paid to the Contractor",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "LessAmountsSupplierLabel",
                            display: "Less amounts to be paid by or retained from the Contractor",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TaxSupplierLabel",
                            display: "Tax which the law requires the Employer to pay to the Employer",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "WarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "RegisterAppliedByCaption",
                            display: "Submitted by",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "SummaryDetailsLabel",
                            display: "Summary details",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "TaxEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "TaxEnabled",
                            display: "Active-Inactive state indicates tax field will be included in notification forms",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ApplicationTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "INTERIM",
                            display: "14.3 submission of a statement of application for interim payment",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SUPPLEMENTARY",
                            display: "14.10 submission of a statement at completion",
                            overrideResources: null
                        },
                        {
                            id: 1,
                            isActive: true,
                            field: "FINAL",
                            display: "14.11 of a draft/agreed final statement",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "PaymentTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "INTERIM",
                            display: "14.6.1 of a statement of interim payment",
                            overrideResources: null
                        },
                        {
                            id: 1,
                            isActive: true,
                            field: "FINAL",
                            display: "14.13 of the final payment certificate",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "SUPPLEMENTARY",
                            display: "Supplementary",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 109,
            title: "Defects",
            workflows: [
                {
                    id: 22,
                    title: "Instructions to Search for a Defect",
                    notificationName: "Instruction to Search for a Defect",
                    abbreviation: "Search-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "NOTIFICATION_DATE",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 23,
                    title: "$PM_NAME Defect Notifications",
                    notificationName: "Notice of a Defect or Damage",
                    abbreviation: "$PM_NAME-Defect-",
                    notify: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "NOTIFICATION_DATE",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    actioningParty: "PM",
                    resolveActioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
                        resolveActionTab: {
                            resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                },
                {
                    id: 26,
                    title: "Master Defect Register",
                    notificationName: "Defect Notification",
                    abbreviation: "Defect",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "InstructionClauseLabel",
                            display: "Under clause 11.8, you are instructed to search for a Defect as follows",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SupervisorClauseLabel",
                            display: "Under clause 11.1 (b), you are hereby given Notice of the following",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierClauseLabel",
                            display: "You are notified of the following Defect",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TestsClauseLabel",
                            display: "You are hereby given Notice of the following test or inspection",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "DefectsWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SearchWarningBannerDate",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "SearchWarningBannerDetails",
                            display: "",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Instruction",
                            display: "$PM_NAME Instruction to Search awaiting sign-off",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SuperDefect",
                            display: "$PM_NAME Notification of Defect awaiting sign-off",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Inspection",
                            display: "$SUPPLIER_NAME Test/Inspection Notification awaiting sign-off",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 110,
            title: "Delegated Actions",
            workflows: [
                {
                    id: 27,
                    title: "$PM_NAME Delegated Actions",
                    notificationName: "Notice of Delegation",
                    abbreviation: "$PM_ABBREVIATION-Delegation-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "DelegationClause",
                            display: "You are hereby notified of assignment of authority or delegation of duties under clause 3.2.\n",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 117,
            title: "Certificates",
            workflows: [
                {
                    id: 68,
                    title: "$PM_NAME Certificates",
                    notificationName: "Certificate",
                    abbreviation: "$PM_ABBREVIATION-Certificate-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmCertificateText",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SupplierCertificateText",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "ClientCertificateText",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "SupervisorCertificateText",
                            display: "",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PM",
                            display: "$PM_NAME Certificates awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Certificates awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Client",
                            display: "$CLIENT_NAME Certificates awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Supervisor",
                            display: "$SUPERVISOR_NAME Certificates awaiting reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "CompletionFields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CertifiedDate",
                            display: "Completion Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Description",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Works",
                            display: "Outstanding Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Defects",
                            display: "Defects",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "SectionalCompletionFields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CertifiedDate",
                            display: "Sectional Completion Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Description",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Works",
                            display: "Outstanding Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Defects",
                            display: "Defects",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "TakingOverFields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CertifiedDate",
                            display: "Taking Over Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Description",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Works",
                            display: "Outstanding Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Defects",
                            display: "Defects",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "DefectsFields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CertifiedDate",
                            display: "Certified Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Description",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "Works",
                            display: "Outstanding Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Defects",
                            display: "Outstanding Defects",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "TerminationFields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CertifiedDate",
                            display: "Certified Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Description",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "Works",
                            display: "Outstanding Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "Defects",
                            display: "Outstanding Defects",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "OtherFields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CertifiedDate",
                            display: "Certified Date",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Description",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "Works",
                            display: "Outstanding Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "Defects",
                            display: "Outstanding Defects",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Accepted",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Not Accepted",
                            display: "Not Accepted",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "PMCertTypes",
                    values: [
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "7.4 issue of a test certificate",
                            effect: "OTHER",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "9.4 issue of a Taking-Over Certificate",
                            effect: "TAKING_OVER",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "10.1 issue of a Taking-Over Certificate",
                            effect: "TAKING_OVER",
                            overrideResources: null
                        },
                        {
                            id: 1,
                            isActive: true,
                            field: "",
                            display: "11.9 issue of a Performance Certificate",
                            effect: "COMPLETION",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "",
                            display: "Sectional Completion Certificate",
                            effect: "SECTIONAL_COMPLETION",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "",
                            display: "Defects Certificate",
                            effect: "DEFECTS",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: false,
                            field: "",
                            display: "Termination Certificate",
                            effect: "TERMINATION",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "SupplierCertTypes",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "",
                            display: "Completion Certificate",
                            effect: "COMPLETION",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "",
                            display: "Sectional Completion Certificate",
                            effect: "SECTIONAL_COMPLETION",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "",
                            display: "Taking Over Certificate",
                            effect: "TAKING_OVER",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "",
                            display: "Defects Certificate",
                            effect: "DEFECTS",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: false,
                            field: "",
                            display: "Termination Certificate",
                            effect: "TERMINATION",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "Other Certificate",
                            effect: "OTHER",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ClientCertTypes",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "",
                            display: "Completion Certificate",
                            effect: "COMPLETION",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "",
                            display: "Sectional Completion Certificate",
                            effect: "SECTIONAL_COMPLETION",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "",
                            display: "Taking Over Certificate",
                            effect: "TAKING_OVER",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "",
                            display: "Defects Certificate",
                            effect: "DEFECTS",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "Termination Certificate",
                            effect: "TERMINATION",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "Other Certificate",
                            effect: "OTHER",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "SupervisorCertTypes",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "",
                            display: "Completion Certificate",
                            effect: "COMPLETION",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "",
                            display: "Sectional Completion Certificate",
                            effect: "SECTIONAL_COMPLETION",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "",
                            display: "Taking Over Certificate",
                            effect: "TAKING_OVER",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "Defects Certificate",
                            effect: "DEFECTS",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: false,
                            field: "",
                            display: "Termination Certificate",
                            effect: "TERMINATION",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: false,
                            field: "",
                            display: "Other Certificate",
                            effect: "OTHER",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "CompletionChangesStateEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "CompletionChangesStateEnabled",
                            display: "Indicate whether communication of a Completion Certificate should change the contract state",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 111,
            title: "Records",
            workflows: [
                {
                    id: 30,
                    title: "$PM_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$PM_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 31,
                    title: "$SUPPLIER_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 32,
                    title: "$SUPPLIER_NAME Site Diary",
                    notificationName: "Site Diary",
                    abbreviation: "Diary-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 33,
                    title: "Weather",
                    notificationName: "Weather",
                    abbreviation: "Weather-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: []
        },
        {
            id: 112,
            title: "RFI / TQ",
            workflows: [
                {
                    id: 34,
                    title: "$SUPPLIER_NAME RFI / TQ",
                    notificationName: "RFI / TQ",
                    abbreviation: "RFI/TQ-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME RFI/TQ awaiting Reply",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 113,
            title: "Contract Information",
            workflows: [
                {
                    id: 39,
                    title: "Documents",
                    notificationName: "Document",
                    abbreviation: "Documents-",
                    notify: true,
                    actioningParty: "PM",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: []
                }
            ]
        }
    ]
};

export default template;