import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AssessmentList from './List';
import AssessmentCreate from './Create';
import AssessmentDetail from './Detail';

class AssessmentRouter extends React.Component {
    constructor(props) {
        super(props);
        this.communicateToUser = this.props.globalResources.SUPPLIER_NAME;
        this.documentType = 'DocumentAssessmentPM';
        this.clauses = props.placeholdersQA.ClausesPM.values
                        .filter(item => item.isActive === true);

        this.fields = props.placeholdersQA.FieldsPM.values.reduce((obj, fieldDesc) => ({ ...obj, [fieldDesc.field]: fieldDesc }), {});

        this.notifyingParty = props.workflowItem.notifyingParty;
        this.replyingParty = props.workflowItem.replyingParty;

        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;

        // #region TASK ORDER ENABLED
        this.taskOrderOptionEnabled = false;
        try {
            if (props.placeholdersQA.TaskOrderOptionEnabled) {
                this.taskOrderOptionEnabled = props.placeholdersQA.TaskOrderOptionEnabled.values
                    .find(phItem => phItem.field === 'TaskOrderOptionEnabled').isActive;
            }
        } catch (error) {
            console.log('TaskOrderOptionEnabled placeholder not available!');
            this.taskOrderOptionEnabled = false;
        }
        //#endregion

        if (this.taskOrderOptionEnabled) {
            this.taskOrderNotificationName = (this.props.workflowsQA['38'] || {}).notificationName;
        }

        // #region PROJECT ORDER ENABLED
        this.projectOrderOptionEnabled = false;
        try {
            if (props.placeholdersQA.ProjectOrderOptionEnabled) {
                this.projectOrderOptionEnabled = props.placeholdersQA.ProjectOrderOptionEnabled.values
                    .find(phItem => phItem.field === 'ProjectOrderOptionEnabled').isActive;
            }
        } catch (error) {
            console.log('ProjectOrderOptionEnabled placeholder not available!');
            this.projectOrderOptionEnabled = false;
        }
        //#endregion

        if (this.projectOrderOptionEnabled) {
            this.projectOrderNotificationName = (this.props.workflowsQA['72'] || {}).notificationName;
        }
    }

    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;
        return (
            <Routes caseSensitive>
                <Route path={workflowItem.paths.basePath} exact element={<Navigate replace to={workflowItem.paths.defaultPath} />} />
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<AssessmentList
                        {...this.props}
                        fields={this.fields}
                        editingProperties={this.editingProperties}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        taskOrderOptionEnabled={this.taskOrderOptionEnabled}
                        taskOrderNotificationName={this.taskOrderNotificationName}
                        projectOrderOptionEnabled={this.projectOrderOptionEnabled}
                        projectOrderNotificationName={this.projectOrderNotificationName}
                    />}

                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                            <AssessmentCreate
                                {...this.props}
                                mode={this.props.mode}
                                clauses={this.clauses}
                                fields={this.fields}
                                editingProperties={this.editingProperties}
                                title={this.notificationName}
                                communicateToUser={this.communicateToUser}
                                taskOrderOptionEnabled={this.taskOrderOptionEnabled}
                                taskOrderNotificationName={this.taskOrderNotificationName}
                                projectOrderOptionEnabled={this.projectOrderOptionEnabled}
                                projectOrderNotificationName={this.projectOrderNotificationName}
                            />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    element={<AssessmentDetail
                        {...this.props}
                        mode={this.props.mode}
                        clauses={this.clauses}
                        fields={this.fields}
                        editingProperties={this.editingProperties}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        documentType={this.documentType}
                        communicateToUser={this.communicateToUser}
                        notifyUserFilter={workflowItem.notifyUserFilter}
                        taskOrderOptionEnabled={this.taskOrderOptionEnabled}
                        taskOrderNotificationName={this.taskOrderNotificationName}
                        projectOrderOptionEnabled={this.projectOrderOptionEnabled}
                        projectOrderNotificationName={this.projectOrderNotificationName}
                    />}
                />
            </Routes>
        );
    }
};




export default (AssessmentRouter);