import React from 'react';
import { connect } from "react-redux";
import { ListGroup, Badge } from "react-bootstrap";
import DxRowItem from '../../../../partials/content/dxControls/dxRowBox';
import { withRouter } from '../../../../shared/hoc/withRouter';
import { Collapse } from "react-bootstrap";

class DraftNotifyArea extends React.Component {

    state = {
        expanded: false,
    }
    handleChange = event => {
        event.preventDefault();
        this.setState({ expanded: !this.state.expanded });
    };
    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.isOpen !== this.props.isOpen) {
                if (!this.props.isOpen) {
                    this.setState({ expanded: false });
                }
            }
        }
    }

    render() {

        const toggleContainerClasses = ['kt-notification__item'];
        if (this.state.expanded) {
            toggleContainerClasses.push('kt-notification__item--open');
        }
        let notification = null;
        let badgeVariant = 'secondary';
        if (this.props.warningCount && this.props.warningCount > 0) {
            notification = this.props.warningCount;
            // badgeVariant = 'danger';
        }
        else {
            notification = 0;
        }


        return (
            <>
                <a href="#0" style={{ paddingLeft: 10, fontWeight: 500, fontSize: '14px', fontFamily: '\'Helvetica Neue\', \'Segoe UI\', Helvetica, Verdana, sans-serif', color: '#333' }} onClick={this.handleChange} className={toggleContainerClasses.join(' ')}>
                    <div className="kt-notification__item-icon">
                        <Badge variant={badgeVariant}>{notification}</Badge>
                    </div>
                    <div className="kt-notification__item-details">
                        <div className="kt-notification__item-title" style={{ fontWeight: 400, fontSize: '14px', color: '#333' }}>
                            {this.props.title}
                        </div>
                    </div>
                </a>
                <Collapse in={this.state.expanded}>
                    <div>
                        <ListGroup>
                            {
                                this.props.items.map((ntfItem, index) => (
                                    <ListGroup.Item
                                        key={this.props.contractid + ntfItem.title}
                                        style={{ padding: '0.75rem 0.25rem' }}
                                        action
                                        onClick={() => {
                                            if (this.props.panelCloseBtnRef) {
                                                this.props.panelCloseBtnRef.current.click();
                                            }
                                            this.props.navigate(ntfItem.navigate)
                                        }}
                                        variant="secondary">
                                        <DxRowItem
                                            strong={this.props.dashboard[ntfItem.prop]}
                                            title={ntfItem.title}
                                        />
                                    </ListGroup.Item>
                                ))
                            }
                        </ListGroup>
                    </div>
                </Collapse>
            </>
        );
    }
}


const mapStateToProps = store => ({
    contract: store.contract.contract,
});



export default withRouter(connect(mapStateToProps)(DraftNotifyArea));