import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TaskList from './Task/List';
import TaskCreate from './Task/Create';
import TaskDetail from './Task/Detail';

const defaultFields = {
    Description: null,
    ItemsList: null,
    TotalListPrice: null,
    CompensationDescription: null,
    TotalAssessed: null,
    TotalPrice: null,
    StartDate: null,
    CompletionDate: null,
    DelayAmount: null,
    DelayPaymentPeriod: null,
}

class TaskPmRouter extends React.Component {
    constructor(props) {
        super(props);
        this.communicateToUser = props.globalResources.SUPPLIER_NAME;
        // this.notificationType = 'PM';
        this.documentType = 'DocumentPmTask';
        this.notifyingParty = props.workflowItem.notifyingParty;

        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;

        this.fields = { ...defaultFields };
        this.fields.Description = this.props.resources.DescriptionLabel;
        this.fields.ItemsList = this.props.resources.ItemsListLabel;
        this.fields.TotalListPrice = this.props.resources.TotalListPriceLabel;
        this.fields.CompensationDescription = this.props.resources.CompensationDescriptionLabel;
        this.fields.TotalAssessed = this.props.resources.TotalAssessedLabel;
        this.fields.TotalPrice = this.props.resources.TotalPriceLabel;
        this.fields.StartDate = this.props.resources.StartDateLabel;
        this.fields.CompletionDate = this.props.resources.CompletionDateLabel;
        this.fields.DelayAmount = this.props.resources.DelayAmountLabel;
        this.fields.DelayPaymentPeriod = this.props.resources.DelayPaymentPeriodLabel;

        this.CENTaskOrderOptionEnabled = false;
        if (this.props.workflowsQA['103']) {
            try {
                this.CENTaskOrderOptionEnabled = (this.props.workflowsQA['103'].placeholders.find(ph => ph.replacementKey === 'TaskOrderOptionEnabled')
                    .values.find(phItem => phItem.field === 'TaskOrderOptionEnabled').isActive);
            } catch (error) {
                console.log('TaskOrderOptionEnabled placeholder not available!');
                this.CENTaskOrderOptionEnabled = false;
            }
        }
        try {
            const TaskOrderFields = props.placeholders.find(ph => ph.replacementKey === 'Fields');
            if (TaskOrderFields) {
                TaskOrderFields.values.forEach(fieldItem => {
                    this.fields[fieldItem.field] = fieldItem.isActive ? fieldItem.display : '';
                });
            }
        } catch (error) {
            console.log('Error loading TaskOrder Fields Placeholder!');
        }

        this.rfqRelationRequired = false;

        try {
            this.rfqRelationRequired = props.placeholders.find(ph => ph.replacementKey === 'RFQRelationRequired')
                .values.find(phItem => phItem.field === 'RFQRelationRequired').isActive;
        } catch (error) {
            console.log('rfqRelationRequired placeholder not available!');
            this.rfqRelationRequired = false;
        }

        this.rfqRelationEnabled = false;

        try {
            this.rfqRelationEnabled = (props.placeholders.find(ph => ph.replacementKey === 'RFQRelationEnabled')
                .values.find(phItem => phItem.field === 'RFQRelationEnabled').isActive);
        } catch (error) {
            console.log('RFQRelationEnabled placeholder not available!');
            this.rfqRelationEnabled = false;
        }

        this.rfqNotificationName = (this.props.workflowsQA['62']||{}).notificationName;
        this.toNotificationName = (this.props.workflowsQA['38']||{}).notificationName;

        this.taskOrderCertifiedStartDateFieldName = null;

        if (props.resources.CertifiedStartDateLabel) {
            this.taskOrderCertifiedStartDateFieldName = props.resources.CertifiedStartDateLabel;
        }
        else if (this.props.workflowsQA['117']) {
            try {
                this.taskOrderCertifiedDateField = this.props.workflowsQA['117'].placeholders.find(ph => ph.replacementKey === 'TaskOrderStartFields')
                    .values.find(phItem => phItem.field === 'CertifiedDate');

                if (this.taskOrderCertifiedDateField?.isActive) {
                    this.taskOrderCertifiedStartDateFieldName = this.taskOrderCertifiedDateField.display;
                }
            } catch (error) {
                console.log('TaskOrderStartFields placeholder not available!');
            }
        }

        this.taskOrderCertifiedCompletionDateFieldName = null;

        if (props.resources.CertifiedCompletionDateLabel) {
            this.taskOrderCertifiedCompletionDateFieldName = props.resources.CertifiedCompletionDateLabel;
        }
        else if (this.props.workflowsQA['117']) {
            try {
                this.taskOrderCertifiedDateField = this.props.workflowsQA['117'].placeholders.find(ph => ph.replacementKey === 'TaskOrderCompletionFields')
                    .values.find(phItem => phItem.field === 'CertifiedDate');

                if (this.taskOrderCertifiedDateField?.isActive) {
                    this.taskOrderCertifiedCompletionDateFieldName = this.taskOrderCertifiedDateField.display;
                }
            } catch (error) {
                console.log('TaskOrderCompletionFields placeholder not available!');
            }
        }

    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;

        return (
            <>
                <Routes caseSensitive>
                    <Route
                        path={workflowItem.config.paths.list}
                        exact
                        element={<TaskList
                            {...this.props}
                            fields={this.fields}
                            CENTaskOrderOptionEnabled={this.CENTaskOrderOptionEnabled}
                            
                            title={this.notificationName}
                            abbreviation={this.abbreviation}
                            rfqNotificationName={this.rfqNotificationName}
                            notificationType={this.notificationType}
                            taskOrderCertifiedStartDateFieldName={this.taskOrderCertifiedStartDateFieldName}
                            taskOrderCertifiedCompletionDateFieldName={this.taskOrderCertifiedCompletionDateFieldName}
                        />}
                    />
                    <Route
                        path={workflowItem.config.paths.create}
                        exact
                        element={
                            workflowItem.userFilters.mainNotice.createAllowed ? (
                                <TaskCreate
                                    {...this.props}
                                    fields={this.fields}
                                    
                                    notificationType={this.notificationType}
                                    title={this.notificationName}
                                    communicateToUser={this.communicateToUser}
                                    rfqRelationEnabled={this.rfqRelationEnabled}
                                    rfqNotificationName={this.rfqNotificationName}
                                />) : (
                                <Navigate replace to={workflowItem.paths.defaultPath} />
                            )
                        }
                    />
                    <Route
                        path={'/:notificationid/*'}
                        exact
                        element={<TaskDetail
                            {...this.props}
                            fields={this.fields}
                            
                            title={this.notificationName}
                            abbreviation={this.abbreviation}
                            documentType={this.documentType}
                            communicateToUser={this.communicateToUser}
                            notificationType={this.notificationType}
                            rfqRelationRequired={this.rfqRelationRequired}
                            toNotificationName={this.toNotificationName}
                            rfqRelationEnabled={this.rfqRelationEnabled}
                            rfqNotificationName={this.rfqNotificationName}
                        />}
                    />
                </Routes>
            </>
        );
    }
}



export default (TaskPmRouter);