import React from "react";
import { Route, Routes } from "react-router-dom";
import WarningListAll from './Warning/ListAll';


class EarlyWarningRegisterRouter extends React.Component {
    constructor(props) {
        super(props);
        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;

        const pmWf = props.workflowGroupItem.workflows.find(wf => wf.id === 1);
        if (pmWf) {
            this.notificationAbbreviation_PM = pmWf.abbreviation;
        }
        const supplierWf = props.workflowGroupItem.workflows.find(wf => wf.id === 2);
        if (supplierWf) {
            this.notificationAbbreviation_SUP = supplierWf.abbreviation;
        }

    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;


        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<WarningListAll
                        {...this.props}
                        notificationAbbreviation_PM={this.notificationAbbreviation_PM}
                        notificationAbbreviation_SUP={this.notificationAbbreviation_SUP}
                        contractTemplate={this.props.contractTemplate}
                        contractUser={this.props.contractUser}
                        title={workflowItem.pageTitle}
                    />}
                />
            </Routes>
        );
    }
};




export default (EarlyWarningRegisterRouter);