import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { connect } from "react-redux";
import * as contractOtherPriceCrud from '../../../../crud/contractOtherPrice.crud';
import DataGrid, { Scrolling, ColumnFixing, HeaderFilter, FilterPanel } from 'devextreme-react/data-grid';
import { withRouter } from '../../../../shared/hoc/withRouter';
import { GetDatePriceChangeStates } from '../../../../shared/lookup';
import Common from '../../../../shared/common';
import { injectIntl } from "react-intl";
import { withSnackbar } from 'notistack';

class ContractSecondaryPriceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.gridRef = React.createRef();
    this.changeStates = GetDatePriceChangeStates(props.lang);
    this.state = {
      secondaryPriceDetails: null,
    }
  }

  componentDidMount() {
    this.fetchSecondaryPriceChanges();
  }
  componentDidUpdate() {
    this.fetchSecondaryPriceChanges();
  }
  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
  fetchSecondaryPriceChanges = async () => {
    if (this.props.secondaryPrice && !this.state.secondaryPriceDetails) {
      if (this.gridRef?.current?.instance) {
        this.gridRef.current.instance.beginCustomLoading();
      }


      const response = await contractOtherPriceCrud.fetchSecondaryPriceChanges(this.props.secondaryPrice.contractotherpriceid);
      this.setState({ secondaryPriceDetails: response.data }, () => {
        if (this.gridRef?.current?.instance) {
          this.gridRef.current.instance.option("filterValue", [["state", "=", 3], "or", ["state", "=", 5], "or", ["state", "=", 6], "or", ["state", "=", 7]]);
          this.gridRef.current.instance.endCustomLoading();
        }
        this.timer = setTimeout(() => {
          if (this.gridRef?.current?.instance) {
            this.gridRef.current.instance.repaint();
          }
        }, 300);

      })
    }
  }
  onClose = () => {
    this.setState({ secondaryPriceDetails: null }, this.props.onClose())
  }
  onLinkClicked = (e) => {
    let { workflowsQA } = this.props.contractTemplate;
    const clonedItem = { ...e.row.data };
    if (workflowsQA[clonedItem.relatedworkflowid]) {
      let navigationPath = workflowsQA[clonedItem.relatedworkflowid].paths.basePath;
      if (clonedItem.relatedparentrecordid && clonedItem.relatedmodel === "Quotation") {
        navigationPath = navigationPath + clonedItem.relatedparentrecordid + "/quote/" + clonedItem.relatedrecordid;
      }
      else {
        navigationPath = navigationPath + clonedItem.relatedrecordid;
      }
      this.props.navigate(navigationPath);
    }
    else {
      this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.WARNING.RELATED_NOTICE_NOT_FOUND' }), { variant: 'warning', });
    }
  }

  render() {
    return (
      <Popup
        visible={!!this.props.secondaryPrice}
        onHiding={this.onClose}
        dragEnabled={true}
        hideOnOutsideClick={true}
        showTitle={true}
        title={this.props.intl.formatMessage({ id: 'GENERAL.CHANGE_LOG.TITLE' }, { ITEM_NAME: this.props.secondaryPrice?.name })}
        width='80vw'
        height='82vh'
      >
        <DataGrid
          ref={this.gridRef}
          width='100%'
          height='100%'
          dataSource={this.state.secondaryPriceDetails}
          keyExpr="contractotherpricechangeid"
          hoverStateEnabled={false}
          rowAlternationEnabled={true}
          noDataText={'No Data'}
          showBorders={true}
          showColumnLines={true}
          showRowLines={true}
          remoteOperations={false}
          columns={[
            {
              caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.STATE' }),
              dataField: "state",
              dataType: "string",
              lookup: {
                dataSource: this.changeStates,
                valueExpr: "value",
                displayExpr: "display"
              }
            },
            {
              caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.APPLIED_DATE' }),
              dataField: "applieddate",
              dataType: "date",
              format: 'dd/MM/yyyy'
            },
            {
              caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CHANGED_BY' }),
              dataType: "string",
              dataField: "appliedby",
            },
            {
              caption: this.props.intl.formatMessage({ id: 'SECONDARYPRICE.FORMS.PRICE_CHANGE' }),
              dataType: "number",
              dataField: "pricechange",
              format: (num) => Common.FormatCurrencyNum(num, this.props.secondaryPrice?.currency)
            },
            {
              caption: this.props.intl.formatMessage({ id: 'SECONDARYPRICE.FORMS.NEW_PRICE' }),
              dataType: "number",
              dataField: "appliednewprice",
              format: (num) => Common.FormatCurrencyNum(num, this.props.secondaryPrice?.currency)
            },
            {
              caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.RELATED_REFERENCE_ID' }),
              dataType: "string",
              dataField: "relatedreferenceid",
            },
            {
              type: "buttons",
              buttons: [{ hint: 'Go to document', icon: 'link', onClick: this.onLinkClicked }]
            }
          ]}
        >
          <HeaderFilter visible={true} />
          <FilterPanel visible={true} />
          <ColumnFixing enabled={true} />
          <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />

        </DataGrid>

      </Popup>
    );
  }
}

const mapStateToProps = store => ({
  contractTemplate: store.contract.template,
  lang: store.i18n.lang
});

export default injectIntl(withSnackbar(withRouter(connect(mapStateToProps)(ContractSecondaryPriceDetails))));