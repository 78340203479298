import React from 'react';
import { withSnackbar } from 'notistack';
import * as companyUserCrud from '../../../../crud/companyUser.crud';
import * as securityCrud from '../../../../crud/security.crud';
import * as companyDuck from '../../../../store/ducks/company.duck';
import { connect } from "react-redux";
import BiDynamicForm from "../../../../partials/layout/BiDynamicForm";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { countries, GetCurrencyCodes } from '../../../../shared/lookup';
import { injectIntl } from "react-intl";
import Common from "../../../../shared/common";
import { withRouter } from '../../../../shared/hoc/withRouter';

const overrideAuth0Enabled = process.env.REACT_APP_OVERRIDE_AUTH0_ENABLED === "true" && !!process.env.REACT_APP_SSO_METHOD;
const isFinancialLimitEnabled = process.env.REACT_APP_FINANCIAL_LIMIT_ENABLED === "true";
const isPowerBIMenusEnabled = process.env.REACT_APP_POWERBI_ENABLED === "true";

class CompanyNewUser extends React.Component {
    constructor(props) {
        super(props);
        this.currencyCodes = GetCurrencyCodes();
        this.roles = Common.getFilteredRoles(props.userProfile);
        this.userTypeOptions = this.getUserTypesOptions();
        this.state = {
            showUserLimit: false
        }
    }

    getUserTypesOptions = () => {
        const datasource = {
            loadMode: 'raw',
            cacheRawData: true,
            load: () => {
                var promise = new Promise((resolve) => {
                    companyUserCrud.getUserTypeToSelectList()
                        .then(response => {
                            resolve(response.data);
                        });
                });
                return promise;
            }
        }
        return datasource;
    }
    submitHandler = (companyUser, setSubmitting) => {
        companyUser.Website = "https://www.builtintelligence.com/";
        companyUser.CompanyID = this.props.company.companyid;
        if (!this.state.showUserLimit) {
            companyUser.userlimit = null;
            companyUser.userlimitcurrency = null;
        }
        if (this.state.showUserLimit && !companyUser.userlimit) {
            companyUser.userlimit = 0;
        }
        companyUserCrud.createAndAddUser(companyUser)
            .then(response => {
                const accountID = response.data;
                this.props.enqueueSnackbar('User successfully created with ID: ' + accountID, { variant: 'success', });

                this.props.reloadCompanyUsers();
                if (!process.env.REACT_APP_SSO_METHOD) {
                    securityCrud.sendResetLink(companyUser.Email)
                        .then(response => {
                            this.props.navigate('/admin/companies/' + this.props.company.companyid + '/users');
                        })
                        .catch(err => {
                            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                            setSubmitting(false);
                        });
                }
                else {
                    this.props.navigate('/admin/companies/' + this.props.company.companyid + '/users');
                }

            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                setSubmitting(false);
            });
    }
    onValueChanged = (field, value) => {
        if (field === "applyfinanciallimit") {
            this.setState({ showUserLimit: value });
        }
    }
    render() {
        const newCompanyUserFormConfig = {
            buttonText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CREATE" }),
            fields: [
                { label: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.USERNAME" }), dataField: 'Username', validation: { type: 'pattern', pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.USERNAME_MATCH_EMAIL" }) } },
                { label: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.EMAIL" }), dataField: 'Email', validation: { type: 'pattern', pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_MAIL_FORMAT" }) } },
                { label: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.NAME" }), dataField: 'Fullname' },
                { label: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.JOB_TITLE" }), dataField: 'jobtitle', required: false },
                { label: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.TELEPHONE" }), dataField: 'Telephone', required: false, validation: { type: 'pattern', pattern: /^[0-9+]*$/, warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_PHONE_NUMBER" }) } },
                // { label: 'Password', dataField: 'password', editorOptions: { mode: 'password' }, validationPattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/, validationWarning: 'Password must be minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character!' },
                { label: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.COUNTRY" }), dataField: 'Country', editorType: 'dxSelectBox', editorOptions: { dataSource: countries, defaultValue: 'United Kingdom' } },
                { label: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.TYPE" }), dataField: 'Usertype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.userTypeOptions } },
            ]
        };
        if (process.env.REACT_APP_SSO_METHOD === 'DISCOVER' && this.props.company.companytenants?.length > 0) {
            const defaultTenantIDValue = this.props.company.companytenants.length === 1 ?
                this.props.company.companytenants[0].companytenantid :
                null;
            newCompanyUserFormConfig.fields.push(
                {
                    label: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.SSO_TENANT" }),
                    tooltipResource: 'COMPANY.USER.FIELD.SSO_TENANT_TOOLTIP',
                    dataField: 'companytenantid',
                    editorType: 'dxSelectBox',
                    required: false,
                    editorOptions: {
                        dataSource: this.props.company.companytenants,
                        valueExpr: 'companytenantid',
                        displayExpr: 'description',
                        defaultValue: defaultTenantIDValue,
                        showClearButton: true
                    }
                }
            );
        }
        if (isFinancialLimitEnabled) {
            newCompanyUserFormConfig.fields.push(
                { label: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.APPLYFINANCIALLIMIT" }), dataField: 'applyfinanciallimit', editorType: 'dxSwitch', editorOptions: { switchedOffText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }) } },
            );
        }
        if (this.state.showUserLimit) {
            newCompanyUserFormConfig.fields.push(
                { label: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.LIMIT" }), dataField: 'userlimit', required: false, editorType: 'dxNumberBox', editorOptions: { format: '#,##0.00', placeholder: '0.00', min: 0 } },
                { label: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.CURRENCY" }), dataField: 'userlimitcurrency', editorType: 'dxSelectBox', editorOptions: { dataSource: this.currencyCodes, valueExpr: 'code', displayExpr: 'name', defaultValue: 'GBP' } }
            );
        }

        if (isPowerBIMenusEnabled) {
            newCompanyUserFormConfig.fields.push(
                { label: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.ALLOW_POWERBI_MENUS" }), dataField: 'allowpowerbimenus', editorType: 'dxSwitch', editorOptions: { switchedOffText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }), hint: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.ALLOW_POWERBI_MENUS_TOOLTIP" }) } }
            )
        }

        if (overrideAuth0Enabled) {
            newCompanyUserFormConfig.fields.push(
                { label: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.ALLOW_NON_SSO" }), dataField: 'OverrideSSOAuthentication', editorType: 'dxSwitch', editorOptions: { switchedOffText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }), hint: this.props.intl.formatMessage({ id: "COMPANY.USER.FIELD.ALLOW_NON_SSO_TOOLTIP" }) } },
            )
        }


        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: "COMPANY.USER.CREATE.TITLE" })} />
                <PortletBody style={{ maxWidth: 800 }}>
                    <BiDynamicForm
                        config={newCompanyUserFormConfig}
                        onSubmitHandler={this.submitHandler}
                        customFields={this.props.company.usercustomfields}
                        valueChangeHandler={this.onValueChanged}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    userProfile: store.auth.userProfile,
    company: store.company.company,
});
const mapDispatchToProps = {
    reloadCompanyUsers: companyDuck.actions.reloadUsers
}

export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CompanyNewUser))));
