/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,eqeqeq */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { FormattedMessage, injectIntl } from "react-intl";
import clsx from "clsx";
import * as authDuck from "../../store/ducks/auth.duck";
import * as authCrud from "../../crud/auth.crud";
// import * as actions from '../../store/actions/index';
import { toAbsoluteUrl } from "../../../_metronic";
import { isIE } from 'react-device-detect';
import { Alert } from "react-bootstrap";

import { TextBox } from 'devextreme-react/text-box';
import { Validator, RequiredRule } from 'devextreme-react/validator';
import { PatternRule } from 'devextreme-react/form';

const debugAuthToken = process.env.REACT_APP_DEBUG_AUTH_TOKEN;
const showSupportLinkOnLogin = process.env.REACT_APP_SHOW_SUPPORT_LINK_ON_LOGIN === 'true';



function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const handleSubmit = (e) => {
    setDisabled(true);
    e.preventDefault();
    const emailaddress = email

    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    // const { intl } = this.props;
    if (regex.test(emailaddress) && password != '') {

      if (debugAuthToken) {
        props.storeSetAuthToken(debugAuthToken, "", "", "");
      }
      else {
        enableLoading();
        authCrud.login(email, password)
          .then(result => {
            disableLoading();
            if (process.env.REACT_APP_ENVIRONMENT === 'DEV') {
              console.log('Auth Token Result:', result.data);
            }
            props.storeSetAuthToken(
              result.data.access_token, 
              result.data.reportingToken, 
              result.data['.issued'], 
              result.data['.expires'],
              "PASSWORD"
            );

          })
          .catch((err) => {
            disableLoading();
            props.enqueueSnackbar(err.toString(), { variant: 'error', });
            setDisabled(false)
            //let errorMessage = intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_LOGIN" });
            if (err instanceof Error) {
              if (err.message && err.message === 'NETWORK_ERROR') {
                //errorMessage = intl.formatMessage({ id: "AUTH.LOGIN.ERROR_CONNECTION" });
              }
              else if (err.message) {
                //errorMessage = err.message;
              }
            }
          });
      }

    } else {

      setDisabled(false)

    }


  }


  const style1 = {
    //borderStyle: 'solid',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  }
  const style2 = {
    //borderStyle: 'solid',
    //marginBottom: '1rem',
    textAlign: 'center',
    height: '50%',
    width: '100%',
    verticalAlign: 'bottom'
  }

  const style3 = {
    display: 'inline-block',
    //justifyContent: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '30%',
    flexDirection: 'column',
    //marginBottom: '3rem',
    maxWidth: '850px',
    verticalAlign: 'bottom'
  }


  return (
    <>
      {/* <div className="kt-login__head">
        <span className="kt-login__signup-label">
          Don't have an account yet?
        </span>
        &nbsp;&nbsp;
        <Link to="/auth/registration" className="kt-link kt-login__signup-link">
          Sign Up!
        </Link>
      </div> */}
      {/* <div style={style1}>
      <h3 className="kt-login__title">Built Intelligence Contract Management</h3>
      <h3 className="kt-login__title">Built Intelligence Contract Management</h3>
      </div> */}
      {isIE &&
        (
          <Alert variant="warning">
            <p>
              <Alert.Heading><FormattedMessage id="AUTH.LOGIN.IE_WARNING_HEADER" /></Alert.Heading>
              <FormattedMessage id="AUTH.LOGIN.IE_WARNING_MESSAGE" />
            </p>
          </Alert>
        )
      }

      <div className="kt-login__body" style={style1}>
        <div style={style3}>
          <div style={style2}>
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/logo-dark-2.png")}
              style={{ width: '300px' }}
            />
          </div>
          <div style={style2}>
            <h3 className="kt-login__title" style={{ color: '#8D8E8C' }}><FormattedMessage id="AUTH.GENERAL.PRODUCT_NAME" /></h3>
          </div>
        </div>


        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>

          </div>


          <form onSubmit={handleSubmit}  >
            <div className="form-group">

              <TextBox
                name="username"
                type="email"
                label={intl.formatMessage({ id: "AUTH.INPUT.USERNAME" })}
                labelMode="static"
                margin="normal"
                className="kt-width-full"
                onValueChanged={e => setEmail(e.value)}
                // valueChangeEvent="input"
                disabled={disabled}
                inputAttr={{ id: "username", style: "padding:14px", autocomplete: "off" }}
              >
                <Validator>
                  <RequiredRule message="Email is required" />
                  <PatternRule message="Invalid Email Format" pattern={emailPattern} />

                </Validator>
              </TextBox>
            </div>
            <div className="form-group">
              <TextBox
                mode="password"
                margin="normal"
                label={intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
                labelMode="static"
                className="kt-width-full"
                onValueChanged={e => setPassword(e.value)}
                disabled={disabled}
                inputAttr={{ id: "password", style: "padding:14px", autocomplete: "off" }}
              >
                <Validator>
                  <RequiredRule
                    message="Password Required" />
                </Validator>
              </TextBox>
            </div>

            <div className="kt-login__actions">
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <Link
                  to="/auth/forgot-password"
                  className="kt-link kt-login__link-forgot"
                >
                  <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                </Link>
                {
                  showSupportLinkOnLogin && (
                    <a className="kt-link kt-login__link-forgot" onClick={(e) => { e.preventDefault(); window.location = 'mailto:support@builtintelligence.com'; }}><FormattedMessage id="AUTH.GENERAL.CONTACT_SUPPORT" /></a>
                  )
                }
              </div>

              <button
                disabled={disabled}
                id="kt_login_signin_submit"
                type="submit"
                text="Submit"

                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                  {
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                  }
                )}`}
                style={loadingButtonStyle}
              >
                <FormattedMessage id="AUTH.LOGIN.BUTTON" />
              </button>
            </div>

          </form>


          {/* <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              if (debugAuthToken) {
                props.storeSetAuthToken(debugAuthToken, "", "", "");
              }
              else {
                enableLoading();
                authCrud.login(values.email, values.password)
                  .then(result => {
                    disableLoading();
                    // if (process.env.REACT_APP_ENVIRONMENT === 'DEV') {
                    //   console.log('Auth Token Result:', result.data);
                    // }
                    props.storeSetAuthToken(result.data.access_token, result.data.reportingToken, result.data['.issued'], result.data['.expires']);
                  })
                  .catch((err) => {
                    disableLoading();
                    setSubmitting(false);
                    let errorMessage = intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_LOGIN" });
                    if (err instanceof Error) {
                      if (err.message && err.message === 'NETWORK_ERROR') {
                        errorMessage = intl.formatMessage({ id: "AUTH.LOGIN.ERROR_CONNECTION" });
                      }
                      else if (err.message) {
                        errorMessage = err.message;
                      }
                    }
                    setStatus(errorMessage);
                  });
              }

            }
            }
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  null
                  // <div role="alert" className="alert alert-info">
                  //   <div className="alert-text">
                  //     Use account <strong>admin@demo.com</strong> and password{" "}
                  //     <strong>demo</strong> to continue.
                  //   </div>
                  // </div>
                )}

                <div className="form-group">
                  <TextField
                    type="email"
                    label={intl.formatMessage({ id: "AUTH.INPUT.USERNAME" })}
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    disabled={isSubmitting}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={(values.email || '')}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                    InputLabelProps={{ shrink: true}} 
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label={intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
                    className="kt-width-full"
                    name="password"
                    disabled={isSubmitting}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={(values.password || '')}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                    InputLabelProps={{ shrink: true}} 
                  />
                </div>

                <div className="kt-login__actions">
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <Link
                      to="/auth/forgot-password"
                      className="kt-link kt-login__link-forgot"
                    >
                      <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                    </Link>
                    {
                      showSupportLinkOnLogin && (
                        <a className="kt-link kt-login__link-forgot" onClick={(e) => { e.preventDefault(); window.location = 'mailto:support@builtintelligence.com'; }}><FormattedMessage id="AUTH.GENERAL.CONTACT_SUPPORT" /></a>
                      )
                    }
                  </div>

                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                  </button>
                </div>
              </form>
            )}
          </Formik>  */}
          {/* 
          <div className="kt-login__divider">
            <div className="kt-divider">
              <span />
              <span>OR</span>
              <span />
            </div>
          </div> */}

          {/* <div className="kt-login__options">
            <Link to="http://facebook.com" className="btn btn-primary kt-btn">
              <i className="fab fa-facebook-f" />
              Facebook
            </Link>
            <Link to="http://twitter.com" className="btn btn-info kt-btn">
              <i className="fab fa-twitter" />
              Twitter
            </Link>
            <Link to="google.com" className="btn btn-danger kt-btn">
              <i className="fab fa-google" />
              Google
            </Link>
          </div> */}
        </div>

      </div>
    </>
  );
}

// const mapDispatchToProps = dispatch =>{
//   return { ...auth.actions
//     // , onSuccess: (token,userId,userName) => dispatch(actions.authSuccess(token,userId,userName))
//   };
// }

// export default injectIntl(
//   connect(
//     null,
//     mapDispatchToProps
//   )(Login)
// );
// const mapStateToProps = store => ({
//   auth: store.auth,
// });
const mapDispatchToProps = {
  storeSetAuthToken: authDuck.actions.setAuthToken
};

export default injectIntl(withSnackbar(connect(null, mapDispatchToProps)(Login)));