import { placeholderTypes } from "../../Types/Placeholder"
const placeholders = [
    {
        replacementKey: 'Resources', values:
            [
                { id: 1, isActive: true, field: 'PmAssumptionLabel', display: 'In accordance with NEC4 PSC clause 61.6 The following $PM_NAME assumptions shall be considered' },
                { id: 2, isActive: true, field: 'PmAssumptionNote', display: 'In accordance with NEC4 ECC clause 61.1 you are instructed to provide a quote by $date' },
                { id: 3, isActive: true, field: 'SupplierDescNote', display: 'In accordance with NEC4 ECC clause 61.3 I notify you that the event described below has happened which: We believe is a compensation event, Has happened less than 5 weeks since we became aware of the event, You have not notified to us.' },
                { id: 4, isActive: true, field: 'ReplyAcceptedCommentLabel', display: 'In accordance with NEC4 PSC clause 61.4 you are notified that the $SUPPLIER_NAME Compensation Event Notification is a Compensation Event' },
                { id: 5, isActive: true, field: 'ReplyRejectedCommentLabel', display: 'In accordance with NEC4 PSC clause 61.4 you are notified that the $SUPPLIER_NAME Compensation Event Notification is Not a Compensation Event' },
                { id: 6, isActive: true, field: 'ReplyAcceptedAssumptionLabel', display: 'In accordance with NEC4 PSC clause 61.6 The following $PM_NAME assumptions shall be considered' },
                { id: 7, isActive: true, field: 'QuoteNote', display: 'In accordance with NEC4 ECC clause 62.3 please find attached our quotation for $reference' },
                { id: 8, isActive: true, field: 'ExtensionPMLabel', display: 'We require an extension to the contractual time to produce a compensation event quotation response as detailed below for the following reasons' },
                { id: 9, isActive: true, field: 'ExtensionSupplierLabel', display: 'We require an extension to the contractual time to produce a compensation event quotation as detailed below for the following reasons' },
                { id: 10, isActive: true, field: 'AssessmentDetailLabel', display: 'Details' },
                { id: 11, isActive: true, field: 'ProposedInstructionNote', display: 'In accordance with NEC4 ECC clause 61.1 you are instructed to provide a quote by $date' },
                { id: 12, isActive: true, field: 'ProposedInstructionLabel', display: 'In accordance with NEC4 PSC clause 61.6 The following $PM_NAME assumptions shall be considered' },
                { id: 13, isActive: true, field: 'ReplyRejectedOptionsLabel', display: 'I have decided that the Compensation Event Notification' },
                { id: 14, isActive: true, field: 'QuoteCostEffectLabel', display: 'Cost effect on total of the prices' },
                { id: 15, isActive: true, field: 'QuoteDateEffectLabel', display: 'Effect to completion date' },
                { id: 16, isActive: true, field: 'QuoteDaysEffectLabel', display: 'Number of days effect to the completion date' },
                { id: 17, isActive: true, field: 'AssessmentCostEffectLabel', display: 'Cost effect on total of the prices' },
                { id: 18, isActive: true, field: 'AssessmentDaysEffectLabel', display: 'Number of days effect to the completion date' },
                { id: 19, isActive: true, field: 'QuoteProgrammeAttachLabel', display: 'Are you attaching a programme showing the effect against the last Accepted Programme with this quotation?' },
                { id: 20, isActive: true, field: 'CompensationTypeLabel', display: 'Compensation Event Type' },
                { id: 21, isActive: true, field: 'AssessmentCompensationLabel', display: 'Compensation Event' },
                { id: 22, isActive: true, field: 'AssessmentWarningBanner', display: 'The $PM_NAME only makes an assessment: if the $SUPPLIER_NAME has not submitted a quotation in the time allowed; if the $PM_NAME decides that a quotation is not assessed correctly and notifies the $SUPPLIER_NAME that the $PM_NAME will assess; if the $SUPPLIER_NAME has not submitted a programme or updated the programme by the time stated in the contract; or the latest submitted programme has not been accepted.' },
                { id: 23, isActive: true, field: 'PmCompensationWarningBanner', display: "If the $PM_NAME decides that the $SUPPLIER_NAME did not give an early warning, the $PM_NAME must immediately issue a notice to that effect using the 'Notification Workflow'" },
                { id: 24, isActive: true, field: 'InstructionQuoteReplyWarningBanner', display: "If the quotation is accepted, the $PM_NAME issues a relevant instruction using the \"Instructions Workflow\"" },
                { id: 25, isActive: true, field: 'QuoteNoteProposedInstruction', display: 'We submit our quotation for proposed compensation event $reference', skipDefaultLoadingIfMissing: true },
                { id: 26, isActive: true, field: 'QuoteContinuingEffectLabel', display: 'Does this Claim have a continuing effect (i.e. is this an Interim Claim)' },
                { id: 27, isActive: true, field: 'SupplierCompensationWarningBanner', display: "", skipDefaultLoadingIfMissing: true },
                { id: 28, isActive: true, field: 'SupplierClaimWithQuotationWarningBanner', display: "", skipDefaultLoadingIfMissing: true },
                { id: 29, isActive: true, field: 'SupplierQuoteWarningBanner', display: "", skipDefaultLoadingIfMissing: true },
                { id: 30, isActive: true, field: 'QuoteReplyWarningBanner', display: "", skipDefaultLoadingIfMissing: true },
                { id: 31, isActive: true, field: 'SupplierClaimsWarningBanner', display: "", skipDefaultLoadingIfMissing: true },
                { id: 32, isActive: true, field: 'SupplierCompensationReplyWarningBanner', display: "If the $PM_NAME decides that the $SUPPLIER_NAME did not give an '$WF_1_NOTIFICATION_NAME', the $PM_NAME must state that in their reply" },
                { id: 33, isActive: true, field: 'ClientCompensationWarningBanner', display: "", skipDefaultLoadingIfMissing: true },
                { id: 34, isActive: true, field: 'CenReplyMissingDueToDeemAcceptance', display: "CEN Reply Document is not available due to Deemed Acceptence" },
                { id: 35, isActive: true, field: 'ReplyAcceptedAssumptionNote', display: 'You are notified of you are instructed to submit a quotation by $date', skipDefaultLoadingIfMissing: true },
            ],
        type: placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'ActionReminders', values:
            [
                { id: 1, isActive: true, field: 'PmCompensationAQ', display: 'Compensation Events notified by $PM_ABBREVIATION awaiting quotes' },
                { id: 2, isActive: true, field: 'PmCompensationARQ', display: 'Compensation Events notified by $PM_ABBREVIATION awaiting revised quotes' },
                { id: 3, isActive: true, field: 'PmCompensationAPMRQ', display: 'Compensation Events notified by $PM_ABBREVIATION awaiting response to quotes' },
                { id: 4, isActive: true, field: 'PmCompensationAPMA', display: 'Compensation Events notified by $PM_ABBREVIATION awaiting $PM_ABBREVIATION Assessment' },
                { id: 5, isActive: true, field: 'SupCompensationAQ', display: 'Compensation Events notified by $SUPPLIER_NAME awaiting quotes' },
                { id: 6, isActive: true, field: 'SupCompensationARQ', display: 'Compensation Events notified by $SUPPLIER_NAME awaiting revised quotes' },
                { id: 7, isActive: true, field: 'SupCompensationAPMR', display: 'Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION response' },
                { id: 8, isActive: true, field: 'SupCompensationAPMRQ', display: 'Compensation Events notified by $SUPPLIER_NAME awaiting response to quotes' },
                { id: 9, isActive: true, field: 'SupCompensationAPMA', display: 'Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment' },
                { id: 10, isActive: true, field: 'PmProposedAQ', display: 'Proposed Compensation Events awaiting quotes' },
                { id: 11, isActive: true, field: 'PmProposedARQ', display: 'Proposed Compensation Events awaiting revised quotes' },
                { id: 12, isActive: true, field: 'PmProposedAPMRQ', display: 'Proposed Compensation Events awaiting response to quotes' },
                { id: 13, isActive: true, field: 'PmProposedAPMA', display: 'Proposed Compensation Events awaiting $PM_ABBREVIATION Assessment' },
                { id: 14, isActive: true, field: 'PmExtension', display: '$PM_ABBREVIATION Quote Response Extensions awaiting Acceptance' },
                { id: 15, isActive: true, field: 'SupExtension', display: '$SUPPLIER_NAME Quote Extensions awaiting Acceptance' },
                { id: 15, isActive: true, field: 'SupAssumption', display: '$SUPPLIER_NAME Assumptions awaiting Acceptance' },
                { id: 16, isActive: true, field: 'SupClaimAQ', display: 'Claims notified by $SUPPLIER_NAME awaiting quotes' },
                { id: 17, isActive: true, field: 'SupClaimARQ', display: 'Claims notified by $SUPPLIER_NAME awaiting revised quotes' },
                { id: 19, isActive: true, field: 'SupClaimAPMRQ', display: 'Claims notified by $SUPPLIER_NAME awaiting response to quotes' },
                { id: 20, isActive: true, field: 'SupClaimAPMA', display: 'Claims notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment' },
                { id: 21, isActive: true, field: 'SupClaimAPMR', display: 'Claims notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION response' },
                { id: 22, isActive: true, field: 'replydraftpmcequotessupplier', display: '$SUPPLIER_NAME Compensation Event Quotes' },
                { id: 23, isActive: true, field: 'COMPENSATION_CWN_AQ', display: '$SUPPLIER_NAME Notice of Claim - Awaiting Claim Details' },
                { id: 24, isActive: true, field: 'COMPENSATION_CWN_ARQ', display: '$SUPPLIER_NAME Notice of Claim - Re-Awaiting Claim Details' },
                { id: 25, isActive: true, field: 'COMPENSATION_CWN_APMR', display: '$SUPPLIER_NAME Notice of Claim Awaiting $PM_NAME Review' },
                { id: 26, isActive: true, field: 'COMPENSATION_CWN_APMRQ', display: '$SUPPLIER_NAME Notice of Claim - awaiting response to quotes' },
                { id: 27, isActive: true, field: 'COMPENSATION_CWN_APMA', display: '$SUPPLIER_NAME Notice of Claim - awaiting $PM_NAME Assessment' },
                { id: 28, isActive: true, field: 'COMPENSATION_CLIENTCWN_AQ', display: '$CLIENT_NAME Notice of Claim - Awaiting Claim Details' },
                { id: 29, isActive: true, field: 'COMPENSATION_CLIENTCWN_ARQ', display: '$CLIENT_NAME Notice of Claim - Re-Awaiting Claim Details' },
                { id: 30, isActive: true, field: 'COMPENSATION_CLIENTCWN_APMR', display: '$CLIENT_NAME Notice of Claim Awaiting $PM_NAME Review' },
                { id: 31, isActive: true, field: 'COMPENSATION_CLIENTCWN_APMRQ', display: '$CLIENT_NAME Notice of Claim - awaiting response to quotes' },
                { id: 32, isActive: true, field: 'COMPENSATION_CLIENTCWN_APMA', display: '$CLIENT_NAME Notice of Claim - awaiting $PM_NAME Assessment' },
                { id: 33, isActive: true, field: 'COMPENSATION_CLIENT_AQ', display: 'Compensation Events notified by $CLIENT_NAME awaiting quotes' },
                { id: 34, isActive: true, field: 'COMPENSATION_CLIENT_ARQ', display: 'Compensation Events notified by $CLIENT_NAME awaiting revised quotes' },
                { id: 35, isActive: true, field: 'COMPENSATION_CLIENT_APMR', display: 'Compensation Events notified by $CLIENT_NAME awaiting $PM_ABBREVIATION response' },
                { id: 36, isActive: true, field: 'COMPENSATION_CLIENT_APMRQ', display: 'Compensation Events notified by $SUPERVISOR_NAME awaiting response to quotes' },
                { id: 37, isActive: true, field: 'COMPENSATION_CLIENT_APMA', display: 'Compensation Events notified by $CLIENT_NAME awaiting $PM_ABBREVIATION Assessment' },

            ],
        type: placeholderTypes.ACTION_REMINDERS
    },
    {
        replacementKey: 'CompensationClauses', values:
            [
                { id: 1, isActive: true, field: '', display: '60.1(6) Late reply to communication' },
                { id: 2, isActive: true, field: '', display: '60.1(1) Employer has given an instruction to change the Scope' },
                { id: 3, isActive: true, field: '', display: '60.1(3) Something not provided' },
                { id: 4, isActive: true, field: '', display: '60.1(5) Work by employer or others' },
                { id: 5, isActive: true, field: '', display: '60.1(7) Previous decision changed' },
                { id: 6, isActive: true, field: '', display: '60.1(2) Access not provided by date stated within the contract' },
                { id: 7, isActive: true, field: '', display: '60.1(4) Stop or change key date' },
                { id: 8, isActive: true, field: '', display: '60.1(9) PM corrects an assumption related to a previous compensation event' },
                { id: 9, isActive: true, field: '', display: '60.1(10) Breach of Contract' },
                { id: 10, isActive: true, field: '', display: '60.1(8) Acceptance Withheld' },
                { id: 11, isActive: true, field: '', display: '60.1(11) Prevention event' },
                { id: 12, isActive: true, field: '', display: '60.1(12) Defect corrected which was not the Consultant’s liability' },
                { id: 13, isActive: true, field: '', display: '60.1(13): Instruction to change Task Order issued by Employer' },
                { id: 14, isActive: true, field: '', display: '60.1(14): Consultant receives late Task Order' },
                { id: 15, isActive: true, field: '', display: '60.1(15): a Task Completion Date is later than the Completion Date' },
                { id: 16, isActive: true, field: '', display: 'X2.1: Change in law' },
            ],
        allowOverrideResources: true,
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'ReplyRejectOptions', values:
            [
                { id: 1, isActive: true, field: 'faultpscp', display: 'Arises from a fault of the $SUPPLIER_NAME' },
                { id: 2, isActive: true, field: 'notexpectnothappen', display: 'Has not happened and is not expected to happen' },
                { id: 3, isActive: true, field: 'noteffectcostcompletion', display: 'Has no effect on Defined Cost, Completion or meeting a Key Date' },
                { id: 4, isActive: true, field: 'statedcontract', display: 'Is not one of the compensation events stated in this contract' },
                { id: 5, isActive: false, field: 'notnotifiedwithintimescales', display: 'Has not been notified within the timescales set out in the contract' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'ClaimClauses', values:
            [
                { id: 1, isActive: true, field: '', display: '60.1(6) Late reply to communication' },
                { id: 2, isActive: true, field: '', display: '60.1(1) Employer has given an instruction to change the Scope' },
                { id: 3, isActive: true, field: '', display: '60.1(3) Something not provided' },
                { id: 4, isActive: true, field: '', display: '60.1(5) Work by employer or others' },
                { id: 5, isActive: true, field: '', display: '60.1(7) Previous decision changed' },
                { id: 6, isActive: true, field: '', display: '60.1(2) Access not provided by date stated within the contract' },
                { id: 7, isActive: true, field: '', display: '60.1(4) Stop or change key date' },
                { id: 8, isActive: true, field: '', display: '60.1(9) PM corrects an assumption related to a previous compensation event' },
                { id: 9, isActive: true, field: '', display: '60.1(10) Breach of Contract' },
                { id: 10, isActive: true, field: '', display: '60.1(8) Acceptance Withheld' },
                { id: 11, isActive: true, field: '', display: '60.1(11) Prevention event' },
                { id: 12, isActive: true, field: '', display: '60.1(12) Defect corrected which was not the Consultant’s liability' },
                { id: 13, isActive: true, field: '', display: '60.1(13): Instruction to change Task Order issued by Employer' },
                { id: 14, isActive: true, field: '', display: '60.1(14): Consultant receives late Task Order' },
                { id: 15, isActive: true, field: '', display: '60.1(15): a Task Completion Date is later than the Completion Date' },
                { id: 16, isActive: true, field: '', display: 'X2.1: Change in law' },
            ],
        allowOverrideResources: true,
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'ProposedInstructionClauses', values:
            [

            ],
        skipDefaultLoadingIfMissing: true,
        allowOverrideResources: true,
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'CompensationStatuses', values:
            [
                { id: 1, isActive: true, field: 'APMA', display: 'Quote Not Accepted - $PM_ABBREVIATION to make own Assessment' },
                { id: 2, isActive: true, field: 'ARQ', display: 'Quote Not Accepted - $SUPPLIER_NAME to resubmit' },
                { id: 3, isActive: true, field: 'AM', display: '$PM_ABBREVIATION Assessment Made' },
                { id: 4, isActive: true, field: 'QA', display: 'Quote Accepted' },
                { id: 5, isActive: true, field: 'APMRQ', display: 'Awaiting $PM_ABBREVIATION Response to quote' },
                { id: 6, isActive: true, field: 'APMR', display: 'Awaiting $PM_ABBREVIATION Response to CEN' },
                { id: 7, isActive: true, field: 'AQ', display: 'Awaiting Quote' },
                { id: 8, isActive: true, field: 'NC', display: 'Not Communicated' },
                { id: 9, isActive: true, field: 'R', display: 'Not a Compensation Event' },
                { id: 10, isActive: true, field: 'DNG', display: 'Decision Not Given' },
                { id: 11, isActive: true, field: 'ADAQ', display: 'Awaiting Deemed Acceptance of Quote' },
                { id: 12, isActive: true, field: 'DA', display: 'Deemed Accepted' },
                { id: 13, isActive: true, field: 'ADAN', display: 'Awaiting Deemed Acceptance of Notice' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC,
        appendMissingFields: true
    },
    {
        replacementKey: 'QuoteStatuses', values:
            [
                { id: 1, isActive: true, field: '0', display: 'Not Accepted - $SUPPLIER_NAME to resubmit' },
                { id: 2, isActive: true, field: '1', display: 'Accepted' },
                { id: 3, isActive: true, field: '2', display: 'Awaiting Response' },
                { id: 4, isActive: true, field: '3', display: 'Not Accepted - $PM_ABBREVIATION to make assessment' },
                { id: 5, isActive: true, field: '4', display: 'Not Accepted - Other quote approved' },
                { id: 6, isActive: true, field: '5', display: 'Not Accepted - Decision will not be given' },
                { id: 7, isActive: true, field: '6', display: 'Awaiting Deemed Acceptance' },
                { id: 8, isActive: true, field: '7', display: 'Deemed Accepted' },
                { id: 9, isActive: true, field: 'none', display: 'Not Communicated' },
                { id: 10, isActive: true, field: '8', display: 'Acknowledged' },
                { id: 11, isActive: true, field: '9', display: 'Not Accepted - Process ended' },
                { id: 12, isActive: true, field: '10', display: 'Assessed' }
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC,
        appendMissingFields: true
    },
    {
        replacementKey: 'InstructionReplyProceedOpts', values:
            [
                { id: 1, isActive: true, field: '61.1', display: '61.1 $SUPPLIER_NAME is instructed to proceed with works' },
                { id: 2, isActive: true, field: '61.2', display: '61.2 $SUPPLIER_NAME is instructed to NOT proceed with works until instructed' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'QuoteProgrammeImpactOpts', values:
            [
                { id: 1, isActive: true, field: 'noeffect', display: 'There is no effect on the Accepted Programme' },
                { id: 2, isActive: true, field: 'mitigated', display: 'There is an effect on the Accepted Programme but this has been mitigated by measures as detailed' },
                { id: 3, isActive: true, field: 'notmitigated', display: 'There is an effect on the Accepted Programme which we are unable to mitigate' },

            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'EWRelationEnabled', values:
            [
                { id: 1, isActive: true, field: 'EWRelationEnabled', display: 'Active-Inactive state indicates whether the EWN can be attached in CEN communications' },
            ],
        isToggle: true,
        fieldRequired: true,
        type: placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'Fields', values:
            [
                { id: 1, isActive: true, field: 'Assumption', display: 'The quotation is to be based on the following assumptions' },
                { id: 2, isActive: true, field: 'ProposedInstructionGivenDate', display: 'Date instruction may be given by' },
                { id: 3, isActive: true, field: 'Instructions', display: 'Instructions' },
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'InstructionReplyProceedEnabled', values:
            [
                { id: 1, isActive: true, field: 'InstructionReplyProceedEnabled', display: 'Proposed instructions Quote Reply field proceed options Enabled/Disabled' },
            ],
        isToggle: true,
        fieldRequired: true,
        type: placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'InstructionReplyOpts', values:
            [
                { id: 1, isActive: true, field: '1', display: 'QuoteStatus_1' },
                { id: 2, isActive: true, field: '0', display: 'QuoteStatus_0' },
                { id: 3, isActive: true, field: '3', display: 'QuoteStatus_3' },
                { id: 4, isActive: true, field: '5', display: 'QuoteStatus_5' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'TaskOrderOptionEnabled', values:
            [
                { id: 1, isActive: false, field: 'TaskOrderOptionEnabled', display: 'Active-Inactive state indicates whether Task-Order can be selected in CEN communications' },
            ],
        isToggle: true,
        fieldRequired: true,
        type: placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'ProjectOrderOptionEnabled', values:
            [
                { id: 1, isActive: false, field: 'ProjectOrderOptionEnabled', display: 'Active-Inactive state indicates whether Project-Order can be selected in CEN communications' },
            ],
        isToggle: true,
        fieldRequired: true,
        type: placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'VariationClauses', values:
            [
                { id: 1, isActive: true, field: '', display: '2.6.2 An additional insurance premium for Early Use by the Employer' },
                { id: 2, isActive: true, field: '', display: '2.10 To not amend errors in the setting out information' },
                { id: 3, isActive: true, field: '', display: '2.14.1 To correct an error in the Contract Bills' },
                { id: 4, isActive: true, field: '', display: '2.14.2 To correct an error in the Employer\'s Requirements' },
                { id: 5, isActive: true, field: '', display: '2.15 To correct an error in the Contract Documents' },
                { id: 6, isActive: true, field: '', display: '2.16.2 To correct an error in the Employer\'s Requirements' },
                { id: 7, isActive: true, field: '', display: '2.17.2 To correct a divergence between Stautory Requirements and the Contractor\'s Proposals' },
                { id: 8, isActive: true, field: '', display: '2.18.1 Of emergency work in connection with a divergence from the Statutory Requirements' },
                { id: 9, isActive: true, field: '', display: '3.10 An instruction requiring a Variation' },
                { id: 10, isActive: true, field: '', display: '3.12 Of a confirmed verbal instruction' },
                { id: 11, isActive: true, field: '', display: '3.15 To postpone works' },
                { id: 12, isActive: true, field: '', display: '3.16 To expend a provisional sum' },
                { id: 13, isActive: true, field: '', display: '3.17 To open up for inspection any covered works' },
                { id: 14, isActive: true, field: '', display: '3.22 On how to deal with the discovery of fossils or antiquities' },
                { id: 15, isActive: true, field: '', display: '6.5.1 Of an insured amount under clause 6.5' },
                { id: 16, isActive: true, field: '', display: '6.13.5 Of an insured amount under clause 6.13' },
                { id: 17, isActive: true, field: '', display: '6.19 Works in connection with a breach of the Joint Fire Code' },
                { id: 18, isActive: true, field: '', display: '6.2 Amendments made to the Joint Fire Code' },
                { id: 19, isActive: true, field: '', display: 'Sch. 1.8.1 Of a Variaion in design' },
                { id: 20, isActive: true, field: '', display: 'Sch. 8.3.2 To change the specification following a proposal by the Contractor' },
                { id: 21, isActive: true, field: '', display: 'Sch. 8.9 In connection with a Named Specialist' },
            ],
        skipDefaultLoadingIfMissing: true,
        type: placeholderTypes.CLAUSES
    },
    {
        //NEEDS TO BE MOVED TO RESOURCES
        //FD-7282
        replacementKey: 'RelatedCompensationText', values:
            [
                { id: 1, isActive: true, field: 'RelatedCompensationText', display: 'Regarding the aforementioned instruction, you are hereby notified of a compensation event under clause 61.1' }
            ],
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'INTERIM_QUOTES_ALLOWED', values:
            [
                { id: 1, isActive: false, field: 'INTERIM_QUOTES_ALLOWED', display: 'Active-Inactive state indicates whether the continuing effect field is asked on quote communications' },
            ],
        isToggle: true,
        fieldRequired: true,
        type: placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'ClaimWNAcceptanceOptions', values:
            [
                { id: 1, isActive: true, field: '1', display: 'Accepted' },
                { id: 2, isActive: true, field: '0', display: 'Not Accepted' },
                { id: 3, isActive: true, field: '2', display: 'Challenged' }
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'CompensationEventsAcceptanceOptions', values:
            [
                { id: 1, isActive: true, field: '1', display: 'Is a Compensation Event' },
                { id: 2, isActive: true, field: '0', display: 'Is not a Compensation Event' }
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'OverdueExtensionsEnabled', values:
            [
                { id: 1, isActive: true, field: 'OverdueExtensionsEnabled', display: 'Active-Inactive state indicates whether or not a PM or Supplier extension request can be accepted after the due date ' },
            ],
        isToggle: true,
        fieldRequired: true,
        type: placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'PMCENReplyOpts', values:
            [
                { id: 1, isActive: true, field: '1', display: 'QuoteStatus_1' },
                { id: 2, isActive: true, field: '0', display: 'QuoteStatus_0' },
                { id: 3, isActive: true, field: '3', display: 'QuoteStatus_3' },
                { id: 4, isActive: false, field: '5', display: 'QuoteStatus_5' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'SupplierCENReplyOpts', values:
            [
                { id: 1, isActive: true, field: '1', display: 'QuoteStatus_1' },
                { id: 2, isActive: true, field: '0', display: 'QuoteStatus_0' },
                { id: 3, isActive: true, field: '3', display: 'QuoteStatus_3' },
                { id: 4, isActive: false, field: '5', display: 'QuoteStatus_5' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'SupplierCWQReplyOpts', values:
            [
                { id: 1, isActive: true, field: '1', display: 'QuoteStatus_1' },
                { id: 2, isActive: true, field: '0', display: 'QuoteStatus_0' },
                { id: 3, isActive: true, field: '3', display: 'QuoteStatus_3' },
                { id: 4, isActive: true, field: '5', display: 'QuoteStatus_5' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    // {
    //     replacementKey: 'SupplierCWNReplyOpts', values:
    //         [
    //             { id: 1, isActive: true, field: '1', display: 'QuoteStatus_1' },
    //             { id: 2, isActive: true, field: '0', display: 'QuoteStatus_0' },
    //             { id: 3, isActive: true, field: '3', display: 'QuoteStatus_3' },
    //             { id: 4, isActive: false, field: '5', display: 'QuoteStatus_5' },
    //         ],
    //     fieldRequired: true,
    //     type: placeholderTypes.GENERIC
    // },
    {
        replacementKey: 'SupplierShortCENReplyOpts', values:
            [
                { id: 1, isActive: true, field: '1', display: 'QuoteStatus_1' },
                { id: 2, isActive: true, field: '0', display: 'QuoteStatus_0' },
                { id: 3, isActive: true, field: '3', display: 'QuoteStatus_3' },
                { id: 4, isActive: false, field: '5', display: 'QuoteStatus_5' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC,
    },
    {
        replacementKey: 'FieldsPM', values:
            [
                { id: 1, isActive: true, field: 'Assumption', display: 'The quotation is to be based on the following assumptions' },
                { id: 3, isActive: true, field: 'Instructions', display: 'Instructions' },
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'FieldsCWQ', values:
            [
                { id: 1, isActive: true, field: 'Assumption', display: 'The quotation is to be based on the following assumptions' },
                { id: 3, isActive: true, field: 'Instructions', display: 'Instructions' },
                { id: 4, isActive: false, field: 'EventAwareDate', display: 'Awareness Date' },
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'InterimQuoteReplyOpts', values:
            [
                { id: 1, isActive: true, field: '8', display: 'QuoteStatus_8' },
                { id: 2, isActive: true, field: '5', display: 'QuoteStatus_5' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'ClientClaimWithNoticeClauses', values:
            [
                { id: 1, isActive: true, field: '', display: 'TEST CLAUSE' },
            ],
        allowOverrideResources: true,
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'CWNQuoteReplyOpts', values:
            [
                { id: 1, isActive: true, field: '1', display: 'QuoteStatus_1' },
                { id: 2, isActive: true, field: '0', display: 'QuoteStatus_0' },
                { id: 3, isActive: true, field: '3', display: 'QuoteStatus_3' },
                { id: 4, isActive: false, field: '5', display: 'QuoteStatus_5' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'FieldsCWN', values:
            [
                { id: 1, isActive: true, field: 'Assumption', display: 'The quotation is to be based on the following assumptions' },
                { id: 2, isActive: true, field: 'Instructions', display: 'Instructions' },
                { id: 3, isActive: true, field: 'EventAwareDate', display: 'Awareness Date' },
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'ClientQuoteReplyOpts', values:
            [
                { id: 1, isActive: true, field: '1', display: 'QuoteStatus_1' },
                { id: 2, isActive: true, field: '0', display: 'QuoteStatus_0' },
                { id: 3, isActive: true, field: '3', display: 'QuoteStatus_3' },
                { id: 4, isActive: false, field: '5', display: 'QuoteStatus_5' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'FieldsClient', values:
            [
                { id: 1, isActive: true, field: 'Assumption', display: 'The quotation is to be based on the following assumptions' },
                { id: 2, isActive: true, field: 'Instructions', display: 'Employer\'s Representative Instruction (if applicable)' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
];

export default placeholders;

