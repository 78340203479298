/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { sendResetLink } from "../../crud/security.crud";
import { withSnackbar } from 'notistack';
import { TextBox } from 'devextreme-react/text-box';
import { Validator, RequiredRule, PatternRule } from 'devextreme-react/validator';
const showSupportLinkOnLogin = process.env.REACT_APP_SHOW_SUPPORT_LINK_ON_LOGIN === 'true';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRequested: false,
      value: "",
      disabled: false
    };

    this.handleValueChange = this.handleValueChange.bind(this);
  }

  handleValueChange(e) {
    //const previousValue = e.previousValue;
    const newValue = e.value;

    this.setState({
      value: newValue
    });
  }

  emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  handleSubmit = (e) => {
    this.setState({ disabled: true });

    const value = this.state.value
    const regex = /[^\s@]+@[^\s@]+\.[^\s@]+/
    const { intl } = this.props;
    e.preventDefault();
    if (regex.test(value)) {

      sendResetLink(value)
        .then((resp) => {
          this.props.enqueueSnackbar(intl.formatMessage({ id: "AUTH.FORGOT.SUCCESS_MAIL_SENT" }), { variant: 'success', });
          this.setState({ isRequested: true });
        })
        .catch(() => {
          this.setState({ disabled: false });
        });

    } else {
      this.setState({ disabled: false });
      this.props.enqueueSnackbar(intl.formatMessage({ id: "AUTH.EMAIL.INVALID" }), { variant: 'error', });
    }
  }


  render() {
    const { intl } = this.props;
    const { isRequested } = this.state;
    if (isRequested) {
      return <Navigate replace to="/auth" />;
    }

    return (
      <>
        <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
          <div className="kt-login__body">
            <div className="kt-login__form">
              <div className="kt-login__title">
                <h3>
                  <FormattedMessage id="AUTH.FORGOT.TITLE" />
                </h3>
              </div>
              <form className="kt-form" action="/Login" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <TextBox
                    type="email"
                    label={intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
                    labelMode="static"
                    margin="normal"
                    className="kt-width-full"
                    value={this.state.value}
                    onValueChanged={this.handleValueChange}
                    disabled={this.state.disabled}
                    inputAttr={{ style: "padding:14px", autocomplete: "off" }}
                  >
                    <Validator>
                      <RequiredRule message="Email is required" />
                      <PatternRule message="Invalid Email Format" pattern={this.emailPattern} />
                    </Validator>
                  </TextBox>
                </div>

                <div className="kt-login__actions">
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <Link
                      to="/auth/login"
                      className="kt-link kt-login__link-forgot"
                    >
                      <FormattedMessage id="AUTH.GENERAL.BACK_BUTTON" />
                    </Link>
                    {
                      showSupportLinkOnLogin && (
                        <a className="kt-link kt-login__link-forgot" onClick={(e) => { e.preventDefault(); window.location = 'mailto:support@builtintelligence.com'; }}><FormattedMessage id="AUTH.GENERAL.CONTACT_SUPPORT" /></a>
                      )
                    }
                  </div>

                  <button

                    type="submit"
                    className="btn btn-primary btn-elevate kt-login__btn-primary"
                    disabled={this.state.disabled}

                  >
                    <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                  </button>

                </div>
              </form>

            </div>
          </div>
        </div>

        {/* <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>
                <FormattedMessage id="AUTH.FORGOT.TITLE" />
              </h3>
            </div>

            <Formik
              initialValues={{ email: "" }}
              validate={values => {
                const errors = {};

                if (!values.email) {
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                } else if (
                  !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(values.email)
                ) {
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_FIELD"
                  });
                }

                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                sendResetLink(values.email)
                  .then((resp) => {
                    this.props.enqueueSnackbar(intl.formatMessage({ id: "AUTH.FORGOT.SUCCESS_MAIL_SENT" }), { variant: 'success', });
                    this.setState({ isRequested: true });
                  })
                  .catch(() => {
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage(
                        { id: "AUTH.VALIDATION.NOT_FOUND" },
                        { name: values.email }
                      )
                    );
                  });
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit} className="kt-form">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}

                  <div className="form-group">
                    <TextField
                      type="email"
                      label={intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
                      margin="normal"
                      fullWidth={true}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      helperText={touched.email && errors.email}
                      error={Boolean(touched.email && errors.email)}
                    />
                  </div>

                  <div className="kt-login__actions">
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                      <Link
                        to="/auth/login"
                        className="kt-link kt-login__link-forgot"
                      >
                        <FormattedMessage id="AUTH.GENERAL.BACK_BUTTON" />
                      </Link>
                      {
                        showSupportLinkOnLogin && (
                          <a className="kt-link kt-login__link-forgot" onClick={(e) => { e.preventDefault(); window.location = 'mailto:support@builtintelligence.com'; }}><FormattedMessage id="AUTH.GENERAL.CONTACT_SUPPORT" /></a>
                        )
                      }
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary btn-elevate kt-login__btn-primary"
                      disabled={isSubmitting}
                    >
                      <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                    </button>

                  </div>

                </form>

              )}
            </Formik>
          </div>
        </div>
      </div> */}
      </>
    );
  }
}

export default withSnackbar(injectIntl(
  connect(
    null,
    auth.actions
  )(ForgotPassword)
));
