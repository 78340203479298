import React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { injectIntl } from "react-intl";
import SharepointFileList from './SharepointFileList';
import * as fileCrud from '../../../crud/file.crud';
import axios from 'axios';
import { connect } from "react-redux";
import ScrollView from 'devextreme-react/scroll-view';

export const RESPONSE = {
    OK: 'OK',
    CANCEL: 'CANCEL',
};

class SharepointDialogModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItems: null
        }
    }

    onClose = () => {
        this.props.onDialogResult(RESPONSE.CANCEL);
    }

    onAttachClicked = async () => {
        const { companyid, projectid, contractid } = this.props.contract;
        try {
            const promises = []
            if (this.state.selectedItems && this.state.selectedItems.length > 0) {
                this.state.selectedItems.forEach(item => {
                    const metadata = [{ metakey: "directlink", metavalue: item.WebUrl }];
                    const exFile = {
                        companyid,
                        projectid,
                        contractid,
                        type: this.props.isReply ? this.props.documentType + 'Reply' : this.props.documentType,
                        reference: this.props.notificationid,
                        title: item.Name,
                        metadata
                    }
                    promises.push(fileCrud.uploadExternalFile(exFile));
                });
            }
            await axios.all(promises);
        } catch (error) {

        }

        this.props.onDialogResult(RESPONSE.OK);
    }
    onSelectionChanged = (newSelectionArr) => {
        this.setState({ selectedItems: newSelectionArr });
    }
    render() {
        return (
            <Popup
                visible={this.props.visible}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showTitle={true}
                title={this.props.intl.formatMessage({ id: "GENERAL.DOCUMENTS.MODAL.TITLE.SHAREPOINT" })}
                width='50vw'
                maxWidth={800}
                minWidth={500}
                height='82vh'
            >
                <ScrollView width='100%' height='100%'>
                    <div>
                        {
                            this.props.visible && (
                                <SharepointFileList
                                    onSelectionChanged={this.onSelectionChanged}
                                    assetid={this.props.assetid}
                                    contractorcompanyid = {this.props.contract.contractorcompanyid}
                                />
                            )
                        }
                    </div>
                </ScrollView>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CANCEL" }), onClick: this.onClose }}>
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: "Attach", disabled: false, onClick: this.onAttachClicked }}>
                </ToolbarItem>
            </Popup>
        );
    }
}
const mapStateToProps = store => ({
    contract: store.contract.contract,
});

export default injectIntl(connect(mapStateToProps)(SharepointDialogModal));