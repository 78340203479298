import axios from '../shared/axios';
// import qs from 'querystring';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function get(paymentbatchid) {
    return axios.get(`/api/PaymentBatch/Get`, { params: { paymentbatchid } });
}

export function batchCreateDrafts(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/PaymentBatch/BatchCreateDrafts`, data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}
export function batchNotify(data, contractid, paymentbatchid) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/PaymentBatch/BatchNotify`, data, { params: { contractid, paymentbatchid } })
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_CONTRACT });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}
export function getRegisterList(contractid, notificationtype) {
    return axios.get(`/api/PaymentBatch/RegisterList`, { params: { contractid , notificationtype} });
}