import React from 'react';
import ResourcesListManager from './ResourcesListManager';


class TabResources extends React.Component {

    onListUpdated = (newList) => {
        this.props.resourcesChanged(newList);
    }
    render() {
        return (
            <div style={{ padding: 20, width: '100%', height: '100%' }}>
                <ResourcesListManager
                    items={this.props.resources}
                    itemDescription="Resource"
                    listChanged={this.onListUpdated}
                    readOnly = {this.props.readOnly}
                />
            </div>

        );
    }

}

export default TabResources;