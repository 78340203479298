import { userFilterCategories } from "../../../shared/lookup";
const workflows = [

    {
        id: 20,
        title: '$PM_NAME Payment Certificates',
        notificationName: 'Payment Certificate',
        abbreviation: '$PM_ABBREVIATION-Payment-',
        notifyingParty: 'PM',
        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Payment Certificates',
            currentModel: 'PaymentCertificate',
            currentModelType: 'Certificate',
            referenceType: 'Payment',
            documentType:'DocumentCertificate',


            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableFinancialLimit: true,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm',
                create: 'create',
                list: 'list',
                default: 'list'
            }

        }
    },
    {
        id: 21,
        title: '$SUPPLIER_NAME Payment Applications',
        notificationName: 'Payment Application',
        abbreviation: '$SUPPLIER_ABBREVIATION-Payment-',
        notifyingParty: 'SUPPLIER',
        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Payment Applications',
            currentModel: 'PaymentCertificate',
            currentModelType: 'Application',
            referenceType: 'Payment',
            documentType:'DocumentApplication',

            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableFinancialLimit: true,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supplier',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 45,
        title: 'Activity Schedule',
        notificationName: 'Activity Schedule',
        abbreviation: 'Activity-',
        config: {
            description: 'Activity Schedule',
            showInRelations: false,
            showInCustomFields: false,
            replyPossible: false,
            userFilterCategories: [
                userFilterCategories.ACCESS,
            ],
            paths: {
                base: 'activity',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 78,
        title: 'Batch Payment Certificates',
        notificationName: 'Batch Certificate',
        abbreviation: 'CERT-BATCH-',
        notifyingParty: 'PM',
        notify: true,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',
        config: {
            description: 'Batch Payments Certificates',
            currentModel: 'PaymentBatch',
            currentModelType: 'Certificate',
            referenceType: 'PaymentBatch',
            documentType:'DocumentCertificate',

            notifyPossible: true,
            showInRelations: false,
            showInCustomFields: false,
            replyPossible: false,
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'certificate-batch',
                create:'list#wizard',
                list: 'list',
                default: 'list'
            },
            enabled: () => (process.env.REACT_APP_PAYMENT_BATCH_ENABLED === "true")
        }
    },
    {
        id: 79,
        title: 'Batch Payment Applications',
        notificationName: 'Batch Application',
        abbreviation: 'APP-BATCH-',
        notifyingParty: 'SUPPLIER',
        notify: true,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        config: {
            description: 'Batch Payments Applications',
            currentModel: 'PaymentBatch',
            currentModelType: 'Application',
            referenceType: 'PaymentBatch',
            documentType:'DocumentApplication',

            notifyPossible: true,
            showInRelations: false,
            showInCustomFields: false,
            replyPossible: false,
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'application-batch',
                create:'list#wizard',
                list: 'list',
                default: 'list'
            },
            enabled: () => (process.env.REACT_APP_PAYMENT_BATCH_ENABLED === "true")
        }
    }

]

export default workflows;