import React from 'react';
import { Popup, ToolbarItem as PopupToolbarItem } from 'devextreme-react/popup';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import DxOrderList from '../../../../../../partials/content/dxControls/dxOrderList';
import DxGroupBox from '../../../../../../partials/content/dxControls/dxGroupBox';
import { withSnackbar } from 'notistack';
import { Badge } from "react-bootstrap";
import { confirm } from 'devextreme/ui/dialog';
import ClauseValueEditor from './ClauseValueEditor';
import { TextArea } from 'devextreme-react';
import { injectIntl } from "react-intl";

const emptyPlaceHolder = {
    // id: 0,
    replacementKey: '',
    values: [],
}
const emptyValue = {
    id: 0,
    isActive: true,
    field: '',
    display: '',
}
const emptyState = {
    placeholder: { ...emptyPlaceHolder },
    ///SELECTED LIST ITEM (ONE VALUE IN PLACEHOLDER)
    value: { ...emptyValue },
    phChanged: false,
    allowCustomReplyPeriods: false,
    allowConfigureReplyParty: false,
    allowOverrideResources: false,
    availableEffects: null
};
//const partySelection = ["PM", "SUPPLIER", "SUPERVISOR", "CLIENT"];
const clauseMaxLength = 250;

class PHModalClauses extends React.Component {
    constructor() {
        super();
        const emptyState = this.getEmptyState();
        this.state = emptyState;
        this.listReference = React.createRef();
    }

    getEmptyState = () => {
        return {
            ...emptyState,
            placeholder: { ...emptyPlaceHolder },
            value: null,
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.item !== this.props.item) {
                if (this.props.item && this.props.item.replacementKey) {
                    const { defaultPH } = this.props;
                    this.setState({
                        placeholder: JSON.parse(JSON.stringify(this.props.item)),
                        allowCustomReplyPeriods: defaultPH.allowCustomReplyPeriods ? true : false,
                        allowConfigureReplyParty: defaultPH.allowConfigureReplyParty ? true : false,
                        allowOverrideResources: defaultPH.allowOverrideResources ? true : false,
                        availableEffects: defaultPH.effects,
                        phChanged: false
                    });
                }
                else {
                    const emptyState = this.getEmptyState();
                    this.setState(emptyState);
                }
            }
        }
    }
    btnAddNew_Clicked = () => {
        this.listReference.current.clearSelection();
        this.setState({ value: { ...emptyValue } });
    }
    onSelectedValueUpdated = (newValue) => {
        if (!newValue) {
            this.setState({ value: null });
            this.listReference.current.clearSelection();
            return;
        }
        const newValues = [...this.state.placeholder.values];
        if (newValue.id > 0) {
            newValues[newValues.findIndex(val => val.id === newValue.id)] = newValue;
        }
        else {
            let maxId = 1;
            if (newValues.length > 0) {
                maxId = Math.max(...newValues.map(val => val.id));
            }
            newValue.id = maxId + 1;
            newValues.push(newValue);
        }
        this.setState({ placeholder: { ...this.state.placeholder, values: newValues }, value: null, phChanged: true });
    }
    onListUpdated = (newList) => {
        this.setState({ placeholder: { ...this.state.placeholder, values: JSON.parse(JSON.stringify(newList)) }, phChanged: true });
    }
    onItemSelected = (item) => {
        this.setState({ value: JSON.parse(JSON.stringify(item)) });
    }

    onClose = async () => {
        if (this.props.item) {
            if (this.state.phChanged) {
                let result = await confirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}</div>`, this.props.intl.formatMessage({ id: 'GENERAL.WARNING.LEAVE_CHANGES_TITLE' }));
                if (!result) {
                    return;
                }

            }
            const emptyState = this.getEmptyState();
            this.setState(emptyState);
            this.props.onClose();
        }
    }
    savePlaceholder = () => {
        const retVal = JSON.parse(JSON.stringify(this.state.placeholder));
        //CLEAR ANY EDITOR OPTIONS
        delete retVal.allowCustomReplyPeriods;
        delete retVal.allowConfigureReplyParty;
        delete retVal.availableEffects;
        delete retVal.skipDefaultLoadingIfMissing;
        this.props.onClose(retVal);
        const emptyState = this.getEmptyState();
        this.setState(emptyState);
    }

    renderListItem = (item) => {

        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                {/* //width: '85%',  */}
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 'auto' }}>{`${item.display}`}</div>
                {
                    //ANY CUSTOMIZATION TO EFFECT - EFFECT BADGE
                    (item.effect) && (
                        <Badge style={{ marginRight: 5 }} variant="warning">Effect</Badge>
                    )
                }
                {
                    //ANY CUSTOMIZATION TO NO REPLY - NO REPLY BADGE
                    (item.noReply) && (
                        <Badge style={{ marginRight: 5 }} variant="warning">No Reply</Badge>
                    )
                }
                {
                    //ANY CUSTOMIZATION TO CUSTOM REPLY - CUSTOM REPLY BADGE
                    (item.applyReplyPeriod) && (
                        <Badge style={{ marginRight: 5 }} variant="warning">Custom</Badge>
                    )
                }
                {
                    //ANY CUSTOMIZATION TO PARTY - PARTY BADGE
                    (item.overrideReplyingParty) && (
                        <Badge style={{ marginRight: 5 }} variant="warning">Party</Badge>
                    )
                }
                {
                    //ANY CUSTOMIZATION TO RESOURCES - Resource BADGE
                    (item.overrideResources && item.overrideResources.length > 0) && (
                        <Badge style={{ marginRight: 5 }} variant="warning">Resource</Badge>
                    )
                }
                <Badge variant={item.isActive ? 'success' : 'danger'}>{item.isActive ? 'Active' : 'Passive'}</Badge>

                {/* 
                style={{display: 'flex', flexDirection: 'row-reverse'}} 
                <div className={item.isActive ? 'kt-font-success' : 'kt-font-danger'} style={{ width: '15%', display: 'flex', flexDirection: 'row-reverse' }}>{item.isActive ? 'Active' : 'Passive'}</div> */}
            </div>
        );
    }
    handlePastedValues = async (e) => {
        // console.log('target.value', e.event.target.value);
        // console.log('clipboardData', e.event.clipboardData);
        // console.log('clipboardData.types', e.event.clipboardData.types);
        // console.log('clipboardData.items', e.event.clipboardData.items);
        // console.log('clipboardData.items[0].length', e.event.clipboardData.items.length);
        let containsList = false;
        for (let index = 0; index < ((e.event.originalEvent.clipboardData || {}).items || []).length; index++) {
            if (e.event.originalEvent.clipboardData.items[index].type === 'text/rtf') {
                containsList = true;
                break;
            }
        }
        // console.log('clipboardData.Get', e.event.clipboardData.getData('text/plain'));
        // console.log('clipboardData.Get', e.event.clipboardData.getData('text/html'));
        if (containsList) {
            e.event.preventDefault();
            if (this.state.fieldRequired) {
                this.props.enqueueSnackbar("List insertion is not possible for this placeholder, FIELD is required!", { variant: 'error', });
                return;
            }
            const pasteData = e.event.originalEvent.clipboardData.getData('text/plain');
            const pastedValues = pasteData.split('\n');
            let maxLengthExceeded = false;
            const newValues = [...this.state.placeholder.values];
            let maxId = 1;
            if (newValues.length > 0) {
                maxId = Math.max(...newValues.map(val => val.id));
            }
            let addedItemCount = 0;
            pastedValues.forEach(value => {
                if (value.trim()) {
                    if (value.trim().length >= clauseMaxLength) {
                        maxLengthExceeded = true;
                        return;
                    }
                    addedItemCount +=1;
                    const newValue = {
                        field: '',
                        display: value.trim(),
                        isActive: true,
                    }
                    maxId += 1;
                    newValue.id = maxId;
                    newValues.push(newValue);
                }
            });
            if (maxLengthExceeded) {
                this.props.enqueueSnackbar(`List contains an element that exceeds the max length of ${clauseMaxLength}`, { variant: 'error', });
                return;
            }
            if(addedItemCount === 0 ){
                this.props.enqueueSnackbar(`No list items found in pasted value`, { variant: 'error', });
                return;
            }
            let result = await confirm(`<div style="max-width:50vw;">You are about to add ${addedItemCount} items to list. Would you want to continue?</div>`, "Add items?");
            if (!result) {
                return;
            }
            this.setState({ placeholder: { ...this.state.placeholder, values: newValues }, value: null, phChanged: true });
            // setTimeout(() => this.drpList.instance.repaint(), 300);
        }

        // console.log('clipboardData.Get', e.event.clipboardData.getData('Files'));
        // console.log('clipboardData.Get', e.event.clipboardData.getData('Text'));
    }
    render() {
        return (
            <Popup
                visible={this.props.item ? true : false}
                onHiding={this.onClose}
                dragEnabled={false}
                showCloseButton={false}
                hideOnOutsideClick={false}
                showTitle={true}
                title={this.state.placeholder && this.state.placeholder.replacementKey ? "Edit Clause Placeholder List - " + this.state.placeholder.replacementKey : ""}
                fullScreen={true}
            >
                <PopupToolbarItem
                    widget="dxButton"
                    toolbar="top"
                    location="after"
                    options={{ text: "Cancel", onClick: this.onClose }}>
                </PopupToolbarItem>
                <PopupToolbarItem
                    widget="dxButton"
                    toolbar="top"
                    location="after"
                    disabled={!this.state.phChanged}
                    options={{
                        text: 'Save Changes',
                        type: "default",
                        stylingMode: this.state.phChanged ? "contained" : "outlined",
                        onClick: this.savePlaceholder
                    }}>
                </PopupToolbarItem>
                <div id="PHMFlexWrapper" style={{ display: 'flex', flexDirection: "row", height: "100%" }}>
                    <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                        <div style={{ margin: 10 }}>
                            <TextArea
                                height={60}
                                onPaste={this.handlePastedValues}
                                placeholder="Paste multiple items on this box in order to Auto-Append to list"
                                readOnly = {this.props.readOnly}
                            />
                        </div>
                        <div style={{ margin: 10 }}>
                            <Toolbar >
                                <ToolbarItem location="before">
                                    <div>
                                        <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">
                                            {`Clause List - ${this.state.placeholder.values ? this.state.placeholder.values.length : 0} items`}
                                        </h3>
                                    </div>
                                </ToolbarItem>
                                <ToolbarItem location="after"
                                    locateInMenu="auto"
                                    widget="dxButton"
                                    disabled={this.props.readOnly}
                                    options={
                                        {
                                            icon: "add",
                                            onClick: this.btnAddNew_Clicked,
                                            text: "Add New",
                                        }
                                    }
                                />
                            </Toolbar>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <DxGroupBox showBorder={true}>
                                <DxOrderList
                                    ref={this.listReference}
                                    items={this.state.placeholder.values}
                                    // keyExpr="id"
                                    displayExpr="display"
                                    listChanged={this.onListUpdated}
                                    onItemClick={this.onItemSelected}
                                    itemRender={this.renderListItem}
                                    readOnly = {this.props.readOnly}
                                />
                            </DxGroupBox>
                        </div>
                    </div>
                    <ClauseValueEditor
                        clauseItem={this.state.value}
                        onUpdate={this.onSelectedValueUpdated}
                        availableEffects={this.state.availableEffects}
                        allowOverrideResources={this.state.allowOverrideResources}
                        allowConfigureReplyParty={this.state.allowConfigureReplyParty}
                        allowCustomReplyPeriods={this.state.allowCustomReplyPeriods}
                        readOnly = {this.props.readOnly}
                    />
                </div>

            </Popup>
        );
    }
}


export default injectIntl(withSnackbar(PHModalClauses));