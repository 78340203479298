import React from 'react';
import DefaultResources from '../../../../../Templates/DefaultResources';
import DefaultWorkflows from '../../../../../Templates/DefaultWorkflows';
import DataGrid, {
    Editing,
    Column,
    Lookup,
    RequiredRule
} from 'devextreme-react/data-grid';
import { typeOptions as actionTypes } from "../../../Contracts/Contract/ContractActions";

const availableActions = ["CREATE", "CLEAR"]; 
const availableParties = ["PM", "SUPPLIER", "SUPERVISOR", "CLIENT"];

class PostActionsGrid extends React.Component {
    constructor(props) {
        super(props);
        this.gridRef = React.createRef();
        const initialList = props.postActionsList ? JSON.parse(JSON.stringify(props.postActionsList)) : [];
        const availableResources = DefaultResources.map(rsc => rsc.key)
        // const initialState = this.getInitialState(props);
        let allWorkflowTitles = DefaultWorkflows.reduce((obj, wfg) => { let wfsMapped = wfg.workflows.reduce((innerObj, wf) => ({ ...innerObj, [wf.id]: `${wf.id} | ${wf.config.description}` }), {}); return { ...obj, ...wfsMapped } }, {});

        this.actionTypes = actionTypes.map(type => {
            return {
                ...type,
                display: (type.wfid > 0) ? allWorkflowTitles[type.wfid] : type.display
            }
        })
        this.state = {
            items: initialList,
            availableResources
        };
    }
    // componentDidMount() {
    //     console.log("PostActionsGrid Mounted")
    // }
    // componentWillUnmount() {
    //     console.log("PostActionsGrid Un-Mounted")
    // }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.postActionsList !== this.props.postActionsList) {
            this.setState({ items: this.props.postActionsList ? JSON.parse(JSON.stringify(this.props.postActionsList)) : [] });
        }
    }
    onRowValidating = async (e) => {
        e.promise = new Promise((resolve, reject) => {
            const newObject = { ...e.oldData, ...e.newData };

            const valResult = this.validateData(newObject, e)

            e.errorText = valResult.errorText;
            e.isValid = valResult.isValid;

            //DONT CHANGE STATUS IN OTHER CASES
            resolve();
        });
    }
    validateData = (data, e) => {
        const valResult = { isValid: true, errorText: '' };
        const actionType = this.actionTypes.find(actT => actT.value === data.actionType);
        if (!actionType) {
            valResult.errorText = "Invalid action type";
            valResult.isValid = false;
        }
        if (data.action === "CLEAR") {
            if (data.daysForAction) {
                valResult.errorText = "Days can only be 0 for CLEAR actions";
                valResult.isValid = false;
            }
        }
        if (e.oldData?.actionType !== data.actionType) {
            if (this.state.items.some(itm => itm.actionType === data.actionType &&  itm.action === data.action)) {
                valResult.errorText = "Action Type already exist!";
                valResult.isValid = false;
            }
        }
        if(!data.ownerParty){
            valResult.errorText = "Owner Party is required!";
            valResult.isValid = false;
        }
        if(data.daysForAction < 0 || data.daysForAction>1000){
            valResult.errorText = "Days to create the action can be between 0 and 1000!";
            valResult.isValid = false;
        }


        return valResult;
    }
    updateParentList = (e) => {
        const source = e.component.getDataSource();
        if(source._items){
            //remove auto generated ID Key
            source._items.forEach((v)=>{ delete v.__KEY__ });
        }
        this.props.onValueChanged(JSON.parse(JSON.stringify(source._items)))
    }
    onInitNewRow = (e) => {
        e.data.askUser = false;
        e.data.daysForAction = 0;
    }
    // onEditorPreparing = (e) => {
    //     if (e.dataField === 'daysForAction' && e.parentType === 'dataRow') {
    //         e.editorOptions.disabled = e.row?.data?.action !== 'CREATE';
    //     }
    // }
    // customizeItem = (item) => {
    //     if (item.dataField === "daysForAction") {
    //         const dataGridInstance = this.gridRef.current.instance;
    //         const editing = dataGridInstance.option('editing');
    //         const rowIndex = dataGridInstance.getRowIndexByKey(editing.editRowKey);
    //         item.disabled = !(dataGridInstance.cellValue(rowIndex, 'action') === "CREATE");
    //         console.log(dataGridInstance.cellValue(rowIndex, 'action'))

    //     }
    // }
    // onOptionChanged = (a, b, c) => {
    //     console.log(a, b, c)
    // }
    // setCellValue = (column, newData, object, currentRowData,a, b, c) => {
    //     // console.log(column, newData, object, currentRowData)
    // }
    render() {
        return (

            <DataGrid
                ref={this.gridRef}
                // key={this.props.id}
                // keyExpr="actionType"
                title="Post Actions"
                dataSource={this.state.items}
                showBorders={true}
                columnAutoWidth={true}
                onInitNewRow={this.onInitNewRow}
                onRowValidating={this.onRowValidating}
                // onEditorPreparing={this.onEditorPreparing}
                onRowInserted={this.updateParentList}
                onRowUpdated={this.updateParentList}
                onRowRemoved={this.updateParentList}
                repaintChangesOnly={true}
            >
                <Editing
                    mode="form"
                    allowUpdating={!this.props.readOnly}
                    allowAdding={!this.props.readOnly}
                    allowDeleting={!this.props.readOnly}
                    form={{
                        // customizeItem: this.customizeItem,
                        // onFieldDataChanged: this.onOptionChanged,
                        // onValueChanged: this.onOptionChanged,
                        // onOptionChanged: this.onOptionChanged
                    }}
                // useIcons={true}
                />
                <Column
                    dataField="action"
                    caption="Action"
                // setCellValue={this.setCellValue}
                >
                    <Lookup
                        dataSource={availableActions}
                    // displayExpr="display"
                    // valueExpr="value"
                    />
                    <RequiredRule />
                </Column>
                <Column
                    dataField="ownerParty"
                    caption="Owner Party"
                >
                    <Lookup
                        dataSource={availableParties}
                    // displayExpr="display"
                    // valueExpr="value"
                    />
                    <RequiredRule />
                </Column>
                <Column
                    dataField="actionType"
                    caption="Action Type"
                >
                    <Lookup
                        dataSource={this.actionTypes}
                        displayExpr="display"
                        valueExpr="value"
                    />
                    <RequiredRule />
                </Column>

                <Column
                    dataField="daysForAction"
                    dataType="number"
                    caption="Days"
                // setCellValue={this.setCellValue}
                >
                    <RequiredRule />

                    {/* <StringLengthRule message="Label must have 2 to 250 characters" min={2} max={250} /> */}
                </Column>
                <Column
                    dataField="askUser"
                    dataType="boolean"
                    caption="Ask User"
                >
                    {/* <StringLengthRule message="Label must have 2 to 250 characters" min={2} max={250} /> */}
                </Column>
            </DataGrid>
        );
    }


}


export default PostActionsGrid