import { placeholderTypes } from "../../Types/Placeholder";
const placeholders = [
    {
        replacementKey: 'Resources', values:
            [
                { id: 1, isActive: true, field: 'ReasonLabel', display: 'Reason for any change to Planned Completion' },
                { id: 2, isActive: true, field: 'ChangeLabel', display: 'Change in any other Key Date, Sectional Completion or other important project milestone in period and reasons why' },
                { id: 3, isActive: true, field: 'MainProgressLabel', display: 'Main progress achieved in period' },
                { id: 4, isActive: true, field: 'KeyActivitiesLabel', display: 'Key activities forecast in next four weeks' },
                { id: 5, isActive: true, field: 'SummaryLabel', display: 'Summary of critical path' },
                { id: 6, isActive: true, field: 'ChangesLabel', display: 'Main changes made to programme in period, e.g. items deleted, new sections or activities added etc.' },
                { id: 7, isActive: true, field: 'CompensationLabel', display: 'Compensation events added to programme this period' },
                { id: 8, isActive: true, field: 'NotificationClause', display: 'In accordance with NEC4 PSC clause 31.1 please find attached submission of $SUPPLIER_NAME’s Programme for acceptance.' },
                { id: 9, isActive: true, field: 'ReplyRejectReasonLabel', display: 'The $SUPPLIER_NAME’s programme is not accepted for the following reasons' },
                { id: 10, isActive: true, field: 'ReplyNotificationClause', display: 'In accordance with NEC4 PSC clause 31.3 you are notified that the $SUPPLIER_NAME’s Programme' },
                { id: 11, isActive: true, field: 'ReferenceLabel', display: 'Programme Reference' },
                { id: 12, isActive: true, field: 'DashboardPlannedCompletionLabel', display: 'Planned Completion shown on the latest Accepted Programme', skipDefaultLoadingIfMissing: true },
                { id: 13, isActive: true, field: 'ProgReplyMissingDueToDeemAcceptance', display: 'Reply Document is not available due to Deemed Acceptence' },

            ],
        type: placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'ActionReminders', values:
            [
                { id: 1, isActive: true, field: 'Supplier', display: '$SUPPLIER_NAME Programme Submission awaiting Acceptance' },
            ],
        type: placeholderTypes.ACTION_REMINDERS
    },
    {
        replacementKey: 'ReplyOptions', values:
            [
                { id: 1, isActive: true, field: 'Accepted', display: 'Accepted' },
                { id: 2, isActive: true, field: 'Not Accepted', display: 'Not Accepted' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'ReplyRejectOptions', values:
            [
                { id: 1, isActive: true, field: 'plansnotpractical', display: 'The $SUPPLIER_NAME’s plans which it shows are not practicable' },
                { id: 2, isActive: true, field: 'missingcontractrequires', display: 'It does not show the information which this contract requires' },
                { id: 3, isActive: true, field: 'notrepresentplan', display: 'It does not represent the $SUPPLIER_NAME’s plans realistically' },
                { id: 4, isActive: true, field: 'notcomply', display: 'It does not comply with the Works Information' },
                { id: 5, isActive: true, field: 'rejectcustomreason1', display: 'rejectcustomreason1' },
                { id: 6, isActive: true, field: 'rejectcustomreason2', display: 'rejectcustomreason2' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'DashboardDisplayPlannedCompletionOnLatest', values:
            [
                { id: 1, isActive: true, field: 'DashboardDisplayPlannedCompletionOnLatest', display: 'Active-Inactive state indicates whether (Planned Completion shown on the latest Accepted Programme) is displayed on dasboard' },
            ],
        isToggle: true,
        fieldRequired: true,
        type: placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'Fields', values:
            [
                { id: 1, isActive: true, field: 'PreviousPlanned', display: 'Previous Planned Completion' },
                { id: 2, isActive: true, field: 'PlannedCompletion', display: 'Date of Planned Completion this period' },
                { id: 3, isActive: true, field: 'Reason', display: 'Reason for any change to Planned Completion' },
                { id: 4, isActive: true, field: 'Change', display: 'Change in any other Key Date, Sectional Completion or other important project milestone in period and reasons why' },
                { id: 5, isActive: true, field: 'MainProgress', display: 'Main progress achieved in period' },
                { id: 6, isActive: true, field: 'KeyActivities', display: 'Key activities forecast in next four weeks' },
                { id: 7, isActive: true, field: 'Summary', display: 'Summary of critical path' },
                { id: 8, isActive: true, field: 'Changes', display: 'Main changes made to programme in period, e.g. items deleted, new sections or activities added etc.' },
                { id: 9, isActive: true, field: 'Compensation', display: 'Compensation events added to programme this period' },
                { id: 10, isActive: true, field: 'Type', display: 'Type' },

            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },
    {
        replacementKey: 'UnifiedClauses', values:
            [
                { id: 1, wfid: 14, isActive: true, field: '', display: 'Test Clause', },

            ],
        skipDefaultLoadingIfMissing: false,
        allowCustomReplyPeriods: false,
        allowConfigureReplyParty: false,
        type: placeholderTypes.UNIFIED_CLAUSES
    },
];

export default placeholders;