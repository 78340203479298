import React from 'react';
import * as fileCrud from '../../../../crud/file.crud';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import Document from '../../_CustomComponents/DocumentV2';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { addYears } from 'date-fns';
import moment from 'moment';
import { withSnackbar } from 'notistack';

class RfiDocument extends BaseDocument {
    constructor(props) {
        super(props);
        this.editorFormConfig = {
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'replyactiondetail' },
                { resource: "GENERAL.FORMS.FIELD.DETAILS", dataField: 'inspectiondetails', editorType: 'dxHtmlEditor' },
            ]
        };
        if (props.workflowItem.replyPeriodOption === "ASKED_ON_COMMUNICATION_FORM") {
            this.editorFormConfig.fields.push(
                { resource: "CONTRACT.WF.RFI.FIELD.RESPONSE_REQ_BY", dataField: 'inspectiondate', editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: new Date() } }, validation: { type: 'range', min: moment().subtract(1, 'days'), max: addYears(new Date(), 10), warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.INVALID_DATE" }) } }
            )
        }

    }


    renderForm = () => {

        return (
            <BiDynamicForm
                item={this.props.notification}
                config={this.editorFormConfig}
                valueChangeHandler={this.props.fieldChanged}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
            />
        );

    }
    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.inspectiondefectid, data, config)
    }


    render() {
        const documentIsFinal = this.props.notification.showtasknotification === 1;
        return (
            <Document
                key={documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.renderForm()}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.notification.assetid}
            />
        );
    }
}

export default withSnackbar(RfiDocument);