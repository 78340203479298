import React from 'react';
import { connect } from "react-redux";
import { TableRow, TableHead, TableCell, TableBody, Table, Typography } from "@mui/material";
import Common from '../../../../shared/common';
import * as quotationCrud from '../../../../crud/quotation.crud';
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";

class QuotesArea extends React.Component {
    state = {
        notifications: null,
    };
    componentDidMount() {
        this.fetchQuotes();
    }
    fetchQuotes = () => {
        const { companyid, projectid, contractid } = this.props.contract;
        quotationCrud.getQuotes(companyid, projectid, contractid)
            .then(response => {
                let quotes = JSON.parse(response.data.aggregatelist);
                quotes = quotes.filter(quo => quo.showtasknotification === 1 && quo.responsestatus === 2);
                this.setState({ notifications: quotes });
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    render() {
        if (!this.state.notifications) {
            return null;
        }
        return (
            <div style={{ marginTop: 20 }}>
                <Typography style={{ marginLeft: 16 }} variant="h6" component="div" gutterBottom>
                    <FormattedMessage id="CONTRACT.HEALTH.QUOTES_AWAITING_ACCEPT" />
                </Typography>
                <Table style={{ border: '1px solid #ccc', borderRadius: '2px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.ID" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.DAYS" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.AMOUNT" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.NOTIFIED_DATE" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.NOTIFIED_BY" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.PM_RESPONSE_REQUIRED" values={{ PM_ABBREVIATION: this.props.globalResources.PM_ABBREVIATION }} /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.state.notifications.map(quote => (
                                <TableRow key={quote.referenceid}>
                                    <TableCell>{quote.referenceid}</TableCell>
                                    <TableCell>{`${quote.days} ${this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' })}`}</TableCell>
                                    <TableCell>{Common.FormatCurrencyNum(quote.amount, this.props.contract.currency)}</TableCell>
                                    <TableCell>{moment(quote.notificationdate).format('L')}</TableCell>
                                    <TableCell>{quote.createby}</TableCell>
                                    <TableCell>{moment(quote.responsedate).format('L')}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </div>
        );
    }
}
const mapStateToProps = store => ({
    globalResources: store.contract.template.resources,
    contract: store.contract.contract,
});

export default injectIntl(connect(mapStateToProps)(QuotesArea));