/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef } from "react";
import { Chart } from "chart.js";

export default function SalesBarChart({ title, labels, datasets, type }) {
  const ref = useRef();
  const successColor = "#07B388";

  const data = useMemo(
    () => ({
      labels: labels,
      datasets: datasets
    }),
    [successColor]
  );

  useEffect(() => {
    const chart = new Chart(ref.current, {
      data,
      type: type ? type : "horizontalBar",
      options: {
        title: {
          display: title ? true : false,
          text: title
        },
        tooltips: {
          intersect: false,
          mode: "nearest",
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10
        },
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false,
        barRadius: 4,
        scales: {
          xAxes: [{ display: true, gridLines: true, stacked: false, position: 'top', offset: true, ticks: { stepSize: 1 } }], //,
          yAxes: [{ display: true, stacked: false, gridLines: false, }]
        },
        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } }
      }
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <div className="kt-widget14">
      <div className="kt-widget14__chart" style={{ height: 500 }}>
        <canvas ref={ref} />
      </div>
    </div>
  );
}
