import {placeholderTypes} from "../../Types/Placeholder"
const placeholders = [
    {
        replacementKey: 'Resources', values:
            [
                { id: 1, isActive: true, field: 'ReplyWarningBanner', display: 'Please note a reason must be given and that if it is not a reason in the contract then it is a compensation event. If the reply is a rejection, we recommend that you add "You are required to resubmit within the time stated in the contract"' },
            ],
        type:placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'UnifiedClauses', values:
            [
                { id: 1, wfid: 18, isActive: true, field: '', display: 'Test Clause', },

            ],
        skipDefaultLoadingIfMissing: true,
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type: placeholderTypes.UNIFIED_CLAUSES
    },
    {
        replacementKey: 'IssueClauses', values:
            [
                { id: 1, isActive: true, field: '', display: '22.1 the following change in key person (from/to) for acceptance, who are equally qualified and experienced as the person originally named in the Contract Data:' },
                { id: 2, isActive: true, field: '', display: '24.2 the following Subconsultant who is being submitted for acceptance:' },
                { id: 3, isActive: true, field: '', display: '24.3 the following Subconsultant contract conditions which are being issued for acceptance:' },
                { id: 4, isActive: true, field: '', display: '34.2 a quotation for acceleration as follows to meet the date requested (in advance of the current Completion Date):' },
                { id: 5, isActive: true, field: '', display: '40.2 the following quality policy statement and/or quality plan for acceptance:' },
                { id: 6, isActive: true, field: '', display: '53.2 a revised activity schedule of which a summary of the key changes are listed below:' },
                { id: 7, isActive: true, field: '', display: 'X13.1: a performance bond:' },
            ],
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type:placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'IssueClausesPM', values:
            [
                { id: 1, isActive: true, field: '', display: '86.1 of certificates of insurance provided by the Client' },

            ],
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type:placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'ActionReminders', values:
            [
                { id: 1, isActive: true, field: 'Supplier', display: '$SUPPLIER_NAME Issue awaiting Acceptance' },
                { id: 2, isActive: true, field: 'PM', display: '$PM_NAME Submission awaiting Acceptance' },
            ],
        type:placeholderTypes.ACTION_REMINDERS
    },
    {
        replacementKey: 'ReplyOptions', values:
            [
                { id: 1, isActive: true, field: 'Accepted', display: 'Accepted' },
                { id: 2, isActive: true, field: 'Accepted with comments', display: 'Accepted with comments' },
                { id: 3, isActive: true, field: 'Not Accepted', display: 'Not Accepted' },
                { id: 4, isActive: true, field: 'Reply Not Required', display: 'Reply Not Required' },
            ],
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
];

export default placeholders;