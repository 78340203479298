import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import { addDays } from 'date-fns';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import * as programCrud from '../../../../crud/program.crud';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import Common from '../../../../shared/common';
import { actionCodes } from "../../../../shared/lookup";
class ProgrammeCreate extends BaseCreatePage {
    constructor(props) {
        super(props);
        const { subject } = ((props.location.state || {}).itemCustomProps || {});
        const createFormConfig = {
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { label: props.fields.Reference, dataField: 'reference', editorOptions: { defaultValue: subject } },

            ]
        };
        if (props.fields.Type) {
            const typeField = { 
                label: props.fields.Type, 
                dataField: 'type', 
                editorType: 'dxSelectBox',
                editorOptions: { dataSource: this.props.workflowItem.clauses, valueExpr: 'display', displayExpr: 'display' } 
            };
            //IF THERE IS ONLY ONE ITEM
            //PRE_SELECT & NON-EDITABLE
            if(this.props.workflowItem.clauses?.length === 1){
                typeField.editorOptions.defaultValue = this.props.workflowItem.clauses[0].display;
            }
            createFormConfig.fields.push(typeField)
        }
        if (props.fields.PreviousPlanned && props.contract.expectedcompletiondate) {
            createFormConfig.fields.push({ label: props.fields.PreviousPlanned, dataField: 'previousplanneddatedisplay', editorType: 'dxDateBox', editorOptions: { readOnly: true, value: new Date(props.contract.expectedcompletiondate) } })
        }
        if (props.fields.PlannedCompletion) {
            createFormConfig.fields.push({ label: props.fields.PlannedCompletion, dataField: 'plannedcompletiondatedisplay', editorType: 'dxDateBox' })
        }
        if (props.fields.Reason) {
            createFormConfig.fields.push({ label: props.fields.Reason, dataField: 'reasonchanged', editorType: 'dxHtmlEditor' })
        }
        if (props.fields.Change) {
            createFormConfig.fields.push({ label: props.fields.Change, dataField: 'details', editorType: 'dxHtmlEditor' })
        }
        if (props.fields.MainProgress) {
            createFormConfig.fields.push({ label: props.fields.MainProgress, dataField: 'mainprogress', editorType: 'dxHtmlEditor' })
        }
        if (props.fields.KeyActivities) {
            createFormConfig.fields.push({ label: props.fields.KeyActivities, dataField: 'keyactivities', editorType: 'dxHtmlEditor' })
        }
        if (props.fields.Summary) {
            createFormConfig.fields.push({ label: props.fields.Summary, dataField: 'summarycritical', editorType: 'dxHtmlEditor' })
        }
        if (props.fields.Changes) {
            createFormConfig.fields.push({ label: props.fields.Changes, dataField: 'mainchanges', editorType: 'dxHtmlEditor' })
        }
        if (props.fields.Compensation) {
            createFormConfig.fields.push({ label: props.fields.Compensation, dataField: 'compensationevents', editorType: 'dxHtmlEditor' })
        }

        this.state = {
            showAlert: true,
            createFormConfig: createFormConfig,
        };
    }

    submitHandler = (program, setSubmitting) => {
        program.companyid = this.props.contract.companyid;
        program.contractid = this.props.contract.contractid;
        program.projectid = this.props.contract.projectid;
        if (this.props.fields.PlannedCompletion) {
            program.plannedcompletiondatedisplay = moment(program.plannedcompletiondatedisplay).format('DD/MM/YYYY');
        }
        // program.changeddatedisplay = moment().format('DD/MM/YYYY');
        if (this.props.fields.PreviousPlanned && this.props.contract.expectedcompletiondate) {
            program.previousplanneddatedisplay = moment(new Date(this.props.contract.expectedcompletiondate)).format('DD/MM/YYYY');
        }
        program.status = 'Not Communicated';
        if (this.props.programmeReplyEnabled) {
            program.replyrequireddatedisplay = moment(addDays(new Date(), this.props.replyPeriod)).format('DD/MM/YYYY');
        }
        program.actioncode = actionCodes.CREATE;
        program.notifyingparty = this.props.workflowItem.notifyingParty;
        if (this.props.workflowItem.replyEnabled) {
            program.replyingparty = this.props.workflowItem.replyingParty;
        }
        this.appendRelationship(program);
        Common.PropertyConversionsBeforeNoticeSaveRequest(program, this.props.customFields, this.props.contractUsers);
        programCrud.saveContractProgram(program)
            .then(response => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
                try {
                    const createdID = Number(response.data);
                    this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
                }
                catch {
                    this.props.navigate(this.props.workflowItem.paths.listPath);
                }
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });

    }

    render() {

        const { assetid } = ((this.props.location.state || {}).itemCustomProps || {});
        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    <BiDynamicForm
                        config={this.state.createFormConfig}
                        onSubmitHandler={this.submitHandler}
                        customFields={(this.props.customFields || {}).COMMUNICATION}
                        assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                        assetid={assetid}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(ProgrammeCreate));
