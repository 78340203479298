import React, { Component } from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import * as contractsCrud from '../../../../crud/contracts.crud';
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";
import { GetCurrencyCodes } from "../../../../shared/lookup";
import { withRouter } from '../../../../shared/hoc/withRouter';
import { SimpleItem, RequiredRule, PatternRule } from 'devextreme-react/form'
import DataGrid, { Paging, SearchPanel, Export, ColumnChooser, StateStoring, ColumnFixing, Editing, Scrolling, HeaderFilter, FilterRow, FilterPanel, Column, Form, Toolbar, Item as ToolbarItem, } from 'devextreme-react/data-grid';
import { Item as FormItem } from 'devextreme-react/form';
import * as pricelistCrud from '../../../../crud/pricelist.crud'
import { Badge, Button } from "react-bootstrap";
import { notificationAggregateListTagsFilter, notificationUniqueTagsFilter } from "../../../../store/helpers/FilterHelper";
import { Link } from "react-router-dom";
import Common from '../../../../shared/common';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
const contractAmountHeaderFilter = JSON.stringify([{
    text: 'Less than 5,000',
    value: ['Amount', '<', 5000]
}, {
    text: '5,000 - 50,000',
    value: [
        ['Amount', '>=', 5000],
        ['Amount', '<', 50000]
    ]
}, {
    text: '50,000 - 250,000',
    value: [
        ['Amount', '>=', 50000],
        ['Amount', '<', 250000]
    ]
}, {
    text: '250,000 - 1,000,000',
    value: [
        ['Amount', '>=', 250000],
        ['Amount', '<', 1000000]
    ]
}, {
    text: '1,000,000 - 5,000,000',
    value: [
        ['Amount', '>=', 1000000],
        ['Amount', '<', 5000000]
    ]
}, {
    text: '5,000,000 - 25,000,000',
    value: [
        ['Amount', '>=', 5000000],
        ['Amount', '<', 25000000]
    ]
}, {
    text: 'Greater than 25,000,000',
    value: ['Amount', '>=', 25000000]
}]);
const amountHeaderFilter = JSON.stringify([{
    text: 'Less than 1,000',
    value: ['Amount', '<', 1000]
}, {
    text: '1,000 - 5,000',
    value: [
        ['Amount', '>=', 1000],
        ['Amount', '<', 5000]
    ]
}, {
    text: '5,000 - 10,000',
    value: [
        ['Amount', '>=', 5000],
        ['Amount', '<', 10000]
    ]
}, {
    text: '10,000 - 25,000',
    value: [
        ['Amount', '>=', 10000],
        ['Amount', '<', 25000]
    ]
}, {
    text: '25,000 - 100,000',
    value: [
        ['Amount', '>=', 25000],
        ['Amount', '<', 100000]
    ]
}, {
    text: 'Greater than 100,000',
    value: ['Amount', '>=', 100000]
}]);
class PriceList extends Component {
    constructor(props) {
        super(props);
        this.currencyCodes = GetCurrencyCodes();
        this.eventName = [
            { value: 'PriceList', display: this.props.intl.formatMessage({ id: "PRICELIST.TYPE.PRICELIST" }) },
            { value: 'CostBreakdown', display: this.props.intl.formatMessage({ id: "PRICELIST.TYPE.COSTBREAKDOWN" }) },
            { value: 'ActivitySchedule', display: this.props.intl.formatMessage({ id: "PRICELIST.TYPE.ACTIVITYSCHEDULE" }) },
            { value: 'BillOfQuantities', display: this.props.intl.formatMessage({ id: "PRICELIST.TYPE.BOQ" }) },
        ]
        this.category = [



            { value: 'BankAccount', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.BANKACCOUNT" }) },
            { value: 'Certificate', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.CERTIFICATE" }) },
            { value: 'CompensationEvent', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.COMPENSATIONEVENT" }) },
            { value: 'Document', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.DOCUMENT" }) },

            { value: 'Documents', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.DOCUMENTS" }) },
            { value: 'EarlyWarning', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.EARLYWARNING" }) },
            { value: 'EarlyWarningReduction', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.EARLYWARNINGREDUCTION" }) },
            { value: 'Forecast', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.FORECAST" }) },

            { value: 'InspectionDefect', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.INSPECTIONDEFECT" }) },
            { value: 'InstructionToQuote', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.INSTRUCTIONTOQUOTE" }) },
            { value: 'ItqQuotation', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.ITQQUOTATION" }) },
            { value: 'PaymentCertificate', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.PAYMENTCERTIFCATE" }) },

            { value: 'Program', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.PROGRAME" }) },
            { value: 'ProjectOrder', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.PROJECTORDER" }) },
            { value: 'Proposal', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.PROPOSAL" }) },
            { value: 'Quotation', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.QUOTATION" }) },
            { value: 'RequestForQuote', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.REQUESTFORQUOTE" }) },
            { value: 'RfqQuotation', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.RFQQUOTATION" }) },

            { value: 'TaskOrder', display: this.props.intl.formatMessage({ id: "PRICELIST.CATEGORY.TASKORDER" }) },




        ]
        this.subCategory = [
            { value: 'Inflation', display: this.props.intl.formatMessage({ id: "PRICELIST.SUBCATEGORY.INFLATION" }) },
            { value: 'ScopeContingency', display: this.props.intl.formatMessage({ id: "PRICELIST.SUBCATEGORY.SCOPECONTINGENCY" }) },
            { value: 'ProjectContingency', display: this.props.intl.formatMessage({ id: "PRICELIST.SUBCATEGORY.PROJECTCONTINGENCY" }) },
            { value: 'ProgrammeContingency', display: this.props.intl.formatMessage({ id: "PRICELIST.SUBCATEGORY.PROGRAMMECONTINGENCY" }) },
            { value: 'UnletScope', display: this.props.intl.formatMessage({ id: "PRICELIST.SUBCATEGORY.UNLETSCOPE" }) },
            { value: 'SanctionedValue', display: this.props.intl.formatMessage({ id: "PRICELIST.SUBCATEGORY.SACTIONEDVALUE" }) },

        ]

        this.isActive = [
            { value: 0, display: this.props.intl.formatMessage({ id: "PRICELIST.INACTIVE" }) },
            { value: 1, display: this.props.intl.formatMessage({ id: "PRICELIST.ACTIVE" }) }
        ]



    }
    state = {
        pricelists: null,
        showModal: false,
        tags: null,
        categoryFilter: null,
        currency: null
    }

    componentDidMount() {
        this.loadList();

    }


    createPriceList = (data, e) => {

        const { companyid, projectid, contractid } = this.props.contract;


        const priceList = {}
        // priceList.pricelistid = data.pricelistid
        priceList.contractid = contractid;
        priceList.name = data.name
        priceList.type = data.type
        priceList.code = data.code
        priceList.description = data.description
        priceList.currency = data.currency
        priceList.category = data.category

        priceList.subcategory = data.subcategory
        priceList.isactive = data.isactive

        return new Promise(async (resolve, reject) => {

            try {

                const resp = await pricelistCrud.createPriceList(priceList);

                this.loadList();
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        })
    }


    loadList = () => {
        const contractid = this.props.contract.contractid;
        pricelistCrud.loadList(contractid)
            .then(response => {
                // let resultData = JSON.parse(response.data);
                let resultData = response.data;

                const extractUniqueCurrencies = (data) => {
                    const uniqueCurrencies = new Set();

                    data.forEach((item) => {
                        uniqueCurrencies.add(item.currency);
                    });

                    return Array.from(uniqueCurrencies);
                };


                const uniqueCurrenciesArray = extractUniqueCurrencies(resultData);


                this.setState({ pricelists: resultData, currency: uniqueCurrenciesArray });


                let categoryFilter = null;


                if (response.data) {
                    const allUniquieTags = resultData;




                    function searchByCategory(items, targetCategory) {
                        return items.filter(item => item.category.includes(targetCategory));
                    }


                    const categories = allUniquieTags.map(obj => obj.category);

                    const flatArray = categories.flat();

                    // Use Set to remove duplicates and then convert back to an array
                    const arrayWithoutDuplicates = [...new Set(flatArray)];



                    const objTag = { tag: arrayWithoutDuplicates }


                    categoryFilter = objTag.tag.map(tag => ({ text: tag, value: ['category', 'contains', tag] }));


                    categoryFilter.push({
                        text: 'Blank',
                        value: ['category', '=', null]
                    });



                }

                this.setState({ categoryFilter });

            })

            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });



    }
    //adding a row
    onRowInserting = async (e) => {
        const newPriceList = { ...e.data };

        e.cancel = this.createPriceList(newPriceList, e);
    }


    onExporting = (e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        
        
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            
            worksheet.columns.forEach(column => {
                column.eachCell({ includeEmpty: true }, cell => {
                    if (typeof cell.value === 'number' && Math.abs(cell.value) > 999999999) {
                        cell.numFmt = '#,##0'; 
                    }
                });
            });
    
            
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PriceList.xlsx');
            });
        });
    }

    //editing a row
    onRowUpdating = async (e) => {
        const { companyid, projectid, contractid } = this.props.contract;
        const newObject = { ...e.oldData, ...e.newData, companyid, projectid, contractid };
        e.cancel = this.updatePriceList(newObject);

    }
    updatePriceList = (data) => {


        const updatedPriceList = {}
        updatedPriceList.pricelistid = data.pricelistid
        updatedPriceList.contractid = data.contractid
        updatedPriceList.name = data.name
        updatedPriceList.type = data.type
        updatedPriceList.code = data.code
        updatedPriceList.description = data.description
        updatedPriceList.currency = data.currency
        updatedPriceList.category = data.category

        updatedPriceList.subcategory = data.subcategory
        updatedPriceList.isactive = data.isactive


        return new Promise(async (resolve, reject) => {
            try {

                const resp = await pricelistCrud.updatePriceList(updatedPriceList);
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        });
    }



    // deleting row
    onRowRemoving = (e) => {
        const { companyid, projectid, contractid } = this.props.contract;
        const deleteObj = { ...e.data, companyid, projectid, contractid };
        deleteObj.deleted = 1;
        e.cancel = this.deletePriceList(deleteObj);
    }

    deletePriceList = (data) => {

        const pricelistid = data.pricelistid

        return new Promise(async (resolve, reject) => {
            try {

                const resp = await pricelistCrud.deletePriceList(pricelistid);
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        });
    }

    onInitNewRow = async (e) => {
        e.data = { deleted: 0 };
    }

    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];

            this.props.navigate(`/contract/${data.contractid}/settings/price-list/${data.pricelistid}/price-list-items`);
        }
    }


    render() {
        if (!this.state.pricelists) {
            return (<PortletSpinner />);
        }
        const columns = [

            { caption: this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.NAME' }), dataField: "name" },

            { caption: this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.TYPE' }), dataField: "type" },

            { caption: this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.CODE' }), dataField: "code" },
            { caption: this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.DESCRIPTION' }), dataField: "description" },
            { caption: this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.CURRENCY' }), dataField: "currency" },

            { caption: this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.CATEGORY' }), dataField: "category", dataType: 'tag', headerFilter: { dataSource: this.state.categoryFilter, allowSearch: false } },

            { caption: this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.SUBCATEGORY' }), dataField: "subcategory" },

            { caption: this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.ITEMCOUNT' }), dataField: "itemcount" },
            { caption: this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.ACTIVEINACTIVE' }), dataField: "isactive", dataType: "boolean" },
            {
                caption: this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.TOTALPRICE' }), dataField: "totalprice", dataType: 'currency', editorOptions: {
                    dataSource: this.currencyCodes,
                    valueExpr: 'code',
                    displayExpr: 'name',
                    defaultValue: 'GBP',
                    format: '#,##0.00',
                },
                cellRender:(e) => Common.FormatCurrencyNum(e.data.totalprice, e.data.currency)

            },

        ]
        const columnsMapped = columns.map((columnItem, index) => {
            const itmProps = { headerFilter: { allowSearch: true }, ...columnItem };

            if (index === 0) {
                itmProps.cssClass = "WrappedIdColumnWithPointer";

            }
            else if (!itmProps.cssClass) {
                itmProps.cssClass = "WrappedIdColumnWithPointerWithoutColor";
            }

            return itmProps;
        });


        return (
            <Portlet>

                <PortletBody>

                    <DataGrid
                        onExporting={this.onExporting}
                        dataSource={this.state.pricelists}
                        version="1"
                        keyExpr="pricelistid"
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        remoteOperations={false}
                        allowColumnReordering={true}
                        columnResizingMode='widget'
                        name={`Pricelists`}
                        onRowUpdating={this.onRowUpdating}
                        onInitNewRow={this.onInitNewRow}
                        onRowInserting={this.onRowInserting}
                        noDataText={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.NO_DATA' })}
                        onRowRemoving={this.onRowRemoving}
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        style={{ maxHeight: '67vh', minHeight: "50vh", cursor: 'pointer !important' }}


                    >

                        <HeaderFilter visible={true} />
                        <FilterRow applyFilter="auto" visible={true} />
                        <FilterPanel visible={true} />
                        <ColumnChooser mode="select" enabled={true} search={{ enabled: true }}  >
                            {/* <ColumnChooserSearch enabled={true} timeout={800} /> */}
                        </ColumnChooser>
                        <ColumnFixing enabled={true} />
                        <StateStoring enabled={true} type="localStorage" />

                        <Export enabled={true} fileName={this.props.name} />
                        <SearchPanel visible={true} highlightCaseSensitive={false} defaultText="" />
                        {
                            columnsMapped.map((columnItem, index) => (
                                <Column cssClass="WrappedColumnClass" key={index} {...columnItem} />
                            ))
                        }
                        <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            allowDeleting={true}
                            allowAdding={true}
                            confirmDelete={true}
                            popup={{ title: this.props.intl.formatMessage({ id: "CONTRACT.ADDITEMS" }), height: 'auto', width: 600, showTitle: true }}
                            useIcons={true}


                        >
                            <Form>
                                <FormItem colSpan={2} dataField="name" label={{ text: this.props.intl.formatMessage({ id: "PRICELIST.FORMS.FIELDS.NAME" }) }} isRequired />
                                <FormItem colSpan={2} dataField="type" label={{ text: this.props.intl.formatMessage({ id: "PRICELIST.FORMS.FIELDS.TYPE" }) }} editorType="dxSelectBox" editorOptions={{ dataSource: this.eventName, valueExpr: 'value', displayExpr: 'display' }} isRequired />
                                <FormItem colSpan={2} dataField="code" label={{ text: this.props.intl.formatMessage({ id: "PRICELIST.FORMS.FIELDS.CODE" }) }} isRequired />
                                <FormItem colSpan={2} dataField="description" label={{ text: this.props.intl.formatMessage({ id: "PRICELIST.FORMS.FIELDS.DESCRIPTION" }) }} isRequired />
                                <FormItem colSpan={2} dataField="currency" label={{ text: this.props.intl.formatMessage({ id: "PRICELIST.FORMS.FIELDS.CURRENCY" }) }} editorType="dxSelectBox" editorOptions={{ dataSource: this.currencyCodes, valueExpr: 'code', displayExpr: 'name' }} isRequired />
                                <FormItem colSpan={2} dataField="category" label={{ text: this.props.intl.formatMessage({ id: "PRICELIST.FORMS.FIELDS.CATEGORY" }) }} editorType='dxTagBox' editorOptions={{ dataSource: this.category, valueExpr: 'value', displayExpr: 'display' }} isRequired />
                                <FormItem colSpan={2} dataField="subcategory" label={{ text: this.props.intl.formatMessage({ id: "PRICELIST.FORMS.FIELDS.SUBCATEGORY" }) }} editorType="dxSelectBox" editorOptions={{ dataSource: this.subCategory, valueExpr: 'value', displayExpr: 'display' }} isRequired />
                                <FormItem colSpan={2} dataField="isactive" label={{ text: this.props.intl.formatMessage({ id: "PRICELIST.FORMS.FIELDS.ACTIVEINACTIVE" }) }} editorType="dxSwitch" />

                            </Form>
                        </Editing>
                        <Toolbar>
                            <ToolbarItem location="before">
                                <div>
                                    <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">
                                        <FormattedMessage id="CONTRACT.PRICELIST.TITLE" />
                                    </h3>
                                </div>
                            </ToolbarItem>
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="addRowButton"
                                showText="always"
                                options={{
                                    icon: "plus",
                                    text: this.props.intl.formatMessage({ id: "CONTRACT.ADDITEMS" }),
                                }}
                            />

                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="exportButton"
                            />
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="columnChooserButton"
                            />
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="searchPanel"
                            />
                        </Toolbar>





                        <Paging pageSize={10} defaultPageSize={10} defaultPageIndex={0} />

                    </DataGrid>


                </PortletBody>

            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    userProfile: store.auth.userProfile
});

export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps)(PriceList))));