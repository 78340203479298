/* eslint-disable import/no-anonymous-default-export */
export default {
  header: {
    self: {},
    items: []
  },
  aside: {
    self: {},
    items: []
  }
};
