import axios from 'axios';
import store from "../store/store";
import * as authCrud from "../crud/auth.crud";
import * as authDuck from "../store/ducks/auth.duck";
import Logger from './logger';

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_URL, ////#ENV //'https://demofastdraftapi.builtintelligence.com/', // 'http://localhost:600/'
  //timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
};

const instance = createInstance();


function setupAxios(_axios) {
  _axios.interceptors.request.use(
    async request => {
      // console.log(request);//#ENV
      const storeState = store.getState();
      let apiLang = "en-GB"
      if (storeState.i18n && storeState.i18n.lang && storeState.i18n.lang !== "en") {
        switch (storeState.i18n.lang) {
          case "nl":
            apiLang = "nl-NL";
            break;
          default:
            apiLang = "en-GB"
            break;
        }
      }
      request.headers['Accept-Language'] = apiLang;

      if (storeState.auth && storeState.auth && storeState.auth.authToken && storeState.auth.authToken.token) {
        const authExpiryDate = new Date(storeState.auth.authToken.expireDateEpochTime);

        if (authExpiryDate < new Date()) {
          console.log('Auth fetch date:', storeState.auth.authToken.fetchDate);
          console.log('Auth expire UI time:', authExpiryDate);
          console.log('token .issued:', storeState.auth.authToken.issued);
          console.log('token .expires:', storeState.auth.authToken.expires);
          console.log("Auth token expired will be redirected to Login");
          await store.dispatch({ type: authDuck.actionTypes.AUTH_LOGOUT_START });
          return false;
        }
        const authToken = storeState.auth.authToken.token;
        request.headers.Authorization = `Bearer ${authToken}`;
        if (storeState.auth.csrfToken && storeState.auth.csrfToken.token) {
          //IF REQUEST URL IS DIFFERENT THAN TOKEN URLS
          if (request.url !== "/api/generic" && request.url !== "/Token") {
            const csrfExpiryDate = new Date(storeState.auth.csrfToken.expireDateEpochTime);
            //IF CSRF TOKEN EXPIRED
            if (csrfExpiryDate < new Date()) {
              console.log("CSRF token expired new token will be retreived prior to request to:" + request.url);
              return authCrud.getCsrfToken().then(async csrfResponse => {
                request.headers['Csrf-Token'] = csrfResponse.data.split('-')[1];
                await store.dispatch({ type: authDuck.actionTypes.AUTH_SET_REFRESH_CSRF_TOKEN, payload: { token: csrfResponse.data } });
                return Promise.resolve(request);
              });
            }
          }

          const csrfToken = storeState.auth.csrfToken.token;
          request.headers['Csrf-Token'] = csrfToken.split('-')[1]
        }
      }

      //MEASURE SERVER RESPONSE TIME
      request.meta = request.meta || {}
      request.meta.requestStartedAt = new Date().getTime();

      return request;
    },
    err => Promise.reject(err)
  );

  _axios.interceptors.response.use(res => {
    try {
      if (res.config && res.config.meta && res.config.meta.requestStartedAt) {
        const executionTimeMs = (new Date().getTime() - res.config.meta.requestStartedAt);
        const executionTimeThresholdMs = process.env.REACT_APP_ENVIRONMENT === "PROD" ? 20000 : 10000;
        //IF RESPONSE LONGER THAN 10 SECONDS
        if ( executionTimeMs > executionTimeThresholdMs) {
          console.log('Request response time longer than expected ms:' + executionTimeMs);
          Logger.PushInfo({ apiEndpoint: res.config.url, executionTimeMs: executionTimeMs }, 'API_LATE_RESPONSE')
        }
      }
    }
    catch { }
    return res;
  },
    err => {
      Logger.PushError(err, null, 'API');
      if (axios.isCancel(err)) {
        console.log('Axios cancel message:', err.message);
        return Promise.reject(err);
      }
      else if (err.response && err.response.config && err.response.config.url && err.response.config.url.startsWith('/api/Security/GetDecryptedToken')) {
        return Promise.reject(err);
      }
      else {
        if (err instanceof Error) {
          if (err.message && err.message.includes('Network Error')) {
            return Promise.reject(Error('NETWORK_ERROR'));
          }
        }

        if (err.response && err.response.data && err.response.data.error && err.response.data.error === 'invalid_login') {
          return Promise.reject(Error(err.response.data.error_description));
        } else {
          if (err.response && err.response.data && typeof err.response.data === 'string' && err.response.data.includes('The account has been deactivated.')) {
            store.dispatch({ type: authDuck.actionTypes.AUTH_LOGOUT_START });
            return Promise.reject(Error(err.response.data));
          }
        }

        let errMessage = 'Response Error!';
        let retMessage = 'Response Error!';
        if (err.response) {
          if (err.response.status && err.response.statusText) {
            errMessage += '\n' + err.response.status + '-' + err.response.statusText;
            retMessage = err.response.status + '-' + err.response.statusText;
          }
          if (err.response.data) {
            if (err.response.data.ExceptionMessage) {
              errMessage += '  \n "' + err.response.data.ExceptionMessage + '"';
              retMessage = err.response.data.ExceptionMessage;
            }
            if (err.response.data.Message) {
              errMessage += '  \n "' + err.response.data.Message + '"';
              retMessage = err.response.data.Message;
              if (err.response.data.Message.includes('invalid_process')) {
                try {
                  const innerException = JSON.parse(err.response.data.Message);
                  if (innerException.description) {
                    console.log(innerException.description);
                    return Promise.reject(Error(innerException.description));
                  }
                } catch { }
              }
            }
            if (err.response.data.error) {
              errMessage += '  \n "' + err.response.data.error + '"';
              retMessage = err.response.data.error;
            }
            if (err.response.data.error_description) {
              errMessage += '  \n "' + err.response.data.error_description + '"';
              retMessage = err.response.data.error_description;
            }
            if (typeof err.response.data === 'string') {
              errMessage += '  \n "' + err.response.data + '"';
              retMessage = err.response.data;
            }
          }
        }

        console.log('axios response error:', err);
        console.log('derived response error:', errMessage);

        // unexpected token invalid error
        if (errMessage.includes('Unable to find Account') ||
          errMessage.includes('Un-Authorized') ||
          retMessage.includes('401') ||
          retMessage.includes('Unauthorized') ||
          errMessage.includes('401') ||
          errMessage.includes('Unauthorized')) {
          const storeState = store.getState();
          if (storeState.auth && storeState.auth.authToken) {
            console.log('Auth fetch date:', storeState.auth.authToken.fetchDate);
            console.log('token .issued:', storeState.auth.authToken.issued);
            console.log('token .expires:', storeState.auth.authToken.expires);
          }
        }
        return Promise.reject(Error(retMessage));
      }
    }
  );
}

function createInstance() {
  let instance = axios.create(defaultOptions);
  setupAxios(instance);
  return instance;
}


export default instance;