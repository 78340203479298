import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import ReactToPrint from "react-to-print";
import { Navigate } from "react-router-dom";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as earlyWarningCrud from '../../../../crud/earlyWarning.crud';
import * as fileCrud from '../../../../crud/file.crud'
import axios from 'axios';
import ActionModal from '../../_CustomComponents/ActionModal';
import DocumentMain from './Document';
import DocumentReply from './DocumentReply';
import WarningRisk from './Risk';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { Alert } from "react-bootstrap";
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import Relations from '../../_CustomComponents/Relation/Relations';
import moment from 'moment';
import { addDays } from 'date-fns';
import { FormattedMessage } from "react-intl";
import PageToolbar from '../../_CustomComponents/PageToolbar';
import Common from '../../../../shared/common';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';

const initialState = {
    notification: null,
    reply: null,
    files: null,
    replyFiles: null,
    showModal: false,
    saving: false,
    isChanged: false,
    isAwaitingReply: false,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    relationCount: 0,
    postActionUrl: null,
    relations: null
};
class WarningDetail extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';


        this.printIssueDivRef = React.createRef();
        this.printAcceptanceDivRef = React.createRef();
        this.validatorRef = React.createRef();
        this.validatorName = 'EarlyWarningValidator';
        this.state = { ...initialState };

    }

    componentDidMount() {
        this.fetchWarning();
        this.fetchFiles();
        this.fetchReplyFiles();
    }



    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.fetchWarning();
                this.fetchFiles();
                this.fetchReplyFiles();
            });
        }
    }

    getMailDescription = () => {
        return this.state.notification.title;
    }
    fetchWarning = () => {
        earlyWarningCrud.getEarlyWarning(this.props.params.notificationid)
            .then(response => {
                const warning = response.data;
                if (warning.contractid !== this.props.contract.contractid ||
                    warning.party !== this.props.workflowItem.config.currentModelType) {
                    throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
                }
                if (warning.createdate) {
                    warning.createdate = new Date(warning.createdate);
                }
                if (warning.notificationdate) {
                    warning.notificationdate = new Date(warning.notificationdate);
                }
                if (warning.meetingdate) {
                    warning.meetingdate = new Date(warning.meetingdate);
                }
                if (warning.replydate) {
                    warning.replydate = new Date(warning.replydate);
                }
                if (warning.resolvedate) {
                    warning.resolvedate = new Date(warning.resolvedate);
                }
                if (warning.draftreplydate) {
                    warning.draftreplydate = new Date(warning.draftreplydate);
                }
                try {
                    if (warning.risk) {
                        var parts = warning.risk.split("/");
                        warning.risk = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
                    }
                } catch { }
                try {
                    if (warning.meetingtime && warning.meetingtime.includes(':')) {
                        warning.meetingtime = moment(warning.meetingtime, 'HH:mm').toDate();
                        // var today = new Date();
                        // var timeParts = warning.meetingtime.split(":");
                        // warning.meetingtime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), Number(timeParts[0]), Number(timeParts[1]));
                    }
                } catch { }
                const awaitingReply = (warning.showtasknotification === 1 && warning.replystatus === 'Awaiting Reply');
                const initialReplyStatus = warning.draftewstatus === "true" ? true : false;
                const replyItem = {
                    replystatus: initialReplyStatus,
                    replycomment: warning.draftreplycomment,
                    customfieldvalues: warning.customfieldvalues
                };

                this.setState({
                    notification: warning,
                    reply: replyItem,
                    isAwaitingReply: awaitingReply,
                    saving: false,
                    isChanged: false,
                    relations: warning.relations,
                    relationCount: Common.GetRelationCount(warning, this.props.accessibleWfIds)
                }, () => { this.props.setReference(this.state.notification.referenceid); });
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
    }


    fetchFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ files: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    fetchReplyFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType + 'Reply')
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ replyFiles: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    printHandler = () => {
        this.printIssueRef.handlePrint();
    }

    printAcceptanceHandler = () => {
        this.printAcceptanceRef.handlePrint();
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }
    showModal = (mode, postActionNavigateObj) => {
        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
                    modalMode: mode,
                    postActionUrl: postActionNavigateObj
                });
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
            });
        }
        else if (mode === 'REPLY_DRAFT') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_REPLY_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'REPLY_SEND') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SEND_REPLY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_SENDING' }, { PARTY_NAME: this.props.globalResources[this.props.workflowItem.notifyingParty + "_NAME"] }),
                    modalMode: mode,
                });
            }
        }
    }
    onModalResponse = (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {


                    if (this.state.modalMode === 'COMMUNICATE') {
                        const warning = { ...this.state.notification };
                        warning.replyrequireddate = moment(addDays(new Date(), this.props.replyPeriod)).toDate();

                        //GENERATE DOCUMENT JSON FORMAT
                        const docBodyConfig = this.documentBodyConfig(warning, this.props.resources, this.props.meetingOptions, this.props.riskTypes);
                        const docConfig = await this.documentConfig(warning, docBodyConfig, this.props.contractUser.name, false, null, null, this.props.workflowItem.form_NotifyCompanyName);
                        warning.pdfjsoncontent = JSON.stringify(docConfig);

                        this.saveWarning(warning, this.state.modalMode);

                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {
                        const warning = { ...this.state.notification };
                        warning.replyrequireddate = moment(addDays(new Date(), this.props.replyPeriod)).toDate();
                        this.saveWarning(warning, this.state.modalMode, (params || {}).userid);

                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {
                        const warning = { ...this.state.notification };
                        const warningid = warning.warningid
                        this.deleteEarlyWarningDraft(warningid);

                    }
                    else if (this.state.modalMode === 'REPLY_DRAFT') {
                        const reply = { ...this.state.reply };
                        const warning = { ...this.state.notification };
                        warning.draftewstatus = reply.replystatus;
                        warning.draftreplycomment = reply.replystatus ? null : reply.replycomment;
                        if ((params || {}).userid) {
                            warning.draftreplytoid = params.userid;
                        }
                        for (let key in reply) {
                            if (key.startsWith("CF_")) {
                                warning[key] = reply[key];
                            }
                        }
                        this.saveWarning(warning, this.state.modalMode, (params || {}).userid);

                    }
                    else if (this.state.modalMode === 'REPLY_SEND') {
                        const reply = { ...this.state.reply };
                        if (!this.replyValidation(reply)) {
                            //FINAL REPLY FIELDS CHECK FAILED
                            throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_FIELDS_MISSING' }))
                        }
                        const warning = { ...this.state.notification, ...reply };
                        warning.replystatus = reply.replystatus ? 'Reply Not Required' : 'Reply Received';
                        warning.replycomment = reply.replystatus ? null : reply.replycomment;

                        for (let key in reply) {
                            if (key.startsWith("CF_")) {
                                warning[key] = reply[key];
                            }
                        }
                        //GENERATE DOCUMENT JSON FORMAT
                        const docReplyBodyConfig = this.documentReplyBodyConfig(warning, this.props.resources);
                        const docReplyConfig = await this.documentConfig(warning, docReplyBodyConfig, this.props.contractUser.name, true, null, null, this.props.workflowItem.form_ReplyCompanyName);
                        warning.pdfreplyjsoncontent = JSON.stringify(docReplyConfig);
                        this.saveWarning(warning, this.state.modalMode);

                    }

                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });
    }
    replyValidation = (reply) => {
        if (!reply.replystatus && !reply.replycomment) { return false; }
        return true;
    }

    onResolveRisk = async (resolveDate, setSubmitting) => {
        try {
            const actiondata = {
                warningid: this.state.notification.warningid,
                version: this.state.notification.version,
                resolvedatedisplay: moment(resolveDate).format('DD/MM/YYYY')
            };
            await earlyWarningCrud.ResolveRiskUpdate(actiondata);

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), {
                variant: "success",
            });
            this.fetchWarning();
            setSubmitting(false);
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }

    onUpdateRisk = (warning) => {
        this.fetchWarning();
    }
    documentBodyConfig = (notification, resources, meetingOptions, riskTypes) => {
        const meetingSelectionLabel = meetingOptions.find(opt => opt.field === notification.meetingrequired).display;
        const items = riskTypes.filter(itm => (notification[itm.field]))
            .map(itm => ({ label: itm.display }));

        const config = [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.title },
            { resource: "CONTRACT.WF.EW.FIELD.BECAME_AWARE", value: notification.risk },
            { label: resources.NotificationClause, list: items, valueType: 'LIST', align: 'vertical' },
            { label: resources.DescriptionLabel, value: notification.description, valueType: 'HTML', align: 'vertical' },
            { label: resources.MeetingRequiredLabel, value: meetingSelectionLabel },
        ];
        if (notification.meetingrequired === 2) {
            config.push(
                { resource: "CONTRACT.WF.MEETING.FIELD.LOCATION", value: notification.meetinglocation, },
                { resource: "CONTRACT.WF.MEETING.FIELD.DATE", value: notification.meetingdate, },
                { resource: "CONTRACT.WF.MEETING.FIELD.TIME", value: notification.meetingtime.toLocaleTimeString([], { hourCycle: 'h23', hour: '2-digit', minute: '2-digit' }) },
                { resource: "CONTRACT.WF.MEETING.FIELD.ATTENDEES", value: notification.meetingattendees, }
            );
        }
        return config;
    }
    documentReplyBodyConfig = (notification, resources) => {
        const config = [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.title, },
        ];
        if (notification.replystatus === 'Reply Not Required' || !notification.replycomment) {
            config.push({ resource: "GENERAL.FORMS.FIELD.REPLY", value: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.Reply Not Required' }) });
        }
        else {
            config.push({ resource: "GENERAL.FORMS.FIELD.REPLY", value: notification.replycomment, valueType: 'HTML', align: 'vertical' });
        }
        return config;
    }
    saveWarning = async (warning, action, notifyuserid) => {
        try {
            const postEvents = () => {
                this.fetchWarning();
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success' });
            };
            if (warning.meetingtime && warning.meetingtime instanceof Date) {
                warning.meetingtime = moment(warning.meetingtime).format('HH:mm');
            }
            //MEETINGDATE FORMAT IS "MM/DD/YYYY" different than others
            if (warning.meetingdate && warning.meetingdate instanceof Date) {
                warning.meetingdate = moment(warning.meetingdate).format('MM/DD/YYYY');
            }
            if (!warning.resolvedatedisplay && warning.resolvedate) {
                warning.resolvedatedisplay = moment(warning.resolvedate).format('DD/MM/YYYY');
            }
            if (!warning.draftreplydatedisplay && warning.draftreplydate) {
                warning.draftreplydatedisplay = moment(warning.draftreplydate).format('DD/MM/YYYY');
            }
            if (warning.risk) {
                warning.risk = moment(warning.risk).format('DD/MM/YYYY');
            }
            this.props.riskTypes.forEach(riskType => {
                warning[riskType.field] = warning[riskType.field] ? 1 : 0;
            });
            warning.actioncode = action;
            warning.replyingparty = this.props.workflowItem.replyingParty;
            Common.PropertyConversionsBeforeNoticeSaveRequest(warning, this.props.customFields, this.props.contractUsers);
            await earlyWarningCrud.saveEarlyWarning(warning);
            warning.notificationtypeforemailtemplate = warning.party;
            warning.pdfjsoncontent = warning.pdfjsoncontent?.replace("$NOW", moment().format('DD/MM/YYYY'));
            this.notify(action, warning, notifyuserid);

            if (this.state.postActionUrl) {
                this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
            } else {
                postEvents();
            }
        }
        catch (error) {
            this.setState({ saving: false });
            this.props.enqueueSnackbar(error.toString(), { variant: 'error', });
        }
    }

    deleteEarlyWarningDraft = async (warningid) => {
        try {
            await earlyWarningCrud.deleteEarlyWarningDraft(warningid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }
    }

    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else {
                this.state.notification[field] = value;
                if (!this.state.isChanged) {
                    this.setState({ isChanged: true });
                }
            }
        }

    }
    replyFieldChanged = (field, value, isDxReplyFormValid, isCheckBoxValue) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            this.state.reply[field] = value;
            if (!this.state.isChanged) {
                this.setState({ isChanged: true });
            }
        }

    }

    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving || this.state.showModal);
        return (
            <PageToolbar
                documentIsFinal={documentIsFinal}
                saveDisabled={saveDisabled}
                isAwaitingReply={this.state.isAwaitingReply}
                actions={{
                    showModal: this.showModal,
                    onPrintReply: this.printAcceptanceHandler,
                    onPrint: this.printHandler
                }}
                workflowItem={this.props.workflowItem}
                referenceid={this.state.notification.referenceid}
                document={this.props.document}
                contract={this.props.contract}
                match={this.props.match}
                customFieldValues={this.state.notification.customfieldvalues}
                relationParams={{
                    subject: this.getMailDescription(),
                    assetid: this.state.notification.assetid
                }}
                notification={this.state.notification}
            />
        );
    }
    tabDocument = () => {
        return (
            <DocumentMain
                {...this.props}
                printDivRef={this.printIssueDivRef}
                fieldChanged={this.fieldChanged}
                notification={this.state.notification}
                files={this.state.files}

                notificationid={this.props.params.notificationid}
            />
        );
    }
    tabRisk = (status) => {
        return (
            <WarningRisk
                {...this.props}
                status={status}
                onResolveRisk={this.onResolveRisk}
                onSubmitUpdate={this.onUpdateRisk}
                notification={this.state.notification}
            />
        );
    }

    tabReply = () => {

        if (!this.props.workflowItem.userFilters.replyNotice.communicateReplyAllowed && !this.props.workflowItem.userFilters.replyNotice.saveDraftReplyAllowed && this.state.isAwaitingReply) {
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <div style={{ width: '100%', maxWidth: '900px', margin: '20px' }}>
                        <Alert variant="warning" >
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_AWAITING_REPLY" values={{ ITEM_NAME: this.props.workflowItem.pageTitle }} />
                            </p>
                        </Alert>
                    </div>
                </div>
            )
        }
        else {
            return (
                <DocumentReply
                    {...this.props}
                    isAwaitingReply={this.state.isAwaitingReply}
                    printDivRef={this.printAcceptanceDivRef}
                    fieldChanged={this.replyFieldChanged}
                    notification={this.state.notification}
                    reply={this.state.reply}

                    files={this.state.replyFiles}
                    notificationid={this.props.params.notificationid}
                />
            )
        }
    }


    tabRelations = () => {
        return (
            <Relations
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.notification.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.title}
                onCountChange={(count) => this.setState({ relationCount: count })}
            />
        );
    }


    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}

            />
        );

    };



    render() {

        if (!this.state.notification || !this.state.files || !this.state.replyFiles) {
            return (<PortletSpinner />);
        }
        const documentIsFinal = this.state.notification.showtasknotification === 1;

        if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
            return (<Navigate replace to={this.props.workflowItem.paths.listPath} />)
        }
        return (
            <Portlet>
                <ReactToPrint
                    ref={el => (this.printIssueRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printIssueDivRef.current}
                />
                <ReactToPrint
                    ref={el => (this.printAcceptanceRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printAcceptanceDivRef.current}
                />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletHeader
                    title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                    toolbar={this.renderToolbar(documentIsFinal)}
                />
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            ref={(ref) => this.tabPanel = ref}
                            width="100%"
                            deferRendering={false}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}
                        >

                            <Item icon="file" title={this.props.title} render={this.tabDocument} />
                            <Item icon="rename" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_REPLY' })} render={documentIsFinal ? this.tabReply : null} visible={documentIsFinal} />
                            <Item icon="warning" title={this.props.intl.formatMessage({ id: 'CONTRACT.WF.EW.TITLE.RISK_MANAGEMENT' })} render={documentIsFinal ? this.tabRisk.bind(this, this.state.notification.status) : null} visible={documentIsFinal} />
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>
                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    workflowItem={this.props.workflowItem}
                    onDialogResult={this.onModalResponse}
                />
            </Portlet>
        );
    }
}



export default withDetailPageProps(WarningDetail);
