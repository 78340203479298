import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import ClaimsCustomForm from "./CustomForm";
import * as compensationEventCrud from '../../../../crud/compensationEvent.crud';
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import Common from '../../../../shared/common';
import { actionCodes } from "../../../../shared/lookup";


class IssueCreate extends BaseCreatePage {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: true
        };
    }

    submitHandler = async (compensation, setSubmitting) => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            compensation.companyid = companyid;
            compensation.contractid = contractid;
            compensation.projectid = projectid;
            compensation.compensationsource = this.props.notificationType;
            compensation.status = 'NC';
            compensation.showtasknotification = 0;
            compensation.actioncode = actionCodes.CREATE;
            compensation.notifyingparty = this.props.workflowItem.notifyingParty;
            compensation.replyingparty = this.props.workflowItem.replyingParty;



            this.appendRelationship(compensation);
            Common.PropertyConversionsBeforeNoticeSaveRequest(compensation, this.props.customFields, this.props.contractUsers);
            const response = await compensationEventCrud.saveCompensationEvent(compensation);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
            const createdID = Number(response.data);
            this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
        }
    }

    render() {
        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    <ClaimsCustomForm
                        {...this.props}
                        onSubmitHandler={this.submitHandler}
                        customFields={(this.props.customFields || {}).COMMUNICATION}
                        assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(IssueCreate));
