import React, { useState, useRef } from "react";
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { withRouter } from '../../../shared/hoc/withRouter';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { injectIntl } from "react-intl";
import * as assetCrud from "../../../crud/asset.crud";
import * as pdfCrud from "../../../crud/pdf.crud";
import { withSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import * as customFieldCrud from "../../../crud/customField.crud";
import Common from '../../../shared/common';
import { listAccountsAllowedDeleteDraft, listAccountsAllowedExportImportDocumentJSON } from '../../../router/Protector/AccessExceptions';
import * as administratorCrud from "../../../crud/administrator.crud";
import { confirm } from 'devextreme/ui/dialog';

const isAssociatedNoticeEnabled = process.env.REACT_APP_CREATE_ASSOCIATED_NOTICE_ENABLED === 'true';
const isShareButtonEnabled = process.env.REACT_APP_SHARE_BUTTON_ENABLED === 'true';
const isDownloadButtonEnabled = process.env.REACT_APP_DOWNLOAD_BUTTON_ENABLED === 'true' && process.env.REACT_APP_ENVIRONMENT !== 'PROD';
const isAssetsEnabled = process.env.REACT_APP_ASSETS_ENABLED === 'true';

const exportJSON = (jsonData) => {
    const stringifiedJson = JSON.stringify(JSON.parse(jsonData), null, 2);
    Common.downloadFile({
        data: stringifiedJson,
        fileName: 'Document.json',
        fileType: 'text/json',
    });
}

const PageToolbar = (props) => {
    const userProfile = useSelector(store => store.auth.userProfile);
    const [disabled, setDisabled] = useState(false);
    const [loadSpin, setLoading] = useState(false);


    const { documentIsFinal, intl, workflowItem, params, actions, relationParams } = props;
    // const btnResourceKey = (!documentIsFinal && workflowItem.userFilters.mainNotice.communicateAllowed) ? "GENERAL.FORMS.BUTTON_COMMUNICATE_CREATE_ASSOCIATED_NOTICE" : "GENERAL.FORMS.BUTTON_CREATE_ASSOCIATED_NOTICE";
    const relatedActions = isAssociatedNoticeEnabled && actions && actions.showModal && workflowItem.associatedWFListAllowed ? workflowItem.associatedWFListAllowed : [];

    let communicateReplyAllowed = (workflowItem.userFilters.replyNotice || {}).communicateReplyAllowed;
    let saveDraftReplyAllowed = (workflowItem.userFilters.replyNotice || {}).saveDraftReplyAllowed;
    if (props.overrideReplyParty) {
        communicateReplyAllowed = props.overrideCommunicateReplyAllowed;
        saveDraftReplyAllowed = props.overrideSaveDraftReplyAllowed;
    }

    const fileImportJSONInputRef = useRef(null);
    const fileImportReplyJSONInputRef = useRef(null);



    const importJSON = (isReply) => {
        if (isReply) {
            if (fileImportReplyJSONInputRef.current) {
                fileImportReplyJSONInputRef.current.click();
            }
        }
        else {
            if (fileImportJSONInputRef.current) {
                fileImportJSONInputRef.current.click();
            }
        }
    }

    const confirmFileImport = async () => {
        const warningMessage = props.intl.formatMessage({ id: 'GENERAL.FORMS.IMPORT_FILE_WARNING' });
        const warningMessageLabel = props.intl.formatMessage({ id: 'GENERAL.FORMS.IMPORT_FILE_WARNING_LABEL' });
        let result = await confirm(`<div style="max-width:50vw;color: red;">${warningMessage}</div>`, `${warningMessageLabel}`);

        return result;
    }

    const importJSONFileSelected = async (e, isReply) => {
        if (e.stopPropagation) { e.stopPropagation(); }
        if (e.preventDefault) { e.preventDefault(); }
        if (e.target && e.target.files && e.target.files.length > 0) {
            var file = e.target.files[0];
            if (file) {
                try {
                    const fileImportConfirmed = await confirmFileImport();

                    if (!fileImportConfirmed) {
                        //if not confirmed exit save
                        //setSubmitting(false);
                        return;
                    }
                    else {
                        const text = await file.text();
                        const importedFile = JSON.parse(text);

                        let workflowname = props.workflowItem.config.currentModel;
                        let recordid = props.params.notificationid;

                        //Set quotes based on currentModel
                        //Quote
                        if (props.workflowItem.config.currentModel === "CompensationEvent" && params.quotationid) {
                            workflowname = "Quotation";
                            recordid = params.quotationid;
                        } else if (props.workflowItem.config.currentModel === "Rfq") { //RfqQuote
                            if (params.quotationid) {
                                workflowname = "RfqQuotation";
                                recordid = params.quotationid;
                            } else {
                                workflowname = "RequestForQuote";
                            }
                        } else if (props.workflowItem.config.currentModel === "Itq") { //ItqQuote
                            if (params.quotationid) {
                                workflowname = "ItqQuotation";
                                recordid = params.quotationid;
                            } else {
                                workflowname = "InstructionToQuote";
                            }
                        }

                        const newFile = {
                            workflowname: workflowname,
                            recordid: recordid,
                            pdfjson: JSON.stringify(importedFile),
                            isreply: isReply
                            //deleted: 0
                        };

                        await administratorCrud.importFile(newFile);

                        props.enqueueSnackbar(props.intl.formatMessage({ id: 'GENERAL.FORMS.IMPORT_SUCCESS' }), { variant: 'success' });

                        window.location.reload(true);
                    }
                } catch (err) {
                    props.enqueueSnackbar(props.intl.formatMessage({ id: 'GENERAL.FORMS.IMPORT_ERROR' }), { variant: 'error', });
                    console.log(err.toString())
                }
                finally {
                    if (isReply) {
                        if (fileImportReplyJSONInputRef.current) {
                            fileImportReplyJSONInputRef.current.value = null;
                        }
                    }
                    else {
                        if (fileImportJSONInputRef.current) {
                            fileImportJSONInputRef.current.value = null;
                        }
                    }
                }
            }
        }
    }

    const shareButtonOptions = [];
    shareButtonOptions.push({
        text: props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SHARE_MAINNOTICE' }),
        isReply: false
    });

    if (workflowItem.replyEnabled && documentIsFinal) {
        shareButtonOptions.push({
            text: props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SHARE_REPLYNOTICE' }),
            isReply: true
        });
    }




    const printButtonOptions = [];
    if (actions && actions.onPrint && props.documentIsFinal) {
        printButtonOptions.push({
            text: props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_PRINT_MAIN_NOTICE' }),
            isReply: false,
            onClick: props.actions.onPrint
        });
    }


    if (actions && actions.onPrintReply && props.documentIsFinal && workflowItem.replyEnabled && !props.isAwaitingReply) {
        printButtonOptions.push({
            text: props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_PRINT_MAIN_NOTICE_REPLY' }),
            isReply: true,
            onClick: props.actions.onPrintReply
        });
    }

    const documentJsonOptions = [];

    documentJsonOptions.push({
        text: props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_EXPORT_NOTICE_JSON' }),
        isReply: false,
        isExport: true
    });

    if (workflowItem.replyEnabled && documentIsFinal) {
        documentJsonOptions.push({
            text: props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_EXPORT_REPLY_NOTICE_JSON' }),
            isReply: true,
            isExport: true
        });
    }

    documentJsonOptions.push({
        text: props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_IMPORT_NOTICE_JSON' }),
        isReply: false
    });

    if (workflowItem.replyEnabled && documentIsFinal) {
        documentJsonOptions.push({
            text: props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_IMPORT_REPLY_NOTICE_JSON' }),
            isReply: true
        });
    }

    const downloadMainNotice = async (e) => {
        try {
            setDisabled(true)
            setLoading(true)
            const contractid = props.contract.contractid;
            const referenceid = props.params.notificationid;
            const category = props.workflowItem.config.currentModel;
            const title = props.referenceid;
            const resp = await pdfCrud.regeneratePDF(category, referenceid, contractid);
            await Common.ExportFile(title, resp.data);
            props.enqueueSnackbar(props.intl.formatMessage({ id: 'GENERAL.DOWNLOAD.SUCCESSFUL' }), { variant: 'success', });
        } catch (err) {
            console.log(err);
            props.enqueueSnackbar(err.toString(), { variant: 'error' });
        }
        finally {
            setDisabled(false)
            setLoading(false)
        }
    }

    const downloadReplyNotice = async (e) => {
        try {
            setDisabled(true)
            setLoading(true)
            const contractid = props.contract.contractid
            const referenceid = props.params.notificationid;
            const category = props.workflowItem.config.currentModel;
            const title = props.referenceid + '-Reply';
            const resp = await pdfCrud.regenerateReplyPDF(category, referenceid, contractid);
            await Common.ExportFile(title, resp.data);
            props.enqueueSnackbar(props.intl.formatMessage({ id: 'GENERAL.DOWNLOAD.SUCCESSFUL' }), { variant: 'success', });
        } catch (err) {
            console.log(err);
            props.enqueueSnackbar(err.toString(), { variant: 'error' });
        }
        finally {
            setDisabled(false)
            setLoading(false)
        }
    }

    return (
        <div style={{ display: 'flex', flex: 1, flexFlow: 'row-reverse', margin: "20px 0px" }}>
            <LoadIndicator className="button-indicator" visible={props.saveDisabled || loadSpin} />
            {listAccountsAllowedExportImportDocumentJSON.includes(userProfile.accounttype) && (
                <>
                    <input type='file' id='importFile' accept='.json' ref={fileImportJSONInputRef} style={{ display: 'none' }} onChange={async (e) => await importJSONFileSelected(e, false)} />
                    <input type='file' id='importFileReply' accept='.json' ref={fileImportReplyJSONInputRef} style={{ display: 'none' }} onChange={async (e) => await importJSONFileSelected(e, true)} />
                </>
            )}
            <Toolbar

                key={props.key}
                disabled={props.saveDisabled || disabled}
            >

                <ToolbarItem location="after"
                    locateInMenu="auto"
                    widget="dxButton"
                    disabled={props.saveDisabled}
                    options={
                        {
                            icon: "back",
                            onClick: () => props.navigate(workflowItem.paths.listPath),
                            text: intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_BACK_REGISTER" }),
                        }
                    }
                />

                {
                    actions && actions.showModal && !props.documentIsFinal && workflowItem.userFilters.mainNotice.communicateAllowed && relatedActions.length === 0 && (
                        <ToolbarItem location="after"
                            locateInMenu="never"
                            widget="dxButton"
                            disabled={props.saveDisabled}
                            options={
                                {
                                    icon: "parentfolder",
                                    onClick: () => { props.actions.showModal('COMMUNICATE', null); },
                                    text: intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM" }, { ITEM_NAME: workflowItem.notificationName }),
                                    stylingMode: "contained",
                                    type: "success"
                                }
                            }
                        />
                    )
                }
                {
                    actions && actions.showModal && !documentIsFinal && workflowItem.userFilters.mainNotice.communicateAllowed && relatedActions.length > 0 && (
                        <ToolbarItem
                            location="after"
                            // id={"ptComAndCreateRelatedDrop"}
                            key={"ptComAndCreateRelatedDrop"}
                            locateInMenu="never"
                            widget="dxDropDownButton"
                            disabled={props.saveDisabled}
                            options={
                                {
                                    icon: "parentfolder",
                                    splitButton: true,
                                    useSelectMode: false,
                                    dropDownOptions: { width: 300, maxHeight: '50vh' },
                                    stylingMode: "contained",
                                    wrapItemText: true,
                                    elementAttr: { id: "ptComAndCreateRelatedDrop", class: "dx-button-mode-contained dx-button-success", style: "border-radius:4px;" },
                                    items: relatedActions.map(itm => ({
                                        disabled: props.saveDisabled,
                                        icon: "parentfolder",
                                        // stylingMode: "contained",
                                        // type: "success",
                                        text: intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_COMMUNICATE_CREATE_ASSOCIATED_NOTICE" }, { ITEM_NAME: itm.title }),
                                        onClick: () => {
                                            const postActionURL = {
                                                pathname: itm.url,
                                                state: {
                                                    itemBaseProps: {
                                                        ...itm.itemBaseProps,
                                                        refid: params.notificationid,
                                                        path: itm.itemBaseProps.path + params.notificationid
                                                    },
                                                    itemCustomProps: {
                                                        ...relationParams
                                                    }
                                                }
                                            };

                                            props.actions.showModal('COMMUNICATE', postActionURL);
                                        },
                                    })),
                                    onButtonClick: () => { props.actions.showModal('COMMUNICATE', null); },
                                    text: intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM" }, { ITEM_NAME: workflowItem.notificationName }),
                                    // stylingMode: "outlined",
                                    // type: "success"
                                }
                            }
                        />
                    )
                }
                {
                    relatedActions.length > 0 && (
                        <ToolbarItem
                            id={"ptCreateRelatedDrop" + props.key}
                            key={"ptCreateRelatedDrop" + props.key}
                            location="after"
                            locateInMenu="never"
                            widget="dxDropDownButton"
                            disabled={props.saveDisabled}
                            // visible={documentIsFinal}
                            options={
                                {
                                    icon: "add",
                                    stylingMode: "outlined",
                                    type: "normal",
                                    text: intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CREATE_RELATED_ITEM" }),
                                    wrapItemText: true,
                                    dropDownOptions: { width: 300, maxHeight: '50vh' },
                                    items: relatedActions.map(itm => ({
                                        disabled: props.saveDisabled,
                                        icon: "add",
                                        text: intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CREATE_ASSOCIATED_NOTICE" }, { ITEM_NAME: itm.title }),
                                        onClick: () => {
                                            props.navigate(itm.url, {
                                                state: {
                                                    itemBaseProps: {
                                                        ...itm.itemBaseProps,
                                                        refid: params.notificationid,
                                                        path: itm.itemBaseProps.path + params.notificationid
                                                    },
                                                    itemCustomProps: {
                                                        ...relationParams
                                                    }
                                                }
                                            });
                                        },
                                    })),
                                }
                            }
                        />
                    )

                }
                {
                    actions && actions.showModal && !props.documentIsFinal && (
                        <ToolbarItem location="after"
                            locateInMenu="auto"
                            widget="dxButton"
                            disabled={props.saveDisabled}
                            options={
                                {
                                    icon: "save",
                                    onClick: props.actions.showModal.bind(this, 'SAVE_DRAFT', null),
                                    text: intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY" })
                                }
                            }
                        />
                    )
                }
                {
                    actions && actions.showModal && !props.documentIsFinal && listAccountsAllowedDeleteDraft.includes(userProfile.accounttype) && (
                        <ToolbarItem location="after"
                            locateInMenu="auto"
                            widget="dxButton"
                            disabled={props.saveDisabled}
                            options={
                                {
                                    icon: "trash",
                                    onClick: props.actions.showModal.bind(this, 'DELETE_DRAFT', null),
                                    text: intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY" })
                                }
                            }
                        />
                    )
                }
                {
                    actions && actions.showModal && props.documentIsFinal && workflowItem.replyEnabled && props.isAwaitingReply && communicateReplyAllowed && (
                        <ToolbarItem location="after"
                            locateInMenu="never"
                            widget="dxButton"
                            disabled={props.saveDisabled}
                            options={
                                {
                                    icon: "parentfolder",
                                    onClick: props.actions.showModal.bind(this, 'REPLY_SEND', null),
                                    text: intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_SEND_REPLY_ITEM" }, { ITEM_NAME: workflowItem.notificationName }),
                                    stylingMode: "contained",
                                    type: "success"
                                }
                            }
                        />
                    )
                }
                {
                    actions && actions.showModal && props.documentIsFinal && workflowItem.replyEnabled && props.isAwaitingReply && saveDraftReplyAllowed && (
                        <ToolbarItem location="after"
                            locateInMenu="auto"
                            widget="dxButton"
                            disabled={props.saveDisabled}
                            options={
                                {
                                    icon: "save",
                                    onClick: props.actions.showModal.bind(this, 'REPLY_DRAFT', null),
                                    text: intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_SAVE_REPLY_DRAFT" })
                                }
                            }
                        />
                    )
                }
                {
                    actions && actions.onPrint && props.documentIsFinal && (
                        <ToolbarItem location="after"
                            locateInMenu="auto"
                            widget="dxDropDownButton"
                            disabled={props.saveDisabled}
                            options={
                                {
                                    icon: "print",
                                    text: 'Print',
                                    stylingMode: "outlined",
                                    dropDownOptions: { width: 150 },
                                    items: printButtonOptions.map(itm => ({
                                        disabled: props.saveDisabled,
                                        icon: "print",
                                        text: itm.text,
                                        onClick: itm.onClick


                                    }))
                                }
                            }
                        />


                    )
                }
                {
                    isShareButtonEnabled && props.referenceid && (
                        <ToolbarItem location="after"
                            locateInMenu="auto"
                            widget="dxDropDownButton"
                            disabled={props.saveDisabled}
                            options={
                                {
                                    text: 'Share',
                                    icon: 'share',
                                    stylingMode: "outlined",
                                    type: "normal",
                                    dropDownOptions: { width: 150 },
                                    items: shareButtonOptions.map(itm => ({
                                        disabled: props.saveDisabled,
                                        icon: "share",
                                        text: itm.text,
                                        onClick: async () => {
                                            const referenceidMailto = props.referenceid;
                                            const titleNotification = props.workflowItem.title;
                                            const nameMailto = props.contract.name;
                                            const urlLinkMalito = window.location.href;

                                            let assetName = '';
                                            if (isAssetsEnabled && (props.relationParams || {}).assetid) {
                                                try {
                                                    const { data: strAsset } = await assetCrud.getAsset(props.relationParams.assetid);
                                                    const asset = JSON.parse(strAsset)
                                                    assetName = asset.AssetName
                                                } catch (error) {
                                                    assetName = '';
                                                }
                                            }

                                            if ((props.customFieldValues || []).length) {
                                                let to = '';
                                                let cc = '';

                                                try {
                                                    let subtype = null;

                                                    //Set subtype based on currentModel
                                                    //Quote
                                                    if (workflowItem.config.currentModel === "CompensationEvent" && params.quotationid) {
                                                        subtype = "quote";
                                                    } else if (workflowItem.config.currentModel === "Rfq" && params.quotationid) { //RfqQuote
                                                        subtype = "rfqquote";
                                                    } else if (workflowItem.config.currentModel === "Itq" && params.quotationid) { //ItqQuote
                                                        subtype = "itqquote";
                                                    }

                                                    //Get cf values based on subtype or workflowid
                                                    const { data: toAndCc } = subtype
                                                        ? await customFieldCrud.getCustomFieldToAndCcValuesBySubType(props.contract.companyid, subtype, params.quotationid, itm.isReply)
                                                        : await customFieldCrud.getCustomFieldToAndCcValues(props.contract.companyid, workflowItem.id, params.notificationid, itm.isReply);

                                                    to = toAndCc.to;
                                                    cc = toAndCc.cc;
                                                } catch (error) {
                                                    to = '';
                                                    cc = '';
                                                }

                                                let location = `mailto:${to ? to + '?' : '?'}${cc ? 'cc=' + cc + '&' : ''}`;
                                                location += `subject=${titleNotification} ${referenceidMailto} on ${nameMailto}&body=${assetName ? assetName + '%0A' : ''}${referenceidMailto} on ${nameMailto}%0A${urlLinkMalito}`;

                                                window.open(location, '_blank');
                                            } else {
                                                const location = `mailto:?subject=${titleNotification} ${referenceidMailto} on ${nameMailto}&body=${assetName ? assetName + '%0A' : ''}${referenceidMailto} on ${nameMailto}%0A${urlLinkMalito}`;

                                                window.open(location, '_blank');
                                            }
                                        },
                                    }))
                                }

                            }
                        />

                    )
                }

                {

                    isDownloadButtonEnabled && props.documentIsFinal && (
                        <ToolbarItem
                            location="after"
                            locateInMenu="auto"
                            widget="dxButton"
                            disabled={props.saveDisabled}
                            options={
                                {
                                    icon: "download",
                                    onClick: downloadMainNotice,
                                    text: intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_DOWNLOAD" })
                                }
                            }
                        />

                    )
                }
                {

                    isDownloadButtonEnabled && props.documentIsFinal && workflowItem.replyEnabled && !props.isAwaitingReply && (
                        <ToolbarItem
                            location="after"
                            locateInMenu="auto"
                            widget="dxButton"
                            disabled={props.saveDisabled}
                            options={
                                {
                                    icon: "download",
                                    onClick: downloadReplyNotice,
                                    text: intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_DOWNLOAD_REPLY" })
                                }
                            }
                        />

                    )
                }
                {
                    props.documentIsFinal && props.notification && listAccountsAllowedExportImportDocumentJSON.includes(userProfile.accounttype) && (
                        <ToolbarItem location="after"
                            locateInMenu="auto"
                            widget="dxDropDownButton"
                            disabled={props.saveDisabled}
                            options={
                                {
                                    text: 'Document JSON',
                                    icon: 'export',
                                    stylingMode: "outlined",
                                    type: "normal",
                                    dropDownOptions: { width: 150 },
                                    items: documentJsonOptions.map(itm => ({
                                        visible: itm.isReply ? props.notification.pdfreplyjsoncontent : props.notification.pdfjsoncontent,
                                        //icon: "share",
                                        text: itm.text,
                                        onClick: itm.isReply
                                            ? (itm.isExport ? exportJSON.bind(this, props.notification.pdfreplyjsoncontent)
                                                : importJSON.bind(this, itm.isReply))
                                            : (itm.isExport ? exportJSON.bind(this, props.notification.pdfjsoncontent)
                                                : importJSON.bind(this, itm.isReply))
                                    }))
                                }
                            }
                        />

                    )
                }

            </Toolbar>
        </div>

    );
}

export default withRouter(injectIntl(withSnackbar(PageToolbar)));