import React from 'react';
import axios from 'axios';
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import * as earlyWarningReductionCrud from '../../../../crud/earlyWarningReduction.crud';
import { withSnackbar } from 'notistack';
import { injectIntl } from "react-intl";
import sanitizeHtml from 'sanitize-html-react';
import { htmlSenitizeOptions } from '../../../../shared/config/DxFormProperties';

class WarningRiskNotifications extends React.Component {
    state = {
        notifications: null,
    }
    componentDidMount() {
        this.fetchNotifications();
    }
    componentWillUnmount() {
        this.requestSource.cancel('Operation canceled by the user.');
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.warningReduction !== nextProps.warningReduction ||
            this.state.notifications !== nextState.notifications) {
            return true;
        }
        else {
            return false;
        }
    }
    fetchNotifications = () => {
        const CancelToken = axios.CancelToken;
        this.requestSource = CancelToken.source();
        earlyWarningReductionCrud.getEarlyWarningReductions(this.props.warning.warningid, this.requestSource.token)
            .then(response => {
                this.setState({ notifications: JSON.parse(response.data.aggregatelist) });
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    this.props.enqueueSnackbar('Failed to load Early Warning notifications', { variant: 'error', });
                }
            });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.warningReduction !== this.props.warningReduction) {
                this.fetchNotifications();
            }
        }
    }

    renderActionColumn = (cellInfo) => {
        if (cellInfo.data.solution) {
            try {
                //return HTMLReactParser();
                const cleanHTML = sanitizeHtml(cellInfo.data.solution, { ...htmlSenitizeOptions });
                return (
                    <div style={{ marginTop: 20 }} dangerouslySetInnerHTML={{ __html: cleanHTML }} />
                );
            } catch (error) {
                console.log(error,cellInfo.data.solution)
                return "HTML PARSING ERROR"
            }
            
        }
        else return null;

    }
    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }

        return (
            <div style={{ margin: 20 }}>
                <DataGridBase
                    version="1"
                    name={this.props.intl.formatMessage({ id: 'CONTRACT.WF.EW.LABEL.RISK_REDUCTION' })}
                    dataSource={this.state.notifications}
                    keyExpr="reductionid"
                    noDataText={this.props.intl.formatMessage({ id: 'CONTRACT.WF.EW.WARNING.NO_ACTION' })}
                    stateStoring={{ enabled: false }}
                    columns={[
                        { resource: "GENERAL.FORMS.FIELD.ACTION", dataField: 'solution', cellRender: this.renderActionColumn },
                        { resource: "GENERAL.FORMS.FIELD.ACTION_OWNER", dataField: "action" },
                    ]}
                />
            </div>

        );
    }
}


export default injectIntl(withSnackbar(WarningRiskNotifications));
