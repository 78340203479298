/* eslint-disable import/no-anonymous-default-export */
import Workflows from "./Workflows";
import Placeholders from "./Placeholders";
export default {
    id: 106,
    title: 'Submissions',
    path: 'submissions',
    icon: "flaticon2-hangouts-logo",
    workflows: Workflows,
    placeholders: Placeholders,
};