/* eslint-disable array-callback-return */
import DefaultWorkflows from './DefaultWorkflows';
import DefaultResources from './DefaultResources';
import CreateRelatedWFBlackList from './CreateRelatedWFBlackList';
import { userFilterCategories, contractUserPartyOptions } from "../shared/lookup";
import TemplateHelper from "./TemplateHelper";
import Common from '../shared/common';
import { placeholderTypes } from "./Types/Placeholder";
import { allMessages } from "../../_metronic/i18n/I18nProvider";
import store from "../store/store";
import { typeOptions as actionTypes } from "../pages/admin/Contracts/Contract/ContractActions";

// Evaluate Filters withing WF.userFilters and assign allowed flags for the user
// Default filters used if filters are missing
const GetEvaluatedUserFilters = (defaultWF, contractWF, contract, user) => {
    try {
        const userFilters = {}
        if ((defaultWF.config || {}).userFilterCategories) {
            let accessAllowed = true;
            if (defaultWF.config.userFilterCategories.includes(userFilterCategories.ACCESS)) {
                let accessFilter = (contractWF.userFilters || {}).accessFilter;
                if (typeof accessFilter === 'undefined') {
                    accessFilter = TemplateHelper.GetDefaultFilter_AccessFilter(defaultWF)
                }
                accessAllowed = TemplateHelper.EvaluateUserAccessRight(accessFilter, user, contract);
                userFilters.accessAllowed = accessAllowed;
                userFilters.accessFilter = accessFilter;
            }
            if (defaultWF.config.userFilterCategories.includes(userFilterCategories.MAIN_NOTICE)) {
                let createAllowed = false;
                let createFilter = (contractWF.userFilters.mainNotice || {}).createFilter;
                if (typeof createFilter === 'undefined') {
                    createFilter = TemplateHelper.GetDefaultFilter_CreateFilter(defaultWF);
                }
                createAllowed = TemplateHelper.EvaluateUserActionRight(createFilter, user, contract);
                if (process.env.REACT_APP_PAYMENT_BATCH_ENABLED === 'true' && Boolean(contract.otherprice)) {
                    //IF BATCH PAYMENT ENABLED
                    //WE PREVENT CREATE OF Regular Payments
                    if (contractWF.id === 20 || contractWF.id === 21) {
                        createAllowed = false;
                    }
                }

                let viewDraftAllowed = false;
                let viewDraftFilter = (contractWF.userFilters.mainNotice || {}).viewDraftFilter;
                if (typeof viewDraftFilter === 'undefined') {
                    viewDraftFilter = TemplateHelper.GetDefaultFilter_ViewDraftFilter(defaultWF);
                }
                viewDraftAllowed = TemplateHelper.EvaluateUserActionRight(viewDraftFilter, user, contract);

                let communicateAllowed = false;
                let communicateFilter = (contractWF.userFilters.mainNotice || {}).communicateFilter;
                if (typeof communicateFilter === 'undefined') {
                    communicateFilter = TemplateHelper.GetDefaultFilter_ComminicateFilter(defaultWF);
                }
                communicateAllowed = TemplateHelper.EvaluateUserActionRight(communicateFilter, user, contract);

                userFilters.mainNotice = {
                    createFilter: createFilter,
                    createAllowed: accessAllowed && createAllowed,
                    viewDraftFilter: viewDraftFilter,
                    viewDraftAllowed: accessAllowed && viewDraftAllowed,
                    communicateFilter: communicateFilter,
                    communicateAllowed: accessAllowed && communicateAllowed
                }

            }
            if (defaultWF.config.userFilterCategories.includes(userFilterCategories.REPLY_NOTICE)) {
                let saveDraftReplyAllowed = false;
                let saveDraftReplyFilter = (contractWF.userFilters.replyNotice || {}).saveDraftReplyFilter
                if (typeof saveDraftReplyFilter === 'undefined') {
                    saveDraftReplyFilter = TemplateHelper.GetDefaultFilter_SaveDraftReplyFilter(defaultWF);
                }
                saveDraftReplyAllowed = TemplateHelper.EvaluateUserActionRight(saveDraftReplyFilter, user, contract);

                let communicateReplyAllowed = false;
                let communicateReplyFilter = (contractWF.userFilters.replyNotice || {}).communicateReplyFilter
                if (typeof communicateReplyFilter === 'undefined') {
                    communicateReplyFilter = TemplateHelper.GetDefaultFilter_CommunicateReplyFilter(defaultWF);
                }
                communicateReplyAllowed = TemplateHelper.EvaluateUserActionRight(communicateReplyFilter, user, contract);

                userFilters.replyNotice = {
                    saveDraftReplyFilter: saveDraftReplyFilter,
                    saveDraftReplyAllowed: accessAllowed && saveDraftReplyAllowed,
                    communicateReplyFilter: communicateReplyFilter,
                    communicateReplyAllowed: accessAllowed && communicateReplyAllowed
                }
            }
            if (defaultWF.config.userFilterCategories.includes(userFilterCategories.ACTION_TAB)) {
                let actionAllowed = false;
                let actionFilter = (contractWF.userFilters.actionTab || {}).actionFilter
                if (typeof actionFilter === 'undefined') {
                    actionFilter = TemplateHelper.GetDefaultFilter_ActionFilter(defaultWF);
                }
                actionAllowed = TemplateHelper.EvaluateUserActionRight(actionFilter, user, contract);
                userFilters.actionTab = {
                    actionFilter: actionFilter,
                    actionAllowed: accessAllowed && actionAllowed
                }
            }
            if (defaultWF.config.userFilterCategories.includes(userFilterCategories.QUOTATION)) {
                let createQuoteAllowed = false;
                let createQuoteFilter = (contractWF.userFilters.quoteMainNotice || {}).createQuoteFilter;
                if (typeof createQuoteFilter === 'undefined') {
                    createQuoteFilter = TemplateHelper.GetDefaultFilter_CreateQuoteFilter(defaultWF);
                }
                createQuoteAllowed = TemplateHelper.EvaluateUserActionRight(createQuoteFilter, user, contract);

                let communicateQuoteAllowed = false;
                let communicateQuoteFilter = (contractWF.userFilters.quoteMainNotice || {}).communicateQuoteFilter;
                if (typeof communicateQuoteFilter === 'undefined') {
                    communicateQuoteFilter = TemplateHelper.GetDefaultFilter_ComminicateQuoteFilter(defaultWF);
                }
                communicateQuoteAllowed = TemplateHelper.EvaluateUserActionRight(communicateQuoteFilter, user, contract);

                userFilters.quoteMainNotice = {
                    createQuoteFilter: createQuoteFilter,
                    createQuoteAllowed: accessAllowed && createQuoteAllowed,
                    communicateQuoteFilter: communicateQuoteFilter,
                    communicateQuoteAllowed: accessAllowed && communicateQuoteAllowed
                }
            }
            if (defaultWF.config.userFilterCategories.includes(userFilterCategories.QUOTE_REPLY)) {
                let saveQuoteDraftReplyAllowed = false;
                let saveQuoteDraftReplyFilter = (contractWF.userFilters.quoteReplyNotice || {}).saveQuoteDraftReplyFilter
                if (typeof saveQuoteDraftReplyFilter === 'undefined') {
                    saveQuoteDraftReplyFilter = TemplateHelper.GetDefaultFilter_SaveQuoteDraftReplyFilter(defaultWF);
                }
                saveQuoteDraftReplyAllowed = TemplateHelper.EvaluateUserActionRight(saveQuoteDraftReplyFilter, user, contract);

                let communicateQuoteReplyAllowed = false;
                let communicateQuoteReplyFilter = (contractWF.userFilters.quoteReplyNotice || {}).communicateQuoteReplyFilter
                if (typeof communicateQuoteReplyFilter === 'undefined') {
                    communicateQuoteReplyFilter = TemplateHelper.GetDefaultFilter_CommunicateQuoteReplyFilter(defaultWF);
                }
                communicateQuoteReplyAllowed = TemplateHelper.EvaluateUserActionRight(communicateQuoteReplyFilter, user, contract);

                userFilters.quoteReplyNotice = {
                    saveQuoteDraftReplyFilter: saveQuoteDraftReplyFilter,
                    saveQuoteDraftReplyAllowed: accessAllowed && saveQuoteDraftReplyAllowed,
                    communicateQuoteReplyFilter: communicateQuoteReplyFilter,
                    communicateQuoteReplyAllowed: accessAllowed && communicateQuoteReplyAllowed
                }
            }
            if (defaultWF.config.userFilterCategories.includes(userFilterCategories.NOTIFY)) {
                let notifyFilter = contractWF.userFilters.notifyFilter;
                if (typeof notifyFilter === 'undefined') {
                    notifyFilter = TemplateHelper.GetDefaultFilter_NotifyFilter(defaultWF)
                }
                userFilters.notifyFilter = notifyFilter;
            }
            if (defaultWF.config.userFilterCategories.includes(userFilterCategories.RESOLVE_ACTION_TAB)) {
                let resolveActionAllowed = false;
                let resolveActionFilter = (contractWF.userFilters.resolveActionTab || {}).resolveActionFilter;
                if (typeof resolveActionFilter === 'undefined') {
                    //to support historic ones, first we're looking at action tab
                    if (defaultWF.config.userFilterCategories.includes(userFilterCategories.ACTION_TAB)) {
                        let actionFilter = (contractWF.userFilters.actionTab || {}).actionFilter;
                        if (typeof actionFilter !== 'undefined') {
                            resolveActionFilter = actionFilter;
                        }
                    }
                    //if it's still undefined
                    if (typeof resolveActionFilter === 'undefined') {
                        resolveActionFilter = TemplateHelper.GetDefaultFilter_ResolveActionFilter(defaultWF);
                    }
                }
                resolveActionAllowed = TemplateHelper.EvaluateUserActionRight(resolveActionFilter, user, contract);
                userFilters.resolveActionTab = {
                    resolveActionFilter: resolveActionFilter,
                    resolveActionAllowed: accessAllowed && resolveActionAllowed
                }
            }
        }
        return userFilters;
    } catch (err) {
        console.log("Error in SetEvaluatedUserFilters");
        throw err;
    }
}
const GetWorkflow = (template, wfid) => {
    for (let wfgIndex = 0; wfgIndex < template.workflowGroups.length; wfgIndex++) {
        if (template.workflowGroups[wfgIndex].workflows) {
            const wfIndex = template.workflowGroups[wfgIndex].workflows.findIndex(wf => wf.id === wfid);
            if (wfIndex > -1) {
                return template.workflowGroups[wfgIndex].workflows[wfIndex];
            }
        }
    }
}
const GetCreateAllowedWorkflows = (template) => {
    let createAllowedWfs = [];
    template.workflowGroups.forEach(wfg => {
        wfg.workflows.forEach(wf => {
            if (wf?.userFilters?.mainNotice?.createAllowed) {
                createAllowedWfs.push(wf);
            }
        });

    });
    return createAllowedWfs;
}


const replyPeriodOptionExceptions = ["ASKED_ON_COMMUNICATION_FORM", "NOTIFICATION_DATE", "CUSTOM", "ORIGINAL_DATE"]
/// adjustContractTemplate / Prepare to load in to use
/// This function is used to covert the contract template saved in DB to Object that is going to be used in store
/// Load defaults for template if not existing
export function adjustContractTemplate(contract, template, contractUser, userProfile, lang = "en") {
    const langStore = store.getState().i18n;
    const templateGlobalResources = {};
    const errors = [];
    //EA EXCEPTION HANDLING
    //INSERT "Activity Schedule" WF to EA contract programmatically even though they dont exist
    if (template.workflowGroups && contract && contract.contracttype && contract.contracttype.includes('Packaged') && process.env.REACT_APP_PRODUCT_KEY === 'EA') {
        const paymentGroupIndex = template.workflowGroups.findIndex(wfg => wfg.id === 108);
        if (paymentGroupIndex > -1) {
            const activityScheduleWFIndex = template.workflowGroups[paymentGroupIndex].workflows.findIndex(wfg => wfg.id === 45);
            if (activityScheduleWFIndex === -1) {
                const defaultActivityScheduleWF = DefaultWorkflows.find(wfg => wfg.id === 108).workflows.find(dwf => dwf.id === 45);
                template.workflowGroups[paymentGroupIndex].workflows.push(defaultActivityScheduleWF);
            }
        }
    }
    const contractPartiesSet = new Set();
    if (template.workflowGroups) {
        //MAP WORKFLOW GROUPS ADJUSTED
        template.workflowGroups = template.workflowGroups.map(WfGroup => {
            //FIND DEFAULT SETTINGS FOR WFG
            const defaultWFG = DefaultWorkflows.find(wfg => wfg.id === WfGroup.id);
            let unifiedClauseList = null
            try {
                if (defaultWFG) {
                    WfGroup.icon = defaultWFG.icon;
                    WfGroup.path = defaultWFG.path;
                }
                else {
                    console.log('defaultWFG not found', WfGroup);
                    throw new Error("WFG does not exist!")
                }
                //CHECK IF ANY PLACEHOLDER IS MISSING
                if (!WfGroup.placeholders) {
                    //ASSIGN EMPTY IF NOT DEFINED AT ALL
                    WfGroup.placeholders = [];
                }
                //CREATE QUICK ACCESS OBJECT / HELP REMOVE SEARCH IN ARRAY
                WfGroup.placeholdersQA = {};

                //ADD MISSING PLACEHOLDERS
                //"skipDefaultLoadingIfMissing" IS THE INDICATOR FLAG TO ADD
                const missingPLC = defaultWFG.placeholders.filter(plc => WfGroup.placeholders.findIndex(exg => exg.replacementKey === plc.replacementKey) === -1 && !plc.skipDefaultLoadingIfMissing);
                if (missingPLC && missingPLC.length > 0) {
                    WfGroup.placeholders.push(...JSON.parse(JSON.stringify(missingPLC)));
                }

                //MAP PLACEHOLDERS ADJUSTED
                WfGroup.placeholders = WfGroup.placeholders.map(PH => {
                    const defaultPH = defaultWFG.placeholders.find(dfg => dfg.replacementKey === PH.replacementKey);
                    if (!defaultPH) {
                        //THIS PH IS PROBABLY NO LONGER USED SHOULD NOT BE IN TEMPLATE
                        return null;
                    }
                    if ([placeholderTypes.RESOURCES, placeholderTypes.ACTION_REMINDERS].includes(defaultPH.type) || defaultPH.appendMissingFields) {
                        //FIND MISSING FIELDS
                        //"skipDefaultLoadingIfMissing"  IS THE INDICATOR FLAG TO ADD
                        const missingFields = defaultPH.values.filter(dPH => (PH.values.findIndex(ePH => ePH.field === dPH.field) === -1) && !dPH.skipDefaultLoadingIfMissing);
                        if (missingFields && missingFields.length > 0) {
                            PH.values.push(...JSON.parse(JSON.stringify(missingFields)));
                        }
                    }

                    //CREATE WFG.resources direct easy access mapping 
                    if (defaultPH.type === placeholderTypes.RESOURCES) {
                        WfGroup.resources = PH.values.reduce((obj, rsc) => ({ ...obj, [rsc.field]: rsc.display }), {});
                    }
                    if (defaultPH.type === placeholderTypes.UNIFIED_CLAUSES) {
                        unifiedClauseList = PH.values.filter(item => item.isActive === true);
                    }
                    PH = { ...defaultPH, values: PH.values };
                    WfGroup.placeholdersQA[PH.replacementKey] = PH;
                    return PH;
                });

                //filter out not existing PHs
                WfGroup.placeholders = WfGroup.placeholders.filter(PH => PH != null)

            } catch (error) {
                console.log('Error while filling template missing placeholders', WfGroup, error);
            }


            //MAP WORKFLOW ADJUSTED
            let mappedWorkflows = WfGroup.workflows.map(workflow => {
                const originalWF = defaultWFG.workflows.find(dwf => dwf.id === workflow.id);
                if (!originalWF) {
                    console.log('defaultWorkFlow not found', workflow);
                    throw new Error("WF does not exist!")
                }

                //MAKE COPY AS IT WILL BE EDITED
                const defaultWorkFlow = { ...originalWF, config: { ...originalWF.config } }

                //Quick access to group title
                workflow.groupTitle = WfGroup.title;
                if (unifiedClauseList) {
                    workflow.clauses = unifiedClauseList.filter(cl => cl.wfid === workflow.id);
                }

                if (!workflow.replyPeriodOption && defaultWorkFlow.replyPeriodOption) {
                    workflow.replyPeriodOption = defaultWorkFlow.replyPeriodOption;
                    workflow.replyPeriod = defaultWorkFlow.replyPeriod;
                }

                if (defaultWorkFlow.config.replyPossible) {
                    if (!workflow.hasOwnProperty("replyEnabled")) {
                        if (defaultWorkFlow.hasOwnProperty("replyEnabled")) {
                            workflow.replyEnabled = defaultWorkFlow.replyEnabled;
                        }
                        else {
                            workflow.replyEnabled = true;
                        }
                    }
                }

                if (defaultWorkFlow.config.quotationPossible) {
                    if (!workflow.hasOwnProperty("quoteNotificationName")) {
                        workflow.quoteNotificationName = defaultWorkFlow.quoteNotificationName;
                    }
                    if (!workflow.hasOwnProperty("quoteReferencePrefix")) {
                        workflow.quoteReferencePrefix = defaultWorkFlow.quoteReferencePrefix;
                    }
                }
                if (defaultWorkFlow.config.configurableFinancialLimit) {
                    if (!workflow.hasOwnProperty("financialLimitEnabled")) {
                        if (defaultWorkFlow.hasOwnProperty("financialLimitEnabled")) {
                            workflow.financialLimitEnabled = defaultWorkFlow.financialLimitEnabled;
                        }
                        else {
                            workflow.financialLimitEnabled = false;
                        }
                    }
                }

                //Asset Fields Enabled
                if (process.env.REACT_APP_ASSETS_ENABLED === 'true') {
                    if (!workflow.hasOwnProperty("assetFieldsEnabled")) {
                        if (defaultWorkFlow.hasOwnProperty("assetFieldsEnabled")) {
                            workflow.assetFieldsEnabled = defaultWorkFlow.assetFieldsEnabled;
                        }
                        else {
                            workflow.assetFieldsEnabled = false;
                        }
                    }
                }
                else {
                    workflow.assetFieldsEnabled = false;
                }

                if (!workflow.hasOwnProperty("notifyAttachments")) {
                    if (defaultWorkFlow.hasOwnProperty("notifyAttachments")) {
                        workflow.notifyAttachments = defaultWorkFlow.notifyAttachments;
                    }
                }
                if (!workflow.hasOwnProperty("replyAttachments")) {
                    if (defaultWorkFlow.hasOwnProperty("replyAttachments")) {
                        workflow.replyAttachments = defaultWorkFlow.replyAttachments;
                    }
                }

                //#region PARTIES
                if (typeof originalWF.notifyingParty === "function") {
                    defaultWorkFlow.notifyingParty = originalWF.notifyingParty(contract);
                }
                if (typeof originalWF.replyingParty === "function") {
                    defaultWorkFlow.replyingParty = originalWF.replyingParty(contract);
                }
                if (typeof originalWF.actioningParty === "function") {
                    defaultWorkFlow.actioningParty = originalWF.actioningParty(contract);
                }
                if (typeof originalWF.resolveActioningParty === "function") {
                    defaultWorkFlow.resolveActioningParty = originalWF.resolveActioningParty(contract);
                }
                if (typeof originalWF.quotingParty === "function") {
                    defaultWorkFlow.quotingParty = originalWF.quotingParty(contract);
                }
                if (typeof originalWF.quoteReplyingParty === "function") {
                    defaultWorkFlow.quoteReplyingParty = originalWF.quoteReplyingParty(contract);
                }
                //ASSIGN replyingParty if missing
                if (workflow.replyEnabled && !workflow.replyingParty && defaultWorkFlow.replyingParty) {
                    workflow.replyingParty = defaultWorkFlow.replyingParty;
                }
                //ASSIGN replyingParty from default if not configurable
                if (workflow.replyEnabled && !defaultWorkFlow.config?.configurableReplyTab && defaultWorkFlow.replyingParty) {
                    workflow.replyingParty = defaultWorkFlow.replyingParty;
                }
                if (defaultWorkFlow.config) {
                    contractPartiesSet.add(defaultWorkFlow.notifyingParty);
                    contractPartiesSet.add(workflow.replyingParty);//can be changed so lookup to wf setting
                    contractPartiesSet.add(defaultWorkFlow.actioningParty);
                    contractPartiesSet.add(defaultWorkFlow.resolveActioningParty);
                    contractPartiesSet.add(workflow.quotingParty);//can be changed so lookup to wf setting
                    contractPartiesSet.add(workflow.quoteReplyingParty);//can be changed so lookup to wf setting
                }
                //#endregion

                if (!workflow.hasOwnProperty("form_IncludeCompanyName")) {
                    if (defaultWorkFlow.hasOwnProperty("form_IncludeCompanyName")) {
                        workflow.form_IncludeCompanyName = defaultWorkFlow.form_IncludeCompanyName;
                    }
                    else {
                        workflow.form_IncludeCompanyName = false;
                    }
                }

                if (!workflow.hasOwnProperty("form_CompanyName")) {
                    if (defaultWorkFlow.hasOwnProperty("form_CompanyName")) {
                        workflow.form_CompanyName = defaultWorkFlow.form_CompanyName;
                    }
                    else {
                        workflow.form_CompanyName = 'PARTY';
                    }
                }

                if (!workflow.hasOwnProperty("form_CompanyLogo")) {
                    if (defaultWorkFlow.hasOwnProperty("form_CompanyLogo")) {
                        workflow.form_CompanyLogo = defaultWorkFlow.form_CompanyLogo;
                    }
                    else {
                        workflow.form_CompanyLogo = 'PARTY';
                    }
                }


                //////ASSIGN FORM COMPANY LOGO ID & NAME BASED ON SETTINGS
                try {
                    if (defaultWorkFlow.notifyingParty) {
                        try {
                            workflow.form_NotifyCompanyLogoID = Common.GetPartyOrUserCompanyID(contract, workflow.form_CompanyLogo, workflow.notifyingParty, userProfile);
                            workflow.form_NotifyCompanyName = Common.GetPartyOrUserCompanyName(contract, workflow.form_CompanyName, workflow.notifyingParty, userProfile);

                        } catch (err) {
                            errors.push(`Error loading workflow WF-ID[${workflow.id}]\n${err.toString()}`);
                        }

                    }
                    if (workflow.replyEnabled) {
                        try {
                            workflow.form_ReplyCompanyLogoID = Common.GetPartyOrUserCompanyID(contract, workflow.form_CompanyLogo, workflow.replyingParty, userProfile);
                            workflow.form_ReplyCompanyName = Common.GetPartyOrUserCompanyName(contract, workflow.form_CompanyName, workflow.replyingParty, userProfile);
                        } catch (err) {
                            errors.push(`Error loading workflow WF-ID[${workflow.id}]\n${err.toString()}`);
                        }
                    }
                }
                catch (err) {
                    console.log('Error assigning workflow company logo and company', workflow, err);
                    throw err;
                }

                //ASSIGN pageTitle IF NOT DEFINED
                if (!workflow.pageTitle) {
                    if (defaultWorkFlow.notifyingParty) {
                        workflow.pageTitle = allMessages[langStore.lang]["GENERAL.NOTICE.TITLE"].replace("{ITEM_NAME}", workflow.notificationName).replace("{PARTY_NAME}", `$${defaultWorkFlow.notifyingParty}_NAME`)
                    }
                    else {
                        workflow.pageTitle = workflow.title;
                    }
                }

                if (!workflow.hasOwnProperty("includePdfjson")) {
                    if (defaultWorkFlow.hasOwnProperty("includePdfjson")) {
                        workflow.includePdfjson = defaultWorkFlow.includePdfjson;
                    }
                }


                const evaluatedUserFilters = GetEvaluatedUserFilters(defaultWorkFlow, workflow, contract, contractUser);
                let wfEnabled = true;
                if (defaultWorkFlow.config.enabled) {
                    wfEnabled = defaultWorkFlow.config.enabled();
                }
                //return empty WF if access not allowed
                if (!evaluatedUserFilters.accessAllowed || !wfEnabled) {
                    console.log("WF NOT ALLOWED:" + workflow.id)
                    return null;
                }

                const basePathTemplate = `/contract/:contractid/${defaultWFG.path}/${defaultWorkFlow.config.paths.base}/`;
                const groupFullPath = `/contract/${contract.contractid}/${defaultWFG.path}/`
                const basePath = basePathTemplate.replace(":contractid", contract.contractid);
                //#region PRE-CHECK IF SOURCE FILES EXIST
                try {
                    require("../pages/contract/" + defaultWFG.path + "/" + defaultWorkFlow.config.paths.base);
                } catch {
                    console.log(
                        `Path not found for ${defaultWFG.path}/${defaultWorkFlow.config.paths.base} required in the contract configuration!`
                    );
                    return null;
                }
                //#endregion
                return {
                    ...workflow,
                    userFilters: evaluatedUserFilters,
                    paths: {
                        basePathTemplate: basePathTemplate,
                        basePath: basePath,
                        routerMatch: `/${defaultWFG.path}/${defaultWorkFlow.config.paths.base}/*`,
                        groupPath: WfGroup.path,
                        groupFullPath,
                        createPath: defaultWorkFlow.config.paths.create ? basePath + defaultWorkFlow.config.paths.create : null,
                        listPath: defaultWorkFlow.config.paths.list ? basePath + defaultWorkFlow.config.paths.list : null,
                        defaultPath: defaultWorkFlow.config.paths.default ? basePath + defaultWorkFlow.config.paths.default : null,
                    },
                    // canCreate: canCreate,
                    // canCommunicate: canCommunicate,
                    // canViewDraft: canViewDraft,
                    // canReply: canReply,
                    // canDraftReply: canDraftReply,
                    // canAction: canAction,
                    // parentPath: WfGroup.path,
                    // path: defaultWorkFlow.path,
                    // model: defaultWorkFlow.model,
                    // type: defaultWorkFlow.type,
                    // referenceType: defaultWorkFlow.referenceType,
                    config: { ...defaultWorkFlow.config },
                    // basePath: `/contract/${contract ? contract.contractid : ""}/${defaultWFG.path}/${defaultWorkFlow.path}/`
                    defaultWF: defaultWorkFlow
                };
            });
            //filter out Access restricted workflows
            mappedWorkflows = mappedWorkflows.filter(wf => wf != null)



            if (mappedWorkflows && mappedWorkflows.length > 0) {
                return {
                    ...WfGroup,
                    workflows: mappedWorkflows,
                };
            }

        });
    }
    //REMOVE SKIPPED EMPTY WFGS FROM ARRAY
    template.workflowGroups = template.workflowGroups.filter(wfg => wfg != null && wfg.workflows && wfg.workflows.length > 0);

    let newTemplate = null;

    //Assign Global Template Resources to Resources Object
    if (template.resources) {
        // const newResources = {};
        let stringifiedTemplate = JSON.stringify(template);
        const missingResources = DefaultResources.filter(rsc => (template.resources.findIndex(givenRsc => givenRsc.key === rsc.key) === -1 && !rsc.skipDefaultLoadingIfMissing));
        template.resources = template.resources.concat(missingResources);

        template.resources.forEach(rsc => {
            if (rsc.type === 'NUMBER') {
                templateGlobalResources[rsc.key] = Number(rsc.value);
            }
            else {
                templateGlobalResources[rsc.key] = rsc.value;
            }
        });

        //HANDLE "PROP_QUOTE_DURATION"  EXCEPTION
        if (!templateGlobalResources.hasOwnProperty("PROP_QUOTE_DURATION")) {
            templateGlobalResources.PROP_QUOTE_DURATION = templateGlobalResources.QUOTE_DURATION;
        }
        //HANDLE  "PROP_REQUOTE_DURATION" EXCEPTION
        if (!templateGlobalResources.hasOwnProperty("PROP_REQUOTE_DURATION")) {
            templateGlobalResources.PROP_REQUOTE_DURATION = templateGlobalResources.REQUOTE_DURATION;
        }

        //REPLACE STRING PLACEHOLDERS IN TEMPLATE RESOURCES (EX. $SUPPLIER_NAME)
        Object.keys(templateGlobalResources).forEach(key => {
            stringifiedTemplate = stringifiedTemplate.replace(new RegExp('\\$' + key, 'g'), templateGlobalResources[key]);
        });
        newTemplate = JSON.parse(stringifiedTemplate);
        newTemplate.resources = templateGlobalResources;


        //AFTER REPLACE KEYS APPLIED (REPLACE KEYS MIGHT BE USED IN TITLES NOTIFICATIONS)
        //ADDITIONAL RESOURCES PER WF CAN BE ADDED
        //ASSIGN ADDITIONAL GLOBAL RESOUCES
        //WF NOTIFICATION NAME & TITLE
        //CAN BE USED AS RESORCE REPLACEMENT KEYS LIKE "$WF_1_NOTIFICATION_NAME"
        let workflowNameResources = {};
        newTemplate.workflowGroups.forEach(wfg => {
            workflowNameResources[`WFG_${wfg.id}_TITLE`] = wfg.title;
            wfg.workflows.forEach(wf => {
                workflowNameResources[`WF_${wf.id}_NOTIFICATION_NAME`] = wf.notificationName;
                workflowNameResources[`WF_${wf.id}_TITLE`] = wf.title;
            })
        })

        ///RE-APPLY REPLACE STRING FOR WF NAMES
        stringifiedTemplate = JSON.stringify(newTemplate);
        Object.keys(workflowNameResources).forEach(key => {
            stringifiedTemplate = stringifiedTemplate.replace(new RegExp('\\$' + key, 'g'), workflowNameResources[key]);
        });
        newTemplate = JSON.parse(stringifiedTemplate);
        newTemplate.resources = { ...newTemplate.resources, ...workflowNameResources };
    }
    else {
        newTemplate = template;
    }

    const createAllowedWorkflows = GetCreateAllowedWorkflows(newTemplate);

    newTemplate.workflowGroups.forEach(WFG => {
        WFG.workflows.forEach(WF => {
            if (WF.replyPeriodOption) {
                if (!replyPeriodOptionExceptions.includes(WF.replyPeriodOption)) {
                    const globalResourcesOverriden = TemplateHelper.getGlobalResourcesOverriden(newTemplate.resources, WF)
                    //FROM RESOURCES
                    WF.replyPeriod = globalResourcesOverriden[WF.replyPeriodOption];
                }
                else if (WF.replyPeriodOption !== "CUSTOM") {
                    // NOTIFICATION_DATE  OR ASKED_ON_COMMUNICATION_FORM OR ORIGINAL_DATE
                    WF.replyPeriod = undefined;
                }
            }
            try {
                const createRelatedBlackList = [...(CreateRelatedWFBlackList[WF.id] || [])];
                WF.associatedWFListAllowed =
                    createAllowedWorkflows
                        .filter(x => !createRelatedBlackList.includes(x.id)) //FILTER OUT BLACKLIST FROM CREATE ALLOWED LIST
                        .map(relatedWF =>
                        ({
                            text: "TO_BE_REPLACED",//allMessages[lang]["GENERAL.FORMS.BUTTON_CREATE_ASSOCIATED_NOTICE"].replace("{ITEM_NAME}",relatedWF.title)
                            url: relatedWF.paths.createPath,
                            itemBaseProps: {
                                path: WF.paths.basePath,
                                refid: null,
                                reftype: WF.config.referenceType,
                                seqno: null,
                                wfgid: WFG.id,
                                wfid: WF.id
                            },
                            title: relatedWF.title
                        })
                        )
                // const associatedWFList = CreateRelatedWFSettings[WF.id];
                // if (associatedWFList) {
                //     const associatedWFListAllowed = [];
                //     associatedWFList.forEach(wfid => {
                //         const relatedWF = GetWorkflow(newTemplate, wfid);
                //         if (relatedWF && (relatedWF.userFilters.mainNotice || {}).createAllowed) {
                //             associatedWFListAllowed.push({
                //                 text: "TO_BE_REPLACED",//allMessages[lang]["GENERAL.FORMS.BUTTON_CREATE_ASSOCIATED_NOTICE"].replace("{ITEM_NAME}",relatedWF.title)
                //                 url: relatedWF.paths.createPath,
                //                 itemBaseProps: {
                //                     path: WF.paths.basePath,
                //                     refid: null,
                //                     reftype: WF.config.referenceType,
                //                     seqno: null,
                //                     wfgid: WFG.id,
                //                     wfid: WF.id
                //                 },
                //                 title: relatedWF.title
                //             })
                //         }
                //     });
                //     WF.associatedWFListAllowed = associatedWFListAllowed;

                // }
            } catch (error) {
                console.log(error);
            }
        });
    });

    //adjust display value of parties accourding to template
    const contractPartyOptions = contractUserPartyOptions.map(opt => ({ ...opt, display: newTemplate.resources[opt.key + "_NAME"] }));
    newTemplate.parties = {
        clientExist: contractPartiesSet.has("CLIENT"),
        supervisorExist: contractPartiesSet.has("SUPERVISOR"),
        supplierExist: contractPartiesSet.has("SUPPLIER"),
        pmExist: contractPartiesSet.has("PM"),
        options: contractPartyOptions
    };

    var accessibleWfIds = [];

    newTemplate.workflowGroups.forEach(wfg => {
        wfg.workflows.forEach(wf => {
            accessibleWfIds.push(wf.id);
        })
    })

    newTemplate.workflowsQA = newTemplate.workflowGroups.reduce((obj, wfg) => {
        const workflowsIndexed = wfg.workflows.reduce((obj, wf) => ({ ...obj, [wf.id]: wf }), {});
        return ({ ...obj, ...workflowsIndexed, [wfg.id]: wfg })
    }
        , {});
    newTemplate.availableActionTypes = actionTypes.map(type => {
        if (type.wfid > 0 && !accessibleWfIds.includes(type.wfid)) {
            //wf does not exist
            return null;
        }
        return {
            ...type,
            display: (type.wfid > 0) ? newTemplate.workflowsQA[type.wfid].title : type.display
        }
    }).filter(x => !!x);
    newTemplate.accessibleWfIds = accessibleWfIds;
    // if(errors.length>0){
    //     alert("Template Load Errors:\n"+errors.join('\n'));
    // }
    return newTemplate;
}