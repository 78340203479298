import React from 'react';
import DropDownBox from 'devextreme-react/drop-down-box';
import { ScrollView } from 'devextreme-react/scroll-view';
import ObjectFieldList from "./ObjectFieldList";
import DefaultWorkflows from "../../../../../Templates/DefaultWorkflows";
import TreeView from 'devextreme-react/tree-view';

export default class WorkflowsWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.treeDataSource = DefaultWorkflows.reduce((itemsArray, WfGroup) => {
            const availableWorkflows = WfGroup.workflows.filter(wf => wf.config.showInCustomFields);
            if ((availableWorkflows || []).length > 0) {
                itemsArray.push({
                    id: WfGroup.id,
                    title: WfGroup.title,
                    itemType: 'WFG',
                    expanded: false
                });
            }
            availableWorkflows.forEach(workflow => {
                itemsArray.push({
                    parentid: WfGroup.id,
                    id: WfGroup.id + "_" + workflow.id,
                    workflowid: workflow.id,
                    title: workflow.config.description,
                    workflow: workflow,
                    subtype: "COMMUNICATION",
                    itemType: 'WF',
                    expanded: false,
                });
                if (workflow.config.replyPossible) {
                    itemsArray.push({
                        parentid: WfGroup.id,
                        id: WfGroup.id + "_" + workflow.id + "_R",
                        workflowid: workflow.id,
                        title: workflow.config.description + " [Reply]",
                        workflow: workflow,
                        subtype: "REPLY",
                        itemType: 'WF',
                        expanded: false,
                    });
                }
            })

            //EXCEPTIONS
            if (WfGroup.id === 101) {
                itemsArray.push({
                    parentid: WfGroup.id,
                    id: WfGroup.id + "_EWREDUCTION",
                    workflowid: 0,
                    title: "Early Warning Reduction",
                    workflow: { id: 0 },
                    subtype: "EWREDUCTION",
                    itemType: 'WF',
                    expanded: false,
                });
            }
            if (WfGroup.id === 103) {
                itemsArray.push({
                    parentid: WfGroup.id,
                    id: WfGroup.id + "_QUOTE",
                    workflowid: 0,
                    title: "Quotation",
                    workflow: { id: 0 },
                    subtype: "QUOTE",
                    itemType: 'WF',
                    expanded: false,
                });
                itemsArray.push({
                    parentid: WfGroup.id,
                    id: WfGroup.id + "_QUOTE_R",
                    workflowid: 0,
                    title: "Quotation [Reply]",
                    workflow: { id: 0 },
                    subtype: "QUOTE_REPLY",
                    itemType: 'WF',
                    expanded: false,
                });
            }
            if (WfGroup.id === 114) {
                itemsArray.push({
                    parentid: WfGroup.id,
                    id: WfGroup.id + "_RFQQUOTE",
                    workflowid: 0,
                    title: "RFQ Quotation",
                    workflow: { id: 0 },
                    subtype: "RFQQUOTE",
                    itemType: 'WF',
                    expanded: false,
                });
                itemsArray.push({
                    parentid: WfGroup.id,
                    id: WfGroup.id + "_RFQQUOTE_R",
                    workflowid: 0,
                    title: "RFQ Quotation [Reply]",
                    workflow: { id: 0 },
                    subtype: "RFQQUOTE_REPLY",
                    itemType: 'WF',
                    expanded: false,
                });
            }
            if (WfGroup.id === 118) {
                itemsArray.push({
                    parentid: WfGroup.id,
                    id: WfGroup.id + "_ITQQUOTE",
                    workflowid: 0,
                    title: "ITQ Quotation",
                    workflow: { id: 0 },
                    subtype: "ITQQUOTE",
                    itemType: 'WF',
                    expanded: false,
                });
                itemsArray.push({
                    parentid: WfGroup.id,
                    id: WfGroup.id + "_ITQQUOTE_R",
                    workflowid: 0,
                    title: "ITQ Quotation [Reply]",
                    workflow: { id: 0 },
                    subtype: "ITQQUOTE_REPLY",
                    itemType: 'WF',
                    expanded: false,
                });
            }
            return itemsArray;
        }, []);
        this.state = {
            treeBoxValue: []
        }
    }
    syncTreeViewSelection = (e) => {
        const treeView = (e.component.selectItem && e.component)
            || (this.treeView && this.treeView.instance);

        if (treeView) {
            if (e.value === null) {
                treeView.unselectAll();
            } else {
                const values = e.value || this.state.treeBoxValue;
                values && values.forEach((value) => {
                    treeView.selectItem(value);
                });
            }
        }

        if (e.value !== undefined) {
            this.setState({
                treeBoxValue: e.value,
            });
        }
    }
    treeViewItemSelectionChanged = (e) => {
        this.setState({
            treeBoxValue: e.component.getSelectedNodeKeys(),
        });
    }
    treeViewRender = () => {
        return (
            <TreeView
                dataSource={this.treeDataSource}
                ref={(ref) => { this.treeView = ref; }}
                dataStructure="plain"
                keyExpr="id"
                parentIdExpr="parentid"
                displayExpr="title"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                selectNodesRecursive={true}
                selectByClick={true}
                onContentReady={this.syncTreeViewSelection}
                onItemSelectionChanged={this.treeViewItemSelectionChanged}
                searchMode="contains"
                searchEnabled={true}
                itemsExpr="items"
            />
        );
    }
    render() {
        const { fields, object, companyid } = this.props;
        const selectedItems = this.treeDataSource.filter(itm => (this.state.treeBoxValue || []).includes(itm.id) && itm.itemType === "WF");
        return (
            <div>
                <div style={{ display: 'flex', width: '100%', padding: '10px', justifyContent: "center" }}>
                    <DropDownBox
                        value={this.state.treeBoxValue}
                        valueExpr="id"
                        parentIdExpr="parentid"
                        displayExpr="title"
                        placeholder="Select workflow forms..."
                        showClearButton={true}
                        dataSource={this.treeDataSource}
                        onValueChanged={this.syncTreeViewSelection}
                        contentRender={this.treeViewRender}
                        width={400}
                    />
                </div>
                {
                    (selectedItems.length > 0) && (
                        <ScrollView scrollByContent={true} scrollByThumb={true} direction="horizontal" height={500} bounceEnabled={true} useNative={false} >
                            <div style={{ display: "flex", height: 450 }}>
                                {
                                    selectedItems.map(item => (
                                        <div key={item.id + "_" + companyid} style={{ minWidth: 400, width: 400, margin: 20, height: "100%" }}>
                                            <ObjectFieldList
                                                key={item.id + "_" + companyid}
                                                companyid={companyid}
                                                object={object}
                                                fields={((fields || {})[item.workflowid] || {})[item.subtype]}
                                                workflow={item.workflow}
                                                subtype={item.subtype}
                                                title={item.title}
                                                onFieldsUpdated={this.props.onFieldsUpdated}
                                                onTriggerRefresh={this.props.onTriggerRefresh}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </ScrollView>
                    )
                }

            </div>
        );
    }
}
