/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import DataGrid, { Editing, Paging, Scrolling, HeaderFilter, SearchPanel, Button, Column, Item, Form } from 'devextreme-react/data-grid';
import * as contractDuck from '../../../../store/ducks/contract.duck';
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as webhookCrud from '../../../../crud/webhook.crud';
import { injectIntl } from "react-intl";
import { confirm as dxConfirm } from 'devextreme/ui/dialog';
import { SimpleItem, RequiredRule, PatternRule } from 'devextreme-react/form'
import { Popup } from 'devextreme-react/popup';

class Webhook extends Component {

    constructor(props) {
        super(props);
        this.allowEdit = this.props.protectedRouteProps.allowAccess;
        this.eventName = [
            { value: 0, display: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.OTHER"}) },
            { value: 1, display: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.COMMUNICATEDNOTICE" }) },
            { value: 2, display: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.USERPROFILE" }) },
            { value: 3, display: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.COMPANYPROFILE" }) },
            { value: 4, display: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.PROJECTPROFILE" }) },
            { value: 5, display: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.CONTRACTPROFILE" }) },
            { value: 6, display: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.FILEPROFILE" }) },
        ]
        this.state = {
            webhooks: null,
            webhooksHistory:null,
            isPopupVisible: false,
            isLoading: false,
            error:null
            
        }
        this.webHooksHistoryColumns = [
            { caption: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.FIELDS.ID" }), dataField: "Id" },
            { caption: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.FIELDS.RESULT" }), dataField: "Result" },
            { caption: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.FIELDS.STATUSCODE" }), dataField: "StatusCode" },
            { caption: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.FIELDS.WEBHOOKCATEGORY" }), dataField: "WebHookCategory" },
            { caption: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.FIELDS.ACTIONCODE" }), dataField: "WebHookActionCode" },
            { caption: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.FIELDS.EXCEPTION" }), dataField: "Exception" },
            { caption: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.FIELDS.TIMESTAMP" }), dataField: "TimeStamp" }
        ];
    }
    
    componentDidMount() {
        this.getWebHooks();
    }
    
    togglePopup = async (e) => {
        if(this.state.isPopupVisible === false){
            
            e.cancel = await this.getWebHooksHistory(e.row.data.id);
            this.setState({ isPopupVisible: true });
        } else {
            
            this.setState({ isPopupVisible: false });
        }
    };



    getWebHooksHistory = async (id) => {
        try {
           
           const result = await webhookCrud.historyWebHook(id)
           this.setState({ webhooksHistory: result.data });
          
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }




    
    getWebHooks = async () => {
        try {
           
           const result = await webhookCrud.getWebHooks()
           this.setState({ webhooks: result.data });
        }
        catch (err) {

            const errorMessage = err.toString();
            this.setState({ error: errorMessage });
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }

    
    
    //editing row
    onRowUpdating = async (e) => {
        const updateWebHook = { ...e.oldData, ...e.newData};
        e.cancel = this.saveWebHookPromise(updateWebHook,e);
    }


    saveWebHookPromise = (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await webhookCrud.updateWebHook(data);
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            } 
        });
    }
    //deleting row
    onRowRemoving = async (e) => {
        e.cancel = this.deleteWebHookPromise(e.row.data.id);
       
    }

    deleteWebHookPromise = (id) => { 
        return new Promise(async (resolve, reject) => {
            try {


                let result = await dxConfirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "WEBHOOK.ISACTIVE.DEACTIVATED" })}</div>`, this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.DEACTIVATE.WEBHOOK" }));
               
                    
                        if(result === true){
                            await webhookCrud.deleteWebHook(id);
                            await this.getWebHooks();
                            resolve(false);
                        } else{
                            resolve(false);
                        }
               
            } catch (error) {
                console.log(error);
                resolve(false);
            }
        });
    }

    //adding row

    onRowInserting = async (e) => {
        const newWebHook = { ...e.data };
        e.cancel = this.createWebHookPromise(newWebHook,e);
    }
    createWebHookPromise = (webhook, e) => {
        return new Promise(async (resolve, reject) => {
            //const webhookUri = webhook.webhookUri
            try {
                const resp = await webhookCrud.createWebHook(webhook);
                e.data.webhookid = resp.data;
                this.getWebHooks();
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        })
    }

    onRowRestoring = async (e) => {
        e.cancel = this.restoreWebhook(e.row.data.id);
    }
    restoreWebhook = (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await dxConfirm(`<div style="max-width:50vw;">${this.props.intl.formatMessage({ id: "WEBHOOK.ISACTIVE.ACTIVATED" })}</div>`, this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.ACTIVATE.WEBHOOK" }));
               
                    
                        if(result === true){
                            await webhookCrud.restoreWebHook(id);
                            await this.getWebHooks();
                            resolve(false);
                        } else{
                            resolve(false);
                        }
               
            } catch (error) {
                resolve(false);
            }
        });
    }
    

    onInitNewRow = async (e) => {
        e.data = { deleted: 0 };
    }
   
    allowDeleting = (e) => {

        return this.allowEdit && !e.row.data.lastamendingreference;
    }

    allowUpdating = (e) => {
        return this.allowEdit && !e.row.data.lastamendingreference;
    }
 
    render() {
        if (!this.state.webhooks && !this.state.error) {
            return (<><PortletSpinner title='Connecting to FastDraft Webhooks platform, please wait...'/> </>);
        } 

        if (this.state.error) {
            return (<>
            <Portlet>
                <PortletBody>
                    <div style={{ textAlign: "center", fontWeight: 600, marginTop: "0.5rem", fontSize: "20px" }} className="text-gray-1200" >{this.state.error}</div>
                    <div style={{ textAlign: "center", fontWeight: 600, marginTop: "0.5rem" }} className="text-gray-800" >Please refresh the page!</div>
                </PortletBody>
            </Portlet>
            </>);
        } 
        
        return (
            <Portlet>
                <PortletHeader resource="WEBHOOK.TITLE" />
                <PortletBody>
                    <Popup id="popup"
                    showTitle={true}
                    title="Webhooks History"
                    visible={this.state.isPopupVisible}
                    hideOnOutsideClick={true}
                    onHiding={this.togglePopup}
                    //dataSource={this.state.webhooks}
                    showCloseButton={true}
                    enableBodyScroll={true}
                    height='auto'
                    >
                        <DataGrid
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        remoteOperations={false}
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        LoadIndicator={false}
                        columnResizingMode='widget'
                        dataSource={this.state.webhooksHistory}
                        columns={this.webHooksHistoryColumns}
                        >
                            <Paging defaultPageSize={10} />
                        </DataGrid>
                    </Popup>
                    
                    <DataGrid
                        dataSource={this.state.webhooks}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        remoteOperations={false}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnResizingMode='NextColumn'
                        onRowUpdating={this.onRowUpdating}
                        onRowRemoving={this.onRowRemoving}
                        onInitNewRow={this.onInitNewRow}
                        onRowInserting={this.onRowInserting}
                        onRowRestoring={this.onRowRestoring}
                        style = {{maxHeight:'67vh', minWidth:'100%'}}
                        name="webHooks"
                        keyExpr="id"
                        // loadPanel={{ showIndicator: !this.state.webhooks }}
                        loadPanel={{
                            enabled: true,
                            text: "Please wait, loading data...",
                            showIndicator: !this.state.webhooks,
                            showPane: true, 
                          }}
                    >
                        <Editing
                            mode="popup"
                            allowUpdating={this.allowUpdating}
                            allowRestoring={this.allowRestoring}
                            allowAdding={this.allowEdit}
                            allowDeleting={true}
                            showCloseButton={true}
                            confirmDelete={true}
                            popup={{ title: this.props.intl.formatMessage({ id: "WEBHOOK.FORMS.MANAGE.WEBHOOK" }), height: 380, width: 600, showTitle: true }}
                            useIcons={true}
                            
                            // form={{
                            //     items: [
                            //         { dataField: "webhookUri", colSpan: 2 , validation: { type: 'pattern', pattern: urlRegexPattern, warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.LINK_INVALID" }) }  },
                            //         { dataField: "description", colSpan: 2},
                            //         { dataField: 'hookEventType', colSpan: 2 ,editorType: 'dxSelectBox', editorOptions: { dataSource: this.eventName, valueExpr: 'value', displayExpr: 'display' }},
                            //     ]
                            // }}                                               
                        >
                            <Form>
                                <Item itemType="group" colSpan={2}>
                                    <SimpleItem dataField="webhookUri" >
                                    <RequiredRule message={this.props.intl.formatMessage({ id: "GENERAL.WEBHOOK.FORMAT" })} />
                                        <PatternRule
                                            pattern={/^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?)$/}
                                            message={this.props.intl.formatMessage({ id: "GENERAL.WEBHOOK.FORMAT" })}
                                        />
                                    </SimpleItem>
                                    <SimpleItem dataField="description" >
                                        
                                    </SimpleItem>
                                    <SimpleItem dataField="hookEventType" >
                                        
                                    </SimpleItem>
                                </Item>
                            </Form>
                        </Editing>
                        <Column caption={this.props.intl.formatMessage({ id: 'WEBHOOK.FORMS.FIELDS.WEBHOOKURI' })} dataField='webhookUri' width={350} />
                        <Column caption={this.props.intl.formatMessage({ id: 'WEBHOOK.FORMS.FIELDS.DESCRIPTION' })} dataField='description' width={400} />
                        <Column caption={this.props.intl.formatMessage({ id: 'WEBHOOK.FORMS.FIELDS.HOOKEVENT' })} dataField='hookEventType'  lookup={{dataSource: this.eventName, valueExpr: 'value', displayExpr: 'display' }} width={"auto"}/>
                        <Column caption={this.props.intl.formatMessage({ id: 'WEBHOOK.FORMS.FIELDS.LASTTRIGGER' })} dataField='lastTrigger' width={"auto"}/>
                        <Column caption={this.props.intl.formatMessage({ id: 'WEBHOOK.FORMS.FIELDS.ISACTIVE' })} dataField='isActive' width={"auto"} />
                        <Column caption={this.props.intl.formatMessage({ id: 'WEBHOOK.FORMS.FIELDS.OPTIONS' })} type='buttons' width={150}>
                            <Button name='edit' />
                            <Button 
                            name='delete' 
                            text='Disable'
                            visible={true}
                            onClick={this.onRowRemoving}
                            />
                            <Button 
                            icon="revert"
                            text="Enable"
                            visible={true}
                            onClick={this.onRowRestoring}
                            />
                            <Button 
                            icon="clock"
                            text="History"
                            visible={true}
                            onClick={this.togglePopup}
                            />
                        </Column>
                        <HeaderFilter visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={false} defaultText="" />
                        <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                        
                    </DataGrid>
                    
                </PortletBody>
            </Portlet>
        );
    }
}





const mapStateToProps = store => ({
    lang: store.i18n.lang,
    contract: store.contract.contract,
    userProfile: store.auth.userProfile
});

const mapDispatchToProps = {
    loadContract: contractDuck.actions.fetchStart,
}

export default injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Webhook)));
