/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import { withRouter } from '../../shared/hoc/withRouter';

const Card = (props) => {
    return (
        <div style={{ marginTop: "1.5rem" }} className="col-12 col-sm-12 col-lg">
            <div className='card h-100'>
                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                    <a href='#' onClick={(e) => { e.preventDefault(); props.navigate(props.url); }} className='text-gray-800 text-hover-primary d-flex flex-column'>
                        <div style={{ display: "inline-block", flexShrink: 0, position: "relative", borderRadius: "0.475rem", marginBottom: "1.5rem" }} className='symbol symbol-75px mb-6'>
                            <img src={toAbsoluteUrl(props.icon)} alt='' />
                        </div>
                        <div style={{ fontSize: '1.15rem', fontWeight: 600, marginBottom: "0.5rem" }} className="text-gray-800" >{props.title}</div>
                    </a>
                    <div style={{ color: "#b5b5c3" }} className='text-gray-400'>{props.description}</div>
                </div>
            </div>
        </div>

    )
}

export default withRouter(Card)
