import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SettingsHome from "./Home";
import SystemDetail from "./System/Detail";
import System from "./System/System";
import Tags from "./Tags/Tags";
import CustomFields from "./CustomFields/CustomFields";
import Protector from "../../../router/Protector/Protector";
import Webhook from "./Webhook/Webhook";
class SettingsRouter extends React.Component {
    render() {
        return (
            <Routes caseSensitive>
                <Route path="/" exact element={<Protector><SettingsHome /></Protector>} sensitive />
                <Route path="/tags" exact element={<Protector><Tags /></Protector>} sensitive />
                <Route path="/system" exact element={<Protector><System /></Protector>} sensitive />
                <Route path="/system/:settingId" exact element={<Protector><SystemDetail /></Protector>} sensitive />
                <Route path="/custom-fields" exact element={<Protector><CustomFields /></Protector>} sensitive />
                <Route path="/webhook" exact element={<Protector><Webhook /></Protector>} sensitive />
                <Route path="*" element={<Navigate replace to="/contracts" />} />
            </Routes>
        );
    }

}


export default SettingsRouter;