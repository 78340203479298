import React from 'react';
import { connect } from "react-redux";
import * as securityCrud from '../../../crud/security.crud';
import * as authDuck from '../../../store/ducks/auth.duck';
import { withRouter } from '../../../shared/hoc/withRouter';
import { FormattedMessage } from "react-intl";

class FDConnectorLoginHandler extends React.Component {
    constructor(props) {
        super(props);
        if (props.tokenId) {
            // console.log('token is:', this.props.tokenId);
            this.fetchDecryptedToken(props.tokenId);
        }

    }
    fetchDecryptedToken = async (tokenId) => {
        securityCrud.getDecryptedToken(tokenId)
            .then(response => {
                if (!response.data.accessToken.includes('Error') && !response.data.accessToken.includes('error')) {
                    this.props.storeSetAuthToken(
                        response.data.accessToken, 
                        response.data.reportingToken,
                        "",
                        "",
                        "FDCONNECTOR"
                    );
                }
                else {
                    this.props.navigate(`/error/error-v5`);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response && err.response.status) {
                    if (err.response.status === 401) {
                        this.props.navigate(`/error/error-v4`);
                    }
                    else {
                        this.props.navigate(`/error/error-v5`);
                    }
                }
                else {
                    this.props.navigate(`/error/error-v5`);
                }

            })
    }
    render() {
        return (
            <div>
                <FormattedMessage id="AUTH.GENERAL.AUTHORIZING" />
                <button onClick={(e) => { e.preventDefault(); this.props.startLogout(); }}> <FormattedMessage id="AUTH.GENERAL.LOGOUT" /></button>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    projects: store.projects.projects,
});

const mapDispatchToProps = {
    storeSetAuthToken: authDuck.actions.setAuthToken,
    startLogout: authDuck.actions.startLogout
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FDConnectorLoginHandler));