import React from 'react';
import { TableRow, TableHead, TableCell, TableBody, Table, Typography } from "@mui/material";
import HTMLReactParser from 'html-react-parser';
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import sanitizeHtml from 'sanitize-html-react';
import { htmlSenitizeOptions } from '../../../../shared/config/DxFormProperties';

class WarningArea extends React.Component {
    constructor(props) {
        super(props);        
        this.state = {
            notifications: null,
        };
    }

    componentDidMount() {
        this.setNotifications();
    }

    setNotifications = () => {
        if (this.props.earlyWarningsList) {
            const notifications = this.props.earlyWarningsList
                .filter(itm => itm.reference === this.props.priority && itm.showtasknotification === 1)
                .sort((a, b) => a.sequencenumber - b.sequencenumber);
            this.setState({ notifications: notifications });
        }
    }
    
    renderActionColumn = (data) => {
        if (data) {
            const cleanHTML = sanitizeHtml(data, { ...htmlSenitizeOptions });
            return HTMLReactParser(cleanHTML);
        } else {
            return null;
        }
    }
    
    render() {
        if (!this.state.notifications) {
            return null;
        }
        return (
            <div style={{ marginTop: 20 }}>
                <Typography className={this.props.headerFontClass} style={{ marginLeft: 16 }} variant="h6" component="div" gutterBottom>
                    {this.props.title}
                </Typography>
                <Table style={{ border: '1px solid #ccc', borderRadius: '2px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.ID" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.SUBJECT" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.NOTIFIED_DATE" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.NOTIFIED_BY" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.ACTION" /></TableCell>
                            <TableCell><FormattedMessage id="CONTRACT.HEALTH.FIELD.OWNER" /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.state.notifications.map(warning => (
                                <TableRow key={warning.referenceid}>
                                    <TableCell>{warning.referenceid}</TableCell>
                                    <TableCell>{warning.title}</TableCell>
                                    <TableCell>{moment(warning.notificationdate).format('L')}</TableCell>
                                    <TableCell>{warning.party}</TableCell>
                                    <TableCell>{this.renderActionColumn(warning.solution)}</TableCell>
                                    <TableCell>{warning.owner}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export default WarningArea;