import React from 'react';
import Form, { SimpleItem, GroupItem, RequiredRule } from 'devextreme-react/form';
import { listAccountsAllowedEditTemplateDefaultFlag } from '../../../../../router/Protector/AccessExceptions';


class TabGeneral extends React.Component {
    updateParent = (e) => {
        this.props.valueChangeHandler(e.dataField, e.value);
    }
    formFields = () => {
        const formFields = [];
        if (this.props.mode === 'TEMPLATE') {
            formFields.push((
                <SimpleItem key={1} label={{ text: 'Master Contract Name' }} dataField="name" editorOptions={{ readOnly: (this.props.mode === 'CONTRACT') }}>
                    <RequiredRule message="Master Contract Name is required" />
                </SimpleItem>
            ));
        }

        if (this.props.mode === 'TEMPLATE') {
            formFields.push((
                <GroupItem key={3} caption="Availability" style={{ fontSize: '10px' }}>
                    <SimpleItem
                        label={{ text: 'Is System Default?' }}
                        dataField="isSystemDefault"
                        editorType="dxSwitch"
                        editorOptions={{
                            readOnly: (this.props.readOnly || !listAccountsAllowedEditTemplateDefaultFlag.includes(this.props.userProfile.accounttype)),
                            switchedOffText: "No",
                            switchedOnText: "Yes",
                        }}>
                    </SimpleItem>
                    <SimpleItem
                        label={{ text: 'Is Globally Available?' }}
                        dataField="isGlobal"
                        editorType="dxSwitch"
                        editorOptions={{
                            readOnly : this.props.readOnly,
                            switchedOffText: "No",
                            switchedOnText: "Yes",
                        }}>
                    </SimpleItem>
                    <SimpleItem
                        label={{ text: 'Available Companies' }}
                        dataField="companies"
                        editorType="dxTagBox"
                        visible={!this.props.template.isGlobal}
                        editorOptions={{
                            readOnly : this.props.readOnly,
                            dataSource: this.props.companies,
                            displayExpr: 'name',
                            valueExpr: 'companyid',
                            hideSelectedItems: true,
                            searchEnabled: true,
                            searchMode: 'contains',
                            searchExpr: 'name',
                            searchTimeout: 200,
                            minSearchLength: 0,
                            placeholder: 'Choose companies...',
                        }}>
                    </SimpleItem>
                </GroupItem>
            ));
        }
        return formFields;
    }
    render() {

        return (
            <div style={{ padding: 20, width: '100%' }}>
                <Form
                    formData={this.props.template}
                    readOnly={false}
                    showColonAfterLabel={true}
                    repaintChangesOnly={false}
                    cacheEnabled={false}
                    onFieldDataChanged={this.updateParent}>
                    <GroupItem>
                        {this.formFields()}
                    </GroupItem>
                </Form>
            </div>
        );
    }
}

export default TabGeneral