import React from 'react';
import ObjectFieldList from "./ObjectFieldList";
import WorkflowsWrapper from "./WorkflowsWrapper";

export default function CustomItem(companyid, companyFields, onFieldsUpdated, onTriggerRefresh, object) {
    if (companyid && companyFields) {

        // let fields = [];
        // const fieldsGroup = companyFields.find(gr => gr.objectname === object.objectname);
        // if (fieldsGroup) {
        //     fields = fieldsGroup.fields;
        // }
        switch (object.objectname) {
            case "Workflows":
                // const allWF = WorkflowHelper.GetAllWorkflowsByReference();
                // const Column = ({ index, style }) => (

                // );
                // const itemKey = index => {
                //     // Find the item at the specified index:
                //     const item = allWF[index];

                //     // Return a value that uniquely identifies this item.
                //     // Typically this will be a UID of some sort.
                //     return item.id;
                // };
                // return (
                //     <AutoSizer disableHeight={true}  >
                //         {({ height, width }) => (
                //             <List
                //                 height={500}
                //                 itemCount={allWF.length}
                //                 itemSize={520}
                //                 layout="horizontal"
                //                 width={width}
                //                 itemKey={itemKey}
                //                 itemData={{ object, companyid, companyFields }}
                //             >
                //                 {WFColumn}
                //             </List>
                //         )}
                //     </AutoSizer>
                // )
                return (
                    <WorkflowsWrapper key={object.objectname + companyid} companyid={companyid} object={object} fields={(companyFields || {})[object.objectname]} onFieldsUpdated={onFieldsUpdated} onTriggerRefresh={onTriggerRefresh} />
                    // <ScrollView scrollByContent={true} scrollByThumb={true} direction="horizontal" height={500} bounceEnabled={true} useNative={false} >
                    //     <div style={{ display: "flex", height: 450 }}>
                    //         {
                    //             WorkflowHelper.GetAllWorkflowsByReference().map(wf => (
                    //                 <div key={wf.id + "_" + object.objectname + "_" + companyid} style={{ minWidth: 400, width: 400, margin: 20, height: "100%" }}>
                    //                     {/* <ObjectFieldList key={object.objectname + companyid} companyid={companyid} object={object} fields={companyFields[object.objectname]} /> */}
                    //                     <ObjectFieldList2
                    //                         key={wf.id + "_" + object.objectname + "_" + companyid}
                    //                         companyid={companyid} object={object}
                    //                         fields={companyFields[object.objectname][wf.id]}
                    //                         workflow={wf}
                    //                     />
                    //                 </div>
                    //             ))
                    //         }
                    //     </div>
                    // </ScrollView>
                )
            case "Contract":
            case "Company":
            case "Project":
            case "CompanyUser":
                return (
                    <ObjectFieldList key={object.objectname + companyid} companyid={companyid} object={object} fields={(companyFields || {})[object.objectname]} onFieldsUpdated={onFieldsUpdated} onTriggerRefresh={onTriggerRefresh} />
                )
            default:
                return (<div>Undefined object</div>);
        }
    }
    else {
        return (<div>Please select a company...</div>)
    }


}
