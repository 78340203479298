import React, { Component } from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import * as contractsCrud from '../../../../crud/contracts.crud';
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import moment from 'moment';
import { GetCurrencyCodes } from "../../../../shared/lookup";
import { FormattedMessage, injectIntl } from "react-intl";
import Common from '../../../../shared/common';
import { withRouter } from '../../../../shared/hoc/withRouter';
import {  SimpleItem, RequiredRule, PatternRule } from 'devextreme-react/form'
import DataGrid, { Paging, SearchPanel, StateStoring,Export, ColumnChooser, ColumnFixing, Editing,  Form, Toolbar, Column,Item as ToolbarItem, } from 'devextreme-react/data-grid';
import { Item as FormItem, ValidationRule } from 'devextreme-react/form';
import * as priceListItemsCrud from '../../../../crud/pricelistitem.crud'
import * as priceListCrud from '../../../../crud/pricelist.crud'
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
class PriceListItems extends Component {
    constructor(props) {
        super(props);
        this.currencyCodes = GetCurrencyCodes();
        this.state = {
            formData: {
              name: '',
              description: '',
              quantity: 0,
              rate: 0,
              price: 0,
            },
            pricelists: null,
            pricelist:null,
            showModal: false,
            currency:null,
            priceListName:null
          };
        
    }
    
   

    componentDidMount() {
        this.loadList();
        this.getPriceList();
    }

    //adding a row
    onRowInserting = async (e) => {
        const newPriceListItem = { ...e.data };
        e.cancel = this.createPriceListItems(newPriceListItem,e);
    }
    handleFieldChange = (fieldName, value) => {
        this.setState(prevState => ({
          formData: {
            ...prevState.formData,
            [fieldName]: value,
            price: fieldName === 'quantity' || fieldName === 'rate'
              ? prevState.formData.quantity * prevState.formData.rate
              : prevState.formData.price,
          },
        }));
      };

    createPriceListItems = (pricelistitem, e) => {
        return new Promise(async (resolve, reject) => {
            
            try {
                const pricelistid =this.props.params.id
                pricelistitem.pricelistid = pricelistid
                
                const resp = await priceListItemsCrud.createPriceListItems(pricelistitem)
                
                this.loadList();
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        })
    }
    onExporting = (e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        
        
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
           
            worksheet.columns.forEach(column => {
                column.eachCell({ includeEmpty: true }, cell => {
                    if (typeof cell.value === 'number' && Math.abs(cell.value) > 999999999) {
                        cell.numFmt = '#,##0'; 
                    }
                });
            });
    
            
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PriceListItems.xlsx');
            });
        });
    }
    getPriceList = () => {
        
        const pricelistid =this.props.params.id
        
        priceListCrud.getPriceList(pricelistid)
            .then(response => {
               
                let resultData = response.data;
                let currencyData = response.data.currency
                let priceListName = response.data.name
                
                this.setState({ pricelist: resultData, currency: currencyData, priceListName:priceListName });
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    loadList = () => {
        const contractid = this.props.contract.contractid;
        const pricelistid =this.props.params.id
       
        priceListItemsCrud.loadList(contractid, pricelistid)
            .then(response => {
                // let resultData = JSON.parse(response.data);
                let resultData = response.data;
                
                this.setState({ pricelistsitems: resultData });
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    
    //editing a row
    onRowUpdating = async (e) => {
        const { companyid, projectid, contractid } = this.props.contract;
        const newObject = { ...e.oldData, ...e.newData, companyid, projectid, contractid };
        e.cancel = this.updatePriceListItems(newObject);

    }
    updatePriceListItems = (data) => {
      

        const updatedPriceListItem = {}

        updatedPriceListItem.pricelistid = data.pricelistid
        updatedPriceListItem.pricelistitemid = data.pricelistitemid
        updatedPriceListItem.name = data.name
        updatedPriceListItem.description = data.description
        updatedPriceListItem.quantity = data.quantity
        updatedPriceListItem.rate = data.rate
        
        

        return new Promise(async (resolve, reject) => {
            try {

                const resp = await priceListItemsCrud.updatePriceListItems(updatedPriceListItem);
                resolve(false);
                this.loadList();
            } catch (error) {
                console.log(error);
                reject(error)
            }
        });
    }





    //deleting row
    onRowRemoving = (e) => {
        const { companyid, projectid, contractid } = this.props.contract;
        const deleteObj = { ...e.data, companyid, projectid, contractid };
        deleteObj.deleted = 1;
        e.cancel = this.deletePriceList(deleteObj);
    }

    deletePriceList = (data) => {
        
        const pricelistitemid = data.pricelistitemid
        
        return new Promise(async (resolve, reject) => {
            try {

                const resp = await priceListItemsCrud.deletePriceItemsList(pricelistitemid)
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        });
    }
   
    

   

    onInitNewRow = async (e) => {
        e.data = { deleted: 0 };
    }

    
    render() {
        if (!this.state.pricelistsitems) {
            return (<PortletSpinner />);
        }
        const currencySymbol = this.currencyCodes.find(currency => currency.code === this.state.currency)?.symbol || '';

        const { formData } = this.state;
        return (
            <Portlet>

                <PortletBody>

                    <DataGrid
                        dataSource={this.state.pricelistsitems}
                        onExporting={this.onExporting}
                        keyExpr="pricelistitemid"
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        remoteOperations={false}
                        allowColumnReordering={true}
                        columnResizingMode='widget'
                        onRowUpdating={this.onRowUpdating}
                        onInitNewRow={this.onInitNewRow}
                        onRowInserting={this.onRowInserting}
                        noDataText={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.NO_DATA' })}
                        onRowRemoving={ this.onRowRemoving }
                        selection={{ mode: 'single' }}
                        style={{ maxHeight: '67vh', minHeight: "50vh" }}
                        

                    >
                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            allowDeleting={true}
                            allowAdding={true}
                            confirmDelete={true}
                            popup={{ title: this.props.intl.formatMessage({ id: "PRICELIST.ADDPRICELISTITEM" }), height: 'auto', width: 600, showTitle: true }}
                            useIcons={true}
                          
                        >
                             <Form>
                                <FormItem colSpan={2} dataField="name"  label={{ text: this.props.intl.formatMessage({ id: "PRICELIST.FORMS.FIELDS.NAME" }) }} isRequired/>

                                <FormItem colSpan={2} dataField="description"  label={{ text: this.props.intl.formatMessage({ id: "PRICELIST.FORMS.FIELDS.DESCRIPTION" }) }} isRequired/>
                                
                                <FormItem colSpan={2} dataField="quantity" editorType='dxNumberBox'  label={{ text: this.props.intl.formatMessage({ id: "PRICELIST.FORMS.FIELDS.QUANTITY" }) }} isRequired >

                                <ValidationRule 
                                    type="range" 
                                    min={0} 
                                    max={999999999} 
                                    message="Please enter a value with up to 9 digits."
                                />
                                <ValidationRule 
                                    type="range" 
                                    min={1} 
                                    message="Minimum value must be 1."
                                />
                                </FormItem>
                                
                                <FormItem colSpan={2} dataField="rate"  editorType='dxNumberBox' editorOptions={{
                                    showSpinButtons: true,
                                    step: 0.01, 
                                    format: {
                                        type: 'fixedPoint',
                                        precision: 2,
                                        formatter: (value) => {
                                            if (value !== null && value !== undefined) {
                                                const formattedValue = parseFloat(value).toFixed(2);
                                                return currencySymbol + ' ' + formattedValue;
                                            } else {
                                                return ''; 
                                            }
                                        }
                                    },}} 
                                    
                                    label={{ text: this.props.intl.formatMessage({ id: "PRICELIST.FORMS.FIELDS.RATE" }) }}  isRequired >
                                <ValidationRule 
                                    type="range" 
                                    min={1} 
                                    message="Minimum value must be 1."
                                />
                                <ValidationRule 
                                    type="range" 
                                    min={0} 
                                    max={999999999} 
                                    message="Please enter a value with up to 9 digits."
                                />
                                </FormItem>
                                {/* <FormItem colSpan={2} dataField="price" editorType='dxNumberBox' editorOptions={{readOnly:true,  format: '#,##0.00', placeholder: '0.00',  dataSource: currencyCodes, valueExpr: 'code', displayExpr: 'name', defaultValue: 'GBP'}}  label={{ text: this.props.intl.formatMessage({ id: "PRICELIST.FORMS.FIELDS.PRICE" }) }}/> */}
                            </Form>
                        </Editing>
                        <ColumnChooser mode="select" enabled={true} search={{ enabled: true }}  >
                        {/* <ColumnChooserSearch enabled={true} timeout={800} /> */}
                        </ColumnChooser>
                        <ColumnFixing enabled={true} />
                        <StateStoring enabled={true} type="localStorage"  />
                        <Column caption={this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.NAME' })} dataField='name'  />
                        <Column caption={this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.DESCRIPTION' })} dataField='description' />
                        <Column caption={this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.QUANTITY' })} dataField="quantity"/>
                       
                        <Column caption={this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.RATE' })} dataType="currency"
                                format={(num) => Common.FormatCurrencyNum(num, this.state.currency)} dataField='rate'  alignment="right"/>
                        
                        <Toolbar></Toolbar>
                        <Column caption={this.props.intl.formatMessage({ id: 'PRICELIST.FORMS.FIELDS.PRICE' })} dataType="currency"
                                format={(num) => Common.FormatCurrencyNum(num, this.state.currency)} dataField='price'  alignment="right"/>
                        
                        <Toolbar>
                            <ToolbarItem location="before">
                                <div>
                                    <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">
                                        {this.state.priceListName + ' '}
                                        <FormattedMessage id="PRICELIST.TITLE.PRICELISTITEMS" ></FormattedMessage>
                                         
                                    </h3>
                                </div>
                            </ToolbarItem>
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                widget="dxButton"
                                // disabled={props.saveDisabled}
                                options={{
                                    icon: "back",
                                    onClick: () => this.props.navigate(`/contract/${this.props.contract.contractid}/settings/price-list`),
                                    text: this.props.intl.formatMessage({ id: "PRICELIST.BACKTOREGISTER" }),
                                }}
                            />
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="addRowButton"
                                showText="always"
                                options={{
                                    icon: "plus",
                                    text: this.props.intl.formatMessage({ id: "PRICELIST.ADDPRICELISTITEM" }),
                                }}
                            />
                            

                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="exportButton"
                            />
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="columnChooserButton"
                            />
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="searchPanel"
                            />
                        </Toolbar>



                        <ColumnChooser enabled={true} />
                        <ColumnFixing enabled={true} />
                        <Export enabled={true} fileName={this.props.name} />
                        <SearchPanel visible={true} highlightCaseSensitive={false} defaultText="" />

                        <Paging pageSize={10} defaultPageSize={10} defaultPageIndex={0} />

                    </DataGrid>


                </PortletBody>

            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    userProfile: store.auth.userProfile
});

export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps)(PriceListItems))));