/* eslint-disable eqeqeq */
import * as commonCrud from "../../crud/common.crud";
import store from "../store";
import { select, put, takeLatest } from "redux-saga/effects";
import * as authDuck from "./auth.duck";
import { custom } from 'devextreme/ui/dialog';
import { getVersion } from "../../crud/auth.crud";
import { allMessages } from "../../../_metronic/i18n/I18nProvider";
import MenuItemsHelper from "../helpers/MenuItemsHelper";
// import Parser from "rss-parser";
import Scheduler from "../helpers/Scheduler";

const getAuth = (store) => store.auth;
const getApplication = (store) => store.application;

export const actionTypes = {
  APPLICATION_SET_MENU_ITEMS: "[APPLICATION_SET_MENU_ITEMS]",
  APPLICATION_LOADED: "[APPLICATION_LOADED]",
  // APPLICATION_SET_OPTIONS: "[APPLICATION_SET_OPTIONS]",
  // APPLICATION_SET_HUBS: "[APPLICATION_SET_HUBS]",
  // APPLICATION_SET_AREAS: "[APPLICATION_SET_AREAS]",
  APPLICATION_CLEAR_SYSTEM_MESSAGE:"[APPLICATION_CLEAR_SYSTEM_MESSAGE]",
 

  APPLICATION_SET_FEED: "[APPLICATION_SET_FEED]",
  APPLICATION_LOGOUT: "[APPLICATION_LOGOUT]",
  APPLICATION_SET_SMARTLINKS: "[APPLICATION_SET_SMARTLINKS]",
  // APPLICATION_SET_TAGS: "[APPLICATION_SET_TAGS]",
  // APPLICATION_REFRESH_TAGS: "[APPLICATION_REFRESH_TAGS]",
  APPLICATION_STARTUP_ACTIONS: "[APPLICATION_STARTUP_ACTIONS]",
  APPLICATION_SET_SYSTEM_MESSAGE:"[APPLICATION_SET_SYSTEM_MESSAGE}",
};

const initialApplicationState = {
  // adminMenuItems: null,
  homepageMenuItems: null,
  // options: null,
  // hubs: null,
  // areas: null,
  systemMessage:{showModal:false, title:"", message:"", htmlRenderString:""},
  // zendeskChat:{showModal:false, title:"", message:""},
  rssFeed: null,
  smartLinks: null,
  // contractTags: null,
  startupActionsTriggered: false,
  UI_VERSION: Number(process.env.REACT_APP_VERSION.split('.').join(""))
};
// const storageKey = "FD-App" + process.env.REACT_APP_PRODUCT_KEY + process.env.REACT_APP_VERSION + Common.GetHashCode(process.env.REACT_APP_API_URL);

export const reducer = (state = initialApplicationState, action) => {
  switch (action.type) {
    case actionTypes.APPLICATION_SET_MENU_ITEMS: {
      return { ...state, homepageMenuItems: action.payload.homepageMenuItems, smartLinks: action.payload.smartLinkItems };
    }
    case actionTypes.APPLICATION_STARTUP_ACTIONS: {
      return { ...state, startupActionsTriggered: true };
    }
    // case actionTypes.APPLICATION_SET_OPTIONS: {
    //     return { ...state, options: action.payload.options };
    // }
    // case actionTypes.APPLICATION_SET_HUBS: {
    //     return { ...state, hubs: action.payload.hubs };
    // }
    // case actionTypes.APPLICATION_SET_AREAS: {
    //     return { ...state, areas: action.payload.areas };
    // }
    case actionTypes.APPLICATION_SET_FEED: {
      return { ...state, rssFeed: action.payload.feed };
    }
    case actionTypes.APPLICATION_LOGOUT: {
      return initialApplicationState;
    }
    case actionTypes.APPLICATION_SET_SMARTLINKS: {
      return { ...state, smartLinks: action.payload.links };
    }
    // case actionTypes.APPLICATION_SET_TAGS: {
    //   return { ...state, contractTags: action.payload.contractTags };
    // }
    case actionTypes.APPLICATION_CLEAR_SYSTEM_MESSAGE:{
      return {...state, systemMessage:{showModal:false, title:"", message:"", htmlRenderString:""}}
    }


    case actionTypes.APPLICATION_SET_SYSTEM_MESSAGE:{
      return {...state, systemMessage:{showModal:true, title:action.payload.title, message:action.payload.message, htmlRenderString:action.payload.htmlRenderString}}
    }
    
    default:
      return state;
  }
};
export const actions = {
  setMenuItems: (homepageMenuItems, smartLinkItems) => ({
    type: actionTypes.APPLICATION_SET_MENU_ITEMS,
    payload: { homepageMenuItems, smartLinkItems },
  }),
  startupActions: () => {
    const applicationStore = store.getState().application;
    if (applicationStore.startupActionsTriggered) {
      return { type: '' };
    }
    else {
      return { type: actionTypes.APPLICATION_STARTUP_ACTIONS }
    }
  },
  // setOptions: (options) => ({ type: actionTypes.APPLICATION_SET_OPTIONS, payload: { options } }),
  // setHubs: (hubs) => ({ type: actionTypes.APPLICATION_SET_HUBS, payload: { hubs } }),
  // setAreas: (areas) => ({ type: actionTypes.APPLICATION_SET_AREAS, payload: { areas } }),
  setRssFeed: (feed) => ({
    type: actionTypes.APPLICATION_SET_FEED,
    payload: { feed },
  }),
  logout: () => ({ type: actionTypes.APPLICATION_LOGOUT }),
  setSmartLinks: (links) => ({
    type: actionTypes.APPLICATION_SET_SMARTLINKS,
    payload: { links },
  }),
  // setTags: (contractTags) => ({ type: actionTypes.APPLICATION_SET_TAGS, payload: { contractTags }, }),
  // refreshTags: () => ({ type: actionTypes.APPLICATION_REFRESH_TAGS }),
  clearSystemMessage: () => ({ type: actionTypes.APPLICATION_CLEAR_SYSTEM_MESSAGE }),

};

export function* saga() {
  yield takeLatest([actionTypes.APPLICATION_LOADED, authDuck.actionTypes.AUTH_LOAD_COMPLETED], function* applicationStartupTriggerSaga() {
    try {
      const applicationStore = yield select(getApplication);
      const authStore = yield select(getAuth);
      if (!authStore || !authStore.userProfile || !authStore.authToken || !authStore.csrfToken || !authStore.authToken.token || !authStore.csrfToken.token) {
        return;
      }
      if (!applicationStore.startupActionsTriggered) {
        yield put(actions.startupActions());
      }

    } catch {
      console.log(
        "error setting admin menu items in applicationStartupTriggerSaga"
      );
    }
  }
  );
  yield takeLatest([authDuck.actionTypes.AUTH_LOAD_COMPLETED], function* applicationVersionCheck() {
    try {
      const authStore = yield select(getAuth);
      const langStore = store.getState().i18n;

      if (!authStore || !authStore.userProfile || !authStore.authToken || !authStore.csrfToken || !authStore.authToken.token || !authStore.csrfToken.token) {
        return;
      }

      const response = yield getVersion()
      let UIVersion = process.env.REACT_APP_VERSION.split(".");
      let apiVersion = response.data.apiVersion.split(".");

      if(UIVersion[1] != apiVersion[3]) {
        let activateDialog = custom({
          title: allMessages[langStore.lang]["SYSTEM.VERSION_POPUP.TITLE"].replace("{UI_Version}", UIVersion[1]).replace("{API_Version}", apiVersion[3]),
          messageHtml: `<div style="max-width:50vw;min-width:300px;text-align:center;">${allMessages[langStore.lang]["SYSTEM.VERSION_POPUP.CONTENT"]} <a href="https://support.builtintelligence.com/hc/en-gb/requests/new" target="_blank">${allMessages[langStore.lang]["SYSTEM.VERSION_POPUP.SUPPORT"]}</a></div>`,
          buttons: [
            {
              text: allMessages[langStore.lang]["SYSTEM.VERSION_POPUP.BUTTON_ONE"],
              type: "danger",
              stylingMode: "outlined",
              onClick: (e) => {
                // This will open ZenDesk for quick access to support.
                //window.zE('webWidget', 'open')
               }
            },
            {
              text: allMessages[langStore.lang]["SYSTEM.VERSION_POPUP.BUTTON_TWO"],
              type: "success",
              stylingMode: "fill",
              onClick: (e) => { 
                window.location.reload(true);
               }
            },
          ]
        });
        yield activateDialog.show();
      }

    } catch {
      console.log(
        "error checking application versions in applicationVersionCheckSaga"
      );
    }
  }
  );
  yield takeLatest(actionTypes.APPLICATION_STARTUP_ACTIONS, function* applicationLoadMenuSaga() {
    try {
      //const applicationStore = yield select(getApplication);
      const authStore = yield select(getAuth);
      if (!authStore || !authStore.userProfile || !authStore.authToken || !authStore.csrfToken || !authStore.authToken.token || !authStore.csrfToken.token) {
        return;
      }

      const homepageMenuItems = MenuItemsHelper.getMainMenuItems(authStore.userProfile);
      const { data: links } = yield commonCrud.getSmartLinks();
      const smartLinkItems = MenuItemsHelper.getSmartLinkItems(links);

      yield put(actions.setMenuItems(homepageMenuItems, smartLinkItems));

    } catch (err) {
      console.log("error in applicationLoadMenuSaga", err);
    }
  }
  );

  // yield takeLatest([actionTypes.APPLICATION_STARTUP_ACTIONS, actionTypes.APPLICATION_REFRESH_TAGS], function* applicationLoadTagsMenuSaga() {
  //   try {
  //     const authStore = yield select(getAuth);
  //     if (!authStore || !authStore.userProfile || !authStore.authToken || !authStore.csrfToken || !authStore.authToken.token || !authStore.csrfToken.token) {
  //       return;
  //     }

  //     const { data: tags } = yield tagCrud.getTagsByCategory(contractTagCategories);
  //     const contractTags = Common.GroupBy(tags, 'category');
  //     yield put(actions.setTags(contractTags));

  //   } catch {
  //     console.log(
  //       "error setting admin menu items in applicationAdminMenuSaga"
  //     );
  //   }
  // }
  // );

  // yield takeLatest(actionTypes.APPLICATION_LOADED, function* applicationLoadOptionsSaga() {
  //     try {
  //         const authStore = yield select(getAuth);
  //         if (!authStore || !authStore.userProfile) {
  //             return;
  //         }

  //         const result = yield commonCrud.getOptions();
  //         yield put(actions.setOptions(JSON.parse(result.data)));
  //     }
  //     catch{
  //         console.log("error in applicationMenuSaga")
  //     }

  // });
  // yield takeLatest(actionTypes.APPLICATION_LOADED, function* applicationLoadHubsSaga() {
  //     try {
  //         if (!(process.env.REACT_APP_CONTRACT_COMPANY_TAGS_ENABLED === 'true')) {
  //             return;
  //         }
  //         const authStore = yield select(getAuth);
  //         if (!authStore || !authStore.userProfile) {
  //             return;
  //         }

  //         const response = yield commonCrud.getCompanyHubs();
  //         const hubs = JSON.parse(response.data);
  //         yield put(actions.setHubs(hubs));
  //     }
  //     catch{
  //         console.log("error in applicationMenuSaga")
  //     }

  // });
  // yield takeLatest(actionTypes.APPLICATION_LOADED, function* applicationLoadAreasSaga() {
  //     try {
  //         if (!(process.env.REACT_APP_AREAS_ENABLED === 'true')) {
  //             return;
  //         }

  //         const authStore = yield select(getAuth);
  //         if (!authStore || !authStore.userProfile) {
  //             return;
  //         }

  //         const response = yield commonCrud.getCompanyAreas();
  //         const areas = JSON.parse(response.data);
  //         yield put(actions.setAreas(areas));
  //     }
  //     catch{
  //         console.log("error in applicationMenuSaga")
  //     }

  // });
  // yield takeLatest(
  //   actionTypes.APPLICATION_LOADED,
  //   function* applicationLoadRssFeedDiscourse() {
  //     try {
  //       if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
  //         let parser = new Parser();
  //         // const CORS_PROXY = "";//"https://cors-anywhere.herokuapp.com/";
  //         const rssResultDiscourse = yield parser.parseURL(
  //           // CORS_PROXY + "https://www.builtintelligence.com/blogs/home.atom"
  //           "https://reachback.builtintelligence.com/latest.rss"
  //         );
  //         yield put(actions.setRssFeed(rssResultDiscourse));
  //       }
  //     } catch (err) {
  //       console.log("error in applicationLoadRssFeedDiscourse", err);
  //     }
  //   }
  // );

  // yield takeLatest(
  //   actionTypes.APPLICATION_LOADED,
  //   function* applicationLoadRssFeedSaga() {
  //     try {
  //       if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
  //         let parser = new Parser();
  //          const CORS_PROXY = "";//"https://cors-anywhere.herokuapp.com/";
  //         const rssResult = yield parser.parseURL(
  //           CORS_PROXY + "https://www.builtintelligence.com/blogs/home.atom"
  //         );
  //         yield put(actions.setRssFeed(rssResult));
  //       }
  //     } catch (err) {
  //       console.log("error in applicationLoadRssFeedSaga", err);
  //     }
  //   }
  // );

  yield takeLatest(
    actionTypes.APPLICATION_LOADED,
    function* applicationLoadContractsSaga() {
      try {
        // yield put(contractsDuck.actions.fetchStart());
        yield put(authDuck.actions.refreshProfile());
        Scheduler.StartClock();
      } catch (err) {
        console.log("error in applicationLoadContractsSaga", err);
      }
    }
  );
}
