import { userFilterCategories } from "../../../shared/lookup";
const workflows = [
    {
        id: 18,
        title: '$SUPPLIER_NAME Submissions',
        notificationName: 'Submission',
        abbreviation: '$SUPPLIER_ABBREVIATION-Submission-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'PM',
        //REPLY
        replyEnabled: true,
        replyPeriodOption: "PM_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier Submissions',
            currentModel: 'InspectionDefect',
            currentModelType: '23.1',
            referenceType: 'Submission',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,
            configurableReplyTab: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["PM_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supplier',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 49,
        title: '$PM_NAME Submissions',
        notificationName: 'Submission',
        abbreviation: '$PM_ABBREVIATION-Submission-',
        notifyingParty: 'PM',
        replyingParty: 'SUPPLIER',

        replyEnabled: true,
        replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Submissions',
            currentModel: 'InspectionDefect',
            currentModelType: 'SubmissionPM',
            referenceType: 'Submission',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableAssets: true,
            configurableReplyTab: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["SUPPLIER_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
]

export default workflows;