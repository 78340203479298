import { userFilterCategories } from "../../../shared/lookup";
const workflows = [
    {
        id: 43,
        title: 'Non-Conformance',
        path: 'shared',
        notificationName: 'Non-Conformance',
        abbreviation: 'Non-Conformance-',
        notifyingParty: 'SUPPLIER',
        actioningParty: 'PM',
        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Non-Conformance',
            currentModel: 'InspectionDefect',
            currentModelType: 'NC',
            referenceType: 'NonConformance',


            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableAssets: true,



            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.ACTION_TAB,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'shared',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },



]

export default workflows;