
export class TemplateGlobalResource {
    constructor(id, type, key, value, config = { options: null, skipDefaultLoadingIfMissing: false }) {
        const { options, skipDefaultLoadingIfMissing } = config || {};
        this.id = id;
        this.type = type;
        this.key = key;
        this.value = value;
        this.options = options ? [...options] : null; //DEEP COPY TO ENSURE NEW REFERENCE
        this.skipDefaultLoadingIfMissing = skipDefaultLoadingIfMissing;
    }
    // All templates should be saved this stripped props
    ToTemplateJSON = () => {
        const { id, type, key, value } = this;
        return { id, type, key, value };
    }

    //In case we need the defaults as a file, get all props
    //DefaultResources.json in API
    ToDefaultsJSON = () => {
        const { id, type, key, value, options, skipDefaultLoadingIfMissing } = this;
        return { id, type, key, value, options, skipDefaultLoadingIfMissing };
    }
}
const defaultResources = [
    new TemplateGlobalResource(1, 'TEXT', 'SUPPLIER_NAME', 'Contractor'),
    new TemplateGlobalResource(2, 'TEXT', 'SUPPLIER_ABBREVIATION', 'CON'),
    new TemplateGlobalResource(3, 'TEXT', 'PM_NAME', 'Project Manager'),
    new TemplateGlobalResource(4, 'TEXT', 'PM_ABBREVIATION', 'PM'),
    new TemplateGlobalResource(5, 'TEXT', 'SUPERVISOR_NAME', 'Supervisor'),
    new TemplateGlobalResource(6, 'TEXT', 'SUPERVISOR_ABBREVIATION', 'SUP'),
    new TemplateGlobalResource(7, 'TEXT', 'CLIENT_NAME', 'Employer'),
    new TemplateGlobalResource(8, 'TEXT', 'CLIENT_ABBREVIATION', 'EMP'),
    new TemplateGlobalResource(9, 'TEXT', 'COMPLETION_DATE', 'Completion Date'),
    new TemplateGlobalResource(10, 'TEXT', 'QUOTE_DEEMED_ACCEPTANCE_STARTS', 'OFF', { options: ['OFF', 'IMMEDIATELY', 'RELATED_NOTICE'] }),
    new TemplateGlobalResource(11, 'NUMBER', 'SUPPLIER_REPLY_PERIOD', 7),
    new TemplateGlobalResource(12, 'NUMBER', 'PM_REPLY_PERIOD', 7),
    new TemplateGlobalResource(13, 'NUMBER', 'QUOTE_DURATION', 21),
    new TemplateGlobalResource(14, 'NUMBER', 'REQUOTE_DURATION', 21),
    new TemplateGlobalResource(15, 'NUMBER', 'QUOTE_RESPONSE', 7),
    new TemplateGlobalResource(16, 'NUMBER', 'REQUOTE_RESPONSE', 21),
    new TemplateGlobalResource(17, 'NUMBER', 'VARIATION_RESPONSE', 14),
    new TemplateGlobalResource(18, 'NUMBER', 'PAYMENT_PERIOD', 21),
    new TemplateGlobalResource(19, 'NUMBER', 'PROGRAMME_REPLY_PERIOD', 14),
    new TemplateGlobalResource(20, 'NUMBER', 'QUOTE_DURATION_PRIOR_REPLY', 14),
    new TemplateGlobalResource(21, 'NUMBER', 'RFQ_QUOTE_DURATION', 21),
    new TemplateGlobalResource(22, 'NUMBER', 'RFQ_REQUOTE_DURATION', 21),
    new TemplateGlobalResource(23, 'NUMBER', 'RFQ_QUOTE_RESPONSE', 7),
    new TemplateGlobalResource(24, 'NUMBER', 'RFQ_REQUOTE_RESPONSE', 21),
    new TemplateGlobalResource(25, 'NUMBER', 'QUOTE_DEEMED_ACCEPTANCE', 14),
    new TemplateGlobalResource(26, 'NUMBER', 'CLIENT_REPLY_PERIOD', 7),
    new TemplateGlobalResource(27, 'NUMBER', 'SUPERVISOR_REPLY_PERIOD', 7),
    new TemplateGlobalResource(28, 'NUMBER', 'PROP_QUOTE_DURATION', 21, { skipDefaultLoadingIfMissing: true }),
    new TemplateGlobalResource(29, 'NUMBER', 'PROP_REQUOTE_DURATION', 21, { skipDefaultLoadingIfMissing: true }),
    new TemplateGlobalResource(30, 'NUMBER', 'CWN_QUOTE_DURATION', 42),
    new TemplateGlobalResource(31, 'NUMBER', 'CWN_QUOTE_RESPONSE', 42),
    new TemplateGlobalResource(32, 'NUMBER', 'INTERIM_CWN_QUOTE_DURATION', 28),
    new TemplateGlobalResource(33, 'NUMBER', 'INTERIM_CWN_QUOTE_RESPONSE', 42),
    new TemplateGlobalResource(34, 'NUMBER', 'ITQ_QUOTE_DURATION', 21),
    new TemplateGlobalResource(35, 'NUMBER', 'ITQ_REQUOTE_DURATION', 21),
    new TemplateGlobalResource(36, 'NUMBER', 'ITQ_QUOTE_RESPONSE', 7),
    new TemplateGlobalResource(37, 'NUMBER', 'ITQ_REQUOTE_RESPONSE', 21),
    new TemplateGlobalResource(38, 'NUMBER', 'CWN_ASSESS_DURATION', 42),
    new TemplateGlobalResource(39, 'TEXT', 'CEN_DEEMED_ACCEPTANCE_STARTS', 'OFF', { options: ['OFF', 'IMMEDIATELY', 'RELATED_NOTICE'] }),
    new TemplateGlobalResource(40, 'NUMBER', 'CEN_DEEMED_ACCEPTANCE', 14),
    new TemplateGlobalResource(41, 'TEXT', 'PROG_DEEMED_ACCEPTANCE_STARTS', 'OFF', { options: ['OFF', 'IMMEDIATELY', 'RELATED_NOTICE'] }),
    new TemplateGlobalResource(42, 'NUMBER', 'PROG_DEEMED_ACCEPTANCE', 14),
    new TemplateGlobalResource(43, 'TEXT', 'DEFECTS_DATE', 'Defects Date')
];
export default defaultResources;