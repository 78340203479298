import React from 'react';
import SecondAsideMenuItem from './SecondAsideMenuItem';
import { injectIntl } from "react-intl";

class SecondAsideMenu extends React.Component {
  render() {
    const { config, children } = this.props;
    return (
      <div className="kt-portlet kt-portlet--height-fluid-" style={{ width: "100%", minWidth:50 }}>
        <div className="kt-portlet__body kt-portlet__body--fit-y" style={{ padding: '5px' }}>
          <div className="kt-widget kt-widget--user-profile-1">
            <div className="kt-widget__body" >
              {
                children ? (
                  <div className="kt-widget__content">
                    {children}
                  </div>
                ) : (<div>&nbsp;</div>)
              }
              
              <div className="kt-widget__items">
                {
                  config.MenuItems.map((menuItem,index) =>
                  (
                    <SecondAsideMenuItem
                      key={index}
                      to={menuItem.toExact ? menuItem.toExact : config.RootPath + menuItem.to}
                      text={menuItem.resource ? this.props.intl.formatMessage({ id: menuItem.resource }) : menuItem.text}
                      icon={menuItem.icon} />
                  )
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(SecondAsideMenu);
