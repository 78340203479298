
import axios from '../shared/axios';
import CrudHelper from "./helper";

export function getProjects() {
  return axios.post('/api/project/loadprojectslist');
}

export function getProject(projectid) {
  return axios.get('/api/project/getproject?projectid=' + projectid);
}

export function getProjectPicture(projectid) {
  return axios.get('/api/picture/getpicture?type=Project&reference=' + projectid);
}

export function setProjectPicture(projectid, file) {
  const data = new FormData();
  data.append("UploadedImage", file);

  const headers = {
    'Content-Type': 'multipart/form-data'
  }

  return axios.post('/api/picture/uploadpicture?type=Project&reference=' + projectid, data, {
    headers: headers
  });
}

export function saveProject(data) {
  data = CrudHelper.appendCustomFields(data);
  return axios.post('/api/Project/SaveProject', data);
}

export function getCutDownProjectsList(companyid) {
  return axios.get('/api/project/toselectlist?companyid=' + companyid);
}

export function changeCompanyIdProjectId(contractid, currentcompanyid, newcompanyid, currentprojectid, newprojectid) {
  return axios.post(`/api/support/changecompanyidprojectid?contractid=${contractid}&currentcompanyid=${currentcompanyid}&newcompanyid=${newcompanyid}&currentprojectid=${currentprojectid}&newprojectid=${newprojectid}`);
}

export function deleteProject(projectid) {
  return axios.post(`/api/Project/Delete?projectid=${projectid}`);
}

export function restoreDeletedProject(projectid) {
  return axios.post(`/api/Project/RestoreDeleted?projectid=${projectid}`);
}

export function archiveProject(projectid) {
  return axios.post(`/api/Project/Archive?projectid=${projectid}`);
}

export function restoreArchivedProject(projectid) {
  return axios.post(`/api/Project/RestoreArchived?projectid=${projectid}`);
}

export function completeProject(projectid) {
  return axios.post(`/api/Project/CompleteProject?projectid=${projectid}`);
}

export function restoreCompletedProject(projectid) {
  return axios.post(`/api/Project/RestoreCompletedProject?projectid=${projectid}`);
}

export function getRelatedActiveContractCount(projectid) {
  return axios.post(`/api/Project/GetRelatedActiveContractCount?projectid=${projectid}`);
}

export function getRelatedArchivedContractCount(projectid) {
  return axios.post(`/api/Project/GetRelatedArchivedContractCount?projectid=${projectid}`);
}