import axios from "../shared/axios";
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function getRfqQuote(rfqquotationid) {
  return axios.get(`/api/RequestForQuoteQuotation/GetRfqQuotation?rfqquotationid=${rfqquotationid}`);
}
export function saveRfqQuote(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/RequestForQuoteQuotation/SaveRfqQuotation`, data)
      .then((result) => {
        // if (data.showtasknotification === 1 ) {
        store.dispatch({
          type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD,
        });
        // }
        resolve(result);
      })
      .catch((error) => reject(error));
  });
}
export function getRfqQuotes(
  companyid,
  projectid,
  contractid,
  requestforquoteid
) {
  return axios.post(
    `/api/RequestForQuoteQuotation/LoadRfqQuotationsList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&requestforquoteid=${requestforquoteid}`
  );
}

export function deleteQuoteDraft(rfqquotationid) { /////not working
  return new Promise((resolve, reject) => {
    axios.post(`api/RequestForQuoteQuotation/DeleteDraft?rfqquotationid=${rfqquotationid}`)
      .then(result => {
        store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
        resolve(result);
    })
    .catch(error => reject(error));
    })
}