import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import * as forecastCrud from '../../../../crud/forecast.crud';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import DxDialogModal from '../../../../partials/content/dxControls/dxDialogModal';
import Common from '../../../../shared/common';
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import { actionCodes } from "../../../../shared/lookup";

class ForecastCreatePage extends BaseCreatePage {
    constructor(props) {
        super(props);
        this.createDefaultSubject = (((props.location || {}).state || {}).itemCustomProps || {}).subject;
        this.createDefaultAsset = (((props.location || {}).state || {}).itemCustomProps || {}).assetid;
        this.state = {
            showModal: false,
            modalTitle: 'Cost Forecast Value',
            modalText: `The value entered is less than ${Common.FormatCurrencyNum(1, this.props.contract.currency)}. Do you want to continue?`,
            modalMode: 'FORECAST',
            minValueAccepted: false,
            minValueDecided: false,
            showAlert: true,
            target: 0,
            carbonForecast: 0,
            variance: 0
        };
    }



    submitHandler = (forecast, setSubmitting) => {
        if (!this.state.minValueDecided && forecast.newcostforecast < 1) {
            this.setState({ showModal: true });
        } else {
            forecast.companyid = this.props.contract.companyid;
            forecast.contractid = this.props.contract.contractid;
            forecast.projectid = this.props.contract.projectid;
            forecast.status = 'Not Communicated';
            // forecast.notificationdatedisplay = moment().format("DD/MM/YYYY");
            forecast.type = this.props.notificationType;
            forecast.forecastdatedisplay = moment(forecast.forecastdate).format('DD/MM/YYYY');
            forecast.actioncode = actionCodes.CREATE;
            forecast.notifyingparty = this.props.workflowItem.notifyingParty;
            this.appendRelationship(forecast);
            Common.PropertyConversionsBeforeNoticeSaveRequest(forecast, this.props.customFields, this.props.contractUsers);
            forecastCrud.saveForecast(forecast)
                .then(response => {
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
                    try {
                        const createdID = Number(response.data);
                        this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
                    }
                    catch {
                        this.props.navigate(this.props.workflowItem.paths.listPath);
                    }
                })
                .catch(err => {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
                });
        }
    }

    onModalResponse = (response, params) => {
        this.setState({ showModal: false, minValueDecided: true }, () => {
            if (response === 'OK') {
                this.setState({ minValueAccepted: true });
            }
        });
    }

    calculateVariance = (obj) => {
        const prc1 = obj.target ? obj.target : 0;
        const prc2 = obj.newcarbonforecast ? obj.newcarbonforecast : 0;
        return prc2 - prc1;
    }

    render() {

        const minAmount = !this.state.minValueAccepted && this.state.minValueDecided ? 1 : 0;

        const createFormConfig = {
            currencyCode: this.props.contract.currency,
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'subject', editorOptions: { defaultValue: this.createDefaultSubject } },
                { resource: 'GENERAL.FORMS.FIELD.FORECASTDATE', dataField: 'forecastdate', editorType: 'dxDateBox', required: true },
                // { resource: "GENERAL.FORMS.FIELD.TYPE", dataField: 'replycomment', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.clauses valueExpr: 'display', displayExpr: 'display' } },
                { resource: 'GENERAL.FORMS.FIELD.DETAILS', dataField: 'description', editorType: 'dxHtmlEditor' }
            ]
        };

        if (this.props.typeFieldEnabled) {
            createFormConfig.fields.splice(2, 0, ({ resource: "GENERAL.FORMS.FIELD.TYPE", dataField: 'replycomment', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.clauses,  valueExpr: 'display', displayExpr: 'display' } }));
        }

        if (this.props.notificationType === 'CostForecast') {
            createFormConfig.fields.push({ resource: "GENERAL.FORMS.FIELD.COSTFORECAST", dataField: 'newcostforecast', editorType: 'dxNumberBox', validation: { type: 'range', min: minAmount, warning: this.props.intl.formatMessage({ id: "GENERAL.FORMS.MINIMUM_VALUE" }) + " " + Common.FormatCurrencyNum(minAmount, this.props.contract.currency)} });
        }

        if (this.props.configurableCarbonFields) {
            if (this.props.configurableCarbonFields.Target) {
                createFormConfig.fields.push({ label: this.props.configurableCarbonFields.Target, dataField: 'target', editorType: 'dxNumberBox', editorOptions: { defaultValue: this.state.target, format: '#,##0.00 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }), placeholder: '0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }) } })
            }
            if (this.props.configurableCarbonFields.Forecast) {
                createFormConfig.fields.push({ label: this.props.configurableCarbonFields.Forecast, dataField: 'newcarbonforecast', editorType: 'dxNumberBox', editorOptions: { defaultValue: this.state.carbonForecast, min: 0, format: '#,##0.00 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }), placeholder: '0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }) } })
            }
            if (this.props.configurableCarbonFields.Actual) {
                createFormConfig.fields.push({ label: this.props.configurableCarbonFields.Actual, dataField: 'actual', editorType: 'dxNumberBox', editorOptions: { format: '#,##0.00 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }), placeholder: '0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }) } })
            }
            if (this.props.configurableCarbonFields.Variance) {
                createFormConfig.fields.push({ label: this.props.configurableCarbonFields.Variance, dataField: 'variance', editorType: 'dxNumberBox', value: this.calculateVariance, editorOptions: { defaultValue: this.state.variance, format: '#,##0.00 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }), placeholder: '0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES_CO2' }) } })
            }
        }

        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    {this.props.notificationType === 'CostForecast' ?
                        this.props.resources.CostForecastWarningBanner && (
                            <Alert variant="warning" >
                                <span>
                                    {
                                        this.props.resources.CostForecastWarningBanner.split("\n").map((line, index) => {
                                            return <div key={index}>{line}</div>
                                        }
                                        )
                                    }
                                </span>
                            </Alert>
                        ) : this.props.resources.CarbonForecastWarningBanner && (
                            <Alert variant="warning" >
                                <span>
                                    {
                                        this.props.resources.CarbonForecastWarningBanner.split("\n").map((line, index) => {
                                            return <div key={index}>{line}</div>
                                        }
                                        )
                                    }
                                </span>
                            </Alert>
                        )}
                    <BiDynamicForm
                        onSubmitHandler={this.submitHandler}
                        minValueCheck={!this.state.minValueDecided}
                        config={createFormConfig}
                        customFields={(this.props.customFields || {}).COMMUNICATION}
                        assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                        assetid={this.createDefaultAsset}
                    />
                </PortletBody>
                <DxDialogModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                />
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(ForecastCreatePage));