
import axios from "../shared/axios";

export function getExportStatus(data) {

  return axios.post(`api/PowerBIExport/ExportStatus`, data)
}

export function createExport(data) {
  
  return axios.post(`api/PowerBIExport/ExportReport`, data)
}

export function downloadPowerBIExport(data) {
return axios({
  url: `api/PowerBIExport/ExportDownload`,
  method: 'POST',
  responseType: 'blob',
  data: data,
})
 
}
