/* eslint-disable eqeqeq */
import React from 'react';
import DataGrid, {
    Editing,
    Column,
    RequiredRule, StringLengthRule,
    Toolbar,
    PatternRule,
    Item as ToolbarItem
} from 'devextreme-react/data-grid';
import { withSnackbar } from 'notistack';
import { injectIntl } from "react-intl";

class OptionsGridComponent extends React.Component {

    constructor(props) {
        super(props);
        const options = props.data.value ? JSON.parse(JSON.stringify(props.data.value)) : [];
        this.state = {
            options
        };

    }
    characterFilter = (e) =>{
        var event = ((e.event||{}).originalEvent||{});
        var keyCode = event.keyCode;

        // Allow: Backspace, Delete, Tab, Escape, Enter '.', ',' and space
        if ([46, 8, 9, 27, 13, 110, 190, 188, 32].includes(keyCode) ||
        // Allow: Numbers
        ([48,49,50,51,52,53,54,55,56,57].includes(keyCode) && ["1","2","3","4","5","6","7","8","9","0"].includes( event.key))||
        // Allow: Ctrl+A
        (keyCode == 65 && event.ctrlKey === true) ||
        // Allow: Ctrl+C
        (keyCode == 67 && event.ctrlKey === true) ||
        // Allow: Ctrl+X
        (keyCode == 88 && event.ctrlKey === true) ||
        // Allow: home, end, left, right
        (keyCode >= 35 && keyCode <= 39)) {
        // Let it happen, don't do anything
            return;
        }
   
        // Ensure that it is a letter and stop the keypress otherwise
        if (keyCode < 65 || keyCode > 90) {
            event.preventDefault();
        }
    }
    
    onRowInserted = (e) => {
        this.props.data.setValue(this.state.options);
    }
    handlePastedValues = (e) => {
        const pasteData = e.event.originalEvent.clipboardData.getData('text/plain');
        if(!/^[a-zA-Z0-9\s.,]*$/i.test(pasteData)){
            e.event.preventDefault();
            this.props.enqueueSnackbar("Invalid characters!", { variant: "error" });
        }
    }
    render() {
        return <DataGrid
            title="Select options"
            // keyExpr="customfieldlabelresorceid"
            dataSource={this.state.options}
            showBorders={true}
            columnAutoWidth={true}
            onRowInsering={this.onRowInserted}
            onRowInserted={this.onRowInserted}
            onRowRemoved={this.onRowInserted}
            onRowUpdated={this.onRowInserted}
            repaintChangesOnly={true}
        >
            <Editing
                mode="form"
                allowUpdating={true}
                allowAdding={true}
                allowDeleting={true}
                useIcons={true}
                confirmDelete={false}
            />
            <Column
                dataField="option"
                caption="Option"
                editorOptions={{ width: 350,  onKeyDown:this.characterFilter, onPaste:this.handlePastedValues }}
            >
                <RequiredRule />
                <StringLengthRule message="Label must have 2 to 250 characters" min={2} max={250} />
                <PatternRule pattern="^[a-zA-Z0-9\s.,]*$" message="Illegal character." />
            </Column>
            <Toolbar>
                <ToolbarItem name="addRowButton" showText="always" />
            </Toolbar>
        </DataGrid>;
    }
}
export default injectIntl(withSnackbar(OptionsGridComponent));