/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import { connect } from "react-redux";
import { Nav, Tab } from "react-bootstrap";
import moment from 'moment';
import PerfectScrollbar from "react-perfect-scrollbar";
const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false
};

const renderDiscourseRSSItem = (item, index) => {
    return (
        <a href="#" key={index} style={{ paddingLeft: 10, fontWeight: 500, fontSize: '14px', fontFamily: '\'Helvetica Neue\', \'Segoe UI\', Helvetica, Verdana, sans-serif', color: '#333' }} onClick={() => window.open(item.link)} className="kt-notification__item">
            <div className="kt-notification__item-details">
                <div className="kt-notification__item-title" >
                    {item.title}
                </div>
                <div className="kt-notification__item-time" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{item.creator}</span>
                    <span>{moment(item.pubDate).format('L')}</span>
                </div>
            </div>
        </a>
    );
};

class ContractSidePanelDiscourseRSS extends React.Component {
    render() {
        if (!this.props.rssFeed) {
            return null;
        }
        const { rssFeed } = this.props;

        return (
            <Tab.Container defaultActiveKey="1">
                <div className="kt-quick-panel__nav">
                    <Nav className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand kt-notification-item-padding-x">
                        <Nav.Item className="nav-item">
                            <Nav.Link eventKey="1" className="nav-link show">
                                {rssFeed.title}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <div className="kt-quick-panel__content">
                    <Tab.Content>
                        <Tab.Pane eventKey="1">
                            <PerfectScrollbar
                                options={perfectScrollbarOptions}
                                style={{ maxHeight: "calc(100vh - 100px)", position: "relative" }}>
                                <div className="kt-notification">
                                    {
                                        rssFeed.items && (
                                            rssFeed.items.map((item, index) => (
                                                renderDiscourseRSSItem(item, index)
                                            )))
                                    }
                                </div>
                            </PerfectScrollbar>
                            {/* <TabNotify
                                panelCloseBtnRef={this.props.panelCloseBtnRef}
                                contractid={this.props.contractid}
                                isOpen={this.props.isOpen}
                                dashboard={contractDashboard}
                                properties={sidePanel.supplierItems} /> */}
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        );
    }
}

const mapStateToProps = store => ({
    rssFeed: store.application.rssFeed,
});

export default connect(mapStateToProps)(ContractSidePanelDiscourseRSS)
