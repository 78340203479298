import axios from "../shared/axios";
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function createContractOtherPrice(data) {
  return new Promise((resolve, reject) => {
    axios.post(`/api/ContractOtherPrice/Create`, data)
      .then(result => {
        store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_CONTRACT });
        resolve(result);
      })
      .catch(error => reject(error));
  });
}

export function updateContractOtherPrice(data) {
  return new Promise((resolve, reject) => {
    axios.put(`/api/ContractOtherPrice/Update`, data)
      .then(result => {
        store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_CONTRACT });
        resolve(result);
      })
      .catch(error => reject(error));
  });
}

export function getContractOtherPrices(companyid, projectid, contractid) {
  return axios.post(
    `/api/ContractOtherPrice/LoadContractOtherPriceList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}`
  );
}

export function getContractOtherPrice(contractotherpriceid) {
  return axios.get(
    `/api/ContractOtherPrice/GetContractOtherPrice?contractotherpriceid=${contractotherpriceid}`
  );
}

export function getContractOtherPriceChangeSummary(contractotherpriceid) {
  return axios.get(`/api/ContractOtherPrice/GetContractOtherPriceChangeSummary`, { params: { contractotherpriceid } });
}

export function getToSelectList(contractid) {
  return axios.get(`/api/ContractOtherPrice/ToSelectList`, { params: { contractid } });
}

export function deleteItem(contractotherpriceid) {
  return axios.delete(`/api/ContractOtherPrice/Delete`, { params: { contractotherpriceid } });
}

export function fetchSecondaryPriceChanges(contractotherpriceid) {
  return axios.get(`/api/ContractOtherPriceChange/LoadChanges`, { params: { contractotherpriceid } });
}