import axios from '../shared/axios';
// import qs from 'querystring';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function create(data) {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(`/api/Assessment/Create`, data);
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
            resolve(result);
        }
        catch (err) {
            reject(err)
        }
    });
}
export function update(data) {
    return axios.put(`/api/Assessment/Update`, data);
}
export function communicate(data) {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.put(`/api/Assessment/Communicate`, data);
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_CONTRACT });
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
            resolve(result);
        }
        catch (err) {
            reject(err)
        }
    });
}
export function get(assessmentid) {
    return axios.get(`/api/Assessment/Get`, { params: { assessmentid } });
}
export function deleteDraft(assessmentid) {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(`api/Assessment/DeleteDraft`, null, { params: { assessmentid } });
            store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
            resolve(result);
        }
        catch (err) {
            reject(err)
        }
    })
}
export function getRegisterList(contractid, notificationtype, includedrafts, accessibleWfIds) {
    return axios.post(`/api/Assessment/RegisterList`, { accessibleWfIds }, { params: { contractid, notificationtype, includedrafts } });
}