import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import RfqList from './Rfq/List';
import RfqCreate from './Rfq/Create';
import RfqDetail from './Rfq/Detail';


class RfqRouter extends React.Component {
    constructor(props) {
        super(props);
        this.communicateToUser = this.props.globalResources.SUPPLIER_NAME;
        this.notificationType = 'RFQ';
        this.documentType = 'DocumentRFQ';
        this.notifyingParty = props.workflowItem.notifyingParty;

        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;


        this.configurableRFQFields = {};
        // try {
        //     const cenFields = props.placeholders.find(ph => ph.replacementKey === 'Fields');

        //     if (cenFields) {
        //         cenFields.values.forEach(fieldItem => {
        //             this.fields[fieldItem.field] = fieldItem.isActive ? fieldItem.display : '';
        //         });
        //     }            
        // } catch (error) {
        //     console.log('Error loading CEN Fields Placeholder!');
        // }

        try {
            const taskRFQFields = props.placeholders.find(ph => ph.replacementKey === 'RFQFields');

            if (taskRFQFields) {
                taskRFQFields.values.forEach(fieldItem => {
                    this.configurableRFQFields[fieldItem.field] = fieldItem.isActive ? fieldItem.display : '';
                });
            }
        } catch (error) {
            console.log('Error loading RFQ Fields Placeholder!');
        }
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;
        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<RfqList
                        {...this.props}
                        // fields={this.fields}
                        
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        notificationType={this.notificationType}
                        configurableRFQFields={this.configurableRFQFields}
                    />}
                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                        <RfqCreate
                            {...this.props}
                            // fields={this.fields}
                            title={this.notificationName}
                            
                            communicateToUser={this.communicateToUser}
                            notificationType={this.notificationType}
                            configurableRFQFields={this.configurableRFQFields}
                        />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    element={<RfqDetail
                        {...this.props}
                        // fields={this.fields}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                        documentType={this.documentType}
                        communicateToUser={this.communicateToUser}
                        notificationType={this.notificationType}
                        notifyUserFilter={workflowItem.notifyUserFilter}
                        configurableRFQFields={this.configurableRFQFields}
                    />}
                />
            </Routes>
        );
    }
};




export default (RfqRouter);