const template = {
  name: "Default NEC4 TSSC v2.6 05/02/2024",
  isSystemDefault: true,
  isGlobal: true,
  companies: [],
  resources: [
    {
      id: 1,
      type: "TEXT",
      key: "SUPPLIER_NAME",
      value: "Contractor"
    },
    {
      id: 2,
      type: "TEXT",
      key: "SUPPLIER_ABBREVIATION",
      value: "CON"
    },
    {
      id: 3,
      type: "TEXT",
      key: "PM_NAME",
      value: "Client's Agent"
    },
    {
      id: 4,
      type: "TEXT",
      key: "PM_ABBREVIATION",
      value: "CA"
    },
    {
      id: 5,
      type: "TEXT",
      key: "SUPERVISOR_NAME",
      value: "Supervisor"
    },
    {
      id: 6,
      type: "TEXT",
      key: "SUPERVISOR_ABBREVIATION",
      value: "SUP"
    },
    {
      id: 7,
      type: "TEXT",
      key: "CLIENT_NAME",
      value: "Client"
    },
    {
      id: 8,
      type: "NUMBER",
      key: "SUPPLIER_REPLY_PERIOD",
      value: 14
    },
    {
      id: 9,
      type: "NUMBER",
      key: "PM_REPLY_PERIOD",
      value: 14
    },
    {
      id: 10,
      type: "NUMBER",
      key: "QUOTE_DURATION",
      value: 21
    },
    {
      id: 13,
      type: "NUMBER",
      key: "REQUOTE_DURATION",
      value: 21
    },
    {
      id: 11,
      type: "NUMBER",
      key: "QUOTE_RESPONSE",
      value: 14
    },
    {
      id: 14,
      type: "NUMBER",
      key: "REQUOTE_RESPONSE",
      value: 14
    },
    {
      id: 12,
      type: "NUMBER",
      key: "VARIATION_RESPONSE",
      value: 7
    },
    {
      id: 8,
      type: "TEXT",
      key: "CLIENT_ABBREVIATION",
      value: "CL"
    },
    {
      id: 9,
      type: "TEXT",
      key: "COMPLETION_DATE",
      value: "service period end date"
    }
  ],
  workflowGroups: [
    {
      id: 101,
      title: "Early Warnings",
      workflows: [
        {
          id: 1,
          title: "$CLIENT_NAME Early Warnings",
          notificationName: "Early Warning",
          abbreviation: "$PM_ABBREVIATION-Warning-",
          notify: true,
          replyEnabled: true,
          assetFieldsEnabled: false,
          replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "PM",
          replyingParty: "SUPPLIER",
          actioningParty: "PM",
          resolveActioningParty: "PM",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
            },
            actionTab: {
              actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
            resolveActionTab: {
              resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
            }
          }
        },
        {
          id: 2,
          title: "$SUPPLIER_NAME Early Warnings",
          notificationName: "Early Warning",
          abbreviation: "$SUPPLIER_ABBREVIATION-Warning-",
          notify: true,
          replyEnabled: true,
          assetFieldsEnabled: false,
          replyPeriodOption: "PM_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "SUPPLIER",
          replyingParty: "PM",
          actioningParty: "PM",
          resolveActioningParty: "PM",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
            },
            actionTab: {
              actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]",
            resolveActionTab: {
              resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
            }
          }
        },
        {
          id: 3,
          title: "Early Warning Register",
          notificationName: "Early Warning",
          abbreviation: "Warning",
          overrideResources: null,
          userFilters: {
            accessFilter: "null"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "MeetingRequiredLabel",
              display: "Is a meeting required?",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "NotificationClause",
              display: "You are given early warning of a matter which could",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "RiskItemsLabel",
              display: "You are given early warning of a matter which could",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Pm",
              display: "$CLIENT_NAME Early Warnings awaiting reply",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "Supplier",
              display: "$SUPPLIER_NAME Early Warnings awaiting reply",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "RiskTypes",
          values: [
            {
              id: 1,
              isActive: true,
              field: "riskincreaseprice",
              display: "Increase the total of the Prices",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "riskdelaymilestone",
              display: "interfere with the timing of the service",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "riskimpairperformance",
              display: "Impair the effectiveness of the service",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "riskincreasecontractorcost",
              display: "Increase the $SUPPLIER_NAME's total cost",
              overrideResources: null
            },
            {
              id: 2,
              isActive: false,
              field: "riskdelaycompletion",
              display: "Delay Completion",
              overrideResources: null
            },
            {
              id: 6,
              isActive: false,
              field: "riskincreasedefinedcost",
              display: "Increase the Defined Cost of expending any Provisional Sums",
              overrideResources: null
            },
            {
              id: 7,
              isActive: false,
              field: "riskaffectsafety",
              display: "Adversely affect the $SUPPLIER_NAME’s ability to safely carry out the works",
              overrideResources: null
            },
            {
              id: 8,
              isActive: false,
              field: "riskcontractbreach",
              display: "Affect the achievement of another Partner's objectives",
              overrideResources: null
            },
            {
              id: 9,
              isActive: false,
              field: "riskaffectmodel",
              display: "Adversely affect the creation or use of the Information Model",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "MeetingOptions",
          values: [
            {
              id: 1,
              isActive: true,
              field: "0",
              display: "Meeting Not Required",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "1",
              display: "Meeting Required - Next Scheduled early warning meeting",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "2",
              display: "Meeting Required - You are instructed to attend a meeting as below",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "RiskTypesSupplier",
          values: [
            {
              id: 2,
              isActive: true,
              field: "riskincreaseprice",
              display: "Increase the total of the Prices",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "riskdelaymilestone",
              display: "Interfere with the timing of the service",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "riskimpairperformance",
              display: "Impair the effectiveness of the service",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "riskincreasecontractorcost",
              display: "Increase the $SUPPLIER_NAME's cost",
              overrideResources: null
            },
            {
              id: 3,
              isActive: false,
              field: "riskdelaycompletion",
              display: "Delay Completion",
              overrideResources: null
            },
            {
              id: 7,
              isActive: false,
              field: "riskincreasedefinedcost",
              display: "Increase the Defined Cost of expending any Provisional Sums",
              overrideResources: null
            },
            {
              id: 8,
              isActive: false,
              field: "riskaffectsafety",
              display: "Adversely affect the $SUPPLIER_NAME’s ability to safely carry out the works",
              overrideResources: null
            },
            {
              id: 9,
              isActive: false,
              field: "riskcontractbreach",
              display: "Affect the achievement of another Partner's objectives",
              overrideResources: null
            },
            {
              id: 10,
              isActive: false,
              field: "riskaffectmodel",
              display: "Adversely affect the creation or use of the Information Model",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 102,
      title: "Instructions",
      workflows: [
        {
          id: 4,
          title: "$CLIENT_NAME Instruction",
          notificationName: "Instruction",
          abbreviation: "$CLIENT_NAME-Instruction-",
          notify: true,
          replyEnabled: false,
          assetFieldsEnabled: false,
          replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "PM",
          replyingParty: "SUPPLIER",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "InstructionTypes",
          values: [
            {
              id: 2,
              isActive: true,
              field: "",
              display: "14.2 to change the Scope or a Task Order",
              overrideResources: null
            },
            {
              id: 19,
              isActive: true,
              field: "",
              display: "14.3 to correct a mistake in the Price List",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "",
              display: "21.3 to remove a person",
              overrideResources: null
            },
            {
              id: 11,
              isActive: true,
              field: "",
              display: "30.2 to stop or not start work",
              overrideResources: null
            },
            {
              id: 20,
              isActive: true,
              field: "",
              display: "30.2 to start or restart work",
              overrideResources: null
            },
            {
              id: 21,
              isActive: true,
              field: "",
              display: "30.2 to remove work from the Scope",
              overrideResources: null
            },
            {
              id: 13,
              isActive: true,
              field: "",
              display: "43.1 to change the Scope after accepting a proposal not to correct a Defect",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "PM",
              display: "$CLIENT_NAME Instructions awaiting reply",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "Supervisor",
              display: "$SUPERVISOR_NAME Instructions awaiting reply",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 114,
      title: "Task Orders",
      workflows: [
        {
          id: 62,
          title: "$CLIENT_NAME Proposed Task Order",
          notificationName: "Proposed Task Order",
          abbreviation: "$CLIENT_NAME-PROP-TO",
          notify: true,
          financialLimitEnabled: false,
          assetFieldsEnabled: false,
          notifyingParty: "PM",
          quotingParty: "SUPPLIER",
          quoteReplyingParty: "PM",
          quoteNotificationName: "TO-Quote",
          quoteReferencePrefix: "TO-Quote-",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            quoteMainNotice: {
              createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            quoteReplyNotice: {
              saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        },
        {
          id: 38,
          title: "$CLIENT_NAME Task Orders",
          notificationName: "Task Order",
          abbreviation: "$CLIENT_NAME-TO-",
          notify: true,
          financialLimitEnabled: false,
          assetFieldsEnabled: false,
          notifyingParty: "PM",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Note",
              display: "Further to our consultation, under clause 14.7 I instruct you to carry out the following task",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "DescriptionLabel",
              display: "Detailed description of the work in the task",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "ItemsListLabel",
              display: "Items taken from price list",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "TotalListPriceLabel",
              display: "Price list total",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "CompensationDescriptionLabel",
              display: "Items assessed in the same way as a compensation event",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "TotalAssessedLabel",
              display: "Assessed list total",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "TotalPriceLabel",
              display: "Total of prices",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "StartDateLabel",
              display: "Start Date",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "CompletionDateLabel",
              display: "Completion Date",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "DelayAmountLabel",
              display: "Amount of delay damages for the late completion of the task",
              overrideResources: null
            },
            {
              id: 11,
              isActive: true,
              field: "DelayPaymentPeriodLabel",
              display: "Payment period",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "Fields",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Description",
              display: "Detailed description of the work in the task",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "ItemsList",
              display: "Items taken from price list",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "TotalListPrice",
              display: "Price list total",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "CompensationDescription",
              display: "Items assessed in the same way as a compensation event",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "TotalAssessed",
              display: "Assessed list total",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "TotalPrice",
              display: "Total of prices",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "StartDate",
              display: "Start Date",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "CompletionDate",
              display: "Completion Date",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "DelayAmount",
              display: "Amount of delay damages for the late completion of the task",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "DelayPaymentPeriod",
              display: "Delay damages calculation period",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "RFQRelationEnabled",
          values: [
            {
              id: 1,
              isActive: true,
              field: "RFQRelationEnabled",
              display: "Active-Inactive state indicates weather the RFQ can be attached in Task Order communications",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "RFQStatuses",
          values: [
            {
              id: 1,
              isActive: true,
              field: "APMA",
              display: "Quote Not Accepted - $PM_ABBREVIATION to make own Assessment",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "ARQ",
              display: "Quote Not Accepted - $SUPPLIER_NAME to resubmit",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "AM",
              display: "$PM_ABBREVIATION Assessment Made",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "QA",
              display: "Quote Accepted",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "APMRQ",
              display: "Awaiting $PM_ABBREVIATION Response to quote",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "APMR",
              display: "Awaiting $PM_ABBREVIATION Response to CEN",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "AQ",
              display: "Awaiting Quote",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "NC",
              display: "Not Communicated",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "R",
              display: "Not a Compensation Event",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "DNG",
              display: "Decision Not Given",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "QuoteStatuses",
          values: [
            {
              id: 1,
              isActive: true,
              field: "0",
              display: "Not Accepted - $SUPPLIER_NAME to resubmit",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "1",
              display: "Accepted",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "2",
              display: "Awaiting Response",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "3",
              display: "Not Accepted - $PM_ABBREVIATION to make assessment",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "4",
              display: "Not Accepted - Other quote approved",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "5",
              display: "Not Accepted - Decision will not be given",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "none",
              display: "Not Communicated",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "QuoteRfqImpactOpts",
          values: [
            {
              id: 1,
              isActive: true,
              field: "noeffect",
              display: "There is no effect on the Accepted Programme",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "mitigated",
              display: "There is an effect on the Accepted Programme but this has been mitigated by measures as detailed",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "notmitigated",
              display: "There is an effect on the Accepted Programme which we are unable to mitigate",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "RfqReplyProceedEnabled",
          values: [
            {
              id: 1,
              isActive: true,
              field: "RfqReplyProceedEnabled",
              display: "RFQ Quote Reply field proceed options Enabled/Disabled",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "QuoteFields",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Description",
              display: "Detailed description of the work in the task",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "ItemsList",
              display: "Items taken from price list",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "TotalListPrice",
              display: "Price list total",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "TotalAssessed",
              display: "Assessed list total",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "TotalPrice",
              display: "Total of prices",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "StartDate",
              display: "Start Date",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "CompletionDate",
              display: "Completion Date",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "DelayAmount",
              display: "Amount of delay damages for the late completion of the task",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "DelayPaymentPeriod",
              display: "Payment period",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "UserLimitCheckEnabled",
          values: [
            {
              id: 1,
              isActive: false,
              field: "UserLimitCheckEnabled",
              display: "Active-Inactive state indicates weather the user limit check can be applied in Task Order communications",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "PmRfqAQ",
              display: "Rfq awaiting quotes",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "PmRfqARQ",
              display: "Rfq awaiting revised quotes",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "PmRfqAPMRQ",
              display: "Rfq awaiting response to quotes",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "PmRfqAPMA",
              display: "Rfq awaiting $CLIENT_NAME Assessment",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "replydraftpmcequotessupplier",
              display: "$SUPPLIER_NAME CEN Quotation",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "draftconrfqquotation",
              display: "RFQ Quotation",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 103,
      title: "Compensation Events",
      workflows: [
        {
          id: 5,
          title: "$PM_NAME Compensation Events",
          notificationName: "Compensation Event",
          abbreviation: "$PM_ABBREVIATION-Compensation-",
          notify: true,
          financialLimitEnabled: false,
          assetFieldsEnabled: false,
          notifyingParty: "PM",
          quotingParty: "SUPPLIER",
          quoteReplyingParty: "PM",
          quoteNotificationName: "Quote",
          quoteReferencePrefix: "Quote-",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            quoteMainNotice: {
              createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            quoteReplyNotice: {
              saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        },
        {
          id: 40,
          title: "$SUPPLIER_NAME Compensation Events",
          notificationName: "Compensation Event",
          abbreviation: "$SUPPLIER_ABBREVIATION-Compensation-",
          notify: true,
          replyEnabled: true,
          assetFieldsEnabled: false,
          replyPeriodOption: "VARIATION_RESPONSE",
          replyPeriod: 0,
          notifyingParty: "SUPPLIER",
          replyingParty: "PM",
          quotingParty: "SUPPLIER",
          quoteReplyingParty: "PM",
          quoteNotificationName: "Quote",
          quoteReferencePrefix: "Quote-",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Support\"]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
            },
            quoteMainNotice: {
              createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            quoteReplyNotice: {
              saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        },
        {
          id: 8,
          title: "$PM_NAME Assessments",
          notificationName: "Assessment",
          abbreviation: "$PM_ABBREVIATION-Assessment-",
          notify: true,
          financialLimitEnabled: false,
          assetFieldsEnabled: false,
          notifyingParty: "PM",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
          }
        },
        {
          id: 11,
          title: "Master Quote Register",
          overrideResources: null,
          userFilters: {
            accessFilter: "null"
          }
        },
        {
          id: 12,
          title: "Master CE Register",
          overrideResources: null,
          userFilters: {
            accessFilter: "null"
          }
        },
        {
          id: 13,
          title: "Implemented CE Register",
          overrideResources: null,
          userFilters: {
            accessFilter: "null"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "PmAssumptionLabel",
              display: "The quotation is to be based on the following assumptions",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "PmAssumptionNote",
              display: "You are instructed to provide a quotation by $date",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "SupplierDescNote",
              display: "You are notified of the following event as a compensation event",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "ReplyAcceptedCommentLabel",
              display: "You are notified that the event is a compensation event",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "ReplyRejectedCommentLabel",
              display: "Details",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "ReplyAcceptedAssumptionLabel",
              display: "The quotation is to be based on the following assumptions",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "QuoteNote",
              display: "We submit our quotation for compensation event $reference",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "ExtensionPMLabel",
              display: "We require an extension to the time to reply as follows",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "ExtensionSupplierLabel",
              display: "We require an extension to the time to reply as follows",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "AssessmentDetailLabel",
              display: "Details",
              overrideResources: null
            },
            {
              id: 11,
              isActive: true,
              field: "ProposedInstructionNote",
              display: "You are instructed to provide a quotation by $date",
              overrideResources: null
            },
            {
              id: 12,
              isActive: true,
              field: "ProposedInstructionLabel",
              display: "The quotation is to be based on the following assumptions",
              overrideResources: null
            },
            {
              id: 13,
              isActive: true,
              field: "ReplyRejectedOptionsLabel",
              display: "You are notified that the event is not a compensation event as it",
              overrideResources: null
            },
            {
              id: 14,
              isActive: true,
              field: "QuoteCostEffectLabel",
              display: "Change to the Prices",
              overrideResources: null
            },
            {
              id: 15,
              isActive: true,
              field: "QuoteDateEffectLabel",
              display: "Delay",
              overrideResources: null
            },
            {
              id: 16,
              isActive: true,
              field: "QuoteDaysEffectLabel",
              display: "The length of time that the $COMPLETION_DATE is delayed",
              overrideResources: null
            },
            {
              id: 17,
              isActive: true,
              field: "AssessmentCostEffectLabel",
              display: "Change in the Total of the Prices",
              overrideResources: null
            },
            {
              id: 18,
              isActive: true,
              field: "AssessmentDaysEffectLabel",
              display: "The length of time that the $COMPLETION_DATE is delayed",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "PmCompensationAQ",
              display: "Compensation Events notified by $CLIENT_NAME awaiting quotes",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "PmCompensationARQ",
              display: "Compensation Events notified by $CLIENT_NAME awaiting revised quotes",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "PmCompensationAPMRQ",
              display: "Compensation Events notified by $CLIENT_NAME awaiting response to quotes",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "PmCompensationAPMA",
              display: "Compensation Events notified by $CLIENT_NAME awaiting Assessment",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "SupCompensationAQ",
              display: "Compensation Events notified by $SUPPLIER_NAME awaiting quotes",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "SupCompensationARQ",
              display: "Compensation Events notified by $SUPPLIER_NAME awaiting revised quotes",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "SupCompensationAPMR",
              display: "Compensation Events notified by $SUPPLIER_NAME awaiting $CLIENT_NAME response",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "SupCompensationAPMRQ",
              display: "Compensation Events notified by $SUPPLIER_NAME awaiting response to quotes",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "SupCompensationAPMA",
              display: "Compensation Events notified by $SUPPLIER_NAME awaiting Assessment",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "PmProposedAQ",
              display: "Proposed changes awaiting quotes",
              overrideResources: null
            },
            {
              id: 11,
              isActive: true,
              field: "PmProposedARQ",
              display: "Proposed changes awaiting revised quotes",
              overrideResources: null
            },
            {
              id: 12,
              isActive: true,
              field: "PmProposedAPMRQ",
              display: "Proposed changes awaiting response to quotes",
              overrideResources: null
            },
            {
              id: 13,
              isActive: true,
              field: "PmProposedAPMA",
              display: "Proposed changes awaiting Assessment",
              overrideResources: null
            },
            {
              id: 14,
              isActive: true,
              field: "PmExtension",
              display: "$PM_NAME Quote Response Extensions awaiting Acceptance",
              overrideResources: null
            },
            {
              id: 15,
              isActive: true,
              field: "SupExtension",
              display: "$SUPPLIER_NAME Quote Extensions awaiting Acceptance",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "CompensationClauses",
          values: [
            {
              id: 2,
              isActive: true,
              field: "",
              display: "60.1(1) An instruction changing the Scope or a Task Order",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "",
              display: "60.1(2) The Client does not provide right of access",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "",
              display: "60.1(3) The Client does not provide something by the date stated in the contract",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "",
              display: "60.1(4) An instruction to stop or not start work",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "",
              display: "60.1(5) The Client does not work within the conditions stated in the Scope",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "",
              display: "60.1(6) The Client does not reply to a communication within the period required by the contract",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "",
              display: "60.1(7) The Client changes a decision",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "",
              display: "60.1(8) Correction of an assumption made for assessment of a compensation event",
              overrideResources: null
            },
            {
              id: 11,
              isActive: true,
              field: "",
              display: "60.1(9) An instruction to correct a mistake in the Price List",
              overrideResources: null
            },
            {
              id: 12,
              isActive: true,
              field: "",
              display: "The Act - 1.1 The $SUPPLIER_NAME exercises its right to suspect performance",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ReplyRejectOptions",
          values: [
            {
              id: 1,
              isActive: true,
              field: "faultpscp",
              display: "arises from a fault of the Contractor",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "notexpectnothappen",
              display: "has not happened and is not expected to happen",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "noteffectcostcompletion",
              display: "has no effect on Defined Cost, Completion or meeting a Key Date or",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "statedcontract",
              display: "is not one of the compensation events stated in this contract",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ClaimClauses",
          values: [
            {
              id: 2,
              isActive: true,
              field: "",
              display: "60.1(1) An instruction changing the Scope or a Task Order",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "",
              display: "60.1(2) The Client does not provide right of access",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "",
              display: "60.1(3) The Client does not provide something by the date stated in the contract",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "",
              display: "60.1(4) An instruction to stop or not start work",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "",
              display: "60.1(5) The Client does not work within the conditions stated in the Scope",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "",
              display: "60.1(6) The Client does not reply to a communication within the period required by the contract",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "",
              display: "60.1(7) The Client changes a decision",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "",
              display: "60.1(8) Correction of an assumption made for assessment of a compensation event",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "",
              display: "60.1(9) An instruction to correct a mistake in the Price List",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "CompensationStatuses",
          values: [
            {
              id: 1,
              isActive: true,
              field: "APMA",
              display: "Quote Not Accepted - Client to make own Assessment",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "ARQ",
              display: "Quote Not Accepted - $SUPPLIER_NAME to resubmit",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "AM",
              display: "Client Assessment Made",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "QA",
              display: "Quote Accepted",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "APMRQ",
              display: "Awaiting Client Response to quote",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "APMR",
              display: "Awaiting Client Response to CE Notification",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "AQ",
              display: "Awaiting Quote",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "NC",
              display: "Not Communicated",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "R",
              display: "Not a Compensation Event",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "DNG",
              display: "Not a Compensation Event",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "QuoteStatuses",
          values: [
            {
              id: 1,
              isActive: true,
              field: "0",
              display: "Not Accepted - $SUPPLIER_NAME to resubmit",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "1",
              display: "Accepted",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "2",
              display: "Awaiting Response",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "3",
              display: "Not Accepted - Client to assess",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "4",
              display: "Not Accepted - Other quote approved",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "5",
              display: "Not Accepted - Proposed instruction will not be given",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "none",
              display: "Not Communicated",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "InstructionReplyProceedOpts",
          values: [
            {
              id: 1,
              isActive: true,
              field: "61.1",
              display: "$SUPPLIER_NAME is instructed to proceed with works",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "61.2",
              display: "$SUPPLIER_NAME is instructed to NOT proceed with works",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "QuoteProgrammeImpactOpts",
          values: [
            {
              id: 1,
              isActive: true,
              field: "noeffect",
              display: "There is no effect on the $COMPLETION_DATE",
              overrideResources: null
            },
            {
              id: 2,
              isActive: false,
              field: "mitigated",
              display: "There is an effect on the $COMPLETION_DATE but this has been mitigated by measures as detailed",
              overrideResources: null
            },
            {
              id: 3,
              isActive: false,
              field: "notmitigated",
              display: "There is an effect on the $COMPLETION_DATE which we are unable to mitigate",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "TaskOrderOptionEnabled",
          values: [
            {
              id: 1,
              isActive: true,
              field: "TaskOrderOptionEnabled",
              display: "Active-Inactive state indicates weather Task-Order can be selected in CEN communications",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "EWRelationEnabled",
          values: [
            {
              id: 1,
              isActive: true,
              field: "EWRelationEnabled",
              display: "Active-Inactive state indicates weather the EWN can be attached in CEN communications",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "InstructionReplyProceedEnabled",
          values: [
            {
              id: 1,
              isActive: false,
              field: "InstructionReplyProceedEnabled",
              display: "Proposed instructions Quote Reply field proceed options Enabled/Disabled",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "RelatedCompensationText",
          values: [
            {
              id: 1,
              isActive: true,
              field: "RelatedCompensationText",
              display: "Regarding the aforementioned instruction, you are hereby notified of a compensation event under clause 61.1",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "InstructionReplyOpts",
          values: [
            {
              id: 1,
              isActive: true,
              field: "1",
              display: "QuoteStatus_1",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "0",
              display: "QuoteStatus_0",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "3",
              display: "QuoteStatus_3",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "5",
              display: "QuoteStatus_5",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 104,
      title: "Contractor's Plans",
      workflows: [
        {
          id: 14,
          title: "$SUPPLIER_NAME Plan",
          notificationName: "Plan",
          abbreviation: "$SUPPLIER_ABBREVIATION-Plan-",
          notify: true,
          replyEnabled: true,
          assetFieldsEnabled: false,
          replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "SUPPLIER",
          replyingParty: "PM",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "ReasonLabel",
              display: "Reason for any change to Planned Completion",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "ChangeLabel",
              display: "Change in any other Key Date, Completion Date for a section or other important project milestone in period and reasons why",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "MainProgressLabel",
              display: "Main progress achieved in period",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "KeyActivitiesLabel",
              display: "Key activities forecast in next four weeks",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "SummaryLabel",
              display: "Summary of critical path",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "ChangesLabel",
              display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc.",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "CompensationLabel",
              display: "Compensation events added to programme this period",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "NotificationClause",
              display: "Please find attached programme for acceptance",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "ReplyRejectReasonLabel",
              display: "The $SUPPLIER_NAME’s programme is not accepted for the following reasons",
              overrideResources: null
            },
            {
              id: 10,
              isActive: true,
              field: "ReplyNotificationClause",
              display: "In accordance with NEC4 PSC clause 31.3 you are notified that the $SUPPLIER_NAME’s Programme",
              overrideResources: null
            },
            {
              id: 11,
              isActive: true,
              field: "ReplyNotificationClause",
              display: "You are notified that the $SUPPLIER_NAME’s Programme",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Supplier",
              display: "$SUPPLIER_NAME Plan awaiting Acceptance",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ReplyRejectOptions",
          values: [
            {
              id: 1,
              isActive: true,
              field: "plansnotpractical",
              display: "The $SUPPLIER_NAME’s plans which it shows are not practicable",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "missingcontractrequires",
              display: "It does not show the information which this contract requires",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "notrepresentplan",
              display: "It does not represent the $SUPPLIER_NAME’s plans realistically",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "notcomply",
              display: "It does not comply with the Scope",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "DashboardDisplayPlannedCompletionOnLatest",
          values: [
            {
              id: 1,
              isActive: true,
              field: "DashboardDisplayPlannedCompletionOnLatest",
              display: "Active-Inactive state indicates weather (Planned Completion shown on the latest Accepted Programme) is displayed on dasboard",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "Fields",
          values: [
            {
              id: 1,
              isActive: true,
              field: "PreviousPlanned",
              display: "Previous Planned Completion",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "PlannedCompletion",
              display: "Date of Planned Completion this period",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "Reason",
              display: "Reason for any change to Planned Completion",
              overrideResources: null
            },
            {
              id: 4,
              isActive: false,
              field: "Change",
              display: "Change in any other Key Date, Sectional Completion or other important project milestone in period and reasons why",
              overrideResources: null
            },
            {
              id: 5,
              isActive: false,
              field: "MainProgress",
              display: "Main progress achieved in period",
              overrideResources: null
            },
            {
              id: 6,
              isActive: false,
              field: "KeyActivities",
              display: "Key activities forecast in next four weeks",
              overrideResources: null
            },
            {
              id: 7,
              isActive: false,
              field: "Summary",
              display: "Summary of critical path",
              overrideResources: null
            },
            {
              id: 8,
              isActive: true,
              field: "Changes",
              display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc.",
              overrideResources: null
            },
            {
              id: 9,
              isActive: true,
              field: "Compensation",
              display: "Compensation events added to programme this period",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ReplyOptions",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Accepted",
              display: "Accepted",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "Not Accepted",
              display: "Not Accepted",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 105,
      title: "Notifications",
      workflows: [
        {
          id: 15,
          title: "$CLIENT_NAME Notifications",
          notificationName: "Notification",
          abbreviation: "$CLIENT_NAME-Notification-",
          notify: true,
          replyEnabled: false,
          assetFieldsEnabled: false,
          replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "PM",
          replyingParty: "SUPPLIER",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        },
        {
          id: 16,
          title: "$SUPPLIER_NAME Notifications",
          notificationName: "Notification",
          abbreviation: "$SUPPLIER_ABBREVIATION-Notification-",
          notify: true,
          replyEnabled: false,
          assetFieldsEnabled: false,
          replyPeriodOption: "PM_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "SUPPLIER",
          replyingParty: "PM",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "PmClauses",
          values: [
            {
              id: 2,
              isActive: true,
              field: "",
              display: "13.1 change of address for communications",
              overrideResources: null
            },
            {
              id: 27,
              isActive: true,
              field: "",
              display: "14.5 cancellation of delegation",
              overrideResources: null
            },
            {
              id: 34,
              isActive: true,
              field: "",
              display: "14.6 of appointment of the Client's Agent",
              overrideResources: null
            },
            {
              id: 13,
              isActive: true,
              field: "",
              display: "44.1 of assessment of the cost of having an uncorrected Defect corrected by others",
              overrideResources: null
            },
            {
              id: 30,
              isActive: true,
              field: "",
              display: "62.5 of correction of an assumption about a compensation event",
              overrideResources: null
            },
            {
              id: 20,
              isActive: true,
              field: "",
              display: "90.1 of intention to terminate",
              overrideResources: null
            },
            {
              id: 21,
              isActive: true,
              field: "",
              display: "90.3 that the Contractor has defaulted",
              overrideResources: null
            },
            {
              id: 35,
              isActive: true,
              field: "",
              display: "90.3 that the Contractor has not stopped defaulting",
              overrideResources: null
            },
            {
              id: 32,
              isActive: true,
              field: "",
              display: "The Act - 1.4 of a Pay Less Notice",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "SupplierClauses",
          values: [
            {
              id: 2,
              isActive: true,
              field: "",
              display: "13.1 change of address for communications",
              overrideResources: null
            },
            {
              id: 5,
              isActive: true,
              field: "",
              display: "44.1 of assessment of the cost of having an uncorrected Defect corrected by others",
              overrideResources: null
            },
            {
              id: 6,
              isActive: true,
              field: "",
              display: "62.5 of correction of an assumption about a compensation event",
              overrideResources: null
            },
            {
              id: 7,
              isActive: true,
              field: "",
              display: "90.1 of intention to terminate",
              overrideResources: null
            },
            {
              id: 12,
              isActive: true,
              field: "",
              display: "The Act - 1.4 of a Pay Less Notice",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Supplier",
              display: "$SUPPLIER_NAME Notifications awaiting reply",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "PM",
              display: "$CLIENT_NAME Notifications awaiting reply",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "Supervisor",
              display: "$SUPERVISOR_NAME Notifications awaiting reply",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 106,
      title: "Submissions",
      workflows: [
        {
          id: 18,
          title: "$SUPPLIER_NAME Submissions",
          notificationName: "Submission",
          abbreviation: "$SUPPLIER_ABBREVIATION-Submission-",
          notify: true,
          replyEnabled: true,
          assetFieldsEnabled: false,
          replyPeriodOption: "PM_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "SUPPLIER",
          replyingParty: "PM",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Support\"]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "IssueClauses",
          values: [
            {
              id: 11,
              isActive: true,
              field: "",
              display: "43.1 of a proposal to accept a Defect",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Supplier",
              display: "$SUPPLIER_NAME Issue awaiting Acceptance",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "IssueClausesPM",
          values: [
            {
              id: 2,
              isActive: true,
              field: "",
              display: "43.1 of a proposal to accept a Defect",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 107,
      title: "Submissions (with quote)",
      workflows: [
        {
          id: 19,
          title: "$SUPPLIER_NAME Submissions (wq)",
          notificationName: "Submission",
          abbreviation: "CON-SubmissionWQ-",
          notify: true,
          replyEnabled: true,
          financialLimitEnabled: false,
          assetFieldsEnabled: false,
          replyPeriodOption: "PM_REPLY_PERIOD",
          replyPeriod: 0,
          notifyingParty: "SUPPLIER",
          replyingParty: "PM",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          replyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            replyNotice: {
              saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Support\"]]",
              communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "CostEffectLabel",
              display: "Change in the Prices",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "DaysEffectLabel",
              display: "Delay to planned Completion",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ProposalClauses",
          values: [
            {
              id: 3,
              isActive: true,
              field: "",
              display: "43.1 of a quotation to accept a Defect",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Supplier",
              display: "$SUPPLIER_NAME Proposals awaiting Acceptance",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ReplyOptions",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Accepted",
              display: "Accepted",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "Not Accepted",
              display: "Not Accepted",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "UserLimitCheckEnabled",
          values: [
            {
              id: 1,
              isActive: false,
              field: "UserLimitCheckEnabled",
              display: "Active-Inactive state indicates weather the user limit check can be applied in Proposal communications",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 108,
      title: "Payments",
      workflows: [
        {
          id: 20,
          title: "$CLIENT_NAME correction of invoice",
          notificationName: "Correction of invoice",
          abbreviation: "$CLIENT_NAME-Payment-",
          notify: true,
          financialLimitEnabled: false,
          assetFieldsEnabled: false,
          notifyingParty: "PM",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
          }
        },
        {
          id: 21,
          title: "$SUPPLIER_NAME Invoice",
          notificationName: "Invoice",
          abbreviation: "$SUPPLIER_ABBREVIATION-Payment-",
          notify: true,
          assetFieldsEnabled: false,
          notifyingParty: "SUPPLIER",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "CumulativePriceLabel",
              display: "Price for Work Done to Date",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "PlusAmountsSupplierLabel",
              display: "Plus other amounts to be paid to the $SUPPLIER_NAME",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "LessAmountsSupplierLabel",
              display: "Less amounts to be paid by or retained from the $SUPPLIER_NAME",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "TaxSupplierLabel",
              display: "Tax which the law requires the Employer to pay to the $SUPPLIER_NAME",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "TaxEnabled",
          values: [
            {
              id: 1,
              isActive: true,
              field: "TaxEnabled",
              display: "Active-Inactive state indicates tax field will be included in notification forms",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 109,
      title: "Quality Management",
      workflows: [
        {
          id: 23,
          title: "$PM_NAME Defect Notifications",
          notificationName: "Defect Notification",
          abbreviation: "$PM_NAME-Defect-",
          notify: true,
          assetFieldsEnabled: false,
          notifyingParty: "PM",
          actioningParty: "PM",
          resolveActioningParty: "PM",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            actionTab: {
              actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]",
            resolveActionTab: {
              resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
            }
          }
        },
        {
          id: 24,
          title: "$SUPPLIER_NAME Defect Notifications",
          notificationName: "Defect Notification",
          abbreviation: "$SUPPLIER_ABBREVIATION-Defect-",
          notify: true,
          assetFieldsEnabled: false,
          notifyingParty: "SUPPLIER",
          actioningParty: "PM",
          resolveActioningParty: "PM",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
              communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            actionTab: {
              actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Client\"]]",
            resolveActionTab: {
              resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
            }
          }
        },
        {
          id: 26,
          title: "Master Defect Register",
          notificationName: "Defect Notification",
          abbreviation: "Defect",
          overrideResources: null,
          userFilters: {
            accessFilter: "null"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "InstructionClauseLabel",
              display: "You are instructed to search for a Defect as follows",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "SupervisorClauseLabel",
              display: "You are notified of the following Defect",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "SupplierClauseLabel",
              display: "You are notified of the following Defect",
              overrideResources: null
            },
            {
              id: 4,
              isActive: true,
              field: "TestsClauseLabel",
              display: "You are notified of the following test or inspection",
              overrideResources: null
            }
          ]
        },
        {
          replacementKey: "ActionReminders",
          values: [
            {
              id: 1,
              isActive: true,
              field: "Instruction",
              display: "$CLIENT_NAME Instruction to Search awaiting sign off",
              overrideResources: null
            },
            {
              id: 2,
              isActive: true,
              field: "SuperDefect",
              display: "$CLIENT_NAME Notification of Defect awaiting acceptance",
              overrideResources: null
            },
            {
              id: 3,
              isActive: true,
              field: "Inspection",
              display: "$SUPPLIER_NAME Test/Inspection Notification awaiting sign off",
              overrideResources: null
            }
          ]
        }
      ]
    },
    {
      id: 110,
      title: "Delegated Actions",
      workflows: [
        {
          id: 27,
          title: "$CLIENT_NAME Delegated Actions",
          notificationName: "Delegated Action",
          abbreviation: "$CLIENT_NAME-Action-",
          notify: true,
          assetFieldsEnabled: false,
          notifyingParty: "PM",
          form_IncludeCompanyName: false,
          form_CompanyLogo: "PARTY",
          form_CompanyName: "PARTY",
          notifyAttachments: true,
          overrideResources: null,
          userFilters: {
            accessFilter: "null",
            mainNotice: {
              createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
              viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
              communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
            },
            notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
          }
        }
      ],
      placeholders: [
        {
          replacementKey: "Resources",
          values: [
            {
              id: 1,
              isActive: true,
              field: "DelegationClause",
              display: "You are notified that the following actions",
              overrideResources: null
            }
          ]
        }
      ]
    }
  ],
  copyInfo: []
};

export default template;