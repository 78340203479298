import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper"
import * as inspectionDefectCrud from '../../../../crud/inspectionDefect.crud';
import { notificationAggregateListTagsFilter } from "../../../../store/helpers/FilterHelper";
import axios from 'axios';
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

class RfiListAll extends React.Component {
    constructor(props) {
        super(props);
        this.gridRef = React.createRef();
        this.maxReferenceIDPrefix = "";
        if (this.props.abbreviationWF1) {
            this.maxReferenceIDPrefix = this.props.abbreviationWF1;
        }
        if (this.props.abbreviationWF2 && this.props.abbreviationWF1.length < this.props.abbreviationWF2.length) {
            this.maxReferenceIDPrefix = this.props.abbreviationWF2;
        }

        this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.notificationsRelsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';

        this.state = {
            notifications: null
        };
    }

    componentDidMount() {
        this.fetchNotifications();
    }

    fetchNotifications = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;

            const promises = [];
            promises.push(inspectionDefectCrud.getContractInspections(companyid, projectid, contractid, "RFI", this.props.accessibleWfIds));
            promises.push(inspectionDefectCrud.getContractInspections(companyid, projectid, contractid, "RFIPM", this.props.accessibleWfIds));

            const responseObj = await axios.all(promises);
            let listData1 = notificationAggregateListTagsFilter(JSON.parse(responseObj[0].data.aggregatelist));
            listData1 = listData1.filter(ntf => ntf.showtasknotification === 1);

            let listData2 = notificationAggregateListTagsFilter(JSON.parse(responseObj[1].data.aggregatelist));
            listData2 = listData2.filter(ntf => ntf.showtasknotification === 1);

            const allItems = listData1.concat(listData2).sort((a, b) => a.sequencenumber - b.sequencenumber);

            this.setState({ notifications: allItems });
        } catch (error) {
            console.log(error);
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        let redirectURL = '';
        if (rowData.type === 'RFI') {
            redirectURL = this.props.workflowItem.paths.basePath.replace('master', 'supplier') + rowData.inspectiondefectid;
        }
        else if (rowData.type === 'RFIPM') {
            redirectURL = this.props.workflowItem.paths.basePath.replace('master', 'pm') + rowData.inspectiondefectid;
        }
        return (skipHostUrl ? '' : process.env.PUBLIC_URL) + redirectURL;
    }

    sortIdColumn = (rowData) => {
        return rowData.sequencenumber;
    }

    renderStatusColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        switch (cellInfo.data.replystatus) {
            case 'Awaiting Reply':
            case 'Awaiting Acceptance':
                badgeVariant = 'warning';
                break;
            case 'Not Communicated':
                badgeVariant = 'secondary';
                break;
            case 'Not Accepted':
                badgeVariant = 'danger';
                break;
            case 'Reply Received':
            case 'Accepted':
                badgeVariant = 'success';
                break;
            default:
                break;
        }
        const displayText = this.calculateStatusValue(cellInfo.data);
        return (<Badge variant={badgeVariant}>{displayText}</Badge>);
    }
    calculateStatusValue = (rowData) => {
        return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + rowData.replystatus });
    }



    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }
        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.SUBJECT", dataField: 'replyactiondetail' },
            { resource: "GENERAL.FORMS.FIELD.COMMUNICATED_DATE", dataField: "notificationdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.COMMUNICATED_BY", dataField: "createby" },
            { resource: "GENERAL.FORMS.FIELD.REPLY_REQUIRED", dataField: "inspectiondate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.REPLY_RECIEVED", dataField: "replydate", dataType: 'date', cellRender: this.replyDateColumn },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "replystatus", cellRender: this.renderStatusColumn, calculateCellValue: this.calculateStatusValue },
            { resource: "GENERAL.FORMS.FIELD.REPLIED_BY", dataField: "inspectionby", visible: false },
            { resource: "GENERAL.FORMS.FIELD.REPLY_DRAFT_BY", dataField: "draftreplyby", visible: false },
            { resource: "GENERAL.FORMS.FIELD.DETAILS", dataField: "inspectiondetails", isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.REPLY_DETAILS", dataField: "replycomment", isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false },
            { resource: "GENERAL.FORMS.REPLING_PARTY", dataField: "replyingparty", lookup: { dataSource: this.props.contractTemplate.parties.options, valueExpr: 'id', displayExpr: 'display' }, visible: false }
        ];
        if (this.notificationsTagsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag' },
                { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag' },
                { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag' },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag' }
            )
        }
        if (this.notificationsRelsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.TAB_RELATIONS", dataField: "relatednotices", visible: false },
            )
        }

        return (
            <Portlet>
                <PortletBody >
                    <DataGridBase
                        version="1"
                        title={this.props.title}
                        gridRef={this.gridRef}
                        referenceIDPrefix={this.maxReferenceIDPrefix}
                        name={this.props.intl.formatMessage({ id: 'CONTRACT.WF.RFI.TITLE.REGISTER' })}
                        dataSource={this.state.notifications}
                        keyExpr="inspectiondefectid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                    />
                </PortletBody>
            </Portlet >
        );
    }
}




export default withListPageProps(RfiListAll);
