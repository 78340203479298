import React from 'react';
import { connect } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material"
import { Portlet, PortletBody, PortletHeader } from "../../../partials/content/Portlet";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";
import { TableCell } from "@mui/material";
import PortletSpinner from '../../../partials/layout/PortletSpinner';
import TableArea from './widgets/TableArea'
import WarningArea from './widgets/WarningArea'
import Common from '../../../shared/common';
import TableActionItems from './widgets/TableActionItems';
import NotifyArea from './widgets/NotifyArea';
import QuotesArea from './widgets/QuotesArea';
import CompensationArea from './widgets/CompensationArea';
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";
import * as earlyWarningCrud from '../../../crud/earlyWarning.crud';
import * as compensationEventCrud from '../../../crud/compensationEvent.crud';
import { withSnackbar } from 'notistack';

const theme = createTheme({
    typography: { fontSize: 20 },
});

class ContractHealthReport extends React.Component {
    constructor(props) {
        super(props);
        this.printDivRef = React.createRef();

        this.ceAbbreviationSupplier = 'CE-';
        this.ceAbbreviationPm = 'CE-';
        const ce_WorkflowGroup = props.contractTemplate.workflowGroups.find(wfg => wfg.id === 103);
        if (ce_WorkflowGroup) {
            if (ce_WorkflowGroup.workflows && ce_WorkflowGroup.workflows.length > 0) {
                const workflowSupplier = ce_WorkflowGroup.workflows.find(wf => wf.id === 7);
                const workflowPm = ce_WorkflowGroup.workflows.find(wf => wf.id === 5);
                if (workflowSupplier) {
                    this.ceAbbreviationSupplier = workflowSupplier.abbreviation;
                }
                if (workflowPm) {
                    this.ceAbbreviationPm = workflowPm.abbreviation;
                }
            }
        }

        this.state = {
            earlyWarningsList: null,
            compensationsList: null,
            partyItems: null,
            priceItems: null,
            dateItems: null,
        }
    }
    componentDidMount() {
        this.setItems();
        this.fetchEarlyWarnings();
        this.fetchCompensations();
    }
    fetchEarlyWarnings = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data: responseObj } = await earlyWarningCrud.getEarlyWarningsReduced(companyid, projectid, contractid, null, null)
            this.setState({ earlyWarningsList: responseObj.aggregatelist });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }
    fetchCompensations = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data: responseObj } = await compensationEventCrud.getCompansationEventsReduced(companyid, projectid, contractid, null, null);
            this.setState({ compensationsList: responseObj.aggregatelist });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.contractTemplate !== this.props.contractTemplate ||
            prevProps.contract !== this.props.contract ||
            prevProps.contractDashboard !== this.props.contractDashboard) {
            this.setItems();
        }
        if (prevProps.contract !== this.props.contract) {
            this.fetchEarlyWarnings();
        }
    }
    setItems = () => {
        if (this.props.contractTemplate && this.props.contract && this.props.contractDashboard) {
            const partyItems = this.getPartyItems();
            const priceItems = this.getPriceItems();
            const dateItems = this.getDateItems();
            this.setState({ partyItems: partyItems, priceItems: priceItems, dateItems: dateItems });
        }
    }
    getPartyItems = () => {
        const { resources } = this.props.contractTemplate;
        const { pm, supplier, supervisor, employer } = (this.props.contract.designatedUsers || {});
        const partyItems = { header: [], row: [] };

        if (pm) {
            partyItems.header.push(<TableCell align="center" key="pm">{resources.PM_NAME}</TableCell>);
            partyItems.row.push(<TableCell align="center" key="pm">{pm.user}</TableCell>);
        }
        if (supplier) {
            partyItems.header.push(<TableCell align="center" key="supplier">{resources.SUPPLIER_NAME}</TableCell>);
            partyItems.row.push(<TableCell align="center" key="supplier">{supplier.user}</TableCell>);
        }
        if (this.props.contractTemplate.parties.supervisorExist && supervisor) {
            partyItems.header.push(<TableCell align="center" key="supervisor">{resources.SUPERVISOR_NAME}</TableCell>);
            partyItems.row.push(<TableCell align="center" key="supervisor">{supervisor.user}</TableCell>);
        }
        if (this.props.contractTemplate.parties.clientExist && employer) {
            partyItems.header.push(<TableCell align="center" key="employer">{resources.CLIENT_NAME}</TableCell>);
            partyItems.row.push(<TableCell align="center" key="employer">{employer.user}</TableCell>);
        }
        return partyItems;
    }
    getPriceItems = () => {
        const priceItems = { header: [], row: [] };
        const { currentcontractvalue: currentPrice, contractvalue: initialPrice } = this.props.contract;
        const priceChange = currentPrice - initialPrice;
        const percent = priceChange / initialPrice * 100;
        const priceChangePrefix = priceChange > 0 ? '+' : '';
        const percentStr = ` ${priceChangePrefix}(${percent.toLocaleString('en-GB', { currency: "GBP", minimumFractionDigits: 2, maximumFractionDigits: 2 })}%)`;

        priceItems.header.push(<TableCell align="center" key="initialPrice"><FormattedMessage id="CONTRACT.HEALTH.TOTAL_PRICES" /></TableCell>);
        priceItems.row.push(<TableCell align="center" key="initialPrice">{Common.FormatCurrencyNum(initialPrice, this.props.contract.currency)}</TableCell>);

        priceItems.header.push(<TableCell align="center" key="currentPrice"><FormattedMessage id="CONTRACT.HEALTH.TENDERED_PRICES" /></TableCell>);
        priceItems.row.push(<TableCell align="center" key="currentPrice">{Common.FormatCurrencyNum(currentPrice, this.props.contract.currency)}</TableCell>);

        priceItems.header.push(<TableCell align="center" key="priceChange"><FormattedMessage id="CONTRACT.HEALTH.INCREASE_PRICES" /></TableCell>);
        priceItems.row.push(<TableCell align="center" key="priceChange">{Common.FormatCurrencyNum(priceChange, this.props.contract.currency)}<strong>{percentStr}</strong></TableCell>);

        return priceItems;
    }
    getDateItems = () => {
        const dateItems = { header: [], row: [] };
        const { startdate: startDate, completedate: originalEndDate, completiondate: currentEndDate, expectedcompletiondate: plannedEndDate } = this.props.contract;
        const { resources } = this.props.contractTemplate;
        dateItems.header.push(<TableCell align="center" key="startDate"><FormattedMessage id="CONTRACT.DASHBOARD.START_DATE" /></TableCell>);
        dateItems.row.push(<TableCell align="center" key="startDate">{moment(startDate).format('L')}</TableCell>);

        dateItems.header.push(<TableCell align="center" key="originalEndDate"><FormattedMessage id="CONTRACT.DASHBOARD.ORIGINAL_COMPLETION" values={{ COMPLETION_DATE: resources.COMPLETION_DATE }} /></TableCell>);
        dateItems.row.push(<TableCell align="center" key="originalEndDate">{moment(originalEndDate).format('L')}</TableCell>);

        dateItems.header.push(<TableCell align="center" key="currentEndDate"><FormattedMessage id="CONTRACT.DASHBOARD.CURRENT_COMPLETION" values={{ COMPLETION_DATE: resources.COMPLETION_DATE }} /></TableCell>);
        dateItems.row.push(<TableCell align="center" key="currentEndDate">{moment(currentEndDate).format('L')}</TableCell>);

        dateItems.header.push(<TableCell align="center" key="plannedEndDate"><FormattedMessage id="CONTRACT.HEALTH.PLANNED_COMPLETION" values={{ COMPLETION_DATE: resources.COMPLETION_DATE }} /></TableCell>);
        dateItems.row.push(<TableCell align="center" key="plannedEndDate">{moment(plannedEndDate).format('L')}</TableCell>);

        return dateItems;
    }
    printHandler = () => {
        this.printerRef.handlePrint();
    }
    renderToolbar = () => {
        return (
            <div style={{ display: 'flex', flexFlow: 'row-reverse', margin: "20px 0px" }}>
                <Button onClick={this.printHandler} variant="primary" size="sm">
                    <i className="fas fa-print"></i>
                    <FormattedMessage id="CONTRACT.DASHBOARD.PRINT_REPORT" />
                </Button>
            </div>
        );
    }
    render() {
        const { sidePanel, contractDashboard, contract, contractTemplate } = this.props;
        if (!this.state.partyItems || !this.state.priceItems || !this.state.earlyWarningsList || !this.state.compensationsList) {
            return (<PortletSpinner />);
        }

        const { resources } = contractTemplate;


        return (
            <Portlet ref={this.printDivRef} >
                <PortletHeader
                    title={`${this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.TITLE' })} - ${contract.name} - ${moment().format('L')}`}
                    toolbar={this.renderToolbar()} />
                <ReactToPrint
                    ref={el => (this.printerRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printDivRef.current}
                />
                <PortletBody style={{ maxWidth: 1200 }}>
                    <ThemeProvider theme={theme}>
                        <TableArea title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.PARTIES' })} items={this.state.partyItems} />
                        <TableArea title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.PRICES' })} items={this.state.priceItems} />
                        <TableArea title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.DATES' })} items={this.state.dateItems} />
                        <TableActionItems />
                        {/* <ChartArea dashboard={contractDashboard} resources={resources} /> */}
                        <NotifyArea
                            title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.OUTSTANDING_PARTY' }, { PARTY: resources.SUPPLIER_NAME })}
                            items={sidePanel.supplierItems}
                            contractDashboard={contractDashboard}
                            contractid={contract.contractid}
                        />
                        <NotifyArea
                            title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.OUTSTANDING_PARTY' }, { PARTY: resources.PM_NAME })}
                            items={sidePanel.pmItems}
                            contractDashboard={contractDashboard}
                            contractid={contract.contractid}
                        />
                        {
                            contractTemplate.parties.supervisorExist && (
                                <NotifyArea
                                    title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.OUTSTANDING_PARTY' }, { PARTY: resources.SUPERVISOR_NAME })}
                                    items={sidePanel.supervisorItems}
                                    contractDashboard={contractDashboard}
                                    contractid={contract.contractid}
                                />
                            )
                        }
                        <WarningArea
                            earlyWarningsList={this.state.earlyWarningsList}
                            title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.HIGH_RISKS' })}
                            priority="High"
                            headerFontClass="kt-font-danger"
                        />
                        <WarningArea
                            earlyWarningsList={this.state.earlyWarningsList}
                            title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.MEDIUM_RISKS' })}
                            priority="Medium"
                            headerFontClass="kt-font-warning"
                        />
                        <WarningArea
                            earlyWarningsList={this.state.earlyWarningsList}
                            title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.LOW_RISKS' })}
                            priority="Low"
                        />
                        <QuotesArea />
                        <CompensationArea
                            compensationsList={this.state.compensationsList}
                            title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.COMPENSATIONS_BY_PARTY' }, { PARTY: resources.PM_NAME })}
                            notificationType="PM"
                            abbreviation={this.ceAbbreviationPm}
                        />
                        <CompensationArea
                            compensationsList={this.state.compensationsList}
                            title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.COMPENSATIONS_BY_PARTY' }, { PARTY: resources.SUPPLIER_NAME })}
                            notificationType="CON"
                            abbreviation={this.ceAbbreviationSupplier}
                        />
                        <CompensationArea
                            compensationsList={this.state.compensationsList}
                            title={this.props.intl.formatMessage({ id: 'CONTRACT.HEALTH.INITIALLY_PROPOSED' })}
                            notificationType="PRO"
                            abbreviation={"CE-"}
                        />
                    </ThemeProvider>
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    contractDashboard: store.contract.dashboard,
    contractTemplate: store.contract.template,
    sidePanel: store.contract.sidePanel,
});


export default withSnackbar(injectIntl(connect(mapStateToProps)(ContractHealthReport)));