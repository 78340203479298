import React, { Component } from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import * as contractsCrud from '../../../../crud/contracts.crud';
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from '../../../../shared/hoc/withRouter';
import {  SimpleItem, RequiredRule, PatternRule } from 'devextreme-react/form'
import DataGrid, { Paging, SearchPanel, Export, ColumnChooser, ColumnFixing, Editing,  Form, Toolbar, Item as ToolbarItem, } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

class ContractEmailRecipients extends Component {

    state = {
        emailRecipients: null,
        emailRecipient: null,
        showModal: false,
    }

    componentDidMount() {
        this.fetchContractEmailRecipients();
    }

    //adding a row
    onRowInserting = async (e) => {
        const { companyid, projectid, contractid } = this.props.contract;
        const regexEmailValue = e.data.emailaddress

        const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        if (regexEmail.test(regexEmailValue)) {

            const emailRecipient = { ...e.data, companyid, projectid, contractid, };
            // this.setState({ emailRecipient }, () => this.setState({ showModal: true }));
            e.cancel = this.saveContractEmailRecipient(emailRecipient, null, null, e);

        } else {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.EMAILRCP.ERROR' }), { variant: 'error', });
        }
    }

    saveContractEmailRecipient = (recipient, setSubmitting, clearForm, e) => {
        return new Promise(async (resolve, reject) => {

            recipient.contractid = this.props.contract.contractid;
            const newEmailAddress = recipient.emailaddress
            const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            try {

                if (regex.test(newEmailAddress)) {

                    const resp = await contractsCrud.saveContractEmailRecipient(recipient)
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.EMAILRCP.MESSAGE_SUCCESS' }), { variant: 'success', });
                    e.data.contractrecipientid = resp.data;
                    e.data.createdate = new Date();
                    e.data.lastediteddate = new Date();

                    resolve(false);
                }
                else {
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.EMAILRCP.ERROR' }), { variant: 'error', });
                }


            } catch (error) {
                console.log(error);
                reject(error)
            }

        })

    }



    fetchContractEmailRecipients = () => {
        contractsCrud.getContractEmailRecipients(this.props.params.contractid)
            .then(response => {
                const emailRecipients = JSON.parse(response.data);
                this.setState({ emailRecipients });
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    //editing a row
    onRowUpdating = async (e) => {
        const { companyid, projectid, contractid } = this.props.contract;
        const newObject = { ...e.oldData, ...e.newData, companyid, projectid, contractid };
        e.cancel = this.updateEmailRecipient(newObject);

    }
    updateEmailRecipient = (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await contractsCrud.saveContractEmailRecipient(data);
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        });
    }

    //deleting row
    onRowRemoving = (e) => {
        const { companyid, projectid, contractid } = this.props.contract;
        const deleteObj = { ...e.data, companyid, projectid, contractid };
        deleteObj.deleted = 1;
        e.cancel = this.deleteEmailRecipient(deleteObj);
    }

    deleteEmailRecipient = (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await contractsCrud.saveContractEmailRecipient(data);
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        });
    }



    onModalResponse = (response) => {
        if (response !== undefined) {
            const emailRecipient = { ...this.state.emailRecipient, ...response };
            this.saveContractEmailRecipient(emailRecipient, null, null);
        }

        this.setState({ showModal: false });
    };

    onInitNewRow = async (e) => {
        e.data = { deleted: 0 };
    }
    onExporting = (e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
          component: e.component,
          worksheet: worksheet,
        }).then(function () {
          workbook.xlsx.writeBuffer()
            .then(function (buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'EmailRecipients.xlsx');
            });
        });
      }
    render() {
        if (!this.state.emailRecipients) {
            return (<PortletSpinner />);
        }

        return (
            <Portlet>

                <PortletBody>

                    <DataGrid
                        dataSource={this.state.emailRecipients}
                        keyExpr="contractrecipientid"
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        remoteOperations={false}
                        allowColumnReordering={true}
                        columnResizingMode='widget'
                        onRowUpdating={this.onRowUpdating}
                        onInitNewRow={this.onInitNewRow}
                        onRowInserting={this.onRowInserting}
                        noDataText={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.NO_DATA' })}
                        onRowRemoving={ this.onRowRemoving }
                        style={{ maxHeight: '67vh', minHeight: "50vh" }}
                        onExporting={this.onExporting}
                        columns={[
                            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAILRCP.FIELD.ID' }), dataField: "contractrecipientid" },
                            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAILRCP.FIELD.EMAIL' }), dataField: "emailaddress" },
                            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAILRCP.FIELD.CREATE_DATE' }), dataField: "createdate", dataType: "date", format: (date) => moment(date).format('DD/MM/YYYY') },
                            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.EMAILRCP.FIELD.EDITED_DATE' }), dataField: "lastediteddate", dataType: "date", format: (date) => moment(date).format('DD/MM/YYYY') },
                        ]}

                    >
                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            allowDeleting={true}
                            allowAdding={true}
                            confirmDelete={true}

                            popup={{ title: this.props.intl.formatMessage({ id: "CONTRACT.USERS.EMAIL" }), height: 380, width: 600, showTitle: true }}
                            useIcons={true}
                        >

                            <Form>
                                <Item itemType="group" colSpan={2}>
                                    <SimpleItem dataField="emailaddress" >
                                        <RequiredRule message={this.props.intl.formatMessage({ id: "GENERAL.EMAIL.FORMAT" })} />
                                        <PatternRule
                                            pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                                            message={this.props.intl.formatMessage({ id: "GENERAL.EMAIL.FORMAT" })}
                                        />
                                    </SimpleItem>

                                </Item>
                            </Form>

                        </Editing>
                        <Toolbar>
                            <ToolbarItem location="before">
                                <div>
                                    <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">
                                        <FormattedMessage id="CONTRACT.EMAILRCP.TITLE" />
                                    </h3>
                                </div>
                            </ToolbarItem>
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="addRowButton"
                                showText="always"
                                options={{
                                    icon: "plus",
                                    text: this.props.intl.formatMessage({ id: "CONTRACT.USERS.EMAIL" }),
                                }}
                            />

                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="exportButton"
                            />
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="columnChooserButton"
                            />
                            <ToolbarItem location="after"
                                locateInMenu="auto"
                                name="searchPanel"
                            />
                        </Toolbar>



                        <ColumnChooser enabled={true} />
                        <ColumnFixing enabled={true} />
                        <Export enabled={true} fileName={this.props.name} />
                        <SearchPanel visible={true} highlightCaseSensitive={false} defaultText="" />

                        <Paging pageSize={10} defaultPageSize={10} defaultPageIndex={0} />

                    </DataGrid>


                </PortletBody>

            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    userProfile: store.auth.userProfile
});

export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps)(ContractEmailRecipients))));