import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PaymentList from "./List";
import PaymentCreate from "./Create";
import PaymentDetail from "./Detail";

class PaymentRouter extends React.Component {
  constructor(props) {
    super(props);
    try {
      this.taxEnabled = props.placeholdersQA.TaxEnabled
                        .values.find(phItem => phItem.field === 'TaxEnabled').isActive;
    } catch (error) {
      console.log("TaxEnabled placeholder not available!");
      this.taxEnabled = true;
    }

    try {
      this.mandateAttachment = props.placeholdersQA.MandateAttachment
                        .values.find(phItem => phItem.field === 'MandateAttachment').isActive;
    } catch (error) {
      console.log("MandateAttachment placeholder not available!");
      this.mandateAttachment = false;
    }

    try {
      this.poNumberEnabled = props.placeholdersQA.PONumberEnabled
                        .values.find(phItem => phItem.field === 'PONumberEnabled').isActive;
    } catch (error) {
      console.log("PONumberEnabled placeholder not available!");
      this.poNumberEnabled = false;
    }

    let configurableTypeFields = null;

    if (props.mode === "SUPPLIER") {
      this.communicateToUser = props.globalResources.PM_NAME;
      this.notificationType = "Application";
      this.documentType = "DocumentApplication";

      const applicationTypesPlaceholder = props.placeholders.find(ph => ph.replacementKey === 'ApplicationTypes');

      if (applicationTypesPlaceholder) {
        configurableTypeFields = applicationTypesPlaceholder.values.filter(item => item.isActive === true).map(x => ({ value: x.field, display: x.display }));
      }
      
    } else if (props.mode === "PM") {
      this.communicateToUser = props.globalResources.SUPPLIER_NAME;
      this.notificationType = "Certificate";
      this.documentType = "DocumentCertificate";

      const paymentTypesPlaceholder = props.placeholders.find(ph => ph.replacementKey === 'PaymentTypes');

      if (paymentTypesPlaceholder) {
        configurableTypeFields = paymentTypesPlaceholder.values.filter(item => item.isActive === true).map(x => ({ value: x.field, display: x.display }));
      }

    }
    
    this.notifyingParty = props.workflowItem.notifyingParty;

    this.notificationName = props.workflowItem.notificationName;
    this.abbreviation = props.workflowItem.abbreviation;

    this.configurableTypeFields = configurableTypeFields;
  }
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { workflowItem } = this.props;

    return (
      <>
        <Routes caseSensitive>
          <Route
            path={workflowItem.config.paths.list}
            exact
            element={
              <PaymentList
                {...this.props}
                taxEnabled={this.taxEnabled}
                
                title={this.notificationName}
                abbreviation={this.abbreviation}
                poNumberEnabled={this.poNumberEnabled}
                configurableTypeFields={this.configurableTypeFields}
                notificationType={this.notificationType}
                documentType={this.documentType}
              />
            }
          />
          <Route
              path={workflowItem.config.paths.create}
              exact
              element={
                  workflowItem.userFilters.mainNotice.createAllowed ? (
                      <PaymentCreate
                        {...this.props}
                        taxEnabled={this.taxEnabled}
                        poNumberEnabled={this.poNumberEnabled}
                        configurableTypeFields={this.configurableTypeFields}
                        
                        notificationType={this.notificationType}
                        title={this.notificationName}
                        communicateToUser={this.communicateToUser}
                      />) : (
                      <Navigate replace to={workflowItem.paths.defaultPath} />
                  )
              }
          />
          <Route
            path={'/:notificationid/*'}
            exact
            element={
              <PaymentDetail
                {...this.props}
                taxEnabled={this.taxEnabled}
                mandateAttachment={this.mandateAttachment}
                poNumberEnabled={this.poNumberEnabled}
                configurableTypeFields={this.configurableTypeFields}
                
                title={this.notificationName}
                abbreviation={this.abbreviation}
                documentType={this.documentType}
                communicateToUser={this.communicateToUser}
                notificationType={this.notificationType}
                notifyUserFilter={workflowItem.notifyUserFilter}
              />
            }
          />
        </Routes>
      </>
    );
  }
}


export default (PaymentRouter);
