import { userFilterCategories } from "../../../shared/lookup";
const workflows = [
    {
        id: 39,
        title: 'Documents',
        notificationName: 'Document',
        abbreviation: 'Documents-',
        actioningParty: "PM",
        config: {
            description: 'Documents',
            currentModel: 'Documents',
            currentModelType: 'Documents',
            referenceType: 'Document',

            showInRelations: true,
            showInCustomFields: false,
            replyPossible: false,



            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.ACTION_TAB
            ],
            paths: {                
                base: 'documents',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 44,
        title: 'Project Bank Accounts',
        notificationName: 'Project Bank Account',
        abbreviation: 'BankAccount-',
        actioningParty: "PM",
        config: {
            description: 'Project Bank Accounts',
            currentModel: 'BankAccount',
            currentModelType: '',
            referenceType: 'BankAccount',

            showInRelations: false,
            showInCustomFields: false,
            replyPossible: false,

            

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.ACTION_TAB
            ],
            paths: {
                base: 'bankaccount',
                list: 'list',
                default: 'list'
            }
        }
    },

]

export default workflows;