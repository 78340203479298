import React from 'react';
import * as fileCrud from '../../../../crud/file.crud';
import Document from '../../_CustomComponents/DocumentV2';
import WarningCustomForm from './CustomForm';
import BaseDocument from '../../_CustomComponents/BaseDocument';

import { withSnackbar } from 'notistack';
class WarningDocumentMain extends BaseDocument {
    
    renderChackedRiskItems = () => {
        const items = this.props.riskTypes.filter(itm => (this.props.notification[itm.field] && this.props.notification[itm.field] === 1));
        return (
            <ul style={{ listStyleType: 'none', paddingInlineStart: 0 }}>
                {items.map((item, index) => (
                    <li key={index}>
                        <i className="fas fa-chevron-right"></i>
                        <span style={{ paddingLeft: 5 }}>{item.display}</span>
                    </li>
                ))}
            </ul>
        );
    }


    renderForm = () => {

        return (
            <WarningCustomForm
                {...this.props}
                item={this.props.notification}
                valueChangeHandler={this.props.fieldChanged}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
            />
        );

    }
    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.warningid, data, config)
    }


    render() {
        const documentIsFinal = this.props.notification.showtasknotification === 1;
        return (
            <Document
                key={documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.renderForm()}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                customFieldValues={this.props.notification.customfieldvalues}
                // asset={this.props.asset}
                assetid={this.props.notification.assetid}
            />
        );
    }
}

export default withSnackbar(WarningDocumentMain);