/* eslint-disable eqeqeq */
import React from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import * as companiesCrud from '../../../crud/companies.crud';
import * as companiesDuck from '../../../store/ducks/companies.duck';
import BiDynamicForm from "../../../partials/layout/BiDynamicForm";
import { Portlet, PortletBody, PortletHeader } from "../../../partials/content/Portlet";
import { companyTypes, countries, companyTagCategories } from '../../../shared/lookup';
import { injectIntl } from "react-intl";
import { withRouter } from '../../../shared/hoc/withRouter';
import * as tagCrud from '../../../crud/tag.crud';
import { confirm } from 'devextreme/ui/dialog';

class CompanyNew extends React.Component {
    constructor(props) {
        super(props);
        this.companyTagsEnabled = process.env.REACT_APP_CONTRACT_COMPANY_TAGS_ENABLED === 'true';
        this.companyTypes = companyTypes.map(ty => ({ value: ty.value, display: this.props.intl.formatMessage({ id: "OPTIONS.COMPANY_TYPE." + ty.value }) }));

        this.newCompanyFormConfig = {
            buttonText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CREATE" }),
            fields: [
                { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_NAME" }), dataField: 'name' },
                { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_TYPE" }), dataField: 'companytype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.companyTypes, valueExpr: 'value', displayExpr: 'display' } },
                { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_ADDRESS1" }), dataField: 'address1', },
                { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_ADDRESS2" }), dataField: 'address2', required: false },
                { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_CITY" }), dataField: 'city', },
                { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_COUNTY" }), dataField: 'state', },
                { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_COUNTRY" }), dataField: 'country', editorType: 'dxSelectBox', editorOptions: { dataSource: countries, defaultValue: 'United Kingdom' } },
                { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_POSTCODE" }), dataField: 'postcode' },
                { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_ENABLECOMPANYUSERS" }), dataField: 'enablecompanyusers', editorType: 'dxSwitch', editorOptions: { switchedOffText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }), defaultValue: true, readOnly: !props.protectedRouteProps.allowEnablingCompanyUsers } },
                { label: this.props.intl.formatMessage({ id: "COMPANY.FIELD.COMPANY_ADDCOMPANYUSERSTO" }), dataField: 'adduserstocompanyid', required: false, editorType: 'dxSelectBox', editorOptions: { dataSource: this.fetchCompanies(), valueExpr: 'companyid', displayExpr: 'name', readOnly: !props.protectedRouteProps.allowEnablingCompanyUsers }, visible: (obj) => !obj.enablecompanyusers }
            ]
        };

        if (this.companyTagsEnabled) {
            this.newCompanyFormConfig.fields.push(
                { resource: 'CONTRACT.FIELD.FRAMEWORKS', dataField: 'frameworkids', required: false, editorType: 'dxTagBox', editorOptions: { dataSource: this.setTags(0), valueExpr: 'tagid', displayExpr: 'name' } }
            );
        };

        this.newCompanyFormConfig.fields.push(
            { resource: 'COMPANY.FIELD.COMPANY_NUMBER', dataField: 'companynumber', required: false }
        );

        this.state = {
            companies: []
        }
    }
    fetchCompanies = () => {
        const datasource = {
            loadMode: 'raw',
            cacheRawData: true,
            load: () => {
                return new Promise((resolve) => {
                    companiesCrud.getCompanies()
                        .then(response => {
                            const filteredCompanies = JSON.parse(response.data.aggregatelist).filter(c => c.archived !== 1 && c.enablecompanyusers != 0 && c.companyid != this.props.params.companyid);
                            this.setState({ companies: filteredCompanies }, () => {
                                resolve(filteredCompanies);
                            });
                        })
                        .catch(error => {
                            console.error("Error fetching companies:", error);
                            resolve([]);
                        });
                });
            }
        };
    
        return datasource;
    }
    setTags = (companyid) => {
        const datasource = {
            loadMode: 'raw',
            cacheRawData: true,
            load: () => {
                var promise = new Promise((resolve) => {
                    tagCrud.GetTagsForCategory(companyTagCategories, companyid)
                        .then(response => {
                          const tags = response.data;
                          resolve(tags);
                        });
                });
                return promise;
            }
        }

        return datasource;
    }
    saveCompanySubmit = async (company, setSubmitting) => {
        if (company.companynumber) {
            const companyNumberResponse = await companiesCrud.checkIfCompanyNumberExists(company.companynumber);

            if (companyNumberResponse.data) {
                const userResponse = await this.confirmCompanyNumber();

                if (!userResponse) {
                    setSubmitting(false);
                    return; //cancelled
                }
            }
        }

        this.submitHandler(company);
    }
    submitHandler = async (company, setSubmitting) => {
        const companyConfirmed = await this.confirmCompanyAssignment(company);
        if (!companyConfirmed) {
            //if not confirmed exit save
            setSubmitting(false);
            return;
        }
        if (company.enablecompanyusers) {
            company.adduserstocompanyid = null;
        }
        companiesCrud.saveCompany(company)
            .then(response => {
                this.props.enqueueSnackbar('Company created successfully', { variant: 'success', });
                this.props.companiesDuckSetPending();
                try {
                    const createdID = Number(response.data);
                    this.props.navigate('/admin/companies/' + createdID + '/overview');
                }
                catch {
                    this.props.navigate('/admin/companies');
                }
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                setSubmitting(false);
            });
    }
    confirmCompanyAssignment = async (company) => {
        if (!company.enablecompanyusers && company.adduserstocompanyid) {
            const companyName = this.state.companies.find(c => c.companyid == company.adduserstocompanyid).name;
            const warningMessage = this.props.intl.formatMessage({ id: 'COMPANY.WARNING.ENABLE_COMPANY_USERS' }, { COMPANY_NAME: companyName });
            const warningMessageLabel = this.props.intl.formatMessage({ id: 'COMPANY.WARNING.ADDING_COMPANY_USERS_TO_ANOTHER_COMPANY' });
            let result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageLabel}`);
            return result;
        }
        
        return true;
    }
    confirmCompanyNumber = async () => {
        const warningMessage = this.props.intl.formatMessage({ id: 'COMPANY.WARNING.COMPANY_NUMBER_EXISTS' });
        const warningMessageLabel = this.props.intl.formatMessage({ id: 'COMPANY.WARNING.COMPANY_NUMBER_CONFIRMATION' });
        let result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageLabel}`);
        
        return result;
    }
    render() {
        const { intl } = this.props;

        return (
            <Portlet>
                <PortletHeader title={intl.formatMessage({ id: "COMPANY.CREATE.TITLE" })} />
                <PortletBody style={{ maxWidth: 800 }}>
                    <BiDynamicForm
                        config={this.newCompanyFormConfig}
                        onSubmitHandler={this.saveCompanySubmit}
                        valueChangeHandler={this.onValueChanged}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = (store) => ({
    companies: store.companies.companies
});

const mapDispatchToProps = {
    companiesDuckSetPending: companiesDuck.actions.setPending,
    loadCompanies: companiesDuck.actions.fetchStart
}

export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CompanyNew))));
