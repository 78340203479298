/* eslint-disable no-useless-escape */
import moment from 'moment';
import saveAs from 'file-saver';
import { custom as CustomDialog } from 'devextreme/ui/dialog';
const manuelActionsEnabled = process.env.REACT_APP_MANUAL_ACTIONS_ENABLED === 'true';
export default class Common {

    static GetPartyDisplay = (userParty, globalResources) => {
        switch (userParty) {
            case 'Contractor':
                return globalResources.SUPPLIER_NAME;
            case 'PM':
                return globalResources.PM_NAME;
            case 'Supervisor':
                return globalResources.SUPERVISOR_NAME;
            case 'Client':
                return globalResources.CLIENT_NAME;
            default:
                return '';
        }
    }
    static GroupBy = (items, key) => items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );
    static GetPartyOrUserCompanyID = (contract, partyOrUserPreference, wfActioningParty, userProfile) => {
        try {
            if (!partyOrUserPreference) {
                throw new Error("GetPartyOrUserCompanyID-> Unsupported partyOrUserPreference!");
            }

            if (partyOrUserPreference === 'USER') {
                return userProfile.companyid;
            } else {
                switch (wfActioningParty) {
                    case "PM":
                        if (contract.pmcompanyid) {
                            return contract.pmcompanyid;
                        }
                        else {
                            throw new Error("Contract PM is not defined!");
                        }

                    case "SUPPLIER":
                        if (contract.contractorcompanyid) {
                            return contract.contractorcompanyid;
                        }
                        else {
                            throw new Error("Contract SUPPLIER is not defined!");
                        }

                    case "SUPERVISOR":
                        if (contract.supervisorcompanyid) {
                            return contract.supervisorcompanyid;
                        }
                        else {
                            throw new Error("Contract SUPERVISOR is not defined!");
                        }
                    case "CLIENT":
                        if (contract.employercompanyid) {
                            return contract.employercompanyid;
                        }
                        else {
                            throw new Error("Contract CLIENT is not defined!");
                        }
                    default:
                        throw new Error("GetPartyOrUserCompanyID-> Unsupported wfActioningParty!");
                }
            }
        }
        catch (err) {
            console.log('Error in GetPartyOrUserCompanyID', err)
            throw err;
        }

    }
    static GetPartyOrUserCompanyName = (contract, partyOrUserPreference, wfActioningParty, userProfile) => {
        try {
            if (!partyOrUserPreference) {
                throw new Error("GetPartyOrUserCompanyName-> Unsupported partyOrUserPreference!");
            }

            if (partyOrUserPreference === 'USER') {
                return userProfile.company;
            } else {
                switch (wfActioningParty) {
                    case "PM":
                        return contract.pmcompanyname;
                    case "SUPPLIER":
                        return contract.contractorcompanyname;
                    case "SUPERVISOR":
                        return contract.supervisorcompanyname;
                    case "CLIENT":
                        return contract.employercompanyname;
                    default:
                        throw new Error("GetPartyOrUserCompanyName-> Unsupported wfActioningParty!");
                }
            }
        } catch (err) {
            console.log('Error in GetPartyOrUserCompanyName', err)
            throw err;
        }

    }
    static GetHashCode = (str) => {
        return str.split('').reduce((prevHash, currVal) =>
            (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
    }
    static downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType });
        const a = document.createElement('a')
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    }
    static FormatCurrencyNum = (num, currencyCode = 'GBP') => {
        let curCode = currencyCode ? currencyCode : 'GBP';
        let number = typeof num === 'number' ? num : Number(num);
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: curCode,
            currencyDisplay: 'narrowSymbol'
        }).format(number);

        // return currencySymbol + ' ' + number.toLocaleString('en-GB', { currency: "GBP", minimumFractionDigits: 2, maximumFractionDigits: 2 });//'£' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, ',')

    }
    static FormatNum = (num) => {
        let number = typeof num === 'number' ? num : Number(num);
        return new Intl.NumberFormat('en-GB', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(number);
    }
    static FormatDateStr(str) {
        return moment(str).format('L');
    }
    static FormatDateStrNullable(theDate) {
        if (theDate == null) {
            return null;
        } else {
            return moment(theDate).format('L');
        }
    }
    static GetDateNullable(theDate) {
        if (theDate == null) {
            return null;
        } else {
            return moment(theDate).toDate();
        }
    }
    static CapitalFirstLetter(str) {
        if (str) {
            try {
                return str.charAt(0).toUpperCase() + str.slice(1);
            } catch (error) {
                console.log("CapitalFirstLetter error", str);
                return str;
            }

        }
        else return "";
    }
    static CapitalFirstLetterOnWords(str) {
        if (str) {
            return str.replace(/^\w/, c => c.toUpperCase());
        }
        else return "";
    }
    static getFirstLetters(text) {
        const firstLetters = text
            .split(' ')
            .map(t => t[0])
            .join('');

        return firstLetters;
    }
    static getTextWidth(text, font) {
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        context.font = font;
        var metrics = context.measureText(text);
        return metrics.width;
    }

    static HtmlToString(htmlStr) {
        if (!htmlStr) {
            return htmlStr;
        }
        htmlStr = htmlStr.replace(/<style([\s\S]*?)<\/style>/gi, '');
        htmlStr = htmlStr.replace(/<script([\s\S]*?)<\/script>/gi, '');
        htmlStr = htmlStr.replace(/<\/div>/ig, '\n');
        htmlStr = htmlStr.replace(/<\/li>/ig, '\n');
        htmlStr = htmlStr.replace(/<li>/ig, '  •  ');
        htmlStr = htmlStr.replace(/&nbsp;/ig, ' ');
        htmlStr = htmlStr.replace(/<\/ul>/ig, '\n');
        htmlStr = htmlStr.replace(/<\/p>/ig, '\n');
        htmlStr = htmlStr.replace(/<br\s*[\/]?>/gi, "\n");
        htmlStr = htmlStr.replace(/<br>/ig, "\n");
        htmlStr = htmlStr.replace(/<[^>]+>/ig, '');
        if (htmlStr.length > 32000) {
            return htmlStr.substring(0, 32000);
        }
        else {
            return htmlStr;
        }
    }

    static HtmlToString2(htmlStr, prefix) {
        if (!prefix) {
            prefix = '';
        }
        const replaceList = [];
        let listIndexStart = htmlStr.indexOf('<ul>');
        while (listIndexStart > 0) {
            listIndexStart += 4;
            let listIndexEnd = 0;
            let closingTagIndexFound = false;
            let innerOpenTagCount = 0;
            let currentSearchIndex = listIndexStart;
            while (!closingTagIndexFound) {
                const nextTagIndex = htmlStr.indexOf('ul>', currentSearchIndex);
                // console.log('nexttag', htmlStr.substr(nextTagIndex - 1, 4), htmlStr.substr(nextTagIndex - 2, 5));
                if (htmlStr.substr(nextTagIndex - 1, 4) === '<ul>') {
                    innerOpenTagCount += 1;
                    currentSearchIndex = nextTagIndex + 3;
                }
                else if (htmlStr.substr(nextTagIndex - 2, 5) === '</ul>') {
                    if (innerOpenTagCount > 0) {
                        innerOpenTagCount -= 1;
                        currentSearchIndex = nextTagIndex + 3;
                    }
                    else {
                        closingTagIndexFound = true;
                        listIndexEnd = nextTagIndex - 2;
                    }
                }
            }
            replaceList.push({ startIndex: listIndexStart, endIndex: listIndexEnd });
            const listSection = htmlStr.substring(listIndexStart, listIndexEnd);



            // const listSection = Common.HtmlToString2(htmlStr.substring(listIndexStart + 4, listIndexEnd), '        ');
            htmlStr = htmlStr.substring(0, listIndexStart - 4) + Common.HtmlToString2(listSection, '-------') + htmlStr.substring(listIndexEnd + 5);
            listIndexStart = htmlStr.indexOf('<ul>');

        }

        if (htmlStr) {
            htmlStr = htmlStr.replace(/<\/div>/ig, '\n' + prefix);
            htmlStr = htmlStr.replace(/<\/li>/ig, '\n' + prefix);
            htmlStr = htmlStr.replace(/<li>/ig, '  •  ');
            htmlStr = htmlStr.replace(/<\/ul>/ig, '\n' + prefix);
            htmlStr = htmlStr.replace(/<\/p>/ig, '\n' + prefix);
            htmlStr = htmlStr.replace(/<br\s*[\/]?>/gi, "\n" + prefix);
            htmlStr = htmlStr.replace(/<[^>]+>/ig, '');
            return htmlStr;
        }
        return '';
    }


    static ReplaceResources(object, resources) {
        let stringifiedObject = JSON.stringify(object);
        resources.forEach(rsc => {
            if (rsc.type === 'TEXT') {
                stringifiedObject = stringifiedObject.replace(new RegExp('\\$' + rsc.key, 'g'), rsc.value);
            }
        });
        return JSON.parse(stringifiedObject);
    }

    static Lpad(n, width, z) {
        z = z || '0';
        if (n == null) { n = '0'; }
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }


    static getFilteredRoles(userProfile) {
        let roles = ['General User', 'View All Hub', 'View Own', 'View All'];

        if (userProfile.accounttype === 'User Administrator') {
            roles.push('User Administrator');
        } else if (userProfile.accounttype === 'Contract Administrator') {
            roles.push('User Administrator');
            roles.push('Contract Administrator');
        } else if (userProfile.accounttype === 'Super User') {
            roles.push('User Administrator');
            roles.push('Contract Administrator');
            roles.push('Super User');
        } else if (userProfile.accounttype === 'Site Support') {
            roles.push('User Administrator');
            roles.push('Contract Administrator');
            roles.push('Super User');
            roles.push('Site Support');
        } else if (userProfile.accounttype === 'Site Administrator') {
            roles.push('User Administrator');
            roles.push('Contract Administrator');
            roles.push('Super User');
            roles.push('Site Support');
            roles.push('Site Administrator');
        } else if (userProfile.accounttype === 'System Administrator') {
            roles.push('User Administrator');
            roles.push('Contract Administrator');
            roles.push('Super User');
            roles.push('Site Support');
            roles.push('Site Administrator');
            roles.push('System Administrator');
        } else if (userProfile.accounttype === 'System Owner') {
            roles.push('User Administrator');
            roles.push('Contract Administrator');
            roles.push('Super User');
            roles.push('Site Support');
            roles.push('Site Administrator');
            roles.push('System Administrator');
            roles.push('System Owner');
        }

        return roles;
    }

    static GetRelationCount(notification, accessibleWfIds) {
        let relationCount = 0;

        if (notification.relations) {
            relationCount = notification.relations.reduce((count, rel) => {
                if (accessibleWfIds.includes(rel.workflowid)) {
                    return count + 1;
                }
                return count;
            }, 0);
        }

        return relationCount;
    }

    static FormatHTML(link, linktext) {
        if (link) {
            //Null in the hyperlink should result in a blank field. Null in the display text
            //should result in the hyperlink being used as the display text.
            return `<a href="${link}">${linktext ? linktext : link}</a>`;
        } else {
            return '';
        }
    }

    static UnformatHTML(value) {
        if (value) {
            const link = value.split('"') ? value.split('"')[1] : '';
            const linktext = value.split('>') ? value.split('>')[1].substring(0, value.split('>')[1].indexOf('<')) : '';

            return { link: link, linktext: linktext };
        } else {
            return '';
        }
    }
    static ExportFile = async (title, dataArr) => {
        try {
            const notificationName = title;
            var byteCharacters = atob(dataArr);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            await saveAs(new Blob([byteArray], { type: 'application/octet-stream' }), notificationName + '.pdf');

        } catch (err) {
            console.log(err)
            throw err;
        }
    }


    static PropertyConversionsBeforeNoticeSaveRequest(notification, customFields, contractUsers) {
        try {

            ///CustomFields conversion
            const isCustomFieldsEnabled = process.env.REACT_APP_CUSTOM_FIELDS_ENABLED === "true";
            if (isCustomFieldsEnabled) {
                const cfs = [];
                let customFieldsExist = false;

                const types = ['COMMUNICATION', 'REPLY', 'QUOTE', 'QUOTE_REPLY', 'RFQQUOTE', 'RFQQUOTE_REPLY', 'EWREDUCTION', 'ITQQUOTE', 'ITQQUOTE_REPLY'];

                for (let field in notification) {
                    if (field.startsWith("CF_")) {
                        customFieldsExist = true;

                        const cfid = Number(field.substring(3));

                        //FIND CF DEFINITION
                        let CF_DEF = null;
                        for (const type of types) {
                            CF_DEF = customFields[type]?.find(cf => cf.customfieldid === cfid);
                            if (CF_DEF) { break; }
                        }

                        if (CF_DEF.fieldtype === 'SINGLE_CONTRACT_USER') {
                            if (!contractUsers) { throw new Error('PropertyConversionsBeforeNoticeSaveRequest->ContractUsers must be provided!') }
                            const { accountid: id, user: name } = contractUsers.find(u => u.accountid === notification[field]) || {};
                            const cfvalue = { id, name }; //converts the result to this format { id: 1, name: 'John' }
                            cfs.push({ customfieldid: cfid, value: JSON.stringify(cfvalue) });
                        } else if (CF_DEF.fieldtype === 'MULTIPLE_CONTRACT_USERS') {
                            if (!contractUsers) { throw new Error('PropertyConversionsBeforeNoticeSaveRequest->ContractUsers must be provided!') }
                            const cfvalue = notification[field].map(i => {
                                const { accountid: id, user: name } = contractUsers.find(u => u.accountid === i) || {};
                                return { id, name };
                            }); //prepares the result in this format [{ id: 1, name: 'John' }, { id: 2, name: 'Steve' }]
                            cfs.push({ customfieldid: cfid, value: JSON.stringify(cfvalue) });
                        }
                        else {
                            let cfvalue = notification[field];

                            if (Array.isArray(notification[field])) {
                                cfvalue = (notification[field]).join("|");
                            }

                            cfs.push({ customfieldid: cfid, value: cfvalue });
                        }

                        delete notification[field];
                    }
                }

                //Appends custom fields to notification
                if (customFieldsExist) {
                    notification.customfieldvalues = cfs;
                }
            }

            ///OtherDateChanges conversion
            if (notification.otherdatechanges) {
                notification.otherdatechanges = notification.otherdatechanges.map(dtChange => ({
                    activityreferenceid: dtChange.activityreferenceid,
                    name: dtChange.name,
                    currentdatedisplay: moment(dtChange.currentdate).format('DD/MM/YYYY'),
                    daychange: dtChange.daychange,
                    actiontype: dtChange.actiontype
                }))
            }

            ///OtherPriceChanges conversion
            if (notification.otherpricechanges) {
                notification.otherpricechanges = notification.otherpricechanges.map(prChange => ({
                    contractotherpriceid: prChange.contractotherpriceid,
                    name: prChange.name,
                    currency: prChange.currency,
                    currentprice: prChange.currentprice,
                    pricechange: prChange.pricechange
                }))
            }
            if (notification.createactions) {
                if (manuelActionsEnabled) {
                    notification.createactions = notification.createactions.map(({ actionType, ownerParty, daysForAction }) => ({
                        actionType,
                        ownerParty,
                        daysForAction
                    }));
                }
                else {
                    notification.createactions = null;
                }
            }
            if (notification.completeactions) {
                if (!manuelActionsEnabled) {
                    notification.completeactions = null;
                }
            }
            return notification;
        } catch (err) {
            console.log("Error in Common.PropertyConversionsBeforeNoticeSaveRequest");
            console.log(err);
            throw err;
        }
    }

    static setDateName = (item, startDateLabel, completionDateLabel) => {
        let name = item.name;

        if (item.sourcedmodel === 'TaskOrder' || item.sourcedmodel === 'ProjectOrder') {
            name = (item.originalreference || item.lastamendingreference);

            if (item.actiontype === 'TaskStartDate' || item.actiontype === 'ProjectStartDate') {
                name += ' ' + startDateLabel;
            } else { //Task/Project Completion Date
                name += ' ' + completionDateLabel;
            }
        }

        return name;
    }
}