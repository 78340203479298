import React from 'react';
import { connect } from "react-redux";
import * as fileCrud from '../../../../crud/file.crud';
import Document from '../../_CustomComponents/DocumentV2';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { Alert } from "react-bootstrap";
import { withSnackbar } from 'notistack';
import { paymentPeriods, calculationPeriods } from '../../../../shared/lookup';

class NotifyDocumentPage extends BaseDocument {
    constructor(props) {
        super(props);
        this.itqRelationRequired = props.itqRelationRequired;
        this.isAsiteEnabled = process.env.REACT_APP_ASITE_ENABLED === 'true';
        this.paymentPeriods = paymentPeriods.map(p => (this.props.intl.formatMessage({ id: "GENERAL.FORMS." + p })));
        this.calculationPeriods = calculationPeriods.map(p => (this.props.intl.formatMessage({ id: "GENERAL.FORMS." + p })));
    }

    calculateTotal = (obj) => {
        const prc1 = obj.pricelisttotal ? obj.pricelisttotal : 0;
        const prc2 = obj.compensationeventtotal ? obj.compensationeventtotal : 0;
        return prc1 + prc2;
    }

    renderForm = () => {
        const editorFormConfig = {
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'subject' },
                { note: this.props.resources.Note }
            ]
        };

        if (this.props.itqRelationEnabled) {
            const itqRelationField = { label: this.props.itqNotificationName, dataField: 'instructiontoquoteid', required: false, editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.itqs, valueExpr: 'value', displayExpr: 'display' } };
            
            if (this.itqRelationRequired) {
                itqRelationField.validation = { type: 'custom', validationCallback: (obj, value) => (value), warning: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_ITQ_REQUIRED_COMMUNICATING' }, { PROJECT_ORDER: this.props.proNotificationName, ITQ: this.props.itqNotificationName }) }
            }

            editorFormConfig.fields.push(itqRelationField);
        }
        if (this.props.fields.Description) {
            editorFormConfig.fields.push({ label: this.props.fields.Description, dataField: 'description', editorType: 'dxHtmlEditor' })
        }
        if (this.props.fields.Comments) {
            editorFormConfig.fields.push({ label: this.props.fields.Comments, dataField: 'comments', editorType: 'dxHtmlEditor' })
        }
        if (this.props.fields.ItemsList) {
            editorFormConfig.fields.push({ label: this.props.fields.ItemsList, dataField: 'pricelistdescription' })
        }
        if (this.props.fields.TotalListPrice) {
            editorFormConfig.fields.push({ label: this.props.fields.TotalListPrice, dataField: 'pricelisttotal', editorType: 'dxNumberBox' })
        }
        if (this.props.fields.CompensationDescription) {
            editorFormConfig.fields.push({ label: this.props.fields.CompensationDescription, dataField: 'compensationeventdescripton' })
        }
        if (this.props.fields.TotalAssessed) {
            editorFormConfig.fields.push({ label: this.props.fields.TotalAssessed, dataField: 'compensationeventtotal', editorType: 'dxNumberBox' })
        }
        if (this.props.fields.TotalPrice) {
            editorFormConfig.fields.push({ label: this.props.fields.TotalPrice, dataField: 'projectordertotal', editorType: 'dxNumberBox', value: this.calculateTotal })
        }
        if (this.props.fields.StartDate) {
            const startDateField = { label: this.props.fields.StartDate, dataField: 'startdate', editorType: 'dxDateBox' };
            if (this.props.fields.CompletionDate) {
                startDateField.validation = { type: 'custom', validationCallback: (obj, value) => (obj.completiondate >= value), warning: 'Completion date can not be earlier than start date' }
            }
            editorFormConfig.fields.push(startDateField)
        }
        if (this.props.fields.CompletionDate) {
            const completionDateField = { label: this.props.fields.CompletionDate, dataField: 'completiondate', editorType: 'dxDateBox' }
            if (this.props.fields.StartDate) {
                completionDateField.validation = { type: 'custom', validationCallback: (obj, value) => (obj.startdate <= value), warning: 'Completion date can not be earlier than start date' }
            }
            editorFormConfig.fields.push(completionDateField)
        }
        if (this.props.fields.DelayAmount) {
            editorFormConfig.fields.push({ label: this.props.fields.DelayAmount, dataField: 'delaydamagestotal', editorType: 'dxNumberBox' })
            editorFormConfig.fields.push({ label: this.props.fields.DelayPaymentPeriod, dataField: 'delaydamagestype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.paymentPeriods } })
        }
        if (this.props.fields.BonusRate) {
            editorFormConfig.fields.push({ label: this.props.fields.BonusRate, dataField: 'bonusrate' })
        }
        if (this.props.fields.BonusCalculationPeriod) {
            editorFormConfig.fields.push({ label: this.props.fields.BonusCalculationPeriod, dataField: 'bonuscalculationperiod', editorType: 'dxSelectBox', editorOptions: { dataSource: this.calculationPeriods } })
        }

        return (
            <>
                {this.props.resources.WarningBanner && (
                    <Alert variant="warning" >
                        <p>
                            {this.props.resources.WarningBanner}
                        </p>
                    </Alert>
                )}
                <BiDynamicForm
                    item={this.props.notification}
                    valueChangeHandler={this.props.fieldChanged}
                    config={editorFormConfig}
                    customFields={(this.props.customFields || {}).COMMUNICATION}
                    assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                />
            </>
        );
    }

    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.projectorderid, data, config)
    }



    render() {
        const documentIsFinal = this.props.notification.showtasknotification === 1;
        return (
            <Document
                key={documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.renderForm()}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFields || {}).COMMUNICATION}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.notification.assetid}
            />
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
});

export default withSnackbar(connect(mapStateToProps)(NotifyDocumentPage));
