import React from 'react';
import { connect } from "react-redux";
import * as fileCrud from '../../../../../crud/file.crud';
import * as paymentBatchCrud from '../../../../../crud/paymentBatch.crud';
import BaseDocument from '../../../_CustomComponents/BaseDocument';
import { withSnackbar } from 'notistack';
import { Container, Col, Row } from "react-bootstrap";
import DragDropFilepond from '../../../../../partials/content/DragDropFilepond';
import { FormattedMessage } from "react-intl";
import Common from "../../../../../shared/common";
import { Button } from 'devextreme-react';
import { confirm } from 'devextreme/ui/dialog';

class NotifyDocumentPage extends BaseDocument {
    componentDidMount() {
        //calling this to prepare the necessary message to user if they close the popup with X button
        this.props.onValueChanged(true, 'second');
    }
    initialButtonHandler = async () => {
        const response = await this.communicateAll();

        if (!response) {
            return; //cancelled
        }

        this.submitHandler();
    }

    secondaryButtonHandler = async () => {
        const response = await this.saveDrafts();

        if (!response) {
            return; //cancelled
        }

        this.props.onClose(null, true);
    }

    communicateAll = async () => {
        const communicatedToParty = this.props.workflowItem.notifyingParty === "PM" ? this.props.globalResources.SUPPLIER_NAME : this.props.globalResources.PM_NAME
        const warningMessage = this.props.intl.formatMessage({ id: 'CURRENCY_BATCH_MODAL.COMMUNICATE.WARNING' }, { ITEM_NAME: this.props.workflowItem.notificationName, PARTY_NAME: communicatedToParty });
        const warningMessageTitle = this.props.intl.formatMessage({ id: 'CURRENCY_BATCH_MODAL.COMMUNICATE.WARNING_TITLE' });
        let result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageTitle}`);
        return result;
    }

    saveDrafts = async () => {
        if (this.props.workflowItem.userFilters.mainNotice.communicateAllowed) {
            const warningMessage = this.props.intl.formatMessage({ id: 'CURRENCY_BATCH_MODAL.SAVE_DRAFT.WARNING' }, { ITEM_NAME: this.props.workflowItem.notificationName });
            const warningMessageTitle = this.props.intl.formatMessage({ id: 'CURRENCY_BATCH_MODAL.SAVE_DRAFT.WARNING_TITLE' });
            let result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageTitle}`);
            return result;
        }
        else {
            return true;
        }

    }

    submitHandler = () => {
        const batchid = this.props.paymentCertificateMultipleCurrencies[0].paymentbatchid;
        paymentBatchCrud.batchNotify(this.props.paymentIdsAndCurrencyNamesList, this.props.contract.contractid, batchid)
            .then(() => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });
                try {
                    this.props.onHandlePaymentCertificateMultipleCurrencies([]);
                    this.props.onClose(null, true);
                }
                catch {
                    this.props.onHandlePaymentCertificateMultipleCurrencies([]);
                    this.props.onClose(null, true);
                }
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });
    }

    uploadFile = (data, config, paymentcertificateid) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.workflowItem.config.documentType, paymentcertificateid, data, config)
    }

    render() {
        const { companyid, projectid, contractid } = this.props.contract;

        return (
            <div style={{ marginTop: 10 }}>
                {
                    this.props.paymentCertificateMultipleCurrencies.map((item, index2) => (
                        <div key={index2}>
                            {
                                <Container>
                                    <Row>
                                        <span style={{ paddingLeft: 10 }}>{`${Common.FormatCurrencyNum(0, item.currency).replace("0.00", "")}\u00a0\u00a0\u00a0${item.currency}\u00a0\u00a0\u00a0${item.reference}`}</span>
                                    </Row>
                                    <Row style={{ marginTop: 10, marginBottom: 30 }}>
                                        <Col>
                                            <span className="dx-field-item-label-text dx-box-item-content dx-widget"><FormattedMessage id="GENERAL.FORMS.FIELD.ATTACHED_DOCUMENTS" />:</span>
                                            {
                                                <div className="dx-field" style={{ marginTop: "10px" }}>
                                                    <DragDropFilepond
                                                        files={[]}
                                                        uploadMethod={(data, config) => this.uploadFile(data, config, item.paymentcertificateid)}
                                                        deleteMethod={(fileid) => { return fileCrud.deleteContractFile(companyid, projectid, contractid, fileid) }}
                                                        onFilesCountChanged={this.props.onFilesCountChanged}
                                                    />
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            }
                        </div>
                    ))
                }
                {
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '10px', marginBottom: '10px', paddingRight: '10px' }}>
                        {
                            <Button
                                id="InitialSubmit"
                                type="default"
                                disabled={!this.props.workflowItem.userFilters.mainNotice.communicateAllowed}
                                elementAttr={{ name: "InitialSubmit" }}
                                onClick={this.initialButtonHandler}
                                text={this.props.intl.formatMessage({ id: "PAYMENT.BATCH_WIZARD.BUTTON.COMMUNICATE" })}
                                useSubmitBehavior={true}
                                style={{ backgroundColor: '#4CAF50', marginLeft: "10px" }}
                            />
                        }
                        {
                            <Button
                                id="SecondarySubmit"
                                type="default"
                                elementAttr={{ name: "SecondarySubmit" }}
                                stylingMode="outlined"
                                onClick={this.secondaryButtonHandler}
                                text={this.props.intl.formatMessage({ id: "PAYMENT.BATCH_WIZARD.BUTTON.EXIT_WIZARD" })}
                            />
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
});

export default withSnackbar(connect(mapStateToProps)(NotifyDocumentPage));
