import React from "react";
import { Navigate, useLocation, matchRoutes } from "react-router-dom";
import { RoutesConfig, RoutesConfigPaths } from "./config/config";
import { useSelector } from "react-redux";
import { listAccountsAllowedContractUsersInDomo } from './AccessExceptions';
// const { Provider, Consumer } = React.createContext({ color: 'white' });
// import { useContext } from 'react';
// import { createContext, useContext } from 'react';

// export const LevelContext = createContext(1);

//https://www.robinwieruch.de/react-router-private-routes/
//https://stackoverflow.com/questions/62384395/protected-route-with-react-router-v6
//https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export const GetAccessRights = (path, systemUser, contract) => {
    let allowAccess = false;
    // let updateAllowed = false;
    // let activateDeactivateAllowed = false;
    // let exportImportAllowed = false;
    // let generateAllowed = false;
    let pathConfig = null;
    let routeMatch = "";
    ///CHECK IF PATH HAS CONFIG DEFINITION
    // const mappedConfig = Object.keys(RoutesConfig).map(x => ({ path: x }));
    const matches = matchRoutes(RoutesConfigPaths, path);
    if (matches && matches.length > 0) {
        if (matches.length > 1) {
            alert("There are more than 1 matches got route path!");
        }
        routeMatch = matches[0].route.path;
        pathConfig = RoutesConfig[routeMatch];
    }
    else {
        alert("Requested path does not exist!");
        console.log("Path not found in config:" + path)
        return { allowAccess: false };
    }

    if (pathConfig.prerequisiteFlag) {
        //REJECT ACCESS IF prerequisiteFlag is not true
        if (process.env[pathConfig.prerequisiteFlag] !== "true") {
            return { allowAccess: false };
        }
    }

    if (pathConfig.contractFlag && contract) {
        //REJECT ACCESS IF contractFlag is not true
        if (!contract[pathConfig.contractFlag]) {
            return { allowAccess: false };
        }
    }


    if ((pathConfig.access || []).includes(systemUser.accounttype)) {
        allowAccess = true;
    }
    else if (pathConfig.additionalAccessProperty) {
        allowAccess = !!systemUser[pathConfig.additionalAccessProperty];
    }

    ///HANDLE EXCEPTIONS
    if (routeMatch === "/contract/:id/settings/users") {
        if (contract && contract.isdemocontract === 1) {
            ///DEMO CONTRACT
            ///OVERRIDE ACCESS RIGHTS IF DEMO
            allowAccess = listAccountsAllowedContractUsersInDomo.includes(systemUser.accounttype)
        }

    }

    if (allowAccess) {
        //CALCULATE ACTIONS ALLOWED IF ACCESS ALLOWED
        const actionProps = {};
        if (pathConfig.actions) {
            for (const property in pathConfig.actions) {
                actionProps[property] = pathConfig.actions[property].includes(systemUser.accounttype)
            }
        }


        return { allowAccess, ...actionProps };

    }
    else {
        return { allowAccess: false };
    }
}

const Protector = ({ children }) => {
    // const { children } = props;
    let location = useLocation();
    // let params = useParams();
    // const matches = useMatch();
    // let dispatch = useDispatch();
    const systemUser = useSelector(store => store.auth.userProfile);
    const contract = useSelector(store => store.contract.contract);
    // const { enqueueSnackbar } = useSnackbar();
    // console.log(props)
    // console.log(location)
    // console.log(params)
    // console.log(matches)
    // console.log(systemUser)
    // console.log(contractUser)
    // let allowAccess = false;
    // let updateAllowed = false;
    // let activateDeactivateAllowed = false;
    // let exportImportAllowed = false;
    // let generateAllowed = false;
    // let pathConfig = null;
    ///CHECK IF PATH HAS CONFIG DEFINITION
    let pathConfig = GetAccessRights(location.pathname, systemUser, contract);
    // const mappedConfig = Object.keys(RoutesConfig).map(x => ({ path: x }));
    // const matches = matchRoutes(RoutesConfigPaths, location.pathname);
    // if (matches && matches.length > 0) {
    //     if (matches.length > 1) {
    //         alert("There are more than 1 matches got route path!");
    //     }
    //     const routeMatch = matches[0].route.path;
    //     pathConfig = RoutesConfig[routeMatch];
    // }
    // else {
    //     enqueueSnackbar("Requested path does not exist", { variant: 'error' });
    //     console.log("Path not found in config:" + location.pathname)
    //     return <Navigate replace to="/contracts" />;
    // }



    // console.log(children)
    // if (!auth.user) {
    //   // Redirect them to the /login page, but save the current location they were
    //   // trying to go to when they were redirected. This allows us to send them
    //   // along to that page after they login, which is a nicer user experience
    //   // than dropping them off on the home page.
    //   return <Navigate to="/login" state={{ from: location }} />;
    // }

    // return <Outlet />;
    // console.log(children)
    // const {user,redirectPath = '/contracts',children,} = props;
    // if (!user) {
    //   return <Navigate to={redirectPath} replace />;
    // }

    if (pathConfig.allowAccess) {
        const Child = React.Children.only(children);
        return React.cloneElement(Child, { protectedRouteProps: pathConfig });
        ///create context
        // return (
        //     <RouteAccessContext.Provider value={{ allowAccess, updateAllowed, exportImportAllowed, generateAllowed }}>
        //         {children}
        //     </RouteAccessContext.Provider>
        // );
        ///ALTERNATIVE APPROACH -> CLONE CHILD AND RENDER WITH PROPS
        // const childrenWithProps = React.Children.map(children, child => {
        //     // Checking isValidElement is the safe way and avoids a
        //     // typescript error too.
        //     // return <Child allowAccess />
        //     if (React.isValidElement(child)) {
        //       return React.cloneElement(child, { protectedRouteProps:{allowAccess, updateAllowed, exportImportAllowed, generateAllowed} });
        //     }
        //     return child;
        //   });
        //   return <div>{childrenWithProps}</div>;

    }
    else {
        return <Navigate to="/contracts" replace />;
    }
};

export default Protector;