/* eslint-disable no-useless-escape, react/no-direct-mutation-state */

import React, { Component } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import BiDynamicForm from './BiDynamicForm';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import { injectIntl } from "react-intl";

export const RESPONSE = {
    OK: 'OK',
    CANCEL: 'CANCEL',
};
const urlRegexPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
class WebLinkEditorModal extends Component {
    constructor(props) {
        super(props);
        this.validatorRef = React.createRef();
        this.validatorName = 'WebLinkValidator';
        this.state = {
            editingItem: null
        }
        this.formConfig = {
            fields: [
                { resource: 'GENERAL.FORMS.LINK', dataField: 'url', editorType: 'dxTextBox', required: true, validation: { type: 'pattern', pattern: urlRegexPattern, warning: props.intl.formatMessage({ id: "GENERAL.FORMS.LINK_INVALID" }) } },
                { resource: 'GENERAL.FORMS.TEXT_TO_DISPLAY', dataField: 'text', editorType: 'dxTextBox', required: false }
            ]
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible !== this.props.visible) {
            if (this.props.visible) {
                //POPUP OPENED
                let url = '';
                let text = '';
                if (this.props.item) {
                    const valueArray = this.props.item.split('|');
                    url = valueArray[0];
                    text = valueArray[1];
                }
                this.setState({ editingItem: { url, text } });
            }
        }
    }
    onClose = () => {
        if (this.props.visible) {
            this.setState({ editingItem: null });
            this.props.onDialogResult(RESPONSE.CANCEL);
        }
    }
    saveItem = () => {
        if (!this.validateForm()) {
            return;
        }
        const retVal = this.state.editingItem.url + '|' + (this.state.editingItem.text ? this.state.editingItem.text : this.state.editingItem.url);
        this.setState({ editingItem: null }, () => {
            this.props.onDialogResult(RESPONSE.OK, retVal);
        });

    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        return validationResult.isValid;
    }
    onValueChanged = (field, value) => {
        this.state.editingItem[field] = value;
    }
    generateForm = () => {
        return (
            <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                <BiDynamicForm
                    config={this.formConfig}
                    item={this.state.editingItem}
                    valueChangeHandler={this.onValueChanged}
                />
                <div>
                    <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                </div>
            </ValidationGroup>
        )
    }
    render() {

        return (
            <Popup
                visible={this.props.visible}
                onHiding={this.onClose}
                dragEnabled={true}
                showTitle={true}
                title={this.props.intl.formatMessage({ id: "GENERAL.FORMS.EDIT_LINK" })}
                width={600}
                height='auto'>
                <div>
                    {this.props.visible && this.state.editingItem && (
                        this.generateForm()
                    )
                    }
                </div>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CANCEL" }), onClick: this.onClose }}>
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.OK" }), onClick: this.saveItem }}>
                </ToolbarItem>
            </Popup>
        );
    }
}


export default injectIntl(WebLinkEditorModal);