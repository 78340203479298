import React from 'react';
import { connect } from "react-redux";
import { Portlet, PortletBody, PortletFooter } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
// import AddButton from '../../../../partials/layout/Buttons/AddButton';
import * as templatesDuck from '../../../../store/ducks/templates.duck';
import { withRouter } from '../../../../shared/hoc/withRouter';
import { injectIntl } from "react-intl";
import { listAccountsAllowedGenerateSystemDefaultTemplates } from '../../../../router/Protector/AccessExceptions';
import * as templateCrud from '../../../../crud/templates.crud';
import TemplateHelper from "../../../../Templates/TemplateHelper";
import Common from '../../../../shared/common';
import DefaultResources from '../../../../Templates/DefaultResources';
import { confirm } from 'devextreme/ui/dialog';
import { withSnackbar } from 'notistack';

class MasterTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.additionalButtons = [];
        if (listAccountsAllowedGenerateSystemDefaultTemplates.includes(props.userProfile.accounttype)) {
            this.additionalButtons.push(
                {
                    location: "after",
                    locateInMenu: "always",
                    widget: "dxButton",
                    options:
                    {
                        icon: "preferences",
                        onClick: this.generateDefaultWorkflowsJson,
                        text: "Download DefaultWorkflows.json",
                    }

                },
                {
                    location: "after",
                    locateInMenu: "always",
                    widget: "dxButton",
                    options:
                    {
                        icon: "preferences",
                        onClick: this.generateDefaultResourcesJson,
                        text: "Download DefaultResources.json",
                    }

                }
            );

            //ONLY ENABLED IN DEBUG MODE FOR NOW
            if (process.env.NODE_ENV === 'development') {
                this.additionalButtons.push(
                    {
                        location: "after",
                        locateInMenu: "always",
                        widget: "dxButton",
                        options: {
                            icon: "upload",
                            type: "danger",
                            stylingMode: "outlined",
                            text: "Generate Defaults in DB",
                            onClick: this.generateDefaultsInDB
                        }
                    }
                );
            }
        }

    }
    componentDidMount() {
        this.props.loadTemplates();

    }
    generateDefaultWorkflowsJson = (e) => {
        const templateFileName = `DefaultWorkflows`;
        const defaultTemplate = { ...templateCrud.getDefaultTemplates()[0] };
        const sanitizedDBTemplate = TemplateHelper.templateSanitizer(defaultTemplate)
        const stringifiedJson = JSON.stringify(sanitizedDBTemplate.workflowGroups, null, 2);

        Common.downloadFile({
            data: stringifiedJson,
            fileName: templateFileName + '.json',
            fileType: 'text/json',
        });
    }
    generateDefaultResourcesJson = (e) => {
        const templateFileName = `DefaultResources`;
        const defaultResources = [...DefaultResources];
        const stringifiedJson = JSON.stringify(defaultResources, null, 2);

        Common.downloadFile({
            data: stringifiedJson,
            fileName: templateFileName + '.json',
            fileType: 'text/json',
        });
    }
    generateDefaultsInDB = async () => {
        let result = await confirm(`<div style="max-width:50vw;">This action will create several default template create request to API, please make sure API is ready in terms of throttle settings as this action will require multiple request to API. Do you want to continue?</div>`, "Continue to create default templates?");
        if (result) {
            this.props.saveDefaultTemplatesToServer();
            this.props.enqueueSnackbar('Default template generation started async in background, please watch network tab to validate success.', { variant: 'warning', });
        }
    }
    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            this.props.navigate("/admin/templates/template-editor/" + data.templateid);
        }
    }

    render() {
        if (!this.props.templates) {
            return (<PortletSpinner />);
        }
        return (

            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="1"
                        title={`Master Template List`}
                        // createPath={this.props.userProfile.isSiteAdmin ? "/admin/templates/template-editor" : null}
                        name="MasterTemplates"
                        dataSource={this.props.templates}
                        keyExpr="templateid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        allowSelection={true}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={[
                            { caption: "Template Name", dataField: "name" },
                            { caption: "Is System Default", dataField: "isSystemDefault" },
                            { caption: "Is Global", dataField: "isGlobal" },
                        ]}
                        additionalToolbarItems={this.additionalButtons}
                    />
                </PortletBody>
                <PortletFooter />
            </Portlet>
        );
    }
}




const mapStateToProps = store => ({
    templates: store.templates.templates,
    userProfile: store.auth.userProfile,
});

const mapDispatchToProps = {
    loadTemplates: templatesDuck.actions.fetchTemplates,
    saveDefaultTemplatesToServer: templatesDuck.actions.saveDefaultTemplatesToServer,
};

export default withSnackbar(injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(MasterTemplates))));
