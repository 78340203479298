import * as templatesCrud from "../../crud/templates.crud";
import { select, put, takeLatest } from "redux-saga/effects";
import store from "../store";
import TemplateHelper from "../../Templates/TemplateHelper";
import DefaultWorkflows from '../../Templates/DefaultWorkflows';
const getAuth = (store) => store.auth;

export const actionTypes = {
    TEMPLATES_FETCH_TEMPLATES: "[TEMPLATES_FETCH_TEMPLATES]",
    TEMPLATES_FETCH_WORKFLOWS: "[TEMPLATES_FETCH_WORKFLOWS]",
    TEMPLATES_SET_WORKFLOWGROUPS: "[TEMPLATES_SET_WORKFLOWGROUPS]",
    TEMPLATES_SET_TEMPLATES: "[TEMPLATES_SET_TEMPLATES]",
    TEMPLATES_SAVEDFT_TEMPLATES: "[TEMPLATES_SAVEDFT_TEMPLATES]",
    TEMPLATES_SAVEDFT_WORKFLOWS: "[TEMPLATES_SAVEDFT_WORKFLOWS]",
};

const initialTemplatesState = {
    templates: null,
    workflowGroups: null,
    wfgLastLoadedEpochTime: null,
    tmpLastLoadedEpochTime: null
};

export const reducer = (state = initialTemplatesState, action) => {
    switch (action.type) {
        case actionTypes.TEMPLATES_FETCH_WORKFLOWS: {
            return { ...state, wfgLastLoadedEpochTime: new Date().getTime() };
        }
        case actionTypes.TEMPLATES_FETCH_TEMPLATES: {
            return { ...state, tmpLastLoadedEpochTime: new Date().getTime() };
        }
        case actionTypes.TEMPLATES_SET_WORKFLOWGROUPS: {
            return { ...state, workflowGroups: action.payload.workflowGroups };
        }
        case actionTypes.TEMPLATES_SET_TEMPLATES: {
            return { ...state, templates: action.payload.templates };
        }
        default:
            return state;
    }
};

export const actions = {
    fetchTemplates: (force) => {
        // return { type: actionTypes.TEMPLATES_FETCH_TEMPLATES }
        const templateStore = store.getState().templates;
        if (templateStore.templates && !force) {
            if (templateStore.tmpLastLoadedEpochTime) {
                const cacheTimeoutSec = Number(process.env.REACT_APP_CACHE_TIMEOUT_TEMPLATES);
                if (new Date(templateStore.tmpLastLoadedEpochTime + (cacheTimeoutSec * 1000)) > new Date()) {
                    return { type: '' };
                }
            }
        }
        return { type: actionTypes.TEMPLATES_FETCH_TEMPLATES };
    },
    fetchWorkflows: (force) => {
        // return { type: actionTypes.TEMPLATES_FETCH_WORKFLOWS }
        const templateStore = store.getState().templates;
        if (templateStore.workflowGroups && !force) {
            if (templateStore.wfgLastLoadedEpochTime) {
                const cacheTimeoutSec = Number(process.env.REACT_APP_CACHE_TIMEOUT_TEMPLATES);
                if (new Date(templateStore.wfgLastLoadedEpochTime + (cacheTimeoutSec * 1000)) > new Date()) {
                    return { type: '' };
                }
            }
        }
        return { type: actionTypes.TEMPLATES_FETCH_WORKFLOWS }
    },
    setWorkflowGroups: (workflowGroups) => ({ type: actionTypes.TEMPLATES_SET_WORKFLOWGROUPS, payload: { workflowGroups } }),
    setTemplates: (templates) => ({ type: actionTypes.TEMPLATES_SET_TEMPLATES, payload: { templates } }),
    saveDefaultTemplatesToServer: () => ({ type: actionTypes.TEMPLATES_SAVEDFT_TEMPLATES }),
    saveDefaultWorkflowsToServer: () => ({ type: actionTypes.TEMPLATES_SAVEDFT_WORKFLOWS }),
};
export function* saga() {
    yield takeLatest(actionTypes.TEMPLATES_FETCH_WORKFLOWS, function* templatesFetchWorkflowSaga() {
        try {
            const { data: WorkFlowGroups } = yield templatesCrud.getWorkflowGroups();
            if (WorkFlowGroups && WorkFlowGroups.workflowjson) {
                const dummyTemplate = { ...templatesCrud.getDefaultTemplates()[0] };
                dummyTemplate.workflowGroups = JSON.parse(WorkFlowGroups.workflowjson);
                const sanitizedDummyTemplate = TemplateHelper.templateSanitizer(dummyTemplate);
                //LETS ADD CONFIG TO OBJECT TO SYSTEM WORKFLOWS CAN BE USED
                sanitizedDummyTemplate.workflowGroups.forEach(WFG => {
                    const defaultWFG = DefaultWorkflows.find(dwfg => dwfg.id === WFG.id);
                    WFG.workflows.forEach(WF => {
                        const defaultWF = defaultWFG.workflows.find(dwf => dwf.id === WF.id);
                        WF.config = { ...defaultWF.config };
                        WF.description = WF.config.description;// just for list display purposes
                    })
                });
                yield put(actions.setWorkflowGroups([...sanitizedDummyTemplate.workflowGroups]));
            }
            else {
                alert("No workflows found in DB. System will continue with loading static default files. Check for record id=1 in dbo.Workflow")
                console.log('Error in templatesFetchWorkflowSaga: No DefaultWF found in DB');
                const sanitizedDBTemplate = TemplateHelper.getDefaultSanitizedTemplate()
                yield put(actions.setWorkflowGroups([...sanitizedDBTemplate.workflowGroups]));
                // yield put(actions.saveDefaultWorkflowsToServer());
            }
        }
        catch (err) {
            alert("No workflows found in DB. System will continue with loading static default files. Check for record id=1 in dbo.Workflow")
            console.log('Error in templatesFetchWorkflowSaga', err);
            try {
                const sanitizedDBTemplate = TemplateHelper.getDefaultSanitizedTemplate()
                yield put(actions.setWorkflowGroups([...sanitizedDBTemplate.workflowGroups]));
                // yield put(actions.saveDefaultWorkflowsToServer());
            }
            catch { }
        }

    });
    yield takeLatest(actionTypes.TEMPLATES_FETCH_TEMPLATES, function* templatesFetchTemplateSaga() {
        try {
            const { data: TemplatesBulk } = yield templatesCrud.getTemplates();
            if (TemplatesBulk.aggregatelist) {
                const TemplatesArray = JSON.parse(TemplatesBulk.aggregatelist);
                const templates = TemplatesArray.map(temp => {
                    const baseObj = JSON.parse(temp.templatejson);
                    const sanitizedObj = TemplateHelper.templateSanitizer(baseObj);
                    sanitizedObj.templateid = temp.templateid;
                    sanitizedObj.name = temp.name;
                    return sanitizedObj;
                });
                yield put(actions.setTemplates(templates));

                
                if (TemplatesArray.length > 0) {

                }
                else {
                    alert("No templates found in DB.")
                    // const defaultTemps = templatesCrud.getDefaultTemplates();
                    // defaultTemps.forEach(tmp => {
                    //     tmp = TemplateHelper.templateSanitizer(tmp);
                    // });
                    // yield put(actions.setTemplates(defaultTemps));
                    // yield put(actions.saveDefaultTemplatesToServer());
                }
            }
            else {
                alert("No templates found in DB")
                // const defaultTemps = templatesCrud.getDefaultTemplates();
                // defaultTemps.forEach(tmp => {
                //     tmp = TemplateHelper.templateSanitizer(tmp);
                // });
                // yield put(actions.setTemplates(defaultTemps));
                // yield put(actions.saveDefaultTemplatesToServer());
            }
        }
        catch (err) {
            console.log('Error in templatesFetchTemplateSaga', err);
            alert("No templates found in DB.")
            // try {
            //     const defaultTemps = templatesCrud.getDefaultTemplates();
            //     defaultTemps.forEach(tmp => {
            //         tmp = TemplateHelper.templateSanitizer(tmp);
            //     });
            //     yield put(actions.setTemplates(defaultTemps));
            // }
            // catch { }
        }

    });
    yield takeLatest(actionTypes.TEMPLATES_SAVEDFT_TEMPLATES, function* templatesSaveDefaultTemplateSaga() {
        try {
            console.log('trying to save default templates');
            /// IF USER IS ADMIN TRY TO SAVE DEFAULT TEMPLATES
            const authStore = yield select(getAuth);
            if (authStore.userProfile && authStore.userProfile.accounttype === "System Owner") {
                const defaultTemps = templatesCrud.getDefaultTemplates();

                const temps = defaultTemps.map(temp => ({ name: temp.name, templatejson: JSON.stringify(TemplateHelper.templateSanitizer(temp)), deleted: 0 }))

                for (let i = 0; i < temps.length; i++) {
                    if (i !== 0) {
                        yield templatesCrud.saveTemplate(temps[i]);
                    }
                }
            }
        }
        catch (err) {
            console.log('Error trying to save default Templates', err);
        }
    });

    yield takeLatest(actionTypes.TEMPLATES_SAVEDFT_WORKFLOWS, function* templatesSaveDefaultWorkflowSaga() {
        try {
            console.log('trying to save default workflows');
            /// IF USER IS ADMIN TRY TO SAVE DEFAULT TEMPLATES
            const authStore = yield select(getAuth);
            if (authStore.userProfile && authStore.userProfile.accounttype === "System Owner") {
                const defaultTemplate = { ...templatesCrud.getDefaultTemplates()[0] };
                const sanitizedDBTemplate = TemplateHelper.templateSanitizer(defaultTemplate)
                const newWorkflowGroups = {
                    workflowid: 1,
                    name: 'ALL',
                    workflowjson: JSON.stringify([...sanitizedDBTemplate.workflowGroups]),
                    deleted: 0
                };
                yield templatesCrud.saveWorkflowGroups(newWorkflowGroups);
            }
        }
        catch (err) {
            console.log('Error trying to save default Workflows', err);
        }
    });
}
