/* eslint-disable import/no-anonymous-default-export */
import Workflows from "./Workflows";
import Placeholders from "./Placeholders";
export default {
    id: 102,
    title: 'Instructions',
    path: 'instructions',
    icon: "flaticon2-list-2",
    workflows: Workflows,
    placeholders: Placeholders,
};