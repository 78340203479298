import { placeholderTypes } from "../../Types/Placeholder"
const placeholders = [
    {
        replacementKey: 'Resources', values: [],
        type: placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'ActionReminders', values:
            [
                { id: 1, isActive: true, field: 'Supplier', display: '$SUPPLIER_NAME Notifications awaiting reply' },
                { id: 2, isActive: true, field: 'PM', display: '$PM_NAME Notifications awaiting reply' },
                { id: 3, isActive: true, field: 'Supervisor', display: '$SUPERVISOR_NAME Notifications awaiting reply' },
                { id: 4, isActive: true, field: 'Client', display: '$CLIENT_NAME Notifications awaiting reply' },
                { id: 5, isActive: true, field: 'PmToSupervisor', display: '$PM_NAME\'s $SUPERVISOR_NAME Notifications awaiting reply' },
                { id: 6, isActive: true, field: 'SupervisorToPm', display: '$SUPERVISOR_NAME\'s $PM_NAME Notifications awaiting reply' },
                { id: 7, isActive: true, field: 'PmToClient', display: '$PM_NAME\'s $CLIENT_NAME Notifications awaiting reply' },
                { id: 8, isActive: true, field: 'PmToPm', display: '$PM_NAME\'s $PM_NAME Notifications awaiting reply' },
                { id: 9, isActive: true, field: 'ClientToClient', display: '$CLIENT_NAME\'s $CLIENT_NAME Notifications awaiting reply' },
                { id: 10, isActive: true, field: 'SupplierToSupplier', display: '$SUPPLIER_NAME\'s $SUPPLIER_NAME Notifications awaiting reply' },
                { id: 11, isActive: true, field: 'SupervisorToSupervisor', display: '$SUPERVISOR_NAME\'s $SUPERVISOR_NAME Notifications awaiting reply' },
                { id: 12, isActive: true, field: 'SupplierToSupervisor', display: '$SUPPLIER_NAME\'s $SUPERVISOR_NAME Notifications awaiting reply' }
            ],
        type: placeholderTypes.ACTION_REMINDERS
    },
    {
        replacementKey: 'UnifiedClauses', values:
            [
                { id: 1, wfid: 15, isActive: true, field: '', display: 'Test Clause', },

            ],
        skipDefaultLoadingIfMissing: true,
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type: placeholderTypes.UNIFIED_CLAUSES
    },
    {
        replacementKey: 'PmClauses', values:
            [
                { id: 1, isActive: true, field: '', display: '13.2 of a change in address as follows:', },
                { id: 2, isActive: true, field: '', display: '15.1 we have become aware of the following ambiguity/inconsistency:', },
                { id: 3, isActive: true, field: '', display: '17.1 we have become aware of the following matter that would either be illegal or impossible:', },
                { id: 4, isActive: true, field: '', display: '21.3 we provide the following forecasts of expenses', },
                { id: 5, isActive: true, field: '', display: '21.4 we provide the following forecasts of expenses and the total Time Charge', },
                { id: 6, isActive: true, field: '', display: '23.2 the following meeting(s) with Others are being held that you may wish to attend', },
                { id: 7, isActive: true, field: '', display: '61.4: the Client has not responded to a compensation event notification within the timescales outlined in the contract as detailed below:', },
                { id: 8, isActive: true, field: '', display: '62.6 the Client has not responded to a compensation event quotation within the timescales outlined in the contract as detailed below:', },
                { id: 9, isActive: true, field: '', display: '64.4 the Client has not made their own assessment of a compensation event quotation within the timescales outlined in the contract as detailed below:', },
                { id: 10, isActive: true, field: '', display: '81.1/2 the following insurances/insurance certificates have been provided by the Consultant:', },
                { id: 11, isActive: true, field: '', display: '90.1: we wish to terminate the contract for the reasons/detail listed below', },
                { id: 12, isActive: true, field: '', display: 'X1.1: the following price adjustment factor', },
                { id: 13, isActive: true, field: '', display: 'X4.1: the following guarantee from the parent company', },
                { id: 14, isActive: true, field: '', display: 'X20.2: the performance against Key Performance Indicators as detailed below', },
                { id: 15, isActive: true, field: '', display: 'other general notification', },
            ],
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'SupplierClauses', values:
            [
                { id: 1, isActive: true, field: '', display: '13.2 of a change in address as follows:', },
                { id: 2, isActive: true, field: '', display: '15.1 we have become aware of the following ambiguity/inconsistency:', },
                { id: 3, isActive: true, field: '', display: '17.1 we have become aware of the following matter that would either be illegal or impossible:', },
                { id: 4, isActive: true, field: '', display: '21.3 we provide the following forecasts of expenses', },
                { id: 5, isActive: true, field: '', display: '21.4 we provide the following forecasts of expenses and the total Time Charge', },
                { id: 6, isActive: true, field: '', display: '23.2 the following meeting(s) with Others are being held that you may wish to attend', },
                { id: 7, isActive: true, field: '', display: '61.4: the Client has not responded to a compensation event notification within the timescales outlined in the contract as detailed below:', },
                { id: 8, isActive: true, field: '', display: '62.6 the Client has not responded to a compensation event quotation within the timescales outlined in the contract as detailed below:', },
                { id: 9, isActive: true, field: '', display: '64.4 the Client has not made their own assessment of a compensation event quotation within the timescales outlined in the contract as detailed below:', },
                { id: 10, isActive: true, field: '', display: '81.1/2 the following insurances/insurance certificates have been provided by the Consultant:', },
                { id: 11, isActive: true, field: '', display: '90.1: we wish to terminate the contract for the reasons/detail listed below', },
                { id: 12, isActive: true, field: '', display: 'X1.1: the following price adjustment factor', },
                { id: 13, isActive: true, field: '', display: 'X4.1: the following guarantee from the parent company', },
                { id: 14, isActive: true, field: '', display: 'X20.2: the performance against Key Performance Indicators as detailed below', },
                { id: 15, isActive: true, field: '', display: 'other general notification', },
            ],
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'SupervisorClauses', values:
            [
                { id: 1, isActive: true, field: '', display: '13.2 of a change in address as follows:', },
                { id: 2, isActive: true, field: '', display: '15.1 we have become aware of the following ambiguity/inconsistency:', },
                { id: 3, isActive: true, field: '', display: '17.1 we have become aware of the following matter that would either be illegal or impossible:', },
                { id: 4, isActive: true, field: '', display: '21.3 we provide the following forecasts of expenses', },
                { id: 5, isActive: true, field: '', display: '21.4 we provide the following forecasts of expenses and the total Time Charge', },
                { id: 6, isActive: true, field: '', display: '23.2 the following meeting(s) with Others are being held that you may wish to attend', },
                { id: 7, isActive: true, field: '', display: '61.4: the Client has not responded to a compensation event notification within the timescales outlined in the contract as detailed below:', },
                { id: 8, isActive: true, field: '', display: '62.6 the Client has not responded to a compensation event quotation within the timescales outlined in the contract as detailed below:', },
                { id: 9, isActive: true, field: '', display: '64.4 the Client has not made their own assessment of a compensation event quotation within the timescales outlined in the contract as detailed below:', },
                { id: 10, isActive: true, field: '', display: '81.1/2 the following insurances/insurance certificates have been provided by the Consultant:', },
                { id: 11, isActive: true, field: '', display: '90.1: we wish to terminate the contract for the reasons/detail listed below', },
                { id: 12, isActive: true, field: '', display: 'X1.1: the following price adjustment factor', },
                { id: 13, isActive: true, field: '', display: 'X4.1: the following guarantee from the parent company', },
                { id: 14, isActive: true, field: '', display: 'X20.2: the performance against Key Performance Indicators as detailed below', },
                { id: 15, isActive: true, field: '', display: 'other general notification', },
            ],
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'ClientClauses', values: [],
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'PmToSupervisorClauses', values: [],
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'PmToClientClauses', values: [],
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'SupervisorToPmToClauses', values: [],
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'PmToPmClauses', values: [],
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'ClientToClientClauses', values: [],
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'SupplierToSupplierClauses', values: [],
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'SupervisorToSupervisorClauses', values: [],
        allowCustomReplyPeriods: true,
        allowConfigureReplyParty: true,
        effects: ["DEEM_START_INTERVAL", "DEEM_STOP_INTERVAL", "CEN_DEEM_START_INTERVAL", "CEN_DEEM_STOP_INTERVAL", "PROG_DEEM_START_INTERVAL", "PROG_DEEM_STOP_INTERVAL"],
        type: placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'ReplyOptions', values:
            [
                { id: 1, isActive: true, field: 'Accepted', display: 'Accepted' },
                { id: 2, isActive: true, field: 'Accepted with comments', display: 'Accepted with comments' },
                { id: 3, isActive: true, field: 'Not Accepted', display: 'Not Accepted' },
                { id: 4, isActive: true, field: 'Reply Not Required', display: 'Reply Not Required' },
            ],
        fieldRequired: true,
        type: placeholderTypes.GENERIC
    },

];
export default placeholders;