import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withSnackbar } from 'notistack';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import { Button as DxButton } from 'devextreme-react/button';
import Spinner from '../../../../partials/layout/Spinner';
import * as taskOrderCrud from '../../../../crud/taskOrder.crud';
import { addYears } from 'date-fns';
import moment from 'moment';
import { injectIntl } from "react-intl";
import { Alert } from "react-bootstrap";
import { addDays } from 'date-fns';
import * as earlyWarningCrud from '../../../../crud/earlyWarning.crud';
import * as projectOrderCrud from '../../../../crud/projectOrder.crud';
import TemplateHelper from "../../../../Templates/TemplateHelper";
import { checkIfAwareDateFieldAllowed } from '../instructions';
import DataSource from 'devextreme/data/data_source';

class InstructionCustomForm extends React.Component {
    constructor(props) {
        super(props);
        this.createDefaultSubject = (((props.location || {}).state || {}).itemCustomProps || {}).subject;
        this.createDefaultAsset = (((props.location || {}).state || {}).itemCustomProps || {}).assetid;
        this.createDefaultFields = ((props.location || {}).state || {});

        let initialRelatedWarnings = [];
        if (props.relatedWarnings) {
            initialRelatedWarnings = [...props.relatedWarnings];
        }

        let selectedClause = null;
        let globalResources = this.props.globalResources;
        if (props.item && props.item.compensationtype && props.clauses) {
            selectedClause = props.clauses.find(c => c.display === props.item.compensationtype);
            if (selectedClause) {
                globalResources = TemplateHelper.getGlobalResourcesOverriden(globalResources, selectedClause)
            }
        }

        this.dateInstructionAllowed = checkIfAwareDateFieldAllowed(props.contract.contracttype);

        this.state = {
            earlyWarningList: null,
            relatedWarnings: initialRelatedWarnings,
            taskOrderOptions: null,
            projectOrderOptions: null,
            selectedClause,
            globalResources
        };
    }
    componentDidMount() {
        if (this.props.taskOrderOptionEnabled) {
            this.fetchTaskOrders();
        }
        if (this.props.projectOrderOptionEnabled) {
            this.fetchProjectOrders();
        }
        if (this.props.ewRelationEnabled) {
            this.fetchEarlyWarnings();
        }
    }
    fetchEarlyWarnings = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data: responseObj } = await earlyWarningCrud.getEarlyWarningsToSelectList(companyid, projectid, contractid)
            this.setState({ earlyWarningList: responseObj }, () => this.addWarningItemIfConditionsMet(responseObj));
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.relatedWarnings !== this.props.relatedWarnings) {
            this.setState({ relatedWarnings: [...this.props.relatedWarnings] });
        }
    }

    fetchTaskOrders = async () => {
        try {
            const { contractid } = this.props.contract;
            const { data: responseObj } = await taskOrderCrud.getTaskOrdersToSelectList(contractid);
            //COVERTED TO DATASOURCE WITH FD-11007 DUE TO PERFORMENCE ISSUES AFTER 3K ITEMS
            const taskOrderOptions = new DataSource({
                store: {
                    type: 'array',
                    data: responseObj,
                    key: 'value'
                }
            });
            this.setState({ taskOrderOptions });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    fetchProjectOrders = async () => {
        try {
            const { contractid } = this.props.contract;
            const { data: responseObj } = await projectOrderCrud.getProjectOrdersToSelectList(contractid);
            const projectOrderOptions = responseObj.map(TO => ({
                value: TO.projectorderid,
                reference: TO.referenceid,
                display: `${TO.referenceid} - ${TO.subject}`
            }));
            this.setState({ projectOrderOptions: projectOrderOptions });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    fieldChanged = (field, value, isDxFormValid, selectedItem) => {
        if (field) {
            if (field === 'Warning' && value) {
                this.addWarningItem(value);
            }
            else {
                if (this.props.valueChangeHandler) {
                    this.props.valueChangeHandler(field, value, isDxFormValid, selectedItem);

                    if (field === 'taskorderid') {
                        if (value) {
                            this.props.valueChangeHandler('taskorderid', value, isDxFormValid, selectedItem);
                            if (selectedItem) {
                                this.props.valueChangeHandler('taskorderreference', selectedItem.referenceid, isDxFormValid, selectedItem);
                            }
                        }
                        else {
                            this.props.valueChangeHandler('taskorderid', value, isDxFormValid, selectedItem);
                            this.props.valueChangeHandler('taskorderreference', "", isDxFormValid, selectedItem);
                        }
                    }

                    if (field === 'projectorderid') {
                        if (value) {
                            this.props.valueChangeHandler('projectorderid', value, isDxFormValid, selectedItem);
                            if (selectedItem) {
                                this.props.valueChangeHandler('projectorderreference', selectedItem.reference, isDxFormValid, selectedItem);
                            }
                        }
                        else {
                            this.props.valueChangeHandler('projectorderid', value, isDxFormValid, selectedItem);
                            this.props.valueChangeHandler('projectorderreference', "", isDxFormValid, selectedItem);
                        }
                    }
                }
                if (field === 'compensationtype') {
                    let selectedClause = null;
                    let globalResources = this.props.globalResources;
                    if (value) {
                        selectedClause = this.props.clauses.find(c => c.display === value);
                        if (selectedClause) {
                            globalResources = TemplateHelper.getGlobalResourcesOverriden(globalResources, selectedClause)
                        }
                    }
                    this.setState({ selectedClause, globalResources })
                }
            }

        }

    }
    renderWarningItem = (warning) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <DxButton style={{ marginLeft: 8, fontSize: '8px' }}
                    icon="close"
                    hint="Remove"
                    onClick={() => this.removeWarningItem(warning.warningid)}
                />
                <Link to={warning.url}><span style={{ padding: 8, whiteSpace: 'nowrap' }}><strong>{warning.reference}</strong> {warning.title}</span></Link>
            </div>
        );
    }

    addWarningItemIfConditionsMet = (ewList) => {
        //AUTO-SELECT EW DROPDOWN (IF REDIRECTED FROM CREATE-RELATED BUTTON)
        if (this.createDefaultFields && this.createDefaultFields.itemBaseProps && this.createDefaultFields.itemBaseProps.reftype === 'EarlyWarning' && ewList) {
            const ewOption = ewList.find(x => x.warningid == this.createDefaultFields.itemBaseProps.refid);
            if (ewOption) {
                const { contractid } = this.props.contract;
                const relatedWarnings = [...this.state.relatedWarnings];
                const ewItem = ewList.find(ew => ew.warningid == this.createDefaultFields.itemBaseProps.refid);
                const url = `/contract/${contractid}/early-warning/${ewItem.party === 'PM' ? 'pm' : 'supplier'}/${ewItem.warningid}`;

                const newWarningItem = {
                    warningid: ewItem.warningid,
                    title: ewItem.title,
                    url: url,
                    reference: ewItem.referenceid
                };
                relatedWarnings.push({ ...newWarningItem });

                this.setState({ relatedWarnings: relatedWarnings }, this.updateParentWarnings.bind(this, ewItem.warningid));
            }
        }
    }

    addWarningItem = (warningid) => {
        const { contractid } = this.props.contract;
        const relatedWarnings = [...this.state.relatedWarnings];
        const ewItem = this.state.earlyWarningList.find(ew => ew.warningid === warningid);
        const url = `/contract/${contractid}/early-warning/${ewItem.party === 'PM' ? 'pm' : 'supplier'}/${ewItem.warningid}`;

        const newWarningItem = {
            warningid: ewItem.warningid,
            title: ewItem.title,
            url: url,
            reference: ewItem.referenceid
        };
        relatedWarnings.push({ ...newWarningItem });

        this.setState({ relatedWarnings: relatedWarnings }, this.updateParentWarnings.bind(this, ewItem.warningid));
    }
    updateParentWarnings = (addedWarningID, removedWarningID) => {
        if (this.props.onWarningsChanged) {
            this.props.onWarningsChanged(addedWarningID, removedWarningID);
        }
    }
    removeWarningItem = (warningid) => {
        const relatedWarnings = [...this.state.relatedWarnings];
        const removedIndex = relatedWarnings.findIndex(dt => dt.warningid === warningid);
        relatedWarnings.splice(removedIndex, 1);
        this.setState({ relatedWarnings: relatedWarnings }, this.updateParentWarnings.bind(this, null, warningid));
    }
    onSubmitHandler = (compensation, setSubmitting) => {
        if (this.props.onSubmitHandler) {
            delete compensation.Warning;
            this.props.onSubmitHandler(compensation, setSubmitting, [...this.state.relatedWarnings]);
        }
    }
    render() {
        if (
            (this.props.ewRelationEnabled && (!this.state.relatedWarnings || !this.state.earlyWarningList)) ||
            ((this.props.taskOrderOptionEnabled && !this.state.taskOrderOptions) ||
                (this.props.projectOrderOptionEnabled && !this.state.projectOrderOptions))
        ) {
            return (<Spinner />);
        }
        
        const createFormConfig = {
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'compensationnumber', editorOptions: { defaultValue: this.createDefaultSubject } },
            ]
        };

        if (this.props.clauses) {
            createFormConfig.fields.push(
                { resource: "GENERAL.FORMS.FIELD.TYPE", dataField: 'compensationtype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.clauses, valueExpr: 'display', displayExpr: 'display' } },
            );
        }
        if (this.props.taskOrderOptionEnabled) {
            //AUTO-SELECT TASK ORDER DROPDOWN (IF REDIRECTED FROM CREATE-RELATED BUTTON)
            let defaultTaskOrderSelection = null;
            if (this.createDefaultFields?.itemBaseProps?.reftype === 'TaskOrder') {
                defaultTaskOrderSelection = Number(this.createDefaultFields.itemBaseProps.refid);
            }

            createFormConfig.fields.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.TASK_ORDER' }, { TASK_ORDER: this.props.taskOrderNotificationName }), dataField: 'taskorderid', editorType: 'dxSelectBox', required: false, editorOptions: { dataSource: this.state.taskOrderOptions, valueExpr: 'taskorderid', displayExpr: 'display', defaultValue: defaultTaskOrderSelection } });
        }
        if (this.props.projectOrderOptionEnabled) {
            //AUTO-SELECT PROJECT ORDER DROPDOWN (IF REDIRECTED FROM CREATE-RELATED BUTTON)
            let defaultProjectOrderSelection = null;
            if (this.createDefaultFields?.itemBaseProps?.reftype === 'ProjectOrder') {
                defaultProjectOrderSelection = Number(this.createDefaultFields.itemBaseProps.refid);
            }

            createFormConfig.fields.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PROJECT_ORDER' }, { PROJECT_ORDER: this.props.projectOrderNotificationName }), dataField: 'projectorderid', editorType: 'dxSelectBox', required: false, editorOptions: { dataSource: this.state.projectOrderOptions, valueExpr: 'value', displayExpr: 'display', defaultValue: defaultProjectOrderSelection } });
        }
        if (this.dateInstructionAllowed && this.props.fields.ProposedInstructionGivenDate) {
            createFormConfig.fields.push({ label: this.props.fields.ProposedInstructionGivenDate, dataField: 'awaredate', editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: addDays(new Date(), this.state.globalResources.PROP_QUOTE_DURATION) } }, validation: { type: 'range', min: moment().add(this.state.globalResources.PROP_QUOTE_DURATION - 1, 'days').toDate(), max: addYears(new Date(), 10), warning: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_DATE' }) } });
        }
        createFormConfig.fields.push(
            { resource: "GENERAL.FORMS.FIELD.DETAILS", dataField: 'description', editorType: 'dxHtmlEditor' },
        );
        if (this.props.resources.ProposedInstructionNote) {
            createFormConfig.fields.push(
                { note: this.props.resources.ProposedInstructionNote.replace('$date', moment().add(this.state.globalResources.PROP_QUOTE_DURATION, 'days').format('L')) }
            )
        }
        if (this.props.fields.Assumption) {
            createFormConfig.fields.push(
                { label: this.props.fields.Assumption, dataField: 'assumption', editorType: 'dxHtmlEditor' }
            );
        }
        if (this.props.ewRelationEnabled) {
            let availableOptions = this.state.earlyWarningList
                .filter(ew => this.state.relatedWarnings.findIndex(sew => sew.warningid === ew.warningid) === -1)
                .map(ew => {
                    return { value: ew.warningid, display: ew.referenceid + '  ' + ew.title };
                });

            const warningConfigGroup = {
                label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.LABEL.ASSOCIATED_EW' }, { EARLY_WARNING: this.props.ewNotificationName }),
                fields: [
                    { label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.LABEL.SELECT_EW' }, { EARLY_WARNING: this.props.ewNotificationName }), dataField: 'Warning', required: false, editorType: 'dxSelectBox', editorOptions: { dataSource: availableOptions, valueExpr: 'value', displayExpr: 'display', placeholder: availableOptions.length === 0 ? this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.LABEL.NO_EW' }, { EARLY_WARNING: this.props.ewNotificationName }) : this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.LABEL.SELECT_EW' }, { EARLY_WARNING: this.props.ewNotificationName }) } },
                ]
            };
            const ewResorce = this.props.ewNotificationName;
            this.state.relatedWarnings.forEach((warning, index) => {
                warningConfigGroup.fields.push({
                    label: `${ewResorce} ${index + 1}`,
                    render: () => this.renderWarningItem(warning)
                })
            });

            createFormConfig.fields.push(warningConfigGroup);
        }

        return (
            <>
                {this.props.resources.PmCompensationWarningBanner && (
                    <Alert variant="warning" >
                        <p>
                            {this.props.resources.PmCompensationWarningBanner}
                        </p>
                    </Alert>
                )}
                <BiDynamicForm
                    item={this.props.item}
                    config={createFormConfig}
                    onSubmitHandler={this.props.onSubmitHandler ? this.onSubmitHandler : null}
                    valueChangeHandler={this.fieldChanged}
                    customFields={this.props.customFields}
                    assetFieldsEnabled={this.props.assetFieldsEnabled}
                    assetid={this.createDefaultAsset}
                />
            </>
        );
    }
}

const mapStateToProps = store => ({
    // contract: store.contract.contract,
    // globalResources: store.contract.template.resources,
});


export default injectIntl(withSnackbar(connect(mapStateToProps)(InstructionCustomForm)));
