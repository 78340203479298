
import axios from '../shared/axios';
import CrudHelper from "./helper";



/**
 * 
 * @param {number} companyid company ID
 * @param {number} displayType 0-Active(default),1-Inactive,99-all
 */
export function getCompanyUsersV2(companyid, displayType = 0) {
  return axios.post(`/api/CompanyUser/LoadCompanyUsersListV2?displayType=${displayType}&companyid=${companyid}`);
}
export function getCompanyUsersToSelectList(companyid) {
  return axios.get(`/api/CompanyUser/ToSelectList`, { params: { companyid } });
}

export function getCompanyUsersToSelectListForContract(companyid,contractid) {
  return axios.get(`/api/CompanyUser/ToSelectListAvailableUsersForContract`, { params: { companyid, contractid } });
}


export function createAndAddUser(data) {
  data = CrudHelper.appendCustomFields(data);
  return axios.post('/api/CompanyUser/CreateAndAssignCompanyUser', data)
}

export function updateCompanyUser(data) {
  data = CrudHelper.appendCustomFields(data);
  return axios.post('/api/Administrator/UpdateUser', data)
}

export function getUserTypeToSelectList() {
  return axios.get(`/api/CompanyUser/GetUserTypeToSelectList`);
}