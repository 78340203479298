import { userFilterCategories } from "../../../shared/lookup";
const workflows = [
    {
        id: 4,
        title: '$PM_NAME Instructions',
        notificationName: 'Instruction',
        abbreviation: '$PM_ABBREVIATION-Instruction-',
        notifyingParty: 'PM',
        replyingParty: 'SUPPLIER',

        replyEnabled: false,
        replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',


        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM to Supplier Instructions',
            currentModel: 'InspectionDefect',
            currentModelType: 'Instruction',
            referenceType: 'PMInstruction',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["SUPPLIER_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 56,
        title: '$CLIENT_NAME Instructions',
        path: '',
        notificationName: 'Instruction',
        abbreviation: '$CLIENT_NAME-Instruction-',
        notifyingParty: 'CLIENT',
        replyingParty: 'PM',

        replyEnabled: false,
        replyPeriodOption: "PM_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',


        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Client to PM Instructions',
            currentModel: 'InspectionDefect',
            currentModelType: 'InstructionClient',
            referenceType: 'PMInstruction',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["PM_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'client',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 61,
        title: '$SUPERVISOR_NAME Instructions',
        notificationName: 'Instruction',
        abbreviation: '$SUPERVISOR_NAME-Instruction-',
        notifyingParty: 'SUPERVISOR',
        replyingParty: 'SUPPLIER',

        replyEnabled: false,
        replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',


        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supervisor to Supplier Instructions',
            currentModel: 'InspectionDefect',
            currentModelType: 'InstructionSupv',
            referenceType: 'PMInstruction',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["SUPPLIER_REPLY_PERIOD", "CUSTOM", "ASKED_ON_COMMUNICATION_FORM"],

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supervisor',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    }
]

export default workflows;