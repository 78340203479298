/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import { Alert } from "react-bootstrap";
import Spinner from '../../../../partials/layout/Spinner';
import * as compensationEventCrud from '../../../../crud/compensationEvent.crud';
import { injectIntl } from "react-intl";
import { confirm } from 'devextreme/ui/dialog';
import * as taskOrderCrud from '../../../../crud/taskOrder.crud';
import * as projectOrderCrud from '../../../../crud/projectOrder.crud';
import DataSource from 'devextreme/data/data_source';

class AssessmentCustomForm extends React.Component {
    constructor(props) {
        super(props);
        this.createDefaultSubject = (((props.location || {}).state || {}).itemCustomProps || {}).subject;
        this.createDefaultAsset = (((props.location || {}).state || {}).itemCustomProps || {}).assetid;
        this.createDefaultFields = ((props.location || {}).state || {});

        this.validatorRef = props.validatorRef ? props.validatorRef : React.createRef();

        this.interimSelectionOptions = [
            { value: 0, display: "FINAL" },
            { value: 1, display: "INTERIM" }
        ];

        this.state = {
            isDxFormValid: true,
            compensations: null,
            selectedCompensation: null,
            taskOrderOptions: null,
            projectOrderOptions: null,
            dateSectionFilters: []
        };
    }
    componentDidMount() {
        if (this.props.item) {
            this.setDateSelectionFilters(this.props.item);
        }
        if (this.props.mode === "PM") {
            this.fetchCompensations();
        }
        if (this.props.mode === "PM_TIME" && this.props.taskOrderOptionEnabled) {
            this.fetchTaskOrders();
        }
        if (this.props.mode === "PM_TIME" && this.props.projectOrderOptionEnabled) {
            this.fetchProjectOrders();
        }
    }
    fetchCompensations = async () => {
        try {
            const { contractid } = this.props.contract;
            const assessmentStates = ['APMA', 'ARQ', 'APMRQ', 'AQ'];
            const { data: responseObj } = await compensationEventCrud.getCompansationEventsToSelectList(contractid, assessmentStates);
            let comps = responseObj.map(itm => {
                let reference = itm.referenceid;

                let reason = null;
                if (itm.status === 'APMA') {
                    reason = 'Not Accepted';
                }
                else if (itm.status === 'AQ' || itm.status === 'ARQ') {
                    if (new Date(itm.requiredate) < new Date()) {
                        reason = 'Expired';
                    }
                }

                return {
                    value: itm.compensationeventid,
                    reference: reference,
                    reason: reason,
                    title: itm.compensationnumber,
                    type: itm.compensationsource,
                    taskorderid: itm.taskorderid,
                    projectorderid: itm.projectorderid,
                    display: `${reference}   {${itm.compensationnumber}}` + (reason ? ` [${this.props.intl.formatMessage({ id: "OPTIONS.STATUS." + reason })}]` : '')
                }
            });

            // the right hand side of the operator ?? added for the case that comes from create related pm assessment in pm ce
            // to be able to filter secondary dates according to task/project order ids in setDateSelectionFilters that are related to the populated ce
            const clause = (this.props.item || {}).replyactiondetail ?? this.createDefaultFields?.itemBaseProps?.reftype === 'CompensationEvent' ? Number(this.createDefaultFields?.itemBaseProps?.refid) : null;
            const selectedCompensation = comps.find(x => x.value === clause);

            if (selectedCompensation) {
                if (this.props.ceTypeHandler) {
                    this.props.ceTypeHandler(selectedCompensation.type);
                }
            }

            this.setState({ compensations: comps, selectedCompensation: selectedCompensation }, () => this.setSelectedCEIfConditionsMet(comps), this.setDateSelectionFilters(selectedCompensation));
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    fetchTaskOrders = async () => {
        try {
            const { contractid } = this.props.contract;
            const { data: responseObj } = await taskOrderCrud.getTaskOrdersToSelectList(contractid);
            //COVERTED TO DATASOURCE WITH FD-11007 DUE TO PERFORMENCE ISSUES AFTER 3K ITEMS
            const taskOrderOptions = new DataSource({
                store: {
                    type: 'array',
                    data: responseObj,
                    key: 'value'
                }
            });
            this.setState({ taskOrderOptions });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    fetchProjectOrders = async () => {
        try {
            const { contractid } = this.props.contract;
            const { data: responseObj } = await projectOrderCrud.getProjectOrdersToSelectList(contractid);
            const projectOrderOptions = responseObj.map(TO => ({
                value: TO.projectorderid,
                reference: TO.referenceid,
                display: `${TO.referenceid} - ${TO.subject}`
            }));
            this.setState({ projectOrderOptions: projectOrderOptions });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    setSelectedCEIfConditionsMet = (ceList) => {
        if (this.createDefaultFields?.itemBaseProps?.reftype === 'CompensationEvent' && ceList) {
            const ceOption = ceList.find(x => x.value == this.createDefaultFields.itemBaseProps.refid);
            if (ceOption) {
                this.setState({ selectedCompensation: ceOption });
            }
        }
    }
    //This is added for CE/PM Assessment wf
    setDateSelectionFilters = (selectedItem) => {
        // Extract taskorderid and projectorderid from selected item
        const taskOrderId = selectedItem ? selectedItem.taskorderid : null;
        const projectOrderId = selectedItem ? selectedItem.projectorderid : null;

        // Set dateSectionFilters state
        const dateSectionFilters = [
            { sourcedmodel: "TaskOrder", sourcedrecordid: taskOrderId },
            { sourcedmodel: "ProjectOrder", sourcedrecordid: projectOrderId }
        ];

        this.setState({ dateSectionFilters });
    }

    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        this.state.isDxFormValid = isDxFormValid;
        if (field) {
            if (field === 'replyactiondetail' && this.props.mode === "PM") {
                if (value) {
                    const selectedCompensation = this.state.compensations.find(x => x.value === value);

                    this.setState({ selectedCompensation });

                    // Extract taskorderid and projectorderid from selected compensation
                    const taskOrderId = selectedCompensation ? selectedCompensation.taskorderid : null;
                    const projectOrderId = selectedCompensation ? selectedCompensation.projectorderid : null;

                    // Set dateSectionFilters state
                    const dateSectionFilters = [
                        { sourcedmodel: "TaskOrder", sourcedrecordid: taskOrderId },
                        { sourcedmodel: "ProjectOrder", sourcedrecordid: projectOrderId }
                    ];

                    this.setState({ dateSectionFilters });
                    
                    if (this.props.valueChangeHandler) {
                        const compOpt = this.state.compensations.find(x => x.value === value);
                        this.props.valueChangeHandler('selectedCompensationType', compOpt.type, isDxFormValid, additionalData);
                        this.props.valueChangeHandler('replyaction', compOpt.reference, isDxFormValid, additionalData);
                        this.props.valueChangeHandler('location', compOpt.title, isDxFormValid, additionalData);
                        this.props.valueChangeHandler('reason', compOpt.reason, isDxFormValid, additionalData);
                        this.props.valueChangeHandler('replyactiondetail', value, isDxFormValid, additionalData);
                    }
                }
                else {
                    if (this.props.valueChangeHandler) {
                        this.props.valueChangeHandler('replyaction', value, isDxFormValid, additionalData);
                    }
                }
            } else if (this.props.mode === 'PM_TIME' && field === 'taskorderid') {
                if (value) {
                    const newItem = { sourcedmodel: "TaskOrder", sourcedrecordid: value };

                    // this.setState(prevState => ({
                    //     dateSectionFilters: [...prevState.dateSectionFilters, newItem]
                    // }));
                    this.setState(prevState => {
                        // Filter out any existing "TaskOrder" items
                        const filteredFilters = prevState.dateSectionFilters.filter(
                            item => item.sourcedmodel !== 'TaskOrder'
                        );
                        // Add the new item to the filtered list
                        return {
                            dateSectionFilters: [...filteredFilters, newItem]
                        };
                    });
                } else {
                    this.setState(prevState => ({
                        dateSectionFilters: prevState.dateSectionFilters.filter(
                            item => (item.sourcedmodel !== 'TaskOrder')
                        )
                    }));
                }
                if (this.props.valueChangeHandler) {
                    this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);

                    if (this.props.mode === 'PM_TIME') {
                        if (field === 'taskorderid') {
                            if (value) {
                                this.props.valueChangeHandler('taskorderid', value, isDxFormValid, additionalData);
                                if (additionalData) {
                                    this.props.valueChangeHandler('taskorderreference', additionalData.referenceid, isDxFormValid, additionalData);
                                }
                            }
                            else {
                                this.props.valueChangeHandler('taskorderid', value, isDxFormValid, additionalData);
                                this.props.valueChangeHandler('taskorderreference', "", isDxFormValid, additionalData);
                            }
                        }
                    }
                }
            } else if (this.props.mode === 'PM_TIME' && field === 'projectorderid') {
                if (value) {
                    const newItem = { sourcedmodel: "ProjectOrder", sourcedrecordid: value };

                    // this.setState(prevState => ({
                    //     dateSectionFilters: [...prevState.dateSectionFilters, newItem]
                    // }));
                    this.setState(prevState => {
                        // Filter out any existing "ProjectOrder" items
                        const filteredFilters = prevState.dateSectionFilters.filter(
                            item => item.sourcedmodel !== 'ProjectOrder'
                        );
                        // Add the new item to the filtered list
                        return {
                            dateSectionFilters: [...filteredFilters, newItem]
                        };
                    });
                } else {
                    this.setState(prevState => ({
                        dateSectionFilters: prevState.dateSectionFilters.filter(
                            item => (item.sourcedmodel !== 'ProjectOrder')
                        )
                    }));
                }
                if (this.props.valueChangeHandler) {
                    this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);

                    if (this.props.mode === 'PM_TIME') {
                        if (field === 'projectorderid') {
                            if (value) {
                                this.props.valueChangeHandler('projectorderid', value, isDxFormValid, additionalData);
                                if (additionalData) {
                                    this.props.valueChangeHandler('projectorderreference', additionalData.reference, isDxFormValid, additionalData);
                                }
                            }
                            else {
                                this.props.valueChangeHandler('projectorderid', value, isDxFormValid, additionalData);
                                this.props.valueChangeHandler('projectorderreference', "", isDxFormValid, additionalData);
                            }
                        }
                    }
                }
            } else {
                if (this.props.valueChangeHandler) {
                    this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);
                }
            }
        }

    }
    confirmPositiveAmount = async (notification) => {
        if (this.state.selectedCompensation?.type === 'CLIENTCWN' || this.state.selectedCompensation?.type === 'CLIENT') {
            if (notification.replycomment > 0) { // amount field
                const warningMessage = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POSITIVE_AMOUNT_WARNING' });
                const warningMessageLabel = this.props.intl.formatMessage({ id: 'GENERAL.FORMS.POSITIVE_AMOUNT_WARNING_LABEL' });
                let result = await confirm(`<div style="max-width:50vw;">${warningMessage}</div>`, `${warningMessageLabel}`);
                return result;
            }
            
            return true;
        }

        return true;
    }
    onSubmitHandler = async (notification, setSubmitting) => {
        const positiveAmountConfirmed = await this.confirmPositiveAmount(notification);

        if (!positiveAmountConfirmed) {
            //if not confirmed exit save
            setSubmitting(false);
            return;
        } else {
            if (notification.replyactiondetail && this.props.mode === "PM") {
                const compOpt = this.state.compensations.find(x => x.value === notification.replyactiondetail);
                notification.replyaction = compOpt.reference;
                notification.location = compOpt.title;
                notification.reason = compOpt.reason;
            }
            this.props.onSubmitHandler(notification, setSubmitting);
        }
    }
    render() {
        if ((this.props.mode === "PM" && !this.state.compensations) ||
        (this.props.mode === "PM_TIME" && this.props.taskOrderOptionEnabled && !this.state.taskOrderOptions) ||
        (this.props.mode === "PM_TIME" && this.props.projectOrderOptionEnabled && !this.state.projectOrderOptions)) {
            return (<Spinner />);
        }

        const visibleConditionEffectField = (item) => {
            if (this.state.selectedCompensation && (this.state.selectedCompensation.type === "CWN" || this.state.selectedCompensation.type === "CLIENTCWN")) {
                return true;
            }
            return false;
        }

        const createFormConfig = {
            currencyCode: this.props.contract.currency,
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: []
        };
        if (this.props.mode !== "PM_TIME") {
            let selectEditorOptions = null;
            if (this.props.mode === "PM") {
                //AUTO-SELECT CE DROPDOWN (IF REDIRECTED FROM CREATE-RELATED BUTTON)
                let defaultCESelection = null;
                if (this.createDefaultFields?.itemBaseProps?.reftype === 'CompensationEvent' && this.state.compensations) {
                    const ceOption = this.state.compensations.find(x => x.value == this.createDefaultFields.itemBaseProps.refid);
                    if (ceOption) {
                        defaultCESelection = ceOption.value;
                    }
                }
                
                selectEditorOptions = { dataSource: this.state.compensations, valueExpr: 'value', displayExpr: 'display', defaultValue: defaultCESelection };
            }
            else {
                selectEditorOptions = { dataSource: this.props.clauses, valueExpr: 'display', displayExpr: 'display' };
            }
            createFormConfig.fields.push({ label: this.props.resources.AssessmentCompensationLabel, dataField: 'replyactiondetail', editorType: 'dxSelectBox', editorOptions: selectEditorOptions });
        }
        if (this.props.mode === "PM" && this.props.interimQuotesAllowed) {
            createFormConfig.fields.push({
                label: "Continuing Effect",
                dataField: 'continuingeffect',
                editorType: 'dxSelectBox',
                visible: visibleConditionEffectField,
                editorOptions: { dataSource: this.interimSelectionOptions, valueExpr: 'value', displayExpr: 'display' },
                dependOn: 'replyactiondetail',
                dependOnValue: (item, additionalData) => { if (additionalData && (additionalData.type === "CWN" || additionalData.type === "CLIENTCWN")) { return item.continuingeffect; } else { return null; } }
            });
        }
        createFormConfig.fields.push({ resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'assistancetestserchdefect', editorOptions: { defaultValue: this.createDefaultSubject } });
        if (this.props.mode === "PM_TIME") {
            if (this.props.taskOrderOptionEnabled) {
                //AUTO-SELECT TASK ORDER DROPDOWN (IF REDIRECTED FROM CREATE-RELATED BUTTON)
                let defaultTaskOrderSelection = null;
                if (this.createDefaultFields?.itemBaseProps?.reftype === 'TaskOrder') {
                    defaultTaskOrderSelection = Number(this.createDefaultFields.itemBaseProps.refid);
                }
    
                createFormConfig.fields.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.TASK_ORDER' }, { TASK_ORDER: this.props.taskOrderNotificationName }), dataField: 'taskorderid', editorType: 'dxSelectBox', required: false, editorOptions: { dataSource: this.state.taskOrderOptions, valueExpr: 'taskorderid', displayExpr: 'display', defaultValue: defaultTaskOrderSelection } });
            }
            if (this.props.projectOrderOptionEnabled) {
                //AUTO-SELECT PROJECT ORDER DROPDOWN (IF REDIRECTED FROM CREATE-RELATED BUTTON)
                let defaultProjectOrderSelection = null;
                if (this.createDefaultFields?.itemBaseProps?.reftype === 'ProjectOrder') {
                    defaultProjectOrderSelection = Number(this.createDefaultFields.itemBaseProps.refid);
                }
    
                createFormConfig.fields.push({ label: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.PROJECT_ORDER' }, { PROJECT_ORDER: this.props.projectOrderNotificationName }), dataField: 'projectorderid', editorType: 'dxSelectBox', required: false, editorOptions: { dataSource: this.state.projectOrderOptions, valueExpr: 'value', displayExpr: 'display', defaultValue: defaultProjectOrderSelection } });
            }
        }
        if (this.props.editingProperties.price) {
            createFormConfig.fields.push({ label: this.props.resources.AssessmentCostEffectLabel, dataField: 'replycomment', editorType: 'dxNumberBox' });
        }
        if (this.props.editingProperties.time) {
            createFormConfig.fields.push({ label: this.props.resources.AssessmentDaysEffectLabel, dataField: 'inspectiontime', editorType: 'dxNumberBox', editorOptions: { format: '#,##0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' }), placeholder: '0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' }) } });
        }
        createFormConfig.fields.push({ label: this.props.resources.AssessmentDetailLabel, dataField: 'inspectiondetails', editorType: 'dxHtmlEditor' });

        return (
            <div>
                {this.props.resources.AssessmentWarningBanner && (
                    <Alert variant="warning" >
                        <span>
                            {
                                this.props.resources.AssessmentWarningBanner.split("\n").map((line, index) => {
                                    return <div key={index}>{line}</div>
                                }
                                )
                            }
                        </span>
                    </Alert>
                )}
                <BiDynamicForm
                    validatorRef={this.validatorRef}
                    item={this.props.item}
                    config={createFormConfig}
                    onSubmitHandler={this.props.onSubmitHandler ? this.onSubmitHandler : null}
                    valueChangeHandler={this.fieldChanged}
                    customFields={this.props.customFields}
                    assetFieldsEnabled={this.props.assetFieldsEnabled}
                    assetid={this.createDefaultAsset}
                    secondaryPriceSectionEnabled={this.props.mode === "PM" || this.props.mode === "PM_PRICE"}
                    secondaryDateSectionEnabled={this.props.mode === "PM" || this.props.mode === "PM_TIME"}
                    priceDateSectionFilters={this.state.dateSectionFilters?.length > 0 ? this.state.dateSectionFilters : [
                        { sourcedmodel: "TaskOrder", sourcedrecordid: this.props.item?.taskorderid },
                        { sourcedmodel: "ProjectOrder", sourcedrecordid: this.props.item?.projectorderid }
                    ]}
                    interimQuotesAllowed={this.props.interimQuotesAllowed}
                />
            </div>
        );
    }
}

const mapStateToProps = store => ({
    // contract: store.contract.contract,
    // contractTemplate: store.contract.template,
    // globalResources: store.contract.template.resources
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(AssessmentCustomForm)));