import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import QuoteCreate from './Create';
import QuoteDetail from './Detail';
import QuoteList from './List';
import Common from '../../../../shared/common';

export const itqQuotableStatuses = ['AQ', 'APMRQ', 'ARQ'];

const defaultFields = {
    Description: null,
    ItemsList: null,
    TotalListPrice: null,
    InstructionToQuoteDescription: null,
    TotalAssessed: null,
    TotalPrice: null,
    StartDate: null,
    CompletionDate: null,
    DelayAmount: null,
    DelayPaymentPeriod: null,
    Comments: null,
}

class QuoteRouter extends React.Component {
    constructor(props) {
        super(props);

        this.notifyingParty = props.parentWorkflowItem.quotingParty;
        this.replyingParty = props.parentWorkflowItem.quoteReplyingParty;

        this.communicateToUser = props.globalResources[this.replyingParty + "_NAME"];

        this.configurableITQFields = {};

        try {
            const projectITQFields = props.placeholders.find(ph => ph.replacementKey === 'ITQFields');

            if (projectITQFields) {
                projectITQFields.values.forEach(fieldItem => {
                    this.configurableITQFields[fieldItem.field] = fieldItem.isActive ? fieldItem.display : '';
                });
            }
        } catch (error) {
            console.log('Error loading ITQ Fields Placeholder!');
        }

        this.notificationType = props.parentWorkflowItem.config.quotationType;
        this.documentType = props.parentWorkflowItem.config.quotationDocumentType;


        this.referenceType = 'ItqQuotation';


        this.notificationAbbreviation_ITQ = (this.props.workflowsQA['72'] || {}).abbreviation;

        const baseNotification = this.props.itq;
        this.notificationName = props.parentWorkflowItem.quoteNotificationName;
        this.abbreviation = props.parentWorkflowItem.quoteReferencePrefix;
        this.pageTitle = this.props.intl.formatMessage({ id: 'GENERAL.NOTICE.TITLE' }, { ITEM_NAME: this.notificationName, PARTY_NAME: props.globalResources[this.notifyingParty + "_NAME"] });

        this.fields = { ...defaultFields };
        this.fields.Description = this.props.resources.DescriptionLabel;
        this.fields.ItemsList = this.props.resources.ItemsListLabel;
        this.fields.TotalListPrice = this.props.resources.TotalListPriceLabel;
        this.fields.InstructionToQuoteDescription = this.props.resources.InstructionToQuoteDescription;
        this.fields.TotalAssessed = this.props.resources.TotalAssessedLabel;
        this.fields.TotalPrice = this.props.resources.TotalPriceLabel;
        this.fields.StartDate = this.props.resources.StartDateLabel;
        this.fields.CompletionDate = this.props.resources.CompletionDateLabel;
        this.fields.DelayAmount = this.props.resources.DelayAmountLabel;
        this.fields.DelayPaymentPeriod = this.props.resources.DelayPaymentPeriodLabel;
        this.fields.Comments = this.props.resources.Comments;

        //Create a Custom WorkflowItem for Quote as its not a WF standalone
        this.workflowItem = {
            ...props.parentWorkflowItem,
            pageTitle: this.pageTitle,
            notificationName: this.notificationName,
            abbreviation: this.abbreviation,
            replyEnabled: true,
            notifyingParty: props.parentWorkflowItem.quotingParty,
            replyingParty: props.parentWorkflowItem.quoteReplyingParty,
            notifyAttachments: props.parentWorkflowItem.notifyAttachments,
            replyAttachments: props.parentWorkflowItem.notifyAttachments,
            includePdfjson: props.parentWorkflowItem.includePdfjson,
            userFilters: {
                ...props.parentWorkflowItem.userFilters,
                mainNotice: {
                    createFilter: props.parentWorkflowItem.userFilters.quoteMainNotice.createQuoteFilter,
                    createAllowed: props.parentWorkflowItem.userFilters.quoteMainNotice.createQuoteAllowed,
                    viewDraftFilter: props.parentWorkflowItem.userFilters.quoteMainNotice.createQuoteFilter,
                    viewDraftAllowed: props.parentWorkflowItem.userFilters.quoteMainNotice.createQuoteAllowed,
                    communicateFilter: props.parentWorkflowItem.userFilters.quoteMainNotice.communicateQuoteFilter,
                    communicateAllowed: props.parentWorkflowItem.userFilters.quoteMainNotice.communicateQuoteAllowed
                },
                replyNotice: {
                    saveDraftReplyFilter: props.parentWorkflowItem.userFilters.quoteReplyNotice.saveQuoteDraftReplyFilter,
                    saveDraftReplyAllowed: props.parentWorkflowItem.userFilters.quoteReplyNotice.saveQuoteDraftReplyAllowed,
                    communicateReplyFilter: props.parentWorkflowItem.userFilters.quoteReplyNotice.communicateQuoteReplyFilter,
                    communicateReplyAllowed: props.parentWorkflowItem.userFilters.quoteReplyNotice.communicateQuoteReplyAllowed
                }
            },
            paths: {
                ...props.parentWorkflowItem.paths,
                basePath: props.parentWorkflowItem.paths.basePath + baseNotification.instructiontoquoteid + "/itqquote/",
                createPath: props.parentWorkflowItem.paths.basePath + baseNotification.instructiontoquoteid + "/itqquote/create",
                listPath: props.parentWorkflowItem.paths.basePath + baseNotification.instructiontoquoteid,
                defaultPath: props.parentWorkflowItem.paths.basePath + baseNotification.instructiontoquoteid,
            },
            config: {
                ...props.parentWorkflowItem.config
            },
            associatedWFListAllowed: null
        };

        this.workflowItem.form_NotifyCompanyLogoID = Common.GetPartyOrUserCompanyID(props.contract, this.workflowItem.form_CompanyLogo, this.workflowItem.notifyingParty, props.userProfile);
        this.workflowItem.form_NotifyCompanyName = Common.GetPartyOrUserCompanyName(props.contract, this.workflowItem.form_CompanyName, this.workflowItem.notifyingParty, props.userProfile);
        this.workflowItem.form_ReplyCompanyLogoID = Common.GetPartyOrUserCompanyID(props.contract, this.workflowItem.form_CompanyLogo, this.workflowItem.replyingParty, props.userProfile);
        this.workflowItem.form_ReplyCompanyName = Common.GetPartyOrUserCompanyName(props.contract, this.workflowItem.form_CompanyName, this.workflowItem.replyingParty, props.userProfile);

        try {
            const QuoteFields = props.placeholders.find(ph => ph.replacementKey === 'QuoteFields');
            if (QuoteFields) {
                QuoteFields.values.forEach(fieldItem => {
                    this.fields[fieldItem.field] = fieldItem.isActive ? fieldItem.display : '';
                });
            }
        } catch (error) {
            console.log('Error loading Quote Fields Placeholder!');
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.itq !== nextProps.itq) {
            return true;
        }
        return false;
    }
    render() {
        const baseNotification = this.props.itq;
        const baseNotificationReadyForCreate = itqQuotableStatuses.includes(baseNotification.status);

        return (
            <Routes caseSensitive>
                <Route
                    path={'/itqquote/create'}
                    exact
                    element={
                        baseNotificationReadyForCreate && this.workflowItem.userFilters.quoteMainNotice.createQuoteAllowed ? (
                            <QuoteCreate
                                {...this.props}
                                fields={this.fields}
                                quoteImpactOptions={this.quoteImpactOptions}
                                mode={this.props.mode}
                                itq={baseNotification}
                                parentTabPanel={this.props.parentTabPanel}
                                
                                communicateToUser={this.communicateToUser}
                                title={this.notificationName}
                                abbreviation={this.abbreviation}
                                notificationType={this.notificationType}
                                workflowItem={this.workflowItem}
                                configurableITQFields={this.configurableITQFields}
                            />) : (
                            <Navigate replace to={this.workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/itqquote/:quotationid'}
                    exact
                    element={<QuoteDetail
                        {...this.props}
                        fields={this.fields}
                        quoteImpactOptions={this.quoteImpactOptions}
                        notificationAbbreviation_ITQ={this.notificationAbbreviation_ITQ}
                        mode={this.props.mode}
                        parentTabPanel={this.props.parentTabPanel}
                        onItqChanged={this.props.onItqChanged}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                        documentType={this.documentType}
                        baseNotificationReadyForCreate={baseNotificationReadyForCreate}
                        communicateToUser={this.communicateToUser}
                        instructionToQuote={baseNotification}
                        notificationType={this.notificationType}
                        notifyUserFilter={this.props.notifyUserFilter}
                        workflowItem={this.workflowItem}
                        configurableITQFields={this.configurableITQFields}
                    />}
                />
                <Route
                    path="*"
                    element={<QuoteList
                        {...this.props}
                        fields={this.fields}
                        notificationAbbreviation_ITQ={this.notificationAbbreviation_ITQ}
                        mode={this.props.mode}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                        workflowItem={this.workflowItem}
                        notificationType={this.notificationType}
                        baseNotificationReadyForCreate={baseNotificationReadyForCreate}
                        reference={this.props.reference}
                        notifyingParty={this.notifyingParty}
                    />}
                />
            </Routes>
        );
    }
};

const mapStateToProps = store => ({
    //WE NEED userProfile because Common.GetPartyOrUserCompanyID is re-assigned depending on party
    userProfile: store.auth.userProfile
});

export default connect(mapStateToProps)(QuoteRouter);