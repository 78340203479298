import {placeholderTypes} from "../../Types/Placeholder";
const placeholders =  [
    {
        replacementKey: 'Resources',
        values: [
            { id: 1, isActive: true, field: 'CostForecastWarningBanner', display: 'The forecast is to be submitted at the interval stated in the Contract Data and in the format included in the Scope.' },
            { id: 2, isActive: true, field: 'CarbonForecastWarningBanner', display: 'The forecast is to be submitted at the interval stated in the Contract Data for Defined Cost forecast and in the format included in the Scope.' },
            //TODO: Once all internal approvals have been received to allow EA to apply the changes to the form this config will need to replace with the below one
            //{ id: 2, isActive: true, field: 'CarbonForecastWarningBanner', display: 'The carbon forecasts / actuals are to be submitted at the interval stated in the Contract Scope.' },
            { id: 3, isActive: true, field: 'TargetLabel', display: 'Target' },
            { id: 4, isActive: true, field: 'ForecastLabel', display: 'Forecast' },
            { id: 5, isActive: true, field: 'ActualLabel', display: 'Actual' },
            { id: 6, isActive: true, field: 'VarianceLabel', display: 'Variance' }
        ],
        type:placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'ActionReminders', values: [],
        type:placeholderTypes.ACTION_REMINDERS
    },
    {
        replacementKey: 'CostForecastClauses', values: [
            { id: 1, isActive: true, field: '', display: 'NEC 4 ECC Option A - no forecast, only original contract price and current contract price' },
            { id: 2, isActive: true, field: '', display: 'NEC 4 ECC Option C - as per Clause 20.4' },
            { id: 3, isActive: true, field: '', display: 'NEC 4 ECC Option E - as per Clause 20.4' },
            { id: 4, isActive: true, field: '', display: 'NEC 4 PSC Option A - as per Clause 20.4' },
            { id: 5, isActive: true, field: '', display: 'NEC 4 PSC Option C - as per Clause 20.5' },
            { id: 6, isActive: true, field: '', display: 'NEC 4 PSC Option E - as per Clause 20.5' },
            { id: 7, isActive: true, field: '', display: 'NEC 3 ECC Option A - no forecast, only original contract price and current contract price' },
            { id: 8, isActive: true, field: '', display: 'NEC 3 ECC Option C - as per Clause 20.4' },
            { id: 9, isActive: true, field: '', display: 'NEC 3 ECC Option E - as per Clause 20.4' },
            { id: 10, isActive: true, field: '', display: 'NEC 3 PSC Option A - as per Clause 21.3' },
            { id: 11, isActive: true, field: '', display: 'NEC 3 PSC Option C - as per Clause 21.4' },
            { id: 12, isActive: true, field: '', display: 'NEC 3 PSC Option E - as per Clause 21.4' }
        ],
        type:placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'CarbonForecastClauses', values: [
            { id: 1, isActive: true, field: '', display: 'NEC4 PSC - whole life carbon of the proposed solution' },
            { id: 2, isActive: true, field: '', display: 'NEC4 ECC - capital carbon of the built solution' },
        ],
        type:placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'CostForecastClausesEnabled', values:
            [
                { id: 1, isActive: false, field: 'CostForecastClausesEnabled', display: 'Active-Inactive state indicates "Type" field will be included in registers and and communication forms' },
            ],
        isToggle: true,
        fieldRequired: true,
        type:placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'CarbonForecastClausesEnabled', values:
            [
                { id: 1, isActive: false, field: 'CarbonForecastClausesEnabled', display: 'Active-Inactive state indicates "Type" field will be included in registers and communication forms' },
            ],
        isToggle: true,
        fieldRequired: true,
        type:placeholderTypes.TOGGLE_SWITCH
    },
    {
        replacementKey: 'Fields', values:
            [
                { id: 1, isActive: false, field: 'Target', display: 'Target' },
                { id: 2, isActive: true, field: 'Forecast', display: 'Forecast' },
                { id: 3, isActive: false, field: 'Actual', display: 'Actual' },
                { id: 4, isActive: false, field: 'Variance', display: 'Variance' }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    }
];
export default placeholders;