/* eslint-disable import/no-anonymous-default-export */
import Workflows from "./Workflows";
import Placeholders from "./Placeholders";
export default {
    id: 113,
    title: 'Contract Information',
    path: 'contract-information',
    icon: "flaticon2-files-and-folders",
    workflows: Workflows,
    placeholders: Placeholders,
};