import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import ExtensionCustomForm from './CustomForm';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import * as inspectionCrud from '../../../../crud/inspectionDefect.crud';
import moment from 'moment';
import { addDays } from 'date-fns';
import { FormattedMessage } from "react-intl";
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import Common from '../../../../shared/common';
import { actionCodes } from "../../../../shared/lookup";

class ExtensionCreatePage extends BaseCreatePage {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: true,
        };
    }

    submitHandler = (notification, setSubmitting) => {
        if (notification.inspectiondate && !notification.inspectiondatedisplay) {
            notification.inspectiondatedisplay = moment(notification.inspectiondate).format('DD/MM/YYYY HH:mm:ss');
        }
        if (this.props.workflowItem.replyPeriodOption === "ORIGINAL_DATE") {
            notification.replyrequireddate = notification.assistancetestserchdefect;
        }
        else {
            notification.replyrequireddate = moment(addDays(new Date(), this.props.replyPeriod)).toDate();
        }

        if (notification.assistancetestserchdefect && notification.assistancetestserchdefect instanceof Date) {
            notification.assistancetestserchdefect = moment(notification.assistancetestserchdefect).format('DD/MM/YYYY');
        }
        notification.companyid = this.props.contract.companyid;
        notification.contractid = this.props.contract.contractid;
        notification.projectid = this.props.contract.projectid;
        notification.replystatus = 'Not Communicated';
        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');
        notification.type = this.props.workflowItem.config.currentModelType;
        notification.actioncode = actionCodes.CREATE;
        notification.notifyingparty = this.props.workflowItem.notifyingParty;
        notification.replyingparty = this.props.workflowItem.replyingParty;
        
        this.appendRelationship(notification);
        Common.PropertyConversionsBeforeNoticeSaveRequest(notification, this.props.customFields, this.props.contractUsers);
        inspectionCrud.saveInspectionDefect(notification)
            .then(response => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
                const createdID = Number(response.data);
                this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });
    }

    render() {
        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    <ExtensionCustomForm
                        {...this.props}
                        onSubmitHandler={this.submitHandler}
                        customFields={(this.props.customFields || {}).COMMUNICATION}
                        assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(ExtensionCreatePage));
