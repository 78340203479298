import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { Badge } from "react-bootstrap";
import * as compensationEventCrud from '../../../../crud/compensationEvent.crud';
import moment from 'moment';
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper";
import { notificationAggregateListTagsFilter, notificationUniqueTagsFilter } from "../../../../store/helpers/FilterHelper";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';
import { getCompensationPathWithType } from './ListAll';

class CompensationListImplemented extends React.Component {
    constructor(props) {
        super(props);

        this.maxReferenceIDPrefix = 'CEN-';
        if (this.props.notificationAbbreviation_SUP && this.maxReferenceIDPrefix.length < this.props.notificationAbbreviation_SUP.length) {
            this.maxReferenceIDPrefix = this.props.notificationAbbreviation_SUP;
        }
        if (this.props.notificationAbbreviation_PM && this.maxReferenceIDPrefix.length < this.props.notificationAbbreviation_PM.length) {
            this.maxReferenceIDPrefix = this.props.notificationAbbreviation_PM;
        }
        if (this.props.notificationAbbreviation_CLIENT && this.maxReferenceIDPrefix.length < this.props.notificationAbbreviation_CLIENT.length) {
            this.maxReferenceIDPrefix = this.props.notificationAbbreviation_CLIENT;
        }

        this.compensationStatuses = props.placeholders
            .find(ph => ph.replacementKey === 'CompensationStatuses')
            .values.filter(item => item.isActive === true);

        this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.notificationsRelsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';
        this.customFields = [];
        if (props.customFields) {
            if (props.customFields.COMMUNICATION && props.customFields.COMMUNICATION.length > 0) {
                this.customFields.push(...(props.customFields.COMMUNICATION.filter(cf => cf.includelisting)));
            }
            if (props.customFields.REPLY && props.customFields.REPLY.length > 0) {
                this.customFields.push(...(props.customFields.REPLY.filter(cf => cf.includelisting)));
            }
        }
        this.state = {
            notifications: null,
            tags: null
        }
    }
    componentDidMount() {
        this.fetchCompensations();
    }
    fetchCompensations = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            //const states = ['AM', 'QA', 'DA'];
            const { data: responseObj } = await compensationEventCrud.getCompansationEventsReduced(companyid, projectid, contractid, '', this.props.accessibleWfIds);
            let listData = notificationAggregateListTagsFilter(responseObj.aggregatelist);
            listData = listData.filter(ntf => !!ntf.assessmentdate);
            let tagFilters = notificationUniqueTagsFilter(responseObj.uniquetags);
            this.setState({ notifications: listData, tags: tagFilters });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    formatCurrency = () => {

        return this.props.contract.currency
    }

    onTableSelectionChanged = ({ selectedRowsData }) => {
        //const { workflowItem } = this.props;
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        const { workflowItem } = this.props;
        const compensationPath = getCompensationPathWithType(rowData.compensationsource);
        return `${skipHostUrl ? '' : process.env.PUBLIC_URL}${workflowItem.paths.groupFullPath}${compensationPath}/${rowData.compensationeventid}`;
    }
    renderIdColumn = (rowData) => {
        if (rowData.compensationsource === 'PRO') {
            return rowData.referenceid_proposedinstructions
        }
        else {
            return rowData.referenceid
        }
    }


    renderQuoteReceived = (cellInfo) => {
        if (cellInfo.data.officereceiveddate) {
            let badgeVariant = 'secondary';
            const quoteRequiredDate = new Date(cellInfo.data.requiredate);
            const quoteReceiveDate = new Date(cellInfo.data.officereceiveddate);
            if (quoteRequiredDate < quoteReceiveDate) {
                badgeVariant = 'danger';
            }
            else {
                badgeVariant = 'success';
            }
            return (<Badge variant={badgeVariant}>{moment(cellInfo.data.officereceiveddate).format('L')}</Badge>);
        }
    }
    pmResponseReceived = (cellInfo) => {
        if (cellInfo.data.projectmanagerresponsedate) {
            let badgeVariant = 'secondary';
            const pmResponseRequiredDate = new Date(cellInfo.data.projectmanagersigneddate);
            const pmResponseReceiveDate = new Date(cellInfo.data.projectmanagerresponsedate);
            if (pmResponseRequiredDate < pmResponseReceiveDate) {
                badgeVariant = 'danger';
            }
            else {
                badgeVariant = 'success';
            }
            return (<Badge variant={badgeVariant}>{moment(pmResponseReceiveDate).format('L')}</Badge>);
        }
    }
    renderStatusColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        let status = this.renderStatusDisplay(cellInfo.data);
        switch (cellInfo.data.status) {
            case 'R':
            case 'APMA':
            case 'ARQ':
            case 'DNG':
                badgeVariant = 'danger';
                break;
            case 'AM':
            case 'QA':
            case 'DA':
                badgeVariant = 'success';
                break;
            case 'APMRQ':
            case 'APMR':
            case 'AQ':
            case 'ADAQ':
            case 'ADAN':
                badgeVariant = 'warning';
                break;
            case 'NC':
                badgeVariant = 'secondary';
                break;
            default:
                badgeVariant = 'secondary';
                break;
        }
        return (<Badge variant={badgeVariant}>{status}</Badge>);
    }
    renderStatusDisplay = (rowData) => {
        const status = this.compensationStatuses.find(x => x.field === rowData.status);
        if (status) {
            return status.display;
        } else {
            return rowData.status;
        }
    }
    sortIdColumn = (rowData) => {
        return rowData.sequencenumber;
    }
    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }
        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', calculateSortValue: this.sortIdColumn, calculateCellValue: this.renderIdColumn },
            { resource: "GENERAL.FORMS.FIELD.SUBJECT", dataField: 'compensationnumber' },
            { resource: "GENERAL.FORMS.FIELD.CLAUSE", dataField: "compensationtype" },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_DATE", dataField: "notificationdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_BY", dataField: "createby" },
            { resource: "CONTRACT.WF.COMPENSATION.FIELD.QUOTE_REQUIRED", dataField: "requiredate", dataType: 'date', visible: false },
            { resource: "CONTRACT.WF.COMPENSATION.FIELD.QUOTE_RECEIVED", dataField: "officereceiveddate", dataType: 'date', cellRender: this.renderQuoteReceived, visible: false },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.RESPONSE_REQUIRED' }), dataField: "projectmanagersigneddate", dataType: 'date' },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.RESPONSE_RECEIVED' }), dataField: "projectmanagerresponsedate", dataType: 'date', cellRender: this.pmResponseReceived },
            { resource: "GENERAL.FORMS.REPLING_PARTY", dataField: "replyingparty", lookup: { dataSource: this.props.contractTemplate.parties.options, valueExpr: 'id', displayExpr: 'display' }, visible: false },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "status", cellRender: this.renderStatusColumn, calculateCellValue: this.renderStatusDisplay, visible: false },
            { resource: "CONTRACT.WF.COMPENSATION.FIELD.IMPLEMENTED", dataField: "assessmentdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.AMOUNT", dataField: "assessmentamount", dataType: 'currency', currencyCode: this.props.contract.currency },
            { resource: "GENERAL.FORMS.FIELD.DAYS", dataField: "alternativequotation", dataType: 'string' },
            { resource: "GENERAL.FORMS.FIELD.DETAILS", dataField: "description", isHtml: true },
            { resource: "CONTRACT.WF.COMPENSATION.FIELD.ASSUMPTIONS", dataField: "assumption", isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "GENERAL.FORMS.FIELD.CURRENCY", dataField: "currency", visible: false, calculateCellValue: this.formatCurrency },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false },
        ];
        if (this.props.globalResources.CEN_DEEMED_ACCEPTANCE_STARTS !== 'OFF') {
            columns.push(
                { resource: "CONTRACT.WF.COMPENSATION.FIELD.CEN_DEEM_DATE", dataField: "cendeemedacceptancedate", dataType: 'date' }
            );
        }
        if (this.props.globalResources.QUOTE_DEEMED_ACCEPTANCE_STARTS !== 'OFF') {
            columns.push(
                { resource: "CONTRACT.WF.COMPENSATION.FIELD.QUOTE_DEEM_DATE", dataField: "quotedeemedacceptancedate", dataType: 'date' }
            );
        }
        if (this.notificationsTagsEnabled && this.state.tags != null) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.PortfolioFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProgrammeFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProjectFilter } },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.OtherFilter } }
            )
        }
        if (this.notificationsRelsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.TAB_RELATIONS", dataField: "relatednotices", visible: false },
            )
        }
        columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(this.customFields));

        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="1"
                        title={this.props.title}
                        referenceIDPrefix={this.maxReferenceIDPrefix}
                        name={this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.TITLE.REGISTER_IMPLEMENTED' })}
                        dataSource={this.state.notifications}
                        keyExpr="compensationeventid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        // columnResizingMode="nextColumn"
                        // columnAutoWidth={true}
                        columns={columns}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}



export default withListPageProps(CompensationListImplemented);
