import axios from '../shared/axios';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function createInstructionToQuote(data) {
    return new Promise((resolve, reject) => {
        axios.post(`/api/InstructionToQuote/Create`, data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}

export function updateInstructionToQuote(data) {
    return new Promise((resolve, reject) => {
        axios.put(`/api/InstructionToQuote/Update`, data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}

export function getInstructionToQuote(instructiontoquoteid) {
    return axios.get(`/api/InstructionToQuote/GetInstructionToQuote?instructiontoquoteid=${instructiontoquoteid}`);
}

export function getContractInstructionToQuotes(companyid, projectid, contractid, accessibleWfIds) {
    return axios.post(`/api/InstructionToQuote/LoadInstructionToQuotesList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&startdate=&enddate=&accessibleWfIds=${accessibleWfIds}`);
}

export function getInstructionToQuotes(companyid, projectid, contractid, accessibleWfIds) {
    return axios.post(`/api/InstructionToQuote/RegisterList`, { accessibleWfIds }, { params: { companyid, projectid, contractid } });
}

export function deleteInstructionToQuoteDraft(instructiontoquoteid) {
    return new Promise((resolve, reject) => {
    axios.post(`/api/InstructionToQuote/DeleteDraft?instructiontoquoteid=${instructiontoquoteid}`)
    .then(result => {
        store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
        resolve(result);
    })
    .catch(error => reject(error));
})
    
}