import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import * as bankAccountCrud from "../../../../crud/bankAccount.crud";
import { SimpleItem } from 'devextreme-react/form'
import DataGrid, {  StateStoring, SearchPanel, Export, Paging,  ColumnChooser, ColumnFixing, Editing, HeaderFilter, Toolbar, Item, Form, FilterRow, FilterPanel } from 'devextreme-react/data-grid';
import moment from 'moment';
import DxFormModal from '../../../../partials/content/dxControls/dxFormModal';
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

const removeModalConfig = {
    fields: [
        { label: 'Date Supplier left project bank account', dataField: 'closedatedisplay', required: true, editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: new Date(1900, 0, 1), max: new Date() } }, validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(), warning: "Removal Date can not be in the future!" } },
    ]
};

class BankAccountList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: null,
            showRemoveModal: false,
            selectedAccount: null
        };
    }

    componentDidMount() {
        this.fetchRecords();
    }

    fetchRecords = () => {
        bankAccountCrud.getBankAccounts(this.props.contract.companyid, this.props.contract.projectid, this.props.contract.contractid)
            .then(response => {
                const parsedList = JSON.parse(response.data.aggregatelist);

                this.setState({ notifications: parsedList });
            })
            .catch(err => {
                this.props.enqueueSnackbar('Error loading ' + this.props.title, { variant: 'error', });
            });
    }
   
    onRowInserting = async (e) => {
        const account = { ...e.data };
        account.companyid = this.props.contract.companyid;
        account.projectid = this.props.contract.projectid;
        account.contractid = this.props.contract.contractid;
        account.createby = this.props.contractUser.accountid;
        account.signupdatedisplay = moment(account.signupdate).format('DD/MM/YYYY');
        bankAccountCrud.saveBankAccount(account)
            .then(response => {
                this.props.enqueueSnackbar('Bank account successfully added to project', { variant: 'success', });
                this.fetchRecords();
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }

    onRowRemoving = (e) => {
        e.cancel = true;
        this.setState({ showRemoveModal: true, selectedAccount: { ...e.data } });
    }


    onRemoveModalResponse = (resp) => {
        this.setState({ showRemoveModal: false });
        if (resp) {
            const account = { ...this.state.selectedAccount, ...resp };
            account.signupdatedisplay = moment(account.signupdate).format('DD/MM/YYYY');
            account.closedatedisplay = moment(resp.closedatedisplay).format('DD/MM/YYYY');
            bankAccountCrud.saveBankAccount(account)
                .then(response => {
                    this.props.enqueueSnackbar('Bank account successfully saved', { variant: 'success', });
                    this.fetchRecords();
                })
                .catch(err => {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                });
        }
    }
    onExporting = (e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
          component: e.component,
          worksheet: worksheet,
        }).then(function () {
          workbook.xlsx.writeBuffer()
            .then(function (buffer) {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'BankAccounts.xlsx');
            });
        });
      }
    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }

        return (
            <Portlet>
                <PortletBody>
                    <DataGrid
                        dataSource={this.state.notifications}
                        keyExpr="bankaccountid"
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        remoteOperations={false}
                        allowColumnReordering={true}
                        columnResizingMode='widget'
                        onContextMenuPreparing={this.addGridContextMenuItems}
                        onRowInserting={this.onRowInserting}
                        onExporting={this.onExporting}
                        onRowRemoving={this.props.workflowItem.userFilters.actionTab.actionAllowed ? this.onRowRemoving : null}
                        style={{ maxHeight: '67vh', minHeight: "50vh" }}
                        columns={[
                            { caption: this.props.intl.formatMessage({ id: "CONTRACT.BANK_ACCOUNT.SUPPLIER_NAME" }), dataField: 'accountname' },
                            { caption: this.props.intl.formatMessage({ id: "CONTRACT.BANK_ACCOUNT.DATE_JOINED" }), dataField: "signupdate", dataType: 'date', format: (date) => moment(date).format('L') },
                            { caption: this.props.intl.formatMessage({ id: "CONTRACT.BANK_ACCOUNT.DATE_REMOVED" }), dataField: "closedate", dataType: 'date', format: (date) => moment(date).format('L') },
                            { caption: this.props.intl.formatMessage({ id: "CONTRACT.BANK_ACCOUNT.ADDED_BY" }), dataField: "createbyname" },
                        ]}
                    >
                        <Editing
                            //CACADING FORM DEMO IMPLEMENTATION
                            //https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CascadingLookups/React/Light/
                            mode="popup"
                            allowUpdating={false}
                            allowDeleting={this.props.workflowItem.userFilters.actionTab.actionAllowed ? (obj) => !obj.row.data.closedate : false}
                            allowAdding={this.props.workflowItem.userFilters.actionTab.actionAllowed ? (obj) => !obj.row.data.closedate : false}
                            confirmDelete={true}
                            //https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxPopup/Configuration/
                            popup={{ title: this.props.intl.formatMessage({ id: "CONTRACT.BANK_ACCOUNT.ADD_BANK_USER" }), minWidth: 600, height: 300, width: "auto", showTitle: true }}
                            texts={{
                                deleteRow: this.props.intl.formatMessage({ id: "GENERAL.FORMS.REMOVE" }),
                                confirmDeleteTitle: this.props.intl.formatMessage({ id: "CONTRACT.USERS.REMOVE_USER" }),
                                confirmDeleteMessage: this.props.intl.formatMessage({ id: "CONTRACT.USERS.REMOVE_SUPPLIER" })
                            }}
                        >
                            <Form>
                                <Item itemType="group" colSpan={2}>
                                    <SimpleItem 
                                        dataField="accountname" 
                                        label={{ text: this.props.intl.formatMessage({ id: "CONTRACT.BANK_ACCOUNT.SUPPLIER_NAME" }) }}
                                        isRequired={true}
                                    />
                                    <SimpleItem 
                                        dataField="signupdate" 
                                        editorType="dxDateBox"
                                        label={{ text: this.props.intl.formatMessage({ id: "CONTRACT.BANK_ACCOUNT.DATE_JOINED" }) }}
                                        isRequired={true}
                                        editorOptions={{
                                            calendarOptions: { min: new Date(1900, 0, 1), max: new Date(2079, 5, 6) }, 
                                            validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: "Removal Date can not be in the future!" }
                                        }}
                                    />
                                </Item>
                            </Form>
                        </Editing>
                        <HeaderFilter visible={true} />
                        <FilterRow applyFilter="auto" visible={true} />
                        <FilterPanel visible={true} />
                        <ColumnChooser mode="select" enabled={true} search={{ enabled: true }}  >
                        {/* <ColumnChooserSearch enabled={true} timeout={800} /> */}
                        </ColumnChooser>
                        <ColumnFixing enabled={true} />
                        <StateStoring enabled={true} type="localStorage" storageKey="DxBankAccountsRegisterState" />
                        <Export enabled={true} fileName={this.props.name} />
                        <SearchPanel visible={true} highlightCaseSensitive={false} defaultText="" />
                        <Paging pageSize={10} defaultPageSize={10} defaultPageIndex={0} />
                        <Toolbar>
                            <Item location="before">
                                <div>
                                    <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">

                                        {this.props.workflowItem.pageTitle}
                                    </h3>
                                </div>
                            </Item>
                            {
                                this.props.workflowItem.userFilters.actionTab.actionAllowed && (
                                    <Item location="after"
                                        locateInMenu="auto"
                                        name="addRowButton"
                                        showText="always"
                                        options={{
                                            icon: "plus",
                                            text: this.props.intl.formatMessage({ id: "CONTRACT.BANK_ACCOUNT.ADD_SUPPLIER" }),
                                        }}
                                    />
                                )
                            }
                            <Item location="after"
                                locateInMenu="auto"
                                name="exportButton"
                            />
                            <Item location="after"
                                locateInMenu="auto"
                                name="columnChooserButton"
                            />
                            <Item location="after"
                                locateInMenu="auto"
                                name="searchPanel"
                            />
                        </Toolbar>
                    </DataGrid>
                </PortletBody>
                <DxFormModal
                    visible={this.state.showRemoveModal}
                    title={this.props.intl.formatMessage({ id: "CONTRACT.USERS.REMOVE_USER" })}
                    saveButtonText={this.props.intl.formatMessage({ id: "GENERAL.FORMS.REMOVE" })}
                    config={removeModalConfig}
                    onDialogResult={this.onRemoveModalResponse}
                    primaryButton={{
                        text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.REMOVE" }),
                        type: "danger",
                        stylingMode: "outlined",
                    }}
                />
            </Portlet>
        );
    }
}

/*const mapStateToProps = (store) => ({
    contract: store.contract.contract,
    contractUser: store.contract.user,
});*/

export default withListPageProps(BankAccountList);
