import React from 'react';
import { connect } from "react-redux";
import PHModalGeneric from './ModalEditors/PHModalGeneric';
import PHModalClauses from './ModalEditors/PHModalClauses';
import PHModalUnifiedClauses from './ModalEditors/PHModalUnifiedClauses';
import PHModalToggle from './ModalEditors/PHModalToggle';
import PHModalResources from './ModalEditors/PHModalResources';
import DxOrderList from '../../../../../partials/content/dxControls/dxOrderList';
import DxGroupBox from '../../../../../partials/content/dxControls/dxGroupBox';
import { SelectBox } from 'devextreme-react/select-box';
import DefaultWorkflows from '../../../../../Templates/DefaultWorkflows';
import { placeholderTypes } from "../../../../../Templates/Types/Placeholder";
import { Badge } from "react-bootstrap";

export const GetPHDefaultSettings = (key, wfgid) => {
    const wfgroup = DefaultWorkflows.find(wfg => wfg.id === wfgid);
    if (wfgroup) {
        if (wfgroup.placeholders && wfgroup.placeholders.length > 0) {
            const placeholder = wfgroup.placeholders.find(ph => ph.replacementKey === key);
            if (placeholder) {
                return JSON.parse(JSON.stringify(placeholder));
            }
            else{
                console.log("WFG placeholder Not found!",key,wfgroup.placeholders)
            }
        }
        else{
            console.log("WFG placeholders Not found!",wfgid)
        }
    }
    else{
        console.log("WFG Not found!",wfgid)
    }
    return false;
}

class PlaceholderListManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState(props);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.items !== this.props.items) {
            this.setState(this.getInitialState(this.props));
        }
    }

    getInitialState = (props) => {
        let initialItemList = [];
        let listDrpOptions = [];
        if (props.items) {
            initialItemList = JSON.parse(JSON.stringify(props.items));
        }
        if (props.allItems && props.allItems.length > 0) {
            if (props.items && props.items.length > 0) {
                listDrpOptions = props.allItems.filter(item => (props.items.findIndex(selectedItem => selectedItem.replacementKey === item.replacementKey) === -1));
            }
            else {
                listDrpOptions = JSON.parse(JSON.stringify(props.allItems));
            }
        }
        return {
            items: initialItemList,
            editingGenericItem: null,
            editingClauseItem: null,
            editingUnifiedClauseItem: null,
            editingToggleItem: null,
            editingResourceItem: null,
            defaultPH: null,
            drpOptions: listDrpOptions,
        };
    }
    onListUpdated = (newList) => {
        this.setState({ items: JSON.parse(JSON.stringify(newList)) }, this.updateParent);
    }
    onItemSelected = (item) => {
        const defaultPH = GetPHDefaultSettings(item.replacementKey, this.props.wfgid);
        if (!defaultPH) { alert("This placeholder is no longer available, please remove item!"); return; }
        if (!defaultPH.type) { alert("This placeholder has no type assigned, please contact administrator!"); return; }
        const itemCopy = JSON.parse(JSON.stringify(item));

        let instanceDefaultPH = defaultPH;
        if (this.props.allItems?.length > 0) {
            const defaultInMaster = this.props.allItems.find(ph => ph.replacementKey === item.replacementKey);
            if (defaultInMaster) {
                /// Use the Master Workflows values as defaults
                //FD-11639
                instanceDefaultPH = {
                    ...defaultPH,
                    values: defaultInMaster.values
                }
            }
        }

        if (defaultPH.type === placeholderTypes.CLAUSES) {
            this.setState({ editingClauseItem: itemCopy, defaultPH: instanceDefaultPH });
        }
        else if (defaultPH.type === placeholderTypes.UNIFIED_CLAUSES) {
            this.setState({ editingUnifiedClauseItem: itemCopy, defaultPH: instanceDefaultPH });
        }
        else if (defaultPH.type === placeholderTypes.TOGGLE_SWITCH) {
            this.setState({ editingToggleItem: itemCopy, defaultPH: instanceDefaultPH });
        }
        else if (defaultPH.type === placeholderTypes.RESOURCES || defaultPH.type === placeholderTypes.ACTION_REMINDERS) {
            this.setState({ editingResourceItem: itemCopy, defaultPH: instanceDefaultPH });
        }
        else {
            this.setState({ editingGenericItem: itemCopy, defaultPH: instanceDefaultPH });
        }
    }

    dropItemSelected = (e) => {
        if (e.value) {
            const newListDrpOptions = [...this.state.drpOptions];
            newListDrpOptions.splice(newListDrpOptions.findIndex(ph => ph.replacementKey === e.value.replacementKey), 1);

            const newitems = [...this.state.items];
            const newItem = JSON.parse(JSON.stringify(e.value));
            newItem.changed = true;
            newitems.push(newItem);

            this.setState({ items: newitems, drpOptions: newListDrpOptions }, this.updateParent);
        }
    }
    onModalClose = (itemFromModal) => {
        if (itemFromModal) {
            const newItem = JSON.parse(JSON.stringify(itemFromModal));
            newItem.changed = true;
            let newItems = [];
            if (this.state.items && this.state.items.length > 0) {
                newItems = JSON.parse(JSON.stringify(this.state.items));
            }
            // if (newItem.id && newItem.id > 0) {
            /// UPDATE EXISTING ITEM
            newItems[newItems.findIndex(wf => wf.replacementKey === newItem.replacementKey)] = newItem;
            // }
            // else {
            //     /// ADD NEW ITEM
            //     let maxId = 1;
            //     if (newWorkflows.length > 0) {
            //         maxId = Math.max(...newWorkflows.map(wf => wf.id));
            //     }
            //     newItem.id = maxId + 1;
            //     newWorkflows.push(newItem);
            // }
            this.setState({
                items: newItems,
                editingGenericItem: null,
                editingClauseItem: null,
                editingUnifiedClauseItem: null,
                editingToggleItem: null,
                editingResourceItem: null,
                defaultPH: null
            }, this.updateParent);
        }
        else {
            this.setState({
                editingGenericItem: null,
                editingClauseItem: null,
                editingUnifiedClauseItem: null,
                editingToggleItem: null,
                editingResourceItem: null,
                defaultPH: null
            });
        }
    }
    updateParent = () => {
        this.props.listChanged(this.state.items.map(itm => ({ ...itm })));
    }
    renderListItem = (item) => {
        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 'auto' }}>{`${item.replacementKey}`}</div>
                {
                    //C-BADGE if any changes
                    (item.changed) && (
                        <Badge variant="warning">C</Badge>
                    )
                }
            </div>
        );
    }
    render() {
        return (
            <DxGroupBox
                showBorder={false}
            // toolbar={(this.props.mode === 'WORKFLOW' || this.props.userProfile.isSystemAdmin) && (
            //     <Button
            //         type="normal"
            //         stylingMode="contained"
            //         icon="plus"
            //         text="New"
            //         onClick={this.newItemClicked} />
            // )}
            >

                <SelectBox dataSource={this.state.drpOptions}
                    keyExpr="replacementKey"
                    displayExpr="replacementKey"
                    searchEnabled={true}
                    searchMode="contains"
                    searchExpr="replacementKey"
                    searchTimeout={200}
                    minSearchLength={0}
                    showDataBeforeSearch={true}
                    onValueChanged={this.dropItemSelected}
                    value={null}
                    readOnly={this.props.readOnly}
                />

                <DxOrderList
                    items={this.state.items}
                    keyExpr="replacementKey"
                    displayExpr="replacementKey"
                    listChanged={this.onListUpdated}
                    onItemClick={this.onItemSelected}
                    itemRender={this.renderListItem}
                    readOnly={this.props.readOnly}
                />

                <PHModalGeneric
                    wfgid={this.props.wfgid}
                    mode={this.props.mode}
                    onClose={this.onModalClose}
                    item={this.state.editingGenericItem}
                    defaultPH={this.state.defaultPH}
                    readOnly={this.props.readOnly}
                />
                <PHModalClauses
                    wfgid={this.props.wfgid}
                    mode={this.props.mode}
                    onClose={this.onModalClose}
                    item={this.state.editingClauseItem}
                    defaultPH={this.state.defaultPH}
                    readOnly={this.props.readOnly}
                />
                <PHModalUnifiedClauses
                    wfgid={this.props.wfgid}
                    mode={this.props.mode}
                    onClose={this.onModalClose}
                    item={this.state.editingUnifiedClauseItem}
                    defaultPH={this.state.defaultPH}
                    readOnly={this.props.readOnly}
                />
                <PHModalToggle
                    wfgid={this.props.wfgid}
                    mode={this.props.mode}
                    onClose={this.onModalClose}
                    item={this.state.editingToggleItem}
                    defaultPH={this.state.defaultPH}
                    readOnly={this.props.readOnly}
                />
                <PHModalResources
                    wfgid={this.props.wfgid}
                    mode={this.props.mode}
                    onClose={this.onModalClose}
                    item={this.state.editingResourceItem}
                    defaultPH={this.state.defaultPH}
                    readOnly={this.props.readOnly}
                />
            </DxGroupBox>
        );
    }


}

const mapStateToProps = store => ({
    userProfile: store.auth.userProfile,
});

export default connect(mapStateToProps)(PlaceholderListManager);