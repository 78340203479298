import React from 'react';
import * as fileCrud from '../../../../crud/file.crud';
import Document from '../../_CustomComponents/DocumentV2';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import moment from 'moment';
import { injectIntl } from "react-intl";
import { paymentPeriods } from '../../../../shared/lookup';

const effectTypes = [
    { value: 'noeffect', display: 'There is no effect on the Accepted Programme' },
    { value: 'mitigated', display: 'There is an effect on the Accepted Programme but this has been mitigated by measures as detailed' },
    { value: 'notmitigated', display: 'There is an effect on the Accepted Programme which we are unable to mitigate' },
];
const effectTypesNoProgramReply = [
    { value: 'noeffect', display: 'There is no effect on the Programme' },
    { value: 'mitigated', display: 'There is an effect on the Programme but this has been mitigated by measures as detailed' },
    { value: 'notmitigated', display: 'There is an effect on the Programme which we are unable to mitigate' },
]

class QuoteDocument extends BaseDocument {
    constructor(props) {
        super(props);
        this.validatorRef = props.validatorRef ? props.validatorRef : React.createRef();

        if (props.programmeReplyEnabled) {
            this.effectTypes = effectTypes;
        }
        else {
            this.effectTypes = effectTypesNoProgramReply;
        }

        this.paymentPeriods = paymentPeriods.map(p => (this.props.intl.formatMessage({ id: "GENERAL.FORMS." + p })));
        this.isAsiteEnabled = process.env.REACT_APP_ASITE_ENABLED === 'true';
    }



    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        if (field) {
            if (this.props.valueChangeHandler) {
                this.props.valueChangeHandler(field, value, isDxFormValid, additionalData);
            }
        }
    }

    calculateTotal = (obj) => {
        const prc1 = obj.totallistprice ? obj.totallistprice : 0;
        const prc2 = obj.totalassessed ? obj.totalassessed : 0;
        return prc1 + prc2;
    }

    renderForm = () => {
        const createFormConfig = {
            currencyCode: this.props.contract.currency,
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'subject', editorOptions: { readOnly: true } }
            ]
        };

        if (this.props.resources.QuoteNoteInstructionToQuote) {
            createFormConfig.fields.push(
                { note: this.props.resources.QuoteNoteInstructionToQuote.replace('$reference', this.props.instructionToQuote.referenceid), valueType: 'NOTE' },
            );
        }

        if (this.props.fields.Description) {
            createFormConfig.fields.push({ label: this.props.fields.Description, dataField: 'description', editorType: 'dxHtmlEditor', editorOptions: { readOnly: true } })
        }
        if (this.props.fields.Comments) {
            createFormConfig.fields.push({ label: this.props.fields.Comments, dataField: 'comments', editorType: 'dxHtmlEditor' })
        }
        if (this.props.fields.ItemsList) {
            createFormConfig.fields.push({ label: this.props.fields.ItemsList, dataField: 'itemslist' })
        }
        if (this.props.fields.TotalListPrice) {
            createFormConfig.fields.push({ label: this.props.fields.TotalListPrice, dataField: 'totallistprice', editorType: 'dxNumberBox' })
        }
        if (this.props.fields.InstructionToQuoteDescription) {
            createFormConfig.fields.push({ label: this.props.fields.InstructionToQuoteDescription, dataField: 'instructiontoquotedescription' })
        }
        if (this.props.fields.TotalAssessed) {
            createFormConfig.fields.push({ label: this.props.fields.TotalAssessed, dataField: 'totalassessed', editorType: 'dxNumberBox' })
        }
        if (this.props.fields.TotalPrice) {
            createFormConfig.fields.push({ label: this.props.fields.TotalPrice, dataField: 'totalprice', editorType: 'dxNumberBox', value: this.calculateTotal })
        }
        if (this.props.fields.StartDate) {
            var startDateField = { label: this.props.fields.StartDate, dataField: 'startdate', editorType: 'dxDateBox' };

            if (this.props.configurableITQFields.StartDate) {
                startDateField = { label: this.props.fields.StartDate, dataField: 'startdate', editorType: 'dxDateBox', editorOptions: { readOnly: true } };
            }

            if (this.props.fields.CompletionDate) {
                startDateField.validation = { type: 'custom', validationCallback: (obj, value) => (obj.completiondate >= value), warning: 'Completion date can not be earlier than start date' }
            }

            createFormConfig.fields.push(startDateField);
        }
        if (this.props.fields.CompletionDate) {
            var completionDateField = { label: this.props.fields.CompletionDate, dataField: 'completiondate', editorType: 'dxDateBox' }

            if (this.props.configurableITQFields.CompletionDate) {
                completionDateField = { label: this.props.fields.CompletionDate, dataField: 'completiondate', editorType: 'dxDateBox', editorOptions: { readOnly: true } }
            }

            if (this.props.fields.StartDate) {
                completionDateField.validation = { type: 'custom', validationCallback: (obj, value) => (obj.startdate <= value), warning: 'Completion date can not be earlier than start date' }
            }

            createFormConfig.fields.push(completionDateField);
        }
        if (this.props.fields.DelayAmount) {
            if (this.props.configurableITQFields.DelayAmount) {
                createFormConfig.fields.push({ label: this.props.fields.DelayAmount, dataField: 'delayamount', editorType: 'dxNumberBox', editorOptions: { readOnly: true } })
            } else {
                createFormConfig.fields.push({ label: this.props.fields.DelayAmount, dataField: 'delayamount', editorType: 'dxNumberBox' })
            }
        }
        if (this.props.fields.DelayPaymentPeriod) {
            if (this.props.configurableITQFields.DelayPaymentPeriod) {
                createFormConfig.fields.push({ label: this.props.fields.DelayPaymentPeriod, dataField: 'delaypaymentperiod', required: false, editorType: 'dxSelectBox', editorOptions: { dataSource: this.paymentPeriods, readOnly: true } })
            } else {
                createFormConfig.fields.push({ label: this.props.fields.DelayPaymentPeriod, dataField: 'delaypaymentperiod', required: false, editorType: 'dxSelectBox', editorOptions: { dataSource: this.paymentPeriods } })
            }
        }

        return (
            <BiDynamicForm
                validatorRef={this.validatorRef}
                item={this.props.notification}
                config={createFormConfig}
                onSubmitHandler={null}
                valueChangeHandler={this.fieldChanged}
                customFields={(this.props.customFieldsCommon || {}).ITQQUOTE}
            />
        );
    }
    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType, this.props.notification.itqquotationid, data, config)
    }



    render() {
        const documentIsFinal = this.props.notification.showtasknotification === 1;
        return (
            <Document
                key={documentIsFinal}
                docConfig={this.props.notification.pdfjsoncontent}
                isFinal={documentIsFinal}
                printDivRef={this.props.printDivRef}
                workflowItem={this.props.workflowItem}
                contract={this.props.contract}
                reference={this.props.notification.referenceid}
                form={documentIsFinal ? null : this.renderForm()}
                uploadMethod={documentIsFinal ? null : this.uploadFile}
                files={this.props.files}
                notificationid={this.props.notificationid}
                documentType={this.props.documentType}
                customFields={(this.props.customFieldsCommon || {}).ITQQUOTE}
                customFieldValues={this.props.notification.customfieldvalues}
                assetid={this.props.instructionToQuote.assetid}
            />
        );
    }
}

export default injectIntl(QuoteDocument);
