import React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { TextBox, Switch } from 'devextreme-react';
import { Alert } from "react-bootstrap";
import { withSnackbar } from 'notistack';

const emptyPlaceHolder = {
    // id: 0,
    replacementKey: '',
    values: [],
}
const emptyValue = {
    id: 0,
    isActive: true,
    field: '',
    display: '',
}



class PHModalToggle extends React.Component {
    constructor() {
        super();
        this.state = {
            description: "",
            placeholder: { ...emptyPlaceHolder },
            value: { ...emptyValue },
        };
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.item !== this.props.item) {
                if (this.props.item && this.props.item.replacementKey) {
                    const { defaultPH } = this.props;
                    this.setState({
                        placeholder: JSON.parse(JSON.stringify(this.props.item)),
                        description: defaultPH.values[0].display
                    });
                }
                else {
                    this.setState({ placeholder: { ...emptyPlaceHolder }, value: { ...emptyValue } });
                }
            }
        }
    }



    onClose = () => {
        this.setState({ placeholder: { ...emptyPlaceHolder }, value: { ...emptyValue } });
        this.props.onClose();
    }
    savePlaceholder = () => {
        const retVal = JSON.parse(JSON.stringify(this.state.placeholder));
        //CLEAR ANY EDITOR OPTIONS
        this.props.onClose(retVal);
        this.setState({ placeholder: { ...emptyPlaceHolder }, value: { ...emptyValue } });
    }


    toggleForm = () => {
        if (this.state.placeholder.values.length > 0) {
            return (
                <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: 10 }}>
                    <Alert variant="secondary" >
                        <p>
                            {this.state.description}
                        </p>
                    </Alert>
                    <div className="dx-field" style={{ marginTop: "10px" }}>
                        <div className="dx-field-label" style={{ width: "20%" }}>Enabled</div>
                        <div className="dx-field-value" style={{ width: "80%", display: 'flex', flexDirection: 'column' }}>
                            <Switch
                                defaultValue={this.state.placeholder.values[0].isActive}
                                onValueChanged={(e) => this.setState({ placeholder: { ...this.state.placeholder, values: [{ ...this.state.placeholder.values[0], isActive: e.value }] } })}
                                switchedOffText="No"
                                switchedOnText="Yes"
                                readOnly={this.props.readOnly}

                            />
                        </div>
                    </div>
                </div>
            )
        }

    }

    render() {
        return (
            <Popup
                visible={this.props.item ? true : false}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showTitle={true}
                title={this.state.placeholder && this.state.placeholder.replacementKey ? "Edit Placeholder" : "Create New Placeholder"}
                width={1200}
                height='80vh'
                minHeight={600}
            >
                {/* <ScrollView style={{ border: '1px solid #ccc', borderRadius: '10px' }} showScrollbar="always" useNative> */}
                <div style={{ display: 'flex', flexDirection: 'column', height: "100%" }}>

                    <div className="dx-field" style={{ margin: "10px 150px 10px 150px" }}>
                        <div className="dx-field-label" style={{ width: "20%", textAlign: "end" }} >Placeholder Key:</div>
                        <div className="dx-field-value" style={{ width: "80%" }} >
                            <TextBox
                                placeholder="'Unique Key' that will match workflow page script"
                                value={this.state.placeholder.replacementKey}
                                onValueChanged={(e) => this.setState({ placeholder: { ...this.state.placeholder, replacementKey: e.value } })}
                                readOnly={true}
                            />
                        </div>
                    </div>

                    {
                        this.toggleForm()
                    }

                </div>
                {/* </ScrollView> */}
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    disabled={this.props.readOnly}
                    options={{
                        text: this.state.placeholder && this.state.placeholder.replacementKey ? 'Update Placeholder' : 'Add Placeholder',
                        type: 'normal',
                        onClick: this.savePlaceholder
                    }}>
                </ToolbarItem>
            </Popup>
        );
    }
}


export default withSnackbar(PHModalToggle);