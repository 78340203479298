import React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import BiDynamicForm from '../../layout/BiDynamicForm';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import { injectIntl } from "react-intl";

export const RESPONSE = {
    OK: 'OK',
    CANCEL: 'CANCEL',
};

class dxDialogModal extends React.Component {
    constructor(props) {
        super(props);
        this.validatorRef = React.createRef();
        this.validatorName = 'ReplyDraftModalValidator';
        this.state = {
            activateWithReinstate: null,
            saveButtonEnabled: false,
            companyid: null,
            projectid: null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible !== this.props.visible) {
            if (this.props.visible) {
                this.saved = false;
            }
            this.setState({ saveButtonEnabled: this.props.visible });
        }
    }

    onClose = () => {
        this.props.onDialogResult(RESPONSE.CANCEL);
    }
    saveItem = () => {
        let props;
        if (this.props.mode === 'ACTIVATION') {
            if (!this.validateForm()) {
                return;
            }
            props = { activationStatus: this.state.activateWithReinstate };
        } else if (this.props.mode === 'DEACTIVATION') {
            props = { activationStatus: null };
        } else if (this.props.mode === 'COMPANY_UPDATE') {
            props = { companyid: this.state.companyid };
        } else if (this.props.mode === 'CONTRACT_COMPANYPROJECT_UPDATE') {
            if (!this.validateForm()) {
                return;
            }
            props = { companyid: this.state.companyid, projectid: this.state.projectid };
        } else if (this.props.mode === 'COMPANY_ACTIVATION') {
            props = { activationStatus: null };
        }

        if (!this.saved) {
            this.saved = true;
            this.setState({ saveButtonEnabled: false }, () => {
                this.props.onDialogResult(RESPONSE.OK, props);
            });
        }
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        return validationResult.isValid;
    }
    onValueChanged = (field, value) => {
        if (field === 'activationStatus') {
            if (value === 'Reinstate Contract Permissions') {
                this.setState({ activateWithReinstate: true })
            } else {
                this.setState({ activateWithReinstate: false })
            }
        } else if (field === 'companyid') {
            this.setState({ companyid: value });
            if (this.props.mode === 'CONTRACT_COMPANYPROJECT_UPDATE') {
                this.props.companyValueChangeHandler(value);
            }
        } else if (field === 'projectid') {
            this.setState({ projectid: value })
        }
    }
    generateForm = () => {
        if (this.props.mode === 'ACTIVATION') {
            const userConfig = {
                fields: [
                    { label: this.props.intl.formatMessage({ id: "COMPANY.USER.ACTIVATE.MODAL.ACTIVATING_TYPE" }), dataField: 'activationStatus', editorType: 'dxSelectBox', editorOptions: { dataSource: [this.props.intl.formatMessage({ id: "COMPANY.USER.ACTIVATE.MODAL.ACTIVATING_TYPE_REINSTATE" }), this.props.intl.formatMessage({ id: "COMPANY.USER.ACTIVATE.MODAL.ACTIVATING_TYPE_NO_REINSTATE" })] } },
                ]
            };
            return (
                <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                    <BiDynamicForm
                        config={userConfig}
                        valueChangeHandler={this.onValueChanged}
                    />
                    <div>
                        <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                    </div>
                </ValidationGroup>
            )
        }
        else if (this.props.mode === 'COMPANY_UPDATE') {
            const userConfig = {
                fields: [
                    // { label: this.props.intl.formatMessage({ id: "COMPANY.USER.COMPANY.UPDATE.MODAL.UPDATING" }), dataField: 'activationStatus', editorType: 'dxSelectBox', editorOptions: { dataSource: [this.props.intl.formatMessage({ id: "COMPANY.USER.ACTIVATE.MODAL.ACTIVATING_TYPE_REINSTATE" }), this.props.intl.formatMessage({ id: "COMPANY.USER.ACTIVATE.MODAL.ACTIVATING_TYPE_NO_REINSTATE" })] } },
                    { resource: 'CONTRACT.FIELD.COMPANY', dataField: 'companyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.dataSource1, valueExpr: 'companyid', displayExpr: 'name' } }
                ]
            };
            return (
                <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                    <BiDynamicForm
                        config={userConfig}
                        valueChangeHandler={this.onValueChanged}
                    />
                    <div>
                        <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                    </div>
                </ValidationGroup>
            )
        }
        else if (this.props.mode === 'CONTRACT_COMPANYPROJECT_UPDATE') {
            const contractConfig = {
                fields: [
                    { resource: 'CONTRACT.FIELD.COMPANY', dataField: 'companyid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.dataSource1, valueExpr: 'companyid', displayExpr: 'name' } },
                    { resource: 'CONTRACT.FIELD.PROJECT', dataField: 'projectid', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.dataSource2, valueExpr: 'projectid', displayExpr: 'display' } }
                ]
            };
            return (
                <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                    <BiDynamicForm
                        config={contractConfig}
                        valueChangeHandler={this.onValueChanged}
                    />
                    <div>
                        <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                    </div>
                </ValidationGroup>
            )
        }
        else { return null; }
    }
    render() {
        const optionType = this.props.mode === 'DEACTIVATION' || this.props.mode === 'DISABLEPOWERBI' ? 'danger' : 'success';

        let primaryButtonText;

        switch (this.props.mode) {
            case 'ACTIVATION':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_ACTIVATE" });
                break;
            case 'DEACTIVATION':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_DEACTIVATE" });
                break;
            case 'COMPANY_ACTIVATION':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_ACTIVATE" });
                break;
            case 'COMPANY_UPDATE':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_UPDATE" });
                break;
            case 'CONTRACT_COMPANYPROJECT_UPDATE':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_UPDATE" });
                break;
            case 'FORECAST':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CONTINUE" });
                break;
            case 'ASSESSMENT':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CONTINUE" });
                break;
            case 'PROPOSAL':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CONTINUE" });
                break;
            case 'PAYMENT':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CONTINUE" });
                break;
            case 'TASKORDER':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CONTINUE" });
                break;
            case 'QUOTES':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CONTINUE" });
                break;
            case 'CLAIMS':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CONTINUE" });
                break;
            case 'ENABLEPOWERBI':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_ENABLE" });
                break;
            case 'DISABLEPOWERBI':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_DISABLE" });
                break;
            case 'PROJECTORDER':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CONTINUE" });
                break;
            case 'CONTRACTFRAMEWORKTAG':
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CONTINUE" });
                break;
            default:
                primaryButtonText = this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_COMMUNICATE" });
                break;
        }

        let userLimitCheck = false;

        if (this.props.userLimitExceed) {
            userLimitCheck = true;
        }

        return (
            <Popup
                visible={this.props.visible}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showTitle={true}
                title={this.props.title}
                width={600}
                height='auto'>
                <div>
                    <p>
                        <span>{this.props.text}</span>
                    </p>
                    {
                        this.generateForm()
                    }
                </div>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CANCEL" }), onClick: this.onClose }}>
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: primaryButtonText, disabled: !this.state.saveButtonEnabled || userLimitCheck, type: optionType, ...this.props.buttonOptions, onClick: this.saveItem }}>
                </ToolbarItem>
            </Popup>
        );
    }
}



export default injectIntl(dxDialogModal);