/* eslint-disable eqeqeq */
import React from 'react';
import { connect } from "react-redux";
import DataGridBase from '../../../../partials/layout/DataGridBase';
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { systemAccountStatus } from "../../../../shared/lookup";
import { Badge } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomFieldHelper from "../../../contract/_Helpers/CustomFieldHelper";
import { withRouter } from '../../../../shared/hoc/withRouter';
import  { GetAccessRights } from "../../../../router/Protector/Protector";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as companiesCrud from '../../../../crud/companies.crud';

const isFinancialLimitEnabled = process.env.REACT_APP_FINANCIAL_LIMIT_ENABLED === "true";

class CompanyUsers extends React.Component {
  constructor(props) {
    super(props);
    this.canCreateNew  = GetAccessRights("/admin/companies/:id/new-company-user", this.props.userProfile).allowAccess;
    this.columns = [
      { resource: "COMPANY.USER.FIELD.ID", dataField: "accountid", dataType: 'string' },
      { resource: "COMPANY.USER.FIELD.USERNAME", dataField: "username" },
      { resource: "COMPANY.USER.FIELD.NAME", dataField: "name" },
      { resource: "COMPANY.USER.FIELD.TYPE", dataField: "type" },
      { resource: "COMPANY.USER.FIELD.EMAIL", dataField: "email" },
      { resource: "COMPANY.USER.FIELD.JOB_TITLE", dataField: "jobtitle" },
      { resource: "COMPANY.USER.FIELD.TELEPHONE", dataField: "telephone" },
      { resource: "COMPANY.USER.FIELD.LASTLOGINDATE", dataField: "lastlogindate", dataType: 'date' },
      { resource: "COMPANY.USER.FIELD.STATUS", dataField: "deleted", cellRender: this.renderStatusColumn, lookup: { dataSource: systemAccountStatus, displayExpr: 'value', valueExpr: 'key' } }
    ];
    if (isFinancialLimitEnabled) {
      this.columns.push(
        { resource: "GENERAL.FORMS.FIELD.FINANCIAL_LIMIT_AMOUNT", dataField: "userlimit" },
        { resource: "GENERAL.FORMS.FIELD.FINANCIAL_LIMIT_CURRENCY", dataField: "userlimitcurrency" },
      )
    }
    this.columns.push(
      { resource: "CONTRACT.USERS.FIELD.EMAILS_DISABLED", dataField: "disableglobalemailnotifications" },
      { resource: "COMPANY.USER.FIELD.EMAILS_OPTEDOUT", dataField: "optoutemailnotifications" },
    )
    this.columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(props.company.usercustomfields));

    this.state = {
      company: null,
      companies: []
    }
  }
  componentDidMount() {
    if (!this.props.company.enablecompanyusers) {
      this.fetchCompanies();
    }
  }
  fetchCompanies = async () => {
    let companies = [];
    
    try {
      companies = (await companiesCrud.getCompanies()).data;
      const filteredCompanies = JSON.parse(companies.aggregatelist).filter(c => c.archived !== 1 && c.enablecompanyusers != 0 && c.companyid != this.props.params.companyid);
      this.setState({ companies: filteredCompanies });
    } catch (err) {
      this.props.enqueueSnackbar("Error fetching companies: " + err.toString(), { variant: 'error', });
    }
  }
  onTableSelectionChanged = ({ selectedRowsData }) => {
    if (selectedRowsData.length > 0) {
      const data = selectedRowsData[0];
      this.props.navigate(this.props.location.pathname + '/' + data.accountid, { replace: false });
    }
  }
  renderStatusColumn = (cellInfo) => {
    let badgeVariant = 'secondary';
    let label = '';
    switch (cellInfo.data.deleted) {
      case 1:
        label = this.props.intl.formatMessage({ id: "GENERAL.ACCOUNT.STATUS_PASSIVE" })
        badgeVariant = 'secondary';
        break;
      case 0:
        label = this.props.intl.formatMessage({ id: "GENERAL.ACCOUNT.STATUS_ACTIVE" })
        badgeVariant = 'success';
        break;
      default:
        break;
    }
    return (<Badge variant={badgeVariant}>{label}</Badge>);
  }
  render() {
    if (!this.props.companyUsers || !this.props.company || (!this.props.company.enablecompanyusers && !this.state.companies)) {
      return (<PortletSpinner />);
    }

    const addUsersToCompanyName = !this.props.company.enablecompanyusers ? this.state.companies.find(c => c.companyid === this.props.company.adduserstocompanyid)?.name : null

    return (
      <Portlet>
        <PortletBody>
          {
            !this.props.company.enablecompanyusers ? (
              <Alert variant="warning" style={{ display: 'block', margin: 20 }}>
                  <Alert.Heading><FormattedMessage id="COMPANY.USERS.ALERT.IS_NOT_ALLOWED_TO_ADD_USERS" /></Alert.Heading>
                  <hr />
                  <p>
                  {addUsersToCompanyName && (
                    <>
                      <FormattedMessage
                        id="COMPANY.USERS.WARNING.LINK_TO_COMPANY"
                        values={{ LINK: (<Link to={`/admin/companies/${this.props.company.adduserstocompanyid}/users`} style={{ fontWeight: 500 }}>{addUsersToCompanyName}</Link>) }}
                      />
                      <br />
                    </>
                  )}
                      <FormattedMessage id="COMPANY.USERS.WARNING.LINK_TO_SETTING" values={{ LINK: (<Link to={`/admin/companies/${this.props.company.companyid}/overview`} style={{ fontWeight: 500 }}><FormattedMessage id="COMPANY.USERS.WARNING.COMPANY_OVERVIEW_PAGE"/></Link>) }} /><br />
                  </p>
              </Alert>
            ) : (
              <DataGridBase
                version="2"
                title={this.props.intl.formatMessage({ id: "COMPANY.USERS.TITLE" })}
                createPath={!this.props.company.deactivated && this.canCreateNew ? `/admin/companies/${this.props.company.companyid}/new-company-user` : null}
                name={this.props.intl.formatMessage({ id: "COMPANY.USERS.TITLE" })}
                dataSource={this.props.companyUsers}
                selection={{ mode: 'single' }}
                onSelectionChanged={this.onTableSelectionChanged}
                keyExpr="companyuserid"
                columns={this.columns}
              />
            )
          }
        </PortletBody>
      </Portlet>
    );
  }
}

const mapStateToProps = store => ({
  company: store.company.company,
  companyUsers: store.company.companyUsers,
  userProfile: store.auth.userProfile
});

export default withRouter(injectIntl(connect(mapStateToProps)(CompanyUsers)));
