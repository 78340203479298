import React from "react";
import Chart from "react-google-charts";
import { injectIntl } from "react-intl";

class DonutChart extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.data !== nextProps.data ||
      this.props.colors !== nextProps.colors
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  render() {
    const { title, data, colors } = this.props;
    const total = data.reduce((previousVal, item) => { if (typeof item[1] === 'number') { return previousVal + item[1] } else { return previousVal } }, 0);
    if (total === 0) {
      return (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.1rem',  color: '#74788d', textAlign: 'center' }}>{this.props.intl.formatMessage({ id: 'GENERAL.WARNING.NO_ITEM_AVAILABLE' }, { ITEM_NAME: title })}</span>
        </div>
      );
    }
    return (
      <Chart
        width={'220px'}
        height={'220px'}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          legend: { position: 'bottom', textStyle: { fontSize: 10 } },
          title: total + ' ' + title,
          titleTextStyle: { color: '#74788d', fontName: 'Poppins', fontSize: 15, bold: false, fontWeight:'500' },
          tooltip: { text: 'value' },
          // Just add this option
          chartArea: { height: '80%', width: '100%', top: 25 },
          pieSliceText: 'value',
          pieSliceTextStyle: { color: '#74788', fontName: 'Poppins', fontSize: 15, bold: true, fontWeight:'500' },
          pieHole: 0.4,
          // is3D: true,
          colors: colors,
        }}
      // rootProps={{ 'data-testid': '3' }}
      />
    )
  }
}

export default injectIntl(DonutChart);