const template = {
    name: "Default FIDIC Green Book 1st Edition 1999 v2.5 01/01/2024",
    isSystemDefault: true,
    isGlobal: true,
    companies: [],
    resources: [
        {
            id: 1,
            type: "TEXT",
            key: "SUPPLIER_NAME",
            value: "Contractor"
        },
        {
            id: 2,
            type: "TEXT",
            key: "SUPPLIER_ABBREVIATION",
            value: "CR"
        },
        {
            id: 3,
            type: "TEXT",
            key: "PM_NAME",
            value: "Engineer"
        },
        {
            id: 4,
            type: "TEXT",
            key: "PM_ABBREVIATION",
            value: "ENG"
        },
        {
            id: 5,
            type: "TEXT",
            key: "SUPERVISOR_NAME",
            value: "Supervisor"
        },
        {
            id: 6,
            type: "TEXT",
            key: "SUPERVISOR_ABBREVIATION",
            value: "SUP"
        },
        {
            id: 7,
            type: "TEXT",
            key: "CLIENT_NAME",
            value: "Employer"
        },
        {
            id: 8,
            type: "NUMBER",
            key: "SUPPLIER_REPLY_PERIOD",
            value: 7
        },
        {
            id: 9,
            type: "NUMBER",
            key: "PM_REPLY_PERIOD",
            value: 7
        },
        {
            id: 10,
            type: "NUMBER",
            key: "QUOTE_DURATION",
            value: 28
        },
        {
            id: 13,
            type: "NUMBER",
            key: "REQUOTE_DURATION",
            value: 14
        },
        {
            id: 11,
            type: "NUMBER",
            key: "QUOTE_RESPONSE",
            value: 14
        },
        {
            id: 14,
            type: "NUMBER",
            key: "REQUOTE_RESPONSE",
            value: 14
        },
        {
            id: 12,
            type: "NUMBER",
            key: "VARIATION_RESPONSE",
            value: 14
        },
        {
            id: 16,
            type: "NUMBER",
            key: "PROGRAMME_REPLY_PERIOD",
            value: 14
        },
        {
            id: 30,
            type: "NUMBER",
            key: "CWN_QUOTE_DURATION",
            value: 56
        },
        {
            id: 31,
            type: "NUMBER",
            key: "CWN_QUOTE_RESPONSE",
            value: 42
        },
        {
            id: 32,
            type: "NUMBER",
            key: "INTERIM_CWN_QUOTE_DURATION",
            value: 28
        },
        {
            id: 33,
            type: "NUMBER",
            key: "INTERIM_CWN_QUOTE_RESPONSE",
            value: 42
        },
        {
            id: 8,
            type: "TEXT",
            key: "CLIENT_ABBREVIATION",
            value: "EMP"
        },
        {
            id: 28,
            type: "NUMBER",
            key: "PROP_QUOTE_DURATION",
            value: 14
        },
        {
            id: 29,
            type: "NUMBER",
            key: "PROP_REQUOTE_DURATION",
            value: 14
        },
        {
            id: 9,
            type: "TEXT",
            key: "COMPLETION_DATE",
            value: "Completion Date"
        }
    ],
    workflowGroups: [
        {
            id: 101,
            title: "Early Warning",
            workflows: [
                {
                    id: 1,
                    title: "$PM_NAME Early Warning",
                    notificationName: "Notification of Early Warning",
                    abbreviation: "$PM_ABBREVIATION-Warning-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    actioningParty: "PM",
                    resolveActioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
                        resolveActionTab: {
                            resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                },
                {
                    id: 2,
                    title: "$SUPPLIER_NAME Early Warning",
                    notificationName: "Notification of Early Warning",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Warning-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    actioningParty: "PM",
                    resolveActioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
                        resolveActionTab: {
                            resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                },
                {
                    id: 3,
                    title: "Advance Warning Register",
                    notificationName: "Advance Warning",
                    abbreviation: "Warning",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "MeetingRequiredLabel",
                            display: "Is a meeting required to discuss the matter?",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "NotificationClause",
                            display: "Under clause 10.3, you are advised of a circumstance which may",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "RiskItemsLabel",
                            display: "You are notified of a circumstance which may",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "DescriptionLabel",
                            display: "Description",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Pm",
                            display: "$PM_NAME Advance Warning awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Advance Warning awaiting reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypes",
                    values: [
                        {
                            id: 4,
                            isActive: true,
                            field: "riskaffectwork",
                            display: "delay or disrupt the Works",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "riskincreaseprice",
                            display: "give rise to a claim or additional payment",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "MeetingOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "0",
                            display: "Meeting Not Required",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Meeting Required - Next Scheduled Meeting",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Meeting Required - You are required to attend a meeting as described below",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypesSupplier",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "riskaffectwork",
                            display: "delay or disrupt the Works",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "riskincreaseprice",
                            display: "give rise to a claim or additional payment",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 102,
            title: "Instructions",
            workflows: [
                {
                    id: 4,
                    title: "$PM_NAME Instruction",
                    notificationName: "Instruction",
                    abbreviation: "$PM_ABBREVIATION-Instruction-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "InstructionTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "1.3 in clarification of any ambiguity or discrepancy",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "2.3 of an instruction in respect of the Works",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 103,
            title: "Variations and Contractor Claims",
            workflows: [
                {
                    id: 5,
                    title: "Variation by Instruction",
                    notificationName: "Notice of Variation by Instruction",
                    abbreviation: "Variation-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    quoteNotificationName: "Detailed Particulars",
                    quoteReferencePrefix: "Particulars-",
                    form_IncludeCompanyName: true,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 37,
                    title: "$SUPPLIER_NAME Claim",
                    notificationName: "Notice of Claim",
                    abbreviation: "$SUPPLIER_ABBREVIATION-NOC-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    quoteNotificationName: "Fully Detailed Claim",
                    quoteReferencePrefix: "Claim-",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                },
                {
                    id: 8,
                    title: "Determinations",
                    notificationName: "Notice of Determination of a Variation or Claim",
                    abbreviation: "$PM_ABBREVIATION-Determination-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmAssumptionLabel",
                            display: "The quotation is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmAssumptionNote",
                            display: "Under clause 13.3.1, you are required to provide detailed particulars by $date",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierDescNote",
                            display: "You are hereby given Notice of a Claim under clause 20.2.1",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "ReplyAcceptedCommentLabel",
                            display: "Comments",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "ReplyRejectedCommentLabel",
                            display: "Comments",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ReplyAcceptedAssumptionLabel",
                            display: "The quotation is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "QuoteNote",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "ExtensionPMLabel",
                            display: "An extension of the time to reply is requested as follows",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ExtensionSupplierLabel",
                            display: "An extension of time to provide a proposal/claim is requested as follows",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "AssessmentDetailLabel",
                            display: "Details",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ProposedInstructionNote",
                            display: "You are requested to provide a proposal by $date",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "ProposedInstructionLabel",
                            display: "Your proposal is to be based on the following assumptions",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "ReplyRejectedOptionsLabel",
                            display: "You are notified that the claim is not valid for the following reason(s)",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "QuoteCostEffectLabel",
                            display: "Change to the Prices",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "QuoteDateEffectLabel",
                            display: "Extension of Time for Completion (days)",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "AssessmentCostEffectLabel",
                            display: "Change to the Prices",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "AssessmentDaysEffectLabel",
                            display: "Extension of Time for Completion (days)",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "AssessmentWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "PmCompensationWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "InstructionQuoteReplyWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "CompensationTypeLabel",
                            display: "Type",
                            overrideResources: null
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "QuoteDaysEffectLabel",
                            display: "Extension of Time for Completion (days)",
                            overrideResources: null
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "QuoteProgrammeAttachLabel",
                            display: "Are you attaching a programme?",
                            overrideResources: null
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "AssessmentCompensationLabel",
                            display: "Claim/Variation",
                            overrideResources: null
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "QuoteNoteProposedInstruction",
                            display: "We submit our proposal for $reference",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmCompensationAQ",
                            display: "Variations awaiting $SUPPLIER_NAME's valuation",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmCompensationARQ",
                            display: "Variations awaiting $SUPPLIER_NAME's revised valuation",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "PmCompensationAPMRQ",
                            display: "Variations awaiting $PM_NAME's response to valuation",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "PmCompensationAPMA",
                            display: "Variations awaiting awaiting $PM_NAME's Determination",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "PmProposedAQ",
                            display: "RFP awaiting proposal",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "PmProposedARQ",
                            display: "RFP awaiting revised proposal",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "PmProposedAPMRQ",
                            display: "Awaiting $PM_NAME's response to $SUPPLIER_NAME's proposal",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "PmProposedAPMA",
                            display: "Proposed Variation awaiting $PM_NAME's Determination",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "SupClaimAPMR",
                            display: "$SUPPLIER_NAME Claims awaiting $PM_NAME's response",
                            overrideResources: null
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "SupClaimAQ",
                            display: "$SUPPLIER_NAME Notices of Claims awaiting Detailed Claim",
                            overrideResources: null
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "SupClaimARQ",
                            display: "$SUPPLIER_NAME Claims awaiting revised details",
                            overrideResources: null
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "SupClaimAPMRQ",
                            display: "$SUPPLIER_NAME Claims awaiting response",
                            overrideResources: null
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "SupClaimAPMA",
                            display: "$SUPPLIER_NAME Claims awaiting $PM_NAME's Determination",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "PmExtension",
                            display: "$PM_NAME Quote Response Extensions awaiting Acceptance",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "SupExtension",
                            display: "$SUPPLIER_NAME Quote Extensions awaiting Acceptance",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SupCompensationAQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting quotes",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SupCompensationARQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting revised quotes",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "SupCompensationAPMR",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION response",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "SupCompensationAPMRQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting response to quotes",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "SupCompensationAPMA",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "COMPENSATION_CWN_AQ",
                            display: "$SUPPLIER_NAME Notice of Claim awaiting valuation",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "COMPENSATION_CWN_ARQ",
                            display: "$SUPPLIER_NAME Claim awating revised valuation",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "COMPENSATION_CWN_APMR",
                            display: "$SUPPLIER_NAME Notice of Claim Awaiting $PM_NAME's Initial Response",
                            overrideResources: null
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "COMPENSATION_CWN_APMRQ",
                            display: "$SUPPLIER_NAME's Claim awaiting $PM_NAME's response",
                            overrideResources: null
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "COMPENSATION_CWN_APMA",
                            display: "$SUPPLIER_NAME Claim awaiting $PM_NAME's Determination",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "faultpscp",
                            display: "Arises from a fault of the Contractor",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "notexpectnothappen",
                            display: "Has not happened and is not expected to happen",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "notnotifiedwithintimescales",
                            display: "The claiming Party has failed to give the Notice of Claim within the period of 28 days under Sub-Clause 20.2.1",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "noteffectcostcompletion",
                            display: "Has no effect on Defined Cost, Completion or meeting a Key Date",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: false,
                            field: "statedcontract",
                            display: "Is not one of the compensation events stated in the contract",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ClaimClauses",
                    values: [
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "10.4 that the Contractor has suffered delay and/or incurred Costs as a consequence of an Employer's Liability",
                            overrideResources: [
                                {
                                    key: "QUOTE_DURATION",
                                    value: "28"
                                }
                            ]
                        }
                    ]
                },
                {
                    replacementKey: "CompensationStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "APMA",
                            display: "Not Accepted - $PM_NAME to make a Determination",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ARQ",
                            display: "Not Accepted - $SUPPLIER_NAME to resubmit",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "AM",
                            display: "$PM_ABBREVIATION Determination Made",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "QA",
                            display: "Quote Accepted",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "APMRQ",
                            display: "Awaiting $PM_NAME response to $SUPPLIER_NAME valuation",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "APMR",
                            display: "Awaiting $PM_ABBREVIATION response to Notice of Claim",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "AQ",
                            display: "Awaiting $SUPPLIER_NAME valuation",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NC",
                            display: "Not Communicated",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "R",
                            display: "Rejected",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "DNG",
                            display: "Proposed Variation will not be instructed",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "InstructionReplyProceedOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "61.1",
                            display: "Instruction of the Variation",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "61.2",
                            display: "Variation not instructed",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "INTERIM_QUOTES_ALLOWED",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "INTERIM_QUOTES_ALLOWED",
                            display: "Active-Inactive state indicates whether the continuing effect field is asked on quote communications",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "EWRelationEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "EWRelationEnabled",
                            display: "Active-Inactive state indicates weather the EWN can be attached in CEN communications",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "RelatedCompensationText",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "RelatedCompensationText",
                            display: "Regarding the aforementioned instruction, you are hereby notified of a Variation",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "VariationClauses",
                    values: [
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "10.1 Variation",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "CompensationClauses",
                    values: [
                        {
                            id: 41,
                            isActive: false,
                            field: "",
                            display: "5.4 Initiated in connection with a change in the law",
                            overrideResources: null
                        },
                        {
                            id: 38,
                            isActive: true,
                            field: "",
                            display: "10.1 Variation by Instruction",
                            overrideResources: null
                        },
                        {
                            id: 40,
                            isActive: false,
                            field: "",
                            display: "13.4 Variation by Instruction - Provisional Sum",
                            overrideResources: null
                        },
                        {
                            id: 39,
                            isActive: false,
                            field: "",
                            display: "13.5 Variation by Instruction - Daywork",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ClaimWNAcceptanceOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "1",
                            display: "Accepted",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "0",
                            display: "Not Accepted",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "Fields",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "Assumption",
                            display: "Additional information",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "QuoteProgrammeImpactOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "noeffect",
                            display: "There is no effect on the Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "mitigated",
                            display: "There is an effect on the Time for Completion but this has been mitigated by measures as detailed",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "notmitigated",
                            display: "There is an effect on the Time for Completion",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "QuoteStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "0",
                            display: "Not Agreed - $SUPPLIER_NAME to resubmit",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Agreed",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Awaiting Response",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "3",
                            display: "Not Agreed - $PM_NAME to make a determination",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "4",
                            display: "Rejected - Other valuation agreed",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "5",
                            display: "Rejected",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: false,
                            field: "6",
                            display: "Awaiting Deemed Acceptance [Not Applicable]",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: false,
                            field: "7",
                            display: "Deemed Acceptance [Not Applicable]",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "none",
                            display: "Not Communicated",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: false,
                            field: "8",
                            display: "Acknowledged [Not Applicable]",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "9",
                            display: "Not Agreed - Process ended",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "InstructionReplyOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "1",
                            display: "QuoteStatus_1",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "0",
                            display: "QuoteStatus_0",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "3",
                            display: "QuoteStatus_3",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "5",
                            display: "QuoteStatus_5",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "InstructionReplyProceedEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "InstructionReplyProceedEnabled",
                            display: "Proposed instructions Quote Reply field proceed options Enabled/Disabled",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 104,
            title: "Programmes",
            workflows: [
                {
                    id: 14,
                    title: "Works Programmes",
                    notificationName: "Programme",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Programme-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 8,
                            isActive: true,
                            field: "NotificationClause",
                            display: "Under clause 7.2 we submit a works programme",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ReplyRejectReasonLabel",
                            display: "No-objection",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "ReplyNotificationClause",
                            display: "Does not comply with the Contract",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ReasonLabel",
                            display: "Reason for any change to Planned Completion",
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "ChangeLabel",
                            display: "Change in any other Key Date, Sectional Completion or other important project milestone in period and reasons why",
                            overrideResources: null
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "MainProgressLabel",
                            display: "Main progress achieved in period",
                            overrideResources: null
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "KeyActivitiesLabel",
                            display: "Key activities forecast in next four weeks",
                            overrideResources: null
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "SummaryLabel",
                            display: "Summary of critical path",
                            overrideResources: null
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "ChangesLabel",
                            display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc.",
                            overrideResources: null
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "CompensationLabel",
                            display: "Claims/Variations added to programme this period",
                            overrideResources: null
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "ReferenceLabel",
                            display: "Programme Reference",
                            overrideResources: null
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "DashboardPlannedCompletionLabel",
                            display: "Planned Completion",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Programme Submission awaiting Reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 4,
                            isActive: true,
                            field: "notcomply",
                            display: "The programme does not comply with the Contract",
                            overrideResources: null
                        },
                        {
                            id: 1,
                            isActive: true,
                            field: "plansnotpractical",
                            display: "The programme does not reflect actual progress",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "missingcontractrequires",
                            display: "The programme is otherwise inconsistent with the Contractor's obligations",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "Fields",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PreviousPlanned",
                            display: "Previous Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlannedCompletion",
                            display: "Anticipated Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Reason",
                            display: "Reason for any change in the Time for Completion",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Change",
                            display: "Change to important project milestone in the period and reasons why",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "MainProgress",
                            display: "Main progress achieved in period",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "KeyActivities",
                            display: "Key activities forecast in next four weeks",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "Summary",
                            display: "Summary of critical path",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "Changes",
                            display: "Change to important project milestones in the period and reasons why",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "Compensation",
                            display: "Claims or Variations added to programme this period",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Clause 8.3 - Notice of No Objection",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Not Accepted",
                            display: "Clause 8.3 - Notice of Rejection",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "DashboardDisplayPlannedCompletionOnLatest",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "DashboardDisplayPlannedCompletionOnLatest",
                            display: "Active-Inactive state indicates weather (Planned Completion shown on the latest Accepted Programme) is displayed on dasboard",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 105,
            title: "Other Notices",
            workflows: [
                {
                    id: 15,
                    title: "$PM_NAME Notices",
                    notificationName: "Notice",
                    abbreviation: "$PM_ABBREVIATION-Notice-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 16,
                    title: "$SUPPLIER_NAME Notices",
                    notificationName: "Notice",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Notice-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "PmClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "1.3 of a change in address for communications",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "1.5 of an ambiguity or discrepancy",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "3.1 of the appointment or revocation of appointment of the natural person or replacement authorised to be the Engineer",
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "8.2 of taking-over",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "12.1 of default by the Contractor",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "12.1 of termination",
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "12.3 of termination",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "13.2 of prevention from performing obligations by Force Majeure",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "13.2 of termination",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "SupplierClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "1.3 of a change in address for communications",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "1.5 of an ambiguity or discrepancy",
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "8.1 that the Works are complete",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "12.2 of default by the Employer",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "12.2 of termination",
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "12.3 of termination",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "13.2 of prevention from performing obligations by Force Majeure",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "13.2 of termination",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Notifications awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PM",
                            display: "$PM_NAME Notifications awaiting reply",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Supervisor",
                            display: "$SUPERVISOR_NAME Notifications awaiting reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Notice of no objection",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "Accepted with comments",
                            display: "Accepted with comments",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Not Accepted",
                            display: "Notice of objection",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Reply Not Required",
                            display: "No reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "Resources",
                    values: []
                }
            ]
        },
        {
            id: 106,
            title: "Other Communications",
            workflows: [
                {
                    id: 49,
                    title: "$PM_NAME Communications",
                    notificationName: "Communication",
                    abbreviation: "$PM_ABBREVIATION-Comm-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 18,
                    title: "$SUPPLIER_NAME Communication",
                    notificationName: "Communication",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Comm-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "ReplyWarningBanner",
                            display: "",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "IssueClauses",
                    values: [
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "2.2 of a request for assistance in applying for permits, licences or approvals",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "4.2 of a submission for consent of the name of a proposed Contractor's Representative or temporary or permanent replacement",
                            applyReplyPeriod: false,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "4.3 of the submission for consent to a proposed Subcontractor",
                            applyReplyPeriod: true,
                            replyPeriod: 14,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "5.1 of the submission of a design prepared by the Contractor",
                            applyReplyPeriod: true,
                            replyPeriod: 14,
                            noReply: false,
                            overrideResources: null
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "7.3 of an application for an extension of Time for Completion",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "11.2 of a submission of a monthly statement showing amounts to be paid to the Contractor",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "11.6 of a submission of a final account showing amounts to be paid to the Contractor",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "12.2 of a complaint relating to breach of the Contract",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Communication awaiting Reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ReplyOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Accepted",
                            display: "Consent/Acceptance/Notice of No-objection",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: false,
                            field: "Accepted with comments",
                            display: "Accepted with comments",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Not Accepted",
                            display: "Rejection/Notice of objection",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Reply Not Required",
                            display: "No reply",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "IssueClausesPM",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "12.1 of a complaint relating to breach of the Contract",
                            applyReplyPeriod: false,
                            noReply: true,
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 108,
            title: "Payments",
            workflows: [
                {
                    id: 20,
                    title: "$PM_NAME Notice of a Statement of Payment",
                    notificationName: "Notice of a Statement of Payment",
                    abbreviation: "$PM_ABBREVIATION-Payment-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 21,
                    title: "$SUPPLIER_NAME Statement of Application of Payment",
                    notificationName: "Statement of Application of Payment",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Statement-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CumulativePriceLabel",
                            display: "Value of Work Done",
                            overrideResources: null
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlusAmountsSupplierLabel",
                            display: "Plus other amounts to be paid to the Contractor",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "LessAmountsSupplierLabel",
                            display: "Less amounts to be paid by or retained from the Contractor",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TaxSupplierLabel",
                            display: "Tax which the law requires the Employer to pay to the Employer",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "WarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "RegisterAppliedByCaption",
                            display: "Submitted by",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "SummaryDetailsLabel",
                            display: "Summary details",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "TaxEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "TaxEnabled",
                            display: "Active-Inactive state indicates tax field will be included in notification forms",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ApplicationTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "INTERIM",
                            display: "8.3 submission of a statement showing the amount due",
                            overrideResources: null
                        },
                        {
                            id: 1,
                            isActive: true,
                            field: "FINAL",
                            display: "8.6 of a draft/agreed final statement",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "PaymentTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "INTERIM",
                            display: "8.4 of a payment certificate",
                            overrideResources: null
                        },
                        {
                            id: 1,
                            isActive: true,
                            field: "FINAL",
                            display: "8.5 of the final statement",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: false,
                            field: "SUPPLEMENTARY",
                            display: "Supplementary",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 109,
            title: "Defects",
            workflows: [
                {
                    id: 22,
                    title: "Instructions to Search for a Defect",
                    notificationName: "Instruction to Search for a Defect",
                    abbreviation: "Search-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "NOTIFICATION_DATE",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 23,
                    title: "$PM_NAME Defect Notifications",
                    notificationName: "Notice of a Defect or Damage",
                    abbreviation: "$PM_NAME-Defect-",
                    notify: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "NOTIFICATION_DATE",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    actioningParty: "PM",
                    resolveActioningParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]",
                        resolveActionTab: {
                            resolveActionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                },
                {
                    id: 26,
                    title: "Master Defect Register",
                    notificationName: "Defect Notification",
                    abbreviation: "Defect",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "SupervisorClauseLabel",
                            display: "Under clause 9.1 you are notified of the following",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierClauseLabel",
                            display: "You are notified of the following Defect",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TestsClauseLabel",
                            display: "You are hereby given Notice of the following test or inspection",
                            overrideResources: null
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "DefectsWarningBanner",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SearchWarningBannerDate",
                            display: "",
                            overrideResources: null
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "SearchWarningBannerDetails",
                            display: "",
                            overrideResources: null
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "SuperDefect",
                            display: "$PM_NAME Notification of Defect awaiting sign-off",
                            overrideResources: null
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Instruction",
                            display: "$PM_NAME Instruction to Search awaiting sign-off",
                            overrideResources: null
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "Inspection",
                            display: "$SUPPLIER_NAME Test/Inspection awaiting sign-off",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 110,
            title: "Delegated Actions",
            workflows: [
                {
                    id: 27,
                    title: "$PM_NAME Delegated Actions",
                    notificationName: "Notice of Delegation",
                    abbreviation: "$PM_ABBREVIATION-Delegation-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "DelegationClause",
                            display: "You are hereby notified of assignment of authority or delegation of duties under clause 3.2.\n",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 111,
            title: "Records",
            workflows: [
                {
                    id: 30,
                    title: "$PM_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$PM_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 31,
                    title: "$SUPPLIER_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 32,
                    title: "$SUPPLIER_NAME Site Diary",
                    notificationName: "Site Diary",
                    abbreviation: "Diary-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 33,
                    title: "Weather",
                    notificationName: "Weather",
                    abbreviation: "Weather-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: []
        },
        {
            id: 112,
            title: "RFI / TQ",
            workflows: [
                {
                    id: 34,
                    title: "$SUPPLIER_NAME RFI / TQ",
                    notificationName: "RFI / TQ",
                    abbreviation: "RFI/TQ-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    form_IncludeCompanyName: false,
                    form_CompanyLogo: "PARTY",
                    form_CompanyName: "PARTY",
                    notifyAttachments: true,
                    replyAttachments: true,
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME RFI/TQ awaiting Reply",
                            overrideResources: null
                        }
                    ]
                }
            ]
        },
        {
            id: 113,
            title: "Contract Information",
            workflows: [
                {
                    id: 39,
                    title: "Documents",
                    notificationName: "Document",
                    abbreviation: "Documents-",
                    notify: true,
                    actioningParty: "PM",
                    overrideResources: null,
                    userFilters: {
                        accessFilter: "null",
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        }
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: []
                }
            ]
        }
    ]
};

export default template;