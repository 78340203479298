import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { LayoutSplashScreen } from "../../../../_metronic";
import * as authDuck from "../../../store/ducks/auth.duck";
import { connect } from "react-redux";

const Auth0LogoutHandler = (props) => {
  const { isAuthenticated, logout, } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      console.log("Auth0 Logout-1 process started!")
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
      });
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated && logout) {
      console.log("Auth0 Logout-2 process started!")
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
      });
    }
    if (!isAuthenticated) {
      console.log("Auth0 FD-Logout process started!")
      props.startLogout();
    }
  }, [logout, isAuthenticated]);


  if (isAuthenticated) {
    return (
      <div style={{ margin: "auto" }}>
        <LayoutSplashScreen />
      </div>
    )
  }
}

const mapDispatchToProps = {
  startLogout: authDuck.actions.startLogout
};


export default connect(null, mapDispatchToProps)(Auth0LogoutHandler);
