/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

const spanStyleViewRegister = {
  whiteSpace: 'normal',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  paddingBottom: 0,
  fontWeight: 400
};

function ContractTimeline(props) {
  let dates = [];
  let navigate = useNavigate();
  const isContractOtherDatesEnabled = Boolean(props.iscontractsectional);

  const getDate = (label, date, circleClass, styles, index, link) => {
    const baseStyles = styles ? { ...styles } : {};
    return (
      <div key={index} className="kt-timeline-v2__item" style={baseStyles}>
        <span className="kt-timeline-v2__item-time">{moment(date).format('L').replace(/\//g, '.')}</span>
        <div className="kt-timeline-v2__item-cricle">
          <i className={"fa fa-genderless " + circleClass} />
        </div>
        <div className="kt-timeline-v2__item-text kt-padding-top-5">
          {label}
        </div>
        {
          (link && isContractOtherDatesEnabled) && (
            <div className="kt-timeline-v2__info" style={{ textAlign: 'right', flexGrow: 2 }}>
              <span className="kt-timeline-v2__desc" style={spanStyleViewRegister}><a onClick={() => { navigate(link); }} style={{ textDecoration: 'underline', fontSize: '1.1rem', paddingTop: '0.5rem' }}><FormattedMessage id="CONTRACT.DASHBOARD.VIEW" /></a></span>
            </div>
          )
        }
        {/* <div className="kt-list-pics kt-list-pics--sm kt-padding-l-20" /> */}
      </div>
    );
  }

  if (props.currentenddate <= props.enddate) {
    const styles = {};
    if (new Date(props.currentenddate).getTime() === new Date(props.enddate).getTime()) {
      styles.marginBottom = '0.2rem';
    }
    dates = [
      { label: props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.CURRENT_COMPLETION' }, { COMPLETION_DATE: props.globalResources.COMPLETION_DATE }), date: props.currentenddate, circle: 'kt-font-success', styles: styles },
      { label: props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.ORIGINAL_COMPLETION' }, { COMPLETION_DATE: props.globalResources.COMPLETION_DATE }), date: props.enddate, circle: 'kt-font-brand' },      
    ];

  }
  else {
    dates = [
      { label: props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.ORIGINAL_COMPLETION' }, { COMPLETION_DATE: props.globalResources.COMPLETION_DATE }), date: props.enddate, circle: 'kt-font-brand' },
      { label: props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.CURRENT_COMPLETION' }, { COMPLETION_DATE: props.globalResources.COMPLETION_DATE }), date: props.currentenddate, circle: 'kt-font-danger' },
    ];
  }
  
  if (props.actualcompletiondate){
    dates.push({ label: props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.ACTUAL_COMPLETION' }), date: props.actualcompletiondate, circle: 'kt-font-brand' })
  }

  if (props.defectsdate){
    dates.push({ label: props.globalResources?.DEFECTS_DATE ?? props.intl.formatMessage({ id: 'GENERAL.FORMS.DEFECTS_DATE' }), date: props.defectsdate, circle: 'kt-font-brand', styles: isContractOtherDatesEnabled ? { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } : '', link: `/contract/${props.contractid}/settings/secondary-dates` })
  }
  
  return (
    <>
      <div className="kt-widget14">
        <div className="kt-widget14__header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h3 className="kt-widget14__title"><FormattedMessage id="CONTRACT.DASHBOARD.CONTRACT_DATES" /></h3>
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="layout-tooltip"><FormattedMessage id="CONTRACT.DASHBOARD.DATES_VIEW_TOOLTIP" /></Tooltip>}
          >
            <a onClick={() => { navigate(`/contract/${props.contractid}/compensation-events/register-implemented/list`); }} style={{ textDecoration: 'underline' , fontSize:'1.1rem', paddingTop:'0.5rem' }}><FormattedMessage id="CONTRACT.DASHBOARD.VIEW_REGISTER" /></a>
          </OverlayTrigger>
        </div>
        {/* style="max-height: 50vh;" */}
        <PerfectScrollbar
          options={perfectScrollbarOptions}
          style={{ maxHeight: "40vh", position: "relative" }}
        >
          <div className="kt-timeline-v2 ps ps--active-y">
            <div className="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
              {
                getDate(props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.START_DATE' }), props.startdate, 'kt-font-brand')
              }
              {
                dates.map((itm, index) => {
                  return getDate(itm.label, itm.date, itm.circle, itm.styles, index, itm.link);
                })
              }
              {/*
              <div className="kt-timeline-v2__item">
                <span className="kt-timeline-v2__item-time">{props.startdate.toLocaleDateString('en-GB').replace(/\//g, '.')}</span>
                <div className="kt-timeline-v2__item-cricle">
                  <i className="fa fa-genderless kt-font-brand" />
                </div>
                <div className="kt-timeline-v2__item-text kt-padding-top-5">
                  Start Date
                  </div>
                <div className="kt-list-pics kt-list-pics--sm kt-padding-l-20" />
              </div>
              <div className="kt-timeline-v2__item">
                <span className="kt-timeline-v2__item-time">{props.enddate.toLocaleDateString('en-GB').replace(/\//g, '.')}</span>
                <div className="kt-timeline-v2__item-cricle">
                  <i className="fa fa-genderless kt-font-success" />
                </div>
                <div className="kt-timeline-v2__item-text kt-padding-top-5">
                  Original Completion Date
                  </div>
              </div>
              <div className="kt-timeline-v2__item">
                <span className="kt-timeline-v2__item-time">{props.currentenddate.toLocaleDateString('en-GB').replace(/\//g, '.')}</span>
                <div className="kt-timeline-v2__item-cricle">
                  <i className="fa fa-genderless kt-font-brand" />
                </div>
                <div className="kt-timeline-v2__item-text kt-padding-top-5">
                  Current Completion Date
                  </div>
                <div className="kt-list-pics kt-list-pics--sm kt-padding-l-20" />
              </div>
              
               <div className="kt-timeline-v2__item">
                <span className="kt-timeline-v2__item-time">{props.supplierenddate.toLocaleDateString('en-GB').replace(/\//g, '.')}</span>
                <div className="kt-timeline-v2__item-cricle">
                  <i className="fa fa-genderless kt-font-info" />
                </div>
                <div className="kt-timeline-v2__item-text kt-padding-top-5">
                  {`${props.supplierName}'s Planned Completion Date`}
                </div>
                <div className="kt-list-pics kt-list-pics--sm kt-padding-l-20" />
              </div> */}
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </>
  );
}
export default injectIntl(ContractTimeline)