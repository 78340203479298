import React, { Component } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DocumentList from "./Document/List";
import DocumentCreate from "./Document/Create";
import DocumentDetail from "./Document/Detail";

class ContractInformationDocumentsRouter extends Component {
  constructor(props) {
    super(props);
    this.notificationName = props.workflowItem.notificationName;
    this.abbreviation = props.workflowItem.abbreviation;
  }

  render() {
    const { workflowItem } = this.props;

    return (
      <Routes caseSensitive>
        <Route
          path={workflowItem.config.paths.list}
          exact
          element={
            <DocumentList
              {...this.props}
              title={this.notificationName}
              abbreviation={this.abbreviation}

            />
          }
        />
        <Route
            path={workflowItem.config.paths.create}
            exact
            element={
                workflowItem.userFilters.actionTab.actionAllowed ? (
                  <DocumentCreate
                    {...this.props}
                    title={this.notificationName}
                    communicateToUser={this.communicateToUser}
                  />
                ) : (
                    <Navigate replace to={workflowItem.paths.defaultPath} />
                )
            }
        />
        <Route
          path={'/:notificationid/*'}
          exact
          element={
            <DocumentDetail
              {...this.props}
              title={this.notificationName}
              abbreviation={this.abbreviation}
              //if does not have create right, can not edit attachments
              canNotEdit={!workflowItem.userFilters.actionTab.actionAllowed}
              communicateToUser={this.communicateToUser}
              notifyUserFilter={workflowItem.userFilters.notifyFilter}
            />
          }
        />

        <Route element={<Navigate replace to="/error/error-v5" />} />
      </Routes>
    );
  }
}

export default ContractInformationDocumentsRouter;
