import * as contractsCrud from "../../crud/contracts.crud";
//import * as authDuck from './auth.duck';
import * as preferencesDuck from './preferences.duck';
import store from "../store";
import { select, put, takeLatest } from "redux-saga/effects";

const getContracts = (store) => store.contracts;
const getPreferences = (store) => store.preferences;
//const getAuth = (store) => store.auth;
const actionTypes = {
    CONTRACTS_SET_PENDING: "[CONTRACTS_SET_PENDING]",
    CONTRACTS_FETCH_START: "[CONTRACTS_FETCH_START]",
    CONTRACTS_SET_CONTRACTS: "[CONTRACTS_SET_CONTRACTS]",
    CONTRACTS_LOGOUT: "[CONTRACTS_LOGOUT]",
    CONTRACTS_SET_ACTIVEFILTERS: "[CONTRACTS_SET_ACTIVEFILTERS]",
};

const initialContractsState = {
    contracts: null,
    images: false,
    lastLoadedEpochTime: null,
    pendingFetch: false,
    //activeFilters TEMPLATE=> [ {property:'propertyName', filters:['apples','bananas']}]
    activeFilters: [],
    areasFilter: null,
    hubsFilter: null,
    frameworkFilter: null,
    lotFilter: null
};

export const reducer = (state = initialContractsState, action) => {
    switch (action.type) {
        case actionTypes.CONTRACTS_SET_PENDING: {
            return { ...state, pendingFetch: true };
        }
        case actionTypes.CONTRACTS_FETCH_START: {
            return { ...state, pendingFetch: false, lastLoadedEpochTime: new Date().getTime() };
        }
        case actionTypes.CONTRACTS_SET_CONTRACTS: {
            return { ...state, contracts: action.payload.contracts, areasFilter: action.payload.areasFilter, hubsFilter: action.payload.hubsFilter, frameworkFilter: action.payload.frameworkFilter, lotFilter: action.payload.lotFilter, otherTagFilter: action.payload.otherTagFilter };
        }
        case actionTypes.CONTRACTS_SET_ACTIVEFILTERS: {
            return { ...state, activeFilters: action.payload.activeFilters };
        }
        case actionTypes.CONTRACTS_LOGOUT: {
            return initialContractsState;
        }

        default:
            return state;
    }
};

export const actions = {
    fetchStart: (force = false) => {
        const authStore = store.getState().auth;
        const contractsStore = store.getState().contracts;

        if (authStore) {
            if (!authStore.authToken.token || !authStore.csrfToken.token) {
                if (!contractsStore.pendingFetch) {
                    return { type: actionTypes.CONTRACTS_SET_PENDING };
                }
                return { type: '' };
            }
        }

        if (!contractsStore.pendingFetch && !force && contractsStore) {
            //DO NOT REPEAT REQUEST IN LESS THAN A MINUTE
            if (contractsStore.lastLoadedEpochTime) {
                const cacheTimeoutSec = Number(process.env.REACT_APP_CACHE_TIMEOUT_CONTRACTS);
                if (new Date(contractsStore.lastLoadedEpochTime + (cacheTimeoutSec * 1000)) > new Date()) {
                    // console.log("Contracts loaded less than a minute ago!");
                    return { type: '' };
                }
            }
        }
        return { type: actionTypes.CONTRACTS_FETCH_START };
    },
    setPending: () => ({ type: actionTypes.CONTRACTS_SET_PENDING }),
    setContracts: (contracts, areasFilter, hubsFilter, frameworkFilter, lotFilter, otherTagFilter) => ({ type: actionTypes.CONTRACTS_SET_CONTRACTS, payload: { contracts, areasFilter, hubsFilter, frameworkFilter, lotFilter, otherTagFilter } }),
    setActiveFilters: (activeFilters) => ({ type: actionTypes.CONTRACTS_SET_ACTIVEFILTERS, payload: { activeFilters } }),
    logout: () => ({ type: actionTypes.CONTRACTS_LOGOUT })
};
export function* saga() {
    /*yield takeLatest(authDuck.actionTypes.AUTH_LOAD_COMPLETED, function* contractsLoginSaga() {
        try {
            //const contractsStore = yield select(getContracts);
            //if (contractsStore.pendingFetch) {
            // yield put(actions.fetchStart(true));
            //}
        }
        catch { console.log("error in contractsLoginSaga") }
    });*/
    yield takeLatest([actionTypes.CONTRACTS_FETCH_START], function* contractsLoadContractsSaga() {
        try {
            // const preferencesStore = yield select(getPreferences);
            const response = yield contractsCrud.getContracts();
            let areasfilter = null;
            let hubsFilter = null;
            let frameworkFilter = null;
            let lotFilter = null;
            let otherTagFilter = null;
            try {
                if (response.data.uniquetags) {
                    const allUniquieTags = response.data.uniquetags;
                    if (allUniquieTags) {
                        const areatags = allUniquieTags.categories.find(ctg => ctg.name === 'Areas');
                        if (areatags && areatags.tags) {
                            areasfilter = areatags.tags.map(tag => ({ text: tag, value: ['tags_area', 'contains', tag] }));
                            areasfilter.push({
                                text: 'Blank',
                                value: ['tags_area', '=', null]
                            });
                        }
                        const hubtags = allUniquieTags.categories.find(ctg => ctg.name === 'Hubs');
                        if (hubtags && hubtags.tags) {
                            hubsFilter = hubtags.tags.map(tag => ({ text: tag, value: ['tags_hub', 'contains', tag] }));
                            hubsFilter.push({
                                text: 'Blank',
                                value: ['tags_hub', '=', null]
                            });
                        }
                        const frameworktags = allUniquieTags.categories.find(ctg => ctg.name === 'Frameworks');
                        if (frameworktags && frameworktags.tags) {
                            frameworkFilter = frameworktags.tags.map(tag => ({ text: tag, value: ['tags_framework', 'contains', tag] }));
                            frameworkFilter.push({
                                text: 'Blank',
                                value: ['tags_framework', '=', null]
                            });
                        }
                        const lottags = allUniquieTags.categories.find(ctg => ctg.name === 'Lots');
                        if (lottags && lottags.tags) {
                            lotFilter = lottags.tags.map(tag => ({ text: tag, value: ['tags_lot', 'contains', tag] }));
                            lotFilter.push({
                                text: 'Blank',
                                value: ['tags_lot', '=', null]
                            });
                        }
                        const othercontracttags = allUniquieTags.categories.find(ctg => ctg.name === 'ContractOther');
                        if (othercontracttags && othercontracttags.tags) {
                            otherTagFilter = othercontracttags.tags.map(tag => ({ text: tag, value: ['tags_other', 'contains', tag] }));
                            otherTagFilter.push({
                                text: 'Blank',
                                value: ['tags_other', '=', null]
                            });
                        }
                    }

                }

            } catch (error) {
                console.log('Error parsing contracts filters', error);
                areasfilter = null;
            }

            let contracts = response.data.aggregatelist;
            if (contracts) {
                contracts = contracts.map(contract => {
                    if (contract.tags && contract.tags.categories) {
                        const contractAreas = contract.tags.categories.find(ctg => ctg.name === 'Areas');
                        if (contractAreas && contractAreas.tags) {
                            contract.tags_area = contractAreas.tags.map(tag => tag.tagname);
                        }
                        const contractHubs = contract.tags.categories.find(ctg => ctg.name === 'Hubs');
                        if (contractHubs && contractHubs.tags) {
                            contract.tags_hub = contractHubs.tags.map(tag => tag.tagname);
                        }
                        const contractFrameworks = contract.tags.categories.find(ctg => ctg.name === 'Frameworks');
                        if (contractFrameworks && contractFrameworks.tags) {
                            contract.tags_framework = contractFrameworks.tags.map(tag => tag.tagname);
                        }
                        const contractLots = contract.tags.categories.find(ctg => ctg.name === 'Lots');
                        if (contractLots && contractLots.tags) {
                            contract.tags_lot = contractLots.tags.map(tag => tag.tagname);
                        }

                        const contractOtherTags = contract.tags.categories.find(ctg => ctg.name === 'ContractOther');
                        if (contractOtherTags && contractOtherTags.tags) {
                            contract.tags_other = contractOtherTags.tags.map(tag => tag.tagname);
                        }
                    }
                    return contract;
                })
            }

            yield put(actions.setContracts(contracts, areasfilter, hubsFilter, frameworkFilter, lotFilter, otherTagFilter));
        }
        catch { }

    });
    // yield takeLatest(preferencesDuck.actionTypes.PREFERENCES_SET_CONTRACTS_FILTERS, function* preferencesUpdateActiveFiltersSaga() {
    //     //IN CASE FILTERS CHANGED ON FLY(WHILE APPLIED)
    //     // REMOVE ANY APPLIED ACTIVE FITERS
    //     try {
    //         const preferencesStore = yield select(getPreferences);
    //         const contractsStore = yield select(getContracts);
    //         if (preferencesStore.contractsPage.filters && contractsStore.activeFilters) {
    //             const activeFilterCopy = contractsStore.activeFilters.map(itm => ({ property: itm.property, filters: [...itm.filters] }));
    //             const filtersPreference = preferencesStore.contractsPage.filters;
    //             const filtersDontShowList = activeFilterCopy.filter(active => filtersPreference.find(filter => filter.dataField === active.property && !filter.show));
    //             if (filtersDontShowList && filtersDontShowList.length > 0) {
    //                 filtersDontShowList.forEach(itm => activeFilterCopy.pop(itm));
    //                 yield put(actions.setActiveFilters(activeFilterCopy));
    //             }
    //         }
    //     }
    //     catch { }

    // });
}
