import React, { Component } from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { Badge } from "react-bootstrap";
import * as forecastCrud from '../../../../crud/forecast.crud';
import { notificationAggregateListTagsFilter, notificationUniqueTagsFilter } from "../../../../store/helpers/FilterHelper";
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

class ForecastListPage extends Component {
    constructor(props) {
        super(props);
        //this.props.fetchForecast(this.props.notificationType, true);

        this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.notificationsRelsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';
        this.customFields = [];
        if (props.customFields) {
            if (props.customFields.COMMUNICATION && props.customFields.COMMUNICATION.length > 0) {
                this.customFields.push(...(props.customFields.COMMUNICATION.filter(cf => cf.includelisting)));
            }
            if (props.customFields.REPLY && props.customFields.REPLY.length > 0) {
                this.customFields.push(...(props.customFields.REPLY.filter(cf => cf.includelisting)));
            }
        }
        this.forecastEnabled = process.env.REACT_APP_FORECAST_ENABLED === 'true';

        this.state = {
            forecasts: null,
            tags: null
        };
    }
    componentDidMount() {
        this.fetchForecasts();
    }
    fetchForecasts = () => {
        const { companyid, projectid, contractid } = this.props.contract;
        forecastCrud.getForecasts(companyid, projectid, contractid, this.props.notificationType, this.props.accessibleWfIds)
            .then(response => {
                let listData = notificationAggregateListTagsFilter(JSON.parse(response.data.aggregatelist));
                listData = (this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed === true) ?
                    listData :
                    listData.filter(ntf => ntf.showtasknotification === 1);

                let tagFilters = notificationUniqueTagsFilter(JSON.parse(response.data.uniquetags));

                this.setState({ forecasts: listData, tags: tagFilters });
            })
            .catch(err => {
                this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
            });
    }

    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        return  (skipHostUrl ? '' : process.env.PUBLIC_URL) +this.props.workflowItem.paths.basePath + rowData.forecastid;
    }
    renderStatusColumn = (cellInfo) => {
        let badgeVariant = cellInfo.data.showtasknotification === 1 ? 'success' : 'secondary';
        const displayText = this.calculateStatusValue(cellInfo.data);
        return (<Badge variant={badgeVariant}>{displayText}</Badge>);
    }
    calculateStatusValue = (rowData) => {
        return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + rowData.status });
    }
    calculateCarbonValue = (cellInfo) => {
        return cellInfo.data.newcarbonforecast + ' ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_TONNES' });
    }
    render() {
        if (!this.state.forecasts) {
            return (<PortletSpinner />);
        }

        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.SUBJECT", dataField: "subject" },
            { resource: "GENERAL.FORMS.FIELD.DETAILS", dataField: "description", isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.COMMUNICATED_BY", dataField: "createby" }
        ];

        if (this.props.notificationType === 'CostForecast') {
            columns.push({ resource: "GENERAL.FORMS.FIELD.COSTFORECAST", dataField: "newcostforecast", dataType: 'currency', currencyCode: this.props.contract.currency })
        } else {
            columns.push({ resource: "GENERAL.FORMS.FIELD.CARBONFORECAST", dataField: "newcarbonforecast", cellRender: this.calculateCarbonValue })
        }

        columns.push(
            //{ resource: "GENERAL.FORMS.FIELD.SUBTYPE", dataField: "replycomment" },
            { resource: "GENERAL.FORMS.FIELD.FORECASTDATE", dataField: "forecastdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_DATE", dataField: "notificationdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "status", cellRender: this.renderStatusColumn, calculateCellValue: this.calculateStatusValue },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false }
        );

        if (this.props.typeFieldEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.SUBTYPE", dataField: "replycomment" }
            )

        }
        if (this.props.workflowItem.assetFieldsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.ASSET_NAME", dataField: "assetname", visible: false },
                { resource: "GENERAL.FORMS.FIELD.ASSET_TYPE", dataField: "assettype", visible: false },
            );
        }
        if (this.notificationsTagsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.PortfolioFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProgrammeFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProjectFilter } },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.OtherFilter } }
            );
        };

        if (this.notificationsRelsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.TAB_RELATIONS", dataField: "relatednotices", visible: false },
            )
        }
        columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(this.customFields));


        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="1"
                        title={this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.TITLE2' }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                        createPath={this.props.workflowItem.userFilters.mainNotice.createAllowed ? this.props.workflowItem.paths.createPath : null}
                        workflowItem={this.props.workflowItem}
                        name={this.props.workflowItem.pageTitle}
                        dataSource={this.state.forecasts}
                        keyExpr="forecastid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}


export default withListPageProps(ForecastListPage);
