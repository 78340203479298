import React from 'react';
import { connect } from "react-redux";
import * as fileCrud from '../../../../crud/file.crud';
import Document from '../../_CustomComponents/DocumentV2';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { Alert } from "react-bootstrap";
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { withSnackbar } from 'notistack';

// const replyOptionsCE = [
//     { value: 1, display: 'Accepted' },
//     { value: 0, display: 'Not Accepted - Consultant to Resubmit' },
//     { value: 3, display: 'Not Accepted - SM to make own Assessment' },
// ];
// const replyOptionsINS = [
//     { value: 1, display: 'Accepted' },
//     { value: 0, display: 'Not Accepted - Consultant to Resubmit' },
//     { value: 3, display: 'Not Accepted - SM to make own Assessment' },
//     { value: 5, display: 'Not Accepted - Decision will not be given' },
// ];

// const proceedOptions = [
//     { value: '61.1', display: '61.1 Consultant is instructed to proceed with works' },
//     { value: '61.2', display: '61.2 Consultant is instructed to NOT proceed with works until instructed' },
// ];
// const replyOptionsDefault = [1, 0, 3];
// const replyOptionsCLAIMS = [1, 0, 3, 5];
// const replyOptionsCWN = [8, 5];

class ReplyDocument extends BaseDocument {
    constructor(props) {
        super(props);

        // #region QUOTE REPLY OPTIONS
        let replyOptionsPlaceholderName = "";
        //PROPOSED INSTRUCTION
        if (props.notificationType === '612PM') {
            replyOptionsPlaceholderName = "InstructionReplyOpts";
        }
        //SUPPLIER CLAIMS WITH QUOTATION
        else if (props.mode === 'CLAIM') {
            replyOptionsPlaceholderName = "SupplierCWQReplyOpts";
        }
        //SUPPLIER COMPENSATION EVENTS SHORT TERM
        else if (props.mode === 'SHORT') {
            replyOptionsPlaceholderName = "SupplierShortCENReplyOpts";
        }
        //SUPPLIER/CLIENT CLAIMS WITH NOTICE 
        else if (props.mode === 'CLAIMWN' || props.mode === 'CLIENTCWN') {
            if (props.notification.continuingeffect === 1) {
                replyOptionsPlaceholderName = "InterimQuoteReplyOpts";
            }
            else {
                replyOptionsPlaceholderName = "CWNQuoteReplyOpts";
                // if (props.placeholdersQA.SupplierCWNReplyOpts) {
                //     //SUPPORT HISTORIC
                //     replyOptionsPlaceholderName = "SupplierCWNReplyOpts";
                // }
            }
        }
        //PM COMPENSATION EVENTS
        else if (props.mode === 'PM') {
            replyOptionsPlaceholderName = "PMCENReplyOpts";
        }
        //SUPPLIER COMPENSATION EVENTS
        else if (props.mode === 'SUPPLIER') {
            replyOptionsPlaceholderName = "SupplierCENReplyOpts";
        }
        else if (props.mode === 'CLIENT') {
            replyOptionsPlaceholderName = "ClientQuoteReplyOpts";
        }

        if (!replyOptionsPlaceholderName) {
            this.props.enqueueSnackbar("UNKNOWN REPLY OPTIONS", { variant: "error" });
        }
        if (!props.placeholdersQA[replyOptionsPlaceholderName]) {
            this.props.enqueueSnackbar(`Placeholder missing: ${replyOptionsPlaceholderName}`, { variant: "error" });
        }
        const replyOptionIDsSelected = props.placeholdersQA[replyOptionsPlaceholderName].values
            .filter(item => item.isActive === true)
            .map(item => Number(item.field));
        //#endregion



        this.quoteStatuses = props.quoteStatuses.map(x => ({ value: +x.field, display: x.display }))
            .filter(y => replyOptionIDsSelected.includes(y.value))
            .sort((a, b) => a.display.localeCompare(b.display));

        this.replyFormConfig = {
            currencyCode: this.props.contract.currency,
            fields: [
                { resource: "CONTRACT.WF.QUOTES.FIELD.AMOUNT", editorType: 'dxNumberBox', dataField: 'amount', value: () => props.notification.amount },
                { label: this.props.resources.QuoteDaysEffectLabel, dataField: 'days', editorType: 'dxNumberBox', editorOptions: { format: '#,##0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' }), placeholder: '0 ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_DAYS' }) }, value: () => props.notification.days },
                { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: 'responsestatus', editorType: 'dxSelectBox', editorOptions: { dataSource: this.quoteStatuses, valueExpr: 'value', displayExpr: 'display' } },
                { resource: "GENERAL.FORMS.FIELD.COMMENTS", dataField: 'responsecomment', editorType: 'dxHtmlEditor' },
            ]
        };
        if (props.notificationType === '612PM') {
            this.proceedOptions = null;
            const proceedOptsPH = props.placeholders.find(ph => ph.replacementKey === 'InstructionReplyProceedOpts');
            if (proceedOptsPH) {
                this.proceedOptions = proceedOptsPH.values.filter(item => item.isActive === true);
            }

            if (props.instructionReplyProceedEnabled && this.proceedOptions) {
                this.replyFormConfig.fields.splice(3, 0, { resource: "CONTRACT.WF.QUOTES.FIELD.PROCEED", dataField: 'proceed', editorType: 'dxSelectBox', editorOptions: { dataSource: this.proceedOptions, valueExpr: 'field', displayExpr: 'display' }, visible: (obj) => obj.responsestatus !== 5 });
            }
        }

    }




    renderForm = () => {
        return (
            <>
                {
                    this.props.notificationType === '612PM' && this.props.resources.InstructionQuoteReplyWarningBanner && (
                        <Alert variant="warning" >
                            <p>
                                {this.props.resources.InstructionQuoteReplyWarningBanner}
                            </p>
                        </Alert>
                    )
                }
                {
                    this.props.notificationType !== '612PM' && this.props.resources.QuoteReplyWarningBanner && (
                        <Alert variant="warning" >
                            <p>
                                {this.props.resources.QuoteReplyWarningBanner}
                            </p>
                        </Alert>
                    )
                }
                <BiDynamicForm
                    isReply={true}
                    item={this.props.reply}
                    config={this.replyFormConfig}
                    valueChangeHandler={this.props.fieldChanged}
                    customFields={(this.props.customFieldsCommon || {}).QUOTE_REPLY}
                    secondaryPriceSectionEnabled={true}
                    secondaryDateSectionEnabled={true}
                />
            </>
        );
    }
    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType + 'Reply', this.props.notification.quotationid, data, config)
    }
    render() {
        const documentIsFinal = !this.props.isAwaitingReply;
        const showNote = !documentIsFinal && this.props.notification.draftreplydate;
        if (documentIsFinal && !this.props.notification.pdfreplyjsoncontent) {
            //QUOTE REPLY IS ASSIGNED BECAUSE OTHER QUOTE ACCEPTED OR REJECTED DEFINITLY
            //THERE IS NO ACTUAL RESPONSE CONTENT TO SHOW
            return (
                <Portlet>
                    <PortletBody style={{ maxWidth: 800 }}>
                        <Alert variant="warning">
                            <p>
                                <FormattedMessage id="GENERAL.NO_DOC_CONTENT" />
                            </p>
                        </Alert>
                    </PortletBody>
                </Portlet>
            );
        }
        return (
            <>
                {
                    showNote && (
                        <Alert variant="secondary">
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFTED" values={{ PERSON_NAME: this.props.notification.draftreplyby, TIME_STAMP: moment(this.props.notification.draftreplydate).format('L') }} />
                                <br />
                                {
                                    this.props.notification.draftreplyto && (
                                        <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT_NOTIFIED" values={{ PERSON_NAME: this.props.notification.draftreplyto }} />
                                    )
                                }
                            </p>
                        </Alert>
                    )
                }
                <Document
                    key={documentIsFinal}
                    docConfig={this.props.notification.pdfreplyjsoncontent}
                    isFinal={documentIsFinal}
                    printDivRef={this.props.printDivRef}
                    workflowItem={this.props.workflowItem}
                    contract={this.props.contract}
                    reference={this.props.notification.referenceid}
                    form={documentIsFinal ? null : this.renderForm()}
                    uploadMethod={documentIsFinal ? null : this.uploadFile}
                    files={this.props.files}
                    notificationid={this.props.notificationid}
                    documentType={this.props.documentType}
                    isReply={true}
                    customFields={(this.props.customFieldsCommon || {}).QUOTE_REPLY}
                    customFieldValues={this.props.notification.customfieldvalues}
                    assetid={this.props.compensation.assetid}
                />
            </>
        );

    }
}

const mapStateToProps = store => ({
    // globalResources: store.contract.template.resources,
    // contract: store.contract.contract,
});

export default withSnackbar(injectIntl(connect(mapStateToProps)(ReplyDocument)));
