/* eslint-disable import/no-anonymous-default-export */
import GRP101 from "./DefaultWorkflowGroups/101/GroupItem";
import GRP102 from "./DefaultWorkflowGroups/102/GroupItem";
import GRP103 from "./DefaultWorkflowGroups/103/GroupItem";
import GRP104 from "./DefaultWorkflowGroups/104/GroupItem";
import GRP105 from "./DefaultWorkflowGroups/105/GroupItem";
import GRP106 from "./DefaultWorkflowGroups/106/GroupItem";
import GRP107 from "./DefaultWorkflowGroups/107/GroupItem";
import GRP108 from "./DefaultWorkflowGroups/108/GroupItem";
import GRP109 from "./DefaultWorkflowGroups/109/GroupItem";
import GRP110 from "./DefaultWorkflowGroups/110/GroupItem";
import GRP111 from "./DefaultWorkflowGroups/111/GroupItem";
import GRP112 from "./DefaultWorkflowGroups/112/GroupItem";
import GRP113 from "./DefaultWorkflowGroups/113/GroupItem";
import GRP114 from "./DefaultWorkflowGroups/114/GroupItem";
import GRP115 from "./DefaultWorkflowGroups/115/GroupItem";
import GRP116 from "./DefaultWorkflowGroups/116/GroupItem";
import GRP117 from "./DefaultWorkflowGroups/117/GroupItem";
import GRP118 from "./DefaultWorkflowGroups/118/GroupItem";
import GRP119 from "./DefaultWorkflowGroups/119/GroupItem";

export default [
    GRP101,
    GRP102,
    GRP103,
    GRP104,
    GRP105,
    GRP106,
    GRP107,
    GRP108,
    GRP109,
    GRP110,
    GRP111,
    GRP112,
    GRP113,
    GRP114,
    GRP115,
    GRP116,
    GRP117,
    GRP118,
    GRP119
];