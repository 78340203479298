import axios from "../shared/axios";

export function getDocumentsList(companyid, projectid, contractid) {
  return axios.post(
    `/api/Document/LoadDocumentsList?companyid=${companyid}&projectid=${projectid}&contractid=${contractid}&latestrevision=true`
  );
}

export function saveDocument(data) {
  return axios.post(`/api/Document/SaveDocument`, data);
}

export function getDocument(reference) {
  return axios.get(`/api/Document/GetDocument?programid=${reference}`);
}
