/* eslint-disable import/no-anonymous-default-export */
import Workflows from "./Workflows";
import Placeholders from "./Placeholders";

export default {
    id: 119,
    title: 'Assessments',
    path: 'assessments',
    icon: "flaticon2-plus",
    workflows: Workflows,
    placeholders: Placeholders,
};