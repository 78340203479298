import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotifyList from '../../_SharedWorkflow/Notify/List';
import NotifyCreate from '../../_SharedWorkflow/Notify/Create';
import NotifyDetail from '../../_SharedWorkflow/Notify/Detail';

class NotificationsRouter extends React.Component {
    constructor(props) {
        super(props);
        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;
        this.replyingParty = props.workflowItem.replyingParty;

        this.communicateToUser = props.globalResources[this.replyingParty + "_NAME"];
        this.clauses = props.workflowItem.clauses;
        if (props.mode === 'PM') {
            this.notificationType = 'PMN';
            this.documentType = 'DocumentPMN';
            if (!this.clauses) {
                this.clauses = props.placeholders.find(ph => ph.replacementKey === 'PmClauses')
                    .values.filter(item => item.isActive === true);
            }

        }
        else if (props.mode === 'SUPERVISOR') {
            this.notificationType = 'SN';
            this.documentType = 'DocumentSN';
            if (!this.clauses) {
                this.clauses = props.placeholders.find(ph => ph.replacementKey === 'SupervisorClauses')
                    .values.filter(item => item.isActive === true);
            }
        }
        else if (props.mode === 'SUPPLIER') {
            this.notificationType = 'CN';
            this.documentType = 'DocumentCN';
            if (!this.clauses) {
                this.clauses = props.placeholders.find(ph => ph.replacementKey === 'SupplierClauses')
                    .values.filter(item => item.isActive === true);
            }
        }
        else if (props.mode === 'CLIENT') {
            this.notificationType = 'ClientNotification';
            this.documentType = 'DocumentClientNotification';
            if (!this.clauses) {
                this.clauses = props.placeholders.find(ph => ph.replacementKey === 'ClientClauses')
                    .values.filter(item => item.isActive === true);
            }
        }
        else if (props.mode === 'PM_SUPERVISOR') {
            this.notificationType = 'PmToSupvNotification';
            this.documentType = 'DocumentPmToSupvNotification';
            if (!this.clauses) {
                this.clauses = props.placeholders.find(ph => ph.replacementKey === 'PmToSupervisorClauses')
                    .values.filter(item => item.isActive === true);
            }
        }
        else if (props.mode === 'PM_CLIENT') {
            this.notificationType = 'PmToClientNotification';
            this.documentType = 'DocumentPmToClientNotification';
            if (!this.clauses) {
                this.clauses = props.placeholders.find(ph => ph.replacementKey === 'PmToClientClauses')
                    .values.filter(item => item.isActive === true);
            }
        }
        else if (props.mode === 'SUPERVISOR_PM') {
            this.notificationType = 'SupvToPmNotification';
            this.documentType = 'DocumentSupvToPmNotification';
            if (!this.clauses) {
                this.clauses = props.placeholders.find(ph => ph.replacementKey === 'SupervisorToPmToClauses')
                    .values.filter(item => item.isActive === true);
            }
        }
        else if (props.mode === 'PM_PM') {
            this.notificationType = 'PmToPmNotification';
            this.documentType = 'DocumentPmToPMNotification';
            if (!this.clauses) {
                this.clauses = props.placeholders.find(ph => ph.replacementKey === 'PmToPmClauses')
                    .values.filter(item => item.isActive === true);
            }
        }
        else if (props.mode === 'CLIENT_CLIENT') {
            this.notificationType = 'ClientToClientNotification';
            this.documentType = 'DocumentClientToClientNotification';
            if (!this.clauses) {
                this.clauses = props.placeholders.find(ph => ph.replacementKey === 'ClientToClientClauses')
                    .values.filter(item => item.isActive === true);
            }
        }
        else if (props.mode === 'SUPPLIER_SUPPLIER') {
            this.notificationType = 'SupplierToSupplierNotification';
            this.documentType = 'DocumentSuppierToSupplierNotification';
            if (!this.clauses) {
                this.clauses = props.placeholders.find(ph => ph.replacementKey === 'SupplierToSupplierClauses')
                    .values.filter(item => item.isActive === true);
            }
        }
        else if (props.mode === 'SUPERVISOR_SUPERVISOR') {
            this.notificationType = 'SupvToSupvNotification';
            this.documentType = 'DocumentSupvToSupvNotification';
            if (!this.clauses) {
                this.clauses = props.placeholders.find(ph => ph.replacementKey === 'SupervisorToSupervisorClauses')
                    .values.filter(item => item.isActive === true);
            }
        }
        else if (props.mode === 'SUPPLIER_SUPERVISOR') {
            this.notificationType = props.workflowItem.config.currentModelType;
            this.documentType = 'Document'+props.workflowItem.config.currentModelType;
        }
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;

        return (
            <>
                <Routes caseSensitive>
                    <Route
                        path={workflowItem.config.paths.list}
                        exact
                        element={<NotifyList
                            {...this.props}
                            title={this.notificationName}
                            abbreviation={this.abbreviation}
                            notificationType={this.notificationType}
                        />}

                    />
                    <Route
                        path={workflowItem.config.paths.create}
                        exact
                        element={
                            workflowItem.userFilters.mainNotice.createAllowed ? (
                                <NotifyCreate
                                    {...this.props}
                                    title={this.notificationName}
                                    abbreviation={this.abbreviation}
                                    clauses={this.clauses}
                                    communicateToUser={this.communicateToUser}
                                    notificationType={this.notificationType}
                                />) : (
                                <Navigate replace to={workflowItem.paths.defaultPath} />
                            )
                        }
                    />
                    <Route
                        path={'/:notificationid/*'}
                        exact
                        element={<NotifyDetail
                            {...this.props}
                            title={this.notificationName}
                            abbreviation={this.abbreviation}
                            clauses={this.clauses}
                            notificationType={this.notificationType}
                            documentType={this.documentType}
                            communicateToUser={this.communicateToUser}
                            notifyUserFilter={workflowItem.notifyUserFilter}
                        />}
                    />
                </Routes>
            </>
        );
    }
}



export default (NotificationsRouter);