import {placeholderTypes} from "../../Types/Placeholder";
const placeholders = [
    {
        replacementKey: 'Resources',
        values: [
            { id: 1, isActive: true, field: 'PmCertificateText', display: 'In accordance with clause 30.2, I hereby certify that Completion was achieved on the date mentioned below.' },
            { id: 2, isActive: true, field: 'SupplierCertificateText', display: 'In accordance with clause 30.2, I hereby certify that Completion was achieved on the date mentioned below.' },
            { id: 3, isActive: true, field: 'ClientCertificateText', display: 'In accordance with clause 30.2, I hereby certify that Completion was achieved on the date mentioned below.' },
            { id: 4, isActive: true, field: 'SupervisorCertificateText', display: 'In accordance with clause 30.2, I hereby certify that Completion was achieved on the date mentioned below.' },
        ],
        type:placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'ActionReminders', values:
            [
                { id: 1, isActive: true, field: 'PM', display: '$PM_NAME Certificates awaiting reply' },
                { id: 2, isActive: true, field: 'Supplier', display: '$SUPPLIER_NAME Certificates awaiting reply' },
                { id: 3, isActive: true, field: 'Client', display: '$CLIENT_NAME Certificates awaiting reply' },
                { id: 4, isActive: true, field: 'Supervisor', display: '$SUPERVISOR_NAME Certificates awaiting reply' }
            ],
        type:placeholderTypes.ACTION_REMINDERS
    },
    {
        replacementKey: 'CompletionFields', values:
            [
                { id: 1, isActive: true, field: 'CertifiedDate', display: 'Completion Date' },
                { id: 2, isActive: true, field: 'Description', display: 'Details' },
                { id: 3, isActive: true, field: 'Works', display: 'Outstanding Works' },
                { id: 4, isActive: true, field: 'Defects', display: 'Defects' }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'SectionalCompletionFields', values:
            [
                { id: 1, isActive: true, field: 'CertifiedDate', display: 'Sectional Completion Date' },
                { id: 2, isActive: true, field: 'Description', display: 'Details' },
                { id: 3, isActive: true, field: 'Works', display: 'Outstanding Works' },
                { id: 4, isActive: true, field: 'Defects', display: 'Defects' }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'TakingOverFields', values:
            [
                { id: 1, isActive: true, field: 'CertifiedDate', display: 'Taking Over Date' },
                { id: 2, isActive: true, field: 'Description', display: 'Details' },
                { id: 3, isActive: true, field: 'Works', display: 'Outstanding Works' },
                { id: 4, isActive: true, field: 'Defects', display: 'Defects' }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'DefectsFields', values:
            [
                { id: 1, isActive: true, field: 'CertifiedDate', display: 'Certified Date' },
                { id: 2, isActive: true, field: 'Description', display: 'Details' },
                { id: 3, isActive: false, field: 'Works', display: 'Outstanding Works' },
                { id: 4, isActive: true, field: 'Defects', display: 'Outstanding Defects' }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'TerminationFields', values:
            [
                { id: 1, isActive: true, field: 'CertifiedDate', display: 'Certified Date' },
                { id: 2, isActive: true, field: 'Description', display: 'Details' },
                { id: 3, isActive: false, field: 'Works', display: 'Outstanding Works' },
                { id: 4, isActive: false, field: 'Defects', display: 'Outstanding Defects' }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'OtherFields', values:
            [
                { id: 1, isActive: true, field: 'CertifiedDate', display: 'Certified Date' },
                { id: 2, isActive: true, field: 'Description', display: 'Details' },
                { id: 3, isActive: false, field: 'Works', display: 'Outstanding Works' },
                { id: 4, isActive: false, field: 'Defects', display: 'Outstanding Defects' }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'TaskOrderStartFields', values:
            [
                { id: 1, isActive: true, field: 'CertifiedDate', display: 'Certified Date' },
                { id: 2, isActive: true, field: 'Description', display: 'Details' },
                { id: 3, isActive: false, field: 'Works', display: 'Outstanding Works' },
                { id: 4, isActive: false, field: 'Defects', display: 'Outstanding Defects' }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'TaskOrderCompletionFields', values:
            [
                { id: 1, isActive: true, field: 'CertifiedDate', display: 'Certified Date' },
                { id: 2, isActive: true, field: 'Description', display: 'Details' },
                { id: 3, isActive: false, field: 'Works', display: 'Outstanding Works' },
                { id: 4, isActive: false, field: 'Defects', display: 'Outstanding Defects' }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'ProjectOrderStartFields', values:
            [
                { id: 1, isActive: true, field: 'CertifiedDate', display: 'Certified Date' },
                { id: 2, isActive: true, field: 'Description', display: 'Details' },
                { id: 3, isActive: false, field: 'Works', display: 'Outstanding Works' },
                { id: 4, isActive: false, field: 'Defects', display: 'Outstanding Defects' }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'ProjectOrderCompletionFields', values:
            [
                { id: 1, isActive: true, field: 'CertifiedDate', display: 'Certified Date' },
                { id: 2, isActive: true, field: 'Description', display: 'Details' },
                { id: 3, isActive: false, field: 'Works', display: 'Outstanding Works' },
                { id: 4, isActive: false, field: 'Defects', display: 'Outstanding Defects' }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'ReplyOptions', values:
            [
                { id: 1, isActive: true, field: 'Accepted', display: 'Accepted' },
                { id: 2, isActive: true, field: 'Not Accepted', display: 'Not Accepted' },
            ],
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'PMCertTypes', values:
            [
                { id: 1, isActive: true, field: '', display: 'Completion Certificate', effect:'COMPLETION' },
                { id: 2, isActive: false, field: '', display: 'Sectional Completion Certificate', effect:'SECTIONAL_COMPLETION' },
                { id: 3, isActive: false, field: '', display: 'Taking Over Certificate', effect:'TAKING_OVER' },
                { id: 4, isActive: true, field: '', display: 'Defects Certificate', effect:'DEFECTS' },
                { id: 5, isActive: false, field: '', display: 'Termination Certificate', effect:'TERMINATION' },
                { id: 6, isActive: true, field: '', display: 'Other Certificate', effect:'OTHER' },
                { id: 7, isActive: false, field: '', display: 'Task Order Start', effect:'TASK_ORDER_START' },
                { id: 8, isActive: false, field: '', display: 'Task Order Completion', effect:'TASK_ORDER_COMPLETION' },
                { id: 9, isActive: false, field: '', display: 'Project Order Start', effect:'PROJECT_ORDER_START' },
                { id: 10, isActive: false, field: '', display: 'Project Order Completion', effect:'PROJECT_ORDER_COMPLETION' }
            ],
        effects: ["COMPLETION", "SECTIONAL_COMPLETION", "TAKING_OVER", "DEFECTS", "TERMINATION", "OTHER", "TASK_ORDER_START", "TASK_ORDER_COMPLETION", "PROJECT_ORDER_START", "PROJECT_ORDER_COMPLETION"],
        type:placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'SupplierCertTypes', values:
            [
                { id: 1, isActive: false, field: '', display: 'Completion Certificate', effect:'COMPLETION' },
                { id: 2, isActive: false, field: '', display: 'Sectional Completion Certificate', effect:'SECTIONAL_COMPLETION' },
                { id: 3, isActive: false, field: '', display: 'Taking Over Certificate', effect:'TAKING_OVER' },
                { id: 4, isActive: false, field: '', display: 'Defects Certificate', effect:'DEFECTS' },
                { id: 5, isActive: false, field: '', display: 'Termination Certificate', effect:'TERMINATION' },
                { id: 6, isActive: true, field: '', display: 'Other Certificate', effect:'OTHER' },
                { id: 7, isActive: false, field: '', display: 'Task Order Start', effect:'TASK_ORDER_START' },
                { id: 8, isActive: false, field: '', display: 'Task Order Completion', effect:'TASK_ORDER_COMPLETION' },
                { id: 9, isActive: false, field: '', display: 'Project Order Start', effect:'PROJECT_ORDER_START' },
                { id: 10, isActive: false, field: '', display: 'Project Order Completion', effect:'PROJECT_ORDER_COMPLETION' }
            ],
        effects: ["COMPLETION", "SECTIONAL_COMPLETION", "TAKING_OVER", "DEFECTS", "TERMINATION", "OTHER", "TASK_ORDER_START", "TASK_ORDER_COMPLETION", "PROJECT_ORDER_START", "PROJECT_ORDER_COMPLETION"],
        type:placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'ClientCertTypes', values:
            [
                { id: 1, isActive: false, field: '', display: 'Completion Certificate', effect:'COMPLETION' },
                { id: 2, isActive: false, field: '', display: 'Sectional Completion Certificate', effect:'SECTIONAL_COMPLETION' },
                { id: 3, isActive: false, field: '', display: 'Taking Over Certificate', effect:'TAKING_OVER' },
                { id: 4, isActive: false, field: '', display: 'Defects Certificate', effect:'DEFECTS' },
                { id: 5, isActive: true, field: '', display: 'Termination Certificate', effect:'TERMINATION' },
                { id: 6, isActive: true, field: '', display: 'Other Certificate', effect:'OTHER' },
                { id: 7, isActive: false, field: '', display: 'Task Order Start', effect:'TASK_ORDER_START' },
                { id: 8, isActive: false, field: '', display: 'Task Order Completion', effect:'TASK_ORDER_COMPLETION' },
                { id: 9, isActive: false, field: '', display: 'Project Order Start', effect:'PROJECT_ORDER_START' },
                { id: 10, isActive: false, field: '', display: 'Project Order Completion', effect:'PROJECT_ORDER_COMPLETION' }
            ],
        effects: ["COMPLETION", "SECTIONAL_COMPLETION", "TAKING_OVER", "DEFECTS", "TERMINATION", "OTHER", "TASK_ORDER_START", "TASK_ORDER_COMPLETION", "PROJECT_ORDER_START", "PROJECT_ORDER_COMPLETION"],
        type:placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'SupervisorCertTypes', values:
            [
                { id: 1, isActive: false, field: '', display: 'Completion Certificate', effect:'COMPLETION' },
                { id: 2, isActive: false, field: '', display: 'Sectional Completion Certificate', effect:'SECTIONAL_COMPLETION' },
                { id: 3, isActive: false, field: '', display: 'Taking Over Certificate', effect:'TAKING_OVER' },
                { id: 4, isActive: true, field: '', display: 'Defects Certificate', effect:'DEFECTS' },
                { id: 5, isActive: false, field: '', display: 'Termination Certificate', effect:'TERMINATION' },
                { id: 6, isActive: false, field: '', display: 'Other Certificate', effect:'OTHER' },
                { id: 7, isActive: false, field: '', display: 'Task Order Start', effect:'TASK_ORDER_START' },
                { id: 8, isActive: false, field: '', display: 'Task Order Completion', effect:'TASK_ORDER_COMPLETION' },
                { id: 9, isActive: false, field: '', display: 'Project Order Start', effect:'PROJECT_ORDER_START' },
                { id: 10, isActive: false, field: '', display: 'Project Order Completion', effect:'PROJECT_ORDER_COMPLETION' }
            ],
        effects: ["COMPLETION", "SECTIONAL_COMPLETION", "TAKING_OVER", "DEFECTS", "TERMINATION", "OTHER", "TASK_ORDER_START", "TASK_ORDER_COMPLETION", "PROJECT_ORDER_START", "PROJECT_ORDER_COMPLETION"],
        type:placeholderTypes.CLAUSES
    },
    {
        replacementKey: 'CompletionChangesStateEnabled', values:
            [
                { id: 1, isActive: false, field: 'CompletionChangesStateEnabled', display: 'Indicate whether communication of a Completion Certificate should change the contract state' }
            ],
        isToggle: true,
        fieldRequired: true,
        type:placeholderTypes.TOGGLE_SWITCH
    }
];

export default placeholders;