/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from '../shared/hoc/withRouter';
import { FormattedMessage } from "react-intl";
class ContractActions extends React.Component {
  getAction = (title, count) => {

    return (
      <div className="contractActions__item ">
        <div className="contractActions__info ">
          <span className="contractActions__party" >{title}</span>
        </div>
        <span className="contractActions__desc">{count}</span>
      </div>
    );
  }

  render() {
    const { contractTemplate, contractDashboard } = this.props;
    if (!contractTemplate || !contractDashboard) {
      return null;
    }
    return (
      <>
        <div className="contractActions">
          <div className="contractActions__header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h3 className="contractActions__title"><FormattedMessage id="CONTRACT.DASHBOARD.OUTSTANDING_ACTIONS" /></h3>
            <a onClick={() => { this.props.navigate(`/contract/${this.props.contractid}/reports/rag`); }} style={{ textDecoration: 'underline', fontSize: '1.1rem', paddingTop: '0.5rem' }}><FormattedMessage id="CONTRACT.DASHBOARD.VIEW_REPORT" /></a>
          </div>
          {
            contractTemplate.parties.supplierExist && (
              this.getAction(`${contractTemplate.resources.SUPPLIER_NAME}`, contractDashboard.warningCountSupplier)
            )
          }
          {
            contractTemplate.parties.pmExist && (
              this.getAction(`${contractTemplate.resources.PM_NAME}`, contractDashboard.warningCountPm)
            )
          }
          {
            contractTemplate.parties.supervisorExist && (
              this.getAction(`${contractTemplate.resources.SUPERVISOR_NAME}`, contractDashboard.warningCountSupervisor)
            )
          }
          {
            contractTemplate.parties.clientExist && (
              this.getAction(`${contractTemplate.resources.CLIENT_NAME}`, contractDashboard.warningCountClient)
            )
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = store => ({
  contractDashboard: store.contract.dashboard,
  contractTemplate: store.contract.template,
});

export default withRouter(connect(mapStateToProps)(ContractActions));
