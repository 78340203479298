import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import IssueList from './List';
import IssueCreate from './Create';
import IssueDetail from './Detail';

class IssueRouter extends React.Component {
    constructor(props) {
        super(props);
        if (props.mode === 'PM') {
            this.communicateToUser = props.globalResources.SUPPLIER_NAME;
            this.notificationType = 'SubmissionPMNC';
            this.documentType = 'DocumentSubmissionPMNC';

            this.notifyingParty = props.workflowItem.notifyingParty;
            this.replyingParty = props.workflowItem.replyingParty;




            // this.clauses = props.placeholders.find(ph => ph.replacementKey === 'NCProposalClausesPM').values.filter(item => item.isActive === true);
        }

        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;
        this.replyPeriod = props.workflowItem.replyPeriod;
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;

        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<IssueList
                        {...this.props}
                        
                        notificationType={this.notificationType}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                    />}

                />
                <Route
                    path={workflowItem.config.paths.create}
                    exact
                    element={
                        workflowItem.userFilters.mainNotice.createAllowed ? (
                            <IssueCreate
                                {...this.props}
                                replyPeriod={this.replyPeriod}
                                title={this.notificationName}
                                
                                communicateToUser={this.communicateToUser}
                                notificationType={this.notificationType}
                            />) : (
                            <Navigate replace to={workflowItem.paths.defaultPath} />
                        )
                    }
                />
                <Route
                    path={'/:notificationid/*'}
                    exact
                    element={<IssueDetail
                        {...this.props}
                        replyPeriod={this.replyPeriod}
                        title={this.notificationName}
                        abbreviation={this.abbreviation}
                        
                        notificationType={this.notificationType}
                        documentType={this.documentType}
                        communicateToUser={this.communicateToUser}
                        notifyUserFilter={workflowItem.notifyUserFilter}
                    />}
                />
            </Routes>
        );
    }
};



export default (IssueRouter);