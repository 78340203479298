const template = {
    name: "Default NEC3 ECS v2.5 01/01/2024",
    isSystemDefault: true,
    isGlobal: true,
    companies: [],
    resources: [
        {
            id: 1,
            type: "TEXT",
            key: "SUPPLIER_NAME",
            value: "Subcontractor"
        },
        {
            id: 2,
            type: "TEXT",
            key: "SUPPLIER_ABBREVIATION",
            value: "SCON"
        },
        {
            id: 3,
            type: "TEXT",
            key: "PM_NAME",
            value: "Contractor"
        },
        {
            id: 4,
            type: "TEXT",
            key: "PM_ABBREVIATION",
            value: "CON"
        },
        {
            id: 5,
            type: "TEXT",
            key: "SUPERVISOR_NAME",
            value: "Contractor (Quality)"
        },
        {
            id: 6,
            type: "TEXT",
            key: "SUPERVISOR_ABBREVIATION",
            value: "CSUP"
        },
        {
            id: 7,
            type: "TEXT",
            key: "CLIENT_NAME",
            value: "Contractor (Client)"
        },
        {
            id: 8,
            type: "NUMBER",
            key: "SUPPLIER_REPLY_PERIOD",
            value: 7
        },
        {
            id: 9,
            type: "NUMBER",
            key: "PM_REPLY_PERIOD",
            value: 7
        },
        {
            id: 10,
            type: "NUMBER",
            key: "QUOTE_DURATION",
            value: 7
        },
        {
            id: 13,
            type: "NUMBER",
            key: "REQUOTE_DURATION",
            value: 7
        },
        {
            id: 11,
            type: "NUMBER",
            key: "QUOTE_RESPONSE",
            value: 28
        },
        {
            id: 14,
            type: "NUMBER",
            key: "REQUOTE_RESPONSE",
            value: 28
        },
        {
            id: 12,
            type: "NUMBER",
            key: "VARIATION_RESPONSE",
            value: 14
        },
        {
            id: 15,
            type: "NUMBER",
            key: "PAYMENT_PERIOD",
            value: 28
        },
        {
            id: 16,
            type: "NUMBER",
            key: "PROGRAMME_REPLY_PERIOD",
            value: 14
        }
    ],
    workflowGroups: [
        {
            id: 101,
            title: "Early Warnings",
            workflows: [
                {
                    id: 1,
                    title: "$PM_NAME Early Warnings",
                    notificationName: "Early Warning",
                    abbreviation: "$PM_ABBREVIATION-Warning-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    actioningParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 2,
                    title: "$SUPPLIER_NAME Early Warnings",
                    notificationName: "Early Warning",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Warning-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    actioningParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 3,
                    title: "Risk Register",
                    notificationName: "Early Warning",
                    abbreviation: "Warning",
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "MeetingRequiredLabel",
                            display: "Is Risk Reduction Meeting required?"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "NotificationClause",
                            display: "You are given early warning of a matter which could"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "RiskItemsLabel",
                            display: "You are given early warning of a matter which could"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Pm",
                            display: "$PM_ABBREVIATION Early Warnings awaiting reply"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Early Warnings awaiting reply"
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypes",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "riskincreaseprice",
                            display: "Increase the total of the Prices"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "riskdelaycompletion",
                            display: "Delay Completion"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "riskdelaymilestone",
                            display: "Delay meeting a Key Date"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "riskimpairperformance",
                            display: "Impair the performance of the subcontract works in use"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "riskotherpartner",
                            display: "Affect the achievement of another Partner's objectives"
                        }
                    ]
                },
                {
                    replacementKey: "MeetingOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "0",
                            display: "Meeting Not Required"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Meeting Required - Next Scheduled Risk Reduction Meeting"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Meeting Required - you are instructed to attend a meeting as below"
                        }
                    ]
                },
                {
                    replacementKey: "RiskTypesSupplier",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "riskincreaseprice",
                            display: "Increase the total of the Prices"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "riskdelaycompletion",
                            display: "Delay Completion"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "riskdelaymilestone",
                            display: "Delay meeting a Key Date"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "riskimpairperformance",
                            display: "Impair the performance of the subcontract works in use"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "riskincreasecontractorcost",
                            display: "Increase the $SUPPLIER_NAME's total cost"
                        }
                    ]
                }
            ]
        },
        {
            id: 102,
            title: "Instructions",
            workflows: [
                {
                    id: 4,
                    title: "$PM_NAME Instruction",
                    notificationName: "Instruction",
                    abbreviation: "$PM_ABBREVIATION-Instruction-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "InstructionTypes",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "14.3 to change the Subcontract Works Information or a Key Date"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "17.1 to resolve an ambiguity or inconsistency"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "18.1 to change the Subcontract Works Information to remove something which is illegal or impossible"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "19.1 on how to deal with a prevention event"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "23.1 to submit particulars of design of an item of Equipment"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "24.2 to remove an employee"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "26.4 to submit proposed contract data for a subsubcontract (main Options C, D, E and F)"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "32.2 to submit a revised programme"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "34.1 to stop or not start work or to start or restart work"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "36.1 to submit a quotation for acceleration"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "44.2 to change the Works Information after accepting a proposal not to correct a Defect"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "62.1 to submit alternative quotations for a compensation event"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "73.1 on how to deal with an object of value of historical interest"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "82.1 to not replace or repair the works, Plant and Material following loss or damage"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "92.2 to leave Site, remove Equipment, Plant and Materials and assign the benefit of the subcontract to the Employer"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "X12.1(4) providing Partnering Information"
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "Other Instruction"
                        }
                    ]
                }
            ]
        },
        {
            id: 103,
            title: "Compensation Events",
            workflows: [
                {
                    id: 5,
                    title: "$PM_NAME Compensation Events",
                    notificationName: "Compensation Event",
                    abbreviation: "$PM_ABBREVIATION-Compensation-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 6,
                    title: "$PM_NAME Proposed Instructions",
                    notificationName: "Proposed Instruction",
                    abbreviation: "$PM_ABBREVIATION-Instruction-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 7,
                    title: "$SUPPLIER_NAME Compensation Events",
                    notificationName: "Compensation Event",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Compensation-",
                    notify: true,
                    replyEnabled: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "VARIATION_RESPONSE",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    quotingParty: "SUPPLIER",
                    quoteReplyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteMainNotice: {
                            createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        quoteReplyNotice: {
                            saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 8,
                    title: "$PM_NAME Assessments",
                    notificationName: "Assessment",
                    abbreviation: "$PM_ABBREVIATION-Assessment-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 9,
                    title: "$PM_NAME Extensions",
                    notificationName: "Extension",
                    abbreviation: "$PM_ABBREVIATION-Extension-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ORIGINAL_DATE",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 10,
                    title: "$SUPPLIER_NAME Extensions",
                    notificationName: "Extension",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Extension-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ORIGINAL_DATE",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 11,
                    title: "Master Quote Register",
                    userFilters: {
                        accessFilter: "null"
                    }
                },
                {
                    id: 12,
                    title: "Master CE Register",
                    userFilters: {
                        accessFilter: "null"
                    }
                },
                {
                    id: 13,
                    title: "Implemented CE Register",
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmAssumptionLabel",
                            display: "The quotation is to be based on the following assumptions"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmAssumptionNote",
                            display: "You are instructed to provide a quotation by $date"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierDescNote",
                            display: "You are notified of the following event as a compensation event"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "ReplyAcceptedCommentLabel",
                            display: "You are notified that the event is a compensation event"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "ReplyRejectedCommentLabel",
                            display: "Details"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ReplyAcceptedAssumptionLabel",
                            display: "The quotation is to be based on the following assumptions"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "QuoteNote",
                            display: "We submit our quotation for compensation event: $reference"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "ExtensionPMLabel",
                            display: "We require an extension to the time to reply as follows"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ExtensionSupplierLabel",
                            display: "We require an extension to the time to reply as follows"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "AssessmentDetailLabel",
                            display: "Details"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "ProposedInstructionNote",
                            display: "You are instructed to provide a quotation by $date"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "ProposedInstructionLabel",
                            display: "The quotation is to be based on the following assumptions"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "ReplyRejectedOptionsLabel",
                            display: "You are notified that the event is not a compensation event as it"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "QuoteNoteProposedInstruction",
                            display: "We submit our quotation for proposed compensation event $reference"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "PmCompensationAQ",
                            display: "Compensation Events notified by $PM_ABBREVIATION awaiting quotes"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PmCompensationARQ",
                            display: "Compensation Events notified by $PM_ABBREVIATION awaiting revised quotes"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "PmCompensationAPMRQ",
                            display: "Compensation Events notified by $PM_ABBREVIATION awaiting response to quotes"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "PmCompensationAPMA",
                            display: "Compensation Events notified by $PM_ABBREVIATION awaiting $PM_ABBREVIATION Assessment"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SupCompensationAQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting quotes"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "SupCompensationARQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting revised quotes"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "SupCompensationAPMR",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION response"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "SupCompensationAPMRQ",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting response to quotes"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "SupCompensationAPMA",
                            display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "PmProposedAQ",
                            display: "Proposed Compensation Events awaiting quotes"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "PmProposedARQ",
                            display: "Proposed Compensation Events awaiting revised quotes"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "PmProposedAPMRQ",
                            display: "Proposed Compensation Events awaiting response to quotes"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "PmProposedAPMA",
                            display: "Proposed Compensation Events awaiting $PM_ABBREVIATION Assessment"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "PmExtension",
                            display: "$PM_ABBREVIATION Quote Response Extensions awaiting Acceptance"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "SupExtension",
                            display: "$SUPPLIER_NAME Quote Extensions awaiting Acceptance"
                        }
                    ]
                },
                {
                    replacementKey: "CompensationClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "60.1(1) An instruction to change the Subcontract Works Information"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "60.1(2) Contractor does not provide access to the Site"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "60.1(3) Contractor does not provide something that he is to provide"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "60.1(4) An instruction to stop or not start work or to change a Key Date"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "60.1(5) Employer, Contractor or Others do not work within the stated constraints"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "60.1(6) Contractor does not reply to a communication"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "60.1(7) An instruction dealing with an object of interest or value"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "60.1(8) Change of a decision"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "60.1(9) Withholds an acceptance"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "60.1(10) An instruction to search for a Defect and no Defect is found"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "60.1(11) A test or inspection causes unnecessary delay"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "60.1(12) Subcontractor encounters physical conditions"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "60.1(13) A weather measurement is recorded"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "60.1(14) An event which is the Employer's or Contractor's risk"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "60.1(15) Contractor certifies Take Over before Completion"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "60.1(16) Contractor does not provide material for testing"
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "60.1(17) Notification of a correction"
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "60.1(18) A breach by the Contractor"
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "60.1(19) A prevention event"
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "60.4 A difference between the final total quantity of an item and the quantity in the Bill of Quantities which delays completion (main Options B and D)"
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "60.5 A difference between the final total quantity of an item and the quantity in the Bill of Quantities (main Options B and D)"
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "60.6 Correction of a mistake in the Bill of Quantities caused by a departure in the method of measurement"
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "",
                            display: "X2.1 A change in the law"
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "",
                            display: "X12.3(7) A change in the Partnering Information"
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "",
                            display: "X14.2 Delay in making advanced payment"
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "",
                            display: "X15.2 Correcting a Defect for which the Contractor is not liable"
                        },
                        {
                            id: 28,
                            isActive: true,
                            field: "",
                            display: "Y2.4 Suspension of performance"
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "faultpscp",
                            display: "Arises from a fault of the $SUPPLIER_NAME"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "notexpectnothappen",
                            display: "Has not happened and is not expected to happen"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "noteffectcostcompletion",
                            display: "Has no effect on Defined Cost, Completion or meeting a Key Date"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "statedcontract",
                            display: "Is not one of the compensation events stated in this subcontract"
                        }
                    ]
                },
                {
                    replacementKey: "ClaimClauses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "",
                            display: "60.1(6) Late reply to communication"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "60.1(1) Employer has given an instruction to change the Scope"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "60.1(3) Something not provided"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "60.1(5) Work by employer or others"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "60.1(7) Previous decision changed"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "60.1(2) Access not provided by date stated within the contract"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "60.1(4) Stop or change key date"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "60.1(9) PM corrects an assumption related to a previous compensation event"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "60.1(10) Breach of Contract"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "60.1(8) Acceptance Withheld"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "60.1(11) Prevention event"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "60.1(12) Defect corrected which was not the Consultant’s liability"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "60.1(13): Instruction to change Task Order issued by Employer"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "60.1(14): Consultant receives late Task Order"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "60.1(15): a Task Completion Date is later than the Completion Date"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "X2.1: Change in law"
                        }
                    ]
                },
                {
                    replacementKey: "CompensationStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "APMA",
                            display: "Quote Not Accepted - $PM_ABBREVIATION to make own Assessment"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ARQ",
                            display: "Quote Not Accepted - $SUPPLIER_NAME to resubmit"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "AM",
                            display: "$PM_ABBREVIATION Assessment Made"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "QA",
                            display: "Quote Accepted"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "APMRQ",
                            display: "Awaiting $PM_ABBREVIATION Response to quote"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "APMR",
                            display: "Awaiting $PM_ABBREVIATION Response to CEN"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "AQ",
                            display: "Awaiting Quote"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NC",
                            display: "Not Communicated"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "R",
                            display: "Not a Compensation Event"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "DNG",
                            display: "Decision Not Given"
                        }
                    ]
                },
                {
                    replacementKey: "QuoteStatuses",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "0",
                            display: "Not Accepted - $SUPPLIER_NAME to resubmit"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "1",
                            display: "Accepted"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "2",
                            display: "Awaiting Response"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "3",
                            display: "Not Accepted - $PM_ABBREVIATION to make assessment"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "4",
                            display: "Not Accepted - Other quote approved"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "5",
                            display: "Not Accepted - Decision will not be given"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "none",
                            display: "Not Communicated"
                        }
                    ]
                },
                {
                    replacementKey: "InstructionReplyProceedOpts",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "61.1",
                            display: "$SUPPLIER_NAME is instructed to proceed with works"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "61.2",
                            display: "$SUPPLIER_NAME is instructed to NOT proceed with works until instructed"
                        }
                    ]
                }
            ]
        },
        {
            id: 104,
            title: "Programme",
            workflows: [
                {
                    id: 14,
                    title: "$SUPPLIER_NAME Programme",
                    notificationName: "Programme",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Programme-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "ReasonLabel",
                            display: "Reason for any change to Planned Completion"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "ChangeLabel",
                            display: "Change in any other Key Date, Completion Date for a section or other important project milestone in period and reasons why"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "MainProgressLabel",
                            display: "Main progress achieved in period"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "KeyActivitiesLabel",
                            display: "Key activities forecast in next four weeks"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SummaryLabel",
                            display: "Summary of critical path"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "ChangesLabel",
                            display: "Main changes made to programme in period, e.g. items deleted, new sections or activities added etc."
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "CompensationLabel",
                            display: "Compensation events added to programme this period"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "NotificationClause",
                            display: "Please find attached submission of Subcontractor's Programme for acceptance"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "ReplyRejectReasonLabel",
                            display: "The $SUPPLIER_NAME’s programme is not accepted for the following reasons"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "ReplyNotificationClause",
                            display: "You are notified that the $SUPPLIER_NAME’s Programme"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Programme Submission awaiting Acceptance"
                        }
                    ]
                },
                {
                    replacementKey: "ReplyRejectOptions",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "plansnotpractical",
                            display: "The $SUPPLIER_NAME’s plans which it shows are not practicable"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "missingcontractrequires",
                            display: "It does not show the information which this subcontract requires"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "notrepresentplan",
                            display: "It does not represent the $SUPPLIER_NAME’s plans realistically"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "notcomply",
                            display: "It does not comply with the Subcontract Works Information"
                        }
                    ]
                }
            ]
        },
        {
            id: 105,
            title: "Notifications",
            workflows: [
                {
                    id: 15,
                    title: "$PM_NAME Notifications",
                    notificationName: "Notification",
                    abbreviation: "$PM_ABBREVIATION-Notification-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 16,
                    title: "$SUPPLIER_NAME Notifications",
                    notificationName: "Notification",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Notification-",
                    notify: true,
                    replyEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "PmClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "13.2 of a change of address for communications"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "13.5 of an agreement to extend the period for reply for a communication (excluding compensation events periods)"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "14.2 of the cancellation of a delegation"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "17.1 of an ambiguity or inconsistency"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "25.2 of an assessment of the amount to be paid as the Subcontractor failed to provide services"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "25.3 of the amount assessed for a failure to meet a Key Date"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "27.2 to provide access to the work being done and Plant and Materials being stored"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "30.2 of certification of the date of Completion"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "35.3 of certification of the date of take over of part of the works"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "40.6 of an assessment of the amount to be paid for repeating a test or inspection"
                        },
                        {
                            id: 12,
                            isActive: true,
                            field: "",
                            display: "41.1 that Plant and Materials have passed a test or inspection"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "43.3 of the issue of the Defects Certificate"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "44.1 of a proposal to accept a Defect"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "45.1 of the amount to be paid for uncorrected Defects"
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "45.2 of the cost the Subontractor would incur in correcting a Defect"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "53.3 of the preliminary assessment of the Subcontractor's share (main Option C and D)"
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "53.4 of the final assessment of the Subcontractor's share (main Option C and D)"
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "61.5 that the Contractor has decided the Subcontractor did not give early warning of a compensation event"
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "61.6 that an assumption on which a compensation event quotation is based was wrong"
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "87.1 of the insurance certificates for insurances taken out by the Employer"
                        },
                        {
                            id: 22,
                            isActive: true,
                            field: "",
                            display: "90.1 of a wish to terminate"
                        },
                        {
                            id: 23,
                            isActive: true,
                            field: "",
                            display: "90.1 of the issue of a termination certificate"
                        },
                        {
                            id: 24,
                            isActive: true,
                            field: "",
                            display: "91.2 of a default/of the Contractor having not put right a default"
                        },
                        {
                            id: 25,
                            isActive: true,
                            field: "",
                            display: "91.3 of a default/of the Contractor having not put right a default"
                        },
                        {
                            id: 26,
                            isActive: true,
                            field: "",
                            display: "92.2 that Equipment is no longer required"
                        },
                        {
                            id: 27,
                            isActive: true,
                            field: "",
                            display: "X7.3 of the amount of reduction in delay damages following take over of part of the works"
                        },
                        {
                            id: 28,
                            isActive: true,
                            field: "",
                            display: "X18.5 of a matter for which the Subontractor is liable"
                        },
                        {
                            id: 29,
                            isActive: true,
                            field: "",
                            display: "Y1.5 of sums due to the Subcontractor and the Named Suppliers"
                        },
                        {
                            id: 30,
                            isActive: true,
                            field: "",
                            display: "Y2.3 of an intention to pay less than the notified sum"
                        },
                        {
                            id: 31,
                            isActive: true,
                            field: "",
                            display: "Other notification"
                        }
                    ]
                },
                {
                    replacementKey: "SupplierClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "13.2 of a change of address for communications"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "17.1 of an ambiguity or inconsistency"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "18.1 of an illegal or impossible requirement"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "20.3 of advice on the practical implications of the design and subsubcontracting arrangements (main Options C, D, E and F)"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "61.4 of a failure by the Contractor to respond to a compensation event notification"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "62.6 of a failure by the Contractor to respond to a quotation"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "64.4 of a failure by the Contractor to make an assessment of a compensation event"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "90.1 of a wish to terminate"
                        },
                        {
                            id: 10,
                            isActive: true,
                            field: "",
                            display: "Y2.3 of an intention to pay less than the notified sum"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "Other notification"
                        }
                    ]
                }
            ]
        },
        {
            id: 106,
            title: "Submissions",
            workflows: [
                {
                    id: 18,
                    title: "$SUPPLIER_NAME Submissions",
                    notificationName: "Submission",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Submission-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "IssueClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "15.1 a proposal to add an area to the Working Area"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "20.4 forecasts of the total Defined Cost for the whole of the works (main Options C, D, E and F)",
                            applyReplyPeriod: false,
                            noReply: true
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "",
                            display: "21.2 particulars of design"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "",
                            display: "23.1 particulars of design for an item of Equipment"
                        },
                        {
                            id: 6,
                            isActive: true,
                            field: "",
                            display: "24.1 details of a replacement person"
                        },
                        {
                            id: 7,
                            isActive: true,
                            field: "",
                            display: "26.2 the name of a proposed subsubcontractor"
                        },
                        {
                            id: 8,
                            isActive: true,
                            field: "",
                            display: "26.3 proposed subsubcontract documents"
                        },
                        {
                            id: 9,
                            isActive: true,
                            field: "",
                            display: "26.4 proposed contract data for each subsubcontract (main Options C, D E and F)"
                        },
                        {
                            id: 11,
                            isActive: true,
                            field: "",
                            display: "44.1 a proposal to accept a Defect"
                        },
                        {
                            id: 13,
                            isActive: true,
                            field: "",
                            display: "54.2 a revised activity schedule (main Option A and C)"
                        },
                        {
                            id: 14,
                            isActive: true,
                            field: "",
                            display: "85.1 certificates of insurance"
                        },
                        {
                            id: 15,
                            isActive: true,
                            field: "",
                            display: "X4.1 a parent company guarantee",
                            applyReplyPeriod: false,
                            noReply: true
                        },
                        {
                            id: 16,
                            isActive: true,
                            field: "",
                            display: "X13.1 a performance bond"
                        },
                        {
                            id: 17,
                            isActive: true,
                            field: "",
                            display: "X14.2 an advance payment bond"
                        },
                        {
                            id: 18,
                            isActive: true,
                            field: "",
                            display: "X20.2 a report of performance",
                            applyReplyPeriod: false,
                            noReply: true
                        },
                        {
                            id: 19,
                            isActive: true,
                            field: "",
                            display: "X20.3 proposals for improving performance",
                            applyReplyPeriod: false,
                            noReply: true
                        },
                        {
                            id: 20,
                            isActive: true,
                            field: "",
                            display: "Y1.3 a proposal to add a Supplier to the named list"
                        },
                        {
                            id: 21,
                            isActive: true,
                            field: "",
                            display: "Other submission"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Submission awaiting Acceptance"
                        }
                    ]
                }
            ]
        },
        {
            id: 107,
            title: "Submissions (with quote)",
            workflows: [
                {
                    id: 19,
                    title: "$SUPPLIER_NAME Submissions (wq)",
                    notificationName: "Submission",
                    abbreviation: "$SUPPLIER_ABBREVIATION-SubmissionWQ-",
                    notify: true,
                    replyEnabled: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "PM_REPLY_PERIOD",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CostEffectLabel",
                            display: "Change in the Prices"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "DaysEffectLabel",
                            display: "Delay to planned Completion"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME Proposals awaiting Acceptance"
                        }
                    ]
                },
                {
                    replacementKey: "ProposalClauses",
                    values: [
                        {
                            id: 2,
                            isActive: true,
                            field: "",
                            display: "36.1 a quotation for acceleration"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "",
                            display: "44.2 a quotation to accept a Defect"
                        }
                    ]
                }
            ]
        },
        {
            id: 108,
            title: "Payments",
            workflows: [
                {
                    id: 20,
                    title: "$PM_NAME Payment Certificates",
                    notificationName: "Payment Certificate",
                    abbreviation: "$PM_ABBREVIATION-Payment-",
                    notify: true,
                    financialLimitEnabled: false,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 21,
                    title: "$SUPPLIER_NAME Payment Applications",
                    notificationName: "Payment Application",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Payment-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "CumulativePriceLabel",
                            display: "Price for Work Done to Date"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "PlusAmountsSupplierLabel",
                            display: "Plus other amounts to be paid to the $SUPPLIER_NAME"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "LessAmountsSupplierLabel",
                            display: "Less amounts to be paid by or retained from the $SUPPLIER_NAME"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TaxSupplierLabel",
                            display: "Tax which the law requires the Employer to pay to the $SUPPLIER_NAME"
                        },
                        {
                            id: 5,
                            isActive: true,
                            field: "SummaryDetailsLabel",
                            display: "Summary details"
                        }
                    ]
                },
                {
                    replacementKey: "TaxEnabled",
                    values: [
                        {
                            id: 1,
                            isActive: false,
                            field: "TaxEnabled",
                            display: "Active-Inactive state indicates tax field will be included in notification forms"
                        }
                    ]
                }
            ]
        },
        {
            id: 109,
            title: "Inspections & Defects",
            workflows: [
                {
                    id: 22,
                    title: "Instruction to Search",
                    notificationName: "Instruction to Search",
                    abbreviation: "Search-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "NOTIFICATION_DATE",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 23,
                    title: "$PM_NAME Defect Notifications",
                    notificationName: "Defect Notification",
                    abbreviation: "$PM_ABBREVIATION-Defect-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    actioningParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 24,
                    title: "$SUPPLIER_NAME Defect Notifications",
                    notificationName: "Defect Notification",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Defect-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    actioningParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        actionTab: {
                            actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 25,
                    title: "$SUPPLIER_NAME Tests & Inspections",
                    notificationName: "Test / Inspection",
                    abbreviation: "Test-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "NOTIFICATION_DATE",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 48,
                    title: "$PM_NAME Tests & Inspections",
                    notificationName: "Test / Inspection",
                    abbreviation: "$PM_NAME-Test-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "NOTIFICATION_DATE",
                    replyPeriod: 0,
                    notifyingParty: "PM",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                },
                {
                    id: 26,
                    title: "Master Defect Register",
                    notificationName: "Defect Notification",
                    abbreviation: "Defect",
                    userFilters: {
                        accessFilter: "null"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "InstructionClauseLabel",
                            display: "You are instructed to search for a Defect as follows"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SupervisorClauseLabel",
                            display: "You are notified of the following Defect"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "SupplierClauseLabel",
                            display: "You are notified of the following Defect"
                        },
                        {
                            id: 4,
                            isActive: true,
                            field: "TestsClauseLabel",
                            display: "You are notified of the following test or inspection"
                        }
                    ]
                },
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Instruction",
                            display: "$PM_NAME Instruction to Search awaiting sign off"
                        },
                        {
                            id: 2,
                            isActive: true,
                            field: "SuperDefect",
                            display: "$PM_NAME Notification of Defect awaiting acceptance"
                        },
                        {
                            id: 3,
                            isActive: true,
                            field: "Inspection",
                            display: "$SUPPLIER_NAME Test/Inspection Notification awaiting sign off"
                        }
                    ]
                }
            ]
        },
        {
            id: 110,
            title: "Delegated Actions",
            workflows: [
                {
                    id: 27,
                    title: "$PM_NAME Delegated Actions",
                    notificationName: "Delegated Action",
                    abbreviation: "$PM_ABBREVIATION-Action-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "Resources",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "DelegationClause",
                            display: "You are notified that the following actions"
                        }
                    ]
                }
            ]
        },
        {
            id: 111,
            title: "Records",
            workflows: [
                {
                    id: 30,
                    title: "$PM_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$PM_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                            communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 31,
                    title: "$SUPPLIER_NAME Meetings",
                    notificationName: "Meeting",
                    abbreviation: "$SUPPLIER_ABBREVIATION-Meeting-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 32,
                    title: "$SUPPLIER_NAME Site Diary",
                    notificationName: "Site Diary",
                    abbreviation: "Diary-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                },
                {
                    id: 33,
                    title: "Weather",
                    notificationName: "Weather",
                    abbreviation: "Weather-",
                    notify: true,
                    assetFieldsEnabled: false,
                    notifyingParty: "SUPPLIER",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
                    }
                }
            ],
            placeholders: []
        },
        {
            id: 112,
            title: "RFI / TQ",
            workflows: [
                {
                    id: 34,
                    title: "$SUPPLIER_NAME RFI / TQ",
                    notificationName: "RFI / TQ",
                    abbreviation: "RFI/TQ-",
                    notify: true,
                    replyEnabled: true,
                    assetFieldsEnabled: false,
                    replyPeriodOption: "ASKED_ON_COMMUNICATION_FORM",
                    replyPeriod: 0,
                    notifyingParty: "SUPPLIER",
                    replyingParty: "PM",
                    userFilters: {
                        accessFilter: "null",
                        mainNotice: {
                            createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                            viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                            communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        replyNotice: {
                            saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                            communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
                        },
                        notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
                    }
                }
            ],
            placeholders: [
                {
                    replacementKey: "ActionReminders",
                    values: [
                        {
                            id: 1,
                            isActive: true,
                            field: "Supplier",
                            display: "$SUPPLIER_NAME RFI/TQ awaiting Reply"
                        }
                    ]
                }
            ]
        }
    ],
    copyInfo: []
};
export default template;