import React from 'react';
import MasterWorkflows from './MasterWorkflows/MasterWorkflows';
import MasterTemplates from './MasterTemplates/MasterTemplates';
import MasterTemplateEditor from './MasterTemplates/MasterTemplateEditor';
import { Navigate, Route, Routes } from "react-router-dom";
import Protector from "../../../router/Protector/Protector";

class TemplatesRouter extends React.Component {
  render() {
    return (
      <Routes caseSensitive>
        <Route path="/" exact element={<Navigate to="master-templates" />} />
        <Route path="/master-workflows" element={<Protector><MasterWorkflows /></Protector>} sensitive />
        <Route path="/master-templates" element={<Protector><MasterTemplates /></Protector>} sensitive />
        <Route path="/template-editor/:templateid" element={<Protector><MasterTemplateEditor /></Protector>} sensitive />
        <Route path="/template-editor" element={<Protector><MasterTemplateEditor /></Protector>} sensitive />
        <Route path="*" element={<Navigate replace to="/contracts" />} />
      </Routes>
    );
  }
}



export default TemplatesRouter;
