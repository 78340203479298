import React from "react";
import { Route, Routes } from "react-router-dom";
import IssueList from './conformity/List';
// import IssueCreate from './conformity/Create';
import IssueDetail from './conformity/Detail';

class IssueRouter extends React.Component {
  constructor(props) {
    super(props);

    this.communicateToUser = props.globalResources.PM_NAME;
    this.notificationType = props.workflowItem.config.currentModelType;
    this.documentType = 'DocumentNonConformity';

    this.notifyingParty = props.workflowItem.notifyingParty;
    this.replyingParty = props.workflowItem.replyingParty;




    this.notificationName = props.workflowItem.notificationName;
    this.abbreviation = props.workflowItem.abbreviation;
    this.replyPeriod = props.workflowItem.replyPeriod;
  }
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { workflowItem } = this.props;
    return (
      <Routes caseSensitive>
        <Route
          path={workflowItem.config.paths.list}
          exact
          element={<IssueList
            {...this.props}
            
            notificationType={this.notificationType}
            title={this.notificationName}
            abbreviation={this.abbreviation}
          />}

        />
        <Route
          path={'/:notificationid/*'}
          exact
          element={<IssueDetail
            {...this.props}
            replyPeriod={this.replyPeriod}
            title={this.notificationName}
            abbreviation={this.abbreviation}
            
            notificationType={this.notificationType}
            documentType={this.documentType}
            communicateToUser={this.communicateToUser}
            notifyUserFilter={workflowItem.notifyUserFilter}
          />}
        />
      </Routes>
    );
  }
};




export default (IssueRouter);