import React from 'react';
import WorkflowModal from './ModalEditors/WorkflowModal';
import DxOrderList from '../../../../../partials/content/dxControls/dxOrderList';
import DxGroupBox from '../../../../../partials/content/dxControls/dxGroupBox';
import { SelectBox } from 'devextreme-react/select-box';
import TemplateHelper from "../../../../../Templates/TemplateHelper";
import { Badge } from "react-bootstrap";

class WorkflowListManager extends React.Component {
    constructor(props) {
        super(props);
        const initialState = this.getInitialState(props);
        this.state = initialState;
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.items !== this.props.items) {
            this.setState(this.getInitialState(this.props));
        }
    }
    getInitialState = (props) => {
        let initialItemList = null;
        let listDrpOptions = null;
        if (props.items) {
            initialItemList = JSON.parse(JSON.stringify(props.items));
        }
        if (props.allItems && props.allItems.length > 0) {
            //ADD DESCRIPTION PROP FROM DEFAULT WFS            
            initialItemList.forEach(WF => {
                const defaultWFofInstance = props.allItems.find(defWF => defWF.id===WF.id);
                const defaultWFsystem = TemplateHelper.GetDefaultWorkflow(this.props.wfgid, WF.id);
                if(!defaultWFofInstance){
                    alert(`Workflow -${WF.id}-${WF.title}-${WF.description} is not enabled in this instance, this WF can not be used.`);
                }
                if(!defaultWFsystem){
                    alert(`Workflow -${WF.id}-${WF.title}-${WF.description} is not defined in the system.`);
                }
                WF.description = defaultWFsystem.config.description;
            });
            props.allItems.forEach(WF => {
                const defaultWFsystem = TemplateHelper.GetDefaultWorkflow(this.props.wfgid, WF.id);
                WF.description = defaultWFsystem.config.description;
            });
            
            listDrpOptions = props.allItems.filter(item => (props.items.findIndex(selectedItem => selectedItem.id === item.id) < 0));
        }
        return {
            items: initialItemList,
            editingItem: null,
            drpOptions: listDrpOptions,
        };
    }
    onListUpdated = (newList) => {
        this.setState({ items: JSON.parse(JSON.stringify(newList)) }, this.updateParent);
    }
    onItemSelected = (item) => {
        // if (this.props.mode === 'WORKFLOW') {
        this.setState({ editingItem: JSON.parse(JSON.stringify(item)) });
        // }

    }
    // newItemClicked = () => {
    //     if (this.props.mode === 'WORKFLOW') {
    //         this.setState({ editingItem: {} });
    //     }
    // }
    dropItemSelected = (e) => {
        if (e.value) {
            const newListDrpOptions = [...this.state.drpOptions];
            newListDrpOptions.splice(newListDrpOptions.findIndex(wf => wf.id === e.value.id), 1);

            const newitems = [...this.state.items];
            const newItem = JSON.parse(JSON.stringify(e.value))
            newItem.changed = true;
            newitems.push(newItem);

            this.setState({ items: newitems, drpOptions: newListDrpOptions }, this.updateParent);
        }
    }
    onModalClose = (itemFromModal) => {
        if (itemFromModal) {
            const newItem = JSON.parse(JSON.stringify(itemFromModal));
            newItem.changed=true;
            let newWorkflows = [];
            if (this.state.items && this.state.items.length > 0) {
                newWorkflows = JSON.parse(JSON.stringify(this.state.items));
            }
            if (newItem.id && newItem.id > 0) {
                /// UPDATE EXISTING ITEM
                newWorkflows[newWorkflows.findIndex(wf => wf.id === newItem.id)] = newItem;
            }
            else {
                /// ADD NEW ITEM
                let maxId = 1;
                if (newWorkflows.length > 0) {
                    maxId = Math.max(...newWorkflows.map(wf => wf.id));
                }
                newItem.id = maxId + 1;
                newWorkflows.push(newItem);
            }
            this.setState({ items: newWorkflows, editingItem: null }, this.updateParent);
        }
        else {
            this.setState({ editingItem: null });
        }
    }
    updateParent = () => {
        this.props.listChanged(this.state.items.map(itm => ({ ...itm })));
    }
    renderListItem = (item) => {
        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 'auto'}}>{`${item.id} | ${item.description}`}</div>
                {
                    //C-BADGE if any changes
                    (item.changed) && (
                        <Badge variant="warning">C</Badge>
                    )
                }
            </div>
        );
    }
    render() {
        return (
            <DxGroupBox
                showBorder={false}
            // toolbar={this.props.mode === 'WORKFLOW' && (
            //     <Button
            //         type="normal"
            //         stylingMode="contained"
            //         icon="plus"
            //         text="New"
            //         onClick={this.newItemClicked} />
            // )}
            >

                <SelectBox dataSource={this.state.drpOptions}
                    searchEnabled={true}
                    searchMode="contains"
                    searchExpr="description"
                    displayExpr={(wf) => { return wf && `${wf.id} | ${wf.description}`; }}
                    searchTimeout={200}
                    minSearchLength={0}
                    showDataBeforeSearch={true}
                    onValueChanged={this.dropItemSelected}
                    value={null}
                    readOnly = {this.props.readOnly}
                />

                <DxOrderList
                    items={this.state.items}
                    keyExpr="id"
                    displayExpr="description"
                    listChanged={this.onListUpdated}
                    onItemClick={this.onItemSelected} 
                    itemRender={this.renderListItem}
                    readOnly = {this.props.readOnly}
                    />
                <WorkflowModal
                    wfgid={this.props.wfgid}
                    mode={this.props.mode}
                    onClose={this.onModalClose}
                    item={this.state.editingItem}
                    readOnly = {this.props.readOnly}
                />

            </DxGroupBox>
        );
    }


}


export default WorkflowListManager