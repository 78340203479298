export const pendingActionTypes = {
    REPLY: "REPLY",
    QUOTE: "QUOTE",
    QUOTE_REPLY: "QUOTE_REPLY",
    // ASSESSMENT: "ASSESSMENT",
    ACTION: "ACTION",
}
export const PendingDrafts = [
    { workflowid: 2, prop: 'draftconew' },
    { workflowid: 7, prop: 'draftconce' },
    { workflowid: 18, prop: 'draftsuppliersubmission' },
    { workflowid: 21, prop: 'draftapplication' },
    { workflowid: 24, prop: 'draftcondefect' },
    { workflowid: 25, prop: 'draftcontest' },
    { workflowid: 28, prop: 'draftcondel' },
    { workflowid: 52, prop: 'draftconcostforecast' },
    { workflowid: 53, prop: 'draftconcarbonforecast' },
    ////STATE pendingAction so the code will check configured Quoting Party and action will appear in that partys action list accordingly
    { workflowid: 62, prop: 'draftconrfqquotation', pendingAction: pendingActionTypes.QUOTE },
    { workflowid: 1, prop: 'draftpmew' },
    { workflowid: 5, prop: 'draftpmce' },
    { workflowid: 6, prop: 'draftproce' },
    //INSTRUCTION DRAFTS
    { workflowid: 4, prop: 'draft_instruction_pm_sup' },
    { workflowid: 56, prop: 'draft_instruction_clt_pm' },
    { workflowid: 61, prop: 'draft_instruction_supv_sup' },
    //NOTIFICATION DRAFTS
    { workflowid: 15, prop: 'draft_notification_pm_sup' },
    { workflowid: 16, prop: 'draft_notification_sup_pm' },
    { workflowid: 17, prop: 'draft_notification_supv_sup' },
    { workflowid: 57, prop: 'draft_notification_clt_pm' },
    { workflowid: 58, prop: 'draft_notification_pm_supv' },
    { workflowid: 59, prop: 'draft_notification_pm_clt' },
    { workflowid: 60, prop: 'draft_notification_supv_pm' },
    //////////////////////
    { workflowid: 20, prop: 'draftcertificate' },
    { workflowid: 27, prop: 'draftpmdel' },
    { workflowid: 49, prop: 'draftpmsubmission' },
    { workflowid: 62, prop: 'draftpmrequestforquote' },
    { workflowid: 22, prop: 'draftsupinstruction' },
    { workflowid: 23, prop: 'draftsupdefect' },
    { workflowid: 29, prop: 'draftsupdel' },
    { workflowid: 73, prop: 'draftpminstructiontoquote' },
    //CERTIFICATE
    { workflowid: 68, prop: 'draft_certificate_pm' },
    { workflowid: 69, prop: 'draft_certificate_con' },
    { workflowid: 70, prop: 'draft_certificate_cli' },
    { workflowid: 71, prop: 'draft_certificate_sup' },
    //ITQ
    { workflowid: 73, prop: 'draftconitqquotation', pendingAction: pendingActionTypes.QUOTE }
]
export const PendingReplyDrafts = [
    { workflowid: 1, prop: 'replydraftconew' },
    { workflowid: 9, prop: 'replydraftsupplierextension' },
    { workflowid: 25, prop: 'replydraftcontractorinspection' },
    { workflowid: 49, prop: 'replydraftpmsubmission' },
    { workflowid: 2, prop: 'replydraftpmew' },
    { workflowid: 7, prop: 'replydraftpmce' },
    { workflowid: 18, prop: 'replydraftsuppliersubmission' },
    { workflowid: 10, prop: 'replydraftpmextension' },
    { workflowid: 14, prop: 'replydraftpmprogramme' },
    { workflowid: 19, prop: 'replydraftpmproposal' },
    { workflowid: 34, prop: 'replydraftpmrfi' },
    ////STATE pendingAction so the code will check configured QuoteReplying Party and action will appear in that partys action list accordingly
    ////STATE navigate  in case you want to navigate to custom register instead of default list register of that WF
    { workflowid: 7, prop: 'replydraftpmcequotessupplier', navigate: '/contract/$contractid/compensation-events/register-quote/list', pendingAction: pendingActionTypes.QUOTE_REPLY },
    { workflowid: 5, prop: 'replydraftpmcequotespm', navigate: '/contract/$contractid/compensation-events/register-quote/list', pendingAction: pendingActionTypes.QUOTE_REPLY },
    { workflowid: 6, prop: 'replydraftpmcequotesins', navigate: '/contract/$contractid/compensation-events/register-quote/list', pendingAction: pendingActionTypes.QUOTE_REPLY },
    ////STATE pendingAction so the code will check configured QuoteReplying Party and action will appear in that partys action list accordingly
    { workflowid: 62, prop: 'replydraftpmrfqquotation', pendingAction: pendingActionTypes.QUOTE_REPLY },
    { workflowid: 22, prop: 'replydraftsupervisorinstruction' },
    //INSTRUCTION DRAFTS
    { workflowid: 4, prop: 'replydraft_instruction_pm_sup' },
    { workflowid: 56, prop: 'replydraft_instruction_clt_pm' },
    { workflowid: 61, prop: 'replydraft_instruction_supv_sup' },
    //NOTIFICATION DRAFTS
    { workflowid: 15, prop: 'replydraft_notification_pm_sup' },
    { workflowid: 16, prop: 'replydraft_notification_sup_pm' },
    { workflowid: 17, prop: 'replydraft_notification_supv_sup' },
    { workflowid: 57, prop: 'replydraft_notification_clt_pm' },
    { workflowid: 58, prop: 'replydraft_notification_pm_supv' },
    { workflowid: 59, prop: 'replydraft_notification_pm_clt' },
    { workflowid: 60, prop: 'replydraft_notification_supv_pm' },
    //CERTIFICATE
    { workflowid: 68, prop: 'replydraft_certificate_pm' },
    { workflowid: 69, prop: 'replydraft_certificate_con' },
    { workflowid: 70, prop: 'replydraft_certificate_cli' },
    { workflowid: 71, prop: 'replydraft_certificate_sup' },
    //ITQ
    { workflowid: 73, prop: 'replydraftpmitqquotation', pendingAction: pendingActionTypes.QUOTE_REPLY }
]

const PendingActionsConfig = [
    ////////////////////////////////////////////////////
    ///////////////SUPPLIER/////////////////////////
    /////////////////////////////////////////////////////////
    //Early Warnings awaiting reply
    {
        actionCode: 'EARLY_WARNING_PM',
        workflowid: 1,
        resource: 'Pm',
        pendingAction: pendingActionTypes.REPLY
    },
    //Compensation Events notified by PM awaiting quotes
    {
        actionCode: 'COMPENSATION_PM_AQ',
        workflowid: 5,
        resource: 'PmCompensationAQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    //Compensation Events notified by PM awaiting revised quotes
    {
        actionCode: 'COMPENSATION_PM_ARQ',
        workflowid: 5,
        resource: 'PmCompensationARQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    //Compensation Events notified by Contractor awaiting quotes
    {
        actionCode: 'COMPENSATION_CON_AQ',
        workflowid: 7,
        resource: 'SupCompensationAQ',
        pendingAction: pendingActionTypes.QUOTE

    },
    //Claims notified by Contractor awaiting quotes
    {
        actionCode: 'COMPENSATION_CWQ_AQ',
        workflowid: 37,
        resource: 'SupClaimAQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    //Compensation Events notified by Subcontractor awaiting quotes
    {
        actionCode: 'COMPENSATION_SCON_AQ',
        workflowid: 40,
        resource: 'SupCompensationAQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    //Supplier Claims with Notice awaiting quotes
    {
        actionCode: 'COMPENSATION_CWN_AQ',
        workflowid: 67,
        resource: 'COMPENSATION_CWN_AQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    //Compensation Events notified by Contractor awaiting revised quotes
    {
        actionCode: 'COMPENSATION_CON_ARQ',
        workflowid: 7,
        resource: 'SupCompensationARQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    //Claims notified by Contractor awaiting revised quotes
    {
        actionCode: 'COMPENSATION_CWQ_ARQ',
        workflowid: 37,
        resource: 'SupClaimARQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    //Compensation Events notified by Subcontractor awaiting revised quotes
    {
        actionCode: 'COMPENSATION_SCON_ARQ',
        workflowid: 40,
        resource: 'SupCompensationARQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    //Supplier Claims with Notice awaiting revised quotes
    {
        actionCode: 'COMPENSATION_CWN_ARQ',
        workflowid: 67,
        resource: 'COMPENSATION_CWN_ARQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    //Proposed Compensation Events awaiting quotes
    {
        actionCode: 'COMPENSATION_PRO_AQ',
        workflowid: 6,
        resource: 'PmProposedAQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    //Proposed Compensation Events awaiting revised quotes
    {
        actionCode: 'COMPENSATION_PRO_ARQ',
        workflowid: 6,
        resource: 'PmProposedARQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    //PM Quote Response Extensions awaiting Acceptance
    {
        actionCode: 'EXTENSION_PM',
        workflowid: 9,
        resource: 'PmExtension',
        pendingAction: pendingActionTypes.REPLY
    },
    //Contractors Test/Inspection awaiting sign off
    {
        actionCode: 'TEST_CON',
        workflowid: 25,
        resource: 'Inspection',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'SUBMISSION_PM',
        workflowid: 49,
        resource: 'PM',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'NC_SUBMISSION_PM',
        workflowid: 54,
        resource: 'PMNCProposal',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'NOTIFICATION_PM',
        workflowid: 15,
        resource: 'PM',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'NOTIFICATION_SUP',
        workflowid: 17,
        resource: 'Supervisor',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'INSTRUCTION_PM',
        workflowid: 4,
        resource: 'PM',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'INSTRUCTION_SUPERVISOR',
        workflowid: 61,
        resource: 'Supervisor',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'RFQ_AQ',
        workflowid: 62,
        resource: 'PmRfqAQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    {
        actionCode: 'RFQ_ARQ',
        workflowid: 62,
        resource: 'PmRfqARQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    {
        actionCode: 'NOTIFICATION_SUPPLIER_SUPPLIER',
        workflowid: 65,
        resource: 'SupplierToSupplier',
        pendingAction: pendingActionTypes.REPLY
    },
    //PM Certificate awaiting reply
    {
        actionCode: 'CERTIFICATE_PM',
        workflowid: 68,
        resource: 'PM',
        pendingAction: pendingActionTypes.REPLY
    },
    //Supervisor Certificate awaiting reply
    {
        actionCode: 'CERTIFICATE_SUP',
        workflowid: 71,
        resource: 'Supervisor',
        pendingAction: pendingActionTypes.REPLY
    },
    //////////////////////////////////////////////////////////////////
    //////////////PROJECT MANAGER/////////////////////////
    /////////////////////////////////////////////////////////////
    //Supplier Early Warnings awaiting reply
    {
        actionCode: 'EARLY_WARNING_CON',
        workflowid: 2,
        resource: 'Supplier',
        pendingAction: pendingActionTypes.REPLY
    },
    //Client Early Warnings awaiting reply
    {
        actionCode: 'EARLY_WARNING_CLI',
        workflowid: 74,
        resource: 'Client',
        pendingAction: pendingActionTypes.REPLY
    },
    //Compensation Events notified by PM awaiting response to quotes
    {
        actionCode: 'COMPENSATION_PM_APMRQ',
        workflowid: 5,
        resource: 'PmCompensationAPMRQ',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Compensation Events notified by PM awaiting PM Assessment
    {
        actionCode: 'COMPENSATION_PM_APMA',
        workflowid: 5,
        resource: 'PmCompensationAPMA',
        pendingAction: pendingActionTypes.QUOTE_REPLY

    },
    //Compensation Events notified by Contractor awaiting PM response
    {
        actionCode: 'COMPENSATION_CON_APMR',
        workflowid: 7,
        resource: 'SupCompensationAPMR',
        pendingAction: pendingActionTypes.REPLY
    },
    //Claims notified by Contractor awaiting PM response
    {
        actionCode: 'CLAIM_CON_APMR',
        workflowid: 46,
        resource: 'SupClaimAPMR',
        pendingAction: pendingActionTypes.REPLY
    },
    //Compensation Events notified by Subcontractor awaiting Contractor response
    {
        actionCode: 'COMPENSATION_SCON_APMR',
        workflowid: 40,
        resource: 'SupCompensationAPMR',
        pendingAction: pendingActionTypes.REPLY
    },
    //Supplier Claims with Notice awaiting PM response
    {
        actionCode: 'COMPENSATION_CWN_APMR',
        workflowid: 67,
        resource: 'COMPENSATION_CWN_APMR',
        pendingAction: pendingActionTypes.REPLY
    },
    //Compensation Events notified by Contractor awaiting response to quotes
    {
        actionCode: 'COMPENSATION_CON_APMRQ',
        workflowid: 7,
        resource: 'SupCompensationAPMRQ',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Claims notified by Contractor awaiting response to quotes
    {
        actionCode: 'COMPENSATION_CWQ_APMRQ',
        workflowid: 37,
        resource: 'SupClaimAPMRQ',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Compensation Events notified by Subcontractor awaiting response to quotes
    {
        actionCode: 'COMPENSATION_SCON_APMRQ',
        workflowid: 40,
        resource: 'SupCompensationAPMRQ',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Supplier Claims with Notice awaiting response to quotes
    {
        actionCode: 'COMPENSATION_CWN_APMRQ',
        workflowid: 67,
        resource: 'COMPENSATION_CWN_APMRQ',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Compensation Events notified by Contractor awaiting PM Assessment
    {
        actionCode: 'COMPENSATION_CON_APMA',
        workflowid: 7,
        resource: 'SupCompensationAPMA',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Claims notified by Contractor awaiting PM Assessment
    {
        actionCode: 'COMPENSATION_CWQ_APMA',
        workflowid: 37,
        resource: 'SupClaimAPMA',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Compensation Events notified by Subcontractor awaiting PM Assessment
    {
        actionCode: 'COMPENSATION_SCON_APMA',
        workflowid: 40,
        resource: 'SupCompensationAPMA',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Supplier Claims with Notice awaiting PM Assessment
    {
        actionCode: 'COMPENSATION_CWN_APMA',
        workflowid: 67,
        resource: 'COMPENSATION_CWN_APMA',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Proposed Compensation Events awaiting response to quotes
    {
        actionCode: 'COMPENSATION_PRO_APMRQ',
        workflowid: 6,
        resource: 'PmProposedAPMRQ',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Proposed Compensation Events awaiting PM Assessment
    {
        actionCode: 'COMPENSATION_PRO_APMA',
        workflowid: 6,
        resource: 'PmProposedAPMA',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Contractors Programme Submission awaiting Acceptance
    {
        actionCode: 'PROGRAMME_CON',
        workflowid: 14,
        resource: 'Supplier',
        pendingAction: pendingActionTypes.REPLY
    },
    //Contractors Issue awaiting Acceptance
    {
        actionCode: 'SUBMISSION_CON',
        workflowid: 18,
        resource: 'Supplier',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'NOTIFICATION_CON',
        workflowid: 16,
        resource: 'Supplier',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'NOTIFICATION_CLIENT',
        workflowid: 57,
        resource: 'Client',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'NOTIFICATION_SUPERVISOR_PM',
        workflowid: 60,
        resource: 'SupervisorToPm',
        pendingAction: pendingActionTypes.REPLY
    },
    //Contractors Proposals awaiting Acceptance
    {
        actionCode: 'PROPOSAL_CON',
        workflowid: 19,
        resource: 'Supplier',
        pendingAction: pendingActionTypes.REPLY
    },
    //Contractors Quote Extensions awaiting Acceptance
    {
        actionCode: 'EXTENSION_CON',
        workflowid: 10,
        resource: 'SupExtension',
        pendingAction: pendingActionTypes.REPLY
    },
    //Contractors Assumptions awaiting Acceptance
    {
        actionCode: 'ASSUMPTION_CON',
        workflowid: 42,
        resource: 'SupAssumption',
        pendingAction: pendingActionTypes.REPLY
    },
    //Contractors RFI/TQ awaiting Reply
    {
        actionCode: 'RFI_CON',
        workflowid: 34,
        resource: 'Supplier',
        pendingAction: pendingActionTypes.REPLY
    },
    //PM RFI/TQ awaiting Reply
    {
        actionCode: 'RFI_PM',
        workflowid: 80,
        resource: 'PM',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'NON_CONFORMITY',
        workflowid: 55,
        resource: 'NonConformity',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'INSTRUCTION_CLIENT',
        workflowid: 56,
        resource: 'Client',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'RFQ_APMRQ',
        workflowid: 62,
        resource: 'PmRfqAPMRQ',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    {
        actionCode: 'RFQ_APMA',
        workflowid: 62,
        resource: 'PmRfqAPMA',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    {
        actionCode: 'NOTIFICATION_PM_PM',
        workflowid: 63,
        resource: 'PmToPm',
        pendingAction: pendingActionTypes.REPLY
    },
    //Contractor Certificate awaiting reply
    {
        actionCode: 'CERTIFICATE_CON',
        workflowid: 69,
        resource: 'Supplier',
        pendingAction: pendingActionTypes.REPLY
    },
    //Client Certificate awaiting reply
    {
        actionCode: 'CERTIFICATE_CLI',
        workflowid: 70,
        resource: 'Client',
        pendingAction: pendingActionTypes.REPLY
    },
    /////////////////////////////////////////////////////////////
    /////////////////////SUPERVISOR///////////////////////
    //////////////////////////////////////////////////////////////////
    //Supervisor Instruction to Search awaiting sign off
    {
        actionCode: 'SEARCH_SUP',
        workflowid: 22,
        resource: 'Instruction',
        pendingAction: pendingActionTypes.REPLY
    },
    //Supervisor Notification of Defect awaiting close
    {
        actionCode: 'DEFECT_SUP',
        workflowid: 23,
        resource: 'SuperDefect',
        pendingAction: pendingActionTypes.ACTION
    },
    //Supplier Notification of Defect awaiting close
    {
        actionCode: 'DEFECT_SUPPLIER',
        workflowid: 24,
        resource: 'SupplierDefect',
        pendingAction: pendingActionTypes.ACTION
    },
    //Supervisor Test/Inspection awaiting sign off
    {
        actionCode: 'TEST_SUP',
        workflowid: 47,
        resource: 'SupervisorTestAndInspection',
        pendingAction: pendingActionTypes.REPLY
    },
    //PM Test/Inspection awaiting sign off
    {
        actionCode: 'TEST_PM',
        workflowid: 48,
        resource: 'PMTestAndInspection',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'NOTIFICATION_PM_SUPERVISOR',
        workflowid: 58,
        resource: 'PmToSupervisor',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'NOTIFICATION_SUPERVISOR_SUPERVISOR',
        workflowid: 66,
        resource: 'SupervisorToSupervisor',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'NOTIFICATION_SUPPLIER_SUPERVISOR',
        workflowid: 81,
        resource: 'SupplierToSupervisor',
        pendingAction: pendingActionTypes.REPLY
    },
    //////////////////////////////////////////////////////////////////
    //////////////CLIENT/////////////////////////
    /////////////////////////////////////////////////////////////
    {
        actionCode: 'NOTIFICATION_PM_CLIENT',
        workflowid: 59,
        resource: 'PmToClient',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'NOTIFICATION_CLIENT_CLIENT',
        workflowid: 64,
        resource: 'ClientToClient',
        pendingAction: pendingActionTypes.REPLY
    },
    {
        actionCode: 'ITQ_AQ',
        workflowid: 73,
        resource: 'PmItqAQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    {
        actionCode: 'ITQ_ARQ',
        workflowid: 73,
        resource: 'PmItqARQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    {
        actionCode: 'ITQ_APMRQ',
        workflowid: 73,
        resource: 'PmItqAPMRQ',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    {
        actionCode: 'ITQ_APMA',
        workflowid: 73,
        resource: 'PmItqAPMA',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Client Claims with Notice awaiting quotes
    {
        actionCode: 'COMPENSATION_CLIENTCWN_AQ',
        workflowid: 75,
        resource: 'COMPENSATION_CLIENTCWN_AQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    //Client Claims with Notice awaiting revised quotes
    {
        actionCode: 'COMPENSATION_CLIENTCWN_ARQ',
        workflowid: 75,
        resource: 'COMPENSATION_CLIENTCWN_ARQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    //Client Claims with Notice awaiting PM response
    {
        actionCode: 'COMPENSATION_CLIENTCWN_APMR',
        workflowid: 75,
        resource: 'COMPENSATION_CLIENTCWN_APMR',
        pendingAction: pendingActionTypes.REPLY
    },
    //Client Claims with Notice awaiting response to quotes
    {
        actionCode: 'COMPENSATION_CLIENTCWN_APMRQ',
        workflowid: 75,
        resource: 'COMPENSATION_CLIENTCWN_APMRQ',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Client Claims with Notice awaiting PM Assessment
    {
        actionCode: 'COMPENSATION_CLIENTCWN_APMA',
        workflowid: 75,
        resource: 'COMPENSATION_CLIENTCWN_APMA',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Compensation Events notified by Client awaiting quotes
    {
        actionCode: 'COMPENSATION_CLIENT_AQ',
        workflowid: 76,
        resource: 'COMPENSATION_CLIENT_AQ',
        pendingAction: pendingActionTypes.QUOTE

    },
    //Compensation Events notified by Client awaiting revised quotes
    {
        actionCode: 'COMPENSATION_CLIENT_ARQ',
        workflowid: 76,
        resource: 'COMPENSATION_CLIENT_ARQ',
        pendingAction: pendingActionTypes.QUOTE
    },
    //Compensation Events notified by Client awaiting PM response
    {
        actionCode: 'COMPENSATION_CLIENT_APMR',
        workflowid: 76,
        resource: 'COMPENSATION_CLIENT_APMR',
        pendingAction: pendingActionTypes.REPLY
    },
    //Compensation Events notified by Client awaiting response to quotes
    {
        actionCode: 'COMPENSATION_CLIENT_APMRQ',
        workflowid: 76,
        resource: 'COMPENSATION_CLIENT_APMRQ',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
    //Compensation Events notified by Client awaiting PM Assessment
    {
        actionCode: 'COMPENSATION_CLIENT_APMA',
        workflowid: 76,
        resource: 'COMPENSATION_CLIENT_APMA',
        pendingAction: pendingActionTypes.QUOTE_REPLY
    },
];


export default PendingActionsConfig;