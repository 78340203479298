import React from 'react';
import { connect } from "react-redux";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import * as templatesDuck from '../../../../store/ducks/templates.duck';
import MasterWorkflowsDetails from './MasterWorkflowsDetails';
import * as templateCrud from '../../../../crud/templates.crud';
import { withSnackbar } from 'notistack';
import TemplateHelper from '../../../../Templates/TemplateHelper';
import { withRouter } from '../../../../shared/hoc/withRouter';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import { Navigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import Common from '../../../../shared/common';
class MasterWorkflows extends React.Component {
    fileImportInputRef = React.createRef();
    state = {
        workflowGroups: null,
        changed: false,
        isSaving: false,
        versionTrackerForChildrenReRender: 1
        
    }
    componentDidMount() {
        // deep object copy
        this.props.loadWorkflows();
        if (this.props.workflowGroups) {
            const newList = JSON.parse(JSON.stringify(this.props.workflowGroups));
            this.setState({ workflowGroups: newList });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.workflowGroups !== this.props.workflowGroups) {
                if (this.props.workflowGroups) {
                    const newList = JSON.parse(JSON.stringify(this.props.workflowGroups));
                    this.setState({ workflowGroups: newList });
                }
            }
        }
    }
    saveWorkflows = async () => {
        try {
            const newTemplate = { ...templateCrud.getTemplates()[0] }           
            newTemplate.workflowGroups = JSON.parse(JSON.stringify(this.state.workflowGroups))
            const sanitizedNewTemplate = TemplateHelper.templateSanitizer(newTemplate);
            const newWorkflowGroups = {
                workflowid: 1,
                name: 'ALL',
                workflowjson: JSON.stringify(sanitizedNewTemplate.workflowGroups),
                deleted: 0
            };
            await templateCrud.saveWorkflowGroups(newWorkflowGroups);
            this.props.enqueueSnackbar('Workflow Groups Successfully Saved', { variant: 'success' });
            this.props.setWorkflowGroups(JSON.parse(JSON.stringify(sanitizedNewTemplate.workflowGroups)));
            this.setState({ changed: false, isSaving: false, navigateto: "/admin/templates/master-templates" });
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ isSaving: false });
        }




        // const promises = [];

        // this.state.workflowGroups.forEach(wfg => {
        //     const newWorkflowGroup = {
        //         workflowid: wfg.workflowid,
        //         name: wfg.title,
        //         workflowjson: JSON.stringify(wfg)
        //     };
        //     promises.push(templateCrud.saveWorkflowGroup(newWorkflowGroup));

        // });
        // axios.all(promises)
        //     .then(response => {
        //         this.props.enqueueSnackbar('Workflow Groups Successfully Saved', { variant: 'success', });

        //     })
        //     .catch(err => {
        //         this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        //     });


    }
    onWorkflowGroupsChanged = (newList) => {
        this.setState({ workflowGroups: JSON.parse(JSON.stringify(newList)), changed: true });
    }

    downloadWorkflowJson = (e) => {
        
        const workflowGroupsArray = JSON.parse(JSON.stringify(this.state.workflowGroups));
        const newWorkflow = {
            workflowGroups: workflowGroupsArray
        };
        const workflowFileName = `MASTER_WORKFLOW`;
        const stringifiedJson = JSON.stringify(TemplateHelper.templateSanitizer(newWorkflow), null, 2);
        Common.downloadFile({
            data: stringifiedJson,
            fileName: workflowFileName + '.json',
            fileType: 'text/json',
        });
    }
    importWorkFlowJson = (e) => {
        if (this.fileImportInputRef.current) {
            this.fileImportInputRef.current.click();
        }
    }
    importFileSelected = async (e) => {
        if (e.stopPropagation) { e.stopPropagation(); }
        if (e.preventDefault) { e.preventDefault(); }
        if (e.target && e.target.files && e.target.files.length > 0) {
            var file = e.target.files[0];
            if (file) {
                try {
                    const text = await file.text();
                    const importedWorkflow = JSON.parse(text);
                    const workflowGroups = importedWorkflow.workflowGroups;

                    if (workflowGroups) {
                        this.setState({
                            workflowGroups: workflowGroups,
                            versionTrackerForChildrenReRender: this.state.versionTrackerForChildrenReRender + 1,
                            changed: true,
                        });
                        
                        this.props.enqueueSnackbar('Workflow successfully imported', { variant: 'success' });
                        
                    } else {
                        this.props.enqueueSnackbar("Invalid workflow file structure", { variant: 'error' });
                    }
                } catch (err) {
                    this.props.enqueueSnackbar("Error reading file", { variant: 'error', });
                    console.log(err.toString());
                } finally {
                    if (this.fileImportInputRef.current) {
                        this.fileImportInputRef.current.value = null;
                    }
                }
            }
        }
    }
    

    render() {
        if (!this.state.workflowGroups) {
            return (<PortletSpinner />);
        }
        const { allowUpdate, allowImportExport } = this.props.protectedRouteProps;
        return (
            <Portlet>
                {!allowUpdate && (<Badge variant="danger"><FormattedMessage id="GENERAL.WARNING.NOT_AUTHORIZED_ON_PAGE" /></Badge>)}
                <NavigationBlocker
                    when={this.state.changed}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} />
                    )
                }
                {allowImportExport && (
                    <input type='file' id='importFile' accept='.json' ref={this.fileImportInputRef} style={{ display: 'none' }} onChange={this.importFileSelected} />
                )}
                <PortletHeader
                    title="Master Workflows"
                    toolbar={(
                        <div style={{ display: 'flex', flex: 1, flexFlow: 'row-reverse', margin: "20px 0px" }}>
                            <LoadIndicator className="button-indicator" visible={this.state.isSaving} />
                            <Toolbar disabled={this.state.isSaving}>
                                {allowUpdate && (
                                    <ToolbarItem
                                        key="masterWorkflowBtnUpdate"
                                        widget="dxButton"
                                        location="after"
                                        locateInMenu="never"
                                        disabled={!this.state.changed || !allowUpdate || this.state.isSaving}
                                        options={{
                                            text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SAVE_CHANGES' }),
                                            type: "default",
                                            stylingMode: this.state.changed ? "contained" : "outlined",
                                            onClick: () => { this.setState({ isSaving: true }, this.saveWorkflows) }
                                        }} />
                                )}
                                {allowImportExport &&
                                    (
                                        <ToolbarItem
                                            key="masterTempBtnExportJSON"
                                            location="after"
                                            locateInMenu="always"
                                            widget="dxButton"
                                            options={
                                                {
                                                    icon: "export",
                                                    onClick: this.downloadWorkflowJson,
                                                    text: this.props.intl.formatMessage({ id: 'MASTERWORKFLOW.BUTTONS.EXPORT_JSON' }),
                                                }
                                            }
                                        />
                                    )}
                                {allowImportExport &&
                                    (
                                        <ToolbarItem
                                            key="masterTempBtnImportJSON"
                                            location="after"
                                            locateInMenu="always"
                                            widget="dxButton"
                                            options={
                                                {
                                                    icon: "import",
                                                    onClick: this.importWorkFlowJson,
                                                    text: this.props.intl.formatMessage({ id: 'MASTERWORKFLOW.BUTTONS.IMPORT_JSON' }),
                                                }
                                            }
                                        />
                                    )}

                            </Toolbar>
                        </div>
                    )}
                />
                <PortletBody style={{ height: 450 }}>
                    <MasterWorkflowsDetails
                        mode="WORKFLOW"
                        workflowGroups={this.state.workflowGroups}
                        onChange={this.onWorkflowGroupsChanged}
                        key={this.state.versionTrackerForChildrenReRender}
                    />

                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    workflowGroups: store.templates.workflowGroups,
    userProfile: store.auth.userProfile,
});
const mapDispatchToProps = {
    loadWorkflows: templatesDuck.actions.fetchWorkflows,
    setWorkflowGroups: templatesDuck.actions.setWorkflowGroups,
}

export default withRouter(injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(MasterWorkflows))));

