import React, { Component } from "react";
import { connect } from "react-redux";
import DxDialogModal from "../../../../partials/content/dxControls/dxDialogModal";
import { Col, Row } from "react-bootstrap";
import * as fileCrud from "../../../../crud/file.crud";
import DragDropFilepond from "../../../../partials/content/DragDropFilepond";
import { withRouter } from '../../../../shared/hoc/withRouter';
import AsiteFileList from '../../../../partials/content/Asite/AsiteFileList';
import { FormattedMessage } from "react-intl";

const borderStyle = "thin solid";

class CustomDocument extends Component {
  constructor(props) {
    super(props);
    this.isAsiteEnabled = process.env.REACT_APP_ATTACHMENT_MODE === 'ASITE';
    this.state = {
        showModal: false,
        deletingFileId: null,
        deletingFileTitle: null
    };
  }

  downloadFile = (token, id) => {
    window.location.href = `${process.env.REACT_APP_API_URL}Helpers/Download.ashx?Token=${token}&ID=${id}`;
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.form !== nextProps.form ||
      this.props.bodyConfig !== nextProps.bodyConfig ||
      this.props.files !== nextProps.files ||
      this.state.showModal !== nextState.showModal
    ) {
      return true;
    } else {
      return false;
    }
  }

  onModalResponse = (response) => {
    this.setState({ showModal: false });

    if (response === "OK") {
      this.deleteNotifiedFile();
    } else {
      this.setState({ deletingFileId: null });
    }
  };

  deleteNotifiedFile = async () => {
    const { companyid, projectid, contractid } = this.props.contract;
    await fileCrud.deleteContractFile(
      companyid,
      projectid,
      contractid,
      this.state.deletingFileId
    );
    window.location.reload();
  };

  getHtmlValue = (value) => {
    if (value && value.length > 0) {
      return (
        <div
          style={{ marginTop: 20 }}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      );
    } else {
      return null;
    }
  };



  renderForm = () => {
    const { companyid, projectid, contractid } = this.props.contract;

    return (
      <>
        <div style={{ marginTop: 10 }}>{this.props.form}</div>
        <Row style={{ marginTop: 10 }}>
          <Col>
            <span className="dx-field-item-label-text dx-box-item-content dx-widget">
              <FormattedMessage id="GENERAL.FORMS.FIELD.ATTACHED_DOCUMENTS" />
            </span>
            {
              this.isAsiteEnabled ?
              <div className="dx-field" style={{ marginTop: "10px" }} >
                  <AsiteFileList
                      notificationid={this.props.notificationid}
                      documentType={this.props.documentType}
                      isDraft={true}
                      isReply={false}
                  />
              </div> :
              <div className="dx-field" style={{ marginTop: "10px" }}>
              <DragDropFilepond
                authToken={this.props.authToken}
                files={this.props.files}
                uploadMethod={this.props.uploadMethod}
                deleteMethod={(fileid) => {
                  return fileCrud.deleteContractFile(
                    companyid,
                    projectid,
                    contractid,
                    fileid
                  );
                }}
                canNotEdit={this.props.canNotEdit}
              />
            </div>
            }
          </Col>
        </Row>
      </>
    );
  };

  render() {
    const containerStyle = {};
    const headerStyle = {};

    if (this.props.isFinal) {
      containerStyle.borderBottom = borderStyle;
    } else {
      headerStyle.borderBottom = borderStyle;
    }

    return (
      <>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "100%", maxWidth: "900px", margin: "20px" }}>
            <div>{this.renderForm()}</div>
          </div>
        </div>
        <DxDialogModal
          visible={this.state.showModal}
          title={`Delete Attachment ${this.state.deletingFileTitle}`}
          text={`You are about to delete attachment "${this.state.deletingFileTitle}" using System Admin privileges, this action can not be reverted, Are you sure you want to delete?`}
          buttonOptions={{ text: "Delete", type: "danger" }}
          onDialogResult={this.onModalResponse}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  authToken: store.auth.authToken.token,
  userProfile: store.auth.userProfile,
  contract: store.contract.contract,
});

export default withRouter(connect(mapStateToProps)(CustomDocument));
