import React, { Component } from "react";
import { connect } from "react-redux";
import Card from "../../../partials/content/Card";
import { GetAccessRights } from "../../../router/Protector/Protector";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.customFieldsEnabled = process.env.REACT_APP_CUSTOM_FIELDS_ENABLED === "true";
    this.customFieldsEnabledForClientRoles = process.env.REACT_APP_CLIENT_MANAGES_CUSTOM_FIELDS === "true";
    this.accessTags = GetAccessRights("/admin/settings/tags", this.props.userProfile).allowAccess;
    this.accessSystemSettings = GetAccessRights("/admin/settings/system", this.props.userProfile).allowAccess;
    this.accessCustomFields = GetAccessRights("/admin/settings/custom-fields", this.props.userProfile).allowAccess;
    this.accessWebHooks = GetAccessRights("/admin/settings/webhook", this.props.userProfile).allowAccess;
    this.renderCustomFieldCard = false

    const { customFieldsEnabled, accessCustomFields, customFieldsEnabledForClientRoles } = this;
    const { accounttype } = this.props.userProfile;


    if (customFieldsEnabled && accessCustomFields) {
      this.renderCustomFieldCard = true;
      if (!customFieldsEnabledForClientRoles && (accounttype === "Site Administrator" || accounttype === "Company Administrator")) {
        this.renderCustomFieldCard = false;
      }
    }


  }

  render() {



    return (

      <div className="container">
        <div className="row g-4 g-xl-9 mb-6 mb-xl-9">
          {this.accessTags && (
            <Card title="Tags" url="/admin/settings/tags" description="Create and edit instance level Tag definitions" icon="/media/icons/svg/Shopping/Price1.svg" />
          )}
          {this.accessSystemSettings && (
            <>
              <Card title="Features" description="Turn on/off instance level features" icon="/media/icons/svg/General/Settings-1.svg" />
              <Card title="System Settings" url="/admin/settings/system" description="System setting configurations" icon="/media/icons/svg/Code/Settings4.svg" />
            </>
          )}
        </div>
        <div className="row g-4 g-xl-9 mb-6 mb-xl-9">
          {this.renderCustomFieldCard && (
            <Card title="Custom Fields" url="/admin/settings/custom-fields" description="Create and edit Custom Fields" icon="/media/icons/svg/Media/Playlist2.svg" />
          )}
          {(this.accessWebHooks && process.env.REACT_APP_WEBHOOKS_ENABLED === 'true') && (
            <Card title="Connections" url="/admin/settings/webhook" description="Connection configurations" icon="/media/icons/svg/Code/Git3.svg" />
          )}
        </div>
      </div>
      // <Portlet>
      //     <PortletHeader title="System Settings" />
      //     <PortletBody >
      //       <TabPanel
      //           width="100%"
      //           deferRendering={false}
      //           animationEnabled={true}
      //           swipeEnabled={true}>
      //           {this.props.isSystemOwner && (<Item title={"General"} render={this.tabGeneral} />)}
      //           <Item title={"Tags"} render={this.tabTag} />
      //       </TabPanel>
      //     </PortletBody>
      // </Portlet>
    );
  }
}

const mapStateToProps = store => ({
  userProfile: store.auth.userProfile,
});

export default connect(mapStateToProps)(Settings);
