import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SystemUsers from './SystemUsers';
import ContractBulkAddUsers from './ContractBulkAddUsers';
import Protector from "../../../router/Protector/Protector";

class UsersRouter extends React.Component {
    render() {
        return (
            <Routes caseSensitive>
                 <Route path="/manage-system-users" exact element={<Protector><SystemUsers/></Protector>} sensitive/>
                 <Route path="/contract-add-bulk-users" exact element={<Protector><ContractBulkAddUsers/></Protector>} sensitive/>
                <Route path="*" element={<Navigate replace to="/contracts" />} />
            </Routes>
        );
    }

}


export default UsersRouter;