import axios from "../shared/axios";
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";

export function getItqQuote(itqquotationid) {
  return axios.get(`/api/InstructionToQuoteQuotation/GetItqQuotation?itqquotationid=${itqquotationid}`);
}

export function createItqQuote(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/InstructionToQuoteQuotation/Create`, data)
      .then((result) => {
        store.dispatch({
          type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD,
        });
        resolve(result);
      })
      .catch((error) => reject(error));
  });
}

export function updateItqQuote(data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/InstructionToQuoteQuotation/Update`, data)
      .then((result) => {
        store.dispatch({
          type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD,
        });
        resolve(result);
      })
      .catch((error) => reject(error));
  });
}

export function getItqQuotes(companyid, projectid, contractid, instructiontoquoteid, accessibleWfIds) {
  return axios.post(`/api/InstructionToQuoteQuotation/RegisterList`, { accessibleWfIds }, { params: { companyid, projectid, contractid, instructiontoquoteid } });
}


export function deleteInstructionToQuoteDraft(itqquotationid) {
  return new Promise((resolve, reject) => {
  axios.post(`/api/InstructionToQuoteQuotation/DeleteDraft?itqquotationid=${itqquotationid}`)
      .then(result => {
          store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_DASHBOARD });
          resolve(result);
      })
      .catch(error => reject(error));
  })
}