import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { injectIntl } from "react-intl";
import { useSelector, useDispatch } from 'react-redux';
import * as contractDuck from "../../store/ducks/contract.duck";
import { useSnackbar } from 'notistack';

export const withDetailPageProps = (Component) => {
  function ComponentWithDetailPageProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let dispatch = useDispatch();
    // const contractUser = useSelector(store => store.contract.user);
    const contractUsers = useSelector(store => store.contract.users);
    const contract = useSelector(store => store.contract.contract);
    // const globalResources = useSelector(store => store.contract.template.resources);
    const dashboard = useSelector(store => store.contract.dashboard);
    const  template = useSelector(store =>store.contract.template );
    const setReference = (x) => { dispatch(contractDuck.actions.setReference(x))}
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // let match = useMatch();
    // console.log(this._reactInternalInstance._currentElement._owner._instance.__proto__.constructor.name      )
    return (

      <Component
        {...props}
        location={location}
        params={params}
        navigate={navigate}
        // contractUser={contractUser}
        contractUsers={contractUsers}
        contract={contract}
        // globalResources={globalResources}
        dashboard={dashboard}
        template={template}
        setReference={setReference}
        enqueueSnackbar={enqueueSnackbar}
        closeSnackbar={closeSnackbar}
      />
    );
  }

  return  injectIntl(ComponentWithDetailPageProp);
}
