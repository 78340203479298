const template = {
    name: "Default NEC3 SSC v2.5 01/01/2024",
    isSystemDefault: true,
    isGlobal: true,
    companies: [],
    resources: [
      {
        id: 1,
        type: "TEXT",
        key: "SUPPLIER_NAME",
        value: "Supplier"
      },
      {
        id: 2,
        type: "TEXT",
        key: "SUPPLIER_ABBREVIATION",
        value: "S"
      },
      {
        id: 3,
        type: "TEXT",
        key: "PM_NAME",
        value: "Purchaser"
      },
      {
        id: 4,
        type: "TEXT",
        key: "PM_ABBREVIATION",
        value: "P"
      },
      {
        id: 5,
        type: "TEXT",
        key: "SUPERVISOR_NAME",
        value: "Supervisor"
      },
      {
        id: 6,
        type: "TEXT",
        key: "SUPERVISOR_ABBREVIATION",
        value: "SUP"
      },
      {
        id: 7,
        type: "TEXT",
        key: "CLIENT_NAME",
        value: "Employer"
      },
      {
        id: 8,
        type: "NUMBER",
        key: "SUPPLIER_REPLY_PERIOD",
        value: 21
      },
      {
        id: 9,
        type: "NUMBER",
        key: "PM_REPLY_PERIOD",
        value: 35
      },
      {
        id: 10,
        type: "NUMBER",
        key: "QUOTE_DURATION",
        value: 14
      },
      {
        id: 13,
        type: "NUMBER",
        key: "REQUOTE_DURATION",
        value: 14
      },
      {
        id: 11,
        type: "NUMBER",
        key: "QUOTE_RESPONSE",
        value: 14
      },
      {
        id: 14,
        type: "NUMBER",
        key: "REQUOTE_RESPONSE",
        value: 14
      },
      {
        id: 12,
        type: "NUMBER",
        key: "VARIATION_RESPONSE",
        value: 7
      },
      {
        id: 18,
        type: "TEXT",
        key: "COMPLETION_DATE",
        value: "Delivery Date"
      },
      {
        id: 20,
        type: "NUMBER",
        key: "QUOTE_DURATION_PRIOR_REPLY",
        value: 7
      }
    ],
    workflowGroups: [
      {
        id: 101,
        title: "Early Warnings",
        workflows: [
          {
            id: 1,
            title: "$PM_NAME Early Warnings",
            notificationName: "Early Warning",
            abbreviation: "$PM_ABBREVIATION-Warning-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "PM",
            replyingParty: "SUPPLIER",
            actioningParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              actionTab: {
                actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 2,
            title: "$SUPPLIER_NAME Early Warnings",
            notificationName: "Early Warning",
            abbreviation: "$SUPPLIER_ABBREVIATION-Warning-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "PM_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            actioningParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              actionTab: {
                actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
            }
          },
          {
            id: 3,
            title: "Early Warning Register",
            notificationName: "Early Warning",
            abbreviation: "Warning",
            userFilters: {
              accessFilter: "null"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "MeetingRequiredLabel",
                display: "Is Early Warning Meeting required?"
              },
              {
                id: 2,
                isActive: true,
                field: "NotificationClause",
                display: "You are given early warning of a matter which could"
              },
              {
                id: 3,
                isActive: true,
                field: "RiskItemsLabel",
                display: "You are given early warning of a matter which could"
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Pm",
                display: "$PM_ABBREVIATION Early Warnings awaiting reply"
              },
              {
                id: 2,
                isActive: true,
                field: "Supplier",
                display: "$SUPPLIER_NAME Early Warnings awaiting reply"
              }
            ]
          },
          {
            replacementKey: "RiskTypes",
            values: [
              {
                id: 1,
                isActive: true,
                field: "riskincreaseprice",
                display: "Increase the total of the Prices"
              },
              {
                id: 2,
                isActive: true,
                field: "riskservicetiming",
                display: "Interfere with the timing of the supply"
              },
              {
                id: 4,
                isActive: true,
                field: "riskserviceperformance",
                display: "Impair the performance of the goods in use"
              },
              {
                id: 5,
                isActive: false,
                field: "riskincreasecontractorcost",
                display: "Increase the $SUPPLIER_NAME's cost"
              },
              {
                id: 9,
                isActive: false,
                field: "riskaffectmodel",
                display: "Adversely affect the creation or use of the Information Model"
              },
              {
                id: 10,
                isActive: false,
                field: "riskotherpartner",
                display: "Affect the achievement of another Partner's objectives"
              },
              {
                id: 8,
                isActive: false,
                field: "riskcontractbreach",
                display: "Affect the achievement of another Partner’s objectives"
              },
              {
                id: 3,
                isActive: false,
                field: "riskdelaymilestone",
                display: "Delay meeting a Key Date"
              },
              {
                id: 6,
                isActive: false,
                field: "riskincreasedefinedcost",
                display: "Increase the Defined Cost of expending any Provisional Sums"
              },
              {
                id: 7,
                isActive: false,
                field: "riskaffectsafety",
                display: "Adversely affect the $SUPPLIER_NAME’s ability to safely carry out the works"
              }
            ]
          },
          {
            replacementKey: "MeetingOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "0",
                display: "Meeting Not Required"
              },
              {
                id: 2,
                isActive: true,
                field: "1",
                display: "Meeting Required - Next Scheduled Early Warning Meeting"
              },
              {
                id: 3,
                isActive: true,
                field: "2",
                display: "Meeting Required - You are instructed to attend a meeting as below"
              }
            ]
          },
          {
            replacementKey: "RiskTypesSupplier",
            values: [
              {
                id: 2,
                isActive: true,
                field: "riskincreaseprice",
                display: "Increase the total of the Prices"
              },
              {
                id: 3,
                isActive: true,
                field: "riskservicetiming",
                display: "Interfere with the timing of the supply"
              },
              {
                id: 5,
                isActive: true,
                field: "riskserviceperformance",
                display: "Impair the performance of the goods in use"
              },
              {
                id: 6,
                isActive: true,
                field: "riskincreasecontractorcost",
                display: "Increase the $SUPPLIER_NAME's total cost"
              },
              {
                id: 10,
                isActive: false,
                field: "riskaffectmodel",
                display: "Adversely affect the creation or use of the Information Model"
              },
              {
                id: 11,
                isActive: false,
                field: "riskotherpartner",
                display: "Affect the achievement of another Partner's objectives"
              },
              {
                id: 9,
                isActive: false,
                field: "riskcontractbreach",
                display: "Affect the achievement of another Partner’s objectives"
              },
              {
                id: 4,
                isActive: false,
                field: "riskdelaymilestone",
                display: "Delay meeting a Key Date"
              },
              {
                id: 7,
                isActive: false,
                field: "riskincreasedefinedcost",
                display: "Increase the Defined Cost of expending any Provisional Sums"
              },
              {
                id: 8,
                isActive: false,
                field: "riskaffectsafety",
                display: "Adversely affect the $SUPPLIER_NAME’s ability to safely carry out the works"
              }
            ]
          }
        ]
      },
      {
        id: 102,
        title: "Instructions",
        workflows: [
          {
            id: 4,
            title: "$PM_NAME Instruction",
            notificationName: "Instruction",
            abbreviation: "$PM_ABBREVIATION-Instruction-",
            notify: true,
            replyEnabled: false,
            assetFieldsEnabled: false,
            replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "PM",
            replyingParty: "SUPPLIER",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "InstructionTypes",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "14.1 to change the Goods Information"
              },
              {
                id: 3,
                isActive: true,
                field: "",
                display: "14.2 to change the Delivery Date"
              },
              {
                id: 11,
                isActive: true,
                field: "",
                display: "31.1 to stop or not start work"
              },
              {
                id: 18,
                isActive: true,
                field: "",
                display: "31.1 to start or restart work"
              },
              {
                id: 12,
                isActive: true,
                field: "",
                display: "43.2 to change the Good Information, the Prices and the Delivery Date in respect of an Accepted Defect"
              },
              {
                id: 13,
                isActive: true,
                field: "",
                display: "82.1 to not replace lost goods or not repair damaged goods"
              },
              {
                id: 17,
                isActive: true,
                field: "",
                display: "Other Instruction"
              }
            ]
          },
          {
            replacementKey: "Resources",
            values: []
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "PM",
                display: "$PM_NAME Instructions awaiting reply"
              },
              {
                id: 2,
                isActive: true,
                field: "Client",
                display: "$CLIENT_NAME Instructions awaiting reply"
              },
              {
                id: 3,
                isActive: true,
                field: "Supervisor",
                display: "$SUPERVISOR_NAME Instructions awaiting reply"
              }
            ]
          },
          {
            replacementKey: "ReplyOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Accepted",
                display: "Accepted"
              },
              {
                id: 2,
                isActive: true,
                field: "Accepted with comments",
                display: "Accepted with comments"
              },
              {
                id: 3,
                isActive: true,
                field: "Not Accepted",
                display: "Not Accepted"
              },
              {
                id: 4,
                isActive: true,
                field: "Reply Not Required",
                display: "Reply Not Required"
              }
            ]
          }
        ]
      },
      {
        id: 114,
        title: "Batch Orders",
        workflows: [
          {
            id: 62,
            title: "$PM_NAME Request For Quote",
            notificationName: "Request For Quote",
            abbreviation: "RFQ-",
            notify: true,
            financialLimitEnabled: false,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            quotingParty: "SUPPLIER",
            quoteReplyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              quoteMainNotice: {
                createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              quoteReplyNotice: {
                saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 38,
            title: "$PM_NAME Batch Orders",
            notificationName: "Batch Order",
            abbreviation: "Batch-",
            notify: true,
            financialLimitEnabled: false,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Note",
                display: "Your quote is accepted as set out below, or we make our own assessment as set out below and we notify as follows"
              },
              {
                id: 2,
                isActive: true,
                field: "DescriptionLabel",
                display: "Detailed description of the work in the batch"
              },
              {
                id: 3,
                isActive: true,
                field: "ItemsListLabel",
                display: "Items taken from price list"
              },
              {
                id: 4,
                isActive: true,
                field: "TotalListPriceLabel",
                display: "Price list total"
              },
              {
                id: 5,
                isActive: true,
                field: "CompensationDescriptionLabel",
                display: "Items assessed in the same way as a compensation event"
              },
              {
                id: 6,
                isActive: true,
                field: "TotalAssessedLabel",
                display: "Assessed list total"
              },
              {
                id: 7,
                isActive: true,
                field: "TotalPriceLabel",
                display: "Total of prices"
              },
              {
                id: 8,
                isActive: true,
                field: "StartDateLabel",
                display: "Start Date"
              },
              {
                id: 9,
                isActive: true,
                field: "CompletionDateLabel",
                display: "Delivery Date"
              },
              {
                id: 10,
                isActive: true,
                field: "DelayAmountLabel",
                display: "Amount of delay damages for the late completion of the batch"
              },
              {
                id: 11,
                isActive: true,
                field: "DelayPaymentPeriodLabel",
                display: "Delay damages period"
              },
              {
                id: 12,
                isActive: true,
                field: "WarningBanner",
                display: ""
              }
            ]
          },
          {
            replacementKey: "Fields",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Description",
                display: "Description of the goods in the batch"
              },
              {
                id: 2,
                isActive: true,
                field: "ItemsList",
                display: "Total quantity"
              },
              {
                id: 3,
                isActive: true,
                field: "TotalListPrice",
                display: "Total price"
              },
              {
                id: 4,
                isActive: false,
                field: "CompensationDescription",
                display: "Items assessed in the same way as a compensation event"
              },
              {
                id: 5,
                isActive: false,
                field: "TotalAssessed",
                display: "Assessed list total"
              },
              {
                id: 6,
                isActive: true,
                field: "TotalPrice",
                display: "Total of prices"
              },
              {
                id: 7,
                isActive: true,
                field: "StartDate",
                display: "Start Date"
              },
              {
                id: 8,
                isActive: true,
                field: "CompletionDate",
                display: "Delivery Date"
              },
              {
                id: 9,
                isActive: false,
                field: "DelayAmount",
                display: "Amount of delay damages for the late completion of the task"
              },
              {
                id: 10,
                isActive: false,
                field: "DelayPaymentPeriod",
                display: "Payment period"
              }
            ]
          },
          {
            replacementKey: "RFQRelationEnabled",
            values: [
              {
                id: 1,
                isActive: true,
                field: "RFQRelationEnabled",
                display: "Active-Inactive state indicates weather the RFQ can be attached in Task Order communications"
              }
            ]
          },
          {
            replacementKey: "RFQStatuses",
            values: [
              {
                id: 1,
                isActive: true,
                field: "APMA",
                display: "Quote Not Accepted - $PM_ABBREVIATION to make own Assessment"
              },
              {
                id: 2,
                isActive: true,
                field: "ARQ",
                display: "Quote Not Accepted - $SUPPLIER_NAME to resubmit"
              },
              {
                id: 3,
                isActive: true,
                field: "AM",
                display: "$PM_ABBREVIATION Assessment Made"
              },
              {
                id: 4,
                isActive: true,
                field: "QA",
                display: "Quote Accepted"
              },
              {
                id: 5,
                isActive: true,
                field: "APMRQ",
                display: "Awaiting $PM_ABBREVIATION Response to quote"
              },
              {
                id: 6,
                isActive: true,
                field: "APMR",
                display: "Awaiting $PM_ABBREVIATION Response to CEN"
              },
              {
                id: 7,
                isActive: true,
                field: "AQ",
                display: "Awaiting Quote"
              },
              {
                id: 8,
                isActive: true,
                field: "NC",
                display: "Not Communicated"
              },
              {
                id: 9,
                isActive: true,
                field: "R",
                display: "Not a Compensation Event"
              },
              {
                id: 10,
                isActive: true,
                field: "DNG",
                display: "Decision Not Given"
              }
            ]
          },
          {
            replacementKey: "QuoteStatuses",
            values: [
              {
                id: 1,
                isActive: true,
                field: "0",
                display: "Not Accepted - $SUPPLIER_NAME to resubmit"
              },
              {
                id: 2,
                isActive: true,
                field: "1",
                display: "Accepted"
              },
              {
                id: 3,
                isActive: true,
                field: "2",
                display: "Awaiting Response"
              },
              {
                id: 4,
                isActive: true,
                field: "3",
                display: "Not Accepted - $PM_ABBREVIATION to make assessment"
              },
              {
                id: 5,
                isActive: true,
                field: "4",
                display: "Not Accepted - Other quote approved"
              },
              {
                id: 6,
                isActive: true,
                field: "5",
                display: "Not Accepted - Decision will not be given"
              },
              {
                id: 7,
                isActive: true,
                field: "none",
                display: "Not Communicated"
              }
            ]
          },
          {
            replacementKey: "QuoteRfqImpactOpts",
            values: [
              {
                id: 1,
                isActive: true,
                field: "noeffect",
                display: "There is no effect on the Accepted Programme"
              },
              {
                id: 2,
                isActive: true,
                field: "mitigated",
                display: "There is an effect on the Accepted Programme but this has been mitigated by measures as detailed"
              },
              {
                id: 3,
                isActive: true,
                field: "notmitigated",
                display: "There is an effect on the Accepted Programme which we are unable to mitigate"
              }
            ]
          },
          {
            replacementKey: "RfqReplyProceedEnabled",
            values: [
              {
                id: 1,
                isActive: true,
                field: "RfqReplyProceedEnabled",
                display: "RFQ Quote Reply field proceed options Enabled/Disabled"
              }
            ]
          },
          {
            replacementKey: "QuoteFields",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Description",
                display: "Description of the goods in the batch"
              },
              {
                id: 2,
                isActive: true,
                field: "ItemsList",
                display: "Total quantity"
              },
              {
                id: 3,
                isActive: true,
                field: "TotalListPrice",
                display: "Total price"
              },
              {
                id: 4,
                isActive: false,
                field: "TotalAssessed",
                display: "Assessed list total"
              },
              {
                id: 5,
                isActive: true,
                field: "TotalPrice",
                display: "Total of prices"
              },
              {
                id: 6,
                isActive: true,
                field: "StartDate",
                display: "Start date"
              },
              {
                id: 7,
                isActive: true,
                field: "CompletionDate",
                display: "Delivery date"
              },
              {
                id: 8,
                isActive: false,
                field: "DelayAmount",
                display: "Amount of delay damages for the late completion of the task"
              },
              {
                id: 9,
                isActive: false,
                field: "DelayPaymentPeriod",
                display: "Payment period"
              }
            ]
          },
          {
            replacementKey: "UserLimitCheckEnabled",
            values: [
              {
                id: 1,
                isActive: false,
                field: "UserLimitCheckEnabled",
                display: "Active-Inactive state indicates weather the user limit check can be applied in Task Order communications"
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "PmRfqAQ",
                display: "Rfq awaiting quotes"
              },
              {
                id: 2,
                isActive: true,
                field: "PmRfqARQ",
                display: "Rfq awaiting revised quotes"
              },
              {
                id: 3,
                isActive: true,
                field: "PmRfqAPMRQ",
                display: "Rfq awaiting response to quotes"
              },
              {
                id: 4,
                isActive: true,
                field: "PmRfqAPMA",
                display: "Rfq awaiting $PM_ABBREVIATION Assessment"
              },
              {
                id: 5,
                isActive: true,
                field: "replydraftpmcequotessupplier",
                display: "$SUPPLIER_NAME CEN Quotation"
              },
              {
                id: 6,
                isActive: true,
                field: "draftconrfqquotation",
                display: "RFQ Quotation"
              }
            ]
          }
        ]
      },
      {
        id: 103,
        title: "Compensation Events",
        workflows: [
          {
            id: 6,
            title: "$PM_NAME Proposed Instructions",
            notificationName: "Proposed Instruction",
            abbreviation: "$PM_ABBREVIATION-PROP-",
            notify: true,
            financialLimitEnabled: false,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            quotingParty: "SUPPLIER",
            quoteReplyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              quoteMainNotice: {
                createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              quoteReplyNotice: {
                saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 7,
            title: "$SUPPLIER_NAME Compensation Events",
            notificationName: "Compensation Event",
            abbreviation: "$SUPPLIER_ABBREVIATION-CEN-",
            notify: true,
            replyEnabled: true,
            financialLimitEnabled: false,
            assetFieldsEnabled: false,
            replyPeriodOption: "VARIATION_RESPONSE",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            quotingParty: "SUPPLIER",
            quoteReplyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              quoteMainNotice: {
                createQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateQuoteFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              quoteReplyNotice: {
                saveQuoteDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateQuoteReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
            }
          },
          {
            id: 8,
            title: "$PM_NAME Assessments",
            notificationName: "Assessment",
            abbreviation: "$PM_ABBREVIATION-Assessment-",
            notify: true,
            financialLimitEnabled: false,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
            }
          },
          {
            id: 11,
            title: "Master Quote Register",
            userFilters: {
              accessFilter: "null"
            }
          },
          {
            id: 12,
            title: "Master CE Register",
            userFilters: {
              accessFilter: "null"
            }
          },
          {
            id: 13,
            title: "Implemented CE Register",
            userFilters: {
              accessFilter: "null"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "PmAssumptionLabel",
                display: "The quotation is to be based on the following assumptions"
              },
              {
                id: 2,
                isActive: true,
                field: "PmAssumptionNote",
                display: "You are instructed to provide a quotation by $date"
              },
              {
                id: 3,
                isActive: true,
                field: "SupplierDescNote",
                display: "You are notified of the following event as a compensation event"
              },
              {
                id: 4,
                isActive: true,
                field: "ReplyAcceptedCommentLabel",
                display: "You are notified that the event is a compensation event"
              },
              {
                id: 5,
                isActive: true,
                field: "ReplyRejectedCommentLabel",
                display: "Details"
              },
              {
                id: 6,
                isActive: true,
                field: "ReplyAcceptedAssumptionLabel",
                display: "The quotation is to be based on the following assumptions"
              },
              {
                id: 7,
                isActive: true,
                field: "QuoteNote",
                display: "We submit our quotation for compensation event $reference"
              },
              {
                id: 8,
                isActive: true,
                field: "ExtensionPMLabel",
                display: "We require an extension to the time to reply as follows"
              },
              {
                id: 9,
                isActive: true,
                field: "ExtensionSupplierLabel",
                display: "We require an extension to the time to reply as follows"
              },
              {
                id: 10,
                isActive: true,
                field: "AssessmentDetailLabel",
                display: "Details"
              },
              {
                id: 11,
                isActive: true,
                field: "ProposedInstructionNote",
                display: "You are instructed to provide a quotation by $date"
              },
              {
                id: 12,
                isActive: true,
                field: "ProposedInstructionLabel",
                display: "The quotation is to be based on the following assumptions"
              },
              {
                id: 13,
                isActive: true,
                field: "ReplyRejectedOptionsLabel",
                display: "You are notified that the event is not a compensation event as it"
              },
              {
                id: 14,
                isActive: true,
                field: "QuoteCostEffectLabel",
                display: "Change to the Prices"
              },
              {
                id: 15,
                isActive: true,
                field: "QuoteDateEffectLabel",
                display: "Delay"
              },
              {
                id: 16,
                isActive: true,
                field: "QuoteDaysEffectLabel",
                display: "The length of time that the $COMPLETION_DATE is delayed"
              },
              {
                id: 17,
                isActive: true,
                field: "AssessmentCostEffectLabel",
                display: "Change in the Total of the Prices"
              },
              {
                id: 18,
                isActive: true,
                field: "AssessmentDaysEffectLabel",
                display: "The length of time that the $COMPLETION_DATE is delayed"
              },
              {
                id: 19,
                isActive: true,
                field: "QuoteProgrammeAttachLabel",
                display: "Are you attaching a programme or plan showing the effect against the last programme or plan with this quotation?"
              },
              {
                id: 20,
                isActive: true,
                field: "QuoteNoteProposedInstruction",
                display: "We submit our quotation for proposed compensation event $reference"
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "PmCompensationAQ",
                display: "Compensation Events notified by $PM_ABBREVIATION awaiting quotes"
              },
              {
                id: 2,
                isActive: true,
                field: "PmCompensationARQ",
                display: "Compensation Events notified by $PM_ABBREVIATION awaiting revised quotes"
              },
              {
                id: 3,
                isActive: true,
                field: "PmCompensationAPMRQ",
                display: "Compensation Events notified by $PM_ABBREVIATION awaiting response to quotes"
              },
              {
                id: 4,
                isActive: true,
                field: "PmCompensationAPMA",
                display: "Compensation Events notified by $PM_ABBREVIATION awaiting $PM_ABBREVIATION Assessment"
              },
              {
                id: 5,
                isActive: true,
                field: "SupCompensationAQ",
                display: "Compensation Events notified by $SUPPLIER_NAME awaiting quotes"
              },
              {
                id: 6,
                isActive: true,
                field: "SupCompensationARQ",
                display: "Compensation Events notified by $SUPPLIER_NAME awaiting revised quotes"
              },
              {
                id: 7,
                isActive: true,
                field: "SupCompensationAPMR",
                display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION response"
              },
              {
                id: 8,
                isActive: true,
                field: "SupCompensationAPMRQ",
                display: "Compensation Events notified by $SUPPLIER_NAME awaiting response to quotes"
              },
              {
                id: 9,
                isActive: true,
                field: "SupCompensationAPMA",
                display: "Compensation Events notified by $SUPPLIER_NAME awaiting $PM_ABBREVIATION Assessment"
              },
              {
                id: 10,
                isActive: true,
                field: "PmProposedAQ",
                display: "Proposed changes awaiting quotes"
              },
              {
                id: 11,
                isActive: true,
                field: "PmProposedARQ",
                display: "Proposed changes awaiting revised quotes"
              },
              {
                id: 12,
                isActive: true,
                field: "PmProposedAPMRQ",
                display: "Proposed changes awaiting response to quotes"
              },
              {
                id: 13,
                isActive: true,
                field: "PmProposedAPMA",
                display: "Proposed changes awaiting $PM_ABBREVIATION Assessment"
              },
              {
                id: 14,
                isActive: true,
                field: "PmExtension",
                display: "$PM_ABBREVIATION Quote Response Extensions awaiting Acceptance"
              },
              {
                id: 15,
                isActive: true,
                field: "SupExtension",
                display: "$SUPPLIER_NAME Quote Extensions awaiting Acceptance"
              }
            ]
          },
          {
            replacementKey: "CompensationClauses",
            values: [
              {
                id: 29,
                isActive: true,
                field: "",
                display: "(1) The Purchaser gives an instruction changing the Goods Information unless the change is in order to make a Defect acceptable."
              },
              {
                id: 30,
                isActive: true,
                field: "",
                display: "(2) The Purchaser gives an instruction changing the Delivery Date."
              },
              {
                id: 31,
                isActive: true,
                field: "",
                display: "(3) The Purchaser does not allow access to and use of the premises as necessary for the work included in this contract."
              },
              {
                id: 32,
                isActive: true,
                field: "",
                display: "(4) The Purchaser does not provide services and other things which he is to provide as stated in this contract."
              },
              {
                id: 33,
                isActive: true,
                field: "",
                display: "(5) The Purchaser gives an instruction to stop or not to start any work."
              },
              {
                id: 34,
                isActive: true,
                field: "",
                display: "(6) The Purchaser does not reply to a communication from the Supplier within the period required by this contract."
              },
              {
                id: 35,
                isActive: true,
                field: "",
                display: "(7) The Purchaser changes a decision which he has previously communicated to the Supplier."
              },
              {
                id: 36,
                isActive: true,
                field: "",
                display: "(8) An event which is a Purchaser’s risk stated in this contract."
              },
              {
                id: 25,
                isActive: true,
                field: "",
                display: "23.1 An item of goods required by the Batch Order is not included in the Price Schedule."
              },
              {
                id: 26,
                isActive: true,
                field: "",
                display: "23.1 The total quantity of goods in the Batch Orders issued under this contract is different from that stated in the Price Schedule."
              },
              {
                id: 27,
                isActive: true,
                field: "",
                display: "23.1 The quantity of goods stated in a Batch Order is not within the range stated in the Contract Data."
              }
            ]
          },
          {
            replacementKey: "ReplyRejectOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "faultpscp",
                display: "arises from a fault of the $SUPPLIER_NAME"
              },
              {
                id: 2,
                isActive: true,
                field: "notexpectnothappen",
                display: "has not happened and is not expected to happen"
              },
              {
                id: 3,
                isActive: true,
                field: "noteffectcostcompletion",
                display: "has no effect on Defined Cost or upon Delivery"
              },
              {
                id: 4,
                isActive: true,
                field: "statedcontract",
                display: "is not one of the compensation events stated in this contract"
              }
            ]
          },
          {
            replacementKey: "CompensationStatuses",
            values: [
              {
                id: 1,
                isActive: true,
                field: "APMA",
                display: "Quote Not Accepted - $PM_ABBREVIATION to make own Assessment"
              },
              {
                id: 2,
                isActive: true,
                field: "ARQ",
                display: "Quote Not Accepted - $SUPPLIER_NAME to resubmit"
              },
              {
                id: 3,
                isActive: true,
                field: "AM",
                display: "$PM_ABBREVIATION Assessment Made"
              },
              {
                id: 4,
                isActive: true,
                field: "QA",
                display: "Quote Accepted"
              },
              {
                id: 5,
                isActive: true,
                field: "APMRQ",
                display: "Awaiting $PM_ABBREVIATION Response to quote"
              },
              {
                id: 6,
                isActive: true,
                field: "APMR",
                display: "Awaiting $PM_ABBREVIATION Response to CEN"
              },
              {
                id: 7,
                isActive: true,
                field: "AQ",
                display: "Awaiting Quote"
              },
              {
                id: 8,
                isActive: true,
                field: "NC",
                display: "Not Communicated"
              },
              {
                id: 9,
                isActive: true,
                field: "R",
                display: "Not a Compensation Event"
              },
              {
                id: 10,
                isActive: true,
                field: "DNG",
                display: "Decision Not Given"
              }
            ]
          },
          {
            replacementKey: "QuoteStatuses",
            values: [
              {
                id: 1,
                isActive: true,
                field: "0",
                display: "Not Accepted, $SUPPLIER_NAME to resubmit - proceed with works"
              },
              {
                id: 2,
                isActive: true,
                field: "1",
                display: "Accepted, proceed with works"
              },
              {
                id: 3,
                isActive: true,
                field: "2",
                display: "Awaiting Response"
              },
              {
                id: 4,
                isActive: true,
                field: "3",
                display: "Not Accepted, $PM_ABBREVIATION to assess - proceed with works"
              },
              {
                id: 5,
                isActive: true,
                field: "4",
                display: "Not Accepted, Other quote approved"
              },
              {
                id: 6,
                isActive: true,
                field: "5",
                display: "Not Accepted, instruction will not be given - do not proceed with works"
              },
              {
                id: 7,
                isActive: true,
                field: "none",
                display: "Not Communicated"
              }
            ]
          },
          {
            replacementKey: "InstructionReplyProceedOpts",
            values: [
              {
                id: 1,
                isActive: true,
                field: "61.1",
                display: "$SUPPLIER_NAME is instructed to proceed with works"
              },
              {
                id: 2,
                isActive: true,
                field: "61.2",
                display: "$SUPPLIER_NAME is instructed to NOT proceed with works"
              }
            ]
          },
          {
            replacementKey: "QuoteProgrammeImpactOpts",
            values: [
              {
                id: 1,
                isActive: true,
                field: "noeffect",
                display: "There is no effect on the Programme or Delivery Date"
              },
              {
                id: 2,
                isActive: true,
                field: "mitigated",
                display: "There is an effect on the Programme or Delivery Date but this has been mitigated by measures as detailed"
              },
              {
                id: 3,
                isActive: true,
                field: "notmitigated",
                display: "There is an effect on the Programme or Delivery Date which we are unable to mitigate"
              }
            ]
          },
          {
            replacementKey: "TaskOrderOptionEnabled",
            values: [
              {
                id: 1,
                isActive: true,
                field: "TaskOrderOptionEnabled",
                display: "Active-Inactive state indicates weather Task-Order can be selected in CEN communications"
              }
            ]
          },
          {
            replacementKey: "Fields",
            values: [
              {
                id: 1,
                isActive: false,
                field: "Assumption",
                display: "The quotation is to be based on the following assumptions"
              }
            ]
          },
          {
            replacementKey: "EWRelationEnabled",
            values: [
              {
                id: 1,
                isActive: true,
                field: "EWRelationEnabled",
                display: "Active-Inactive state indicates weather the EWN can be attached in CEN communications"
              }
            ]
          },
          {
            replacementKey: "ProposedInstructionClauses",
            values: []
          }
        ]
      },
      {
        id: 104,
        title: "$SUPPLIER_NAME Programme",
        workflows: [
          {
            id: 14,
            title: "$SUPPLIER_NAME Programme",
            notificationName: "Programme",
            abbreviation: "$SUPPLIER_ABBREVIATION-Prog-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "PROGRAMME_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "ReasonLabel",
                display: "Reason for any changes"
              },
              {
                id: 2,
                isActive: true,
                field: "ChangeLabel",
                display: "Change in any other important milestone in period and reasons why"
              },
              {
                id: 3,
                isActive: true,
                field: "MainProgressLabel",
                display: "Main progress achieved in period"
              },
              {
                id: 4,
                isActive: true,
                field: "KeyActivitiesLabel",
                display: "Key activities forecast before the next submission"
              },
              {
                id: 5,
                isActive: true,
                field: "SummaryLabel",
                display: "Summary of critical path"
              },
              {
                id: 6,
                isActive: true,
                field: "ChangesLabel",
                display: "Main changes made in period"
              },
              {
                id: 7,
                isActive: true,
                field: "CompensationLabel",
                display: "Compensation events added this period"
              },
              {
                id: 8,
                isActive: true,
                field: "NotificationClause",
                display: "Please find attached Programme for acceptance"
              },
              {
                id: 9,
                isActive: true,
                field: "ReplyRejectReasonLabel",
                display: "The $SUPPLIER_NAME’s Programme is not accepted for the following reasons"
              },
              {
                id: 10,
                isActive: true,
                field: "ReplyNotificationClause",
                display: "In accordance with the conditions of contract you are notified that the $SUPPLIER_NAME’s Programme"
              },
              {
                id: 11,
                isActive: true,
                field: "ReplyNotificationClause",
                display: "You are notified that the $SUPPLIER_NAME’s Programme"
              },
              {
                id: 12,
                isActive: true,
                field: "ReferenceLabel",
                display: "Programme Reference"
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Supplier",
                display: "$SUPPLIER_NAME's Plan/Programme Submission awaiting Acceptance"
              }
            ]
          },
          {
            replacementKey: "ReplyRejectOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "plansnotpractical",
                display: "The $SUPPLIER_NAME’s plans which it shows are not practical"
              },
              {
                id: 2,
                isActive: true,
                field: "missingcontractrequires",
                display: "It does not show the information which this contract requires"
              },
              {
                id: 3,
                isActive: true,
                field: "notrepresentplan",
                display: "It does not represent the $SUPPLIER_NAME’s plans realistically"
              },
              {
                id: 4,
                isActive: true,
                field: "notcomply",
                display: "It does not comply with the Scope"
              }
            ]
          },
          {
            replacementKey: "DashboardDisplayPlannedCompletionOnLatest",
            values: [
              {
                id: 1,
                isActive: false,
                field: "DashboardDisplayPlannedCompletionOnLatest",
                display: "Active-Inactive state indicates weather (Planned Completion shown on the latest Accepted Programme) is displayed on dasboard"
              }
            ]
          },
          {
            replacementKey: "Fields",
            values: [
              {
                id: 1,
                isActive: false,
                field: "PreviousPlanned",
                display: "Previous Planned Completion"
              },
              {
                id: 2,
                isActive: false,
                field: "PlannedCompletion",
                display: "Date of Planned Completion this period"
              },
              {
                id: 8,
                isActive: false,
                field: "Changes",
                display: "Main changes made in period"
              },
              {
                id: 3,
                isActive: true,
                field: "Reason",
                display: "Reason for any changes since last period"
              },
              {
                id: 4,
                isActive: false,
                field: "Change",
                display: "Change in any other important project milestone in period and reasons why"
              },
              {
                id: 5,
                isActive: true,
                field: "MainProgress",
                display: "Main progress achieved in period"
              },
              {
                id: 6,
                isActive: false,
                field: "KeyActivities",
                display: "Key activities forecast in next reporting period"
              },
              {
                id: 7,
                isActive: false,
                field: "Summary",
                display: "Summary of critical path"
              },
              {
                id: 9,
                isActive: true,
                field: "Compensation",
                display: "Compensation events added to programme this period"
              },
              {
                id: 10,
                isActive: false,
                field: "",
                display: ""
              }
            ]
          }
        ]
      },
      {
        id: 105,
        title: "Notifications",
        workflows: [
          {
            id: 15,
            title: "$PM_NAME Notifications",
            notificationName: "Notification",
            abbreviation: "$PM_ABBREVIATION-Notification-",
            notify: true,
            replyEnabled: false,
            assetFieldsEnabled: false,
            replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "PM",
            replyingParty: "SUPPLIER",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 16,
            title: "$SUPPLIER_NAME Notifications",
            notificationName: "Notification",
            abbreviation: "$SUPPLIER_ABBREVIATION-Notification-",
            notify: true,
            replyEnabled: false,
            assetFieldsEnabled: false,
            replyPeriodOption: "PM_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"PM\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "PmClauses",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "13.1 of a change of address for communications",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 35,
                isActive: true,
                field: "",
                display: "23.1 of an agreement to extend or reduce the batch order interval",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 37,
                isActive: true,
                field: "",
                display: "85.1 of a request for insurance certificates",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 36,
                isActive: true,
                field: "",
                display: "85.1 of insurance certificates",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 30,
                isActive: true,
                field: "",
                display: "90.1 of a wish to terminate",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 32,
                isActive: true,
                field: "",
                display: "90.1 of a Termination Certificate",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 33,
                isActive: true,
                field: "",
                display: "90.3 of $SUPPLIER_NAME default",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 31,
                isActive: true,
                field: "",
                display: "Other notification",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              }
            ]
          },
          {
            replacementKey: "SupplierClauses",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "13.1 of a change of address for communications",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 9,
                isActive: true,
                field: "",
                display: "85.1 of a request for insurance certificates",
                applyReplyPeriod: false,
                noReply: false,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 8,
                isActive: true,
                field: "",
                display: "85.1 of insurance certificates",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 3,
                isActive: true,
                field: "",
                display: "90.1 of a wish to terminate",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              },
              {
                id: 7,
                isActive: true,
                field: "",
                display: "Other notification",
                applyReplyPeriod: false,
                noReply: true,
                overrideReplyingParty: false,
                deemed: false
              }
            ]
          }
        ]
      },
      {
        id: 106,
        title: "Submissions",
        workflows: [
          {
            id: 49,
            title: "$PM_NAME Submissions",
            notificationName: "Submission",
            abbreviation: "$PM_ABBREVIATION-Submission-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "PM",
            replyingParty: "SUPPLIER",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 18,
            title: "$SUPPLIER_NAME Submissions",
            notificationName: "Submission",
            abbreviation: "$SUPPLIER_ABBREVIATION-Submission-",
            notify: true,
            replyEnabled: true,
            assetFieldsEnabled: false,
            replyPeriodOption: "PM_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "IssueClauses",
            values: [
              {
                id: 11,
                isActive: true,
                field: "",
                display: "43.1 of a proposal to accept a Defect",
                applyReplyPeriod: false,
                noReply: false
              },
              {
                id: 25,
                isActive: true,
                field: "",
                display: "Other submission",
                applyReplyPeriod: false,
                noReply: false
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Supplier",
                display: "$SUPPLIER_NAME Submission awaiting Acceptance"
              }
            ]
          },
          {
            replacementKey: "IssueClausesPM",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "43.1 a proposal to accept a Defect",
                applyReplyPeriod: false,
                noReply: false
              },
              {
                id: 3,
                isActive: true,
                field: "",
                display: "Other submission",
                applyReplyPeriod: false,
                noReply: false
              }
            ]
          }
        ]
      },
      {
        id: 107,
        title: "$SUPPLIER_NAME Quotations for accepting a Defect",
        workflows: [
          {
            id: 19,
            title: "$SUPPLIER_NAME Submissions (wq)",
            notificationName: "Quotation to accept a Defect",
            abbreviation: "$SUPPLIER-ABBREVIATION-Defect-Quote-",
            notify: true,
            replyEnabled: true,
            financialLimitEnabled: false,
            assetFieldsEnabled: false,
            replyPeriodOption: "PM_REPLY_PERIOD",
            replyPeriod: 0,
            notifyingParty: "SUPPLIER",
            replyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              replyNotice: {
                saveDraftReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Support\"],\"or\",[\"rolepermissions\",\"=\",\"Manager\"]]]",
                communicateReplyFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "CostEffectLabel",
                display: "Change in the Prices"
              },
              {
                id: 2,
                isActive: true,
                field: "DaysEffectLabel",
                display: "Delay to Delivery Date"
              },
              {
                id: 3,
                isActive: true,
                field: "WarningBanner",
                display: "Do not use this workflow for a Batch Order quotation."
              }
            ]
          },
          {
            replacementKey: "ProposalClauses",
            values: [
              {
                id: 2,
                isActive: true,
                field: "",
                display: "43.2 a quotation to accept a Defect"
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Supplier",
                display: "$SUPPLIER_NAME Proposals awaiting Acceptance"
              }
            ]
          },
          {
            replacementKey: "ReplyOptions",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Accepted",
                display: "Accepted"
              },
              {
                id: 2,
                isActive: true,
                field: "Not Accepted",
                display: "Not Accepted"
              }
            ]
          },
          {
            replacementKey: "UserLimitCheckEnabled",
            values: [
              {
                id: 1,
                isActive: false,
                field: "UserLimitCheckEnabled",
                display: "Active-Inactive state indicates weather the user limit check can be applied in Proposal communications"
              }
            ]
          }
        ]
      },
      {
        id: 108,
        title: "Payments",
        workflows: [
          {
            id: 21,
            title: "$SUPPLIER_NAME Payment Applications",
            notificationName: "Payment Application",
            abbreviation: "$SUPPLIER_ABBREVIATION-Payment-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "SUPPLIER",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"Contractor\"]",
                communicateFilter: "[[\"party\",\"=\",\"Contractor\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 20,
            title: "$PM_NAME Payment Notifications",
            notificationName: "Payment Certificate",
            abbreviation: "$PM_ABBREVIATION-Payment-",
            notify: true,
            financialLimitEnabled: false,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "CumulativePriceLabel",
                display: "Price for goods delivered to date"
              },
              {
                id: 2,
                isActive: true,
                field: "PlusAmountsSupplierLabel",
                display: "Plus other amounts to be paid to the $SUPPLIER_NAME"
              },
              {
                id: 3,
                isActive: true,
                field: "LessAmountsSupplierLabel",
                display: "Less amounts to be paid by or retained from the $SUPPLIER_NAME"
              },
              {
                id: 4,
                isActive: true,
                field: "TaxSupplierLabel",
                display: "Tax which the law requires the Employer to pay to the $SUPPLIER_NAME"
              },
              {
                id: 5,
                isActive: true,
                field: "WarningBanner",
                display: "The Supplier assesses the amount due and, by each assessment day, applies to the Purchaser for payment. There is an assessment day in each month from the starting date until the month after the defects date."
              },
              {
                id: 6,
                isActive: true,
                field: "SummaryDetailsLabel",
                display: "Summary details"
              }
            ]
          },
          {
            replacementKey: "TaxEnabled",
            values: [
              {
                id: 1,
                isActive: true,
                field: "TaxEnabled",
                display: "Active-Inactive state indicates tax field will be included in notification forms"
              }
            ]
          }
        ]
      },
      {
        id: 109,
        title: "Defects",
        workflows: [
          {
            id: 23,
            title: "$PM_NAME Defect Notifications",
            notificationName: "Defect Notification",
            abbreviation: "Defect-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            actioningParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              actionTab: {
                actionFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"]]"
            }
          },
          {
            id: 26,
            title: "Master Defect Register",
            notificationName: "Defect Notification",
            abbreviation: "Defect",
            userFilters: {
              accessFilter: "null"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "InstructionClauseLabel",
                display: "You are instructed to search for a Defect as follows"
              },
              {
                id: 2,
                isActive: true,
                field: "SupervisorClauseLabel",
                display: "You are notified of the following Defect"
              },
              {
                id: 3,
                isActive: true,
                field: "SupplierClauseLabel",
                display: "You are notified of the following Defect"
              },
              {
                id: 4,
                isActive: true,
                field: "TestsClauseLabel",
                display: "You are notified of the following test or inspection"
              },
              {
                id: 5,
                isActive: true,
                field: "DefectsWarningBanner",
                display: "A Defect is not notified after the end of the service period."
              }
            ]
          },
          {
            replacementKey: "ActionReminders",
            values: [
              {
                id: 1,
                isActive: true,
                field: "Instruction",
                display: "$PM_NAME Instruction to Search awaiting sign off"
              },
              {
                id: 2,
                isActive: true,
                field: "SuperDefect",
                display: "$PM_NAME Notification of Defect awaiting acceptance"
              },
              {
                id: 3,
                isActive: true,
                field: "Inspection",
                display: "$SUPPLIER_NAME Test/Inspection Notification awaiting sign off"
              }
            ]
          }
        ]
      },
      {
        id: 110,
        title: "Delegated Actions",
        workflows: [
          {
            id: 27,
            title: "$PM_NAME Delegated Actions",
            notificationName: "Delegated Action",
            abbreviation: "$PM_ABBREVIATION-Action-",
            notify: true,
            assetFieldsEnabled: false,
            notifyingParty: "PM",
            userFilters: {
              accessFilter: "null",
              mainNotice: {
                createFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[[\"rolepermissions\",\"=\",\"Manager\"],\"or\",[\"rolepermissions\",\"=\",\"Support\"]]]",
                viewDraftFilter: "[\"party\",\"=\",\"PM\"]",
                communicateFilter: "[[\"party\",\"=\",\"PM\"],\"and\",[\"rolepermissions\",\"=\",\"Manager\"]]"
              },
              notifyFilter: "[[\"party\",\"=\",\"PM\"],\"or\",[\"party\",\"=\",\"Contractor\"],\"or\",[\"party\",\"=\",\"Supervisor\"],\"or\",[\"party\",\"=\",\"Client\"]]"
            }
          }
        ],
        placeholders: [
          {
            replacementKey: "Resources",
            values: [
              {
                id: 1,
                isActive: true,
                field: "DelegationClause",
                display: "You are notified that the following actions"
              }
            ]
          }
        ]
      }
    ]
  };
  export default template;