import React from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import * as earlyWarningCrud from '../../../../crud/earlyWarning.crud';
import { injectIntl } from "react-intl";
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';

class WarningRiskAssessment extends React.Component {
    constructor(props) {
        super(props);

        this.riskOptions = [
            { value: "Very Low", effect: 1, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.Very Low' }) },
            { value: "Low", effect: 2, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.Low' }) },
            { value: "Medium", effect: 3, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.Medium' }) },
            { value: "High", effect: 4, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.High' }) },
            { value: "Very High", effect: 5, display: this.props.intl.formatMessage({ id: 'OPTIONS.RISK.Very High' }) },
        ];

        this.formConfig = {
            buttonResource: 'CONTRACT.WF.EW.BUTTON.UPDATE_RISK',
            fields: [
                { resource: 'CONTRACT.WF.EW.FIELD.LIKELIHOOD', dataField: 'likelihood', editorType: 'dxSelectBox', editorOptions: { dataSource: this.riskOptions, valueExpr: 'value', displayExpr: 'display' } },
                { resource: "CONTRACT.WF.EW.FIELD.SEVERITY", dataField: 'severity', editorType: 'dxSelectBox', editorOptions: { dataSource: this.riskOptions, valueExpr: 'value', displayExpr: 'display' } },
                { resource: "CONTRACT.WF.EW.FIELD.PRIORITY", dataField: 'reference', required: false, value: this.calculateRiskPriority, editorType: 'dxSelectBox', editorOptions: { dataSource: this.riskOptions, valueExpr: 'value', displayExpr: 'display', readOnly: true } },
            ]
        }
        
        if (this.props.riskStatusOptions !== null && this.props.riskStatusOptions !== undefined && this.props.riskStatusOptions.length > 0) {
            this.formConfig.fields.push({ label: this.props.resources.RiskStatusLabel, dataField: 'riskstatus', editorType: 'dxSelectBox', editorOptions: { dataSource: this.props.riskStatusOptions, valueExpr: 'display', displayExpr: 'display'} });
        }

        this.state = {
            warning: { ...props.warning }
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.warning !== this.props.warning) {
            this.setState({
                warning: { ...this.props.warning }
            });
        }
    }
    submitHandler = async (warning, setSubmitting) => {
        try {
            const earlyWarning = {
                warningid: this.props.warning.warningid,
                likelihood: warning.likelihood,
                severity: warning.severity,
                reference: warning.reference,
                riskstatus: warning.riskstatus,
                version: this.state.warning.version
            }

            await earlyWarningCrud.UpdateRisk(earlyWarning);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });
            //Inform parent about update
            this.props.onRiskAssessmentUpdate();
            setSubmitting(false);
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            setSubmitting(false);
        }
    }

    calculateRiskPriority = (obj) => {
        if (typeof obj.severity !== 'undefined' && obj.severity !== null && typeof obj.likelihood !== 'undefined' && obj.likelihood !== null) {
            const riskPoint = this.riskOptions.find(itm => itm.value === obj.severity).effect * this.riskOptions.find(itm => itm.value === obj.likelihood).effect;

            if (riskPoint >= 0 && riskPoint <= 4) {
                return 'Low';
            } else if (riskPoint >= 5 && riskPoint <= 10) {
                return 'Medium';
            } else if (riskPoint >= 11 && riskPoint <= 25) {
                return 'High';
            }
            return '';
        }
        else return null;
    }
    render() {
        return (
            <div style={{ margin: 20 }}>
                <BiDynamicForm
                    item={this.state.warning}
                    config={this.formConfig}
                    onSubmitHandler={this.submitHandler}
                />
            </div>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default injectIntl(withSnackbar(connect(mapStateToProps)(WarningRiskAssessment)));
