/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import Accordion from 'devextreme-react/accordion';
import { connect } from "react-redux";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import * as customFieldCrud from "../../../../crud/customField.crud";
import { withSnackbar } from 'notistack';
import * as companiesDuck from '../../../../store/ducks/companies.duck';
import { injectIntl } from "react-intl";
import AccordionItem from './CustomComponents/AccordionItem';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import Common from '../../../../shared/common';
import { Navigate } from 'react-router-dom';


const accordionConfig = [
    {
        objectname: "Workflows",
        title: "Workflows"
    },
    {
        objectname: "Contract",
        title: "Contract"
    },
    {
        objectname: "Company",
        title: "Company"
    },
    {
        objectname: "CompanyUser",
        title: "Company User"
    },
    {
        objectname: "Project",
        title: "Project"
    }
];

class CustomFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyid: null,
            companyFields: null,
            selectedItems: [],
            loading: false 

        };
        this.customFieldsEnabled = process.env.REACT_APP_CUSTOM_FIELDS_ENABLED === "true";
        this.customFieldsEnabledForClientRoles = process.env.REACT_APP_CLIENT_MANAGES_CUSTOM_FIELDS === "true";
        this.userHasAccess = false;
        const accounttype = this.props.userProfile.accounttype;


        if (this.customFieldsEnabled) {
      
            this.userHasAccess = true;

            if (!this.customFieldsEnabledForClientRoles && accounttype === "Site Administrator") {
              
                this.userHasAccess = false;
            }
          }

        this.companyFormConfig = {
            fields: [
                { label: "Select a Company", dataField: 'companyid', editorType: 'dxSelectBox', editorOptions: { dataSource: null, valueExpr: 'companyid', displayExpr: 'name' } },
            ]
        }
    }

    componentDidMount() {

        const { userHasAccess  } = this;
       

        if (userHasAccess){
            this.props.loadCompanies();
        }
        
    }
    onValueChanged = (field, value) => {
        this.setState({ companyid: value, companyFields: null }, () => {
            if (value) {
                this.setCompanyFields(value);
            }
        });


    };
    setCompanyFields = async (companyid) => {
        this.setState({ companyid: companyid, loading: !!companyid, companyFields: null }, async () => {
            if (!companyid) { return; }
            try {
                const reducedCompanyFields = await this.fetchCompanyFields(companyid);
                this.setState({ loading: false, companyid: companyid, companyFields: reducedCompanyFields });
            } catch (error) {
                this.props.enqueueSnackbar(error.toString(), { variant: "error" });
                this.setState({ loading: false, companyid: null, companyFields: null });
            }
        });
    }
    setCompanyFieldsSilent = async (companyid) => {
        if (!companyid) { return; }
        try {
            const reducedCompanyFields = await this.fetchCompanyFields(companyid);
            this.setState({ loading: false, companyid: companyid, companyFields: reducedCompanyFields });
        } catch (error) {
            this.props.enqueueSnackbar(error.toString(), { variant: "error" });
            this.setState({ loading: false, companyid: null, companyFields: null });
        }
    }

    fetchCompanyFields = async (companyid) => {
        const response = await customFieldCrud.getCustomFieldSettings(companyid);
        const reducedCompanyFields = response.data.reduce((obj, fieldGroup) => ({ ...obj, [fieldGroup.objectname]: fieldGroup.fields }), {});

        if (reducedCompanyFields.Workflows) {
            reducedCompanyFields.Workflows = Common.GroupBy(reducedCompanyFields.Workflows, 'workflowid');
            for (let workflowid in reducedCompanyFields.Workflows) {
                reducedCompanyFields.Workflows[workflowid] = Common.GroupBy(reducedCompanyFields.Workflows[workflowid], 'subtype');
            }
        }
        return reducedCompanyFields;
    }

    CustomTitle = (data) => {
        return (
            <div style={{ textAlign: "center" }}>
                <h5>{data.title}</h5>
            </div>

        );
    }
    selectionChanged = (e) => {
        // console.log(e);
        let newItems = [...this.state.selectedItems];
        e.removedItems.forEach((item) => {
            const index = newItems.indexOf(item);
            if (index >= 0) {
                newItems.splice(index, 1);
            }
        });
        if (e.addedItems.length) {
            newItems = [...newItems, ...e.addedItems];
        }
        this.setState({
            selectedItems: newItems,
        });
    }

    //IF FIELD LIST CHANGED ON CHILD COMPONENTS
    //SET THIS TO PARENT CONTROLLER STATE
    //JUST IN CASE COMPONENTS UNMOUNT & REMOUNT
    onFieldsUpdated = (newFieldList, objectname, workflowid, subtype) => {
        //SILENTLY SET STATE
        //DO NOT TRIGGER RE-RENDER
        if (workflowid) {
            if (typeof this.state.companyFields[objectname] === 'undefined') {
                this.state.companyFields[objectname] = {};
            }
            if (typeof this.state.companyFields[objectname][workflowid] === 'undefined') {
                this.state.companyFields[objectname][workflowid] = {};
            }
            this.state.companyFields[objectname][workflowid][subtype] = newFieldList;
        }
        else {
            this.state.companyFields[objectname] = newFieldList;
        }
    }
    onTriggerRefresh = (error) => {
        //IN CASE OF UN HANDLED ERROR IN CHILDREN
        //THIS FUNCTION MAY BE TRIGGERED TO RESTORE THE LATEST FROM DB
        this.setCompanyFields(this.state.companyid);
        this.props.enqueueSnackbar(error.toString(), { variant: "error" });
    }
    render() {
        
        if (!this.userHasAccess) {
            return <Navigate to="/contracts" />; 
        }
        if (!this.props.companies) {
            return <PortletSpinner />;
        }
        return (
            <Portlet>
                <PortletBody id="CustomFieldsPane">
                    <div >
                        <div style={{ display: 'flex', width: '100%', padding: '10px', justifyContent: "center" }}>
                            <SelectBox
                                dataSource={this.props.companies}
                                valueExpr="companyid"
                                displayExpr="name"
                                searchEnabled={true}
                                searchMode="contains"
                                searchExpr="name"
                                searchTimeout={200}
                                minSearchLength={0}
                                showDataBeforeSearch={true}
                                onValueChanged={(e) => { this.setCompanyFields(e.value); }}
                                placeholder="Select a Company"
                                value={this.state.companyid}
                                width={600}
                            />
                        </div>
                        <div style={{ height: 20 }} />
                        <Accordion
                            id="ObjectsAccordion"
                            dataSource={accordionConfig}
                            collapsible={true}
                            multiple={false}
                            animationDuration={1000}
                            onSelectionChanged={this.selectionChanged}
                            // itemTitleRender={this.CustomTitle}
                            itemRender={AccordionItem.bind(this, this.state.companyid, this.state.companyFields, this.onFieldsUpdated, this.onTriggerRefresh)}
                            selectedItems={this.state.selectedItems}
                            // onSelectionChanged={this.selectionChanged}
                            deferRendering={true}
                            noDataText="Select Company"
                        >
                        </Accordion>
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            position={{ of: '#CustomFieldsPane' }}
                            visible={this.state.loading}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                            hideOnOutsideClick={false}
                        />
                    </div>

                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = (store) => ({
    companies: store.companies.companies,
    userProfile: store.auth.userProfile,
});


const mapDispatchToProps = {
    loadCompanies: companiesDuck.actions.fetchStart,
}

export default injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CustomFields)));
