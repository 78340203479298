import React from 'react';
import DataGrid, { Column, SearchPanel, Export, StateStoring, ColumnChooser, ColumnFixing, Scrolling, HeaderFilter, FilterRow, FilterPanel, Summary, TotalItem, Toolbar, Item as ToolbarItem } from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import Common from '../../shared/common';
import saveAs from 'file-saver';
import { Link } from "react-router-dom";
import { withRouter } from '../../shared/hoc/withRouter';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html-react';
import { injectIntl } from "react-intl";
import { htmlSenitizeOptions } from '../../shared/config/DxFormProperties';
// const storageKey = "Dx" + process.env.REACT_APP_PRODUCT_KEY + process.env.REACT_APP_VERSION;

const amountHeaderFilter = JSON.stringify([{
    text: 'Less than 1,000',
    value: ['Amount', '<', 1000]
}, {
    text: '1,000 - 5,000',
    value: [
        ['Amount', '>=', 1000],
        ['Amount', '<', 5000]
    ]
}, {
    text: '5,000 - 10,000',
    value: [
        ['Amount', '>=', 5000],
        ['Amount', '<', 10000]
    ]
}, {
    text: '10,000 - 25,000',
    value: [
        ['Amount', '>=', 10000],
        ['Amount', '<', 25000]
    ]
}, {
    text: '25,000 - 100,000',
    value: [
        ['Amount', '>=', 25000],
        ['Amount', '<', 100000]
    ]
}, {
    text: 'Greater than 100,000',
    value: ['Amount', '>=', 100000]
}]);

const contractAmountHeaderFilter = JSON.stringify([{
    text: 'Less than 5,000',
    value: ['Amount', '<', 5000]
}, {
    text: '5,000 - 50,000',
    value: [
        ['Amount', '>=', 5000],
        ['Amount', '<', 50000]
    ]
}, {
    text: '50,000 - 250,000',
    value: [
        ['Amount', '>=', 50000],
        ['Amount', '<', 250000]
    ]
}, {
    text: '250,000 - 1,000,000',
    value: [
        ['Amount', '>=', 250000],
        ['Amount', '<', 1000000]
    ]
}, {
    text: '1,000,000 - 5,000,000',
    value: [
        ['Amount', '>=', 1000000],
        ['Amount', '<', 5000000]
    ]
}, {
    text: '5,000,000 - 25,000,000',
    value: [
        ['Amount', '>=', 5000000],
        ['Amount', '<', 25000000]
    ]
}, {
    text: 'Greater than 25,000,000',
    value: ['Amount', '>=', 25000000]
}]);

class DataGridBase extends React.Component {
    constructor(props) {
        super(props);
        this.dataGrid = props.gridRef ? props.gridRef : React.createRef();
        let referenceIDPrefix = props.referenceIDPrefix;
        if(!referenceIDPrefix && props.workflowItem){
            referenceIDPrefix = props.workflowItem.abbreviation;
        }
        this.minIDColumnWidth = Math.round(Common.getTextWidth(referenceIDPrefix + '10000', "bold 14pt Helvetica Neue"));


        // this.createPath = this.props.createPath;
        // this.canCreate = !!this.props.createPath;
        // if (this.props.workflowItem) {
        //     this.createPath = this.props.workflowItem.paths.createPath;
        //     // this.canCreate = this.props.workflowItem.userFilters.mainNotice.createAllowed;
        // }
        // this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        // this.onExporting = this.onExporting.bind(this);
    }
    componentDidMount() {
        if (this.dataGrid && this.dataGrid.current && this.dataGrid.current.instance && this.props.repaint) {
            //this.dataGrid.instance.clearFilter('search');

            this.timer = setTimeout(() => {
                // this.dataGrid.instance.pageIndex(0);
                this.dataGrid.current.instance.repaint();
                // this.dataGrid.instance.updateDimensions();
            }, 300);
        }

    }
    componentWillUnmount() {
        clearTimeout(this.timer);
    }
    // headerCellRender = cellInfo => {
    //     return (
    //         <h5 style={{ color: "black" }} className="kt-subheader__title">{cellInfo.column.caption}</h5>
    //     )
    // }
    onExporting = (e) => {
        e.component.beginUpdate();
        const hiddenColumns = [];
        this.props.columns.forEach(column => {
            const isVisible = e.component.columnOption(column.dataField, "visible");
            if (!isVisible) {
                hiddenColumns.push(column.dataField);
            }
        });

        hiddenColumns.forEach(columnField => {
            e.component.columnOption(columnField, "visible", true);
        });
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        const fileName = this.props.name;

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            customizeCell: (options) => {
                const { gridCell, excelCell } = options;
                if (gridCell.rowType === 'data') {
                    if (typeof gridCell.value === 'number' && Math.abs(gridCell.value) > 999999999) {

                        excelCell.numFmt = '#,##0';
                    }
                    if (gridCell.column.dataField === 'url') {
                        // https://github.com/exceljs/exceljs#hyperlink-value
                        excelCell.value = { text: 'Link', hyperlink: gridCell.value };
                        // https://github.com/exceljs/exceljs#fonts
                        excelCell.font = { color: { argb: 'FF0000FF' }, underline: true };
                        // https://github.com/exceljs/exceljs#alignment
                        excelCell.alignment = { horizontal: 'left', vertical: 'top' };
                    }
                }
            }
        }).then(() => {
            // https://github.com/exceljs/exceljs#writing-xlsx
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');
            });
        }).then(function () {
            hiddenColumns.forEach(columnField => {
                e.component.columnOption(columnField, "visible", false);
            });
            e.component.endUpdate();
        });

        e.cancel = true;
    }

    // onExported = (e) => {
    //     // if (this.exporting) {
    //     //     // e.component.columnOption("url", "visible", false);
    //     //     this.hiddenColumns.forEach(columnField => {
    //     //         e.component.columnOption(columnField, "visible", false);
    //     //     });
    //     //     this.hiddenColumns.forEach(columnField => {
    //     //         e.component.columnOption(columnField, "visible", false);
    //     //     });
    //     //     e.component.endUpdate();
    //     //     this.exporting = false;
    //     //     e.cancel = true;
    //     // }

    // }
    toolbarTitleRender = () => {
        if (this.props.title) {
            return (
                <div>
                    <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">{Common.CapitalFirstLetter(this.props.title)}</h3>
                </div>
            );
        }
        return null;

    }
    onRowPrepared = (e) => {
        e.rowElement.style.maxHeight = "50px";
    }
    navigateToCreate = () => {
        this.props.navigate(this.props.createPath);
    }
    onToolbarPreparing = (e) => {

        const navigateToCreate = () => {
            this.props.navigate(this.props.createPath);
        }
        const items = [];
        if (this.props.title) {
            items.push(
                {
                    location: 'before',
                    template: 'toolbarTitle'
                }
            );
        }
        if (this.props.createPath) {
            items.push(
                {
                    location: "after",
                    widget: "dxButton",
                    locateInMenu: 'auto',
                    options: {
                        icon: "plus",
                        text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CREATE_NEW" }),
                        onClick: function () {
                            navigateToCreate();
                        }
                    }
                }
            );
        }
        e.toolbarOptions.items.unshift(...items);
    }
    renderIdColumn = (cellInfo) => {
        const calculateURL = cellInfo.component.columnOption('url', 'calculateCellValue');
        const url = calculateURL(cellInfo.data);
        return (
            <Link to={url}>
                <strong style={{ whiteSpace: 'normal' }}>
                    {cellInfo.value}
                </strong>
            </Link>
        );
    }
    // calculateIdCellValue = (rowData) => {
    //     return rowData.referenceid;
    // }
    // sortIdColumn = (rowData) => {
    //     return rowData.sequencenumber;
    // }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.dataSource !== nextProps.dataSource) {
            return true;
        }
        else {
            return false;
        }
    }
    generateUniqueGridName = () => {
        const name = this.props.name.replace(/\s/g, "");
        return name + this.props.version;
    }
    renderHtmlCell = (field, cellInfo) => {
        if (cellInfo.data[field]) {
            const cleanHTML = sanitizeHtml(cellInfo.data[field], { ...htmlSenitizeOptions });
            return (
                <div style={{ maxHeight: 100, overflow: 'hidden', textOverflow: 'ellipsis' }} dangerouslySetInnerHTML={{ __html: cleanHTML }} />
            );
        }
        else {
            return null
        }

    }
    calculateHtmlValue = (field, rowData) => {
        if (rowData[field]) {
            const cleanHTML = sanitizeHtml(rowData[field], { ...htmlSenitizeOptions });
            return Common.HtmlToString(cleanHTML);
        }
        else return null;

    }
    calculateTagValue = (field, rowData) => {
        if (rowData[field]) {
            return rowData[field].join(", ");;
        }
        else return null;

    }
    render() {
        if (!this.props.dataSource) {
            return null;
        }
        const noDataText = this.props.name ? this.props.intl.formatMessage({ id: "GENERAL.WORDS.NONE_PREFIX" }) + ' ' + this.props.name : this.props.intl.formatMessage({ id: "GENERAL.WORDS.NO_DATA" });
        const defaultProps = {
            selectedRowKeys: [],
            //paging: { pageIndex: 0 },
            showBorders: true,
            showColumnLines: true,
            showRowLines: true,
            remoteOperations: false,
            allowColumnReordering: true,
            columnAutoWidth: true,
            rowAlternationEnabled: true,
            allowColumnResizing: true,
            columnResizingMode: window.innerWidth <= 1024 ? 'nextColumn' : 'widget',
            columnHidingEnabled: window.innerWidth <= 1024 ? true : false,
            wordWrapEnabled: true,
            noDataText: noDataText,
            onExporting: this.onExporting,
            // onExported: this.onExported,
            // onToolbarPreparing: this.onToolbarPreparing,
            onRowPrepared: this.onRowPrepared
        }
        if (this.props.toolbar) {
            delete defaultProps.onToolbarPreparing;
        }
        if (this.props.allowSelection === true || this.props.onSelectionChanged) {
            // delete defaultProps.selectedRowKeys;
            defaultProps.allowSelection = true;
        }
        const columnsMapped = this.props.columns.map((columnItem, index) => {
            const itmProps = { headerFilter: {}, ...columnItem }; ///, headerCellRender: this.headerCellRender
            if (itmProps.resource) {
                itmProps.caption = this.props.intl.formatMessage({ id: itmProps.resource })
            }
            if (itmProps.dataType && itmProps.dataType === 'date' && !itmProps.format) {
                //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
                //itmProps.format = { year: "numeric", month: "2-digit", day: "2-digit" };
                itmProps.format = (date) => moment(date).format('L');
            }
            if (itmProps.dataType && itmProps.dataType === 'datetime' && !itmProps.format) {
                //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
                //itmProps.format = { year: "numeric", month: "2-digit", day: "2-digit" };
                itmProps.format = (date) => moment(date).format('DD/MM/YYYY HH:mm');
            }
            if (itmProps.dataType && itmProps.dataType === 'currency') {
                //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat#Parameters
                //itmProps.format = { type: "currency", precision: 2, currency: "GBP" };
                itmProps.format = (num) => Common.FormatCurrencyNum(num, itmProps.currencyCode);
                itmProps.headerFilter.dataSource = this.props.isContract
                    ? JSON.parse(
                        contractAmountHeaderFilter.replace(
                            new RegExp("Amount", "g"),
                            itmProps.dataField
                        )
                    )
                    : JSON.parse(
                        amountHeaderFilter.replace(
                            new RegExp("Amount", "g"),
                            itmProps.dataField
                        )
                    );
            }
            if (itmProps.dataType === "tag") {
                if (!itmProps.calculateCellValue) {
                    itmProps.calculateCellValue = this.calculateTagValue.bind(this, itmProps.dataField);
                }
                itmProps.calculateFilterExpression = (filterValue, selectedFilterOperation, target) => {
                    if (target === "headerFilter") {
                        if (selectedFilterOperation === "=" && filterValue === null) {
                            return [itmProps.dataField, "=", null];
                        }
                        else {
                            const filterExpression = [
                                [itmProps.dataField, "=", filterValue],
                                "or",
                                [itmProps.dataField, "startswith", filterValue + ","],
                                "or",
                                [itmProps.dataField, "contains", ", " + filterValue + ","],
                                "or",
                                [itmProps.dataField, "endswith", ", " + filterValue]
                            ];

                            return filterExpression;
                        }

                    }
                    return [itmProps.dataField, "contains", filterValue];
                }
            }
            if (itmProps.isHtml === true) {
                //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat#Parameters
                //itmProps.format = { type: "currency", precision: 2, currency: "GBP" };
                if (!itmProps.cellRender) {
                    itmProps.cellRender = this.renderHtmlCell.bind(this, itmProps.dataField);
                }
                if (!itmProps.calculateCellValue) {
                    itmProps.calculateCellValue = this.calculateHtmlValue.bind(this, itmProps.dataField);
                }
                if (!itmProps.hasOwnProperty('visible')) {
                    itmProps.visible = false;
                }
                // if (!itmProps.hasOwnProperty('allowFiltering')) {
                //     itmProps.allowFiltering = false;
                // }
            }
            if (this.props.onSelectionChanged) {
                if (index === 0) {
                    itmProps.cssClass = "WrappedIdColumnWithPointer";
                    if (!itmProps.skipAutoReference) {
                        itmProps.minWidth = this.minIDColumnWidth;
                        // if (!itmProps.calculateSortValue && itmProps.dataField === "referenceid") {
                        //     //WF REGISTER
                        //     // itmProps.calculateCellValue = this.calculateIdCellValue;
                        //     itmProps.calculateSortValue = this.sortIdColumn;
                        // }
                        if (itmProps.renderReferenceidLink) {
                            itmProps.cellRender = this.renderIdColumn;
                        }
                    }
                }
                else if (!itmProps.cssClass) {
                    itmProps.cssClass = "WrappedColumnWithPointer";
                }
            }
            return itmProps;
        });

        const finalProps = { ...defaultProps, ...this.props, style: { width: '100%', height: '100%', maxHeight: '67vh', ...this.props.style } };
        delete finalProps.columns;
        delete finalProps.name;
        return (
            <div style={{ width: '100%', height: '67vh' }}>
                <DataGrid
                    ref={this.dataGrid}
                    {...finalProps}
                >
                    <HeaderFilter visible={true} />
                    <FilterRow applyFilter="auto" visible={true} />
                    <FilterPanel visible={true} />
                    <ColumnChooser mode="select" enabled={true} search={{ enabled: true }}  >
                        {/* <ColumnChooserSearch enabled={true} timeout={800} /> */}
                    </ColumnChooser>
                    <ColumnFixing enabled={true} />
                    <StateStoring enabled={true} type="localStorage" storageKey={this.generateUniqueGridName()} />
                    <Export enabled={true} fileName={this.props.name} />
                    <SearchPanel visible={true} highlightCaseSensitive={false} defaultText="" />
                    {
                        columnsMapped.map((columnItem, index) => (
                            <Column cssClass="WrappedColumnClass" key={index} {...columnItem} />
                        ))
                    }
                    {/* // pageIndex={0}  */}
                    {/* <Paging pageSize={10} defaultPageSize={10} defaultPageIndex={0} /> */}
                    {/* mode="virtual"  */}
                    <Template name="toolbarTitle" render={this.toolbarTitleRender} />
                    <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                    <Toolbar>
                        <ToolbarItem location="before"
                            locateInMenu="never"
                            template="toolbarTitle"
                        />

                        {
                            (this.props.additionalToolbarItems || []).map((item, index) => (
                                <ToolbarItem key={"CustomToolbarItem" + index.toString()} {...item} />
                            ))
                        }

                        {
                            this.props.createPath && (
                                <ToolbarItem location="after"
                                    locateInMenu="auto"
                                    widget="dxButton"
                                    options={{
                                        icon: "plus",
                                        text: this.props.intl.formatMessage({ id: "GENERAL.FORMS.BUTTON_CREATE_NEW" }),
                                        onClick: this.navigateToCreate
                                    }}
                                />
                            )
                        }
                        <ToolbarItem location="after"
                            locateInMenu="auto"
                            name="exportButton"
                        />
                        <ToolbarItem location="after"
                            locateInMenu="auto"
                            name="columnChooserButton"
                        />
                        <ToolbarItem location="after"
                            locateInMenu="auto"
                            name="searchPanel"
                        />
                    </Toolbar>
                    <Summary>
                        <TotalItem column={0} summaryType="count" />
                    </Summary>
                </DataGrid>
            </div>

        );
    }

}

export default injectIntl(withRouter(DataGridBase));