import React from "react";
import { Route, Routes } from "react-router-dom";
import DefectListAll from './Defect/ListAll';


class DefectRegisterRouter extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.plannedClosureEnabled = props.placeholders
                .find((ph) => ph.replacementKey === "DefectPlannedClosureEnabled")
                .values.find((phItem) => phItem.field === "DefectPlannedClosureEnabled").isActive;
        } catch (error) {
            console.log("DefectPlannedClosureEnabled placeholder not available!");
            this.plannedClosureEnabled = false;
        }
        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;

        const superWf = props.workflowGroupItem.workflows.find(wf => wf.id === 23);
        if (superWf) {
            this.notificationAbbreviation_SUPER = superWf.abbreviation;
            this.canActionSuper = superWf.userFilters.actionTab.actionAllowed;
        }
        const supplierWf = props.workflowGroupItem.workflows.find(wf => wf.id === 24);
        if (supplierWf) {
            this.notificationAbbreviation_SUPP = supplierWf.abbreviation;
            this.canActionSupplier = supplierWf.userFilters.actionTab.actionAllowed;
        }

        this.NCSubmissionWFexist = !!props.workflowGroupItem.workflows.find(wf => wf.id === 54);
        this.NonConformityWFexist = !!props.workflowGroupItem.workflows.find(wf => wf.id === 55);
        if (this.NCSubmissionWFexist) {
            this.NCSubmissionReplyOptions = props.placeholders.find(ph => ph.replacementKey === 'NCProposalReplyOptions')
                .values.reduce((obj, item) => ({ ...obj, [item.field]: item.display }), {});

            if (this.NonConformityWFexist) {
                this.NonConformityReplyOptions = props.placeholders.find(ph => ph.replacementKey === 'NonConformityReplyOptions')
                    .values.reduce((obj, item) => ({ ...obj, [item.field]: item.display }), {});
            }

        }

    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;


        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<DefectListAll
                        {...this.props}
                        notificationAbbreviation_SUPER={this.notificationAbbreviation_SUPER}
                        notificationAbbreviation_SUPP={this.notificationAbbreviation_SUPP}
                        contractTemplate={this.props.contractTemplate}
                        contractUser={this.props.contractUser}
                        title={workflowItem.title}
                        canActionSuper={this.canActionSuper}
                        canActionSupplier={this.canActionSupplier}
                        plannedClosureEnabled={this.plannedClosureEnabled}
                        NCSubmissionWFexist={this.NCSubmissionWFexist}
                        NCSubmissionReplyOptions={this.NCSubmissionReplyOptions}
                        NonConformityReplyOptions={this.NonConformityReplyOptions}
                    />}
                />
            </Routes>
        );
    }
};




export default DefectRegisterRouter;