import { userFilterCategories } from "../../../shared/lookup";
const workflows = [
    {
        id: 68,
        title: '$PM_NAME Certificates',
        notificationName: 'Certificate',
        abbreviation: '$PM_ABBREVIATION-Certificate-',
        notifyingParty: 'PM',
        replyingParty: 'SUPPLIER',

        //REPLY
        replyEnabled: false,
        replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM to Supplier Certificates',
            currentModel: 'Certificate',
            currentModelType: 'PM',
            referenceType: 'Certificate',
            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["SUPPLIER_REPLY_PERIOD", "CUSTOM"],// "ASKED_ON_COMMUNICATION_FORM" removed as its not supported

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 69,
        title: '$SUPPLIER_NAME Certificates',
        notificationName: 'Certificate',
        abbreviation: '$SUPPLIER_ABBREVIATION-Certificate-',
        notifyingParty: 'SUPPLIER',
        replyingParty: 'PM',

        //REPLY
        replyEnabled: false,
        replyPeriodOption: "PM_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supplier to PM Certificates',
            currentModel: 'Certificate',
            currentModelType: 'CON',
            referenceType: 'Certificate',
            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["PM_REPLY_PERIOD", "CUSTOM"],// "ASKED_ON_COMMUNICATION_FORM" removed as its not supported

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supplier',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 70,
        title: '$CLIENT_NAME Certificates',
        notificationName: 'Certificate',
        abbreviation: '$CLIENT_ABBREVIATION-Certificate-',
        notifyingParty: 'CLIENT',
        replyingParty: 'PM',

        //REPLY
        replyEnabled: false,
        replyPeriodOption: "PM_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Client to PM Certificates',
            currentModel: 'Certificate',
            currentModelType: 'CLI',
            referenceType: 'Certificate',
            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["PM_REPLY_PERIOD", "CUSTOM"],// "ASKED_ON_COMMUNICATION_FORM" removed as its not supported

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'client',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 71,
        title: '$SUPERVISOR_NAME Certificates',
        notificationName: 'Certificate',
        abbreviation: '$SUPERVISOR_ABBREVIATION-Certificate-',
        notifyingParty: 'SUPERVISOR',
        replyingParty: 'SUPPLIER',

        //REPLY
        replyEnabled: false,
        replyPeriodOption: "SUPPLIER_REPLY_PERIOD",
        replyPeriod: 0,

        notify: true,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,
        replyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'Supervisor to Supplier Certificates',
            currentModel: 'Certificate',
            currentModelType: 'SUP',
            referenceType: 'Certificate',
            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: true,
            configurableReplyTab: true,
            configurableAssets: true,
            configurableReplyingParty: true,

            replyPeriodOptions: ["SUPPLIER_REPLY_PERIOD", "CUSTOM"], // "ASKED_ON_COMMUNICATION_FORM" removed as its not supported

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.REPLY_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'supervisor',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    }
]

export default workflows;