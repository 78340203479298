//import axios from "axios";
import axios from '../shared/axios';
// import qs  from 'querystring';

// export const LOGIN_URL = "api/auth/login";
// export const REGISTER_URL = "api/auth/register";
// export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
// export const ME_URL = "api/me";

export function login(email, password) {
  const params = new URLSearchParams({
    grant_type: 'password',
    username: email,
    password: password
  });

  const headers = {
    'Content-Type':'application/x-www-form-urlencoded'
  }

  //const requestBody = `grant_type=password&username=${email}&password=${password}`;
  return axios.post('/Token', params.toString(), {
    headers: headers
  });
}

export function getCsrfToken() {
  return axios.get('/api/generic');
}

export function getUserProfile() {
  return axios.get('/api/user/getprofile');
}

export function getVersion() {
  return axios.get('/api/Version/GetAPIVersion');
}



export function register(email, fullname, username, password) {
  //return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  //return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  //return axios.get(ME_URL);
}
