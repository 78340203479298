import React from 'react';
import Form, { SimpleItem, } from 'devextreme-react/form';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import PlaceholderListManager from './PlaceholderListManager';
import WorkflowListManager from './WorkflowListManager';
import TemplateHelper from '../../../../../Templates/TemplateHelper';
import DefaultWorkflows from '../../../../../Templates/DefaultWorkflows';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import {placeholderTypes} from "../../../../../Templates/Types/Placeholder";
import { withSnackbar } from 'notistack';

class WorkflowGroupDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            workflowGroup: null,
            changed: false
        };
    }

    componentDidMount() {
        if (this.props.workflowGroup) {
            this.setState(
                {
                    workflowGroup: JSON.parse(JSON.stringify(this.props.workflowGroup)),
                    changed: false
                });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.workflowGroup !== this.props.workflowGroup) {
                if (this.props.workflowGroup) {
                    //UNMOUNT ALL COMPONENTS BEFORE LOADING NEW
                    this.setState({ workflowGroup: null, changed: false }, () => {
                        this.setState(
                            {
                                workflowGroup: JSON.parse(JSON.stringify(this.props.workflowGroup))
                            });
                    })
                }
                else {
                    this.setState(
                        {
                            workflowGroup: null,
                        });
                }
            }
        }
    }
    saveWorkflowGroupHandler = () => {
        //Check UnifiedClause
        if(this.props.mode !== 'WORKFLOW'){
            if(this.state.workflowGroup.placeholders?.length>0){
                const staticDefaultWFG = DefaultWorkflows.find(wfg => wfg.id === this.state.workflowGroup.id);
                let unifiedClauseDef =  staticDefaultWFG.placeholders.find(ph=>ph.type === placeholderTypes.UNIFIED_CLAUSES)
                if(unifiedClauseDef){
                    //Find if it is defined in our template
                    let unifiedClauseInGroup = this.state.workflowGroup.placeholders.find(ph=>ph.replacementKey === unifiedClauseDef.replacementKey);
                    if(unifiedClauseInGroup){
                        //Exist
                        //Now check if other clause definitions exist
                        const forbiddenPHKeys = staticDefaultWFG.placeholders.filter(ph=>ph.type === placeholderTypes.CLAUSES).map(ph=>ph.replacementKey);
                        const existingForbiddenKeys = this.state.workflowGroup.placeholders.filter(ph=> forbiddenPHKeys.includes(ph.replacementKey)).map(ph=>ph.replacementKey);
                        if(existingForbiddenKeys.length){
                            this.props.enqueueSnackbar(`Unified Clause List can not be used with [${existingForbiddenKeys.join(', ')}]`, { variant: 'error', });
                            return;
                        }
                    }
                }
                
            }
        }
        this.props.saveHandler(this.state.workflowGroup);
    }
    tabGeneral = () => {
        return (
            <div style={{ padding: 20, width: '100%' }}>
                <Form
                    style={{ marginTop: '10px', width: '100%' }}
                    formData={this.state.workflowGroup}
                    onFieldDataChanged={(e) => { this.setState({ changed: true }); this.props.onChange(); }}
                >
                    <SimpleItem label={{ text: 'Group Menu Title' }} dataField="title" editorOptions={{ readOnly: this.props.readOnly }} />
                    {/* <SimpleItem label={{ text: 'Group Path' }} dataField="path" editorOptions={{ readOnly: this.props.mode !== 'WORKFLOW' }} /> */}
                </Form>
            </div>
        )
    }

    onPlaceholdersUpdated = (newList) => {
        const newWFGroup = JSON.parse(JSON.stringify(this.state.workflowGroup));
        newWFGroup.placeholders = JSON.parse(JSON.stringify(newList));
        this.props.onChange();
        this.setState({ workflowGroup: newWFGroup, changed: true });
    }
    tabPlaceHolders = () => {
        let allPlaceholders = null;
        if (this.state.workflowGroup.id > 0) {
            let defaultWFG;
            if (this.props.mode === 'WORKFLOW') {
                const defaultTemp = TemplateHelper.getDefaultSanitizedTemplate();
                console.log("Default Workflows are ",defaultTemp)
                defaultWFG = defaultTemp.workflowGroups.find(wfg => wfg.id === this.state.workflowGroup.id);
            }
            else {
                defaultWFG = this.props.allWorkflowGroups.find(wfg => wfg.id === this.state.workflowGroup.id);
            }
            if (defaultWFG) {
                allPlaceholders = defaultWFG.placeholders;
            }
            else {
                console.log('Default workflowgroup not found ID:', this.state.workflowGroup.id);
            }

        }
        else {
            return (<div>Select a Workflow-Group</div>);
        }
        return (
            <PlaceholderListManager
                wfgid={this.state.workflowGroup.id}
                mode={this.props.mode}
                listChanged={this.onPlaceholdersUpdated}
                items={this.state.workflowGroup.placeholders ? this.state.workflowGroup.placeholders : []}
                allItems={allPlaceholders}
                readOnly={this.props.readOnly}
            />
        )
    }
    onWorkflowsUpdated = (newList) => {
        const newWFGroup = JSON.parse(JSON.stringify(this.state.workflowGroup));
        newWFGroup.workflows = JSON.parse(JSON.stringify(newList));
        this.props.onChange();
        this.setState({ workflowGroup: newWFGroup, changed: true });
    }
    tabWorkflows = () => {
        let allWFItems = null;
        if (this.state.workflowGroup.id > 0) {
            let defaultWFG;
            
            const defaultTemp = TemplateHelper.getDefaultSanitizedTemplate()
            if (this.props.mode === 'WORKFLOW') {
                defaultWFG = defaultTemp.workflowGroups.find(wfg => wfg.id === this.state.workflowGroup.id);
            }
            else {
                defaultWFG = this.props.allWorkflowGroups.find(wfg => wfg.id === this.state.workflowGroup.id);
                if (!defaultWFG) {
                    alert("This workflow group is not enabled for the instance")
                    return (<div>Select a Workflow-Group</div>);
                }

                //FILL MISSING PROPERTIES OF WORKFLOWS SAVED IN THE SYSTEM
                const staticDefaultWFG = DefaultWorkflows.find(wfg => wfg.id === this.state.workflowGroup.id);

                defaultWFG.workflows.forEach(WF => {
                    WF.description = staticDefaultWFG.workflows.find(sdefWF => sdefWF.id === WF.id).config.description;
                });


            }

            allWFItems = defaultWFG.workflows;
        }
        else {
            return (<div>Select a Workflow-Group</div>);
        }

        return (
            <WorkflowListManager
                wfgid={this.state.workflowGroup.id}
                mode={this.props.mode}
                listChanged={this.onWorkflowsUpdated}
                items={this.state.workflowGroup.workflows}
                allItems={allWFItems}
                readOnly={this.props.readOnly}
            />
        )
    }
    render() {
        if (!this.state.workflowGroup) {
            return <div style={{ padding: 10, margin: 10, textAlign: "center" }}>Select a group...</div>;
        }
        return (
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div style={{ margin: 10 }}>
                    <Toolbar >
                        <ToolbarItem location="before">
                            <div>
                                <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">
                                    {this.state.workflowGroup.title}
                                </h3>
                            </div>
                        </ToolbarItem>
                        <ToolbarItem location="after"
                            locateInMenu="never"
                            widget="dxButton"
                            disabled={!this.state.changed}
                            options={
                                {
                                    icon: "save",
                                    type: "default",
                                    stylingMode: this.state.changed ? "contained" : "outlined",
                                    onClick: this.saveWorkflowGroupHandler,
                                    text: "Update Workflow Group",
                                }
                            }
                        />
                    </Toolbar>
                </div>
                <div style={{ flexGrow: 1, minHeight: 0 }}>
                    <TabPanel
                        disabled={this.state.workflowGroup.id === 0}
                        ref={(ref) => this.tabPanel = ref}
                        height="100%"
                        width="100%"
                        animationEnabled={true}
                        swipeEnabled={true}
                    >
                        <Item title={"Main"} render={this.tabGeneral} />
                        <Item title={"Workflows"} render={this.tabWorkflows} />
                        <Item title={"Placeholders"} render={this.tabPlaceHolders} />

                    </TabPanel>
                </div>
                {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', marginLeft: '10px' }}>
                    <Button
                        disabled={this.state.workflowGroup.id === 0}
                        type="normal"
                        stylingMode="contained"
                        icon="save"
                        text={'Update Workflow Group'}
                        onClick={this.saveWorkflowGroupHandler} />
                </div> */}
            </div>

        );
    }
}

export default withSnackbar(WorkflowGroupDetails);