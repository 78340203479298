import {placeholderTypes} from "../../Types/Placeholder";
const placeholders =  [
    {
        replacementKey: 'Resources', values:
            [
                { id: 1, isActive: true, field: 'MeetingRequiredLabel', display: 'Is Risk Reduction Meeting required?' },
                { id: 2, isActive: true, field: 'NotificationClause', display: 'In accordance with NEC4 PSC clause 15.1. You are given early warning that an occurrence/situation is anticipated which may' },
                { id: 3, isActive: true, field: 'RiskItemsLabel', display: 'You are given early warning that an occurrence/situation is anticipated which may' },
                { id: 4, isActive: true, field: 'DescriptionLabel', display: 'Description of early warning' },
                { id: 5, isActive: true, field: 'RiskStatusLabel', display: 'Risk status' }
            ],
        type:placeholderTypes.RESOURCES
    },
    {
        replacementKey: 'ActionReminders', values:
            [
                { id: 1, isActive: true, field: 'Pm', display: '$PM_ABBREVIATION Early Warnings awaiting reply' },
                { id: 2, isActive: true, field: 'Supplier', display: '$SUPPLIER_NAME Early Warnings awaiting reply' },
                { id: 3, isActive: true, field: 'Client', display: '$CLIENT_NAME Early Warnings awaiting reply' },
            ],
        type:placeholderTypes.ACTION_REMINDERS
    },
    {
        replacementKey: 'RiskTypes', values:
            [
                { id: 1, isActive: true, field: 'riskincreaseprice', display: 'Increase the total of the Prices' },
                { id: 2, isActive: true, field: 'riskdelaycompletion', display: 'Delay Completion' },
                { id: 3, isActive: true, field: 'riskdelaymilestone', display: 'Delay meeting a Key Date' },
                { id: 4, isActive: true, field: 'riskimpairperformance', display: 'Impair the performance of the works in use' },
                { id: 5, isActive: true, field: 'riskincreasecontractorcost', display: 'Increase $SUPPLIER_NAME cost' },
                { id: 6, isActive: false, field: 'riskincreasedefinedcost', display: 'Increase the Defined Cost of expending any Provisional Sums' },
                { id: 7, isActive: false, field: 'riskaffectsafety', display: 'Adversely affect the $SUPPLIER_NAME’s ability to safely carry out the works' },
                { id: 8, isActive: false, field: 'riskcontractbreach', display: 'Constitute or cause a breach of any of the $SUPPLIER_NAME’s obligations under this contract' },
                { id: 9, isActive: false, field: 'riskaffectmodel', display: 'Adversely affect the creation or use of the Information Model' },
                { id: 10, isActive: false, field: 'riskaffectwork', display: "Affect the work of the $PM_NAME" }
            ],
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'RiskTypesSupplier', values:
            [
                { id: 1, isActive: true, field: "riskincreaseprice", display: "Increase the total of the Prices" },
                { id: 2, isActive: true, field: "riskdelaycompletion", display: "Delay Completion" },
                { id: 3, isActive: true, field: "riskdelaymilestone", display: "Delay meeting a Key Date" },
                { id: 4, isActive: true, field: "riskimpairperformance", display: "Impair the performance of the works in use" },
                { id: 5, isActive: true, field: "riskincreasecontractorcost", display: "Increase the $SUPPLIER_NAME's cost" },
                { id: 6, isActive: false, field: "riskincreasedefinedcost", display: "Increase the Defined Cost of expending any Provisional Sums" },
                { id: 7, isActive: false, field: "riskaffectsafety", display: "Adversely affect the $SUPPLIER_NAME’s ability to safely carry out the works" },
                { id: 8, isActive: true, field: "riskcontractbreach", display: "Affect the achievement of another Partner's objectives" },
                { id: 9, isActive: false, field: "riskaffectmodel", display: "Adversely affect the creation or use of the Information Model" },
                { id: 10, isActive: false, field: 'riskaffectwork', display: "Affect the work of the $PM_NAME" }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'RiskTypesClient', values:
            [
                { id: 1, isActive: true, field: "riskincreaseprice", display: "Increase the total of the Prices" },
                { id: 2, isActive: true, field: "riskdelaycompletion", display: "Delay Completion" },
                { id: 3, isActive: true, field: "riskdelaymilestone", display: "Delay meeting a Key Date" },
                { id: 4, isActive: true, field: "riskimpairperformance", display: "Impair the performance of the works in use" },
                { id: 5, isActive: true, field: "riskincreasecontractorcost", display: "Increase the $SUPPLIER_NAME's cost" },
                { id: 6, isActive: false, field: "riskincreasedefinedcost", display: "Increase the Defined Cost of expending any Provisional Sums" },
                { id: 7, isActive: false, field: "riskaffectsafety", display: "Adversely affect the $SUPPLIER_NAME’s ability to safely carry out the works" },
                { id: 8, isActive: true, field: "riskcontractbreach", display: "Affect the achievement of another Partner's objectives" },
                { id: 9, isActive: false, field: "riskaffectmodel", display: "Adversely affect the creation or use of the Information Model" },
                { id: 10, isActive: false, field: 'riskaffectwork', display: "Affect the work of the $PM_NAME" }
            ],
        skipDefaultLoadingIfMissing: true,
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'MeetingOptions', values:
            [
                { id: 1, isActive: true, field: '0', display: 'Meeting Not Required' },
                { id: 2, isActive: true, field: '1', display: 'Meeting Required - Next Scheduled Risk Reduction Meeting' },
                { id: 3, isActive: true, field: '2', display: 'Meeting Required - Propose Risk Reduction Meeting' },
            ],
        fieldRequired: true,
        type:placeholderTypes.GENERIC
    },
    {
        replacementKey: 'RiskStatusOptions', values:
            [
                { id: 1, isActive: true, field: 'Mitigated', display: 'Mitigated' },
                { id: 2, isActive: true, field: 'NotMitigated', display: 'Not Mitigated' }
            ],
        fieldRequired: true,
        type:placeholderTypes.GENERIC,
        skipDefaultLoadingIfMissing: true
    }
];
export default placeholders;