/* eslint-disable import/no-anonymous-default-export */
import Workflows from "./Workflows";
import Placeholders from "./Placeholders";

export default {
    id: 118,
    title: 'Project Orders',
    path: 'projects',
    icon: "flaticon2-box",
    workflows: Workflows,
    placeholders: Placeholders,
};