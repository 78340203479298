// https://stackoverflow.com/questions/74064963/react-router-dom-v6-prevent-transition-without-prompt/74065505#74065505
// https://codesandbox.io/s/agitated-blackwell-dpl9b3?fontsize=14&hidenavigation=1&initialpath=page2&module=/src/App.js&theme=dark&file=/src/App.js:139-183

import history from "../../../history";
import { useEffect } from "react";
//#TODO: WILL BE REMOVED WHEN react-router v6 support (Propmpt, usePrompt, useBlocker)
const NavigationBlocker = ({ when, message }) => {
  useEffect(() => {
    let unblock;
    if (when) {
      // Block navigation and register a callback that
      // fires when a navigation attempt is blocked.
      unblock = history.block((tx) => {
        // Navigation was blocked! Let's show a confirmation dialog
        // so the user can decide if they actually want to navigate
        // away and discard changes they've made in the current page.
        //let url = tx.location.pathname;
        if (window.confirm(message)) {
          // Unblock the navigation.
          unblock();

          // Retry the transition.
          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [when, message]);

  return null;
};

export default NavigationBlocker;