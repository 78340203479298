import * as projectsCrud from "../../crud/projects.crud";
import { select, put, takeLatest } from "redux-saga/effects";
import * as authDuck from './auth.duck';
import store from "../store";

const getProjects = (store) => store.projects;

const actionTypes = {
    PROJECTS_SET_PENDING: "[PROJECTS_SET_PENDING]",
    PROJECTS_FETCH_START: "[PROJECTS_FETCH_START]",
    PROJECTS_SET_PROJECTS: "[PROJECTS_SET_PROJECTS]",
    PROJECTS_LOGOUT: "[PROJECTS_LOGOUT]"
};

const initialProjectsState = {
    projects: null,
    lastLoadedEpochTime: null,
    pendingFetch: false
};

export const reducer = (state = initialProjectsState, action) => {
    switch (action.type) {
        case actionTypes.PROJECTS_SET_PENDING: {
            return { ...state, pendingFetch: true };
        }
        case actionTypes.PROJECTS_FETCH_START: {
            return { ...state, pendingFetch: false, lastLoadedEpochTime: new Date().getTime() };
        }
        case actionTypes.PROJECTS_SET_PROJECTS: {
            return { ...state, projects: action.payload.projects };
        }
        case actionTypes.PROJECTS_LOGOUT: {
            return initialProjectsState;
        }
        default:
            return state;
    }
};

export const actions = {
    fetchStart: (force = false) => {
        const authStore = store.getState().auth;
        const projectsStore = store.getState().projects;

        if (authStore) {
            if (!authStore.authToken.token || !authStore.csrfToken.token) {
                if (!projectsStore.pendingFetch) {
                    return { type: actionTypes.PROJECTS_SET_PENDING }
                }
                return { type: '' };
            }
        }
        if (!projectsStore.pendingFetch && !force && projectsStore) {
            //DO NOT REPEAT REQUEST IN LESS THAN A MINUTE
            if (projectsStore.lastLoadedEpochTime) {
                if (new Date(projectsStore.lastLoadedEpochTime + (60 * 1000)) > new Date()) {
                    // console.log("Projects loaded less than a minute ago!");
                    return { type: '' };
                }
            }
        }
        return { type: actionTypes.PROJECTS_FETCH_START };
    },
    setPending:()=> ({ type: actionTypes.PROJECTS_SET_PENDING }),
    setProjects: (projects) => ({ type: actionTypes.PROJECTS_SET_PROJECTS, payload: { projects } }),
    logout: () => ({ type: actionTypes.PROJECTS_LOGOUT })
};
export function* saga() {
    yield takeLatest(authDuck.actionTypes.AUTH_LOAD_COMPLETED, function* projectsLoginSaga() {
        try {
            let projectsStore = yield select(getProjects);
            if (projectsStore.pendingFetch) {
                yield put(actions.fetchStart(true));
            }

        }
        catch{ console.log("error in projectsLoginSaga") }

    });
    yield takeLatest(actionTypes.PROJECTS_FETCH_START, function* projectsLoadProjectsSaga() {
        try {
            const response = yield projectsCrud.getProjects();
            yield put(actions.setProjects(JSON.parse(response.data.aggregatelist)));
        }
        catch{ }

    });
}
