import React from 'react';
import { withSnackbar } from 'notistack';
// import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { Portlet, PortletBody, PortletHeader } from "../../partials/content/Portlet";
// import { Button } from "react-bootstrap";
import * as registerCrud from "../../crud/register.crud";
import * as companiesCrud from "../../crud/companies.crud";
// import { adjustContractTemplate } from '../../store/helpers/TemplateAdjust';
import BiDynamicForm from '../../partials/layout/BiDynamicForm';
// import DataGridBase from '../../partials/layout/DataGridBase';
import SimpleBox from '../../partials/content/SimpleBox';
// import PortletSpinner from '../../partials/layout/PortletSpinner';
import { connect } from "react-redux";
import moment from 'moment';
import DataGrid, { Column, ColumnFixing, Scrolling, HeaderFilter, FilterRow, FilterPanel, Summary, TotalItem } from 'devextreme-react/data-grid';
// import Common from '../../shared/common';
import { LinearProgress } from "@mui/material";
import { injectIntl } from "react-intl";
// import { Redirect } from "react-router-dom";
import { recordStatusesOptions } from '../../shared/lookup';
import {
    listAccountsAllowedViewReportSystemUser,
    listAccountsAllowedViewReportHubUser,
    listAccountsAllowedViewReportContractUser
} from '../../router/Protector/AccessExceptions';

const isCustomFieldsEnabled = process.env.REACT_APP_CUSTOM_FIELDS_ENABLED === "true";
const isDateRestrictionEnabled = process.env.REACT_APP_AGGREGATE_12MONTH_LIMIT === "true";
const isAssetsEnabled = process.env.REACT_APP_ASSETS_ENABLED === 'true';
const isSecondaryPricesEnabled = process.env.REACT_APP_SECONDARY_PRICES_ENABLED === "true";

class AggregateReports extends React.Component {
    constructor(props) {
        super(props);
        this.gridRef = React.createRef();
        this.reportTypes = [];
        if (isAssetsEnabled) {
            this.reportTypes.push(
                { value: 'AssetDetails', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.ASSETDETAILS' }) }
            )
        }
        this.reportTypes.push(
            { value: 'Certificate_ProjectManager', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTMANAGERCERTIFICATE' }) },
            { value: 'Certificate_Supplier', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTORCERTIFICATE' }) },
            { value: 'Certificate_Client', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CLIENTCERTIFICATE' }) },
            { value: 'Certificate_Supervisor', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.SUPERVISORCERTIFICATE' }) },
            { value: 'CompensationEvent', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.MASTERCOMPENSATIONEVENT' }) }
        );

        if (isSecondaryPricesEnabled) {
            this.reportTypes.push(
                { value: 'ContractOtherPrice', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTOTHERPRICE' }) },
                { value: 'ContractOtherPriceChange', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTOTHERPRICECHANGE' }) },
            )
        }

        this.reportTypes.push(
            { value: 'ContractSummary', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTSUMMARY' }) }
        )

        if (listAccountsAllowedViewReportContractUser.includes(props.userProfile.accounttype)) {
            this.reportTypes.push(
                { value: 'ContractUser', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTUSER' }) }
            )
        }

        this.reportTypes.push(
            { value: 'MasterDefect', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.MASTERINSPECTIONDEFECT' }) },
            { value: 'DelegatedAction_ProjectManager', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTMANAGERDELEGATEDACTION' }) },
            { value: 'DelegatedAction_Supervisor', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.SUPERVISORDELEGATEDACTION' }) },
            { value: 'DelegatedAction_Supplier', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTORDELEGATEDACTION' }) },
            { value: 'Documents', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.DOCUMENTS' }) },
            { value: 'EarlyWarning', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.MASTEREARLYWARNINGS' }) },
            { value: 'Extension_ProjectManager', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTMANAGEREXTENSIONREQUEST' }) },
            { value: 'Extension_Quote', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTORQUOTEEXTENSION' }) },
            { value: 'Forecast_Carbon', display: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.CARBONFORECAST' }) },
            { value: 'Forecast_Cost', display: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.FIELD.COSTFORECAST' }) }
        )

        if (listAccountsAllowedViewReportHubUser.includes(props.userProfile.accounttype)) {
            this.reportTypes.push(
                { value: 'HubUser', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.HUBUSER' }) }
            )
        }

        this.reportTypes.push(
            { value: 'Instruction_Client', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTMANAGERCLIENT' }) },
            { value: 'Instruction_ProjectManager', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTMANAGERINSTRUCTION' }) },
            { value: 'Instruction_Supervisor', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.SUPERVISORINSTRUCTION' }) },
            { value: 'Instruction_Search', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTORINSTRUCTION' }) },
            { value: 'Meetings', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.MEETINGS' }) },
            { value: 'NonConformance', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.NONCONFORMANCE' }) },
            { value: 'NonConformity', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.NONCONFORMITY' }) },
            { value: 'NonConformity_Submission_ProjectManager', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.NONCONFORMITYSUBMISSIONPROJECTMANAGER' }) },
            { value: 'Notification_ClientToClient', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CLIENTTOCLIENTNOTIFICATION' }) },
            { value: 'Notification_ClientToProjectManager', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.NOTIFICATIONCLIENTTOPM' }) },
            { value: 'Notification_ProjectManagerToClient', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTMANAGERTOCLIENTNOTIFICATION' }) },
            { value: 'Notification_ProjectManagerToProjectManager', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTMANAGERTOPROJECTMANAGERNOTIFICATION' }) },
            { value: 'Notification_ProjectManagerToSupervisor', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTMANAGERTOSUPERVISORNOTIFICATION' }) },
            { value: 'Notification_ProjectManager', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTMANAGERNOTIFICATION' }) },
            { value: 'Notification_SupervisorToProjectManager', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.SUPERVISORTOPROJECTMANAGERNOTIFICATION' }) },
            { value: 'Notification_SupervisorToSupervisor', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.SUPERVISORTOSUPERVISORNOTIFICATION' }) },
            { value: 'Notification_Supervisor', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.SUPERVISORNOTIFICATION' }) },
            { value: 'Notification_SupplierToSupplier', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTORTOCONTRACTORNOTIFICATION' }) },
            { value: 'Notification_Supplier', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTORNOTIFICATION' }) },
            { value: 'PaymentSummary', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PAYMENTSUMMARY' }) },
            { value: 'PaymentApplication_Supplier', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTORPAYMENTAPPLICATION' }) },
            { value: 'PaymentCertificate_ProjectManager', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTMANAGERPAYMENTCERTIFICATE' }) },
            { value: 'Programme_Supplier', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTORPROGRAMMEREGISTER' }) },
            { value: 'ProjectList', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTLIST' }) },
            { value: 'ProjectOrder', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTMANAGERPROJECTORDERS' }) },
            { value: 'Proposal_Supplier', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTORPROPOSAL' }) },
            { value: 'PurchaseOrderDetail', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PURCHASEORDERDETAIL' }) },
            { value: 'Quotation', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.MASTERQUOTATION' }) },
            { value: 'RFITQ_Supplier', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTORRFITQ' }) },
            { value: 'RFITQ_PM', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTORRFITQPM' }) },
            { value: 'SiteDiary', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.SITEDIARY' }) },
            { value: 'Notification_SupplierToSupervisor', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.SUPPLIERTOSUPERVISORNOTIFICATION' }) },

        )




        if (listAccountsAllowedViewReportSystemUser.includes(props.userProfile.accounttype)) {
            this.reportTypes.push(
                { value: 'SystemUser', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.SYSTEMUSER' }) }
            )
        }

        this.reportTypes.push(
            { value: 'IssueAcceptance_ProjectManager', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTMANAGERISSUEFORACCEPTANCE' }) },
            { value: 'IssueAcceptance_Supplier', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTORISSUEFORACCEPTANCE' }) },
            { value: 'TaskOrder', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTMANAGERTASKORDERS' }) },
            { value: 'TestInspection_ProjectManager', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.PROJECTMANAGERTESTINSPECTION' }) },
            { value: 'TestInspection_Supervisor', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.SUPERVISORTESTINSPECTION' }) },
            { value: 'TestInspection_Supplier', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTORTESTINSPECTION' }) },
            { value: 'Weather', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.WEATHER' }) },
            { value: 'InstructionToQuote', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.INSTRUCTION_FOR_QUOTE' }) },
            { value: 'RequestForQuote', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.REQUEST_FOR_QUOTE' }) },

            { value: 'ItqQuotation', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.ITQ_QUOTATION' }) },
            { value: 'RfqQuotation', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.RFQ_QUOTATION' }) },
            { value: 'ContractOtherDate', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTOTHERDATE' }) },
            { value: 'ContractOtherDateChange', display: this.props.intl.formatMessage({ id: 'REPORTS.WF.CONTRACTOTHERDATECHANGE' }) },

        )
        this.reportTypes = this.reportTypes.sort((a, b) => a.display.localeCompare(b.display));



        this.formConfig = {
            buttonText: this.props.intl.formatMessage({ id: 'REPORTS.BUTTON.STARTPROCESS' }),
            fields: [
                { label: this.props.intl.formatMessage({ id: 'REPORTS.FIELDS.REPORTTYPE' }), dataField: 'registertype', editorType: 'dxSelectBox', editorOptions: { dataSource: this.reportTypes, valueExpr: 'value', displayExpr: 'display' } },
                { resource: 'REPORTS.FIELDS.COMPANY', dataField: 'companyid', editorType: 'dxSelectBox', required: false, editorOptions: { dataSource: this.getCompaniesListOfUserContractsDataSource(), valueExpr: 'companyid', displayExpr: 'name', placeholder: this.props.intl.formatMessage({ id: "REPORTS.MESSAGE.COMPANY_PLACEHOLDER" }) } },
                { resource: "GENERAL.FORMS.FIELD.START_DATE", dataField: 'startdate', editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: new Date(1900, 0, 1), max: new Date(2079, 5, 6) } }, validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: 'REPORTS.FORM.VALIDATION.STARTDATE' }) }, visible: (obj) => !['ProjectList', 'AssetDetails', 'HubUser', 'ContractSummary', 'ContractOtherDate', 'ContractOtherDateChange', 'ContractOtherPrice', 'ContractOtherPriceChange'].includes(obj.registertype) },
                { resource: "GENERAL.FORMS.FIELD.END_DATE", dataField: 'enddate', editorType: 'dxDateBox', editorOptions: { calendarOptions: { min: new Date(1900, 0, 1), max: new Date(2079, 5, 6) } }, validation: { type: 'range', min: new Date(1900, 0, 1), max: new Date(2079, 5, 6), warning: this.props.intl.formatMessage({ id: 'REPORTS.FORM.VALIDATION.ENDDATE' }) }, visible: (obj) => !['ProjectList', 'AssetDetails', 'HubUser', 'ContractSummary', 'ContractOtherDate', 'ContractOtherDateChange', 'ContractOtherPrice', 'ContractOtherPriceChange'].includes(obj.registertype) },
                {
                    resource: 'REPORTS.FIELDS.INCLUDECUSTOMFIELDS',
                    dataField: 'includecustomfields',
                    editorType: 'dxSwitch',
                    visible: (obj) => !['ContractOtherDate', 'ContractOtherDateChange', 'ContractOtherPrice', 'ContractOtherPriceChange'].includes(obj.registertype),
                    editorOptions: { switchedOffText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" }), switchedOnText: this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" }) }
                },
                {
                    label: this.props.intl.formatMessage({ id: 'REPORTS.FIELDS.CONTRACTSTATUS' }),
                    visible: (obj) => !['ProjectList', 'AssetDetails', 'HubUser', 'ContractUser', 'SystemUser'].includes(obj.registertype),
                    groupHorizontalDisplay: true,
                    fields: [
                        { label: this.props.intl.formatMessage({ id: 'REPORTS.FIELDS.COMPLETE' }), dataField: 'contractcomplete', required: false, editorType: 'dxSelectBox', editorOptions: { dataSource: recordStatusesOptions, valueExpr: 'valueExpr', displayExpr: 'displayExpr', defaultValue: '2' } },
                        { label: this.props.intl.formatMessage({ id: 'REPORTS.FIELDS.ARCHIVED' }), dataField: 'contractarchived', required: false, editorType: 'dxSelectBox', editorOptions: { dataSource: recordStatusesOptions, valueExpr: 'valueExpr', displayExpr: 'displayExpr', defaultValue: '2' } }
                    ]
                },
                {
                    label: this.props.intl.formatMessage({ id: 'REPORTS.FIELDS.PROJECTSTATUS' }),
                    visible: (obj) => !['ProjectList', 'AssetDetails', 'HubUser', 'ContractUser', 'SystemUser'].includes(obj.registertype),
                    groupHorizontalDisplay: true,
                    fields: [
                        { label: this.props.intl.formatMessage({ id: 'REPORTS.FIELDS.COMPLETE' }), dataField: 'projectcomplete', required: false, editorType: 'dxSelectBox', editorOptions: { dataSource: recordStatusesOptions, valueExpr: 'valueExpr', displayExpr: 'displayExpr', defaultValue: '2' } },
                        { label: this.props.intl.formatMessage({ id: 'REPORTS.FIELDS.ARCHIVED' }), dataField: 'projectarchived', required: false, editorType: 'dxSelectBox', editorOptions: { dataSource: recordStatusesOptions, valueExpr: 'valueExpr', displayExpr: 'displayExpr', defaultValue: '2' } }
                    ]
                },

            ]
        };




        this.state = {
            processing: false,
            reportsList: null,
            showCompanies: false,
            hideCustomFields: false,
            hideContractProjectFields: false,
            hideDateFields: false
        }
    }

    componentDidMount() {
        this.loadReports();
        this.setRefreshTimer();
    }
    setRefreshTimer = () => {
        this.timer = setTimeout(() => {
            if (!this.state.processing) {
                this.loadReports();
            }
            this.setRefreshTimer();
        }, 20000);
    }
    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }
    loadReports = () => {
        registerCrud.getRegisterList()
            .then(resp => {
                // console.log(resp.data);
                this.setState({ reportsList: JSON.parse(resp.data.aggregatelist) });
            })
            .catch(err => {
                console.log(err);
            })
    }

    getCompaniesListOfUserContractsDataSource = () => {
        const datasource = {
            loadMode: 'raw',
            cacheRawData: true,
            load: () => {
                var promise = new Promise((resolve) => {
                    companiesCrud.getCompaniesListOfUserContracts()
                        .then(resp => {
                            resolve(resp.data);
                        })
                });
                return promise;
            }
        }
        return datasource;
    }
    waitFor = (ms) => new Promise(r => setTimeout(r, ms));

    export = async (registertype, dataArr) => {
        try {
            const reportName = this.reportTypes.find(x => x.value === registertype).display;

            var byteCharacters = atob(dataArr);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);

            await saveAs(new Blob([byteArray], { type: 'application/octet-stream' }), reportName + '.xlsx');


            this.setState({ processing: false });
        } catch (error) {
            this.setState({ processing: false });
            this.props.enqueueSnackbar(error.toString(), { variant: 'error', });
            console.log(error);
        }
    }

    onStartNewReport = async (newReportObj, setSubmitting) => {

        const startDate = newReportObj.startdate;
        const endDate = newReportObj.enddate;
        newReportObj.startdate = startDate ? moment(newReportObj.startdate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        newReportObj.enddate = endDate ? moment(newReportObj.enddate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

        if (isDateRestrictionEnabled) {
            const differenceDate = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
            if (differenceDate > 365) {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'REPORTS.MESSAGE.REPORTPROCESSOUTOFDATE' }), { variant: 'error', });
                setSubmitting(false);
                return;
            }
        }

        newReportObj.includecustomfields = isCustomFieldsEnabled && newReportObj.includecustomfields === true;

        if (newReportObj.includecustomfields && !newReportObj.companyid) {
            //COMPANY SELECTION IS MANDATORY IF CUSTOM-FIELDS ENABLED
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'REPORTS.MESSAGE.COMPANY_REQUIRED_FOR_CF' }), { variant: 'error', });
            setSubmitting(false);
            return;
        }
        // console.log(newReportObj);
        try {
            await registerCrud.createRegister(newReportObj);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'REPORTS.MESSAGE.REPORTPROCESSSTARTED' }), { variant: 'success', });
            this.loadReports();
            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
            this.props.enqueueSnackbar(error.toString(), { variant: 'error', });
            console.log(error);
        }
    }
    // onRowValidating = async (e) => {
    //     newReportObj.startdate = moment(newReportObj.startdate).format('YYYY-MM-DD');
    //     newReportObj.enddate = moment(newReportObj.enddate).format('YYYY-MM-DD');

    // }
    isDownloadIconVisible = (e) => {
        return e.row.data.status === 'Complete' && !this.state.processing;
    }
    onDownloadClick = (e) => {
        e.event.preventDefault();
        this.setState({ processing: true }, () => {
            // this.gridRef.current.instance.repaint();
            registerCrud.getRegister(e.row.data.registerid)
                .then(resp => {

                    this.export(e.row.data.registertype, resp.data);
                })
                .catch(err => {
                    this.setState({ processing: false });
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                    console.log(err);
                });
        });

    }
    // onValueChanged = (field, value) => {
    //     if (isCustomFieldsEnabled && field === "includecustomfields") {
    //         this.setState({ showCompanies: value });
    //     }
    //     else if (field === "registertype") {
    //         if (value === "HubUser" || value === "AssetDetails") {
    //             this.setState({ hideDateFields: true, hideCustomFields: true, hideContractProjectFields: true });
    //         } else if (value === "ContractUser" || value === "SystemUser") {
    //             this.setState({ hideDateFields: false, hideCustomFields: true, hideContractProjectFields: true });
    //         } else if (value === "ProjectList") {
    //             this.setState({ hideDateFields: true, hideCustomFields: false, hideContractProjectFields: true });
    //         } else {
    //             this.setState({ hideDateFields: false, hideCustomFields: false, hideContractProjectFields: false });
    //         }
    //     }
    // }
    render() {


        return (
            <Portlet>
                <PortletHeader
                    title={this.props.intl.formatMessage({ id: 'REPORTS.TITLE.AGGREGATEREPORTS' })} />
                <PortletBody >
                    <SimpleBox title={this.props.intl.formatMessage({ id: 'REPORTS.TITLE.STARTANEWPROCESS' })}>
                        <BiDynamicForm
                            config={this.formConfig}
                            onSubmitHandler={this.onStartNewReport}
                        // valueChangeHandler={this.onValueChanged}
                        />
                    </SimpleBox>
                    <div style={{ height: 20 }} />
                    <SimpleBox title={this.props.intl.formatMessage({ id: 'REPORTS.TITLE.AGGREGATEREPORTSLIST' })}>
                        <div style={{ width: '100%', height: '67vh' }}>
                            {
                                this.state.processing && (
                                    <LinearProgress style={{ margin: 20 }} />
                                )
                            }
                            <DataGrid
                                height={470}
                                dataSource={this.state.reportsList}
                                keyExpr="registerid"
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                remoteOperations={false}
                                allowColumnReordering={true}
                                columnAutoWidth={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode='widget'
                                wordWrapEnabled={true}
                                noDataText={this.props.intl.formatMessage({ id: 'REPORTS.TITLE.NOREPORTSINPROGRESS' })}
                                style={{ width: '100%', height: '100%', maxHeight: '67vh', ...this.props.style }}
                            >
                                <HeaderFilter visible={true} />
                                <FilterRow applyFilter="auto" visible={true} />
                                <FilterPanel visible={true} />
                                {/* <ColumnChooser enabled={true} /> */}
                                <ColumnFixing enabled={true} />
                                {/* <StateStoring enabled={true} type="localStorage" storageKey={'AggregateReportsRegirterV1'} /> */}

                                <Column caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.REPORTTYPE' })} dataField="registertype" lookup={{ dataSource: this.reportTypes, valueExpr: 'value', displayExpr: 'display' }} />
                                <Column caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.STATUS' })} dataField="status" />
                                <Column caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.REPORTSTART' })} dataField="startdate" dataType='date' format={(date) => moment(date).format('DD/MM/YYYY')} />
                                <Column caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.REPORTEND' })} dataField="enddate" dataType='date' format={(date) => moment(date).format('DD/MM/YYYY')} />
                                <Column caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.PROCESSSTART' })} dataField="createddate" dataType='date' format={(date) => moment(date).format('DD/MM/YYYY  HH:mm')} />
                                <Column caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.PROCESSEND' })} dataField="completeddate" dataType='date' format={(date) => moment(date).format('DD/MM/YYYY  HH:mm')} />
                                <Column caption={this.props.intl.formatMessage({ id: 'REPORTS.CAPTION.DOWNLOAD' })} type="buttons" buttons={[{ hint: 'Download', icon: 'download', visible: this.isDownloadIconVisible, onClick: this.onDownloadClick }]} />

                                <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                                <Summary>
                                    <TotalItem column={0} summaryType="count" />
                                </Summary>
                            </DataGrid>
                        </div>

                        {/* <DataGridBase
                            version="2"
                            gridRef={this.gridRef}
                            height={470}
                            name="Aggregate Reports"
                            dataSource={this.state.reportsList}
                            keyExpr="registerid"
                            columns={[
                                { caption: "Report Type", dataField: "registertype", lookup: { dataSource: reportTypes, valueExpr: 'value', displayExpr: 'display', } },
                                { caption: "Status", dataField: "status" },
                                { caption: "Report Start", dataField: "startdate", dataType: 'date' },
                                { caption: "Report End", dataField: "enddate", dataType: 'date' },
                                { caption: "Process Start", dataField: "createddate", dataType: 'date', format: (date) => moment(date).format('DD/MM/YYYY HH:mm') },
                                { caption: "Process End", dataField: "completeddate", dataType: 'date', format: (date) => moment(date).format('DD/MM/YYYY HH:mm') },
                                { caption: "Actions", type: "buttons", buttons: [{ hint: 'Download', icon: 'download', visible: this.isDownloadIconVisible, onClick: this.onDownloadClick }] },
                            ]}
                        /> */}
                    </SimpleBox>
                </PortletBody>
            </Portlet>
        )
    }
}

const mapStateToProps = store => ({
    userProfile: store.auth.userProfile
});


export default injectIntl(withSnackbar(connect(mapStateToProps)(AggregateReports)));