import React from "react";
import { Route, Routes } from "react-router-dom";
import CompensationListAll from './compensation/ListAll';

class CompensationRegisterRouter extends React.Component {
    constructor(props) {
        super(props);
        this.notificationName = props.workflowItem.notificationName;
        this.abbreviation = props.workflowItem.abbreviation;

        this.notificationAbbreviation_PM = (this.props.workflowsQA['5'] || {}).abbreviation;
        this.notificationAbbreviation_SUP = (this.props.workflowsQA['7'] || {}).abbreviation;
        this.notificationAbbreviation_CLIENT = (this.props.workflowsQA['76']||{}).abbreviation;

        this.taskOrderOptionEnabled = false;
        try {
            this.taskOrderOptionEnabled = (props.placeholders.find(ph => ph.replacementKey === 'TaskOrderOptionEnabled')
                .values.find(phItem => phItem.field === 'TaskOrderOptionEnabled').isActive);
        } catch (error) {
            console.log('TaskOrderOptionEnabled placeholder not available!');
            this.taskOrderOptionEnabled = false;
        }
        if (this.taskOrderOptionEnabled) {
            this.taskOrderNotificationName = (this.props.workflowsQA['38'] || {}).notificationName;
        }

        this.projectOrderOptionEnabled = false;
        try {
            this.projectOrderOptionEnabled = (props.placeholders.find(ph => ph.replacementKey === 'ProjectOrderOptionEnabled')
                .values.find(phItem => phItem.field === 'ProjectOrderOptionEnabled').isActive);
        } catch (error) {
            console.log('ProjectOrderOptionEnabled placeholder not available!');
            this.projectOrderOptionEnabled = false;
        }
        if (this.projectOrderOptionEnabled) {
            this.projectOrderNotificationName = (this.props.workflowsQA['72'] || {}).notificationName;
        }


        
        this.ewRelationEnabled = true;
        try {
            this.ewRelationEnabled = (props.placeholders.find(ph => ph.replacementKey === 'EWRelationEnabled')
                .values.find(phItem => phItem.field === 'EWRelationEnabled').isActive);
        } catch (error) {
            console.log('EWRelationEnabled placeholder not available!');
            this.ewRelationEnabled = true;
        }

        if (this.ewRelationEnabled) {
            this.ewNotificationName = (this.props.workflowsQA['1'] || this.props.workflowsQA['2']).notificationName;
        }
    }
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { workflowItem } = this.props;


        return (
            <Routes caseSensitive>
                <Route
                    path={workflowItem.config.paths.list}
                    exact
                    element={<CompensationListAll
                        {...this.props}
                        taskOrderOptionEnabled={this.taskOrderOptionEnabled}
                        taskOrderNotificationName={this.taskOrderNotificationName}
                        projectOrderOptionEnabled={this.projectOrderOptionEnabled}
                        projectOrderNotificationName={this.projectOrderNotificationName}
                        ewRelationEnabled={this.ewRelationEnabled}
                        ewNotificationName={this.ewNotificationName}
                        notificationAbbreviation_PM={this.notificationAbbreviation_PM}
                        notificationAbbreviation_SUP={this.notificationAbbreviation_SUP}
                        notificationAbbreviation_CLIENT={this.notificationAbbreviation_CLIENT}
                    />}
                />
            </Routes>
        );
    }
};

export default (CompensationRegisterRouter);