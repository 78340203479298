import React from 'react';
// import Box, { Item } from 'devextreme-react/box';




export default function dxRowBox(props) {
    return (
        <div style={{ width: '100%', float: 'right', display: 'flex', flexDirection: 'row-reverse' }}>
            {props.secondary && (<div>{props.secondary}</div>)}
            <div style={{
                width: '100%',
                fontWeight: 400,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                boxOrient: 'vertical',
                overflow: 'hidden',
                marginLeft: 5,
            }}>
                {props.hasOwnProperty('strong') && (<strong style={{ marginRight: 5 }}>{props.strong}</strong>)}
                {props.title}
            </div>
        </div>
    );
    // return (
    //     <div >
    //         <Box
    //             direction="row"
    //             width="100%">
    //             <Item ratio={3}>
    //                 <div style={{
    //                     fontWeight: 400,
    //                     textOverflow: 'ellipsis',
    //                     whiteSpace: 'nowrap',
    //                     boxOrient: 'vertical',
    //                     overflow: 'hidden',
    //                     marginLeft:5,
    //                 }}>
    //                     {props.strong && (<strong style={{ marginRight: 5 }}>{props.strong}</strong>)}
    //                     {props.title}
    //                 </div>
    //             </Item>
    //             {props.secondary && (
    //                 <Item align="right">
    //                     <div style={{ float: 'right', display: 'flex', flexDirection: 'row-reverse' }}>
    //                         {props.secondary}
    //                     </div>
    //                 </Item>
    //             )}
    //         </Box>
    //     </div>

    // )
}