import React from 'react';
import { Popup } from 'devextreme-react/popup';
// import DataGridBase from '../../../../../partials/layout/DataGridBase';
import { DataGrid } from 'devextreme-react';

const columns = [
    { caption: "Copy Template ID", dataField: "copiedTemplateID" },
    { caption: "Copy Template Name", dataField: "copiedTemplateName" },
    { caption: "Copied By Account ID", dataField: "copiedById" },
    { caption: "Copied By Name", dataField: "copiedByName" },
    { caption: "Date Time", dataField: "copiedDateTime" },
];

class CopyTracksModal extends React.Component {

    state = {
        records: [],
    }
    componentDidUpdate(revProps, prevState) {
        //Modal closed
        if (revProps.visible === true && this.props.visible === false) {
            this.setState({ records: [] });
        }
        // Modal opened
        else if (revProps.visible !== this.props.visible && this.props.visible === true) {
            let newRecords = [];
            if (this.props.records) {
                newRecords = JSON.parse(JSON.stringify(this.props.records));
            }
            this.setState({ records: newRecords });
        }
    }
    onClose = () => {
        this.props.onDialogResult();
    }
    groupTemplate = (item) => {
        return <div>{`Copied: ${item.copiedByName} ${item.copiedDateTime}`}</div>;
    }

    render() {
        return (
            <Popup
                visible={this.props.visible}
                onHiding={this.onClose}
                dragEnabled={!this.props.disabled ? true : false}
                hideOnOutsideClick={true}
                showTitle={true}
                title={this.props.title}
                width={900}
                height='auto'>
                <div>
                    {
                        this.state.records.length > 0 ? (
                            <DataGrid
                                dataSource={this.state.records}
                                columns={columns}
                                showBorders={true}
                                height={300}
                                showColumnLines={true}
                                showRowLines={true}
                                columnAutoWidth={true}
                            />
                        ) :
                        (
                            <div>No copy history records found!</div>
                        )
                    }
                </div>
            </Popup>
        );
    }
}

export default CopyTracksModal;