import React from 'react';
import { connect } from "react-redux";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { withSnackbar } from 'notistack';
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { injectIntl } from "react-intl";
import * as contractDuck from '../../../../store/ducks/contract.duck';

class ActivityScheduleList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assets: null
        };
        this.columns = [
            { resource: "CONTRACT.EMAILRCP.FIELD.ID", dataField: "AssetID" },
            { resource: 'GENERAL.FORMS.FIELD.ASSET_NAME', dataField: "AssetName" },
            { resource: 'GENERAL.FORMS.FIELD.ASSET_CODE', dataField: "AssetCode" },
            { resource: "GENERAL.FORMS.FIELD.ASSET_TYPE", dataField: "AssetType" },
            { resource: "GENERAL.FORMS.FIELD.ASSET_LINK", dataField: "AssetLink", visible:false },
        ];

    }

    componentDidMount() {
        this.loadAssets();
    }
    loadAssets = async () => {
        try {
            const assets = await contractDuck.getAssets()
            this.setState({ assets: assets });
        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }


    render() {
        if (!this.state.assets) {
            return (<PortletSpinner />);
        }

        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="1"
                        title={this.props.intl.formatMessage({ id: 'NAVIGATION.MENU.ASSETS' })}
                        name={this.props.intl.formatMessage({ id: 'NAVIGATION.MENU.ASSETS' })}
                        dataSource={this.state.assets}
                        keyExpr="AssetID"
                        rowAlternationEnabled={true}
                        columns={this.columns}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}


const mapStateToProps = (store) => ({
    contract: store.contract.contract,
    contractUser: store.contract.user,
});


export default injectIntl(withSnackbar(connect(mapStateToProps)(ActivityScheduleList)));
