import axios from '../shared/axios';

export function getPowerBICustomBookmarkViews(companyid) {
  return axios.get(`/api/PowerBICustomBookmarkView/LoadPowerBICustomBookmarkViews?companyid=${companyid}`);
}

export function getPowerBICustomBookmarkViewByName(bookmarkname) {
  return axios.get(`/api/PowerBICustomBookmarkView/LoadPowerBICustomBookmarkViewByName?bookmarkname=${bookmarkname}`);
}

export function savePowerBICustomBookmarkView(data) {
  return axios.post(`/api/PowerBICustomBookmarkView/SavePowerBICustomBookmarkView`, data);
}

export function deletePowerBICustomBookmarkView(powerbicustombookmarkviewid) {
  return axios.delete(`/api/PowerBICustomBookmarkView/DeletePowerBICustomBookmarkView?powerbicustombookmarkviewid=${powerbicustombookmarkviewid}`);
}