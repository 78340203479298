import React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import RelationModules from './RelationModules';
//import DxEditorForm from '../../../../../../partials/layout/dxEditorForm';
import { injectIntl } from "react-intl";
import moment from 'moment';

class RelationModal extends React.Component {
    constructor() {
        super();
        this.state = {
            saveEnabled: false
        };
    }

    saveWorkflow = () => {
        const relatedItems = [];
        for (let wfgIndex = 0; wfgIndex < this.relations.length; wfgIndex++) {
            const WFG = this.relations[wfgIndex];
            for (let wfIndex = 0; wfIndex < WFG.items.length; wfIndex++) {
                const WF = WFG.items[wfIndex];
                if (WF.items && WF.items.length > 0) {
                    WF.items.forEach(item => {
                        relatedItems.push({ ...item, wfgid: WFG.id, wfid: WF.id, referenceType: WF.referenceType, createdate: moment().format('MM/DD/YYYY') });
                    });
                }
            }

        }
        this.props.onClose(relatedItems);
        this.setState({ saveEnabled: false });
    }
    onChange = (relations) => {
        this.relations = relations;
        this.setState({ saveEnabled: true });
    }
    render() {
        return (
            <Popup
                visible={this.props.isOpen}
                dragEnabled={true}
                hideOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.LABEL_EDIT_RELATIONS' })}
                width={'90%'}
                height='90vh'
            >
                {
                    this.props.isOpen && (
                        <div style={{ width: '100%', height: '100%' }}>
                            <RelationModules
                                relatedItems={this.props.relatedItems}
                                contractid={this.props.contractid}
                                onChange={this.onChange}
                                workflowGroups={this.props.workflowGroups}
                                availableWorkflowIds={this.props.availableWorkflowIds}
                                currentWFid={this.props.currentWFid}
                                currentNotificationID={this.props.currentNotificationID}
                            />
                        </div>
                    )
                }

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_CANCEL' }), onClick: () => { this.props.onClose(); } }}>
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SAVE_CHANGES' }),
                        type: 'success',
                        disabled: !this.state.saveEnabled,
                        onClick: this.saveWorkflow
                    }}>
                </ToolbarItem>
            </Popup>
        );
    }
}

export default injectIntl(RelationModal);