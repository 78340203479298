import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as contract from "./ducks/contract.duck";
import * as contracts from "./ducks/contracts.duck";
import * as companies from "./ducks/companies.duck";
import * as company from "./ducks/company.duck";
import * as projects from "./ducks/projects.duck";
import * as project from "./ducks/project.duck";
import * as application from "./ducks/application.duck";
import * as preferences from "./ducks/preferences.duck";
import * as templates from "./ducks/templates.duck";
import * as notifications from "./ducks/notifications.duck";
import { metronic } from "../../_metronic";


export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  contract: contract.reducer,
  contracts: contracts.reducer,
  companies: companies.reducer,
  company: company.reducer,
  projects: projects.reducer,
  project: project.reducer,
  application: application.reducer,
  preferences: preferences.reducer,
  templates: templates.reducer,
  notifications: notifications.reducer
});

export function* rootSaga() {
  yield all([auth.saga(),
  company.saga(),
  companies.saga(),
  contract.saga(),
  contracts.saga(),
  projects.saga(),
  project.saga(),
  application.saga(),
  preferences.saga(),
  templates.saga()]);
}
