import React from 'react';
import { connect } from "react-redux";
import * as contractsDuck from '../../store/ducks/contracts.duck';
import PortletSpinner from '../../partials/layout/PortletSpinner';
import ContractsGrid from './Contracts/ContractsGrid';


class Contracts extends React.Component {
  constructor(props) {
    super(props);
    this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
  }
  componentDidMount() {
    // removeAsideMenu();
    // minimizeAsideMenu();
    this.props.loadContracts();
  }
  componentWillUnmount() {
    // displayAsideMenu();
    // minimizeAsideMenu();
  }

  render() {
    if (!this.props.contracts) {
      return (<PortletSpinner />);
    }
    return (
      <>
        <ContractsGrid contracts={this.props.contracts} />
      </>
    );
  }
}



const mapStateToProps = store => ({
  contracts: store.contracts.contracts
});

const mapDispatchToProps = {
  loadContracts: contractsDuck.actions.fetchStart
}

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);