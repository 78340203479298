import React from 'react';
import BaseDetailPage from '../../_CustomComponents/BaseDetailPage';
import { Navigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import * as compensationEventCrud from '../../../../crud/compensationEvent.crud';
import * as inspectionCrud from '../../../../crud/inspectionDefect.crud';
import * as quotationCrud from '../../../../crud/quotation.crud';
import * as fileCrud from '../../../../crud/file.crud';
import axios from 'axios';
import ActionModal from '../../_CustomComponents/ActionModal';
import ExtensionDocument from './Document';
import ReplyDocument from './ReplyDocument';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { Alert } from "react-bootstrap";
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import moment from 'moment';
import Relations from '../../_CustomComponents/Relation/Relations';
import { addDays } from 'date-fns';
import { FormattedMessage } from "react-intl";
import {
    compensationStatusFilterPM,
    compensationStatusFilterSupplier,
    editingDatePropertySupplier,
    editingDatePropertyPM
} from './CustomForm';
import PageToolbar from '../../_CustomComponents/PageToolbar';
import Common from '../../../../shared/common';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import { getCompensationPathWithType } from '../compensation/ListAll';
import EmailNotifications from '../../_CustomComponents/EmailNotifications';
const initialState = {
    notification: null,
    compensation: null,
    files: null,
    showModal: false,
    saving: false,
    // isDxFormValid: true,
    isChanged: false,
    //NEW REPLY STATES
    reply: null,
    replyFiles: null,
    isAwaitingReply: false,
    modalTitle: '',
    modalText: '',
    modalMode: '',
    relationCount: 0,
    relations: null
};

class ExtensionDetailPage extends BaseDetailPage {
    constructor(props) {
        super(props);
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.isRelationsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true' || process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true';
        this.printIssueDivRef = React.createRef();
        this.printAcceptanceDivRef = React.createRef();
        //NEW REPLY
        this.validatorRef = React.createRef();
        this.validatorName = 'ExtensionValidator';
        if (props.mode === 'PM') {
            this.compensationStatusFilter = compensationStatusFilterPM;
            this.editingProperty = editingDatePropertyPM;
            this.editingUpdateProperty = 'projectmanagersigneddatedisplay';
        }
        else if (props.mode === 'SUPPLIER') {
            this.compensationStatusFilter = compensationStatusFilterSupplier;
            this.editingProperty = editingDatePropertySupplier;
            this.editingUpdateProperty = 'requiredatedisplay';
        }

        this.state = { ...initialState };
    }
    componentDidMount() {
        this.fetchInspectionDefect();
        this.fetchFiles();
        this.fetchReplyFiles();
    }

    componentDidUpdate(prevprops, prevSatate) {
        if (this.props.params.notificationid !== prevprops.params.notificationid) {
            this.setState({ ...initialState }, () => {
                this.fetchInspectionDefect();
                this.fetchFiles();
                this.fetchReplyFiles();
            });
        }
    }

    getMailDescription = () => {
        return this.state.notification.replyactiondetail;
    }
    fetchInspectionDefect = async () => {
        await this.props.closeSnackbar('PersistentFormWarning');
        inspectionCrud.getInspectionDefect(this.props.params.notificationid)
            .then(async (response) => {
                const defect = response.data;
                if (defect.contractid !== this.props.contract.contractid ||
                    defect.type !== this.props.workflowItem.config.currentModelType) {
                    throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
                }
                if (defect.inspectiondate) {
                    defect.inspectiondate = new Date(defect.inspectiondate);
                }
                if (defect.notificationdate) {
                    defect.notificationdate = new Date(defect.notificationdate);
                }
                if (defect.assistancetestserchdefect) {
                    defect.assistancetestserchdefect = moment(defect.assistancetestserchdefect, "DD/MM/YYYY").toDate();
                    // const tempDate = new Date(defect.assistancetestserchdefect);
                    // if (isNaN(tempDate) || isNaN(tempDate.getTime())) {
                    //     //Old version UI must be formatted differently

                    // }
                    // else {
                    //     defect.assistancetestserchdefect = tempDate;
                    // }
                }
                if (defect.inspectiontime) {
                    defect.inspectiontime = Number(defect.inspectiontime);
                }
                if (defect.draftreplydate) {
                    defect.draftreplydate = new Date(defect.draftreplydate);
                }
                const { data: compObj } = await compensationEventCrud.getCompensationEvent(defect.inspectiontime);
                if (defect.showtasknotification !== 1) {
                    if (!this.compensationStatusFilter.includes(compObj.status)) {
                        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.WF.EXTENSION.WARNING.COMPENSATION_UPDATED' }), { variant: 'error', key: 'PersistentFormWarning', persist: true });
                        defect.inspectiontime = null;
                    }
                    else {
                        if (!moment(defect.assistancetestserchdefect).isSame(compObj[this.editingProperty], 'day')) {
                            defect.assistancetestserchdefect = new Date(compObj[this.editingProperty]);
                            if (this.props.mode === 'PM') {
                                defect.location = compObj.lastquotereference ? compObj.lastquotereference : '-';
                            }
                            //defect.replyaction = getCompensationReference(compObj, this.props);
                            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.WF.EXTENSION.WARNING.FIELDS_UPDATED' }), { variant: 'warning', key: 'PersistentFormWarning', persist: true });
                        }
                        else {
                            const quoteRef = compObj.lastquotereference ? compObj.lastquotereference : '-';
                            if (this.props.mode === 'PM' && defect.location !== quoteRef) {
                                defect.location = quoteRef;
                                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'CONTRACT.WF.EXTENSION.WARNING.FIELDS_UPDATED' }), { variant: 'warning', key: 'PersistentFormWarning', persist: true });
                            }
                        }
                    }
                }
                const awaitingReply = (defect.showtasknotification === 1 && defect.replystatus === 'Awaiting Acceptance');
                const replyItem = {
                    replystatus: defect.draftinspectionstatus,
                    replycomment: defect.draftreplycomment,
                    customfieldvalues: defect.customfieldvalues
                };

                this.setState({
                    notification: defect,
                    compensation: compObj,
                    reply: replyItem,
                    isAwaitingReply: awaitingReply,
                    saving: false,
                    isChanged: false,
                    relations: defect.relations,
                    relationCount: Common.GetRelationCount(defect, this.props.accessibleWfIds)
                }, () => { this.props.setReference(this.state.notification.referenceid); });
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            });
    }
    fetchCompensation = (compensationid) => {
        compensationEventCrud.getCompensationEvent(compensationid)
            .then(response => {
                this.setState({ compensation: response.data });
            })
            .catch(err => {
                if (!axios.isCancel(err)) {
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            });
    }

    fetchFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType)
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ files: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    fetchReplyFiles = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.notificationid, this.props.documentType + 'Reply')
            const filesList = JSON.parse(data.aggregatelist)
            this.setState({ replyFiles: filesList });
            return filesList;
        } catch (err) {
            if (!axios.isCancel(err)) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            }
        }
    }
    printHandler = () => {
        this.printIssueRef.handlePrint();
    }
    printAcceptanceHandler = () => {
        this.printAcceptanceRef.handlePrint();
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        if (!validationResult.isValid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), { variant: 'warning', });
        }
        return validationResult.isValid && !this.state.saving;
    }
    showModal = (mode, postActionNavigateObj) => {
        if (mode === 'COMMUNICATE') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'SAVE_DRAFT') {
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'DELETE_DRAFT') {
            this.setState({
                showModal: true,
                modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
                modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
                modalMode: mode,
            });
        }
        else if (mode === 'REPLY_DRAFT') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_REPLY_DRAFT_NOTIFY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
                    modalMode: mode,
                });
            }
        }
        else if (mode === 'REPLY_SEND') {
            //show reply tab
            this.tabPanel.instance.option("selectedIndex", 1);
            if (this.validateForm()) {
                this.setState({
                    showModal: true,
                    modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SEND_REPLY' }),
                    modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_SENDING' }, { PARTY_NAME: this.props.globalResources[this.props.workflowItem.notifyingParty + "_NAME"] }),
                    modalMode: mode,
                });
            }
        }
    }
    onModalResponse = (response, params) => {
        this.setState({ showModal: false, saving: response === 'OK' }, async () => {
            try {
                if (response === 'OK') {


                    if (this.state.modalMode === 'COMMUNICATE') {
                        const notification = { ...this.state.notification };
                        // notification.createby = this.props.contractUser.name;
                        notification.createbyid = this.props.contractUser.accountid;
                        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');
                        notification.replystatus = 'Awaiting Acceptance';
                        notification.showtasknotification = 1;
                        if (this.props.workflowItem.replyPeriodOption === "ORIGINAL_DATE") {
                            notification.replyrequireddate = new Date(notification.assistancetestserchdefect);
                        }
                        else {
                            notification.replyrequireddate = moment(addDays(new Date(), this.props.replyPeriod)).toDate();
                        }
                        const { data: compObj } = await compensationEventCrud.getCompensationEvent(Number(notification.inspectiontime));
                        if (!this.compensationStatusFilter.includes(compObj.status)) {
                            throw new Error(this.props.intl.formatMessage({ id: 'CONTRACT.WF.EXTENSION.WARNING.COMPENSATION_UPDATED' }));
                        }
                        else {
                            if (!moment(notification.assistancetestserchdefect).isSame(compObj[this.editingProperty], 'day')) {
                                throw new Error(this.props.intl.formatMessage({ id: 'CONTRACT.WF.EXTENSION.WARNING.COMPENSATION_UPDATED_RESRESH' }));
                            }
                            else {
                                const quoteRef = compObj.lastquotereference ? compObj.lastquotereference : '-';
                                if (this.props.mode === 'PM' && notification.location !== quoteRef) {
                                    throw new Error(this.props.intl.formatMessage({ id: 'CONTRACT.WF.EXTENSION.WARNING.COMPENSATION_UPDATED_RESRESH' }));
                                }
                            }
                        }
                        const docBodyConfig = this.documentBodyConfig(notification, this.props.resources);
                        const docConfig = await this.documentConfig(notification, docBodyConfig, this.props.contractUser.name, false, null, null, this.props.workflowItem.form_NotifyCompanyName);
                        notification.pdfjsoncontent = JSON.stringify(docConfig);

                        this.saveExtension(notification, null, this.state.modalMode);

                    }
                    else if (this.state.modalMode === 'SAVE_DRAFT') {

                        const notification = { ...this.state.notification };
                        notification.createbyid = this.props.contractUser.accountid;
                        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');
                        // notification.createby = this.props.contractUser.name;
                        if (this.props.workflowItem.replyPeriodOption === "ORIGINAL_DATE") {
                            notification.replyrequireddate = new Date(notification.assistancetestserchdefect);
                        }
                        else {
                            notification.replyrequireddate = moment(addDays(new Date(), this.props.replyPeriod)).toDate();
                        }
                        this.saveExtension(notification, null, this.state.modalMode, params ? params.userid : null);

                    }
                    else if (this.state.modalMode === 'DELETE_DRAFT') {

                        const inspection = { ...this.state.notification };
                        const inspectiondefectid = inspection.inspectiondefectid;
                        this.deleteExtensionDraft(inspectiondefectid);

                    }
                    else if (this.state.modalMode === 'REPLY_DRAFT') {

                        const reply = { ...this.state.reply };
                        const notification = { ...this.state.notification };
                        notification.draftinspectionstatus = reply.replystatus;
                        notification.draftreplycomment = reply.replycomment;
                        notification.draftreplystatus = 'Drafted';
                        notification.draftreplybyid = this.props.contractUser.accountid;
                        if ((params || {}).userid) {
                            notification.draftreplytoid = params.userid;
                        }
                        notification.draftreplydatedisplay = moment().format('DD/MM/YYYY');
                        for (let key in reply) {
                            if (key.startsWith("CF_")) {
                                notification[key] = reply[key];
                            }
                        }
                        this.saveExtension(notification, null, this.state.modalMode, (params || {}).userid);

                    }
                    else if (this.state.modalMode === 'REPLY_SEND') {
                        const reply = { ...this.state.reply };
                        if (!this.replyValidation(reply)) {
                            //FINAL REPLY FIELDS CHECK FAILED
                            throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_FIELDS_MISSING' }))
                        }
                        const notification = { ...this.state.notification };
                        notification.replystatus = reply.replystatus;
                        notification.replycomment = reply.replycomment;
                        notification.inspectionbyid = this.props.contractUser.accountid;

                        for (let key in reply) {
                            if (key.startsWith("CF_")) {
                                notification[key] = reply[key];
                            }
                        }

                        const docReplyBodyConfig = this.documentReplyBodyConfig(notification, this.props.resources, this.replyOptions);
                        const docReplyConfig = await this.documentConfig(notification, docReplyBodyConfig, this.props.contractUser.name, true, null, null, this.props.workflowItem.form_ReplyCompanyName);
                        notification.pdfreplyjsoncontent = JSON.stringify(docReplyConfig);

                        this.saveExtension(notification, null, this.state.modalMode);
                    }
                }
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                this.setState({ saving: false });
            }

        });
    }
    replyValidation = (reply) => {
        if (!reply.replystatus) { return false; }
        if (!reply.replycomment) { return false; }
        return true;
    }
    getCompensationURL = () => {
        const compensationPath = getCompensationPathWithType(this.state.compensation.compensationsource);
        return `${window.location.origin}/contract/${this.props.contract.contractid}/${this.props.workflowItem.paths.groupPath}/${compensationPath}/${this.state.compensation.compensationeventid}`;
    }

    documentBodyConfig = (notification, resources) => {
        const detailLabel = this.props.mode === 'SUPPLIER' ? resources.ExtensionSupplierLabel : resources.ExtensionPMLabel;
        const bodyConfig = [
            { resource: 'GENERAL.FORMS.FIELD.COMPENSATION', text: notification.replyaction, valueType: 'LINK', url: this.getCompensationURL() },
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.replyactiondetail },
            { resource: 'CONTRACT.WF.EXTENSION.FIELD.CURRENT_QUOTE_DATE', value: notification.assistancetestserchdefect },
            { resource: "CONTRACT.WF.EXTENSION.FIELD.PROPOSED_EXT_DATE", value: notification.inspectiondate },
            { label: detailLabel, value: notification.inspectiondetails, valueType: 'HTML', align: 'vertical' },
        ];
        if (this.props.mode === 'PM') {
            bodyConfig.splice(2, 0, { resource: "CONTRACT.WF.EXTENSION.FIELD.QUOTE_REFERENCE", value: notification.location });
        }



        return bodyConfig;
    }
    documentReplyBodyConfig = (notification, resources, replyOptions) => {
        return [
            { resource: 'GENERAL.FORMS.FIELD.SUBJECT', value: notification.replyactiondetail, },
            { resource: "GENERAL.FORMS.FIELD.REPLY", value: this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + notification.replystatus }) },
            { resource: "GENERAL.FORMS.FIELD.REPLY_DETAIL", value: notification.replycomment, valueType: 'HTML', align: 'vertical' },
        ]
    }
    updateCompensation = (compensation) => {
        if (!compensation.projectmanagersigneddatedisplay && compensation.projectmanagersigneddate) {
            compensation.projectmanagersigneddatedisplay = moment(new Date(compensation.projectmanagersigneddate)).format('DD/MM/YYYY');
        }
        // if (!compensation.officereceiveddatedisplay && compensation.officereceiveddate) {
        //     compensation.officereceiveddatedisplay = moment(new Date(compensation.officereceiveddate)).format('DD/MM/YYYY');
        // }
        // if (!compensation.notificationdatedisplay && compensation.notificationdate) {
        //     compensation.notificationdatedisplay = moment(new Date(compensation.notificationdate)).format('DD/MM/YYYY');
        // }
        if (!compensation.originalquotedatedisplay && compensation.originalquotedate) {
            compensation.originalquotedatedisplay = moment(new Date(compensation.originalquotedate)).format('DD/MM/YYYY');
        }
        if (!compensation.requiredatedisplay && compensation.requiredate) {
            compensation.requiredatedisplay = moment(new Date(compensation.requiredate)).format('DD/MM/YYYY');
        }
        // if (!compensation.projectmanagerresponsedatedisplay && compensation.projectmanagerresponsedate) {
        //     compensation.projectmanagerresponsedatedisplay = moment(new Date(compensation.projectmanagerresponsedate)).format('DD/MM/YYYY');
        // }
        // if (!compensation.contractorreceiveddatedisplay && compensation.contractorreceiveddate) {
        //     compensation.contractorreceiveddatedisplay = moment(new Date(compensation.contractorreceiveddate)).format('DD/MM/YYYY');
        // }
        if (!compensation.awaredatedisplay && compensation.awaredate) {
            compensation.awaredatedisplay = moment(new Date(compensation.awaredate)).format('DD/MM/YYYY');
        }
        // if (!compensation.assessmentdatedisplay && compensation.assessmentdate) {
        //     compensation.assessmentdatedisplay = moment(new Date(compensation.assessmentdate)).format('DD/MM/YYYY');
        // }
        Common.PropertyConversionsBeforeNoticeSaveRequest(compensation, this.props.customFields, this.props.contractUsers);
        return compensationEventCrud.saveCompensationEvent(compensation);
    }
    updateQuote = (quote) => {
        if (!quote.createdatedisplay && quote.createdate) {
            quote.createdatedisplay = moment(new Date(quote.createdate)).format('DD/MM/YYYY');
        }
        if (!quote.responsedatedisplay && quote.responsedate) {
            quote.responsedatedisplay = moment(new Date(quote.responsedate)).format('DD/MM/YYYY');
        }
        if (!quote.responsecreatedatedisplay && quote.responsecreatedate) {
            quote.responsecreatedatedisplay = moment(new Date(quote.responsecreatedate)).format('DD/MM/YYYY');
        }
        // if (!quote.notificationdatedisplay && quote.notificationdate) {
        //     quote.notificationdatedisplay = moment(new Date(quote.notificationdate)).format('DD/MM/YYYY');
        // }
        Common.PropertyConversionsBeforeNoticeSaveRequest(quote, this.props.customFields, this.props.contractUsers);
        return quotationCrud.saveCompensationQuote(quote);
    }


    deleteExtensionDraft = async (inspectionid) => {

        try {
            await inspectionCrud.deleteInspectionDraft(inspectionid);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
            this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ saving: false });
        }

    }
    saveExtension = async (extension, compensation, action, notifyuserid) => {
        try {
            const postEvents = () => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });

                if (this.state.postActionUrl) {
                    this.setState({ isChanged: false, saving: false, navigateto: this.state.postActionUrl.pathname });
                }
                else {
                    this.fetchInspectionDefect();
                }
            };
            if (!extension.replystatus) { throw Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_NOT_SELECTED' })); }

            if (!extension.inspectiondatedisplay && !extension.inspectiondatedisplay && extension.inspectiondate) {
                extension.inspectiondatedisplay = moment(extension.inspectiondate).format('DD/MM/YYYY HH:mm:ss');
            }
            if (!extension.draftreplydatedisplay && extension.draftreplydate) {
                extension.draftreplydatedisplay = moment(extension.draftreplydate).format('DD/MM/YYYY');
            }
            if (extension.assistancetestserchdefect && extension.assistancetestserchdefect instanceof Date) {
                extension.assistancetestserchdefect = moment(extension.assistancetestserchdefect).format('DD/MM/YYYY');
            }
            extension.replyingparty = this.props.workflowItem.replyingParty;
            extension.actioncode = action;
            extension.notifyuserlist = this.getNotifyUserListForUpdate(action, notifyuserid);
            Common.PropertyConversionsBeforeNoticeSaveRequest(extension, this.props.customFields, this.props.contractUsers);
            await inspectionCrud.updateInspectionDefect(extension);
            // if (compensation) {
            //     await this.updateCompensation(compensation);
            //     if (this.props.mode === 'PM' && extension.replystatus === 'Accepted') {
            //         const { companyid, projectid, contractid } = this.props.contract;
            //         const quotesResponse = await quotationCrud.getCompensationQuotes(companyid, projectid, contractid, compensation.compensationeventid)
            //         const quotes = JSON.parse(quotesResponse.data.aggregatelist);
            //         const quotesWaitingReply = quotes.filter(q => q.showtasknotification === 1 && q.responsestatus === 2);
            //         if (quotesWaitingReply.length > 0) {
            //             const promises = [];
            //             quotesWaitingReply.forEach(quote => {
            //                 quote.responsedatedisplay = extension.inspectiondatedisplay;
            //                 promises.push(this.updateQuote(quote));
            //             });
            //             await axios.all(promises);
            //         }
            //     }
            // }

            postEvents();
        } catch (err) {
            this.setState({ saving: false });
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }

    }
    fieldChanged = (field, value, isDxFormValid, additionalData) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            if (field === "assetid") {
                this.setState({
                    notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
                    isChanged: true
                });
            }
            else {
                this.state.notification[field] = value;
                if (!this.state.isChanged) {
                    this.setState({ isChanged: true });
                }
            }

            if (field === 'inspectiontime') {
                this.fetchCompensation(value);
            }
        }
    }
    replyFieldChanged = (field, value, isDxReplyFormValid, isCheckBoxValue) => {
        //SILENT STATE CHANGE
        //WE DONT WANT COMPONENT RERENDER WİTH THIS
        //this.setState({ notification: { ...this.state.notification, [field]: value } });
        if (field) {
            this.state.reply[field] = value;
            if (!this.state.isChanged) {
                this.setState({ isChanged: true });
            }
        }

    }
    renderToolbar = (documentIsFinal) => {
        const saveDisabled = (this.state.saving);
        return (
            <PageToolbar
                documentIsFinal={documentIsFinal}
                saveDisabled={saveDisabled}
                isAwaitingReply={this.state.isAwaitingReply}
                actions={{
                    showModal: this.showModal,
                    onPrintReply: this.printAcceptanceHandler,
                    onPrint: this.printHandler
                }}
                workflowItem={this.props.workflowItem}
                match={this.props.match}
                customFieldValues={this.state.notification.customfieldvalues}
                referenceid={this.state.notification.referenceid}
                contract={this.props.contract}
                relationParams={{
                    subject: this.getMailDescription(),
                    assetid: this.state.notification.assetid
                }}
                notification={this.state.notification}
            />
        );
    }
    tabDocument = () => {
        return (
            <ExtensionDocument
                {...this.props}
                compensation={this.state.compensation}
                mode={this.props.mode}
                printDivRef={this.printIssueDivRef}
                fieldChanged={this.fieldChanged}
                notification={this.state.notification}
                files={this.state.files}

                notificationid={this.props.params.notificationid}
            />
        );
    }
    tabRelations = () => {
        return (
            <Relations
                contractid={this.props.contract.contractid}
                workflowItem={this.props.workflowItem}
                workflowGroupItem={this.props.workflowGroupItem}
                notificationid={Number(this.props.params.notificationid)}
                relations={this.state.notification.relations}
                itemversion={this.state.notification.version}
                subject={this.state.notification.replyactiondetail}
                onCountChange={(count) => this.setState({ relationCount: count })}
            />
        );
    }



    tabReply = () => {
        if (!this.props.workflowItem.userFilters.replyNotice.communicateReplyAllowed && !this.props.workflowItem.userFilters.replyNotice.saveDraftReplyAllowed && this.state.isAwaitingReply) {
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <div style={{ width: '100%', maxWidth: '900px', margin: '20px' }}>
                        <Alert variant="warning" >
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_AWAITING_REPLY" values={{ ITEM_NAME: this.props.workflowItem.pageTitle }} />
                            </p>
                        </Alert>
                    </div>
                </div>
            )
        }
        else {
            return (
                <ReplyDocument
                    {...this.props}
                    isAwaitingReply={this.state.isAwaitingReply}
                    printDivRef={this.printAcceptanceDivRef}
                    fieldChanged={this.replyFieldChanged}
                    notification={this.state.notification}
                    reply={this.state.reply}

                    files={this.state.replyFiles}
                    notificationid={this.props.params.notificationid}
                />
            )
        }

    }
    tabEmailNotification = () => {

        return (
            <EmailNotifications
                {...this.props}

            />
        );

    };
    render() {
        if (!this.state.notification || !this.state.files || !this.state.compensation || !this.state.replyFiles) {
            return (<PortletSpinner />);
        }
        const documentIsFinal = this.state.notification.showtasknotification === 1;

        if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
            return (<Navigate replace to={this.props.workflowItem.paths.listPath} />)
        }

        return (
            <Portlet>
                <ReactToPrint
                    ref={el => (this.printIssueRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printIssueDivRef.current}
                />
                <ReactToPrint
                    ref={el => (this.printAcceptanceRef = el)}
                    trigger={() => (<div style={{ display: 'none' }} ></div>)}
                    content={() => this.printAcceptanceDivRef.current}
                />
                <NavigationBlocker
                    when={(this.state.isChanged || this.state.saving)}
                    message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
                />
                {this.state.navigateto &&
                    (
                        <Navigate replace to={this.state.navigateto} state={(this.state.postActionUrl || {}).state} />
                    )
                }
                <PortletHeader
                    title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                    toolbar={this.renderToolbar(documentIsFinal)}
                />
                <PortletBody>
                    <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
                        <div>
                            <ValidationSummary elementAttr={{ align: "right", paddingBottom: 50 }} id={this.validatorName}></ValidationSummary>
                        </div>
                        <TabPanel
                            ref={(ref) => this.tabPanel = ref}
                            width="100%"
                            deferRendering={false}
                            animationEnabled={true}
                            focusStateEnabled={true}
                            hoverStateEnabled={true}
                            swipeEnabled={false}
                        >
                            <Item icon="file" title={this.props.title} render={this.tabDocument} />
                            <Item icon="rename" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_REPLY' })} render={documentIsFinal ? this.tabReply : null} visible={documentIsFinal} />
                            {
                                this.isRelationsEnabled && (
                                    <Item icon="share" title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_RELATIONS' })} render={this.tabRelations} badge={this.state.relationCount > 0 ? this.state.relationCount : null} />
                                )
                            }
                            <Item
                                icon="bulletlist"
                                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
                            />
                        </TabPanel>
                    </ValidationGroup>
                </PortletBody>
                <ActionModal
                    visible={this.state.showModal}
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    mode={this.state.modalMode}
                    onDialogResult={this.onModalResponse}
                    workflowItem={this.props.workflowItem}
                />
            </Portlet>
        );
    }
}



export default withDetailPageProps(ExtensionDetailPage);
