//edited on 18/01/2024, Create Related Notices - Default Config v14, FD-11036
const CreateRelatedBlackList = {
    1:[8,9,10,39,44,54,78,79],
    2:[8,9,10,39,44,54,78,79],
    4:[8,9,10,39,44,54,78,79],
    56:[8,9,10,39,44,54,78,79],
    61:[8,9,10,39,44,54,78,79],
    5:[38,39,44,50,51,54,62,72,73,77,78,79],
    6:[38,39,44,50,51,54,62,72,73,77,78,79],
    7:[38,39,44,50,51,54,62,72,73,77,78,79],
    8:[8,9,10,38,39,44,50,51,54,62,72,73,77,78,79],
    9:[8,9,10,38,39,44,50,51,54,62,72,73,77,78,79],
    10:[8,9,10,38,39,44,50,51,54,62,72,73,77,78,79],
    37:[38,39,44,50,51,54,62,72,73,77,78,79],
    46:[8,9,10,38,39,44,50,51,54,62,72,73,77,78,79],
    67:[38,39,44,50,51,54,62,72,73,77,78,79],
    42:[38,39,44,50,51,54,62,72,73,77,78,79],
    40:[38,39,44,50,51,54,62,72,73,77,78,79],
    50:[8,9,10,38,39,44,54,62,72,73,77,78,79],
    51:[8,9,10,38,39,44,54,62,72,73,77,78,79],
    14:[8,9,10,39,44,54,78,79],
    15:[8,9,10,39,44,54,78,79],
    17:[8,9,10,39,44,54,78,79],
    16:[8,9,10,39,44,54,78,79],
    57:[8,9,10,39,44,54,78,79],
    58:[8,9,10,39,44,54,78,79],
    59:[8,9,10,39,44,54,78,79],
    60:[8,9,10,39,44,54,78,79],
    63:[8,9,10,39,44,54,78,79],
    64:[8,9,10,39,44,54,78,79],
    65:[8,9,10,39,44,54,78,79],
    66:[8,9,10,39,44,54,78,79],
    49:[8,9,10,39,44,54,78,79],
    18:[8,9,10,39,44,54,78,79],
    19:[8,9,10,39,44,54,78,79],
    20:[8,9,10,39,44,54,78,79],
    21:[8,9,10,39,44,54,78,79],
    22:[8,9,10,39,44,54,78,79],
    23:[8,9,10,39,44,55,78,79],
    48:[8,9,10,39,44,54,78,79],
    54:[8,9,10,39,43,44,54,78,79],
    47:[8,9,10,39,44,54,78,79],
    24:[8,9,10,39,44,55,78,79],
    25:[8,9,10,39,44,54,78,79],
    27:[8,9,10,39,44,54,78,79],
    29:[8,9,10,39,44,54,78,79],
    28:[8,9,10,39,44,54,78,79],
    30:[8,9,10,39,44,54,78,79],
    31:[8,9,10,39,44,54,78,79],
    32:[8,9,10,39,44,54,78,79],
    33:[8,9,10,39,44,54,78,79],
    34:[8,9,10,39,44,54,78,79],
    52:[8,9,10,39,44,54,78,79],
    53:[8,9,10,39,44,54,78,79],
    38:[8,9,10,39,44,54,78,79],
    62:[8,9,10,39,44,54,78,79],
    43:[8,9,10,39,44,54,78,79],
    55:[8,9,10,39,44,54,78,79],
    39:[8,9,10,39,44,54,78,79],
    44:[8,9,10,39,44,54,78,79],
    68:[8,9,10,39,44,54,78,79],
    69:[8,9,10,39,44,54,78,79],
    70:[8,9,10,39,44,54,78,79],
    71:[8,9,10,39,44,54,78,79],
    72:[8,9,10,39,44,54,78,79],
    73:[8,9,10,39,44,54,78,79],
    74:[8,9,10,39,44,54,78,79],
    75:[39,44,54,78,79],
    76:[39,44,54,78,79],
    77:[8,9,10,39,44,54,78,79],
    78:[8,9,10,39,44,54,78,79],
    79:[8,9,10,39,44,54,79],    
}
export const CreateRelatedBlackListGlobal = [78,79];
export default CreateRelatedBlackList;