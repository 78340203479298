import { RoutesConfig as DefaultConfig } from "./default";
const configOverrideFile = process.env.REACT_APP_PROTECTED_ROUTES_CONFIG;
let overrideConfig = {}
if(configOverrideFile){
    try {
        overrideConfig = require('./' + configOverrideFile).default;
    } catch (error) {
        alert("Private routes configuration file not found:"+configOverrideFile)
    }
}
export const RoutesConfig = {...DefaultConfig, ...overrideConfig };

export const RoutesConfigPaths = Object.keys(RoutesConfig).map(x => ({ path: x }));