import React from 'react';
import * as contractsCrud from "../../../../../crud/contracts.crud";
import { SelectBox } from 'devextreme-react';
export default class ContractSelectionComponent extends React.Component {

    constructor(props) {
        super(props);
        const contractid = props.data.data.contractid;
        this.state = {
            companyid: props.data.row.data.companyid,
            contracts: null,
            contractid
        };

    }
    componentDidMount() {
        this.StartClock()
        this.fetchCompanyContracts(this.state.companyid);
    }
    componentWillUnmount() {
        this.StopClock();
    }

    StartClock = () => {
        if (this.timerId) {
            clearInterval(this.timerId);
        }
        this.timerId = setInterval(this.ClockTick, (1 * 1000));
    }
    StopClock = () => {
        if (this.timerId) {
            clearInterval(this.timerId);
        }
    }

    ClockTick = () => {
        const { companyid } = this.props.data.row.data;
        if (companyid !== this.state.companyid) {
            this.props.data.setValue(null);
            this.setState({ companyid, contracts: null, contractid: null }, () => { this.fetchCompanyContracts(companyid); });
        }
    }
    fetchCompanyContracts = async (companyid) => {
        if (companyid) {            
            const { data } = await contractsCrud.getContractOptions(companyid);
            this.setState({ contracts: data });
        }
    }

    syncDataGridSelection = (e) => {
        if (this.state.contractid !== e.value) {
            this.setState({
                contractid: e.value || null,
            }, () => {
                this.props.data.setValue(this.state.contractid);
            });
        }

    }
    render() {
        return (
            <SelectBox
                value={this.state.contractid}
                valueExpr="contractid"
                deferRendering={false}
                displayExpr="name"
                placeholder="Leave empty for all contracts..."
                showClearButton={true}
                dataSource={this.state.contracts}
                onValueChanged={this.syncDataGridSelection}
                contentRender={this.dataGridRender}
            />
        );
    }
}