/* eslint-disable eqeqeq */
import React, { Component } from "react";
import ResourcesGridComponent from './ResourcesGridComponent.js';
import OptionsGridComponent from './OptionsGridComponent.js';
import * as customFieldCrud from "../../../../../crud/customField.crud";
import { withSnackbar } from 'notistack';
import { Badge } from "react-bootstrap";

import DataGrid, {
    // Paging,
    // HeaderFilter,
    // SearchPanel,
    Editing,
    Column,
    Lookup,
    RequiredRule,
    PatternRule,
    Popup,
    Form,
    RowDragging,
    Sorting,
    Button,
    Toolbar,
    Item as ToolbarItem,
    Scrolling
} from 'devextreme-react/data-grid';
import { injectIntl } from "react-intl";
import { Item as FormItem, TabbedItem, TabPanelOptions, Tab, } from 'devextreme-react/form';
import { CustomRule, } from 'devextreme-react/validator';

const initialCustomerField = {
    ismandatory: false,
    includelisting: false,
    maxlength: 250,
    selectoptions: []
}

class ObjectFieldList extends Component {
    constructor(props) {
        super(props);
        this.grid = null;

        this.fieldTypes = [
            { value: "TEXT_SINGLE", display: "Textbox" },
            { value: "TEXT_MULTI", display: "Multiline Textbox" },
            { value: "SELECTBOX", display: "Selectbox" },
            { value: "MULTI_SELECT", display: "Multiple Select Box" },
            { value: "CHECKBOX", display: "Checkbox" },
            { value: "EMAIL", display: "Email" },
            { value: "DATE", display: "Date" },
            { value: "DATETIME", display: "Date-Time" },
            { value: "NUMBER", display: "Number" },
            { value: "INTEGER", display: "Integer" },
            { value: "CURRENCY", display: "Currency" }
        ];

        if (props.object.objectname === 'Workflows') {
            this.fieldTypes.push({ value: "SINGLE_CONTRACT_USER", display: "Single Contract User" });
            this.fieldTypes.push({ value: "MULTIPLE_CONTRACT_USERS", display: "Multiple Contract Users" });
        }

        this.fieldTypes.push({ value: "WEB_LINK", display: "Web Link" });

        this.state = {
            fields: [],
            orderChanged: false,
            clonedItem: {}
        };
    }
    componentDidMount() {
        let fields = [];
        if (this.props.fields) {
            fields = JSON.parse(JSON.stringify(this.props.fields));
        }

        fields = fields.map(field => ({ ...field, selectoptions: field.selectoptions ? field.selectoptions.split('|').map(opt => ({ option: opt })) : [] }));
        this.setState({ fields });
    }

    onRowInserted = (e) => {
        e.component.navigateToRow(e.key);
    }

    labelsCellTemplate = (container, options) => {
        const noBreakSpace = '\u00A0';
        // const text = (options.value || []).map((resource) => resource.labelvalue).join(', ');
        const engResource = (options.value || []).find(resource => resource.lang === "en-GB")
        let text = "";
        if (engResource) {
            text = engResource.labelvalue;
            if (options.value.length > 1) {
                text += ` (${options.value.length - 1} more)`;
            }
        }
        container.textContent = text || noBreakSpace;
        container.title = text;
    }
    optionsCellTemplate = (container, options) => {
        const noBreakSpace = '\u00A0';
        const text = (options.value || []).map(opt => opt.option).join(', ');
        container.textContent = text || noBreakSpace;
        container.title = text;
    }
    onReorder = (e) => {
        if (e.toIndex === e.fromIndex) { return; }
        e.promise = this.processReorder(e);
    }
    processReorder = async (e) => {
        return new Promise(async (resolve, reject) => {
            try {
                const visibleRows = e.component.getVisibleRows();
                // const newOrderIndex = visibleRows[e.toIndex].data.fieldorder;

                await customFieldCrud.changeOrder(e.itemData.customfieldid, e.toIndex);


                const newFields = [...this.state.fields];
                const toIndex = newFields.indexOf(visibleRows[e.toIndex].data);
                const fromIndex = newFields.indexOf(e.itemData);

                newFields.splice(fromIndex, 1);
                newFields.splice(toIndex, 0, e.itemData);
                newFields.forEach((field, index) => { field.fieldorder = index + 1 });
                this.setState({ fields: newFields, }, () => {
                    this.updateParentList();
                });
                resolve(true);
            } catch (error) {
                this.props.enqueueSnackbar(error.toString(), { variant: "error" });
                reject(error);
            }

        })

    }
    optionObjectsToString = (objects) => {
        if (objects) {
            return objects.reduce((str, opt) => str + "|" + opt.option, "").substring(1);
        }
        return "";
    }
    onRowInserting = async (e) => {
        let newLabels = [];
        if (e.data.labels) {
            newLabels = e.data.labels.map(r => ({ lang: r.lang, labelvalue: r.labelvalue }));
        }
        const newCustomField = { ...e.data, labels: newLabels, objectname: this.props.object.objectname, companyid: this.props.companyid };
        newCustomField.selectoptions = this.optionObjectsToString(newCustomField.selectoptions);
        e.cancel = this.createCustomFieldPromise(newCustomField, e);
    }
    onRowUpdating = async (e) => {
        const newObject = { ...e.oldData, ...e.newData };
        //IF LABEL RESOURCES ARE NOT MODIFIED DONT INCLUDE IN SAVE REQUEST
        if (!e.newData.hasOwnProperty("labels")) {
            delete newObject.labels;
        }
        newObject.selectoptions = this.optionObjectsToString(newObject.selectoptions);
        e.cancel = this.saveCustomFieldPromise(newObject, e);
    }
    onRowRemoving = async (e) => {
        e.cancel = this.deleteCustomFieldPromise(e.data.customfieldid);
    }
    onRowRemoved = async (e) => {
        const newFields = [...this.state.fields];
        newFields.forEach((field, index) => { field.fieldorder = index + 1 });
        this.setState({ fields: newFields }, () => { this.updateParentList(); });
    }
    onRowValidating = async (e) => {
        const newObject = { ...e.oldData, ...e.newData };
        e.promise = new Promise((resolve, reject) => {
            //CHECK IF LABEL TEXT PROVIDED
            if (newObject.labels) {
                const enLabel = newObject.labels.find(r => r.lang === "en-GB");
                if (!enLabel) {
                    e.errorText = "English label translation is required";
                    e.isValid = false;
                }
            }
            else {
                e.errorText = "Label translations are required";
                e.isValid = false;
            }

            //CHECK IF SELECT OPTIONS EXIST
            if (newObject.fieldtype === "MULTI_SELECT" || newObject.fieldtype === "SELECTBOX") {
                if (!(newObject.selectoptions && newObject.selectoptions.length > 0)) {
                    e.errorText = "Select options are required for this field type!";
                    e.isValid = false;
                }
            }

            //CHECK IF SELECT OPTIONS EXIST
            if (newObject.fieldtype === "SINGLE_CONTRACT_USER" || newObject.fieldtype === "MULTIPLE_CONTRACT_USERS") {
                if (newObject.selectoptions && newObject.selectoptions.length > 0) {
                    e.errorText = "Select options must be empty for this field type!";
                    e.isValid = false;
                }
            }

            //DONT CHANGE STATUS IN OTHER CASES
            resolve();
        });
    }
    onInitNewRow = async (e) => {
        e.data = {
            ...initialCustomerField,
            ...this.state.clonedItem,
            locked: false,
            isactive: true,
            objectname: this.props.object.objectname,
            companyid: this.props.companyid
        };
        //delete in case cloned item
        delete e.data.customfieldid;
        delete e.data.fieldorder;
        if (this.props.workflow) {
            e.data.workflowid = this.props.workflow.id;
            e.data.subtype = this.props.subtype;
        }
        this.setState({ clonedItem: {} })
    }
    createCustomFieldPromise = (data, e) => {
        return new Promise(async (resolve, reject) => {
            try {
                const resp = await customFieldCrud.createCustomField(data);
                e.data.customfieldid = resp.data;
                let maxOrder = 0;
                if (this.state.fields.length > 0) {
                    maxOrder = Math.max(...this.state.fields.map(cf => cf.fieldorder));
                }
                e.data.fieldorder = maxOrder + 1;
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
                

            }
        })
    }
    saveCustomFieldPromise = (data, e) => {
        return new Promise(async (resolve, reject) => {
            try {
                await customFieldCrud.saveCustomField(data);
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        })
    }
    deleteCustomFieldPromise = (customfieldid) => {
        return new Promise(async (resolve, reject) => {
            try {
                await customFieldCrud.deleteCustomField(customfieldid);
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        })
    }
    isDeleteIconDisabled = (e) => {
        return e.row.data.customfieldid === 1;
    }
    allowDeleting = (e) => {
        return !e.row.data.locked;
    }
    cloneIconClick = (e) => {
        const clonedItem = { ...e.row.data };
        this.setState({ clonedItem }, () => {
            // this.grid.instance.editRow(e.row.rowIndex);
            this.grid.instance.addRow();
        });
        // e.event.preventDefault();
    }
    onDragStart = (e) => {
        //REORDERING IS NOW BACK ENABLED
        //SINCE THE ORDER WILL BE STORED ON VALUE TABLE, THIS WILL HAVE NO EFFECT ON HISTORIC
        // e.cancel = this.state.fields.some(cf => cf.fieldorder >= e.itemData.fieldorder && cf.locked);
    }
    cellRenderStatus = (cellInfo) => {
        return (<Badge variant={cellInfo.data.isactive ? 'success' : 'warning'}>{cellInfo.data.isactive ? 'Active' : 'Passive'}</Badge>);
    }
    toolbarTitleRender = () => {
        if (this.props.object) {
            return (
                <div>
                    <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">{this.props.object.title}</h3>
                </div>
            );
        }
        return null;

    }
    onAdd = async (e) => {
        try {
            const newItem = { ...e.itemData };
            const newFields = [...this.state.fields];
            //override newitem  properties from other grid
            newItem.companyid = this.props.companyid;
            newItem.objectname = this.props.object.objectname;
            if (this.props.workflow) {
                newItem.workflowid = this.props.workflow.id;
                newItem.subtype = this.props.subtype;
            }
            newFields.splice(e.toIndex, 0, newItem);
            newFields.forEach((field, index) => { field.fieldorder = index + 1 });
            //CREATE A SIMPLIFIED REQUEST OBJ
            //SINCE MOST PROPS NOT NECESSARY
            const requestObj = {
                companyid: newItem.companyid,
                customfieldid: newItem.customfieldid,
                objectname: newItem.objectname,
                subtype: newItem.subtype,
                workflowid: newItem.workflowid,
            }
            await customFieldCrud.changeObject(requestObj, e.toIndex);
            this.setState({ fields: newFields }, () => { this.updateParentList(); });
        } catch (error) {
            this.props.onTriggerRefresh(error);
            this.props.enqueueSnackbar(error.toString(), { variant: "error" });
        }
    }
    onRemove = (e) => {
        const newFields = [...this.state.fields];
        newFields.splice(e.fromIndex, 1);
        newFields.forEach((field, index) => { field.fieldorder = index + 1 });
        this.setState({ fields: newFields }, () => { this.updateParentList(); });
    }
    onMove = (e) => {
        console.log("OnMove!!");
    }
    updateParentList = () => {
        this.props.onFieldsUpdated(this.state.fields, this.props.object.objectname, (this.props.workflow || {}).id, this.props.subtype);
    }
    characterFilter = (e) =>{
        var event = ((e.event||{}).originalEvent||{});
        var keyCode = event.keyCode;

        // Allow: Backspace, Delete, Tab, Escape, Enter '.', ',' and space
        if ([46, 8, 9, 27, 13, 110, 190, 188, 32].includes(keyCode) ||
        // Allow: Numbers
        ([48,49,50,51,52,53,54,55,56,57].includes(keyCode) && ["1","2","3","4","5","6","7","8","9","0"].includes( event.key))||
        // Allow: Ctrl+A
        (keyCode == 65 && event.ctrlKey === true) ||
        // Allow: Ctrl+C
        (keyCode == 67 && event.ctrlKey === true) ||
        // Allow: Ctrl+X
        (keyCode == 88 && event.ctrlKey === true) ||
        // Allow: home, end, left, right
        (keyCode >= 35 && keyCode <= 39)) {
        // Let it happen, don't do anything
            return;
        }
   
        // Ensure that it is a letter and stop the keypress otherwise
        if (keyCode < 65 || keyCode > 90) {
            event.preventDefault();
        }
    }
    handlePastedValues = (e) => {
        const pasteData = e.event.originalEvent.clipboardData.getData('text/plain');
        if(!/^[a-zA-Z0-9\s.,]*$/i.test(pasteData)){
            e.event.preventDefault();
            this.props.enqueueSnackbar("Invalid characters!", { variant: "error" });
        }
    }
    render() {
        return (
            <DataGrid
                ref={(ref) => { this.grid = ref; }}
                keyExpr="customfieldid"
                dataSource={this.state.fields}
                showBorders={true}
                showRowLines={true}
                // onSaving={this.onSaving}
                onRowInserted={this.updateParentList}
                onRowUpdated={this.updateParentList}
                // onRowRemoved={this.updateParentList}
                onRowUpdating={this.onRowUpdating}
                onRowInserting={this.onRowInserting}
                onRowRemoving={this.onRowRemoving}
                onRowValidating={this.onRowValidating}
                onInitNewRow={this.onInitNewRow}
                onRowRemoved={this.onRowRemoved}
                // onRowPrepared={this.onRowPrepared}
                // title="test"
                height="100%"
                columnChooser={{ enabled: true }}

            >
                {/* <Template name="toolbarTitle" render={this.toolbarTitleRender} /> */}
                <RowDragging
                    onAdd={this.onAdd}
                    onRemove={this.onRemove}
                    onMove={this.onMove}
                    onDragStart={this.onDragStart}
                    allowReordering={true}
                    onReorder={this.onReorder}
                    dropFeedbackMode="push"
                    group={this.props.object.objectname}
                // filter = ".enable-drag"
                />
                {/* <Paging enabled={true} defaultPageSize={15} /> */}
                {/* <HeaderFilter visible={true} /> */}
                {/* <SearchPanel visible={true} /> */}
                <Sorting mode="none" />
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={this.allowDeleting}
                    useIcons={true}
                >
                    <Popup title="Custom Field Info" showTitle={true} height={600} width={600} />
                    <Form labelLocation="top" width="98%" showValidationSummary={true}>
                        <TabbedItem colSpan={2} >
                            <TabPanelOptions deferRendering={true} />
                            <Tab title="Label resources">
                                <FormItem dataField="labels" label={{ visible: false }}>
                                </FormItem>
                            </Tab>
                            <Tab title="Properties" colCount={2} colSpan={2}>
                                <FormItem dataField="fieldtype" />
                                <FormItem dataField="isactive" editorType="dxSwitch" editorOptions={{ switchedOffText: "PASSIVE", switchedOnText: "ACTIVE", width: 80, hint: this.props.intl.formatMessage({ id: "CUSTOMFIELDS.HINTS.PASSIVE_FIELDS" })} } />
                                <FormItem dataField="fieldname" editorType="dxTextBox" colSpan={2} editorOptions={{  maxLength: 250, onKeyDown:this.characterFilter, onPaste:this.handlePastedValues } } />
                                <FormItem dataField="description" editorType="dxTextArea" colSpan={2} editorOptions={{height: 100, maxLength: 500, onKeyDown:this.characterFilter, onPaste:this.handlePastedValues }} />
                                <FormItem dataField="includelisting" editorType="dxSwitch" />
                                <FormItem dataField="ismandatory" editorType="dxSwitch" />
                                <FormItem dataField="maxlength" editorType="dxNumberBox" />
                            </Tab>
                            <Tab title="Select options">
                                <FormItem dataField="selectoptions" label={{ visible: false }}>
                                </FormItem>
                            </Tab>
                        </TabbedItem>
                    </Form>
                </Editing>

                <Column dataField="customfieldid" allowEditing={false} visible={false} caption="ID" />
                <Column dataField="fieldorder" allowEditing={false} visible={false} caption="Order" />
                <Column dataField="fieldname" caption="Name" allowSorting={false} allowFiltering={false} visible={true} >
                    <RequiredRule />
                    <PatternRule pattern="^[a-zA-Z0-9\s.,]*$" message="Illegal character." />
                </Column>
                <Column
                    dataField="labels"
                    caption="Label text"
                    // width={200}
                    allowSorting={false}
                    editCellComponent={ResourcesGridComponent}
                    cellTemplate={this.labelsCellTemplate}
                    dataType="object"
                    // calculateFilterExpression={this.calculateFilterExpression}
                    visible={false}
                >
                    {/* <RequiredRule /> */}
                </Column>
                <Column
                    dataField="selectoptions"
                    editCellComponent={OptionsGridComponent}
                    cellTemplate={this.optionsCellTemplate}
                    dataType="object"
                    visible={false}
                    caption="Options"
                >
                </Column>
    
                <Column dataField="description" caption="Description" allowSorting={false} allowFiltering={false} visible={false} >
                    <RequiredRule />                    
                    <PatternRule pattern="^[a-zA-Z0-9\s.,]*$" message="Illegal character." />
                </Column>
                <Column dataField="fieldtype" caption="Field Type" visible={false} >
                    <Lookup
                        dataSource={this.fieldTypes}
                        valueExpr="value"
                        displayExpr="display"
                    />
                    <RequiredRule />
                </Column>
                <Column dataField="includelisting" dataType="boolean" caption="Filterable" visible={false} >
                    <CustomRule
                        type="custom"
                        validationCallback={(e) => {
                            if (e.value === true || e.value === false) { return true; }
                            return false;
                        }}
                        message="Filterable option is required"
                    />
                </Column>
                <Column dataField="ismandatory" dataType="boolean" caption="Is Mandatory" visible={false}  >
                    <CustomRule
                        type="custom"
                        validationCallback={(e) => {
                            if (e.value === true || e.value === false) { return true; }
                            return false;
                        }}
                        message="Mandatory option is required"
                    />
                </Column>
                <Column dataField="isactive" dataType="boolean" caption="Is Active" cellRender={this.cellRenderStatus} >
                    <CustomRule
                        type="custom"
                        validationCallback={(e) => {
                            if (e.value === true || e.value === false) { return true; }
                            return false;
                        }}
                        message="Active option is required"
                    />
                </Column>
                <Column dataField="maxlength" dataType="number" caption="Max Length" visible={false} >
                    <RequiredRule />
                </Column>
                <Column name="editButtons" type="buttons" >
                    <Button name="edit" />
                    <Button name="delete" />
                </Column>
                <Column name="cloneButton" type="buttons" >
                    <Button name="clone" hint="Clone" icon="copy" visible={true} disabled={false} onClick={this.cloneIconClick} />
                </Column>
                {/* <Template name="toolbarTitle" render={this.toolbarTitleRender} /> */}
                <Toolbar>
                    <ToolbarItem location="before">
                        <div>
                            <h3 style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', padding: 0, margin: 0, lineHeight: 1.2 }} className="kt-portlet__head-title">
                                {this.props.title || this.props.object.title}
                            </h3>
                        </div>
                    </ToolbarItem>
                    <ToolbarItem name="columnChooserButton" />
                    <ToolbarItem name="addRowButton" />
                    {/* showText="always" */}
                </Toolbar>
                <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
            </DataGrid>
        );

    }
}


export default injectIntl(withSnackbar(ObjectFieldList));
