/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import Common from '../shared/common';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

function ContractTimeline2(props) {
  let navigate = useNavigate();
  const getDate = (label, date, index) => {
    return (
      <div key={index.toString()} className="kt-timeline-v4__item">
        <span className="kt-timeline-v4__item-time">{moment(date).format('L').replace(/\//g, '.')}</span>
        <div className="kt-timeline-v4__item-cricle">
          <i className={"fa fa-genderless kt-font-brand"} />
        </div>
        <div className="kt-timeline-v4__item-text kt-padding-top-5">
          {label}
        </div>
      </div>
    );
  }
  if (!props.expectedcompletiondate && !props.dashboard.lastprogramsubmitted && !props.dashboard.lastprogramaccepted) {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <span style={{ fontFamily: 'Poppins, Helvetica, sans-serif', fontSize: '1.1rem', color: '#74788d', textAlign: 'center' }}>{props.intl.formatMessage({ id: 'GENERAL.WARNING.NO_ITEM_AVAILABLE' }, { ITEM_NAME: props.dashboard.resources.programmeTitle })}</span>
      </div>
    );
  }
  let dates = [];
  let plannedCompletionLabelText = "";
  if (props.dashboard.resources.programmePlannedCompletionLabel) {
    plannedCompletionLabelText = props.dashboard.resources.programmePlannedCompletionLabel;
  }
  else {
    plannedCompletionLabelText = props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.PLANNED_COMPLETION' }, { PROGRAMME_TITLE: Common.CapitalFirstLetter(props.dashboard.resources.programmeTitle) });
  }
  if (props.expectedcompletiondate && props.dashboard.resources.displayPlannedCompletionOnLatest) {
    dates.push({ label: plannedCompletionLabelText, date: new Date(props.expectedcompletiondate) });
  }
  if (props.dashboard) {
    if (props.dashboard.lastprogramsubmitted) {
      dates.push({ label: props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.PROGRAMME_LAST_SUBMISSION' }, { PROGRAMME_TITLE: props.dashboard.resources.programmeTitle }), date: new Date(props.dashboard.lastprogramsubmitted) });
    }
    if (props.dashboard.lastprogramaccepted) {
      dates.push({ label: props.intl.formatMessage({ id: 'CONTRACT.DASHBOARD.PROGRAMME_LAST_ACCEPTED' }, { PROGRAMME_TITLE: Common.CapitalFirstLetter(props.dashboard.resources.programmeTitle) }), date: new Date(props.dashboard.lastprogramaccepted) });
    }
  }
  // dates.sort((a, b) => a.date - b.date);
  return (
    <>
      <div className="kt-widget14">
        <div className="kt-widget14__header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h3 className="kt-widget14__title"><FormattedMessage id="CONTRACT.DASHBOARD.PROGRAMME_DATES" values={{ PROGRAMME_TITLE: Common.CapitalFirstLetter(props.dashboard.resources.programmeTitle) }} /></h3>
          <a onClick={() => { navigate(`/contract/${props.contractid}/programme/supplier/list`); }} style={{ textDecoration: 'underline' , fontSize:'1.1rem'}}><FormattedMessage id="CONTRACT.DASHBOARD.VIEW_REGISTER" /></a>
        </div>
        <PerfectScrollbar
          options={perfectScrollbarOptions}
          style={{ maxHeight: "40vh", position: "relative" }}
        >
          <div className="kt-timeline-v4 ps ps--active-y" >
            <div className="kt-timeline-v4__items kt-padding-top-25 kt-padding-bottom-30">
              {
                dates.map((itm, index) => {
                  return getDate(itm.label, itm.date, index);
                })
              }
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </>
  );
}


export default injectIntl(ContractTimeline2)