import React from 'react';
import { injectIntl } from "react-intl";
import List from 'devextreme-react/list';
import CustomStore from 'devextreme/data/custom_store';
import * as sharepointCrud from '../../../crud/sharepoint.crud';
import Spinner from '../../../partials/layout/Spinner';
import { withSnackbar } from 'notistack';

class SharepointFileList extends React.Component {
    constructor(props) {
        super(props);
        const assetid = props.assetid;
        let datasource = null;
        if(props.assetid){
            datasource = this.getDatasource(props.assetid);
        }
        this.state = {
            selectedItems: null,
            currentassetid: assetid,
            datasource: datasource
        }


    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.currentassetid !== this.props.assetid) {
            this.setDatasource();
        }
    }
    setDatasource = () => {
        let newDatasource = null;
        if (this.props.assetid) {
            newDatasource = this.getDatasource(this.props.assetid);
        }
        this.setState({
            currentassetid: this.props.assetid,
            datasource: newDatasource
        })
    }
    getDatasource = (assetid) => {
        return new CustomStore({
            key: 'ID',
            loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
            load: () => {
                var promise = new Promise(async (resolve, reject) => {
                    try {
                        const { data: items } = await sharepointCrud.getFolderItems(assetid, this.props.contractorcompanyid)
                        resolve(items);
                    } catch (error) {
                        this.props.enqueueSnackbar(error.toString(), { variant: 'error', });
                        reject(error)
                    }

                });
                return promise;
            },
            paginate: true,
        });
    }

    onSelectedItemKeysChange = (args) => {
        if (args.name === 'selectedItems') {
            this.props.onSelectionChanged(args.value);
            this.setState({ selectedItems: args.value });
        }
    }
    render() {
        if (!this.state.datasource) {
            return (<Spinner />);
        }
        return (
            <List
                dataSource={this.state.datasource}
                showSelectionControls={true}
                selectionMode="multiple"
                pageLoadMode="scrollBottom"
                displayExpr="Name"
                searchEnabled={true}
                searchExpr="Name"
                searchMode="contains"
                onOptionChanged={this.onSelectedItemKeysChange}
            />
        );
    }
}


export default withSnackbar(injectIntl(SharepointFileList));