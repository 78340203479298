import React from 'react';

const isAssociatedNoticeEnabled = process.env.REACT_APP_CREATE_ASSOCIATED_NOTICE_ENABLED === 'true';
class BaseCreatePage extends React.Component {

    componentWillUnmount() {
        if (this.props.closeSnackbar) {
            this.props.closeSnackbar("PersistentFormWarning");
        }
    }
    appendRelationship = (object) => {
        if (isAssociatedNoticeEnabled && ((this.props.location || {}).state || {}).itemBaseProps) {
            // const queryObj = QueryString.parse(this.props.location.search);
            // if (Object.keys(queryObj).length > 0) {
                const relationshipItems = [];

                const relationship = {
                    referenceid: this.props.location.state.itemBaseProps.refid,
                    referencetype: this.props.location.state.itemBaseProps.reftype,
                    path: this.props.location.state.itemBaseProps.path,
                    workflowgroupid: this.props.location.state.itemBaseProps.wfgid,
                    workflowid: this.props.location.state.itemBaseProps.wfid,
                    creationsource: 1 // 1 = System Generated, 2 = User Selection
                };

                relationshipItems.push({ ...relationship });

                const newRelations = {
                    referencetype: this.props.workflowItem.config.referenceType,
                    path: this.props.workflowItem.paths.basePath,
                    workflowgroupid: this.props.workflowGroupItem.id,
                    workflowid: this.props.workflowItem.id,
                    relationships: relationshipItems
                }
                object.relationships = newRelations;
            // }
        }
    }
}

export default BaseCreatePage;
