import React, { Component } from "react";
import { connect } from "react-redux";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGrid, { Column, SearchPanel, Paging, ColumnChooser, ColumnFixing, Editing, HeaderFilter, FilterPanel, FilterRow } from 'devextreme-react/data-grid';
import * as tagCrud from "../../../../crud/tag.crud";
import { communicationTagCategories, accessLevel } from '../../../../shared/lookup';
import { withSnackbar } from 'notistack';
import { injectIntl } from "react-intl";
import moment from 'moment';
import * as companiesDuck from '../../../../store/ducks/companies.duck';
import ContractSelectionComponent from "./CustomComponents/ContractSelectionComponent";
import * as contractsCrud from "../../../../crud/contracts.crud";

class CommunicationTags extends Component {
  state = {
    tags: null,
  };

  componentDidMount() {
    this.props.loadCompanies();
    this.fetchTags();
    this.fetchContracts();
  }
  fetchTags = async () => {
    const response = await tagCrud.getTagsByCategory(communicationTagCategories);
    this.setState({ tags: response.data });
  }
  fetchContracts = async () => {
        const { data } = await contractsCrud.getContractOptions();
        this.setState({ contracts: data });
}
  onRowUpdating = async (e) => {
    const newObject = { ...e.oldData, ...e.newData };
    e.cancel = this.saveTagPromise(newObject);
  }
  saveTagPromise = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await tagCrud.updateTag(data);
        resolve(false);
      } catch (error) {
        console.log(error);
        reject(error)
      }
    });
  }
  onRowRemoving = async (e) => {
    // const deleteObj = { ...e.data };
    // deleteObj.deleted = 1;
    e.cancel = this.deleteTagPromise(e.data.tagid);
  }
  deleteTagPromise = (tagid) => {
    return new Promise(async (resolve, reject) => {
      try {
        await tagCrud.deleteTag(tagid);
        resolve(false);
      } catch (error) {
        console.log(error);
        reject(error)
      }
    });
  }
  onRowInserting = async (e) => {
    const newTag = { ...e.data };
    e.cancel = this.createTagPromise(newTag, e);
  }
  createTagPromise = (data, e) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await tagCrud.createTag(data);
        e.data.tagid = resp.data;
        resolve(false);
      } catch (error) {
        console.log(error);
        reject(error)
      }
    })
  }
  onInitNewRow = async (e) => {
    e.data = { deleted: 0, accesslevel: 1 };
  }
  render() {
    if (!this.state.tags || !this.props.companies|| !this.state.contracts) {
      return <PortletSpinner />;
    }
    return (
      <Portlet>
        <PortletBody>
          <DataGrid
            dataSource={this.state.tags}
            keyExpr="tagid"
            hoverStateEnabled={true}
            rowAlternationEnabled={false}
            showBorders={true}
            showColumnLines={true}
            showRowLines={true}
            remoteOperations={false}
            allowColumnReordering={true}
            columnResizingMode='widget'
            onContextMenuPreparing={this.addGridContextMenuItems}
            onRowRemoving={this.onRowRemoving}
            onRowUpdating={this.onRowUpdating}
            onRowInserting={this.onRowInserting}
            onInitNewRow={this.onInitNewRow}

          >
            <Editing
              mode="popup"
              allowUpdating={true}
              allowDeleting={true}
              allowAdding={true}
              confirmDelete={true}
              popup={{ height: 500, width: 600, showTitle: true }}
              useIcons={true}
              form={{
                items: [
                  { dataField: "category", colSpan: 2 },
                  { dataField: "name", colSpan: 2 },
                  { dataField: "accesslevel", colSpan: 2 },
                  { dataField: "companyid", colSpan: 2, editorOptions: { showClearButton: true, placeholder: this.props.intl.formatMessage({ id: "ADMIN.SETTINGS.HINTS.COMPANYID" }), hint: this.props.intl.formatMessage({ id: "ADMIN.SETTINGS.HINTS.COMPANYID" })} },
                  { dataField: "contractid", colSpan: 2 },
                ]
              }}
            />
            <HeaderFilter visible={true} />
            <FilterPanel visible={true} />
            <FilterRow applyFilter="auto" visible={true} />
            <ColumnChooser enabled={true} />
            <ColumnFixing enabled={true} />
            <SearchPanel visible={true} highlightCaseSensitive={false} defaultText="" />
            <Column
              caption="Category"
              dataField="category"
              lookup={{ dataSource: communicationTagCategories }}
              validationRules={[{ type: "required" }]}
            />
            <Column
              caption="Name"
              dataField="name"
              validationRules={[{ type: "required" }]}
            />
            <Column
              caption="Access Level"
              dataField="accesslevel"
              lookup={{
                dataSource: accessLevel,
                displayExpr: "value",
                valueExpr: "key",
              }}
              visible={false}
              validationRules={[{ type: "required" }]}
              allowEditing={false}
            />
            <Column
              caption="Company"
              dataField="companyid"
              lookup={{
                dataSource: this.props.companies,
                displayExpr: "name",
                valueExpr: "companyid",
              }}
            />
            <Column
              caption="Contract"
              dataField="contractid"
              editCellComponent={ContractSelectionComponent}
              lookup={{
                dataSource: this.state.contracts,
                displayExpr: "name",
                valueExpr: "contractid",
              }}
            />
            <Column
              caption="Create Date"
              dataField="createdate"
              dataType="date"
              format={(date) => moment(date).format('DD/MM/YYYY')}
              allowEditing={false}
              visible={false}
            />
            <Paging pageSize={10} defaultPageSize={10} defaultPageIndex={0} />
          </DataGrid>
        </PortletBody>
      </Portlet>
    );
  }
}

const mapStateToProps = (store) => ({
  userProfile: store.auth.userProfile,
  companies: store.companies.companies
});

const mapDispatchToProps = {
  loadCompanies: companiesDuck.actions.fetchStart
}

export default injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CommunicationTags)));
