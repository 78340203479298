/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import Spinner from '../partials/layout/Spinner';
import { withRouter } from '../shared/hoc/withRouter';
import { FormattedMessage } from "react-intl";
import { GetAccessRights } from "../router/Protector/Protector";
import Common from '../shared/common';

const isShowMultipleUsersOnDashboardEnabled = process.env.REACT_APP_SHOW_MULTIPLE_USERS_ON_DASHBOARD_ENABLED === "true";

class ContractParties extends React.Component {
  constructor(props) {
    super(props);
    this.canSeeRedirectLink = GetAccessRights("/contract/:id/settings/users", this.props.userProfile).allowAccess;

    this.state = {
      contractUsersWhoWillBeShownOnDashboard: []
    };
  }

  componentDidMount() {
    const filteredUsers = this.props.contractUsers.filter(user => user.showondashboard);
    this.setState({ contractUsersWhoWillBeShownOnDashboard: filteredUsers });
  }

  getUserDetails = (user, title) => {
    if (user) {
     const companiesSame = user.company === user.usercompany
      return (
        <div className="contractParties__item ">
          <div className="contractParties__info ">
            <p className="contractParties__text ">
              {title}<br />
            </p>
            
            {
            companiesSame && (
              <span className="contractParties__username" >{user.user}</span>

            )
          }
          {
            !companiesSame && (
              <span className="contractParties__descUserCompany">{user.user} {' '} {`(`}<span>{user.usercompany}{`)`}</span> </span>
            )
          }
          </div>
          {
            companiesSame && (
              <span className="contractParties__desc" >{user.company}</span>

            )
          }
          {
            !companiesSame && (
              <span className="contractParties__descUserCompany">{user.company} </span>
            )
          }
          
        </div>
      );
    }
    return null;

  }
  render() {
    if (!this.props.contract || !this.props.contractTemplate) {
      return (<Spinner />);
    }
    const { pm, supplier, supervisor } = (this.props.contract.designatedUsers||{});
    
    const supervisorExist = this.props.contract.partystructure === 3 || this.props.contract.partystructure === 4;

    return (
      <div className="kt-widget14">
        {/* <div className="kt-widget1"> */}
        <div className="kt-widget14__header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h3 className="kt-widget14__title"><FormattedMessage id="CONTRACT.MENU.USERS" /></h3>
          {
            this.canSeeRedirectLink && <a onClick={() => { this.props.navigate(`/contract/${this.props.contract.contractid}/settings/users`); }} style={{ textDecoration: 'underline', fontSize:'1.1rem', paddingTop:'0.5rem' }}><FormattedMessage id="CONTRACT.DASHBOARD.VIEW_REGISTER" /></a>
          }
        </div>
        {
          isShowMultipleUsersOnDashboardEnabled && this.state.contractUsersWhoWillBeShownOnDashboard.length > 0 ? (
            <div style={{ height: '280px', overflow: 'auto' }}>
              <table className="table table-row-dashed table-row-gray-300 gy-7">
                <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200" style={{ color: '#3F96C4' }}>
                        <th>Party</th>
                        <th>Name</th>
                        <th>Company</th>
                    </tr>
                </thead>
                <tbody>
                {this.state.contractUsersWhoWillBeShownOnDashboard.map((user, index) => (
                  <tr key={index}>
                    <td>{Common.GetPartyDisplay(user.usergroup, this.props.contractTemplate.resources)}</td>
                    <td>{user.user}</td>
                    <td>{user.usercompany}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
            ) : (
            <div className="contractParties">
              {
                this.getUserDetails(pm, this.props.contractTemplate.resources.PM_NAME)
              }
              {supervisorExist && (
                this.getUserDetails(supervisor, this.props.contractTemplate.resources.SUPERVISOR_NAME)
              )
              }
              {
                this.getUserDetails(supplier, this.props.contractTemplate.resources.SUPPLIER_NAME)
              }
            </div>
            )
        }
        {/* </div> */}
      </div>
    );
  }
}


const mapStateToProps = store => ({
  contract: store.contract.contract,
  contractTemplate: store.contract.template,
  contractUsers: store.contract.users
});

export default withRouter(connect(mapStateToProps)(ContractParties));
