import axios from "../shared/axios";

export function getWebHooks() {
    return axios.get(`api/WebHook/GetWebHooks`);
}

export function getWebHook(id) {
    return axios.get(`api/WebHook/GetWebHook?id=${id}`);
}

export function createWebHook(data) {
    return axios.post(`api/WebHook/CreateWebHook`, data);
}

export function updateWebHook(data) {
    return axios.put(`api/WebHook/UpdateWebHook`, data);
}

export function restoreWebHook(id) {
    return axios.patch(`api/WebHook/RestoreWebHook?id=${id}`);
}

export function deleteWebHook(id) {
    return axios.delete(`api/WebHook/DeleteWebHook?id=${id}`);
}

export function historyWebHook(id) {
    return axios.get(`api/WebHook/GetWebHooksHistory?id=${id}`);
}